

















































import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import {
  ContinuousDiscreteCriterionContent,
  Criterion,
} from '../../../store/types/criterions.types';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

@Component({
  components: { ValidationProvider, ValidationObserver },
})
export default class ContinuousDiscreteCriterionInput extends Vue {
  @Prop()
  public dirtyCriterion!: Criterion;
  public isContinuousDiscreteRangeEnabled: boolean = false;
  public minRangeError: boolean = false;
  public maxRangeError: boolean = false;
  public rangeConfigMin: number = 0;
  public rangeConfigMax: number = 0;

  @Watch('isContinuousDiscreteRangeEnabled')
  public watchIsContinuousDiscreteRangeEnabled(newValue: boolean) {
    if (
      this.dirtyCriterion.content instanceof ContinuousDiscreteCriterionContent
    ) {
      if (newValue) {
        this.dirtyCriterion.content.config = this.dirtyCriterion.content.config
          ? this.dirtyCriterion.content.config
          : [1, 10];
      } else {
        this.dirtyCriterion.content.config = null;
        this.minRangeError = false;
        this.maxRangeError = false;
        this.$emit('disableSubmitButton', false);
      }
    }
  }

  @Watch('dirtyCriterion.content.config', {
    deep: true,
    immediate: true,
  })
  public onRangeConfigChanged(rangeConfig: any) {
    if (rangeConfig) {
      const rangeConfigArray: any[] =
        typeof rangeConfig === 'string' ? rangeConfig.split(', ') : rangeConfig;

      this.isContinuousDiscreteRangeEnabled = true;
      this.rangeConfigMin = rangeConfigArray[0];
      this.rangeConfigMax = rangeConfigArray[1];
    }
  }

  @Watch('rangeConfigMin')
  public onMinChanged(minRange: number) {
    this.minRangeError =
      minRange.toString() !== '' &&
      Number(minRange) >= Number(this.rangeConfigMax)
        ? true
        : false;
    if (this.maxRangeError) {
      this.maxRangeError = this.minRangeError;
    }

    if (
      !this.minRangeError &&
      !this.maxRangeError &&
      this.dirtyCriterion.content instanceof ContinuousDiscreteCriterionContent
    ) {
      const updatedRangeConfig = [minRange, this.rangeConfigMax];
      this.dirtyCriterion.content.config = updatedRangeConfig;
      this.$emit('update:dirtyCriterion', this.dirtyCriterion);
    }

    this.$emit('disableSubmitButton', this.minRangeError);
  }

  @Watch('rangeConfigMax')
  public onMaxChanged(maxRange: number) {
    this.maxRangeError =
      maxRange.toString() !== '' &&
      Number(maxRange) <= Number(this.rangeConfigMin)
        ? true
        : false;
    if (this.minRangeError) {
      this.minRangeError = this.maxRangeError;
    }

    if (
      !this.minRangeError &&
      !this.maxRangeError &&
      this.dirtyCriterion.content instanceof ContinuousDiscreteCriterionContent
    ) {
      const updatedRangeConfig = [this.rangeConfigMin, maxRange];
      this.dirtyCriterion.content.config = updatedRangeConfig;
      this.$emit('update:dirtyCriterion', this.dirtyCriterion);
    }

    this.$emit('disableSubmitButton', this.maxRangeError);
  }
}
