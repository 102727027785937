

























































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { cloneDeep as _cloneDeep, map as _map, get as _get } from 'lodash';
import { Action, State } from 'vuex-class';
import ProjectReportFilters from '@/views/Project/components/ProjectReportFilters.vue';
import RenameAnalysisModal from '@/components/reports/RenameAnalysisModal.vue';
import DeleteAnalysisModal from '@/components/reports/DeleteAnalysisModal.vue';
import SingleSiteReport from '@/views/Project/components/SingleSiteReport.vue';
import SingleSiteComparisonReport from '@/views/Project/components/SingleSiteComparisonReport.vue';
import MultiSiteReport from '@/views/Project/components/MultiSiteReport.vue';
import MultiSiteComparisonReport from '@/views/Project/components/MultiSiteComparisonReport.vue';
import {
  ChartType,
  ProjectCriteria,
  ProjectSite,
  DeleteAnalysisPayload,
  UpdateAnalysisTitlePayload,
  ProjectReportAnalysis,
  ProjectReportPeriod,
  ProjectReportFiltersConfig,
  ProjectReportSinglePeriodResponse,
  ProjectReportMultiplePeriodResponse,
  ProjectReportAnalysisPayload,
} from '@/store/modules/projects/types/projects.types';
import { RootState } from '../../../store/store';
import { ApiState } from '../../../store/types/general.types';
import { isTruthy } from '../../../jbi-shared/util/watcher.vue-decorator';

@Component({
  components: {
    ProjectReportFilters,
    RenameAnalysisModal,
    DeleteAnalysisModal,
    SingleSiteReport,
    SingleSiteComparisonReport,
    MultiSiteReport,
    MultiSiteComparisonReport,
  },
})
export default class ProjectReportIndividualAnalysis extends Vue {
  @Prop()
  public projectId!: number;
  @Prop()
  public projectSites!: ProjectSite[];
  @Prop()
  public projectCriteria!: ProjectCriteria[];
  @Prop()
  public analysisDetail!: ProjectReportAnalysis;
  @Prop()
  public auditsConfigurationList!: ProjectReportPeriod[];

  public analysisFilter: ProjectReportFiltersConfig = {
    filteredSites: [],
    filteredCriterias: [],
    selectedChartType: '',
    checkIndividualSite: false,
    checkIndividualCriteria: false,
    selectedPeriod: {
      title: '',
      startedAt: '',
      endedAt: null,
      isAudit: false,
      auditIds: [],
    },
    checkComparison: false,
    comparisonPeriods: [],
  };
  public showProjectReportFilters: boolean = false;
  public dirtyAnalysisDetails: any = {};
  public isLoading: boolean = false;
  public validateSiteCriteriaFilter: boolean = false;
  public showSingleSiteReport: boolean = false;
  public showMultiSiteReport: boolean = false;
  public showSingleSiteComparisonReport: boolean = false;
  public showMultiSiteComparisonReport: boolean = false;
  public projectReportResponse: ProjectReportSinglePeriodResponse = {
    chartData: [],
    statisticData: {
      configurationData: null,
      samplingConfiguration: null,
    },
    success: false,
    analysisId: 0,
  };
  public projectComparisonReportResponse: ProjectReportMultiplePeriodResponse = {
    comparisonResult: [],
    success: false,
    analysisId: 0,
  };

  @Action('projects/getProjectReportAnalysis')
  public getProjectReportAnalysis!: (
    payload: ProjectReportAnalysisPayload,
  ) => void;

  @State((state: RootState) => state.projects.projectReportAnalysis)
  public projectReportAnalysis!: any;

  @State((state: RootState) => state.projects.apiState.getProjectReportAnalysis)
  public getProjectReportAnalysisState!: ApiState;

  @Action('projects/updateProjectReportAnalysisTitleById')
  public updateProjectReportAnalysis!: (
    payload: UpdateAnalysisTitlePayload,
  ) => void;

  @State((state: RootState) => state.projects.updatedProjectReportAnalysis)
  public updatedProjectReportAnalysis!: any;

  @State(
    (state: RootState) => state.projects.apiState.updateProjectReportAnalysis,
  )
  public updateProjectReportAnalysisState!: ApiState;

  @Action('projects/deleteProjectReportAnalysisById')
  public deleteProjectReportAnalysis!: (payload: DeleteAnalysisPayload) => void;

  @State(
    (state: RootState) => state.projects.apiState.deleteProjectReportAnalysis,
  )
  public deleteProjectReportAnalysisState!: ApiState;

  public mounted() {
    this.dirtyAnalysisDetails = _cloneDeep(this.analysisDetail);
    this.analysisFilter = _cloneDeep(
      this.dirtyAnalysisDetails.filtersConfiguration,
    );
    this.showProjectReportFilters = true;
    const payload: ProjectReportAnalysisPayload = {
      ...this.analysisFilter,
      projectId: this.getProjectId,
      analysisId: this.getAnalysisId,
      analysisTitle: this.getAnalysisTitle,
      checkIndividualSite:
        this.analysisFilter.filteredSites.length > 1
          ? this.analysisFilter.checkIndividualSite
          : false,
      type: this.projectSitelist.length > 1 ? 'multiSite' : 'singleSite',
    };
    if (this.$route.hash === '#report') {
      this.getProjectReportAnalysisData(payload);
    }
  }

  public applyAnalysisFilters(newValue: ProjectReportFiltersConfig) {
    this.analysisFilter = _cloneDeep(newValue);
    this.$emit('updateAnalysisFilter', this.analysisFilter);
    if (
      this.analysisFilter.filteredSites.length === 0 ||
      this.analysisFilter.filteredCriterias.length === 0
    ) {
      this.showSingleSiteReport = false;
      this.showSingleSiteComparisonReport = false;
      this.showMultiSiteReport = false;
      this.showMultiSiteComparisonReport = false;
      this.validateSiteCriteriaFilter = true;
    } else {
      const payload: ProjectReportAnalysisPayload = {
        ...this.analysisFilter,
        projectId: this.getProjectId,
        analysisId: this.getAnalysisId,
        analysisTitle: this.getAnalysisTitle,
        checkIndividualSite:
          this.analysisFilter.filteredSites.length > 1
            ? this.analysisFilter.checkIndividualSite
            : false,
        type: this.projectSitelist.length > 1 ? 'multiSite' : 'singleSite',
      };
      if (this.projectSitelist.length === 1) {
        this.showSingleSiteReport = false;
        this.showSingleSiteComparisonReport = false;
      } else {
        this.showMultiSiteReport = false;
        this.showMultiSiteComparisonReport = false;
      }
      this.isLoading = true;
      this.validateSiteCriteriaFilter = false;
      this.getProjectReportAnalysisData(payload);
    }
  }

  public getProjectReportAnalysisData(payload: ProjectReportAnalysisPayload) {
    this.getProjectReportAnalysis(payload);
  }

  @Watch('getProjectReportAnalysisState.success')
  @isTruthy
  public watchGetProjectReportAnalysisState(): void {
    if (this.analysisFilter.checkComparison) {
      this.projectComparisonReportResponse = JSON.parse(
        JSON.stringify(this.projectReportAnalysis),
      );
    } else {
      this.projectReportResponse = JSON.parse(
        JSON.stringify(this.projectReportAnalysis),
      );
    }
    if (
      this.dirtyAnalysisDetails.analysisId !==
      JSON.parse(JSON.stringify(this.projectReportAnalysis)).analysisId
    ) {
      this.dirtyAnalysisDetails.analysisId = JSON.parse(
        JSON.stringify(this.projectReportAnalysis),
      ).analysisId;
      this.$emit('updateAnalysisId', this.dirtyAnalysisDetails.analysisId);
    }
  }

  @Watch('updateProjectReportAnalysisState.success')
  @isTruthy
  public watchUpdateProjectReportAnalysisState(): void {
    this.$emit('updateAnalysisTitle', this.updatedProjectReportAnalysis.title);
  }

  @Watch('deleteProjectReportAnalysisState.success')
  @isTruthy
  public watchDeleteProjectReportAnalysisState(): void {
    this.$emit('deleteAnalysis');
  }

  @Watch('projectReportResponse')
  public watchProjectReportResponse(
    newValue: ProjectReportSinglePeriodResponse,
  ) {
    if (this.projectSitelist.length === 1) {
      if (newValue.success) {
        this.showSingleSiteReport = true;
        this.isLoading = false;
      }
    } else {
      if (newValue.success) {
        this.showMultiSiteReport = true;
        this.isLoading = false;
      }
    }
  }

  @Watch('projectComparisonReportResponse')
  public watchProjectComparisonResponse(
    newValue: ProjectReportMultiplePeriodResponse,
  ) {
    if (this.projectSitelist.length === 1) {
      if (newValue.success) {
        this.showSingleSiteComparisonReport = true;
        this.isLoading = false;
      }
    } else {
      if (newValue.success) {
        this.showMultiSiteComparisonReport = true;
        this.isLoading = false;
      }
    }
  }

  public renameAnalysis() {
    this.$buefy.modal.open({
      parent: this,
      component: RenameAnalysisModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        currentAnalysisTitle: this.dirtyAnalysisDetails.title,
      },
      events: {
        updateAnalysisTitle: (newAnalysisTitle: string) => {
          this.updateAnalysisTitleById(newAnalysisTitle);
        },
      },
    });
  }

  public updateAnalysisTitleById(newAnalysisTitle: string) {
    const payload: UpdateAnalysisTitlePayload = {
      projectId: this.getProjectId,
      analysisId: this.dirtyAnalysisDetails.analysisId,
      analysisTitle: newAnalysisTitle,
    };
    this.updateProjectReportAnalysis(payload);
  }

  public deleteAnalysis() {
    this.$buefy.modal.open({
      parent: this,
      component: DeleteAnalysisModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        analysisTitle: this.dirtyAnalysisDetails.title,
      },
      events: {
        deleteAnalysis: () => {
          this.deleteAnalysisById();
        },
      },
    });
  }

  public deleteAnalysisById() {
    const payload: DeleteAnalysisPayload = {
      projectId: this.getProjectId,
      analysisId: this.dirtyAnalysisDetails.analysisId,
    };
    this.deleteProjectReportAnalysis(payload);
  }

  get projectSiteList(): ProjectSite[] {
    return this.projectSites;
  }

  get projectCriteriaList(): ProjectCriteria[] {
    return this.projectCriteria;
  }

  get getProjectId(): number {
    return this.projectId;
  }

  get getAnalysisId(): number {
    return this.dirtyAnalysisDetails.analysisId;
  }

  get getAnalysisTitle(): string {
    return this.dirtyAnalysisDetails.title;
  }

  get projectSitelist(): ProjectSite[] {
    return this.projectSites;
  }

  get getAllSiteIds(): number[] {
    const siteIdList: number[] = [];
    _map(this.projectSites, (projectSite: ProjectSite) => {
      siteIdList.push(projectSite.id);
    });
    return siteIdList;
  }

  get getAuditConfigurationList(): ProjectReportPeriod[] {
    return this.auditsConfigurationList;
  }
}
