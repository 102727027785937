import localforage from 'localforage';
import VueRouter from 'vue-router';

export enum LOCALSTORAGE_KEYS {
  after_login_destination = 'after_login_destination',
}

export const setLocalStorageItem = (key: LOCALSTORAGE_KEYS, value: any) =>
  localforage.setItem(key, value);

export const getLocalStorageItem = (key: LOCALSTORAGE_KEYS) =>
  localforage.getItem(key);

export const removeLocalStorageItem = (key: LOCALSTORAGE_KEYS) =>
  localforage.removeItem(key);

export const setRedirectDestination = async (location: string) => {
  await setLocalStorageItem(
    LOCALSTORAGE_KEYS.after_login_destination,
    location,
  );
};

export const handleRedirectDestination = async (router: VueRouter) => {
  const location = (await getLocalStorageItem(
    LOCALSTORAGE_KEYS.after_login_destination,
  )) as string;
  if (!location) {
    return;
  }

  await removeLocalStorageItem(LOCALSTORAGE_KEYS.after_login_destination);
  router.push(location);
};
