import { RawLocation, Route } from 'vue-router';
import store, { RootState } from './../store/store';
import {
  SubscriptionInfo,
  SubscriptionStatus,
} from '@/store/modules/auth/types/auth.types';

export const permissionGuard = (
  to: Route,
  from: Route,
  next: (to?: RawLocation | false | ((vm: any) => any) | void) => void,
) => {
  const subscriptionInfo: SubscriptionInfo | null = (store.state as RootState)
    .auth.subscriptionInfo;
  if (
    subscriptionInfo &&
    subscriptionInfo.subscriptionStatus === SubscriptionStatus.VALID
  ) {
    return next();
  } else if (subscriptionInfo && subscriptionInfo.isParticipant) {
    return next('/projects/me');
  } else {
    return next('/');
  }
};
