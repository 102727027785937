








































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import HomeHeader from '../../views/Home/components/HomeHeader.vue';
import HomeLayout from '../../layouts/HomeLayout.vue';
import { useAction, useAuthState } from '../../utils/store.util';
import { useUserEmail } from '@/utils/user.util';
import { axios } from '../../api/axios';
import { AxiosError, AxiosResponse } from 'axios';
import { ToastProgrammatic as Toast } from 'buefy';
import BaseDialog from '../../components/base/BaseDialog.vue';
import { get as _get } from 'lodash';
import { JAAS_LOGIN_URL, redirectToJaasLogin } from '../../utils/login.util';
import ProjectOwnerRejectForm from '../Project/components/ProjectOwnerRejectForm.vue';

@Component({ components: { HomeHeader, HomeLayout } })
export default class ProjectOwnerRequestInvitation extends Vue {
  public isLoading: boolean = false;
  public showAcceptPage: boolean = false;
  public showDeclinedPage: boolean = false;
  public showDeclinedForm: boolean = false;
  public isRequestAccepted: boolean = false;
  public isReclaimProject: boolean = false;
  public isSelectProjectAdmin: boolean = false;
  public projectId: number = 0;
  public projectOwnerRequestId: number = 0;
  public loginUrl: string = '';
  public projectName: any = '';
  public senderName: string | any = '';
  public reason: string = '';
  public token: any = '';
  public email: any = '';
  public originatorProjectEmail: any = '';

  get isLoggedIn(): boolean {
    return useAuthState.call(this).isLoggedIn;
  }

  get switchAccount() {
    return useAction.call(this, 'auth/switchAccount');
  }

  get signOut() {
    return useAction.call(this, 'auth/signOut');
  }

  get userEmail(): string | undefined {
    return useUserEmail.call(this);
  }

  get JAAS_LOGIN_URL(): string {
    return JAAS_LOGIN_URL;
  }

  get redirectToJaasLogin() {
    return redirectToJaasLogin;
  }

  public redirectToProject(projectId: number, loginUrl: string) {
    this.showDeclinedPage = false;
    this.showDeclinedForm = false;
    if (this.isLoggedIn) {
      if (this.email === this.userEmail) {
        this.$router.push({
          path: `/projects/${projectId}#participants`,
        });
      } else {
        this.logoutMismatchUser();
      }
    } else {
      redirectToJaasLogin({
        destination: `/projects/${projectId}#participants`,
        loginUrl,
      });
    }
  }

  public submitModalDecline(info: any) {
    this.$router.replace('/');
    this.$buefy.modal.open({
      parent: this,
      props: {
        notificationSenderName: info.senderName,
        projectName: info.projectName,
      },
      events: {
        confirm: async (newValue: any) => {
          this.respondProjectOwnerRequest(newValue);
        },
      },
      component: ProjectOwnerRejectForm,
      hasModalCard: true,
    });
  }

  public handleSubmitReason(declineReason: any) {
    this.isLoading = true;
    const payload = {
      token: this.$route.query.token || this.token,
      email: this.$route.query.email || this.email,
      originatorProjectEmail:
        this.$route.query.originatorProjectEmail || this.originatorProjectEmail,
      projectId: this.$route.query.projectId || this.projectId,
      isLoggedIn: this.isLoggedIn,
      isRequestAccepted: this.isRequestAccepted,
      isReclaimProject: this.isReclaimProject,
    };
    return axios
      .post(`project-owner-request/updateDeclineProjectOwnerRequest`, {
        ...payload,
      })
      .then((response: AxiosResponse) => {
        Toast.open({
          queue: true,
          type: 'is-dark',
          position: 'is-top',
          message: `Project administration request successfully declined with reason.`,
        });
        this.respondProjectOwnerRequest(declineReason);
        this.showDeclinedPage = true;
        this.showDeclinedForm = false;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  public respondProjectOwnerRequest(declineReason: any) {
    this.projectName = this.$route.query.projectName;
    const rejectReason = declineReason ? declineReason : null;
    const payload = {
      token: this.$route.query.token || this.token,
      email: this.$route.query.email || this.email,
      originatorProjectEmail:
        this.$route.query.originatorProjectEmail || this.originatorProjectEmail,
      projectId: this.$route.query.projectId || this.projectId,
      isLoggedIn: this.isLoggedIn,
      isRequestAccepted: this.isRequestAccepted,
      reason: rejectReason,
      isReclaimProject: this.isReclaimProject,
    };
    return axios
      .post(`project-owner-request/updateProjectOwnerRequest`, {
        ...payload,
      })
      .then((response: AxiosResponse) => {
        if (this.isRequestAccepted) {
          if (this.isLoggedIn) {
            let toastMessage: string = '';
            if (this.isReclaimProject) {
              toastMessage = `You have accepted the project administration change request`;
            } else {
              toastMessage = `You are now the project administrator in ‘${this.$route.query.projectName}'.`;
            }
            Toast.open({
              queue: true,
              type: 'is-dark',
              position: 'is-top',
              message: toastMessage,
            });
            this.$router.push({
              path: `/projects/${payload.projectId}#dashboard`,
            });
          } else {
            this.showAcceptPage = true;
          }
        } else {
          if (this.isLoggedIn) {
            Toast.open({
              queue: true,
              type: 'is-dark',
              position: 'is-top',
              message: `Project administration request successfully declined.`,
            });
          }
        }
      })
      .catch((error: AxiosError) => {
        this.isLoading = false;
        if (error.response!.status === 409) {
          this.$buefy.modal.open({
            parent: this,
            component: BaseDialog,
            hasModalCard: true,
            trapFocus: true,
            props: {
              title: 'Invitation link is not valid',
              content:
                'Your request link is not valid for the MyJBI account you are signed in to. Please sign in with the email address the invitation was sent to instead.',
              confirmButtonText: 'Logout',
            },
            events: {
              confirm: async () => {
                await this.switchAccount(true);
                await this.signOut();
              },
              cancel: () => {
                this.$router.replace('/');
              },
            },
          });
        } else {
          Toast.open({
            queue: true,
            type: 'is-danger',
            position: 'is-top',
            message: `${_get(error.response, 'data.message')}`,
          });
          this.$router.replace('/');
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  public logoutMismatchUser() {
    this.isLoading = false;
    this.$buefy.modal.open({
      parent: this,
      component: BaseDialog,
      hasModalCard: true,
      trapFocus: true,
      props: {
        title: 'Invitation link is not valid',
        content:
          'Your request link is not valid for the MyJBI account you are signed in to. Please sign in with the email address the invitation was sent to instead.',
        confirmButtonText: 'Logout',
      },
      events: {
        confirm: async () => {
          await this.switchAccount(true);
          await this.signOut();
        },
        cancel: () => {
          this.$router.replace('/');
        },
      },
    });
  }

  public respondDeclineProjectOwnerRequest() {
    const payload = {
      token: this.$route.query.token || this.token,
      email: this.$route.query.email || this.email,
      originatorProjectEmail:
        this.$route.query.originatorProjectEmail || this.originatorProjectEmail,
      projectId: this.$route.query.projectId || this.projectId,
      isLoggedIn: this.isLoggedIn,
      isRequestAccepted: this.isRequestAccepted,
      isReclaimProject: this.isReclaimProject,
    };
    return axios
      .post(`project-owner-request/updateDeclineProjectOwnerRequest`, {
        ...payload,
      })
      .then((response: AxiosResponse) => {
        if (this.isSelectProjectAdmin) {
          this.redirectToProject(this.projectId, this.loginUrl);
        } else {
          const declineInfo = {
            senderName: this.senderName,
            projectName: this.projectName,
          };
          this.submitModalDecline(declineInfo);
        }
      })
      .catch((error: AxiosError) => {
        this.isLoading = false;
        if (error.response!.status === 409) {
          this.$buefy.modal.open({
            parent: this,
            component: BaseDialog,
            hasModalCard: true,
            trapFocus: true,
            props: {
              title: 'Invitation link is not valid',
              content:
                'Your request link is not valid for the MyJBI account you are signed in to. Please sign in with the email address the invitation was sent to instead.',
              confirmButtonText: 'Logout',
            },
            events: {
              confirm: async () => {
                await this.switchAccount(true);
                await this.signOut();
              },
              cancel: () => {
                this.$router.replace('/');
              },
            },
          });
        } else {
          Toast.open({
            queue: true,
            type: 'is-danger',
            position: 'is-top',
            message: `${_get(error.response, 'data.message')}`,
          });
          this.$router.replace('/');
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  public mounted() {
    this.isLoading = true;
    this.token = this.$route.query.token.toString();
    this.email = this.$route.query.email.toString();
    this.originatorProjectEmail = this.$route.query.originatorProjectEmail.toString();
    this.senderName = this.$route.query.senderName;
    this.projectId = Number(this.$route.query.projectId);
    this.projectOwnerRequestId = Number(
      this.$route.query.projectOwnerRequestId,
    );
    this.loginUrl = this.$route.query.loginUrl.toString();
    this.projectName = this.$route.query.projectName;
    this.isRequestAccepted = JSON.parse(
      this.$route.query.isAcceptRequest.toString(),
    );
    this.isReclaimProject = JSON.parse(
      this.$route.query.isReclaimProject.toString(),
    );
    this.isSelectProjectAdmin = JSON.parse(
      this.$route.query.isSelectProjectAdmin.toString(),
    );

    if (this.isSelectProjectAdmin) {
      this.redirectToProject(this.projectId, this.loginUrl);
    } else {
      if (this.isLoggedIn && !this.isRequestAccepted) {
        this.respondDeclineProjectOwnerRequest();
      } else {
        if (this.isRequestAccepted) {
          this.respondProjectOwnerRequest(this.reason);
        } else {
          this.showDeclinedPage = true;
          this.showDeclinedForm = true;
          this.isLoading = false;
        }
      }
    }
  }
}
