














































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { ToastProgrammatic as Toast } from 'buefy';
import {
  CreateGripBarrierBase,
  CreateGripBarrierPayload,
  GetBarriersPayload,
  GripBarrier,
} from '../../../store/types/grip.types';
import BarrierModal from './BarrierModal.vue';
import {
  ApiState,
  Pagination,
  PaginatorDto,
} from '../../../store/types/general.types';
import { Debounce } from '../../../jbi-shared/util/debounce.vue-decorator';
import BaseLoading from '../../../components/base/BaseLoading.vue';
import BarrierList from './BarrierList.vue';
import BasePaginatorHoc from '../../../components/base/BasePaginatorHoc.vue';
import {
  isDifferentDeep,
  isTruthy,
} from '../../../jbi-shared/util/watcher.vue-decorator';

@Component({
  components: { BaseLoading, BasePaginatorHoc, BarrierList, BarrierModal },
})
export default class BarrierSettings extends Vue {
  public isLoading: boolean = true;
  public sortColumn: string = 'title';
  public sortOrder: 'ASC' | 'DESC' = 'ASC';
  public perPage: number = 50;
  public page: number = 1;
  public totalCount: number = 0;
  public searchTitle: string = '';
  public filteredParams: GetBarriersPayload = {
    title: this.searchTitle,
    limit: this.perPage,
    page: this.page,
  };
  public modalKey: number = Math.floor(Math.random() * 999);
  public isModalActive = false;

  @Action('projects/getGripBarriers')
  public getGripBarriers!: (payload: {
    projectId: number;
    params: GetBarriersPayload;
  }) => void;

  @State((state) => state.projects.gripBarriers)
  public gripBarriers!: Pagination<GripBarrier>;

  @State((state) => state.projects.apiState.getGripBarriers)
  public getGripBarriersApiState!: ApiState;

  @Action('projects/createGripBarrier')
  public createGripBarrier!: (payload: CreateGripBarrierPayload) => void;

  @State((state) => state.projects.apiState.createGripBarrier)
  public createGripBarrierApiState!: ApiState;

  get projectId(): number {
    return +this.$route.params.projectId;
  }

  get BarrierList() {
    return BarrierList;
  }

  get getGripBarrierList(): GripBarrier[] {
    this.totalCount = this.gripBarriers ? this.gripBarriers.totalItems : 0;
    return this.gripBarriers && this.gripBarriers.items.length
      ? this.gripBarriers.items
      : [];
  }

  public closeBarrierModal(): void {
    this.isModalActive = false;
  }

  public openBarrierModal(): void {
    this.isModalActive = true;
    this.modalKey += 1;
  }

  public handleBarrierCreate(gripBarrier: CreateGripBarrierBase) {
    this.createGripBarrier({ ...gripBarrier, projectId: this.projectId });
    this.closeBarrierModal();
  }

  public mounted(): void {
    this.getFilteredGripBarriers();
  }

  public handlePaginator({ perPage, page }: PaginatorDto) {
    this.page = page;
    this.perPage = perPage;
    this.filteredParams = {
      title: this.searchTitle,
      limit: this.perPage,
      page: this.page,
    };
  }

  public getFilteredGripBarriers(): void {
    this.getGripBarriers({
      projectId: this.projectId,
      params: this.filteredParams,
    });
  }

  @Watch('filteredParams', { deep: true })
  @isDifferentDeep
  public watchFilteredParams() {
    this.getFilteredGripBarriers();
  }

  @Watch('searchTitle')
  @Debounce(500)
  public onTitleSearch(): void {
    this.filteredParams = {
      title: this.searchTitle,
      limit: this.perPage,
      page: this.page,
    };
  }

  @Watch('getGripBarriersApiState.loading')
  public watchGetGripBarriersLoading(): void {
    this.isLoading = true;
  }

  @Watch('getGripBarriersApiState.success')
  public watchGetGripBarriersSuccess(): void {
    this.isLoading = false;
  }

  @Watch('createGripBarrierApiState.success')
  @isTruthy
  public onCreateBarrierSuccess(): void {
    this.getFilteredGripBarriers();
    Toast.open({
      message: `Barrier added`,
      position: 'is-top',
      type: 'is-dark',
      duration: 3500,
    });
  }

  @Watch('createGripBarrierApiState.error')
  public onCreateBarrierError(): void {
    this.$emit('close');
  }
}
