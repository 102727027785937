import { defaultApiState } from '@/store/types/general.types';
import {
  AcceptUserInvite,
  UsersState,
  VerifyUserInviteResponse,
} from './types/users.types';

export const usersState: UsersState = {
  apiState: {
    inviteNewUser: defaultApiState,
    getUsers: defaultApiState,
    getUserInvitations: defaultApiState,
    removeUserInvitation: defaultApiState,
    resendUserInvitation: defaultApiState,
    searchUsersByText: defaultApiState,
    getMyRoles: defaultApiState,
    acceptUserInvite: defaultApiState,
    verifyUserInvite: defaultApiState,
    updateStatus: defaultApiState,
    getSurveyData: defaultApiState,
    saveSurveyAcknowledgement: defaultApiState,
    oicPayload: defaultApiState,
  },
  users: undefined,
  userInvitations: undefined,
  searchUsersByTextResults: undefined,
  myRoles: undefined,
  acceptUserInvite: undefined,
  verifyUserInvite: undefined,
  surveyData: undefined,
  surveyAcknowledgement: undefined,
  oicPayload: undefined,
};
