

































import { Component, Vue, Watch } from 'vue-property-decorator';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import HeroBar from '@/components/base/HeroBar.vue';
import AuditListDataCollector from '@/views/Project/components/dataCollector/AuditListDataCollector.vue';
import { Action, State } from 'vuex-class';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { sortBy as _sortBy, filter as _filter } from 'lodash';
import moment from 'moment';
import {
  AuditDetail,
  AuditStatus,
} from '@/store/modules/audits/types/audits.types';
import { useUserEmail } from '@/utils/user.util';
import Card from '@/components/Card.vue';
import {
  ProjectDetailPayload,
  UserRoleInAudit,
  UserRoleInProject,
} from '../../../store/modules/projects/types/projects.types';
import { PacesParticipantRoles } from '@/jbi-shared/types/paces-project.types';

@Component({
  components: {
    DefaultLayout,
    HeroBar,
    AuditListDataCollector,
    Card,
  },
})
export default class ProjectDetailPageDataCollector extends Vue {
  public isDataCollectionArchived: boolean = false;

  @Action('projects/getProjectDetail')
  public getProjectDetail!: (projectId: number) => void;

  @State((state) => state.projects.projectDetail)
  public projectDetail!: ProjectDetailPayload;

  public mounted() {
    this.getProjectDetail(this.projectId);
  }

  @Watch('projectDetail')
  @isTruthy
  public watchProjectDetails(value: any) {
    if (value.status === 'Deleted' || !this.checkUserAccess(value)) {
      this.$router.push({
        name: 'project-me',
      });
    } else if (value.status === AuditStatus.ARCHIVED) {
      this.isDataCollectionArchived = true;
    } else {
      this.isDataCollectionArchived = false;
    }
  }

  public checkUserAccess(project: ProjectDetailPayload) {
    if (!this.userEmail) {
      return false;
    }
    return project.userRoleInProject === UserRoleInProject.isDataCollector
      ? true
      : false;
  }

  get message() {
    if (this.isDataCollectionArchived) {
      return 'This project has been archived';
    } else {
      return 'Click on the Data Collection Period title to submit a new record';
    }
  }

  get userEmail(): string | undefined {
    return useUserEmail.call(this);
  }

  get projectId(): number {
    return +this.$route.params.projectId;
  }

  get activeAuditList() {
    if (!this.projectDetail.audits) {
      return [];
    }

    const filteredAudit = this.projectDetail.audits.filter(
      (auditDetail: AuditDetail) => {
        const isUserDataCollector =
          auditDetail.userRoleInAudit === UserRoleInAudit.isDataCollector;

        return auditDetail.status === AuditStatus.ACTIVE && isUserDataCollector;
      },
    );

    return _sortBy(filteredAudit, (auditDetail: AuditDetail) => {
      if (moment(auditDetail.startDate).isAfter(new Date())) {
        auditDetail.isFutureAudit = true;
      } else {
        auditDetail.isFutureAudit = false;
      }
      return auditDetail.startDate ? new Date(auditDetail.startDate) : null;
    });
  }

  get archivedAuditList() {
    if (!this.projectDetail.audits) {
      return [];
    }

    const filteredAudit = this.projectDetail.audits.filter(
      (auditDetail: AuditDetail) => {
        const isDataCollector: boolean =
          auditDetail.userRoleInAudit === UserRoleInAudit.isDataCollector;

        return isDataCollector && auditDetail.status === AuditStatus.ARCHIVED;
      },
    );

    return _sortBy(filteredAudit, (auditDetail) => {
      if (moment(auditDetail.startDate).isAfter(new Date())) {
        auditDetail.isFutureAudit = true;
      } else {
        auditDetail.isFutureAudit = false;
      }
      return auditDetail.startDate ? new Date(auditDetail.startDate) : null;
    });
  }
}
