




















import {Component, Prop, Vue} from 'vue-property-decorator';
import dayjs from 'dayjs';
import {DATE_FORMAT} from '@/utils/date.util';

@Component({ components: {
    },
})
export default class CriteriaCard extends Vue {
    @Prop()
    public auditDetails: any;
    @Prop()
    public criteriaDetail: any;
    @Prop()
    public criteriaIndex!: number;

    public formatDate(s: string) {
        return dayjs(s).format(DATE_FORMAT);
    }

    get auditId() {
        return this.auditDetails.id;
    }

    public redirectToConfigureAudit() {
        this.$router.push({
            name: 'audit-sampling',
            params: {
                auditId: this.auditId.toString(),
            },
        });
    }
}
