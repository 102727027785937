































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import {cloneDeep as _cloneDeep} from 'lodash';
import {ProjectReportAnalysis} from '@/store/modules/projects/types/projects.types';
import {AuditReportAnalysis} from '@/store/modules/audits/types/audits.types';

@Component({})
export default class CompressedAnalysisNavigationTabs extends Vue {
    @Prop()
    public compressedAnalysis!: ProjectReportAnalysis[] | AuditReportAnalysis[];

    public dirtyCompressedAnalysis: ProjectReportAnalysis[] | AuditReportAnalysis[] = [];

    public mounted() {
        this.dirtyCompressedAnalysis = _cloneDeep(this.compressedAnalysis);
    }

    public updateVisibleAnalysisHeader(compressedAnalysisIndex: number) {
        this.$emit('updateVisibleAnalysisHeader', compressedAnalysisIndex);
    }

    public getCompressedAnalysisTitle(compressedAnalysisTitle: string): string {
        if (compressedAnalysisTitle.length > 13) {
            return `${compressedAnalysisTitle.substring(0, 11)} ...`.toUpperCase();
        }
        return compressedAnalysisTitle.toUpperCase();
    }

    get getCompressedAnalysisDropdownText(): string {
        return `${this.dirtyCompressedAnalysis.length} MORE...`;
    }
}
