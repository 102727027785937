


























import { Component, Vue, Prop } from 'vue-property-decorator';
import { GripAnalysis } from '../../../store/types/grip.types';

@Component({})
export default class GripAnalysisNavigationTabs extends Vue {
  @Prop() public visibleAnalysis!: GripAnalysis[];
  @Prop() public selectedTabIndex!: number;
  public dirtyVisibleAnalysis: GripAnalysis[] = [];
  public dirtySelectedTabIndex: number = 0;

  public mounted(): void {
    this.dirtyVisibleAnalysis = JSON.parse(
      JSON.stringify(this.visibleAnalysis),
    );
    this.dirtySelectedTabIndex = this.selectedTabIndex;
  }

  public selectAnalysis(analysisItemIndex: number): void {
    this.$emit('selectAnalysis', analysisItemIndex);
  }

  public getUpperCase(value: string): string {
    return value.toUpperCase();
  }

  public getAnalysisTitle(analysisTitle: string): string {
    if (analysisTitle.length > 14) {
      return `${analysisTitle.substring(0, 12)} ...`.toUpperCase();
    }
    return analysisTitle.toUpperCase();
  }
}
