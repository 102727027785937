























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import { Action, State } from 'vuex-class';
import HeroBar from '@/components/base/HeroBar.vue';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import {
  AuditSamplingDataConfig,
  AuditSamplingResponse,
  AuditSamplingType,
  ConsecutiveSamplingMode,
  DataCollectionSummaryRequestPayload,
  SingleSitePayload,
  SingleSiteSamplingRequestPayload,
} from '@/store/modules/audits/types/audits.types';
import {
  map as _map,
  round as _round,
  cloneDeep as _cloneDeep,
  orderBy as _orderBy,
  findIndex as _findIndex,
} from 'lodash';
import { ToastProgrammatic as Toast } from 'buefy';
import SamplingCalculator from '@/views/Audit/components/SamplingCalculator.vue';
import ConsecutiveCalculator from '@/views/Audit/components/ConsecutiveCalculator.vue';

@Component({
  components: {
    ConsecutiveCalculator,
    SamplingCalculator,
    DefaultLayout,
    HeroBar,
  },
})
export default class ConsecutiveTargetSampleSingleSiteSampling extends Vue {
  @Prop()
  public auditId!: number;
  @Prop()
  public auditConfig!: AuditSamplingResponse;
  @Prop()
  public auditSamplingType!: AuditSamplingType;
  @Prop()
  public consecutiveSamplingMode!: ConsecutiveSamplingMode;
  @Prop({ default: true })
  public editable!: boolean;
  public singleSitePayload: SingleSitePayload[] = [];
  public calculateData = {
    output: 0,
  };

  @Action('audits/updateSingleSiteSampling')
  public updateSingleSiteSampling!: (
    payload: SingleSiteSamplingRequestPayload,
  ) => void;

  @State((state) => state.audits.updatedSingleSiteSampling)
  public updatedSingleSiteSampling!: any;

  @Watch('updatedSingleSiteSampling')
  @isTruthy
  public watchUpdatedSingleSiteSampling() {
    this.showNotificationAndRedirectToProject();
  }

  public mounted() {
    this.populateSingeSiteData();
  }

  public populateSingeSiteData() {
    _map(this.auditConfig.dataConfigs, (dataConfig: any) => {
      const currentCriteriaPayload: SingleSitePayload = {
        auditId: this.auditId,
        criteriaId: 0, // todo: change this logic later
        auditCriteriaId: 0,
        sampleSize: 0,
      };
      _map(dataConfig, (singleConfig: AuditSamplingDataConfig) => {
        if (singleConfig.auditCriteriaMap.isActive) {
          currentCriteriaPayload.criteriaId =
            singleConfig.auditCriteriaMap.criteria.id;
          currentCriteriaPayload.auditId = this.auditId;
          currentCriteriaPayload.auditCriteriaId =
            singleConfig.auditCriteriaMap.id;
          currentCriteriaPayload.criteriaTitle =
            singleConfig.auditCriteriaMap.criteria.title;
          currentCriteriaPayload.order = singleConfig.auditCriteriaMap.order;
          if (singleConfig.auditSiteMap === null) {
            currentCriteriaPayload.sampleSize = _round(singleConfig.sampleSize);
            currentCriteriaPayload.dataConfig = singleConfig;
          }
        }
      });
      this.singleSitePayload.push(currentCriteriaPayload);
    });
  }

  public showNotificationAndRedirectToProject() {
    Toast.open({
      message: `Changes saved`,
      position: 'is-top',
      type: 'is-dark',
      duration: 3500,
    });
  }

  public handleSingleSiteChanges() {
    this.updateSingleSiteSampling({
      auditId: this.auditId,
      singleSitePayload: this.singleSitePayload,
      auditSamplingType: this.auditSamplingType,
      calculateData: this.calculateData,
      auditSamplingMode: this.consecutiveSamplingMode,
      samplingConfigId: this.auditConfig.auditSamplingConfigs.id,
    });
  }

  public handleApplySampleSize(calculateData: any) {
    if (calculateData.auditCriteriaId !== undefined) {
      const updateDataPayloadIndex = _findIndex(this.auditCriteriaList, {
        auditCriteriaId: calculateData.auditCriteriaId,
      });
      this.auditCriteriaList[updateDataPayloadIndex].sampleSize =
        calculateData.output;
    } else {
      this.calculateData = calculateData;
      this.auditCriteriaList.map((payload) => {
        payload.sampleSize = calculateData.output;
      });
    }
  }

  get auditCriteriaList() {
    return _orderBy(this.singleSitePayload, ['order'], ['asc']);
  }
}
