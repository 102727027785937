









































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { State, Action } from 'vuex-class';
import BasePagination from '@/components/base/BasePagination.vue';
import BaseModal from '@/jbi-shared/vue-components/BaseModal.vue';
import { RootState } from '@/store/store';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { FilteredUserPayload } from '@/store/modules/users/types/users.types';
import { mixins } from 'vue-class-component';
import { PaginationMixin } from '@/components/base/pagination.mixin';
import { ToastProgrammatic as Toast } from 'buefy';
import dayjs from 'dayjs';
import ProjectOwnerRequestForm from '../../Project/components/ProjectOwnerRequestForm.vue';
import {
  ProjectOwnerHistoryPayload,
  AdminProjectDTO,
  ChangeProjectOwnerDTO,
} from '@/store/modules/projects/types/projects.types';
import TableDropdown from './TableDropdown.vue';
import ProjectOwnerHistory from './ProjectOwnerHistory.vue';
import { useUserEmail } from '@/utils/user.util';
import { cloneData } from '@/utils/general.util';

@Component({
  components: {
    BaseModal,
    BasePagination,
    TableDropdown,
  },
})
export default class ProjectList extends mixins(PaginationMixin) {
  @Prop() public items!: AdminProjectDTO[];
  @Prop(Number) public currentPage!: number;
  @Prop(Number) public totalNumberOfPage!: number;
  @Prop(Number) public totalCount!: number;
  @Prop(Boolean) public isFirstPage!: boolean;
  @Prop(Boolean) public isLastPage!: boolean;
  @Prop(Number) public startItemIndex!: number;
  @Prop(Number) public endItemIndex!: number;
  @Prop() public filteredParams!: FilteredUserPayload;

  public projectOwnerHistoryLog: any = [];
  public unAvailableParticipantTooltip =
    'More than one participant is required' +
    '\n to change the project administrator.';

  @Action('projects/getProjectOwnerHistory')
  public getProjectOwnerHistory!: (projectId: number) => void;

  @State((state: RootState) => state.projects.projectOwnerHistory)
  public projectOwnerHistory!: ProjectOwnerHistoryPayload;

  get userEmail(): string | undefined {
    return useUserEmail.call(this);
  }

  public updateSort(field: string, sort: string) {
    if (this.filteredParams.orderBy) {
      this.filteredParams.orderBy.field = field;
      this.filteredParams.orderBy.sort = sort;
    }
  }

  public truncateAdministratorName(
    item: AdminProjectDTO,
    isNameLonger: boolean,
  ) {
    const administratorName = item.administratorName.slice(
      0,
      -(item.email.length + 3),
    );
    if (isNameLonger) {
      return `${administratorName.substring(0, 35)} ...`;
    } else {
      return `${item.email.substring(0, 40 - administratorName.length)} ...`;
    }
  }

  public getSubscriptionStatus(data: AdminProjectDTO) {
    let subscriptionStatus: string = '';
    const remainingDays = dayjs(data.expire_at).diff(new Date(), 'day');
    if (remainingDays <= 7) {
      subscriptionStatus =
        remainingDays <= 0 ? `Expired` : `Expiring (${remainingDays} days)`;
    } else {
      subscriptionStatus = 'Active';
    }
    return subscriptionStatus;
  }

  // public projectOwnerEmail(item: any) {
  //   if (item.email === this.userEmail) {
  //     return 'You';
  //   } else {
  //     return item.email;
  //   }
  // }

  public handleChangeProjectAdministrator(item: AdminProjectDTO) {
    this.$buefy.modal.open({
      parent: this,
      props: {
        projectId: item.id,
        projectOwnerEmail: item.email,
        projectOwnerName: item.administratorName,
        isSystemAdmin: true,
      },
      events: {
        confirm: (newValue: ChangeProjectOwnerDTO) => {
          let confirmMessage: string = '';
          if (newValue.isAdminNewProjectOwner) {
            confirmMessage = `You are now the project administrator in ‘${item.title}'`;
          } else {
            confirmMessage = `Project administration request successfully sent to ${newValue.newRequestedProjectOwnerEmail}`;
          }
          Toast.open({
            message: confirmMessage,
            position: 'is-top',
            type: 'is-dark',
            duration: 3500,
          });
          this.$emit('updateProjectList', newValue.isAdminNewProjectOwner);
        },
      },
      component: ProjectOwnerRequestForm,
      hasModalCard: true,
    });
  }

  public handleProjectOwnerHistory(item: AdminProjectDTO) {
    this.getProjectOwnerHistory(item.id);
  }

  @Watch('projectOwnerHistory')
  @isTruthy
  public projectOwnerHistoryState(newValue: any) {
    this.projectOwnerHistoryLog = [];
    if (newValue.isOriginalProjectOwner) {
      this.projectOwnerHistoryLog.push({
        originalProjectOwnerName: newValue.originalProjectOwner,
        eventOccuredAt: newValue.dateProjectOwnerCreated,
        isOriginalProjectOwner: true,
      });
    } else {
      this.projectOwnerHistoryLog = cloneData(newValue);
      this.projectOwnerHistoryLog.push({
        originalProjectOwnerName: newValue[0].originalProjectOwner,
        eventOccuredAt: newValue[0].dateProjectOwnerCreated,
        isOriginalProjectOwner: true,
      });
    }
    this.$buefy.modal.open({
      parent: this,
      props: {
        projectOwnerHistoryLog: this.projectOwnerHistoryLog,
      },
      component: ProjectOwnerHistory,
      hasModalCard: true,
    });
  }
}
