










































































































import { Prop, Component, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import UploadError from '@/components/uploader/UploadError.vue';
import UploadTimeout from '@/components/uploader/UploadTimeout.vue';
import UploadLoading from '@/components/uploader/UploadLoading.vue';
import {
  GetSignedUrlForUploadRequestPayload,
  GetSignedUrlForUploadResponsePayload,
  GetFileAttachmentPayload,
} from '@/store/modules/static-file/types/static-file.types';

@Component({
  components: {
    UploadError,
    UploadLoading,
    UploadTimeout,
  },
})
export default class GenericFileUploader extends Vue {
  public isLoading: boolean = false;
  public isSuccess: boolean = false;
  public isFailed: boolean = false;
  public error = '';
  public file: File[] = [];
  public fileSizeLimit: number = 50000000;

  @Prop({ default: [] })
  public fileAttachment!: GetFileAttachmentPayload[];

  public isFileDeleted: boolean = false;

  @Action('staticFile/getSignedUrlForUploads')
  public getSignedUrlForUploads!: (payload: {
    data: GetSignedUrlForUploadRequestPayload[];
  }) => void;

  @State((state) => state.staticFile.signedUrlData)
  public signedUrlData!: any;

  @Watch('file')
  public async onFileSelected() {
    if (this.file.length === 0) {
      return;
    }

    if (this.isFileDeleted) {
      this.isFileDeleted = false;
    }

    const oversizedFile = this.file.find((file: File) => {
      return file.size > this.fileSizeLimit;
    });

    if (oversizedFile) {
      this.error = 'File size exceeded the 50MB limit';
      this.isFailed = true;
      this.file.splice(-1);
      return;
    }

    return this.getSignedUrlForUploads({
      data: this.file.map((file: File) => {
        return {
          fileName: file.name,
          contentType: file.type,
        };
      }),
    });
  }

  @Watch('signedUrlData', { deep: true })
  public onGetSignedUrlData(
    signedUrls: GetSignedUrlForUploadResponsePayload[],
  ) {
    if (signedUrls) {
      this.uploadFileToSignedUrl(this.file, signedUrls);
      this.file = [];
    }
  }

  public handleRetry() {
    this.isFailed = false;
    this.isSuccess = false;
  }

  public deleteDropFile(index: number) {
    if (this.fileAttachment.length !== 0) {
      this.fileAttachment.splice(index, 1);
      this.isFileDeleted = true;
    }
  }

  public uploadFileToSignedUrl(
    selectedFiles: File[],
    signedUrls: GetSignedUrlForUploadResponsePayload[],
  ) {
    selectedFiles.forEach((file, fileIndex) => {
      const formData = new FormData();
      formData.append('file', file as Blob);
      // upload file to GCS
      return new Promise((resolve, reject) => {
        this.isLoading = true;
        this.$emit('fileUploading', this.isLoading);

        const xhr = new XMLHttpRequest();
        xhr.open('PUT', signedUrls[fileIndex].signedUrl, true);

        xhr.onload = (e) => {
          this.isLoading = false;
          this.$emit('fileUploading', this.isLoading);
          this.isSuccess = true;
          const subFileName = signedUrls[fileIndex].storageUrl.substring(105);
          // update file attachment once file is uploaded
          this.fileAttachment.push({
            type: signedUrls[fileIndex].storageUrl,
            name: subFileName,
          });
          return resolve(signedUrls);
        };

        xhr.onerror = (error) => {
          this.isFailed = true;
          return reject(error);
        };

        xhr.send(file);
      });
    });
  }
}
