import { UserSubscriptionStatus } from '@/jbi-shared/types/jaas-oauth2.types';
import { ApiState } from '@/store/types/general.types';

export type DateOrString = string | Date;

export interface AuthInfo {
  idToken?: string;
  oicPayload?: OicPayload;
}

export interface ExchangeGrantForTokenRequestPayload {
  grant_type: string;
  code: string;
  client_id: string;
  client_secret: string;
  redirect_uri: string;
}

export interface ExchangeGrantForTokenResponsePayload {
  id_token?: string;
  roles: string[];
  subscriptionInfo: SubscriptionInfo;
}

export interface SubscriptionInfo {
  productId: number | null;
  subscriptionStatus: SubscriptionStatus;
  expireAt: DateOrString | null;
  calledAt: DateOrString | null;
  isParticipant: boolean;
}

export enum SubscriptionStatus {
  VALID = 'valid',
  INVALID = 'invalid',
}

export type RefreshTokenResponsePayload = ExchangeGrantForTokenResponsePayload;

export interface JaasAccountDetail {
  id: number;
  username: string;
  passwordHash: string;
  email: string;
  emailVerified: boolean;
  blocked: boolean;
  createdBy: null;
  updatedBy: null;
  createdAt: string;
  updatedAt: string;
}

export interface AuthApiState {
  exchangeGrantForToken: ApiState;
  signOut: ApiState;
  checkSessionHealth: ApiState;
  checkSubscriptionHealth: ApiState;
  getJaasAccountDetail: ApiState;
  checkSubscriptionStatusByEmailIds: ApiState;
}

export interface Permission {
  id: number;
  name: string;
}

export interface AuthState {
  apiState: AuthApiState;
  isSwitchAccount: boolean;
  authInfo: AuthInfo | null;
  subscriptionInfo: SubscriptionInfo | null;
  isLoggedIn: boolean;
  jaasAccountDetail: JaasAccountDetail | null;
  roles: string[];
  userSubscriptionStatusByEmail: UserSubscriptionStatusByEmailPayload | null;
}

export interface OicPayload {
  // reference: https://tools.ietf.org/html/rfc7519#section-4.1
  // openid
  iss?: string;
  sub?: string;
  aud?: string;
  exp?: number;
  iat?: number;
  // email
  email?: string;
  email_verified?: boolean;
  // profile
  name?: string;
  family_name?: string;
  given_name?: string;
  middle_name?: string;
  nickname?: string;
  preferred_username?: string;
  picture?: string;
  website?: string;
  gender?: string;
  birthdate?: string;
  zoneinfo?: string;
  locale?: string;
  updated_at?: number;
}

export interface UserSubscriptionStatusByEmailPayload {
  user: string;
  isValidUser: boolean;
  subscriptionStatus: UserSubscriptionStatus;
}

export interface CheckSubscriptionStatusByEmailIdsPayload {
  email_list: string[];
}
