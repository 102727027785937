import {
  GenerateHyperLinks,
  HyperLinks,
} from '@/store/modules/projects/types/projects.types';
import {
  Grip,
  GripAnalysisItem,
  GripAnalysis,
  GripAnalysisBarrier,
  GripAnalysisCriteria,
  GripAnalysisEnabler,
  GripAnalysisStrategy,
  GripExportReportResponse,
} from '@/store/types/grip.types';
import dayjs from 'dayjs';
import {
  AlignmentType,
  Document,
  Footer,
  Header,
  HeightRule,
  HyperlinkRef,
  HyperlinkType,
  Media,
  Packer,
  Paragraph,
  PictureRun,
  ShadingType,
  Table,
  TableCell,
  TableRow,
  TextDirection,
  TextRun,
  VerticalAlign,
  WidthType,
} from 'docx';

export const GripExportColors = {
  primary: '#00205B',
  secondary: '#1D70B8',
  lightGray: '#DBDBDB',
  darkGray: '#444f51',
  gray: '#8C8C8C',
  lightBlue: '#D3E9FA',
};

export const GripExportSize = {
  titleHeader: 24,
  secondary: 18,
};

const emptyLine: Paragraph = new Paragraph('');

const newLine: Paragraph = new Paragraph({
  thematicBreak: true,
});

const spacing: Paragraph = new Paragraph({
  spacing: {
    after: 50,
  },
});

async function setHeader(document: Document): Promise<Paragraph> {
  const blob: any = await fetch(
    `${process.env.VUE_APP_FRONTEND_URL}/assets/images/JBI_GRiP_Banner.png`,
  ).then((r) => r.blob());
  const image: PictureRun = Media.addImage(document, blob, 625, 48);
  return new Paragraph(image);
}

function setFooter(): Paragraph {
  const exportDateTime: string = dayjs().format('D MMM YYYY, HH:mm A');

  return new Paragraph({
    children: [
      new TextRun({
        text: `Exported on ${exportDateTime}`,
        size: GripExportSize.secondary,
        font: {
          name: 'Open Sans',
        },
      }),
    ],
  });
}

export const generateGripReportDocx = async (
  gripExportReport: GripExportReportResponse,
  isDomainCategoryRequired: boolean,
  projectTitle: string,
) => {
  const document: Document = new Document({
    hyperlinks: generateHyperLinks(gripExportReport.gripAnalysis),
  });

  const documentContent: Array<Paragraph | Table> = [
    /** Generates the Grip Report */
    ...setReportTitle(projectTitle),
    ...setTeamDetails(gripExportReport.gripTeamDetails),
    ...setGripAnalysis(gripExportReport.gripAnalysis),
  ];

  if (gripExportReport.gripAnalysis.length > 0) {
    /** Generates the Grip Report Table */
    documentContent.push(setTableReportTitle());
    documentContent.push(
      setGripTableReport(gripExportReport, isDomainCategoryRequired),
    );
  }

  document.addSection({
    headers: {
      default: new Header({
        children: [await setHeader(document), emptyLine],
      }),
    },
    children: documentContent,
    footers: {
      default: new Footer({
        children: [setFooter(), emptyLine],
      }),
    },
  });
  const fileName: string =
    projectTitle + '_' + dayjs().format('DDMMYYYY') + '.docx';

  Packer.toBlob(document).then((blob: any) => {
    saveAs(blob, fileName);
  });
};

function setReportTitle(projectTitle: string): Paragraph[] {
  return [
    new Paragraph({
      children: [
        new TextRun({
          text: projectTitle.replace(/\s+/g, ' ').toUpperCase(),
          bold: true,
          size: 40,
          color: GripExportColors.primary,
          font: {
            name: 'Open Sans',
          },
          style: '',
        }),
      ],
      spacing: {
        after: 150,
      },
    }),
    emptyLine,
  ];
}

function setTeamDetails(gripTeamDetails: Grip) {
  return [
    ...setGripTeamDetailContent('Project Leader', gripTeamDetails.projectLead),
    emptyLine,
    ...setGripTeamDetailContent('Stakeholders', gripTeamDetails.stakeholder),
    emptyLine,
    ...setGripTeamDetailContent(
      'Date to Implement Action',
      dayjs(gripTeamDetails.actionDate).format('D MMM YYYY'),
    ),
    newLine,
    spacing,
  ];
}

function setGripTeamDetailContent(label: string, value: string) {
  /** to handle new line in detail text input */
  const textValues: string[] = value ? value.split('\n') : ['No data entered'];
  const textValueParagraphs: Paragraph[] = textValues.map(
    (textValue) =>
      new Paragraph({
        children: [
          new TextRun({
            text: textValue,
            bold: value ? true : false,
            size: GripExportSize.secondary,
            color: GripExportColors.darkGray,
            font: {
              name: 'Open Sans',
            },
          }),
        ],
      }),
  );
  return [
    new Paragraph({
      children: [
        new TextRun({
          text: label,
          bold: true,
          size: GripExportSize.secondary,
          color: GripExportColors.secondary,
          font: {
            name: 'Open Sans',
          },
        }),
      ],
    }),
    ...textValueParagraphs,
  ];
}

function setGripAnalysisTitle(title: string): Paragraph {
  return new Paragraph({
    children: [
      new TextRun({
        text: title.replace(/\s+/g, ' ').toUpperCase(),
        bold: true,
        size: GripExportSize.titleHeader,
        color: GripExportColors.gray,
        font: {
          name: 'Open Sans',
        },
      }),
    ],
  });
}

function setCriteriaTitle(title: string): Paragraph {
  return new Paragraph({
    children: [
      new TextRun({
        text: title,
        bold: true,
        size: GripExportSize.titleHeader,
        color: GripExportColors.secondary,
        font: {
          name: 'Open Sans',
        },
      }),
    ],
  });
}

function setContentTitle(title: string, leftIndent: number): Paragraph {
  return new Paragraph({
    indent: {
      left: leftIndent,
    },
    children: [
      new TextRun({
        text: title,
        bold: true,
        size: GripExportSize.titleHeader,
        color: GripExportColors.darkGray,
        font: {
          name: 'Open Sans',
        },
      }),
    ],
  });
}

function setGripAnalysis(gripAnalysis: GripAnalysis[]) {
  const analysisReportData: Paragraph[] = [];

  /** loop grip analysis */
  gripAnalysis.forEach((analysis: GripAnalysis) => {
    const analysisTitle: Paragraph = setGripAnalysisTitle(analysis.title);
    analysisReportData.push(analysisTitle);

    /** set analysis criteria list */
    if (analysis.gripAnalysisCriteriaMaps.length > 0) {
      const criteriaHeader: Paragraph = setCriteriaTitle('Criteria');
      const analysisCriteria: Paragraph[] = setAnalysisCriteria(
        analysis.gripAnalysisCriteriaMaps,
      );
      analysisReportData.push(criteriaHeader);
      analysisReportData.push(...analysisCriteria);
      analysisReportData.push(spacing);

      if (analysis.gripAnalysisBarriers.length > 0) {
        const analysisData: Paragraph[] = setAnalysisData(
          analysis.gripAnalysisBarriers,
        );
        analysisReportData.push(...analysisData);
      }
    } else {
      const noData: Paragraph = setContentTitle(
        'No data added for this analysis.',
        0,
      );
      analysisReportData.push(noData);
    }
    analysisReportData.push(newLine);
    analysisReportData.push(spacing);
  });

  return analysisReportData;
}

function setAnalysisCriteria(
  gripAnalysisCriteriaMaps: GripAnalysisCriteria[],
): Paragraph[] {
  const analysisCriteriaDetails: Paragraph[] = [];
  gripAnalysisCriteriaMaps.forEach((analysisCriteria, index) => {
    analysisCriteriaDetails.push(
      new Paragraph({
        children: [
          new TextRun({
            text: `${index + 1}. ${analysisCriteria.criteria.documentId}`,
            size: GripExportSize.secondary,
            color: GripExportColors.darkGray,
            font: {
              name: 'Open Sans',
            },
          }),
          new TextRun({
            text: `\t${analysisCriteria.criteria.title}`,
            bold: true,
            size: GripExportSize.secondary,
            color: GripExportColors.darkGray,
            font: {
              name: 'Open Sans',
            },
          }),
        ],
      }),
    );
  });

  return analysisCriteriaDetails;
}

function setAnalysisData(
  gripAnalysisBarriers: GripAnalysisBarrier[],
): Paragraph[] {
  const analysisData: Paragraph[] = [];

  /** loop barriers and add */
  gripAnalysisBarriers.forEach((analysisBarrier, analysisBarrierIndex) => {
    /** Add Barrier */
    const barrier: Paragraph[] = setBarrier(
      analysisBarrier,
      analysisBarrierIndex,
    );
    analysisData.push(...barrier);

    if (
      analysisBarrier.action &&
      analysisBarrier.gripAnalysisEnablers.length > 0
    ) {
      /** loop enablers and add */
      analysisBarrier.gripAnalysisEnablers.forEach(
        (analysisEnabler, analysisEnablerIndex) => {
          /** Add Enabler */
          const enabler = setEnabler(analysisEnabler, analysisEnablerIndex);
          analysisData.push(...enabler);

          /** loop strategies and add */
          if (analysisEnabler.gripAnalysisStrategies.length > 0) {
            analysisEnabler.gripAnalysisStrategies.forEach(
              (analysisStrategy, analysisStrategyIndex) => {
                /** Add Strategy */
                const strategy: Paragraph[] = setStrategy(
                  analysisStrategy,
                  analysisStrategyIndex,
                );
                analysisData.push(...strategy);
              },
            );
          }
        },
      );
    }
  });
  return analysisData;
}

function setBarrier(barrier: GripAnalysisBarrier, barrierIndex: number) {
  const barrierContent = [];
  const contentLeftIndent: number = 0;
  const listContentLeftIndent: number = 80;

  /** Barrier Title */
  const barrierTitle: Paragraph = setContentTitle(
    `Barrier ${barrierIndex + 1}: ${barrier.title}`,
    contentLeftIndent,
  );
  barrierContent.push(barrierTitle);

  /** Barrier Domain Category */
  if (barrier.gripDomainCategory) {
    const barrierDomainCategory: Paragraph = setContentDetails(
      'Domain/Category:',
      barrier.gripDomainCategory.name,
      contentLeftIndent,
    );
    barrierContent.push(barrierDomainCategory);
  }

  /** Barrier Description */
  if (barrier.description) {
    const barrierDescription: Paragraph = setContentDetails(
      'Description:',
      barrier.description,
      contentLeftIndent,
    );
    barrierContent.push(barrierDescription);
  }

  /** Barrier Links */
  if (barrier.links.length > 0) {
    const barrierLinkLabel: Paragraph = setContentDetails(
      'Link(s):',
      '',
      contentLeftIndent,
    );
    const barrierLinks: Paragraph[] = barrier.links.map(
      (link, barrierLinkIndex) =>
        setHyperLinks(
          'barrier',
          barrier.id,
          barrierLinkIndex,
          listContentLeftIndent,
        ),
    );
    barrierContent.push(barrierLinkLabel);
    barrierContent.push(...barrierLinks);
  }

  /** Barrier Attachments */
  if (barrier.attachments.length > 0) {
    const barrierAttachmentsLabel: Paragraph = setContentDetails(
      'Attachment(s):',
      '',
      contentLeftIndent,
    );
    const barrierAttachments: Paragraph[] = barrier.attachments.map(
      (attachment, barrierAttachmentIndex) =>
        setHyperLinks(
          'barrier-attachment',
          barrier.id,
          barrierAttachmentIndex,
          listContentLeftIndent,
        ),
    );
    barrierContent.push(barrierAttachmentsLabel);
    barrierContent.push(...barrierAttachments);
  }

  /** Barrier Justification, if not to be actioned */
  if (!barrier.action && barrier.reason) {
    const isNotToBeActioned: Paragraph = setContentDetails(
      'Not to be actioned',
      '',
      contentLeftIndent,
    );
    barrierContent.push(isNotToBeActioned);

    const barrierJustification: Paragraph = setContentDetails(
      'Justification:',
      barrier.reason,
      contentLeftIndent,
    );
    barrierContent.push(barrierJustification);
  }

  barrierContent.push(spacing);
  return barrierContent;
}

function setEnabler(
  enabler: GripAnalysisEnabler,
  enablerIndex: number,
): Paragraph[] {
  const enablerContent: Paragraph[] = [];
  const contentLeftIndent: number = 280;
  const listContentLeftIndent: number = 360;
  /** Enabler Title */
  const enablerTitle = setContentTitle(
    `Enabler ${enablerIndex + 1}: ${enabler.title}`,
    contentLeftIndent,
  );
  enablerContent.push(enablerTitle);

  /** Enabler Domain Category */
  if (enabler.gripDomainCategory) {
    const enablerDomainCategory = setContentDetails(
      'Domain/Category:',
      enabler.gripDomainCategory.name,
      contentLeftIndent,
    );
    enablerContent.push(enablerDomainCategory);
  }

  /** Enabler Description */
  if (enabler.description) {
    const enablerDescription = setContentDetails(
      'Description:',
      enabler.description,
      contentLeftIndent,
    );
    enablerContent.push(enablerDescription);
  }

  /** Enabler Links */
  if (enabler.links.length > 0) {
    const enablerLinkLabel = setContentDetails(
      'Link(s):',
      '',
      contentLeftIndent,
    );
    const enablerLinks = enabler.links.map((link, enablerLinkIndex) =>
      setHyperLinks(
        'enabler',
        enabler.id,
        enablerLinkIndex,
        listContentLeftIndent,
      ),
    );
    enablerContent.push(enablerLinkLabel);
    enablerContent.push(...enablerLinks);
  }

  /** Enabler Attachments */
  if (enabler.attachments.length > 0) {
    const enablerAttachmentsLabel = setContentDetails(
      'Attachment(s):',
      '',
      contentLeftIndent,
    );
    const enablerAttachments = enabler.attachments.map(
      (attachment, enablerAttachmentIndex) =>
        setHyperLinks(
          'enabler-attachment',
          enabler.id,
          enablerAttachmentIndex,
          listContentLeftIndent,
        ),
    );
    enablerContent.push(enablerAttachmentsLabel);
    enablerContent.push(...enablerAttachments);
  }

  enablerContent.push(spacing);
  return enablerContent;
}

function setStrategy(
  strategy: GripAnalysisStrategy,
  strategyIndex: number,
): Paragraph[] {
  const strategyContent: Paragraph[] = [];
  const contentLeftIndent: number = 840;
  const listContentLeftIndent: number = 920;

  /** Strategy Title */
  const strategyTitle = setContentTitle(
    `Strategy ${strategyIndex + 1}: ${strategy.title}`,
    contentLeftIndent,
  );
  strategyContent.push(strategyTitle);

  /** Strategy Domain Category */
  if (strategy.gripDomainCategory) {
    const strategyDomainCategory = setContentDetails(
      'Domain/Category:',
      strategy.gripDomainCategory.name,
      contentLeftIndent,
    );
    strategyContent.push(strategyDomainCategory);
  }

  /** Strategy Description */
  if (strategy.description) {
    const strategyDescription = setContentDetails(
      'Description:',
      strategy.description,
      contentLeftIndent,
    );
    strategyContent.push(strategyDescription);
  }

  /** Strategy Links */
  if (strategy.links.length > 0) {
    const strategyLinkLabel = setContentDetails(
      'Link(s):',
      '',
      contentLeftIndent,
    );
    const strategyLinks = strategy.links.map((link, strategyLinkIndex) =>
      setHyperLinks(
        'strategy',
        strategy.id,
        strategyLinkIndex,
        listContentLeftIndent,
      ),
    );
    strategyContent.push(strategyLinkLabel);
    strategyContent.push(...strategyLinks);
  }

  /** Strategy Attachments */
  if (strategy.attachments.length > 0) {
    const strategyAttachmentsLabel = setContentDetails(
      'Attachment(s):',
      '',
      contentLeftIndent,
    );
    const strategyAttachments = strategy.attachments.map(
      (attachment, strategyAttachmentIndex) =>
        setHyperLinks(
          'strategy-attachment',
          strategy.id,
          strategyAttachmentIndex,
          listContentLeftIndent,
        ),
    );
    strategyContent.push(strategyAttachmentsLabel);
    strategyContent.push(...strategyAttachments);
  }

  /** Strategy Resource */
  if (strategy.resource && strategy.resource.length > 0) {
    const strategyResource = setContentDetails(
      'Resources Required:',
      strategy.resource,
      contentLeftIndent,
    );
    strategyContent.push(strategyResource);
  }

  /** Strategy Method */
  if (strategy.method && strategy.method.length > 0) {
    const strategyMethod = setContentDetails(
      'How the Strategy is measured or evaluated:',
      strategy.method,
      contentLeftIndent,
    );
    strategyContent.push(strategyMethod);
  }

  strategyContent.push(spacing);
  return strategyContent;
}

function generateHyperLinks(gripAnalysis: GripAnalysis[]): GenerateHyperLinks {
  const analysisGripBarriers: GripAnalysisBarrier[] = [];
  const analysisGripEnablers: GripAnalysisEnabler[] = [];
  const analysisGripStrategies: GripAnalysisStrategy[] = [];

  gripAnalysis.forEach((analysis) => {
    analysis.gripAnalysisBarriers.forEach((analysisBarrier) => {
      analysisGripBarriers.push(analysisBarrier);
      analysisBarrier.gripAnalysisEnablers.forEach((analysisEnabler) => {
        analysisGripEnablers.push(analysisEnabler);
        analysisEnabler.gripAnalysisStrategies.forEach((analysisStrategy) => {
          analysisGripStrategies.push(analysisStrategy);
        });
      });
    });
  });

  const barrierHyperLinks: HyperLinks = generateHyperLinksOfItem(
    analysisGripBarriers,
    'barrier',
  );
  const enablerHyperLinks: HyperLinks = generateHyperLinksOfItem(
    analysisGripEnablers,
    'enabler',
  );

  const strategyHyperLinks: HyperLinks = generateHyperLinksOfItem(
    analysisGripStrategies,
    'strategy',
  );

  return {
    ...barrierHyperLinks,
    ...enablerHyperLinks,
    ...strategyHyperLinks,
  };
}

function generateHyperLinksOfItem(
  gripAnalysisItems: GripAnalysisItem[],
  itemType: string,
) {
  const hyperLinks: HyperLinks = {};
  gripAnalysisItems.forEach((item: GripAnalysisItem) => {
    if (item.links && item.links.length > 0) {
      item.links.forEach((link: string, linkIndex: number) => {
        hyperLinks[`${itemType}-${item.id}-${linkIndex}`] = {
          link,
          text: link,
          type: HyperlinkType.EXTERNAL,
        };
      });
    }
    if (item.attachments && item.attachments.length > 0) {
      item.attachments.forEach((link: string, linkIndex: number) => {
        const lastSegment: string | undefined = link.split('/').pop();
        hyperLinks[`${itemType}-attachment-${item.id}-${linkIndex}`] = {
          link,
          text: lastSegment || '',
          type: HyperlinkType.EXTERNAL,
        };
      });
    }
  });

  return hyperLinks;
}

/** set link or attachment */
function setHyperLinks(
  itemType: string,
  itemId: number,
  itemLinkIndex: number,
  leftIndent: number,
): Paragraph {
  return new Paragraph({
    indent: {
      left: leftIndent,
    },
    children: [new HyperlinkRef(`${itemType}-${itemId}-${itemLinkIndex}`)],
  });
}

function setContentDetails(
  label: string,
  value: string,
  leftIndent: number,
): Paragraph {
  return new Paragraph({
    indent: {
      left: leftIndent,
    },
    children: [
      new TextRun({
        text: `${label} `,
        bold: true,
        size: GripExportSize.secondary,
        color: GripExportColors.darkGray,
        font: {
          name: 'Open Sans',
        },
      }),
      new TextRun({
        text: `${value}`,
        size: GripExportSize.secondary,
        color: GripExportColors.darkGray,
        font: {
          name: 'Open Sans',
        },
      }),
    ],
  });
}

function setTableReportTitle(): Paragraph {
  return new Paragraph({
    pageBreakBefore: true,
    alignment: AlignmentType.CENTER,
    children: [
      new TextRun({
        text: 'GRiP REPORT TABLE',
        bold: true,
        size: 50,
        color: GripExportColors.primary,
        font: {
          name: 'Open Sans',
        },
      }),
    ],
  });
}

function setGripTableReport(
  gripExportReport: GripExportReportResponse,
  isDomainCategoryRequired: boolean,
): Table {
  const analysisData: TableRow[] = [];
  /** loop analysis and add the respective criteria/barriers/enablers/strategies */
  gripExportReport.gripAnalysis.forEach((analysis) => {
    const analysisTitle: TableRow = getGripTableAnalysisTitle(
      analysis.title.toLocaleUpperCase(),
      isDomainCategoryRequired,
    );
    analysisData.push(analysisTitle);
    if (!analysis.gripAnalysisCriteriaMaps.length) {
      analysisData.push(
        setTableNoDataRow('No Data added.', isDomainCategoryRequired),
      );
    } else {
      analysisData.push(
        ...setTableAnalysisData(analysis, isDomainCategoryRequired),
      );
    }
  });

  const columnWidths: number[] = isDomainCategoryRequired
    ? [25, 1, 24, 1, 24, 1, 24]
    : [25, 25, 25, 25];

  const table: Table = new Table({
    rows: [setTableHeader(isDomainCategoryRequired), ...analysisData],
    columnWidths,
  });
  return table;
}

function setTableAnalysisData(
  analysis: GripAnalysis,
  isDomainCategoryRequired: boolean,
): TableRow[] {
  let tableContent: TableCell[][] = [];
  const currentRowIndex: number = tableContent.length;
  let totalAnalysisRowCount: number = 0;

  /** get count of rows required for analysis */
  if (analysis.gripAnalysisBarriers.length > 0) {
    analysis.gripAnalysisBarriers.forEach((analysisBarrier) => {
      if (analysisBarrier.gripAnalysisEnablers.length > 0) {
        analysisBarrier.gripAnalysisEnablers.forEach((analysisEnabler) => {
          if (analysisEnabler.gripAnalysisStrategies.length > 0) {
            const analysisStrategyCount: number =
              analysisEnabler.gripAnalysisStrategies.length;

            totalAnalysisRowCount += isDomainCategoryRequired
              ? analysisStrategyCount * 2
              : analysisStrategyCount;
          } else {
            totalAnalysisRowCount += isDomainCategoryRequired ? 2 : 1;
          }
        });
      } else {
        totalAnalysisRowCount += isDomainCategoryRequired ? 2 : 1;
      }
    });
  } else {
    totalAnalysisRowCount += 1;
  }

  /** Add empty rows for analysis data */
  for (let i = 1; i <= totalAnalysisRowCount; i++) {
    tableContent.push([]);
  }

  const analysisTableData: any[] = isDomainCategoryRequired
    ? getTableContentDetailsWithDomainCategory(analysis, totalAnalysisRowCount)
    : getTableContentDetails(analysis, totalAnalysisRowCount);

  /** Add analysis criteria */
  const criteria: Paragraph[] = analysis.gripAnalysisCriteriaMaps
    .filter((criteriaMap) => !criteriaMap.criteria.isDeleted)
    .map((criteriaMap, index) => {
      return setColumnData({
        title: `${index + 1}. ${criteriaMap.criteria.title}`,
      });
    });

  tableContent[currentRowIndex].push(
    new TableCell({
      rowSpan: analysisTableData.length,
      children: criteria,
    }),
  );

  analysisTableData.forEach((contentDetails, index) => {
    contentDetails.forEach((content: any) => {
      if (content.title) {
        tableContent[currentRowIndex + index].push(setTableCellData(content));
      }
    });
  });

  /** remove extra empty rows */
  tableContent = tableContent.filter((row) => {
    return JSON.stringify(row) !== JSON.stringify([]);
  });

  const analysisData: TableRow[] = tableContent.map((content) => {
    return new TableRow({
      tableHeader: true,
      cantSplit: true,
      children: content,
      height: {
        height: 100,
        rule: HeightRule.AUTO,
      },
    });
  });

  return analysisData;
}

function getTableContentDetails(
  analysis: GripAnalysis,
  totalAnalysisRowCount: number,
) {
  let barrierRowIndex: number = 0;
  let enablerRowIndex: number = 0;
  let strategyRowIndex: number = 0;
  let tableContent: any[] = [];

  /** loop analysis barriers */
  if (analysis.gripAnalysisBarriers.length > 0) {
    /** Add empty rows for analysis data */
    for (let i = 1; i <= totalAnalysisRowCount; i++) {
      tableContent.push(['', '', '', '']);
    }
    analysis.gripAnalysisBarriers.forEach((analysisBarrier) => {
      let barrierRowSpan: number = 0;
      if (
        analysisBarrier.action &&
        analysisBarrier.gripAnalysisEnablers.length > 0
      ) {
        /** loop analysis enablers */
        analysisBarrier.gripAnalysisEnablers.forEach((analysisEnabler) => {
          const enablerRowSpan: number =
            analysisEnabler.gripAnalysisStrategies.length > 0
              ? analysisEnabler.gripAnalysisStrategies.length
              : 1;

          if (analysisEnabler.gripAnalysisStrategies.length > 0) {
            /** loop analysis strategies */
            analysisEnabler.gripAnalysisStrategies.forEach(
              (analysisStrategy) => {
                /** Add analysis strategy data to table */
                tableContent[strategyRowIndex].splice(3, 1, {
                  title: analysisStrategy.title,
                  rowSpan: 1,
                });
                strategyRowIndex += 1;
              },
            );
          } else {
            tableContent[strategyRowIndex].splice(3, 1, {
              title: 'No Strategy Included.',
              rowSpan: 1,
            });
            strategyRowIndex += 1;
          }

          /** Add analysis enabler data to table */
          tableContent[enablerRowIndex].splice(2, 1, {
            title: analysisEnabler.title,
            rowSpan: enablerRowSpan ? enablerRowSpan : 1,
          });
          enablerRowIndex += enablerRowSpan ? enablerRowSpan : 1;
          barrierRowSpan += enablerRowSpan ? enablerRowSpan : 1;
        });
      } else {
        tableContent[enablerRowIndex].splice(
          2,
          2,
          {
            title: 'No Enabler Included.',
            rowSpan: 1,
          },
          {
            title: 'No Strategy Included.',
            rowSpan: 1,
          },
        );
        enablerRowIndex += 1;
        strategyRowIndex += 1;
      }

      /** Add analysis barrier data to table */
      tableContent[barrierRowIndex].splice(1, 1, {
        title: analysisBarrier.title,
        justification:
          !analysisBarrier.action && analysisBarrier.reason
            ? analysisBarrier.reason
            : null,
        rowSpan: barrierRowSpan ? barrierRowSpan : 1,
      });
      barrierRowIndex += barrierRowSpan ? barrierRowSpan : 1;
    });
  } else {
    tableContent.push([
      '',
      {
        title: 'No Barrier Included.',
        rowSpan: 1,
      },
      {
        title: 'No Enabler Included.',
        rowSpan: 1,
      },
      {
        title: 'No Strategy Included.',
        rowSpan: 1,
      },
    ]);
  }

  /** remove extra empty rows */
  tableContent = tableContent.filter((row) => {
    return JSON.stringify(row) !== JSON.stringify(['', '', '', '']);
  });

  return tableContent;
}

function getTableContentDetailsWithDomainCategory(
  analysis: GripAnalysis,
  totalAnalysisRowCount: number,
) {
  const tableContent: any[] = [];

  /** loop analysis barriers */
  if (analysis.gripAnalysisBarriers.length > 0) {
    /** generate analysis data based on DomainCategory */
    const {
      barriers,
      enablers,
      strategies,
    } = generateAnalysisTableDataByDomainCategory(analysis);

    /** map analysis data to table */
    const analysisData = mapAnalysisTableDataVertical(
      barriers,
      enablers,
      strategies,
      totalAnalysisRowCount,
    );
    analysisData.forEach((data) => tableContent.push(data));
  } else {
    tableContent.push([
      '',
      {
        title: 'No Barrier Included.',
        colSpan: 2,
      },
      '',
      {
        title: 'No Enabler Included.',
        colSpan: 2,
      },
      '',
      {
        title: 'No Strategy Included.',
        colSpan: 2,
      },
      '',
    ]);
  }

  return tableContent;
}

function generateAnalysisTableDataByDomainCategory(analysis: GripAnalysis) {
  const barriers: any = {};
  const enablers: any = {};
  const strategies: any = {};

  analysis.gripAnalysisBarriers.forEach((barrier) => {
    enablers[`barrier_${barrier.id}`] = {};

    const barrierDomainCategory: string = barrier.gripDomainCategory
      ? barrier.gripDomainCategory.name
      : 'NoDomainCategory';

    /** separate the barriers based on DomainCategory  */
    if (
      barriers[barrierDomainCategory] &&
      barriers[barrierDomainCategory].length
    ) {
      barriers[barrierDomainCategory].push(barrier);
    } else {
      barriers[barrierDomainCategory] = [barrier];
    }

    barrier.gripAnalysisEnablers.forEach((enabler) => {
      strategies[`enabler_${enabler.id}`] = {};
      const enablerDomainCategory: string = enabler.gripDomainCategory
        ? enabler.gripDomainCategory.name
        : 'NoDomainCategory';

      /** separate the enablers based on DomainCategory  */
      const analysisEnablers =
        enablers[`barrier_${barrier.id}`][enablerDomainCategory];

      if (analysisEnablers) {
        enablers[`barrier_${barrier.id}`][enablerDomainCategory].push(enabler);
      } else {
        enablers[`barrier_${barrier.id}`][enablerDomainCategory] = [enabler];
      }

      enabler.gripAnalysisStrategies.forEach((strategy) => {
        const strategyDomainCategory: string = strategy.gripDomainCategory
          ? strategy.gripDomainCategory.name
          : 'NoDomainCategory';

        const analysisStrategies =
          strategies[`enabler_${enabler.id}`][strategyDomainCategory];

        /** separate the strategies based on DomainCategory  */
        if (analysisStrategies) {
          strategies[`enabler_${enabler.id}`][strategyDomainCategory].push(
            strategy,
          );
        } else {
          strategies[`enabler_${enabler.id}`][strategyDomainCategory] = [
            strategy,
          ];
        }
      });
    });
  });

  return { barriers, enablers, strategies };
}

function mapAnalysisTableData(
  barriers: any,
  enablers: any,
  strategies: any,
  totalAnalysisRowCount: number,
) {
  let barrierRowIndex: number = 0;
  let enablerRowIndex: number = 0;
  let strategyRowIndex: number = 0;
  let tableContent: any[] = [];

  /** Add empty rows for analysis data */
  for (let i = 1; i <= totalAnalysisRowCount; i++) {
    tableContent.push(['', '', '', '']);
  }

  const domainCategoriesOfBarriers: string[] = Object.keys(barriers);
  /** Find NoDomainCategory index and add them at the end of the object */
  const noDomainCategoryBarrierIndex: number = domainCategoriesOfBarriers.findIndex(
    (value) => value === 'NoDomainCategory',
  );
  if (noDomainCategoryBarrierIndex >= 0) {
    domainCategoriesOfBarriers.splice(noDomainCategoryBarrierIndex, 1);
    domainCategoriesOfBarriers.push('NoDomainCategory');
  }

  domainCategoriesOfBarriers.forEach((barrierDCK, barrierDCKIndex) => {
    const analysisBarriers: GripAnalysisBarrier[] = barriers[barrierDCK];
    let barrierDomainCategory = { isAdded: false, index: barrierRowIndex };
    let noEnablerStrategyDataRowSpan: number = 0;

    /** add domain category */
    tableContent[barrierRowIndex].splice(1, 1, {
      title: barrierDCK === 'NoDomainCategory' ? '-' : barrierDCK,
      colSpan: 1,
      rowSpan: 1,
      style: {
        fillColor: GripExportColors.lightBlue,
        alignment: 'center',
        isDomainCategoryRequired: true,
      },
    });
    barrierDomainCategory = { isAdded: true, index: barrierRowIndex };
    barrierRowIndex += 1;
    noEnablerStrategyDataRowSpan += 1;

    analysisBarriers.forEach(
      (analysisBarrier: GripAnalysisBarrier, abIndex: number) => {
        let barrierRowSpan = 0;
        /**
         * check whether barrier is to be actioned and have enablers,
         * if true, add enablers, else add no data column
         */
        if (
          analysisBarrier.action &&
          enablers[`barrier_${analysisBarrier.id}`] &&
          Object.keys(enablers[`barrier_${analysisBarrier.id}`]).length > 0
        ) {
          const barrierEnablers = enablers[`barrier_${analysisBarrier.id}`];
          const domainCategoriesOfEnablers: string[] = Object.keys(
            barrierEnablers,
          );

          /** Find NoDomainCategory index and add them at the end of the object */
          const noDomainCategoryEnablerIndex = domainCategoriesOfEnablers.findIndex(
            (value) => value === 'NoDomainCategory',
          );
          if (noDomainCategoryEnablerIndex >= 0) {
            domainCategoriesOfEnablers.splice(noDomainCategoryEnablerIndex, 1);
            domainCategoriesOfEnablers.push('NoDomainCategory');
          }

          domainCategoriesOfEnablers.forEach((enablerDCK, enablerDCKIndex) => {
            const analysisEnablers: GripAnalysisEnabler[] =
              barrierEnablers[enablerDCK];
            let enablerDomainCategory = {
              isAdded: false,
              index: enablerRowIndex,
            };
            let noStrategyDataRowSpan = 0;

            /** Add domain category:
             * Barriers, Enablers and Strategies can each have their own domain categories.
             * The combination of column cells for domain categories within barriers, enablers can be done if,
             * 1. barrier and enabler share the same domain category
             * 2. It should be first barrier of respective domain category (i.e abIndex)
             * 3. It should be first domain category for enabler(i.e. enablerDCKIndex)
             */
            if (
              barrierDCK === enablerDCK &&
              abIndex === 0 &&
              enablerDCKIndex === 0
            ) {
              let index = barrierRowIndex;
              if (barrierDomainCategory.isAdded) {
                index = barrierDomainCategory.index;
                noStrategyDataRowSpan += 1;
              } else {
                barrierRowIndex += 1;
              }

              tableContent[index].splice(1, 1, {
                colSpan: 2,
                title: barrierDCK === 'NoDomainCategory' ? '-' : barrierDCK,
                style: {
                  fillColor: GripExportColors.lightBlue,
                  alignment: 'center',
                  isDomainCategoryRequired: true,
                },
              });
              enablerRowIndex += 1;
            } else {
              enablerDomainCategory = {
                isAdded: true,
                index: enablerRowIndex,
              };

              tableContent[enablerRowIndex].splice(2, 1, {
                title: enablerDCK === 'NoDomainCategory' ? '-' : enablerDCK,
                colspan: 1,
                rowSpan: 1,
                style: {
                  fillColor: GripExportColors.lightBlue,
                  alignment: 'center',
                  isDomainCategoryRequired: true,
                },
              });
              enablerRowIndex += 1;
              barrierRowSpan += barrierRowIndex === enablerRowIndex ? 0 : 1;
              noStrategyDataRowSpan += 1;
            }

            analysisEnablers.forEach(
              (analysisEnabler: GripAnalysisEnabler, aeIndex: number) => {
                let enablerRowSpan: number = 0;
                const enablerStrategies =
                  strategies[`enabler_${analysisEnabler.id}`];

                const domainCategoriesOfStrategies = Object.keys(
                  enablerStrategies,
                );
                /** Find NoDomainCategory index and add them at the end of the object */
                const noDomainCategoryStrategyIndex = domainCategoriesOfStrategies.findIndex(
                  (value) => value === 'NoDomainCategory',
                );
                if (noDomainCategoryStrategyIndex >= 0) {
                  domainCategoriesOfStrategies.splice(
                    noDomainCategoryStrategyIndex,
                    1,
                  );
                  domainCategoriesOfStrategies.push('NoDomainCategory');
                }

                /**
                 * if enabler have startegy(ies) add enablers,
                 * else add no data column
                 */
                if (
                  strategies[`enabler_${analysisEnabler.id}`] &&
                  Object.keys(strategies[`enabler_${analysisEnabler.id}`])
                    .length > 0
                ) {
                  domainCategoriesOfStrategies.forEach(
                    (strategyDCK, strategyDCKIndex) => {
                      /**  Add domain category:
                       * Barriers, Enablers and Strategies can each have their own domain categories.
                       * The combination of column cells for domain categories
                       * within barriers, enablers, and strategies can be done in the following ways.
                       *
                       * A. if all 3 share the same domain category
                       *  1. barrier, enabler and strategy share the same domain category
                       *  2. It should be first barrier and enabler of respective domain category(i.e. abIndex, aeIndex)
                       *  3. It should be first domain category for enablers and strategy
                       *     (i.e. enablerDCKIndex, strategyDCKIndex)
                       *
                       * B. else if only 2 share the same domain category
                       *  1. if enabler and strategy share the same domain category
                       *  2. It should be first enabler of respective domain category(i.e. aeIndex)
                       *  3. It should be first domain category for strategies(i.e. strategyDCKIndex)
                       */
                      if (
                        barrierDCK === enablerDCK &&
                        enablerDCK === strategyDCK &&
                        abIndex === 0 &&
                        aeIndex === 0 &&
                        enablerDCKIndex === 0 &&
                        strategyDCKIndex === 0
                      ) {
                        let index = barrierRowIndex;
                        if (barrierDomainCategory.isAdded) {
                          index = barrierDomainCategory.index;
                        } else {
                          barrierRowIndex += 1;
                          enablerRowIndex += 1;
                        }

                        tableContent[index].splice(1, 1, {
                          colSpan: 3,
                          title:
                            barrierDCK === 'NoDomainCategory'
                              ? '-'
                              : barrierDCK,
                          style: {
                            fillColor: GripExportColors.lightBlue,
                            alignment: 'center',
                            isDomainCategoryRequired: true,
                          },
                        });
                        strategyRowIndex += 1;
                      } else if (
                        enablerDCK === strategyDCK &&
                        aeIndex === 0 &&
                        strategyDCKIndex === 0
                      ) {
                        let index = enablerRowIndex;
                        if (enablerDomainCategory.isAdded) {
                          index = enablerDomainCategory.index;
                        } else {
                          enablerRowIndex += 1;
                          barrierRowSpan +=
                            enablerRowIndex === barrierRowIndex ? 0 : 1;
                        }
                        tableContent[index].splice(2, 1, {
                          colSpan: 2,
                          title:
                            enablerDCK === 'NoDomainCategory'
                              ? '-'
                              : enablerDCK,
                          style: {
                            fillColor: GripExportColors.lightBlue,
                            alignment: 'center',
                            isDomainCategoryRequired: true,
                          },
                        });
                        strategyRowIndex += 1;
                      } else {
                        tableContent[strategyRowIndex].splice(3, 1, {
                          title:
                            strategyDCK === 'NoDomainCategory'
                              ? '-'
                              : strategyDCK,
                          colSpan: 1,
                          rowSpan: 1,
                          style: {
                            fillColor: GripExportColors.lightBlue,
                            alignment: 'center',
                            isDomainCategoryRequired: true,
                          },
                        });
                        strategyRowIndex += 1;
                        enablerRowSpan +=
                          strategyRowIndex === enablerRowIndex ? 0 : 1;
                      }

                      const analysisStrategies: GripAnalysisStrategy[] =
                        enablerStrategies[strategyDCK];

                      /**  add strategy(ies) data */
                      analysisStrategies.forEach(
                        (analysisStrategy: GripAnalysisStrategy) => {
                          tableContent[strategyRowIndex].splice(3, 1, {
                            title: analysisStrategy.title,
                            rowSpan: 1,
                          });
                          strategyRowIndex += 1;
                          enablerRowSpan += 1;
                        },
                      );
                    },
                  );

                  /**  add enabler data */
                  tableContent[enablerRowIndex].splice(2, 1, {
                    title: analysisEnabler.title,
                    rowSpan: enablerRowSpan ? enablerRowSpan : 1,
                  });
                  enablerRowIndex += enablerRowSpan ? enablerRowSpan : 1;
                  barrierRowSpan += enablerRowSpan ? enablerRowSpan : 1;
                } else {
                  /** add enabler data  */
                  tableContent[enablerRowIndex].splice(2, 1, {
                    title: analysisEnabler.title,
                    rowSpan: enablerRowSpan ? enablerRowSpan : 1,
                  });
                  enablerRowIndex += enablerRowSpan ? enablerRowSpan : 1;
                  barrierRowSpan += enablerRowSpan ? enablerRowSpan : 1;
                  noStrategyDataRowSpan += enablerRowSpan ? enablerRowSpan : 1;

                  /** add empty data */
                  tableContent[strategyRowIndex].splice(3, 1, {
                    title: 'No Strategy Included.',
                    rowSpan: noStrategyDataRowSpan ? noStrategyDataRowSpan : 1,
                  });
                  strategyRowIndex += noStrategyDataRowSpan
                    ? noStrategyDataRowSpan
                    : 1;
                }

                noStrategyDataRowSpan = 0;
              },
            );
          });
          /** add barrier data */
          tableContent[barrierRowIndex].splice(1, 1, {
            title: analysisBarrier.title,
            rowSpan: barrierRowSpan ? barrierRowSpan : 1,
          });
          barrierRowIndex += barrierRowSpan ? barrierRowSpan : 1;
        } else {
          /** add barrier data */
          tableContent[barrierRowIndex].splice(1, 1, {
            title: analysisBarrier.title,
            justification: !analysisBarrier.action
              ? analysisBarrier.reason
              : null,
            rowSpan: barrierRowSpan ? barrierRowSpan : 1,
          });
          barrierRowIndex += barrierRowSpan ? barrierRowSpan : 1;
          noEnablerStrategyDataRowSpan += 1;

          /** add empty data */
          tableContent[enablerRowIndex].splice(2, 1, {
            title: 'No Enabler Included.',
            rowSpan:
              noEnablerStrategyDataRowSpan > 0
                ? noEnablerStrategyDataRowSpan
                : 1,
          });
          enablerRowIndex += noEnablerStrategyDataRowSpan
            ? noEnablerStrategyDataRowSpan
            : 1;

          tableContent[strategyRowIndex].splice(3, 1, {
            title: 'No Strategy Included.',
            rowSpan:
              noEnablerStrategyDataRowSpan > 0
                ? noEnablerStrategyDataRowSpan
                : 1,
          });

          strategyRowIndex += noEnablerStrategyDataRowSpan
            ? noEnablerStrategyDataRowSpan
            : 1;
        }
        noEnablerStrategyDataRowSpan = 0;
      },
    );
  });

  /** remove extra empty rows */
  tableContent = tableContent.filter((row) => {
    return JSON.stringify(row) !== JSON.stringify([]);
  });

  return tableContent;
}

function mapAnalysisTableDataVertical(
  barriers: any,
  enablers: any,
  strategies: any,
  totalAnalysisRowCount: number,
) {
  let barrierRowIndex: number = 0;
  let enablerRowIndex: number = 0;
  let strategyRowIndex: number = 0;
  let tableContent: any[] = [];

  /** Add empty rows for analysis data */
  for (let i = 1; i <= totalAnalysisRowCount; i++) {
    tableContent.push(['', '', '', '', '', '', '']);
  }

  const domainCategoriesOfBarriers: string[] = Object.keys(barriers);
  /** Find NoDomainCategory index and add them at the end of the object */
  const noDomainCategoryBarrierIndex: number = domainCategoriesOfBarriers.findIndex(
    (value) => value === 'NoDomainCategory',
  );
  if (noDomainCategoryBarrierIndex >= 0) {
    domainCategoriesOfBarriers.splice(noDomainCategoryBarrierIndex, 1);
    domainCategoriesOfBarriers.push('NoDomainCategory');
  }

  domainCategoriesOfBarriers.forEach((barrierDCK) => {
    const analysisBarriers: GripAnalysisBarrier[] = barriers[barrierDCK];
    const barrierDomainCategory = { isAdded: true, index: barrierRowIndex };
    let barrierCategorySpan = 0;
    const barrierColSpan = barrierDCK === 'NoDomainCategory' ? 2 : 1;

    analysisBarriers.forEach((analysisBarrier: GripAnalysisBarrier) => {
      let barrierRowSpan = 0;
      /**
       * check whether barrier is to be actioned and have enablers,
       * if true, add enablers, else add no data column
       */
      if (
        analysisBarrier.action &&
        enablers[`barrier_${analysisBarrier.id}`] &&
        Object.keys(enablers[`barrier_${analysisBarrier.id}`]).length > 0
      ) {
        const barrierEnablers = enablers[`barrier_${analysisBarrier.id}`];
        const domainCategoriesOfEnablers: string[] = Object.keys(
          barrierEnablers,
        );

        /** Find NoDomainCategory index and add them at the end of the object */
        const noDomainCategoryEnablerIndex = domainCategoriesOfEnablers.findIndex(
          (value) => value === 'NoDomainCategory',
        );
        if (noDomainCategoryEnablerIndex >= 0) {
          domainCategoriesOfEnablers.splice(noDomainCategoryEnablerIndex, 1);
          domainCategoriesOfEnablers.push('NoDomainCategory');
        }

        domainCategoriesOfEnablers.forEach((enablerDCK) => {
          const analysisEnablers: GripAnalysisEnabler[] =
            barrierEnablers[enablerDCK];
          const enablerDomainCategory = {
            isAdded: true,
            index: enablerRowIndex,
          };
          let enablerCategorySpan = 0;
          const enablerColSpan = enablerDCK === 'NoDomainCategory' ? 2 : 1;

          analysisEnablers.forEach((analysisEnabler: GripAnalysisEnabler) => {
            let enablerRowSpan: number = 0;
            const enablerStrategies =
              strategies[`enabler_${analysisEnabler.id}`];

            const domainCategoriesOfStrategies = Object.keys(enablerStrategies);
            /** Find NoDomainCategory index and add them at the end of the object */
            const noDomainCategoryStrategyIndex = domainCategoriesOfStrategies.findIndex(
              (value) => value === 'NoDomainCategory',
            );
            if (noDomainCategoryStrategyIndex >= 0) {
              domainCategoriesOfStrategies.splice(
                noDomainCategoryStrategyIndex,
                1,
              );
              domainCategoriesOfStrategies.push('NoDomainCategory');
            }

            /**
             * if enabler have startegy(ies) add enablers,
             * else add no data column
             */
            if (
              strategies[`enabler_${analysisEnabler.id}`] &&
              Object.keys(strategies[`enabler_${analysisEnabler.id}`]).length >
                0
            ) {
              domainCategoriesOfStrategies.forEach((strategyDCK) => {
                const analysisStrategies: GripAnalysisStrategy[] =
                  enablerStrategies[strategyDCK];

                const strategyColSpan =
                  strategyDCK === 'NoDomainCategory' ? 2 : 1;

                if (strategyDCK !== 'NoDomainCategory') {
                  /**  Add domain category */
                  tableContent[strategyRowIndex].splice(5, 1, {
                    rowSpan: analysisStrategies.length,
                    title: strategyDCK,
                    style: {
                      fillColor: GripExportColors.lightBlue,
                      alignment: 'center',
                      isDomainCategoryRequired: true,
                    },
                  });
                }

                /**  add strategy(ies) data */
                analysisStrategies.forEach(
                  (analysisStrategy: GripAnalysisStrategy) => {
                    tableContent[strategyRowIndex].splice(6, 1, {
                      title: analysisStrategy.title,
                      colSpan: strategyColSpan,
                    });
                    strategyRowIndex += 1;
                    enablerRowSpan += 1;
                  },
                );
              });

              /**  add enabler data */
              tableContent[enablerRowIndex].splice(4, 1, {
                title: analysisEnabler.title,
                rowSpan: enablerRowSpan ? enablerRowSpan : 1,
                colSpan: enablerColSpan,
              });
              enablerRowIndex += enablerRowSpan ? enablerRowSpan : 1;
              enablerCategorySpan += enablerRowSpan ? enablerRowSpan : 1;
              barrierRowSpan += enablerRowSpan ? enablerRowSpan : 1;
            } else {
              /** add enabler data  */
              tableContent[enablerRowIndex].splice(4, 1, {
                title: analysisEnabler.title,
                rowSpan: enablerRowSpan ? enablerRowSpan : 1,
                colSpan: enablerColSpan,
              });
              enablerRowIndex += enablerRowSpan ? enablerRowSpan : 1;
              enablerCategorySpan += enablerRowSpan ? enablerRowSpan : 1;
              barrierRowSpan += enablerRowSpan ? enablerRowSpan : 1;

              /** add empty data */
              tableContent[strategyRowIndex].splice(5, 1, {
                title: 'No Strategy Included.',
                colSpan: 2,
              });
              strategyRowIndex += 1;
            }
          });

          if (enablerDCK !== 'NoDomainCategory') {
            /** Add domain category */
            tableContent[enablerDomainCategory.index].splice(3, 1, {
              title: enablerDCK,
              rowSpan: enablerCategorySpan ? enablerCategorySpan : 1,
              style: {
                fillColor: GripExportColors.lightBlue,
                alignment: 'center',
                isDomainCategoryRequired: true,
              },
            });
          }
        });
        /** add barrier data */
        tableContent[barrierRowIndex].splice(2, 1, {
          title: analysisBarrier.title,
          rowSpan: barrierRowSpan ? barrierRowSpan : 1,
          colSpan: barrierColSpan,
        });
        barrierRowIndex += barrierRowSpan ? barrierRowSpan : 1;
        barrierCategorySpan += barrierRowSpan ? barrierRowSpan : 1;
      } else {
        /** add barrier data */
        tableContent[barrierRowIndex].splice(2, 1, {
          title: analysisBarrier.title,
          justification: !analysisBarrier.action
            ? analysisBarrier.reason
            : null,
          rowSpan: barrierRowSpan ? barrierRowSpan : 1,
          colSpan: barrierColSpan,
        });
        barrierRowIndex += barrierRowSpan ? barrierRowSpan : 1;
        barrierCategorySpan += barrierRowSpan ? barrierRowSpan : 1;

        /** add empty data */
        tableContent[enablerRowIndex].splice(3, 1, {
          title: 'No Enabler Included.',
          colSpan: 2,
        });
        enablerRowIndex += 1;

        tableContent[strategyRowIndex].splice(5, 1, {
          title: 'No Strategy Included.',
          colSpan: 2,
        });
        strategyRowIndex += 1;
      }
    });

    if (barrierDCK !== 'NoDomainCategory') {
      /** add domain category */
      tableContent[barrierDomainCategory.index].splice(1, 1, {
        title: barrierDCK,
        rowSpan: barrierCategorySpan ? barrierCategorySpan : 1,
        style: {
          fillColor: GripExportColors.lightBlue,
          alignment: 'center',
          isDomainCategoryRequired: true,
        },
      });
    }
  });

  /** remove extra empty rows */
  tableContent = tableContent.filter((row) => {
    return JSON.stringify(row) !== JSON.stringify(['', '', '', '', '', '', '']);
  });

  return tableContent;
}

function setTableHeader(isDomainCategoryRequired: boolean): TableRow {
  const tableHeaderTitles: string[] = isDomainCategoryRequired
    ? ['Criteria', 'Barriers', '', 'Enablers', '', 'Strategies', '']
    : ['Criteria', 'Barriers', 'Enablers', 'Strategies'];

  const tableHeaderRowData: TableCell[] = [];
  tableHeaderTitles.forEach((headerTitle) => {
    const columnSpan: number = isDomainCategoryRequired
      ? headerTitle === 'Criteria'
        ? 1
        : 2
      : 1;

    if (!headerTitle.length) {
      return;
    }

    tableHeaderRowData.push(
      new TableCell({
        shading: {
          val: ShadingType.SOLID,
          fill: '#FFFFFF',
          color: '#333333',
        },
        width: {
          size: 25,
          type: WidthType.PERCENTAGE,
        },
        columnSpan,
        children: [
          new Paragraph({
            spacing: {
              before: 80,
              after: 80,
            },
            children: [
              new TextRun({
                text: headerTitle,
                bold: true,
                size: GripExportSize.secondary,
                color: '#FAFBFC',
                font: {
                  name: 'Open Sans',
                },
              }),
            ],
            alignment: AlignmentType.CENTER,
          }),
        ],
      }),
    );
  });

  return new TableRow({
    tableHeader: true,
    cantSplit: true,
    children: tableHeaderRowData,
  });
}

function getGripTableAnalysisTitle(
  analysisTitle: string,
  isDomainCategoryRequired: boolean,
): TableRow {
  const columnSpan: number = isDomainCategoryRequired ? 7 : 4;
  return new TableRow({
    tableHeader: true,
    cantSplit: true,
    children: [
      new TableCell({
        columnSpan,
        children: [
          new Paragraph({
            spacing: {
              before: 80,
              after: 80,
            },
            alignment: AlignmentType.CENTER,
            children: [
              new TextRun({
                text: analysisTitle.replace(/\s+/g, ' '),
                bold: true,
                size: GripExportSize.secondary,
                color: GripExportColors.gray,
                font: {
                  name: 'Open Sans',
                },
              }),
            ],
          }),
        ],
      }),
    ],
  });
}

function setJustification(text: string): Paragraph[] {
  return [
    new Paragraph({
      indent: {
        left: 120,
        right: 120,
      },
      spacing: {
        before: 80,
        after: 80,
      },
      children: [
        new TextRun({
          text: 'Not to be actioned.',
          bold: false,
          size: GripExportSize.secondary,
          color: GripExportColors.darkGray,
          font: {
            name: 'Open Sans',
          },
        }),
      ],
    }),
    new Paragraph({
      indent: {
        left: 120,
        right: 120,
      },
      spacing: {
        before: 80,
        after: 80,
      },
      children: [
        new TextRun({
          text: 'Justification: ',
          bold: true,
          size: GripExportSize.secondary,
          color: GripExportColors.darkGray,
          font: {
            name: 'Open Sans',
          },
        }),
        new TextRun({
          text,
          bold: false,
          size: GripExportSize.secondary,
          color: GripExportColors.darkGray,
          font: {
            name: 'Open Sans',
          },
        }),
      ],
    }),
  ];
}

function setTableNoDataRow(
  text: string,
  isDomainCategoryRequired: boolean,
): TableRow {
  const columnSpan: number = isDomainCategoryRequired ? 7 : 4;
  return new TableRow({
    tableHeader: true,
    cantSplit: true,
    children: [
      new TableCell({
        columnSpan,
        children: [
          new Paragraph({
            indent: {
              left: 120,
              right: 120,
            },
            spacing: {
              before: 80,
              after: 80,
            },
            alignment: AlignmentType.CENTER,
            children: [
              new TextRun({
                text,
                bold: true,
                size: GripExportSize.secondary,
                color: GripExportColors.darkGray,
                font: {
                  name: 'Open Sans',
                },
              }),
            ],
          }),
        ],
      }),
    ],
  });
}

function setTableCellData(content: any): TableCell {
  const tableData: Paragraph[] = [setColumnData(content)];
  if (content.justification) {
    tableData.push(...setJustification(content.justification));
  }
  const fillColor: string =
    content.style && content.style.fillColor
      ? content.style.fillColor
      : '#FFFFFF';

  const isDomainCategoryData: boolean =
    content.style && content.style.fillColor;

  return new TableCell({
    children: tableData,
    rowSpan: content.rowSpan ? content.rowSpan : 1,
    columnSpan: content.colSpan ? content.colSpan : 1,
    shading: {
      val: ShadingType.SOLID,
      fill: fillColor,
      color: fillColor,
    },
    textDirection: isDomainCategoryData
      ? TextDirection.BOTTOM_TO_TOP_LEFT_TO_RIGHT
      : TextDirection.LEFT_TO_RIGHT_TOP_TO_BOTTOM,
    verticalAlign: isDomainCategoryData
      ? VerticalAlign.CENTER
      : VerticalAlign.TOP,
    width: {
      size: 100,
      type: WidthType.DXA,
    },
  });
}

function setColumnData(content: any): Paragraph {
  const isDomainCategoryRequired: boolean =
    content.style && content.style.isDomainCategoryRequired;

  const color: string =
    content.style && content.style.color
      ? content.style.color
      : GripExportColors.darkGray;

  const alignment: AlignmentType =
    content.style && content.style.alignment === 'center'
      ? AlignmentType.CENTER
      : AlignmentType.START;

  return new Paragraph({
    indent: {
      left: isDomainCategoryRequired ? 0 : 120,
      right: isDomainCategoryRequired ? 0 : 120,
    },
    spacing: {
      before: isDomainCategoryRequired ? 240 : 120,
      after: isDomainCategoryRequired ? 240 : 120,
    },
    alignment,
    children: [
      new TextRun({
        text: content.title,
        bold: false,
        size: GripExportSize.secondary,
        color,
        font: {
          name: 'Open Sans',
        },
      }),
    ],
  });
}
