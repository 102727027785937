




















































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { cloneDeep as _cloneDeep, get as _get } from 'lodash';
import { CplusCriteria } from '@/store/modules/projects/types/projects.types';
import Card from '@/components/Card.vue';
import { RootState } from '@/store/store';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { ToastProgrammatic as Toast } from 'buefy';
import { AxiosError } from 'axios';
import { CRITERION_TYPE } from '@/store/types/criterions.types';

@Component({
  components: {
    Card,
  },
  data: () => {
    return {
      CRITERION_TYPE,
    };
  },
})
export default class CriteriaGuideModal extends Vue {
  @Prop()
  public currentCriteria!: CplusCriteria;
  @Prop()
  public criteriaIndex!: number;
  @Prop({ default: null })
  public projectId!: number;
  @Prop({ default: null })
  public auditId!: number;
  @Prop({ default: false })
  public createProject!: boolean;

  public criteriaGuide: string = '';
  public dirtyCurrentCriteria!: CplusCriteria;

  @Action('projects/updateProjectCriteriaGuide')
  public updateProjectCriteriaGuide!: (params: any) => void;

  @Action('audits/updateAuditCriteriaGuide')
  public updateAuditCriteriaGuide!: (params: any) => void;

  @State((state: RootState) => state.projects.apiState.getProjectCriteria)
  public getProjectCriteriaState!: any;

  @State((state: RootState) => state.audits.apiState.getAuditCriteria)
  public getAuditCriteriaState!: any;

  @Watch('getProjectCriteriaState.success')
  @isTruthy
  public onGetProjectCriteriaSuccess() {
    Toast.open({
      type: 'is-dark',
      position: 'is-top',
      message: `Changes saved`,
    });
    this.$emit('close');
  }

  @Watch('getProjectCriteriaState.error')
  @isTruthy
  public onGetProjectCriteriaError(error: AxiosError) {
    Toast.open({
      queue: true,
      type: 'is-danger',
      position: 'is-top',
      message: _get(error, 'response.data.message', 'Error'),
    });
    this.$emit('close');
  }

  @Watch('getAuditCriteriaState.success')
  @isTruthy
  public onGetAuditCriteriaSuccess() {
    Toast.open({
      type: 'is-dark',
      position: 'is-top',
      message: `Changes saved`,
    });
    this.$emit('close');
  }

  @Watch('getAuditCriteriaState.error')
  @isTruthy
  public onGetAuditCriteriaError(error: AxiosError) {
    Toast.open({
      queue: true,
      type: 'is-danger',
      position: 'is-top',
      message: _get(error, 'response.data.message', 'Error'),
    });
    this.$emit('close');
  }

  public mounted() {
    this.dirtyCurrentCriteria = _cloneDeep(this.currentCriteria);
    this.criteriaGuide = this.dirtyCurrentCriteria.guide;
  }

  public saveCriteriaGuide() {
    this.dirtyCurrentCriteria.guide = this.criteriaGuide;
    if (this.createProject) {
      this.$emit('updateCriteriaGuide', this.dirtyCurrentCriteria);
      this.$emit('close');
    } else {
      if (this.projectId) {
        this.updateProjectCriteriaGuide({
          projectId: this.projectId,
          criteriaId: this.dirtyCurrentCriteria.criteriaDetails.id,
          guide: this.criteriaGuide,
        });
      } else {
        this.updateAuditCriteriaGuide({
          auditId: this.auditId,
          criteriaId: this.dirtyCurrentCriteria.criteriaDetails.id,
          guide: this.criteriaGuide,
        });
      }
    }
  }

  public clearCriteriaGuide() {
    this.criteriaGuide = '';
  }

  public getTags(tagInfo: any) {
    if (Array.isArray(tagInfo)) {
      return tagInfo;
    } else {
      const tags: string[] = [];
      Object.keys(tagInfo).forEach((key: string) => {
        const tag = tagInfo[key];
        tag.forEach((value: string) => {
          tags.push(value);
        });
      });
      return tags;
    }
  }

  public closeModal() {
    this.$emit('close');
  }
}
