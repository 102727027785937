import { axios } from './axios';
import {
  DataCollectionSubmitRequestPayload,
  DataCollectionSummaryRequestPayload,
  MultiSiteSamplingRequestPayload,
  SingleSiteSamplingRequestPayload,
  UpdateAuditCriteriasRequestPayload,
  UpdateAuditGeneralSettingRequestPayload,
  UpdateAuditDataCollectionSettingRequestPayload,
  DataCollectionActivityLogRequestPayload,
  AuditSamplingRequestPayload,
  DashboardChartDataRequestPayload,
  AuditReportAnalysisPayload,
  UpdateAnalysisTitlePayload,
  DeleteAnalysisPayload,
  CreateAuditParticipantPayload,
  UpdateAuditParticipantPayload,
  PaginatedAuditEventLogsPayload,
  UpdateAuditStatusPayload,
  UpdateAuditGripPayload,
  UpdateAuditGripBarrierModalPayload,
  UpdateAuditGripEnablerModalPayload,
  UpdateAuditGripStrategyModalPayload,
  AuditGripBarrierModalPayload,
  AuditGripEnablerModalPayload,
  AuditGripStrategyModalPayload,
  ResumeEndDatePayload,
  DeleteAuditParticipantPayload,
} from '@/store/modules/audits/types/audits.types';
import {
  CplusCriteria,
  CriteriaPayload,
  JBICriteriaDTO,
} from '@/store/modules/projects/types/projects.types';
import { AxiosResponse } from 'axios';

export const auditsApi = {
  async getAuditDetail(id: number) {
    return await axios.get(`/audits/${id}`);
  },

  async updateSingleSiteSampling(
    auditId: number,
    payload: SingleSiteSamplingRequestPayload,
  ) {
    return axios.put(`/audits/${auditId}/sampling-config?type=singleSite`, {
      ...payload,
    });
  },

  async updateMultiSiteSampling(
    auditId: number,
    payload: MultiSiteSamplingRequestPayload,
  ) {
    return axios.put(`/audits/${auditId}/sampling-config?type=multiSite`, {
      ...payload,
    });
  },

  async getDataCollectionSummary(auditId: number, payload: any) {
    return axios.get(`/audits/${auditId}/data-collection`, {
      params: payload,
    });
  },

  async postDataCollection(payload: DataCollectionSubmitRequestPayload) {
    return axios.post(`/audits/${payload.auditId}/submitDataCollection`, {
      ...payload,
    });
  },

  async getDataCollectionActivityLogs(
    auditId: number,
    payload: DataCollectionActivityLogRequestPayload,
  ) {
    return axios.get(`/audits/${auditId}/data-collection-history`, {
      params: payload,
    });
  },

  async getDataCollectionUserActivityLogs(
    auditId: number,
    payload: DataCollectionActivityLogRequestPayload,
  ) {
    return axios.get(`/audits/${auditId}/user-data-collection-history`, {
      params: payload,
    });
  },

  async startAudit(id: number) {
    return axios.get(`/audits/${id}/startAudit`);
  },

  async endAudit(id: number) {
    return axios.put(`/audits/${id}/endAudit`);
  },

  async resumeAudit(auditId: number, payload: ResumeEndDatePayload) {
    return axios.put(`/audits/${auditId}/resumeAudit`, {
      ...payload,
    });
  },

  async updateCriteria(payload: CriteriaPayload) {
    return axios.put(`audits/criteria-update`, {
      ...payload,
    });
  },
  async updateAuditCriterias(
    auditId: number,
    payload: UpdateAuditCriteriasRequestPayload,
  ) {
    return axios.put(`audits/${auditId}/criteria`, {
      ...payload,
    });
  },

  async updateAuditCriteriaById(auditId: number, payload: CplusCriteria) {
    return axios.put(`audits/${auditId}/criteria/${payload.id}`, {
      ...payload,
    });
  },

  async getDataCollectionRecord(payload: {
    auditId: number;
    dataCollectionRecordId: number;
  }) {
    return axios.get(
      `/audits/${payload.auditId}/data-collection-records/${payload.dataCollectionRecordId}`,
    );
  },

  async deleteDataCollectionRecord(payload: {
    auditId: number;
    dataCollectionRecordId: number;
  }) {
    return axios.delete(
      `/audits/${payload.auditId}/data-collection-record/${payload.dataCollectionRecordId}`,
    );
  },

  async updateAuditGeneralSettings(
    auditId: number,
    payload: UpdateAuditGeneralSettingRequestPayload,
  ) {
    return axios.put(`audits/${auditId}/audit-settings`, {
      ...payload,
    });
  },

  async updateAuditDataCollectionSettings(
    auditId: number,
    payload: UpdateAuditDataCollectionSettingRequestPayload,
  ) {
    return axios.put(`audits/${auditId}/audit-data-collection-settings`, {
      ...payload,
    });
  },

  async updateDataCollectionStatus(auditId: number, payload: any) {
    return axios.put(`audits/audit-data-collection-status/${auditId}`, {
      ...payload,
    });
  },

  async getAuditDetailAndSettings(id: number) {
    return await axios.get(`/audits/${id}/audit-detail-and-settings`);
  },

  async getAuditCriteria(id: number, params?: { title: string }) {
    return axios.get(`/audits/${id}/criteria`, {
      params,
    });
  },

  async getAuditSites(auditId: number) {
    return axios.get(`/audits/${auditId}/sites`);
  },

  async getAuditSitesForDataCollection(auditId: number) {
    return axios.get(`/audits/${auditId}/sites`, {
      params: {
        is_data_collection: true,
      },
    });
  },

  async getSamplingConfig(
    auditId: number,
    payload: AuditSamplingRequestPayload,
  ) {
    return axios.get(`/audits/${auditId}/sampling-config`, {
      params: payload,
    });
  },

  async getAuditReportAnalysis(
    auditId: number,
    payload: AuditReportAnalysisPayload,
  ): Promise<AxiosResponse<any>> {
    return axios.get(`/audits/${auditId}/report-analysis`, {
      params: payload,
    });
  },

  async getAuditReportExport(
    auditId: number,
    payload: any,
  ): Promise<AxiosResponse<any>> {
    return axios.get(`/audits/${auditId}/audit-report-export`, {
      params: payload,
    });
  },

  async getTotalAuditReportAnalysis(
    auditId: number,
    projectId: number,
  ): Promise<AxiosResponse<any>> {
    return axios.get(`/audits/${auditId}/report-total-analysis`, {
      params: { projectId },
    });
  },

  async updateAuditReportAnalysisTitleById(
    payload: UpdateAnalysisTitlePayload,
  ): Promise<AxiosResponse<any>> {
    return axios.put(`/audits/rename-report-analysis-title`, {
      ...payload,
    });
  },

  async deleteAuditReportAnalysisById(
    payload: DeleteAnalysisPayload,
  ): Promise<AxiosResponse<any>> {
    return axios.put(`/audits/delete-report-analysis`, {
      ...payload,
    });
  },

  async getDashboardChartData(
    auditId: number,
    payload: DashboardChartDataRequestPayload,
  ) {
    return axios.get(`/audits/${auditId}/dashboard-chart-data`, {
      params: payload,
    });
  },

  async getParticipants(auditId: number) {
    return axios.get(`/audits/${auditId}/participants`);
  },
  async createParticipant({ auditId, ...body }: CreateAuditParticipantPayload) {
    return axios.post(`/audits/${auditId}/participants`, {
      ...body,
    });
  },
  async updateParticipant(payload: UpdateAuditParticipantPayload) {
    return axios.put(`/audits/${payload.auditId}/participants/${payload.id}`, {
      ...payload,
    });
  },
  async deleteParticipant(payload: DeleteAuditParticipantPayload) {
    return axios.delete(`/audits/${payload.auditId}/participant`, {
      data: { ...payload },
    });
  },
  async resendInvitation(payload: {
    auditId: number;
    invitationId: number;
    auditParticipantId: number;
  }) {
    return axios.put(
      `/audits/${payload.auditId}/audit-participant-invitations/resend`,
      {
        ...payload,
      },
    );
  },
  async getPaginatedEventLogs(params: PaginatedAuditEventLogsPayload) {
    return axios.get('/event-logs/audit-logs', { params });
  },
  async updateStatus(id: number, params: UpdateAuditStatusPayload) {
    return axios.put(`/audits/${id}/status`, {
      ...params,
    });
  },

  async getProjectReportExport(auditId: number, payload: any) {
    return axios.get(`/projects/${auditId}/audit-grip-export`, {
      params: payload,
    });
  },

  async updateAuditGripByAuditId(
    auditId: number,
    payload: UpdateAuditGripPayload,
  ) {
    return axios.put(`audits/${auditId}/audit-level-grip/grip`, {
      ...payload,
    });
  },

  async getAuditGripByAuditId(id: number) {
    return axios.get(`/audits/${id}/audit-level-grip/grip`);
  },

  async createAuditGripBarrierModal(payload: AuditGripBarrierModalPayload) {
    return axios.post(`audits/create-audit-grip-barrier`, {
      ...payload,
    });
  },

  async updateAuditGripBarrierModal(
    auditId: number,
    payload: UpdateAuditGripBarrierModalPayload,
  ) {
    return axios.put(`audits/${auditId}/grip/barrier`, {
      ...payload,
    });
  },

  async getAuditGripBarrierModal(auditId: number) {
    return axios.get(`/audits/${auditId}/grip/barrier`);
  },

  async deleteGripBarrierRecord(payload: {
    auditId: number;
    gripBarrierId: number;
  }) {
    return axios.delete(
      `/audits/${payload.auditId}/grip-barrier-record/${payload.gripBarrierId}`,
    );
  },

  async createAuditGripEnablerModal(payload: AuditGripEnablerModalPayload) {
    return axios.post(`audits/create-audit-grip-enabler`, {
      ...payload,
    });
  },

  async updateAuditGripEnablerModal(
    auditId: number,
    payload: UpdateAuditGripEnablerModalPayload,
  ) {
    return axios.put(`audits/${auditId}/grip/enabler`, {
      ...payload,
    });
  },

  async getAuditGripEnablerModal(id: number) {
    return axios.get(`/audits/${id}/grip/enabler`);
  },

  async deleteGripEnablerRecord(payload: {
    auditId: number;
    gripEnablerId: number;
  }) {
    return axios.delete(
      `/audits/${payload.auditId}/grip-enabler-record/${payload.gripEnablerId}`,
    );
  },

  async createAuditGripStrategyModal(payload: AuditGripStrategyModalPayload) {
    return axios.post(`audits/create-audit-grip-strategy`, {
      ...payload,
    });
  },

  async updateAuditGripStrategyModal(
    auditId: number,
    payload: UpdateAuditGripStrategyModalPayload,
  ) {
    return axios.put(`audits/${auditId}/grip/strategy`, {
      ...payload,
    });
  },

  async getAuditGripStrategyModal(id: number) {
    return axios.get(`/audits/${id}/grip/strategy`);
  },

  async deleteGripStrategyRecord(payload: {
    auditId: number;
    gripStrategyId: number;
  }) {
    return axios.delete(
      `/audits/${payload.auditId}/grip-strategy-record/${payload.gripStrategyId}`,
    );
  },

  async updateAuditCriteriaGuide(payload: any) {
    return axios.put(`/audits/${payload.auditId}/update-audit-criteria-guide`, {
      ...payload,
    });
  },

  getUpdatedAuditJBICriteriaList(
    auditId: number,
  ): Promise<AxiosResponse<JBICriteriaDTO[]>> {
    return axios.get(`/audits/${auditId}/updated-jbi-criteria-list`);
  },
};
