import { defaultApiState, Pagination } from '@/store/types/general.types';
import {
  ProjectsState,
  CplusCriteriaResults,
  CreateProjectResponsePayload,
  ProjectDetailPayload,
  Site,
  AcceptInvitationResponsePayload,
  CriteriaResponsePayload,
  Participant,
  ProjectSettingResponsePayload,
  DashboardChartData,
  ProjectCriteria,
  ProjectSite,
  PaginatedProjectEventLogsResponse,
  ProjectAuditDataCollectionRecordsPayload,
  DocumentByPublication,
  ValidUserPayload,
  ExistingProjectOwnerRequestPayload,
  ProjectOwnerHistoryPayload,
  AuditGripTypePayload,
  ProjectListPagePayload,
  ProjectDataCollectionRecordExportPayload,
  ProjectGrip,
  ProjectGripDetails,
  ProjectGripBarrier,
  ProjectGripEnabler,
  ProjectGripStrategy,
  ProjectReportAnalysis,
  UpdatedJBICriteriaAcknowledgement,
  JBICriteriaDTO,
  AdministratorProjectListDTO,
  ProjectParticipantDTO,
} from './types/projects.types';
import { AxiosError } from 'axios';
import { AuditDetail } from '../audits/types/audits.types';
import {
  Grip,
  GripAnalysis,
  GripAnalysisBarrier,
  GripAnalysisEnabler,
  GripAnalysisStrategy,
  GripBarrier,
  GripDomainCategory,
  GripDomainCategoryDetails,
  GripEnabler,
  GripExportReportResponse,
  GripNotificationResponse,
  GripStrategy,
} from '../../types/grip.types';

export const projectsMutations = {
  /*
   ** getCPlusCriterias
   */
  GET_CPLUS_CRITERIAS_LOADING(state: ProjectsState) {
    state.cplusCriterias = undefined;
    state.apiState.getCplusCriterias = {
      ...defaultApiState,
      loading: true,
    };
  },
  GET_CPLUS_CRITERIAS_SUCCESS(
    state: ProjectsState,
    payload: CplusCriteriaResults,
  ) {
    state.cplusCriterias = payload;
    state.apiState.getCplusCriterias = {
      ...defaultApiState,
      success: true,
    };
  },
  GET_CPLUS_CRITERIAS_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.getCplusCriterias = {
      ...defaultApiState,
      error,
    };
  },

  /*
   ** create project
   */
  CREATE_PROJECT_LOADING(state: ProjectsState) {
    state.apiState.createProject = {
      ...defaultApiState,
      loading: true,
    };
    state.createdProject = undefined;
  },
  CREATE_PROJECT_SUCCESS(
    state: ProjectsState,
    payload: CreateProjectResponsePayload,
  ) {
    state.apiState.createProject = {
      ...defaultApiState,
      success: true,
    };
    state.createdProject = payload;
  },
  CREATE_PROJECT_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.createProject = {
      ...defaultApiState,
      error,
    };
  },

  /* // TODO: Remove me, not in use.
   ** get user projects
   */
  GET_MY_PROJECTS_LOADING(state: ProjectsState) {
    state.apiState.getMyProjects = {
      ...defaultApiState,
      loading: true,
    };
    state.projects = undefined;
  },
  GET_MY_PROJECTS_SUCCESS(
    state: ProjectsState,
    payload: ProjectDetailPayload[],
  ) {
    state.apiState.getMyProjects = {
      ...defaultApiState,
      success: true,
    };
    state.projects = payload;
  },
  GET_MY_PROJECTS_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.getMyProjects = {
      ...defaultApiState,
      error,
    };
  },

  /*
   ** get user active projects
   */
  GET_ACTIVE_PROJECTS_LOADING(state: ProjectsState) {
    state.apiState.getActiveProjects = {
      ...defaultApiState,
      loading: true,
    };
    state.activeListProject = undefined;
  },
  GET_ACTIVE_PROJECTS_SUCCESS(
    state: ProjectsState,
    payload: ProjectListPagePayload[],
  ) {
    state.apiState.getActiveProjects = {
      ...defaultApiState,
      success: true,
    };
    state.activeListProject = payload;
  },
  GET_ACTIVE_PROJECTS_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.getActiveProjects = {
      ...defaultApiState,
      error,
    };
  },

  /*
   ** get user created projects
   */
  GET_CREATED_PROJECTS_LOADING(state: ProjectsState) {
    state.apiState.getCreatedProjects = {
      ...defaultApiState,
      loading: true,
    };
    state.createdListProject = undefined;
  },
  GET_CREATED_PROJECTS_SUCCESS(
    state: ProjectsState,
    payload: ProjectListPagePayload[],
  ) {
    state.apiState.getCreatedProjects = {
      ...defaultApiState,
      success: true,
    };
    state.createdListProject = payload;
  },
  GET_CREATED_PROJECTS_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.getCreatedProjects = {
      ...defaultApiState,
      error,
    };
  },

  /*
   ** get user draft projects
   */
  GET_DRAFT_PROJECTS_LOADING(state: ProjectsState) {
    state.apiState.getDraftProjects = {
      ...defaultApiState,
      loading: true,
    };
    state.draftListProject = undefined;
  },
  GET_DRAFT_PROJECTS_SUCCESS(
    state: ProjectsState,
    payload: ProjectListPagePayload[],
  ) {
    state.apiState.getDraftProjects = {
      ...defaultApiState,
      success: true,
    };
    state.draftListProject = payload;
  },
  GET_DRAFT_PROJECTS_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.getDraftProjects = {
      ...defaultApiState,
      error,
    };
  },

  /*
   ** get user archive projects
   */
  GET_ARCHIVE_PROJECTS_LOADING(state: ProjectsState) {
    state.apiState.getArchiveProjects = {
      ...defaultApiState,
      loading: true,
    };
    state.archiveListProject = undefined;
  },
  GET_ARCHIVE_PROJECTS_SUCCESS(
    state: ProjectsState,
    payload: ProjectListPagePayload[],
  ) {
    state.apiState.getArchiveProjects = {
      ...defaultApiState,
      success: true,
    };
    state.archiveListProject = payload;
  },
  GET_ARCHIVE_PROJECTS_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.getArchiveProjects = {
      ...defaultApiState,
      error,
    };
  },

  /*
   ** get user project
   */
  GET_PROJECT_LOADING(state: ProjectsState) {
    state.apiState.getProjectDetail = {
      ...defaultApiState,
      loading: true,
    };
    state.projectDetail = undefined;
  },
  GET_PROJECT_SUCCESS(state: ProjectsState, payload: ProjectDetailPayload) {
    state.apiState.getProjectDetail = {
      ...defaultApiState,
      success: true,
    };
    state.projectDetail = payload;
  },
  GET_PROJECT_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.getProjectDetail = {
      ...defaultApiState,
      error,
    };
  },

  /*
   ** update project status
   */
  UPDATE_PROJECT_STATUS_LOADING(state: ProjectsState) {
    state.apiState.updateProjectStatus = {
      ...defaultApiState,
      loading: true,
    };
    state.updatedProjectStatus = undefined;
  },
  UPDATE_PROJECT_STATUS_SUCCESS(state: ProjectsState, payload: any) {
    state.apiState.updateProjectStatus = {
      ...defaultApiState,
      success: true,
    };
    state.updatedProjectStatus = payload;
  },
  UPDATE_PROJECT_STATUS_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.updateProjectStatus = {
      ...defaultApiState,
      error,
    };
  },

  /*
   ** get site listing
   */
  GET_SITES_LOADING(state: ProjectsState) {
    state.apiState.getSites = {
      ...defaultApiState,
      loading: true,
    };
    state.sites = undefined;
  },
  GET_SITES_SUCCESS(state: ProjectsState, payload: Site[]) {
    state.apiState.getSites = {
      ...defaultApiState,
      success: true,
    };
    state.sites = payload;
  },
  GET_SITES_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.getSites = {
      ...defaultApiState,
      error,
    };
  },
  ADD_NEW_SITE(state: ProjectsState, payload: Site) {
    if (state.sites) {
      state.sites.push(payload);
    }
  },

  CREATE_SITE_LOADING(state: ProjectsState) {
    state.apiState.createSite = {
      ...defaultApiState,
      loading: true,
    };
    state.createdSite = undefined;
  },
  CREATE_SITE_SUCCESS(state: ProjectsState, payload: Site) {
    state.apiState.createSite = {
      ...defaultApiState,
      success: true,
    };
    state.createdSite = payload;
  },
  CREATE_SITE_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.createSite = {
      ...defaultApiState,
      error,
    };
  },

  /*
   ** acceptInvitation
   */
  ACCEPT_INVITATION_LOADING(state: ProjectsState) {
    state.apiState.acceptInvitation = {
      ...defaultApiState,
      loading: true,
    };
  },
  ACCEPT_INVITATION_SUCCESS(
    state: ProjectsState,
    payload: AcceptInvitationResponsePayload,
  ) {
    state.apiState.acceptInvitation = {
      ...defaultApiState,
      success: true,
    };
    state.acceptedInvitedProject = payload;
  },
  ACCEPT_INVITATION_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.acceptInvitation = {
      ...defaultApiState,
      error,
    };
  },

  /*
   ** Update Criteria
   */
  UPDATE_CRITEIA_LOADING(state: ProjectsState) {
    state.apiState.updateCriteria = {
      ...defaultApiState,
      loading: true,
    };
  },
  UPDATE_CRITEIA_SUCCESS(
    state: ProjectsState,
    payload: CriteriaResponsePayload,
  ) {
    state.apiState.updateCriteria = {
      ...defaultApiState,
      success: true,
    };
    state.updatedCriteria = payload;
  },
  UPDATE_CRITEIA_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.updateCriteria = {
      ...defaultApiState,
      error,
    };
  },

  /*
   ** Update Project Criterias
   */
  UPDATE_PROJECT_CRITEIAS_LOADING(state: ProjectsState) {
    state.apiState.updateProjectCriterias = {
      ...defaultApiState,
      loading: true,
    };
  },
  UPDATE_PROJECT_CRITEIAS_SUCCESS(
    state: ProjectsState,
    payload: CriteriaResponsePayload[],
  ) {
    state.apiState.updateProjectCriterias = {
      ...defaultApiState,
      success: true,
    };
    state.updatedProjectCriterias = payload;
  },
  UPDATE_PROJECT_CRITEIAS_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.updateProjectCriterias = {
      ...defaultApiState,
      error,
    };
  },

  /*
   ** Update Project Sites
   */
  UPDATE_PROJECT_SITES_LOADING(state: ProjectsState) {
    state.apiState.updateProjectSites = {
      ...defaultApiState,
      loading: true,
    };
  },
  UPDATE_PROJECT_SITES_SUCCESS(state: ProjectsState, payload: Site[]) {
    state.apiState.updateProjectSites = {
      ...defaultApiState,
      success: true,
    };
    state.updatedProjectSites = payload;
  },
  UPDATE_PROJECT_SITES_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.updateProjectSites = {
      ...defaultApiState,
      error,
    };
  },
  UPDATE_NEW_PROJECT_SITES_SUCCESS(state: ProjectsState, payload: any) {
    if (state.projectDetail) {
      state.projectDetail.projectSites = payload;
    }
  },

  /*
   ** Update Project Participants
   */
  UPDATE_PROJECT_PARTICIPANTS_LOADING(state: ProjectsState) {
    state.apiState.updateProjectParticipants = {
      ...defaultApiState,
      loading: true,
    };
  },
  UPDATE_PROJECT_PARTICIPANTS_SUCCESS(
    state: ProjectsState,
    payload: Participant[],
  ) {
    state.apiState.updateProjectParticipants = {
      ...defaultApiState,
      success: true,
    };
    state.updatedProjectParticipants = payload;
  },
  UPDATE_PROJECT_PARTICIPANTS_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.updateProjectParticipants = {
      ...defaultApiState,
      error,
    };
  },

  /*
   ** Update Project Settings
   */
  UPDATE_PROJECT_SETTING_LOADING(state: ProjectsState) {
    state.apiState.updateProjectSetting = {
      ...defaultApiState,
      loading: true,
    };
  },
  UPDATE_PROJECT_SETTING_SUCCESS(
    state: ProjectsState,
    payload: ProjectSettingResponsePayload,
  ) {
    state.apiState.updateProjectSetting = {
      ...defaultApiState,
      success: true,
    };
    state.updatedProjectSetting = payload;
  },
  UPDATE_PROJECT_SETTING_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.updateProjectSetting = {
      ...defaultApiState,
      error,
    };
  },

  /*
   ** Update Project General Settings
   */
  UPDATE_PROJECT_GENERAL_SETTING_LOADING(state: ProjectsState) {
    state.apiState.updateProjectGeneralSettings = {
      ...defaultApiState,
      loading: true,
    };
  },
  UPDATE_PROJECT_GENERAL_SETTING_SUCCESS(state: ProjectsState, payload: any) {
    state.apiState.updateProjectGeneralSettings = {
      ...defaultApiState,
      success: true,
    };
    state.updatedProjectGeneralSettings = payload;
  },
  UPDATE_PROJECT_GENERAL_SETTING_ERROR(
    state: ProjectsState,
    error: AxiosError,
  ) {
    state.apiState.updateProjectGeneralSettings = {
      ...defaultApiState,
      error,
    };
  },

  /*
   ** Add New Site
   */
  ADD_NEW_AUDIT_LOADING(state: ProjectsState) {
    state.apiState.addNewAudit = {
      ...defaultApiState,
      loading: true,
    };
    state.addedNewAudit = undefined;
  },
  ADD_NEW_AUDIT_SUCCESS(state: ProjectsState, payload: any[]) {
    state.apiState.addNewAudit = {
      ...defaultApiState,
      success: true,
    };
    state.addedNewAudit = payload;
  },
  ADD_NEW_AUDIT_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.addNewAudit = {
      ...defaultApiState,
      error,
    };
  },
  GET_PROJECT_PARTICIPANT_LOADING(state: ProjectsState) {
    state.apiState.getProjectParticipants = {
      ...defaultApiState,
      loading: true,
    };
  },
  GET_PROJECT_PARTICIPANT_SUCCESS(
    state: ProjectsState,
    payload: ProjectParticipantDTO[],
  ) {
    state.apiState.getProjectParticipants = {
      ...defaultApiState,
      success: true,
    };
    state.participants = payload;
  },
  GET_PROJECT_PARTICIPANT_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.getProjectParticipants = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * get Dashboard Chart Data
   * @param state
   * @constructor
   */
  GET_DASHBOARD_CHART_DATA_LOADING(state: ProjectsState) {
    state.apiState.getDashboardChartData = {
      ...defaultApiState,
      loading: true,
    };
    state.dashboardChartData = undefined;
  },
  GET_DASHBOARD_CHART_DATA_SUCCESS(
    state: ProjectsState,
    payload: DashboardChartData[],
  ) {
    state.apiState.getDashboardChartData = {
      ...defaultApiState,
      success: true,
    };
    state.dashboardChartData = payload;
  },
  GET_DASHBOARD_CHART_DATA_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.getDashboardChartData = {
      ...defaultApiState,
      error,
    };
  },
  /*
   ** get user projects summary for dashboard
   */
  GET_MY_PROJECTS_SUMMARY_LOADING(state: ProjectsState) {
    state.apiState.getMyProjectsSummary = {
      ...defaultApiState,
      loading: true,
    };
    state.myProjectsSummary = undefined;
  },
  GET_MY_PROJECTS_SUMMARY_SUCCESS(
    state: ProjectsState,
    payload: ProjectDetailPayload[],
  ) {
    state.apiState.getMyProjectsSummary = {
      ...defaultApiState,
      success: true,
    };
    state.myProjectsSummary = payload;
  },
  GET_MY_PROJECTS_SUMMARY_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.getMyProjectsSummary = { ...defaultApiState, error };
  },
  CREATE_PROJECT_PARTICIPANT_LOADING(state: ProjectsState) {
    state.apiState.createProjectParticipant = {
      ...defaultApiState,
      loading: true,
    };
  },
  CREATE_PROJECT_PARTICIPANT_SUCCESS(state: ProjectsState) {
    state.apiState.createProjectParticipant = {
      ...defaultApiState,
      success: true,
    };
  },
  CREATE_PROJECT_PARTICIPANT_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.createProjectParticipant = {
      ...defaultApiState,
      error,
    };
  },

  UPDATE_PROJECT_PARTICIPANT_LOADING(state: ProjectsState) {
    state.apiState.updateProjectParticipant = {
      ...defaultApiState,
      loading: true,
    };
  },
  UPDATE_PROJECT_PARTICIPANT_SUCCESS(state: ProjectsState) {
    state.apiState.updateProjectParticipant = {
      ...defaultApiState,
      success: true,
    };
  },
  UPDATE_PROJECT_PARTICIPANT_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.updateProjectParticipant = {
      ...defaultApiState,
      error,
    };
  },

  DELETE_PROJECT_PARTICIPANT_LOADING(state: ProjectsState) {
    state.apiState.deleteProjectParticipant = {
      ...defaultApiState,
      loading: true,
    };
  },
  DELETE_PROJECT_PARTICIPANT_SUCCESS(state: ProjectsState) {
    state.apiState.deleteProjectParticipant = {
      ...defaultApiState,
      success: true,
    };
  },
  DELETE_PROJECT_PARTICIPANT_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.deleteProjectParticipant = {
      ...defaultApiState,
      error,
    };
  },
  /**
   * get project criteria list
   * @param state
   * @constructor
   */
  GET_PROJECT_CRITERIA_LOADING(state: ProjectsState) {
    state.apiState.getProjectCriteria = {
      ...defaultApiState,
      loading: true,
    };
    state.projectCriteria = undefined;
  },
  GET_PROJECT_CRITERIA_SUCCESS(
    state: ProjectsState,
    payload: ProjectCriteria[],
  ) {
    state.apiState.getProjectCriteria = {
      ...defaultApiState,
      success: true,
    };
    state.projectCriteria = payload;
  },
  GET_PROJECT_CRITERIA_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.getProjectCriteria = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * get project sites list
   * @param state
   * @constructor
   */
  GET_PROJECT_SITES_LOADING(state: ProjectsState) {
    state.apiState.getProjectSites = {
      ...defaultApiState,
      loading: true,
    };
    state.projectSites = undefined;
  },
  GET_PROJECT_SITES_SUCCESS(state: ProjectsState, payload: ProjectSite[]) {
    state.apiState.getProjectSites = {
      ...defaultApiState,
      success: true,
    };
    state.projectSites = payload;
  },
  GET_AUDIT_SITES_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.getProjectSites = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * Get Data Collection
   * @param state
   * @constructor
   */
  GET_DATA_COLLECTION_ACTIVITY_LOGS_LOADING(state: ProjectsState) {
    state.apiState.getDataCollectionActivityLogs = {
      ...defaultApiState,
      loading: true,
    };
    state.dataCollectionActivityLogs = undefined;
  },
  GET_DATA_COLLECTION_ACTIVITY_LOGS_SUCCESS(
    state: ProjectsState,
    payload: any[],
  ) {
    state.apiState.getDataCollectionActivityLogs = {
      ...defaultApiState,
      success: true,
    };
    state.dataCollectionActivityLogs = payload;
  },
  GET_DATA_COLLECTION_ACTIVITY_LOGS_ERROR(
    state: ProjectsState,
    error: AxiosError,
  ) {
    state.apiState.getDataCollectionActivityLogs = {
      ...defaultApiState,
      error,
    };
  },
  /**
   * get project participants list
   * @param state
   * @constructor
   */
  GET_PROJECT_PARTICIPANTS_LOADING(state: ProjectsState) {
    state.apiState.getProjectParticipants = {
      ...defaultApiState,
      loading: true,
    };
    state.projectParticipants = undefined;
  },
  GET_PROJECT_PARTICIPANTS_SUCCESS(
    state: ProjectsState,
    payload: ProjectParticipantDTO[],
  ) {
    state.apiState.getProjectParticipants = {
      ...defaultApiState,
      success: true,
    };
    state.projectParticipants = payload;
  },
  GET_PROJECT_PARTICIPANTS_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.getProjectParticipants = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * Get Recent Event Logs
   * @param state
   * @constructor
   */
  GET_RECENT_EVENT_LOGS_LOADING(state: ProjectsState) {
    state.apiState.getRecentEventLogs = {
      ...defaultApiState,
      loading: true,
    };
    state.recentEventLogs = undefined;
  },
  GET_RECENT_EVENT_LOGS_SUCCESS(state: ProjectsState, payload: any[]) {
    state.apiState.getRecentEventLogs = {
      ...defaultApiState,
      success: true,
    };
    state.recentEventLogs = payload;
  },
  GET_RECENT_EVENT_LOGS_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.getRecentEventLogs = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * get project audit list
   * @param state
   * @constructor
   */
  GET_PROJECT_AUDIT_LIST_LOADING(state: ProjectsState) {
    state.apiState.getProjectAuditList = {
      ...defaultApiState,
      loading: true,
    };
    state.projectAuditList = undefined;
  },
  GET_PROJECT_AUDIT_LIST_SUCCESS(state: ProjectsState, payload: AuditDetail[]) {
    state.apiState.getProjectAuditList = {
      ...defaultApiState,
      success: true,
    };
    state.projectAuditList = payload;
  },
  GET_PROJECT_AUDIT_LIST_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.getProjectAuditList = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * get project paginated audit list
   * @param state
   * @constructor
   */
  GET_PAGINATED_EVENT_LOGS_LOADING(state: ProjectsState) {
    state.apiState.getPaginatedEventLogs = {
      ...defaultApiState,
      loading: true,
    };
    state.paginatedEventLogs = undefined;
  },
  GET_PAGINATED_EVENT_LOGS_SUCCESS(
    state: ProjectsState,
    payload: PaginatedProjectEventLogsResponse,
  ) {
    state.apiState.getPaginatedEventLogs = {
      ...defaultApiState,
      success: true,
    };
    state.paginatedEventLogs = payload;
  },
  GET_PAGINATED_EVENT_LOGS_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.getPaginatedEventLogs = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * get project audit data collection records
   * @param state
   * @constructor
   */
  GET_PROJECT_AUDIT_LOADING(state: ProjectsState) {
    state.apiState.getProjectAuditDataCollectionRecords = {
      ...defaultApiState,
      loading: true,
    };
    state.projectAuditDataCollectionRecords = undefined;
  },
  GET_PROJECT_AUDIT_SUCCESS(
    state: ProjectsState,
    payload: ProjectAuditDataCollectionRecordsPayload,
  ) {
    state.apiState.getProjectAuditDataCollectionRecords = {
      ...defaultApiState,
      success: true,
    };
    state.projectAuditDataCollectionRecords = payload;
  },
  GET_PROJECT_AUDIT_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.getProjectAuditDataCollectionRecords = {
      ...defaultApiState,
      error,
    };
  },

  /*
   ** get Document By Publication
   */
  GET_DOCUMENT_BY_PUBLICATION_LOADING(state: ProjectsState) {
    state.documentByPublication = undefined;
    state.apiState.getDocumentByPublication = {
      ...defaultApiState,
      loading: true,
    };
  },
  GET_DOCUMENT_BY_PUBLICATION_SUCCESS(
    state: ProjectsState,
    payload: DocumentByPublication,
  ) {
    state.documentByPublication = payload;
    state.apiState.getDocumentByPublication = {
      ...defaultApiState,
      success: true,
    };
  },
  GET_DOCUMENT_BY_PUBLICATION_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.getDocumentByPublication = {
      ...defaultApiState,
      error,
    };
  },

  /*  TODO: remove this, not in use
   ** Update Jbi Criteria
   */
  UPDATE_JBI_CRITERIA_LOADING(state: ProjectsState) {
    state.updatedJbiCriteria = undefined;
    state.apiState.updateJBICriteria = {
      ...defaultApiState,
      loading: true,
    };
  },
  UPDATE_JBI_CRITERIA_SUCCESS(state: ProjectsState, payload: any) {
    state.updatedJbiCriteria = payload;
    state.apiState.updateJBICriteria = {
      ...defaultApiState,
      success: true,
    };
  },
  UPDATE_JBI_CRITERIA_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.updateJBICriteria = {
      ...defaultApiState,
      error,
    };
  },

  /*
   ** get audit grip type
   */
  GET_AUDIT_GRIP_TYPE_LOADING(state: ProjectsState) {
    state.apiState.getAuditGripType = {
      ...defaultApiState,
      loading: true,
    };
    state.auditGripType = undefined;
  },
  GET_AUDIT_GRIP_TYPE_SUCCESS(
    state: ProjectsState,
    payload: AuditGripTypePayload[],
  ) {
    state.apiState.getAuditGripType = {
      ...defaultApiState,
      success: true,
    };
    state.auditGripType = payload;
  },
  GET_AUDIT_GRIP_TYPE_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.getAuditGripType = {
      ...defaultApiState,
      error,
    };
  },

  /*
   ** create audit grip type
   */
  CREATE_AUDIT_GRIP_TYPE_LOADING(state: ProjectsState) {
    state.apiState.createAuditGripType = {
      ...defaultApiState,
      loading: true,
    };
    state.createdAuditGripType = undefined;
  },
  CREATE_AUDIT_GRIP_TYPE_SUCCESS(
    state: ProjectsState,
    payload: AuditGripTypePayload,
  ) {
    state.apiState.createAuditGripType = {
      ...defaultApiState,
      success: true,
    };
    state.createdAuditGripType = payload;
  },
  CREATE_AUDIT_GRIP_TYPE_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.createAuditGripType = {
      ...defaultApiState,
      error,
    };
  },

  /*
   ** update audit grip type
   */
  UPDATE_AUDIT_GRIP_TYPE_LOADING(state: ProjectsState) {
    state.apiState.updateAuditGripType = {
      ...defaultApiState,
      loading: true,
    };
    state.updatedAuditGripType = undefined;
  },
  UPDATE_AUDIT_GRIP_TYPE_SUCCESS(
    state: ProjectsState,
    payload: AuditGripTypePayload,
  ) {
    state.apiState.updateAuditGripType = {
      ...defaultApiState,
      success: true,
    };
    state.updatedAuditGripType = payload;
  },
  UPDATE_AUDIT_GRIP_TYPE_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.updateAuditGripType = {
      ...defaultApiState,
      error,
    };
  },

  /*
   ** get audit grip type
   */
  GET_GRIP_TYPE_DETAIL_LOADING(state: ProjectsState) {
    state.apiState.getAuditGripType = {
      ...defaultApiState,
      loading: true,
    };
    state.auditGripTypeDetail = undefined;
  },
  GET_GRIP_TYPE_DETAIL_SUCCESS(
    state: ProjectsState,
    payload: AuditGripTypePayload,
  ) {
    state.apiState.getAuditGripType = {
      ...defaultApiState,
      success: true,
    };
    state.auditGripTypeDetail = payload;
  },
  GET_GRIP_TYPE_DETAIL_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.getAuditGripType = {
      ...defaultApiState,
      error,
    };
  },

  /*
   ** delete audit grip type
   */
  DELETE_AUDIT_GRIP_TYPE_LOADING(state: ProjectsState) {
    state.apiState.deleteAuditGripType = {
      ...defaultApiState,
      loading: true,
    };
    state.deletedAuditGripType = undefined;
  },
  DELETE_AUDIT_GRIP_TYPE_SUCCESS(
    state: ProjectsState,
    payload: AuditGripTypePayload,
  ) {
    state.apiState.deleteAuditGripType = {
      ...defaultApiState,
      success: true,
    };
    state.deletedAuditGripType = payload;
  },
  DELETE_AUDIT_GRIP_TYPE_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.deleteAuditGripType = {
      ...defaultApiState,
      error,
    };
  },

  /*
   ** get data collection record
   */
  GET_PROJECT_DATA_COLLECTION_RECORD_EXPORT_LOADING(state: ProjectsState) {
    state.apiState.getProjectDataCollectionRecordExport = {
      ...defaultApiState,
      loading: true,
    };
    state.projectDataCollectionRecordExport = undefined;
  },
  GET_PROJECT_DATA_COLLECTION_RECORD_EXPORT_SUCCESS(
    state: ProjectsState,
    payload: ProjectDataCollectionRecordExportPayload,
  ) {
    state.apiState.getProjectDataCollectionRecordExport = {
      ...defaultApiState,
      success: true,
    };
    state.projectDataCollectionRecordExport = payload;
  },
  GET_PROJECT_DATA_COLLECTION_RECORD_EXPORT_ERROR(
    state: ProjectsState,
    error: AxiosError,
  ) {
    state.apiState.getProjectDataCollectionRecordExport = {
      ...defaultApiState,
      error,
    };
  },

  /*
   ** get report export data
   */
  GET_PROJECT_REPORT_EXPORT_LOADING(state: ProjectsState): void {
    state.apiState.getProjectReportExport = {
      ...defaultApiState,
      loading: true,
    };
    state.projectReportExport = undefined;
  },
  GET_PROJECT_REPORT_EXPORT_SUCCESS(state: ProjectsState, payload: any): void {
    state.apiState.getProjectReportExport = {
      ...defaultApiState,
      success: true,
    };
    state.projectReportExport = payload;
  },
  GET_PROJECT_REPORT_EXPORT_ERROR(
    state: ProjectsState,
    error: AxiosError,
  ): void {
    state.apiState.getProjectReportExport = {
      ...defaultApiState,
      error,
    };
  },

  /*
   ** get project report analysis
   */
  GET_PROJECT_REPORT_ANALYSIS_LOADING(state: ProjectsState): void {
    state.apiState.getProjectReportAnalysis = {
      ...defaultApiState,
      loading: true,
    };
    state.projectReportAnalysis = undefined;
  },
  GET_PROJECT_REPORT_ANALYSIS_SUCCESS(
    state: ProjectsState,
    payload: any,
  ): void {
    state.apiState.getProjectReportAnalysis = {
      ...defaultApiState,
      success: true,
    };
    state.projectReportAnalysis = payload;
  },
  GET_PROJECT_REPORT_ANALYSIS_ERROR(
    state: ProjectsState,
    error: AxiosError,
  ): void {
    state.apiState.getProjectReportAnalysis = {
      ...defaultApiState,
      error,
    };
  },

  /*
   ** get total project report analysis
   */
  GET_TOTAL_PROJECT_REPORT_ANALYSIS_LOADING(state: ProjectsState): void {
    state.apiState.getTotalProjectReportAnalysis = {
      ...defaultApiState,
      loading: true,
    };
    state.totalProjectReportAnalysis = undefined;
  },
  GET_TOTAL_PROJECT_REPORT_ANALYSIS_SUCCESS(
    state: ProjectsState,
    payload: ProjectReportAnalysis[],
  ): void {
    state.apiState.getTotalProjectReportAnalysis = {
      ...defaultApiState,
      success: true,
    };
    state.totalProjectReportAnalysis = payload;
  },
  GET_TOTAL_PROJECT_REPORT_ANALYSIS_ERROR(
    state: ProjectsState,
    error: AxiosError,
  ): void {
    state.apiState.getTotalProjectReportAnalysis = {
      ...defaultApiState,
      error,
    };
  },

  /*
   ** update project report analysis
   */
  UPDATE_PROJECT_REPORT_ANALYSIS_LOADING(state: ProjectsState): void {
    state.apiState.updateProjectReportAnalysis = {
      ...defaultApiState,
      loading: true,
    };
    state.updatedProjectReportAnalysis = undefined;
  },
  UPDATE_PROJECT_REPORT_ANALYSIS_SUCCESS(
    state: ProjectsState,
    payload: any,
  ): void {
    state.apiState.updateProjectReportAnalysis = {
      ...defaultApiState,
      success: true,
    };
    state.updatedProjectReportAnalysis = payload;
  },
  UPDATE_PROJECT_REPORT_ANALYSIS_ERROR(
    state: ProjectsState,
    error: AxiosError,
  ): void {
    state.apiState.updateProjectReportAnalysis = {
      ...defaultApiState,
      error,
    };
  },

  /*
   ** delete project report analysis
   */
  DELETE_PROJECT_REPORT_ANALYSIS_LOADING(state: ProjectsState): void {
    state.apiState.deleteProjectReportAnalysis = {
      ...defaultApiState,
      loading: true,
    };
    state.deletedProjectReportAnalysis = undefined;
  },
  DELETE_PROJECT_REPORT_ANALYSIS_SUCCESS(
    state: ProjectsState,
    payload: any,
  ): void {
    state.apiState.deleteProjectReportAnalysis = {
      ...defaultApiState,
      success: true,
    };
    state.deletedProjectReportAnalysis = payload;
  },
  DELETE_PROJECT_REPORT_ANALYSIS_ERROR(
    state: ProjectsState,
    error: AxiosError,
  ): void {
    state.apiState.deleteProjectReportAnalysis = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * get project grip
   * @param state
   * @constructor
   */
  GET_PROJECT_GRIP_LOADING(state: ProjectsState) {
    state.apiState.getProjectGrip = {
      ...defaultApiState,
      loading: true,
    };
    state.projectGrip = undefined;
  },
  GET_PROJECT_GRIP_SUCCESS(state: ProjectsState, payload: ProjectGripDetails) {
    state.apiState.getProjectGrip = {
      ...defaultApiState,
      success: true,
    };
    state.projectGrip = payload;
  },
  GET_PROJECT_GRIP_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.getProjectGrip = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * Project Grip Update
   * @param state
   * @constructor
   */
  CREATE_PROJECT_GRIP_LOADING(state: ProjectsState) {
    state.apiState.createProjectGrip = {
      ...defaultApiState,
      loading: true,
    };
    state.createProjectGrip = undefined;
  },
  CREATE_PROJECT_GRIP_SUCCESS(state: ProjectsState, payload: ProjectGrip) {
    state.apiState.createProjectGrip = {
      ...defaultApiState,
      success: true,
    };
    state.createProjectGrip = payload;
  },
  CREATE_PROJECT_GRIP_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.createProjectGrip = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * Project Grip Update
   * @param state
   * @constructor
   */
  UPDATE_PROJECT_GRIP_LOADING(state: ProjectsState) {
    state.apiState.updateProjectGrip = {
      ...defaultApiState,
      loading: true,
    };
    state.updateProjectGrip = undefined;
  },
  UPDATE_PROJECT_GRIP_SUCCESS(state: ProjectsState, payload: ProjectGrip) {
    state.apiState.updateProjectGrip = {
      ...defaultApiState,
      success: true,
    };
    state.updateProjectGrip = payload;
  },
  UPDATE_PROJECT_GRIP_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.updateProjectGrip = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * get project grip barrier list
   * @param state
   * @constructor
   */
  GET_PROJECT_GRIP_BARRIERS_LOADING(state: ProjectsState) {
    state.apiState.getProjectGripBarriers = {
      ...defaultApiState,
      loading: true,
    };
    state.projectGripBarriers = undefined;
  },
  GET_PROJECT_GRIP_BARRIERS_SUCCESS(
    state: ProjectsState,
    payload: ProjectGripBarrier[],
  ) {
    state.apiState.getProjectGripBarriers = {
      ...defaultApiState,
      success: true,
    };
    state.projectGripBarriers = payload;
  },
  GET_PROJECT_GRIP_BARRIERS_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.getProjectGripBarriers = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * Project Grip Barrier Create
   * @param state
   * @constructor
   */
  CREATE_PROJECT_GRIP_BARRIER_LOADING(state: ProjectsState) {
    state.apiState.createProjectGripBarrier = {
      ...defaultApiState,
      loading: true,
    };
    state.createProjectGripBarrier = undefined;
  },
  CREATE_PROJECT_GRIP_BARRIER_SUCCESS(
    state: ProjectsState,
    payload: ProjectGripBarrier,
  ) {
    state.apiState.createProjectGripBarrier = {
      ...defaultApiState,
      success: true,
    };
    state.createProjectGripBarrier = payload;
  },
  CREATE_PROJECT_GRIP_BARRIER_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.createProjectGripBarrier = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * Project Grip Barrier Update
   * @param state
   * @constructor
   */
  UPDATE_PROJECT_GRIP_BARRIER_LOADING(state: ProjectsState) {
    state.apiState.updateProjectGripBarrier = {
      ...defaultApiState,
      loading: true,
    };
    state.updateProjectGripBarrier = undefined;
  },
  UPDATE_PROJECT_GRIP_BARRIER_SUCCESS(
    state: ProjectsState,
    payload: ProjectGripBarrier,
  ) {
    state.apiState.updateProjectGripBarrier = {
      ...defaultApiState,
      success: true,
    };
    state.updateProjectGripBarrier = payload;
  },
  UPDATE_PROJECT_GRIP_BARRIER_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.updateProjectGripBarrier = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * Delete Project Grip Barrier
   * @param state
   * @constructor
   */
  DELETE_PROJECT_GRIP_BARRIER_LOADING(state: ProjectsState) {
    state.apiState.deletedProjectGripBarrier = {
      ...defaultApiState,
      loading: true,
    };
    state.deletedProjectGripBarrier = undefined;
  },
  DELETE_PROJECT_GRIP_BARRIER_SUCCESS(state: ProjectsState, payload: any) {
    state.apiState.deletedProjectGripBarrier = {
      ...defaultApiState,
      success: true,
    };
    state.deletedProjectGripBarrier = payload;
  },
  DELETE_PROJECT_GRIP_BARRIER_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.deletedProjectGripEnabler = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * get project grip enabler list
   * @param state
   * @constructor
   */
  GET_PROJECT_GRIP_ENABLERS_LOADING(state: ProjectsState) {
    state.apiState.getProjectGripEnablers = {
      ...defaultApiState,
      loading: true,
    };
    state.projectGripEnablers = undefined;
  },
  GET_PROJECT_GRIP_ENABLERS_SUCCESS(
    state: ProjectsState,
    payload: ProjectGripEnabler[],
  ) {
    state.apiState.getProjectGripEnablers = {
      ...defaultApiState,
      success: true,
    };
    state.projectGripEnablers = payload;
  },
  GET_PROJECT_GRIP_ENABLERS_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.getProjectGripEnablers = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * Project Grip Enabler Create
   * @param state
   * @constructor
   */
  CREATE_PROJECT_GRIP_ENABLER_LOADING(state: ProjectsState) {
    state.apiState.createProjectGripEnabler = {
      ...defaultApiState,
      loading: true,
    };
    state.createProjectGripEnabler = undefined;
  },
  CREATE_PROJECT_GRIP_ENABLER_SUCCESS(
    state: ProjectsState,
    payload: ProjectGripEnabler,
  ) {
    state.apiState.createProjectGripEnabler = {
      ...defaultApiState,
      success: true,
    };
    state.createProjectGripEnabler = payload;
  },
  CREATE_PROJECT_GRIP_ENABLER_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.createProjectGripEnabler = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * Project Grip Enabler Update
   * @param state
   * @constructor
   */
  UPDATE_PROJECT_GRIP_ENABLER_LOADING(state: ProjectsState) {
    state.apiState.updateProjectGripEnabler = {
      ...defaultApiState,
      loading: true,
    };
    state.updateProjectGripEnabler = undefined;
  },
  UPDATE_PROJECT_GRIP_ENABLER_SUCCESS(
    state: ProjectsState,
    payload: ProjectGripEnabler,
  ) {
    state.apiState.updateProjectGripEnabler = {
      ...defaultApiState,
      success: true,
    };
    state.updateProjectGripEnabler = payload;
  },
  UPDATE_PROJECT_GRIP_ENABLER_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.updateProjectGripEnabler = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * Delete Project Grip Enabler
   * @param state
   * @constructor
   */
  DELETE_PROJECT_GRIP_ENABLER_LOADING(state: ProjectsState) {
    state.apiState.deletedProjectGripEnabler = {
      ...defaultApiState,
      loading: true,
    };
    state.deletedProjectGripEnabler = undefined;
  },
  DELETE_PROJECT_GRIP_ENABLER_SUCCESS(state: ProjectsState, payload: any) {
    state.apiState.deletedProjectGripEnabler = {
      ...defaultApiState,
      success: true,
    };
    state.deletedProjectGripEnabler = payload;
  },
  DELETE_PROJECT_GRIP_ENABLER_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.deletedProjectGripEnabler = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * get project grip strategy list
   * @param state
   * @constructor
   */
  GET_PROJECT_GRIP_STRATEGIES_LOADING(state: ProjectsState) {
    state.apiState.getProjectGripStrategies = {
      ...defaultApiState,
      loading: true,
    };
    state.projectGripStrategies = undefined;
  },
  GET_PROJECT_GRIP_STRATEGIES_SUCCESS(
    state: ProjectsState,
    payload: ProjectGripStrategy[],
  ) {
    state.apiState.getProjectGripStrategies = {
      ...defaultApiState,
      success: true,
    };
    state.projectGripStrategies = payload;
  },
  GET_PROJECT_GRIP_STRATEGIES_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.getProjectGripStrategies = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * Project Grip Enabler Create
   * @param state
   * @constructor
   */
  CREATE_PROJECT_GRIP_STRATEGY_LOADING(state: ProjectsState) {
    state.apiState.createProjectGripStrategy = {
      ...defaultApiState,
      loading: true,
    };
    state.createProjectGripStrategy = undefined;
  },
  CREATE_PROJECT_GRIP_STRATEGY_SUCCESS(
    state: ProjectsState,
    payload: ProjectGripStrategy,
  ) {
    state.apiState.createProjectGripStrategy = {
      ...defaultApiState,
      success: true,
    };
    state.createProjectGripStrategy = payload;
  },
  CREATE_PROJECT_GRIP_STRATEGY_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.createProjectGripStrategy = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * Project Grip Strategy Update
   * @param state
   * @constructor
   */
  UPDATE_PROJECT_GRIP_STRATEGY_LOADING(state: ProjectsState) {
    state.apiState.updateProjectGripStrategy = {
      ...defaultApiState,
      loading: true,
    };
    state.updateProjectGripStrategy = undefined;
  },
  UPDATE_PROJECT_GRIP_STRATEGY_SUCCESS(
    state: ProjectsState,
    payload: ProjectGripStrategy,
  ) {
    state.apiState.updateProjectGripStrategy = {
      ...defaultApiState,
      success: true,
    };
    state.updateProjectGripStrategy = payload;
  },
  UPDATE_PROJECT_GRIP_STRATEGY_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.updateProjectGripStrategy = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * Delete Project Grip Strategy
   * @param state
   * @constructor
   */
  DELETE_PROJECT_GRIP_STRATEGY_LOADING(state: ProjectsState) {
    state.apiState.deletedProjectGripStrategy = {
      ...defaultApiState,
      loading: true,
    };
    state.deletedProjectGripStrategy = undefined;
  },
  DELETE_PROJECT_GRIP_STRATEGY_SUCCESS(state: ProjectsState, payload: any) {
    state.apiState.deletedProjectGripStrategy = {
      ...defaultApiState,
      success: true,
    };
    state.deletedProjectGripStrategy = payload;
  },
  DELETE_PROJECT_GRIP_STRATEGY_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.deletedProjectGripStrategy = {
      ...defaultApiState,
      error,
    };
  },

  /*
   ** Update Project Criterias By Id
   */
  UPDATE_PROJECT_CRITERIAS_BY_ID_LOADING(state: ProjectsState) {
    state.apiState.updateProjectCriteriaById = {
      ...defaultApiState,
      loading: true,
    };
  },
  UPDATE_PROJECT_CRITERIAS_BY_ID_SUCCESS(
    state: ProjectsState,
    payload: CriteriaResponsePayload,
  ) {
    state.apiState.updateProjectCriteriaById = {
      ...defaultApiState,
      success: true,
    };
    state.updatedProjectCriteriaById = payload;
  },
  UPDATE_PROJECT_CRITERIAS_BY_ID_ERROR(
    state: ProjectsState,
    error: AxiosError,
  ) {
    state.apiState.updateProjectCriteriaById = {
      ...defaultApiState,
      error,
    };
  },

  /** get grip domain categories */
  GET_GRIP_DOMAIN_CATEGORIES_LOADING(state: ProjectsState): void {
    state.apiState.getGripDomainCategories = {
      ...defaultApiState,
      loading: true,
    };
    state.gripDomainCategories = undefined;
  },
  GET_GRIP_DOMAIN_CATEGORIES_SUCCESS(
    state: ProjectsState,
    payload: GripDomainCategory[],
  ): void {
    state.apiState.getGripDomainCategories = {
      ...defaultApiState,
      success: true,
    };
    state.gripDomainCategories = payload;
  },
  GET_GRIP_DOMAIN_CATEGORIES_ERROR(
    state: ProjectsState,
    error: AxiosError,
  ): void {
    state.apiState.getGripDomainCategories = {
      ...defaultApiState,
      error,
    };
  },

  /** get grip domain category details */
  GET_GRIP_DOMAIN_CATEGORY_LOADING(state: ProjectsState): void {
    state.apiState.getGripDomainCategory = {
      ...defaultApiState,
      loading: true,
    };
    state.gripDomainCategory = undefined;
  },
  GET_GRIP_DOMAIN_CATEGORY_SUCCESS(
    state: ProjectsState,
    payload: GripDomainCategoryDetails,
  ): void {
    state.apiState.getGripDomainCategory = {
      ...defaultApiState,
      success: true,
    };
    state.gripDomainCategory = payload;
  },
  GET_GRIP_DOMAIN_CATEGORY_ERROR(
    state: ProjectsState,
    error: AxiosError,
  ): void {
    state.apiState.getGripDomainCategory = {
      ...defaultApiState,
      error,
    };
  },

  /** verify grip domain category */
  VERIFY_GRIP_DOMAIN_CATEGORY_LOADING(state: ProjectsState): void {
    state.apiState.verifyGripDomainCategory = {
      ...defaultApiState,
      loading: true,
    };
    state.verifiedGripDomainCategory = undefined;
  },
  VERIFY_GRIP_DOMAIN_CATEGORY_SUCCESS(
    state: ProjectsState,
    payload: boolean,
  ): void {
    state.apiState.verifyGripDomainCategory = {
      ...defaultApiState,
      success: true,
    };
    state.verifiedGripDomainCategory = payload;
  },
  VERIFY_GRIP_DOMAIN_CATEGORY_ERROR(
    state: ProjectsState,
    error: AxiosError,
  ): void {
    state.apiState.verifyGripDomainCategory = {
      ...defaultApiState,
      error,
    };
  },

  /** create grip domain category */
  CREATE_GRIP_DOMAIN_CATEGORY_LOADING(state: ProjectsState): void {
    state.apiState.createGripDomainCategory = {
      ...defaultApiState,
      loading: true,
    };
    state.createdGripDomainCategory = undefined;
  },
  CREATE_GRIP_DOMAIN_CATEGORY_SUCCESS(
    state: ProjectsState,
    payload: GripDomainCategory,
  ): void {
    state.apiState.createGripDomainCategory = {
      ...defaultApiState,
      success: true,
    };
    state.createdGripDomainCategory = payload;
  },
  CREATE_GRIP_DOMAIN_CATEGORY_ERROR(
    state: ProjectsState,
    error: AxiosError,
  ): void {
    state.apiState.createGripDomainCategory = {
      ...defaultApiState,
      error,
    };
  },

  /** update grip domain category */
  UPDATE_GRIP_DOMAIN_CATEGORY_LOADING(state: ProjectsState): void {
    state.apiState.updateGripDomainCategory = {
      ...defaultApiState,
      loading: true,
    };
    state.updatedGripDomainCategory = undefined;
  },
  UPDATE_GRIP_DOMAIN_CATEGORY_SUCCESS(
    state: ProjectsState,
    payload: GripDomainCategory,
  ): void {
    state.apiState.updateGripDomainCategory = {
      ...defaultApiState,
      success: true,
    };
    state.updatedGripDomainCategory = payload;
  },
  UPDATE_GRIP_DOMAIN_CATEGORY_ERROR(
    state: ProjectsState,
    error: AxiosError,
  ): void {
    state.apiState.updateGripDomainCategory = {
      ...defaultApiState,
      error,
    };
  },

  /** delete grip domain category */
  DELETE_GRIP_DOMAIN_CATEGORY_LOADING(state: ProjectsState): void {
    state.apiState.deleteGripDomainCategory = {
      ...defaultApiState,
      loading: true,
    };
    state.deletedGripDomainCategory = undefined;
  },
  DELETE_GRIP_DOMAIN_CATEGORY_SUCCESS(
    state: ProjectsState,
    payload: void,
  ): void {
    state.apiState.deleteGripDomainCategory = {
      ...defaultApiState,
      success: true,
    };
    state.deletedGripDomainCategory = payload;
  },
  DELETE_GRIP_DOMAIN_CATEGORY_ERROR(
    state: ProjectsState,
    error: AxiosError,
  ): void {
    state.apiState.deleteGripDomainCategory = {
      ...defaultApiState,
      error,
    };
  },

  /** get grip barriers */
  GET_GRIP_BARRIERS_LOADING(state: ProjectsState): void {
    state.apiState.getGripBarriers = {
      ...defaultApiState,
      loading: true,
    };
    state.gripBarriers = undefined;
  },
  GET_GRIP_BARRIERS_SUCCESS(
    state: ProjectsState,
    payload: Pagination<GripBarrier[]>,
  ): void {
    state.apiState.getGripBarriers = {
      ...defaultApiState,
      success: true,
    };
    state.gripBarriers = payload;
  },
  GET_GRIP_BARRIERS_ERROR(state: ProjectsState, error: AxiosError): void {
    state.apiState.getGripBarriers = {
      ...defaultApiState,
      error,
    };
  },

  /** create grip barrier */
  CREATE_GRIP_BARRIER_LOADING(state: ProjectsState): void {
    state.apiState.createGripBarrier = {
      ...defaultApiState,
      loading: true,
    };
    state.createdGripBarrier = undefined;
  },
  CREATE_GRIP_BARRIER_SUCCESS(
    state: ProjectsState,
    payload: GripBarrier,
  ): void {
    state.apiState.createGripBarrier = {
      ...defaultApiState,
      success: true,
    };
    state.createdGripBarrier = payload;
  },
  CREATE_GRIP_BARRIER_ERROR(state: ProjectsState, error: AxiosError): void {
    state.apiState.createGripBarrier = {
      ...defaultApiState,
      error,
    };
  },

  /** update grip barrier */
  UPDATE_GRIP_BARRIER_LOADING(state: ProjectsState): void {
    state.apiState.updateGripBarrier = {
      ...defaultApiState,
      loading: true,
    };
    state.updatedGripBarrier = undefined;
  },
  UPDATE_GRIP_BARRIER_SUCCESS(state: ProjectsState, payload: any): void {
    state.apiState.updateGripBarrier = {
      ...defaultApiState,
      success: true,
    };
    state.updatedGripBarrier = payload;
  },
  UPDATE_GRIP_BARRIER_ERROR(state: ProjectsState, error: AxiosError): void {
    state.apiState.updateGripBarrier = {
      ...defaultApiState,
      error,
    };
  },

  /** delete grip barrier */
  DELETE_GRIP_BARRIER_LOADING(state: ProjectsState): void {
    state.apiState.deleteGripBarrier = {
      ...defaultApiState,
      loading: true,
    };
    state.deletedGripBarrier = undefined;
  },
  DELETE_GRIP_BARRIER_SUCCESS(state: ProjectsState, payload: void): void {
    state.apiState.deleteGripBarrier = {
      ...defaultApiState,
      success: true,
    };
    state.deletedGripBarrier = payload;
  },
  DELETE_GRIP_BARRIER_ERROR(state: ProjectsState, error: AxiosError): void {
    state.apiState.deleteGripBarrier = {
      ...defaultApiState,
      error,
    };
  },

  /** get grip enablers */
  GET_GRIP_ENABLERS_LOADING(state: ProjectsState): void {
    state.apiState.getGripEnablers = {
      ...defaultApiState,
      loading: true,
    };
    state.gripEnablers = undefined;
  },
  GET_GRIP_ENABLERS_SUCCESS(
    state: ProjectsState,
    payload: Pagination<GripEnabler[]>,
  ): void {
    state.apiState.getGripEnablers = {
      ...defaultApiState,
      success: true,
    };
    state.gripEnablers = payload;
  },
  GET_GRIP_ENABLERS_ERROR(state: ProjectsState, error: AxiosError): void {
    state.apiState.getGripEnablers = {
      ...defaultApiState,
      error,
    };
  },

  /** create grip enabler */
  CREATE_GRIP_ENABLER_LOADING(state: ProjectsState): void {
    state.apiState.createGripEnabler = {
      ...defaultApiState,
      loading: true,
    };
    state.createdGripEnabler = undefined;
  },
  CREATE_GRIP_ENABLER_SUCCESS(
    state: ProjectsState,
    payload: GripEnabler,
  ): void {
    state.apiState.createGripEnabler = {
      ...defaultApiState,
      success: true,
    };
    state.createdGripEnabler = payload;
  },
  CREATE_GRIP_ENABLER_ERROR(state: ProjectsState, error: AxiosError): void {
    state.apiState.createGripEnabler = {
      ...defaultApiState,
      error,
    };
  },

  /** update grip enabler */
  UPDATE_GRIP_ENABLER_LOADING(state: ProjectsState): void {
    state.apiState.updateGripEnabler = {
      ...defaultApiState,
      loading: true,
    };
    state.updatedGripEnabler = undefined;
  },
  UPDATE_GRIP_ENABLER_SUCCESS(state: ProjectsState, payload: any): void {
    state.apiState.updateGripEnabler = {
      ...defaultApiState,
      success: true,
    };
    state.updatedGripEnabler = payload;
  },
  UPDATE_GRIP_ENABLER_ERROR(state: ProjectsState, error: AxiosError): void {
    state.apiState.updateGripEnabler = {
      ...defaultApiState,
      error,
    };
  },

  /** delete grip enabler */
  DELETE_GRIP_ENABLER_LOADING(state: ProjectsState): void {
    state.apiState.deleteGripEnabler = {
      ...defaultApiState,
      loading: true,
    };
    state.deletedGripEnabler = undefined;
  },
  DELETE_GRIP_ENABLER_SUCCESS(state: ProjectsState, payload: void): void {
    state.apiState.deleteGripEnabler = {
      ...defaultApiState,
      success: true,
    };
    state.deletedGripEnabler = payload;
  },
  DELETE_GRIP_ENABLER_ERROR(state: ProjectsState, error: AxiosError): void {
    state.apiState.deleteGripEnabler = {
      ...defaultApiState,
      error,
    };
  },

  /** get grip strategies */
  GET_GRIP_STRATEGIES_LOADING(state: ProjectsState): void {
    state.apiState.getGripStrategies = {
      ...defaultApiState,
      loading: true,
    };
    state.gripStrategies = undefined;
  },
  GET_GRIP_STRATEGIES_SUCCESS(
    state: ProjectsState,
    payload: Pagination<GripStrategy[]>,
  ): void {
    state.apiState.getGripStrategies = {
      ...defaultApiState,
      success: true,
    };
    state.gripStrategies = payload;
  },
  GET_GRIP_STRATEGIES_ERROR(state: ProjectsState, error: AxiosError): void {
    state.apiState.getGripStrategies = {
      ...defaultApiState,
      error,
    };
  },

  /** create grip strategy */
  CREATE_GRIP_STRATEGY_LOADING(state: ProjectsState): void {
    state.apiState.createGripStrategy = {
      ...defaultApiState,
      loading: true,
    };
    state.createdGripStrategy = undefined;
  },
  CREATE_GRIP_STRATEGY_SUCCESS(
    state: ProjectsState,
    payload: GripStrategy,
  ): void {
    state.apiState.createGripStrategy = {
      ...defaultApiState,
      success: true,
    };
    state.createdGripStrategy = payload;
  },
  CREATE_GRIP_STRATEGY_ERROR(state: ProjectsState, error: AxiosError): void {
    state.apiState.createGripStrategy = {
      ...defaultApiState,
      error,
    };
  },

  /** update grip strategy */
  UPDATE_GRIP_STRATEGY_LOADING(state: ProjectsState): void {
    state.apiState.updateGripStrategy = {
      ...defaultApiState,
      loading: true,
    };
    state.updatedGripStrategy = undefined;
  },
  UPDATE_GRIP_STRATEGY_SUCCESS(state: ProjectsState, payload: any): void {
    state.apiState.updateGripStrategy = {
      ...defaultApiState,
      success: true,
    };
    state.updatedGripStrategy = payload;
  },
  UPDATE_GRIP_STRATEGY_ERROR(state: ProjectsState, error: AxiosError): void {
    state.apiState.updateGripStrategy = {
      ...defaultApiState,
      error,
    };
  },

  /** delete grip strategy */
  DELETE_GRIP_STRATEGY_LOADING(state: ProjectsState): void {
    state.apiState.deleteGripStrategy = {
      ...defaultApiState,
      loading: true,
    };
    state.deletedGripStrategy = undefined;
  },
  DELETE_GRIP_STRATEGY_SUCCESS(state: ProjectsState, payload: void): void {
    state.apiState.deleteGripStrategy = {
      ...defaultApiState,
      success: true,
    };
    state.deletedGripStrategy = payload;
  },
  DELETE_GRIP_STRATEGY_ERROR(state: ProjectsState, error: AxiosError): void {
    state.apiState.deleteGripStrategy = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * get grip details
   */
  GET_GRIP_DETAILS_LOADING(state: ProjectsState): void {
    state.apiState.getGripDetails = {
      ...defaultApiState,
      loading: true,
    };
    state.gripDetails = undefined;
  },
  GET_GRIP_DETAILS_SUCCESS(state: ProjectsState, payload: Grip): void {
    state.apiState.getGripDetails = {
      ...defaultApiState,
      success: true,
    };
    state.gripDetails = payload;
  },
  GET_GRIP_DETAILS_ERROR(state: ProjectsState, error: AxiosError): void {
    state.apiState.getGripDetails = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * create grip details
   */
  CREATE_GRIP_DETAILS_LOADING(state: ProjectsState): void {
    state.apiState.createGripDetails = {
      ...defaultApiState,
      loading: true,
    };
    state.createdGripDetails = undefined;
  },
  CREATE_GRIP_DETAILS_SUCCESS(state: ProjectsState, payload: Grip): void {
    state.apiState.createGripDetails = {
      ...defaultApiState,
      success: true,
    };
    state.createdGripDetails = payload;
  },
  CREATE_GRIP_DETAILS_ERROR(state: ProjectsState, error: AxiosError): void {
    state.apiState.createGripDetails = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * create grip details
   */
  UPDATE_GRIP_DETAILS_LOADING(state: ProjectsState): void {
    state.apiState.updateGripDetails = {
      ...defaultApiState,
      loading: true,
    };
    state.updatedGripDetails = undefined;
  },
  UPDATE_GRIP_DETAILS_SUCCESS(state: ProjectsState, payload: Grip): void {
    state.apiState.updateGripDetails = {
      ...defaultApiState,
      success: true,
    };
    state.updatedGripDetails = payload;
  },
  UPDATE_GRIP_DETAILS_ERROR(state: ProjectsState, error: AxiosError): void {
    state.apiState.updateGripDetails = {
      ...defaultApiState,
      error,
    };
  },

  /** get grip analysis list */
  GET_GRIP_ANALYSIS_LIST_LOADING(state: ProjectsState): void {
    state.apiState.getGripAnalysisList = {
      ...defaultApiState,
      loading: true,
    };
    state.gripAnalysisList = undefined;
  },
  GET_GRIP_ANALYSIS_LIST_SUCCESS(
    state: ProjectsState,
    payload: GripAnalysis[],
  ): void {
    state.apiState.getGripAnalysisList = {
      ...defaultApiState,
      success: true,
    };
    state.gripAnalysisList = payload;
  },
  GET_GRIP_ANALYSIS_LIST_ERROR(state: ProjectsState, error: AxiosError): void {
    state.apiState.getGripAnalysisList = {
      ...defaultApiState,
      error,
    };
  },

  /** get filtered grip analysis list for export grip report */
  GET_FILTERED_GRIP_ANALYSIS_LIST_LOADING(state: ProjectsState): void {
    state.apiState.getFilteredGripAnalysisList = {
      ...defaultApiState,
      loading: true,
    };
    state.filteredGripAnalysisList = undefined;
  },
  GET_FILTERED_GRIP_ANALYSIS_LIST_SUCCESS(
    state: ProjectsState,
    payload: Pagination<GripAnalysis>,
  ): void {
    state.apiState.getFilteredGripAnalysisList = {
      ...defaultApiState,
      success: true,
    };
    state.filteredGripAnalysisList = payload;
  },
  GET_FILTERED_GRIP_ANALYSIS_LIST_ERROR(
    state: ProjectsState,
    error: AxiosError,
  ): void {
    state.apiState.getFilteredGripAnalysisList = {
      ...defaultApiState,
      error,
    };
  },

  /** get grip analysis */
  GET_GRIP_ANALYSIS_LOADING(state: ProjectsState): void {
    state.apiState.getGripAnalysis = {
      ...defaultApiState,
      loading: true,
    };
    state.gripAnalysis = undefined;
  },
  GET_GRIP_ANALYSIS_SUCCESS(state: ProjectsState, payload: GripAnalysis): void {
    state.apiState.getGripAnalysis = {
      ...defaultApiState,
      success: true,
    };
    state.gripAnalysis = payload;
  },
  GET_GRIP_ANALYSIS_ERROR(state: ProjectsState, error: AxiosError): void {
    state.apiState.getGripAnalysis = {
      ...defaultApiState,
      error,
    };
  },

  /** create grip analysis */
  CREATE_GRIP_ANALYSIS_LOADING(state: ProjectsState): void {
    state.apiState.createGripAnalysis = {
      ...defaultApiState,
      loading: true,
    };
    state.createdGripAnalysis = undefined;
  },
  CREATE_GRIP_ANALYSIS_SUCCESS(
    state: ProjectsState,
    payload: GripAnalysis,
  ): void {
    state.apiState.createGripAnalysis = {
      ...defaultApiState,
      success: true,
    };
    state.createdGripAnalysis = payload;
  },
  CREATE_GRIP_ANALYSIS_ERROR(state: ProjectsState, error: AxiosError): void {
    state.apiState.createGripAnalysis = {
      ...defaultApiState,
      error,
    };
  },

  /** rename grip analysis */
  RENAME_GRIP_ANALYSIS_LOADING(state: ProjectsState): void {
    state.apiState.renameGripAnalysis = {
      ...defaultApiState,
      loading: true,
    };
    state.renamedGripAnalysis = undefined;
  },
  RENAME_GRIP_ANALYSIS_SUCCESS(
    state: ProjectsState,
    payload: GripAnalysis,
  ): void {
    state.apiState.renameGripAnalysis = {
      ...defaultApiState,
      success: true,
    };
    state.renamedGripAnalysis = payload;
  },
  RENAME_GRIP_ANALYSIS_ERROR(state: ProjectsState, error: AxiosError): void {
    state.apiState.renameGripAnalysis = {
      ...defaultApiState,
      error,
    };
  },

  /** update grip analysis */
  UPDATE_GRIP_ANALYSIS_LOADING(state: ProjectsState): void {
    state.apiState.updateGripAnalysis = {
      ...defaultApiState,
      loading: true,
    };
    state.updatedGripAnalysis = undefined;
  },
  UPDATE_GRIP_ANALYSIS_SUCCESS(
    state: ProjectsState,
    payload: GripAnalysis,
  ): void {
    state.apiState.updateGripAnalysis = {
      ...defaultApiState,
      success: true,
    };
    state.updatedGripAnalysis = payload;
  },
  UPDATE_GRIP_ANALYSIS_ERROR(state: ProjectsState, error: AxiosError): void {
    state.apiState.updateGripAnalysis = {
      ...defaultApiState,
      error,
    };
  },

  /** delete grip analysis */
  DELETE_GRIP_ANALYSIS_LOADING(state: ProjectsState): void {
    state.apiState.deleteGripAnalysis = {
      ...defaultApiState,
      loading: true,
    };
    state.deletedGripAnalysis = undefined;
  },
  DELETE_GRIP_ANALYSIS_SUCCESS(state: ProjectsState, payload: any): void {
    state.apiState.deleteGripAnalysis = {
      ...defaultApiState,
      success: true,
    };
    state.deletedGripAnalysis = payload;
  },
  DELETE_GRIP_ANALYSIS_ERROR(state: ProjectsState, error: AxiosError): void {
    state.apiState.deleteGripAnalysis = {
      ...defaultApiState,
      error,
    };
  },

  /** create grip analysis barrier */
  CREATE_GRIP_ANALYSIS_BARRIER_LOADING(state: ProjectsState): void {
    state.apiState.createGripAnalysisBarrier = {
      ...defaultApiState,
      loading: true,
    };
    state.createdGripAnalysisBarrier = undefined;
  },
  CREATE_GRIP_ANALYSIS_BARRIER_SUCCESS(
    state: ProjectsState,
    payload: GripAnalysisBarrier,
  ): void {
    state.apiState.createGripAnalysisBarrier = {
      ...defaultApiState,
      success: true,
    };
    state.createdGripAnalysisBarrier = payload;
  },
  CREATE_GRIP_ANALYSIS_BARRIER_ERROR(
    state: ProjectsState,
    error: AxiosError,
  ): void {
    state.apiState.createGripAnalysisBarrier = {
      ...defaultApiState,
      error,
    };
  },

  /** update grip analysis barrier */
  UPDATE_GRIP_ANALYSIS_BARRIER_LOADING(state: ProjectsState): void {
    state.apiState.updateGripAnalysisBarrier = {
      ...defaultApiState,
      loading: true,
    };
    state.updatedGripAnalysisBarrier = undefined;
  },
  UPDATE_GRIP_ANALYSIS_BARRIER_SUCCESS(
    state: ProjectsState,
    payload: any,
  ): void {
    state.apiState.updateGripAnalysisBarrier = {
      ...defaultApiState,
      success: true,
    };
    state.updatedGripAnalysisBarrier = payload;
  },
  UPDATE_GRIP_ANALYSIS_BARRIER_ERROR(
    state: ProjectsState,
    error: AxiosError,
  ): void {
    state.apiState.updateGripAnalysisBarrier = {
      ...defaultApiState,
      error,
    };
  },

  /** delete grip analysis barrier */
  DELETE_GRIP_ANALYSIS_BARRIER_LOADING(state: ProjectsState): void {
    state.apiState.deleteGripAnalysisBarrier = {
      ...defaultApiState,
      loading: true,
    };
    state.deletedGripAnalysisBarrier = undefined;
  },
  DELETE_GRIP_ANALYSIS_BARRIER_SUCCESS(
    state: ProjectsState,
    payload: void,
  ): void {
    state.apiState.deleteGripAnalysisBarrier = {
      ...defaultApiState,
      success: true,
    };
    state.deletedGripAnalysisBarrier = payload;
  },
  DELETE_GRIP_ANALYSIS_BARRIER_ERROR(
    state: ProjectsState,
    error: AxiosError,
  ): void {
    state.apiState.deleteGripAnalysisBarrier = {
      ...defaultApiState,
      error,
    };
  },

  /** create grip analysis enabler */
  CREATE_GRIP_ANALYSIS_ENABLER_LOADING(state: ProjectsState): void {
    state.apiState.createGripAnalysisEnabler = {
      ...defaultApiState,
      loading: true,
    };
    state.createdGripAnalysisEnabler = undefined;
  },
  CREATE_GRIP_ANALYSIS_ENABLER_SUCCESS(
    state: ProjectsState,
    payload: GripAnalysisEnabler,
  ): void {
    state.apiState.createGripAnalysisEnabler = {
      ...defaultApiState,
      success: true,
    };
    state.createdGripAnalysisEnabler = payload;
  },
  CREATE_GRIP_ANALYSIS_ENABLER_ERROR(
    state: ProjectsState,
    error: AxiosError,
  ): void {
    state.apiState.createGripAnalysisEnabler = {
      ...defaultApiState,
      error,
    };
  },

  /** update grip analysis enabler */
  UPDATE_GRIP_ANALYSIS_ENABLER_LOADING(state: ProjectsState): void {
    state.apiState.updateGripAnalysisEnabler = {
      ...defaultApiState,
      loading: true,
    };
    state.updatedGripAnalysisEnabler = undefined;
  },
  UPDATE_GRIP_ANALYSIS_ENABLER_SUCCESS(
    state: ProjectsState,
    payload: any,
  ): void {
    state.apiState.updateGripAnalysisEnabler = {
      ...defaultApiState,
      success: true,
    };
    state.updatedGripAnalysisEnabler = payload;
  },
  UPDATE_GRIP_ANALYSIS_ENABLER_ERROR(
    state: ProjectsState,
    error: AxiosError,
  ): void {
    state.apiState.updateGripAnalysisEnabler = {
      ...defaultApiState,
      error,
    };
  },

  /** delete grip analysis enabler */
  DELETE_GRIP_ANALYSIS_ENABLER_LOADING(state: ProjectsState): void {
    state.apiState.deleteGripAnalysisEnabler = {
      ...defaultApiState,
      loading: true,
    };
    state.deletedGripAnalysisEnabler = undefined;
  },
  DELETE_GRIP_ANALYSIS_ENABLER_SUCCESS(
    state: ProjectsState,
    payload: void,
  ): void {
    state.apiState.deleteGripAnalysisEnabler = {
      ...defaultApiState,
      success: true,
    };
    state.deletedGripAnalysisEnabler = payload;
  },
  DELETE_GRIP_ANALYSIS_ENABLER_ERROR(
    state: ProjectsState,
    error: AxiosError,
  ): void {
    state.apiState.deleteGripAnalysisEnabler = {
      ...defaultApiState,
      error,
    };
  },

  /** create grip analysis strategy */
  CREATE_GRIP_ANALYSIS_STRATEGY_LOADING(state: ProjectsState): void {
    state.apiState.createGripAnalysisStrategy = {
      ...defaultApiState,
      loading: true,
    };
    state.createdGripAnalysisStrategy = undefined;
  },
  CREATE_GRIP_ANALYSIS_STRATEGY_SUCCESS(
    state: ProjectsState,
    payload: GripAnalysisStrategy,
  ): void {
    state.apiState.createGripAnalysisStrategy = {
      ...defaultApiState,
      success: true,
    };
    state.createdGripAnalysisStrategy = payload;
  },
  CREATE_GRIP_ANALYSIS_STRATEGY_ERROR(
    state: ProjectsState,
    error: AxiosError,
  ): void {
    state.apiState.createGripAnalysisStrategy = {
      ...defaultApiState,
      error,
    };
  },

  /** update grip analysis strategy */
  UPDATE_GRIP_ANALYSIS_STRATEGY_LOADING(state: ProjectsState): void {
    state.apiState.updateGripAnalysisStrategy = {
      ...defaultApiState,
      loading: true,
    };
    state.updatedGripAnalysisStrategy = undefined;
  },
  UPDATE_GRIP_ANALYSIS_STRATEGY_SUCCESS(
    state: ProjectsState,
    payload: any,
  ): void {
    state.apiState.updateGripAnalysisStrategy = {
      ...defaultApiState,
      success: true,
    };
    state.updatedGripAnalysisStrategy = payload;
  },
  UPDATE_GRIP_ANALYSIS_STRATEGY_ERROR(
    state: ProjectsState,
    error: AxiosError,
  ): void {
    state.apiState.updateGripAnalysisStrategy = {
      ...defaultApiState,
      error,
    };
  },

  /** delete grip analysis strategy */
  DELETE_GRIP_ANALYSIS_STRATEGY_LOADING(state: ProjectsState): void {
    state.apiState.deleteGripAnalysisStrategy = {
      ...defaultApiState,
      loading: true,
    };
    state.deletedGripAnalysisStrategy = undefined;
  },
  DELETE_GRIP_ANALYSIS_STRATEGY_SUCCESS(
    state: ProjectsState,
    payload: void,
  ): void {
    state.apiState.deleteGripAnalysisStrategy = {
      ...defaultApiState,
      success: true,
    };
    state.deletedGripAnalysisStrategy = payload;
  },
  DELETE_GRIP_ANALYSIS_STRATEGY_ERROR(
    state: ProjectsState,
    error: AxiosError,
  ): void {
    state.apiState.deleteGripAnalysisStrategy = {
      ...defaultApiState,
      error,
    };
  },

  /** get grip notifications */
  GET_GRIP_NOTIFICATIONS_LOADING(state: ProjectsState): void {
    state.apiState.getGripNotifications = {
      ...defaultApiState,
      loading: true,
    };
    state.gripNotifications = undefined;
  },
  GET_GRIP_NOTIFICATIONS_SUCCESS(
    state: ProjectsState,
    payload: GripNotificationResponse[],
  ): void {
    state.apiState.getGripNotifications = {
      ...defaultApiState,
      success: true,
    };
    state.gripNotifications = payload;
  },
  GET_GRIP_NOTIFICATIONS_ERROR(state: ProjectsState, error: AxiosError): void {
    state.apiState.getGripNotifications = {
      ...defaultApiState,
      error,
    };
  },

  /** update grip notifications */
  UPDATE_GRIP_NOTIFICATIONS_LOADING(state: ProjectsState): void {
    state.apiState.updateGripNotifications = {
      ...defaultApiState,
      loading: true,
    };
    state.updatedGripNotifications = undefined;
  },
  UPDATE_GRIP_NOTIFICATIONS_SUCCESS(state: ProjectsState, payload: any): void {
    state.apiState.updateGripNotifications = {
      ...defaultApiState,
      success: true,
    };
    state.updatedGripNotifications = payload;
  },
  UPDATE_GRIP_NOTIFICATIONS_ERROR(
    state: ProjectsState,
    error: AxiosError,
  ): void {
    state.apiState.updateGripNotifications = {
      ...defaultApiState,
      error,
    };
  },

  /** get grip export report */
  GET_GRIP_EXPORT_REPORT_LOADING(state: ProjectsState): void {
    state.apiState.getGripExportReport = {
      ...defaultApiState,
      loading: true,
    };
    state.gripExportReport = undefined;
  },
  GET_GRIP_EXPORT_REPORT_SUCCESS(
    state: ProjectsState,
    payload: GripExportReportResponse,
  ): void {
    state.apiState.getGripExportReport = {
      ...defaultApiState,
      success: true,
    };
    state.gripExportReport = payload;
  },
  GET_GRIP_EXPORT_REPORT_ERROR(state: ProjectsState, error: AxiosError): void {
    state.apiState.getGripExportReport = {
      ...defaultApiState,
      error,
    };
  },

  /*
   ** get Updated JBI Criteria List And Acknowledgment
   */
  GET_UPDATED_JBI_CRITERIA_LIST_WITH_ACKNOWLEDGMENT_LOADING(
    state: ProjectsState,
  ) {
    state.updatedJBICriteriaListWithAcknowledgment = undefined;
    state.apiState.getUpdatedJBICriteriaListWithAcknowledgment = {
      ...defaultApiState,
      loading: true,
    };
  },
  GET_UPDATED_JBI_CRITERIA_LIST_WITH_ACKNOWLEDGMENT_SUCCESS(
    state: ProjectsState,
    payload: UpdatedJBICriteriaAcknowledgement,
  ) {
    state.updatedJBICriteriaListWithAcknowledgment = payload;
    state.apiState.getUpdatedJBICriteriaListWithAcknowledgment = {
      ...defaultApiState,
      success: true,
    };
  },
  GET_UPDATED_JBI_CRITERIA_LIST_WITH_ACKNOWLEDGMENT_ERROR(
    state: ProjectsState,
    error: AxiosError,
  ) {
    state.apiState.getUpdatedJBICriteriaListWithAcknowledgment = {
      ...defaultApiState,
      error,
    };
  },

  /*
   ** get Updated Project Report JBI Criteria List
   */
  GET_UPDATED_PROJECT_REPORT_JBI_CRITERIA_LIST_LOADING(state: ProjectsState) {
    state.updatedProjectReportJBICriteriaList = undefined;
    state.apiState.getUpdatedProjectReportJBICriteriaList = {
      ...defaultApiState,
      loading: true,
    };
  },
  GET_UPDATED_PROJECT_REPORT_JBI_CRITERIA_LIST_SUCCESS(
    state: ProjectsState,
    payload: JBICriteriaDTO[],
  ) {
    state.updatedProjectReportJBICriteriaList = payload;
    state.apiState.getUpdatedProjectReportJBICriteriaList = {
      ...defaultApiState,
      success: true,
    };
  },
  GET_UPDATED_PROJECT_REPORT_JBI_CRITERIA_LIST_ERROR(
    state: ProjectsState,
    error: AxiosError,
  ) {
    state.apiState.getUpdatedProjectReportJBICriteriaList = {
      ...defaultApiState,
      error,
    };
  },

  /*
   ** Updated Project Criteria Acknowledgment
   */
  UPDATE_PROJECT_CRITERIA_ACKNOWLEDGMENT_LOADING(state: ProjectsState): void {
    state.updatedJBICriteriaAcknowledgment = undefined;
    state.apiState.getUpdateJBICriteriaAcknowledgment = {
      ...defaultApiState,
      loading: true,
    };
  },
  UPDATE_PROJECT_CRITERIA_ACKNOWLEDGMENT_SUCCESS(
    state: ProjectsState,
    payload: any,
  ): void {
    state.updatedJBICriteriaAcknowledgment = payload;
    state.apiState.getUpdateJBICriteriaAcknowledgment = {
      ...defaultApiState,
      success: true,
    };
  },
  UPDATE_PROJECT_CRITERIA_ACKNOWLEDGMENT_ERROR(
    state: ProjectsState,
    error: AxiosError,
  ): void {
    state.apiState.getUpdateJBICriteriaAcknowledgment = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * get project list for administrator
   * @param state
   * @constructor
   */
  GET_PROJECT_LIST_FOR_ADMIN_LOADING(state: ProjectsState) {
    state.apiState.getProjectListForAdmin = {
      ...defaultApiState,
      loading: true,
    };
    state.administratorProjectList = undefined;
  },
  GET_PROJECT_LIST_FOR_ADMIN_SUCCESS(
    state: ProjectsState,
    payload: AdministratorProjectListDTO,
  ) {
    state.apiState.getProjectListForAdmin = {
      ...defaultApiState,
      success: true,
    };
    state.administratorProjectList = payload;
  },
  GET_PROJECT_LIST_FOR_ADMIN_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.getProjectListForAdmin = {
      ...defaultApiState,
      loading: true,
    };
  },

  /**
   * get project participants for administrator
   * @param state
   * @constructor
   */
  GET_PROJECT_PARTICIPANTS_FOR_ADMIN_LOADING(state: ProjectsState) {
    state.apiState.getProjectParticipantsForAdmin = {
      ...defaultApiState,
      loading: true,
    };
    state.projectParticipantsForAdmin = undefined;
  },
  GET_PROJECT_PARTICIPANTS_FOR_ADMIN_SUCCESS(
    state: ProjectsState,
    payload: ProjectParticipantDTO[],
  ) {
    state.apiState.getProjectParticipantsForAdmin = {
      ...defaultApiState,
      success: true,
    };
    state.projectParticipantsForAdmin = payload;
  },
  GET_PROJECT_PARTICIPANTS_FOR_ADMIN_ERROR(
    state: ProjectsState,
    error: AxiosError,
  ) {
    state.apiState.getProjectParticipantsForAdmin = {
      ...defaultApiState,
      loading: true,
    };
  },

  /**
   * get project site for administrator
   * @param state
   * @constructor
   */
  GET_PROJECT_SITES_FOR_ADMIN_LOADING(state: ProjectsState) {
    state.apiState.getProjectSitesForAdmin = {
      ...defaultApiState,
      loading: true,
    };
    state.projectSitesForAdmin = undefined;
  },
  GET_PROJECT_SITES_FOR_ADMIN_SUCCESS(
    state: ProjectsState,
    payload: ProjectSite[],
  ) {
    state.apiState.getProjectSitesForAdmin = {
      ...defaultApiState,
      success: true,
    };
    state.projectSitesForAdmin = payload;
  },
  GET_PROJECT_SITES_FOR_ADMIN_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.getProjectSitesForAdmin = {
      ...defaultApiState,
      loading: true,
    };
  },

  /**
   * get Existing Project Owner Request
   * @param state
   * @constructor
   */
  GET_EXISTING_PROJECT_OWNER_REQUEST_LOADING(state: ProjectsState) {
    state.apiState.getExistingProjectOwnerRequest = {
      ...defaultApiState,
      loading: true,
    };
    state.existingProjectOwnerRequest = undefined;
  },
  GET_EXISTING_PROJECT_OWNER_REQUEST_SUCCESS(
    state: ProjectsState,
    payload: ExistingProjectOwnerRequestPayload,
  ) {
    state.apiState.getExistingProjectOwnerRequest = {
      ...defaultApiState,
      success: true,
    };
    state.existingProjectOwnerRequest = payload;
  },
  GET_EXISTING_PROJECT_OWNER_REQUEST_ERROR(
    state: ProjectsState,
    error: AxiosError,
  ) {
    state.apiState.getExistingProjectOwnerRequest = {
      ...defaultApiState,
      loading: true,
    };
  },

  /**
   * get Valid User Subscription
   * @param state
   * @constructor
   */
  CHANGE_PROJECT_OWNER_REQUEST_LOADING(state: ProjectsState) {
    state.apiState.changeProjectOwnerRequest = {
      ...defaultApiState,
      loading: true,
    };
  },
  CHANGE_PROJECT_OWNER_REQUEST_SUCCESS(
    state: ProjectsState,
    payload: ValidUserPayload,
  ) {
    state.apiState.changeProjectOwnerRequest = {
      ...defaultApiState,
      success: true,
    };
  },
  CHANGE_PROJECT_OWNER_REQUEST_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.changeProjectOwnerRequest = {
      ...defaultApiState,
      loading: true,
    };
  },

  /*
   ** Update Project Owner Request
   */
  UPDATE_PROJECT_OWNER_REQUEST_LOADING(state: ProjectsState) {
    state.apiState.updateProjectOwnerRequest = {
      ...defaultApiState,
      loading: true,
    };
  },
  UPDATE_PROJECT_OWNER_REQUEST_SUCCESS(state: ProjectsState, payload: any) {
    state.apiState.updateProjectOwnerRequest = {
      ...defaultApiState,
      success: true,
    };
  },
  UPDATE_PROJECT_OWNER_REQUEST_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.updateProjectOwnerRequest = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * update cancel project owner request
   * @param state
   * @constructor
   */
  UPDATE_CANCEL_PROJECT_REQUEST_LOADING(state: ProjectsState) {
    state.apiState.updateCancelProjectRequest = {
      ...defaultApiState,
      loading: true,
    };
    // state.cancelProjectRequest = undefined;
  },
  UPDATE_CANCEL_PROJECT_REQUEST_SUCCESS(state: ProjectsState, payload: any) {
    state.apiState.updateCancelProjectRequest = {
      ...defaultApiState,
      success: true,
    };
    // state.cancelProjectRequest = payload;
  },
  UPDATE_CANCEL_PROJECT_REQUEST_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.updateCancelProjectRequest = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * get project owner history
   * @param state
   * @constructor
   */
  GET_PROJECT_OWNER_HISTORY_LOADING(state: ProjectsState) {
    state.apiState.getProjectOwnerHistory = {
      ...defaultApiState,
      loading: true,
    };
    state.projectOwnerHistory = undefined;
  },
  GET_PROJECT_OWNER_HISTORY_SUCCESS(
    state: ProjectsState,
    payload: ProjectOwnerHistoryPayload,
  ) {
    state.apiState.getProjectOwnerHistory = {
      ...defaultApiState,
      success: true,
    };
    state.projectOwnerHistory = payload;
  },
  GET_PROJECT_OWNER_HISTORY_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.getProjectOwnerHistory = {
      ...defaultApiState,
      error,
    };
  },
};
