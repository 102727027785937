import { auditsState } from './audits.state';
import { ModuleTree } from 'vuex';
import { RootState } from '@/store/store';
import {auditsActions} from './audits.actions';
import {auditsMutations} from './audits.mutations';
import {auditsGetters} from './audits.getters';

export default {
    namespaced: true,
    state: auditsState,
    getters: auditsGetters,
    actions: auditsActions,
    mutations: auditsMutations,
} as ModuleTree<RootState>;
