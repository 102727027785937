import dayjs from 'dayjs';
import {
  AuthState,
  ExchangeGrantForTokenResponsePayload,
  JaasAccountDetail,
  OicPayload,
  SubscriptionInfo,
  SubscriptionStatus,
  UserSubscriptionStatusByEmailPayload,
} from './types/auth.types';
import { defaultApiState } from '@/store/types/general.types';
import jwtDecode from 'jwt-decode';
import { AxiosError } from 'axios';

export const authMutations = {
  /*
   ** exchangeGrantForToken
   */
  EXCHANGE_GRANT_FOR_TOKEN_LOADING(state: AuthState) {
    state.apiState.exchangeGrantForToken = {
      ...defaultApiState,
      loading: true,
    };
    state.apiState.signOut = {
      ...defaultApiState,
      loading: true,
    };
  },
  EXCHANGE_GRANT_FOR_TOKEN_SUCCESS(
    state: AuthState,
    payload: ExchangeGrantForTokenResponsePayload,
  ) {
    state.apiState.exchangeGrantForToken = {
      ...defaultApiState,
      success: true,
    };
    const { id_token: idToken, roles } = payload;
    const oicPayload = idToken ? (jwtDecode(idToken) as OicPayload) : undefined;
    state.authInfo = {
      ...state.authInfo,
      idToken,
      oicPayload,
    };

    state.apiState.signOut = {
      ...defaultApiState,
    };
    state.isLoggedIn = true;
    state.roles = roles;
    state.subscriptionInfo = payload.subscriptionInfo;
  },
  EXCHANGE_GRANT_FOR_TOKEN_ERROR(state: AuthState, error: AxiosError) {
    state.apiState.exchangeGrantForToken = {
      ...defaultApiState,
      error,
    };
  },
  /*
   ** signOut
   */
  SIGN_OUT_LOADING(state: AuthState) {
    state.apiState.signOut = {
      ...defaultApiState,
      loading: true,
    };
  },
  SIGN_OUT_SUCCESS(state: AuthState) {
    state.apiState.signOut = {
      ...defaultApiState,
      success: true,
    };
    state.authInfo = null;
    state.isLoggedIn = false;
    state.roles = [];
  },
  SIGN_OUT_ERROR(state: AuthState, error: AxiosError) {
    state.apiState.signOut = {
      ...defaultApiState,
      error,
    };
  },
  /*
   ** checkSessionHealth
   */
  CHECK_SESSION_HEALTH_LOADING(state: AuthState) {
    state.apiState.checkSessionHealth = {
      ...defaultApiState,
      loading: true,
    };
  },
  CHECK_SESSION_HEALTH_SUCCESS(state: AuthState) {
    state.apiState.checkSessionHealth = {
      ...defaultApiState,
      success: true,
    };
    state.isLoggedIn = true;
  },
  CHECK_SESSION_HEALTH_ERROR(state: AuthState, error: AxiosError) {
    state.apiState.checkSessionHealth = {
      ...defaultApiState,
      error,
    };
    state.authInfo = null;
    state.isLoggedIn = false;
  },

  /*
   ** checkSubscriptionHealth
   */
  CHECK_SUBSCRIPTION_HEALTH_LOADING(state: AuthState) {
    state.apiState.checkSubscriptionHealth = {
      ...defaultApiState,
      loading: true,
    };
  },
  CHECK_SUBSCRIPTION_HEALTH_SUCCESS(state: AuthState, response: any) {
    state.apiState.checkSubscriptionHealth = {
      ...defaultApiState,
      success: true,
    };
    state.subscriptionInfo = response.data;
  },
  CHECK_SUBSCRIPTION_HEALTH_ERROR(state: AuthState, error: AxiosError) {
    state.apiState.checkSubscriptionHealth = {
      ...defaultApiState,
      error,
    };
    state.subscriptionInfo = null;
  },
  /*
   ** getJaasAccountDetail
   */
  GET_JAAS_ACCOUNT_DETAIL_LOADING(state: AuthState) {
    state.apiState.getJaasAccountDetail = {
      ...defaultApiState,
      loading: true,
    };
  },
  GET_JAAS_ACCOUNT_DETAIL_SUCCESS(
    state: AuthState,
    payload: JaasAccountDetail,
  ) {
    state.apiState.getJaasAccountDetail = {
      ...defaultApiState,
      success: true,
    };
    state.jaasAccountDetail = payload;
  },
  GET_JAAS_ACCOUNT_DETAIL_ERROR(state: AuthState, error: AxiosError) {
    state.apiState.getJaasAccountDetail = {
      ...defaultApiState,
      error,
    };
  },
  SWITCH_ACCOUNT(state: AuthState, value: boolean) {
    state.isSwitchAccount = value;
  },
  /*check user subscriptionn by email
   */
  CHECK_SUBSCRIPTION_STATUS_EMAIL_LOADING(state: AuthState) {
    state.apiState.checkSubscriptionStatusByEmailIds = {
      ...defaultApiState,
      loading: true,
    };
  },
  CHECK_SUBSCRIPTION_STATUS_EMAIL_SUCCESS(
    state: AuthState,
    payload: UserSubscriptionStatusByEmailPayload,
  ) {
    state.apiState.checkSubscriptionStatusByEmailIds = {
      ...defaultApiState,
      success: true,
    };
    state.userSubscriptionStatusByEmail = payload;
  },
  CHECK_SUBSCRIPTION_STATUS_EMAIL_ERROR(state: AuthState, error: AxiosError) {
    state.apiState.checkSubscriptionStatusByEmailIds = {
      ...defaultApiState,
      error,
    };
  },
};
