























































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { State, Action } from 'vuex-class';
import { RootState } from '@/store/store';
import { MultipleRecurrenceFrequencyOptions } from '@/store/modules/projects/types/projects.types';
import {
  AuditDetail,
  AuditStatus,
  UpdateAuditGeneralSettingRequestPayload,
  UpdateAuditDataCollectionSettingRequestPayload,
  UpdateAuditStatusPayload,
  ResumeEndDatePayload,
} from '@/store/modules/audits/types/audits.types';
import { isTruthy, isDifferent } from '@/jbi-shared/util/watcher.vue-decorator';
import { isEqual as _isEqual } from 'lodash';
import { ToastProgrammatic as Toast } from 'buefy';
import BaseDialog from '../../../components/base/BaseDialog.vue';
import ArchiveModalBox from '../../../components/ArchiveModalBox.vue';
import SiteSettings from '@/views/Project/components/SiteSettings.vue';
import { cloneDeep as _cloneDeep, map as _map } from 'lodash';
import SettingActiveDataCollectionDialog from './SettingActiveDataCollectionDialog.vue';
import moment from 'moment';
import { getEndDateTime, getStartDateTime } from '../../../utils/date.util';

const dirtyMonths: string[] = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

@Component({
  components: {
    BaseDialog,
    SiteSettings,
  },
})
export default class AuditSettingsTab extends Vue {
  @Prop() public auditId!: number;
  @Prop() public auditDetail!: AuditDetail;
  @Prop() public auditSettingInfo!: any;
  public settingTab: number = 0;
  public isGeneralSettingSubmitButtonDisabled: boolean = true;
  public isDataCollectionSubmitButtonDisabled: boolean = true;
  public auditDataTimestamp: string = '';
  public auditDetailInfo: any = [];
  public auditSettingDetail: any = [];
  public isDataCollectionActive: boolean = false;
  public isCycleTypeAudit: boolean = false;
  public dataCollectionPeriodDate: Date[] = [];
  public generalStartDate: Date = getStartDateTime();
  public generalEndDate: Date | null = getEndDateTime();
  public auditStatus: AuditStatus = this.auditDetail.status
    ? this.auditDetail.status
    : AuditStatus.ACTIVE;
  public updateAuditStatusPayload: UpdateAuditStatusPayload = {
    auditId: this.auditId,
    status: this.auditStatus as AuditStatus, // setting current status od audit.
  };

  @Action('audits/updateAuditGeneralSettings')
  public updateAuditGeneralSettings!: (
    params: UpdateAuditGeneralSettingRequestPayload,
  ) => void;

  @Action('audits/updateAuditDataCollectionSettings')
  public updateAuditDataCollectionSettings!: (
    params: UpdateAuditDataCollectionSettingRequestPayload,
  ) => void;

  @Action('audits/getAuditDetail')
  public getAuditDetail!: (auditId: number) => void;

  @Action('audits/updateStatus')
  public updateAuditStatus!: (params: UpdateAuditStatusPayload) => void;

  @Action('audits/endAudit')
  public endAudit!: (auditId: number) => void;

  @Action('audits/resumeAudit')
  public resumeAudit!: (params: ResumeEndDatePayload) => void;

  @State((state: RootState) => state.audits.apiState.updateStatus.success)
  public updateStatusSuccess!: boolean;

  @State((state: RootState) => state.audits.dataCollectionPeriodTitle)
  public dataCollectionPeriodTitle!: any;

  @State((state: RootState) => state.audits.updatedAuditGeneralSettings)
  public updatedAuditGeneralSettings!: any;

  @State((state: RootState) => state.audits.updatedAuditDataCollectionSettings)
  public updatedAuditDataCollectionSettings!: any;

  @State((state: RootState) => state.audits.apiState.endAudit.success)
  public endDataCollectionAuditSuccess!: boolean;

  @State((state: RootState) => state.audits.apiState.resumeAudit.success)
  public resumeDataCollectionAuditSuccess!: boolean;

  get isAuditArchived(): boolean {
    return this.auditStatus === AuditStatus.ARCHIVED;
  }

  get editable(): boolean {
    if (
      this.auditDetail.project.status === 'Archived' ||
      this.auditStatus === AuditStatus.ARCHIVED
    ) {
      return false;
    }
    return true;
  }

  public updateDisableSettingsButton(type: string) {
    if (type === 'auditDescription') {
      this.isGeneralSettingSubmitButtonDisabled = _isEqual(
        this.auditSettingInfo.description,
        this.auditSettingDetail.description,
      );
    } else if (type === 'auditName') {
      this.isGeneralSettingSubmitButtonDisabled = _isEqual(
        this.auditSettingInfo.name,
        this.auditSettingDetail.name,
      );
    } else if (type === 'dataCollectionDate') {
      const auditDataCollectionDate = [
        getStartDateTime(this.auditDetail.startDate),
        getEndDateTime(this.auditDetail.endDate),
      ];
      this.isGeneralSettingSubmitButtonDisabled = _isEqual(
        auditDataCollectionDate,
        this.dataCollectionPeriodDate,
      );
    } else {
      this.isGeneralSettingSubmitButtonDisabled = _isEqual(
        this.auditSettingInfo.enableGrip,
        this.auditSettingDetail.enableGrip,
      );
    }
  }

  public updateGeneralSettingHandler() {
    this.generalStartDate = this.isCycleTypeAudit
      ? getStartDateTime(this.dataCollectionPeriodDate[0])
      : this.generalStartDate;

    this.generalEndDate = this.isCycleTypeAudit
      ? getEndDateTime(this.dataCollectionPeriodDate[1])
      : null;

    this.updateAuditGeneralSettings({
      auditId: this.auditId,
      auditName: this.auditSettingDetail.name,
      auditDescription: this.auditSettingDetail.description,
      enableGrip: this.auditSettingDetail.enableGrip,
      startDate: this.generalStartDate,
      endDate: this.generalEndDate as Date, // FIXME: This is a hack. Null cases need to be handled properly.
    });
  }

  public updateDataCollectionSettingHandler() {
    this.updateAuditDataCollectionSettings({
      auditId: this.auditId,
      auditDataTimestamp: this.auditDataTimestamp,
    });
  }

  public getDate(newValue: Date): string {
    return (
      newValue.getDate() +
      ' ' +
      dirtyMonths[newValue.getMonth()] +
      ' ' +
      newValue.getFullYear()
    );
  }

  public formatDateRange(date: Date): string {
    return this.getDate(date);
  }

  public formatDataCollectionDateRange(date: Date[]): string {
    return this.getDate(date[0]) + ' - ' + this.getDate(date[1]);
  }

  public mounted() {
    this.auditDetailInfo = _cloneDeep(this.auditDetail);
    this.auditSettingDetail = _cloneDeep(this.auditSettingInfo);
    this.auditDataTimestamp = this.auditDetailInfo.auditDataTimestamp;
    this.isDataCollectionActive = this.auditDetailInfo.endedAt ? false : true;
    this.isCycleTypeAudit =
      this.auditDetailInfo.auditCategory === 'Cycle' ? true : false;
    this.generalStartDate =
      this.auditDetailInfo.auditCategory === 'Cycle'
        ? moment(this.auditSettingDetail.startDate).toDate()
        : getStartDateTime(this.auditSettingDetail.startDate);

    this.generalEndDate = null;
    if (this.auditDetailInfo.auditCategory === 'Cycle') {
      if (this.auditSettingDetail.endDate) {
        this.generalEndDate = getEndDateTime(this.auditSettingDetail.endDate);
      } else {
        const endDate = moment(this.auditSettingDetail.startDate);
        switch (
          this.auditDetailInfo.project.settings[0].multipleRecurrenceType
        ) {
          case Object.keys(MultipleRecurrenceFrequencyOptions)[0]:
            endDate.add(this.auditDetailInfo.project.settings[0].interval, 'd');
            break;
          case Object.keys(MultipleRecurrenceFrequencyOptions)[1]:
            endDate.add(this.auditDetailInfo.project.settings[0].interval, 'w');
            break;
          case Object.keys(MultipleRecurrenceFrequencyOptions)[2]:
            endDate.add(this.auditDetailInfo.project.settings[0].interval, 'M');
            break;
          default:
            endDate.add(this.auditDetailInfo.project.settings[0].interval, 'y');
            break;
        }
        const newEndDate = endDate.subtract(1, 'd');
        this.generalEndDate = endDate.isBefore(new Date())
          ? getEndDateTime()
          : getEndDateTime(newEndDate);
      }
    }
    this.dataCollectionPeriodDate = [
      this.generalStartDate,
      this.generalEndDate as Date, // FIXME: This is a hack. Null cases need to be handled properly.
    ];
  }

  public handleArchiveAudit() {
    this.$buefy.modal.open({
      parent: this,
      component: ArchiveModalBox,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle: 'Archive Data Collection Period?',
        modalContent: `Archiving ${this.auditSettingDetail.name} will disable any ongoing data collection activies. You can find archived data collection in Project Dashboard under Data Collection Period.`,
      },
      events: {
        confirm: () => {
          this.updateAuditStatusPayload.status = AuditStatus.ARCHIVED;
          this.updateAuditStatus(this.updateAuditStatusPayload);
        },
      },
    });
  }

  public handleUnarchiveAudit() {
    this.$buefy.modal.open({
      parent: this,
      component: BaseDialog,
      hasModalCard: true,
      trapFocus: true,
      props: {
        title: `Unarchive Data Collection Period`,
        content:
          'This will enable data collection period to be edited. You may archive it again anytime',
        cancelButtonText: 'Unarchive',
        confirmButtonText: 'Cancel',
      },
      events: {
        cancel: () => {
          this.updateAuditStatusPayload.status = AuditStatus.ACTIVE;
          this.updateAuditStatus(this.updateAuditStatusPayload);
        },
      },
    });
  }

  public handleDeleteAudit() {
    this.$buefy.modal.open({
      parent: this,
      component: BaseDialog,
      hasModalCard: true,
      trapFocus: true,
      props: {
        title: `Delete Data Collection Period?`,
        content: `Deleting '${this.auditSettingDetail.name}' will remove it completely from PACES and you can’t access this data collection period.`,
        confirmButtonText: 'Delete',
      },
      events: {
        confirm: () => {
          this.updateAuditStatusPayload.status = AuditStatus.DELETED;
          this.updateAuditStatus(this.updateAuditStatusPayload);
        },
      },
    });
  }

  @Watch('updateStatusSuccess')
  @isTruthy
  public watchUpdatedStatus(): void {
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `Changes saved`,
    });
    this.auditStatus = this.updateAuditStatusPayload.status as AuditStatus;
    this.$emit('updatedAuditStatus', this.auditStatus);
    if (this.auditStatus === AuditStatus.DELETED) {
      this.$router.push({
        name: 'project-details',
        params: {
          projectId: this.auditDetail.project.id.toString(),
        },
      });
    }
  }

  @Watch('updatedAuditGeneralSettings')
  @isTruthy
  public updateSavedGeneralSettings(newValue: any) {
    this.isGeneralSettingSubmitButtonDisabled = true;
    const updatedGeneralData = {
      name: newValue.name,
      description: newValue.description,
      enableGrip: newValue.enableGrip,
      startDate: this.generalStartDate,
      endDate: this.generalEndDate,
    };
    this.$emit('updateAuditSettings', updatedGeneralData);
    Toast.open({
      message: `Changes saved`,
      position: 'is-top',
      type: 'is-dark',
    });
  }

  @Watch('updatedAuditDataCollectionSettings')
  @isTruthy
  public updateSavedDataCollectionSettings(newValue: any) {
    this.isDataCollectionSubmitButtonDisabled = true;
    this.getAuditDetail(this.auditId);
    Toast.open({
      message: `Changes saved`,
      position: 'is-top',
      type: 'is-dark',
    });
  }

  @Watch('endDataCollectionAuditSuccess')
  @isTruthy
  public watchEndDataCollectionAuditSuccess(newValue: any) {
    this.isDataCollectionActive = false;
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: 'Changes saved',
    });
  }

  @Watch('resumeDataCollectionAuditSuccess')
  @isTruthy
  public watchResumeDataCollectionAuditSuccess(newValue: any) {
    this.isDataCollectionActive = true;
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: 'Changes saved',
    });
  }

  @Watch('auditDataTimestamp')
  @isTruthy
  @isDifferent
  public watchAuditDataTimestamp(newValue: any) {
    this.isDataCollectionSubmitButtonDisabled = _isEqual(
      this.auditDetail.auditDataTimestamp,
      newValue,
    );
  }

  public actionDataCollectionAudit(isActive: boolean) {
    let title: string;
    let content: string;
    let confirmButtonText: string;
    if (isActive) {
      title = 'Stop Data Collection?';
      content = `Stopping data collection for '${this.auditSettingDetail.name}' will disable anyone from enterring data.`;
      confirmButtonText = 'Stop';
    } else {
      title = 'Resume Data Collection?';
      content = `Resuming data collection for '${this.auditSettingDetail.name}' will enable data entry.`;
      confirmButtonText = 'Resume';
    }
    this.$buefy.modal.open({
      parent: this,
      component: SettingActiveDataCollectionDialog,
      hasModalCard: true,
      trapFocus: true,
      props: {
        title,
        content,
        confirmButtonText,
        isActive,
        resumeEndDate: this.generalEndDate,
      },
      events: {
        confirm: (newValue: any) => {
          this.dataCollectionPeriodDate = [this.generalStartDate, newValue];
          if (isActive) {
            this.endAudit(this.auditId);
          } else {
            this.resumeAudit({
              auditId: this.auditId,
              endDate: newValue,
            });
          }
        },
      },
    });
  }
}
