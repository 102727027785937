

































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { get as _get, cloneDeep as _cloneDeep } from 'lodash';
import NotificationBell from 'vue-notification-bell';
import { State, Action } from 'vuex-class';
import { RootState } from '../../store/store';
import { isTruthy } from '../../jbi-shared/util/watcher.vue-decorator';
import ProjectOwnerRequestNotification from '../../views/Project/components/ProjectOwnerRequestNotification.vue';
import { UpdateNewNotificationPayload } from '../../store/modules/notification/types/notification.types';

@Component({
  components: {
    NotificationBell,
    ProjectOwnerRequestNotification,
  },
})
export default class BaseNotificationDropdown extends Vue {
  @Prop() public notificationInfo!: any;
  @Prop() public notificationCount!: number;
  @Prop() public notificationIds!: number[];

  public notificationDetail: any = [];
  public notificationNumber: number = 0;
  public hoverMessage: boolean = false;
  public isViewAllNotification: boolean = false;

  @Action('notifications/updateViewedNotification')
  public updateViewedNotification!: (
    payload: UpdateNewNotificationPayload,
  ) => void;

  @State((state: RootState) => state.notifications.userViewedNotifications)
  public userViewedNotifications!: any;

  public mounted() {
    this.notificationNumber = _cloneDeep(this.notificationCount);
  }

  public handleNavigateNotifications() {
    this.isViewAllNotification = true;
    if (this.notificationIds.length > 0) {
      this.updateViewedNotification({
        notificationIds: this.notificationIds,
      });
    } else {
      this.$router.replace('/notification');
    }
  }

  @Watch('userViewedNotifications')
  @isTruthy
  public watchUserViewedNotification(newValue: any) {
    if (this.$route.path !== '/notification' && this.isViewAllNotification) {
      this.$router.replace('/notification');
    }
  }
}
