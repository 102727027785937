
























































































































import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseLoading from '../../../components/base/BaseLoading.vue';
import BasePagination from '../../../components/base/BasePagination.vue';
import { PaginationMixin } from '../../../components/base/pagination.mixin';
import { ProjectCriteria } from '../../../store/modules/projects/types/projects.types';
import { GripAnalysis } from '../../../store/types/grip.types';
import TableDropdown from '../../AdminProjectManagement/components/TableDropdown.vue';
import ViewGripAnalysisDetailsModal from './ViewGripAnalysisDetailsModal.vue';

@Component({
  components: {
    BasePagination,
    BaseLoading,
    TableDropdown,
    ViewGripAnalysisDetailsModal,
  },
})
export default class ExportGripReportModalAnalysisList extends mixins(
  PaginationMixin,
) {
  @Prop() public gripAnalysisItems!: GripAnalysis[];
  @Prop() public selectedGripAnalysisIds!: number[];
  @Prop(Number) public totalNumberOfPage!: number;
  @Prop(Number) public totalCount!: number;
  @Prop(Boolean) public isFirstPage!: boolean;
  @Prop(Boolean) public isLastPage!: boolean;
  @Prop(Number) public startItemIndex!: number;
  @Prop(Number) public endItemIndex!: number;
  @Prop(String) public sortColumn!: string;
  @Prop(String) public sortOrder!: 'ASC' | 'DESC';
  public isAllSelected: boolean = false;
  public selectedAnalysisIds: number[] = [];

  public mounted(): void {
    this.selectedAnalysisIds = this.selectedGripAnalysisIds;
    this.isAllSelected =
      this.selectedAnalysisIds.length === this.gripAnalysisItems.length;
  }

  public toggleOption(analysisId: number): void {
    /**
     * checking checkbox is selcted or not
     * if already selected removing from  selected Array
     * else adding to selected array
     */
    if (this.selectedAnalysisIds.includes(analysisId)) {
      this.selectedAnalysisIds = this.selectedAnalysisIds.filter(
        (id) => id !== analysisId,
      );
    } else {
      this.selectedAnalysisIds = [...this.selectedAnalysisIds, analysisId];
    }

    /** checking all analysis are selected or not */
    this.isAllSelected =
      this.selectedAnalysisIds.length === this.gripAnalysisItems.length;

    this.$emit('updateSelectedAnalysis', this.selectedAnalysisIds);
  }

  public selectAllAttributes(): void {
    if (this.isAllSelected) {
      this.selectedAnalysisIds = [];
      this.isAllSelected = false;
    } else {
      this.selectedAnalysisIds = this.gripAnalysisItems.map((item) => item.id);
      this.isAllSelected = true;
    }

    this.$emit(
      'updateSelectedAnalysis',
      this.selectedAnalysisIds,
      this.isAllSelected,
    );
  }

  public handleSort(column: string): void {
    this.$emit('sort', {
      sortColumn: column,
      sortOrder: this.sortOrder === 'ASC' ? 'DESC' : 'ASC',
    });
  }

  public getAnalysisCriteria(gripAnalysis: GripAnalysis) {
    const analysisCriteria: ProjectCriteria[] = gripAnalysis.gripAnalysisCriteriaMaps
      .filter((criteriaMap) => !criteriaMap.criteria.isDeleted)
      .map((criteriaMap) => criteriaMap.criteria);

    if (!analysisCriteria.length) {
      return { criteria: null, viewDetails: false, moreCriteriaCount: 0 };
    } else {
      return {
        criteria: analysisCriteria[0],
        viewDetails: true,
        moreCriteriaCount: analysisCriteria.length - 1,
      };
    }
  }

  public handleViewDetails(gripAnalysis: GripAnalysis): void {
    this.$buefy.modal.open({
      parent: this,
      component: ViewGripAnalysisDetailsModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        gripAnalysis,
      },
      events: {
        close: () => {
          this.$emit('close');
        },
      },
    });
  }

  @Watch('selectedAnalysisIds')
  public watchSelectedAnalysisIds(): void {
    this.$emit(
      'updateSelectedAnalysis',
      this.selectedAnalysisIds,
      this.isAllSelected,
    );
  }
}
