











import { Component, Vue, Prop, Watch, Model } from 'vue-property-decorator';
import { State, Getter, Action, Mutation, namespace } from 'vuex-class';
import AuditCriteriaList from '@/views/Project/components/AuditCriteriaList.vue';

@Component({
  components: {
    AuditCriteriaList,
  },
})
export default class SelectCriteria extends Vue {
  @Prop()
  public value!: string;
}
