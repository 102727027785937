var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.auditSites && _vm.auditParticipants)?_c('div',{class:_vm.$style.projectCriteriaEditContainer},[_c('div',{class:_vm.$style.title},[_c('b',{staticClass:"is-uppercase"},[_vm._v("Project Administrator")])]),_c('div',{class:[
      _vm.$style.adjustMarginsCard,
      'flex',
      'flex-row',
      'list-item',
      'mb1' ]},[_c('div',{staticClass:"flex cell flex-column"},[_c('b',{staticClass:"text-size text-color padding-bottom8 bold"},[_vm._v(" "+_vm._s(_vm.projectAdminUsername)+" ")]),_c('p',{staticClass:"mbx"},[_vm._v("Project Administrator")])])]),_c('div',{class:_vm.$style.title},[_c('b',{staticClass:"is-uppercase"},[_vm._v("Participant(S)")])]),_vm._l((_vm.items),function(item){return _c('div',{key:((item.participantRole) + "_" + (item.participantRoleId)),class:[
      item.level === 'Project' ? _vm.$style.adjustMarginsCard : null,
      'flex',
      'flex-row',
      'list-item',
      'mb1' ]},[_c('div',{staticClass:"flex cell flex-column"},[_c('div',{staticClass:"mbx"},[_c('b',{staticClass:"text-size text-color padding-right20 bold"},[_vm._v(" "+_vm._s(_vm.getParticipantName(item))+" ")]),(
            _vm.invitationStatus(item) === 'Expired' && item.level !== 'Project'
          )?_c('span',{staticClass:"tag is-light mrx"},[_c('span',{staticClass:"has-text-danger"},[_vm._v(_vm._s(_vm.invitationStatus(item)))])]):_vm._e()]),_c('p',{staticClass:"mbx"},[_vm._v(_vm._s(item.participantRole))]),(item.level !== 'Project')?_c('div',[(item.participantSites.length !== _vm.auditSites.length)?_c('p',[_vm._v(" "+_vm._s(item.participantSites.length)+" Selected Sites ")]):_c('p',[_vm._v("All Sites")])]):_vm._e()]),(
        _vm.editable &&
        item.level !== 'Project' &&
        _vm.projectOwner.email !== item.userEmail
      )?_c('div',[_c('a',{class:_vm.$style.editBtn,on:{"click":function($event){return _vm.handleEdit(item)}}},[_c('b-icon',{attrs:{"icon":"pencil","size":"is-small"}})],1),_c('a',{class:_vm.$style.deletebtn,on:{"click":function($event){return _vm.handleDelete(item)}}},[_c('b-icon',{attrs:{"icon":"delete","size":"is-small"}})],1)]):_vm._e()])}),(_vm.items.length === 0)?_c('Card',{class:_vm.$style.noItem,attrs:{"shadowless":true}},[_vm._v(" Please invite participants ")]):_vm._e(),(_vm.editable)?_c('a',{class:_vm.$style.addParticipant,on:{"click":_vm.handleCreate}},[_vm._v(" + Add Participant ")]):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }