var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showProjectDetail)?_c('DefaultLayout',{attrs:{"name":"DefaultLayout"}},[_c('vue-headful',{attrs:{"title":_vm.getPageTitle('Project Detail')}}),[_c('HeroBar',{class:_vm.$style.projectDetailPageContainer},[(_vm.projectSettingInfo.name.length > 50)?_c('b-tooltip',{class:_vm.$style.tooltipTitle,attrs:{"label":_vm.projectSettingInfo.name,"multilined":"","position":"is-right"}},[_vm._v(" "+_vm._s(_vm.projectSettingInfo.name.substring(0, 50) + '...')+" ")]):_c('span',{class:_vm.$style.projectTitle},[_vm._v(_vm._s(_vm.projectSettingInfo.name))])],1),(_vm.isProjectArchived)?_c('article',{staticClass:"tile notification bg-primary-light p1"},[_c('small',[_vm._v(_vm._s(_vm.message))])]):_vm._e(),_c('b-tabs',{staticClass:"dashboard-tabs",class:_vm.$style.tabLabel,attrs:{"animated":false},on:{"input":_vm.tabChange},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('b-tab-item',{attrs:{"label":"DASHBOARD"}},[(
            !_vm.isLoading && _vm.showDashboardTab && _vm.isUserProjectOwnerOrCoordinator
          )?_c('ProjectDashboardTab',{key:_vm.dashboardTabKey,attrs:{"editable":_vm.editable,"isProjectOwner":_vm.isProjectOwner,"isUserProjectOwnerOrCoordinator":_vm.isUserProjectOwnerOrCoordinator},on:{"changeActiveTab":_vm.changeActiveTab}}):_vm._e()],1),_c('b-tab-item',{attrs:{"label":"CRITERIA"}},[(
            !_vm.isLoading && _vm.showCriteriaTab && _vm.isUserProjectOwnerOrCoordinator
          )?_c('ProjectCriteriaTab',{key:_vm.criteriaTabKey,attrs:{"projectId":_vm.projectId,"projectDetail":_vm.projectDetail,"editable":_vm.editable,"isUserProjectOwnerOrCoordinator":_vm.isUserProjectOwnerOrCoordinator}}):_vm._e()],1),_c('b-tab-item',{class:_vm.$style.tabItem,attrs:{"label":"PARTICIPANTS"}},[(_vm.showParticipantsTab && _vm.isUserProjectOwnerOrCoordinator)?_c('ParticipantTab',{key:_vm.participantsTabKey,attrs:{"editable":_vm.editable,"projectOwner":_vm.projectOwner}}):_vm._e()],1),_c('b-tab-item',{class:_vm.$style.tabItem,attrs:{"label":"GRIP"}},[(!_vm.isLoading && _vm.activeTab === _vm.getTabIndex('#grip'))?_c('GripTab',{key:_vm.gripTabKey,attrs:{"editable":_vm.editable,"projectId":_vm.projectId,"projectDetail":_vm.projectDetail},on:{"changeActiveTab":_vm.changeActiveTab}}):_vm._e()],1),_c('b-tab-item',{class:_vm.$style.tabItem,attrs:{"label":"REPORTS"}},[(_vm.showReportTab && _vm.isUserProjectOwnerOrCoordinator)?_c('ProjectReportsTab',{key:_vm.reportTabKey,attrs:{"editable":_vm.editable}}):_vm._e()],1),_c('b-tab-item',{attrs:{"label":"SETTINGS"}},[_c('div',{class:_vm.$style.settingTabItem},[(
              !_vm.isLoading && _vm.showSettingTab && _vm.isUserProjectOwnerOrCoordinator
            )?_c('ProjectSettingsTab',{key:_vm.settingTabKey,attrs:{"projectId":_vm.projectId,"projectDetail":_vm.projectDetail,"projectSettingInfo":_vm.projectSettingInfo,"initialValue":_vm.initialValue,"isProjectOwner":_vm.isProjectOwner,"activeTab":_vm.activeSettingsTab},on:{"updateProjectSettings":_vm.updateProjectSettingInfo,"updatedProjectStatus":_vm.handleUpdatedProjectStatus}}):_vm._e()],1)]),_c('b-tab-item',{attrs:{"label":"ACTIVITY LOG"}},[_c('div',{class:_vm.$style.activityLogContainer},[(
              !_vm.isLoading &&
              _vm.showActivityLogTab &&
              _vm.isUserProjectOwnerOrCoordinator
            )?_c('ProjectEventLogs',{key:_vm.activityLogTabKey,attrs:{"projectDetail":_vm.projectDetail,"projectId":_vm.projectId}}):_vm._e()],1)])],1)]],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }