






















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { State, Action } from 'vuex-class';
import { RootState } from '@/store/store';
import {
  AuditCriteria,
  AuditDetail,
  AuditSite,
  DashboardChartData,
  DashboardChartDataRequestPayload,
} from '@/store/modules/audits/types/audits.types';
import SiteFilter from '@/views/Audit/components/SiteFilter.vue';
import CriteriaFilter from '@/views/Audit/components/CriteriaFilter.vue';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import VerticalBarChart from '@/components/reports/VerticalBarChart';
import { useUserEmail } from '@/utils/user.util';
const dirtyMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

@Component({
  components: {
    VerticalBarChart,
    CriteriaFilter,
    SiteFilter,
  },
})
export default class ChartView extends Vue {
  @Action('audits/getAuditCriteria')
  public getAuditCriteria!: (auditId: number) => void;

  @Action('audits/getDashboardChartData')
  public getDashboardChartData!: (
    payload: DashboardChartDataRequestPayload,
  ) => void;

  @Prop() public auditSites!: AuditSite[];

  @State((state: RootState) => state.audits.auditDetail)
  public auditDetail!: AuditDetail;

  @State((state: RootState) => state.audits.auditCriteria)
  public auditCriteria!: AuditCriteria[];

  @State((state: RootState) => state.audits.dashboardChartData)
  public dashboardChartData!: DashboardChartData[];

  public today = new Date();
  public maxDate = new Date();
  public selectedCriteriaIds: number[] = [];
  public selectedAuditSiteIds: number[] = [];
  public todayDate: Date = new Date();
  public selectedPeriod: Date[] = [
    new Date(
      this.todayDate.getFullYear(),
      this.todayDate.getMonth(),
      this.todayDate.getDate() - 7,
    ),
    this.todayDate,
  ];
  public barChartKey: number = Math.floor(Math.random() * 999);

  public chartData = {
    labels: [],
    datasets: [
      {
        label: 'Total Data Collected',
        data: [],
        backgroundColor: Array(0).fill('#1D445E'),
        barThickness: 60,
      },
    ],
  };

  get auditId(): number {
    return +this.$route.params.auditId;
  }

  public options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
          gridLines: {
            display: true,
          },
          scaleLabel: {
            labelString: 'New Responses',
            display: true,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          scaleLabel: {
            labelString: 'Date',
            display: true,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  get userEmail(): string | undefined {
    return useUserEmail.call(this);
  }

  public mounted() {
    this.selectedAuditSiteIds = this.auditSites.map(
      (auditSite) => auditSite.id,
    );
    this.getAuditCriteria(this.auditId);
  }

  @Watch('auditCriteria')
  @isTruthy
  public watchAuditCriteria() {
    this.selectedCriteriaIds = this.auditCriteria.map(
      (criteria) => criteria.id,
    );
    this.filterChartData();
  }

  get siteList(): any[] {
    if (!this.auditSites) {
      return [];
    }
    return this.auditSites.map((auditSite) => ({
      siteId: auditSite.site.id,
      name: auditSite.site.name,
      auditSiteId: auditSite.id,
    }));
  }

  public applySiteFilter(newValue: number[]) {
    this.selectedAuditSiteIds = newValue;
    this.filterChartData();
  }

  get criteriaList() {
    return this.auditCriteria;
  }

  public applyCriteriaFilter(newValue: number[]) {
    this.selectedCriteriaIds = newValue;
    this.filterChartData();
  }

  public filterPeriod(newValue: Date[]) {
    this.selectedPeriod = newValue;
    this.filterChartData();
  }

  public filterChartData() {
    const payload: DashboardChartDataRequestPayload = {
      auditId: this.auditId,
      fromDate: this.setTimeStart(this.selectedPeriod[0]),
      toDate: this.setTimeEnd(this.selectedPeriod[1]),
      siteIds: this.selectedAuditSiteIds,
      criteriaIds: this.selectedCriteriaIds,
    };

    this.getDashboardChartData(payload);
  }

  public setTimeEnd(date: Date) {
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    return date;
  }

  public setTimeStart(date: Date) {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    return date;
  }

  public formatDateRange(date: Date[]) {
    return this.getDate(date[0]) + ' - ' + this.getDate(date[1]);
  }

  public getDate(newValue: Date) {
    return (
      newValue.getDate() +
      ' ' +
      dirtyMonths[newValue.getMonth()] +
      ' ' +
      newValue.getFullYear()
    );
  }

  public accumulateNewResponseByDate(
    data: DashboardChartData[],
  ): DashboardChartData[] {
    const accumulatedData: DashboardChartData[] = [];
    data.reduce((prev, curr) => {
      const pureDate: string = new Date(curr.createdDate).toLocaleDateString();
      if (!prev[pureDate]) {
        prev[pureDate] = { pureDate, sum: 0, createdDate: curr.createdDate };
        accumulatedData.push(prev[pureDate]);
      }
      prev[pureDate].sum += +curr.sum;
      return prev;
    }, {} as any);
    return accumulatedData;
  }

  @Watch('dashboardChartData')
  @isTruthy
  public watchDashboardChartData(newValue: DashboardChartData[]) {
    const lables: any = [];
    const data: any = [];

    this.accumulateNewResponseByDate(newValue).forEach(
      (chartData: DashboardChartData) => {
        lables.push(this.getDate(new Date(chartData.createdDate)));
        data.push(chartData.sum);
      },
    );
    this.chartData = {
      labels: lables,
      datasets: [
        {
          label: 'Total Data Collected',
          data,
          backgroundColor: Array(data.length).fill('#1D445E'),
          barThickness: 60,
        },
      ],
    };
    this.barChartKey += 1;
  }
}
