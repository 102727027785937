



























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { cloneDeep as _cloneDeep } from 'lodash';
import { State, Action } from 'vuex-class';
import { RootState } from '../../../store/store';
import { isTruthy } from '../../../jbi-shared/util/watcher.vue-decorator';
import { UpdateCancelProjectRequestPayload } from '@/store/modules/projects/types/projects.types';

@Component({ components: {} })
export default class ProjectOwnerCancelRequestModal extends Vue {
  @Prop()
  public modalContent!: string;
  @Prop()
  public projectOwnerRequestId!: number;
  @Prop()
  public projectId!: number;
  @Prop()
  public token!: string;
  @Prop()
  public isReclaimProject!: boolean;
  @Prop()
  public isSystemAdmin!: boolean;

  @Action('projects/updateCancelProjectRequest')
  public updateCancelProjectRequest!: (
    payload: UpdateCancelProjectRequestPayload,
  ) => void;

  @State(
    (state: RootState) =>
      state.projects.apiState.updateCancelProjectRequest.success,
  )
  public cancelProjectRequestStateSuccess!: boolean;

  public closeModal() {
    this.$emit('close');
  }

  public cancelRequest() {
    this.updateCancelProjectRequest({
      projectOwnerRequestId: this.projectOwnerRequestId,
      projectId: this.projectId,
      isReclaimProject: this.isReclaimProject,
      token: this.token,
      isSystemAdmin: this.isSystemAdmin,
    });
    this.$emit('close');
  }

  @Watch('cancelProjectRequestStateSuccess')
  @isTruthy
  public watchCancelProjectRequestState() {
    this.$emit('confirm');
    this.$emit('close');
  }
}
