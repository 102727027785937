import { render, staticRenderFns } from "./InfoModal.vue?vue&type=template&id=7baf249f&"
import script from "./InfoModal.vue?vue&type=script&lang=ts&"
export * from "./InfoModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports