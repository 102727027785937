





































import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseLoading from '../../../components/base/BaseLoading.vue';
import { ProjectCriteria } from '../../../store/modules/projects/types/projects.types';
import {
  CreateGripAnalysisBarrierPayload,
  CreateGripAnalysisEnablerPayload,
  CreateGripAnalysisStrategyPayload,
  GripAnalysisDTO,
  GripItemReloadDTO,
  UpdateGripAnalysisBarrierPayload,
  UpdateGripAnalysisEnablerPayload,
  UpdateGripAnalysisStrategyPayload,
} from '../../../store/types/grip.types';
import GripAnalysisBarriers from './GripAnalysisBarriers.vue';
import GripAnalysisCriteria from './GripAnalysisCriteria.vue';

@Component({
  components: { BaseLoading, GripAnalysisCriteria, GripAnalysisBarriers },
})
export default class GripAnalysisDetails extends Vue {
  @Prop() public projectId!: number;
  @Prop() public isDataCollector!: boolean;
  @Prop() public isSideBarOpen!: boolean;
  @Prop() public gripAnalysisDetails!: GripAnalysisDTO;
  @Prop() public selectedCriteria!: ProjectCriteria[];
  @Prop() public updatedItem!: GripItemReloadDTO;

  public handleUpdateGripAnalysis(): void {
    this.$emit('updateGripAnalysis');
  }

  public handleAddGripAnalysisBarrier(barrierDetails: {
    barrierPayload: CreateGripAnalysisBarrierPayload;
    isNew: boolean;
  }): void {
    this.$emit('addGripAnalysisBarrier', barrierDetails);
  }

  public handleUpdateGripAnalysisBarrier(
    barrierPayload: UpdateGripAnalysisBarrierPayload,
  ): void {
    this.$emit('updateGripAnalysisBarrier', barrierPayload);
  }

  public handleDeleteGripAnalysisBarrier(gripAnalysisBarrierId: number): void {
    this.$emit('deleteGripAnalysisBarrier', gripAnalysisBarrierId);
  }

  public handleAddGripAnalysisEnabler(enablerDetails: {
    enablerPayload: CreateGripAnalysisEnablerPayload;
    isNew: boolean;
  }): void {
    this.$emit('addGripAnalysisEnabler', enablerDetails);
  }

  public handleUpdateGripAnalysisEnabler(
    enablerPayload: UpdateGripAnalysisEnablerPayload,
  ): void {
    this.$emit('updateGripAnalysisEnabler', enablerPayload);
  }

  public handleDeleteGripAnalysisEnabler(gripAnalysisEnablerId: number) {
    this.$emit('deleteGripAnalysisEnabler', gripAnalysisEnablerId);
  }

  public handleAddGripAnalysisStrategy(strategyDetails: {
    strategyPayload: CreateGripAnalysisStrategyPayload;
    isNew: boolean;
  }): void {
    this.$emit('addGripAnalysisStrategy', strategyDetails);
  }

  public handleUpdateGripAnalysisStrategy(
    strategyPayload: UpdateGripAnalysisStrategyPayload,
  ): void {
    this.$emit('updateGripAnalysisStrategy', strategyPayload);
  }

  public handleDeleteGripAnalysisStrategy(
    gripAnalysisStrategyId: number,
  ): void {
    this.$emit('deleteGripAnalysisStrategy', gripAnalysisStrategyId);
  }

  public handleUpdatedItemReload(data: GripItemReloadDTO): void {
    this.$emit('handleUpdatedItemReload', data);
  }
}
