
























































































































import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {Site} from '@/store/modules/projects/types/projects.types';
import { cloneDeep as _cloneDeep, map as _map } from 'lodash';
import {isDifferent} from '@/jbi-shared/util/watcher.vue-decorator';

@Component({ components : {} })

export default class ParticipantInputs extends Vue {
    @Prop()
    public localParticipantList !: any[];
    @Prop()
    public participantIndex !: number;
    @Prop()
    public localSelectedSiteArray !: Site[];

    public dirtyEmail: string = '';
    public dirtyRole: string | null = null;
    public dirtySites: any[] = [];
    public dirtyParticipantList!: any[];
    public dirtyAllSiteFlag: boolean = true;
    public dirtySiteFlag: string[] = [];

    public updateSelection(event: any) {
        const value = event.target.value;
        const isChecked = event.target.checked;

        if (value === 'All Sites') {
            if (isChecked) {
                this.dirtySites = [];
                this.dirtySiteFlag = [];
                this.localSelectedSiteArray.map((temp: Site) => {
                    this.dirtySiteFlag.push(temp.name);
                    this.dirtySites.push(temp.name);
                });
            } else {
                this.dirtySites = [];
                this.dirtySiteFlag = [];
            }
        } else {
            this.dirtyAllSiteFlag = false;
            if (isChecked) {
                this.dirtySites.push(value);
                if (this.dirtySites.length === this.localSelectedSiteArray.length) {
                    this.dirtyAllSiteFlag = true;
                }
            } else {
                this.dirtySites = this.dirtySites.filter((elem) => elem !== value);
            }
        }
    }

    public mounted() {
        this.populateData();
    }

    public populateData() {

        if (this.localParticipantList[this.participantIndex] !== undefined) {
            this.dirtyEmail = this.localParticipantList[this.participantIndex].email;
            this.dirtyRole = this.localParticipantList[this.participantIndex].role;
            if (this.dirtyEmail === '') {
                this.localParticipantList[this.participantIndex].sites.map((temp: Site) => {
                    this.dirtySiteFlag.push(temp.name);
                    this.dirtySites.push(temp.name);
                });
            } else {
                const dirtyIndex = this.participantIndex;
                if (this.localParticipantList[dirtyIndex].sites.length === this.localSelectedSiteArray.length) {
                    this.localSelectedSiteArray.map((temp: Site) => {
                        this.dirtySiteFlag.push(temp.name);
                        this.dirtySites.push(temp.name);
                    });
                    this.dirtyAllSiteFlag = true;
                } else {
                    this.dirtySites = this.localParticipantList[this.participantIndex].sites.map((x: Site) => x.name);
                    this.dirtyAllSiteFlag = false;
                    this.dirtySiteFlag = _cloneDeep(this.dirtySites);
                }
            }
        }
    }

    get siteOptions() {
        return this.localSelectedSiteArray;
    }

    @isDifferent
    @Watch('localSelectedSiteArray')
    public watchLocalSelectedSiteArray(newValue: string) {
        this.localSelectedSiteArray.map((temp: Site) => {
            if (!this.dirtySites.includes(temp.name)) {
                this.dirtySites.push(temp.name);
            }
            if (!this.dirtySiteFlag.includes(temp.name)) {
                this.dirtySiteFlag.push(temp.name);
            }
        });
    }

    @isDifferent
    @Watch('dirtyEmail')
    public watchDirtyEmail(newValue: string) {
        this.emitParticipantList();
    }

    @isDifferent
    @Watch('dirtyRole')
    public watchDirtyRole(newValue: string) {
        this.emitParticipantList();
    }

    @Watch('dirtySites')
    public watchDirtySites(newValue: any) {
        this.emitParticipantList();
    }

    public emitParticipantList() {
        this.dirtyParticipantList = _cloneDeep(this.localParticipantList);
        let dirtySelectedSites = [];
        if (this.dirtySites.length === this.localSelectedSiteArray.length) {
            dirtySelectedSites = this.localSelectedSiteArray;
        } else {
            dirtySelectedSites = this.localSelectedSiteArray.filter((temp) => this.dirtySites.includes(temp.name));
        }
        if (this.dirtyEmail.trim() && this.dirtyRole && this.dirtyRole.trim()) {
            this.dirtyParticipantList[this.participantIndex] = {
                email: this.dirtyEmail,
                role: this.dirtyRole,
                sites: dirtySelectedSites,
            };
            this.$emit('updateInviteUserArray', this.dirtyParticipantList);
        }
    }

    get indexToDisplay() {
        return this.participantIndex + 1;
    }

    public removeParticipant() {
        this.$emit('removeSelectedParticipant', this.participantIndex);
    }

    public dropdownItemClick(dirtyValue: any) {
        let checked;
        if (this.dirtySiteFlag.includes(dirtyValue.name)) {
            const dirtySiteFlagIndex = this.dirtySiteFlag.indexOf(dirtyValue.name);
            this.dirtySiteFlag.splice(dirtySiteFlagIndex, 1);
            checked = false;
        } else {
            this.dirtySiteFlag.push(dirtyValue.name);
            checked = true;
        }
        const dirtyEvent = {
            target: {
                value: dirtyValue.name,
                checked,
            },
        };
        this.updateSelection(dirtyEvent);
    }

    public dropdownAllSitesItemClick(dirtyValue: any) {
        this.dirtyAllSiteFlag = !this.dirtyAllSiteFlag;
        const dirtyEvent = {
            target: {
                value: dirtyValue,
                checked: this.dirtyAllSiteFlag,
            },
        };
        this.updateSelection(dirtyEvent);
    }

}
