

















































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { ApiState } from '../../../store/types/general.types';
import { Debounce } from '../../../jbi-shared/util/debounce.vue-decorator';
import BaseLoading from '../../../components/base/BaseLoading.vue';
import { ProjectCriteria } from '../../../store/modules/projects/types/projects.types';

@Component({
  components: { BaseLoading },
})
export default class ExistingCriteriaList extends Vue {
  @Prop() public projectId!: number;
  @Prop() public analysisCriteriaIds!: number[];
  @Prop() public isGripAnalysis!: boolean;

  public currentCriteriaSelection: ProjectCriteria[] = [];
  public currentCriteriaIds: number[] = [];
  public isLoading: boolean = true;
  public searchTitle: string = '';
  public isButtonDisabled: boolean = true;
  public criteriaInfo: string =
    'This criteria list can be amended for use of GRiP Report only.';

  @Action('projects/getFilteredProjectCriteria')
  public getFilteredProjectCriteria!: (payload: {
    projectId: number;
    params: { title: string };
  }) => void;

  @State((state) => state.projects.projectCriteria)
  public projectCriteria!: ProjectCriteria[];

  @State((state) => state.projects.apiState.getProjectCriteria)
  public getProjectCriteriaApiState!: ApiState;

  get itemList(): ProjectCriteria[] {
    return this.projectCriteria ? this.projectCriteria : [];
  }

  public mounted(): void {
    this.getFilteredProjectCriteria({
      projectId: this.projectId,
      params: { title: this.searchTitle },
    });
  }

  public collapseSideNav(): void {
    this.$emit('collapseSideNav');
  }

  public changeSelectedCriteria(
    currentCriteriaIds: number[],
    projectCriteria: ProjectCriteria,
  ): void {
    if (currentCriteriaIds.includes(projectCriteria.id)) {
      this.currentCriteriaSelection.push(projectCriteria);
    } else {
      this.currentCriteriaSelection = this.currentCriteriaSelection.filter(
        (selectionCriteria) => selectionCriteria.id !== projectCriteria.id,
      );
    }

    /** Existing selected criteria of analysis */
    const analysisCriteriaIds: string = JSON.stringify(
      this.analysisCriteriaIds.sort((a, b) => a - b),
    );

    /** Current selectionof criteria */
    const selectedCriteria: string = JSON.stringify(
      this.currentCriteriaSelection
        .map((criteria) => criteria.id)
        .sort((a, b) => a - b),
    );

    /** Disabled based on criteria selection */
    this.isButtonDisabled =
      this.currentCriteriaSelection.length > 0 &&
      analysisCriteriaIds !== selectedCriteria
        ? false
        : true;
  }

  public handleAddCriteria(): void {
    const selectedCriteriaIds: number[] = [];
    this.projectCriteria.forEach((criteria) => {
      if (this.currentCriteriaIds.includes(criteria.id)) {
        selectedCriteriaIds.push(criteria.id);
      }
    });

    if (!this.isButtonDisabled) {
      this.$emit('updateSelectedCriteria', {
        selectedCriteriaIds,
        update: true,
      });
    }
  }

  @Watch('searchTitle')
  @Debounce(500)
  public onTitleSearch(): void {
    this.getFilteredProjectCriteria({
      projectId: this.projectId,
      params: { title: this.searchTitle },
    });
  }

  @Watch('getProjectCriteriaApiState.loading')
  public watchGetProjectCriteriaLoading(): void {
    this.isLoading = true;
  }

  @Watch('getProjectCriteriaApiState.success')
  public watchGetProjectCriteriaSuccess(): void {
    this.isLoading = false;
    if (this.analysisCriteriaIds && this.analysisCriteriaIds.length > 0) {
      this.analysisCriteriaIds.forEach((criteriaId) => {
        const criteria: ProjectCriteria | undefined = this.projectCriteria
          ? this.projectCriteria.find(
              (projectCriteria) => projectCriteria.id === criteriaId,
            )
          : undefined;

        if (criteria) {
          this.currentCriteriaIds.push(criteriaId);
          if (criteria) {
            this.changeSelectedCriteria(this.currentCriteriaIds, criteria);
          }
        }
      });
    }
    const selectedCriteriaIds: number[] = this.currentCriteriaSelection.map(
      (criteria) => criteria.id,
    );
    this.$emit('updateSelectedCriteria', {
      selectedCriteriaIds,
      update: false,
    });
  }
}
