









import { Component, Vue, Prop, Watch, Model } from 'vue-property-decorator';
import { State, Getter, Action, Mutation, namespace } from 'vuex-class';

@Component({
  components: {},
})
export default class ListItem extends Vue {
  @Prop()
  public title!: string;
}
