
















































import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import { cloneDeep as _cloneDeep } from 'lodash';
import dayjs from 'dayjs';
import {ProjectReportPeriod} from '@/store/modules/projects/types/projects.types';
const dirtyMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

@Component({ components : {} })

export default class ProjectAnalysisPeriodPicker extends Vue {
    @Prop()
    public selectedPeriod!: ProjectReportPeriod;
    @Prop({default: false})
    public isDisabled!: boolean;
    @Prop()
    public projectAuditIds!: number[];

    public dirtySelectedPeriod: any = null;
    public dirtyPeriodRange: Date[] = [];
    public showEditLabelInput: boolean = false;
    public newLabel: string = '';
    public emptyLabelError: boolean = false;

    public mounted() {
        this.dirtySelectedPeriod = _cloneDeep(this.selectedPeriod);
        const startDate: Date = new Date(this.getStartDate);
        const endDate: Date = new Date(this.getEndDate);
        this.dirtyPeriodRange.push(startDate);
        this.dirtyPeriodRange.push(endDate);
    }

    get getStartDate(): string {
        return dayjs(this.dirtySelectedPeriod.startedAt).startOf('day').format();
    }

    get getEndDate(): string {
        if (this.dirtySelectedPeriod.endedAt !== null) {
            return dayjs(this.dirtySelectedPeriod.endedAt).endOf('day').format();
        }
        return dayjs().endOf('day').format();
    }

    get getLabel(): string {
        if (this.dirtySelectedPeriod.title.length > 45) {
            return this.dirtySelectedPeriod.title.slice(0, 43) + '...';
        }
        return this.dirtySelectedPeriod.title;
    }

    public updatePeriod(newValue: Date[]) {
        const updatedSelectedPeriod: any = this.getUpdatedSelectedPeriod(newValue);
        this.$emit('applyAnalysisPeriod', updatedSelectedPeriod);
    }

    public getUpdatedSelectedPeriod(newDateRange: Date[]): any {
        if (dayjs(this.getStartDate).diff(dayjs(newDateRange[0]).startOf('day').format()) !== 0
            ||
            dayjs(this.getEndDate).diff(dayjs(newDateRange[1]).endOf('day').format()) !== 0
        ) {
            return {
                title: 'Custom Period',
                startedAt: new Date(dayjs(newDateRange[0]).startOf('day').format()),
                endedAt: new Date(dayjs(newDateRange[1]).endOf('day').format()),
                isAudit: false,
                auditIds: this.projectAuditIds,
            };
        } else {
            return this.dirtySelectedPeriod;
        }
    }

    public getDate(newValue: Date): string {
        return newValue.getDate() + ' ' + dirtyMonths[newValue.getMonth()] + ' ' + newValue.getFullYear();
    }

    public formatDateRange(date: Date[]): string {
        if (this.dirtySelectedPeriod.endedAt !== null) {
            return this.getDate(date[0]) + ' - ' + this.getDate(date[1]);
        }
        return this.getDate(date[0]) + ' - ' + 'Ongoing';
    }

    public handleDoubleClickLabel() {
        if (!this.dirtySelectedPeriod.isAudit) {
            this.newLabel = this.dirtySelectedPeriod.title;
            this.showEditLabelInput = true;
        }
    }

    public updateLabel() {
        if (this.newLabel !== '') {
            this.dirtySelectedPeriod.title = this.newLabel.trim();
            this.showEditLabelInput = false;
            this.$emit('applyAnalysisPeriod', this.dirtySelectedPeriod);
        }
    }

    @Watch('newLabel')
    public watchNewLabel(value: string) {
        if (value === '') {
            this.emptyLabelError = true;
        } else {
            this.emptyLabelError = false;
        }
    }
}
