import {
  ChartType,
  ProjectSite,
  ProjectCriteria,
  ReportSamplingDto,
} from '@/store/modules/projects/types/projects.types';
import { chartColorSet, labelSplitIndex } from '@/store/types/general.types';
import { cloneDeep as _cloneDeep, get as _get, map as _map } from 'lodash';
import { CRITERION_TYPE } from '@/store/types/criterions.types';
import Chart from 'chart.js';
import dayjs from 'dayjs';
import {
  getAdHocSampling,
  getConsecutiveMinMaxSampling,
  getDefaultSampling,
} from '@/components/reports/utils/ReportSampling.util';

export class GenerateSingleSiteComparisonPdf {
  public dirtyMonths: string[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];

  public create(
    result: any,
    payload: any,
    analysisTitle: string,
    jbiLogo: string,
    projectTitle: string,
    projectSites: ProjectSite[],
    projectCriteria: ProjectCriteria[],
  ) {
    const exportDateTime = dayjs().format('D MMM YYYY, HH:mm A');
    let reportContent = {
      pageMargins: [60, 60, 60, 60],
      content: [],
      footer: (currentPage: number) => {
        return {
          columns: [
            {
              text: 'Exported on ' + exportDateTime,
              alignment: 'left',
              color: '#444F51',
              fontSize: 11,
              margin: [60, 40, 0, 0],
            },
            {
              text: currentPage.toString(),
              alignment: 'right',
              color: '#444F51',
              fontSize: 11,
              margin: [0, 40, 60, 0],
            },
          ],
        };
      },
      pageBreakBefore: (currentNode: any) => {
        return currentNode.pageNumbers.length > 1 && currentNode.unbreakable;
      },
    };
    reportContent = this.generateReportContent(
      reportContent,
      result,
      payload,
      jbiLogo,
      projectTitle,
      projectSites,
      projectCriteria,
      analysisTitle,
    );
    return reportContent;
  }

  public generateReportContent(
    reportContent: any,
    result: any,
    payload: any,
    jbiLogo: string,
    projectTitle: string,
    projectSites: ProjectSite[],
    projectCriteria: ProjectCriteria[],
    analysisTitle: string,
  ) {
    reportContent.content.push(this.setReportTitle(jbiLogo));
    reportContent.content.push(this.setProjectName(projectTitle));
    reportContent.content.push(this.setSubTitle(analysisTitle));
    reportContent.content.push({
      text: 'Site(s)',
      bold: true,
      color: '#1D70B8',
      fontSize: 11,
    });
    reportContent.content.push(this.setSelectedSites(projectSites, payload));
    reportContent.content.push({
      text: 'Data Collection Period Criteria',
      bold: true,
      color: '#1D70B8',
      fontSize: 11,
    });
    reportContent.content.push(
      this.setSelectedCriteria(projectCriteria, payload),
    );
    if (payload.checkIndividualCriteria) {
      const {
        allCharts,
        renderBooleanTables,
        renderBooleanSamplingTables,
        renderMixedTables,
        renderMixedSamplingTables,
      } = this.generateComparisonIndividualCriteria(
        result,
        payload,
        projectCriteria,
        projectSites,
      );
      allCharts.forEach((individualChart, individualChartIndex) => {
        reportContent.content.push(individualChart);
        reportContent.content.push(renderBooleanTables[individualChartIndex]);
        reportContent.content.push(
          renderBooleanSamplingTables[individualChartIndex],
        );
      });
      renderMixedTables.forEach((individualTable, individualTableIndex) => {
        reportContent.content.push(individualTable);
        reportContent.content.push(
          renderMixedSamplingTables[individualTableIndex],
        );
      });
    } else {
      const {
        aggregateChart,
        renderTables,
        renderSamplingTables,
      } = this.generateComparisonAggregateCriteria(
        result,
        payload,
        projectSites,
      );
      reportContent.content.push(aggregateChart[0]);
      renderTables.forEach((individualTable, individualTableIndex) => {
        reportContent.content.push(individualTable);
        reportContent.content.push(renderSamplingTables[individualTableIndex]);
      });
    }
    return reportContent;
  }

  public setReportTitle(jbiLogo: string) {
    const reportTitle = {
      layout: 'noBorders',
      table: {
        widths: [96, '*'],
        body: [
          [
            {
              fit: [75, 26],
              image: jbiLogo,
              margin: [0, 7, 0, 5],
            },
            {
              text: [
                {
                  text: 'JBI PACES ',
                  bold: true,
                },
                'REPORT',
              ],
              margin: [0, 2, 0, 0],
              fillColor: '#062157',
              style: {
                color: 'white',
                fontSize: 30,
              },
            },
          ],
        ],
      },
    };
    return reportTitle;
  }

  public setProjectName(projectTitle: string) {
    return {
      text: projectTitle.replace(/\s+/g, ' ').toUpperCase(),
      fontSize: 30,
      color: '#00205B',
      bold: true,
      margin: [0, 30, 0, 5],
    };
  }

  public setSubTitle(subTitle: string) {
    return {
      text: subTitle.replace(/\s+/g, ' '),
      fontSize: 11,
      color: '#444F51',
      bold: true,
      margin: [0, 0, 0, 15],
    };
  }

  public setSelectedSites(projectSites: ProjectSite[], payload: any) {
    const siteNames: string[] = [];
    payload.filteredSites.forEach((projectSiteMapId: number) => {
      const projectSite: any = projectSites.find((projectSiteDetail) => {
        return projectSiteDetail.id === projectSiteMapId;
      });
      siteNames.push(projectSite.site.name);
    });
    return {
      text: siteNames.join(', '),
      color: '#444F51',
      fontSize: 11,
      margin: [0, 0, 0, 12],
    };
  }

  public setSelectedCriteria(projectCriteria: ProjectCriteria[], payload: any) {
    const criteriaNames: any[] = [];
    payload.filteredCriterias.forEach((projectCriteriaId: number) => {
      const criteria: any = projectCriteria.find((projectCriteriaDetail) => {
        return projectCriteriaDetail.id === projectCriteriaId;
      });
      criteriaNames.push({
        text: criteria.title,
        color: '#444F51',
        fontSize: 11,
        margin: [0, 0, 0, 3],
      });
    });
    return {
      ol: criteriaNames,
      markerColor: '#444F51',
      margin: [0, 0, 0, 30],
      fontSize: 11,
    };
  }

  public generateComparisonIndividualCriteria(
    result: any,
    payload: any,
    projectCriteria: ProjectCriteria[],
    projectSites: ProjectSite[],
  ) {
    const allCharts: any[] = [];
    const renderBooleanTables: any[] = [];
    const renderBooleanSamplingTables: any[] = [];
    const renderMixedTables: any[] = [];
    const renderMixedSamplingTables: any[] = [];
    // generate chart
    const dataSet: any[] = [];
    const dataSetOptions: any[] = [];
    const verticalBarChartDataSetOption: any = {
      animation: {
        duration: 0,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              max: 100,
              min: 0,
              fontSize: 20,
            },
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Compliance %',
              display: true,
              fontSize: 20,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Criteria',
              display: false,
            },
            ticks: {
              fontSize: 20,
              minRotation: 0,
              callback: (label: string, index: number) => {
                if (payload.checkIndividualCriteria) {
                  return this.transformLabel(label);
                } else {
                  if (payload.filteredCriterias.length > 4) {
                    return index + 1 + '.';
                  }
                  return this.transformLabel(label);
                }
              },
            },
          },
        ],
      },
      legend: {
        display: true,
        onHover: (e: any) => {
          e.target.style.cursor = 'pointer';
        },
        onLeave: (e: any) => {
          e.target.style.cursor = 'default';
        },
        labels: {
          boxWidth: 40,
          fontSize: 25,
        },
        align: 'start',
      },
      responsive: true,
      maintainAspectRatio: true,
      tooltips: {
        callbacks: {},
      },
    };
    const horizontalBarChartDataSetOption: any = {
      animation: {
        duration: 0,
      },
      scales: {
        xAxes: [
          {
            ticks: {
              beginAtZero: true,
              max: 100,
              min: 0,
              fontSize: 20,
            },
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Compliance %',
              display: true,
              fontSize: 20,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Criteria',
              display: false,
            },
            ticks: {
              fontSize: 20,
              minRotation: 0,
              callback: (label: string, index: number) => {
                if (payload.checkIndividualCriteria) {
                  return this.transformLabel(label);
                } else {
                  if (payload.filteredCriterias.length > 4) {
                    return index + 1 + '.';
                  }
                  return this.transformLabel(label);
                }
              },
            },
          },
        ],
      },
      legend: {
        display: true,
        onHover: (e: any) => {
          e.target.style.cursor = 'pointer';
        },
        onLeave: (e: any) => {
          e.target.style.cursor = 'default';
        },
        labels: {
          boxWidth: 40,
          fontSize: 25,
        },
        align: 'start',
      },
      responsive: true,
      maintainAspectRatio: true,
      tooltips: {
        callbacks: {},
      },
    };
    const chartData: any[][] = [];
    const statisticData: any[][] = [];
    const samplingConfigurationData: any[] = [];
    _map(result.comparisonResult, (comparisonResult: any) => {
      const dirtyChartData = comparisonResult.chartData;
      const dirtyStatisticData =
        comparisonResult.statisticData.configurationData;
      if (dirtyChartData.length > 0) {
        chartData.push(dirtyChartData);
      }
      statisticData.push(dirtyStatisticData);
      samplingConfigurationData.push(
        comparisonResult.statisticData.samplingConfiguration,
      );
    });
    const {
      comparisonAggregateCriteriaConfiguration,
      comparisonIndividualBooleanCriteriaConfiguration,
      comparisonIndividualMixedCriteriaConfiguration,
    } = this.generateComparisonConfigurationData(
      statisticData,
      payload,
      samplingConfigurationData,
    );
    const siteName: string = projectSites[0].site.name;
    if (chartData.length > 0) {
      const criteriaNames = [];
      const booleanFilteredCriteriaIds: number[] = this.getBooleanCriteriaMapIds(
        projectCriteria,
        payload,
      );
      _map(booleanFilteredCriteriaIds, (criteriaId: number) => {
        let criteriaName = '';
        _map(projectCriteria, (criteria: ProjectCriteria) => {
          if (criteria.id === criteriaId) {
            criteriaName = criteria.title;
            criteriaNames.push(criteria.title);
          }
        });
        const data = {
          labels: [] as string[],
          datasets: [] as any[],
        };
        let dataOptions = {};
        let dataOptionsObject: any = {};
        Object.keys(chartData).forEach((key: string) => {
          const chartValues: number[] = [];
          const backgroundColors: string[] = [];
          const singleChartData = chartData[Number(key)];
          Object.keys(singleChartData).map((dataKey: string) => {
            if (
              singleChartData[Number(dataKey)].projectCriteriaId === criteriaId
            ) {
              chartValues.push(
                singleChartData[Number(dataKey)].data[0].compliance,
              );
            }
          });
          backgroundColors.push(chartColorSet[Number(key)]);
          const dataSetObject = {
            label: this.formatDateRange(payload.comparisonPeriods[Number(key)]),
            backgroundColor: backgroundColors,
            hoverBackgroundColor: backgroundColors,
            pointBackgroundColor: 'white',
            borderWidth: 1,
            pointBorderColor: '#249EBF',
            data: chartValues,
            categoryPercentage: this.getIndividualCriteriaChartCategoryPercentage(
              payload,
            ),
          };
          data.datasets.push(dataSetObject);
        });
        data.labels = [siteName];
        if (payload.selectedChartType === ChartType.verticalBarChart) {
          dataOptionsObject = _cloneDeep(verticalBarChartDataSetOption);
        } else {
          dataOptionsObject = _cloneDeep(horizontalBarChartDataSetOption);
        }
        dataOptionsObject.tooltips.callbacks.title = () => criteriaName;
        dataOptionsObject.tooltips.callbacks.afterTitle = () => siteName;
        dataOptions = _cloneDeep(dataOptionsObject);
        dataSet.push(data);
        dataSetOptions.push(dataOptions);
      });
      dataSet.forEach((individualSet, individualSetIndex) => {
        const renderChart = {
          width: 443,
          height: 250,
          image: '',
          alignment: 'center',
          margin: [0, 0, 0, 30],
          unbreakable: true,
        };
        const canvas = document.createElement('canvas');
        document.body.appendChild(canvas);
        if (payload.selectedChartType === ChartType.verticalBarChart) {
          const verticalChart = new Chart(
            canvas.getContext('2d') as CanvasRenderingContext2D,
            {
              type: 'bar',
              data: {
                labels: individualSet.labels,
                datasets: individualSet.datasets,
              },
              options: dataSetOptions[individualSetIndex],
              plugins: [
                {
                  afterDatasetDraw(chart: any, args: any) {
                    args.meta.data.forEach((element: any) => {
                      if (element._model.base - element._model.y < 0) {
                        const borderWidth = 8;
                        const ctx = chart.ctx;
                        const vm = element._view;
                        const half = vm.width / 2;
                        const left = vm.x - half;
                        const right = vm.x + half;
                        const top = vm.y - 2;
                        const width = right - left;
                        const height =
                          chart.chartArea.bottom - top + borderWidth / 2 - 1;
                        ctx.beginPath();
                        ctx.lineWidth = borderWidth;
                        ctx.strokeStyle = '#000000';
                        ctx.setLineDash([3, 4]);
                        ctx.moveTo(left, top);
                        ctx.lineTo(left, top + height);
                        ctx.moveTo(left, top);
                        ctx.lineTo(left + width, top);
                        ctx.moveTo(left + width, top);
                        ctx.lineTo(left + width, top + height);
                        ctx.stroke();
                        ctx.save();
                      }
                    });
                  },
                },
              ],
            },
          );
          renderChart.image = verticalChart.toBase64Image();
        } else {
          const horizontalChart = new Chart(
            canvas.getContext('2d') as CanvasRenderingContext2D,
            {
              type: 'horizontalBar',
              data: {
                labels: individualSet.labels,
                datasets: individualSet.datasets,
              },
              options: dataSetOptions[individualSetIndex],
              plugins: [
                {
                  afterDatasetDraw(chart: any, args: any) {
                    args.meta.data.forEach((element: any) => {
                      if (element._model.base - element._model.x > 0) {
                        const borderWidth = 8;
                        const ctx = chart.ctx;
                        const vm = element._view;
                        const half = vm.height / 2;
                        const top = vm.y - half;
                        const bottom = vm.y + half;
                        const right = vm.x + 10;
                        const left = chart.chartArea.left;
                        ctx.beginPath();
                        ctx.lineWidth = borderWidth;
                        ctx.strokeStyle = '#000000';
                        ctx.setLineDash([4, 5]);
                        ctx.moveTo(left, top);
                        ctx.lineTo(right, top);
                        ctx.moveTo(right, top);
                        ctx.lineTo(right, bottom);
                        ctx.moveTo(right, bottom);
                        ctx.lineTo(left, bottom);
                        ctx.stroke();
                        ctx.save();
                      }
                    });
                  },
                },
              ],
            },
          );
          renderChart.image = horizontalChart.toBase64Image();
        }
        const stack: any[] = [renderChart];
        const criteriaChart = {
          stack,
          unbreakable: true,
        };
        allCharts.push(criteriaChart);
        document.body.removeChild(canvas);
      });
    } else {
      allCharts.push({
        stack: [],
        unbreakable: true,
      });
    }
    const periodChunks: any[] = [];
    const dirtyPeriodChunks = _cloneDeep(payload.comparisonPeriods);
    while (dirtyPeriodChunks.length) {
      periodChunks.push(dirtyPeriodChunks.splice(0, 2));
    }
    const samplingPeriodChunks: any[] = [];
    const dirtySamplingPeriodChunks = _cloneDeep(payload.comparisonPeriods);
    while (dirtySamplingPeriodChunks.length) {
      samplingPeriodChunks.push(dirtySamplingPeriodChunks.splice(0, 3));
    }
    // generate tables
    comparisonIndividualBooleanCriteriaConfiguration.forEach(
      (booleanCriteriaDetails, booleanCriteriaDetailsIndex) => {
        const dirtyBooleanTables: any[] = [];
        const booleanCriteriaChunks: any[] = [];
        const dirtyBooleanCriteriaDetails = _cloneDeep(booleanCriteriaDetails);
        while (dirtyBooleanCriteriaDetails.length) {
          booleanCriteriaChunks.push(dirtyBooleanCriteriaDetails.splice(0, 2));
        }
        const title = {
          text:
            booleanCriteriaDetailsIndex +
            1 +
            '. ' +
            booleanCriteriaChunks[0][0].title,
          bold: true,
          color: '#444F51',
          fontSize: 11,
          margin: [0, 0, 0, 17],
        };
        booleanCriteriaChunks.forEach(
          (booleanCriteriaOptionDetails, booleanCriteriaOptionDetailsIndex) => {
            switch (booleanCriteriaOptionDetails.length) {
              case 1:
                const bodyRowsSinglePeriod: any[][] = [];
                booleanCriteriaOptionDetails[0].criteriaSamplingData.forEach(
                  (optionsData: any, optionDataIndex: number) => {
                    const rowDataSinglePeriod: any[] = [];
                    const optionSinglePeriod = Object.keys(optionsData)[0];
                    rowDataSinglePeriod.push({
                      text: optionSinglePeriod,
                      color: '#444F51',
                      fontSize: 12,
                      border: [true, false, true, false],
                      margin:
                        optionDataIndex ===
                        booleanCriteriaOptionDetails[0].criteriaSamplingData
                          .length -
                          1
                          ? [8, 6, 0, 6]
                          : [8, 6, 0, 0],
                    });
                    rowDataSinglePeriod.push({
                      text: booleanCriteriaOptionDetails[0]
                        .criteriaSamplingDataConfiguration.length
                        ? optionsData[optionSinglePeriod]
                        : '-',
                      color: '#444F51',
                      fontSize: 12,
                      border: [true, false, true, false],
                      alignment: 'right',
                      margin:
                        optionDataIndex ===
                        booleanCriteriaOptionDetails[0].criteriaSamplingData
                          .length -
                          1
                          ? [0, 6, 8, 6]
                          : [0, 6, 8, 0],
                    });
                    bodyRowsSinglePeriod.push(rowDataSinglePeriod);
                  },
                );
                let singlePeriodTotal: number | string = 0;
                let singlePeriodCompliance: any = 0;
                bodyRowsSinglePeriod.forEach((optionValues) => {
                  if (optionValues[1].text === '-') {
                    singlePeriodTotal = '-';
                  } else {
                    singlePeriodTotal += optionValues[1].text;
                  }
                });
                bodyRowsSinglePeriod.push([
                  {
                    text: 'Total Data Collected',
                    color: '#444F51',
                    fontSize: 12,
                    margin: [8, 6, 0, 6],
                  },
                  {
                    text: singlePeriodTotal,
                    color: '#444F51',
                    fontSize: 12,
                    alignment: 'right',
                    margin: [0, 6, 8, 6],
                  },
                ]);
                if (bodyRowsSinglePeriod[0][1].text === 0) {
                  bodyRowsSinglePeriod.push([
                    {
                      text: 'Compliance',
                      color: '#444F51',
                      fontSize: 12,
                      margin: [8, 6, 0, 6],
                      bold: true,
                    },
                    {
                      text: singlePeriodCompliance + '%',
                      color: '#444F51',
                      fontSize: 12,
                      alignment: 'right',
                      bold: true,
                      margin: [0, 6, 8, 6],
                    },
                  ]);
                } else {
                  if (bodyRowsSinglePeriod[0][1].text === '-') {
                    singlePeriodCompliance = '-';
                  } else {
                    singlePeriodCompliance =
                      (bodyRowsSinglePeriod[0][1].text /
                        (singlePeriodTotal - bodyRowsSinglePeriod[2][1].text)) *
                      100;
                  }
                  bodyRowsSinglePeriod.push([
                    {
                      text: 'Compliance',
                      color: '#444F51',
                      fontSize: 12,
                      margin: [8, 6, 0, 6],
                      bold: true,
                    },
                    {
                      text:
                        singlePeriodCompliance !== '-'
                          ? Math.round(singlePeriodCompliance * 100) / 100 + '%'
                          : singlePeriodCompliance,
                      color: '#444F51',
                      fontSize: 12,
                      alignment: 'right',
                      bold: true,
                      margin: [0, 6, 8, 6],
                    },
                  ]);
                }
                const singlePeriodTableData = {
                  table: {
                    unbreakable: true,
                    widths: ['*', 150],
                    body: [
                      [
                        {
                          text: 'Answer Choices',
                          color: '#9F9F9F',
                          fontSize: 11,
                          margin: [8, 10, 0, 10],
                          rowSpan: 2,
                        },
                        {
                          text: siteName,
                          color: '#9F9F9F',
                          fontSize: 11,
                          alignment: 'right',
                          margin: [0, 10, 8, 10],
                        },
                      ],
                      [
                        {},
                        {
                          text: [
                            periodChunks[booleanCriteriaOptionDetailsIndex][0]
                              .title + '\n\n',
                            this.formatDateRange(
                              periodChunks[
                                booleanCriteriaOptionDetailsIndex
                              ][0],
                            ),
                          ],
                          color: '#9F9F9F',
                          fontSize: 11,
                          alignment: 'right',
                          margin: [0, 10, 8, 10],
                        },
                      ],
                      ...bodyRowsSinglePeriod,
                    ],
                  },
                  layout: {
                    hLineWidth: () => {
                      return 0.5;
                    },
                    vLineWidth: () => {
                      return 0.5;
                    },
                    hLineColor: () => {
                      return '#DBDBDB';
                    },
                    vLineColor: () => {
                      return '#DBDBDB';
                    },
                  },
                };
                const singlePeriodStack: any[] = [singlePeriodTableData];
                const criteriaSinglePeriodTable = {
                  stack: singlePeriodStack,
                  unbreakable: true,
                };
                dirtyBooleanTables.push(criteriaSinglePeriodTable);
                break;
              default:
                const bodyRows: any[] = [];
                const valueRows: any[] = [];
                const totalRow: number[] = [];
                const complianceRow: any[] = [];
                booleanCriteriaOptionDetails.forEach((detail: any) => {
                  const periodRow: any[] = [];
                  detail.criteriaSamplingData.forEach((optionsData: any) => {
                    const rowData: any = {
                      option: '',
                      value: null,
                    };
                    const option = Object.keys(optionsData)[0];
                    rowData.option = option;
                    rowData.value = optionsData[option];
                    if (detail.criteriaSamplingDataConfiguration.length === 0) {
                      rowData.value = '-';
                    }
                    periodRow.push(rowData);
                  });
                  valueRows.push(periodRow);
                  let total: number | string = 0;
                  periodRow.forEach((optionValues) => {
                    if (
                      optionValues.value === '-' ||
                      detail.criteriaSamplingDataConfiguration.length === 0
                    ) {
                      total = '-';
                    } else {
                      total += optionValues.value;
                    }
                  });
                  totalRow.push(total);
                  let compliance: number | string = 0;
                  if (detail.criteriaType === CRITERION_TYPE.BOOLEAN) {
                    if (periodRow[0].value === 0) {
                      complianceRow.push(compliance);
                    } else {
                      if (periodRow[0].value === '-') {
                        complianceRow.push('-');
                      } else {
                        compliance =
                          (periodRow[0].value / (total - periodRow[2].value)) *
                          100;
                        complianceRow.push(Math.round(compliance * 100) / 100);
                      }
                    }
                  }
                });
                const periodOne = valueRows[0];
                const periodTwo = valueRows[1];
                for (let step = 0; step < periodOne.length; step++) {
                  const optionColumnOne = {
                    text: periodOne[step].option,
                    color: '#444F51',
                    fontSize: 12,
                    border: [true, false, true, false],
                    margin:
                      step === periodOne.length - 1
                        ? [8, 6, 0, 6]
                        : [8, 6, 0, 0],
                  };
                  const optionColumnTwo = {
                    text: periodOne[step].value,
                    color: '#444F51',
                    fontSize: 12,
                    border: [true, false, true, false],
                    alignment: 'right',
                    margin:
                      step === periodOne.length - 1
                        ? [0, 6, 8, 6]
                        : [0, 6, 8, 0],
                  };
                  const optionColumnThree = {
                    text: periodTwo[step].value,
                    color: '#444F51',
                    fontSize: 12,
                    border: [true, false, true, false],
                    alignment: 'right',
                    margin:
                      step === periodOne.length - 1
                        ? [0, 6, 8, 6]
                        : [0, 6, 8, 0],
                  };
                  bodyRows.push([
                    optionColumnOne,
                    optionColumnTwo,
                    optionColumnThree,
                  ]);
                }
                const totalColumnOne = {
                  text: 'Total Data Collected',
                  color: '#444F51',
                  fontSize: 12,
                  margin: [8, 6, 0, 6],
                };
                const totalColumnTwo = {
                  text: totalRow[0],
                  color: '#444F51',
                  fontSize: 12,
                  alignment: 'right',
                  margin: [0, 6, 8, 6],
                };
                const totalColumnThree = {
                  text: totalRow[1],
                  color: '#444F51',
                  fontSize: 12,
                  alignment: 'right',
                  margin: [0, 6, 8, 6],
                };
                bodyRows.push([
                  totalColumnOne,
                  totalColumnTwo,
                  totalColumnThree,
                ]);
                const complianceColumnOne = {
                  text: 'Compliance',
                  color: '#444F51',
                  fontSize: 12,
                  margin: [8, 6, 0, 6],
                  bold: true,
                };
                const complianceColumnTwo = {
                  text:
                    complianceRow[0] !== '-'
                      ? complianceRow[0] + '%'
                      : complianceRow[0],
                  color: '#444F51',
                  fontSize: 12,
                  alignment: 'right',
                  bold: true,
                  margin: [0, 6, 8, 6],
                };
                const complianceColumnThree = {
                  text:
                    complianceRow[1] !== '-'
                      ? complianceRow[1] + '%'
                      : complianceRow[1],
                  color: '#444F51',
                  fontSize: 12,
                  alignment: 'right',
                  bold: true,
                  margin: [0, 6, 8, 6],
                };
                bodyRows.push([
                  complianceColumnOne,
                  complianceColumnTwo,
                  complianceColumnThree,
                ]);
                const tableData = {
                  table: {
                    unbreakable: true,
                    widths: ['*', 150, 150],
                    body: [
                      [
                        {
                          text: 'Answer Choices',
                          color: '#9F9F9F',
                          fontSize: 11,
                          margin: [8, 10, 0, 10],
                          rowSpan: 2,
                        },
                        {
                          text: siteName,
                          color: '#9F9F9F',
                          fontSize: 11,
                          alignment: 'right',
                          margin: [0, 10, 8, 10],
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {},
                        {
                          text: [
                            periodChunks[booleanCriteriaOptionDetailsIndex][0]
                              .title + '\n\n',
                            this.formatDateRange(
                              periodChunks[
                                booleanCriteriaOptionDetailsIndex
                              ][0],
                            ),
                          ],
                          color: '#9F9F9F',
                          fontSize: 11,
                          alignment: 'right',
                          margin: [0, 10, 8, 10],
                        },
                        {
                          text: [
                            periodChunks[booleanCriteriaOptionDetailsIndex][1]
                              .title + '\n\n',
                            this.formatDateRange(
                              periodChunks[
                                booleanCriteriaOptionDetailsIndex
                              ][1],
                            ),
                          ],
                          color: '#9F9F9F',
                          fontSize: 11,
                          alignment: 'right',
                          margin: [0, 10, 8, 10],
                        },
                      ],
                      ...bodyRows,
                    ],
                  },
                  layout: {
                    hLineWidth: () => {
                      return 0.5;
                    },
                    vLineWidth: () => {
                      return 0.5;
                    },
                    hLineColor: () => {
                      return '#DBDBDB';
                    },
                    vLineColor: () => {
                      return '#DBDBDB';
                    },
                  },
                };
                const stack: any[] = [tableData];
                const criteriaTable = {
                  stack,
                  unbreakable: true,
                };
                dirtyBooleanTables.push(criteriaTable);
                break;
            }
          },
        );
        const setMargin = {
          canvas: [],
          margin: [0, 0, 0, 13],
        };
        allCharts[booleanCriteriaDetailsIndex].stack.unshift(title);
        switch (dirtyBooleanTables.length) {
          case 1:
            dirtyBooleanTables[0].stack.push(setMargin);
            break;
          case 2:
            dirtyBooleanTables[0].stack.push(setMargin);
            break;
          default:
            dirtyBooleanTables.forEach((dirtyBooleanTable, dirtyTableIndex) => {
              switch (dirtyTableIndex) {
                case 0:
                  dirtyBooleanTable.stack.push(setMargin);
                  break;
                case dirtyBooleanTables.length - 1:
                  dirtyBooleanTable.stack.push(setMargin);
                  break;
                default:
                  dirtyBooleanTable.stack.push(setMargin);
              }
            });
        }
        renderBooleanTables.push(dirtyBooleanTables);
      },
    );
    comparisonIndividualBooleanCriteriaConfiguration.forEach(
      (criteriaDetails, criteriaDetailsIndex) => {
        const criteriaChunks: any[] = [];
        const dirtyCriteriaDetails = _cloneDeep(criteriaDetails);
        while (dirtyCriteriaDetails.length) {
          criteriaChunks.push(dirtyCriteriaDetails.splice(0, 3));
        }
        const samplingTitle = {
          text: 'Sampling',
          bold: true,
          color: '#444F51',
          fontSize: 11,
          margin: [0, 13, 0, 17],
        };
        const dirtySamplingTables = this.getSamplingTables(
          criteriaChunks,
          samplingPeriodChunks,
          projectSites,
          samplingTitle,
        );
        renderBooleanSamplingTables.push(dirtySamplingTables);
      },
    );
    if (comparisonIndividualMixedCriteriaConfiguration.length !== 0) {
      comparisonIndividualMixedCriteriaConfiguration.forEach(
        (mixedCriteriaDetails, mixedCriteriaDetailsIndex) => {
          const dirtyMixedTables: any[] = [];
          const mixedCriteriaChunks: any[] = [];
          const dirtyMixedCriteriaDetails = _cloneDeep(mixedCriteriaDetails);
          while (dirtyMixedCriteriaDetails.length) {
            mixedCriteriaChunks.push(dirtyMixedCriteriaDetails.splice(0, 2));
          }
          const title = {
            text:
              comparisonIndividualBooleanCriteriaConfiguration.length +
              mixedCriteriaDetailsIndex +
              1 +
              '. ' +
              mixedCriteriaChunks[0][0].title,
            bold: true,
            color: '#444F51',
            fontSize: 11,
            margin: [0, 0, 0, 17],
          };
          mixedCriteriaChunks.forEach(
            (mixedCriteriaOptionDetails, mixedCriteriaOptionDetailsIndex) => {
              switch (mixedCriteriaOptionDetails.length) {
                case 1:
                  const bodyRowsSinglePeriod: any[][] = [];
                  mixedCriteriaOptionDetails[0].criteriaSamplingData.forEach(
                    (optionsData: any, optionDataIndex: number) => {
                      const rowDataSinglePeriod: any[] = [];
                      const optionSinglePeriod = Object.keys(optionsData)[0];
                      rowDataSinglePeriod.push({
                        text: optionSinglePeriod,
                        color: '#444F51',
                        fontSize: 12,
                        border: [true, false, true, false],
                        margin:
                          optionDataIndex ===
                          mixedCriteriaOptionDetails[0].criteriaSamplingData
                            .length -
                            1
                            ? [8, 6, 0, 6]
                            : [8, 6, 0, 0],
                      });
                      rowDataSinglePeriod.push({
                        text: mixedCriteriaOptionDetails[0].length
                          ? optionsData[optionSinglePeriod]
                          : '-',
                        color: '#444F51',
                        fontSize: 12,
                        border: [true, false, true, false],
                        alignment: 'right',
                        margin:
                          optionDataIndex ===
                          mixedCriteriaOptionDetails[0].criteriaSamplingData
                            .length -
                            1
                            ? [0, 6, 8, 6]
                            : [0, 6, 8, 0],
                      });
                      bodyRowsSinglePeriod.push(rowDataSinglePeriod);
                    },
                  );
                  let singlePeriodTotal: number | string = 0;
                  bodyRowsSinglePeriod.forEach((optionValues) => {
                    if (optionValues[1].text === '-') {
                      singlePeriodTotal = '-';
                    } else {
                      singlePeriodTotal += optionValues[1].text;
                    }
                  });
                  bodyRowsSinglePeriod.push([
                    {
                      text: 'Total Data Collected',
                      color: '#444F51',
                      fontSize: 12,
                      margin: [8, 6, 0, 6],
                    },
                    {
                      text: singlePeriodTotal,
                      color: '#444F51',
                      fontSize: 12,
                      alignment: 'right',
                      margin: [0, 6, 8, 6],
                    },
                  ]);
                  const singlePeriodTableData = {
                    table: {
                      unbreakable: true,
                      widths: ['*', 150],
                      body: [
                        [
                          {
                            text: 'Answer Choices',
                            color: '#9F9F9F',
                            fontSize: 11,
                            margin: [8, 10, 0, 10],
                            rowSpan: 2,
                          },
                          {
                            text: siteName,
                            color: '#9F9F9F',
                            fontSize: 11,
                            alignment: 'right',
                            margin: [0, 10, 8, 10],
                          },
                        ],
                        [
                          {},
                          {
                            text: [
                              periodChunks[mixedCriteriaOptionDetailsIndex][0]
                                .title + '\n\n',
                              this.formatDateRange(
                                periodChunks[
                                  mixedCriteriaOptionDetailsIndex
                                ][0],
                              ),
                            ],
                            color: '#9F9F9F',
                            fontSize: 11,
                            alignment: 'right',
                            margin: [0, 10, 8, 10],
                          },
                        ],
                        ...bodyRowsSinglePeriod,
                      ],
                    },
                    layout: {
                      hLineWidth: () => {
                        return 0.5;
                      },
                      vLineWidth: () => {
                        return 0.5;
                      },
                      hLineColor: () => {
                        return '#DBDBDB';
                      },
                      vLineColor: () => {
                        return '#DBDBDB';
                      },
                    },
                  };
                  const singlePeriodStack: any[] = [singlePeriodTableData];
                  const criteriaSinglePeriodTable = {
                    stack: singlePeriodStack,
                    unbreakable: true,
                  };
                  dirtyMixedTables.push(criteriaSinglePeriodTable);
                  break;
                default:
                  const bodyRows: any[] = [];
                  const valueRows: any[] = [];
                  const totalRow: number[] = [];
                  const optionsList: string[] = [];
                  mixedCriteriaOptionDetails.forEach(
                    (detail: any, detailIndex: number) => {
                      let total: number | string = 0;
                      detail.criteriaSamplingData.forEach(
                        (optionsData: any) => {
                          const option = Object.keys(optionsData)[0];
                          if (!optionsList.includes(option)) {
                            optionsList.push(option);
                          }
                          total += optionsData[option];

                          if (
                            detail.criteriaSamplingDataConfiguration.length ===
                            0
                          ) {
                            optionsData[option] = '-';
                            total = '-';
                          }
                          if (valueRows[detailIndex]) {
                            valueRows[detailIndex] = {
                              ...valueRows[detailIndex],
                              ...optionsData,
                            };
                          } else {
                            valueRows[detailIndex] = optionsData;
                          }
                        },
                      );
                      totalRow.push(total);
                    },
                  );
                  const periodOne = valueRows[0];
                  const periodTwo = valueRows[1];
                  optionsList.forEach((option, index) => {
                    const optionColumnOne = {
                      text: option,
                      color: '#444F51',
                      fontSize: 12,
                      border: [true, false, true, false],
                      margin:
                        index === optionsList.length - 1
                          ? [8, 6, 0, 6]
                          : [8, 6, 0, 0],
                    };
                    let periodOneValue: number | string;
                    if (periodOne && periodOne[option] !== undefined) {
                      periodOneValue = periodOne[option];
                    } else {
                      periodOneValue = option === 'N/A' ? 0 : '-';
                    }
                    const optionColumnTwo = {
                      text: periodOneValue,
                      color: '#444F51',
                      fontSize: 12,
                      border: [true, false, true, false],
                      alignment: 'right',
                      margin:
                        index === optionsList.length - 1
                          ? [0, 6, 8, 6]
                          : [0, 6, 8, 0],
                    };
                    let periodTwoValue: number | string;
                    if (periodTwo && periodTwo[option] !== undefined) {
                      periodTwoValue = periodTwo[option];
                    } else {
                      periodTwoValue = option === 'N/A' ? 0 : '-';
                    }
                    const optionColumnThree = {
                      text: periodTwoValue,
                      color: '#444F51',
                      fontSize: 12,
                      border: [true, false, true, false],
                      alignment: 'right',
                      margin:
                        index === optionsList.length - 1
                          ? [0, 6, 8, 6]
                          : [0, 6, 8, 0],
                    };
                    bodyRows.push([
                      optionColumnOne,
                      optionColumnTwo,
                      optionColumnThree,
                    ]);
                  });
                  const totalColumnOne = {
                    text: 'Total Data Collected',
                    color: '#444F51',
                    fontSize: 12,
                    margin: [8, 6, 0, 6],
                  };
                  const totalColumnTwo = {
                    text: totalRow[0],
                    color: '#444F51',
                    fontSize: 12,
                    alignment: 'right',
                    margin: [0, 6, 8, 6],
                  };
                  const totalColumnThree = {
                    text: totalRow[1],
                    color: '#444F51',
                    fontSize: 12,
                    alignment: 'right',
                    margin: [0, 6, 8, 6],
                  };
                  bodyRows.push([
                    totalColumnOne,
                    totalColumnTwo,
                    totalColumnThree,
                  ]);
                  const tableData = {
                    table: {
                      unbreakable: true,
                      widths: ['*', 150, 150],
                      body: [
                        [
                          {
                            text: 'Answer Choices',
                            color: '#9F9F9F',
                            fontSize: 11,
                            margin: [8, 10, 0, 10],
                            rowSpan: 2,
                          },
                          {
                            text: siteName,
                            color: '#9F9F9F',
                            fontSize: 11,
                            alignment: 'right',
                            margin: [0, 10, 8, 10],
                            colSpan: 2,
                          },
                          {},
                        ],
                        [
                          {},
                          {
                            text: [
                              periodChunks[mixedCriteriaOptionDetailsIndex][0]
                                .title + '\n\n',
                              this.formatDateRange(
                                periodChunks[
                                  mixedCriteriaOptionDetailsIndex
                                ][0],
                              ),
                            ],
                            color: '#9F9F9F',
                            fontSize: 11,
                            alignment: 'right',
                            margin: [0, 10, 8, 10],
                          },
                          {
                            text: [
                              periodChunks[mixedCriteriaOptionDetailsIndex][1]
                                .title + '\n\n',
                              this.formatDateRange(
                                periodChunks[
                                  mixedCriteriaOptionDetailsIndex
                                ][1],
                              ),
                            ],
                            color: '#9F9F9F',
                            fontSize: 11,
                            alignment: 'right',
                            margin: [0, 10, 8, 10],
                          },
                        ],
                        ...bodyRows,
                      ],
                    },
                    layout: {
                      hLineWidth: () => {
                        return 0.5;
                      },
                      vLineWidth: () => {
                        return 0.5;
                      },
                      hLineColor: () => {
                        return '#DBDBDB';
                      },
                      vLineColor: () => {
                        return '#DBDBDB';
                      },
                    },
                  };
                  const stack: any[] = [tableData];
                  const criteriaTable = {
                    stack,
                    unbreakable: true,
                  };
                  dirtyMixedTables.push(criteriaTable);
                  break;
              }
            },
          );
          const setMargin = {
            canvas: [],
            margin: [0, 0, 0, 13],
          };
          switch (dirtyMixedTables.length) {
            case 1:
              dirtyMixedTables[0].stack.unshift(title);
              dirtyMixedTables[0].stack.push(setMargin);
              break;
            case 2:
              dirtyMixedTables[0].stack.unshift(title);
              dirtyMixedTables[0].stack.push(setMargin);
              break;
            default:
              dirtyMixedTables.forEach((dirtyMixedTable, dirtyTableIndex) => {
                switch (dirtyTableIndex) {
                  case 0:
                    dirtyMixedTable.stack.unshift(title);
                    dirtyMixedTable.stack.push(setMargin);
                    break;
                  case dirtyMixedTables.length - 1:
                    dirtyMixedTable.stack.push(setMargin);
                    break;
                  default:
                    dirtyMixedTable.stack.push(setMargin);
                }
              });
          }
          renderMixedTables.push(dirtyMixedTables);
        },
      );
      comparisonIndividualMixedCriteriaConfiguration.forEach(
        (criteriaDetails, criteriaDetailsIndex) => {
          const criteriaChunks: any[] = [];
          const dirtyCriteriaDetails = _cloneDeep(criteriaDetails);
          while (dirtyCriteriaDetails.length) {
            criteriaChunks.push(dirtyCriteriaDetails.splice(0, 3));
          }
          const samplingTitle = {
            text: 'Sampling',
            bold: true,
            color: '#444F51',
            fontSize: 11,
            margin: [0, 13, 0, 17],
          };
          const dirtySamplingTables = this.getSamplingTables(
            criteriaChunks,
            samplingPeriodChunks,
            projectSites,
            samplingTitle,
          );
          renderMixedSamplingTables.push(dirtySamplingTables);
        },
      );
    }
    return {
      allCharts,
      renderBooleanTables,
      renderBooleanSamplingTables,
      renderMixedTables,
      renderMixedSamplingTables,
    };
  }

  public generateComparisonAggregateCriteria(
    result: any,
    payload: any,
    projectSites: ProjectSite[],
  ) {
    const aggregateChart: any[] = [];
    const renderChart = {
      width: 443,
      height: 250,
      image: '',
      alignment: 'center',
      margin: [0, 0, 0, 30],
      unbreakable: true,
    };
    const renderTables: any[] = [];
    const renderSamplingTables: any[] = [];
    // generate chart
    const dataSet = [];
    const dataSetOptions = [];
    const verticalBarChartDataSetOption: any = {
      animation: {
        duration: 0,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              max: 100,
              min: 0,
              fontSize: 20,
            },
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Compliance %',
              display: true,
              fontSize: 20,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Criteria',
              display: false,
            },
            ticks: {
              fontSize: 20,
              minRotation: 0,
              callback: (label: string, index: number) => {
                if (payload.checkIndividualCriteria) {
                  return this.transformLabel(label);
                } else {
                  if (payload.filteredCriterias.length > 4) {
                    return index + 1 + '.';
                  }
                  return this.transformLabel(label);
                }
              },
            },
          },
        ],
      },
      legend: {
        display: true,
        onHover: (e: any) => {
          e.target.style.cursor = 'pointer';
        },
        onLeave: (e: any) => {
          e.target.style.cursor = 'default';
        },
        labels: {
          boxWidth: 40,
          fontSize: 25,
        },
        align: 'start',
      },
      responsive: true,
      maintainAspectRatio: true,
      tooltips: {
        callbacks: {},
      },
    };
    const horizontalBarChartDataSetOption: any = {
      animation: {
        duration: 0,
      },
      scales: {
        xAxes: [
          {
            ticks: {
              beginAtZero: true,
              max: 100,
              min: 0,
              fontSize: 20,
            },
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Compliance %',
              display: true,
              fontSize: 20,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Criteria',
              display: false,
            },
            ticks: {
              fontSize: 20,
              minRotation: 0,
              callback: (label: string, index: number) => {
                if (payload.checkIndividualCriteria) {
                  return this.transformLabel(label);
                } else {
                  if (payload.filteredCriterias.length > 4) {
                    return index + 1 + '.';
                  }
                  return this.transformLabel(label);
                }
              },
            },
          },
        ],
      },
      legend: {
        display: true,
        onHover: (e: any) => {
          e.target.style.cursor = 'pointer';
        },
        onLeave: (e: any) => {
          e.target.style.cursor = 'default';
        },
        labels: {
          boxWidth: 40,
          fontSize: 25,
        },
        align: 'start',
      },
      responsive: true,
      maintainAspectRatio: true,
      tooltips: {
        callbacks: {},
      },
    };
    const chartData: any[][] = [];
    const statisticData: any[][] = [];
    const samplingConfigurationData: any[] = [];
    _map(result.comparisonResult, (comparisonResult: any) => {
      const dirtyChartData = comparisonResult.chartData;
      const dirtyStatisticData =
        comparisonResult.statisticData.configurationData;
      if (dirtyChartData.length > 0) {
        chartData.push(dirtyChartData);
      }
      statisticData.push(dirtyStatisticData);
      samplingConfigurationData.push(
        comparisonResult.statisticData.samplingConfiguration,
      );
    });
    const {
      comparisonAggregateCriteriaConfiguration,
      comparisonIndividualBooleanCriteriaConfiguration,
      comparisonIndividualMixedCriteriaConfiguration,
    } = this.generateComparisonConfigurationData(
      statisticData,
      payload,
      samplingConfigurationData,
    );
    const data = {
      labels: [] as string[],
      datasets: [] as any[],
    };
    let dataOptions = {};
    const labels: string[] = [];
    let dataOptionsObject: any = {};
    const siteName: string = projectSites[0].site.name;
    if (chartData.length > 0) {
      Object.keys(chartData).map((key: string) => {
        const chartValues: number[] = [];
        const backgroundColors: string[] = [];
        const singleChartData = chartData[Number(key)];
        Object.keys(singleChartData).map((dataKey: string) => {
          if (Number(key) === 0) {
            labels.push(singleChartData[Number(dataKey)].criteria.title);
          }
          chartValues.push(singleChartData[Number(dataKey)].data[0].compliance);
          backgroundColors.push(chartColorSet[Number(key)]);
        });
        const dataSetObject = {
          label: this.formatDateRange(payload.comparisonPeriods[Number(key)]),
          backgroundColor: backgroundColors,
          hoverBackgroundColor: backgroundColors,
          pointBackgroundColor: 'white',
          borderWidth: 1,
          pointBorderColor: '#249EBF',
          data: chartValues,
          categoryPercentage: this.getAggregateCriteriaChartCategoryPercentage(
            comparisonIndividualBooleanCriteriaConfiguration,
            payload,
          ),
        };
        data.datasets.push(dataSetObject);
      });
      if (payload.selectedChartType === ChartType.verticalBarChart) {
        dataOptionsObject = _cloneDeep(verticalBarChartDataSetOption);
        dataOptionsObject.tooltips.callbacks.afterTitle = () => siteName;
      } else {
        dataOptionsObject = _cloneDeep(horizontalBarChartDataSetOption);
        dataOptionsObject.tooltips.callbacks.afterTitle = () => siteName;
      }
      data.labels = _cloneDeep(labels);
      dataOptions = _cloneDeep(dataOptionsObject);
      dataSet.push(data);
      dataSetOptions.push(dataOptions);
      const canvas = document.createElement('canvas');
      document.body.appendChild(canvas);
      if (payload.selectedChartType === ChartType.verticalBarChart) {
        const verticalChart = new Chart(
          canvas.getContext('2d') as CanvasRenderingContext2D,
          {
            type: 'bar',
            data: {
              labels: dataSet[0].labels,
              datasets: dataSet[0].datasets,
            },
            options: dataSetOptions[0],
            plugins: [
              {
                afterDatasetDraw(chart: any, args: any) {
                  args.meta.data.forEach((element: any) => {
                    if (element._model.base - element._model.y < 0) {
                      const borderWidth = 8;
                      const ctx = chart.ctx;
                      const vm = element._view;
                      const half = vm.width / 2;
                      const left = vm.x - half;
                      const right = vm.x + half;
                      const top = vm.y - 2;
                      const width = right - left;
                      const height =
                        chart.chartArea.bottom - top + borderWidth / 2 - 1;
                      ctx.beginPath();
                      ctx.lineWidth = borderWidth;
                      ctx.strokeStyle = '#000000';
                      ctx.setLineDash([3, 4]);
                      ctx.moveTo(left, top);
                      ctx.lineTo(left, top + height);
                      ctx.moveTo(left, top);
                      ctx.lineTo(left + width, top);
                      ctx.moveTo(left + width, top);
                      ctx.lineTo(left + width, top + height);
                      ctx.stroke();
                      ctx.save();
                    }
                  });
                },
              },
            ],
          },
        );
        renderChart.image = verticalChart.toBase64Image();
      } else {
        const horizontalChart = new Chart(
          canvas.getContext('2d') as CanvasRenderingContext2D,
          {
            type: 'horizontalBar',
            data: {
              labels: dataSet[0].labels,
              datasets: dataSet[0].datasets,
            },
            options: dataSetOptions[0],
            plugins: [
              {
                afterDatasetDraw(chart: any, args: any) {
                  args.meta.data.forEach((element: any) => {
                    if (element._model.base - element._model.x > 0) {
                      const borderWidth = 8;
                      const ctx = chart.ctx;
                      const vm = element._view;
                      const half = vm.height / 2;
                      const top = vm.y - half;
                      const bottom = vm.y + half;
                      const right = vm.x + 10;
                      const left = chart.chartArea.left;
                      ctx.beginPath();
                      ctx.lineWidth = borderWidth;
                      ctx.strokeStyle = '#000000';
                      ctx.setLineDash([4, 5]);
                      ctx.moveTo(left, top);
                      ctx.lineTo(right, top);
                      ctx.moveTo(right, top);
                      ctx.lineTo(right, bottom);
                      ctx.moveTo(right, bottom);
                      ctx.lineTo(left, bottom);
                      ctx.stroke();
                      ctx.save();
                    }
                  });
                },
              },
            ],
          },
        );
        renderChart.image = horizontalChart.toBase64Image();
      }
      const criteriaNamesText: any[] = labels.map((label) => {
        return {
          text: label,
          color: '#444F51',
          margin: [10, 5, 0, 2],
        };
      });
      const names = {
        layout: 'noBorders',
        table: {
          widths: ['*'],
          body: [
            [
              {
                ol: criteriaNamesText,
                fontSize: 9,
                fillColor: '#EFF7FF',
                markerColor: '#444F51',
              },
            ],
          ],
        },
        margin: [10, 0, 0, 23],
      };
      let aggregateStack: any[];
      if (payload.filteredCriterias.length > 4) {
        renderChart.margin = [0, 0, 0, 15];
        aggregateStack = [renderChart, names];
      } else {
        aggregateStack = [renderChart];
      }
      const criteriaChart = {
        stack: aggregateStack,
        unbreakable: false,
      };
      aggregateChart.push(criteriaChart);
      document.body.removeChild(canvas);
    } else {
      aggregateChart.push({
        stack: [],
        unbreakable: false,
      });
    }
    const periodChunks: any[] = [];
    const dirtyPeriodChunks = _cloneDeep(payload.comparisonPeriods);
    while (dirtyPeriodChunks.length) {
      periodChunks.push(dirtyPeriodChunks.splice(0, 2));
    }
    const samplingPeriodChunks: any[] = [];
    const dirtySamplingPeriodChunks = _cloneDeep(payload.comparisonPeriods);
    while (dirtySamplingPeriodChunks.length) {
      samplingPeriodChunks.push(dirtySamplingPeriodChunks.splice(0, 3));
    }
    // generate tables
    comparisonAggregateCriteriaConfiguration.forEach(
      (criteriaDetails, criteriaDetailsIndex) => {
        const dirtyTables: any[] = [];
        const criteriaChunks: any[] = [];
        const dirtyCriteriaDetails = _cloneDeep(criteriaDetails);
        while (dirtyCriteriaDetails.length) {
          criteriaChunks.push(dirtyCriteriaDetails.splice(0, 2));
        }
        const title = {
          text: criteriaDetailsIndex + 1 + '. ' + criteriaChunks[0][0].title,
          bold: true,
          color: '#444F51',
          fontSize: 11,
          margin: [0, 0, 0, 17],
        };
        criteriaChunks.forEach(
          (criteriaOptionDetails, criteriaOptionDetailsIndex) => {
            switch (criteriaOptionDetails.length) {
              case 1:
                const bodyRowsSinglePeriod: any[][] = [];
                criteriaOptionDetails[0].criteriaSamplingData.forEach(
                  (optionsData: any, optionDataIndex: number) => {
                    const rowDataSinglePeriod: any[] = [];
                    const optionSinglePeriod = Object.keys(optionsData)[0];
                    rowDataSinglePeriod.push({
                      text: optionSinglePeriod,
                      color: '#444F51',
                      fontSize: 12,
                      border: [true, false, true, false],
                      margin:
                        optionDataIndex ===
                        criteriaOptionDetails[0].criteriaSamplingData.length - 1
                          ? [8, 6, 0, 6]
                          : [8, 6, 0, 0],
                    });
                    rowDataSinglePeriod.push({
                      text: criteriaOptionDetails[0]
                        .criteriaSamplingDataConfiguration.length
                        ? optionsData[optionSinglePeriod]
                        : '-',
                      color: '#444F51',
                      fontSize: 12,
                      border: [true, false, true, false],
                      alignment: 'right',
                      margin:
                        optionDataIndex ===
                        criteriaOptionDetails[0].criteriaSamplingData.length - 1
                          ? [0, 6, 8, 6]
                          : [0, 6, 8, 0],
                    });
                    bodyRowsSinglePeriod.push(rowDataSinglePeriod);
                  },
                );
                let singlePeriodTotal: number | string = 0;
                let singlePeriodCompliance: any = 0;
                bodyRowsSinglePeriod.forEach((optionValues) => {
                  if (optionValues[1].text === '-') {
                    singlePeriodTotal = '-';
                  } else {
                    singlePeriodTotal += optionValues[1].text;
                  }
                });
                bodyRowsSinglePeriod.push([
                  {
                    text: 'Total Data Collected',
                    color: '#444F51',
                    fontSize: 12,
                    margin: [8, 6, 0, 6],
                  },
                  {
                    text: singlePeriodTotal,
                    color: '#444F51',
                    fontSize: 12,
                    alignment: 'right',
                    margin: [0, 6, 8, 6],
                  },
                ]);
                if (
                  criteriaOptionDetails[0].criteriaType ===
                  CRITERION_TYPE.BOOLEAN
                ) {
                  if (bodyRowsSinglePeriod[0][1].text === 0) {
                    bodyRowsSinglePeriod.push([
                      {
                        text: 'Compliance',
                        color: '#444F51',
                        fontSize: 12,
                        margin: [8, 6, 0, 6],
                        bold: true,
                      },
                      {
                        text: singlePeriodCompliance + '%',
                        color: '#444F51',
                        fontSize: 12,
                        alignment: 'right',
                        bold: true,
                        margin: [0, 6, 8, 6],
                      },
                    ]);
                  } else {
                    if (bodyRowsSinglePeriod[0][1].text === '-') {
                      singlePeriodCompliance = '-';
                    } else {
                      singlePeriodCompliance =
                        (bodyRowsSinglePeriod[0][1].text /
                          (singlePeriodTotal -
                            bodyRowsSinglePeriod[2][1].text)) *
                        100;
                    }
                    bodyRowsSinglePeriod.push([
                      {
                        text: 'Compliance',
                        color: '#444F51',
                        fontSize: 12,
                        margin: [8, 6, 0, 6],
                        bold: true,
                      },
                      {
                        text:
                          singlePeriodCompliance !== '-'
                            ? Math.round(singlePeriodCompliance * 100) / 100 +
                              '%'
                            : singlePeriodCompliance,
                        color: '#444F51',
                        fontSize: 12,
                        alignment: 'right',
                        bold: true,
                        margin: [0, 6, 8, 6],
                      },
                    ]);
                  }
                }
                const singlePeriodTableData = {
                  table: {
                    unbreakable: true,
                    widths: ['*', 150],
                    body: [
                      [
                        {
                          text: 'Answer Choices',
                          color: '#9F9F9F',
                          fontSize: 11,
                          margin: [8, 10, 0, 10],
                          rowSpan: 2,
                        },
                        {
                          text: siteName,
                          color: '#9F9F9F',
                          fontSize: 11,
                          alignment: 'right',
                          margin: [0, 10, 8, 10],
                        },
                      ],
                      [
                        {},
                        {
                          text: [
                            periodChunks[criteriaOptionDetailsIndex][0].title +
                              '\n\n',
                            this.formatDateRange(
                              periodChunks[criteriaOptionDetailsIndex][0],
                            ),
                          ],
                          color: '#9F9F9F',
                          fontSize: 11,
                          alignment: 'right',
                          margin: [0, 10, 8, 10],
                        },
                      ],
                      ...bodyRowsSinglePeriod,
                    ],
                  },
                  layout: {
                    hLineWidth: () => {
                      return 0.5;
                    },
                    vLineWidth: () => {
                      return 0.5;
                    },
                    hLineColor: () => {
                      return '#DBDBDB';
                    },
                    vLineColor: () => {
                      return '#DBDBDB';
                    },
                  },
                };
                const singlePeriodStack: any[] = [singlePeriodTableData];
                const criteriaSinglePeriodTable = {
                  stack: singlePeriodStack,
                  unbreakable: true,
                };
                dirtyTables.push(criteriaSinglePeriodTable);
                break;
              default:
                const bodyRows: any[] = [];
                const valueRows: any[] = [];
                const totalRow: number[] = [];
                const complianceRow: any[] = [];
                const optionsList: string[] = [];
                criteriaOptionDetails.forEach(
                  (detail: any, detailIndex: number) => {
                    let totalValue: number = 0;
                    let total: number | string = 0;
                    let trueValue: number = 0;
                    let naValue: number = 0;
                    detail.criteriaSamplingData.forEach(
                      (optionsData: any, optionDataIndex: number) => {
                        const option = Object.keys(optionsData)[0];
                        if (!optionsList.includes(option)) {
                          optionsList.push(option);
                        }
                        switch (optionDataIndex) {
                          case 0:
                            trueValue = optionsData[option];
                            break;
                          case 1:
                            break;
                          default:
                            naValue = optionsData[option];
                        }

                        totalValue += optionsData[option];
                        total = detail.criteriaSamplingDataConfiguration.length
                          ? detail.criteriaSamplingDataConfiguration[0]
                              .isSamplingEnabled
                            ? totalValue
                            : '-'
                          : '-';

                        if (
                          detail.criteriaSamplingDataConfiguration.length === 0
                        ) {
                          for (const [key] of Object.entries(optionsData)) {
                            optionsData[key] = '-';
                          }
                        }

                        if (valueRows[detailIndex]) {
                          valueRows[detailIndex] = {
                            ...valueRows[detailIndex],
                            ...optionsData,
                          };
                        } else {
                          valueRows[detailIndex] = optionsData;
                        }
                      },
                    );
                    totalRow.push(total);
                    if (detail.criteriaType === CRITERION_TYPE.BOOLEAN) {
                      let compliance: number | string = (
                        (trueValue / (total - naValue)) *
                        100
                      ).toFixed(2);

                      if (compliance === 'NaN') {
                        compliance = detail.criteriaSamplingDataConfiguration
                          .length
                          ? detail.criteriaSamplingDataConfiguration[0]
                              .isSamplingEnabled
                            ? 0
                            : '-'
                          : '-';
                      }
                      complianceRow.push(compliance ? compliance : 0);
                    }
                  },
                );
                const periodOne = valueRows[0];
                const periodTwo = valueRows[1];
                optionsList.forEach((option, index) => {
                  let periodOneValue: number | string;
                  if (periodOne && periodOne[option] !== undefined) {
                    periodOneValue = periodOne[option];
                  } else {
                    periodOneValue = option === 'N/A' ? 0 : '-';
                  }
                  const optionColumnOne = {
                    text: option,
                    color: '#444F51',
                    fontSize: 12,
                    border: [true, false, true, false],
                    margin:
                      index === optionsList.length - 1
                        ? [8, 6, 0, 6]
                        : [8, 6, 0, 0],
                  };
                  const optionColumnTwo = {
                    text: periodOneValue,
                    color: '#444F51',
                    fontSize: 12,
                    border: [true, false, true, false],
                    alignment: 'right',
                    margin:
                      index === optionsList.length - 1
                        ? [0, 6, 8, 6]
                        : [0, 6, 8, 0],
                  };
                  let periodTwoValue: number | string;
                  if (periodTwo && periodTwo[option] !== undefined) {
                    periodTwoValue = periodTwo[option];
                  } else {
                    periodTwoValue = option === 'N/A' ? 0 : '-';
                  }
                  const optionColumnThree = {
                    text: periodTwoValue,
                    color: '#444F51',
                    fontSize: 12,
                    border: [true, false, true, false],
                    alignment: 'right',
                    margin:
                      index === optionsList.length - 1
                        ? [0, 6, 8, 6]
                        : [0, 6, 8, 0],
                  };
                  bodyRows.push([
                    optionColumnOne,
                    optionColumnTwo,
                    optionColumnThree,
                  ]);
                });
                const totalColumnOne = {
                  text: 'Total Data Collected',
                  color: '#444F51',
                  fontSize: 12,
                  margin: [8, 6, 0, 6],
                };
                const totalColumnTwo = {
                  text: totalRow[0],
                  color: '#444F51',
                  fontSize: 12,
                  alignment: 'right',
                  margin: [0, 6, 8, 6],
                };
                const totalColumnThree = {
                  text: totalRow[1],
                  color: '#444F51',
                  fontSize: 12,
                  alignment: 'right',
                  margin: [0, 6, 8, 6],
                };
                bodyRows.push([
                  totalColumnOne,
                  totalColumnTwo,
                  totalColumnThree,
                ]);
                if (
                  criteriaOptionDetails[0].criteriaType ===
                  CRITERION_TYPE.BOOLEAN
                ) {
                  const complianceColumnOne = {
                    text: 'Compliance',
                    color: '#444F51',
                    fontSize: 12,
                    margin: [8, 6, 0, 6],
                    bold: true,
                  };
                  const complianceColumnTwo = {
                    text:
                      complianceRow[0] !== '-'
                        ? complianceRow[0] + '%'
                        : complianceRow[0],
                    color: '#444F51',
                    fontSize: 12,
                    alignment: 'right',
                    bold: true,
                    margin: [0, 6, 8, 6],
                  };
                  const complianceColumnThree = {
                    text:
                      complianceRow[1] !== '-'
                        ? complianceRow[1] + '%'
                        : complianceRow[1],
                    color: '#444F51',
                    fontSize: 12,
                    alignment: 'right',
                    bold: true,
                    margin: [0, 6, 8, 6],
                  };
                  bodyRows.push([
                    complianceColumnOne,
                    complianceColumnTwo,
                    complianceColumnThree,
                  ]);
                }
                const tableData = {
                  table: {
                    unbreakable: true,
                    widths: ['*', 150, 150],
                    body: [
                      [
                        {
                          text: 'Answer Choices',
                          color: '#9F9F9F',
                          fontSize: 11,
                          margin: [8, 10, 0, 10],
                          rowSpan: 2,
                        },
                        {
                          text: siteName,
                          color: '#9F9F9F',
                          fontSize: 11,
                          alignment: 'right',
                          margin: [0, 10, 8, 10],
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {},
                        {
                          text: [
                            periodChunks[criteriaOptionDetailsIndex][0].title +
                              '\n\n',
                            this.formatDateRange(
                              periodChunks[criteriaOptionDetailsIndex][0],
                            ),
                          ],
                          color: '#9F9F9F',
                          fontSize: 11,
                          alignment: 'right',
                          margin: [0, 10, 8, 10],
                        },
                        {
                          text: [
                            periodChunks[criteriaOptionDetailsIndex][1].title +
                              '\n\n',
                            this.formatDateRange(
                              periodChunks[criteriaOptionDetailsIndex][1],
                            ),
                          ],
                          color: '#9F9F9F',
                          fontSize: 11,
                          alignment: 'right',
                          margin: [0, 10, 8, 10],
                        },
                      ],
                      ...bodyRows,
                    ],
                  },
                  layout: {
                    hLineWidth: () => {
                      return 0.5;
                    },
                    vLineWidth: () => {
                      return 0.5;
                    },
                    hLineColor: () => {
                      return '#DBDBDB';
                    },
                    vLineColor: () => {
                      return '#DBDBDB';
                    },
                  },
                };
                const stack: any[] = [tableData];
                const criteriaTable = {
                  stack,
                  unbreakable: true,
                };
                dirtyTables.push(criteriaTable);
                break;
            }
          },
        );
        const setMargin = {
          canvas: [],
          margin: [0, 0, 0, 13],
        };
        switch (dirtyTables.length) {
          case 1:
            dirtyTables[0].stack.unshift(title);
            dirtyTables[0].stack.push(setMargin);
            break;
          case 2:
            dirtyTables[0].stack.unshift(title);
            dirtyTables[0].stack.push(setMargin);
            break;
          default:
            dirtyTables.forEach((dirtyTable, dirtyTableIndex) => {
              switch (dirtyTableIndex) {
                case 0:
                  dirtyTable.stack.unshift(title);
                  dirtyTable.stack.push(setMargin);
                  break;
                case dirtyTables.length - 1:
                  dirtyTable.stack.push(setMargin);
                  break;
                default:
                  dirtyTable.stack.push(setMargin);
              }
            });
        }
        renderTables.push(dirtyTables);
      },
    );
    comparisonAggregateCriteriaConfiguration.forEach(
      (criteriaDetails, criteriaDetailsIndex) => {
        const criteriaChunks: any[] = [];
        const dirtyCriteriaDetails = _cloneDeep(criteriaDetails);
        while (dirtyCriteriaDetails.length) {
          criteriaChunks.push(dirtyCriteriaDetails.splice(0, 3));
        }
        const samplingTitle = {
          text: 'Sampling',
          bold: true,
          color: '#444F51',
          fontSize: 11,
          margin: [0, 13, 0, 17],
        };
        const dirtySamplingTables = this.getSamplingTables(
          criteriaChunks,
          samplingPeriodChunks,
          projectSites,
          samplingTitle,
        );
        renderSamplingTables.push(dirtySamplingTables);
      },
    );
    return { aggregateChart, renderTables, renderSamplingTables };
  }

  public getSamplingTables(
    criteriaChunks: any[],
    samplingPeriodChunks: any[],
    projectSites: ProjectSite[],
    samplingTitle: any,
  ) {
    const dirtySamplingTables: any[] = [];
    criteriaChunks.forEach(
      (criteriaOptionDetails: any, criteriaOptionDetailsIndex: number) => {
        switch (criteriaOptionDetails.length) {
          case 1:
            const bodyRowsPrimary: any[] = [];
            let totalColumnPrimary: number = 0;
            const periodHeaderRowPrimary: any[] = [];
            const titleHeaderRowPrimary: any[] = [];
            const transformedSamplingDataPrimary: any[] = [];
            const samplingDataRowsPrimary: any[] = [];
            const dirtySamplingDataRowsPrimary: any[] = [];
            criteriaOptionDetails.forEach(
              (detail: any, detailIndex: number) => {
                let colSpanValue = 0;
                switch (detail.samplingConfiguration.auditSamplingType) {
                  case 'adHoc':
                    titleHeaderRowPrimary.push({
                      text: 'Site',
                      color: '#444F51',
                      fontSize: 9,
                      margin: [8, 6, 8, 6],
                    });
                    titleHeaderRowPrimary.push({
                      text: 'Sampling',
                      color: '#444F51',
                      fontSize: 9,
                      alignment: 'right',
                      margin: [8, 6, 8, 6],
                    });
                    totalColumnPrimary += 2;
                    colSpanValue = 2;
                    const dirtyAdHocSamplingRows: ReportSamplingDto[] = getAdHocSampling(
                      samplingPeriodChunks[criteriaOptionDetailsIndex][
                        detailIndex
                      ].isAudit,
                      detail.isAuditCriteria,
                      detail,
                      projectSites,
                    );
                    dirtySamplingDataRowsPrimary.push(
                      ...dirtyAdHocSamplingRows,
                    );
                    break;
                  case 'consecutive':
                    if (
                      detail.samplingConfiguration.samplingMode === 'minAndMax'
                    ) {
                      if (
                        samplingPeriodChunks[criteriaOptionDetailsIndex][
                          detailIndex
                        ].isAudit
                      ) {
                        titleHeaderRowPrimary.push({
                          text: 'Site',
                          color: '#444F51',
                          fontSize: 9,
                          margin: [8, 6, 8, 6],
                        });
                        titleHeaderRowPrimary.push({
                          text: 'Min.',
                          color: '#444F51',
                          fontSize: 9,
                          alignment: 'right',
                          margin: [8, 6, 8, 6],
                        });
                        titleHeaderRowPrimary.push({
                          text: 'Max.',
                          color: '#444F51',
                          fontSize: 9,
                          alignment: 'right',
                          margin: [8, 6, 8, 6],
                        });
                        totalColumnPrimary += 3;
                        colSpanValue = 3;
                      } else {
                        titleHeaderRowPrimary.push({
                          text: 'Site',
                          color: '#444F51',
                          fontSize: 9,
                          margin: [8, 6, 8, 6],
                        });
                        titleHeaderRowPrimary.push({
                          text: 'Sampling',
                          color: '#444F51',
                          fontSize: 9,
                          alignment: 'right',
                          margin: [8, 6, 8, 6],
                        });
                        totalColumnPrimary += 2;
                        colSpanValue = 2;
                      }
                      const dirtyConsecutiveMinMaxSamplingRows: ReportSamplingDto[] = getConsecutiveMinMaxSampling(
                        samplingPeriodChunks[criteriaOptionDetailsIndex][
                          detailIndex
                        ].isAudit,
                        detail.isAuditCriteria,
                        detail,
                        projectSites,
                      );
                      dirtySamplingDataRowsPrimary.push(
                        ...dirtyConsecutiveMinMaxSamplingRows,
                      );
                    } else {
                      if (
                        samplingPeriodChunks[criteriaOptionDetailsIndex][
                          detailIndex
                        ].isAudit
                      ) {
                        titleHeaderRowPrimary.push({
                          text: 'Site',
                          color: '#444F51',
                          fontSize: 9,
                          margin: [8, 6, 8, 6],
                        });
                        titleHeaderRowPrimary.push({
                          text: 'Target',
                          color: '#444F51',
                          fontSize: 9,
                          alignment: 'right',
                          margin: [8, 6, 8, 6],
                        });
                      } else {
                        titleHeaderRowPrimary.push({
                          text: 'Site',
                          color: '#444F51',
                          fontSize: 9,
                          margin: [8, 6, 8, 6],
                        });
                        titleHeaderRowPrimary.push({
                          text: 'Sampling',
                          color: '#444F51',
                          fontSize: 9,
                          alignment: 'right',
                          margin: [8, 6, 8, 6],
                        });
                      }
                      totalColumnPrimary += 2;
                      colSpanValue = 2;
                      const dirtyDefaultConsecutiveSamplingRowsData: ReportSamplingDto[] = getDefaultSampling(
                        samplingPeriodChunks[criteriaOptionDetailsIndex][
                          detailIndex
                        ].isAudit,
                        detail.isAuditCriteria,
                        detail,
                        projectSites,
                      );
                      dirtySamplingDataRowsPrimary.push(
                        ...dirtyDefaultConsecutiveSamplingRowsData,
                      );
                    }
                    break;
                  default:
                    if (
                      samplingPeriodChunks[criteriaOptionDetailsIndex][
                        detailIndex
                      ].isAudit
                    ) {
                      titleHeaderRowPrimary.push({
                        text: 'Site',
                        color: '#444F51',
                        fontSize: 9,
                        margin: [8, 6, 8, 6],
                      });
                      titleHeaderRowPrimary.push({
                        text: 'Target',
                        color: '#444F51',
                        fontSize: 9,
                        alignment: 'right',
                        margin: [8, 6, 8, 6],
                      });
                    } else {
                      titleHeaderRowPrimary.push({
                        text: 'Site',
                        color: '#444F51',
                        fontSize: 9,
                        margin: [8, 6, 8, 6],
                      });
                      titleHeaderRowPrimary.push({
                        text: 'Sampling',
                        color: '#444F51',
                        fontSize: 9,
                        alignment: 'right',
                        margin: [8, 6, 8, 6],
                      });
                    }
                    totalColumnPrimary += 2;
                    colSpanValue = 2;
                    const dirtyDefaultSamplingRowsData: ReportSamplingDto[] = getDefaultSampling(
                      samplingPeriodChunks[criteriaOptionDetailsIndex][
                        detailIndex
                      ].isAudit,
                      detail.isAuditCriteria,
                      detail,
                      projectSites,
                      detail.samplingConfiguration.samplingMode === 'siteBased',
                    );
                    dirtySamplingDataRowsPrimary.push(
                      ...dirtyDefaultSamplingRowsData,
                    );
                }
                const periodData = {
                  text: [
                    samplingPeriodChunks[criteriaOptionDetailsIndex][
                      detailIndex
                    ].title + '\n\n',
                    this.formatDateRange(
                      samplingPeriodChunks[criteriaOptionDetailsIndex][
                        detailIndex
                      ],
                    ),
                  ],
                  color: '#9F9F9F',
                  fontSize: 9,
                  alignment: 'left',
                  margin: [8, 10, 8, 10],
                  colSpan: colSpanValue,
                };
                if (colSpanValue === 2) {
                  periodHeaderRowPrimary.push(periodData);
                  periodHeaderRowPrimary.push({});
                } else {
                  periodHeaderRowPrimary.push(periodData);
                  periodHeaderRowPrimary.push({});
                  periodHeaderRowPrimary.push({});
                }
              },
            );
            projectSites.forEach((siteData) => {
              const siteSampling = dirtySamplingDataRowsPrimary.filter(
                (rowData) => rowData.siteName === siteData.site.name,
              );
              const siteSamplingData: any[] = [];
              siteSampling.forEach((siteDataVar) => {
                siteSamplingData.push(siteDataVar.siteName);
                siteDataVar.values.forEach((value: any) => {
                  siteSamplingData.push(value);
                });
              });
              samplingDataRowsPrimary.push(siteSamplingData);
            });
            samplingDataRowsPrimary.forEach((samplingDataRow) => {
              const transformedData: any[] = [];
              samplingDataRow.forEach((rowData: any) => {
                transformedData.push({
                  text: rowData,
                  color: '#444F51',
                  fontSize: 9,
                  alignment:
                    Number.isInteger(Number(rowData)) ||
                    Number.isInteger(Number(rowData.replace('/', ''))) ||
                    rowData === '-' ||
                    rowData === 'Disabled' ||
                    rowData === 'Enabled'
                      ? 'right'
                      : 'left',
                  margin: [8, 6, 8, 6],
                });
              });
              transformedSamplingDataPrimary.push(transformedData);
            });
            bodyRowsPrimary.push(periodHeaderRowPrimary);
            bodyRowsPrimary.push(titleHeaderRowPrimary);
            bodyRowsPrimary.push(...transformedSamplingDataPrimary);
            const samplingTableDataPrimary = {
              table: {
                unbreakable: true,
                widths: [...Array(totalColumnPrimary).keys()].map(
                  (temp) => '*',
                ),
                body: [...bodyRowsPrimary],
              },
              layout: {
                hLineWidth: () => {
                  return 0.5;
                },
                vLineWidth: () => {
                  return 0.5;
                },
                hLineColor: () => {
                  return '#DBDBDB';
                },
                vLineColor: () => {
                  return '#DBDBDB';
                },
              },
            };
            const stackPrimary: any[] = [samplingTableDataPrimary];
            const criteriaSamplingTablePrimary = {
              stack: stackPrimary,
              unbreakable: true,
            };
            dirtySamplingTables.push(criteriaSamplingTablePrimary);
            break;
          case 2:
            const bodyRowsSecondary: any[] = [];
            let totalColumnSecondary: number = 0;
            const periodHeaderRowSecondary: any[] = [];
            const titleHeaderRowSecondary: any[] = [];
            const transformedSamplingDataSecondary: any[] = [];
            const samplingDataRowsSecondary: any[] = [];
            const dirtySamplingDataRowsSecondary: any[] = [];
            criteriaOptionDetails.forEach(
              (detail: any, detailIndex: number) => {
                let colSpanValue = 0;
                switch (detail.samplingConfiguration.auditSamplingType) {
                  case 'adHoc':
                    titleHeaderRowSecondary.push({
                      text: 'Site',
                      color: '#444F51',
                      fontSize: 9,
                      margin: [8, 6, 8, 6],
                    });
                    titleHeaderRowSecondary.push({
                      text: 'Sampling',
                      color: '#444F51',
                      fontSize: 9,
                      alignment: 'right',
                      margin: [8, 6, 8, 6],
                    });
                    totalColumnSecondary += 2;
                    colSpanValue = 2;
                    const dirtyAdHocSamplingRows: ReportSamplingDto[] = getAdHocSampling(
                      samplingPeriodChunks[criteriaOptionDetailsIndex][
                        detailIndex
                      ].isAudit,
                      detail.isAuditCriteria,
                      detail,
                      projectSites,
                    );
                    dirtySamplingDataRowsSecondary.push(
                      ...dirtyAdHocSamplingRows,
                    );
                    break;
                  case 'consecutive':
                    if (
                      detail.samplingConfiguration.samplingMode === 'minAndMax'
                    ) {
                      if (
                        samplingPeriodChunks[criteriaOptionDetailsIndex][
                          detailIndex
                        ].isAudit
                      ) {
                        titleHeaderRowSecondary.push({
                          text: 'Site',
                          color: '#444F51',
                          fontSize: 9,
                          margin: [8, 6, 8, 6],
                        });
                        titleHeaderRowSecondary.push({
                          text: 'Min.',
                          color: '#444F51',
                          fontSize: 9,
                          alignment: 'right',
                          margin: [8, 6, 8, 6],
                        });
                        titleHeaderRowSecondary.push({
                          text: 'Max.',
                          color: '#444F51',
                          fontSize: 9,
                          alignment: 'right',
                          margin: [8, 6, 8, 6],
                        });
                        totalColumnSecondary += 3;
                        colSpanValue = 3;
                      } else {
                        titleHeaderRowSecondary.push({
                          text: 'Site',
                          color: '#444F51',
                          fontSize: 9,
                          margin: [8, 6, 8, 6],
                        });
                        titleHeaderRowSecondary.push({
                          text: 'Sampling',
                          color: '#444F51',
                          fontSize: 9,
                          alignment: 'right',
                          margin: [8, 6, 8, 6],
                        });
                        totalColumnSecondary += 2;
                        colSpanValue = 2;
                      }
                      const dirtyConsecutiveMinMaxSamplingRows: ReportSamplingDto[] = getConsecutiveMinMaxSampling(
                        samplingPeriodChunks[criteriaOptionDetailsIndex][
                          detailIndex
                        ].isAudit,
                        detail.isAuditCriteria,
                        detail,
                        projectSites,
                      );
                      dirtySamplingDataRowsSecondary.push(
                        ...dirtyConsecutiveMinMaxSamplingRows,
                      );
                    } else {
                      if (
                        samplingPeriodChunks[criteriaOptionDetailsIndex][
                          detailIndex
                        ].isAudit
                      ) {
                        titleHeaderRowSecondary.push({
                          text: 'Site',
                          color: '#444F51',
                          fontSize: 9,
                          margin: [8, 6, 8, 6],
                        });
                        titleHeaderRowSecondary.push({
                          text: 'Target',
                          color: '#444F51',
                          fontSize: 9,
                          alignment: 'right',
                          margin: [8, 6, 8, 6],
                        });
                      } else {
                        titleHeaderRowSecondary.push({
                          text: 'Site',
                          color: '#444F51',
                          fontSize: 9,
                          margin: [8, 6, 8, 6],
                        });
                        titleHeaderRowSecondary.push({
                          text: 'Sampling',
                          color: '#444F51',
                          fontSize: 9,
                          alignment: 'right',
                          margin: [8, 6, 8, 6],
                        });
                      }
                      totalColumnSecondary += 2;
                      colSpanValue = 2;
                      const dirtyDefaultConsecutiveSamplingRowsData: ReportSamplingDto[] = getDefaultSampling(
                        samplingPeriodChunks[criteriaOptionDetailsIndex][
                          detailIndex
                        ].isAudit,
                        detail.isAuditCriteria,
                        detail,
                        projectSites,
                      );
                      dirtySamplingDataRowsSecondary.push(
                        ...dirtyDefaultConsecutiveSamplingRowsData,
                      );
                    }
                    break;
                  default:
                    if (
                      samplingPeriodChunks[criteriaOptionDetailsIndex][
                        detailIndex
                      ].isAudit
                    ) {
                      titleHeaderRowSecondary.push({
                        text: 'Site',
                        color: '#444F51',
                        fontSize: 9,
                        margin: [8, 6, 8, 6],
                      });
                      titleHeaderRowSecondary.push({
                        text: 'Target',
                        color: '#444F51',
                        fontSize: 9,
                        alignment: 'right',
                        margin: [8, 6, 8, 6],
                      });
                    } else {
                      titleHeaderRowSecondary.push({
                        text: 'Site',
                        color: '#444F51',
                        fontSize: 9,
                        margin: [8, 6, 8, 6],
                      });
                      titleHeaderRowSecondary.push({
                        text: 'Sampling',
                        color: '#444F51',
                        fontSize: 9,
                        alignment: 'right',
                        margin: [8, 6, 8, 6],
                      });
                    }
                    totalColumnSecondary += 2;
                    colSpanValue = 2;
                    const dirtyDefaultSamplingRowsData: ReportSamplingDto[] = getDefaultSampling(
                      samplingPeriodChunks[criteriaOptionDetailsIndex][
                        detailIndex
                      ].isAudit,
                      detail.isAuditCriteria,
                      detail,
                      projectSites,
                      detail.samplingConfiguration.samplingMode === 'siteBased',
                    );
                    dirtySamplingDataRowsSecondary.push(
                      ...dirtyDefaultSamplingRowsData,
                    );
                }
                const periodData = {
                  text: [
                    samplingPeriodChunks[criteriaOptionDetailsIndex][
                      detailIndex
                    ].title + '\n\n',
                    this.formatDateRange(
                      samplingPeriodChunks[criteriaOptionDetailsIndex][
                        detailIndex
                      ],
                    ),
                  ],
                  color: '#9F9F9F',
                  fontSize: 9,
                  alignment: 'left',
                  margin: [8, 10, 8, 10],
                  colSpan: colSpanValue,
                };
                if (colSpanValue === 2) {
                  periodHeaderRowSecondary.push(periodData);
                  periodHeaderRowSecondary.push({});
                } else {
                  periodHeaderRowSecondary.push(periodData);
                  periodHeaderRowSecondary.push({});
                  periodHeaderRowSecondary.push({});
                }
              },
            );
            projectSites.forEach((siteData) => {
              const siteSampling = dirtySamplingDataRowsSecondary.filter(
                (rowData) => rowData.siteName === siteData.site.name,
              );
              const siteSamplingData: any[] = [];
              siteSampling.forEach((siteDataVar) => {
                siteSamplingData.push(siteDataVar.siteName);
                siteDataVar.values.forEach((value: any) => {
                  siteSamplingData.push(value);
                });
              });
              samplingDataRowsSecondary.push(siteSamplingData);
            });
            samplingDataRowsSecondary.forEach((samplingDataRow) => {
              const transformedData: any[] = [];
              samplingDataRow.forEach((rowData: any) => {
                transformedData.push({
                  text: rowData,
                  color: '#444F51',
                  fontSize: 9,
                  alignment:
                    Number.isInteger(Number(rowData)) ||
                    Number.isInteger(Number(rowData.replace('/', ''))) ||
                    rowData === '-' ||
                    rowData === 'Disabled' ||
                    rowData === 'Enabled'
                      ? 'right'
                      : 'left',
                  margin: [8, 6, 8, 6],
                });
              });
              transformedSamplingDataSecondary.push(transformedData);
            });
            bodyRowsSecondary.push(periodHeaderRowSecondary);
            bodyRowsSecondary.push(titleHeaderRowSecondary);
            bodyRowsSecondary.push(...transformedSamplingDataSecondary);
            const samplingTableDataSecondary = {
              table: {
                unbreakable: true,
                widths: [...Array(totalColumnSecondary).keys()].map(
                  (temp) => '*',
                ),
                body: [...bodyRowsSecondary],
              },
              layout: {
                hLineWidth: () => {
                  return 0.5;
                },
                vLineWidth: () => {
                  return 0.5;
                },
                hLineColor: () => {
                  return '#DBDBDB';
                },
                vLineColor: () => {
                  return '#DBDBDB';
                },
              },
            };
            const stackSecondary: any[] = [samplingTableDataSecondary];
            const criteriaSamplingTableSecondary = {
              stack: stackSecondary,
              unbreakable: true,
            };
            dirtySamplingTables.push(criteriaSamplingTableSecondary);
            break;
          default:
            const bodyRows: any[] = [];
            let totalColumn: number = 0;
            const periodHeaderRow: any[] = [];
            const titleHeaderRow: any[] = [];
            const transformedSamplingData: any[] = [];
            const samplingDataRows: any[] = [];
            const dirtySamplingDataRows: any[] = [];
            criteriaOptionDetails.forEach(
              (detail: any, detailIndex: number) => {
                let colSpanValue: number = 0;
                switch (detail.samplingConfiguration.auditSamplingType) {
                  case 'adHoc':
                    titleHeaderRow.push({
                      text: 'Site',
                      color: '#444F51',
                      fontSize: 9,
                      margin: [8, 6, 8, 6],
                    });
                    titleHeaderRow.push({
                      text: 'Sampling',
                      color: '#444F51',
                      fontSize: 9,
                      alignment: 'right',
                      margin: [8, 6, 8, 6],
                    });
                    totalColumn += 2;
                    colSpanValue = 2;
                    const dirtyAdHocSamplingRows: ReportSamplingDto[] = getAdHocSampling(
                      samplingPeriodChunks[criteriaOptionDetailsIndex][
                        detailIndex
                      ].isAudit,
                      detail.isAuditCriteria,
                      detail,
                      projectSites,
                    );
                    dirtySamplingDataRows.push(...dirtyAdHocSamplingRows);
                    break;
                  case 'consecutive':
                    if (
                      detail.samplingConfiguration.samplingMode === 'minAndMax'
                    ) {
                      if (
                        samplingPeriodChunks[criteriaOptionDetailsIndex][
                          detailIndex
                        ].isAudit
                      ) {
                        titleHeaderRow.push({
                          text: 'Site',
                          color: '#444F51',
                          fontSize: 9,
                          margin: [8, 6, 8, 6],
                        });
                        titleHeaderRow.push({
                          text: 'Min.',
                          color: '#444F51',
                          fontSize: 9,
                          alignment: 'right',
                          margin: [8, 6, 8, 6],
                        });
                        titleHeaderRow.push({
                          text: 'Max.',
                          color: '#444F51',
                          fontSize: 9,
                          alignment: 'right',
                          margin: [8, 6, 8, 6],
                        });
                        totalColumn += 3;
                        colSpanValue = 3;
                      } else {
                        titleHeaderRow.push({
                          text: 'Site',
                          color: '#444F51',
                          fontSize: 9,
                          margin: [8, 6, 8, 6],
                        });
                        titleHeaderRow.push({
                          text: 'Sampling',
                          color: '#444F51',
                          fontSize: 9,
                          alignment: 'right',
                          margin: [8, 6, 8, 6],
                        });
                        totalColumn += 2;
                        colSpanValue = 2;
                      }
                      const dirtyConsecutiveMinMaxSamplingRows: ReportSamplingDto[] = getConsecutiveMinMaxSampling(
                        samplingPeriodChunks[criteriaOptionDetailsIndex][
                          detailIndex
                        ].isAudit,
                        detail.isAuditCriteria,
                        detail,
                        projectSites,
                      );
                      dirtySamplingDataRows.push(
                        ...dirtyConsecutiveMinMaxSamplingRows,
                      );
                    } else {
                      if (
                        samplingPeriodChunks[criteriaOptionDetailsIndex][
                          detailIndex
                        ].isAudit
                      ) {
                        titleHeaderRow.push({
                          text: 'Site',
                          color: '#444F51',
                          fontSize: 9,
                          margin: [8, 6, 8, 6],
                        });
                        titleHeaderRow.push({
                          text: 'Target',
                          color: '#444F51',
                          fontSize: 9,
                          alignment: 'right',
                          margin: [8, 6, 8, 6],
                        });
                      } else {
                        titleHeaderRow.push({
                          text: 'Site',
                          color: '#444F51',
                          fontSize: 9,
                          margin: [8, 6, 8, 6],
                        });
                        titleHeaderRow.push({
                          text: 'Sampling',
                          color: '#444F51',
                          fontSize: 9,
                          alignment: 'right',
                          margin: [8, 6, 8, 6],
                        });
                      }
                      totalColumn += 2;
                      colSpanValue = 2;
                      const dirtyDefaultConsecutiveSamplingRowsData: ReportSamplingDto[] = getDefaultSampling(
                        samplingPeriodChunks[criteriaOptionDetailsIndex][
                          detailIndex
                        ].isAudit,
                        detail.isAuditCriteria,
                        detail,
                        projectSites,
                      );
                      dirtySamplingDataRows.push(
                        ...dirtyDefaultConsecutiveSamplingRowsData,
                      );
                    }
                    break;
                  default:
                    if (
                      samplingPeriodChunks[criteriaOptionDetailsIndex][
                        detailIndex
                      ].isAudit
                    ) {
                      titleHeaderRow.push({
                        text: 'Site',
                        color: '#444F51',
                        fontSize: 9,
                        margin: [8, 6, 8, 6],
                      });
                      titleHeaderRow.push({
                        text: 'Target',
                        color: '#444F51',
                        fontSize: 9,
                        alignment: 'right',
                        margin: [8, 6, 8, 6],
                      });
                    } else {
                      titleHeaderRow.push({
                        text: 'Site',
                        color: '#444F51',
                        fontSize: 9,
                        margin: [8, 6, 8, 6],
                      });
                      titleHeaderRow.push({
                        text: 'Sampling',
                        color: '#444F51',
                        fontSize: 9,
                        alignment: 'right',
                        margin: [8, 6, 8, 6],
                      });
                    }
                    totalColumn += 2;
                    colSpanValue = 2;
                    const dirtyDefaultSamplingRowsData: ReportSamplingDto[] = getDefaultSampling(
                      samplingPeriodChunks[criteriaOptionDetailsIndex][
                        detailIndex
                      ].isAudit,
                      detail.isAuditCriteria,
                      detail,
                      projectSites,
                      detail.samplingConfiguration.samplingMode === 'siteBased',
                    );
                    dirtySamplingDataRows.push(...dirtyDefaultSamplingRowsData);
                }
                const periodData = {
                  text: [
                    samplingPeriodChunks[criteriaOptionDetailsIndex][
                      detailIndex
                    ].title + '\n\n',
                    this.formatDateRange(
                      samplingPeriodChunks[criteriaOptionDetailsIndex][
                        detailIndex
                      ],
                    ),
                  ],
                  color: '#9F9F9F',
                  fontSize: 9,
                  alignment: 'left',
                  margin: [8, 10, 8, 10],
                  colSpan: colSpanValue,
                };
                if (colSpanValue === 2) {
                  periodHeaderRow.push(periodData);
                  periodHeaderRow.push({});
                } else {
                  periodHeaderRow.push(periodData);
                  periodHeaderRow.push({});
                  periodHeaderRow.push({});
                }
              },
            );
            projectSites.forEach((siteData) => {
              const siteSampling = dirtySamplingDataRows.filter(
                (rowData) => rowData.siteName === siteData.site.name,
              );
              const siteSamplingData: any[] = [];
              siteSampling.forEach((siteDataVar) => {
                siteSamplingData.push(siteDataVar.siteName);
                siteDataVar.values.forEach((value: any) => {
                  siteSamplingData.push(value);
                });
              });
              samplingDataRows.push(siteSamplingData);
            });
            samplingDataRows.forEach((samplingDataRow) => {
              const transformedData: any[] = [];
              samplingDataRow.forEach((rowData: any) => {
                transformedData.push({
                  text: rowData,
                  color: '#444F51',
                  fontSize: 9,
                  alignment:
                    Number.isInteger(Number(rowData)) ||
                    Number.isInteger(Number(rowData.replace('/', ''))) ||
                    rowData === '-' ||
                    rowData === 'Disabled' ||
                    rowData === 'Enabled'
                      ? 'right'
                      : 'left',
                  margin: [8, 6, 8, 6],
                });
              });
              transformedSamplingData.push(transformedData);
            });
            bodyRows.push(periodHeaderRow);
            bodyRows.push(titleHeaderRow);
            bodyRows.push(...transformedSamplingData);
            const samplingTableData = {
              table: {
                unbreakable: true,
                widths: [...Array(totalColumn).keys()].map((temp) => '*'),
                body: [...bodyRows],
              },
              layout: {
                hLineWidth: () => {
                  return 0.5;
                },
                vLineWidth: () => {
                  return 0.5;
                },
                hLineColor: () => {
                  return '#DBDBDB';
                },
                vLineColor: () => {
                  return '#DBDBDB';
                },
              },
            };
            const stack: any[] = [samplingTableData];
            const criteriaSamplingTable = {
              stack,
              unbreakable: true,
            };
            dirtySamplingTables.push(criteriaSamplingTable);
        }
      },
    );
    const divider = {
      canvas: [
        {
          type: 'line',
          x1: 0,
          y1: 15,
          x2: 475,
          y2: 15,
          lineWidth: 0.5,
          lineColor: '#DBDBDB',
        },
      ],
      margin: [0, 0, 0, 13],
    };
    const setMargin = {
      canvas: [],
      margin: [0, 0, 0, 13],
    };
    switch (dirtySamplingTables.length) {
      case 1:
        dirtySamplingTables[0].stack.unshift(samplingTitle);
        dirtySamplingTables[0].stack.push(divider);
        break;
      case 2:
        dirtySamplingTables[0].stack.unshift(samplingTitle);
        dirtySamplingTables[0].stack.push(setMargin);
        dirtySamplingTables[1].stack.push(divider);
        break;
      default:
        dirtySamplingTables.forEach(
          (dirtySamplingTable, dirtySamplingTableIndex) => {
            switch (dirtySamplingTableIndex) {
              case 0:
                dirtySamplingTable.stack.unshift(samplingTitle);
                dirtySamplingTable.stack.push(setMargin);
                break;
              case dirtySamplingTables.length - 1:
                dirtySamplingTable.stack.push(divider);
                break;
              default:
                dirtySamplingTable.stack.push(setMargin);
            }
          },
        );
    }
    return dirtySamplingTables;
  }

  public transformLabel(label: string): string | string[] {
    const words = label.split(' ');
    let endIndex: number = labelSplitIndex;
    let eachLine: string = '';
    const eachLabel: string[] = [];
    _map(words, (word: string, wordIndex: string) => {
      switch (Number(wordIndex)) {
        case 0:
          eachLine = word;
          break;
        case endIndex:
          eachLabel.push(eachLine);
          eachLine = word;
          endIndex += labelSplitIndex;
          break;
        case words.length - 1:
          eachLine += ' ' + word;
          eachLabel.push(eachLine);
          break;
        default:
          eachLine += ' ' + word;
      }
    });
    if (eachLabel.length >= 3) {
      eachLabel[2] = eachLabel[2] + '...';
      return eachLabel.slice(0, 3);
    }
    return label;
  }

  public getAggregateCriteriaChartCategoryPercentage(
    comparisonIndividualBooleanCriteriaConfiguration: any[],
    payload: any,
  ): number {
    const noOfBooleanCriterias: number =
      comparisonIndividualBooleanCriteriaConfiguration.length;
    if (noOfBooleanCriterias === 1) {
      return this.getIndividualCriteriaChartCategoryPercentage(payload);
    }
    const noOfPeriods: number = payload.comparisonPeriods.length;
    return noOfBooleanCriterias * noOfPeriods <= 10 ? noOfPeriods * 0.2 : 0.8;
  }

  public getIndividualCriteriaChartCategoryPercentage(payload: any): number {
    const noOfPeriods: number = payload.comparisonPeriods.length;
    return noOfPeriods <= 10 ? noOfPeriods * 0.1 : 0.8;
  }

  public generateComparisonConfigurationData(
    configurationData: any[],
    payload: any,
    samplingConfigurationData: any[],
  ) {
    let comparisonAggregateCriteriaConfiguration: any[];
    if (
      Array.isArray(configurationData) &&
      configurationData.length === payload.comparisonPeriods.length
    ) {
      const aggregateConfiguration: any[] = [];
      _map(payload.filteredCriterias, (criteriaId, p) => {
        const aggregateCriteriaConfiguration: any[] = [];
        _map(configurationData, (configData, index) => {
          let dirtyAggregateConfiguration: any = {};
          configData.forEach((data: any) => {
            if (data.projectCriteria.id === criteriaId) {
              const criteriaOptionsDataDistribution =
                data.criteriaSamplingData.criteriaOptionsDataDistribution;
              const criteriaSamplingData = Object.keys(
                criteriaOptionsDataDistribution,
              ).map((key: string) => ({
                [key]:
                  data.criteriaSamplingData.criteriaOptionsDataDistribution[
                    key
                  ],
              }));
              const dirtyConfig = {
                title: _get(data, 'projectCriteria.title', ''),
                criteriaType: _get(data, 'projectCriteria.criteriaType', ''),
                sampleSize: Number(
                  _get(
                    data,
                    'criteriaSamplingDataConfigurations.sampleSize',
                    0,
                  ),
                ),
                siteSamplingConfiguration: data.siteSamplingDataConfigurations,
                criteriaSamplingDataConfiguration:
                  data.criteriaSamplingDataConfigurations,
                samplingConfiguration: samplingConfigurationData[index],
                criteriaSamplingData,
                isAuditCriteria: data.isAuditCriteria,
              };
              dirtyAggregateConfiguration = _cloneDeep(dirtyConfig);
            }
          });
          aggregateCriteriaConfiguration.push(dirtyAggregateConfiguration);
        });
        aggregateConfiguration.push(aggregateCriteriaConfiguration);
      });
      comparisonAggregateCriteriaConfiguration = _cloneDeep(
        aggregateConfiguration,
      );
    } else {
      comparisonAggregateCriteriaConfiguration = [];
    }
    const {
      comparisonIndividualBooleanCriteriaConfiguration,
      comparisonIndividualMixedCriteriaConfiguration,
    } = this.generateComparisonIndividualCriteriaSet(
      comparisonAggregateCriteriaConfiguration,
    );
    return {
      comparisonAggregateCriteriaConfiguration,
      comparisonIndividualBooleanCriteriaConfiguration,
      comparisonIndividualMixedCriteriaConfiguration,
    };
  }

  public generateComparisonIndividualCriteriaSet(
    comparisonAggregateCriteriaConfiguration: any[],
  ) {
    const comparisonIndividualBooleanCriteriaConfiguration = comparisonAggregateCriteriaConfiguration.filter(
      (item) => {
        return item[0].criteriaType === CRITERION_TYPE.BOOLEAN;
      },
    );
    const comparisonIndividualMixedCriteriaConfiguration = comparisonAggregateCriteriaConfiguration.filter(
      (item) => {
        return item[0].criteriaType !== CRITERION_TYPE.BOOLEAN;
      },
    );
    return {
      comparisonIndividualBooleanCriteriaConfiguration,
      comparisonIndividualMixedCriteriaConfiguration,
    };
  }

  public getBooleanCriteriaMapIds(
    projectCriteria: ProjectCriteria[],
    payload: any,
  ): number[] {
    const criteriaIds: number[] = [];
    const booleanCriteriaIds: number[] = [];
    _map(projectCriteria, (criteria: ProjectCriteria) => {
      if (criteria.criteriaType === CRITERION_TYPE.BOOLEAN) {
        booleanCriteriaIds.push(criteria.id);
      }
    });
    _map(booleanCriteriaIds, (eachId: number) => {
      if (payload.filteredCriterias.includes(eachId)) {
        criteriaIds.push(eachId);
      }
    });
    return criteriaIds;
  }

  public formatDateRange(dateObject: any): string {
    const startDate = new Date(dateObject.startedAt);
    let endDate = dateObject.endedAt;
    if (endDate === null) {
      endDate = dayjs().endOf('day').format();
    }
    return this.getDate(startDate) + ' - ' + this.getDate(new Date(endDate));
  }

  public getDate(newValue: Date): string {
    return (
      newValue.getDate() +
      ' ' +
      this.dirtyMonths[newValue.getMonth()] +
      ' ' +
      newValue.getFullYear()
    );
  }
}
