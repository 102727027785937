












































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import { Action, State } from 'vuex-class';
import HeroBar from '@/components/base/HeroBar.vue';
import {
  AuditDetail,
  AuditStatus,
} from '@/store/modules/audits/types/audits.types';
import CriteriaCard from '@/views/Audit/CriteriaCard.vue';
import AuditSampling from '@/views/Audit/AuditSampling.vue';
import {
  ProjectParticipantDTO,
  UserEntity,
  UserRoleInAudit,
} from '@/store/modules/projects/types/projects.types';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import AuditCriteriaList from '@/views/Project/components/AuditCriteriaList.vue';
import SiteSettings from '@/views/Project/components/SiteSettings.vue';
import DataCollectionModal from '@/views/Audit/dataCollectionModal.vue';

import AuditHeader from '@/views/Audit/components/AuditHeader.vue';
import AuditCriteriaTab from '@/views/Audit/components/AuditCriteriaTab.vue';
import DataCollectionTab from '@/views/Audit/components/DataCollectionTab.vue';
import ReportsTab from '@/views/Audit/components/ReportsTab.vue';
import AuditDashboardTab from '@/views/Audit/tabs/AuditDashboardTab.vue';
import AuditParticipantTab from '@/views/Audit/tabs/AuditParticipantTab.vue';
import AuditEventLogsTab from '@/views/Audit/tabs/AuditEventLogsTab.vue';
import { useUserEmail } from '@/utils/user.util';
import AuditSettingsTab from '@/views/Audit/tabs/AuditSettingsTab.vue';
import GripTab from '../Grip/GripTab.vue';
import { ProjectDetailPayload } from '../../store/modules/projects/types/projects.types';
import { RootState } from '@/store/store';

/**
 * Declaration of available tabs in the page
 */
const tabNames = [
  '#dashboard',
  '#criteria',
  '#participants',
  '#sampling',
  '#dataCollection',
  '#grip',
  '#report',
  '#settings',
  '#activityLog',
];

@Component({
  components: {
    AuditEventLogsTab,
    DataCollectionTab,
    AuditCriteriaTab,
    AuditHeader,
    DataCollectionModal,
    AuditSampling,
    DefaultLayout,
    HeroBar,
    CriteriaCard,
    AuditCriteriaList,
    SiteSettings,
    ReportsTab,
    AuditDashboardTab,
    AuditParticipantTab,
    GripTab,
    AuditSettingsTab,
  },
})
export default class AuditPage extends Vue {
  public currentTab = 0;
  public deletedSiteIdsList: number[] = [];
  public perPage = 10;
  public page = 1;
  public renderComponent = false;
  public renderDataCollectionComponent = false;
  public showDataCollectionTemplate: boolean = false;
  public singleAuditCriteriaId?: number;
  public editDataCollectionRecord?: any = null;
  public isSiteSubmitButtonDisabled: boolean = true;
  public auditSettingInfo: any = [];
  public projectOwner: UserEntity | null = null;

  public isAuditArchived: boolean = false;
  public showCriteriaTab: boolean = false;
  public showSamplingTab: boolean = false;
  public showDataCollectionTab: boolean = false;
  public showReportTab: boolean = false;
  public showSettingsTab: boolean = false;
  public showAuditPage: boolean = false;
  public showGripTab: boolean = false;

  public criteriaTabKey: number = Math.floor(Math.random() * 999);
  public samplingTabKey: number = Math.floor(Math.random() * 999);
  public dataCollectionTabKey: number = Math.floor(Math.random() * 999);
  public auditGripTabKey: number = Math.floor(Math.random() * 999);
  public reportTabKey: number = Math.floor(Math.random() * 999);
  public settingsTabKey: number = Math.floor(Math.random() * 999);
  public isLoading: boolean = true;
  public isProjectDetailsLoading: boolean = true;

  get currentRouteHash() {
    return this.$route.hash;
  }

  get projectStatus() {
    return this.auditDetail && this.auditDetail.project
      ? this.auditDetail.project.status
      : '';
  }

  get currentAuditStatus() {
    if (this.auditDetail) {
      return this.auditDetail.status;
    }
    return '';
  }

  get auditStatus() {
    return AuditStatus;
  }

  get editable() {
    if (
      this.projectStatus === 'Archived' ||
      this.currentAuditStatus === AuditStatus.ARCHIVED
    ) {
      return false;
    }
    return true;
  }

  get message() {
    return 'Archived data collection cannot be edited';
  }

  @Action('projects/getProjectDetail')
  public getProjectDetail!: (projectId: number) => void;

  @State((state: RootState) => state.projects.projectDetail)
  public projectDetail!: ProjectDetailPayload;

  @State((state: RootState) => state.projects.participants)
  public projectParticipants!: ProjectParticipantDTO[];

  @State((state: RootState) => state.projects.apiState.getProjectDetail.success)
  public getProjectDetailApiStateSuccess!: boolean;

  @Action('audits/getAuditDetail')
  public getAuditDetail!: (auditId: number) => void;

  @Action('audits/getUpdatedAuditJBICriteriaList')
  public getUpdatedAuditJBICriteriaList!: (auditId: number) => void;

  @State((state: RootState) => state.audits.auditDetail)
  public auditDetail!: AuditDetail;

  @State((state: RootState) => state.audits.apiState.getAuditDetail.success)
  public getAuditDetailApiStateSuccess!: boolean;

  @State((state: RootState) => state.audits.dataCollectionSummary)
  public dataCollectionSummary!: any;

  @State((state) => state.audits.dataCollectionPeriodTitle)
  public dataCollectionPeriodTitle!: any;

  get auditId(): number {
    return +this.$route.params.auditId;
  }

  public handleUpdatedAuditStatus(status: AuditStatus) {
    this.isAuditArchived = Boolean(status === AuditStatus.ARCHIVED);
  }

  public updateAuditSettingInfo(newValue: any) {
    this.auditSettingInfo = [];
    this.auditSettingInfo = {
      name: newValue.name,
      description: newValue.description,
      enableGrip: newValue.enableGrip,
      startDate: newValue.startDate,
      endDate: newValue.endDate,
    };
  }

  public mounted(): void {
    this.getAuditDetail(this.auditId);
  }

  @Watch('$route')
  public watchRoute(to: any, from: any) {
    if (from.hash === '#submitRecord') {
      this.showDataCollectionTemplate = false;
    }
  }

  public changeActiveTabBasedOnUrl() {
    let routeTabIndex;
    if (this.currentRouteHash === '#dataHistory') {
      routeTabIndex = tabNames.findIndex(
        (value) => value === '#dataCollection',
      );
    } else {
      routeTabIndex = tabNames.findIndex(
        (value) => value === this.currentRouteHash,
      );
    }
    const samplingTabIndex = tabNames.findIndex(
      (value) => value === '#sampling',
    );
    const dashboardTabIndex = tabNames.findIndex(
      (value) => value === '#dashboard',
    );

    if (routeTabIndex === -1) {
      this.currentTab = this.auditDetail.isUserAuthorizedToAccessAllSites
        ? samplingTabIndex
        : dashboardTabIndex;
      this.$router.replace(`${this.$route.path}${tabNames[this.currentTab]}`);
    } else if (
      routeTabIndex === samplingTabIndex &&
      !this.auditDetail.isUserAuthorizedToAccessAllSites
    ) {
      this.currentTab = dashboardTabIndex;
      this.$router.replace(`${this.$route.path}${tabNames[this.currentTab]}`);
    } else {
      this.currentTab = routeTabIndex;
    }

    this.renderTabComponent();
  }

  public renderTabComponent(updateKey: boolean = false) {
    switch (tabNames[this.currentTab]) {
      case '#criteria':
        this.showCriteriaTab = true;
        if (updateKey) {
          this.criteriaTabKey += 1;
        }
        break;
      case '#sampling':
        this.showSamplingTab = true;
        if (updateKey) {
          this.samplingTabKey += 1;
        }
        break;
      case '#dataCollection':
        this.showDataCollectionTab = true;
        if (updateKey) {
          this.dataCollectionTabKey += 1;
        }
        break;
      case '#grip':
        this.showGripTab = true;
        if (updateKey) {
          this.auditGripTabKey += 1;
        }
        break;
      case '#report':
        this.showReportTab = true;
        if (updateKey) {
          this.reportTabKey += 1;
        }
        break;
      case '#settings':
        this.showSettingsTab = true;
        if (updateKey) {
          // TODO: quick hack for reset form when navigate away from setting tab
          // proper way is to reset the form if not yet save or implement dialog on page leave
          this.settingsTabKey += 1;
        }
        break;
      default:
        if (this.currentRouteHash === '#dataHistory') {
          this.currentTab = 4;
          this.showDataCollectionTab = true;
          if (updateKey) {
            this.dataCollectionTabKey += 1;
          }
        }
        break;
    }
  }

  public tabChange(): void {
    this.isLoading = true;
    this.getAuditDetail(this.auditId);
    this.$router.replace({ hash: tabNames[this.currentTab] });
    if (tabNames[this.currentTab] === '#criteria') {
      this.getUpdatedAuditJBICriteriaList(this.auditId);
    }
    this.renderTabComponent(true);
  }

  public changeActiveTab(newValue: string) {
    this.$router.replace({ hash: newValue });
    this.currentTab = 7;
    this.changeActiveTabBasedOnUrl();
  }

  public checkUserAccess(auditDetail: AuditDetail) {
    if (!this.userEmail) {
      return false;
    }
    return auditDetail.userRoleInAudit === UserRoleInAudit.isOwner ||
      auditDetail.userRoleInAudit === UserRoleInAudit.isProjectCoOrdinator
      ? true
      : false;
  }

  get userEmail(): string | undefined {
    return useUserEmail.call(this);
  }

  public handleShowDataCollectionTemplate(auditCriteriaId?: number) {
    this.showDataCollectionTemplate = true;
    this.singleAuditCriteriaId = auditCriteriaId;
    this.editDataCollectionRecord = null;
  }

  public closeDataCollectionTemplate() {
    this.showDataCollectionTemplate = false;
    this.currentTab = 4;
    this.singleAuditCriteriaId = undefined;
    this.editDataCollectionRecord = null;
  }

  public handleEditDataCollectionRecord(rowData: any) {
    this.editDataCollectionRecord = rowData;
    this.showDataCollectionTemplate = true;
    this.singleAuditCriteriaId = undefined;
  }

  @Watch('getAuditDetailApiStateSuccess')
  @isTruthy
  public watchGetAuditDetailSuccess(): void {
    this.getUpdatedAuditJBICriteriaList(this.auditId);
    if (this.auditDetail.status === 'Deleted') {
      this.$router.push({
        name: 'project-details',
        params: {
          projectId: this.auditDetail.project.id.toString(),
        },
      });
    }
    this.isProjectDetailsLoading = true;
    this.getProjectDetail(this.auditDetail.project.id);
  }

  @Watch('getProjectDetailApiStateSuccess')
  @isTruthy
  public watchGetProjectDetailSuccess() {
    if (this.projectDetail.status === 'Deleted') {
      this.$router.push({
        name: 'project-me',
      });
    } else if (
      this.auditDetail.status === 'Deleted' ||
      !this.checkUserAccess(this.auditDetail)
    ) {
      this.$router.push({
        name: 'project-details',
        params: {
          projectId: this.auditDetail.project.id.toString(),
        },
      });
    }

    this.renderComponent = false;
    this.auditSettingInfo = {
      name: this.auditDetail.name,
      description: this.auditDetail.description,
      enableGrip: this.auditDetail.enableGrip,
      startDate: this.auditDetail.startDate,
      endDate: this.auditDetail.endDate,
    };
    this.changeActiveTabBasedOnUrl();
    this.$nextTick(() => {
      this.renderComponent = true;
    });

    this.isLoading = false;
    this.showAuditPage = true;
    this.isProjectDetailsLoading = false;
    this.isAuditArchived = Boolean(
      this.projectStatus === 'Archived' ||
        this.currentAuditStatus === AuditStatus.ARCHIVED,
    );
    this.projectOwner = this.projectDetail.user;
  }
}
