import dayjs from 'dayjs';
import XLSX from 'xlsx';
import JSZip from 'jszip';
import { ToastProgrammatic as Toast } from 'buefy';
import {
  ProjectDataCollectionRecordExportPayload,
  ProjectExportDataCollectionType,
} from '@/store/modules/projects/types/projects.types';

export class ProjectExportDataCsv {
  public async create(
    projectTitle: string,
    projectDataCollectionRecords: ProjectDataCollectionRecordExportPayload,
  ): Promise<void> {
    const zip = new JSZip();
    let exportedWorkbook;
    let exportedWorkSheet;
    const exportDateTime = dayjs().format('D MMM YYYY');
    const truncatedProjectTitle =
      projectTitle.length > 10 ? projectTitle.slice(0, 10) : projectTitle;
    const exportTitle = truncatedProjectTitle + '_' + exportDateTime;

    // constrctedData is an array of with dynamic key attribute which stores information of
    // header, subheader and data record in the exported file
    const constructedDataCollectionRecords: any[] = [];

    const {
      aggregate,
      individual,
      combined,
    } = await this.getProjectDataCollectionRecord(projectDataCollectionRecords);

    constructedDataCollectionRecords.push(aggregate, individual, combined);

    constructedDataCollectionRecords.forEach((csvList, csvListIndex) => {
      let exportDataCollectionMode: string;
      switch (csvListIndex) {
        case 0:
          exportDataCollectionMode = ProjectExportDataCollectionType.aggregate;
          break;
        case 1:
          exportDataCollectionMode = ProjectExportDataCollectionType.individual;
          break;
        default:
          exportDataCollectionMode = ProjectExportDataCollectionType.combined;
      }
      exportedWorkbook = XLSX.utils.book_new();
      exportedWorkSheet = XLSX.utils.json_to_sheet(csvList, {
        skipHeader: true,
      });

      XLSX.utils.book_append_sheet(
        exportedWorkbook,
        exportedWorkSheet,
        exportDataCollectionMode,
      );
      const outputFile = XLSX.write(exportedWorkbook, {
        bookType: 'csv',
        type: 'binary',
      });
      zip.file(exportDataCollectionMode + '.csv', outputFile, {
        binary: true,
      });
    });
    zip.generateAsync({ type: 'blob' }).then((content) => {
      try {
        saveAs(content, exportTitle + '.zip');
      } catch (err) {
        Toast.open({
          message: `Something went wrong. Try again.`,
          position: 'is-top',
          type: 'is-danger',
          duration: 3500,
        });
      } finally {
        Toast.open({
          message: `Export complete.`,
          position: 'is-top',
          type: 'is-dark',
          duration: 3500,
        });
      }
    });
  }

  public async getProjectDataCollectionRecord(
    projectDataCollectionRecords: ProjectDataCollectionRecordExportPayload,
  ) {
    const projectExportDataAggregate: any[] = this.modifiedProjectDataCollectionRecord(
      projectDataCollectionRecords.aggregate.projectHeaderExport,
      projectDataCollectionRecords.aggregate.projectSubHeaderExport,
      projectDataCollectionRecords.aggregate
        .projectDataCollectionRecordAggregate,
    );

    const projectExportDataIndividual: any[] = this.modifiedProjectDataCollectionRecord(
      projectDataCollectionRecords.individual.projectHeaderExportIndividual,
      projectDataCollectionRecords.individual.projectSubHeaderExportIndividual,
      projectDataCollectionRecords.individual
        .projectDataCollectionRecordIndividual,
    );

    const projectExportDataCombined: any[] = this.modifiedProjectDataCollectionRecord(
      projectDataCollectionRecords.combined.projectHeaderExport,
      projectDataCollectionRecords.combined.projectSubHeaderExport,
      projectDataCollectionRecords.combined.projectDataCollectionRecordInfo,
    );

    return {
      aggregate: projectExportDataAggregate,
      individual: projectExportDataIndividual,
      combined: projectExportDataCombined,
    };
  }

  public modifiedProjectDataCollectionRecord(
    dataCollectionRecordHeader: any,
    dataCollectionRecordSubHeader: any,
    dataCollectionRecordInfo: any,
  ) {
    const projectExportDataCollection: any[] = [];
    projectExportDataCollection.push(
      {
        ...dataCollectionRecordHeader,
      },
      {
        ...dataCollectionRecordSubHeader,
      },
    );

    dataCollectionRecordInfo.forEach((data: any) => {
      projectExportDataCollection.push({ ...data });
    });

    return projectExportDataCollection;
  }
}
