import { AuditSite } from '@/store/modules/audits/types/audits.types';
import {
  ProjectSite,
  ReportSamplingDto,
} from '@/store/modules/projects/types/projects.types';

export const getAdHocSampling = (
  isAudit: boolean,
  isAuditCriteria: boolean,
  sourceItem: any,
  projectSites: ProjectSite[] | AuditSite[],
): ReportSamplingDto[] => {
  const adHocRowData: ReportSamplingDto[] = [];
  const adHocSitesData: boolean[] = [];

  /**
   * adding header data of sampling table for total.
   * criteriaSamplingDataConfiguration will always have 0 index.
   */
  if (projectSites.length > 1) {
    adHocRowData.push({
      siteName: 'All Sites',
      values:
        isAudit &&
        isAuditCriteria &&
        isAuditCriteria &&
        sourceItem.criteriaSamplingDataConfiguration[0] &&
        sourceItem.criteriaSamplingDataConfiguration[0].isSamplingEnabled
          ? ['Enabled']
          : ['-'],
    });
  }

  /**
   * looping projects sites to return sampling data for each site
   * if custom comparison period return default values(samplingRowData),
   * cause custom period does't have sampling values
   *
   * if comparison period is audit cycle
   * i.e not custom comparison period find and return sampling value for the site(siteSampling)
   */
  projectSites.forEach((siteDetails) => {
    const samplingRowData = {
      siteName: siteDetails.site.name,
      values: ['-'],
    };
    let siteSampling = sourceItem.siteSamplingConfiguration.find(
      (sampling: any) =>
        sampling.auditSiteMap &&
        sampling.auditSiteMap.site.id === siteDetails.site.id,
    );
    if (isAudit && isAuditCriteria && siteSampling) {
      /** if project is single site clone site sampling from criteriaSampling */
      if (sourceItem.siteSamplingConfiguration.length === 1) {
        siteSampling = sourceItem.criteriaSamplingDataConfiguration[0];
      }

      samplingRowData.values = siteSampling.isSamplingEnabled
        ? ['Enabled']
        : ['Disabled'];
    }
    adHocRowData.push(samplingRowData);
    if (siteSampling && siteSampling.isSamplingEnabled) {
      adHocSitesData.push(true);
    } else {
      adHocSitesData.push(false);
    }
  });

  if (projectSites.length > 1) {
    /** get total enabled sites count for AdHoc sampling type */
    const trueCount: number = adHocSitesData.filter((value) => value).length;
    adHocRowData[0] = {
      ...adHocRowData[0],
      values:
        isAudit &&
        isAuditCriteria &&
        sourceItem.criteriaSamplingDataConfiguration[0]
          ? [trueCount + '/' + sourceItem.siteSamplingConfiguration.length]
          : ['-'],
    };
  }
  return adHocRowData;
};

export const getConsecutiveMinMaxSampling = (
  isAudit: boolean,
  isAuditCriteria: boolean,
  sourceItem: any,
  projectSites: ProjectSite[] | AuditSite[],
): ReportSamplingDto[] => {
  const dirtySamplingRows: ReportSamplingDto[] = [];

  /**
   * adding header data of sampling table for total.
   * criteriaSamplingDataConfiguration will always have 0 index.
   */
  if (projectSites.length > 1) {
    dirtySamplingRows.push({
      siteName: 'All Sites',
      values:
        isAudit && isAuditCriteria
          ? sourceItem.criteriaSamplingDataConfiguration[0]
            ? [
                sourceItem.criteriaSamplingDataConfiguration[0].minSampleSize,
                sourceItem.criteriaSamplingDataConfiguration[0].maxSampleSize,
              ]
            : ['-', '-']
          : ['-'],
    });
  }
  /**
   * looping projects sites to return sampling data for each site
   * if custom comparison period return default values(samplingRowData),
   * cause custom period does't have sampling values
   *
   * if comparison period is audit cycle
   * i.e not custom comparison period find and return sampling value for the site(siteSampling)
   */
  projectSites.forEach((siteDetails) => {
    const samplingRowData = {
      siteName: siteDetails.site.name,
      values: isAudit && isAuditCriteria ? ['-', '-'] : ['-'],
    };
    let siteSampling = sourceItem.siteSamplingConfiguration.find(
      (sampling: any) => {
        return (
          sampling.auditSiteMap &&
          sampling.auditSiteMap.site.id === siteDetails.site.id
        );
      },
    );
    if (isAudit && isAuditCriteria && siteSampling) {
      /**   if project is single site clone site sampling from criteriaSampling */
      if (sourceItem.siteSamplingConfiguration.length === 1) {
        siteSampling = sourceItem.criteriaSamplingDataConfiguration[0];
      }
      samplingRowData.values = [
        siteSampling.minSampleSize,
        siteSampling.maxSampleSize,
      ];
    }
    dirtySamplingRows.push(samplingRowData);
  });

  return dirtySamplingRows;
};

export const getDefaultSampling = (
  isAudit: boolean,
  isAuditCriteria: boolean,
  sourceItem: any,
  projectSites: ProjectSite[] | AuditSite[],
  isSamplingSiteBased: boolean = false,
): ReportSamplingDto[] => {
  const dirtySamplingRows: ReportSamplingDto[] = [];

  /**
   * adding header data of sampling table for total.
   * criteriaSamplingDataConfiguration will always have 0 index.
   */
  if (projectSites.length > 1) {
    dirtySamplingRows.push({
      siteName: 'All Sites',
      values:
        isAudit &&
        isAuditCriteria &&
        sourceItem.criteriaSamplingDataConfiguration[0]
          ? [sourceItem.criteriaSamplingDataConfiguration[0].sampleSize]
          : ['-'],
    });
  }
  /**
   * looping projects sites to return sampling data for each site
   * if custom comparison period return default values(samplingRowData),
   * cause custom period does't have sampling values
   *
   * if comparison period is audit cycle
   * i.e not custom comparison period find and return sampling value for the site(siteSampling)
   */
  let totalSiteSamplingValue: number = 0;
  projectSites.forEach((siteDetails) => {
    const samplingRowData = {
      siteName: siteDetails.site.name,
      values: ['-'],
    };
    let siteSampling = sourceItem.siteSamplingConfiguration.find(
      (sampling: any) =>
        sampling.auditSiteMap &&
        sampling.auditSiteMap.site.id === siteDetails.site.id,
    );
    if (isAudit && isAuditCriteria && siteSampling) {
      /**  if project is single site clone site sampling from criteriaSampling */
      if (sourceItem.siteSamplingConfiguration.length === 1) {
        siteSampling = sourceItem.criteriaSamplingDataConfiguration[0];
      }
      samplingRowData.values = [siteSampling.sampleSize];
      totalSiteSamplingValue += siteSampling.sampleSize;
    }
    dirtySamplingRows.push(samplingRowData);
  });

  if (projectSites.length > 1) {
    /** get total sampling sites count for siteBased samplingMode */
    if (isSamplingSiteBased) {
      dirtySamplingRows[0] = {
        ...dirtySamplingRows[0],
        values:
          isAudit &&
          isAuditCriteria &&
          sourceItem.criteriaSamplingDataConfiguration[0]
            ? [totalSiteSamplingValue]
            : ['-'],
      };
    }
  }

  return dirtySamplingRows;
};
