




























































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseLoading from '../../../components/base/BaseLoading.vue';
import ExistingCriteriaList from './ExistingCriteriaList.vue';
import ExistingGripBarrierList from './ExistingGripBarrierList.vue';
import ExistingGripEnablerList from './ExistingGripEnablerList.vue';
import ExistingGripStrategyList from './ExistingGripStrategyList.vue';
const tabList: string[] = [
  'library',
  'criteria',
  'barrier',
  'enabler',
  'strategy',
];
@Component({
  components: {
    BaseLoading,
    ExistingCriteriaList,
    ExistingGripBarrierList,
    ExistingGripEnablerList,
    ExistingGripStrategyList,
  },
})
export default class GripAnalysisSideBar extends Vue {
  @Prop() public projectId!: number;
  @Prop() public analysisCriteriaIds!: number[];
  @Prop() public isGripAnalysis!: boolean;
  @Prop() public isSideBarOpen!: boolean;
  @Prop() public activeSidebarTab!: number;

  public isLoading: boolean = true;
  public isSideBarCollapsed: boolean = false;
  public activeTab: number = this.activeSidebarTab ? this.activeSidebarTab : 1;
  public criteriaTabKey: number = Math.floor(Math.random() * 999);
  public barrierTabKey: number = Math.floor(Math.random() * 999);
  public enablerTabKey: number = Math.floor(Math.random() * 999);
  public strategyTabKey: number = Math.floor(Math.random() * 999);

  get tabList() {
    return tabList;
  }

  public tabChange(): void {
    switch (this.activeTab) {
      case tabList.indexOf('criteria'):
        this.criteriaTabKey += 1;
        break;
      case tabList.indexOf('barrier'):
        this.barrierTabKey += 1;
        break;
      case tabList.indexOf('enabler'):
        this.enablerTabKey += 1;
        break;
      case tabList.indexOf('strategy'):
        this.strategyTabKey += 1;
        break;
      default:
        break;
    }

    if (this.activeTab !== tabList.indexOf('library')) {
      this.$emit('updateActiveSidebarTab', this.activeTab);
    }
  }

  public mounted(): void {
    this.isSideBarCollapsed = this.isSideBarOpen ? this.isSideBarOpen : false;
  }

  public handleSelectedCriteria(data: {
    selectedCriteriaIds: number[];
    update: boolean;
  }): void {
    this.$emit('updateSelectedCriteria', data);
  }

  public openSideNav(tabValue: string): void {
    if (!this.isSideBarCollapsed) {
      this.isSideBarCollapsed = true;
      this.$emit('collapseSideNav', this.isSideBarCollapsed);
    }

    if (tabValue === 'library') {
      this.closeSideNav();
      window.open(`/projects/${this.projectId}#settings-grip`, '_blank');
    }
  }

  public closeSideNav(): void {
    this.isSideBarCollapsed = false;
    this.$emit('collapseSideNav', this.isSideBarCollapsed);
  }
}
