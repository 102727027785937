





























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Site } from '@/store/modules/site/types/site.types';
import { Action } from 'vuex-class';
import { cloneDeep as _cloneDeep } from 'lodash';
import { required } from 'vee-validate/dist/rules';
import {
  RecurrenceSettingPayload,
  DataCollectionPeriodPayload,
} from '@/store/modules/projects/types/projects.types';
import NewAuditSettings from './NewAuditSettings.vue';
import moment from 'moment';
import { getStartDateTime, getEndDateTime } from '../../../utils/date.util';

@Component({ components: { NewAuditSettings } })
export default class NewAuditModal extends Vue {
  @Prop()
  public modalTitle!: string;
  @Prop()
  public projectDetail!: any;
  @Prop()
  public latestDate!: Date;
  public defaultEndDate: any = moment(new Date()).add(1, 'M');
  public dataCollectionPeriodDateTotal: DataCollectionPeriodPayload = {
    auditNumber: 1,
    auditName: 'Baseline',
    startDate: getStartDateTime(this.latestDate),
    endDate: getEndDateTime(this.defaultEndDate.subtract(1, 'd')),
  };
  public auditSetting: RecurrenceSettingPayload = {
    recurrenceType: 'oneOff',
    scheduledRecurrenceStartDate: getStartDateTime(this.latestDate),
    auditSamplingType: 'targetSampleSize',
    enableGrip: false,
  };
  public showNewDataCollectionPeriod: boolean = false;

  public mounted() {
    this.showNewDataCollectionPeriod = true;
  }

  public addNewAuditSetting() {
    if (this.auditSetting.scheduledRecurrenceStartDate) {
      this.auditSetting.scheduledRecurrenceStartDate = getStartDateTime(
        this.auditSetting.scheduledRecurrenceStartDate,
      );
    }
    this.$emit('addNewAudit', this.auditSetting);
    this.closeModal();
  }

  public handleAuditSettingUpdate(newValue: RecurrenceSettingPayload) {
    if (newValue.scheduledRecurrenceStartDate) {
      newValue.scheduledRecurrenceStartDate =
        newValue.scheduledRecurrenceStartDate;
    }
    if (newValue.scheduledRecurrenceEndDate) {
      newValue.scheduledRecurrenceEndDate = newValue.scheduledRecurrenceEndDate;
    }
    this.auditSetting = newValue;
  }

  public closeModal() {
    this.$emit('close');
  }

  public isAuditIntervalInValid(): boolean {
    if (
      this.auditSetting.interval !== undefined &&
      this.auditSetting.interval < 1
    ) {
      return true;
    }
    return false;
  }

  public isAuditNumberInValid(): boolean {
    if (
      this.auditSetting.auditNumber !== undefined &&
      (this.auditSetting.auditNumber < 1 || this.auditSetting.auditNumber > 10)
    ) {
      return true;
    }
    return false;
  }

  get validateForm() {
    if (
      this.auditSetting.recurrenceType === 'multiple' &&
      (this.isAuditIntervalInValid() || this.isAuditNumberInValid())
    ) {
      return true;
    }
    return false;
  }

  public convertDate(value: any) {
    const myDate = new Date(Date.parse(value));
    const realDate =
      myDate.getFullYear() +
      '-' +
      ('0' + (myDate.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + myDate.getDate()).slice(-2);
    return realDate;
  }
}
