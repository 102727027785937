






































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { State, Action } from 'vuex-class';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '@/utils/date.util';
import { useUserEmail } from '@/utils/user.util';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';

@Component({
  components: {},
})
export default class DashboardRecentEventLogs extends Vue {
  @Action('projects/getRecentEventLogs')
  public getRecentEventLogs!: () => void;

  @State((state) => state.projects.recentEventLogs)
  public recentEventLogs!: any;

  @State((state) => state.projects.apiState.getRecentEventLogs)
  public getRecentEventLogsState!: any;

  public histories: any = [];

  public mounted() {
    this.getRecentEventLogs();
  }

  @Watch('getRecentEventLogsState.success')
  @isTruthy
  public watchGetRecentEventLogsState() {
    this.histories = this.recentEventLogs
      .filter((log: any) => {
        return log.eventPayload.originator;
      })
      .map((log: any) => {
        const today = dayjs().format(DATE_FORMAT);
        const yesterday = dayjs().add(-1, 'day').format(DATE_FORMAT);
        const dataUpdatedAt = dayjs(new Date(log.eventOccurredAt)).format(
          DATE_FORMAT,
        );

        let dateToDisplay;
        if (dataUpdatedAt === today) {
          dateToDisplay = 'Today';
        } else if (dataUpdatedAt === yesterday) {
          dateToDisplay = 'Yesterday';
        } else {
          dateToDisplay = dataUpdatedAt;
        }

        return {
          ...log,
          // @ts-ignore
          datetime: dateToDisplay,
        };
      });
  }

  public getUserName(userInfo: any) {
    const name = userInfo.name;
    const loggedInUserEmail = this.userEmail;
    if (loggedInUserEmail === userInfo.email) {
      return 'You';
    } else {
      return name;
    }
  }

  public getFormattedTitle(title: string) {
    if (title.length > 45) {
      return title.substring(0, 45) + '...';
    } else {
      return title;
    }
  }

  get userEmail(): string | undefined {
    return useUserEmail.call(this);
  }
}
