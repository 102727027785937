








import jsCookie from 'js-cookie';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import InfoModal from './InfoModal.vue';

@Component({
  components: { InfoModal },
})

/**
 * This component includes:
 * 1. Survey button link.
 * 2. Survey modal asking, user to take survey.
 *
 * Note:
 * * Show the modal only when the user is loggedIn and has not taken the survey yet.
 * * If the user selects the 'Maybe Later' option, set a cookie to manage the survey modal on refresh.
 *   (Do not show modal on refresh, even if the user hasn't taken a survey).
 */
export default class SurveyAcknowledgement extends Vue {
  @Prop({ default: false }) public showSurveyModal!: boolean;

  public tooltipMessage: string =
    'Help us improve PACES in less than 5 minutes';

  @Action('users/saveSurveyAcknowledgement')
  public saveSurveyAcknowledgement!: () => void;

  public mounted(): void {
    /**
     * Show the modal only when the user hasn't taken the survey yet ('showSurveyModal' prop)
     * and the user has just loggedIn (no cookie).
     */
    const surveyCookie: string | undefined = jsCookie.get('paces_survey');
    if (this.showSurveyModal && !surveyCookie) {
      this.openModal();
    }
  }

  public openModal(): void {
    const Modal = this.$buefy.modal.open({
      parent: this,
      component: InfoModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle: 'We value your feedback!',
        modalContent: 'Please help us improve PACES in less than 5 minutes.',
        confirmButtonText: 'Take the survey',
        cancelButtonText: 'Maybe later',
      },
      events: {
        confirm: () => {
          /** On confirm:
           * 1. Save the survey acknowledgement.
           * 2. Open the survey link in another window.
           * 3. Remove the cookie as the user has taken a survey.
           */
          jsCookie.remove('paces_survey');
          this.saveSurveyAcknowledgement();
          window.open('https://www.surveymonkey.com/r/KPGFZGY', '_blank');
          Modal.close();
        },
        close: () => {
          Modal.close();
        },
        cancel: () => {
          /** Set cookie to manage survey modal on refresh. */
          jsCookie.set('paces_survey', 'maybe_later');
          Modal.close();
        },
      },
    });
  }
}
