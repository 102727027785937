













import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class Card extends Vue {
  @Prop({ default: '' })
  public avatarText!: string;
}
