








































































import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import { cloneDeep as _cloneDeep } from 'lodash';

@Component({ components : {} })

export default class CriteriaFilter extends Vue {
  @Prop()
  public auditCriteria!: any[];
  @Prop()
  public filteredCriterias!: number[];
  @Prop({default: false})
  public isDisabled!: boolean;
  @Prop({default: false})
  public isExpanded!: boolean;
  public selectedCriteria: number[] = [];
  public isAllCriteriaSelected: boolean = true;
  public allCriteriaIds: number[] = [];

  public mounted() {
    if (this.filteredCriterias) {
      this.populateFilteredData();
    } else {
      this.populateData();
    }
  }

  public populateData() {
    this.auditCriteria.map((auditCriteria: any) => {
      this.selectedCriteria.push(auditCriteria.id);
    });
    this.allCriteriaIds = _cloneDeep(this.selectedCriteria);
  }

  public populateFilteredData() {
    this.auditCriteria.map((auditCriteria: any) => {
      this.allCriteriaIds.push(auditCriteria.id);
    });
    this.selectedCriteria = _cloneDeep(this.filteredCriterias);
    if (this.allCriteriaIds.length !== this.selectedCriteria.length) {
      this.isAllCriteriaSelected = false;
    }
  }

  public updateSelection(label: boolean = false, id: number) {
    if (label) {
      const criteriaIndex = this.selectedCriteria.indexOf(id);
      if (criteriaIndex === -1) {
        this.selectedCriteria.push(id);
      } else {
        this.selectedCriteria.splice(criteriaIndex, 1);
      }
    }
    this.isAllCriteriaSelected = this.allCriteriaIds.length === this.selectedCriteria.length;
    this.emitCriteriaFilter();
  }

  public updateAllSelection(label: boolean = false) {
    if (label) {
      this.isAllCriteriaSelected = !this.isAllCriteriaSelected;
    }
    if (this.isAllCriteriaSelected) {
      this.selectedCriteria = _cloneDeep(this.allCriteriaIds);
    } else {
      this.selectedCriteria = [];
    }
    this.emitCriteriaFilter();
  }

  public mapOrder(selectedCriteria: number[], auditCriteriaMapIds: number[]) {
    selectedCriteria.sort((selectedSite, selectedSiteNext) => {
        if (auditCriteriaMapIds.indexOf(selectedSite) > auditCriteriaMapIds.indexOf(selectedSiteNext)) {
            return 1;
        } else {
            return -1;
        }
    });
    return selectedCriteria;
  }

  public emitCriteriaFilter() {
    if (!(this.selectedCriteria.length === 0 || this.selectedCriteria.length === 1)) {
        const auditCriteriaMapIds: number[] = this.auditCriteria.map((auditCriteria) => auditCriteria.id);
        this.selectedCriteria = this.mapOrder(this.selectedCriteria, auditCriteriaMapIds);
    }
    this.$emit('applyCriteriaFilter', this.selectedCriteria);
  }
}
