





































import { Component, Vue, Watch } from 'vue-property-decorator';
import HomeHeader from '@/views/Home/components/HomeHeader.vue';
import HomeLayout from '../../layouts/HomeLayout.vue';
import { useAction } from '@/utils/store.util';

@Component({ components: { HomeHeader, HomeLayout } })
export default class NoSubscription extends Vue {
  public redirectToJaas() {
    window.location.href =
      process.env.VUE_APP_JAAS_DASHBOARD_URL + `/application/paces/products`;
  }

  get signOut() {
    return useAction.call(this, 'auth/signOut');
  }
}
