


































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { State, Action } from 'vuex-class';
import { RootState } from '../../store/store';
import { useUserEmail } from '../../utils/user.util';
import { get as _get, cloneDeep as _cloneDeep } from 'lodash';
import { isTruthy } from '../../jbi-shared/util/watcher.vue-decorator';
import BaseNotificationDropdown from './BaseNotificationDropdown.vue';
import { GetNotificationsPayload } from '../../store/modules/notification/types/notification.types';
import SurveyAcknowledgement from '../SurveyAcknowledgement.vue';
import { SurveyDTO } from '../../store/modules/users/types/users.types';
import { ApiState } from '../../store/types/general.types';

@Component({
  components: {
    BaseNotificationDropdown,
    SurveyAcknowledgement,
  },
})
export default class Navbar extends Vue {
  @Prop({ default: 'default' })
  public layout!: string;
  @Prop({
    default() {
      return false;
    },
  })
  public isAdmin!: boolean;

  @Prop({
    default() {
      return false;
    },
  })
  public isLoggedIn!: boolean;

  @Prop({
    default() {
      return true;
    },
  })
  public showWithoutSubscriptionLink!: boolean;

  @Action('notifications/getNotificationDropdown')
  public getNotificationDropdown!: () => void;

  @Action('notifications/getUserNotification')
  public getUserNotification!: (params: GetNotificationsPayload) => void;

  @State((state: RootState) => state.notifications.notificationDropdown)
  public notificationDropdown!: any;

  @State((state: RootState) => state.users.surveyData)
  public surveyData!: SurveyDTO;

  get userEmail(): string | undefined {
    return useUserEmail.call(this);
  }

  public notificationCount: number = 0;
  public notificationInfo: any = [];
  public notificationIds: number[] = [];
  public notificationKey: number = Math.floor(Math.random() * 999);
  public showSurveyButton: boolean =
    this.surveyData && this.surveyData.isSurveyActive ? true : false;
  public showSurveyModal: boolean = false;

  public rerenderNotificationDropdown() {
    if (this.$route.path !== '/notification') {
      this.getNotificationDropdown();
    }
  }

  public mounted(): void {
    this.showSurveyButton = this.surveyData && this.surveyData.isSurveyActive;
    this.showSurveyModal = false;
  }

  // TODO: Disable Notification
  // public async mounted() {
  //   if (this.isLoggedIn) {
  //     if (this.layout === 'default') {
  //       this.getNotificationDropdown();
  //     }
  //   }

  //   if (
  //     this.isLoggedIn &&
  //     this.showWithoutSubscriptionLink &&
  //     this.layout === 'default'
  //   ) {
  //     this.$socket.client.open();
  //     this.$socket.client.on('connect', () => {
  //       // this.$socket.client is `socket.io-client` instance
  //       // authApi.updateSessionSocket(this.$socket.client.id);
  //       this.$socket.client.emit('JOIN_NOTIFICATION', {
  //         userId: this.userEmail,
  //       });
  //       this.$socket.$subscribe('NOTIFICATION', async (payload: any) => {
  //         if (payload.type === 'VIEWED_NOTIFICATION') {
  //           this.notificationCount = 0;
  //           this.notificationInfo = [];
  //           this.notificationKey += 1;
  //         } else if (payload.type === 'VIEWED_SINGLE_NOTIFICATION') {
  //           this.notificationCount =
  //             this.notificationCount === 0 ? 0 : this.notificationCount - 1;
  //           const viewedNotification = this.notificationInfo;
  //           viewedNotification.filter((data: any, index: number) => {
  //             if (data.id === payload.notificationId) {
  //               this.notificationInfo.splice(index, 1);
  //             }
  //           });
  //           this.notificationKey += 1;
  //         } else {
  //           this.getNotificationDropdown();
  //         }
  //       });
  //     });
  //   }
  // }

  public beforeDestroy() {
    // TODO: Disable Notification
    // if (this.$socket.connected) {
    //   this.$socket.client.off('LEAVE_NOTIFICATION');
    // }
  }

  @Watch('notificationDropdown')
  @isTruthy
  public watchUserNotification(newValue: any) {
    this.notificationInfo = [];
    if (newValue && newValue.notificationInfoDetail.length > 0) {
      this.notificationInfo = _cloneDeep(newValue.notificationInfoDetail);
      this.notificationCount = newValue.totalItemsCount;
      this.notificationIds = newValue.notificationIds;
      this.notificationKey += 1;
    }
  }

  @Watch('surveyData', { deep: true })
  public onSurveyDataChange(): void {
    this.showSurveyButton = this.surveyData && this.surveyData.isSurveyActive;
    this.showSurveyModal = this.surveyData && !this.surveyData.isSurveyTaken;
  }

  private isNavActive(navItemName: string) {
    return this.$route.path.indexOf(navItemName) >= 0;
  }
}
