




import { Component, Vue, Watch } from 'vue-property-decorator';
import HomeHeader from '@/views/Home/components/HomeHeader.vue';
import { useAuthState } from '@/utils/store.util';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import HomeLayout from '../../layouts/HomeLayout.vue';
import jsCookie from 'js-cookie';
import { JAAS_LOGIN_URL } from '@/utils/login.util';
import BaseLoading from '../../components/base/BaseLoading.vue';

@Component({ components: { HomeHeader, HomeLayout, BaseLoading } })
export default class HomePage extends Vue {
  get isLoggedIn(): boolean {
    return useAuthState.call(this).isLoggedIn;
  }

  public showDefaultLayout: boolean = false;

  @Watch('isLoggedIn', { immediate: true })
  @isTruthy
  public onLoggedIn(value: boolean) {
    this.$router.push('/dashboard');
  }

  public mounted() {
    const jaasSID = jsCookie.get('jaas.sid');
    if (jaasSID === undefined || !this.isLoggedIn) {
      window.location.href = JAAS_LOGIN_URL;
    }
  }
}
