var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-card"},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v(" View update from "+_vm._s(_vm.criteria.updatedJbiCriteria.connectPlusDocumentId)+". ")]),_c('button',{class:_vm.$style.closeIcon,attrs:{"type":"button"},on:{"click":_vm.closeModal}},[_c('i',{staticClass:"mdi mdi-close"})])]),_c('section',{staticClass:"modal-card-body"},[_c('div',{staticClass:"columns"},[_c('div',{class:["column", _vm.$style.updatedJbiCriteriaNotification]},[_c('span',[_vm._v(" All updated criteria will only be available for newly created data collection period. ")])])]),_c('div',{class:["column", _vm.$style.initialText]},[_c('span',[_vm._v(" Here are the update for your JBI Criteria from "+_vm._s(_vm.criteria.updatedJbiCriteria.connectPlusDocumentId)+". ")])]),_c('div',{staticClass:"columns"},[_c('div',{class:["column is-half"]},[_c('div',{class:_vm.$style.criteriaContainer},[_c('div',{class:_vm.$style.criteriaHeader},[_vm._v("Your current criteria")]),_c('div',{class:_vm.$style.criteriaDocumentId},[_vm._v(" "+_vm._s(_vm.criteria.updatedJbiCriteria.currentCriteria.documentId)+" ")]),_c('div',{class:_vm.$style.criteriaTitle},[_vm._v(" "+_vm._s(_vm.criteria.title)+" ")]),(_vm.criteria.criteriaOptions)?_c('div',{class:_vm.$style.criteriaOptions},[(
                _vm.criteria.type === _vm.criteriaType.BOOLEAN ||
                _vm.criteria.criteriaType === _vm.criteriaType.BOOLEAN
              )?_vm._l((_vm.criteria.criteriaOptions),function(booleanOption,index){return _c('div',{key:("" + index + booleanOption)},[_c('b-radio',{class:_vm.$style.booleanOption,attrs:{"disabled":"","native-value":"booleanOption","size":"is-small"}},[_c('span',{class:_vm.$style.optionText},[_vm._v(" "+_vm._s(booleanOption)+" ")])])],1)}):_vm._e(),(
                _vm.criteria.type === _vm.criteriaType.RANGE ||
                _vm.criteria.criteriaType === _vm.criteriaType.RANGE
              )?[_c('div',[_vm._v("Min: "+_vm._s(_vm.criteria.criteriaOptions[0]))]),_c('div',[_vm._v("Max: "+_vm._s(_vm.criteria.criteriaOptions[1]))])]:_vm._e(),(
                _vm.criteria.type === _vm.criteriaType.CHECKBOXES ||
                _vm.criteria.criteriaType === _vm.criteriaType.CHECKBOXES
              )?_vm._l((_vm.criteria.criteriaOptions),function(checkboxesOption,index){return _c('div',{key:("" + index + checkboxesOption)},[_c('b-checkbox',{attrs:{"disabled":"","type":"is-info"}},[_c('span',{class:_vm.$style.optionText},[_vm._v(_vm._s(checkboxesOption))])])],1)}):_vm._e()],2):_vm._e()])]),_c('div',{class:['column', _vm.$style.customeCssChevronRight]},[_c('span',{class:['icon', _vm.$style.iconRight]},[_c('i',{staticClass:"fas fa-chevron-right"})])]),_c('div',{class:["column is-half"]},[(
            _vm.criteria.updatedJbiCriteria.status === _vm.jbiCriteriaStatus.DELETED
          )?_c('div',{class:_vm.$style.deletedCriteriaContainer},[_c('div',{class:_vm.$style.deleteCriteriaDetails},[_c('div',{class:[_vm.$style.criteriaHeader, _vm.$style.paddingHorizontal]},[_vm._v(" Deleted JBI Criteria ")]),_c('div',{class:[_vm.$style.criteriaDocumentId, _vm.$style.paddingHorizontal]},[_vm._v(" "+_vm._s(_vm.criteria.updatedJbiCriteria.connectPlusDocumentId)+" ")])]),_c('div',{class:_vm.$style.deleteCriteriaBox},[_c('p',{class:_vm.$style.deleteCriteriaText},[_vm._v(" This criteria has been deleted ")])])]):(
            _vm.criteria.updatedJbiCriteria.status === _vm.jbiCriteriaStatus.UPDATED
          )?_c('div',{class:_vm.$style.criteriaContainer},[_c('div',{class:_vm.$style.criteriaHeader},[_vm._v("Updated JBI Criteria")]),_c('div',{class:_vm.$style.criteriaDocumentId},[_vm._v(" "+_vm._s(_vm.criteria.updatedJbiCriteria.connectPlusDocumentId)+" ")]),_c('div',{class:_vm.$style.criteriaTitle},[_vm._v(" "+_vm._s(_vm.criteria.updatedJbiCriteria.content.title)+" ")]),_c('div',{class:_vm.$style.criteriaOptions},[(
                _vm.criteria.updatedJbiCriteria.content.type ===
                  _vm.criteriaType.BOOLEAN &&
                _vm.criteria.updatedJbiCriteria.content.booleanOptions
              )?_vm._l((_vm.criteria.updatedJbiCriteria
                  .content.booleanOptions),function(booleanOption,index){return _c('div',{key:("" + index + booleanOption)},[_c('b-radio',{class:_vm.$style.booleanOption,attrs:{"disabled":"","native-value":"booleanOption","size":"is-small"}},[_c('span',{class:_vm.$style.optionText},[_vm._v(" "+_vm._s(booleanOption)+" ")])])],1)}):_vm._e(),(
                _vm.criteria.updatedJbiCriteria.content.type ===
                  _vm.criteriaType.RANGE &&
                _vm.criteria.updatedJbiCriteria.content.range
              )?[_c('div',[_vm._v(" Min: "+_vm._s(_vm.criteria.updatedJbiCriteria.content.range[0])+" ")]),_c('div',[_vm._v(" Max: "+_vm._s(_vm.criteria.updatedJbiCriteria.content.range[1])+" ")])]:_vm._e(),(
                _vm.criteria.updatedJbiCriteria.content.type ===
                  _vm.criteriaType.CHECKBOXES &&
                _vm.criteria.updatedJbiCriteria.content.checkboxesOptions
              )?_vm._l((_vm.criteria
                  .updatedJbiCriteria.content.checkboxesOptions),function(checkboxesOption,index){return _c('div',{key:("" + index + checkboxesOption)},[_c('b-checkbox',{attrs:{"disabled":"","type":"is-info"}},[_c('span',{class:_vm.$style.optionText},[_vm._v(_vm._s(checkboxesOption))])])],1)}):_vm._e()],2)]):_vm._e()])])]),_c('footer',{staticClass:"modal-card-foot"},[_c('button',{staticClass:"button is-primary is-rounded",on:{"click":_vm.closeModal}},[_vm._v(" Done ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }