











































import { Component, Vue } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '@/utils/date.util';
import { Action, State } from 'vuex-class';
import { ProjectDetailPayload } from '@/store/modules/projects/types/projects.types';
import { useUserEmail } from '@/utils/user.util';
import { getProjectUrlByRole } from '@/utils/project.util';

@Component({ components: {} })
export default class DashboardProjectList extends Vue {
  @Action('projects/getMyProjectsSummary')
  public getMyProjectsSummary!: () => void;

  @State((state) => state.projects.myProjectsSummary)
  public projects!: ProjectDetailPayload[];

  public mounted() {
    this.getMyProjectsSummary();
  }

  get projectList() {
    return this.projects ? this.projects : [];
  }

  public getProjectUrl(item: any) {
    return getProjectUrlByRole(item.id, item.userRoleInProject);
  }

  get userEmail(): string | undefined {
    return useUserEmail.call(this);
  }

  public dataCollectionStartDate(project: any): string {
    if (project.dataCollectionStartDate) {
      return this.formatDate(project.dataCollectionStartDate);
    } else {
      return 'N/A';
    }
  }

  public formatDate(s: string) {
    return dayjs(s).format(DATE_FORMAT);
  }
}
