

































import { Component, Vue, Prop } from 'vue-property-decorator';
import { cloneDeep as _cloneDeep } from 'lodash';
import { reportExportType } from '@/store/types/general.types';

@Component({ components: {} })
export default class ExportButton extends Vue {
  @Prop({ default: false })
  public disabled!: boolean;
  @Prop({ default: false })
  public isGripExport!: boolean;
  public dirtyExportType: string[] = [];

  public mounted() {
    this.dirtyExportType = _cloneDeep(reportExportType);
    /** this export should have 'PDF' & 'DOCX' formats only as option */
    this.dirtyExportType.splice(2, 4);
  }

  public handleExport(exportType: string) {
    this.$emit('handleExport', exportType);
  }
}
