import { ModuleTree } from 'vuex';
import { usersState } from './users.state';
import { usersActions } from './users.actions';
import { usersMutations } from './users.mutations';
import { RootState } from '@/store/store';

export default {
    namespaced: true,
    state: usersState,
    actions: usersActions,
    mutations: usersMutations,
} as ModuleTree<RootState>;
