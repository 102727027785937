































import { Component, Vue, Prop, Watch, Model } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import HeroBar from '@/components/base/HeroBar.vue';
import {
  Pagination,
  GetUserPayload,
  FilteredUserPayload,
  User,
} from '@/store/modules/users/types/users.types';
import { AxiosError } from 'axios';
import { get as _get } from 'lodash';
import { RootState } from '@/store/store';
import { ApiState } from '@/store/types/general.types';
import { Debounce } from '@/jbi-shared/util/debounce.vue-decorator';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { ToastProgrammatic as Toast } from 'buefy';
import UserFilter from '@/views/AdminUserManagement/components/UserFilter.vue';
import PaginatedUserList from '@/views/AdminUserManagement/components/PaginatedUserList.vue';

@Component({
  components: {
    PaginatedUserList,
    UserFilter,
    DefaultLayout,
    HeroBar,
  },
})
export default class AdminUserManagement extends Vue {
  public page: number = 1;
  public perPage = 50;
  public filteredParams: FilteredUserPayload = {};
  public showUserList: boolean = false;

  @Action('users/getUsers')
  public getUsers!: (params: GetUserPayload) => void;
  @State(({ users }: RootState) => users.users)
  public users!: Pagination<User>;
  @State(({ users }: RootState) => users.apiState.updateStatus)
  public updateStatusState!: ApiState;

  public handleReset() {
    this.filteredParams = {
      firstName: '',
      lastName: '',
      email: '',
      selectedStatus: '',
      username: '',
      orderBy: {
        field: 'username',
        sort: 'ASC',
      },
    };
    this.$router
      .push({
        path: this.$route.path,
        query: {
          firstName: '',
          lastName: '',
          email: '',
          username: '',
          selectedStatus: '',
          orderByField: 'username',
          orderBySort: 'ASC',
          limit: this.perPage.toString(),
          page: this.page.toString(),
        },
      })
      .catch(() => {
        return;
      });
  }

  @Debounce(500)
  public handleFilter() {
    this.page = 1;
    this.handlePaginator();
  }

  get userPaginated() {
    return {
      items: _get(this.users, 'items', []),
      totalItems: _get(this.users, 'totalItems', 0),
    };
  }

  public mounted() {
    this.filteredParams = {
      firstName: (this.$route.query.firstName as string) || '',
      lastName: (this.$route.query.lastName as string) || '',
      email: (this.$route.query.email as string) || '',
      selectedStatus: (this.$route.query.selectedStatus as string) || '',
      username: (this.$route.query.username as string) || '',
      orderBy: {
        field: (this.$route.query.orderByField as string) || 'username',
        sort: (this.$route.query.orderBySort as string) || 'ASC',
      },
    };
    this.getUsers({
      ...this.filteredParams,
      limit: Number(this.$route.query.limit) || this.perPage,
      page: Number(this.$route.query.page) || this.page,
    });
    this.perPage = Number(this.$route.query.limit) || this.perPage;
    this.page = Number(this.$route.query.page) || this.page;
    this.showUserList = true;
  }

  public handlePaginator() {
    let orderBy: any = {};
    let orderByField: string | undefined = '';
    let orderBySort: string | undefined = '';
    if (this.filteredParams.orderBy) {
      orderBy = {
        field: this.filteredParams.orderBy.field,
        sort: this.filteredParams.orderBy.sort,
      };
      orderByField = this.filteredParams.orderBy.field;
      orderBySort = this.filteredParams.orderBy.sort;
    }

    this.getUsers({
      firstName: this.filteredParams.firstName,
      lastName: this.filteredParams.lastName,
      email: this.filteredParams.email,
      username: this.filteredParams.username,
      selectedStatus: this.filteredParams.selectedStatus,
      orderBy,
      limit: this.perPage,
      page: this.page,
    });

    this.$router
      .push({
        path: this.$route.path,
        query: {
          firstName: this.filteredParams.firstName,
          lastName: this.filteredParams.lastName,
          email: this.filteredParams.email,
          username: this.filteredParams.username,
          selectedStatus: this.filteredParams.selectedStatus,
          orderByField,
          orderBySort,
          limit: this.perPage.toString(),
          page: this.page.toString(),
        },
      })
      .catch(() => {
        return;
      });
  }
}
