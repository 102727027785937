import { ProjectsState } from './types/projects.types';
import { defaultApiState } from '@/store/types/general.types';

export const projectsState: ProjectsState = {
  apiState: {
    getCplusCriterias: defaultApiState,
    createProject: defaultApiState,
    getMyProjects: defaultApiState,
    getProjectDetail: defaultApiState,
    updateProjectStatus: defaultApiState,
    getProjectParticipants: defaultApiState,
    getProjectParticipant: defaultApiState,
    createProjectParticipant: defaultApiState,
    deleteProjectParticipant: defaultApiState,
    updateProjectParticipant: defaultApiState,
    getSites: defaultApiState,
    createSite: defaultApiState,
    acceptInvitation: defaultApiState,
    updateCriteria: defaultApiState,
    updateProjectCriterias: defaultApiState,
    updateProjectSites: defaultApiState,
    updateProjectParticipants: defaultApiState,
    updateProjectSetting: defaultApiState,
    updateProjectGeneralSettings: defaultApiState,
    getDashboardChartData: defaultApiState,
    getMyProjectsSummary: defaultApiState,
    getDataCollectionActivityLogs: defaultApiState,
    getProjectCriteria: defaultApiState,
    getProjectSites: defaultApiState,
    getProjectAuditList: defaultApiState,
    getRecentEventLogs: defaultApiState,
    getPaginatedEventLogs: defaultApiState,
    getProjectAuditDataCollectionRecords: defaultApiState,
    getDocumentByPublication: defaultApiState,
    getUpdatedJBICriteriaListWithAcknowledgment: defaultApiState,
    getUpdatedProjectReportJBICriteriaList: defaultApiState,
    getUpdateJBICriteriaAcknowledgment: defaultApiState,
    updateJBICriteria: defaultApiState,
    getActiveProjects: defaultApiState,
    getCreatedProjects: defaultApiState,
    getDraftProjects: defaultApiState,
    getArchiveProjects: defaultApiState,
    addNewAudit: defaultApiState,
    getAuditGripType: defaultApiState,
    createAuditGripType: defaultApiState,
    updateAuditGripType: defaultApiState,
    getGripTypeDetail: defaultApiState,
    deleteAuditGripType: defaultApiState,
    getProjectDataCollectionRecordExport: defaultApiState,
    getProjectReportExport: defaultApiState,
    getProjectReportAnalysis: defaultApiState,
    getTotalProjectReportAnalysis: defaultApiState,
    updateProjectReportAnalysis: defaultApiState,
    deleteProjectReportAnalysis: defaultApiState,
    getProjectGrip: defaultApiState,
    updateProjectGrip: defaultApiState,
    createProjectGrip: defaultApiState,
    getProjectGripBarriers: defaultApiState,
    createProjectGripBarrier: defaultApiState,
    updateProjectGripBarrier: defaultApiState,
    deletedProjectGripBarrier: defaultApiState,
    getProjectGripEnablers: defaultApiState,
    createProjectGripEnabler: defaultApiState,
    updateProjectGripEnabler: defaultApiState,
    deletedProjectGripEnabler: defaultApiState,
    getProjectGripStrategies: defaultApiState,
    createProjectGripStrategy: defaultApiState,
    updateProjectGripStrategy: defaultApiState,
    deletedProjectGripStrategy: defaultApiState,
    updateProjectCriteriaById: defaultApiState,
    getGripDomainCategories: defaultApiState,
    getGripDomainCategory: defaultApiState,
    verifyGripDomainCategory: defaultApiState,
    createGripDomainCategory: defaultApiState,
    updateGripDomainCategory: defaultApiState,
    deleteGripDomainCategory: defaultApiState,
    getGripBarriers: defaultApiState,
    createGripBarrier: defaultApiState,
    updateGripBarrier: defaultApiState,
    deleteGripBarrier: defaultApiState,
    getGripEnablers: defaultApiState,
    createGripEnabler: defaultApiState,
    updateGripEnabler: defaultApiState,
    deleteGripEnabler: defaultApiState,
    getGripStrategies: defaultApiState,
    createGripStrategy: defaultApiState,
    updateGripStrategy: defaultApiState,
    deleteGripStrategy: defaultApiState,
    getGripDetails: defaultApiState,
    createGripDetails: defaultApiState,
    updateGripDetails: defaultApiState,
    getGripAnalysisList: defaultApiState,
    getFilteredGripAnalysisList: defaultApiState,
    getGripAnalysis: defaultApiState,
    createGripAnalysis: defaultApiState,
    renameGripAnalysis: defaultApiState,
    updateGripAnalysis: defaultApiState,
    deleteGripAnalysis: defaultApiState,
    createGripAnalysisBarrier: defaultApiState,
    updateGripAnalysisBarrier: defaultApiState,
    deleteGripAnalysisBarrier: defaultApiState,
    createGripAnalysisEnabler: defaultApiState,
    updateGripAnalysisEnabler: defaultApiState,
    deleteGripAnalysisEnabler: defaultApiState,
    createGripAnalysisStrategy: defaultApiState,
    updateGripAnalysisStrategy: defaultApiState,
    deleteGripAnalysisStrategy: defaultApiState,
    getGripNotifications: defaultApiState,
    updateGripNotifications: defaultApiState,
    getGripExportReport: defaultApiState,
    getProjectListForAdmin: defaultApiState,
    getProjectParticipantsForAdmin: defaultApiState,
    getProjectSitesForAdmin: defaultApiState,
    getExistingProjectOwnerRequest: defaultApiState,
    changeProjectOwnerRequest: defaultApiState,
    updateProjectOwnerRequest: defaultApiState,
    updateCancelProjectRequest: defaultApiState,
    getProjectOwnerHistory: defaultApiState,
  },
  cplusCriterias: undefined,
  createdProject: undefined,
  projects: undefined,
  projectDetail: undefined,
  updatedProjectStatus: undefined,
  participants: undefined,
  sites: undefined,
  createdSite: undefined,
  acceptedInvitedProject: undefined,
  updatedCriteria: undefined,
  updatedProjectCriterias: undefined,
  updatedProjectSites: undefined,
  updatedProjectParticipants: undefined,
  updatedProjectSetting: undefined,
  updatedProjectGeneralSettings: undefined,
  dashboardChartData: undefined,
  myProjectsSummary: undefined,
  dataCollectionActivityLogs: undefined,
  projectCriteria: undefined,
  projectSites: undefined,
  projectParticipants: undefined,
  projectAuditList: undefined,
  recentEventLogs: undefined,
  paginatedEventLogs: undefined,
  projectAuditDataCollectionRecords: undefined,
  documentByPublication: undefined,
  projectOwnerRequestPayload: undefined,
  updatedJBICriteriaListWithAcknowledgment: undefined,
  updatedProjectReportJBICriteriaList: undefined,
  updatedJBICriteriaAcknowledgment: undefined,
  updatedJbiCriteria: undefined,
  activeListProject: undefined,
  projectName: undefined,
  createdListProject: undefined,
  draftListProject: undefined,
  archiveListProject: undefined,
  addedNewAudit: undefined,
  auditGripType: undefined,
  createdAuditGripType: undefined,
  updatedAuditGripType: undefined,
  auditGripTypeDetail: undefined,
  deletedAuditGripType: undefined,
  projectDataCollectionRecordExport: undefined,
  projectReportExport: undefined,
  projectReportAnalysis: undefined,
  totalProjectReportAnalysis: undefined,
  updatedProjectReportAnalysis: undefined,
  deletedProjectReportAnalysis: undefined,
  projectGrip: undefined,
  updateProjectGrip: undefined,
  createProjectGrip: undefined,
  projectGripBarriers: undefined,
  createProjectGripBarrier: undefined,
  updateProjectGripBarrier: undefined,
  deletedProjectGripBarrier: undefined,
  projectGripEnablers: undefined,
  createProjectGripEnabler: undefined,
  updateProjectGripEnabler: undefined,
  deletedProjectGripEnabler: undefined,
  projectGripStrategies: undefined,
  createProjectGripStrategy: undefined,
  updateProjectGripStrategy: undefined,
  deletedProjectGripStrategy: undefined,
  updatedProjectCriteriaById: undefined,
  gripDomainCategories: undefined,
  gripDomainCategory: undefined,
  verifiedGripDomainCategory: undefined,
  createdGripDomainCategory: undefined,
  updatedGripDomainCategory: undefined,
  deletedGripDomainCategory: undefined,
  gripBarriers: undefined,
  createdGripBarrier: undefined,
  updatedGripBarrier: undefined,
  deletedGripBarrier: undefined,
  gripEnablers: undefined,
  createdGripEnabler: undefined,
  updatedGripEnabler: undefined,
  deletedGripEnabler: undefined,
  gripStrategies: undefined,
  createdGripStrategy: undefined,
  updatedGripStrategy: undefined,
  deletedGripStrategy: undefined,
  gripDetails: undefined,
  createdGripDetails: undefined,
  updatedGripDetails: undefined,
  gripAnalysisList: undefined,
  filteredGripAnalysisList: undefined,
  gripAnalysis: undefined,
  createdGripAnalysis: undefined,
  renamedGripAnalysis: undefined,
  updatedGripAnalysis: undefined,
  deletedGripAnalysis: undefined,
  createdGripAnalysisBarrier: undefined,
  updatedGripAnalysisBarrier: undefined,
  deletedGripAnalysisBarrier: undefined,
  createdGripAnalysisEnabler: undefined,
  updatedGripAnalysisEnabler: undefined,
  deletedGripAnalysisEnabler: undefined,
  createdGripAnalysisStrategy: undefined,
  updatedGripAnalysisStrategy: undefined,
  deletedGripAnalysisStrategy: undefined,
  gripNotifications: undefined,
  updatedGripNotifications: undefined,
  gripExportReport: undefined,
  administratorProjectList: undefined,
  projectParticipantsForAdmin: undefined,
  projectSitesForAdmin: undefined,
  existingProjectOwnerRequest: undefined,
  projectOwnerHistory: undefined,
};
