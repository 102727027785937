































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { State, Action } from 'vuex-class';
import Card from '@/components/Card.vue';
import AvatarCard from '@/components/AvatarCard.vue';
import {
  AuditDetail,
  AuditParticipantDTO,
  AuditParticipantRole,
  AuditSite,
  DataCollectionActivityLogRequestPayload,
} from '@/store/modules/audits/types/audits.types';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import moment from 'moment';
import { getJBIUsername } from '@/jbi-shared/util/user.utils';
import {
  ProjectParticipantDTO,
  ProjectParticipantRole,
  ProjectParticipantSiteMap,
} from '@/store/modules/projects/types/projects.types';
import { RootState } from '@/store/store';
import { PacesParticipantRoles } from '@/jbi-shared/types/paces-project.types';

@Component({
  components: {
    Card,
    AvatarCard,
  },
})
export default class AuditParticipantList extends Vue {
  @Prop() public auditDetail!: AuditDetail;
  @Prop() public auditParticipants!: AuditParticipantDTO[];
  @Prop() public projectParticipants!: ProjectParticipantDTO[];
  @Prop() public auditSites!: AuditSite[];
  public maxItemDisplay: number = 8;
  public viewAll: boolean = false;
  public dataCollectionRecords: any = [];

  @Action('audits/getDataCollectionActivityLogs')
  public getDataCollectionActivityLogs!: (
    payload: DataCollectionActivityLogRequestPayload,
  ) => void;

  @State((state: RootState) => state.audits.dataCollectionActivityLogs)
  public dataCollectionActivityLogs!: any;

  public mounted() {
    this.getDataCollectionActivityLogs({
      auditId: this.auditDetail.id,
      fromDate: new Date(this.auditDetail.startDate),
      toDate: new Date(),
      siteIds: this.auditSites.map((auditSite) => auditSite.id),
    });
  }

  @Watch('dataCollectionActivityLogs')
  @isTruthy
  public watchDataCollectionActivityLogs(newValue: any) {
    this.dataCollectionRecords = [];
    newValue.map((value: any) => {
      value.dataCollectionRecords.map((item: any) => {
        this.dataCollectionRecords.push({
          email: value.user.email,
          total: item.total,
        });
      });
    });
  }

  public totalDataCollection(value: any, email: any) {
    let totalValue = 0;
    value.map((data: any) => {
      if (data.email === email) {
        totalValue += Number(data.total);
      }
    });
    return totalValue;
  }

  public getLastSubmission(activityLogs: any[], email: string) {
    const userActivityLogs = activityLogs.filter((activityLog: any) => {
      return activityLog.user.email === email;
    });
    switch (userActivityLogs.length) {
      case 0:
        return '-';
      case 1:
        return this.getFormattedTimeGap(userActivityLogs[0].updatedAt);
      default:
        const lastSubmission = userActivityLogs.reduce(
          (firstDate, secondDate) =>
            moment(secondDate.date) > moment(firstDate.date)
              ? secondDate
              : firstDate,
        );
        return this.getFormattedTimeGap(lastSubmission.updatedAt);
    }
  }

  public getFormattedTimeGap(lastSubmissionDate: string) {
    const timeGap = moment(lastSubmissionDate).fromNow();
    if (timeGap === 'a day ago') {
      return 'Yesterday';
    } else if (timeGap.includes('days')) {
      return moment(lastSubmissionDate).format('DD MMM YYYY');
    } else {
      return timeGap.charAt(0).toUpperCase() + timeGap.slice(1);
    }
  }

  get allParticipants() {
    const participantData: Array<{
      userEmail: string;
      participantName: string;
    }> = [];
    const participantUserEmails: string[] = [];
    if (this.auditParticipants && this.auditSites) {
      const auditSiteIds: number[] = this.auditSites.map(
        (auditSite) => auditSite.site.id,
      );

      /** get project coordinators and owner */
      if (this.projectParticipants) {
        this.projectParticipants.forEach(
          (projectParticipant: ProjectParticipantDTO) => {
            if (!projectParticipant.isDeleted) {
              /**
               * Participant's role should be matched from participant role.
               * (As a participant could be both project coordinator and data collector
               * within the same project under different sites)
               */
              const projectCoOrdinatorRole:
                | ProjectParticipantRole
                | undefined = projectParticipant.participantRoles.find(
                (participantRole) =>
                  participantRole.role !== PacesParticipantRoles.DataCollector,
              );

              if (projectCoOrdinatorRole && !projectCoOrdinatorRole.isDeleted) {
                // ** Filter coordinator's sites based on audit sites */
                const participantSites: ProjectParticipantSiteMap[] = projectCoOrdinatorRole.participantSites.filter(
                  (participantSite) =>
                    auditSiteIds.includes(participantSite.site.id),
                );
                if (participantSites.length > 0) {
                  const participantName: string = projectParticipant.user
                    ? getJBIUsername(
                        projectParticipant.userEmail,
                        projectParticipant.user.oicPayload,
                      )
                    : projectParticipant.userEmail;

                  participantUserEmails.push(projectParticipant.userEmail);
                  participantData.push({
                    userEmail: projectParticipant.userEmail,
                    participantName,
                  });
                }
              }
            }
          },
        );
      }
      /** get audit data collectors */
      this.auditParticipants.forEach(
        (auditParticipant: AuditParticipantDTO) => {
          if (
            !auditParticipant.isDeleted &&
            !participantUserEmails.includes(auditParticipant.userEmail)
          ) {
            const dataCollectorRole:
              | AuditParticipantRole
              | undefined = auditParticipant.auditParticipantRoles.find(
              (participantRole: AuditParticipantRole) =>
                participantRole.role === PacesParticipantRoles.DataCollector,
            );

            if (dataCollectorRole && !dataCollectorRole.isDeleted) {
              // ** Filter sites based on audit sites */
              const participantSites: ProjectParticipantSiteMap[] = dataCollectorRole.auditParticipantSites.filter(
                (auditParticipantSite) =>
                  auditSiteIds.includes(auditParticipantSite.site.id),
              );

              if (participantSites.length > 0) {
                const participantName: string = auditParticipant.user
                  ? getJBIUsername(
                      auditParticipant.userEmail,
                      auditParticipant.user.oicPayload,
                    )
                  : auditParticipant.userEmail;

                participantData.push({
                  userEmail: auditParticipant.userEmail,
                  participantName,
                });
              }
            }
          }
        },
      );
    }

    return participantData;
  }

  get items() {
    if (this.viewAll) {
      return this.allParticipants;
    }
    if (this.allParticipants.length > 0) {
      return this.allParticipants.slice(0, this.maxItemDisplay);
    }
    return [];
  }

  public handleViewAll() {
    this.$emit('changeActiveTab', '#participants');
  }
}
