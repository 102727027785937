



















































import { Component, Vue, Prop, Watch, Model } from 'vue-property-decorator';
import { State, Getter, Action, Mutation, namespace } from 'vuex-class';
import { uniq, cloneDeep as _cloneDeep } from 'lodash';
const dirtyMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

@Component({
  components: {},
})
export default class SettingActiveDataCollectionDialog extends Vue {
  @Prop({ default: '' })
  public title!: string;

  @Prop({ default: '' })
  public content!: string;

  @Prop({ default: 'Remove' })
  public confirmButtonText!: string;

  @Prop({ default: true })
  public confirmButton!: boolean;

  @Prop({ default: 'Cancel' })
  public cancelButtonText!: string;

  @Prop({ default: true })
  public cancelButton!: boolean;

  @Prop({ default: true })
  public isActive!: boolean;

  @Prop()
  public resumeEndDate!: Date;

  public endDate: Date = new Date();

  public mounted() {
    this.endDate = _cloneDeep(this.resumeEndDate);
  }

  public getDate(newValue: Date): string {
    return (
      newValue.getDate() +
      ' ' +
      dirtyMonths[newValue.getMonth()] +
      ' ' +
      newValue.getFullYear()
    );
  }

  public updateSettingDataCollection() {
    this.$emit('close');
    this.$emit('confirm', this.endDate);
  }

  public formatDateRange(date: Date): string {
    return this.getDate(date);
  }
}
