










































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  CreateGripAnalysisStrategyPayload,
  CreateGripStrategyBase,
  GripAnalysisStrategy,
  GripItemReloadDTO,
  GripItemType,
  GripStrategyDTO,
  GripStrategyStatus,
  UpdateGripStrategyBase,
} from '../../../store/types/grip.types';
import BaseLoading from '../../../components/base/BaseLoading.vue';
import StrategyModal from '../Strategy/StrategyModal.vue';
import DeleteModalBox from '../../../components/DeleteModalBox.vue';

@Component({
  components: {
    BaseLoading,
    StrategyModal,
    DeleteModalBox,
    GripAnalysisStrategies,
  },
})
export default class GripAnalysisStrategies extends Vue {
  @Prop() public projectId!: number;
  @Prop() public isDataCollector!: boolean;
  @Prop() public gripAnalysisId!: number;
  @Prop() public gripAnalysisEnablerId!: number;
  @Prop() public gripAnalysisStrategies!: GripAnalysisStrategy[];
  @Prop() public updatedItem!: GripItemReloadDTO;
  public isGripAnalysisStrategiesLoading: boolean = false;
  public showStrategyDraggableBox: boolean = false;
  public itemList: GripAnalysisStrategy[] = [];
  public tempDirtyStrategy: CreateGripStrategyBase = {
    title: '',
    description: '',
    resource: '',
    method: '',
    links: [],
    attachments: [],
    gripDomainCategoryId: null,
  };
  public modalKey: number = Math.floor(Math.random() * 999);
  public isModalActive: boolean = false;
  public isNew: boolean = false;
  public gripStrategyDetails: GripStrategyDTO | null = null;

  public getUpperCase(value: string): string {
    return value.toUpperCase();
  }

  public updated(): void {
    if (this.updatedItem.item === GripItemType.AnalysisStrategy) {
      const element = document.getElementById(
        this.updatedItem.item + '_' + this.updatedItem.id,
      );
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      } else if (this.updatedItem.prevItemId) {
        const prevElement = document.getElementById(
          this.updatedItem.prevItemId as string,
        );
        if (prevElement) {
          prevElement.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }

    if (this.showStrategyDraggableBox) {
      const element = document.getElementById('strategyDropZone');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  public mounted(): void {
    this.itemList = this.gripAnalysisStrategies
      ? this.gripAnalysisStrategies
          .filter((strategy) => strategy.status === GripStrategyStatus.ACTIVE)
          .sort((a, b) => a.id - b.id)
      : [];
  }

  public handleShowDraggableBox(): void {
    this.showStrategyDraggableBox = true;
  }

  public onDragOver(event: any): void {
    const dragSupported = event.dataTransfer.types.length;
    if (dragSupported) {
      event.dataTransfer.dropEffect = 'copy';
      event.preventDefault();
    }
  }

  public onDrop(event: any, id: string): void {
    event.preventDefault();
    const jsonData = event.dataTransfer.getData('application/json');
    const data = JSON.parse(jsonData);
    if (!data) {
      return;
    }
    const { itemType, ...strategyData } = data;
    if (id === 'strategyDropZone' && itemType === 'strategy') {
      const strategyPayload: CreateGripAnalysisStrategyPayload = {
        ...strategyData,
        gripAnalysisId: this.gripAnalysisId,
        gripAnalysisEnablerId: this.gripAnalysisEnablerId,
      };
      this.$emit('addGripAnalysisStrategy', { strategyPayload, isNew: false });
    }
  }

  public openStrategyModal(): void {
    this.isNew = true;
    this.isModalActive = true;
    this.modalKey += 1;
    this.gripStrategyDetails = null;
  }

  public handleEdit(strategy: GripAnalysisStrategy): void {
    const { status, createdDate, updatedDate, ...strategyDetails } = strategy;

    this.gripStrategyDetails = strategyDetails as GripStrategyDTO;
    this.isNew = false;
    this.isModalActive = true;
    this.modalKey += 1;
  }

  public closeStrategyModal(): void {
    this.isModalActive = false;
  }

  public handleStrategyCreate(gripStrategy: CreateGripStrategyBase): void {
    const strategyPayload: CreateGripAnalysisStrategyPayload = {
      ...gripStrategy,
      gripAnalysisId: this.gripAnalysisId,
      gripAnalysisEnablerId: this.gripAnalysisEnablerId,
    };
    this.$emit('addGripAnalysisStrategy', {
      strategyPayload,
      isNew: true,
    });

    this.showStrategyDraggableBox = false;
  }

  public handleStrategyUpdate(gripStrategy: UpdateGripStrategyBase): void {
    this.$emit('updateGripAnalysisStrategy', {
      ...gripStrategy,
      gripAnalysisId: this.gripAnalysisId,
      gripAnalysisEnablerId: this.gripAnalysisEnablerId,
    });
    this.$emit('handleUpdatedItemReload', {
      item: GripItemType.AnalysisStrategy,
      id: gripStrategy.id,
      prevItemId: this.getPrevItemId(gripStrategy.id),
    });
    this.closeStrategyModal();
  }

  public handleDelete(gripAnalysisStrategyId: number): void {
    this.$buefy.modal.open({
      parent: this,
      component: DeleteModalBox,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle: 'Delete Strategy?',
        modalContent:
          'Are you sure you want to delete this strategy? This action cannot be undone.',
      },
      events: {
        close: () => {
          this.$emit('close');
        },
        confirm: () => {
          this.$emit('deleteGripAnalysisStrategy', gripAnalysisStrategyId);
          this.$emit('handleUpdatedItemReload', {
            item: GripItemType.AnalysisStrategy,
            id: gripAnalysisStrategyId,
            prevItemId: this.getPrevItemId(gripAnalysisStrategyId),
          });
        },
      },
    });
  }

  public getPrevItemId(gripStrategyId: number): string {
    const strategyIndex: number = this.itemList.findIndex(
      (item) => item.id === gripStrategyId,
    );

    return strategyIndex > 0
      ? GripItemType.AnalysisStrategy +
          '_' +
          this.itemList[strategyIndex - 1].id
      : GripItemType.AnalysisEnabler + '_' + this.gripAnalysisEnablerId;
  }
}
