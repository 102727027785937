var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.singleSitePayload.length > 0)?_c('div',[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-checkbox',{class:_vm.$style.inputCheckbox,attrs:{"disabled":!_vm.editable},model:{value:(_vm.validateDataEntry),callback:function ($$v) {_vm.validateDataEntry=$$v},expression:"validateDataEntry"}},[_vm._v("Validate when performing data collection beyond max value.")])],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column",class:_vm.$style.headerTitleColumn},[_c('label',{staticClass:"label",class:[_vm.$style.tableName, _vm.$style.criteriaBasedLabel]},[_vm._v("CRITERIA BASED SAMPLING (OPTIONAL)")])])]),_c('b-table',{class:_vm.$style.criteriaBasedTable,attrs:{"data":_vm.auditCriteriaList},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-table-column',{attrs:{"field":"index","label":"No."}},[_vm._v(" "+_vm._s(props.index + 1)+". ")]),_c('b-table-column',{attrs:{"field":"criteria","label":"Data Collection Period Criteria","width":"100%"}},[_vm._v(" "+_vm._s(props.row.criteriaTitle)+" ")]),_c('b-table-column',{attrs:{"width":"100px","field":"sampleSize","label":"Min."}},[_c('b-input',{class:_vm.$style.numberInput,attrs:{"disabled":!_vm.editable,"type":"number","onkeydown":"javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))","oninput":"javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);","maxlength":"7"},nativeOn:{"change":function($event){return _vm.validateRange(
              props.index,
              'min',
              props.row.minSampleSize,
              props.row.maxSampleSize
            )}},model:{value:(props.row.minSampleSize),callback:function ($$v) {_vm.$set(props.row, "minSampleSize", $$v)},expression:"props.row.minSampleSize"}})],1),_c('b-table-column',{attrs:{"width":"100px","field":"sampleSize","label":"Max."}},[_c('b-input',{class:_vm.$style.numberInput,attrs:{"disabled":!_vm.editable,"type":"number","onkeydown":"javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))","oninput":"javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);","maxlength":"7"},nativeOn:{"change":function($event){return _vm.validateRange(
              props.index,
              'max',
              props.row.minSampleSize,
              props.row.maxSampleSize
            )}},model:{value:(props.row.maxSampleSize),callback:function ($$v) {_vm.$set(props.row, "maxSampleSize", $$v)},expression:"props.row.maxSampleSize"}})],1)]}}],null,false,3762967493)}),(_vm.editable)?_c('div',{class:_vm.$style.saveChangesContainer},[_c('b-button',{class:_vm.$style.saveChangesBtn,attrs:{"type":"is-primary"},on:{"click":_vm.handleSingleSiteChanges}},[_vm._v("Save")])],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }