import { ProjectSite } from '@/store/modules/projects/types/projects.types';
import { cloneDeep as _cloneDeep, get as _get, map as _map } from 'lodash';
import { CRITERION_TYPE } from '@/store/types/criterions.types';
import dayjs from 'dayjs';

export class GenerateMultiSiteExcel {
  public dirtyMonths: string[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];
  public filterRowsCount: number = 6;

  public create(
    result: any,
    payload: any,
    analysisTitle: string,
    projectTitle: string,
    projectSites: ProjectSite[],
  ) {
    const exportDateTime = dayjs().format('D MMM YYYY, HH:mm A');
    const responseArray: any[] = [];
    responseArray.push({
      cellInfo: [
        {
          name: 'A1',
          bgColor: 'EFEFEF',
          border: true,
          bold: true,
          wrapText: true,
          alignment: 'left',
        },
        {
          name: 'B1',
          bgColor: 'FFFFFF',
          border: true,
          bold: false,
          wrapText: true,
          alignment: 'left',
        },
      ],
      data: ['Project Name', projectTitle],
    });
    responseArray.push({
      cellInfo: [
        {
          name: 'A2',
          bgColor: 'EFEFEF',
          border: true,
          bold: true,
          wrapText: true,
          alignment: 'left',
        },
        {
          name: 'B2',
          bgColor: 'FFFFFF',
          border: true,
          bold: false,
          wrapText: true,
          alignment: 'left',
        },
      ],
      data: ['Analysis Name', analysisTitle],
    });
    const selectedSites: string[] = [];
    payload.filteredSites.forEach((projectSiteMapId: number) => {
      const projectSiteMap = projectSites.find(
        (projectSite: ProjectSite) => projectSite.id === projectSiteMapId,
      );
      if (projectSiteMap) {
        selectedSites.push(projectSiteMap.site.name);
      }
    });
    responseArray.push({
      cellInfo: [
        {
          name: 'A3',
          bgColor: 'EFEFEF',
          border: true,
          bold: true,
          wrapText: true,
          alignment: 'left',
        },
        {
          name: 'B3',
          bgColor: 'FFFFFF',
          border: true,
          bold: false,
          wrapText: true,
          alignment: 'left',
        },
      ],
      data: ['Site', selectedSites.join('\n')],
    });
    responseArray.push({
      cellInfo: [
        {
          name: 'A4',
          bgColor: 'EFEFEF',
          border: true,
          bold: true,
          wrapText: true,
          alignment: 'left',
        },
        {
          name: 'B4',
          bgColor: 'FFFFFF',
          border: true,
          bold: false,
          wrapText: true,
          alignment: 'left',
        },
      ],
      data: [
        'Site Display Mode',
        payload.checkIndividualSite ? 'Individual' : 'Aggregate',
      ],
    });
    responseArray.push({
      cellInfo: [
        {
          name: 'A5',
          bgColor: 'EFEFEF',
          border: true,
          bold: true,
          wrapText: true,
          alignment: 'left',
        },
        {
          name: 'B5',
          bgColor: 'FFFFFF',
          border: true,
          bold: false,
          wrapText: true,
          alignment: 'left',
        },
      ],
      data: [
        'Data Collection Period',
        payload.selectedPeriod.title +
          ': ' +
          this.formatDateRange(payload.selectedPeriod),
      ],
    });
    switch (payload.checkIndividualSite) {
      case true:
        responseArray.push(
          ...this.generateIndividualSiteCriteriaTables(
            result,
            payload,
            selectedSites,
            this.filterRowsCount,
          ),
        );
        break;
      default:
        responseArray.push(
          ...this.generateAggregateSiteCriteriaTables(
            result,
            payload,
            selectedSites,
            projectSites,
            this.filterRowsCount,
          ),
        );
    }
    responseArray.push({
      cellInfo: [
        {
          name: 'A' + (responseArray.length + 1),
          isEmpty: true,
        },
      ],
      data: [''],
    });
    responseArray.push({
      cellInfo: [
        {
          name: 'A' + (responseArray.length + 1),
          isEmpty: true,
        },
      ],
      data: ['Exported on ' + exportDateTime],
    });
    return responseArray;
  }

  public generateIndividualSiteCriteriaTables(
    result: any,
    payload: any,
    siteNames: string[],
    rowsCount: number,
  ): any {
    const criteriaStatistics: any[] = [];
    const { aggregateCriteriaConfiguration } = this.generateConfigurationData(
      result.statisticData.configurationData,
      payload,
      result.statisticData.samplingConfiguration,
    );
    aggregateCriteriaConfiguration.forEach(
      (criteriaDetails, criteriaDetailsIndex) => {
        criteriaStatistics.push(
          ...[
            {
              cellInfo: [
                {
                  name: 'A' + rowsCount,
                  isEmpty: true,
                },
              ],
              data: [''],
            },
            {
              cellInfo: [
                {
                  name: 'A' + (rowsCount + 1),
                  isEmpty: true,
                },
              ],
              data: [''],
            },
            {
              cellInfo: [
                {
                  name: 'A' + (rowsCount + 2),
                  isEmpty: true,
                },
              ],
              data: [''],
            },
          ],
        );
        rowsCount += 3;
        criteriaStatistics.push({
          cellInfo: [
            {
              name: 'A' + rowsCount,
              bgColor: 'D3F1FC',
              border: true,
              bold: true,
              wrapText: true,
              alignment: 'left',
              mergeInfo:
                'A' +
                rowsCount +
                ':' +
                this.getColumnName(siteNames.length + 1) +
                rowsCount,
            },
          ],
          data: [criteriaDetailsIndex + 1 + '. ' + criteriaDetails.title],
        });
        rowsCount += 1;
        const headerRow: any = ['Answer Choices'];
        const headerRowCellInfo: any = [
          {
            name: 'A' + rowsCount,
            bgColor: 'EFEFEF',
            border: true,
            bold: true,
            wrapText: true,
            alignment: 'left',
          },
        ];
        siteNames.forEach((siteName: string, siteNameIndex: number) => {
          headerRow.push(siteName);
          headerRowCellInfo.push({
            name: this.getColumnName(siteNameIndex + 2) + rowsCount,
            bgColor: 'EFEFEF',
            border: true,
            bold: true,
            wrapText: true,
            alignment: 'right',
          });
        });
        criteriaStatistics.push({
          cellInfo: headerRowCellInfo,
          data: headerRow,
        });
        rowsCount += 1;
        const optionsList: string[] = [];
        const totalList: number[] = [];
        const complianceList: number[] = [];
        const valuesList: any[] = [];
        const trueValueList: number[][] = [];
        const naValueList: number[][] = [];
        criteriaDetails.criteriaSamplingData.forEach(
          (siteData: any, siteDataIndex: number) => {
            totalList.push(siteData.total);
            let trueValue: number = 0;
            let naValue: number = 0;
            const criteriaOptions = Object.keys(
              siteData.criteriaOptionsDataDistribution,
            );
            criteriaOptions.forEach((option: string, optionIndex: number) => {
              if (!optionsList.includes(option)) {
                optionsList.push(option);
              }
              switch (optionIndex) {
                case 0:
                  trueValue = siteData.criteriaOptionsDataDistribution[option];
                  break;
                case 1:
                  break;
                default:
                  naValue = siteData.criteriaOptionsDataDistribution[option];
              }
              trueValueList[siteDataIndex] = [trueValue];
              naValueList[siteDataIndex] = [naValue];
              if (valuesList[siteDataIndex]) {
                valuesList[siteDataIndex] = {
                  ...valuesList[siteDataIndex],
                  ...siteData.criteriaOptionsDataDistribution,
                };
              } else {
                valuesList[siteDataIndex] =
                  siteData.criteriaOptionsDataDistribution;
              }
            });
            if (criteriaDetails.criteriaType === CRITERION_TYPE.BOOLEAN) {
              const compliance = (trueValue / (siteData.total - naValue)) * 100;
              complianceList.push(compliance ? compliance : 0);
            }
          },
        );
        optionsList.forEach((optionName: string, optionNameIndex: number) => {
          const rowObject: any = [optionName];
          const rowObjectCellInfo: any = [
            {
              name: 'A' + rowsCount,
              bgColor: 'FFFFFF',
              border: true,
              bold: false,
              wrapText: true,
              alignment: 'left',
            },
          ];
          siteNames.forEach((siteName: string, siteNameIndex: number) => {
            const optionValue: number =
              valuesList[siteNameIndex] && valuesList[siteNameIndex][optionName]
                ? valuesList[siteNameIndex][optionName]
                : 0;
            rowObject.push(optionValue);
            rowObjectCellInfo.push({
              name: this.getColumnName(siteNameIndex + 2) + rowsCount,
              bgColor: 'FFFFFF',
              border: true,
              bold: false,
              wrapText: true,
              alignment: 'right',
            });
          });
          criteriaStatistics.push({
            cellInfo: rowObjectCellInfo,
            data: rowObject,
          });
          rowsCount += 1;
        });
        const totalRow: any = ['Total Data Collected'];
        const totalRowCellInfo: any = [
          {
            name: 'A' + rowsCount,
            bgColor: 'FFFFFF',
            border: true,
            bold: true,
            wrapText: true,
            alignment: 'left',
          },
        ];
        totalList.forEach((total: number, totalIndex: number) => {
          let formattedTotal;
          if (total.toString().includes('-')) {
            formattedTotal = '-';
          } else {
            formattedTotal = total;
          }
          totalRow.push(formattedTotal);
          totalRowCellInfo.push({
            name: this.getColumnName(totalIndex + 2) + rowsCount,
            bgColor: 'FFFFFF',
            border: true,
            bold: true,
            wrapText: true,
            alignment: 'right',
          });
        });
        criteriaStatistics.push({
          cellInfo: totalRowCellInfo,
          data: totalRow,
        });
        rowsCount += 1;
        if (criteriaDetails.criteriaType === CRITERION_TYPE.BOOLEAN) {
          const complianceRow: any = ['Compliance'];
          const complianceRowCellInfo: any = [
            {
              name: 'A' + rowsCount,
              bgColor: 'FFFFFF',
              border: true,
              bold: true,
              wrapText: true,
              alignment: 'left',
            },
          ];
          complianceList.forEach(
            (compliance: number, complianceIndex: number) => {
              complianceRow.push(Math.round(compliance * 100) / 100 + '%');
              complianceRowCellInfo.push({
                name: this.getColumnName(complianceIndex + 2) + rowsCount,
                bgColor: 'FFFFFF',
                border: true,
                bold: true,
                wrapText: true,
                alignment: 'right',
              });
            },
          );
          criteriaStatistics.push({
            cellInfo: complianceRowCellInfo,
            data: complianceRow,
          });
          rowsCount += 1;
        }
        // sampling table
        criteriaStatistics.push({
          cellInfo: [
            {
              name: 'A' + rowsCount,
              isEmpty: true,
            },
          ],
          data: [''],
        });
        rowsCount += 1;
        criteriaStatistics.push({
          cellInfo: [
            {
              name: 'A' + rowsCount,
              bgColor: 'FFFFFF',
              border: true,
              bold: true,
              wrapText: true,
              alignment: 'left',
              mergeInfo:
                'A' +
                rowsCount +
                ':' +
                this.getColumnMergeName(criteriaDetails) +
                rowsCount,
            },
          ],
          data: ['Sampling'],
        });
        rowsCount += 1;
        criteriaStatistics.push({
          cellInfo: [
            {
              name: 'A' + rowsCount,
              bgColor: 'EFEFEF',
              border: true,
              bold: true,
              wrapText: true,
              alignment: 'left',
              mergeInfo:
                'A' +
                rowsCount +
                ':' +
                this.getColumnMergeName(criteriaDetails) +
                rowsCount,
            },
          ],
          data: [
            payload.selectedPeriod.title +
              '\n' +
              this.formatDateRange(payload.selectedPeriod),
          ],
        });
        rowsCount += 1;
        const samplingTableData = this.getSamplingTable(
          criteriaDetails,
          rowsCount,
          payload.selectedPeriod,
        );
        criteriaStatistics.push(...samplingTableData.samplingTableData);
        rowsCount = samplingTableData.rowsCount;
      },
    );
    return criteriaStatistics;
  }

  public generateAggregateSiteCriteriaTables(
    result: any,
    payload: any,
    siteNames: string[],
    projectSites: ProjectSite[],
    rowsCount: number,
  ): any {
    const criteriaStatistics: any[] = [];
    const { aggregateCriteriaConfiguration } = this.generateConfigurationData(
      result.statisticData.configurationData,
      payload,
      result.statisticData.samplingConfiguration,
    );
    aggregateCriteriaConfiguration.forEach(
      (criteriaDetails, criteriaDetailsIndex) => {
        criteriaStatistics.push(
          ...[
            {
              cellInfo: [
                {
                  name: 'A' + rowsCount,
                  isEmpty: true,
                },
              ],
              data: [''],
            },
            {
              cellInfo: [
                {
                  name: 'A' + (rowsCount + 1),
                  isEmpty: true,
                },
              ],
              data: [''],
            },
            {
              cellInfo: [
                {
                  name: 'A' + (rowsCount + 2),
                  isEmpty: true,
                },
              ],
              data: [''],
            },
          ],
        );
        rowsCount += 3;
        criteriaStatistics.push({
          cellInfo: [
            {
              name: 'A' + rowsCount,
              bgColor: 'D3F1FC',
              border: true,
              bold: true,
              wrapText: true,
              alignment: 'left',
              mergeInfo: 'A' + rowsCount + ':' + 'B' + rowsCount,
            },
          ],
          data: [criteriaDetailsIndex + 1 + '. ' + criteriaDetails.title],
        });
        rowsCount += 1;
        let siteText: string;
        switch (siteNames.length) {
          case 1:
            siteText = siteNames[0];
            break;
          case projectSites.length:
            siteText = 'All Sites';
            break;
          default:
            siteText = 'Selected sites (' + siteNames.length + ')';
        }
        criteriaStatistics.push({
          cellInfo: [
            {
              name: 'A' + rowsCount,
              bgColor: 'EFEFEF',
              border: true,
              bold: true,
              wrapText: true,
              alignment: 'left',
            },
            {
              name: 'B' + rowsCount,
              bgColor: 'EFEFEF',
              border: true,
              bold: true,
              wrapText: true,
              alignment: 'right',
            },
          ],
          data: ['Answer Choices', siteText],
        });
        rowsCount += 1;
        let total: number = 0;
        let trueValue: number = 0;
        let naValue: number = 0;
        criteriaDetails.criteriaSamplingData.forEach(
          (optionsData: any, optionDataIndex: number) => {
            const option = Object.keys(optionsData)[0];
            switch (optionDataIndex) {
              case 0:
                trueValue = optionsData[option];
                break;
              case 1:
                break;
              default:
                naValue = optionsData[option];
            }
            total += optionsData[option];
            criteriaStatistics.push({
              cellInfo: [
                {
                  name: 'A' + rowsCount,
                  bgColor: 'FFFFFF',
                  border: true,
                  bold: false,
                  wrapText: true,
                  alignment: 'left',
                },
                {
                  name: 'B' + rowsCount,
                  bgColor: 'FFFFFF',
                  border: true,
                  bold: false,
                  wrapText: true,
                  alignment: 'right',
                },
              ],
              data: [option, optionsData[option]],
            });
            rowsCount += 1;
          },
        );
        let formattedTotal;
        if (total.toString().includes('-')) {
          formattedTotal = '-';
        } else {
          formattedTotal = total;
        }
        criteriaStatistics.push({
          cellInfo: [
            {
              name: 'A' + rowsCount,
              bgColor: 'FFFFFF',
              border: true,
              bold: true,
              wrapText: true,
              alignment: 'left',
            },
            {
              name: 'B' + rowsCount,
              bgColor: 'FFFFFF',
              border: true,
              bold: true,
              wrapText: true,
              alignment: 'right',
            },
          ],
          data: ['Total Data Collected', formattedTotal],
        });
        rowsCount += 1;
        if (criteriaDetails.criteriaType === CRITERION_TYPE.BOOLEAN) {
          const compliance = (trueValue / (total - naValue)) * 100;
          criteriaStatistics.push({
            cellInfo: [
              {
                name: 'A' + rowsCount,
                bgColor: 'FFFFFF',
                border: true,
                bold: true,
                wrapText: true,
                alignment: 'left',
              },
              {
                name: 'B' + rowsCount,
                bgColor: 'FFFFFF',
                border: true,
                bold: true,
                wrapText: true,
                alignment: 'right',
              },
            ],
            data: [
              'Compliance',
              compliance ? Math.round(compliance * 100) / 100 + '%' : '0%',
            ],
          });
          rowsCount += 1;
        }
        // sampling table
        criteriaStatistics.push({
          cellInfo: [
            {
              name: 'A' + rowsCount,
              isEmpty: true,
            },
          ],
          data: [''],
        });
        rowsCount += 1;
        criteriaStatistics.push({
          cellInfo: [
            {
              name: 'A' + rowsCount,
              bgColor: 'FFFFFF',
              border: true,
              bold: true,
              wrapText: true,
              alignment: 'left',
              mergeInfo:
                'A' +
                rowsCount +
                ':' +
                this.getColumnMergeName(criteriaDetails) +
                rowsCount,
            },
          ],
          data: ['Sampling'],
        });
        rowsCount += 1;
        criteriaStatistics.push({
          cellInfo: [
            {
              name: 'A' + rowsCount,
              bgColor: 'EFEFEF',
              border: true,
              bold: true,
              wrapText: true,
              alignment: 'left',
              mergeInfo:
                'A' +
                rowsCount +
                ':' +
                this.getColumnMergeName(criteriaDetails) +
                rowsCount,
            },
          ],
          data: [
            payload.selectedPeriod.title +
              '\n' +
              this.formatDateRange(payload.selectedPeriod),
          ],
        });
        rowsCount += 1;
        const samplingTableData = this.getSamplingTable(
          criteriaDetails,
          rowsCount,
          payload.selectedPeriod,
        );
        criteriaStatistics.push(...samplingTableData.samplingTableData);
        rowsCount = samplingTableData.rowsCount;
      },
    );
    return criteriaStatistics;
  }

  public getColumnMergeName(criteriaDetails: any) {
    switch (criteriaDetails.samplingConfiguration.auditSamplingType) {
      case 'consecutive':
        if (
          criteriaDetails.samplingConfiguration.samplingMode === 'minAndMax'
        ) {
          return 'C';
        } else {
          return 'B';
        }
      default:
        return 'B';
    }
  }

  public getSamplingTable(
    criteriaDetails: any,
    rowsCount: number,
    periodInfo: any,
  ) {
    const samplingTableData: any[] = [];
    if (!periodInfo.isAudit) {
      samplingTableData.push({
        cellInfo: [
          {
            name: 'A' + rowsCount,
            bgColor: 'EFEFEF',
            border: true,
            bold: true,
            wrapText: true,
            alignment: 'left',
          },
          {
            name: 'B' + rowsCount,
            bgColor: 'EFEFEF',
            border: true,
            bold: true,
            wrapText: true,
            alignment: 'right',
          },
        ],
        data: ['Site', 'Sampling'],
      });
      rowsCount += 1;
      samplingTableData.push({
        cellInfo: [
          {
            name: 'A' + rowsCount,
            bgColor: 'FFFFFF',
            border: true,
            bold: false,
            wrapText: true,
            alignment: 'left',
          },
          {
            name: 'B' + rowsCount,
            bgColor: 'FFFFFF',
            border: true,
            bold: false,
            wrapText: true,
            alignment: 'right',
          },
        ],
        data: ['All Sites', '-'],
      });
      rowsCount += 1;
      criteriaDetails.siteSamplingConfiguration.forEach((siteSampling: any) => {
        samplingTableData.push({
          cellInfo: [
            {
              name: 'A' + rowsCount,
              bgColor: 'FFFFFF',
              border: true,
              bold: false,
              wrapText: true,
              alignment: 'left',
            },
            {
              name: 'B' + rowsCount,
              bgColor: 'FFFFFF',
              border: true,
              bold: false,
              wrapText: true,
              alignment: 'right',
            },
          ],
          data: [siteSampling.auditSiteMap.site.name, '-'],
        });
        rowsCount += 1;
      });
    } else {
      switch (criteriaDetails.samplingConfiguration.auditSamplingType) {
        case 'adHoc':
          samplingTableData.push({
            cellInfo: [
              {
                name: 'A' + rowsCount,
                bgColor: 'EFEFEF',
                border: true,
                bold: true,
                wrapText: true,
                alignment: 'left',
              },
              {
                name: 'B' + rowsCount,
                bgColor: 'EFEFEF',
                border: true,
                bold: true,
                wrapText: true,
                alignment: 'right',
              },
            ],
            data: ['Site', 'Sampling'],
          });
          rowsCount += 1;
          const adHocRowData: any[] = [];
          adHocRowData.push({
            cellInfo: [
              {
                name: 'A' + rowsCount,
                bgColor: 'FFFFFF',
                border: true,
                bold: false,
                wrapText: true,
                alignment: 'left',
              },
              {
                name: 'B' + rowsCount,
                bgColor: 'FFFFFF',
                border: true,
                bold: false,
                wrapText: true,
                alignment: 'right',
              },
            ],
            data: [
              'All Sites',
              criteriaDetails.criteriaSamplingDataConfiguration
                .isSamplingEnabled
                ? 'Enabled'
                : '-',
            ],
          });
          rowsCount += 1;
          const adHocSitesData: boolean[] = [];
          criteriaDetails.siteSamplingConfiguration.forEach(
            (siteSampling: any) => {
              if (siteSampling.isSamplingEnabled) {
                adHocSitesData.push(true);
              } else {
                adHocSitesData.push(false);
              }
              adHocRowData.push({
                cellInfo: [
                  {
                    name: 'A' + rowsCount,
                    bgColor: 'FFFFFF',
                    border: true,
                    bold: false,
                    wrapText: true,
                    alignment: 'left',
                  },
                  {
                    name: 'B' + rowsCount,
                    bgColor: 'FFFFFF',
                    border: true,
                    bold: false,
                    wrapText: true,
                    alignment: 'right',
                  },
                ],
                data: [
                  siteSampling.auditSiteMap.site.name,
                  siteSampling.isSamplingEnabled ? 'Enabled' : 'Disabled',
                ],
              });
              rowsCount += 1;
            },
          );
          const trueCount = adHocSitesData.filter((value) => value).length;
          adHocRowData[0] = {
            ...adHocRowData[0],
            data: [
              adHocRowData[0].data[0],
              trueCount + ' out of ' + adHocSitesData.length,
            ],
          };
          samplingTableData.push(...adHocRowData);
          break;
        case 'consecutive':
          if (
            criteriaDetails.samplingConfiguration.samplingMode === 'minAndMax'
          ) {
            samplingTableData.push({
              cellInfo: [
                {
                  name: 'A' + rowsCount,
                  bgColor: 'EFEFEF',
                  border: true,
                  bold: false,
                  wrapText: true,
                  alignment: 'left',
                },
                {
                  name: 'B' + rowsCount,
                  bgColor: 'EFEFEF',
                  border: true,
                  bold: false,
                  wrapText: true,
                  alignment: 'right',
                },
                {
                  name: 'C' + rowsCount,
                  bgColor: 'EFEFEF',
                  border: true,
                  bold: false,
                  wrapText: true,
                  alignment: 'right',
                },
              ],
              data: ['Site', 'Min.', 'Max.'],
            });
            rowsCount += 1;
            samplingTableData.push({
              cellInfo: [
                {
                  name: 'A' + rowsCount,
                  bgColor: 'FFFFFF',
                  border: true,
                  bold: false,
                  wrapText: true,
                  alignment: 'left',
                },
                {
                  name: 'B' + rowsCount,
                  bgColor: 'FFFFFF',
                  border: true,
                  bold: false,
                  wrapText: true,
                  alignment: 'right',
                },
                {
                  name: 'C' + rowsCount,
                  bgColor: 'FFFFFF',
                  border: true,
                  bold: false,
                  wrapText: true,
                  alignment: 'right',
                },
              ],
              data: [
                'All Sites',
                criteriaDetails.criteriaSamplingDataConfiguration.minSampleSize,
                criteriaDetails.criteriaSamplingDataConfiguration.maxSampleSize,
              ],
            });
            rowsCount += 1;
            criteriaDetails.siteSamplingConfiguration.forEach(
              (siteSampling: any) => {
                samplingTableData.push({
                  cellInfo: [
                    {
                      name: 'A' + rowsCount,
                      bgColor: 'FFFFFF',
                      border: true,
                      bold: false,
                      wrapText: true,
                      alignment: 'left',
                    },
                    {
                      name: 'B' + rowsCount,
                      bgColor: 'FFFFFF',
                      border: true,
                      bold: false,
                      wrapText: true,
                      alignment: 'right',
                    },
                    {
                      name: 'C' + rowsCount,
                      bgColor: 'FFFFFF',
                      border: true,
                      bold: false,
                      wrapText: true,
                      alignment: 'right',
                    },
                  ],
                  data: [
                    siteSampling.auditSiteMap.site.name,
                    siteSampling.minSampleSize,
                    siteSampling.maxSampleSize,
                  ],
                });
                rowsCount += 1;
              },
            );
          } else {
            samplingTableData.push({
              cellInfo: [
                {
                  name: 'A' + rowsCount,
                  bgColor: 'EFEFEF',
                  border: true,
                  bold: true,
                  wrapText: true,
                  alignment: 'left',
                },
                {
                  name: 'B' + rowsCount,
                  bgColor: 'EFEFEF',
                  border: true,
                  bold: true,
                  wrapText: true,
                  alignment: 'right',
                },
              ],
              data: ['Site', 'Target'],
            });
            rowsCount += 1;
            samplingTableData.push({
              cellInfo: [
                {
                  name: 'A' + rowsCount,
                  bgColor: 'FFFFFF',
                  border: true,
                  bold: false,
                  wrapText: true,
                  alignment: 'left',
                },
                {
                  name: 'B' + rowsCount,
                  bgColor: 'FFFFFF',
                  border: true,
                  bold: false,
                  wrapText: true,
                  alignment: 'right',
                },
              ],
              data: [
                'All Sites',
                criteriaDetails.criteriaSamplingDataConfiguration.sampleSize,
              ],
            });
            rowsCount += 1;
            criteriaDetails.siteSamplingConfiguration.forEach(
              (siteSampling: any) => {
                samplingTableData.push({
                  cellInfo: [
                    {
                      name: 'A' + rowsCount,
                      bgColor: 'FFFFFF',
                      border: true,
                      bold: false,
                      wrapText: true,
                      alignment: 'left',
                    },
                    {
                      name: 'B' + rowsCount,
                      bgColor: 'FFFFFF',
                      border: true,
                      bold: false,
                      wrapText: true,
                      alignment: 'right',
                    },
                  ],
                  data: [
                    siteSampling.auditSiteMap.site.name,
                    siteSampling.sampleSize,
                  ],
                });
                rowsCount += 1;
              },
            );
          }
          break;
        default:
          samplingTableData.push({
            cellInfo: [
              {
                name: 'A' + rowsCount,
                bgColor: 'EFEFEF',
                border: true,
                bold: true,
                wrapText: true,
                alignment: 'left',
              },
              {
                name: 'B' + rowsCount,
                bgColor: 'EFEFEF',
                border: true,
                bold: true,
                wrapText: true,
                alignment: 'right',
              },
            ],
            data: ['Site', 'Target'],
          });
          rowsCount += 1;
          samplingTableData.push({
            cellInfo: [
              {
                name: 'A' + rowsCount,
                bgColor: 'FFFFFF',
                border: true,
                bold: false,
                wrapText: true,
                alignment: 'left',
              },
              {
                name: 'B' + rowsCount,
                bgColor: 'FFFFFF',
                border: true,
                bold: false,
                wrapText: true,
                alignment: 'right',
              },
            ],
            data: [
              'All Sites',
              criteriaDetails.criteriaSamplingDataConfiguration.sampleSize,
            ],
          });
          rowsCount += 1;
          criteriaDetails.siteSamplingConfiguration.forEach(
            (siteSampling: any, siteSamplingIndex: number) => {
              samplingTableData.push({
                cellInfo: [
                  {
                    name: 'A' + rowsCount,
                    bgColor: 'FFFFFF',
                    border: true,
                    bold: false,
                    wrapText: true,
                    alignment: 'left',
                  },
                  {
                    name: 'B' + rowsCount,
                    bgColor: 'FFFFFF',
                    border: true,
                    bold: false,
                    wrapText: true,
                    alignment: 'right',
                  },
                ],
                data: [
                  siteSampling.auditSiteMap.site.name,
                  siteSampling.sampleSize,
                ],
              });
              rowsCount += 1;
            },
          );
      }
    }
    return { samplingTableData, rowsCount };
  }

  public generateConfigurationData(
    configurationData: any[],
    payload: any,
    samplingConfiguration: any,
  ) {
    let aggregateCriteriaConfiguration: any[];
    if (payload.checkIndividualSite) {
      if (Array.isArray(configurationData) && configurationData.length > 0) {
        const aggregateConfig = configurationData.map((data: any) => {
          const criteriaSamplingData = data.criteriaSamplingData.filter(
            (item: any) => {
              const criteriaOptionsDataDistribution =
                item.criteriaOptionsDataDistribution;
              const dirtyCriteriaSamplingData = Object.keys(
                criteriaOptionsDataDistribution,
              ).map((key: string) => ({
                [key]:
                  data.criteriaSamplingData[0].criteriaOptionsDataDistribution[
                    key
                  ],
              }));
              return dirtyCriteriaSamplingData;
            },
          );
          return {
            title: _get(data, 'projectCriteria.title', ''),
            criteriaType: _get(data, 'projectCriteria.criteriaType', ''),
            sampleSize: Number(
              _get(data, 'criteriaSamplingDataConfigurations.sampleSize', 0),
            ),
            siteSamplingConfiguration: data.siteSamplingDataConfigurations,
            criteriaSamplingDataConfiguration:
              data.criteriaSamplingDataConfigurations,
            samplingConfiguration,
            criteriaSamplingData,
            isAuditCriteria: _get(data, 'isAuditCriteria', false),
          };
        });
        aggregateCriteriaConfiguration = _cloneDeep(aggregateConfig);
      } else {
        aggregateCriteriaConfiguration = [];
      }
    } else {
      if (Array.isArray(configurationData) && configurationData.length > 0) {
        const aggregateConfig = configurationData.map((data: any) => {
          const criteriaOptionsDataDistribution =
            data.criteriaSamplingData.criteriaOptionsDataDistribution;
          const criteriaSamplingData = Object.keys(
            criteriaOptionsDataDistribution,
          ).map((key: string) => ({
            [key]:
              data.criteriaSamplingData.criteriaOptionsDataDistribution[key],
          }));
          return {
            title: _get(data, 'projectCriteria.title', ''),
            criteriaType: _get(data, 'projectCriteria.criteriaType', ''),
            sampleSize: Number(
              _get(data, 'criteriaSamplingDataConfigurations.sampleSize', 0),
            ),
            siteSamplingConfiguration: data.siteSamplingDataConfigurations,
            criteriaSamplingDataConfiguration:
              data.criteriaSamplingDataConfigurations,
            samplingConfiguration,
            criteriaSamplingData,
            isAuditCriteria: _get(data, 'isAuditCriteria', false),
          };
        });
        aggregateCriteriaConfiguration = _cloneDeep(aggregateConfig);
      } else {
        aggregateCriteriaConfiguration = [];
      }
    }
    return { aggregateCriteriaConfiguration };
  }

  public formatDateRange(dateObject: any): string {
    const startDate = new Date(dateObject.startedAt);
    let endDate = dateObject.endedAt;
    if (endDate === null) {
      endDate = dayjs().endOf('day').format();
    }
    return this.getDate(startDate) + ' - ' + this.getDate(new Date(endDate));
  }

  public getDate(newValue: Date): string {
    return (
      newValue.getDate() +
      ' ' +
      this.dirtyMonths[newValue.getMonth()] +
      ' ' +
      newValue.getFullYear()
    );
  }

  public getColumnName(columnNumber: number): string {
    let temp;
    let columnLetter = '';
    while (columnNumber > 0) {
      temp = (columnNumber - 1) % 26;
      columnLetter = String.fromCharCode(temp + 65) + columnLetter;
      columnNumber = (columnNumber - temp - 1) / 26;
    }
    return columnLetter;
  }
}
