var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-card"},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v("Acknowledge JBI Criteria Update?")]),_c('button',{class:_vm.$style.closeIcon,attrs:{"type":"button"},on:{"click":_vm.closeModal}},[_c('i',{staticClass:"mdi mdi-close"})])]),_c('section',{staticClass:"modal-card-body"},[_c('div',{staticClass:"columns"},[_c('div',{class:['column', _vm.$style.updatedJbiCriteriaNotification]},[_c('span',[_vm._v(" All updated criteria will only be available for newly created data collection period. ")])])]),_c('div',{staticClass:"columns mb1"},[_c('div',{class:['column', _vm.$style.initialText]},[_c('span',[_vm._v(_vm._s("Here are the following JBI criteria"))])])]),_vm._l((_vm.JBICriteriaUpdates),function(jbiCriteria,index){return _c('div',{key:((jbiCriteria.status) + "_" + index),staticClass:"mb1 mt1"},[(jbiCriteria.criteriaList.length > 0)?[_c('h6',{class:_vm.$style.jbiCriteriaStatusTitle},[_vm._v(" "+_vm._s(jbiCriteria.status)+" ")]),_vm._l((jbiCriteria.criteriaList),function(criteria,index){
var _obj;
return _c('li',{key:("criteria_" + index),class:_vm.$style.criteriaList},[_c('div',{staticClass:"columns"},[(jbiCriteria.status !== _vm.jbiCriteriaStatus.NEW)?_c('div',{class:['column is-half']},[_c('div',{class:_vm.$style.criteriaContainer},[_c('div',{class:_vm.$style.criteriaHeader},[_vm._v(" Your current criteria ")]),_c('div',{class:_vm.$style.criteriaDocumentId},[_vm._v(" "+_vm._s(criteria.currentCriteria.documentId)+" ")]),_c('b-tooltip',{attrs:{"type":"is-dark","active":criteria.currentCriteria.title.length > 70,"label":criteria.currentCriteria.title,"multilined":""}},[_c('div',{class:_vm.$style.criteriaTitle},[_vm._v(" "+_vm._s(criteria.currentCriteria.title)+" ")])]),(criteria.currentCriteria.criteriaOptions)?_c('div',{class:_vm.$style.criteriaOptions},[(
                      criteria.currentCriteria.criteriaType ===
                      _vm.criteriaType.BOOLEAN
                    )?_vm._l((criteria
                        .currentCriteria.criteriaOptions),function(booleanOption,index){return _c('div',{key:("" + index + booleanOption)},[_c('b-radio',{attrs:{"disabled":"","native-value":"booleanOption","size":"is-small"}},[_c('span',{class:_vm.$style.optionText},[_vm._v(" "+_vm._s(booleanOption)+" ")])])],1)}):_vm._e(),(
                      criteria.currentCriteria.criteriaType ===
                      _vm.criteriaType.RANGE
                    )?[_c('div',[_vm._v(" Min: "+_vm._s(criteria.currentCriteria.criteriaOptions[0])+" ")]),_c('div',[_vm._v(" Max: "+_vm._s(criteria.currentCriteria.criteriaOptions[1])+" ")])]:_vm._e(),(
                      criteria.currentCriteria.criteriaType ===
                      _vm.criteriaType.CHECKBOXES
                    )?_vm._l((criteria
                        .currentCriteria.criteriaOptions),function(checkboxesOption,index){return _c('div',{key:("" + index + checkboxesOption)},[_c('b-checkbox',{attrs:{"disabled":"","type":"is-info"}},[_c('span',{class:_vm.$style.optionText},[_vm._v(" "+_vm._s(checkboxesOption)+" ")])])],1)}):_vm._e()],2):_vm._e()],1)]):_vm._e(),(criteria.status !== _vm.jbiCriteriaStatus.NEW)?_c('div',{class:['column', _vm.$style.customeCssChevronRight]},[_c('span',{class:['icon', _vm.$style.iconRight]},[_c('i',{staticClass:"fas fa-chevron-right"})])]):_vm._e(),_c('div',{class:( _obj = {}, _obj['column'] = true, _obj['is-half'] = jbiCriteria.status !== _vm.jbiCriteriaStatus.NEW, _obj )},[(criteria.status === _vm.jbiCriteriaStatus.DELETED)?_c('div',{class:_vm.$style.deletedCriteriaContainer},[_c('div',{class:_vm.$style.deleteCriteriaDetails},[_c('div',{class:[_vm.$style.criteriaHeader, _vm.$style.paddingHorizontal]},[_vm._v(" Deleted JBI Criteria ")]),_c('div',{class:[
                      _vm.$style.criteriaDocumentId,
                      _vm.$style.paddingHorizontal ]},[_vm._v(" "+_vm._s(criteria.connectPlusDocumentId)+" ")])]),_c('div',{class:_vm.$style.deleteCriteriaBox},[_c('p',{class:_vm.$style.deleteCriteriaText},[_vm._v(" This criteria has been deleted ")])])]):_c('div',{class:_vm.$style.criteriaContainer},[_c('div',{class:_vm.$style.criteriaHeader},[_vm._v(" "+_vm._s(criteria.status === _vm.jbiCriteriaStatus.NEW ? 'New JBI criteria' : 'Updated JBI criteria')+" "),(criteria.status === _vm.jbiCriteriaStatus.NEW)?_c('b-tag',{attrs:{"type":"is-primary"}},[_c('p',{staticClass:"is-text-info"},[_vm._v("NEW")])]):_vm._e()],1),_c('div',{class:_vm.$style.criteriaDocumentId},[_vm._v(" "+_vm._s(criteria.connectPlusDocumentId)+" ")]),_c('b-tooltip',{attrs:{"type":"is-dark","active":criteria.content.title.length > 70,"label":criteria.content.title,"multilined":""}},[_c('div',{class:_vm.$style.criteriaTitle},[_vm._v(" "+_vm._s(criteria.content.title)+" ")])]),_c('div',{class:_vm.$style.criteriaOptions},[(
                      criteria.content.type === _vm.criteriaType.BOOLEAN &&
                      criteria.content.booleanOptions
                    )?_vm._l((criteria.content
                        .booleanOptions),function(booleanOption,index){return _c('div',{key:("" + index + booleanOption)},[_c('b-radio',{attrs:{"disabled":"","native-value":"booleanOption","size":"is-small"}},[_c('span',{class:_vm.$style.optionText},[_vm._v(" "+_vm._s(booleanOption)+" ")])])],1)}):_vm._e(),(
                      criteria.content.type === _vm.criteriaType.RANGE &&
                      criteria.content.range
                    )?[_c('div',[_vm._v(" Min: "+_vm._s(criteria.content.range[0])+" ")]),_c('div',[_vm._v(" Max: "+_vm._s(criteria.content.range[1])+" ")])]:_vm._e(),(
                      criteria.content.type === _vm.criteriaType.CHECKBOXES &&
                      criteria.content.checkboxesOptions
                    )?_vm._l((criteria.content
                        .checkboxesOptions),function(checkboxesOption,index){return _c('div',{key:("" + index + checkboxesOption)},[_c('b-checkbox',{attrs:{"disabled":"","type":"is-info"}},[_c('span',{class:_vm.$style.optionText},[_vm._v(" "+_vm._s(checkboxesOption)+" ")])])],1)}):_vm._e()],2)],1)])])])})]:_vm._e()],2)}),_c('div',{staticClass:"columns"},[_c('div',{class:['column', _vm.$style.initialText]},[_c('span',[_vm._v(" I hereby acknowledge that I have carefully considered the following criteria. ")])])])],2),_c('footer',{staticClass:"modal-card-foot"},[_c('a',{class:_vm.$style.cancelBtn,on:{"click":_vm.closeModal}},[_vm._v(" Cancel ")]),_c('button',{staticClass:"button is-primary is-rounded",on:{"click":_vm.acknowledgeJBICriteriaChanges}},[_vm._v(" Acknowledge ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }