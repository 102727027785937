



































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import GenericFileUploader from '../../../components/uploader/GenericFileUploader.vue';
import { isTruthy } from '../../../jbi-shared/util/watcher.vue-decorator';
import { ToastProgrammatic as Toast } from 'buefy';
import {
  CreateGripStrategyPayload,
  FileAttachmentDTO,
  GripStrategyDTO,
  GripDomainCategory,
  LinkDTO,
  UpdateGripStrategyPayload,
  CreateGripStrategyBase,
} from '../../../store/types/grip.types';
import { ApiState } from '../../../store/types/general.types';
import DomainCategoryDropdown from '../DomainCategory/DomainCategoryDropdown.vue';
import {
  getSourceLink,
  getWordCount,
  validateLink,
} from '../../../utils/general.util';

@Component({
  components: { DomainCategoryDropdown, GenericFileUploader },
})
export default class StrategyModal extends Vue {
  @Prop() public projectId!: number;
  @Prop() public gripStrategyDetails!: GripStrategyDTO;
  @Prop() public isNew!: boolean;
  @Prop() public isModalActive!: boolean;
  @Prop() public draggable!: boolean;

  @Action('projects/getGripDomainCategories')
  public getGripDomainCategories!: (projectId: number) => void;

  @State((state) => state.projects.apiState.getGripDomainCategories)
  public getGripDomainCategoriesState!: ApiState;

  @State((state) => state.projects.gripDomainCategories)
  public gripDomainCategories!: GripDomainCategory[];

  @Action('projects/createGripStrategy')
  public createGripStrategy!: (payload: CreateGripStrategyPayload) => void;

  @State((state) => state.projects.apiState.createGripStrategy)
  public createGripStrategyApiState!: ApiState;

  public dirtyGripStrategy: CreateGripStrategyBase = {
    title: '',
    description: '',
    resource: '',
    method: '',
    links: [],
    attachments: [],
    gripDomainCategoryId: null,
  };
  public areLinksValid: boolean = true;
  public isDomainCategoriesLoading: boolean = false;
  public gripDomainCategoryOptions: GripDomainCategory[] = [];
  public selectedGripDomainCategoryId: number | null = null;
  public strategyLinks: LinkDTO[] = [];
  public link: string = '';
  public count: number = 1;
  public fileAttachment: FileAttachmentDTO[] = [];
  public errorMessage: {
    title: string | null;
    description: string | null;
  } = { title: null, description: null };
  public isDraggedStart: boolean = false;
  public modalPosX: number = 0;
  public modalPosY: number = 0;
  public dragStartPosX: number = 0;
  public dragStartPosY: number = 0;

  get GenericFileUploader() {
    return GenericFileUploader;
  }

  get gripDomainCategoryDetails(): GripDomainCategory | null {
    return this.gripStrategyDetails &&
      this.gripStrategyDetails.gripDomainCategory
      ? this.gripStrategyDetails.gripDomainCategory
      : null;
  }

  get modalStyle(): string {
    return `left: ${this.modalPosX}px; top: ${this.modalPosY}px;`;
  }

  get sourceLink(): string {
    return getSourceLink(this.strategyLinks);
  }

  public wordCount(value: string): number {
    return value.trim().length > 0 ? getWordCount(value.trim()) : 0;
  }

  public onDragStart(e: any): void {
    const jsonData = JSON.stringify({ id: 'strategyModal' });
    e.dataTransfer.setData('application/json', jsonData);
    this.dragStartPosX = e.clientX;
    this.dragStartPosY = e.clientY;
    e.dataTransfer.effectAllowed = 'move';
  }

  public onDragOver(e: any): void {
    e.preventDefault();
    e.dataTransfer.effectAllowed = 'move';
  }

  public onDrop(e: any): void {
    e.preventDefault();
    const jsonData = e.dataTransfer.getData('application/json');
    const element = document.getElementById('strategyModal');
    e.preventDefault();
    e.dataTransfer.effectAllowed = 'move';

    if (element && jsonData) {
      /**
       * calculate the new cursor position:
       * newCursorPosX/newCursorPosY = diffrence between element's current position and cursor's drop position
       * oldCursorPosX/oldCursorPosY = diffrence between element's current position and cursor's drag start position
       */
      const newCursorPosX = element.offsetLeft - e.clientX || 0;
      const newCursorPosY = element.offsetTop - e.clientY || 0;
      const oldCursorPosX = element.offsetLeft - this.dragStartPosX || 0;
      const oldCursorPosY = element.offsetTop - this.dragStartPosY || 0;

      /** calculate the new modal position: */
      this.modalPosX += oldCursorPosX - newCursorPosX;
      this.modalPosY += oldCursorPosY - newCursorPosY;
    }
  }

  public handleSelectedGripDomainCategory(
    gripDomainCategory: GripDomainCategory,
  ): void {
    this.selectedGripDomainCategoryId = gripDomainCategory
      ? gripDomainCategory.id
      : null;
  }

  public strategyLinkTextInput(): void {
    this.count++;
    this.strategyLinks.push({
      url: this.link,
      count: this.count,
      isValid: true,
      errorMessage: '',
    });
  }

  public removeLinkTextInput(index: number): void {
    this.strategyLinks.splice(index, 1);
    this.areLinksValid = !this.strategyLinks.some((link) => !link.isValid);
  }

  public getGripStrategyLinks(): void {
    const linkLists: string[] = [];
    this.strategyLinks.map((value: LinkDTO) => {
      if (value.url) {
        linkLists.push(value.url);
      }
    });
    this.dirtyGripStrategy.links = linkLists;
  }

  public getStrategyAttachments(): void {
    const updateFileAttachment: string[] = [];
    this.fileAttachment.forEach((element: FileAttachmentDTO) => {
      updateFileAttachment.push(element.type);
    });
    this.dirtyGripStrategy.attachments = updateFileAttachment;
  }

  public validateData() {
    if (!this.dirtyGripStrategy.title.trim().length) {
      this.errorMessage.title = 'This Field is Required';
    } else {
      this.errorMessage.title = null;
    }

    if (
      this.dirtyGripStrategy.description &&
      this.wordCount(this.dirtyGripStrategy.description.trim()) > 150
    ) {
      this.errorMessage.description = 'Max 150 words are allowed.';
    } else {
      this.errorMessage.description = null;
    }

    return this.errorMessage;
  }

  public validateGripLink(index: number): void {
    const link: LinkDTO = validateLink(this.strategyLinks[index]);
    this.strategyLinks.splice(index, 1, link);
    this.areLinksValid = !this.strategyLinks.some(
      (strategyLink) => !strategyLink.isValid,
    );
  }

  public onAddGripStrategy(): void {
    this.validateData();
    if (
      !this.errorMessage.title &&
      !this.errorMessage.description &&
      this.areLinksValid
    ) {
      this.getGripStrategyLinks();
      this.getStrategyAttachments();
      this.dirtyGripStrategy.gripDomainCategoryId = this.selectedGripDomainCategoryId;
      this.$emit('create', this.dirtyGripStrategy);
    }
  }

  public onUpdateGripStrategy(): void {
    this.getGripStrategyLinks();
    this.getStrategyAttachments();
    this.dirtyGripStrategy.gripDomainCategoryId = this.selectedGripDomainCategoryId;
    const strategy: { [key: string]: any } = { ...this.dirtyGripStrategy };
    const strategyInfo: { [key: string]: any } = {
      ...this.gripStrategyDetails,
      projectId: this.projectId,
    };
    let updateStrategy: UpdateGripStrategyPayload | any = {};
    Object.keys(strategy).forEach((key: string) => {
      if (key === 'gripDomainCategoryId') {
        if (strategyInfo.gripDomainCategory) {
          if (
            JSON.stringify(strategy.gripDomainCategoryId) !==
            JSON.stringify(strategyInfo.gripDomainCategory.id)
          ) {
            updateStrategy.gripDomainCategoryId = strategy.gripDomainCategoryId;
          }
        } else if (
          strategyInfo.gripDomainCategory !== strategy.gripDomainCategoryId
        ) {
          updateStrategy.gripDomainCategoryId = strategy.gripDomainCategoryId;
        }
      } else {
        if (
          JSON.stringify(strategy[key]) !== JSON.stringify(strategyInfo[key])
        ) {
          updateStrategy = {
            ...updateStrategy,
            [key]: strategy[key],
          };
        }
      }
    });
    if (Object.keys(updateStrategy).length) {
      this.validateData();
      if (
        !this.errorMessage.title &&
        !this.errorMessage.description &&
        this.areLinksValid
      ) {
        this.$emit('update', {
          id: this.gripStrategyDetails.id,
          ...updateStrategy,
        });
      }
    } else {
      this.$emit('close');
    }
  }

  public mounted(): void {
    this.isDomainCategoriesLoading = true;
    this.getGripDomainCategories(this.projectId);
    if (
      this.gripStrategyDetails &&
      Object.keys(this.gripStrategyDetails).length > 0
    ) {
      const {
        gripDomainCategory,
        id,
        ...strategyInfo
      } = this.gripStrategyDetails;
      strategyInfo.links.forEach((link: string) => {
        this.count++;
        this.strategyLinks.push({
          url: link,
          count: this.count,
          isValid: true,
          errorMessage: '',
        });
      });

      strategyInfo.attachments.forEach((file) => {
        const fileData = file.split('/');
        this.fileAttachment.push({
          type: file,
          name: fileData[fileData.length - 1],
        });
      });

      this.selectedGripDomainCategoryId = gripDomainCategory
        ? gripDomainCategory.id
        : null;

      this.dirtyGripStrategy = {
        ...strategyInfo,
        gripDomainCategoryId: this.selectedGripDomainCategoryId,
      };
    }
  }

  @Watch('getGripDomainCategoriesState.success')
  @isTruthy
  public watchGripDomainCategorySuccess(): void {
    this.isDomainCategoriesLoading = false;
    this.gripDomainCategoryOptions = this.gripDomainCategories
      ? this.gripDomainCategories
      : [];
  }

  @Watch('getGripDomainCategoriesState.error')
  @isTruthy
  public watchGripDomainCategoryError(): void {
    this.isDomainCategoriesLoading = false;
    Toast.open({
      message: `Something went wrong, while fetching Domain/Categories. Try again.`,
      position: 'is-top',
      type: 'is-danger',
      duration: 3500,
    });
  }
}
