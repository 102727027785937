





























































































































































import { Component, Vue, Prop, Watch, Model } from 'vue-property-decorator';
import { State, Getter, Action, Mutation } from 'vuex-class';
import { AuditSite } from '../../store/modules/audits/types/audits.types';
import { cloneDeep as _cloneDeep, get as _get, map as _map } from 'lodash';
import {
  getAdHocSampling,
  getConsecutiveMinMaxSampling,
  getDefaultSampling,
} from '../../components/reports/utils/ReportSampling.util';
import { ReportSamplingDto } from '../../store/modules/projects/types/projects.types';

const dirtyMonths: string[] = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

@Component({
  components: {},
})
export default class SingleSiteComparisonTable extends Vue {
  @Prop()
  public source!: any[];
  @Prop()
  public siteList!: AuditSite[];
  @Prop()
  public comparisonPeriods!: Date[][];
  @Prop({ default: true })
  public showComplianceRate!: boolean;
  @Prop({ default: null })
  public periodsInfo!: any[];
  @Prop({ default: false })
  public auditReport!: boolean;

  public siteName: string = '';

  public mounted() {
    this.siteName = this.siteList[0].site.name;
  }

  public getDate(newValue: Date): string {
    return (
      newValue.getDate() +
      ' ' +
      dirtyMonths[newValue.getMonth()] +
      ' ' +
      newValue.getFullYear()
    );
  }

  public formatDateRange(date: Date[]): string {
    return this.getDate(date[0]) + ' - ' + this.getDate(date[1]);
  }

  get bodyItems(): any[] {
    const rowItems: any[] = [];
    const criteriaOptionKeys: string[] = this.getKeys;
    criteriaOptionKeys.forEach((optionKey: string) => {
      const val: number[] = [];
      const rowData: any = {
        optionKey: '' as string,
        periodData: [] as number[],
      };
      rowData.optionKey = optionKey;
      this.source.forEach((data, dataIndex) => {
        const dataOptionKeys = data.criteriaSamplingData.map(
          (entry: any) => Object.keys(entry)[0],
        );
        data.criteriaSamplingData.forEach((entry: any, index: any) => {
          if (
            Object.keys(entry)[0] === optionKey &&
            data.criteriaSamplingDataConfiguration.length
          ) {
            rowData.periodData.push(entry[optionKey]);
          } else {
            if (
              index + 1 === data.criteriaSamplingData.length &&
              !dataOptionKeys.includes(optionKey)
            ) {
              rowData.periodData.push('-');
            }
          }
        });
        if (data.criteriaSamplingDataConfiguration.length === 0) {
          rowData.periodData.push('-');
        }
      });
      rowItems.push(rowData);
    });
    return rowItems;
  }

  get getKeys(): string[] {
    const keys: string[] = [];
    if (!this.auditReport && this.source) {
      this.source.forEach((sourceRecord: any) => {
        if (sourceRecord.criteriaSamplingData) {
          sourceRecord.criteriaSamplingData.forEach((optionSet: any) => {
            if (!keys.includes(Object.keys(optionSet)[0])) {
              keys.push(Object.keys(optionSet)[0]);
            }
          });
        }
      });
      return keys;
    } else if (this.source[0].criteriaSamplingData) {
      _map(this.source[0].criteriaSamplingData, (optionSet) => {
        keys.push(Object.keys(optionSet)[0]);
      });
      return keys;
    } else {
      return keys;
    }
  }

  get getTotalData(): string[] {
    const totalData: string[] = [];
    this.source.forEach((data) => {
      let sum: number = 0;
      let sumNa: string = '';
      data.criteriaSamplingData.map((value: any) => {
        if (Object.values(value)[0] === '-') {
          return (sumNa = '-');
        }
        return (sum += Number(Object.values(value)[0]));
      });
      if (data.criteriaSamplingDataConfiguration.length === 0) {
        sumNa = '-';
      }
      if (sumNa === '-') {
        totalData.push(sumNa);
      } else {
        totalData.push(sum.toString());
      }
    });
    return totalData;
  }

  get getCompliance(): string[] {
    const allCompliance: any[] = [];
    const criteriaOptionKeys: string[] = this.getKeys;
    this.source.forEach((data) => {
      let totalSum: number = 0;
      let totalNA: number = 0;
      let totalYes: number = 0;
      data.criteriaSamplingData.map((value: any, valueIndex: number) => {
        totalSum += Number(Object.values(value)[0]);
        if (valueIndex === 2) {
          totalNA += Number(Object.values(value)[0]);
        } else if (valueIndex === 0) {
          totalYes += Number(Object.values(value)[0]);
        }
      });
      if (totalYes === 0) {
        const allComplianceValue: string = data
          .criteriaSamplingDataConfiguration.length
          ? '0%'
          : '-';
        allCompliance.push(allComplianceValue);
      } else {
        const rate: number = (totalYes / (totalSum - totalNA)) * 100;
        if (!rate) {
          allCompliance.push('-');
        } else {
          allCompliance.push(Number(rate.toFixed(2)) + '%');
        }
      }
    });
    return allCompliance;
  }

  get getNumberOfPeriods(): number {
    return this.comparisonPeriods.length;
  }

  get getPeriods(): string[] {
    const periods: any[] = [];
    _map(this.comparisonPeriods, (period: Date[], periodIndex: number) => {
      if (this.periodsInfo) {
        periods.push({
          title: this.periodsInfo[periodIndex].title,
          date: this.formatDateRange(period),
        });
      } else {
        periods.push({ date: this.formatDateRange(period) });
      }
    });
    return periods;
  }

  get getSamplingTitles() {
    const samplingTitles: string[] = [];
    this.source.forEach((sourceItem, sourceItemIndex) => {
      samplingTitles.push('Site');
      switch (sourceItem.samplingConfiguration.auditSamplingType) {
        case 'adHoc':
          samplingTitles.push('Sampling');
          break;
        case 'consecutive':
          if (this.periodsInfo[sourceItemIndex].isAudit) {
            if (sourceItem.samplingConfiguration.samplingMode === 'minAndMax') {
              samplingTitles.push('Min.');
              samplingTitles.push('Max.');
            } else {
              samplingTitles.push('Target');
            }
          } else {
            samplingTitles.push('Sampling');
          }
          break;
        default:
          if (this.periodsInfo[sourceItemIndex].isAudit) {
            samplingTitles.push('Target');
          } else {
            samplingTitles.push('Sampling');
          }
      }
    });
    return samplingTitles;
  }

  get getSamplingAggregate() {
    const samplingRows: any[][] = [];
    const dirtySamplingRows: any[] = [];
    this.source.forEach((sourceItem, sourceItemIndex) => {
      switch (sourceItem.samplingConfiguration.auditSamplingType) {
        case 'adHoc':
          const dirtyAdHocSamplingRows: ReportSamplingDto[] = getAdHocSampling(
            this.periodsInfo[sourceItemIndex].isAudit,
            sourceItem.isAuditCriteria,
            sourceItem,
            this.siteList,
          );
          dirtySamplingRows.push(...dirtyAdHocSamplingRows);
          break;
        case 'consecutive':
          if (sourceItem.samplingConfiguration.samplingMode === 'minAndMax') {
            const dirtyConsecutiveMinMaxSamplingRows: ReportSamplingDto[] = getConsecutiveMinMaxSampling(
              this.periodsInfo[sourceItemIndex].isAudit,
              sourceItem.isAuditCriteria,
              sourceItem,
              this.siteList,
            );
            dirtySamplingRows.push(...dirtyConsecutiveMinMaxSamplingRows);
          } else {
            const dirtyDefaultConsecutiveSamplingRowsData: ReportSamplingDto[] = getDefaultSampling(
              this.periodsInfo[sourceItemIndex].isAudit,
              sourceItem.isAuditCriteria,
              sourceItem,
              this.siteList,
            );
            dirtySamplingRows.push(...dirtyDefaultConsecutiveSamplingRowsData);
          }
          break;
        default:
          const dirtyDefaultSamplingRowsData: ReportSamplingDto[] = getDefaultSampling(
            this.periodsInfo[sourceItemIndex].isAudit,
            sourceItem.isAuditCriteria,
            sourceItem,
            this.siteList,
            sourceItem.samplingConfiguration.samplingMode === 'siteBased',
          );
          dirtySamplingRows.push(...dirtyDefaultSamplingRowsData);
      }
    });
    this.siteList.forEach((siteData) => {
      const siteSampling = dirtySamplingRows.filter(
        (rowData) => rowData.siteName === siteData.site.name,
      );
      const siteSamplingData: any[] = [];
      siteSampling.forEach((siteDataVar) => {
        siteSamplingData.push(siteDataVar.siteName);
        siteDataVar.values.forEach((value: any) => {
          siteSamplingData.push(value);
        });
      });
      samplingRows.push(siteSamplingData);
    });
    return samplingRows;
  }

  get getSamplingTitlesAudit() {
    const samplingTitles: string[] = [];
    samplingTitles.push('Site');
    switch (this.source[0].samplingConfiguration.auditSamplingType) {
      case 'adHoc':
        samplingTitles.push('Sampling');
        break;
      case 'consecutive':
        if (this.source[0].samplingConfiguration.samplingMode === 'minAndMax') {
          samplingTitles.push('Min.');
          samplingTitles.push('Max.');
        } else {
          samplingTitles.push('Target');
        }
        break;
      default:
        samplingTitles.push('Target');
    }
    return samplingTitles;
  }

  get getSamplingAggregateAudit() {
    const samplingRows: any[] = [];
    switch (this.source[0].samplingConfiguration.auditSamplingType) {
      case 'adHoc':
        const dirtyAdHocSamplingRows: ReportSamplingDto[] = getAdHocSampling(
          true,
          true,
          this.source[0],
          this.siteList,
        );
        samplingRows.push(...dirtyAdHocSamplingRows);

        break;
      case 'consecutive':
        if (this.source[0].samplingConfiguration.samplingMode === 'minAndMax') {
          const dirtyConsecutiveMinMaxSamplingRows: ReportSamplingDto[] = getConsecutiveMinMaxSampling(
            true,
            true,
            this.source[0],
            this.siteList,
          );
          samplingRows.push(...dirtyConsecutiveMinMaxSamplingRows);
        } else {
          const dirtyDefaultConsecutiveSamplingRowsData: ReportSamplingDto[] = getDefaultSampling(
            true,
            true,
            this.source[0],
            this.siteList,
          );
          samplingRows.push(...dirtyDefaultConsecutiveSamplingRowsData);
        }
        break;
      default:
        const dirtyDefaultSamplingRowsData: ReportSamplingDto[] = getDefaultSampling(
          true,
          true,
          this.source[0],
          this.siteList,
          this.source[0].samplingConfiguration.samplingMode === 'siteBased',
        );
        samplingRows.push(...dirtyDefaultSamplingRowsData);
    }
    return samplingRows;
  }
}
