import { AuthState } from './types/auth.types';
import { defaultApiState } from '@/store/types/general.types';

export const authState: AuthState = {
  apiState: {
    exchangeGrantForToken: defaultApiState,
    signOut: defaultApiState,
    checkSessionHealth: defaultApiState,
    checkSubscriptionHealth: defaultApiState,
    getJaasAccountDetail: defaultApiState,
    checkSubscriptionStatusByEmailIds: defaultApiState,
  },
  isSwitchAccount: false,
  authInfo: null,
  subscriptionInfo: null,
  isLoggedIn: false,
  jaasAccountDetail: null,
  roles: [],
  userSubscriptionStatusByEmail: null,
};
