import { Component, Prop, Mixins } from 'vue-property-decorator';
import { HorizontalBar } from 'vue-chartjs';

// @ts-ignore
@Component({})
export default class VerticalBarChart extends Mixins(HorizontalBar) {
  @Prop({ default: null })
  private barChartData!: any | null;
  @Prop({ default: null })
  private barChartOptions!: any | null;

  public mounted() {
    const showZeroPlugin = {
      beforeRender(chartInstance: any) {
        const dataSets = chartInstance.config.data.datasets;
        for (const [dataSetIndex] of dataSets.entries()) {
          const meta = dataSets[dataSetIndex]._meta;
          const metaData = meta[Object.keys(meta)[0]];
          const bars = metaData.data;
          for (const [barIndex] of bars.entries()) {
            const model = bars[barIndex]._model;
            if (model.base === model.x) {
              model.x = model.base + 2;
            } else if (model.base > model.x) {
              model.backgroundColor = 'transparent';
              model.borderWidth = '1';
              model.x = model.base + 1;
            }
          }
        }
      },
    };
    const dashBarPlugin = {
      afterDatasetDraw(chart: any, args: any) {
        args.meta.data.forEach((element: any) => {
          if (element._model.base - element._model.x > 0) {
            const borderWidth = 8;
            const ctx = chart.ctx;
            const vm = element._view;
            const half = vm.height / 2;
            const top = vm.y - half;
            const bottom = vm.y + half;
            const right = vm.x + 10;
            const left = chart.chartArea.left;
            ctx.beginPath();
            ctx.lineWidth = borderWidth;
            ctx.strokeStyle = '#000000';
            ctx.setLineDash([4, 5]);
            ctx.moveTo(left, top);
            ctx.lineTo(right, top);
            ctx.moveTo(right, top);
            ctx.lineTo(right, bottom);
            ctx.moveTo(right, bottom);
            ctx.lineTo(left, bottom);
            ctx.stroke();
            ctx.save();
          }
        });
      },
    };
    if (this.barChartData) {
      // Uncomment next line to show bar for 0 values
      // this.addPlugin(showZeroPlugin);
      this.addPlugin(dashBarPlugin);
      this.renderChart(this.barChartData, this.barChartOptions);
    }
  }
}
