import {
  AuditCriteria,
  AuditReportAnalysisResponseDto,
  AuditSite,
} from '@/store/modules/audits/types/audits.types';
import { GenerateSingleSiteDocx } from '@/components/reports/export/docx/audit/SingleSiteDocx';
import { GenerateSingleSiteComparisonDocx } from '@/components/reports/export/docx/audit/SingleSiteComparisonDocx';
import { GeneerateMultiSiteComparisonDocx } from '@/components/reports/export/docx/audit/MultiSiteComparisonDocx';
import { GenerateMultiSiteDocx } from '@/components/reports/export/docx/audit/MultiSiteDocx';
import { Document, Packer } from 'docx';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { ToastProgrammatic as Toast } from 'buefy';

export class AuditReportDocx {
  public async create(
    reportAnalysisResponse: AuditReportAnalysisResponseDto[],
    auditTitle: string,
  ) {
    const document = new Document();
    const fileTitle =
      reportAnalysisResponse.length === 1
        ? reportAnalysisResponse[0].auditTitle
        : auditTitle;
    const resultArray: any[] = [];
    await Promise.all(
      reportAnalysisResponse.map(
        async (analysis: AuditReportAnalysisResponseDto, index: number) => {
          resultArray[index] = await this.handleReportResponse(
            document,
            analysis,
          );
        },
      ),
    );

    resultArray.map((sectionData) => {
      document.addSection(sectionData);
    });

    const fileName = fileTitle + '_' + dayjs().format('DDMMMYYYY') + '.docx';
    Packer.toBlob(document).then((blob) => {
      try {
        saveAs(blob, fileName);
      } catch (err) {
        Toast.open({
          message: `Something went wrong. Try again.`,
          position: 'is-top',
          type: 'is-danger',
          duration: 3500,
        });
      } finally {
        Toast.open({
          message: `Export complete.`,
          position: 'is-top',
          type: 'is-dark',
          duration: 3500,
        });
      }
    });
  }

  public async handleReportResponse(
    document: Document,
    analysisResponse: AuditReportAnalysisResponseDto,
  ) {
    const {
      result,
      payload,
      analysisTitle,
      jbiLogo,
      projectTitle,
      auditTitle,
      auditSites,
      auditCriteria,
    } = analysisResponse;

    switch (payload.comparisonPeriods.length) {
      case 1:
        return await this.generateSinglePeriodDocx(
          document,
          result,
          payload,
          analysisTitle,
          jbiLogo,
          projectTitle,
          auditTitle,
          auditSites,
          auditCriteria,
        );
      default:
        return await this.generateMultiPeriodPdf(
          document,
          result,
          payload,
          analysisTitle,
          jbiLogo,
          projectTitle,
          auditTitle,
          auditSites,
          auditCriteria,
        );
    }
  }

  public async generateSinglePeriodDocx(
    document: Document,
    result: any,
    payload: any,
    analysisTitle: string,
    jbiLogo: string,
    projectTitle: string,
    auditTitle: string,
    auditSites: AuditSite[],
    auditCriteria: AuditCriteria[],
  ) {
    switch (auditSites.length) {
      case 1:
        const generateSingleSiteDocx = new GenerateSingleSiteDocx();

        return await generateSingleSiteDocx.create(
          document,
          result,
          payload,
          analysisTitle,
          jbiLogo,
          projectTitle,
          auditTitle,
          auditSites,
          auditCriteria,
        );
      default:
        const generateMultiSiteDocx = new GenerateMultiSiteDocx();

        return await generateMultiSiteDocx.create(
          document,
          result,
          payload,
          analysisTitle,
          jbiLogo,
          projectTitle,
          auditTitle,
          auditSites,
          auditCriteria,
        );
    }
  }

  public async generateMultiPeriodPdf(
    document: Document,
    result: any,
    payload: any,
    analysisTitle: string,
    jbiLogo: string,
    projectTitle: string,
    auditTitle: string,
    auditSites: AuditSite[],
    auditCriteria: AuditCriteria[],
  ) {
    switch (auditSites.length) {
      case 1:
        const generateSingleSiteComparisonDocx = new GenerateSingleSiteComparisonDocx();
        return await generateSingleSiteComparisonDocx.create(
          document,
          result,
          payload,
          analysisTitle,
          jbiLogo,
          projectTitle,
          auditTitle,
          auditSites,
          auditCriteria,
        );
      default:
        const generateMultiSiteComparisonDocx = new GeneerateMultiSiteComparisonDocx();
        return await generateMultiSiteComparisonDocx.create(
          document,
          result,
          payload,
          analysisTitle,
          jbiLogo,
          projectTitle,
          auditTitle,
          auditSites,
          auditCriteria,
        );
    }
  }
}
