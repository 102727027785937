import { AuditSite } from '@/store/modules/audits/types/audits.types';
import { cloneDeep as _cloneDeep, get as _get, map as _map } from 'lodash';
import { CRITERION_TYPE } from '@/store/types/criterions.types';
import dayjs from 'dayjs';

export class GenerateSingleSiteComparisonCsv {
  public dirtyMonths: string[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];

  public create(
    result: any,
    payload: any,
    analysisTitle: string,
    projectTitle: string,
    auditTitle: string,
    auditSites: AuditSite[],
  ) {
    const exportDateTime = dayjs().format('D MMM YYYY, HH:mm A');
    const responseArray: any[] = [];
    responseArray.push({
      column1: 'Project Name',
      column2: projectTitle,
    });
    responseArray.push({
      column1: 'Data Collection Period Name',
      column2: auditTitle,
    });
    responseArray.push({
      column1: 'Analysis Name',
      column2: analysisTitle,
    });
    responseArray.push({
      column1: 'Site',
      column2: auditSites[0].site.name,
    });
    const comparisonPeriods = this.getPeriods(payload.comparisonPeriods);
    responseArray.push({
      column1: 'Data Collection Periods',
      column2: this.transformPeriods(comparisonPeriods).join('\n'),
    });
    responseArray.push(
      ...this.generateCriteriaTables(
        result,
        payload,
        auditSites,
        comparisonPeriods,
      ),
    );
    responseArray.push(...this.generateSingleRowSpace());
    responseArray.push({
      column1: 'Exported on ' + exportDateTime,
    });
    return responseArray;
  }

  public generateCriteriaTables(
    result: any,
    payload: any,
    auditSites: AuditSite[],
    comparisonPeriods: string[],
  ): any {
    const criteriaStatistics: any[] = [];
    const statisticData: any[][] = [];
    const samplingConfigurationData: any[] = [];
    _map(result.comparisonResult, (comparisonResult: any) => {
      const dirtyStatisticData =
        comparisonResult.statisticData.configurationData;
      statisticData.push(dirtyStatisticData);
      samplingConfigurationData.push(
        comparisonResult.statisticData.samplingConfiguration,
      );
    });
    const {
      comparisonAggregateCriteriaConfiguration,
    } = this.generateComparisonConfigurationData(
      statisticData,
      payload,
      samplingConfigurationData,
    );
    comparisonAggregateCriteriaConfiguration.forEach(
      (criteriaDetails, criteriaDetailsIndex) => {
        criteriaStatistics.push(...this.generateMultipleRowsSpace());
        criteriaStatistics.push({
          column1: criteriaDetailsIndex + 1 + '. ' + criteriaDetails[0].title,
        });
        criteriaStatistics.push({
          column1: 'Answer Choices',
          column2: auditSites[0].site.name,
        });
        const periodRow: any = {
          column1: '',
        };
        comparisonPeriods.forEach((period: string, periodIndex: number) => {
          periodRow['column' + (periodIndex + 2)] = period;
        });
        criteriaStatistics.push(periodRow);
        const optionsList: string[][] = [];
        const totalList: number[] = [];
        const complianceList: number[] = [];
        const valuesList: number[][] = [];
        const trueValueList: number[][] = [];
        const naValueList: number[][] = [];
        criteriaDetails.forEach(
          (criteriaDetailPeriod: any, criteriaDetailPeriodIndex: number) => {
            let total: number = 0;
            let trueValue: number = 0;
            let naValue: number = 0;
            criteriaDetailPeriod.criteriaSamplingData.forEach(
              (optionsData: any, optionDataIndex: number) => {
                const option = Object.keys(optionsData)[0];
                if (optionsList[criteriaDetailPeriodIndex]) {
                  optionsList[criteriaDetailPeriodIndex].push(option);
                } else {
                  optionsList[criteriaDetailPeriodIndex] = [option];
                }
                switch (optionDataIndex) {
                  case 0:
                    trueValue = optionsData[option];
                    break;
                  case 1:
                    break;
                  default:
                    naValue = optionsData[option];
                }
                total += optionsData[option];
                if (valuesList[criteriaDetailPeriodIndex]) {
                  valuesList[criteriaDetailPeriodIndex].push(
                    optionsData[option],
                  );
                } else {
                  valuesList[criteriaDetailPeriodIndex] = [optionsData[option]];
                }
              },
            );
            totalList.push(total);
            if (trueValueList[criteriaDetailPeriodIndex]) {
              trueValueList[criteriaDetailPeriodIndex].push(trueValue);
            } else {
              trueValueList[criteriaDetailPeriodIndex] = [trueValue];
            }
            if (naValueList[criteriaDetailPeriodIndex]) {
              naValueList[criteriaDetailPeriodIndex].push(naValue);
            } else {
              naValueList[criteriaDetailPeriodIndex] = [naValue];
            }
            if (criteriaDetailPeriod.criteriaType === CRITERION_TYPE.BOOLEAN) {
              const compliance = (trueValue / (total - naValue)) * 100;
              complianceList.push(compliance ? compliance : 0);
            }
          },
        );
        optionsList[0].forEach(
          (optionName: string, optionNameIndex: number) => {
            const rowObject: any = {
              column1: optionName,
            };
            comparisonPeriods.forEach((period: any, periodIndex: number) => {
              rowObject['column' + (periodIndex + 2)] =
                valuesList[periodIndex][optionNameIndex];
            });
            criteriaStatistics.push(rowObject);
          },
        );
        const totalRow: any = {
          column1: 'Total Data Collected',
        };
        totalList.forEach((total: number, totalIndex: number) => {
          let formattedTotal;
          if (total.toString().includes('-')) {
            formattedTotal = '-';
          } else {
            formattedTotal = total;
          }
          totalRow['column' + (totalIndex + 2)] = formattedTotal;
        });
        criteriaStatistics.push(totalRow);
        if (criteriaDetails[0].criteriaType === CRITERION_TYPE.BOOLEAN) {
          const complianceRow: any = {
            column1: 'Compliance',
          };
          complianceList.forEach(
            (compliance: number, complianceIndex: number) => {
              complianceRow['column' + (complianceIndex + 2)] =
                compliance + '%';
            },
          );
          criteriaStatistics.push(complianceRow);
        }
        // sampling table
        criteriaStatistics.push(...this.generateSingleRowSpace());
        criteriaStatistics.push({
          column1: 'Sampling',
        });
        switch (criteriaDetails[0].samplingConfiguration.auditSamplingType) {
          case 'adHoc':
            criteriaStatistics.push({
              column1: 'Site',
              column2: 'Sampling',
            });
            criteriaStatistics.push({
              column1:
                criteriaDetails[0].siteSamplingConfiguration[0].auditSiteMap
                  .site.name,
              column2: criteriaDetails[0].criteriaSamplingDataConfiguration
                .isSamplingEnabled
                ? 'Enabled'
                : 'Disabled',
            });
            break;
          case 'consecutive':
            if (
              criteriaDetails[0].samplingConfiguration.samplingMode ===
              'minAndMax'
            ) {
              criteriaStatistics.push({
                column1: 'Site',
                column2: 'Min.',
                column3: 'Max.',
              });
              criteriaStatistics.push({
                column1:
                  criteriaDetails[0].siteSamplingConfiguration[0].auditSiteMap
                    .site.name,
                column2:
                  criteriaDetails[0].criteriaSamplingDataConfiguration
                    .minSampleSize,
                column3:
                  criteriaDetails[0].criteriaSamplingDataConfiguration
                    .maxSampleSize,
              });
            } else {
              criteriaStatistics.push({
                column1: 'Site',
                column2: 'Target',
              });
              criteriaStatistics.push({
                column1:
                  criteriaDetails[0].siteSamplingConfiguration[0].auditSiteMap
                    .site.name,
                column2:
                  criteriaDetails[0].criteriaSamplingDataConfiguration
                    .sampleSize,
              });
            }
            break;
          default:
            criteriaStatistics.push({
              column1: 'Site',
              column2: 'Target',
            });
            criteriaStatistics.push({
              column1:
                criteriaDetails[0].siteSamplingConfiguration[0].auditSiteMap
                  .site.name,
              column2:
                criteriaDetails[0].criteriaSamplingDataConfiguration.sampleSize,
            });
        }
      },
    );
    return criteriaStatistics;
  }

  public generateComparisonConfigurationData(
    configurationData: any[],
    payload: any,
    samplingConfigurationData: any[],
  ) {
    let comparisonAggregateCriteriaConfiguration: any[];
    if (
      Array.isArray(configurationData) &&
      configurationData.length === payload.comparisonPeriods.length
    ) {
      const aggregateConfiguration: any[] = [];
      _map(payload.filteredCriterias, (criteriaMapId, p) => {
        const aggregateCriteriaConfiguration: any[] = [];
        _map(configurationData, (configData, index) => {
          let dirtyAggregateConfiguration: any = {};
          configData.forEach((data: any) => {
            if (data.id === criteriaMapId) {
              const criteriaOptionsDataDistribution =
                data.criteriaSamplingData.criteriaOptionsDataDistribution;
              const criteriaSamplingData = Object.keys(
                criteriaOptionsDataDistribution,
              ).map((key: string) => ({
                [key]:
                  data.criteriaSamplingData.criteriaOptionsDataDistribution[
                    key
                  ],
              }));
              const dirtyConfig = {
                title: _get(data, 'criteria.title', ''),
                criteriaType: _get(data, 'criteria.criteriaType', ''),
                sampleSize: Number(
                  _get(
                    data,
                    'criteriaSamplingDataConfigurations.sampleSize',
                    0,
                  ),
                ),
                siteSamplingConfiguration: data.siteSamplingDataConfigurations,
                criteriaSamplingDataConfiguration:
                  data.criteriaSamplingDataConfigurations,
                samplingConfiguration: samplingConfigurationData[index],
                criteriaSamplingData,
              };
              dirtyAggregateConfiguration = _cloneDeep(dirtyConfig);
            }
          });
          aggregateCriteriaConfiguration.push(dirtyAggregateConfiguration);
        });
        aggregateConfiguration.push(aggregateCriteriaConfiguration);
      });
      comparisonAggregateCriteriaConfiguration = _cloneDeep(
        aggregateConfiguration,
      );
    } else {
      comparisonAggregateCriteriaConfiguration = [];
    }
    return { comparisonAggregateCriteriaConfiguration };
  }

  public generateSingleRowSpace(): any {
    return [{}];
  }

  public generateMultipleRowsSpace(): any {
    return [{}, {}, {}];
  }

  public getPeriods(comparisonPeriods: Date[][]): string[] {
    const periods: string[] = [];
    comparisonPeriods.forEach((period: Date[], periodIndex: number) => {
      periods.push(this.formatDateRange(period));
    });
    return periods;
  }

  public transformPeriods(comparisonPeriods: string[]): string[] {
    const transformedPeriods: string[] = [];
    comparisonPeriods.forEach((period: string, periodIndex: number) => {
      transformedPeriods.push(periodIndex + 1 + '. ' + period);
    });
    return transformedPeriods;
  }

  public formatDateRange(date: Date[]): string {
    return (
      this.getDate(new Date(date[0])) + ' - ' + this.getDate(new Date(date[1]))
    );
  }

  public getDate(newValue: Date): string {
    return (
      newValue.getDate() +
      ' ' +
      this.dirtyMonths[newValue.getMonth()] +
      ' ' +
      newValue.getFullYear()
    );
  }
}
