






























import { Component, Vue, Prop } from 'vue-property-decorator';
import BaseNav from '../../jbi-shared/vue-components/BaseNav.vue';
import BaseNavDropdown from '../../jbi-shared/vue-components/BaseNavDropdown.vue';
import { NavLink } from '../../jbi-shared/types/Nav';
import { useUserEmail, useUserName } from '../../utils/user.util';
import { useAction, useAuthState } from '../../utils/store.util';
import { JAAS_LOGIN_URL, redirectToJaasLogin } from '../../utils/login.util';
import { GeneralRoles } from '../../jbi-shared/types/casbin-permission.types';
import Navbar from './Navbar.vue';
import {
  SubscriptionInfo,
  SubscriptionStatus,
} from '../../store/modules/auth/types/auth.types';

@Component({ components: { BaseNav, BaseNavDropdown, Navbar } })
export default class Header extends Vue {
  @Prop()
  public layout!: string;
  private isMenuNavBarActive = false;
  get isLoggedIn(): boolean {
    return useAuthState.call(this).isLoggedIn;
  }

  get signOut() {
    return useAction.call(this, 'auth/signOut');
  }

  get JAAS_LOGIN_URL(): string {
    return JAAS_LOGIN_URL;
  }

  get redirectToJaasLogin() {
    return redirectToJaasLogin;
  }

  get isAdmin(): boolean {
    const permissions = useAuthState.call(this).roles;
    return permissions.some((e) => e === GeneralRoles.Admin);
  }

  get navLinks(): NavLink[] {
    const links: NavLink[] = [
      {
        label: `Sign Out`,
        onClick: this.signOut,
        class: `has-text-primary has-text-weight-semibold hover-color`,
      },
    ];

    if (this.isAdmin) {
      const userAdministrationLink = {
        label: `User Administration`,
        to: {
          name: 'admin-user-management',
        },
        class: `hover-color`,
      } as NavLink;
      const projectAdministrationLink = {
        label: `Project Administration`,
        to: {
          name: 'admin-project-management',
        },
        class: `hover-color`,
      } as NavLink;
      links.unshift(userAdministrationLink, projectAdministrationLink);
    }
    return links;
  }

  get userName(): string | undefined {
    return useUserName.call(this);
  }

  get userEmail(): string | undefined {
    return useUserEmail.call(this);
  }

  get menuToggleMobile() {
    return this.$store.commit('asideMobileStateToggle');
  }

  private menuNavBarToggle() {
    this.isMenuNavBarActive = !this.isMenuNavBarActive;
  }

  get subscriptionInfo(): SubscriptionInfo | null {
    return useAuthState.call(this).subscriptionInfo;
  }

  private isNavActive(navItemName: string) {
    return this.$route.path.indexOf(navItemName) >= 0;
  }

  get showWithoutSubscriptionLink(): boolean {
    if (this.subscriptionInfo) {
      return !(
        this.subscriptionInfo.subscriptionStatus === SubscriptionStatus.INVALID
      );
    } else {
      return true;
    }
  }
}
