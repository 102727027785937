
















import { Component, Vue, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';
import {
  CplusCriteria,
  JBICriteriaDTO,
  JBICriteriaStatus,
} from '../../../store/modules/projects/types/projects.types';
import JbiCriteriaUpdateModal from './JbiCriteriaUpdateModal.vue';

@Component({})
export default class CriteriaViewUpdateComponent extends Vue {
  @Prop()
  public criteria!: any;
  @Prop()
  public count!: boolean;
  @Prop()
  public isProject!: boolean;

  @State((state) => state.projects.updatedProjectReportJBICriteriaList)
  public updatedProjectReportJBICriteriaList!: JBICriteriaDTO[];

  @State((state) => state.audits.updatedAuditJBICriteriaList)
  public updatedAuditJBICriteriaList!: JBICriteriaDTO[];

  public openJbiCriteriaUpdateModal(criteriaId: number): void {
    const criteria: CplusCriteria | undefined = this.getUpdatedJbiCriteria(
      criteriaId,
    );
    if (!criteria) {
      return;
    }

    this.$buefy.modal.open({
      parent: this,
      component: JbiCriteriaUpdateModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        criteria,
      },
    });
  }

  public getUpdatedJbiCriteria(criteriaId: number): CplusCriteria | undefined {
    if (
      !this.updatedProjectReportJBICriteriaList &&
      !this.updatedAuditJBICriteriaList
    ) {
      return undefined;
    }

    const criteriaList: JBICriteriaDTO[] = this.isProject
      ? this.updatedProjectReportJBICriteriaList
      : this.updatedAuditJBICriteriaList;

    const updatedJbiCriteria: JBICriteriaDTO | undefined = criteriaList.find(
      (updatedCriteria: JBICriteriaDTO) => {
        return (
          updatedCriteria.status !== JBICriteriaStatus.NEW &&
          updatedCriteria.currentCriteria &&
          updatedCriteria.currentCriteria.id === criteriaId
        );
      },
    );

    if (!updatedJbiCriteria) {
      return undefined;
    }

    /**
     * "criteria.criteriaOptions" can carry value of stringified array.
     * Hence, using JSON.parse() to transform the data.
     */
    let options: any = this.criteria.criteriaOptions || [];
    if (typeof this.criteria.criteriaOptions === typeof 'string') {
      options =
        this.criteria.criteriaOptions !== ''
          ? JSON.parse(this.criteria.criteriaOptions)
          : [];
    }

    const criteriaOptions: string[] | number[] = options.filter(
      (option: any) => {
        return option !== 'N/A';
      },
    );

    return {
      ...this.criteria,
      criteriaOptions,
      updatedJbiCriteria,
    };
  }
}
