import { defaultApiState } from '@/store/types/general.types';
import { AxiosError } from 'axios';
import { JBICriteriaDTO } from '../projects/types/projects.types';
import {
  AuditDetail,
  AuditsState,
  AuditCriteria,
  AuditSite,
  DataCollectionSummaryResponsePayload,
  AuditSamplingResponse,
  DataCollectionRecord,
  DashboardChartData,
  PaginatedAuditEventLogsResponse,
  UpdateAuditGripBarrierModalPayload,
  UpdateAuditGripEnablerModalPayload,
  UpdateAuditGripStrategyModalPayload,
  AuditReportAnalysis,
  AuditGrip,
  AuditParticipantDTO,
} from './types/audits.types';

export const auditsMutations = {
  /**
   * get audit details
   * @param state
   * @constructor
   */
  GET_AUDIT_LOADING(state: AuditsState) {
    state.apiState.getAuditDetail = {
      ...defaultApiState,
      loading: true,
    };
    state.auditDetail = undefined;
  },
  GET_AUDIT_SUCCESS(state: AuditsState, payload: AuditDetail) {
    state.apiState.getAuditDetail = {
      ...defaultApiState,
      success: true,
    };
    state.auditDetail = payload;
  },
  GET_AUDIT_ERROR(state: AuditsState, error: AxiosError) {
    state.apiState.getAuditDetail = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * Update Single Site Sampling Loading
   * @param state
   * @constructor
   */
  UPDATE_SINGLE_SITE_SAMPLING_LOADING(state: AuditsState) {
    state.apiState.updateSingleSiteSampling = {
      ...defaultApiState,
      loading: true,
    };
    state.updatedSingleSiteSampling = undefined;
  },
  UPDATE_SINGLE_SITE_SAMPLING_SUCCESS(state: AuditsState, payload: any) {
    state.apiState.updateSingleSiteSampling = {
      ...defaultApiState,
      success: true,
    };
    state.updatedSingleSiteSampling = payload;
  },
  UPDATE_SINGLE_SITE_SAMPLING_ERROR(state: AuditsState, error: AxiosError) {
    state.apiState.updateSingleSiteSampling = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * Update Multi Site Sampling Loading
   * @param state
   * @constructor
   */
  UPDATE_MULTI_SITE_SAMPLING_LOADING(state: AuditsState) {
    state.apiState.updateMultiSiteSampling = {
      ...defaultApiState,
      loading: true,
    };
    state.updatedMultiSiteSampling = undefined;
  },
  UPDATE_MULTI_SITE_SAMPLING_SUCCESS(state: AuditsState, payload: any) {
    state.apiState.updateMultiSiteSampling = {
      ...defaultApiState,
      success: true,
    };
    state.updatedMultiSiteSampling = payload;
  },
  UPDATE_MULTI_SITE_SAMPLING_ERROR(state: AuditsState, error: AxiosError) {
    state.apiState.updateMultiSiteSampling = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * Empty Data Collection Summary
   * @param state
   * @constructor
   */
  EMPTY_DATA_COLLECTION_SUMMARY_SUCCESS(state: AuditsState) {
    state.apiState.getDataCollectionSummary = {
      ...defaultApiState,
      success: true,
    };
    state.dataCollectionSummary = undefined;
  },

  /**
   * Get Data Collection Summary
   * @param state
   * @constructor
   */
  GET_DATA_COLLECTION_SUMMARY_LOADING(state: AuditsState) {
    state.apiState.getDataCollectionSummary = {
      ...defaultApiState,
      loading: true,
    };
    state.dataCollectionSummary = undefined;
  },
  GET_DATA_COLLECTION_SUMMARY_SUCCESS(
    state: AuditsState,
    payload: DataCollectionSummaryResponsePayload,
  ) {
    state.apiState.getDataCollectionSummary = {
      ...defaultApiState,
      success: true,
    };
    state.dataCollectionSummary = payload;
  },
  GET_DATA_COLLECTION_SUMMARY_ERROR(state: AuditsState, error: AxiosError) {
    state.apiState.getDataCollectionSummary = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * Post Data Collection
   * @param state
   * @constructor
   */
  POST_DATA_COLLECTION_LOADING(state: AuditsState) {
    state.apiState.postDataCollection = {
      ...defaultApiState,
      loading: true,
    };
    state.dataCollectionResponse = undefined;
  },
  POST_DATA_COLLECTION_SUCCESS(state: AuditsState, payload: any) {
    state.apiState.postDataCollection = {
      ...defaultApiState,
      success: true,
    };
    if (state.auditDetail && state.auditDetail.startedAt === null) {
      state.auditDetail.startedAt = payload.createdAt;
    }
    state.dataCollectionResponse = payload;
  },
  POST_DATA_COLLECTION_ERROR(state: AuditsState, error: AxiosError) {
    state.apiState.postDataCollection = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * Post Data Collection
   * @param state
   * @constructor
   */
  GET_DATA_COLLECTION_ACTIVITY_LOGS_LOADING(state: AuditsState) {
    state.apiState.getDataCollectionActivityLogs = {
      ...defaultApiState,
      loading: true,
    };
    state.dataCollectionActivityLogs = undefined;
  },
  GET_DATA_COLLECTION_ACTIVITY_LOGS_SUCCESS(state: AuditsState, payload: any) {
    state.apiState.getDataCollectionActivityLogs = {
      ...defaultApiState,
      success: true,
    };
    state.dataCollectionActivityLogs = payload;
  },
  GET_DATA_COLLECTION_ACTIVITY_LOGS_ERROR(
    state: AuditsState,
    error: AxiosError,
  ) {
    state.apiState.getDataCollectionActivityLogs = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * Start Audit
   * @param state
   * @constructor
   */
  START_AUDIT_LOADING(state: AuditsState) {
    state.apiState.startAudit = {
      ...defaultApiState,
      loading: true,
    };
    state.auditDetail = undefined;
  },
  START_AUDIT_SUCCESS(state: AuditsState, payload: any) {
    state.apiState.startAudit = {
      ...defaultApiState,
      success: true,
    };
    state.auditDetail = payload;
  },
  START_AUDIT_ERROR(state: AuditsState, error: AxiosError) {
    state.apiState.startAudit = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * End Audit
   * @param state
   * @constructor
   */
  END_AUDIT_LOADING(state: AuditsState) {
    state.apiState.endAudit = {
      ...defaultApiState,
      loading: true,
    };
    state.auditDetail = undefined;
  },
  END_AUDIT_SUCCESS(state: AuditsState, payload: any) {
    state.apiState.endAudit = {
      ...defaultApiState,
      success: true,
    };
    state.auditDetail = payload;
  },
  END_AUDIT_ERROR(state: AuditsState, error: AxiosError) {
    state.apiState.getDataCollectionActivityLogs = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * Resume Audit
   * @param state
   * @constructor
   */
  RESUME_AUDIT_LOADING(state: AuditsState) {
    state.apiState.resumeAudit = {
      ...defaultApiState,
      loading: true,
    };
    state.auditDetail = undefined;
  },
  RESUME_AUDIT_SUCCESS(state: AuditsState, payload: any) {
    state.apiState.resumeAudit = {
      ...defaultApiState,
      success: true,
    };
    state.auditDetail = payload;
  },
  RESUME_AUDIT_ERROR(state: AuditsState, error: AxiosError) {
    state.apiState.getDataCollectionActivityLogs = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * Audit Multiple Criteria Update
   * @param state
   * @constructor
   */
  UPDATE_AUDIT_CRITEIAS_LOADING(state: AuditsState) {
    state.apiState.updateAuditCriterias = {
      ...defaultApiState,
      loading: true,
    };
    state.updatedAuditCriterias = undefined;
  },
  UPDATE_AUDIT_CRITEIAS_SUCCESS(state: AuditsState, payload: any) {
    state.apiState.updateAuditCriterias = {
      ...defaultApiState,
      success: true,
    };
    state.updatedAuditCriterias = payload;
  },
  UPDATE_AUDIT_CRITEIAS_ERROR(state: AuditsState, error: AxiosError) {
    state.apiState.updateAuditCriterias = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * Audit Criteria Update By Id
   * @param state
   * @constructor
   */
  UPDATE_AUDIT_CRITEIA_BY_ID_LOADING(state: AuditsState) {
    state.apiState.updateAuditCriteriaById = {
      ...defaultApiState,
      loading: true,
    };
    state.updatedAuditCriteriaById = undefined;
  },
  UPDATE_AUDIT_CRITEIA_BY_ID_SUCCESS(state: AuditsState, payload: any) {
    state.apiState.updateAuditCriteriaById = {
      ...defaultApiState,
      success: true,
    };
    state.updatedAuditCriteriaById = payload;
  },
  UPDATE_AUDIT_CRITEIA_BY_ID_ERROR(state: AuditsState, error: AxiosError) {
    state.apiState.updateAuditCriteriaById = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * Delete Data Collection record
   * @param state
   * @constructor
   */
  DELETE_DATA_COLLECTION_RECORD_LOADING(state: AuditsState) {
    state.apiState.deleteDataRecord = {
      ...defaultApiState,
      loading: true,
    };
    state.deletedDataRecord = undefined;
  },
  DELETE_DATA_COLLECTION_RECORD_SUCCESS(state: AuditsState, payload: any) {
    state.apiState.deleteDataRecord = {
      ...defaultApiState,
      success: true,
    };
    state.deletedDataRecord = payload;
  },
  DELETE_DATA_COLLECTION_RECORD_ERROR(state: AuditsState, error: AxiosError) {
    state.apiState.deleteDataRecord = {
      ...defaultApiState,
      error,
    };
  },

  /*
   ** Update Project General Settings
   */
  UPDATE_AUDIT_GENERAL_SETTING_LOADING(state: AuditsState) {
    state.apiState.updateAuditGeneralSettings = {
      ...defaultApiState,
      loading: true,
    };
  },
  UPDATE_AUDIT_GENERAL_SETTING_SUCCESS(state: AuditsState, payload: any) {
    state.apiState.updateAuditGeneralSettings = {
      ...defaultApiState,
      success: true,
    };
    state.updatedAuditGeneralSettings = payload;
    state.auditDetail = payload;
  },
  UPDATE_AUDIT_GENERAL_SETTING_ERROR(state: AuditsState, error: AxiosError) {
    state.apiState.updateAuditGeneralSettings = {
      ...defaultApiState,
      error,
    };
  },

  /*
   ** Update Project Data Collection Settings
   */
  UPDATE_AUDIT_DATA_COLLECTION_SETTING_LOADING(state: AuditsState) {
    state.apiState.updateAuditDataCollectionSettings = {
      ...defaultApiState,
      loading: true,
    };
  },
  UPDATE_AUDIT_DATA_COLLECTION_SETTING_SUCCESS(
    state: AuditsState,
    payload: any,
  ) {
    state.apiState.updateAuditDataCollectionSettings = {
      ...defaultApiState,
      success: true,
    };
    state.updatedAuditDataCollectionSettings = payload;
  },
  UPDATE_AUDIT_DATA_COLLECTION_SETTING_ERROR(
    state: AuditsState,
    error: AxiosError,
  ) {
    state.apiState.updateAuditDataCollectionSettings = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * get audit detail and settings
   * @param state
   * @constructor
   */
  GET_AUDIT_DETAIL_AND_SETTINGS_LOADING(state: AuditsState) {
    state.apiState.getAuditDetailAndSettings = {
      ...defaultApiState,
      loading: true,
    };
    state.auditDetailAndSettings = undefined;
  },
  GET_AUDIT_DETAIL_AND_SETTINGS_SUCCESS(
    state: AuditsState,
    payload: AuditDetail,
  ) {
    state.apiState.getAuditDetailAndSettings = {
      ...defaultApiState,
      success: true,
    };
    state.auditDetailAndSettings = payload;
  },
  GET_AUDIT_DETAIL_AND_SETTINGS_ERROR(state: AuditsState, error: AxiosError) {
    state.apiState.getAuditDetailAndSettings = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * get audit criteria list
   * @param state
   * @constructor
   */
  GET_AUDIT_CRITERIA_LOADING(state: AuditsState) {
    state.apiState.getAuditCriteria = {
      ...defaultApiState,
      loading: true,
    };
    state.auditCriteria = undefined;
  },
  GET_AUDIT_CRITERIA_SUCCESS(state: AuditsState, payload: AuditCriteria[]) {
    state.apiState.getAuditCriteria = {
      ...defaultApiState,
      success: true,
    };
    state.auditCriteria = payload;
  },
  GET_AUDIT_CRITERIA_ERROR(state: AuditsState, error: AxiosError) {
    state.apiState.getAuditCriteria = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * get audit site list
   * @param state
   * @constructor
   */
  GET_AUDIT_SITES_LOADING(state: AuditsState) {
    state.apiState.getAuditSites = {
      ...defaultApiState,
      loading: true,
    };
    state.auditSites = undefined;
  },
  GET_AUDIT_SITES_SUCCESS(state: AuditsState, payload: AuditSite[]) {
    state.apiState.getAuditSites = {
      ...defaultApiState,
      success: true,
    };
    state.auditSites = payload;
  },
  GET_AUDIT_SITES_ERROR(state: AuditsState, error: AxiosError) {
    state.apiState.getAuditSites = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * get audit Sampling Config
   * @param state
   * @constructor
   */
  GET_SAMPLING_CONFIG_LOADING(state: AuditsState) {
    state.apiState.getAuditConfig = {
      ...defaultApiState,
      loading: true,
    };
    state.auditConfig = undefined;
  },
  GET_SAMPLING_CONFIG_SUCCESS(
    state: AuditsState,
    payload: AuditSamplingResponse,
  ) {
    state.apiState.getAuditConfig = {
      ...defaultApiState,
      success: true,
    };
    state.auditConfig = payload;
  },
  GET_SAMPLING_CONFIG_ERROR(state: AuditsState, error: AxiosError) {
    state.apiState.getAuditConfig = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * get Data Collection Record
   * @param state
   * @constructor
   */
  GET_DATA_COLLECTION_RECORD_LOADING(state: AuditsState) {
    state.apiState.getDataCollectionRecord = {
      ...defaultApiState,
      loading: true,
    };
    state.dataCollectionRecord = undefined;
  },
  GET_DATA_COLLECTION_RECORD_SUCCESS(
    state: AuditsState,
    payload: DataCollectionRecord,
  ) {
    state.apiState.getDataCollectionRecord = {
      ...defaultApiState,
      success: true,
    };
    state.dataCollectionRecord = payload;
  },
  GET_DATA_COLLECTION_RECORD_ERROR(state: AuditsState, error: AxiosError) {
    state.apiState.getDataCollectionRecord = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * get Dashboard Chart Data
   * @param state
   * @constructor
   */
  GET_DASHBOARD_CHART_DATA_LOADING(state: AuditsState) {
    state.apiState.getDashboardChartData = {
      ...defaultApiState,
      loading: true,
    };
    state.dashboardChartData = undefined;
  },
  GET_DASHBOARD_CHART_DATA_SUCCESS(
    state: AuditsState,
    payload: DashboardChartData[],
  ) {
    state.apiState.getDashboardChartData = {
      ...defaultApiState,
      success: true,
    };
    state.dashboardChartData = payload;
  },
  GET_DASHBOARD_CHART_DATA_ERROR(state: AuditsState, error: AxiosError) {
    state.apiState.getDashboardChartData = {
      ...defaultApiState,
      error,
    };
  },
  GET_PARTICIPANTS_LOADING(state: AuditsState) {
    state.apiState.getParticipants = {
      ...defaultApiState,
      loading: true,
    };
  },
  GET_PARTICIPANTS_SUCCESS(state: AuditsState, data: AuditParticipantDTO[]) {
    state.apiState.getParticipants = {
      ...defaultApiState,
      success: true,
    };
    state.participants = data;
  },
  GET_PARTICIPANTS_ERROR(state: AuditsState, error: AxiosError) {
    state.apiState.getParticipants = {
      ...defaultApiState,
      error,
    };
  },

  CREATE_PARTICIPANT_LOADING(state: AuditsState) {
    state.apiState.createParticipant = {
      ...defaultApiState,
      loading: true,
    };
  },
  CREATE_PARTICIPANT_SUCCESS(state: AuditsState) {
    state.apiState.createParticipant = {
      ...defaultApiState,
      success: true,
    };
  },
  CREATE_PARTICIPANT_ERROR(state: AuditsState, error: AxiosError) {
    state.apiState.createParticipant = {
      ...defaultApiState,
      error,
    };
  },

  UPDATE_PARTICIPANT_LOADING(state: AuditsState) {
    state.apiState.updateParticipant = {
      ...defaultApiState,
      loading: true,
    };
  },
  UPDATE_PARTICIPANT_SUCCESS(state: AuditsState) {
    state.apiState.updateParticipant = {
      ...defaultApiState,
      success: true,
    };
  },
  UPDATE_PARTICIPANT_ERROR(state: AuditsState, error: AxiosError) {
    state.apiState.updateParticipant = {
      ...defaultApiState,
      error,
    };
  },

  DELETE_PARTICIPANT_LOADING(state: AuditsState) {
    state.apiState.deleteParticipant = {
      ...defaultApiState,
      loading: true,
    };
  },
  DELETE_PARTICIPANT_SUCCESS(state: AuditsState) {
    state.apiState.deleteParticipant = {
      ...defaultApiState,
      success: true,
    };
  },
  DELETE_PARTICIPANT_ERROR(state: AuditsState, error: AxiosError) {
    state.apiState.deleteParticipant = {
      ...defaultApiState,
      error,
    };
  },
  RESEND_INVITATION_LOADING(state: AuditsState) {
    state.apiState.resendInvitation = {
      ...defaultApiState,
      loading: true,
    };
  },
  RESEND_INVITATION_SUCCESS(state: AuditsState) {
    state.apiState.resendInvitation = {
      ...defaultApiState,
      success: true,
    };
  },
  RESEND_INVITATION_ERROR(state: AuditsState, error: AxiosError) {
    state.apiState.resendInvitation = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * get audit paginated audit log list
   * @param state
   * @constructor
   */
  GET_PAGINATED_EVENT_LOGS_LOADING(state: AuditsState) {
    state.apiState.getPaginatedEventLogs = {
      ...defaultApiState,
      loading: true,
    };
    state.paginatedEventLogs = undefined;
  },
  GET_PAGINATED_EVENT_LOGS_SUCCESS(
    state: AuditsState,
    payload: PaginatedAuditEventLogsResponse,
  ) {
    state.apiState.getPaginatedEventLogs = {
      ...defaultApiState,
      success: true,
    };
    state.paginatedEventLogs = payload;
  },
  GET_PAGINATED_EVENT_LOGS_ERROR(state: AuditsState, error: AxiosError) {
    state.apiState.getPaginatedEventLogs = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * Update Audit Status
   * @param state
   * @constructor
   */
  UPDATE_STATUS_LOADING(state: AuditsState) {
    state.apiState.updateStatus = {
      ...defaultApiState,
      loading: true,
    };
    state.updatedStatus = undefined;
  },
  UPDATE_STATUS_SUCCESS(state: AuditsState, payload: any) {
    state.apiState.updateStatus = {
      ...defaultApiState,
      success: true,
    };
    state.updatedStatus = payload;
  },
  UPDATE_STATUS_ERROR(state: AuditsState, error: AxiosError) {
    state.apiState.updateStatus = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * Audit Grip Update
   * @param state
   * @constructor
   */
  UPDATE_AUDIT_GRIP_LOADING(state: AuditsState) {
    state.apiState.updateAuditGrip = {
      ...defaultApiState,
      loading: true,
    };
    state.updateAuditGrip = undefined;
  },
  UPDATE_AUDIT_GRIP_SUCCESS(state: AuditsState, payload: any) {
    state.apiState.updateAuditGrip = {
      ...defaultApiState,
      success: true,
    };
    state.updateAuditGrip = payload;
  },
  UPDATE_AUDIT_GRIP_ERROR(state: AuditsState, error: AxiosError) {
    state.apiState.updateAuditGrip = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * get audit grip list
   * @param state
   * @constructor
   */
  GET_AUDIT_GRIP_LOADING(state: AuditsState) {
    state.apiState.getAuditGrip = {
      ...defaultApiState,
      loading: true,
    };
    state.auditGrip = undefined;
  },
  GET_AUDIT_GRIP_SUCCESS(state: AuditsState, payload: AuditGrip) {
    state.apiState.getAuditGrip = {
      ...defaultApiState,
      success: true,
    };
    state.auditGrip = payload;
  },
  GET_AUDIT_GRIP_ERROR(state: AuditsState, error: AxiosError) {
    state.apiState.getAuditGrip = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * Audit Grip Barrier Modal Post
   * @param state
   * @constructor
   */
  POST_AUDIT_GRIP_BARRIER_MODAL_LOADING(state: AuditsState) {
    state.apiState.createAuditGripBarrierModal = {
      ...defaultApiState,
      loading: true,
    };
    state.createAuditGripBarrier = undefined;
  },
  POST_AUDIT_GRIP_BARRIER_MODAL_SUCCESS(state: AuditsState, payload: any) {
    state.apiState.createAuditGripBarrierModal = {
      ...defaultApiState,
      success: true,
    };
    state.createAuditGripBarrier = payload;
  },
  POST_AUDIT_GRIP_BARRIER_MODAL_ERROR(state: AuditsState, error: AxiosError) {
    state.apiState.createAuditGripBarrierModal = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * Audit Grip Barrier Modal Update
   * @param state
   * @constructor
   */
  UPDATE_AUDIT_GRIP_BARRIER_MODAL_LOADING(state: AuditsState) {
    state.apiState.updateAuditGripBarrierModal = {
      ...defaultApiState,
      loading: true,
    };
    state.updateAuditGripBarrier = undefined;
  },
  UPDATE_AUDIT_GRIP_BARRIER_MODAL_SUCCESS(state: AuditsState, payload: any) {
    state.apiState.updateAuditGripBarrierModal = {
      ...defaultApiState,
      success: true,
    };
    state.updateAuditGripBarrier = payload;
  },
  UPDATE_AUDIT_GRIP_BARRIER_MODAL_ERROR(state: AuditsState, error: AxiosError) {
    state.apiState.updateAuditGripBarrierModal = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * get audit grip modal list
   * @param state
   * @constructor
   */
  GET_AUDIT_GRIP_BARRIER_MODAL_LOADING(state: AuditsState) {
    state.apiState.getAuditGripBarrierModal = {
      ...defaultApiState,
      loading: true,
    };
    state.auditGripBarrier = undefined;
  },
  GET_AUDIT_GRIP_BARRIER_MODAL_SUCCESS(
    state: AuditsState,
    payload: UpdateAuditGripBarrierModalPayload[],
  ) {
    state.apiState.getAuditGripBarrierModal = {
      ...defaultApiState,
      success: true,
    };
    state.auditGripBarrier = payload;
  },
  GET_AUDIT_GRIP_BARRIER_MODAL_ERROR(state: AuditsState, error: AxiosError) {
    state.apiState.getAuditGripBarrierModal = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * Delete Grip Barrier record
   * @param state
   * @constructor
   */
  DELETE_GRIP_BARRIER_RECORD_LOADING(state: AuditsState) {
    state.apiState.deletedGripBarrierRecord = {
      ...defaultApiState,
      loading: true,
    };
    state.deletedGripBarrierRecord = undefined;
  },
  DELETE_GRIP_BARRIER_RECORD_SUCCESS(state: AuditsState, payload: any) {
    state.apiState.deletedGripBarrierRecord = {
      ...defaultApiState,
      success: true,
    };
    state.deletedGripBarrierRecord = payload;
  },
  DELETE_GRIP_BARRIER_RECORD_ERROR(state: AuditsState, error: AxiosError) {
    state.apiState.deletedGripEnablerRecord = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * Audit Grip Enabler Modal Post
   * @param state
   * @constructor
   */
  POST_AUDIT_GRIP_ENABLER_MODAL_LOADING(state: AuditsState) {
    state.apiState.createAuditGripEnablerModal = {
      ...defaultApiState,
      loading: true,
    };
    state.createAuditGripEnabler = undefined;
  },
  POST_AUDIT_GRIP_ENABLER_MODAL_SUCCESS(state: AuditsState, payload: any) {
    state.apiState.createAuditGripEnablerModal = {
      ...defaultApiState,
      success: true,
    };
    state.createAuditGripEnabler = payload;
  },
  POST_AUDIT_GRIP_ENABLER_MODAL_ERROR(state: AuditsState, error: AxiosError) {
    state.apiState.createAuditGripEnablerModal = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * Audit Grip Enabler Modal Update
   * @param state
   * @constructor
   */
  UPDATE_AUDIT_GRIP_ENABLER_MODAL_LOADING(state: AuditsState) {
    state.apiState.updateAuditGripEnablerModal = {
      ...defaultApiState,
      loading: true,
    };
    state.updateAuditGripEnabler = undefined;
  },
  UPDATE_AUDIT_GRIP_ENABLER_MODAL_SUCCESS(state: AuditsState, payload: any) {
    state.apiState.updateAuditGripEnablerModal = {
      ...defaultApiState,
      success: true,
    };
    state.updateAuditGripEnabler = payload;
  },
  UPDATE_AUDIT_GRIP_ENABLER_MODAL_ERROR(state: AuditsState, error: AxiosError) {
    state.apiState.updateAuditGripEnablerModal = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * get audit grip enabler modal list
   * @param state
   * @constructor
   */
  GET_AUDIT_GRIP_ENABLER_MODAL_LOADING(state: AuditsState) {
    state.apiState.getAuditGripEnablerModal = {
      ...defaultApiState,
      loading: true,
    };
    state.auditGripEnabler = undefined;
  },
  GET_AUDIT_GRIP_ENABLER_MODAL_SUCCESS(
    state: AuditsState,
    payload: UpdateAuditGripEnablerModalPayload[],
  ) {
    state.apiState.getAuditGripEnablerModal = {
      ...defaultApiState,
      success: true,
    };
    state.auditGripEnabler = payload;
  },
  GET_AUDIT_GRIP_ENABLER_MODAL_ERROR(state: AuditsState, error: AxiosError) {
    state.apiState.getAuditGripEnablerModal = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * Delete Grip Enabler record
   * @param state
   * @constructor
   */
  DELETE_GRIP_ENABLER_RECORD_LOADING(state: AuditsState) {
    state.apiState.deletedGripEnablerRecord = {
      ...defaultApiState,
      loading: true,
    };
    state.deletedGripEnablerRecord = undefined;
  },
  DELETE_GRIP_ENABLER_RECORD_SUCCESS(state: AuditsState, payload: any) {
    state.apiState.deletedGripEnablerRecord = {
      ...defaultApiState,
      success: true,
    };
    state.deletedGripEnablerRecord = payload;
  },
  DELETE_GRIP_ENABLER_RECORD_ERROR(state: AuditsState, error: AxiosError) {
    state.apiState.deletedGripEnablerRecord = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * Audit Grip Enabler Modal Post
   * @param state
   * @constructor
   */
  POST_AUDIT_GRIP_STRATEGY_MODAL_LOADING(state: AuditsState) {
    state.apiState.createAuditGripStrategyModal = {
      ...defaultApiState,
      loading: true,
    };
    state.createAuditGripStrategy = undefined;
  },
  POST_AUDIT_GRIP_STRATEGY_MODAL_SUCCESS(state: AuditsState, payload: any) {
    state.apiState.createAuditGripStrategyModal = {
      ...defaultApiState,
      success: true,
    };
    state.createAuditGripStrategy = payload;
  },
  POST_AUDIT_GRIP_STRATEGY_MODAL_ERROR(state: AuditsState, error: AxiosError) {
    state.apiState.createAuditGripStrategyModal = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * get audit grip strategy modal list
   * @param state
   * @constructor
   */
  GET_AUDIT_GRIP_STRATEGY_MODAL_LOADING(state: AuditsState) {
    state.apiState.getAuditGripStrategyModal = {
      ...defaultApiState,
      loading: true,
    };
    state.auditGripStrategy = undefined;
  },
  GET_AUDIT_GRIP_STRATEGY_MODAL_SUCCESS(
    state: AuditsState,
    payload: UpdateAuditGripStrategyModalPayload[],
  ) {
    state.apiState.getAuditGripStrategyModal = {
      ...defaultApiState,
      success: true,
    };
    state.auditGripStrategy = payload;
  },
  GET_AUDIT_GRIP_STRATEGY_MODAL_ERROR(state: AuditsState, error: AxiosError) {
    state.apiState.getAuditGripStrategyModal = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * Audit Grip Strategy Modal Update
   * @param state
   * @constructor
   */
  UPDATE_AUDIT_GRIP_STRATEGY_MODAL_LOADING(state: AuditsState) {
    state.apiState.updateAuditGripStrategyModal = {
      ...defaultApiState,
      loading: true,
    };
    state.updateAuditGripStrategy = undefined;
  },
  UPDATE_AUDIT_GRIP_STRATEGY_MODAL_SUCCESS(state: AuditsState, payload: any) {
    state.apiState.updateAuditGripStrategyModal = {
      ...defaultApiState,
      success: true,
    };
    state.updateAuditGripStrategy = payload;
  },
  UPDATE_AUDIT_GRIP_STRATEGY_MODAL_ERROR(
    state: AuditsState,
    error: AxiosError,
  ) {
    state.apiState.updateAuditGripStrategyModal = {
      ...defaultApiState,
      error,
    };
  },

  /**
   * Delete Grip Strategy record
   * @param state
   * @constructor
   */
  DELETE_GRIP_STRATEGY_RECORD_LOADING(state: AuditsState) {
    state.apiState.deletedGripStrategyRecord = {
      ...defaultApiState,
      loading: true,
    };
    state.deletedGripStrategyRecord = undefined;
  },
  DELETE_GRIP_STRATEGY_RECORD_SUCCESS(state: AuditsState, payload: any) {
    state.apiState.deletedGripStrategyRecord = {
      ...defaultApiState,
      success: true,
    };
    state.deletedGripStrategyRecord = payload;
  },
  DELETE_GRIP_STRATEGY_RECORD_ERROR(state: AuditsState, error: AxiosError) {
    state.apiState.deletedGripStrategyRecord = {
      ...defaultApiState,
      error,
    };
  },

  /*
   ** get audit report analysis
   */
  GET_AUDIT_REPORT_ANALYSIS_LOADING(state: AuditsState): void {
    state.apiState.getAuditReportAnalysis = {
      ...defaultApiState,
      loading: true,
    };
    state.auditReportAnalysis = undefined;
  },
  GET_AUDIT_REPORT_ANALYSIS_SUCCESS(state: AuditsState, payload: any): void {
    state.apiState.getAuditReportAnalysis = {
      ...defaultApiState,
      success: true,
    };
    state.auditReportAnalysis = payload;
  },
  GET_AUDIT_REPORT_ANALYSIS_ERROR(state: AuditsState, error: AxiosError): void {
    state.apiState.getAuditReportAnalysis = {
      ...defaultApiState,
      error,
    };
  },

  /*
   ** get total audit report analysis
   */
  GET_TOTAL_AUDIT_REPORT_ANALYSIS_LOADING(state: AuditsState): void {
    state.apiState.getTotalAuditReportAnalysis = {
      ...defaultApiState,
      loading: true,
    };
    state.totalAuditReportAnalysis = undefined;
  },
  GET_TOTAL_AUDIT_REPORT_ANALYSIS_SUCCESS(
    state: AuditsState,
    payload: AuditReportAnalysis[],
  ): void {
    state.apiState.getTotalAuditReportAnalysis = {
      ...defaultApiState,
      success: true,
    };
    state.totalAuditReportAnalysis = payload;
  },
  GET_TOTAL_AUDIT_REPORT_ANALYSIS_ERROR(
    state: AuditsState,
    error: AxiosError,
  ): void {
    state.apiState.getTotalAuditReportAnalysis = {
      ...defaultApiState,
      error,
    };
  },

  /*
   ** update audit report analysis
   */
  UPDATE_AUDIT_REPORT_ANALYSIS_LOADING(state: AuditsState): void {
    state.apiState.updateAuditReportAnalysis = {
      ...defaultApiState,
      loading: true,
    };
    state.updatedAuditReportAnalysis = undefined;
  },
  UPDATE_AUDIT_REPORT_ANALYSIS_SUCCESS(state: AuditsState, payload: any): void {
    state.apiState.updateAuditReportAnalysis = {
      ...defaultApiState,
      success: true,
    };
    state.updatedAuditReportAnalysis = payload;
  },
  UPDATE_AUDIT_REPORT_ANALYSIS_ERROR(
    state: AuditsState,
    error: AxiosError,
  ): void {
    state.apiState.updateAuditReportAnalysis = {
      ...defaultApiState,
      error,
    };
  },

  /*
   ** delete audit report analysis
   */
  DELETE_AUDIT_REPORT_ANALYSIS_LOADING(state: AuditsState): void {
    state.apiState.deleteAuditReportAnalysis = {
      ...defaultApiState,
      loading: true,
    };
    state.deletedAuditReportAnalysis = undefined;
  },
  DELETE_AUDIT_REPORT_ANALYSIS_SUCCESS(state: AuditsState, payload: any): void {
    state.apiState.deleteAuditReportAnalysis = {
      ...defaultApiState,
      success: true,
    };
    state.deletedAuditReportAnalysis = payload;
  },
  DELETE_AUDIT_REPORT_ANALYSIS_ERROR(
    state: AuditsState,
    error: AxiosError,
  ): void {
    state.apiState.deleteAuditReportAnalysis = {
      ...defaultApiState,
      error,
    };
  },

  /*
   ** get Updated Audit JBI Criteria List
   */
  GET_UPDATED_AUDIT_JBI_CRITERIA_LIST_LOADING(state: AuditsState): void {
    state.updatedAuditJBICriteriaList = undefined;
    state.apiState.getUpdatedAuditJBICriteriaList = {
      ...defaultApiState,
      loading: true,
    };
  },
  GET_UPDATED_AUDIT_JBI_CRITERIA_LIST_SUCCESS(
    state: AuditsState,
    payload: JBICriteriaDTO[],
  ): void {
    state.updatedAuditJBICriteriaList = payload;
    state.apiState.getUpdatedAuditJBICriteriaList = {
      ...defaultApiState,
      success: true,
    };
  },
  GET_UPDATED_AUDIT_JBI_CRITERIA_LIST_ERROR(
    state: AuditsState,
    error: AxiosError,
  ): void {
    state.apiState.getUpdatedAuditJBICriteriaList = {
      ...defaultApiState,
      error,
    };
  },
};
