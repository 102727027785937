








































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { ToastProgrammatic as Toast } from 'buefy';
import {
  AuditDetail,
  AuditCriteria,
  AuditSite,
  DataCollectionActivityLogRequestPayload,
  AuditParticipantDTO,
} from '@/store/modules/audits/types/audits.types';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import CriteriaFilter from '@/views/Audit/components/CriteriaFilter.vue';
import SiteFilter from '@/views/Audit/components/SiteFilter.vue';
import BasePagination from '@/components/base/BasePagination.vue';
import DataCollectionActivityLog from '@/views/Audit/components/DataCollectionActivityLog.vue';
import DeleteRecordModal from '@/views/Audit/components/DeleteRecordModal.vue';
import { AuditStatus } from '@/store/modules/audits/types/audits.types';
import { useUserEmail } from '@/utils/user.util';
import { AuditSiteListDto } from '../../../../store/modules/audits/types/audits.types';
import { PacesParticipantRoles } from '@/jbi-shared/types/paces-project.types';

const dirtyMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

@Component({
  components: {
    CriteriaFilter,
    SiteFilter,
    BasePagination,
    DataCollectionActivityLog,
  },
})
export default class DataHistory extends Vue {
  @Prop() public auditDetail!: AuditDetail;
  @Prop() public auditId!: number;

  public isDataCollectionActive: boolean = false;
  public dataCollectionActivityLogsKey: number = Math.floor(
    Math.random() * 999,
  ); // Adding component key to initiate seamless refresh dataCollectionActivityLogs list

  @Action('audits/getAuditSitesForDataCollection')
  public getAuditSitesForDataCollection!: (auditId: number) => void;

  @State((state) => state.audits.auditSites)
  public auditSites!: AuditSite[];

  @Action('audits/getAuditCriteria')
  public getAuditCriteria!: (auditId: number) => void;

  @State((state) => state.audits.auditCriteria)
  public auditCriteria!: AuditCriteria[];

  @Action('audits/getDataCollectionUserActivityLogs')
  public getDataCollectionUserActivityLogs!: (
    payload: DataCollectionActivityLogRequestPayload,
  ) => void;

  @State((state) => state.audits.dataCollectionActivityLogs)
  public dataCollectionActivityLogs!: any;

  @Action('audits/deleteDataCollectionRecord')
  public deleteDataCollectionRecord!: (payload: {
    auditId: number;
    dataCollectionRecordId: number;
  }) => void;

  @State((state) => state.audits.deletedDataRecord)
  public deletedDataCollectionRecord!: any;

  public dirtyToDate: Date =
    this.auditDetail && this.auditDetail.endDate
      ? this.setTimeEnd(new Date(this.auditDetail.endDate))
      : new Date();
  public dirtyFromDate: Date =
    this.auditDetail && this.auditDetail.startDate
      ? this.setTimeStart(new Date(this.auditDetail.startDate))
      : new Date(
          this.dirtyToDate.getFullYear(),
          this.dirtyToDate.getMonth(),
          this.dirtyToDate.getDate() - 7,
        );
  public filteredCriterias: number[] = [];
  public dirtyDataCollectionActivityLogs: any = [];
  public noSiteSelectedText: string = 'No site selected';
  public noCriteriaSelectedText: string = 'No criteria selected';
  public noneSelectedText: string = 'No site and criteria selected';
  public noDataAvailableText: string = 'No data history available';
  public selectedSites: number[] = [];
  public page: number = 1;
  public perPage: number = 50;
  public totalCount: number = 0;

  get emptyDataText(): string {
    if (!this.selectedSites.length && !this.filteredCriterias.length) {
      return this.noneSelectedText;
    } else if (!this.selectedSites.length) {
      return this.noSiteSelectedText;
    } else if (!this.filteredCriterias.length) {
      return this.noCriteriaSelectedText;
    } else if (
      !this.dataCollectionActivityLogs ||
      !this.dataCollectionActivityLogs.length
    ) {
      return this.noDataAvailableText;
    } else {
      return '';
    }
  }

  public mounted() {
    if (
      this.auditDetail.endedAt ||
      this.auditDetail.project.status === AuditStatus.ARCHIVED ||
      this.auditDetail.status === AuditStatus.ARCHIVED
    ) {
      this.isDataCollectionActive = false;
    } else {
      this.isDataCollectionActive = true;
    }
    this.getAuditCriteria(this.auditId);
    this.getAuditSitesForDataCollection(this.auditId);
  }

  public formatDate(date: Date) {
    return this.getDate(date);
  }

  public getDate(newValue: Date) {
    return (
      newValue.getDate() +
      ' ' +
      dirtyMonths[newValue.getMonth()] +
      ' ' +
      newValue.getFullYear()
    );
  }

  public handleFromDateChange(newValue: Date) {
    const nowDate = new Date();
    this.dirtyFromDate = new Date(
      new Date(
        new Date(this.dirtyFromDate.setHours(nowDate.getHours())).setMinutes(
          nowDate.getMinutes(),
        ),
      ).setSeconds(nowDate.getSeconds()),
    );
    this.filterDataHistory();
  }

  public handleToDateChange(newValue: Date) {
    const nowDate = new Date();
    this.dirtyToDate = new Date(
      new Date(
        new Date(this.dirtyToDate.setHours(nowDate.getHours())).setMinutes(
          nowDate.getMinutes(),
        ),
      ).setSeconds(nowDate.getSeconds()),
    );
    this.filterDataHistory();
  }

  public applyCriteriaFilter(newValue: number[]) {
    this.filteredCriterias = newValue;
    if (newValue.length === 0) {
      this.dirtyDataCollectionActivityLogs = [];
    } else {
      this.filterDataHistory();
    }
  }

  public applySiteFilter(newValue: number[]) {
    this.selectedSites = newValue;
    if (newValue.length === 0) {
      this.dirtyDataCollectionActivityLogs = [];
    } else {
      this.filterDataHistory();
    }
  }

  @Watch('auditSites')
  public watchAuditSites() {
    this.selectedSites = this.siteList
      ? this.siteList.map((auditSite: AuditSiteListDto) => {
          return auditSite.auditSiteId;
        })
      : [];
    this.filterDataHistory();
  }

  @Watch('auditCriteria')
  public watchAuditCriteria() {
    this.filteredCriterias = this.auditCriteria
      ? this.auditCriteria.map((auditCriteria: AuditCriteria) => {
          return auditCriteria.id;
        })
      : [];
    this.filterDataHistory();
  }

  @Watch('dataCollectionActivityLogs')
  @isTruthy
  public watchDataCollectionActivityLogs() {
    this.dirtyDataCollectionActivityLogs = this.dataCollectionActivityLogs.slice(
      0,
      this.perPage,
    );
    this.totalCount = this.dataCollectionActivityLogs.length;
    this.dataCollectionActivityLogsKey += 1;
  }

  @Watch('deletedDataCollectionRecord')
  @isTruthy
  public watchDeletedDataCollectionRecord(newValue: any) {
    if (newValue.isDeleted) {
      Toast.open({
        message: `Data collection record deleted successfully.`,
        position: 'is-top',
        type: 'is-dark',
        duration: 3500,
      });
      this.filterDataHistory();
    }
  }

  public filterDataHistory() {
    const payload: DataCollectionActivityLogRequestPayload = {
      auditId: this.auditId,
      fromDate: this.setTimeStart(this.dirtyFromDate),
      toDate: this.setTimeEnd(this.dirtyToDate),
      siteIds: this.selectedSites,
      criteriaIds: this.filteredCriterias,
    };

    if (this.selectedSites.length > 0 && this.filteredCriterias.length > 0) {
      this.getDataCollectionUserActivityLogs(payload);
    }
  }

  public setTimeEnd(date: Date) {
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    return date;
  }

  public setTimeStart(date: Date) {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    return date;
  }

  public handleDeleteDataCollectionRecord(rowData: any) {
    return this.$buefy.modal.open({
      parent: this,
      component: DeleteRecordModal,
      hasModalCard: true,
      trapFocus: true,
      events: {
        deleteRecord: () => {
          this.deleteDataCollectionRecord({
            auditId: this.auditId,
            dataCollectionRecordId: rowData.id,
          });
        },
      },
    });
  }

  get criteriaList() {
    return this.auditCriteria;
  }

  get siteList(): AuditSiteListDto[] {
    if (!this.auditSites || !this.auditSites.length) {
      return [];
    }

    return this.auditSites.map((auditSite) => {
      return {
        siteId: auditSite.site.id,
        name: auditSite.site.name,
        auditSiteId: auditSite.id,
        id: auditSite.id,
      };
    });
  }

  get getCurrentPage(): number {
    return this.page;
  }

  set getCurrentPage(newValue: number) {
    this.page = Number(newValue);
    this.dirtyDataCollectionActivityLogs = this.dataCollectionActivityLogs.slice(
      this.startItemIndex - 1,
      this.endItemIndex,
    );
  }

  get getCurrentPerPage(): number {
    return this.perPage;
  }

  get totalNumberOfPage(): number {
    return Math.ceil(this.totalCount / this.perPage);
  }

  get isFirstPage(): boolean {
    return this.page === 1;
  }

  get isLastPage(): boolean {
    return this.page === this.totalNumberOfPage;
  }

  get startItemIndex(): number {
    return this.page * this.perPage - this.perPage + 1;
  }

  get endItemIndex(): number {
    return Math.min(this.totalCount, this.page * this.perPage);
  }

  get userEmail(): string | undefined {
    return useUserEmail.call(this);
  }
}
