import { AuditSite } from '@/store/modules/audits/types/audits.types';
import { cloneDeep as _cloneDeep, get as _get, map as _map } from 'lodash';
import { CRITERION_TYPE } from '@/store/types/criterions.types';
import dayjs from 'dayjs';

export class GenerateSingleSiteComparisonExcel {
  public dirtyMonths: string[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];
  public filterRowsCount: number = 6;

  public create(
    result: any,
    payload: any,
    analysisTitle: string,
    projectTitle: string,
    auditTitle: string,
    auditSites: AuditSite[],
  ) {
    const exportDateTime = dayjs().format('D MMM YYYY, HH:mm A');
    const responseArray: any[] = [];
    responseArray.push({
      cellInfo: [
        {
          name: 'A1',
          bgColor: 'EFEFEF',
          border: true,
          bold: true,
          wrapText: true,
          alignment: 'left',
        },
        {
          name: 'B1',
          bgColor: 'FFFFFF',
          border: true,
          bold: false,
          wrapText: true,
          alignment: 'left',
        },
      ],
      data: ['Project Name', projectTitle],
    });
    responseArray.push({
      cellInfo: [
        {
          name: 'A2',
          bgColor: 'EFEFEF',
          border: true,
          bold: true,
          wrapText: true,
          alignment: 'left',
        },
        {
          name: 'B2',
          bgColor: 'FFFFFF',
          border: true,
          bold: false,
          wrapText: true,
          alignment: 'left',
        },
      ],
      data: ['Data Collection Period Name', auditTitle],
    });
    responseArray.push({
      cellInfo: [
        {
          name: 'A3',
          bgColor: 'EFEFEF',
          border: true,
          bold: true,
          wrapText: true,
          alignment: 'left',
        },
        {
          name: 'B3',
          bgColor: 'FFFFFF',
          border: true,
          bold: false,
          wrapText: true,
          alignment: 'left',
        },
      ],
      data: ['Analysis Name', analysisTitle],
    });
    responseArray.push({
      cellInfo: [
        {
          name: 'A4',
          bgColor: 'EFEFEF',
          border: true,
          bold: true,
          wrapText: true,
          alignment: 'left',
        },
        {
          name: 'B4',
          bgColor: 'FFFFFF',
          border: true,
          bold: false,
          wrapText: true,
          alignment: 'left',
        },
      ],
      data: ['Site', auditSites[0].site.name],
    });
    const comparisonPeriods = this.getPeriods(payload.comparisonPeriods);
    responseArray.push({
      cellInfo: [
        {
          name: 'A5',
          bgColor: 'EFEFEF',
          border: true,
          bold: true,
          wrapText: true,
          alignment: 'left',
        },
        {
          name: 'B5',
          bgColor: 'FFFFFF',
          border: true,
          bold: false,
          wrapText: true,
          alignment: 'left',
        },
      ],
      data: [
        'Data Collection Periods',
        this.transformPeriods(comparisonPeriods).join('\n'),
      ],
    });
    responseArray.push(
      ...this.generateCriteriaTables(
        result,
        payload,
        auditSites,
        comparisonPeriods,
        this.filterRowsCount,
      ),
    );
    responseArray.push({
      cellInfo: [
        {
          name: 'A' + (responseArray.length + 1),
          isEmpty: true,
        },
      ],
      data: [''],
    });
    responseArray.push({
      cellInfo: [
        {
          name: 'A' + (responseArray.length + 1),
          isEmpty: true,
        },
      ],
      data: ['Exported on ' + exportDateTime],
    });
    return responseArray;
  }

  public generateCriteriaTables(
    result: any,
    payload: any,
    auditSites: AuditSite[],
    comparisonPeriods: string[],
    rowsCount: number,
  ): any {
    const criteriaStatistics: any[] = [];
    const statisticData: any[][] = [];
    const samplingConfigurationData: any[] = [];
    _map(result.comparisonResult, (comparisonResult: any) => {
      const dirtyStatisticData =
        comparisonResult.statisticData.configurationData;
      statisticData.push(dirtyStatisticData);
      samplingConfigurationData.push(
        comparisonResult.statisticData.samplingConfiguration,
      );
    });
    const {
      comparisonAggregateCriteriaConfiguration,
    } = this.generateComparisonConfigurationData(
      statisticData,
      payload,
      samplingConfigurationData,
    );
    comparisonAggregateCriteriaConfiguration.forEach(
      (criteriaDetails, criteriaDetailsIndex) => {
        criteriaStatistics.push(
          ...[
            {
              cellInfo: [
                {
                  name: 'A' + rowsCount,
                  isEmpty: true,
                },
              ],
              data: [''],
            },
            {
              cellInfo: [
                {
                  name: 'A' + (rowsCount + 1),
                  isEmpty: true,
                },
              ],
              data: [''],
            },
            {
              cellInfo: [
                {
                  name: 'A' + (rowsCount + 2),
                  isEmpty: true,
                },
              ],
              data: [''],
            },
          ],
        );
        rowsCount += 3;
        criteriaStatistics.push({
          cellInfo: [
            {
              name: 'A' + rowsCount,
              bgColor: 'D3F1FC',
              border: true,
              bold: true,
              wrapText: true,
              alignment: 'left',
              mergeInfo:
                'A' +
                rowsCount +
                ':' +
                this.getColumnName(comparisonPeriods.length + 1) +
                rowsCount,
            },
          ],
          data: [criteriaDetailsIndex + 1 + '. ' + criteriaDetails[0].title],
        });
        rowsCount += 1;
        criteriaStatistics.push({
          cellInfo: [
            {
              name: 'A' + rowsCount,
              bgColor: 'EFEFEF',
              border: true,
              bold: true,
              wrapText: true,
              alignment: 'left',
            },
            {
              name: 'B' + rowsCount,
              bgColor: 'EFEFEF',
              border: true,
              bold: true,
              wrapText: true,
              alignment: 'center',
              mergeInfo:
                'B' +
                rowsCount +
                ':' +
                this.getColumnName(comparisonPeriods.length + 1) +
                rowsCount,
            },
          ],
          data: ['Answer Choices', auditSites[0].site.name],
        });
        rowsCount += 1;
        const periodRow: any = [''];
        const periodRowCellInfo: any = [
          {
            name: 'A' + rowsCount,
            bgColor: 'EFEFEF',
            border: true,
            bold: true,
            wrapText: true,
            alignment: 'left',
          },
        ];
        comparisonPeriods.forEach((period: string, periodIndex: number) => {
          periodRow.push(period);
          periodRowCellInfo.push({
            name: this.getColumnName(periodIndex + 2) + rowsCount,
            bgColor: 'EFEFEF',
            border: true,
            bold: true,
            wrapText: true,
            alignment: 'right',
          });
        });
        criteriaStatistics.push({
          cellInfo: periodRowCellInfo,
          data: periodRow,
        });
        rowsCount += 1;
        const optionsList: string[][] = [];
        const totalList: number[] = [];
        const complianceList: number[] = [];
        const valuesList: number[][] = [];
        const trueValueList: number[][] = [];
        const naValueList: number[][] = [];
        criteriaDetails.forEach(
          (criteriaDetailPeriod: any, criteriaDetailPeriodIndex: number) => {
            let total: number = 0;
            let trueValue: number = 0;
            let naValue: number = 0;
            criteriaDetailPeriod.criteriaSamplingData.forEach(
              (optionsData: any, optionDataIndex: number) => {
                const option = Object.keys(optionsData)[0];
                if (optionsList[criteriaDetailPeriodIndex]) {
                  optionsList[criteriaDetailPeriodIndex].push(option);
                } else {
                  optionsList[criteriaDetailPeriodIndex] = [option];
                }
                switch (optionDataIndex) {
                  case 0:
                    trueValue = optionsData[option];
                    break;
                  case 1:
                    break;
                  default:
                    naValue = optionsData[option];
                }
                total += optionsData[option];
                if (valuesList[criteriaDetailPeriodIndex]) {
                  valuesList[criteriaDetailPeriodIndex].push(
                    optionsData[option],
                  );
                } else {
                  valuesList[criteriaDetailPeriodIndex] = [optionsData[option]];
                }
              },
            );
            totalList.push(total);
            if (trueValueList[criteriaDetailPeriodIndex]) {
              trueValueList[criteriaDetailPeriodIndex].push(trueValue);
            } else {
              trueValueList[criteriaDetailPeriodIndex] = [trueValue];
            }
            if (naValueList[criteriaDetailPeriodIndex]) {
              naValueList[criteriaDetailPeriodIndex].push(naValue);
            } else {
              naValueList[criteriaDetailPeriodIndex] = [naValue];
            }
            if (criteriaDetailPeriod.criteriaType === CRITERION_TYPE.BOOLEAN) {
              const compliance = (trueValue / (total - naValue)) * 100;
              complianceList.push(compliance ? compliance : 0);
            }
          },
        );
        optionsList[0].forEach(
          (optionName: string, optionNameIndex: number) => {
            const rowObject: any = [optionName];
            const rowObjectCellInfo: any = [
              {
                name: 'A' + rowsCount,
                bgColor: 'FFFFFF',
                border: true,
                bold: false,
                wrapText: true,
                alignment: 'left',
              },
            ];
            comparisonPeriods.forEach((period: any, periodIndex: number) => {
              rowObject.push(valuesList[periodIndex][optionNameIndex]);
              rowObjectCellInfo.push({
                name: this.getColumnName(periodIndex + 2) + rowsCount,
                bgColor: 'FFFFFF',
                border: true,
                bold: false,
                wrapText: true,
                alignment: 'right',
              });
            });
            criteriaStatistics.push({
              cellInfo: rowObjectCellInfo,
              data: rowObject,
            });
            rowsCount += 1;
          },
        );
        const totalRow: any = ['Total Data Collected'];
        const totalRowCellInfo: any = [
          {
            name: 'A' + rowsCount,
            bgColor: 'FFFFFF',
            border: true,
            bold: true,
            wrapText: true,
            alignment: 'left',
          },
        ];
        totalList.forEach((total: number, totalIndex: number) => {
          let formattedTotal;
          if (total.toString().includes('-')) {
            formattedTotal = '-';
          } else {
            formattedTotal = total;
          }
          totalRow.push(formattedTotal);
          totalRowCellInfo.push({
            name: this.getColumnName(totalIndex + 2) + rowsCount,
            bgColor: 'FFFFFF',
            border: true,
            bold: true,
            wrapText: true,
            alignment: 'right',
          });
        });
        criteriaStatistics.push({
          cellInfo: totalRowCellInfo,
          data: totalRow,
        });
        rowsCount += 1;
        if (criteriaDetails[0].criteriaType === CRITERION_TYPE.BOOLEAN) {
          const complianceRow: any = ['Compliance'];
          const complianceRowCellInfo: any = [
            {
              name: 'A' + rowsCount,
              bgColor: 'FFFFFF',
              border: true,
              bold: true,
              wrapText: true,
              alignment: 'left',
            },
          ];
          complianceList.forEach(
            (compliance: number, complianceIndex: number) => {
              complianceRow.push(Math.round(compliance * 100) / 100 + '%');
              complianceRowCellInfo.push({
                name: this.getColumnName(complianceIndex + 2) + rowsCount,
                bgColor: 'FFFFFF',
                border: true,
                bold: true,
                wrapText: true,
                alignment: 'right',
              });
            },
          );
          criteriaStatistics.push({
            cellInfo: complianceRowCellInfo,
            data: complianceRow,
          });
          rowsCount += 1;
        }
        // sampling table
        criteriaStatistics.push({
          cellInfo: [
            {
              name: 'A' + rowsCount,
              isEmpty: true,
            },
          ],
          data: [''],
        });
        rowsCount += 1;
        criteriaStatistics.push({
          cellInfo: [
            {
              name: 'A' + rowsCount,
              bgColor: 'FFFFFF',
              border: true,
              bold: true,
              wrapText: true,
              alignment: 'left',
              mergeInfo:
                'A' +
                rowsCount +
                ':' +
                this.getColumnMergeName(criteriaDetails[0]) +
                rowsCount,
            },
          ],
          data: ['Sampling'],
        });
        rowsCount += 1;
        switch (criteriaDetails[0].samplingConfiguration.auditSamplingType) {
          case 'adHoc':
            criteriaStatistics.push({
              cellInfo: [
                {
                  name: 'A' + rowsCount,
                  bgColor: 'EFEFEF',
                  border: true,
                  bold: true,
                  wrapText: true,
                  alignment: 'left',
                },
                {
                  name: 'B' + rowsCount,
                  bgColor: 'EFEFEF',
                  border: true,
                  bold: true,
                  wrapText: true,
                  alignment: 'right',
                },
              ],
              data: ['Site', 'Sampling'],
            });
            rowsCount += 1;
            criteriaStatistics.push({
              cellInfo: [
                {
                  name: 'A' + rowsCount,
                  bgColor: 'FFFFFF',
                  border: true,
                  bold: false,
                  wrapText: true,
                  alignment: 'left',
                },
                {
                  name: 'B' + rowsCount,
                  bgColor: 'FFFFFF',
                  border: true,
                  bold: false,
                  wrapText: true,
                  alignment: 'right',
                },
              ],
              data: [
                criteriaDetails[0].siteSamplingConfiguration[0].auditSiteMap
                  .site.name,
                criteriaDetails[0].criteriaSamplingDataConfiguration
                  .isSamplingEnabled
                  ? 'Enabled'
                  : 'Disabled',
              ],
            });
            rowsCount += 1;
            break;
          case 'consecutive':
            if (
              criteriaDetails[0].samplingConfiguration.samplingMode ===
              'minAndMax'
            ) {
              criteriaStatistics.push({
                cellInfo: [
                  {
                    name: 'A' + rowsCount,
                    bgColor: 'EFEFEF',
                    border: true,
                    bold: false,
                    wrapText: true,
                    alignment: 'left',
                  },
                  {
                    name: 'B' + rowsCount,
                    bgColor: 'EFEFEF',
                    border: true,
                    bold: false,
                    wrapText: true,
                    alignment: 'right',
                  },
                  {
                    name: 'C' + rowsCount,
                    bgColor: 'EFEFEF',
                    border: true,
                    bold: false,
                    wrapText: true,
                    alignment: 'right',
                  },
                ],
                data: ['Site', 'Min.', 'Max.'],
              });
              rowsCount += 1;
              criteriaStatistics.push({
                cellInfo: [
                  {
                    name: 'A' + rowsCount,
                    bgColor: 'FFFFFF',
                    border: true,
                    bold: false,
                    wrapText: true,
                    alignment: 'left',
                  },
                  {
                    name: 'B' + rowsCount,
                    bgColor: 'FFFFFF',
                    border: true,
                    bold: false,
                    wrapText: true,
                    alignment: 'right',
                  },
                  {
                    name: 'C' + rowsCount,
                    bgColor: 'FFFFFF',
                    border: true,
                    bold: false,
                    wrapText: true,
                    alignment: 'right',
                  },
                ],
                data: [
                  criteriaDetails[0].siteSamplingConfiguration[0].auditSiteMap
                    .site.name,
                  criteriaDetails[0].criteriaSamplingDataConfiguration
                    .minSampleSize,
                  criteriaDetails[0].criteriaSamplingDataConfiguration
                    .maxSampleSize,
                ],
              });
              rowsCount += 1;
            } else {
              criteriaStatistics.push({
                cellInfo: [
                  {
                    name: 'A' + rowsCount,
                    bgColor: 'EFEFEF',
                    border: true,
                    bold: true,
                    wrapText: true,
                    alignment: 'left',
                  },
                  {
                    name: 'B' + rowsCount,
                    bgColor: 'EFEFEF',
                    border: true,
                    bold: true,
                    wrapText: true,
                    alignment: 'right',
                  },
                ],
                data: ['Site', 'Target'],
              });
              rowsCount += 1;
              criteriaStatistics.push({
                cellInfo: [
                  {
                    name: 'A' + rowsCount,
                    bgColor: 'FFFFFF',
                    border: true,
                    bold: false,
                    wrapText: true,
                    alignment: 'left',
                  },
                  {
                    name: 'B' + rowsCount,
                    bgColor: 'FFFFFF',
                    border: true,
                    bold: false,
                    wrapText: true,
                    alignment: 'right',
                  },
                ],
                data: [
                  criteriaDetails[0].siteSamplingConfiguration[0].auditSiteMap
                    .site.name,
                  criteriaDetails[0].criteriaSamplingDataConfiguration
                    .sampleSize,
                ],
              });
              rowsCount += 1;
            }
            break;
          default:
            criteriaStatistics.push({
              cellInfo: [
                {
                  name: 'A' + rowsCount,
                  bgColor: 'EFEFEF',
                  border: true,
                  bold: true,
                  wrapText: true,
                  alignment: 'left',
                },
                {
                  name: 'B' + rowsCount,
                  bgColor: 'EFEFEF',
                  border: true,
                  bold: true,
                  wrapText: true,
                  alignment: 'right',
                },
              ],
              data: ['Site', 'Target'],
            });
            rowsCount += 1;
            criteriaStatistics.push({
              cellInfo: [
                {
                  name: 'A' + rowsCount,
                  bgColor: 'FFFFFF',
                  border: true,
                  bold: false,
                  wrapText: true,
                  alignment: 'left',
                },
                {
                  name: 'B' + rowsCount,
                  bgColor: 'FFFFFF',
                  border: true,
                  bold: false,
                  wrapText: true,
                  alignment: 'right',
                },
              ],
              data: [
                criteriaDetails[0].siteSamplingConfiguration[0].auditSiteMap
                  .site.name,
                criteriaDetails[0].criteriaSamplingDataConfiguration.sampleSize,
              ],
            });
            rowsCount += 1;
        }
      },
    );
    return criteriaStatistics;
  }

  public getColumnMergeName(criteriaDetails: any) {
    switch (criteriaDetails.samplingConfiguration.auditSamplingType) {
      case 'consecutive':
        if (
          criteriaDetails.samplingConfiguration.samplingMode === 'minAndMax'
        ) {
          return 'C';
        } else {
          return 'B';
        }
      default:
        return 'B';
    }
  }

  public generateComparisonConfigurationData(
    configurationData: any[],
    payload: any,
    samplingConfigurationData: any[],
  ) {
    let comparisonAggregateCriteriaConfiguration: any[];
    if (
      Array.isArray(configurationData) &&
      configurationData.length === payload.comparisonPeriods.length
    ) {
      const aggregateConfiguration: any[] = [];
      _map(payload.filteredCriterias, (criteriaMapId, p) => {
        const aggregateCriteriaConfiguration: any[] = [];
        _map(configurationData, (configData, index) => {
          let dirtyAggregateConfiguration: any = {};
          configData.forEach((data: any) => {
            if (data.id === criteriaMapId) {
              const criteriaOptionsDataDistribution =
                data.criteriaSamplingData.criteriaOptionsDataDistribution;
              const criteriaSamplingData = Object.keys(
                criteriaOptionsDataDistribution,
              ).map((key: string) => ({
                [key]:
                  data.criteriaSamplingData.criteriaOptionsDataDistribution[
                    key
                  ],
              }));
              const dirtyConfig = {
                title: _get(data, 'criteria.title', ''),
                criteriaType: _get(data, 'criteria.criteriaType', ''),
                sampleSize: Number(
                  _get(
                    data,
                    'criteriaSamplingDataConfigurations.sampleSize',
                    0,
                  ),
                ),
                siteSamplingConfiguration: data.siteSamplingDataConfigurations,
                criteriaSamplingDataConfiguration:
                  data.criteriaSamplingDataConfigurations,
                samplingConfiguration: samplingConfigurationData[index],
                criteriaSamplingData,
              };
              dirtyAggregateConfiguration = _cloneDeep(dirtyConfig);
            }
          });
          aggregateCriteriaConfiguration.push(dirtyAggregateConfiguration);
        });
        aggregateConfiguration.push(aggregateCriteriaConfiguration);
      });
      comparisonAggregateCriteriaConfiguration = _cloneDeep(
        aggregateConfiguration,
      );
    } else {
      comparisonAggregateCriteriaConfiguration = [];
    }
    return { comparisonAggregateCriteriaConfiguration };
  }

  public getPeriods(comparisonPeriods: Date[][]): string[] {
    const periods: string[] = [];
    comparisonPeriods.forEach((period: Date[], periodIndex: number) => {
      periods.push(this.formatDateRange(period));
    });
    return periods;
  }

  public transformPeriods(comparisonPeriods: string[]): string[] {
    const transformedPeriods: string[] = [];
    comparisonPeriods.forEach((period: string, periodIndex: number) => {
      transformedPeriods.push(periodIndex + 1 + '. ' + period);
    });
    return transformedPeriods;
  }

  public formatDateRange(date: Date[]): string {
    return (
      this.getDate(new Date(date[0])) + ' - ' + this.getDate(new Date(date[1]))
    );
  }

  public getDate(newValue: Date): string {
    return (
      newValue.getDate() +
      ' ' +
      this.dirtyMonths[newValue.getMonth()] +
      ' ' +
      newValue.getFullYear()
    );
  }

  public getColumnName(columnNumber: number): string {
    let temp;
    let columnLetter = '';
    while (columnNumber > 0) {
      temp = (columnNumber - 1) % 26;
      columnLetter = String.fromCharCode(temp + 65) + columnLetter;
      columnNumber = (columnNumber - temp - 1) / 26;
    }
    return columnLetter;
  }
}
