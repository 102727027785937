




























































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { FilteredUserPayload } from '@/store/modules/users/types/users.types';
import { Debounce } from '@/jbi-shared/util/debounce.vue-decorator';

@Component({
  components: {},
})
export default class UserFilter extends Vue {
  @Prop(Object) public filterParams!: FilteredUserPayload;
  public userStatusOptions = {
    all: '',
    active: 'active',
    expired: 'expired',
  };

  @Watch('filterParams', { deep: true })
  public onChange() {
    this.$emit('input', this.filterParams);
  }
}
