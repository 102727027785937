import { Store, ActionContext } from 'vuex';
import { siteApi } from '@/api/site.api';
import { SiteState, Site, SiteInfo, GetSitesPayload } from './types/site.types';

export const siteActions = {
  async getSites(store: Store<SiteState>, params: GetSitesPayload) {
    try {
      store.commit('GET_SITE_LOADING');
      const { data } = await siteApi.getSites(params);
      const result: SiteInfo = data;
      store.commit('GET_SITE_SUCCESS', result);
    } catch (error) {
      store.commit('GET_SITE_ERROR', error);
    }
  },
  async addNewSite(store: Store<SiteState>, newSite: Site) {
    try {
      store.commit('ADD_SITE_LOADING');
      const { data } = await siteApi.addNewSite(newSite);
      const result: SiteInfo = data;
      store.commit('ADD_SITE_SUCCESS', result);
      return result;
    } catch (error) {
      store.commit('ADD_SITE_ERROR', error);
    }
  },
  async deleteSite(store: Store<SiteState>, id: number) {
    try {
      store.commit('DELETE_SITE_LOADING');
      await siteApi.deleteSite(id);
      store.commit('DELETE_SITE_SUCCESS', id);
    } catch (error) {
      store.commit('DELETE_SITE_ERROR', error);
    }
  },
  async editSite(store: Store<SiteState>, updatedSite: Site) {
    try {
      store.commit('EDIT_SITE_LOADING');
      const { data } = await siteApi.editSite(updatedSite);
      const result: SiteInfo = data;
      store.commit('EDIT_SITE_SUCCESS', result);
    } catch (error) {
      store.commit('EDIT_SITE_ERROR', error);
    }
  },
};
