






import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { useAuthState } from '@/utils/store.util';
import { JAAS_LOGIN_URL, redirectToJaasLogin } from '@/utils/login.util';

@Component({})
export default class ProjectNewUserInvite extends Vue {
  get isLoggedIn(): boolean {
    return useAuthState.call(this).isLoggedIn;
  }

  public async mounted() {
    if (this.isLoggedIn) {
      this.$router.push({
        name: 'project-me',
      });
    } else {
      redirectToJaasLogin({ destination: `/` });
    }
  }
}
