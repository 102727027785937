var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.singleSiteComparisonReportContainer},[(_vm.comparisonAggregateCriteriaConfiguration.length > 0)?_c('div',[(_vm.analysisFilter.checkIndividualCriteria && _vm.dataSet.length > 0)?_c('Card',{class:[
        _vm.$style.individualCriteriaContainer,
        _vm.comparisonIndividualMixedCriteriaConfiguration.length === 0
          ? _vm.$style.removeBottomMarginIndividualCriteriaContainer
          : null ]},_vm._l((_vm.dataSet),function(dataSetValue,dataSetIndex){return _c('div',{key:dataSetIndex},[(_vm.loadingSet[dataSetIndex] ? _vm.loadingSet[dataSetIndex] : false)?_c('div',{class:[
            _vm.$style.individualChartTitle,
            dataSetIndex !== 0 ? _vm.$style.applyMarginTitle : null ]},[_c('CriteriaViewUpdateComponent',{attrs:{"criteria":_vm.criteriaList[dataSetIndex],"count":dataSetIndex + 1}})],1):_vm._e(),(_vm.analysisFilter.selectedChartType === 'Vertical Bar Chart')?_c('div',[(_vm.loadingSet[dataSetIndex] ? _vm.loadingSet[dataSetIndex] : false)?_c('VerticalBarChart',{class:_vm.$style.individualChartVertical,attrs:{"barChartData":dataSetValue,"barChartOptions":_vm.dataSetOptions[dataSetIndex]}}):_vm._e()],1):_c('div',[(_vm.loadingSet[dataSetIndex] ? _vm.loadingSet[dataSetIndex] : false)?_c('HorizontalBarChart',{class:_vm.$style.individualChartHorizontal,attrs:{"barChartData":dataSetValue,"barChartOptions":_vm.dataSetOptions[dataSetIndex]}}):_vm._e()],1),_c('div',{class:_vm.$style.individualCriteriaStatisticContainer},[_c('h1',{class:_vm.$style.title},[_vm._v("Table")]),_c('div',{class:_vm.$style.table},[_c('SingleSiteComparisonTable',{attrs:{"siteList":_vm.auditSitelist,"comparisonPeriods":_vm.analysisFilter.comparisonPeriods,"source":_vm.comparisonIndividualBooleanCriteriaConfiguration[dataSetIndex],"auditReport":true,"showComplianceRate":_vm.comparisonIndividualBooleanCriteriaConfiguration[
                  dataSetIndex
                ][0].criteriaType === 'BOOLEAN'}})],1)])])}),0):_vm._e(),(
        !_vm.analysisFilter.checkIndividualCriteria &&
        _vm.dataSet.length > 0 &&
        _vm.analysisFilter.selectedChartType === 'Vertical Bar Chart'
      )?_c('div',{class:_vm.$style.verticalChartContainer},[(_vm.loadingSet[0] ? _vm.loadingSet[0] : false)?_c('VerticalBarChart',{class:_vm.$style.aggregateCriteriasVerticalBarChart,attrs:{"barChartData":_vm.dataSet[0],"barChartOptions":_vm.dataSetOptions[0]}}):_vm._e(),(_vm.analysisFilter.filteredCriterias.length > 8)?_c('div',[_c('table',{class:_vm.$style.criteriaListTable},_vm._l((_vm.getSelectedCriteriaList),function(auditCriteria,auditCriteriaIndex){return _c('tr',{key:auditCriteriaIndex},[_c('td',[_vm._v(_vm._s(auditCriteriaIndex + 1 + '.'))]),_c('td',[_vm._v(_vm._s(auditCriteria.criteria.title))])])}),0)]):_vm._e()],1):_vm._e(),(
        !_vm.analysisFilter.checkIndividualCriteria &&
        _vm.dataSet.length > 0 &&
        _vm.analysisFilter.selectedChartType === 'Horizontal Bar Chart'
      )?_c('div',{class:_vm.$style.horizontalChartContainer},[(_vm.loadingSet[0] ? _vm.loadingSet[0] : false)?_c('HorizontalBarChart',{class:_vm.$style.aggregateCriteriasHorizontalBarChart,attrs:{"barChartData":_vm.dataSet[0],"barChartOptions":_vm.dataSetOptions[0]}}):_vm._e(),(_vm.analysisFilter.filteredCriterias.length > 8)?_c('div',[_c('table',{class:_vm.$style.criteriaListTable},_vm._l((_vm.getSelectedCriteriaList),function(auditCriteria,auditCriteriaIndex){return _c('tr',{key:auditCriteriaIndex},[_c('td',[_vm._v(_vm._s(auditCriteriaIndex + 1 + '.'))]),_c('td',[_vm._v(_vm._s(auditCriteria.criteria.title))])])}),0)]):_vm._e()],1):_vm._e(),(_vm.renderOverallStatisticTable)?_c('div',{class:_vm.$style.statisticContainer},[(!_vm.analysisFilter.checkIndividualCriteria)?_c('div',[_c('h1',{class:_vm.$style.title},[_vm._v("Table")]),_vm._l((_vm.comparisonAggregateCriteriaConfiguration),function(record,index){return _c('div',{key:("static" + index),class:_vm.$style.item},[_c('h2',[_c('CriteriaViewUpdateComponent',{attrs:{"criteria":record[0],"count":index + 1}})],1),_c('div',{class:_vm.$style.table},[_c('SingleSiteComparisonTable',{attrs:{"siteList":_vm.auditSitelist,"comparisonPeriods":_vm.analysisFilter.comparisonPeriods,"source":record,"auditReport":true,"showComplianceRate":record[0].criteriaType === 'BOOLEAN'}})],1)])})],2):_c('div',_vm._l((_vm.comparisonIndividualMixedCriteriaConfiguration),function(record,index){return _c('div',{key:("static" + index),class:_vm.$style.mixedItem},[_c('h2',[_vm._v(" "+_vm._s(_vm.getMixedBooleanTitleIndex(index))+". "+_vm._s(record[0].title)+" ")]),_c('h1',{class:_vm.$style.mixedTitle},[_vm._v("Table")]),_c('div',{class:_vm.$style.table},[_c('SingleSiteComparisonTable',{attrs:{"siteList":_vm.auditSitelist,"comparisonPeriods":_vm.analysisFilter.comparisonPeriods,"source":record,"auditReport":true,"showComplianceRate":record[0].criteriaType === 'BOOLEAN'}})],1)])}),0)]):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }