var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showTableContent)?_c('div',{class:_vm.$style.container},[_c('table',{class:_vm.$style.table},[_c('thead',[_c('tr',[_c('td',[_vm._v("No")]),_c('td',[_vm._v("Data Collection Period Criteria")]),(
            _vm.samplingConfigurations.auditSamplingType === 'consecutive' &&
            _vm.samplingConfigurations.samplingMode === 'minAndMax'
          )?[_c('td',{attrs:{"width":"5%"}},[_vm._v("Min")]),_c('td',{attrs:{"width":"5%"}},[_vm._v("Max")])]:(
            _vm.samplingConfigurations.auditSamplingType === 'targetSampleSize' ||
            (_vm.samplingConfigurations.auditSamplingType === 'consecutive' &&
              _vm.samplingConfigurations.samplingMode === 'targetSample')
          )?[_c('td',{attrs:{"width":"5%"}},[_vm._v("Target")])]:(_vm.samplingConfigurations.auditSamplingType === 'adHoc')?[_c('td',{attrs:{"width":"5%"}},[_vm._v("Ad-Hoc")])]:_vm._e(),_c('td',{attrs:{"width":"15%"}},[_vm._v("Total-To-Date")])],2)]),_c('tbody',_vm._l((_vm.data),function(item,index){return _c('tr',{key:("dataCollection" + index)},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(item.criteria))]),(
            _vm.samplingConfigurations.auditSamplingType === 'consecutive' &&
            _vm.samplingConfigurations.samplingMode === 'minAndMax'
          )?[_c('td',[_vm._v(_vm._s(item.minSampleSize))]),_c('td',[_vm._v(_vm._s(item.maxSampleSize))])]:(
            _vm.samplingConfigurations.auditSamplingType === 'targetSampleSize' ||
            (_vm.samplingConfigurations.auditSamplingType === 'consecutive' &&
              _vm.samplingConfigurations.samplingMode === 'targetSample')
          )?[_c('td',{staticStyle:{"text-align":"right"}},[_vm._v(_vm._s(item.targetSize))])]:(_vm.samplingConfigurations.auditSamplingType === 'adHoc')?[_c('td',{staticStyle:{"text-align":"right"}},[_vm._v(" "+_vm._s(item.isSamplingEnabled ? 'Enabled' : 'Disabled')+" ")])]:_vm._e(),_c('td',{staticStyle:{"text-align":"right"}},[_vm._v(_vm._s(item.total))])],2)}),0)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }