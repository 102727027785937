import { notificationState } from './notification.state';
import { notificationActions } from './notification.actions';
import { notificationMutations } from './notification.mutations';
import { notificationGetters } from './notification.getters';
import { ModuleTree } from 'vuex';
import { RootState } from '@/store/store';

export default {
  namespaced: true,
  state: notificationState,
  getters: notificationGetters,
  actions: notificationActions,
  mutations: notificationMutations,
} as ModuleTree<RootState>;
