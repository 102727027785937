














































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { ToastProgrammatic as Toast } from 'buefy';
import StrategyList from './StrategyList.vue';
import BasePaginatorHoc from '../../../components/base/BasePaginatorHoc.vue';
import {
  CreateGripStrategyBase,
  CreateGripStrategyPayload,
  GetStrategiesPayload,
  GripStrategy,
} from '../../../store/types/grip.types';
import StrategyModal from './StrategyModal.vue';
import {
  ApiState,
  Pagination,
  PaginatorDto,
} from '../../../store/types/general.types';
import { Debounce } from '../../../jbi-shared/util/debounce.vue-decorator';
import BaseLoading from '../../../components/base/BaseLoading.vue';
import {
  isDifferentDeep,
  isTruthy,
} from '../../../jbi-shared/util/watcher.vue-decorator';

@Component({
  components: { BaseLoading, BasePaginatorHoc, StrategyList, StrategyModal },
})
export default class StrategySettings extends Vue {
  public isLoading: boolean = true;
  public sortColumn: string = 'title';
  public sortOrder: 'ASC' | 'DESC' = 'ASC';
  public perPage: number = 50;
  public page: number = 1;
  public totalCount: number = 0;
  public searchTitle: string = '';
  public filteredParams: GetStrategiesPayload = {
    title: this.searchTitle,
    limit: this.perPage,
    page: this.page,
  };
  public modalKey: number = Math.floor(Math.random() * 999);
  public isModalActive = false;

  @Action('projects/getGripStrategies')
  public getGripStrategies!: (payload: {
    projectId: number;
    params: GetStrategiesPayload;
  }) => void;

  @State((state) => state.projects.gripStrategies)
  public gripStrategies!: Pagination<GripStrategy>;

  @State((state) => state.projects.apiState.getGripStrategies)
  public getGripStrategiesApiState!: ApiState;

  @Action('projects/createGripStrategy')
  public createGripStrategy!: (payload: CreateGripStrategyPayload) => void;

  @State((state) => state.projects.apiState.createGripStrategy)
  public createGripStrategyApiState!: ApiState;

  get StrategyList() {
    return StrategyList;
  }

  get projectId(): number {
    return +this.$route.params.projectId;
  }

  get getGripStrategyList(): GripStrategy[] {
    this.totalCount = this.gripStrategies ? this.gripStrategies.totalItems : 0;
    return this.gripStrategies && this.gripStrategies.items.length
      ? this.gripStrategies.items
      : [];
  }

  public closeStrategyModal(): void {
    this.isModalActive = false;
  }

  public openStrategyModal(): void {
    this.isModalActive = true;
    this.modalKey += 1;
  }

  public handleStrategyCreate(gripStrategy: CreateGripStrategyBase) {
    this.createGripStrategy({
      ...gripStrategy,
      projectId: this.projectId,
    });
    this.closeStrategyModal();
  }

  public mounted(): void {
    this.getFilteredGripStrategies();
  }

  public handlePaginator({ perPage, page }: PaginatorDto) {
    this.page = page;
    this.perPage = perPage;
    this.filteredParams = {
      title: this.searchTitle,
      limit: this.perPage,
      page: this.page,
    };
  }

  public getFilteredGripStrategies(): void {
    this.getGripStrategies({
      projectId: this.projectId,
      params: this.filteredParams,
    });
  }

  @Watch('filteredParams', { deep: true })
  @isDifferentDeep
  public watchFilteredParams() {
    this.getFilteredGripStrategies();
  }

  @Watch('searchTitle')
  @Debounce(500)
  public onTitleSearch(): void {
    this.filteredParams = {
      title: this.searchTitle,
      limit: this.perPage,
      page: this.page,
    };
  }

  @Watch('getGripStrategiesApiState.loading')
  public watchGetGripStrategiesLoading(): void {
    this.isLoading = true;
  }

  @Watch('getGripStrategiesApiState.success')
  public watchGetGripStrategiesSuccess(): void {
    this.isLoading = false;
  }

  @Watch('createGripStrategyApiState.success')
  @isTruthy
  public onCreateStrategySuccess(): void {
    this.getFilteredGripStrategies();
    Toast.open({
      message: `Strategy added`,
      position: 'is-top',
      type: 'is-dark',
      duration: 3500,
    });
  }

  @Watch('createGripStrategyApiState.error')
  public onCreateStrategyError(): void {
    this.$emit('close');
  }
}
