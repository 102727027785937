



































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { cloneDeep as _cloneDeep } from 'lodash';

@Component({ components: {} })
export default class ProjectSiteFilter extends Vue {
  @Prop()
  public projectSites!: any[];
  @Prop()
  public filteredSites!: number[];
  @Prop({ default: false })
  public isDisabled!: boolean;
  public selectedSites: number[] = [];
  public isAllSitesSelected: boolean = true;
  public allSiteIds: number[] = [];
  public selectedSiteNames: string = '';

  get displaySelectedSiteName() {
    if (this.selectedSites.length > 0) {
      const siteNames: any = [];
      this.selectedSites.map((siteId: number) => {
        const selectedAuditSite = this.projectSites.find(
          (site: any) => site.projectSiteId === siteId,
        );
        if (selectedAuditSite) {
          siteNames.push(selectedAuditSite);
        }
      });
      if (siteNames) {
        this.selectedSiteNames = this.convertArrayItemIntoString(siteNames);
      }
      const selectedSiteName =
        this.selectedSiteNames.length > 55
          ? this.selectedSiteNames.substring(0, 55) + '...'
          : this.selectedSiteNames;
      return this.isAllSitesSelected ? 'All Sites' : selectedSiteName;
    } else {
      return 'Select Sites';
    }
  }

  public mounted() {
    if (this.filteredSites) {
      this.populateFilteredData();
    } else {
      this.populateData();
    }
  }

  public populateData() {
    this.projectSites.map((projectSite: any) => {
      this.selectedSites.push(projectSite.projectSiteId);
    });
    this.allSiteIds = _cloneDeep(this.selectedSites);
  }

  public populateFilteredData() {
    this.projectSites.map((projectSite: any) => {
      this.allSiteIds.push(projectSite.projectSiteId);
    });
    this.selectedSites = _cloneDeep(this.filteredSites);
    if (this.allSiteIds.length !== this.selectedSites.length) {
      this.isAllSitesSelected = false;
    }
  }

  public updateSelection(label: boolean = false, siteId: number) {
    if (label) {
      const siteIndex = this.selectedSites.indexOf(siteId);
      if (siteIndex === -1) {
        this.selectedSites.push(siteId);
      } else {
        this.selectedSites.splice(siteIndex, 1);
      }
    }
    this.isAllSitesSelected =
      this.allSiteIds.length === this.selectedSites.length;
    this.emitSiteFilter();
  }

  public updateAllSelection(label: boolean = false) {
    if (label) {
      this.isAllSitesSelected = !this.isAllSitesSelected;
    }
    if (this.isAllSitesSelected) {
      this.selectedSites = _cloneDeep(this.allSiteIds);
    } else {
      this.selectedSites = [];
    }
    this.emitSiteFilter();
  }

  public mapOrder(selectedSites: number[], projectSiteMapIds: number[]) {
    selectedSites.sort((selectedSite, selectedSiteNext) => {
      if (
        projectSiteMapIds.indexOf(selectedSite) >
        projectSiteMapIds.indexOf(selectedSiteNext)
      ) {
        return 1;
      } else {
        return -1;
      }
    });
    return selectedSites;
  }

  public emitSiteFilter() {
    if (this.selectedSites.length === 0 || this.selectedSites.length === 1) {
      this.$emit('applySiteFilter', this.selectedSites);
    } else {
      const projectSiteMapIds: number[] = this.projectSites.map(
        (projectSite) => projectSite.projectSiteId,
      );
      this.selectedSites = this.mapOrder(this.selectedSites, projectSiteMapIds);
      this.$emit('applySiteFilter', this.selectedSites);
    }
  }

  public convertArrayItemIntoString(data: any[]) {
    const values = data.map((item: any) => `${item.name}`).join(', ');
    return `${values}`;
  }
}
