import { AuditSite } from '@/store/modules/audits/types/audits.types';
import { cloneDeep as _cloneDeep, get as _get, map as _map } from 'lodash';
import { CRITERION_TYPE } from '@/store/types/criterions.types';
import dayjs from 'dayjs';

export class GenerateSingleSiteExcel {
  public dirtyMonths: string[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];
  public filterRowsCount: number = 6;

  public create(
    result: any,
    payload: any,
    analysisTitle: string,
    projectTitle: string,
    auditTitle: string,
    auditSites: AuditSite[],
  ) {
    const exportDateTime = dayjs().format('D MMM YYYY, HH:mm A');
    const responseArray: any[] = [];
    responseArray.push({
      cellInfo: [
        {
          name: 'A1',
          bgColor: 'EFEFEF',
          border: true,
          bold: true,
          wrapText: true,
          alignment: 'left',
        },
        {
          name: 'B1',
          bgColor: 'FFFFFF',
          border: true,
          bold: false,
          wrapText: true,
          alignment: 'left',
        },
      ],
      data: ['Project Name', projectTitle],
    });
    responseArray.push({
      cellInfo: [
        {
          name: 'A2',
          bgColor: 'EFEFEF',
          border: true,
          bold: true,
          wrapText: true,
          alignment: 'left',
        },
        {
          name: 'B2',
          bgColor: 'FFFFFF',
          border: true,
          bold: false,
          wrapText: true,
          alignment: 'left',
        },
      ],
      data: ['Data Collection Period Name', auditTitle],
    });
    responseArray.push({
      cellInfo: [
        {
          name: 'A3',
          bgColor: 'EFEFEF',
          border: true,
          bold: true,
          wrapText: true,
          alignment: 'left',
        },
        {
          name: 'B3',
          bgColor: 'FFFFFF',
          border: true,
          bold: false,
          wrapText: true,
          alignment: 'left',
        },
      ],
      data: ['Analysis Name', analysisTitle],
    });
    responseArray.push({
      cellInfo: [
        {
          name: 'A4',
          bgColor: 'EFEFEF',
          border: true,
          bold: true,
          wrapText: true,
          alignment: 'left',
        },
        {
          name: 'B4',
          bgColor: 'FFFFFF',
          border: true,
          bold: false,
          wrapText: true,
          alignment: 'left',
        },
      ],
      data: ['Site', auditSites[0].site.name],
    });
    responseArray.push({
      cellInfo: [
        {
          name: 'A5',
          bgColor: 'EFEFEF',
          border: true,
          bold: true,
          wrapText: true,
          alignment: 'left',
        },
        {
          name: 'B5',
          bgColor: 'FFFFFF',
          border: true,
          bold: false,
          wrapText: true,
          alignment: 'left',
        },
      ],
      data: [
        'Data Collection Period',
        this.formatDateRange(payload.selectedPeriod),
      ],
    });
    responseArray.push(
      ...this.generateCriteriaTables(result, auditSites, this.filterRowsCount),
    );
    responseArray.push({
      cellInfo: [
        {
          name: 'A' + (responseArray.length + 1),
          isEmpty: true,
        },
      ],
      data: [''],
    });
    responseArray.push({
      cellInfo: [
        {
          name: 'A' + (responseArray.length + 1),
          isEmpty: true,
        },
      ],
      data: ['Exported on ' + exportDateTime],
    });
    return responseArray;
  }

  public generateCriteriaTables(
    result: any,
    auditSites: AuditSite[],
    rowsCount: number,
  ): any {
    const criteriaStatistics: any[] = [];
    const { aggregateCriteriaConfiguration } = this.generateConfigurationData(
      result.statisticData.configurationData,
      result.statisticData.samplingConfiguration,
    );
    aggregateCriteriaConfiguration.forEach(
      (criteriaDetails, criteriaDetailsIndex) => {
        criteriaStatistics.push(
          ...[
            {
              cellInfo: [
                {
                  name: 'A' + rowsCount,
                  isEmpty: true,
                },
              ],
              data: [''],
            },
            {
              cellInfo: [
                {
                  name: 'A' + (rowsCount + 1),
                  isEmpty: true,
                },
              ],
              data: [''],
            },
            {
              cellInfo: [
                {
                  name: 'A' + (rowsCount + 2),
                  isEmpty: true,
                },
              ],
              data: [''],
            },
          ],
        );
        rowsCount += 3;
        criteriaStatistics.push({
          cellInfo: [
            {
              name: 'A' + rowsCount,
              bgColor: 'D3F1FC',
              border: true,
              bold: true,
              wrapText: true,
              alignment: 'left',
              mergeInfo: 'A' + rowsCount + ':' + 'B' + rowsCount,
            },
          ],
          data: [criteriaDetailsIndex + 1 + '. ' + criteriaDetails.title],
        });
        rowsCount += 1;
        criteriaStatistics.push({
          cellInfo: [
            {
              name: 'A' + rowsCount,
              bgColor: 'EFEFEF',
              border: true,
              bold: true,
              wrapText: true,
              alignment: 'left',
            },
            {
              name: 'B' + rowsCount,
              bgColor: 'EFEFEF',
              border: true,
              bold: true,
              wrapText: true,
              alignment: 'right',
            },
          ],
          data: ['Answer Choices', auditSites[0].site.name],
        });
        rowsCount += 1;
        let total: number = 0;
        let trueValue: number = 0;
        let naValue: number = 0;
        criteriaDetails.criteriaSamplingData.forEach(
          (optionsData: any, optionDataIndex: number) => {
            const option = Object.keys(optionsData)[0];
            switch (optionDataIndex) {
              case 0:
                trueValue = optionsData[option];
                break;
              case 1:
                break;
              default:
                naValue = optionsData[option];
            }
            total += optionsData[option];
            criteriaStatistics.push({
              cellInfo: [
                {
                  name: 'A' + rowsCount,
                  bgColor: 'FFFFFF',
                  border: true,
                  bold: false,
                  wrapText: true,
                  alignment: 'left',
                },
                {
                  name: 'B' + rowsCount,
                  bgColor: 'FFFFFF',
                  border: true,
                  bold: false,
                  wrapText: true,
                  alignment: 'right',
                },
              ],
              data: [option, optionsData[option]],
            });
            rowsCount += 1;
          },
        );
        let formattedTotal;
        if (total.toString().includes('-')) {
          formattedTotal = '-';
        } else {
          formattedTotal = total;
        }
        criteriaStatistics.push({
          cellInfo: [
            {
              name: 'A' + rowsCount,
              bgColor: 'FFFFFF',
              border: true,
              bold: true,
              wrapText: true,
              alignment: 'left',
            },
            {
              name: 'B' + rowsCount,
              bgColor: 'FFFFFF',
              border: true,
              bold: true,
              wrapText: true,
              alignment: 'right',
            },
          ],
          data: ['Total Data Collected', formattedTotal],
        });
        rowsCount += 1;
        if (criteriaDetails.criteriaType === CRITERION_TYPE.BOOLEAN) {
          const compliance = (trueValue / (total - naValue)) * 100;
          criteriaStatistics.push({
            cellInfo: [
              {
                name: 'A' + rowsCount,
                bgColor: 'FFFFFF',
                border: true,
                bold: true,
                wrapText: true,
                alignment: 'left',
              },
              {
                name: 'B' + rowsCount,
                bgColor: 'FFFFFF',
                border: true,
                bold: true,
                wrapText: true,
                alignment: 'right',
              },
            ],
            data: [
              'Compliance',
              compliance ? Math.round(compliance * 100) / 100 + '%' : '0%',
            ],
          });
          rowsCount += 1;
        }
        // sampling table
        criteriaStatistics.push({
          cellInfo: [
            {
              name: 'A' + rowsCount,
              isEmpty: true,
            },
          ],
          data: [''],
        });
        rowsCount += 1;
        criteriaStatistics.push({
          cellInfo: [
            {
              name: 'A' + rowsCount,
              bgColor: 'FFFFFF',
              border: true,
              bold: true,
              wrapText: true,
              alignment: 'left',
              mergeInfo:
                'A' +
                rowsCount +
                ':' +
                this.getColumnMergeName(criteriaDetails) +
                rowsCount,
            },
          ],
          data: ['Sampling'],
        });
        rowsCount += 1;
        switch (criteriaDetails.samplingConfiguration.auditSamplingType) {
          case 'adHoc':
            criteriaStatistics.push({
              cellInfo: [
                {
                  name: 'A' + rowsCount,
                  bgColor: 'EFEFEF',
                  border: true,
                  bold: true,
                  wrapText: true,
                  alignment: 'left',
                },
                {
                  name: 'B' + rowsCount,
                  bgColor: 'EFEFEF',
                  border: true,
                  bold: true,
                  wrapText: true,
                  alignment: 'right',
                },
              ],
              data: ['Site', 'Sampling'],
            });
            rowsCount += 1;
            criteriaStatistics.push({
              cellInfo: [
                {
                  name: 'A' + rowsCount,
                  bgColor: 'FFFFFF',
                  border: true,
                  bold: false,
                  wrapText: true,
                  alignment: 'left',
                },
                {
                  name: 'B' + rowsCount,
                  bgColor: 'FFFFFF',
                  border: true,
                  bold: false,
                  wrapText: true,
                  alignment: 'right',
                },
              ],
              data: [
                criteriaDetails.siteSamplingConfiguration[0].auditSiteMap.site
                  .name,
                criteriaDetails.criteriaSamplingDataConfiguration
                  .isSamplingEnabled
                  ? 'Enabled'
                  : 'Disabled',
              ],
            });
            rowsCount += 1;
            break;
          case 'consecutive':
            if (
              criteriaDetails.samplingConfiguration.samplingMode === 'minAndMax'
            ) {
              criteriaStatistics.push({
                cellInfo: [
                  {
                    name: 'A' + rowsCount,
                    bgColor: 'EFEFEF',
                    border: true,
                    bold: false,
                    wrapText: true,
                    alignment: 'left',
                  },
                  {
                    name: 'B' + rowsCount,
                    bgColor: 'EFEFEF',
                    border: true,
                    bold: false,
                    wrapText: true,
                    alignment: 'right',
                  },
                  {
                    name: 'C' + rowsCount,
                    bgColor: 'EFEFEF',
                    border: true,
                    bold: false,
                    wrapText: true,
                    alignment: 'right',
                  },
                ],
                data: ['Site', 'Min.', 'Max.'],
              });
              rowsCount += 1;
              criteriaStatistics.push({
                cellInfo: [
                  {
                    name: 'A' + rowsCount,
                    bgColor: 'FFFFFF',
                    border: true,
                    bold: false,
                    wrapText: true,
                    alignment: 'left',
                  },
                  {
                    name: 'B' + rowsCount,
                    bgColor: 'FFFFFF',
                    border: true,
                    bold: false,
                    wrapText: true,
                    alignment: 'right',
                  },
                  {
                    name: 'C' + rowsCount,
                    bgColor: 'FFFFFF',
                    border: true,
                    bold: false,
                    wrapText: true,
                    alignment: 'right',
                  },
                ],
                data: [
                  criteriaDetails.siteSamplingConfiguration[0].auditSiteMap.site
                    .name,
                  criteriaDetails.criteriaSamplingDataConfiguration
                    .minSampleSize,
                  criteriaDetails.criteriaSamplingDataConfiguration
                    .maxSampleSize,
                ],
              });
              rowsCount += 1;
            } else {
              criteriaStatistics.push({
                cellInfo: [
                  {
                    name: 'A' + rowsCount,
                    bgColor: 'EFEFEF',
                    border: true,
                    bold: true,
                    wrapText: true,
                    alignment: 'left',
                  },
                  {
                    name: 'B' + rowsCount,
                    bgColor: 'EFEFEF',
                    border: true,
                    bold: true,
                    wrapText: true,
                    alignment: 'right',
                  },
                ],
                data: ['Site', 'Target'],
              });
              rowsCount += 1;
              criteriaStatistics.push({
                cellInfo: [
                  {
                    name: 'A' + rowsCount,
                    bgColor: 'FFFFFF',
                    border: true,
                    bold: false,
                    wrapText: true,
                    alignment: 'left',
                  },
                  {
                    name: 'B' + rowsCount,
                    bgColor: 'FFFFFF',
                    border: true,
                    bold: false,
                    wrapText: true,
                    alignment: 'right',
                  },
                ],
                data: [
                  criteriaDetails.siteSamplingConfiguration[0].auditSiteMap.site
                    .name,
                  criteriaDetails.criteriaSamplingDataConfiguration.sampleSize,
                ],
              });
              rowsCount += 1;
            }
            break;
          default:
            criteriaStatistics.push({
              cellInfo: [
                {
                  name: 'A' + rowsCount,
                  bgColor: 'EFEFEF',
                  border: true,
                  bold: true,
                  wrapText: true,
                  alignment: 'left',
                },
                {
                  name: 'B' + rowsCount,
                  bgColor: 'EFEFEF',
                  border: true,
                  bold: true,
                  wrapText: true,
                  alignment: 'right',
                },
              ],
              data: ['Site', 'Target'],
            });
            rowsCount += 1;
            criteriaStatistics.push({
              cellInfo: [
                {
                  name: 'A' + rowsCount,
                  bgColor: 'FFFFFF',
                  border: true,
                  bold: false,
                  wrapText: true,
                  alignment: 'left',
                },
                {
                  name: 'B' + rowsCount,
                  bgColor: 'FFFFFF',
                  border: true,
                  bold: false,
                  wrapText: true,
                  alignment: 'right',
                },
              ],
              data: [
                criteriaDetails.siteSamplingConfiguration[0].auditSiteMap.site
                  .name,
                criteriaDetails.criteriaSamplingDataConfiguration.sampleSize,
              ],
            });
            rowsCount += 1;
        }
      },
    );
    return criteriaStatistics;
  }

  public getColumnMergeName(criteriaDetails: any) {
    switch (criteriaDetails.samplingConfiguration.auditSamplingType) {
      case 'consecutive':
        if (
          criteriaDetails.samplingConfiguration.samplingMode === 'minAndMax'
        ) {
          return 'C';
        } else {
          return 'B';
        }
      default:
        return 'B';
    }
  }

  public generateConfigurationData(
    configurationData: any[],
    samplingConfiguration: any,
  ) {
    let aggregateCriteriaConfiguration: any[];
    if (Array.isArray(configurationData) && configurationData.length > 0) {
      const aggregateConfig: any = configurationData.map((data: any) => {
        const criteriaOptionsDataDistribution =
          data.criteriaSamplingData.criteriaOptionsDataDistribution;
        const criteriaSamplingData = Object.keys(
          criteriaOptionsDataDistribution,
        ).map((key: string) => ({
          [key]: data.criteriaSamplingData.criteriaOptionsDataDistribution[key],
        }));
        return {
          title: _get(data, 'criteria.title', ''),
          criteriaType: _get(data, 'criteria.criteriaType', ''),
          sampleSize: Number(
            _get(data, 'criteriaSamplingDataConfigurations.sampleSize', 0),
          ),
          siteSamplingConfiguration: data.siteSamplingDataConfigurations,
          criteriaSamplingDataConfiguration:
            data.criteriaSamplingDataConfigurations,
          samplingConfiguration,
          criteriaSamplingData,
        };
      });
      aggregateCriteriaConfiguration = _cloneDeep(aggregateConfig);
    } else {
      aggregateCriteriaConfiguration = [];
    }
    return { aggregateCriteriaConfiguration };
  }

  public formatDateRange(date: Date[]): string {
    return (
      this.getDate(new Date(date[0])) + ' - ' + this.getDate(new Date(date[1]))
    );
  }

  public getDate(newValue: Date): string {
    return (
      newValue.getDate() +
      ' ' +
      this.dirtyMonths[newValue.getMonth()] +
      ' ' +
      newValue.getFullYear()
    );
  }
}
