










































































import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { BooleanCriterionContent } from '@/store/types/criterions.types';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';

@Component({
  components: { ValidationProvider, ValidationObserver },
})
export default class BooleanCriterionInput extends Vue {
  @Prop({
    default() {
      return ['True', 'False', 'N/A'];
    },
  })
  public options!: BooleanCriterionContent['booleanOptions'];

  public truthy = this.options[0];
  public falsy = this.options[1];
  public notApplicable = this.options[2];
  public isSamePositiveInputError: boolean = false;
  public isSameNegativeInputError: boolean = false;

  @Watch('truthy')
  public onChangedTruthy(newValue: string) {
    switch (newValue) {
      case '':
        if (this.isSameNegativeInputError) {
          this.isSameNegativeInputError = false;
        }
        this.isSamePositiveInputError = false;
        this.$emit('disableSubmitButton', true);
        break;
      case this.falsy:
        this.isSamePositiveInputError = true;
        this.$emit('disableSubmitButton', true);
        break;
      default:
        if (this.isSameNegativeInputError) {
          this.isSameNegativeInputError = false;
        }
        this.isSamePositiveInputError = false;
        this.$emit('disableSubmitButton', false);
        this.$emit('update:options', [
          this.truthy,
          this.falsy,
          this.notApplicable,
        ]);
    }
  }

  @Watch('falsy')
  public onChangedFalsy(newValue: string) {
    switch (newValue) {
      case '':
        if (this.isSamePositiveInputError) {
          this.isSamePositiveInputError = false;
        }
        this.isSameNegativeInputError = false;
        this.$emit('disableSubmitButton', true);
        break;
      case this.truthy:
        this.isSameNegativeInputError = true;
        this.$emit('disableSubmitButton', true);
        break;
      default:
        if (this.isSamePositiveInputError) {
          this.isSamePositiveInputError = false;
        }
        this.isSameNegativeInputError = false;
        this.$emit('disableSubmitButton', false);
        this.$emit('update:options', [
          this.truthy,
          this.falsy,
          this.notApplicable,
        ]);
    }
  }

  @isTruthy
  @Watch('options')
  public onRangeUpdated() {
    this.truthy = this.options[0];
    this.falsy = this.options[1];
  }
}
