



























import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { ToastProgrammatic as Toast } from 'buefy';
import { isTruthy } from '../../../jbi-shared/util/watcher.vue-decorator';
import {
  ProjectDetailPayload,
  ProjectParticipantDTO,
} from '../../../store/modules/projects/types/projects.types';
import { ApiState } from '../../../store/types/general.types';
import { RootState } from '../../../store/store';
import { AxiosError } from 'axios';
import {
  GripNotificationResponse,
  UpdateGripNotificationsPayload,
} from '../../../store/types/grip.types';

@Component({})
export default class GripNotificationModal extends Vue {
  @Prop()
  public gripNotifications!: GripNotificationResponse[];
  @Prop()
  public projectId!: number;
  @Prop()
  public currentUser!: ProjectParticipantDTO;

  @Action('projects/updateGripNotifications')
  public updateGripNotifications!: (payload: {
    projectId: number;
    payload: UpdateGripNotificationsPayload;
  }) => void;

  @State((state: RootState) => state.projects.updatedGripNotifications)
  public updatedGripNotifications!: any;

  @State((state) => state.projects.apiState.updateGripNotifications)
  public updateGripNotificationsState!: ApiState;

  @State((state) => state.projects.projectDetail)
  public projectDetail!: ProjectDetailPayload;

  get gripNotificationInfo(): GripNotificationResponse[] {
    return this.gripNotifications;
  }

  public acknowledgeGripNotifications(): void {
    this.updateGripNotifications({
      projectId: this.projectId,
      payload: {
        gripNotificationIds: this.gripNotifications.map(
          (notification) => notification.id,
        ),
        acknowledgedBy: this.currentUser,
        acknowledgedDate: new Date(),
      },
    });
  }

  @Watch('updateGripNotificationsState.success')
  @isTruthy
  public watchUpdateGripNotificationsStateSuccess(): void {
    Toast.open({
      message: 'Successfully Acknowledged.',
      position: 'is-top',
      type: 'is-dark',
      duration: 3500,
    });
    this.$emit('success');
    this.$emit('close');
  }

  @Watch('updateGripNotificationsState.error')
  @isTruthy
  public watchUpdateGripNotificationsStateError(error: AxiosError): void {
    Toast.open({
      message:
        error && error.response && error.response.data
          ? error.response.data.message
          : 'Something went wrong. Please try again.',
      position: 'is-top',
      type: 'is-danger',
      duration: 3500,
    });
    this.$emit('error');
    this.$emit('close');
  }
}
