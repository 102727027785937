
































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {Action} from 'vuex-class';

@Component({ components: {}})

export default class DeleteAnalysisModal extends Vue {
    @Prop()
    public analysisTitle!: string;

    public deleteAnalysis() {
        this.$emit('deleteAnalysis');
        this.$emit('close');
    }
    public closeModal() {
        this.$emit('close');
    }
}
