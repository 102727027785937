






























import { Component, Vue, Prop } from 'vue-property-decorator';
import { State, Action } from 'vuex-class';
import { AuditDetail } from '@/store/modules/audits/types/audits.types';
import { DataCollectionActivityLogRequestPayload } from '../../../store/modules/audits/types/audits.types';
import dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import { DATE_FORMAT } from '@/utils/date.util';
import { useUserEmail } from '@/utils/user.util';

// @ts-ignore
dayjs.extend(relativeTime);

@Component({
  components: {},
})
export default class AuditDataHistoryList extends Vue {
  @Prop() public auditDetail!: AuditDetail;

  @Action('audits/getDataCollectionActivityLogs')
  public getDataCollectionActivityLogs!: (
    payload: DataCollectionActivityLogRequestPayload,
  ) => void;

  @State((state) => state.audits.dataCollectionActivityLogs)
  public dataCollectionActivityLogs!: any;

  get auditId() {
    return this.$route.params.auditId;
  }

  public handleNavigate() {
    this.$emit('changeActiveTab', '#dataHistory');
  }

  public async mounted() {
    await this.getDataCollectionActivityLogs({
      auditId: +this.auditId,
      fromDate: new Date(this.auditDetail.startDate),
      toDate: new Date(),
    });
  }

  get userEmail(): string | undefined {
    return useUserEmail.call(this);
  }

  get histories() {
    const userEmail = this.userEmail;
    if (Array.isArray(this.dataCollectionActivityLogs)) {
      return this.dataCollectionActivityLogs.slice(0, 5).map((log) => {
        const today = dayjs().format(DATE_FORMAT);
        const yesterday = dayjs().add(-1, 'day').format(DATE_FORMAT);
        let total: number = 0;
        log.dataCollectionRecords.forEach((record: any) => {
          total += Number(record.total);
        });
        const dataUpdatedAt = dayjs(new Date(log.recordedStartDateTime)).format(
          DATE_FORMAT,
        );
        let dateToDisplay;
        if (dataUpdatedAt === today) {
          dateToDisplay = 'Today';
        } else if (dataUpdatedAt === yesterday) {
          dateToDisplay = 'Yesterday';
        } else {
          dateToDisplay = dataUpdatedAt;
        }
        return {
          datetime: dateToDisplay,
          email: log.user.email === userEmail ? 'You' : log.user.email,
          description: `added ${total} responses`,
        };
      });
    }
    return [];
  }
}
