import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/store';
import Buefy from 'buefy';
import VueRx from 'vue-rx';
import VueHeadful from 'vue-headful';
import { getPageTitle } from './utils/page-title.util';
import { axios } from './api/axios';
import PortalVue from 'portal-vue';
import { ValidationProvider, extend } from 'vee-validate';
import DataTable from '@/components/Table/DataTable.vue';
import ListItem from '@/components/ListItem.vue';
import { required, max, email, min } from 'vee-validate/dist/rules';
import VueGtag from 'vue-gtag';
import VueCompositionAPI from '@vue/composition-api';
// import validator from 'validator';

Vue.config.productionTip = false;
Vue.prototype.JAAS_PUBLIC_URL = process.env.VUE_APP_JAAS_PUBLIC_URL;
Vue.prototype.JAAS_DASHBOARD_URL = process.env.VUE_APP_JAAS_DASHBOARD_URL;
Vue.prototype.JBI_GLOBAL_URL = process.env.VUE_APP_JBI_GLOBAL_URL;
Vue.prototype.BACKEND_HOSTNAME = process.env.VUE_APP_BACKEND_HOSTNAME;
Vue.prototype.FRONTEND_ANALYTICS_TRACKING_ID =
  process.env.VUE_APP_FRONTEND_ANALYTICS_TRACKING_ID;
Vue.prototype.console = console;

// axios for Paces backend
axios.defaults.withCredentials = true;
axios.defaults.baseURL = Vue.prototype.BACKEND_HOSTNAME;
Vue.prototype.axios = axios;

Vue.use(Buefy);
Vue.use(VueRx);
Vue.use(PortalVue);
Vue.use(VueCompositionAPI as any);
Vue.use(
  VueGtag,
  {
    config: { id: Vue.prototype.FRONTEND_ANALYTICS_TRACKING_ID },
  },
  router,
);
// TODO: Disable Notification
// const socket = io(Vue.prototype.BACKEND_HOSTNAME, {
//   autoConnect: false,
//   transports: ['websocket', 'polling'],
// });
// Vue.use(VueSocketIOExt, socket);

Vue.component('VueHeadful', VueHeadful);
Vue.component('p-data-table', DataTable);
Vue.component('p-list-item', ListItem);

Vue.component('ValidationProvider', ValidationProvider);
extend('required', { ...required, message: '{_field_} field is required' });
extend('max', max);
extend('min', {
  validate(value, { length }) {
    return value.length >= length;
  },
  params: ['length'],
  message: '{_field_} field must have at least {length} characters',
});
extend('email', {
  ...email,
  message: '{_field_} field is invalid',
});

// utilify for page title
Vue.prototype.getPageTitle = getPageTitle;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
