





























































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { ToastProgrammatic as Toast } from 'buefy';
import BaseLoading from '../../../components/base/BaseLoading.vue';
import Card from '../../../components/Card.vue';
import RenameAnalysisModal from '../../../components/reports/RenameAnalysisModal.vue';
import { ApiState } from '../../../store/types/general.types';
import {
  GripAnalysis,
  GripAnalysisBarrier,
  GripAnalysisEnabler,
  CreateGripAnalysisBarrierPayload,
  CreateGripAnalysisEnablerPayload,
  CreateGripAnalysisPayload,
  CreateGripAnalysisStrategyPayload,
  GripAnalysisDTO,
  GripAnalysisStrategy,
  GripBarrierStatus,
  GripEnablerStatus,
  GripStrategyStatus,
  RenameGripAnalysisPayload,
  UpdateGripAnalysisBarrierPayload,
  UpdateGripAnalysisEnablerPayload,
  UpdateGripAnalysisStrategyPayload,
  CreateGripStrategyPayload,
  CreateGripEnablerPayload,
  CreateGripBarrierPayload,
  GripItemReloadDTO,
  GripItemType,
} from '../../../store/types/grip.types';
import GripAnalysisCompressedNavigationTabs from './GripAnalysisCompressedNavigationTabs.vue';
import GripAnalysisDetails from './GripAnalysisDetails.vue';
import GripAnalysisNavigationTabs from './GripAnalysisNavigationTabs.vue';
import DeleteModalBox from '../../../components/DeleteModalBox.vue';
import { ProjectCriteria } from '../../../store/modules/projects/types/projects.types';
import { isTruthy } from '../../../jbi-shared/util/watcher.vue-decorator';

@Component({
  components: {
    BaseLoading,
    Card,
    GripAnalysisNavigationTabs,
    GripAnalysisDetails,
    GripAnalysisCompressedNavigationTabs,
    RenameAnalysisModal,
    DeleteModalBox,
  },
})
export default class GripAnalysisPane extends Vue {
  @Prop() public projectId!: number;
  @Prop() public isDataCollector!: boolean;
  @Prop() public isSideBarOpen!: boolean;
  @Prop() public isGripDetails!: boolean;
  @Prop() public gripAnalysisList!: GripAnalysis[];
  @Prop() public selectedAnalysisTabIndex!: number;
  public selectedCriteria: ProjectCriteria[] = [];
  public isGripAnalysisTabChange: boolean = false;
  public isGripAnalysisLoading: boolean = false;
  public showCompressedAnalysisHeader: boolean = false;
  public showNullContainer: boolean = false;
  public analysisDetailsKey: number = Math.floor(Math.random() * 999);
  public visibleAnalysisHeaderKey: number = Math.floor(Math.random() * 999);
  public compressedAnalysisHeaderKey: number = Math.floor(Math.random() * 999);
  public displayTabCount: number = this.getDisplayTabCount;
  public selectedTabIndex: number = 0;
  public selectedAnalysisId: number = 0;
  public dirtyTotalAnalysis: GripAnalysis[] = [];
  public isAddToProjectGrip: boolean = false;
  public updatedItem: GripItemReloadDTO = { item: '', id: 0, prevItemId: null };

  @Action('projects/createGripBarrier')
  public createGripBarrier!: (payload: CreateGripBarrierPayload) => void;

  @State((state) => state.projects.apiState.createGripBarrier)
  public createGripBarrierApiState!: ApiState;

  @Action('projects/createGripEnabler')
  public createGripEnabler!: (payload: CreateGripEnablerPayload) => void;

  @State((state) => state.projects.apiState.createGripEnabler)
  public createGripEnablerApiState!: ApiState;

  @Action('projects/createGripStrategy')
  public createGripStrategy!: (payload: CreateGripStrategyPayload) => void;

  @State((state) => state.projects.apiState.createGripStrategy)
  public createGripStrategyApiState!: ApiState;

  @Action('projects/getGripAnalysis')
  public getGripAnalysis!: (payload: {
    projectId: number;
    gripAnalysisId: number;
  }) => void;

  @State((state) => state.projects.apiState.getGripAnalysis)
  public getGripAnalysisState!: ApiState;

  @State((state) => state.projects.gripAnalysis)
  public gripAnalysis!: GripAnalysis;

  @Action('projects/createGripAnalysis')
  public createGripAnalysis!: (payload: CreateGripAnalysisPayload) => void;

  @State((state) => state.projects.apiState.createGripAnalysis)
  public createGripAnalysisState!: ApiState;

  @Action('projects/deleteGripAnalysis')
  public deleteGripAnalysis!: (payload: {
    projectId: number;
    gripAnalysisId: number;
  }) => void;

  @State((state) => state.projects.apiState.deleteGripAnalysis)
  public deleteGripAnalysisState!: ApiState;

  @Action('projects/createGripAnalysisBarrier')
  public createGripAnalysisBarrier!: (payload: {
    projectId: number;
    payload: CreateGripAnalysisBarrierPayload;
  }) => void;

  @State((state) => state.projects.apiState.createGripAnalysisBarrier)
  public createGripAnalysisBarrierState!: ApiState;

  @State((state) => state.projects.createdGripAnalysisBarrier)
  public createdGripAnalysisBarrier!: GripAnalysisBarrier;

  @Action('projects/updateGripAnalysisBarrier')
  public updateGripAnalysisBarrier!: (payload: {
    projectId: number;
    payload: UpdateGripAnalysisBarrierPayload;
  }) => void;

  @State((state) => state.projects.apiState.updateGripAnalysisBarrier)
  public updateGripAnalysisBarrierState!: ApiState;

  @State((state) => state.projects.updatedGripAnalysisBarrier)
  public updatedGripAnalysisBarrier!: any;

  @Action('projects/deleteGripAnalysisBarrier')
  public deleteGripAnalysisBarrier!: (payload: {
    projectId: number;
    gripAnalysisBarrierId: number;
  }) => void;

  @State((state) => state.projects.apiState.deleteGripAnalysisBarrier)
  public deleteGripAnalysisBarrierState!: ApiState;

  @Action('projects/createGripAnalysisEnabler')
  public createGripAnalysisEnabler!: (payload: {
    projectId: number;
    payload: CreateGripAnalysisEnablerPayload;
  }) => void;

  @State((state) => state.projects.apiState.createGripAnalysisEnabler)
  public createGripAnalysisEnablerState!: ApiState;

  @State((state) => state.projects.createdGripAnalysisEnabler)
  public createdGripAnalysisEnabler!: GripAnalysisEnabler;

  @Action('projects/updateGripAnalysisEnabler')
  public updateGripAnalysisEnabler!: (payload: {
    projectId: number;
    payload: UpdateGripAnalysisEnablerPayload;
  }) => void;

  @State((state) => state.projects.apiState.updateGripAnalysisEnabler)
  public updateGripAnalysisEnablerState!: ApiState;

  @State((state) => state.projects.updatedGripAnalysisEnabler)
  public updatedGripAnalysisEnabler!: any;

  @Action('projects/deleteGripAnalysisEnabler')
  public deleteGripAnalysisEnabler!: (payload: {
    projectId: number;
    gripAnalysisEnablerId: number;
  }) => void;

  @State((state) => state.projects.apiState.deleteGripAnalysisEnabler)
  public deleteGripAnalysisEnablerState!: ApiState;

  @Action('projects/createGripAnalysisStrategy')
  public createGripAnalysisStrategy!: (payload: {
    projectId: number;
    payload: CreateGripAnalysisStrategyPayload;
  }) => void;

  @State((state) => state.projects.apiState.createGripAnalysisStrategy)
  public createGripAnalysisStrategyState!: ApiState;

  @State((state) => state.projects.createdGripAnalysisStrategy)
  public createdGripAnalysisStrategy!: GripAnalysisStrategy;

  @Action('projects/updateGripAnalysisStrategy')
  public updateGripAnalysisStrategy!: (payload: {
    projectId: number;
    payload: UpdateGripAnalysisStrategyPayload;
  }) => void;

  @State((state) => state.projects.apiState.updateGripAnalysisStrategy)
  public updateGripAnalysisStrategyState!: ApiState;

  @State((state) => state.projects.updatedGripAnalysisStrategy)
  public updatedGripAnalysisStrategy!: any;

  @Action('projects/deleteGripAnalysisStrategy')
  public deleteGripAnalysisStrategy!: (payload: {
    projectId: number;
    gripAnalysisStrategyId: number;
  }) => void;

  @State((state) => state.projects.apiState.deleteGripAnalysisStrategy)
  public deleteGripAnalysisStrategyState!: ApiState;

  /** It returns count of how many tabs should be visible */
  get getDisplayTabCount(): number {
    return this.isDataCollector ? 8 : this.isSideBarOpen ? 4 : 6;
  }

  get getVisibleAnalysis(): GripAnalysis[] {
    return this.dirtyTotalAnalysis.slice(0, this.displayTabCount);
  }

  get getCompressedAnalysis(): GripAnalysis[] {
    return this.dirtyTotalAnalysis.slice(this.displayTabCount);
  }

  get gripAnalysisDetails(): GripAnalysisDTO | undefined {
    if (!this.gripAnalysis) {
      this.showNullContainer = true;
      return;
    }

    const {
      gripAnalysisBarriers,
      gripAnalysisCriteriaMaps,
      ...gripAnalysis
    } = this.gripAnalysis;

    const barriers: GripAnalysisBarrier[] = gripAnalysisBarriers
      .filter((barrier) => barrier.status === GripBarrierStatus.ACTIVE)
      .map((barrier) => {
        const { gripAnalysisEnablers, ...barrierDetails } = barrier;

        const enablers: GripAnalysisEnabler[] = gripAnalysisEnablers
          .filter((enabler) => enabler.status === GripEnablerStatus.ACTIVE)
          .map((enabler) => {
            const { gripAnalysisStrategies, ...enablerDetails } = enabler;

            const strategies: GripAnalysisStrategy[] = gripAnalysisStrategies.filter(
              (strategy) => strategy.status === GripStrategyStatus.ACTIVE,
            );
            return {
              ...enablerDetails,
              gripAnalysisStrategies: strategies,
            };
          });

        return { ...barrierDetails, gripAnalysisEnablers: enablers };
      });

    return { ...gripAnalysis, gripAnalysisBarriers: barriers };
  }

  public getUpperCase(value: string): string {
    return value.toUpperCase();
  }

  public getGripAnalysisDetails(): void {
    this.getGripAnalysis({
      projectId: this.projectId,
      gripAnalysisId: this.selectedAnalysisId,
    });
  }

  public mounted(): void {
    this.selectedTabIndex = this.selectedAnalysisTabIndex
      ? this.selectedAnalysisTabIndex
      : 0;
    this.visibleAnalysisHeaderKey += 1;
    this.dirtyTotalAnalysis = this.gripAnalysisList
      ? JSON.parse(JSON.stringify(this.gripAnalysisList))
      : [];
    this.showNullContainer = this.dirtyTotalAnalysis.length > 0 ? false : true;
    if (this.dirtyTotalAnalysis.length > this.displayTabCount) {
      this.showCompressedAnalysisHeader = true;
      this.compressedAnalysisHeaderKey += 1;
    }
    if (this.dirtyTotalAnalysis.length > 0) {
      this.selectedAnalysisId = this.dirtyTotalAnalysis[
        this.selectedTabIndex
      ].id;
      this.getGripAnalysisDetails();
    }
  }

  public addNewAnalysis(): void {
    const analysisTitle: string =
      'Analysis ' + (this.dirtyTotalAnalysis.length + 1);
    this.createGripAnalysis({
      title: analysisTitle,
      projectId: this.projectId,
    });
    this.visibleAnalysisHeaderKey += 1;
    this.selectedTabIndex = 0;
    if (this.dirtyTotalAnalysis.length > this.displayTabCount) {
      this.showCompressedAnalysisHeader = true;
      this.compressedAnalysisHeaderKey += 1;
    }
  }

  public handleCompressedAnalysisClick(compressedAnalysisIndex: number): void {
    const selectedCompressedAnalysis: GripAnalysis = this.dirtyTotalAnalysis[
      this.displayTabCount + compressedAnalysisIndex
    ];
    this.dirtyTotalAnalysis.splice(
      this.displayTabCount + compressedAnalysisIndex,
      1,
    );
    this.dirtyTotalAnalysis.unshift(selectedCompressedAnalysis);
    this.visibleAnalysisHeaderKey += 1;
    this.compressedAnalysisHeaderKey += 1;
    this.selectedTabIndex = 0;
    this.selectedAnalysisId = this.dirtyTotalAnalysis[this.selectedTabIndex].id;
    this.isGripAnalysisTabChange = true;
    this.getGripAnalysisDetails();
  }

  public handleSelectedAnalysis(analysisItemIndex: number) {
    this.visibleAnalysisHeaderKey += 1;
    this.selectedTabIndex = analysisItemIndex;
    this.selectedAnalysisId = this.dirtyTotalAnalysis[this.selectedTabIndex].id;
    this.isGripAnalysisTabChange = true;
    this.getGripAnalysisDetails();
  }

  public handleRenameAnalysis(): void {
    this.$buefy.modal.open({
      parent: this,
      component: RenameAnalysisModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        currentAnalysisTitle: this.dirtyTotalAnalysis[this.selectedTabIndex]
          .title,
      },
      events: {
        updateAnalysisTitle: (newAnalysisTitle: string) => {
          this.dirtyTotalAnalysis[
            this.selectedTabIndex
          ].title = newAnalysisTitle;
          const renamePayload: RenameGripAnalysisPayload = {
            gripAnalysisId: this.dirtyTotalAnalysis[this.selectedTabIndex].id,
            title: newAnalysisTitle,
          };
          this.$emit('renameGripAnalysis', renamePayload);
        },
      },
    });
  }

  public handleDeleteAnalysis(): void {
    this.$buefy.modal.open({
      parent: this,
      component: DeleteModalBox,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle: `Delete ${
          this.dirtyTotalAnalysis[this.selectedTabIndex].title
        }?`,
        modalContent: `Are you sure you want to delete this ${
          this.dirtyTotalAnalysis[this.selectedTabIndex].title
        }? This action cannot be undone.`,
      },
      events: {
        close: () => {
          this.$emit('close');
        },
        confirm: () => {
          this.deleteGripAnalysis({
            projectId: this.projectId,
            gripAnalysisId: this.dirtyTotalAnalysis[this.selectedTabIndex].id,
          });
          this.dirtyTotalAnalysis.splice(this.selectedTabIndex, 1);
          this.visibleAnalysisHeaderKey += 1;
          this.compressedAnalysisHeaderKey += 1;
          this.selectedTabIndex = 0;
        },
      },
    });
  }

  public handleAddGripAnalysisBarrier(barrierDetails: {
    barrierPayload: CreateGripAnalysisBarrierPayload;
    isNew: boolean;
  }): void {
    this.createGripAnalysisBarrier({
      projectId: this.projectId,
      payload: barrierDetails.barrierPayload,
    });

    /** if new barrier add to the project barriers */
    if (barrierDetails.isNew) {
      const { gripAnalysisId, ...gripBarrier } = barrierDetails.barrierPayload;

      this.createGripBarrier({
        ...gripBarrier,
        projectId: this.projectId,
      });
      this.isAddToProjectGrip = true;
    }
  }

  public handleUpdateGripAnalysisBarrier(
    barrierPayload: UpdateGripAnalysisBarrierPayload,
  ): void {
    this.updateGripAnalysisBarrier({
      projectId: this.projectId,
      payload: barrierPayload,
    });
  }

  public handleDeleteGripAnalysisBarrier(gripAnalysisBarrierId: number): void {
    this.deleteGripAnalysisBarrier({
      projectId: this.projectId,
      gripAnalysisBarrierId,
    });
  }

  public handleAddGripAnalysisEnabler(enablerDetails: {
    enablerPayload: CreateGripAnalysisEnablerPayload;
    isNew: boolean;
  }): void {
    this.createGripAnalysisEnabler({
      projectId: this.projectId,
      payload: enablerDetails.enablerPayload,
    });

    /** if new enabler add to the project enablers */
    if (enablerDetails.isNew) {
      const {
        gripAnalysisBarrierId,
        gripAnalysisId,
        ...gripEnabler
      } = enablerDetails.enablerPayload;

      this.createGripEnabler({
        ...gripEnabler,
        projectId: this.projectId,
      });
      this.isAddToProjectGrip = true;
    }
  }

  public handleUpdateGripAnalysisEnabler(
    EnablerPayload: UpdateGripAnalysisEnablerPayload,
  ): void {
    this.updateGripAnalysisEnabler({
      projectId: this.projectId,
      payload: EnablerPayload,
    });
  }

  public handleDeleteGripAnalysisEnabler(gripAnalysisEnablerId: number): void {
    this.deleteGripAnalysisEnabler({
      projectId: this.projectId,
      gripAnalysisEnablerId,
    });
  }

  public handleAddGripAnalysisStrategy(strategyDetails: {
    strategyPayload: CreateGripAnalysisStrategyPayload;
    isNew: boolean;
  }): void {
    this.createGripAnalysisStrategy({
      projectId: this.projectId,
      payload: strategyDetails.strategyPayload,
    });

    /** if new strategy add to the project strategies */
    if (strategyDetails.isNew) {
      const {
        gripAnalysisEnablerId,
        gripAnalysisId,
        ...gripStrategy
      } = strategyDetails.strategyPayload;

      this.createGripStrategy({
        ...gripStrategy,
        projectId: this.projectId,
      });
      this.isAddToProjectGrip = true;
    }
  }

  public handleUpdateGripAnalysisStrategy(
    StrategyPayload: UpdateGripAnalysisStrategyPayload,
  ): void {
    this.updateGripAnalysisStrategy({
      projectId: this.projectId,
      payload: StrategyPayload,
    });
  }

  public handleDeleteGripAnalysisStrategy(
    gripAnalysisStrategyId: number,
  ): void {
    this.deleteGripAnalysisStrategy({
      projectId: this.projectId,
      gripAnalysisStrategyId,
    });
  }

  public handleUpdatedItemReload(data: GripItemReloadDTO): void {
    this.updatedItem = data;
  }

  @Watch('isSideBarOpen')
  public watchSideBarOpen(): void {
    this.displayTabCount = this.getDisplayTabCount;
    this.visibleAnalysisHeaderKey += 1;
    this.compressedAnalysisHeaderKey += 1;
    if (this.dirtyTotalAnalysis.length > this.displayTabCount) {
      this.showCompressedAnalysisHeader = true;
    } else {
      this.showCompressedAnalysisHeader = false;
    }
  }

  @Watch('createGripBarrierApiState.success')
  @isTruthy
  public onCreateBarrierSuccess(): void {
    if (this.isAddToProjectGrip) {
      this.isAddToProjectGrip = false;
      this.$emit('addToProjectGrip', 'barrier');
    }
  }

  @Watch('createGripEnablerApiState.success')
  @isTruthy
  public onCreateEnablerSuccess(): void {
    if (this.isAddToProjectGrip) {
      this.isAddToProjectGrip = false;
      this.$emit('addToProjectGrip', 'enabler');
    }
  }

  @Watch('createGripStrategyApiState.success')
  @isTruthy
  public onCreateStrategySuccess(): void {
    if (this.isAddToProjectGrip) {
      this.isAddToProjectGrip = false;
      this.$emit('addToProjectGrip', 'strategy');
    }
  }

  @Watch('getGripAnalysisState')
  public watchGetGripAnalysisStateLoading(state: ApiState): void {
    if (state.loading) {
      this.isGripAnalysisLoading = true;
    } else if (state.success) {
      this.isGripAnalysisLoading = false;
      this.selectedAnalysisId = this.gripAnalysis.id;
      this.selectedCriteria = this.gripAnalysis.gripAnalysisCriteriaMaps
        .filter((criteria) => !criteria.criteria.isDeleted)
        .map((criteria) => criteria.criteria);

      this.$emit(
        'gripAnalysisCriteria',
        this.gripAnalysis.gripAnalysisCriteriaMaps,
        this.isGripAnalysisTabChange,
      );

      if (this.isGripAnalysisTabChange) {
        this.isGripAnalysisTabChange = false;
      }
    }
  }

  @Watch('createGripAnalysisState')
  public watchCreateGripAnalysisState(state: ApiState): void {
    if (state.success) {
      Toast.open({
        message: `Analysis created successfully.`,
        position: 'is-top',
        type: 'is-dark',
        duration: 3500,
      });
      this.$emit('updateGripAnalysisList', 0);
    } else if (state.error) {
      Toast.open({
        message: `Something went wrong. Try again.`,
        position: 'is-top',
        type: 'is-danger',
        duration: 3500,
      });
    }
  }

  @Watch('deleteGripAnalysisState')
  public watchDeleteGripAnalysisState(state: ApiState): void {
    if (state.success) {
      Toast.open({
        message: `Analysis deleted successfully.`,
        position: 'is-top',
        type: 'is-dark',
        duration: 3500,
      });
      this.$emit('updateGripAnalysisList', 0);
    } else if (state.error) {
      Toast.open({
        message: `Something went wrong. Try again.`,
        position: 'is-top',
        type: 'is-danger',
        duration: 3500,
      });
    }
  }

  @Watch('createGripAnalysisBarrierState')
  public watchCreateGripAnalysisBarrierState(state: ApiState): void {
    if (state.success) {
      this.updatedItem = {
        item: GripItemType.AnalysisBarrier,
        id: this.createdGripAnalysisBarrier.id,
        prevItemId: null,
      };
      Toast.open({
        message: `GRiP barrier added successfully.`,
        position: 'is-top',
        type: 'is-dark',
        duration: 3500,
      });
    } else if (state.error) {
      Toast.open({
        message: `Something went wrong. Try again.`,
        position: 'is-top',
        type: 'is-danger',
        duration: 3500,
      });
    }
    this.getGripAnalysisDetails();
  }

  @Watch('updateGripAnalysisBarrierState')
  public watchUpdateGripAnalysisBarrierState(state: ApiState): void {
    if (state.success) {
      Toast.open({
        message: `GRiP analysis barrier updated successfully.`,
        position: 'is-top',
        type: 'is-dark',
        duration: 3500,
      });
    } else if (state.error) {
      Toast.open({
        message: `Something went wrong. Try again.`,
        position: 'is-top',
        type: 'is-danger',
        duration: 3500,
      });
    }
    this.getGripAnalysisDetails();
  }

  @Watch('deleteGripAnalysisBarrierState')
  public watchDeleteGripAnalysisBarrierState(state: ApiState): void {
    if (state.success) {
      Toast.open({
        message: `GRiP analysis barrier deleted successfully.`,
        position: 'is-top',
        type: 'is-dark',
        duration: 3500,
      });
    } else if (state.error) {
      Toast.open({
        message: `Something went wrong. Try again.`,
        position: 'is-top',
        type: 'is-danger',
        duration: 3500,
      });
    }
    this.getGripAnalysisDetails();
  }

  @Watch('createGripAnalysisEnablerState')
  public watchCreateGripAnalysisEnablerState(state: ApiState): void {
    if (state.success) {
      this.updatedItem = {
        item: GripItemType.AnalysisEnabler,
        id: this.createdGripAnalysisEnabler.id,
        prevItemId: null,
      };
      Toast.open({
        message: `GRiP enabler added successfully.`,
        position: 'is-top',
        type: 'is-dark',
        duration: 3500,
      });
    } else if (state.error) {
      Toast.open({
        message: `Something went wrong. Try again.`,
        position: 'is-top',
        type: 'is-danger',
        duration: 3500,
      });
    }
    this.getGripAnalysisDetails();
  }

  @Watch('updateGripAnalysisEnablerState')
  public watchUpdateGripAnalysisEnablerState(state: ApiState): void {
    if (state.success) {
      Toast.open({
        message: `GRiP analysis enabler updated successfully.`,
        position: 'is-top',
        type: 'is-dark',
        duration: 3500,
      });
    } else if (state.error) {
      Toast.open({
        message: `Something went wrong. Try again.`,
        position: 'is-top',
        type: 'is-danger',
        duration: 3500,
      });
    }
    this.getGripAnalysisDetails();
  }

  @Watch('deleteGripAnalysisEnablerState')
  public watchDeleteGripAnalysisEnablerState(state: ApiState): void {
    if (state.success) {
      Toast.open({
        message: `GRiP analysis enabler deleted successfully.`,
        position: 'is-top',
        type: 'is-dark',
        duration: 3500,
      });
    } else if (state.error) {
      Toast.open({
        message: `Something went wrong. Try again.`,
        position: 'is-top',
        type: 'is-danger',
        duration: 3500,
      });
    }
    this.getGripAnalysisDetails();
  }

  @Watch('createGripAnalysisStrategyState')
  public watchCreateGripAnalysisStrategyState(state: ApiState): void {
    if (state.success) {
      this.updatedItem = {
        item: GripItemType.AnalysisStrategy,
        id: this.createdGripAnalysisStrategy.id,
        prevItemId: null,
      };
      Toast.open({
        message: `GRiP strategy added successfully.`,
        position: 'is-top',
        type: 'is-dark',
        duration: 3500,
      });
    } else if (state.error) {
      Toast.open({
        message: `Something went wrong. Try again.`,
        position: 'is-top',
        type: 'is-danger',
        duration: 3500,
      });
    }
    this.getGripAnalysisDetails();
  }

  @Watch('updateGripAnalysisStrategyState')
  public watchUpdateGripAnalysisStrategyState(state: ApiState): void {
    if (state.success) {
      Toast.open({
        message: `GRiP analysis strategy updated successfully.`,
        position: 'is-top',
        type: 'is-dark',
        duration: 3500,
      });
    } else if (state.error) {
      Toast.open({
        message: `Something went wrong. Try again.`,
        position: 'is-top',
        type: 'is-danger',
        duration: 3500,
      });
    }
    this.getGripAnalysisDetails();
  }

  @Watch('deleteGripAnalysisStrategyState')
  public watchDeleteGripAnalysisStrategyState(state: ApiState): void {
    if (state.success) {
      Toast.open({
        message: `GRiP analysis strategy deleted successfully.`,
        position: 'is-top',
        type: 'is-dark',
        duration: 3500,
      });
    } else if (state.error) {
      Toast.open({
        message: `Something went wrong. Try again.`,
        position: 'is-top',
        type: 'is-danger',
        duration: 3500,
      });
    }
    this.getGripAnalysisDetails();
  }
}
