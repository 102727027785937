

































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { ToastProgrammatic as Toast } from 'buefy';
import {
  AuditCriteria,
  AuditDetail,
  DataCollectionActivityLogRequestPayload,
} from '@/store/modules/audits/types/audits.types';
import DeleteRecordModal from '@/views/Audit/components/DeleteRecordModal.vue';
import CriteriaFilter from '@/views/Audit/components/CriteriaFilter.vue';
import BasePagination from '@/components/base/BasePagination.vue';
import DataCollectionActivityLog from '@/views/Audit/components/DataCollectionActivityLog.vue';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { getStartDateTime, getEndDateTime } from '../../../utils/date.util';

const dirtyMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

@Component({
  components: {
    DataCollectionActivityLog,
    BasePagination,
    CriteriaFilter,
    DeleteRecordModal,
  },
})
export default class DataHistoryTab extends Vue {
  @Prop() public selectedSites!: number[];
  @Prop() public auditId!: number;
  @Prop() public auditDetail!: AuditDetail;
  @Prop({ default: true }) public editable!: boolean;
  @Prop({ default: false }) public isDataCollectionActive!: boolean;
  public page: number = 1;
  public perPage: number = 50;
  public totalCount: number = 0;
  public noSiteSelectedText: string = 'No site selected';
  public noCriteriaSelectedText: string = 'No criteria selected';
  public noneSelectedText: string = 'No site and criteria selected';
  public noDataAvailableText: string = 'No data history found';
  public dirtyToDate: Date =
    this.auditDetail && this.auditDetail.endDate
      ? getEndDateTime(this.auditDetail.endDate)
      : getEndDateTime();

  public dirtyFromDate: Date =
    this.auditDetail && this.auditDetail.startDate
      ? getStartDateTime(this.auditDetail.startDate)
      : getStartDateTime(
          new Date(
            this.dirtyToDate.getFullYear(),
            this.dirtyToDate.getMonth(),
            this.dirtyToDate.getDate() - 7,
          ),
        );
  public dirtyMinDate: Date = this.dirtyFromDate;
  public filteredCriteriaIds: number[] = [];
  public dirtyDataCollectionActivityLogs: any = [];
  public dataCollectionActivityLogsKey: number = Math.floor(
    Math.random() * 999,
  ); // Adding component key to initiate seamless refresh dataCollectionActivityLogs list

  @Action('audits/getAuditCriteria')
  public getAuditCriteria!: (auditId: number) => void;

  @State((state) => state.audits.auditCriteria)
  public auditCriteria!: AuditCriteria[];

  @Action('audits/deleteDataCollectionRecord')
  public deleteDataCollectionRecord!: (payload: {
    auditId: number;
    dataCollectionRecordId: number;
  }) => void;

  @State((state) => state.audits.deletedDataRecord)
  public deletedDataCollectionRecord!: any;

  @Action('audits/getDataCollectionActivityLogs')
  public getDataCollectionActivityLogs!: (
    payload: DataCollectionActivityLogRequestPayload,
  ) => void;

  @State((state) => state.audits.dataCollectionActivityLogs)
  public dataCollectionActivityLogs!: any;

  get emptyDataText(): string {
    if (!this.selectedSites.length && !this.filteredCriteriaIds.length) {
      return this.noneSelectedText;
    } else if (!this.selectedSites.length) {
      return this.noSiteSelectedText;
    } else if (!this.filteredCriteriaIds.length) {
      return this.noCriteriaSelectedText;
    } else if (
      !this.dataCollectionActivityLogs ||
      !this.dataCollectionActivityLogs.length
    ) {
      return this.noDataAvailableText;
    } else {
      return '';
    }
  }

  @Watch('dataCollectionActivityLogs')
  @isTruthy
  public watchDataCollectionActivityLogs() {
    this.dataCollectionActivityLogs.map((data: any) => {
      const dataRecord = data.dataCollectionRecords.find(
        (item: any) => item.auditCriteria.isActive,
      );
      data.isActive = dataRecord ? true : false;
    });
    this.dirtyDataCollectionActivityLogs = this.dataCollectionActivityLogs.slice(
      0,
      this.perPage,
    );
    this.totalCount = this.dataCollectionActivityLogs.length;
    this.dataCollectionActivityLogsKey += 1;
  }

  @Watch('deletedDataCollectionRecord')
  @isTruthy
  public watchDeletedDataCollectionRecord(newValue: any) {
    if (newValue.isDeleted) {
      Toast.open({
        message: `Data collection record deleted successfully.`,
        position: 'is-top',
        type: 'is-dark',
        duration: 3500,
      });
      this.filterDataHistory();
    }
  }

  public mounted() {
    this.getAuditCriteria(this.auditId);
  }

  get criteriaList() {
    return this.auditCriteria;
  }

  public applyCriteriaFilter(newValue: number[]) {
    this.filteredCriteriaIds = newValue;
    if (newValue.length === 0) {
      this.dirtyDataCollectionActivityLogs = [];
    } else {
      this.filterDataHistory();
    }
  }

  public formatDate(date: Date) {
    return this.getDate(date);
  }

  public getDate(newValue: Date) {
    return (
      newValue.getDate() +
      ' ' +
      dirtyMonths[newValue.getMonth()] +
      ' ' +
      newValue.getFullYear()
    );
  }

  public handleFromDateChange(newValue: Date) {
    const nowDate = new Date();
    this.dirtyFromDate = new Date(
      new Date(
        new Date(this.dirtyFromDate.setHours(nowDate.getHours())).setMinutes(
          nowDate.getMinutes(),
        ),
      ).setSeconds(nowDate.getSeconds()),
    );
    this.filterDataHistory();
  }

  public handleToDateChange(newValue: Date) {
    const nowDate = new Date();
    this.dirtyToDate = new Date(
      new Date(
        new Date(this.dirtyToDate.setHours(nowDate.getHours())).setMinutes(
          nowDate.getMinutes(),
        ),
      ).setSeconds(nowDate.getSeconds()),
    );
    this.filterDataHistory();
  }

  public filterDataHistory() {
    const payload: DataCollectionActivityLogRequestPayload = {
      auditId: this.auditId,
      fromDate: getStartDateTime(this.dirtyFromDate),
      toDate: getEndDateTime(this.dirtyToDate),
      siteIds: this.selectedSites,
      criteriaIds: this.filteredCriteriaIds,
    };
    if (this.selectedSites.length > 0 && this.filteredCriteriaIds.length > 0) {
      this.getDataCollectionActivityLogs(payload);
    }
  }

  public editDataCollectionRecord(rowData: any) {
    this.$emit('editDataCollectionRecord', rowData);
  }

  get totalNumberOfPage(): number {
    return Math.ceil(this.totalCount / this.perPage);
  }

  get startItemIndex(): number {
    return this.page * this.perPage - this.perPage + 1;
  }

  get endItemIndex(): number {
    return Math.min(this.totalCount, this.page * this.perPage);
  }

  get isFirstPage(): boolean {
    return this.page === 1;
  }

  get isLastPage(): boolean {
    return this.page === this.totalNumberOfPage;
  }

  get getCurrentPage(): number {
    return this.page;
  }

  set getCurrentPage(newValue: number) {
    this.page = Number(newValue);
    this.dirtyDataCollectionActivityLogs = this.dataCollectionActivityLogs.slice(
      this.startItemIndex - 1,
      this.endItemIndex,
    );
  }

  get getCurrentPerPage(): number {
    return this.perPage;
  }

  set getCurrentPerPage(newValue: number) {
    this.perPage = newValue;
    this.dirtyDataCollectionActivityLogs = this.dataCollectionActivityLogs.slice(
      0,
      newValue,
    );
  }

  public handleDeleteDataCollectionRecord(rowData: any) {
    return this.$buefy.modal.open({
      parent: this,
      component: DeleteRecordModal,
      hasModalCard: true,
      trapFocus: true,
      events: {
        deleteRecord: () => {
          this.deleteDataCollectionRecord({
            auditId: this.auditId,
            dataCollectionRecordId: rowData.id,
          });
        },
      },
    });
  }

  @Watch('selectedSites')
  public watchSelectedSites() {
    if (this.selectedSites.length === 0) {
      this.dirtyDataCollectionActivityLogs = [];
    } else {
      this.filterDataHistory();
    }
  }

  @Watch('auditCriteria')
  public watchAuditCriteria() {
    this.filteredCriteriaIds = this.auditCriteria
      ? this.auditCriteria.map((auditCriteria: AuditCriteria) => {
          return auditCriteria.id;
        })
      : [];
    this.filterDataHistory();
  }
}
