var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dataCollectionRecord && _vm.auditDetail)?_c('DefaultLayout',[_c('vue-headful',{attrs:{"title":_vm.getPageTitle('Data Collection')}}),(_vm.auditCriteria && _vm.auditSites && _vm.auditConfig)?_c('div',{key:_vm.currentComponentKey,staticClass:"container",class:_vm.$style.container},[_c('nav',{staticClass:"breadcrumb has-succeeds-separator m-t-1",attrs:{"aria-label":"breadcrumbs"}},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":_vm.getProjectPath(_vm.auditDetail.project.id)}},[_vm._v(" "+_vm._s(_vm.getFormattedTitle(_vm.auditDetail.project.title))+" ")])],1),_c('li',[_c('router-link',{attrs:{"to":_vm.getAuditPath(_vm.auditDetail.id)}},[_vm._v(" "+_vm._s(_vm.getFormattedTitle(_vm.auditDetail.name))+" ")])],1),_c('li',{staticClass:"is-active"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v(" "+_vm._s(_vm.breadcrumbTitle)+" ")])],1)])]),_c('div',{class:_vm.$style.titleSection},[_c('h1',{class:['oswald-font', _vm.$style.title]},[_vm._v(" "+_vm._s(_vm.newEntryTitle ? 'Submit New Record' : 'Edit Record')+" ")]),(_vm.newEntryTitle)?_c('b-field',[_c('p',{staticClass:"control"},[_c('b-button',{class:[
              _vm.$style.button,
              _vm.dataCollectionType === 'aggregate' && _vm.$style.buttonActive ],on:{"click":function($event){return _vm.switchMode('aggregate')}}},[_c('img',{attrs:{"src":require(_vm.dataCollectionType === 'aggregate'
                  ? '@/assets/icons/ic_aggregated_white.png'
                  : '@/assets/icons/ic_aggregated_grey.png')}}),_vm._v(" Aggregate ")])],1),_c('p',{staticClass:"control"},[_c('b-button',{class:[
              _vm.$style.button,
              _vm.dataCollectionType === 'individual' && _vm.$style.buttonActive ],on:{"click":function($event){return _vm.switchMode('individual')}}},[_c('img',{attrs:{"src":require(_vm.dataCollectionType === 'individual'
                  ? '@/assets/icons/ic_individual_white.png'
                  : '@/assets/icons/ic_individual_grey.png')}}),_vm._v(" Individual ")])],1)]):_c('div',{staticClass:"buttons has-addons"},[(_vm.isPreviousEnabled)?_c('button',{staticClass:"button",class:_vm.$style.linkButton,on:{"click":_vm.redirectToPrevious}},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-chevron-left"})]),_c('span',[_vm._v("Prev")])]):_vm._e(),(_vm.isNextEnabled)?_c('button',{staticClass:"button",class:_vm.$style.linkButton,on:{"click":_vm.redirectToNext}},[_c('span',[_vm._v("Next")]),_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-chevron-right"})])]):_vm._e()])],1),_c('div',{class:_vm.$style.dataContainer},[_c('p',{class:_vm.$style.title},[_vm._v(_vm._s(_vm.auditDetail.project.title))]),_c('p',{class:_vm.$style.name},[_vm._v(_vm._s(_vm.auditDetail.name))]),_c('p',{class:_vm.$style.type},[_c('img',{class:_vm.$style.icon,attrs:{"src":require(_vm.dataCollectionType === 'aggregate'
              ? '@/assets/icons/ic_aggregated_grey.png'
              : '@/assets/icons/ic_individual_grey.png')}}),_c('span',[_vm._v(_vm._s(_vm.dataCollectionType))])]),(_vm.dataCollectionType === 'aggregate' && _vm.renderComponent)?[(_vm.defaultSiteId !== undefined)?_c('DataCollectionAggregate',{attrs:{"auditDetail":_vm.auditDetail,"editDataCollectionRecord":_vm.editDataCollectionRecord,"defaultSiteId":_vm.defaultSiteId,"siteList":_vm.siteList,"auditCriteriaList":_vm.auditCriteriaList,"auditConfig":_vm.auditConfig,"isEdit":true},on:{"submitRecords":_vm.handleEnteredDataRecords,"updateSiteDropdownItem":_vm.handleSiteDropdownItem}}):_vm._e()]:_vm._e(),(_vm.dataCollectionType === 'individual' && _vm.renderComponent)?[(_vm.defaultSiteId !== undefined)?_c('DataCollectionIndividual',{attrs:{"auditDetail":_vm.auditDetail,"editDataCollectionRecord":_vm.editDataCollectionRecord,"defaultSiteId":_vm.defaultSiteId,"siteList":_vm.siteList,"auditCriteriaList":_vm.auditCriteriaList,"auditConfig":_vm.auditConfig,"isEdit":true},on:{"submitRecords":_vm.handleEnteredDataRecords,"updateSiteDropdownItem":_vm.handleSiteDropdownItem}}):_vm._e()]:_vm._e()],2)]):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }