
































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Site } from '@/store/modules/projects/types/projects.types';
import SiteModal from '@/views/Project/components/SiteModal.vue';
import { ProjectSite } from '../../../store/modules/projects/types/projects.types';
import { Action, State } from 'vuex-class';
import { RootState } from '../../../store/store';
import { ApiState } from '../../../store/types/general.types';
import { isTruthy } from '../../../../src/jbi-shared/util/watcher.vue-decorator';
import { cloneData } from '@/utils/general.util';
@Component
export default class SiteAutoCompleteInput extends Vue {
  @Prop({ default: 0 }) public selectedSiteIndex!: number;
  @Prop({ default: true }) public editable!: boolean;
  @Prop() public sites!: Site[];
  @Prop() public localSelectedSiteArray!: Site[];

  public name: string = '';
  public address: string | undefined = '';
  public description: string | undefined = '';
  public selected: string | null = 'Select site';
  public localSitesList: Site[] = [];
  public temporarySelectedSiteArray: Site[] = [];

  @Action('projects/createSite')
  public createSite!: (newSite: Site) => void;

  @State((state: RootState) => state.projects.apiState.createSite)
  public createSiteState!: ApiState;

  @State((state: RootState) => state.projects.createdSite)
  public createdSite!: Site;

  @State((state) => state.projects.projectSites)
  public projectSites!: ProjectSite[];

  get isSiteDeleteEnable(): boolean {
    const filteredSites: Site[] = this.localSelectedSiteArray.filter(
      (site) => site.name !== '',
    );
    return filteredSites.length > 1 ? true : false;
  }

  public mounted() {
    this.localSitesList = cloneData(this.sites);
    const selectedSite: Site = this.localSelectedSiteArray[
      this.selectedSiteIndex
    ];
    if (selectedSite !== undefined) {
      this.name = selectedSite.name;
      this.address = selectedSite.address;
      this.description = selectedSite.description;
      if (selectedSite.name !== '') {
        this.selected = selectedSite.name;
      }
      const localSelectedSiteId: number[] = this.localSelectedSiteArray.map(
        (obj) => obj.id,
      );
      this.localSitesList = this.localSitesList.filter(
        (x) => !localSelectedSiteId.includes(x.id),
      );
    }
  }

  public showAddSitePopup(siteDetail: Site) {
    siteDetail = {
      id: 0,
      name: '',
      address: '',
      description: '',
      isIdentifierRequired: false,
    };

    this.$buefy.modal.open({
      parent: this,
      component: SiteModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle: 'Add Site',
        siteDetail,
      },
      events: {
        addNewSite: (newSite: Site) => {
          this.createSite(newSite);
        },
      },
    });
  }

  public optionSelect(selectedOption: Site) {
    this.selected = selectedOption.name;
    this.temporarySelectedSiteArray = cloneData(this.localSelectedSiteArray);
    const selectedSiteObject: Site | undefined = this.sites.find(
      (site) => site.id === selectedOption.id,
    );

    const existingProjectSite = this.projectSites.find(
      (site: ProjectSite) => site.site.id === selectedOption.id,
    );

    if (selectedSiteObject) {
      this.temporarySelectedSiteArray[this.selectedSiteIndex] = {
        id: selectedSiteObject.id,
        name: selectedSiteObject.name,
        address: selectedSiteObject.address,
        description: selectedSiteObject.description,
        isIdentifierRequired: existingProjectSite
          ? existingProjectSite.isIdentifierRequired
          : selectedOption.isIdentifierRequired,
      };
    } else {
      this.temporarySelectedSiteArray[this.selectedSiteIndex] = {
        id: selectedOption.id,
        name: selectedOption.name,
        address: selectedOption.address,
        description: selectedOption.description,
        isIdentifierRequired: existingProjectSite
          ? existingProjectSite.isIdentifierRequired
          : selectedOption.isIdentifierRequired,
      };
    }
    this.$emit('updateSelectedSiteArray', this.temporarySelectedSiteArray);
  }

  public removeSite() {
    this.$emit('removeSelectedSite', this.selectedSiteIndex);
  }

  @Watch('createSiteState.success')
  @isTruthy
  public onCreateSiteStateSuccess() {
    const selectedNewSiteIndex = this.localSelectedSiteArray.findIndex(
      (site) => site.id === 0,
    );
    this.localSelectedSiteArray[selectedNewSiteIndex] = this.createdSite;
    this.temporarySelectedSiteArray = cloneData(this.localSelectedSiteArray);
    this.$emit('updateSelectedSiteArray', this.temporarySelectedSiteArray);
  }
}
