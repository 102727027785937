import { ProjectSite } from '@/store/modules/projects/types/projects.types';
import { cloneDeep as _cloneDeep, get as _get, map as _map } from 'lodash';
import { CRITERION_TYPE } from '@/store/types/criterions.types';
import dayjs from 'dayjs';

export class GenerateSingleSiteComparisonExcel {
  public dirtyMonths: string[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];
  public filterRowsCount: number = 5;

  public create(
    result: any,
    payload: any,
    analysisTitle: string,
    projectTitle: string,
    projectSites: ProjectSite[],
  ) {
    const exportDateTime = dayjs().format('D MMM YYYY, HH:mm A');
    const responseArray: any[] = [];
    responseArray.push({
      cellInfo: [
        {
          name: 'A1',
          bgColor: 'EFEFEF',
          border: true,
          bold: true,
          wrapText: true,
          alignment: 'left',
        },
        {
          name: 'B1',
          bgColor: 'FFFFFF',
          border: true,
          bold: false,
          wrapText: true,
          alignment: 'left',
        },
      ],
      data: ['Project Name', projectTitle],
    });
    responseArray.push({
      cellInfo: [
        {
          name: 'A2',
          bgColor: 'EFEFEF',
          border: true,
          bold: true,
          wrapText: true,
          alignment: 'left',
        },
        {
          name: 'B2',
          bgColor: 'FFFFFF',
          border: true,
          bold: false,
          wrapText: true,
          alignment: 'left',
        },
      ],
      data: ['Analysis Name', analysisTitle],
    });
    responseArray.push({
      cellInfo: [
        {
          name: 'A3',
          bgColor: 'EFEFEF',
          border: true,
          bold: true,
          wrapText: true,
          alignment: 'left',
        },
        {
          name: 'B3',
          bgColor: 'FFFFFF',
          border: true,
          bold: false,
          wrapText: true,
          alignment: 'left',
        },
      ],
      data: ['Site', projectSites[0].site.name],
    });
    const comparisonPeriods = this.getPeriods(payload.comparisonPeriods);
    responseArray.push({
      cellInfo: [
        {
          name: 'A4',
          bgColor: 'EFEFEF',
          border: true,
          bold: true,
          wrapText: true,
          alignment: 'left',
        },
        {
          name: 'B4',
          bgColor: 'FFFFFF',
          border: true,
          bold: false,
          wrapText: true,
          alignment: 'left',
        },
      ],
      data: [
        'Data Collection Periods',
        this.transformPeriods(comparisonPeriods).join('\n'),
      ],
    });
    responseArray.push(
      ...this.generateCriteriaTables(
        result,
        payload,
        projectSites,
        comparisonPeriods,
        this.filterRowsCount,
      ),
    );
    responseArray.push({
      cellInfo: [
        {
          name: 'A' + (responseArray.length + 1),
          isEmpty: true,
        },
      ],
      data: [''],
    });
    responseArray.push({
      cellInfo: [
        {
          name: 'A' + (responseArray.length + 1),
          isEmpty: true,
        },
      ],
      data: ['Exported on ' + exportDateTime],
    });
    return responseArray;
  }

  public generateCriteriaTables(
    result: any,
    payload: any,
    projectSites: ProjectSite[],
    comparisonPeriods: string[],
    rowsCount: number,
  ): any {
    const criteriaStatistics: any[] = [];
    const statisticData: any[][] = [];
    const samplingConfigurationData: any[] = [];
    _map(result.comparisonResult, (comparisonResult: any) => {
      const dirtyStatisticData =
        comparisonResult.statisticData.configurationData;
      statisticData.push(dirtyStatisticData);
      samplingConfigurationData.push(
        comparisonResult.statisticData.samplingConfiguration,
      );
    });
    const {
      comparisonAggregateCriteriaConfiguration,
    } = this.generateComparisonConfigurationData(
      statisticData,
      payload,
      samplingConfigurationData,
    );
    comparisonAggregateCriteriaConfiguration.forEach(
      (criteriaDetails, criteriaDetailsIndex) => {
        criteriaStatistics.push(
          ...[
            {
              cellInfo: [
                {
                  name: 'A' + rowsCount,
                  isEmpty: true,
                },
              ],
              data: [''],
            },
            {
              cellInfo: [
                {
                  name: 'A' + (rowsCount + 1),
                  isEmpty: true,
                },
              ],
              data: [''],
            },
            {
              cellInfo: [
                {
                  name: 'A' + (rowsCount + 2),
                  isEmpty: true,
                },
              ],
              data: [''],
            },
          ],
        );
        rowsCount += 3;
        criteriaStatistics.push({
          cellInfo: [
            {
              name: 'A' + rowsCount,
              bgColor: 'D3F1FC',
              border: true,
              bold: true,
              wrapText: true,
              alignment: 'left',
              mergeInfo:
                'A' +
                rowsCount +
                ':' +
                this.getColumnName(comparisonPeriods.length + 1) +
                rowsCount,
            },
          ],
          data: [criteriaDetailsIndex + 1 + '. ' + criteriaDetails[0].title],
        });
        rowsCount += 1;
        criteriaStatistics.push({
          cellInfo: [
            {
              name: 'A' + rowsCount,
              bgColor: 'EFEFEF',
              border: true,
              bold: true,
              wrapText: true,
              alignment: 'left',
            },
            {
              name: 'B' + rowsCount,
              bgColor: 'EFEFEF',
              border: true,
              bold: true,
              wrapText: true,
              alignment: 'center',
              mergeInfo:
                'B' +
                rowsCount +
                ':' +
                this.getColumnName(comparisonPeriods.length + 1) +
                rowsCount,
            },
          ],
          data: ['Answer Choices', projectSites[0].site.name],
        });
        rowsCount += 1;
        const periodRow: any = [''];
        const periodRowCellInfo: any = [
          {
            name: 'A' + rowsCount,
            bgColor: 'EFEFEF',
            border: true,
            bold: true,
            wrapText: true,
            alignment: 'left',
          },
        ];
        comparisonPeriods.forEach((period: string, periodIndex: number) => {
          periodRow.push(period.split(':').join('\n'));
          periodRowCellInfo.push({
            name: this.getColumnName(periodIndex + 2) + rowsCount,
            bgColor: 'EFEFEF',
            border: true,
            bold: true,
            wrapText: true,
            alignment: 'right',
          });
        });
        criteriaStatistics.push({
          cellInfo: periodRowCellInfo,
          data: periodRow,
        });
        rowsCount += 1;
        const optionsList: string[] = [];
        const totalList: number[] = [];
        const complianceList: number[] = [];
        const valuesList: any[] = [];
        const trueValueList: number[][] = [];
        const naValueList: number[][] = [];
        const samplingStatistics: any = [];
        criteriaDetails.forEach(
          (criteriaDetailPeriod: any, criteriaDetailPeriodIndex: number) => {
            let total: number = 0;
            let trueValue: number = 0;
            let naValue: number = 0;
            criteriaDetailPeriod.criteriaSamplingData.forEach(
              (optionsData: any, optionDataIndex: number) => {
                const option = Object.keys(optionsData)[0];
                if (!optionsList.includes(option)) {
                  optionsList.push(option);
                }
                switch (optionDataIndex) {
                  case 0:
                    trueValue = optionsData[option];
                    break;
                  case 1:
                    break;
                  default:
                    naValue = optionsData[option];
                }
                total += optionsData[option];
                if (valuesList[criteriaDetailPeriodIndex]) {
                  valuesList[criteriaDetailPeriodIndex] = {
                    ...valuesList[criteriaDetailPeriodIndex],
                    ...optionsData,
                  };
                } else {
                  valuesList[criteriaDetailPeriodIndex] = optionsData;
                }
              },
            );
            totalList.push(total);
            if (trueValueList[criteriaDetailPeriodIndex]) {
              trueValueList[criteriaDetailPeriodIndex].push(trueValue);
            } else {
              trueValueList[criteriaDetailPeriodIndex] = [trueValue];
            }
            if (naValueList[criteriaDetailPeriodIndex]) {
              naValueList[criteriaDetailPeriodIndex].push(naValue);
            } else {
              naValueList[criteriaDetailPeriodIndex] = [naValue];
            }
            if (criteriaDetailPeriod.criteriaType === CRITERION_TYPE.BOOLEAN) {
              const compliance = (trueValue / (total - naValue)) * 100;
              complianceList.push(compliance ? compliance : 0);
            }
            samplingStatistics.push(
              this.generateSamplingTable(
                criteriaDetailPeriod,
                criteriaDetailPeriodIndex,
                payload.comparisonPeriods,
                comparisonPeriods,
              ),
            );
          },
        );
        optionsList.forEach((optionName: string, optionNameIndex: number) => {
          const rowObject: any = [optionName];
          const rowObjectCellInfo: any = [
            {
              name: 'A' + rowsCount,
              bgColor: 'FFFFFF',
              border: true,
              bold: false,
              wrapText: true,
              alignment: 'left',
            },
          ];
          comparisonPeriods.forEach((period: any, periodIndex: number) => {
            const optionValue: number = valuesList[periodIndex][optionName]
              ? valuesList[periodIndex][optionName]
              : 0;
            rowObject.push(optionValue);
            rowObjectCellInfo.push({
              name: this.getColumnName(periodIndex + 2) + rowsCount,
              bgColor: 'FFFFFF',
              border: true,
              bold: false,
              wrapText: true,
              alignment: 'right',
            });
          });
          criteriaStatistics.push({
            cellInfo: rowObjectCellInfo,
            data: rowObject,
          });
          rowsCount += 1;
        });
        const totalRow: any = ['Total Data Collected'];
        const totalRowCellInfo: any = [
          {
            name: 'A' + rowsCount,
            bgColor: 'FFFFFF',
            border: true,
            bold: true,
            wrapText: true,
            alignment: 'left',
          },
        ];
        totalList.forEach((total: number, totalIndex: number) => {
          let formattedTotal;
          if (total.toString().includes('-')) {
            formattedTotal = '-';
          } else {
            formattedTotal = total;
          }
          totalRow.push(formattedTotal);
          totalRowCellInfo.push({
            name: this.getColumnName(totalIndex + 2) + rowsCount,
            bgColor: 'FFFFFF',
            border: true,
            bold: true,
            wrapText: true,
            alignment: 'right',
          });
        });
        criteriaStatistics.push({
          cellInfo: totalRowCellInfo,
          data: totalRow,
        });
        rowsCount += 1;
        if (criteriaDetails[0].criteriaType === CRITERION_TYPE.BOOLEAN) {
          const complianceRow: any = ['Compliance'];
          const complianceRowCellInfo: any = [
            {
              name: 'A' + rowsCount,
              bgColor: 'FFFFFF',
              border: true,
              bold: true,
              wrapText: true,
              alignment: 'left',
            },
          ];
          complianceList.forEach(
            (compliance: number, complianceIndex: number) => {
              complianceRow.push(Math.round(compliance * 100) / 100 + '%');
              complianceRowCellInfo.push({
                name: this.getColumnName(complianceIndex + 2) + rowsCount,
                bgColor: 'FFFFFF',
                border: true,
                bold: true,
                wrapText: true,
                alignment: 'right',
              });
            },
          );
          criteriaStatistics.push({
            cellInfo: complianceRowCellInfo,
            data: complianceRow,
          });
          rowsCount += 1;
        }
        // sampling table
        criteriaStatistics.push({
          cellInfo: [
            {
              name: 'A' + rowsCount,
              isEmpty: true,
            },
          ],
          data: [''],
        });
        rowsCount += 1;
        criteriaStatistics.push({
          cellInfo: [
            {
              name: 'A' + rowsCount,
              bgColor: 'FFFFFF',
              border: true,
              bold: true,
              wrapText: true,
              alignment: 'left',
              mergeInfo:
                'A' +
                rowsCount +
                ':' +
                this.getColumnMergeName(criteriaDetails) +
                rowsCount,
            },
          ],
          data: ['Sampling'],
        });
        rowsCount += 1;
        let samplingPeriodRowCount: number = 1;
        let samplingTypeRowCount: number = 1;
        let samplingDataRowCount: number = 1;
        const samplingPeriodRow: any = [];
        const samplingPeriodRowCellInfo: any = [];
        const samplingTypeRow: any = [];
        const samplingTypeRowCellInfo: any = [];
        const samplingDataRow: any = [];
        const samplingDataRowCellInfo: any = [];
        samplingStatistics.forEach(
          (samplingInfo: any, samplingStatisticsIndex: number) => {
            samplingInfo[0].data.forEach(
              (value: string, valueIndex: number) => {
                samplingPeriodRow.push(value);
                const cellInfo = {
                  name: this.getColumnName(samplingPeriodRowCount) + rowsCount,
                  bgColor: 'EFEFEF',
                  border: true,
                  bold: true,
                  wrapText: true,
                  alignment: 'left',
                  mergeInfo: '',
                };
                if (valueIndex === 0) {
                  cellInfo.mergeInfo =
                    this.getColumnName(samplingPeriodRowCount) +
                    rowsCount +
                    ':' +
                    this.getColumnName(
                      samplingPeriodRowCount + samplingInfo[0].data.length - 1,
                    ) +
                    rowsCount;
                }
                samplingPeriodRowCellInfo.push(cellInfo);
                samplingPeriodRowCount += 1;
              },
            );
          },
        );
        criteriaStatistics.push({
          cellInfo: samplingPeriodRowCellInfo,
          data: samplingPeriodRow,
        });
        rowsCount += 1;
        samplingStatistics.forEach(
          (samplingInfo: any, samplingStatisticsIndex: number) => {
            samplingInfo[1].data.forEach(
              (value: string, valueIndex: number) => {
                samplingTypeRow.push(value);
                samplingTypeRowCellInfo.push({
                  name: this.getColumnName(samplingTypeRowCount) + rowsCount,
                  bgColor: 'EFEFEF',
                  border: true,
                  bold: true,
                  wrapText: true,
                  alignment: valueIndex === 0 ? 'left' : 'right',
                });
                samplingTypeRowCount += 1;
              },
            );
          },
        );
        criteriaStatistics.push({
          cellInfo: samplingTypeRowCellInfo,
          data: samplingTypeRow,
        });
        rowsCount += 1;
        samplingStatistics.forEach(
          (samplingInfo: any, samplingStatisticsIndex: number) => {
            samplingInfo[2].data.forEach(
              (value: string, valueIndex: number) => {
                samplingDataRow.push(value);
                samplingDataRowCellInfo.push({
                  name: this.getColumnName(samplingDataRowCount) + rowsCount,
                  bgColor: 'FFFFFF',
                  border: true,
                  bold: false,
                  wrapText: true,
                  alignment: valueIndex === 0 ? 'left' : 'right',
                });
                samplingDataRowCount += 1;
              },
            );
          },
        );
        criteriaStatistics.push({
          cellInfo: samplingDataRowCellInfo,
          data: samplingDataRow,
        });
        rowsCount += 1;
      },
    );
    return criteriaStatistics;
  }

  public getColumnMergeName(criteriaDetails: any[]) {
    let count: number = 0;
    criteriaDetails.forEach((criteriaDetail: any) => {
      switch (criteriaDetail.samplingConfiguration.auditSamplingType) {
        case 'consecutive':
          if (
            criteriaDetail.samplingConfiguration.samplingMode === 'minAndMax'
          ) {
            count += 3;
          } else {
            count += 2;
          }
          break;
        default:
          count += 2;
      }
    });
    return this.getColumnName(count);
  }

  public generateSamplingTable(
    criteriaDetailPeriod: any,
    criteriaDetailPeriodIndex: number,
    periodsInfo: any,
    formattedPeriods: string[],
  ): any {
    const isCustomPeriod: boolean = !periodsInfo[criteriaDetailPeriodIndex]
      .isAudit;
    const samplingStatistics: any = [];
    if (isCustomPeriod) {
      samplingStatistics.push({
        data: [
          formattedPeriods[criteriaDetailPeriodIndex].split(':').join('\n'),
          '',
        ],
      });
      samplingStatistics.push({
        data: ['Site', 'Sampling'],
      });
      samplingStatistics.push({
        data: [
          criteriaDetailPeriod.siteSamplingConfiguration[0].auditSiteMap.site
            .name,
          '-',
        ],
      });
    } else {
      switch (criteriaDetailPeriod.samplingConfiguration.auditSamplingType) {
        case 'adHoc':
          samplingStatistics.push({
            data: [
              formattedPeriods[criteriaDetailPeriodIndex].split(':').join('\n'),
              '',
            ],
          });
          samplingStatistics.push({
            data: ['Site', 'Sampling'],
          });
          samplingStatistics.push({
            data: [
              criteriaDetailPeriod.siteSamplingConfiguration[0].auditSiteMap
                .site.name,
              criteriaDetailPeriod.criteriaSamplingDataConfiguration
                .isSamplingEnabled
                ? 'Enabled'
                : 'Disabled',
            ],
          });
          break;
        case 'consecutive':
          if (
            criteriaDetailPeriod.samplingConfiguration.samplingMode ===
            'minAndMax'
          ) {
            samplingStatistics.push({
              data: [
                formattedPeriods[criteriaDetailPeriodIndex]
                  .split(':')
                  .join('\n'),
                '',
                '',
              ],
            });
            samplingStatistics.push({
              data: ['Site', 'Min.', 'Max.'],
            });
            samplingStatistics.push({
              data: [
                criteriaDetailPeriod.siteSamplingConfiguration[0].auditSiteMap
                  .site.name,
                criteriaDetailPeriod.criteriaSamplingDataConfiguration
                  .minSampleSize,
                criteriaDetailPeriod.criteriaSamplingDataConfiguration
                  .maxSampleSize,
              ],
            });
          } else {
            samplingStatistics.push({
              data: [
                formattedPeriods[criteriaDetailPeriodIndex]
                  .split(':')
                  .join('\n'),
                '',
              ],
            });
            samplingStatistics.push({
              data: ['Site', 'Target'],
            });
            samplingStatistics.push({
              data: [
                criteriaDetailPeriod.siteSamplingConfiguration[0].auditSiteMap
                  .site.name,
                criteriaDetailPeriod.criteriaSamplingDataConfiguration
                  .sampleSize,
              ],
            });
          }
          break;
        default:
          samplingStatistics.push({
            data: [
              formattedPeriods[criteriaDetailPeriodIndex].split(':').join('\n'),
              '',
            ],
          });
          samplingStatistics.push({
            data: ['Site', 'Target'],
          });
          samplingStatistics.push({
            data: [
              criteriaDetailPeriod.siteSamplingConfiguration[0].auditSiteMap
                .site.name,
              criteriaDetailPeriod.criteriaSamplingDataConfiguration.sampleSize,
            ],
          });
      }
    }
    return samplingStatistics;
  }

  public generateComparisonConfigurationData(
    configurationData: any[],
    payload: any,
    samplingConfigurationData: any[],
  ) {
    let comparisonAggregateCriteriaConfiguration: any[];
    if (
      Array.isArray(configurationData) &&
      configurationData.length === payload.comparisonPeriods.length
    ) {
      const aggregateConfiguration: any[] = [];
      _map(payload.filteredCriterias, (criteriaId, p) => {
        const aggregateCriteriaConfiguration: any[] = [];
        _map(configurationData, (configData, index) => {
          let dirtyAggregateConfiguration: any = {};
          configData.forEach((data: any) => {
            if (data.projectCriteria.id === criteriaId) {
              const criteriaOptionsDataDistribution =
                data.criteriaSamplingData.criteriaOptionsDataDistribution;
              const criteriaSamplingData = Object.keys(
                criteriaOptionsDataDistribution,
              ).map((key: string) => ({
                [key]:
                  data.criteriaSamplingData.criteriaOptionsDataDistribution[
                    key
                  ],
              }));
              const dirtyConfig = {
                title: _get(data, 'projectCriteria.title', ''),
                criteriaType: _get(data, 'projectCriteria.criteriaType', ''),
                sampleSize: Number(
                  _get(
                    data,
                    'criteriaSamplingDataConfigurations.sampleSize',
                    0,
                  ),
                ),
                siteSamplingConfiguration: data.siteSamplingDataConfigurations,
                criteriaSamplingDataConfiguration:
                  data.criteriaSamplingDataConfigurations,
                samplingConfiguration: samplingConfigurationData[index],
                criteriaSamplingData,
                isAuditCriteria: data.isAuditCriteria,
              };
              dirtyAggregateConfiguration = _cloneDeep(dirtyConfig);
            }
          });
          aggregateCriteriaConfiguration.push(dirtyAggregateConfiguration);
        });
        aggregateConfiguration.push(aggregateCriteriaConfiguration);
      });
      comparisonAggregateCriteriaConfiguration = _cloneDeep(
        aggregateConfiguration,
      );
    } else {
      comparisonAggregateCriteriaConfiguration = [];
    }
    return { comparisonAggregateCriteriaConfiguration };
  }

  public getPeriods(comparisonPeriods: any): any {
    const periodsInfo: string[] = [];
    comparisonPeriods.forEach((periodInfo: any, periodIndex: number) => {
      periodsInfo.push(
        periodInfo.title + ': ' + this.formatDateRange(periodInfo),
      );
    });
    return periodsInfo;
  }

  public transformPeriods(comparisonPeriods: string[]): string[] {
    const transformedPeriods: string[] = [];
    comparisonPeriods.forEach((period: string, periodIndex: number) => {
      transformedPeriods.push(periodIndex + 1 + '. ' + period);
    });
    return transformedPeriods;
  }

  public formatDateRange(dateObject: any): string {
    const startDate = new Date(dateObject.startedAt);
    let endDate = dateObject.endedAt;
    if (endDate === null) {
      endDate = dayjs().endOf('day').format();
    }
    return this.getDate(startDate) + ' - ' + this.getDate(new Date(endDate));
  }

  public getDate(newValue: Date): string {
    return (
      newValue.getDate() +
      ' ' +
      this.dirtyMonths[newValue.getMonth()] +
      ' ' +
      newValue.getFullYear()
    );
  }

  public getColumnName(columnNumber: number): string {
    let temp;
    let columnLetter = '';
    while (columnNumber > 0) {
      temp = (columnNumber - 1) % 26;
      columnLetter = String.fromCharCode(temp + 65) + columnLetter;
      columnNumber = (columnNumber - temp - 1) / 26;
    }
    return columnLetter;
  }
}
