import { AxiosError } from 'axios';

export interface ApiState {
  loading: boolean;
  success: boolean;
  error: AxiosError | Error | null;
}

export const defaultApiState: ApiState = {
  loading: false,
  success: false,
  error: null,
};

export interface Pagination<T> {
  items: T[];
  itemCount: number;
  totalItems: number;
  pageCount: number;
  next?: string;
  previous?: string;
}

export interface PaginatorDto {
  perPage: number;
  page: number;
}

export interface TableSortDto {
  sortOrder: SortOrder;
  sortColumn: string;
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface PaginatedApiResponse<T> {
  total_count: number;
  incomplete_results: boolean;
  items: T[];
}

export interface PaginationQuery {
  sort?: string;
  order?: 'asc' | 'desc';
  page?: number;
  per_page?: number;
}

export const primaryChartColor: string = '#1D445E';

export const chartColorSet: string[] = [
  '#1D445E',
  '#5AB4BD',
  '#14848F',
  '#B4D63E',
  '#D4C34E',
  '#F58623',
  '#FFAE4A',
  '#C17716',
  '#5F548E',
  '#BE5492',
  '#029378',
  '#5B9C4A',
  '#A69A14',
  '#CC5945',
  '#BC4A6C',
  '#87891E',
  '#4E8E4A',
  '#19536D',
  '#814A80',
  '#0E7486',
  '#ED745B',
  '#006BA3',
  '#8070C3',
  '#EA657B',
];

export const monthsList: string[] = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

export const labelSplitIndex: number = 3;

export const reportExportType: string[] = ['PDF', 'Docx', 'CSV', 'XLSX'];

// tslint:disable-next-line:ban-types
export type AnyFunction = Function;
