var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.notificationDetail.length === 0)?_c('div',{class:_vm.$style.noNotification},[_c('p',{class:_vm.$style.noNotificationText},[_vm._v("No new notification")])]):_vm._e(),(_vm.notificationDetail.length > 0)?_c('div',_vm._l((_vm.nonViewedNotification),function(info,index){return _c('div',{key:index,class:_vm.$style.userInfo},[(
          info.type === 'ADD_NEW_PROJECT_OWNER' &&
          !info.isViewed &&
          info.email !== info.projectOwnerEmail &&
          !info.isReclaimProject
        )?_c('div',{class:_vm.$style.navigateNotification},[_c('div',{on:{"click":function($event){return _vm.navigateToNotifications()}}},[_c('p',{class:_vm.$style.userInfoDateTime},[_vm._v(" "+_vm._s(info.datetime)+" ")]),_c('p',{class:_vm.$style.userInfoPrimary},[_vm._v(" Project Administration Request ")]),_c('br'),_c('p',{class:_vm.$style.userInfoTertiary},[_vm._v(" "+_vm._s(info.notificationSenderName)+" requested for you to be the project administrator in "+_vm._s(info.projectName)+". ")]),_c('br')]),_c('button',{class:_vm.$style.acceptButton,on:{"click":function($event){return _vm.handleNotificationRespond(info, true, false)}}},[_vm._v(" Accept ")]),_c('button',{class:_vm.$style.declineButtton,on:{"click":function($event){return _vm.handleNotificationRespond(info, false, false)}}},[_vm._v(" Decline ")])]):_vm._e(),(
          info.type === 'ADD_NEW_PROJECT_OWNER' &&
          !info.isViewed &&
          info.email === info.projectOwnerEmail
        )?_c('div',{class:_vm.$style.navigateNotification},[_c('div',{on:{"click":function($event){return _vm.navigateToNotifications()}}},[_c('p',{class:_vm.$style.userInfoDateTime},[_vm._v(" "+_vm._s(info.datetime)+" ")]),_c('p',{class:_vm.$style.userInfoPrimary},[_vm._v(" Project Administration Change Request ")]),_c('br'),_c('p',{class:_vm.$style.userInfoTertiary},[_vm._v(" "+_vm._s(info.notificationSenderName)+" requested to be the project administrator for "+_vm._s(info.projectName)+". If you accept this request, "+_vm._s(_vm.getParticipantRole(info.payload.participantRole))+" ")])]),_c('button',{class:_vm.$style.acceptButton,on:{"click":function($event){return _vm.handleNotificationRespond(info, true, true)}}},[_vm._v(" Accept ")]),_c('button',{class:_vm.$style.declineButtton,on:{"click":function($event){return _vm.handleNotificationRespond(info, false, true)}}},[_vm._v(" Decline ")])]):_vm._e(),(
          info.type === 'UPDATE_ACCEPT_FORMER_PROJECT_OWNER' &&
          !info.payload.systemAdminId &&
          info.status === 'accept' &&
          !info.isReclaimProject
        )?_c('div',{class:_vm.$style.navigateNotification,on:{"click":function($event){return _vm.handleNavigateNotifications()}}},[_c('p',{class:_vm.$style.userInfoDateTime},[_vm._v(" "+_vm._s(info.datetime)+" ")]),_c('p',{class:_vm.$style.userInfoPrimary},[_vm._v(" Project Administration Request Accepted ")]),_c('br'),_c('p',{class:_vm.$style.userInfoTertiary},[_vm._v(" "+_vm._s(info.notificationSenderName)+" accepted your request to be the project administrator in "+_vm._s(info.projectName)+". ")])]):_vm._e(),(
          info.type === 'UPDATE_ACCEPT_FORMER_PROJECT_OWNER' &&
          info.payload.systemAdminId &&
          info.status === 'accept'
        )?_c('div',{class:_vm.$style.navigateNotification,on:{"click":function($event){return _vm.handleNavigateNotifications()}}},[_c('p',{class:_vm.$style.userInfoDateTime},[_vm._v(" "+_vm._s(info.datetime)+" ")]),_c('p',{class:_vm.$style.userInfoPrimary},[_vm._v("Project Administrator Changed")]),_c('br'),_c('p',{class:_vm.$style.userInfoTertiary},[_vm._v(" Project administrator for "+_vm._s(info.projectName)+" has been changed to "+_vm._s(info.notificationSenderName)+". ")])]):_vm._e(),(
          info.type === 'UPDATE_ACCEPT_PROJECT_OWNER' &&
          !info.isActionable &&
          info.status === 'accept' &&
          !info.isReclaimProject
        )?_c('div',{class:_vm.$style.navigateNotification},[_c('div',{on:{"click":function($event){return _vm.handleNavigateNotifications()}}},[_c('p',{class:_vm.$style.userInfoDateTime},[_vm._v(" "+_vm._s(info.datetime)+" ")]),_c('p',{class:_vm.$style.userInfoPrimary},[_vm._v(" You Are Now Project Administrator ")]),_c('br'),_c('p',{class:_vm.$style.userInfoTertiary},[_vm._v(" You are now the project administrator for "+_vm._s(info.projectName)+". ")])]),_c('div',{class:_vm.$style.viewProjectLinkStyle,on:{"click":function($event){return _vm.handleNavigateSingleNotification(info)}}},[_vm._v(" View Project ")])]):_vm._e(),(
          info.type === 'UPDATE_ACCEPT_RECLAIM_PROJECT' &&
          !info.payload.systemAdminId &&
          info.status === 'accept' &&
          info.isReclaimProject
        )?_c('div',{class:_vm.$style.navigateNotification},[_c('div',{on:{"click":function($event){return _vm.handleNavigateNotifications()}}},[_c('p',{class:_vm.$style.userInfoDateTime},[_vm._v(" "+_vm._s(info.datetime)+" ")]),_c('p',{class:_vm.$style.userInfoPrimary},[_vm._v(" Your Project Administration Change Request Has Been Accepted ")]),_c('br'),_c('p',{class:_vm.$style.userInfoTertiary},[_vm._v(" You are now the project administrator for "+_vm._s(info.projectName)+". ")])]),_c('div',{class:_vm.$style.viewProjectLinkStyle,on:{"click":function($event){return _vm.handleNavigateSingleNotification(info)}}},[_vm._v(" View Project ")])]):_vm._e(),(
          info.type === 'UPDATE_DECLINE_PROJECT_OWNER' &&
          info.status === 'decline' &&
          !info.isReclaimProject
        )?_c('div',{class:_vm.$style.navigateNotification,on:{"click":function($event){return _vm.handleNavigateNotifications()}}},[_c('p',{class:_vm.$style.userInfoDateTime},[_vm._v(" "+_vm._s(info.datetime)+" ")]),_c('p',{class:_vm.$style.userInfoPrimary},[_vm._v(" Project Administration Request Declined ")]),_c('br'),_c('p',{class:_vm.$style.userInfoTertiary},[_vm._v(" "+_vm._s(info.notificationSenderName)+" declined your request to be the project administrator in "+_vm._s(info.projectName)+". ")]),_c('router-link',{class:_vm.$style.descriptionLinkStyle,attrs:{"to":"/notification"}},[_vm._v(" View ")])],1):_vm._e(),(
          info.type === 'UPDATE_DECLINE_RECLAIM_PROJECT' &&
          !info.payload.systemAdminId &&
          info.status === 'decline' &&
          info.isReclaimProject
        )?_c('div',{class:_vm.$style.navigateNotification,on:{"click":function($event){return _vm.handleNavigateNotifications()}}},[_c('p',{class:_vm.$style.userInfoDateTime},[_vm._v(" "+_vm._s(info.datetime)+" ")]),_c('p',{class:_vm.$style.userInfoPrimary},[_vm._v(" Your Project Administration Change Request Has Been Declined ")]),_c('br'),_c('p',{class:_vm.$style.userInfoTertiary},[_vm._v(" "+_vm._s(info.notificationSenderName)+" will remain as the project administrator for "+_vm._s(info.projectName)+". ")]),_c('router-link',{class:_vm.$style.descriptionLinkStyle,attrs:{"to":"/notification"}},[_vm._v(" View ")])],1):_vm._e(),(
          info.type === 'UPDATE_CANCEL_PROJECT_OWNER' &&
          info.status === 'cancel' &&
          !info.isReclaimProject
        )?_c('div',{class:_vm.$style.navigateNotification,on:{"click":function($event){return _vm.handleNavigateNotifications()}}},[_c('p',{class:_vm.$style.userInfoDateTime},[_vm._v(" "+_vm._s(info.datetime)+" ")]),_c('p',{class:_vm.$style.userInfoPrimary},[_vm._v(" Project Administration Request Is Cancelled ")]),_c('br'),_c('p',{class:_vm.$style.userInfoTertiary},[_vm._v(" "+_vm._s(info.notificationSenderName)+" has cancelled the project administrator request for "+_vm._s(info.projectName)+". ")])]):_vm._e(),(
          info.type === 'UPDATE_CANCEL_RECLAIM_PROJECT' &&
          info.status === 'cancel' &&
          info.isReclaimProject
        )?_c('div',{class:_vm.$style.navigateNotification,on:{"click":function($event){return _vm.handleNavigateNotifications()}}},[_c('p',{class:_vm.$style.userInfoDateTime},[_vm._v(" "+_vm._s(info.datetime)+" ")]),_c('p',{class:_vm.$style.userInfoPrimary},[_vm._v(" Project Administration Change Request Is Cancelled ")]),_c('br'),_c('p',{class:_vm.$style.userInfoTertiary},[_vm._v(" "+_vm._s(info.notificationSenderName)+" has cancelled the project administrator change request for "+_vm._s(info.projectName)+". ")])]):_vm._e()])}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }