




























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({ components: {} })
export default class InfoModal extends Vue {
  @Prop()
  public modalTitle!: string;
  @Prop()
  public modalContent!: string;
  @Prop()
  public confirmButtonText!: string;
  @Prop()
  public cancelButtonText!: string;

  public onClick(action: string) {
    this.$emit(action);
  }
}
