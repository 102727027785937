
































import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({ components: {}})

export default class ConfirmationDialogue extends Vue {
  @Prop()
  public modalTitle!: string;
  @Prop()
  public confirmationText!: string;
  @Prop({
    default() {
      return 'Confirm';
    },
  })
  public confirmationButtonText!: string;
  @Prop({
    default() {
      return 'Cancel';
    },
  })
  public cancelButtonText!: string;
  public confirm() {
    this.$emit('confirm');
    this.$emit('close');
  }
  public cancel() {
    this.$emit('cancel');
    this.$emit('close');
  }
  public closeModal() {
    this.$emit('cancel');
    this.$emit('close');
  }
}
