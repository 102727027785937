






































import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { CheckboxesCriterionContent } from '@/store/types/criterions.types';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { isEqual as _isEqual } from 'lodash';
import draggable from 'vuedraggable';

@Component({
    components: { ValidationProvider, ValidationObserver, draggable },
})
export default class CheckboxesCriterionInput extends Vue {
    @Prop({
        default() {
            return ['N/A'];
        },
    })
    public options!: CheckboxesCriterionContent['checkboxesOptions'];

    public dirtyOptions = [...this.options];
    public drag = false;

    @Watch('dirtyOptions')
    public onChanged() {
        this.$emit('update:options', [...this.dirtyOptions]);
    }

    @isTruthy
    @Watch('options')
    public onRangeUpdated() {
        if (!_isEqual(this.dirtyOptions, this.options)) {
            this.dirtyOptions = [...this.options];
        }
    }
}
