





























import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { ToastProgrammatic as Toast } from 'buefy';
import {
  CreateGripDomainCategoryPayload,
  GripDomainCategory,
  UpdateGripDomainCategoryPayload,
} from '../../../store/types/grip.types';
import { ApiState } from '../../../store/types/general.types';
import { Debounce } from '../../../jbi-shared/util/debounce.vue-decorator';
import { isTruthy } from '../../../jbi-shared/util/watcher.vue-decorator';

@Component({
  components: {},
})
export default class DomainCategoryModal extends Vue {
  public name = '';
  public errorMessage: string | null = null;
  public disableSubmitButton: boolean = true;

  @Prop() public projectId!: number;
  @Prop() public modalTitle!: string;
  @Prop() public gripDomainCategoryDetails!: GripDomainCategory;

  @Action('projects/getGripDomainCategories')
  public getGripDomainCategories!: (projectId: number) => void;

  @Action('projects/createGripDomainCategory')
  public createGripDomainCategory!: (
    payload: CreateGripDomainCategoryPayload,
  ) => void;

  @Action('projects/updateGripDomainCategory')
  public updateGripDomainCategory!: (
    payload: UpdateGripDomainCategoryPayload,
  ) => void;

  @Action('projects/verifyGripDomainCategory')
  public verifyGripDomainCategory!: (
    payload: CreateGripDomainCategoryPayload,
  ) => void;

  @State((state) => state.projects.apiState.createGripDomainCategory)
  public createGripDomainCategoryApiState!: ApiState;

  @State((state) => state.projects.apiState.updateGripDomainCategory)
  public updateGripDomainCategoryApiState!: ApiState;

  @State((state) => state.projects.apiState.verifyGripDomainCategory)
  public verifyGripDomainCategoryApiState!: boolean;

  @State((state) => state.projects.verifiedGripDomainCategory)
  public verifiedGripDomainCategory!: boolean;

  @State((state) => state.projects.createdGripDomainCategory)
  public createdGripDomainCategory!: GripDomainCategory;

  public handleSubmit(): void {
    if (this.gripDomainCategoryDetails) {
      this.updateGripDomainCategory({
        id: this.gripDomainCategoryDetails.id,
        projectId: this.projectId,
        name: this.name,
      });
    } else {
      this.createGripDomainCategory({
        name: this.name,
        projectId: this.projectId,
      });
    }
  }

  public mounted(): void {
    if (this.gripDomainCategoryDetails) {
      this.name = this.gripDomainCategoryDetails.name;
    }
  }

  @Watch('name')
  @Debounce(300)
  public onNameChange(): void {
    if (this.name.trim().length > 0) {
      if (
        this.gripDomainCategoryDetails &&
        this.name === this.gripDomainCategoryDetails.name
      ) {
        this.disableSubmitButton = true;
        this.errorMessage = null;
      } else {
        this.disableSubmitButton = false;
        this.verifyGripDomainCategory({
          name: this.name,
          projectId: this.projectId,
        });
      }
    } else {
      this.disableSubmitButton = true;
      this.errorMessage = null;
    }
  }

  @Watch('createGripDomainCategoryApiState.success')
  @isTruthy
  public watchCreateCategorySuccess(): void {
    this.$emit('submit', this.createdGripDomainCategory);
    this.$emit('close', false);
    this.getGripDomainCategories(this.projectId);
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `successfully added`,
    });
  }

  @Watch('createGripDomainCategoryApiState.error')
  public watchCreateCategoryError(): void {
    this.$emit('close', true);
  }

  @Watch('updateGripDomainCategoryApiState.success')
  @isTruthy
  public watchUpdateCategorySuccess(): void {
    this.$emit('submit', this.name);
    this.$emit('close', false);
    this.getGripDomainCategories(this.projectId);
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `Updated successfully`,
    });
  }

  @Watch('updateGripDomainCategoryApiState.error')
  public watchUpdateCategoryError(): void {
    this.$emit('close', true);
  }

  @Watch('verifyGripDomainCategoryApiState.success')
  public watchVerifyCategorySuccess(): void {
    if (this.verifiedGripDomainCategory === true) {
      this.disableSubmitButton = true;
      this.errorMessage =
        'A category with the same name already exists. Specify another name.';
    } else {
      this.disableSubmitButton = false;
      this.errorMessage = null;
    }
  }

  @Watch('verifyGripDomainCategoryApiState.error')
  public watchVerifyCategoryError(): void {
    this.$emit('close', true);
  }
}
