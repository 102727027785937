






















































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import { Action, State } from 'vuex-class';
import HeroBar from '@/components/base/HeroBar.vue';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import {
  AuditSamplingType,
  AuditSamplingSite,
  ConsecutiveSamplingMode,
  DataCollectionSummaryRequestPayload,
  MultiSitePayload,
  MultiSiteSamplingRequestPayload,
  AuditSite,
  AuditSamplingDataConfig,
  AuditSamplingResponse,
} from '@/store/modules/audits/types/audits.types';
import {
  map as _map,
  round as _round,
  cloneDeep as _cloneDeep,
  orderBy as _orderBy,
  findIndex as _findIndex,
  find as _find,
} from 'lodash';
import { ToastProgrammatic as Toast } from 'buefy';
import SamplingCalculator from '@/views/Audit/components/SamplingCalculator.vue';
import ConsecutiveCalculator from '@/views/Audit/components/ConsecutiveCalculator.vue';

@Component({
  components: {
    ConsecutiveCalculator,
    SamplingCalculator,
    DefaultLayout,
    HeroBar,
  },
})
export default class ConsecutiveTargetSampleMultiSiteSampling extends Vue {
  @Prop()
  public auditId!: number;
  @Prop()
  public auditSiteList!: AuditSite[];
  @Prop()
  public auditConfig!: AuditSamplingResponse;
  @Prop()
  public auditSamplingType!: AuditSamplingType;
  @Prop()
  public consecutiveSamplingMode!: ConsecutiveSamplingMode;
  @Prop({ default: true })
  public editable!: boolean;
  public auditStartDate: any = null;
  public multiSitePayload: MultiSitePayload[] = [];
  public siteArray: AuditSamplingSite[] = [];
  public renderComponent: boolean = true;
  public calculateData = {
    output: 0,
  };

  public criteriaBasedCalculateData = {
    ...this.calculateData,
    sites: this.siteArray,
  };

  @Action('audits/updateMultiSiteSampling')
  public updateMultiSiteSampling!: (
    payload: MultiSiteSamplingRequestPayload,
  ) => void;

  @State((state) => state.audits.updatedMultiSiteSampling)
  public updatedMultiSiteSampling!: any;

  @Watch('updatedMultiSiteSampling')
  @isTruthy
  public watchUpdatedMultiSiteSampling() {
    this.showNotificationAndRedirectToProject();
  }

  public mounted() {
    this.populateMultiSiteData();
  }

  public populateMultiSiteData() {
    this.auditSiteList.forEach((auditSite: AuditSite) => {
      const siteObject: AuditSamplingSite = {
        siteId: auditSite.site.id,
        name: auditSite.site.name,
        sampleSize: 0,
        auditSiteId: auditSite.id,
        calculateData: _cloneDeep({
          ...this.calculateData,
          auditSiteId: auditSite.id,
        }),
      };
      this.siteArray.push(siteObject);
    });

    this.auditConfig.dataConfigs.forEach(
      (dataConfig: AuditSamplingDataConfig) => {
        const currentCriteriaPayload: MultiSitePayload = {
          auditId: this.auditId,
          criteriaId: 0, // todo: change this logic later
          auditCriteriaId: 0,
          sites: [],
          sampleSize: 0,
        };
        Object.values(dataConfig).forEach(
          (singleConfig: AuditSamplingDataConfig) => {
            if (singleConfig.auditCriteriaMap.isActive) {
              currentCriteriaPayload.criteriaId =
                singleConfig.auditCriteriaMap.criteria.id;
              currentCriteriaPayload.auditId = this.auditId;
              currentCriteriaPayload.auditCriteriaId =
                singleConfig.auditCriteriaMap.id;
              currentCriteriaPayload.criteriaTitle =
                singleConfig.auditCriteriaMap.criteria.title;
              currentCriteriaPayload.order =
                singleConfig.auditCriteriaMap.order;
              if (singleConfig.auditSiteMap === null) {
                currentCriteriaPayload.sampleSize = _round(
                  singleConfig.sampleSize,
                );
                currentCriteriaPayload.dataConfig = singleConfig;
              } else {
                const auditSamplingConfig = _find(
                  this.siteArray,
                  (value: AuditSamplingSite) => {
                    return value.auditSiteId === singleConfig.auditSiteMap.id;
                  },
                );
                if (auditSamplingConfig) {
                  currentCriteriaPayload.sites.push({
                    ...auditSamplingConfig,
                    sampleSize: _round(singleConfig.sampleSize),
                    dataConfig: singleConfig,
                  });
                }
              }
            }
          },
        );
        this.multiSitePayload.push(currentCriteriaPayload);
      },
    );
    this.multiSitePayload = this.multiSitePayload.map(
      (criteria: MultiSitePayload) => {
        const sitesIdsToCheckAgainst: number[] = this.siteArray.map(
          (site: AuditSamplingSite) => site.siteId,
        );
        return {
          ...criteria,
          sites: criteria.sites.sort(
            (optionA: AuditSamplingSite, optionB: AuditSamplingSite) => {
              if (
                sitesIdsToCheckAgainst.indexOf(optionA.siteId) >
                sitesIdsToCheckAgainst.indexOf(optionB.siteId)
              ) {
                return 1;
              } else {
                return -1;
              }
            },
          ),
        };
      },
    );
  }

  public showNotificationAndRedirectToProject() {
    Toast.open({
      message: `Changes saved`,
      position: 'is-top',
      type: 'is-dark',
      duration: 3500,
    });
  }

  public updateChildSamplingSize(index: number) {
    const currentSamplingData = this.auditCriteriaList[index];
    const parentVal = !currentSamplingData.sampleSize
      ? 0
      : currentSamplingData.sampleSize;
    const siteArrayLength = this.siteArray.length;
    const modulo = parentVal % siteArrayLength;
    const childVal = (parentVal - modulo) / siteArrayLength;
    this.auditCriteriaList[index].sites.forEach(
      (currentVal: AuditSamplingSite, siteIndex: number) => {
        this.auditCriteriaList[index].sites[siteIndex].sampleSize =
          siteIndex === 0 ? childVal + modulo : childVal;
      },
    );
  }

  public updateOtherChildSamplingSize(index: number, siteIndex: number) {
    const currentSamplingData = this.auditCriteriaList[index];
    const currentElementVal = !this.auditCriteriaList[index].sites[siteIndex]
      .sampleSize
      ? 0
      : Number(this.auditCriteriaList[index].sites[siteIndex].sampleSize);
    const parentVal: number = !currentSamplingData.sampleSize
      ? 0
      : Number(currentSamplingData.sampleSize);

    if (parentVal < currentElementVal) {
      this.updateChildSamplingSize(index);
    } else {
      const tempParentVal = parentVal - currentElementVal;
      const siteArrayLength = Number(this.siteArray.length - 1);
      const modulo = tempParentVal % siteArrayLength;
      const childVal = (tempParentVal - modulo) / siteArrayLength;
      this.auditCriteriaList[index].sites.forEach(
        (currentVal: AuditSamplingSite, currentSiteIndex: number) => {
          if (currentSiteIndex !== siteIndex && siteIndex !== 0) {
            this.auditCriteriaList[index].sites[currentSiteIndex].sampleSize =
              currentSiteIndex === 0 ? childVal + modulo : childVal;
          } else if (currentSiteIndex !== siteIndex) {
            this.auditCriteriaList[index].sites[currentSiteIndex].sampleSize =
              currentSiteIndex === 1 ? childVal + modulo : childVal;
          }
        },
      );
    }
  }

  public updateTotalSamplingSize(index: number) {
    let total = 0;
    this.auditCriteriaList[index].sites.forEach(
      (currentVal: AuditSamplingSite, currentSiteIndex: number) => {
        total =
          Number(total) +
          Number(
            this.auditCriteriaList[index].sites[currentSiteIndex].sampleSize,
          );
      },
    );
    this.auditCriteriaList[index].sampleSize = total;
  }

  public handleMultiSiteChanges() {
    this.updateMultiSiteSampling({
      auditId: this.auditId,
      multiSitePayload: this.multiSitePayload,
      auditSamplingType: this.auditSamplingType,
      auditSamplingMode: this.consecutiveSamplingMode,
      calculateData: this.criteriaBasedCalculateData,
      samplingConfigId: this.auditConfig.auditSamplingConfigs.id,
    });
  }

  public handleApplySampleSizeForCriteriaBased(newValue: any) {
    this.criteriaBasedCalculateData = newValue;
    this.auditCriteriaList.forEach(
      (currentObject: MultiSitePayload, currentIndex: number) => {
        currentObject.sampleSize = newValue.output;
        currentObject.sites.forEach(
          (currentSiteData: AuditSamplingSite, currentSiteIndex: number) => {
            newValue.sites.forEach((siteData: any, siteIndex: number) => {
              if (currentSiteData.auditSiteId === siteData.auditSiteId) {
                currentSiteData.sampleSize = siteData.calculateData.output;
              }
            });
          },
        );
      },
    );
  }

  get auditCriteriaList() {
    return _orderBy(this.multiSitePayload, ['order'], ['asc']);
  }
}
