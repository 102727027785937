














import Header from '../components/base/Header.vue';
import Footer from '../components/base/Footer.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: { Header, Footer },
})
export default class HomeLayout extends Vue {
  @Prop()
  public layout!: string;
}
