


























































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { State, Action } from 'vuex-class';
import {
  UpdateProjectGeneralSettingRequestPayload,
  ProjectDetailPayload,
  ProjectStatus,
} from '@/store/modules/projects/types/projects.types';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { isEqual as _isEqual } from 'lodash';
import { ToastProgrammatic as Toast } from 'buefy';
import BaseDialog from '../../../components/base/BaseDialog.vue';
import ArchiveModalBox from '../../../components/ArchiveModalBox.vue';
import DeleteModalBox from '../../../components/DeleteModalBox.vue';
import { useUserEmail } from '@/utils/user.util';
import SiteSettings from '@/views/Project/components/SiteSettings.vue';
import DomainCategorySettings from '../../Grip/DomainCategory/DomainCategorySettings.vue';
import BarrierSettings from '../../Grip/Barrier/BarrierSettings.vue';
import EnablerSettings from '../../Grip/Enabler/EnablerSettings.vue';
import StrategySettings from '../../Grip/Strategy/StrategySettings.vue';
import { cloneData } from '@/utils/general.util';

@Component({
  components: {
    BaseDialog,
    SiteSettings,
    DomainCategorySettings,
    BarrierSettings,
    EnablerSettings,
    StrategySettings,
  },
})
export default class ProjectSettingsTab extends Vue {
  @Prop() public projectId!: number;
  @Prop() public projectDetail!: ProjectDetailPayload;
  @Prop() public initialValue!: any;
  @Prop() public projectSettingInfo!: any;
  @Prop() public isProjectOwner!: boolean;
  @Prop() public activeTab!: number;

  public settingTab: number = 0;
  public isGeneralSettingSubmitButtonDisabled: boolean = true;
  public projectSettingDetail: any = [];
  public projectDetailInfo: any = [];
  public tabVisiblity: boolean = false;
  public gripTabDisabled: boolean = false;
  public showBarrierTab: boolean = false;
  public showEnablerTab: boolean = false;
  public showStrategyTab: boolean = false;
  public showDomainCategoryTab: boolean = false;
  public projectStatus: ProjectStatus = this.projectDetail.status
    ? (this.projectDetail.status as ProjectStatus)
    : ProjectStatus.ACTIVE;
  public updateProjectStatusTo: ProjectStatus = this.projectStatus;

  /** Adding unique keys for tab components for dynamically adding or removing tab components */
  public generalSettingsTabKey: number = Math.floor(Math.random() * 999);
  public siteTabKey: number = Math.floor(Math.random() * 999);
  public barrierTabKey: number = Math.floor(Math.random() * 999);
  public enablerTabKey: number = Math.floor(Math.random() * 999);
  public strategyTabKey: number = Math.floor(Math.random() * 999);
  public domainCategoryTabKey: number = Math.floor(Math.random() * 999);

  @Action('projects/getProjectDetail')
  public getProjectDetail!: (payload: { projectId: number }) => void;

  @Action('projects/publishProjectById')
  public publishProjectById!: (projectId: number) => void;

  @Action('projects/archiveProjectById')
  public archiveProjectById!: (projectId: number) => void;

  @Action('projects/deleteProjectById')
  public deleteProjectById!: (projectId: number) => void;

  @Action('projects/updateProjectGeneralSettings')
  public updateProjectGeneralSettings!: (
    params: UpdateProjectGeneralSettingRequestPayload,
  ) => void;

  @State((state) => state.projects.updatedProjectGeneralSettings)
  public updatedProjectGeneralSettings!: any;

  @State((state) => state.projects.apiState.updateProjectStatus.success)
  public updateProjectSuccess!: boolean;

  @State((state) => state.projects.projectName)
  public projectNameState!: any;

  @State((state) => state.projects.auditGripType)
  public auditGripType!: any;

  get userEmail(): string | undefined {
    return useUserEmail.call(this);
  }

  get isProjectArchived(): boolean {
    return this.projectStatus === ProjectStatus.ARCHIVED;
  }

  get editable(): boolean {
    return !this.isProjectArchived;
  }

  public tabChange(): void {
    switch (this.settingTab) {
      case 0:
        this.tabVisiblity = false;
        this.gripTabDisabled = false;
        this.generalSettingsTabKey += 1;
        break;
      case 1:
        if (!this.isProjectOwner) {
          this.settingTab = 2;
          this.tabVisiblity = true;
          this.showBarrierTab = true;
          this.gripTabDisabled = true;
          this.barrierTabKey += 1;
        } else {
          this.tabVisiblity = false;
          this.gripTabDisabled = false;
          this.siteTabKey += 1;
        }
        break;
      case 2:
        this.gripTabDisabled = true;
        if (!this.isProjectOwner) {
          this.showEnablerTab = true;
          this.enablerTabKey += 1;
        } else {
          this.settingTab = 3;
          this.tabVisiblity = true;
          this.showBarrierTab = true;
        }
        break;
      case 3:
        this.gripTabDisabled = true;
        if (!this.isProjectOwner) {
          this.showStrategyTab = true;
          this.strategyTabKey += 1;
          break;
        } else {
          this.showBarrierTab = true;
          this.barrierTabKey += 1;
        }
        break;
      case 4:
        this.gripTabDisabled = true;
        if (!this.isProjectOwner) {
          this.showDomainCategoryTab = true;
          this.domainCategoryTabKey += 1;
        } else {
          this.showEnablerTab = true;
          this.enablerTabKey += 1;
        }
        break;
      case 5:
        this.gripTabDisabled = true;
        this.showStrategyTab = true;
        this.strategyTabKey += 1;
        break;
      case 6:
        this.gripTabDisabled = true;
        this.showDomainCategoryTab = true;
        this.domainCategoryTabKey += 1;
        break;
      default:
    }
  }

  public updateDisableButton(type: string) {
    if (type === 'projectDescription') {
      this.isGeneralSettingSubmitButtonDisabled = _isEqual(
        this.projectSettingInfo.description,
        this.projectSettingDetail.description,
      );
    } else if (type === 'enableProjectGrip') {
      this.isGeneralSettingSubmitButtonDisabled = _isEqual(
        this.projectSettingInfo.enableProjectGrip,
        this.projectSettingDetail.enableProjectGrip,
      );
    } else {
      this.isGeneralSettingSubmitButtonDisabled = _isEqual(
        this.projectSettingInfo.name,
        this.projectSettingDetail.name,
      );
    }
  }

  public handleUnarchiveProject() {
    this.$buefy.modal.open({
      parent: this,
      component: BaseDialog,
      hasModalCard: true,
      trapFocus: true,
      props: {
        title: 'Unarchive Project?',
        content: `This will enable the project and its data collection period to be edited. You may archive the project again anytime`,
        confirmButtonText: 'Cancel',
        cancelButtonText: 'Unarchive',
      },
      events: {
        cancel: () => {
          this.updateProjectStatusTo = ProjectStatus.ACTIVE;
          this.publishProjectById(this.projectDetail.id);
        },
      },
    });
  }

  public handleArchiveProject() {
    this.$buefy.modal.open({
      parent: this,
      component: ArchiveModalBox,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle: `Archive this Project?`,
        modalContent: `Archiving '${this.projectSettingDetail.name}' will convert the project to view-only and disable any ongoing data collection period under this project.`,
      },
      events: {
        confirm: () => {
          this.updateProjectStatusTo = ProjectStatus.ARCHIVED;
          this.archiveProjectById(this.projectDetail.id);
        },
      },
    });
  }

  public handleDeleteProject() {
    this.$buefy.modal.open({
      parent: this,
      component: DeleteModalBox,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle: 'Delete Project?',
        modalContent: `Deleting the project '${this.projectSettingDetail.name}' will remove it completely from PACES and you can no longer access any data collection period made under this project.`,
      },
      events: {
        confirm: () => {
          this.updateProjectStatusTo = ProjectStatus.DELETED;
          this.deleteProjectById(this.projectDetail.id);
        },
      },
    });
  }

  public updateGeneralSettingHandler() {
    this.updateProjectGeneralSettings({
      projectId: this.projectId,
      projectName: this.projectSettingDetail.name.trim(),
      projectDescription: this.projectSettingDetail.description
        ? this.projectSettingDetail.description.trim()
        : '',
      enableProjectGrip: this.projectSettingDetail.enableProjectGrip,
    });
  }

  public mounted(): void {
    this.projectSettingDetail = cloneData(this.projectSettingInfo);
    this.projectDetailInfo = cloneData(this.projectDetail);
    this.settingTab = this.activeTab;
    this.tabChange();
  }

  @Watch('updatedProjectGeneralSettings')
  @isTruthy
  public updateSavedGeneralSettings(newValue: any) {
    this.isGeneralSettingSubmitButtonDisabled = true;
    const updatedProjectGeneralData = {
      title: newValue.title,
      description: newValue.description,
      enableProjectGrip: newValue.enableProjectGrip,
    };
    this.$emit('updateProjectSettings', updatedProjectGeneralData);
    Toast.open({
      message: `Changes saved`,
      position: 'is-top',
      type: 'is-dark',
    });
  }

  @Watch('updateProjectSuccess')
  @isTruthy
  public watchUpdatedStatus(): void {
    this.projectStatus = this.updateProjectStatusTo as ProjectStatus;
    this.$emit('updatedProjectStatus', this.projectStatus);
    if (this.projectStatus === ProjectStatus.DELETED) {
      this.$router.push({
        name: 'project-me',
      });
    }
  }
}
