









































import { Action, State } from 'vuex-class';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import DomainCategoryModal from './DomainCategoryModal.vue';
import { GripDomainCategory } from '../../../store/types/grip.types';
import { isTruthy } from '../../../jbi-shared/util/watcher.vue-decorator';
import { ApiState } from '../../../store/types/general.types';

@Component({
  components: { DomainCategoryModal },
})
export default class DomainCategoryDropdown extends Vue {
  public gripDomainCategoryOptions: string[] = [];
  public selectedGripDomainCategory: string | null = 'Select Domain/Category';

  @Prop() public projectId!: number;
  @Prop() public modalTitle!: string;
  @Prop() public isNew!: boolean;
  @Prop() public gripDomainCategoryDetails!: GripDomainCategory;
  @Prop() public gripDomainCategories!: GripDomainCategory[];

  @Action('projects/getGripDomainCategories')
  public getGripDomainCategories!: (projectId: number) => void;

  @State((state) => state.projects.apiState.getGripDomainCategories)
  public getGripDomainCategoriesState!: ApiState;

  public getGripDomainCategoryOptions(): void {
    this.gripDomainCategoryOptions = this.gripDomainCategories
      .filter(
        (data: GripDomainCategory) =>
          data.name !== this.selectedGripDomainCategory,
      )
      .map((data: GripDomainCategory) => data.name);

    if (
      this.selectedGripDomainCategory !==
      `No ${this.modalTitle} Domain/Category`
    ) {
      this.gripDomainCategoryOptions.push(
        `No ${this.modalTitle} Domain/Category`,
      );
    }
  }

  public handleSelectCategory(selectedOption: string): void {
    this.getGripDomainCategoryOptions();
    const selectedGripDomainCategoryOption:
      | GripDomainCategory
      | undefined = this.gripDomainCategories.find(
      (gripDomainCategory) => gripDomainCategory.name === selectedOption,
    );
    this.$emit(
      'selectedGripDomainCategory',
      selectedGripDomainCategoryOption
        ? selectedGripDomainCategoryOption
        : null,
    );
  }

  public handleCreate(): void {
    this.$buefy.modal.open({
      parent: this,
      props: {
        projectId: this.projectId,
        title: 'Add',
      },
      events: {
        close: (isError: boolean) => {
          if (isError) {
            this.$emit('close');
          }
        },
        submit: (newCategory: GripDomainCategory) => {
          this.selectedGripDomainCategory = newCategory.name;
          this.$emit('selectedGripDomainCategory', newCategory);
        },
      },
      component: DomainCategoryModal,
      hasModalCard: true,
    });
  }

  public mounted(): void {
    if (this.isNew) {
      this.gripDomainCategoryOptions = this.gripDomainCategories.map(
        (gripDomainCategory) => gripDomainCategory.name,
      );
    } else {
      this.selectedGripDomainCategory = this.gripDomainCategoryDetails
        ? this.gripDomainCategoryDetails.name
        : `No ${this.modalTitle} Domain/Category`;

      this.getGripDomainCategoryOptions();
    }
  }

  @Watch('getGripDomainCategoriesState.success')
  @isTruthy
  public watchGripDomainCategorySuccess(): void {
    this.getGripDomainCategoryOptions();
  }
}
