



































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '@/utils/date.util';
import moment from 'moment';
import { cloneData } from '@/utils/general.util';
import { AuditDetail } from '../../../store/modules/audits/types/audits.types';
import { UserRoleInAudit } from '@/store/modules/projects/types/projects.types';
import { getAuditUrlByRole } from '@/utils/project.util';

@Component({ components: {} })
export default class AuditList extends Vue {
  @Prop()
  public activeAuditList!: AuditDetail[];
  @Prop()
  public archivedAuditList!: AuditDetail[];

  public dirtyActiveAuditList: AuditDetail[] = [];
  public dirtyArchivedAuditList: AuditDetail[] = [];
  public showViewLessBtn: boolean = false;
  public showViewAllBtn: boolean = true;
  public showArchivedList: boolean = false;
  public dateToday: Date = new Date();

  public formatDate(s: string) {
    return dayjs(s).format(DATE_FORMAT);
  }

  public mounted() {
    this.dirtyActiveAuditList = cloneData(this.activeAuditList);
    this.dirtyArchivedAuditList = cloneData(this.archivedAuditList);
    if (this.dirtyActiveAuditList.length > 5) {
      this.dirtyActiveAuditList = this.activeAuditList.slice(0, 5);
    }
  }

  public isDataCollector(item: AuditDetail): boolean {
    return item.userRoleInAudit === UserRoleInAudit.isDataCollector
      ? true
      : false;
  }

  public getAuditUrl(audit: AuditDetail): string {
    if (audit.userRoleInAudit) {
      return getAuditUrlByRole(audit.id, audit.userRoleInAudit);
    } else {
      return '/audits/' + audit.id + '/homepage#dataCollectionSheet';
    }
  }

  public showAuditDetails(item: AuditDetail) {
    return (
      this.isDataCollector(item) &&
      (new Date(item.startDate) > this.dateToday ||
        new Date(item.endDate) < this.dateToday)
    );
  }

  public getDurationText(startDate: string, endDate: string) {
    if (startDate === null && endDate === null) {
      return 'N/A';
    } else {
      if (endDate !== null) {
        return `${dayjs(startDate).format(DATE_FORMAT)} - ${dayjs(
          endDate,
        ).format(DATE_FORMAT)}`;
      } else {
        return `from ${dayjs(startDate).format(DATE_FORMAT)}`;
      }
    }
  }

  public dataCollectionStatus(auditDetail: any) {
    if (
      auditDetail.endedAt &&
      moment(new Date()).isSameOrAfter(auditDetail.endedAt)
    ) {
      return `Completed on ${this.formatDate(auditDetail.endedAt)}`;
    } else if (
      auditDetail.startedAt &&
      moment(new Date()).isSameOrAfter(auditDetail.startedAt)
    ) {
      return 'Ongoing';
    } else {
      return 'Not Started';
    }
  }

  public handleViewAll() {
    this.showViewLessBtn = true;
    this.showViewAllBtn = false;
    this.dirtyActiveAuditList = cloneData(this.activeAuditList);
  }

  public handleViewLess() {
    this.showViewLessBtn = false;
    this.showViewAllBtn = true;
    this.dirtyActiveAuditList = this.activeAuditList.slice(0, 5);
  }

  public showArchivedAudits() {
    this.showArchivedList = true;
  }

  public hideArchivedAudits() {
    this.showArchivedList = false;
  }
}
