


















































































































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { ToastProgrammatic as Toast } from 'buefy';
import { isTruthy } from '../../../jbi-shared/util/watcher.vue-decorator';
import {
  JBICriteriaStatus,
  ProjectCriteriaAcknowledgement,
  ProjectDetailPayload,
  ProjectParticipantDTO,
  UpdatedJBICriteriaResponse,
  UpdateJBICriteriaAcknowledgmentPayload,
} from '../../../store/modules/projects/types/projects.types';
import { ApiState } from '../../../store/types/general.types';
import { RootState } from '../../../store/store';
import { AxiosError } from 'axios';
import { CRITERION_TYPE } from '../../../store/types/criterions.types';

@Component({})
export default class JBICriteriaAcknowledgmentModal extends Vue {
  @Prop() public projectId!: number;
  @Prop() public currentUser!: ProjectParticipantDTO;
  @Prop() public updatedJBICriteriaResponse!: UpdatedJBICriteriaResponse;
  @Prop()
  public projectCriteriaAcknowledgement!: ProjectCriteriaAcknowledgement;

  @Action('projects/updateJBICriteriaAcknowledgment')
  public updateJBICriteriaAcknowledgment!: (
    payload: UpdateJBICriteriaAcknowledgmentPayload,
  ) => void;

  @Action('projects/getUpdatedJBICriteriaWithAcknowledgement')
  public getUpdatedJBICriteriaWithAcknowledgement!: (projectId: number) => void;

  @State(
    (state: RootState) =>
      state.projects.apiState.getUpdateJBICriteriaAcknowledgment,
  )
  public getUpdateJBICriteriaAcknowledgmentState!: ApiState;

  @State((state) => state.projects.projectDetail)
  public projectDetail!: ProjectDetailPayload;

  get criteriaType(): typeof CRITERION_TYPE {
    return CRITERION_TYPE;
  }

  get jbiCriteriaStatus(): typeof JBICriteriaStatus {
    return JBICriteriaStatus;
  }

  get JBICriteriaUpdates() {
    return [
      {
        status: JBICriteriaStatus.UPDATED,
        criteriaList: this.updatedJBICriteriaResponse.updatedJBICriteria,
      },
      {
        status: JBICriteriaStatus.NEW,
        criteriaList: this.updatedJBICriteriaResponse.newJBICriteria,
      },
      {
        status: JBICriteriaStatus.DELETED,
        criteriaList: this.updatedJBICriteriaResponse.deletedJBICriteria,
      },
    ];
  }

  public closeModal(): void {
    this.$emit('close');
  }

  public acknowledgeJBICriteriaChanges(): void {
    const payload: UpdateJBICriteriaAcknowledgmentPayload = {
      projectId: this.projectId,
      projectCriteriaAcknowledgement: this.projectCriteriaAcknowledgement,
      acknowledged_by: this.currentUser,
    };
    this.updateJBICriteriaAcknowledgment(payload);
  }

  @Watch('getUpdateJBICriteriaAcknowledgmentState.success')
  @isTruthy
  public watchGetUpdateJBICriteriaAcknowledgmentStateSuccess(): void {
    Toast.open({
      message: 'Successfully Acknowledged.',
      position: 'is-top',
      type: 'is-dark',
      duration: 3500,
    });
    this.$emit('close');
    this.getUpdatedJBICriteriaWithAcknowledgement(this.projectId);
  }

  @Watch('getUpdateJBICriteriaAcknowledgmentState.error')
  @isTruthy
  public watchGetUpdateJBICriteriaAcknowledgmentStateError(
    error: AxiosError,
  ): void {
    Toast.open({
      message:
        error && error.response && error.response.data
          ? error.response.data.message
          : 'Something went wrong. Please try again.',
      position: 'is-top',
      type: 'is-danger',
      duration: 3500,
    });
    this.$emit('close');
  }
}
