





















































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import GenericFileUploader from '../../../components/uploader/GenericFileUploader.vue';
import { isTruthy } from '../../../jbi-shared/util/watcher.vue-decorator';
import { ToastProgrammatic as Toast } from 'buefy';
import {
  CreateGripBarrierBase,
  FileAttachmentDTO,
  GripBarrierDTO,
  GripDomainCategory,
  LinkDTO,
  UpdateGripBarrierPayload,
} from '../../../store/types/grip.types';
import { ApiState } from '../../../store/types/general.types';
import DomainCategoryDropdown from '../DomainCategory/DomainCategoryDropdown.vue';
import {
  getSourceLink,
  getWordCount,
  validateLink,
} from '../../../utils/general.util';

@Component({
  components: { DomainCategoryDropdown, GenericFileUploader },
})
export default class BarrierModal extends Vue {
  @Prop() public projectId!: number;
  @Prop() public gripBarrierDetails!: GripBarrierDTO;
  @Prop() public isNew!: boolean;
  @Prop() public isModalActive!: boolean;
  @Prop() public draggable!: boolean;

  @Action('projects/getGripDomainCategories')
  public getGripDomainCategories!: (projectId: number) => void;

  @State((state) => state.projects.apiState.getGripDomainCategories)
  public getGripDomainCategoriesState!: ApiState;

  @State((state) => state.projects.gripDomainCategories)
  public gripDomainCategories!: GripDomainCategory[];

  public dirtyGripBarrier: CreateGripBarrierBase = {
    title: '',
    description: '',
    action: true,
    reason: '',
    links: [],
    attachments: [],
    gripDomainCategoryId: null,
  };
  public areLinksValid: boolean = true;
  public isDomainCategoriesLoading: boolean = false;
  public gripDomainCategoryOptions: GripDomainCategory[] = [];
  public selectedGripDomainCategoryId: number | null = null;
  public barrierLinks: LinkDTO[] = [];
  public link: string = '';
  public count: number = 1;
  public fileAttachment: FileAttachmentDTO[] = [];
  public errorMessage: {
    title: string | null;
    description: string | null;
    reason: string | null;
  } = { title: null, description: null, reason: null };
  public isDraggedStart: boolean = false;
  public modalPosX: number = 0;
  public modalPosY: number = 0;
  public dragStartPosX: number = 0;
  public dragStartPosY: number = 0;

  get GenericFileUploader() {
    return GenericFileUploader;
  }

  get gripDomainCategoryDetails(): GripDomainCategory | null {
    return this.gripBarrierDetails && this.gripBarrierDetails.gripDomainCategory
      ? this.gripBarrierDetails.gripDomainCategory
      : null;
  }

  get modalStyle(): string {
    return `left: ${this.modalPosX}px; top: ${this.modalPosY}px;`;
  }

  get sourceLink(): string {
    return getSourceLink(this.barrierLinks);
  }

  public wordCount(value: string): number {
    return value.trim().length > 0 ? getWordCount(value.trim()) : 0;
  }

  public onDragStart(e: any): void {
    const jsonData = JSON.stringify({ id: 'barrierModal' });
    e.dataTransfer.setData('application/json', jsonData);
    this.dragStartPosX = e.clientX;
    this.dragStartPosY = e.clientY;
    e.dataTransfer.effectAllowed = 'move';
  }

  public onDragOver(e: any): void {
    e.preventDefault();
    e.dataTransfer.effectAllowed = 'move';
  }

  public onDrop(e: any): void {
    e.preventDefault();
    const jsonData = e.dataTransfer.getData('application/json');
    const element = document.getElementById('barrierModal');
    e.preventDefault();
    e.dataTransfer.effectAllowed = 'move';

    if (element && jsonData) {
      /**
       * calculate the new cursor position:
       * newCursorPosX/newCursorPosY = diffrence between element's current position and cursor's drop position
       * oldCursorPosX/oldCursorPosY = diffrence between element's current position and cursor's drag start position
       */
      const newCursorPosX = element.offsetLeft - e.clientX || 0;
      const newCursorPosY = element.offsetTop - e.clientY || 0;
      const oldCursorPosX = element.offsetLeft - this.dragStartPosX || 0;
      const oldCursorPosY = element.offsetTop - this.dragStartPosY || 0;

      /** calculate the new modal position: */
      this.modalPosX += oldCursorPosX - newCursorPosX;
      this.modalPosY += oldCursorPosY - newCursorPosY;
    }
  }

  public handleSelectedGripDomainCategory(
    gripDomainCategory: GripDomainCategory,
  ): void {
    this.selectedGripDomainCategoryId = gripDomainCategory
      ? gripDomainCategory.id
      : null;
  }

  public barrierLinkTextInput(): void {
    this.count++;
    this.barrierLinks.push({
      url: this.link,
      count: this.count,
      isValid: true,
      errorMessage: '',
    });
  }

  public removeLinkTextInput(index: number): void {
    this.barrierLinks.splice(index, 1);
    this.areLinksValid = !this.barrierLinks.some((link) => !link.isValid);
  }

  public getGripBarrierLinks(): void {
    const linkLists: string[] = [];
    this.barrierLinks.map((value: LinkDTO) => {
      if (value.url) {
        linkLists.push(value.url);
      }
    });
    this.dirtyGripBarrier.links = linkLists;
  }

  public getBarrierAttachments(): void {
    const updateFileAttachment: string[] = [];
    this.fileAttachment.forEach((element: FileAttachmentDTO) => {
      updateFileAttachment.push(element.type);
    });
    this.dirtyGripBarrier.attachments = updateFileAttachment;
  }

  public validateData() {
    if (!this.dirtyGripBarrier.title.trim().length) {
      this.errorMessage.title = 'This Field is Required';
    } else {
      this.errorMessage.title = null;
    }

    if (
      this.dirtyGripBarrier.description &&
      this.wordCount(this.dirtyGripBarrier.description.trim()) > 150
    ) {
      this.errorMessage.description = 'Max 150 words are allowed.';
    } else {
      this.errorMessage.description = null;
    }

    if (!this.dirtyGripBarrier.action) {
      if (
        this.dirtyGripBarrier.reason &&
        this.dirtyGripBarrier.reason.trim().length
      ) {
        if (
          this.dirtyGripBarrier.reason &&
          this.wordCount(this.dirtyGripBarrier.reason.trim()) <= 50
        ) {
          this.errorMessage.reason = null;
        } else {
          this.errorMessage.reason = 'Max 50 words are allowed.';
        }
      } else {
        this.errorMessage.reason = 'This Field is Required';
      }
    } else {
      this.errorMessage.reason = null;
    }
    return this.errorMessage;
  }

  public validateGripLink(index: number): void {
    const link: LinkDTO = validateLink(this.barrierLinks[index]);
    this.barrierLinks.splice(index, 1, link);
    this.areLinksValid = !this.barrierLinks.some(
      (barrierLink) => !barrierLink.isValid,
    );
  }

  public onAddGripBarrier(): void {
    this.validateData();
    if (
      !this.errorMessage.title &&
      !this.errorMessage.description &&
      !this.errorMessage.reason &&
      this.areLinksValid
    ) {
      this.getGripBarrierLinks();
      this.getBarrierAttachments();
      this.dirtyGripBarrier.gripDomainCategoryId = this.selectedGripDomainCategoryId;
      this.$emit('create', this.dirtyGripBarrier);
    }
  }

  public onUpdateGripBarrier(): void {
    this.getGripBarrierLinks();
    this.getBarrierAttachments();
    this.dirtyGripBarrier.gripDomainCategoryId = this.selectedGripDomainCategoryId;
    const barrier: { [key: string]: any } = { ...this.dirtyGripBarrier };
    const barrierInfo: { [key: string]: any } = {
      ...this.gripBarrierDetails,
    };

    let updateBarrier: UpdateGripBarrierPayload | any = {};
    Object.keys(barrier).forEach((key: string) => {
      if (key === 'gripDomainCategoryId') {
        if (barrierInfo.gripDomainCategory) {
          if (
            JSON.stringify(barrier.gripDomainCategoryId) !==
            JSON.stringify(barrierInfo.gripDomainCategory.id)
          ) {
            updateBarrier.gripDomainCategoryId = barrier.gripDomainCategoryId;
          }
        } else if (
          barrierInfo.gripDomainCategory !== barrier.gripDomainCategoryId
        ) {
          updateBarrier.gripDomainCategoryId = barrier.gripDomainCategoryId;
        }
      } else {
        if (JSON.stringify(barrier[key]) !== JSON.stringify(barrierInfo[key])) {
          updateBarrier = {
            ...updateBarrier,
            [key]: barrier[key],
          };
        }
      }
    });
    if (Object.keys(updateBarrier).length > 0) {
      this.validateData();
      if (
        !this.errorMessage.title &&
        !this.errorMessage.description &&
        !this.errorMessage.reason &&
        this.areLinksValid
      ) {
        this.$emit('update', {
          id: this.gripBarrierDetails.id,
          ...updateBarrier,
        });
      }
    } else {
      this.$emit('close');
    }
  }

  public mounted(): void {
    this.isDomainCategoriesLoading = true;
    this.getGripDomainCategories(this.projectId);
    if (
      this.gripBarrierDetails &&
      Object.keys(this.gripBarrierDetails).length > 0
    ) {
      const {
        gripDomainCategory,
        id,
        ...barrierInfo
      } = this.gripBarrierDetails;
      barrierInfo.links.forEach((link: string) => {
        this.count++;
        this.barrierLinks.push({
          url: link,
          count: this.count,
          isValid: true,
          errorMessage: '',
        });
      });

      barrierInfo.attachments.forEach((file) => {
        const fileData = file.split('/');
        this.fileAttachment.push({
          type: file,
          name: fileData[fileData.length - 1],
        });
      });

      this.selectedGripDomainCategoryId = gripDomainCategory
        ? gripDomainCategory.id
        : null;

      this.dirtyGripBarrier = {
        ...barrierInfo,
        gripDomainCategoryId: this.selectedGripDomainCategoryId,
      };
    }
  }

  @Watch('getGripDomainCategoriesState.success')
  @isTruthy
  public watchGripDomainCategorySuccess(): void {
    this.isDomainCategoriesLoading = false;
    this.gripDomainCategoryOptions = this.gripDomainCategories
      ? this.gripDomainCategories
      : [];
  }

  @Watch('getGripDomainCategoriesState.error')
  @isTruthy
  public watchGripDomainCategoryError(): void {
    this.isDomainCategoriesLoading = false;
    Toast.open({
      message: `Something went wrong, while fetching Domain/Categories. Try again.`,
      position: 'is-top',
      type: 'is-danger',
      duration: 3500,
    });
  }
}
