import { ProjectSite } from '@/store/modules/projects/types/projects.types';
import { cloneDeep as _cloneDeep, get as _get, map as _map } from 'lodash';
import { CRITERION_TYPE } from '@/store/types/criterions.types';
import dayjs from 'dayjs';

export class GenerateSingleSiteComparisonCsv {
  public dirtyMonths: string[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];

  public create(
    result: any,
    payload: any,
    analysisTitle: string,
    projectTitle: string,
    projectSites: ProjectSite[],
  ) {
    const exportDateTime = dayjs().format('D MMM YYYY, HH:mm A');
    const responseArray: any[] = [];
    responseArray.push({
      column1: 'Project Name',
      column2: projectTitle,
    });
    responseArray.push({
      column1: 'Analysis Name',
      column2: analysisTitle,
    });
    responseArray.push({
      column1: 'Site',
      column2: projectSites[0].site.name,
    });
    const comparisonPeriods = this.getPeriods(payload.comparisonPeriods);
    responseArray.push({
      column1: 'Data Collection Periods',
      column2: this.transformPeriods(comparisonPeriods).join('\n'),
    });
    responseArray.push(
      ...this.generateCriteriaTables(
        result,
        payload,
        projectSites,
        comparisonPeriods,
      ),
    );
    responseArray.push(...this.generateSingleRowSpace());
    responseArray.push({
      column1: 'Exported on ' + exportDateTime,
    });
    return responseArray;
  }

  public generateCriteriaTables(
    result: any,
    payload: any,
    projectSites: ProjectSite[],
    comparisonPeriods: string[],
  ): any {
    const criteriaStatistics: any[] = [];
    const statisticData: any[][] = [];
    const samplingConfigurationData: any[] = [];
    _map(result.comparisonResult, (comparisonResult: any) => {
      const dirtyStatisticData =
        comparisonResult.statisticData.configurationData;
      statisticData.push(dirtyStatisticData);
      samplingConfigurationData.push(
        comparisonResult.statisticData.samplingConfiguration,
      );
    });
    const {
      comparisonAggregateCriteriaConfiguration,
    } = this.generateComparisonConfigurationData(
      statisticData,
      payload,
      samplingConfigurationData,
    );
    comparisonAggregateCriteriaConfiguration.forEach(
      (criteriaDetails, criteriaDetailsIndex) => {
        criteriaStatistics.push(...this.generateMultipleRowsSpace());
        criteriaStatistics.push({
          column1: criteriaDetailsIndex + 1 + '. ' + criteriaDetails[0].title,
        });
        criteriaStatistics.push({
          column1: 'Answer Choices',
          column2: projectSites[0].site.name,
        });
        const periodRow: any = {
          column1: '',
        };
        comparisonPeriods.forEach((period: any, periodIndex: number) => {
          periodRow['column' + (periodIndex + 2)] = period
            .split(':')
            .join('\n');
        });
        criteriaStatistics.push(periodRow);
        const optionsList: string[] = [];
        const totalList: number[] = [];
        const complianceList: number[] = [];
        const valuesList: any[] = [];
        const trueValueList: number[][] = [];
        const naValueList: number[][] = [];
        const samplingStatistics: any = [];
        criteriaDetails.forEach(
          (criteriaDetailPeriod: any, criteriaDetailPeriodIndex: number) => {
            let total: number = 0;
            let trueValue: number = 0;
            let naValue: number = 0;
            criteriaDetailPeriod.criteriaSamplingData.forEach(
              (optionsData: any, optionDataIndex: number) => {
                const option = Object.keys(optionsData)[0];
                if (!optionsList.includes(option)) {
                  optionsList.push(option);
                }
                switch (optionDataIndex) {
                  case 0:
                    trueValue = optionsData[option];
                    break;
                  case 1:
                    break;
                  default:
                    naValue = optionsData[option];
                }
                total += optionsData[option];
                if (valuesList[criteriaDetailPeriodIndex]) {
                  valuesList[criteriaDetailPeriodIndex] = {
                    ...valuesList[criteriaDetailPeriodIndex],
                    ...optionsData,
                  };
                } else {
                  valuesList[criteriaDetailPeriodIndex] = optionsData;
                }
              },
            );
            totalList.push(total);
            if (trueValueList[criteriaDetailPeriodIndex]) {
              trueValueList[criteriaDetailPeriodIndex].push(trueValue);
            } else {
              trueValueList[criteriaDetailPeriodIndex] = [trueValue];
            }
            if (naValueList[criteriaDetailPeriodIndex]) {
              naValueList[criteriaDetailPeriodIndex].push(naValue);
            } else {
              naValueList[criteriaDetailPeriodIndex] = [naValue];
            }
            if (criteriaDetailPeriod.criteriaType === CRITERION_TYPE.BOOLEAN) {
              const compliance = (trueValue / (total - naValue)) * 100;
              complianceList.push(compliance ? compliance : 0);
            }
            samplingStatistics.push(
              this.generateSamplingTable(
                criteriaDetailPeriod,
                criteriaDetailPeriodIndex,
                payload.comparisonPeriods,
                comparisonPeriods,
              ),
            );
          },
        );
        optionsList.forEach((optionName: string, optionNameIndex: number) => {
          const rowObject: any = {
            column1: optionName,
          };
          comparisonPeriods.forEach((period: any, periodIndex: number) => {
            const optionValue: number = valuesList[periodIndex][optionName]
              ? valuesList[periodIndex][optionName]
              : 0;
            rowObject['column' + (periodIndex + 2)] = optionValue;
          });
          criteriaStatistics.push(rowObject);
        });
        const totalRow: any = {
          column1: 'Total Data Collected',
        };
        totalList.forEach((total: number, totalIndex: number) => {
          let formattedTotal;
          if (total.toString().includes('-')) {
            formattedTotal = '-';
          } else {
            formattedTotal = total;
          }
          totalRow['column' + (totalIndex + 2)] = formattedTotal;
        });
        criteriaStatistics.push(totalRow);
        if (criteriaDetails[0].criteriaType === CRITERION_TYPE.BOOLEAN) {
          const complianceRow: any = {
            column1: 'Compliance',
          };
          complianceList.forEach(
            (compliance: number, complianceIndex: number) => {
              complianceRow['column' + (complianceIndex + 2)] =
                compliance + '%';
            },
          );
          criteriaStatistics.push(complianceRow);
        }
        // sampling table
        criteriaStatistics.push(...this.generateSingleRowSpace());
        criteriaStatistics.push({
          column1: 'Sampling',
        });
        let samplingTypeRowCount: number = 1;
        let samplingDataRowCount: number = 1;
        let samplingPeriodRowCount: number = 1;
        const samplingTypeRow: any = {};
        const samplingDataRow: any = {};
        const samplingPeriodRow: any = {};
        samplingStatistics.forEach((samplingInfo: any) => {
          Object.keys(samplingInfo[0]).forEach((option: string) => {
            samplingPeriodRow['column' + samplingPeriodRowCount] =
              samplingInfo[0][option];
            samplingPeriodRowCount += 1;
          });
          Object.keys(samplingInfo[1]).forEach((option: string) => {
            samplingTypeRow['column' + samplingTypeRowCount] =
              samplingInfo[1][option];
            samplingTypeRowCount += 1;
          });
          Object.keys(samplingInfo[2]).forEach((option: string) => {
            samplingDataRow['column' + samplingDataRowCount] =
              samplingInfo[2][option];
            samplingDataRowCount += 1;
          });
        });
        criteriaStatistics.push(samplingPeriodRow);
        criteriaStatistics.push(samplingTypeRow);
        criteriaStatistics.push(samplingDataRow);
      },
    );
    return criteriaStatistics;
  }

  public generateSamplingTable(
    criteriaDetailPeriod: any,
    criteriaDetailPeriodIndex: number,
    periodsInfo: any,
    formattedPeriods: string[],
  ): any {
    const isCustomPeriod: boolean = !periodsInfo[criteriaDetailPeriodIndex]
      .isAudit;
    const samplingStatistics: any = [];
    if (isCustomPeriod) {
      samplingStatistics.push({
        column1: formattedPeriods[criteriaDetailPeriodIndex]
          .split(':')
          .join('\n'),
        column2: '',
      });
      samplingStatistics.push({
        column1: 'Site',
        column2: 'Sampling',
      });
      samplingStatistics.push({
        column1:
          criteriaDetailPeriod.siteSamplingConfiguration[0].auditSiteMap.site
            .name,
        column2: '-',
      });
    } else {
      switch (criteriaDetailPeriod.samplingConfiguration.auditSamplingType) {
        case 'adHoc':
          samplingStatistics.push({
            column1: formattedPeriods[criteriaDetailPeriodIndex]
              .split(':')
              .join('\n'),
            column2: '',
          });
          samplingStatistics.push({
            column1: 'Site',
            column2: 'Sampling',
          });
          samplingStatistics.push({
            column1:
              criteriaDetailPeriod.siteSamplingConfiguration[0].auditSiteMap
                .site.name,
            column2: criteriaDetailPeriod.criteriaSamplingDataConfiguration
              .isSamplingEnabled
              ? 'Enabled'
              : 'Disabled',
          });
          break;
        case 'consecutive':
          if (
            criteriaDetailPeriod.samplingConfiguration.samplingMode ===
            'minAndMax'
          ) {
            samplingStatistics.push({
              column1: formattedPeriods[criteriaDetailPeriodIndex]
                .split(':')
                .join('\n'),
              column2: '',
              column3: '',
            });
            samplingStatistics.push({
              column1: 'Site',
              column2: 'Min.',
              column3: 'Max.',
            });
            samplingStatistics.push({
              column1:
                criteriaDetailPeriod.siteSamplingConfiguration[0].auditSiteMap
                  .site.name,
              column2:
                criteriaDetailPeriod.criteriaSamplingDataConfiguration
                  .minSampleSize,
              column3:
                criteriaDetailPeriod.criteriaSamplingDataConfiguration
                  .maxSampleSize,
            });
          } else {
            samplingStatistics.push({
              column1: formattedPeriods[criteriaDetailPeriodIndex]
                .split(':')
                .join('\n'),
              column2: '',
            });
            samplingStatistics.push({
              column1: 'Site',
              column2: 'Target',
            });
            samplingStatistics.push({
              column1:
                criteriaDetailPeriod.siteSamplingConfiguration[0].auditSiteMap
                  .site.name,
              column2:
                criteriaDetailPeriod.criteriaSamplingDataConfiguration
                  .sampleSize,
            });
          }
          break;
        default:
          samplingStatistics.push({
            column1: formattedPeriods[criteriaDetailPeriodIndex]
              .split(':')
              .join('\n'),
            column2: '',
          });
          samplingStatistics.push({
            column1: 'Site',
            column2: 'Target',
          });
          samplingStatistics.push({
            column1:
              criteriaDetailPeriod.siteSamplingConfiguration[0].auditSiteMap
                .site.name,
            column2:
              criteriaDetailPeriod.criteriaSamplingDataConfiguration.sampleSize,
          });
      }
    }
    return samplingStatistics;
  }

  public generateComparisonConfigurationData(
    configurationData: any[],
    payload: any,
    samplingConfigurationData: any[],
  ) {
    let comparisonAggregateCriteriaConfiguration: any[];
    if (
      Array.isArray(configurationData) &&
      configurationData.length === payload.comparisonPeriods.length
    ) {
      const aggregateConfiguration: any[] = [];
      _map(payload.filteredCriterias, (criteriaId, p) => {
        const aggregateCriteriaConfiguration: any[] = [];
        _map(configurationData, (configData, index) => {
          let dirtyAggregateConfiguration: any = {};
          configData.forEach((data: any) => {
            if (data.projectCriteria.id === criteriaId) {
              const criteriaOptionsDataDistribution =
                data.criteriaSamplingData.criteriaOptionsDataDistribution;
              const criteriaSamplingData = Object.keys(
                criteriaOptionsDataDistribution,
              ).map((key: string) => ({
                [key]:
                  data.criteriaSamplingData.criteriaOptionsDataDistribution[
                    key
                  ],
              }));
              const dirtyConfig = {
                title: _get(data, 'projectCriteria.title', ''),
                criteriaType: _get(data, 'projectCriteria.criteriaType', ''),
                sampleSize: Number(
                  _get(
                    data,
                    'criteriaSamplingDataConfigurations.sampleSize',
                    0,
                  ),
                ),
                siteSamplingConfiguration: data.siteSamplingDataConfigurations,
                criteriaSamplingDataConfiguration:
                  data.criteriaSamplingDataConfigurations,
                samplingConfiguration: samplingConfigurationData[index],
                criteriaSamplingData,
                isAuditCriteria: data.isAuditCriteria,
              };
              dirtyAggregateConfiguration = _cloneDeep(dirtyConfig);
            }
          });
          aggregateCriteriaConfiguration.push(dirtyAggregateConfiguration);
        });
        aggregateConfiguration.push(aggregateCriteriaConfiguration);
      });
      comparisonAggregateCriteriaConfiguration = _cloneDeep(
        aggregateConfiguration,
      );
    } else {
      comparisonAggregateCriteriaConfiguration = [];
    }
    return { comparisonAggregateCriteriaConfiguration };
  }

  public generateSingleRowSpace(): any {
    return [{}];
  }

  public generateMultipleRowsSpace(): any {
    return [{}, {}, {}];
  }

  public getPeriods(comparisonPeriods: any): any {
    const periodsInfo: string[] = [];
    comparisonPeriods.forEach((periodInfo: any, periodIndex: number) => {
      periodsInfo.push(
        periodInfo.title + ': ' + this.formatDateRange(periodInfo),
      );
    });
    return periodsInfo;
  }

  public transformPeriods(comparisonPeriods: string[]): string[] {
    const transformedPeriods: string[] = [];
    comparisonPeriods.forEach((period: string, periodIndex: number) => {
      transformedPeriods.push(periodIndex + 1 + '. ' + period);
    });
    return transformedPeriods;
  }

  public formatDateRange(dateObject: any): string {
    const startDate = new Date(dateObject.startedAt);
    let endDate = dateObject.endedAt;
    if (endDate === null) {
      endDate = dayjs().endOf('day').format();
    }
    return this.getDate(startDate) + ' - ' + this.getDate(new Date(endDate));
  }

  public getDate(newValue: Date): string {
    return (
      newValue.getDate() +
      ' ' +
      this.dirtyMonths[newValue.getMonth()] +
      ' ' +
      newValue.getFullYear()
    );
  }
}
