














































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { ToastProgrammatic as Toast } from 'buefy';
import EnablerList from './EnablerList.vue';
import BasePaginatorHoc from '../../../components/base/BasePaginatorHoc.vue';
import {
  CreateGripEnablerBase,
  CreateGripEnablerPayload,
  GetEnablersPayload,
  GripEnabler,
} from '../../../store/types/grip.types';
import EnablerModal from './EnablerModal.vue';
import {
  ApiState,
  Pagination,
  PaginatorDto,
} from '../../../store/types/general.types';
import { Debounce } from '../../../jbi-shared/util/debounce.vue-decorator';
import BaseLoading from '../../../components/base/BaseLoading.vue';
import {
  isDifferentDeep,
  isTruthy,
} from '../../../jbi-shared/util/watcher.vue-decorator';

@Component({
  components: { BaseLoading, BasePaginatorHoc, EnablerList, EnablerModal },
})
export default class EnablerSettings extends Vue {
  public isLoading: boolean = true;
  public sortColumn: string = 'title';
  public sortOrder: 'ASC' | 'DESC' = 'ASC';
  public perPage: number = 50;
  public page: number = 1;
  public totalCount: number = 0;
  public searchTitle: string = '';
  public filteredParams: GetEnablersPayload = {
    title: this.searchTitle,
    limit: this.perPage,
    page: this.page,
  };
  public modalKey: number = Math.floor(Math.random() * 999);
  public isModalActive = false;

  @Action('projects/getGripEnablers')
  public getGripEnablers!: (payload: {
    projectId: number;
    params: GetEnablersPayload;
  }) => void;

  @State((state) => state.projects.gripEnablers)
  public gripEnablers!: Pagination<GripEnabler>;

  @State((state) => state.projects.apiState.getGripEnablers)
  public getGripEnablersApiState!: ApiState;

  @Action('projects/createGripEnabler')
  public createGripEnabler!: (payload: CreateGripEnablerPayload) => void;

  @State((state) => state.projects.apiState.createGripEnabler)
  public createGripEnablerApiState!: ApiState;

  get EnablerList() {
    return EnablerList;
  }

  get projectId(): number {
    return +this.$route.params.projectId;
  }

  get getGripEnablerList(): GripEnabler[] {
    this.totalCount = this.gripEnablers ? this.gripEnablers.totalItems : 0;
    return this.gripEnablers && this.gripEnablers.items.length
      ? this.gripEnablers.items
      : [];
  }

  public closeEnablerModal(): void {
    this.isModalActive = false;
  }

  public openEnablerModal(): void {
    this.isModalActive = true;
    this.modalKey += 1;
  }

  public handleEnablerCreate(gripEnabler: CreateGripEnablerBase) {
    this.createGripEnabler({ ...gripEnabler, projectId: this.projectId });
    this.closeEnablerModal();
  }

  public mounted(): void {
    this.getFilteredGripEnablers();
  }

  public handlePaginator({ perPage, page }: PaginatorDto) {
    this.page = page;
    this.perPage = perPage;
    this.filteredParams = {
      title: this.searchTitle,
      limit: this.perPage,
      page: this.page,
    };
  }

  public getFilteredGripEnablers(): void {
    this.getGripEnablers({
      projectId: this.projectId,
      params: this.filteredParams,
    });
  }

  @Watch('filteredParams', { deep: true })
  @isDifferentDeep
  public watchFilteredParams() {
    this.getFilteredGripEnablers();
  }

  @Watch('searchTitle')
  @Debounce(500)
  public onTitleSearch(): void {
    this.filteredParams = {
      title: this.searchTitle,
      limit: this.perPage,
      page: this.page,
    };
  }

  @Watch('getGripEnablersApiState.loading')
  public watchGetGripEnablersLoading(): void {
    this.isLoading = true;
  }

  @Watch('getGripEnablersApiState.success')
  public watchGetGripEnablersSuccess(): void {
    this.isLoading = false;
  }

  @Watch('createGripEnablerApiState.success')
  @isTruthy
  public onCreateEnablerSuccess(): void {
    this.getFilteredGripEnablers();
    Toast.open({
      message: `Enabler added`,
      position: 'is-top',
      type: 'is-dark',
      duration: 3500,
    });
  }

  @Watch('createGripEnablerApiState.error')
  public onCreateEnablerError(): void {
    this.$emit('close');
  }
}
