






































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import CriteriaFilter from '@/views/Audit/components/CriteriaFilter.vue';
import { AuditDetail } from '@/store/modules/audits/types/audits.types';
import { cloneDeep as _cloneDeep, map as _map } from 'lodash';
import BasePagination from '@/components/base/BasePagination.vue';
import { useUserEmail } from '@/utils/user.util';
import moment from 'moment';
import { CRITERION_TYPE } from '../../../store/types/criterions.types';
import CriteriaViewUpdateComponent from '../../Project/components/CriteriaViewUpdateComponent.vue';

const dirtyMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

@Component({
  components: {
    CriteriaFilter,
    BasePagination,
    CriteriaViewUpdateComponent,
  },
})
export default class DataCollectionActivityLog extends Vue {
  @Prop()
  public dataCollectionActivityLogs!: any[];
  @Prop()
  public auditDetail!: AuditDetail;
  @Prop({ default: true })
  public editable!: boolean;
  @Prop({ default: false })
  public isDataCollectionActive!: boolean;

  public nowDate: Date = new Date();
  public dirtyLogs: any[] = [];
  public isRecordOpen: boolean = false;

  public mounted() {
    this.dirtyLogs = _cloneDeep(this.dataCollectionActivityLogs);
  }

  @Watch('dataCollectionActivityLogs')
  public watchDataCollectionActivityLogs(newValue: any) {
    this.dirtyLogs = _cloneDeep(newValue);
  }

  public getOptionValues(
    dataCollectionRecords: any,
    dataCollectionType: string,
  ) {
    let optionValues = JSON.parse(dataCollectionRecords.optionValues);
    if (
      (dataCollectionRecords.auditCriteria.criteria.criteriaType ===
        CRITERION_TYPE.RANGE ||
        dataCollectionRecords.auditCriteria.criteria.criteriaType ===
          CRITERION_TYPE.CONTINUOUS_DISCRETE) &&
      dataCollectionType === 'individual'
    ) {
      optionValues = optionValues.filter(
        (optionValue: any) => optionValue.value === 1,
      );
    }
    return optionValues;
  }

  public editDataCollectionRecord(rowData: any) {
    this.$emit('editDataCollectionRecord', rowData);
  }

  public deleteDataCollectionRecord(rowData: any) {
    this.$emit('deleteDataCollectionRecord', rowData);
  }

  public formatDate(date: Date) {
    return this.getDate(date);
  }

  public formatTime(date: Date) {
    return this.getTime(date);
  }

  public getPureDate(date: Date | string): number {
    return new Date(date).setHours(0, 0, 0, 0);
  }

  public getDate(newValue: Date) {
    return (
      newValue.getDate() +
      ' ' +
      dirtyMonths[newValue.getMonth()] +
      ' ' +
      newValue.getFullYear()
    );
  }

  public getTime(newValue: Date) {
    return (
      newValue.getHours() +
      ':' +
      ((newValue.getMinutes() < 10 ? '0' : '') + newValue.getMinutes())
    );
  }

  public getInteger(value: string) {
    return parseInt(value, 10) ? Math.round(parseInt(value, 10)) : 0;
  }

  public getDateTime(value: string) {
    const dateDiff = this.nowDate.getTime() - new Date(value).getTime();
    const isYesterday: boolean =
      this.getPureDate(moment(this.nowDate).subtract(1, 'day').toDate()) ===
      this.getPureDate(value);
    const isSameDay =
      this.getPureDate(this.nowDate) === this.getPureDate(value);
    if (isSameDay) {
      const seconds = Math.floor(dateDiff / 1000);
      let interval = seconds / 3600;
      if (interval > 1) {
        return (
          Math.floor(interval) +
          (Math.floor(interval) > 2 ? ' hours ago' : ' hour ago')
        );
      }
      interval = seconds / 60;
      return (
        Math.floor(interval) +
        (Math.floor(interval) > 2 ? ' minutes ago' : ' minute ago')
      );
    }
    return isYesterday ? 'Yesterday' : this.getDate(new Date(value));
  }

  public getTitle(value: any) {
    const currentUserEmail = this.userEmail;
    const titleValue = [];
    const userEmail = value.user.email;
    let dirtyTotal = 0;
    _map(value.dataCollectionRecords, (tempRecord) => {
      dirtyTotal += parseInt(tempRecord.total, 10);
    });
    if (currentUserEmail === userEmail) {
      titleValue.push('You');
    } else {
      titleValue.push(userEmail);
    }
    if (dirtyTotal === 1) {
      titleValue.push('added ' + dirtyTotal + ' response');
    } else {
      titleValue.push('added ' + dirtyTotal + ' responses');
    }
    return titleValue;
  }

  public getDataRecordedRowinfo(value: any) {
    const recordedStartDateTime = new Date(value.recordedStartDateTime);
    const recordedEndDateTime = value.recordedEndDateTime
      ? new Date(value.recordedEndDateTime)
      : new Date(value.recordedStartDateTime);
    const siteName = value.auditSite.site.name;
    switch (this.auditDetail.auditDataTimestamp) {
      case 'Single Date':
        return `Data recorded on ${this.formatDate(
          recordedStartDateTime,
        )} at ${siteName}`;
      case 'Single Date with Time':
        return `Data recorded on ${this.formatDate(recordedStartDateTime)} ;
                            ${this.formatTime(
                              recordedStartDateTime,
                            )} at ${siteName}`;
      case 'Date Range':
        return `Data recorded between ${this.formatDate(
          recordedStartDateTime,
        )} and
                            ${this.formatDate(
                              recordedEndDateTime,
                            )} at ${siteName}`;
      case 'Date Range with Time':
        return `Data recorded between ${this.formatDate(
          recordedStartDateTime,
        )} ;
                            ${this.formatTime(recordedStartDateTime)} and
                            ${this.formatDate(recordedEndDateTime)} ;
                            ${this.formatTime(
                              recordedEndDateTime,
                            )} at ${siteName}`;
      default:
        return `Data recorded at ${siteName}`;
    }
  }

  get userEmail(): string | undefined {
    return useUserEmail.call(this);
  }
}
