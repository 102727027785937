















































































































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import {
  MultipleRecurrenceFrequencyOptions,
  RecurrenceSettingPayload,
  RecurrenceType,
  DataCollectionPeriodPayload,
} from '@/store/modules/projects/types/projects.types';
import { StringInputOption } from '@/jbi-shared/types/form.types';
import { cloneDeep as _cloneDeep, find as _find } from 'lodash';
import ProjectCreationMultipleDatePicker from './ProjectCreationMultipleDatepicker.vue';
import moment from 'moment';
import { getStartDateTime, getEndDateTime } from '../../../utils/date.util';
const dirtyMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

@Component({
  components: {
    ProjectCreationMultipleDatePicker,
  },
})
export default class RecurrenceSettings extends Vue {
  @Prop()
  public recurrenceSetting!: RecurrenceSettingPayload;
  @Prop()
  public draftRecurrenceSetting!: RecurrenceSettingPayload;

  public minStartDate: Date = new Date(
    new Date().setDate(new Date().getDate() - 1),
  );
  public projectExpiryDatePicker: any = null;
  public auditSamplingTypesOptions = {
    targetSampleSize: 'targetSampleSize',
    adHoc: 'adHoc',
    consecutive: 'consecutive',
  };
  public dirtyAuditSamplingType = this.auditSamplingTypesOptions
    .targetSampleSize;
  public defaultEndDate: any = moment(new Date()).add(1, 'M');
  public dataCollectionPeriodDateTotal: DataCollectionPeriodPayload = {
    auditNumber: 1,
    auditName: 'Baseline',
    startDate: getStartDateTime(),
    endDate: getEndDateTime(this.defaultEndDate.subtract(1, 'd')),
  };
  public dirtyRecurrenceSetting: RecurrenceSettingPayload = {
    recurrenceType: 'oneOff',
    multipleRecurrenceType: 'Monthly',
    auditSamplingType: this.dirtyAuditSamplingType,
    interval: 1,
    auditNumber: 1,
    scheduledRecurrenceStartDate: getStartDateTime(),
    enableGrip: true,
    multipleDataCollectionPeriod: [
      {
        ...this.dataCollectionPeriodDateTotal,
      },
    ],
  };
  public totalDataCollectionDatepicker: any = [];
  public datePickerKey: number = Math.floor(Math.random() * 999);
  public isDraftProject: boolean = false;
  public showIntervalError: boolean = false;
  public showTotalError: boolean = false;

  public mounted() {
    if (this.recurrenceSetting !== undefined) {
      this.dirtyRecurrenceSetting = _cloneDeep(this.recurrenceSetting);
      this.dirtyRecurrenceSetting.scheduledRecurrenceStartDate = getStartDateTime();
    }
    if (this.draftRecurrenceSetting !== null) {
      this.dirtyRecurrenceSetting = _cloneDeep(this.draftRecurrenceSetting);
      this.isDraftProject = true;
    }
  }

  get recurrenceOptions(): StringInputOption[] {
    return Object.entries(RecurrenceType).map(([key, value]) => {
      return {
        id: key,
        name: value,
      };
    });
  }

  get recurrenceType() {
    return this.dirtyRecurrenceSetting.recurrenceType;
  }

  set recurrenceType(newValue) {
    this.dirtyRecurrenceSetting.recurrenceType = newValue;
    this.dirtyRecurrenceSetting.auditNumber = 1;
    this.dirtyRecurrenceSetting.interval = 1;
    this.dirtyRecurrenceSetting.multipleRecurrenceType = 'Monthly';
    if (newValue === 'multiple') {
      this.updateTotalDataCollectionPeriod();
    }
    this.$emit('updateRecurenceSetting', this.dirtyRecurrenceSetting);
  }

  get scheduledRecurrenceStartDate() {
    const recurrenceStartDate: any = this.dirtyRecurrenceSetting
      .scheduledRecurrenceStartDate;
    return recurrenceStartDate !== undefined && recurrenceStartDate
      ? getStartDateTime(recurrenceStartDate)
      : getStartDateTime();
  }
  set scheduledRecurrenceStartDate(newValue) {
    this.dirtyRecurrenceSetting.scheduledRecurrenceStartDate = newValue;
    this.datePickerKey += 1;
    this.$emit('updateRecurenceSetting', this.dirtyRecurrenceSetting);
  }

  get totalMultipleDataCollection() {
    return this.dirtyRecurrenceSetting.multipleDataCollectionPeriod
      ? this.dirtyRecurrenceSetting.multipleDataCollectionPeriod
      : this.updateTotalDataCollectionPeriod();
  }

  public multiRecurrenceFrequencyValueFromKey(key: string) {
    const objectValue: any = _find(this.multipleRecurrenceFrequencyOptions, [
      'id',
      key,
    ]);
    return objectValue.name;
  }

  get multipleRecurrenceFrequencyOptions(): StringInputOption[] {
    return Object.entries(MultipleRecurrenceFrequencyOptions).map(
      ([key, value]) => {
        return {
          id: key,
          name: value,
        };
      },
    );
  }

  get multipleRecurrenceType() {
    return this.dirtyRecurrenceSetting.multipleRecurrenceType !== undefined &&
      this.dirtyRecurrenceSetting.multipleRecurrenceType
      ? this.dirtyRecurrenceSetting.multipleRecurrenceType
      : 'Monthly';
  }
  set multipleRecurrenceType(newValue) {
    this.dirtyRecurrenceSetting.multipleRecurrenceType = newValue;
    this.datePickerKey += 1;
    this.$emit('updateRecurenceSetting', this.dirtyRecurrenceSetting);
  }

  get interval(): number {
    return this.dirtyRecurrenceSetting.interval
      ? this.dirtyRecurrenceSetting.interval
      : 0;
  }

  set interval(newValue: number) {
    this.dirtyRecurrenceSetting.interval = newValue;
    this.showIntervalError = !newValue || newValue < 1 ? true : false;
    this.datePickerKey += 1;
    this.$emit('updateRecurenceSetting', this.dirtyRecurrenceSetting);
  }

  get auditNumber(): number {
    return this.dirtyRecurrenceSetting.auditNumber
      ? this.dirtyRecurrenceSetting.auditNumber
      : 0;
  }

  set auditNumber(newValue: number) {
    this.dirtyRecurrenceSetting.auditNumber = newValue;
    this.showTotalError = newValue < 1 || newValue > 10;
    this.datePickerKey += 1;
    this.$emit('updateRecurenceSetting', this.dirtyRecurrenceSetting);
  }

  @Watch('dirtyAuditSamplingType')
  public watchDirtySamplingType(newValue: string) {
    this.dirtyRecurrenceSetting.auditSamplingType = newValue;
    this.$emit('updateRecurenceSetting', this.dirtyRecurrenceSetting);
  }

  public updateDatePeriod(newValue: any) {
    this.dirtyRecurrenceSetting.multipleDataCollectionPeriod[
      newValue.auditNumber
    ] = _cloneDeep(newValue);
    this.$emit('updateRecurenceSetting', this.dirtyRecurrenceSetting);
  }

  public getDate(newValue: Date): string {
    return (
      newValue.getDate() +
      ' ' +
      dirtyMonths[newValue.getMonth()] +
      ' ' +
      newValue.getFullYear()
    );
  }

  public formatDateRange(date: Date): string {
    return this.getDate(date);
  }

  public formatDataCollectionDateRange(date: Date[]): string {
    return this.getDate(date[0]) + ' - ' + this.getDate(date[1]);
  }

  public updateTotalDataCollectionPeriod() {
    if (this.recurrenceType !== 'multiple') {
      return;
    }
    let auditNumberValue =
      this.isDraftProject &&
      this.dirtyRecurrenceSetting.auditNumber !== undefined
        ? this.dirtyRecurrenceSetting.auditNumber
        : this.auditNumber;
    if (auditNumberValue > 10) {
      auditNumberValue = 0;
    }
    const intervalValue =
      this.isDraftProject && this.dirtyRecurrenceSetting.interval !== undefined
        ? this.dirtyRecurrenceSetting.interval
        : this.interval;
    this.totalDataCollectionDatepicker = [];
    const endDate = moment(this.scheduledRecurrenceStartDate);
    for (let value = 0; value < auditNumberValue; value++) {
      const startDate =
        value > 0 ? endDate.add(1, 'd').toDate() : endDate.toDate();
      const newAuditName = value > 0 ? `Follow Up ${value}` : 'Baseline';
      switch (this.multipleRecurrenceType) {
        case Object.keys(MultipleRecurrenceFrequencyOptions)[0]:
          endDate.add(intervalValue, 'd');
          break;
        case Object.keys(MultipleRecurrenceFrequencyOptions)[1]:
          endDate.add(intervalValue, 'w');
          break;
        case Object.keys(MultipleRecurrenceFrequencyOptions)[2]:
          endDate.add(intervalValue, 'M');
          break;
        default:
          endDate.add(intervalValue, 'y');
          break;
      }
      const newEndDate = endDate.subtract(1, 'd').toDate();
      const dateRangePeriod: any = [startDate, newEndDate];
      this.totalDataCollectionDatepicker.push({
        startDate: getStartDateTime(dateRangePeriod[0]),
        endDate: getEndDateTime(dateRangePeriod[1]),
        auditNumber: value + 1,
        auditName: newAuditName,
      });
    }
    const multipleAuditsDate = {
      ...this.totalDataCollectionDatepicker,
    };
    this.dirtyRecurrenceSetting.multipleDataCollectionPeriod = multipleAuditsDate;
    this.$emit('updateRecurenceSetting', this.dirtyRecurrenceSetting);
    return multipleAuditsDate;
  }
}
