














import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class Card extends Vue {
  @Prop({ default: false })
  public center!: boolean;

  @Prop({ default: false })
  public shadowless!: boolean;

  @Prop({ default: false })
  public borderless!: boolean;

  @Prop({ default: false })
  public radiusless!: boolean;
}
