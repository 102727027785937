import { ProjectSite } from '@/store/modules/projects/types/projects.types';
import { cloneDeep as _cloneDeep, get as _get, map as _map } from 'lodash';
import { CRITERION_TYPE } from '@/store/types/criterions.types';
import dayjs from 'dayjs';

export class GenerateMultiSiteComparisonCsv {
  public dirtyMonths: string[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];

  public create(
    result: any,
    payload: any,
    analysisTitle: string,
    projectTitle: string,
    projectSites: ProjectSite[],
  ) {
    const exportDateTime = dayjs().format('D MMM YYYY, HH:mm A');
    const responseArray: any[] = [];
    responseArray.push({
      column1: 'Project Name',
      column2: projectTitle,
    });
    responseArray.push({
      column1: 'Analysis Name',
      column2: analysisTitle,
    });
    const projectSitesNames: string[] = [];
    projectSites.forEach((projectSite: ProjectSite) => {
      projectSitesNames.push(projectSite.site.name);
    });
    const selectedSites: string[] = [];
    payload.filteredSites.forEach((projectSiteMapId: number) => {
      const projectSiteMap = projectSites.find(
        (projectSite: ProjectSite) => projectSite.id === projectSiteMapId,
      );
      if (projectSiteMap) {
        selectedSites.push(projectSiteMap.site.name);
      }
    });
    responseArray.push({
      column1: 'Site',
      column2: selectedSites.join('\n'),
    });
    responseArray.push({
      column1: 'Site Display Mode',
      column2: payload.checkIndividualSite ? 'Individual' : 'Aggregate',
    });
    const comparisonPeriods = this.getPeriods(payload.comparisonPeriods);
    responseArray.push({
      column1: 'Data Collection Periods',
      column2: this.transformPeriods(comparisonPeriods).join('\n'),
    });
    switch (payload.checkIndividualSite) {
      case true:
        responseArray.push(
          ...this.generateIndividualSiteCriteriaTables(
            result,
            payload,
            selectedSites,
            comparisonPeriods,
            projectSitesNames,
          ),
        );
        break;
      default:
        responseArray.push(
          ...this.generateAggregateSiteCriteriaTables(
            result,
            payload,
            comparisonPeriods,
            selectedSites,
            projectSites,
            projectSitesNames,
          ),
        );
    }
    responseArray.push(...this.generateSingleRowSpace());
    responseArray.push({
      column1: 'Exported on ' + exportDateTime,
    });
    return responseArray;
  }

  public generateIndividualSiteCriteriaTables(
    result: any,
    payload: any,
    siteNames: string[],
    comparisonPeriods: string[],
    projectSitesNames: string[],
  ): any {
    const criteriaStatistics: any[] = [];
    const statisticData: any[][] = [];
    const samplingConfigurationData: any[] = [];
    _map(result.comparisonResult, (comparisonResult: any) => {
      const dirtyStatisticData =
        comparisonResult.statisticData.configurationData;
      statisticData.push(dirtyStatisticData);
      samplingConfigurationData.push(
        comparisonResult.statisticData.samplingConfiguration,
      );
    });
    const {
      comparisonAggregateCriteriaConfiguration,
    } = this.generateComparisonConfigurationData(
      statisticData,
      payload,
      samplingConfigurationData,
    );
    comparisonAggregateCriteriaConfiguration.forEach(
      (criteriaDetails, criteriaDetailsIndex) => {
        criteriaStatistics.push(...this.generateMultipleRowsSpace());
        criteriaStatistics.push({
          column1: criteriaDetailsIndex + 1 + '. ' + criteriaDetails[0].title,
        });
        const headerRow: any = {
          column1: 'Answer Choices',
        };
        const periodRow: any = {
          column1: '',
        };
        let columnCount = 2;
        siteNames.forEach((siteName: string, siteNameIndex: number) => {
          comparisonPeriods.forEach((period: string, periodIndex: number) => {
            periodRow['column' + columnCount] = period.split(':').join('\n');
            switch (periodIndex) {
              case 0:
                headerRow['column' + columnCount] = siteName;
                break;
              default:
                headerRow['column' + columnCount] = '';
            }
            columnCount += 1;
          });
        });
        criteriaStatistics.push(headerRow);
        criteriaStatistics.push(periodRow);
        const optionsList: string[] = [];
        const totalList: number[][] = [];
        const complianceList: number[][] = [];
        const valuesList: any[] = [];
        criteriaDetails.forEach(
          (criteriaDetailPeriod: any, criteriaDetailPeriodIndex: number) => {
            const trueValueList: number[] = [];
            const naValueList: number[] = [];
            criteriaDetailPeriod.criteriaSamplingData.forEach(
              (siteData: any, siteDataIndex: number) => {
                const options = Object.keys(
                  siteData.criteriaOptionsDataDistribution,
                );
                trueValueList.push(
                  siteData.criteriaOptionsDataDistribution[options[0]],
                );
                naValueList.push(
                  siteData.criteriaOptionsDataDistribution[options[2]],
                );
                options.forEach((option) => {
                  if (!optionsList.includes(option)) {
                    optionsList.push(option);
                  }
                });
                if (totalList[criteriaDetailPeriodIndex]) {
                  totalList[criteriaDetailPeriodIndex].push(siteData.total);
                } else {
                  totalList[criteriaDetailPeriodIndex] = [siteData.total];
                }
                if (valuesList[criteriaDetailPeriodIndex]) {
                  valuesList[criteriaDetailPeriodIndex].push(
                    siteData.criteriaOptionsDataDistribution,
                  );
                } else {
                  valuesList[criteriaDetailPeriodIndex] = [
                    siteData.criteriaOptionsDataDistribution,
                  ];
                }
              },
            );
            if (criteriaDetailPeriod.criteriaType === CRITERION_TYPE.BOOLEAN) {
              siteNames.forEach((siteName: string, siteNameIndex: number) => {
                const compliance =
                  (trueValueList[siteNameIndex] /
                    (totalList[criteriaDetailPeriodIndex][siteNameIndex] -
                      naValueList[siteNameIndex])) *
                  100;
                if (complianceList[criteriaDetailPeriodIndex]) {
                  complianceList[criteriaDetailPeriodIndex].push(
                    compliance ? compliance : 0,
                  );
                } else {
                  complianceList[criteriaDetailPeriodIndex] = [
                    compliance ? compliance : 0,
                  ];
                }
              });
            }
          },
        );
        optionsList.forEach((optionName: string, optionNameIndex: number) => {
          const rowObject: any = {
            column1: optionName,
          };
          let valuesColumnCount = 2;
          siteNames.forEach((siteName: string, siteNameIndex: number) => {
            comparisonPeriods.forEach((period: any, periodIndex: number) => {
              const optionValue: number =
                valuesList[periodIndex][siteNameIndex] &&
                valuesList[periodIndex][siteNameIndex][optionName]
                  ? valuesList[periodIndex][siteNameIndex][optionName]
                  : 0;
              rowObject['column' + valuesColumnCount] = optionValue;
              valuesColumnCount += 1;
            });
          });
          criteriaStatistics.push(rowObject);
        });
        const totalRow: any = {
          column1: 'Total Data Collected',
        };
        let totalColumnCount = 2;
        siteNames.forEach((siteName: string, siteNameIndex: number) => {
          comparisonPeriods.forEach((period: any, periodIndex: number) => {
            let formattedTotal;
            if (
              totalList[periodIndex][siteNameIndex].toString().includes('-')
            ) {
              formattedTotal = '-';
            } else {
              formattedTotal = totalList[periodIndex][siteNameIndex];
            }
            totalRow['column' + totalColumnCount] = formattedTotal;
            totalColumnCount += 1;
          });
        });
        criteriaStatistics.push(totalRow);
        if (criteriaDetails[0].criteriaType === CRITERION_TYPE.BOOLEAN) {
          const complianceRow: any = {
            column1: 'Compliance',
          };
          let complianceColumnCount = 2;
          siteNames.forEach((siteName: string, siteNameIndex: number) => {
            comparisonPeriods.forEach((period: any, periodIndex: number) => {
              complianceRow['column' + complianceColumnCount] =
                complianceList[periodIndex][siteNameIndex] + '%';
              complianceColumnCount += 1;
            });
          });
          criteriaStatistics.push(complianceRow);
        }
        // sampling table
        criteriaStatistics.push(...this.generateSingleRowSpace());
        criteriaStatistics.push({
          column1: 'Sampling',
        });
        const {
          samplingPeriodRow,
          samplingTypeRow,
          samplingAllSitesRow,
          samplingData,
        } = this.getSamplingTable(
          criteriaDetails,
          payload,
          comparisonPeriods,
          projectSitesNames,
        );
        criteriaStatistics.push(samplingPeriodRow);
        criteriaStatistics.push(samplingTypeRow);
        criteriaStatistics.push(samplingAllSitesRow);
        criteriaStatistics.push(...samplingData);
      },
    );
    return criteriaStatistics;
  }

  public generateAggregateSiteCriteriaTables(
    result: any,
    payload: any,
    comparisonPeriods: string[],
    siteNames: string[],
    projectSites: ProjectSite[],
    projectSitesNames: string[],
  ): any {
    const criteriaStatistics: any[] = [];
    const statisticData: any[][] = [];
    const samplingConfigurationData: any[] = [];
    _map(result.comparisonResult, (comparisonResult: any) => {
      const dirtyStatisticData =
        comparisonResult.statisticData.configurationData;
      statisticData.push(dirtyStatisticData);
      samplingConfigurationData.push(
        comparisonResult.statisticData.samplingConfiguration,
      );
    });
    const {
      comparisonAggregateCriteriaConfiguration,
    } = this.generateComparisonConfigurationData(
      statisticData,
      payload,
      samplingConfigurationData,
    );
    comparisonAggregateCriteriaConfiguration.forEach(
      (criteriaDetails: any, criteriaDetailsIndex: number) => {
        criteriaStatistics.push(...this.generateMultipleRowsSpace());
        criteriaStatistics.push({
          column1: criteriaDetailsIndex + 1 + '. ' + criteriaDetails[0].title,
        });
        let siteText: string;
        switch (siteNames.length) {
          case 1:
            siteText = siteNames[0];
            break;
          case projectSites.length:
            siteText = 'All Sites';
            break;
          default:
            siteText = 'Selected sites (' + siteNames.length + ')';
        }
        criteriaStatistics.push({
          column1: 'Answer Choices',
          column2: siteText,
        });
        const periodRow: any = {
          column1: '',
        };
        comparisonPeriods.forEach((period: string, periodIndex: number) => {
          periodRow['column' + (periodIndex + 2)] = period
            .split(':')
            .join('\n');
        });
        criteriaStatistics.push(periodRow);
        const optionsList: string[] = [];
        const totalList: number[] = [];
        const complianceList: number[] = [];
        const valuesList: any[] = [];
        const trueValueList: number[][] = [];
        const naValueList: number[][] = [];
        criteriaDetails.forEach(
          (criteriaDetailPeriod: any, criteriaDetailPeriodIndex: number) => {
            let total: number = 0;
            let trueValue: number = 0;
            let naValue: number = 0;
            criteriaDetailPeriod.criteriaSamplingData.forEach(
              (optionsData: any, optionDataIndex: number) => {
                const option = Object.keys(optionsData)[0];
                if (!optionsList.includes(option)) {
                  optionsList.push(option);
                }
                switch (optionDataIndex) {
                  case 0:
                    trueValue = optionsData[option];
                    break;
                  case 1:
                    break;
                  default:
                    naValue = optionsData[option];
                }
                total += optionsData[option];
                if (valuesList[criteriaDetailPeriodIndex]) {
                  valuesList[criteriaDetailPeriodIndex] = {
                    ...valuesList[criteriaDetailPeriodIndex],
                    ...optionsData,
                  };
                } else {
                  valuesList[criteriaDetailPeriodIndex] = optionsData;
                }
              },
            );
            totalList.push(total);
            if (trueValueList[criteriaDetailPeriodIndex]) {
              trueValueList[criteriaDetailPeriodIndex].push(trueValue);
            } else {
              trueValueList[criteriaDetailPeriodIndex] = [trueValue];
            }
            if (naValueList[criteriaDetailPeriodIndex]) {
              naValueList[criteriaDetailPeriodIndex].push(naValue);
            } else {
              naValueList[criteriaDetailPeriodIndex] = [naValue];
            }
            if (criteriaDetailPeriod.criteriaType === CRITERION_TYPE.BOOLEAN) {
              const compliance = (trueValue / (total - naValue)) * 100;
              complianceList.push(compliance ? compliance : 0);
            }
          },
        );
        optionsList.forEach((optionName: string, optionNameIndex: number) => {
          const rowObject: any = {
            column1: optionName,
          };
          comparisonPeriods.forEach((period: any, periodIndex: number) => {
            const optionValue: number = valuesList[periodIndex][optionName]
              ? valuesList[periodIndex][optionName]
              : 0;
            rowObject['column' + (periodIndex + 2)] = optionValue;
          });
          criteriaStatistics.push(rowObject);
        });
        const totalRow: any = {
          column1: 'Total Data Collected',
        };
        totalList.forEach((total: number, totalIndex: number) => {
          let formattedTotal;
          if (total.toString().includes('-')) {
            formattedTotal = '-';
          } else {
            formattedTotal = total;
          }
          totalRow['column' + (totalIndex + 2)] = formattedTotal;
        });
        criteriaStatistics.push(totalRow);
        if (criteriaDetails[0].criteriaType === CRITERION_TYPE.BOOLEAN) {
          const complianceRow: any = {
            column1: 'Compliance',
          };
          complianceList.forEach(
            (compliance: number, complianceIndex: number) => {
              complianceRow['column' + (complianceIndex + 2)] =
                compliance + '%';
            },
          );
          criteriaStatistics.push(complianceRow);
        }
        // sampling table
        criteriaStatistics.push(...this.generateSingleRowSpace());
        criteriaStatistics.push({
          column1: 'Sampling',
        });
        const {
          samplingPeriodRow,
          samplingTypeRow,
          samplingAllSitesRow,
          samplingData,
        } = this.getSamplingTable(
          criteriaDetails,
          payload,
          comparisonPeriods,
          projectSitesNames,
        );
        criteriaStatistics.push(samplingPeriodRow);
        criteriaStatistics.push(samplingTypeRow);
        criteriaStatistics.push(samplingAllSitesRow);
        criteriaStatistics.push(...samplingData);
      },
    );
    return criteriaStatistics;
  }

  public getSamplingTable(
    criteriaDetails: any,
    payload: any,
    comparisonPeriods: string[],
    projectSitesNames: string[],
  ) {
    const samplingTableData: any = [];
    criteriaDetails.forEach(
      (criteriaDetailPeriod: any, criteriaDetailPeriodIndex: number) => {
        samplingTableData.push(
          this.generateSamplingTable(
            criteriaDetailPeriod,
            criteriaDetailPeriodIndex,
            payload.comparisonPeriods,
            comparisonPeriods,
          ),
        );
      },
    );
    let samplingPeriodRowCount: number = 1;
    let samplingTypeRowCount: number = 1;
    let samplingAllSitesRowCount: number = 1;
    const samplingPeriodRow: any = {};
    const samplingTypeRow: any = {};
    const samplingAllSitesRow: any = {};
    const samplingData: any = [];
    const dataHashMap = new Map();
    samplingTableData.forEach(
      (samplingInfo: any, samplingInfoIndex: number) => {
        samplingInfo.forEach((sampling: any, samplingIndex: number) => {
          switch (samplingIndex) {
            case 0:
              Object.keys(sampling).forEach((option: string) => {
                samplingPeriodRow['column' + samplingPeriodRowCount] =
                  sampling[option];
                samplingPeriodRowCount += 1;
              });
              break;
            case 1:
              Object.keys(sampling).forEach((option: string) => {
                samplingTypeRow['column' + samplingTypeRowCount] =
                  sampling[option];
                samplingTypeRowCount += 1;
              });
              break;
            case 2:
              Object.keys(sampling).forEach((option: string) => {
                samplingAllSitesRow['column' + samplingAllSitesRowCount] =
                  sampling[option];
                samplingAllSitesRowCount += 1;
              });
              break;
            default:
          }
        });
        switch (samplingInfoIndex) {
          case 0:
            projectSitesNames.forEach(
              (siteName: string, siteNameIndex: number) => {
                dataHashMap.set(siteName, {
                  siteColumnCount: Object.keys(samplingInfo[3 + siteNameIndex])
                    .length,
                  siteRow: samplingInfo[3 + siteNameIndex],
                });
              },
            );
            break;
          default:
            projectSitesNames.forEach(
              (siteName: string, siteNameIndex: number) => {
                const siteHistory = dataHashMap.get(siteName);
                let siteColumnCount = siteHistory.siteColumnCount;
                const siteRow = siteHistory.siteRow;
                Object.keys(samplingInfo[3 + siteNameIndex]).forEach(
                  (option: string) => {
                    siteRow['column' + (siteColumnCount + 1)] =
                      samplingInfo[3 + siteNameIndex][option];
                    siteColumnCount += 1;
                  },
                );
                dataHashMap.set(siteName, {
                  siteColumnCount,
                  siteRow,
                });
              },
            );
        }
      },
    );
    projectSitesNames.forEach((siteName: string) => {
      samplingData.push(dataHashMap.get(siteName).siteRow);
    });
    return {
      samplingPeriodRow,
      samplingTypeRow,
      samplingAllSitesRow,
      samplingData,
    };
  }

  public generateSamplingTable(
    criteriaDetailPeriod: any,
    criteriaDetailPeriodIndex: number,
    periodsInfo: any,
    formattedPeriods: string[],
  ) {
    const isCustomPeriod: boolean = !periodsInfo[criteriaDetailPeriodIndex]
      .isAudit;
    const samplingTableData: any = [];
    if (isCustomPeriod) {
      samplingTableData.push({
        column1: formattedPeriods[criteriaDetailPeriodIndex]
          .split(':')
          .join('\n'),
        column2: '',
      });
      samplingTableData.push({
        column1: 'Site',
        column2: 'Sampling',
      });
      samplingTableData.push({
        column1: 'All Sites',
        column2: '-',
      });
      criteriaDetailPeriod.siteSamplingConfiguration.forEach(
        (siteSampling: any) => {
          samplingTableData.push({
            column1: siteSampling.auditSiteMap.site.name,
            column2: '-',
          });
        },
      );
    } else {
      switch (criteriaDetailPeriod.samplingConfiguration.auditSamplingType) {
        case 'adHoc':
          samplingTableData.push({
            column1: formattedPeriods[criteriaDetailPeriodIndex]
              .split(':')
              .join('\n'),
            column2: '',
          });
          samplingTableData.push({
            column1: 'Site',
            column2: 'Sampling',
          });
          const adHocRowData: any[] = [];
          adHocRowData.push({
            column1: 'All Sites',
            column2: criteriaDetailPeriod.criteriaSamplingDataConfiguration
              .isSamplingEnabled
              ? 'Enabled'
              : '-',
          });
          const adHocSitesData: boolean[] = [];
          criteriaDetailPeriod.siteSamplingConfiguration.forEach(
            (siteSampling: any) => {
              if (siteSampling.isSamplingEnabled) {
                adHocSitesData.push(true);
              } else {
                adHocSitesData.push(false);
              }
              adHocRowData.push({
                column1: siteSampling.auditSiteMap.site.name,
                column2: siteSampling.isSamplingEnabled
                  ? 'Enabled'
                  : 'Disabled',
              });
            },
          );
          const trueCount = adHocSitesData.filter((value) => value).length;
          adHocRowData[0] = {
            ...adHocRowData[0],
            column2: trueCount + ' out of ' + adHocSitesData.length,
          };
          samplingTableData.push(...adHocRowData);
          break;
        case 'consecutive':
          if (
            criteriaDetailPeriod.samplingConfiguration.samplingMode ===
            'minAndMax'
          ) {
            samplingTableData.push({
              column1: formattedPeriods[criteriaDetailPeriodIndex]
                .split(':')
                .join('\n'),
              column2: '',
              column3: '',
            });
            samplingTableData.push({
              column1: 'Site',
              column2: 'Min.',
              column3: 'Max.',
            });
            samplingTableData.push({
              column1: 'All Sites',
              column2:
                criteriaDetailPeriod.criteriaSamplingDataConfiguration
                  .minSampleSize,
              column3:
                criteriaDetailPeriod.criteriaSamplingDataConfiguration
                  .maxSampleSize,
            });
            criteriaDetailPeriod.siteSamplingConfiguration.forEach(
              (siteSampling: any) => {
                samplingTableData.push({
                  column1: siteSampling.auditSiteMap.site.name,
                  column2: siteSampling.minSampleSize,
                  column3: siteSampling.maxSampleSize,
                });
              },
            );
          } else {
            samplingTableData.push({
              column1: formattedPeriods[criteriaDetailPeriodIndex]
                .split(':')
                .join('\n'),
              column2: '',
            });
            samplingTableData.push({
              column1: 'Site',
              column2: 'Target',
            });
            samplingTableData.push({
              column1: 'All Sites',
              column2:
                criteriaDetailPeriod.criteriaSamplingDataConfiguration
                  .sampleSize,
            });
            criteriaDetailPeriod.siteSamplingConfiguration.forEach(
              (siteSampling: any) => {
                samplingTableData.push({
                  column1: siteSampling.auditSiteMap.site.name,
                  column2: siteSampling.sampleSize,
                });
              },
            );
          }
          break;
        default:
          samplingTableData.push({
            column1: formattedPeriods[criteriaDetailPeriodIndex]
              .split(':')
              .join('\n'),
            column2: '',
          });
          samplingTableData.push({
            column1: 'Site',
            column2: 'Target',
          });
          samplingTableData.push({
            column1: 'All Sites',
            column2:
              criteriaDetailPeriod.criteriaSamplingDataConfiguration.sampleSize,
          });
          criteriaDetailPeriod.siteSamplingConfiguration.forEach(
            (siteSampling: any) => {
              samplingTableData.push({
                column1: siteSampling.auditSiteMap.site.name,
                column2: siteSampling.sampleSize,
              });
            },
          );
      }
    }
    return samplingTableData;
  }

  public generateComparisonConfigurationData(
    configurationData: any[],
    payload: any,
    samplingConfigurationData: any[],
  ) {
    let comparisonAggregateCriteriaConfiguration: any[];
    if (payload.checkIndividualSite) {
      if (
        Array.isArray(configurationData) &&
        configurationData.length === payload.comparisonPeriods.length
      ) {
        const aggregateConfiguration: any[] = [];
        _map(payload.filteredCriterias, (criteriaId) => {
          const aggregateCriteriaConfiguration: any[] = [];
          _map(configurationData, (configData, index) => {
            let dirtyAggregateConfiguration: any = {};
            configData.forEach((data: any) => {
              if (data.projectCriteria.id === criteriaId) {
                const criteriaSamplingData = data.criteriaSamplingData.filter(
                  (item: any) => {
                    const criteriaOptionsDataDistribution =
                      item.criteriaOptionsDataDistribution;
                    const dirtyCriteriaSamplingData = Object.keys(
                      criteriaOptionsDataDistribution,
                    ).map((key: string) => ({
                      [key]:
                        data.criteriaSamplingData[0]
                          .criteriaOptionsDataDistribution[key],
                    }));
                    return dirtyCriteriaSamplingData;
                  },
                );
                const dirtyConfig = {
                  title: _get(data, 'projectCriteria.title', ''),
                  criteriaType: _get(data, 'projectCriteria.criteriaType', ''),
                  sampleSize: Number(
                    _get(
                      data,
                      'criteriaSamplingDataConfigurations.sampleSize',
                      0,
                    ),
                  ),
                  siteSamplingConfiguration:
                    data.siteSamplingDataConfigurations,
                  criteriaSamplingDataConfiguration:
                    data.criteriaSamplingDataConfigurations,
                  samplingConfiguration: samplingConfigurationData[index],
                  criteriaSamplingData,
                  isAuditCriteria: _get(data, 'isAuditCriteria', false),
                };
                dirtyAggregateConfiguration = _cloneDeep(dirtyConfig);
              }
            });
            aggregateCriteriaConfiguration.push(dirtyAggregateConfiguration);
          });
          aggregateConfiguration.push(aggregateCriteriaConfiguration);
        });
        comparisonAggregateCriteriaConfiguration = _cloneDeep(
          aggregateConfiguration,
        );
      } else {
        comparisonAggregateCriteriaConfiguration = [];
      }
    } else {
      if (
        Array.isArray(configurationData) &&
        configurationData.length === payload.comparisonPeriods.length
      ) {
        const aggregateConfiguration: any[] = [];
        _map(payload.filteredCriterias, (criteriaId) => {
          const aggregateCriteriaConfiguration: any[] = [];
          _map(configurationData, (configData, index) => {
            let dirtyAggregateConfiguration: any = {};
            configData.forEach((data: any) => {
              if (data.projectCriteria.id === criteriaId) {
                const criteriaOptionsDataDistribution =
                  data.criteriaSamplingData.criteriaOptionsDataDistribution;
                const criteriaSamplingData = Object.keys(
                  criteriaOptionsDataDistribution,
                ).map((key: string) => ({
                  [key]:
                    data.criteriaSamplingData.criteriaOptionsDataDistribution[
                      key
                    ],
                }));
                const dirtyConfig = {
                  title: _get(data, 'projectCriteria.title', ''),
                  criteriaType: _get(data, 'projectCriteria.criteriaType', ''),
                  sampleSize: Number(
                    _get(
                      data,
                      'criteriaSamplingDataConfigurations.sampleSize',
                      0,
                    ),
                  ),
                  siteSamplingConfiguration:
                    data.siteSamplingDataConfigurations,
                  criteriaSamplingDataConfiguration:
                    data.criteriaSamplingDataConfigurations,
                  samplingConfiguration: samplingConfigurationData[index],
                  criteriaSamplingData,
                  isAuditCriteria: _get(data, 'isAuditCriteria', false),
                };
                dirtyAggregateConfiguration = _cloneDeep(dirtyConfig);
              }
            });
            aggregateCriteriaConfiguration.push(dirtyAggregateConfiguration);
          });
          aggregateConfiguration.push(aggregateCriteriaConfiguration);
        });
        comparisonAggregateCriteriaConfiguration = _cloneDeep(
          aggregateConfiguration,
        );
      } else {
        comparisonAggregateCriteriaConfiguration = [];
      }
    }
    return { comparisonAggregateCriteriaConfiguration };
  }

  public generateSingleRowSpace(): any {
    return [{}];
  }

  public generateMultipleRowsSpace(): any {
    return [{}, {}, {}];
  }

  public getPeriods(comparisonPeriods: any): any {
    const periodsInfo: string[] = [];
    comparisonPeriods.forEach((periodInfo: any, periodIndex: number) => {
      periodsInfo.push(
        periodInfo.title + ': ' + this.formatDateRange(periodInfo),
      );
    });
    return periodsInfo;
  }

  public transformPeriods(comparisonPeriods: string[]): string[] {
    const transformedPeriods: string[] = [];
    comparisonPeriods.forEach((period: string, periodIndex: number) => {
      transformedPeriods.push(periodIndex + 1 + '. ' + period);
    });
    return transformedPeriods;
  }

  public formatDateRange(dateObject: any): string {
    const startDate = new Date(dateObject.startedAt);
    let endDate = dateObject.endedAt;
    if (endDate === null) {
      endDate = dayjs().endOf('day').format();
    }
    return this.getDate(startDate) + ' - ' + this.getDate(new Date(endDate));
  }

  public getDate(newValue: Date): string {
    return (
      newValue.getDate() +
      ' ' +
      this.dirtyMonths[newValue.getMonth()] +
      ' ' +
      newValue.getFullYear()
    );
  }
}
