import { AuditSite } from '@/store/modules/audits/types/audits.types';
import { cloneDeep as _cloneDeep, get as _get, map as _map } from 'lodash';
import { CRITERION_TYPE } from '@/store/types/criterions.types';
import dayjs from 'dayjs';

export class GenerateMultiSiteCsv {
  public dirtyMonths: string[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];

  public create(
    result: any,
    payload: any,
    analysisTitle: string,
    projectTitle: string,
    auditTitle: string,
    auditSites: AuditSite[],
  ) {
    const exportDateTime = dayjs().format('D MMM YYYY, HH:mm A');
    const responseArray: any[] = [];
    responseArray.push({
      column1: 'Project Name',
      column2: projectTitle,
    });
    responseArray.push({
      column1: 'Data Collection Period Name',
      column2: auditTitle,
    });
    responseArray.push({
      column1: 'Analysis Name',
      column2: analysisTitle,
    });
    const selectedSites: string[] = [];
    payload.filteredSites.forEach((auditSiteMapId: number) => {
      const auditSiteMap = auditSites.find(
        (auditSite: AuditSite) => auditSite.id === auditSiteMapId,
      );
      if (auditSiteMap) {
        selectedSites.push(auditSiteMap.site.name);
      }
    });
    responseArray.push({
      column1: 'Site',
      column2: selectedSites.join('\n'),
    });
    responseArray.push({
      column1: 'Site Display Mode',
      column2: payload.checkIndividualSite ? 'Individual' : 'Aggregate',
    });
    responseArray.push({
      column1: 'Data Collection Period',
      column2: this.formatDateRange(payload.selectedPeriod),
    });
    switch (payload.checkIndividualSite) {
      case true:
        responseArray.push(
          ...this.generateIndividualSiteCriteriaTables(
            result,
            payload,
            selectedSites,
          ),
        );
        break;
      default:
        responseArray.push(
          ...this.generateAggregateSiteCriteriaTables(
            result,
            payload,
            selectedSites,
            auditSites,
          ),
        );
    }
    responseArray.push(...this.generateSingleRowSpace());
    responseArray.push({
      column1: 'Exported on ' + exportDateTime,
    });
    return responseArray;
  }

  public generateIndividualSiteCriteriaTables(
    result: any,
    payload: any,
    siteNames: string[],
  ): any {
    const criteriaStatistics: any[] = [];
    const { aggregateCriteriaConfiguration } = this.generateConfigurationData(
      result.statisticData.configurationData,
      payload,
      result.statisticData.samplingConfiguration,
    );
    aggregateCriteriaConfiguration.forEach(
      (criteriaDetails, criteriaDetailsIndex) => {
        criteriaStatistics.push(...this.generateMultipleRowsSpace());
        criteriaStatistics.push({
          column1: criteriaDetailsIndex + 1 + '. ' + criteriaDetails.title,
        });
        const headerRow: any = {
          column1: 'Answer Choices',
        };
        siteNames.forEach((siteName: string, siteNameIndex: number) => {
          headerRow['column' + (siteNameIndex + 2)] = siteName;
        });
        criteriaStatistics.push(headerRow);
        const optionsList: string[][] = [];
        const totalList: number[] = [];
        const complianceList: number[] = [];
        const valuesList: number[][] = [];
        const trueValueList: number[][] = [];
        const naValueList: number[][] = [];
        criteriaDetails.criteriaSamplingData.forEach(
          (siteData: any, siteDataIndex: number) => {
            totalList.push(siteData.total);
            let trueValue: number = 0;
            let naValue: number = 0;
            const criteriaOptions = Object.keys(
              siteData.criteriaOptionsDataDistribution,
            );
            optionsList[siteDataIndex] = _cloneDeep(criteriaOptions);
            criteriaOptions.forEach((option: string, optionIndex: number) => {
              switch (optionIndex) {
                case 0:
                  trueValue = siteData.criteriaOptionsDataDistribution[option];
                  break;
                case 1:
                  break;
                default:
                  naValue = siteData.criteriaOptionsDataDistribution[option];
              }
              trueValueList[siteDataIndex] = [trueValue];
              naValueList[siteDataIndex] = [naValue];
              if (valuesList[siteDataIndex]) {
                valuesList[siteDataIndex].push(
                  siteData.criteriaOptionsDataDistribution[option],
                );
              } else {
                valuesList[siteDataIndex] = [
                  siteData.criteriaOptionsDataDistribution[option],
                ];
              }
            });
            if (criteriaDetails.criteriaType === CRITERION_TYPE.BOOLEAN) {
              const compliance = (trueValue / (siteData.total - naValue)) * 100;
              complianceList.push(compliance ? compliance : 0);
            }
          },
        );
        optionsList[0].forEach(
          (optionName: string, optionNameIndex: number) => {
            const rowObject: any = {
              column1: optionName,
            };
            siteNames.forEach((siteName: string, siteNameIndex: number) => {
              rowObject['column' + (siteNameIndex + 2)] =
                valuesList[siteNameIndex][optionNameIndex];
            });
            criteriaStatistics.push(rowObject);
          },
        );
        const totalRow: any = {
          column1: 'Total Data Collected',
        };
        totalList.forEach((total: number, totalIndex: number) => {
          let formattedTotal;
          if (total.toString().includes('-')) {
            formattedTotal = '-';
          } else {
            formattedTotal = total;
          }
          totalRow['column' + (totalIndex + 2)] = formattedTotal;
        });
        criteriaStatistics.push(totalRow);
        if (criteriaDetails.criteriaType === CRITERION_TYPE.BOOLEAN) {
          const complianceRow: any = {
            column1: 'Compliance',
          };
          complianceList.forEach(
            (compliance: number, complianceIndex: number) => {
              complianceRow['column' + (complianceIndex + 2)] =
                compliance + '%';
            },
          );
          criteriaStatistics.push(complianceRow);
        }
        // sampling table
        criteriaStatistics.push(...this.generateSingleRowSpace());
        criteriaStatistics.push({
          column1: 'Sampling',
        });
        const samplingTableData = this.getSamplingTable(criteriaDetails);
        criteriaStatistics.push(...samplingTableData);
      },
    );
    return criteriaStatistics;
  }

  public generateAggregateSiteCriteriaTables(
    result: any,
    payload: any,
    siteNames: string[],
    auditSites: AuditSite[],
  ): any {
    const criteriaStatistics: any[] = [];
    const { aggregateCriteriaConfiguration } = this.generateConfigurationData(
      result.statisticData.configurationData,
      payload,
      result.statisticData.samplingConfiguration,
    );
    aggregateCriteriaConfiguration.forEach(
      (criteriaDetails, criteriaDetailsIndex) => {
        criteriaStatistics.push(...this.generateMultipleRowsSpace());
        criteriaStatistics.push({
          column1: criteriaDetailsIndex + 1 + '. ' + criteriaDetails.title,
        });
        let siteText: string;
        switch (siteNames.length) {
          case 1:
            siteText = siteNames[0];
            break;
          case auditSites.length:
            siteText = 'All Sites';
            break;
          default:
            siteText = 'Selected sites (' + siteNames.length + ')';
        }
        criteriaStatistics.push({
          column1: 'Answer Choices',
          column2: siteText,
        });
        let total: number = 0;
        let trueValue: number = 0;
        let naValue: number = 0;
        criteriaDetails.criteriaSamplingData.forEach(
          (optionsData: any, optionDataIndex: number) => {
            const option = Object.keys(optionsData)[0];
            switch (optionDataIndex) {
              case 0:
                trueValue = optionsData[option];
                break;
              case 1:
                break;
              default:
                naValue = optionsData[option];
            }
            total += optionsData[option];
            criteriaStatistics.push({
              column1: option,
              column2: optionsData[option],
            });
          },
        );
        let formattedTotal;
        if (total.toString().includes('-')) {
          formattedTotal = '-';
        } else {
          formattedTotal = total;
        }
        criteriaStatistics.push({
          column1: 'Total Data Collected',
          column2: formattedTotal,
        });
        if (criteriaDetails.criteriaType === CRITERION_TYPE.BOOLEAN) {
          const compliance = (trueValue / (total - naValue)) * 100;
          criteriaStatistics.push({
            column1: 'Compliance',
            column2: compliance
              ? Math.round(compliance * 100) / 100 + '%'
              : '0%',
          });
        }
        // sampling table
        criteriaStatistics.push(...this.generateSingleRowSpace());
        criteriaStatistics.push({
          column1: 'Sampling',
        });
        const samplingTableData = this.getSamplingTable(criteriaDetails);
        criteriaStatistics.push(...samplingTableData);
      },
    );
    return criteriaStatistics;
  }

  public getSamplingTable(criteriaDetails: any) {
    const samplingTableData: any[] = [];
    switch (criteriaDetails.samplingConfiguration.auditSamplingType) {
      case 'adHoc':
        samplingTableData.push({
          column1: 'Site',
          column2: 'Sampling',
        });
        const adHocRowData: any[] = [];
        adHocRowData.push({
          column1: 'All Sites',
          column2: criteriaDetails.criteriaSamplingDataConfiguration
            .isSamplingEnabled
            ? 'Enabled'
            : '-',
        });
        const adHocSitesData: boolean[] = [];
        criteriaDetails.siteSamplingConfiguration.forEach(
          (siteSampling: any) => {
            if (siteSampling.isSamplingEnabled) {
              adHocSitesData.push(true);
            } else {
              adHocSitesData.push(false);
            }
            adHocRowData.push({
              column1: siteSampling.auditSiteMap.site.name,
              column2: siteSampling.isSamplingEnabled ? 'Enabled' : 'Disabled',
            });
          },
        );
        const trueCount = adHocSitesData.filter((value) => value).length;
        adHocRowData[0] = {
          ...adHocRowData[0],
          column2: trueCount + ' out of ' + adHocSitesData.length,
        };
        samplingTableData.push(...adHocRowData);
        break;
      case 'consecutive':
        if (
          criteriaDetails.samplingConfiguration.samplingMode === 'minAndMax'
        ) {
          samplingTableData.push({
            column1: 'Site',
            column2: 'Min.',
            column3: 'Max.',
          });
          samplingTableData.push({
            column1: 'All Sites',
            column2:
              criteriaDetails.criteriaSamplingDataConfiguration.minSampleSize,
            column3:
              criteriaDetails.criteriaSamplingDataConfiguration.maxSampleSize,
          });
          criteriaDetails.siteSamplingConfiguration.forEach(
            (siteSampling: any) => {
              samplingTableData.push({
                column1: siteSampling.auditSiteMap.site.name,
                column2: siteSampling.minSampleSize,
                column3: siteSampling.maxSampleSize,
              });
            },
          );
        } else {
          samplingTableData.push({
            column1: 'Site',
            column2: 'Target',
          });
          samplingTableData.push({
            column1: 'All Sites',
            column2:
              criteriaDetails.criteriaSamplingDataConfiguration.sampleSize,
          });
          criteriaDetails.siteSamplingConfiguration.forEach(
            (siteSampling: any) => {
              samplingTableData.push({
                column1: siteSampling.auditSiteMap.site.name,
                column2: siteSampling.sampleSize,
              });
            },
          );
        }
        break;
      default:
        samplingTableData.push({
          column1: 'Site',
          column2: 'Target',
        });
        samplingTableData.push({
          column1: 'All Sites',
          column2: criteriaDetails.criteriaSamplingDataConfiguration.sampleSize,
        });
        criteriaDetails.siteSamplingConfiguration.forEach(
          (siteSampling: any) => {
            samplingTableData.push({
              column1: siteSampling.auditSiteMap.site.name,
              column2: siteSampling.sampleSize,
            });
          },
        );
    }
    return samplingTableData;
  }

  public generateConfigurationData(
    configurationData: any[],
    payload: any,
    samplingConfiguration: any,
  ) {
    let aggregateCriteriaConfiguration: any[];
    if (payload.checkIndividualSite) {
      if (Array.isArray(configurationData) && configurationData.length > 0) {
        const aggregateConfig = configurationData.map((data: any) => {
          const criteriaSamplingData = data.criteriaSamplingData.filter(
            (item: any) => {
              const criteriaOptionsDataDistribution =
                item.criteriaOptionsDataDistribution;
              const dirtyCriteriaSamplingData = Object.keys(
                criteriaOptionsDataDistribution,
              ).map((key: string) => ({
                [key]:
                  data.criteriaSamplingData[0].criteriaOptionsDataDistribution[
                    key
                  ],
              }));
              return dirtyCriteriaSamplingData;
            },
          );
          return {
            title: _get(data, 'criteria.title', ''),
            criteriaType: _get(data, 'criteria.criteriaType', ''),
            sampleSize: Number(
              _get(data, 'criteriaSamplingDataConfigurations.sampleSize', 0),
            ),
            siteSamplingConfiguration: data.siteSamplingDataConfigurations,
            criteriaSamplingDataConfiguration:
              data.criteriaSamplingDataConfigurations,
            samplingConfiguration,
            criteriaSamplingData,
          };
        });
        aggregateCriteriaConfiguration = _cloneDeep(aggregateConfig);
      } else {
        aggregateCriteriaConfiguration = [];
      }
    } else {
      if (Array.isArray(configurationData) && configurationData.length > 0) {
        const aggregateConfig = configurationData.map((data: any) => {
          const criteriaOptionsDataDistribution =
            data.criteriaSamplingData.criteriaOptionsDataDistribution;
          const criteriaSamplingData = Object.keys(
            criteriaOptionsDataDistribution,
          ).map((key: string) => ({
            [key]:
              data.criteriaSamplingData.criteriaOptionsDataDistribution[key],
          }));
          return {
            title: _get(data, 'criteria.title', ''),
            criteriaType: _get(data, 'criteria.criteriaType', ''),
            sampleSize: Number(
              _get(data, 'criteriaSamplingDataConfigurations.sampleSize', 0),
            ),
            siteSamplingConfiguration: data.siteSamplingDataConfigurations,
            criteriaSamplingDataConfiguration:
              data.criteriaSamplingDataConfigurations,
            samplingConfiguration,
            criteriaSamplingData,
          };
        });
        aggregateCriteriaConfiguration = _cloneDeep(aggregateConfig);
      } else {
        aggregateCriteriaConfiguration = [];
      }
    }
    return { aggregateCriteriaConfiguration };
  }

  public generateSingleRowSpace(): any {
    return [{}];
  }

  public generateMultipleRowsSpace(): any {
    return [{}, {}, {}];
  }

  public formatDateRange(date: Date[]): string {
    return (
      this.getDate(new Date(date[0])) + ' - ' + this.getDate(new Date(date[1]))
    );
  }

  public getDate(newValue: Date): string {
    return (
      newValue.getDate() +
      ' ' +
      this.dirtyMonths[newValue.getMonth()] +
      ' ' +
      newValue.getFullYear()
    );
  }
}
