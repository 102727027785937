








































































import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import { cloneDeep as _cloneDeep } from 'lodash';

@Component({ components : {} })

export default class OverallExportFilter extends Vue {
    @Prop()
    public totalAnalysis!: any[];
    public selectedAnalysis: number[] = [];
    public isAllAnalysisSelected: boolean = true;
    public allAnalysisIds: number[] = [];

    public mounted() {
        this.populateData();
    }

    public populateData() {
      this.totalAnalysis.map((analysis: any) => {
        this.selectedAnalysis.push(analysis.analysisId);
      });
      this.allAnalysisIds = _cloneDeep(this.selectedAnalysis);
    }

    public updateSelection(label: boolean = false, analysisId: number) {
        if (label) {
            const analysisIndex = this.selectedAnalysis.indexOf(analysisId);
            if (analysisIndex === -1) {
                this.selectedAnalysis.push(analysisId);
            } else {
                this.selectedAnalysis.splice(analysisIndex, 1);
            }
        }
        this.isAllAnalysisSelected = this.allAnalysisIds.length === this.selectedAnalysis.length;
        this.emitAnalysisFilter();
    }

    public updateAllSelection(label: boolean = false) {
        if (label) {
            this.isAllAnalysisSelected = !this.isAllAnalysisSelected;
        }
        if (this.isAllAnalysisSelected) {
            this.selectedAnalysis = _cloneDeep(this.allAnalysisIds);
        } else {
            this.selectedAnalysis = [];
        }
        this.emitAnalysisFilter();
    }

    public emitAnalysisFilter() {
      this.$emit('applyOverallExportAnalysisFilter', this.selectedAnalysis);
    }
}
