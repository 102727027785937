



































































































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { cloneDeep as _cloneDeep } from 'lodash';
import {
  DataCollectionSubmitRequestPayload,
  DataCollectionSummaryCriteria,
  RangeOptionValueType,
  DataCollectionCriteriaDataPayload,
  RangeErrorValidation,
} from '@/store/modules/audits/types/audits.types';
import Slider from '@vueform/slider/dist/slider.vue2.js';
import CriteriaViewUpdateComponent from '../../Project/components/CriteriaViewUpdateComponent.vue';

@Component({
  components: {
    Slider,
    CriteriaViewUpdateComponent,
  },
})
export default class DataCollectionRangeCriteria extends Vue {
  @Prop()
  public index!: number;

  @Prop()
  public criteriaOption!: DataCollectionCriteriaDataPayload;

  @Prop()
  public totalToDateMessage!: boolean;

  @Prop()
  public resultData!: DataCollectionSubmitRequestPayload;

  @Prop()
  public editDataCollectionRecord!: any;

  @Prop()
  public criteriaSamplingData!: any;

  public sliderValue: number = 0;
  public countValue: number = 0;
  public showRangeErrorMessage: boolean = false;
  public showCountErrorMessage: boolean = false;
  public showValueErrorMessage: boolean = false;
  public answerValueDetail: RangeOptionValueType[] = [];
  public removedAnswerValueDetail: RangeOptionValueType[] = [];
  public validateValueError: RangeErrorValidation[] = [];

  public mounted() {
    this.answerValueDetail = this.criteriaOption.optionValues;
    const indexOfExistingNA = this.answerValueDetail
      .map((optionValue) => optionValue.option)
      .indexOf('N/A');

    const naOptionValue: RangeOptionValueType | undefined = {
      option: 'N/A',
      totalToDate:
        indexOfExistingNA >= 0
          ? this.answerValueDetail[indexOfExistingNA].totalToDate
          : 0,
      value:
        indexOfExistingNA >= 0
          ? this.answerValueDetail[indexOfExistingNA].value
          : 0,
    };

    if (indexOfExistingNA >= 0) {
      this.answerValueDetail.splice(indexOfExistingNA, 1);
    }
    this.answerValueDetail.push(naOptionValue as RangeOptionValueType); // to ensure NA option is always the last item
    this.sliderValue = this.minValue;
    this.answerValueDetail.forEach((data: RangeOptionValueType) => {
      this.validateValueError.push({
        option: data.option,
        hasError: false,
      });
    });
    this.$emit('valuePopulated');
  }

  get totalToDateValue(): number {
    const existingOptionValue = this.criteriaOption.optionValues.find(
      (data: RangeOptionValueType) => Number(data.option) === this.sliderValue,
    );
    let existingTotalToDateValue = this.removedAnswerValueDetail.find(
      (data: RangeOptionValueType) => {
        if (Number(data.option) === this.sliderValue) {
          return data;
        }
      },
    );

    if (this.editDataCollectionRecord && this.criteriaSamplingData) {
      if (!existingTotalToDateValue) {
        existingTotalToDateValue = this.criteriaSamplingData.find(
          (data: RangeOptionValueType) => {
            if (Number(data.option) === this.sliderValue) {
              return data;
            }
          },
        );
      }
    }

    if (existingOptionValue) {
      return existingOptionValue.totalToDate;
    } else if (existingTotalToDateValue) {
      return existingTotalToDateValue.totalToDate;
    } else {
      return 0;
    }
  }

  get minValue(): number {
    return parseInt(
      JSON.parse(this.criteriaOption.criteria.criteriaOptions)[0],
      10,
    );
  }

  get maxValue(): number {
    return parseInt(
      JSON.parse(this.criteriaOption.criteria.criteriaOptions)[1],
      10,
    );
  }

  public showCommentBox(rowData: DataCollectionCriteriaDataPayload) {
    rowData.isCommentSectionExpanded = !rowData.isCommentSectionExpanded;
  }

  public handleAddCountValue(value: number | string) {
    let optionIndex = 0;
    const existingOptionValue = this.answerValueDetail.find(
      (data: RangeOptionValueType, index: number) => {
        if (Number(data.option) === this.sliderValue) {
          optionIndex = index;
          return data;
        }
      },
    );

    const existingTotalToDateValue = this.removedAnswerValueDetail.find(
      (data: RangeOptionValueType) => {
        if (Number(data.option) === this.sliderValue) {
          return data;
        }
      },
    );

    if (existingOptionValue) {
      this.answerValueDetail[optionIndex].value =
        Number(existingOptionValue.value) + Number(value);
    } else {
      this.answerValueDetail.unshift({
        option: this.sliderValue,
        totalToDate: existingTotalToDateValue
          ? existingTotalToDateValue.totalToDate
          : this.totalToDateValue,
        value: Number(value),
      });
    }

    this.countValue = 0;

    this.resultData.criteriaData.forEach(
      (data: DataCollectionCriteriaDataPayload) => {
        if (data.criteria.id === this.criteriaOption.criteria.id) {
          data.optionValues = this.answerValueDetail;
        }
      },
    );
  }

  public handleRemoveRangeValue(
    item: RangeOptionValueType,
    rangeIndex: number,
  ) {
    this.removedAnswerValueDetail.push(this.answerValueDetail[rangeIndex]);
    this.answerValueDetail.splice(rangeIndex, 1);

    this.validateValueError.find((element: RangeErrorValidation) => {
      if (element.option === item.option) {
        element.hasError = false;
      }
    });

    this.resultData.criteriaData.forEach(
      (data: DataCollectionCriteriaDataPayload) => {
        if (data.criteria.id === this.criteriaOption.criteria.id) {
          data.optionValues = this.answerValueDetail;
        }
      },
    );
  }

  public updateRangeValue(data: RangeOptionValueType) {
    this.showValueErrorMessage = data.value ? false : true;

    this.validateRangeValue(data.option);

    if (!this.showValueErrorMessage) {
      this.answerValueDetail.find((item: RangeOptionValueType) => {
        if (Number(item.option) === Number(data.option)) {
          return (item.value = data.value);
        }
      });
      this.resultData.criteriaData.forEach(
        (element: DataCollectionCriteriaDataPayload) => {
          if (element.criteria.id === this.criteriaOption.criteria.id) {
            element.optionValues = this.answerValueDetail;
          }
        },
      );
    }

    this.$emit('updateSubmitButton', {
      index: this.index,
      isError: this.showValueErrorMessage,
    });
  }

  public validateRangeValue(option: number | string) {
    const hasValueValidateError = this.validateValueError.find(
      (element: RangeErrorValidation) => element.option === option,
    );

    if (hasValueValidateError) {
      this.validateValueError.find((element: RangeErrorValidation) => {
        if (element.option === hasValueValidateError.option) {
          element.hasError = this.showValueErrorMessage;
        }
      });
    } else {
      this.validateValueError.push({
        option,
        hasError: this.showValueErrorMessage,
      });
    }
  }

  @Watch('sliderValue')
  public watchSliderValue(newValue: number) {
    this.showRangeErrorMessage =
      newValue < this.minValue || newValue > this.maxValue || newValue === null
        ? true
        : false;

    this.$emit('updateSubmitButton', {
      index: this.index,
      isError: this.showRangeErrorMessage,
    });
  }

  @Watch('countValue')
  public watchCountValue(newValue: number) {
    this.showCountErrorMessage = newValue || newValue === 0 ? false : true;
    if (!this.showRangeErrorMessage) {
      this.$emit('updateSubmitButton', {
        index: this.index,
        isError: this.showCountErrorMessage,
      });
    }
  }
}
