





























import { Component, Vue, Watch } from 'vue-property-decorator';
@Component({})
export default class ProjectSubscription extends Vue {
  public redirectToJaas() {
    window.location.href =
      process.env.VUE_APP_JAAS_DASHBOARD_URL + `/application/paces/products`;
  }
}
