



































import { Component, Vue } from 'vue-property-decorator';
import { State, Action } from 'vuex-class';
import dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import { DATE_FORMAT } from '@/utils/date.util';
import { useUserEmail } from '@/utils/user.util';

// @ts-ignore
dayjs.extend(relativeTime);

@Component({
  components: {},
})
export default class DashboardDataHistory extends Vue {
  @Action('projects/getDataCollectionActivityLogs')
  public getDataCollectionActivityLogs!: () => void;

  @State((state) => state.projects.dataCollectionActivityLogs)
  public dataCollectionActivityLogs!: any;

  public async mounted() {
    await this.getDataCollectionActivityLogs();
  }

  get userEmail(): string | undefined {
    return useUserEmail.call(this);
  }

  public getFormattedTitle(title: string) {
    if (title.length > 45) {
      return title.substring(0, 45) + '...';
    } else {
      return title;
    }
  }

  get histories() {
    const userEmail = this.userEmail;
    if (Array.isArray(this.dataCollectionActivityLogs)) {
      return this.dataCollectionActivityLogs.slice(0, 10).map((log) => {
        const today = dayjs().format(DATE_FORMAT);
        const yesterday = dayjs().add(-1, 'day').format(DATE_FORMAT);
        const dataUpdatedAt = dayjs(new Date(log.recordedStartDateTime)).format(
          DATE_FORMAT,
        );
        const total: number = log.recordValueTotal || 0;
        let dateToDisplay;
        if (dataUpdatedAt === today) {
          dateToDisplay = 'Today';
        } else if (dataUpdatedAt === yesterday) {
          dateToDisplay = 'Yesterday';
        } else {
          dateToDisplay = dataUpdatedAt;
        }

        return {
          // @ts-ignore
          datetime: dateToDisplay,
          email: log.user.email === userEmail ? 'You' : log.user.email,
          audit: log.audit,
          description: `added ${total} responses to`,
        };
      });
    }
    return [];
  }
}
