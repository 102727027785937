import {
  ProjectSite,
  ProjectCriteria,
  ProjectReportAnalysisResponseDto,
} from '@/store/modules/projects/types/projects.types';
import dayjs from 'dayjs';
import { Document, Packer } from 'docx';
import { saveAs } from 'file-saver';
import { GenerateSingleSiteComparisonDocx } from '@/components/reports/export/docx/project/SingleSiteComparisonDocx';
import { GenerateMultiSiteComparisonDocx } from '@/components/reports/export/docx/project/MultiSiteComparisonDocx';
import { GenerateMultiSiteDocx } from '@/components/reports/export/docx/project/MultiSiteDocx';
import { GenerateSingleSiteDocx } from '@/components/reports/export/docx/project/SingleSiteDocx';
import { ToastProgrammatic as Toast } from 'buefy';

export class ProjectReportExportToDocx {
  public async create(
    reportAnalysisResponse: ProjectReportAnalysisResponseDto[],
    projectTitle: string,
  ) {
    const document: Document = new Document();
    const fileTitle =
      reportAnalysisResponse.length === 1
        ? reportAnalysisResponse[0].projectTitle
        : projectTitle;

    const resultArray: any[] = [];
    await Promise.all(
      reportAnalysisResponse.map(
        async (analysis: ProjectReportAnalysisResponseDto, index: number) => {
          resultArray[index] = await this.handleReportResponse(
            document,
            analysis,
          );
        },
      ),
    );

    resultArray.forEach((sectionData) => {
      document.addSection(sectionData);
    });

    const fileName = fileTitle + '_' + dayjs().format('DDMMMYYYY') + '.docx';
    Packer.toBlob(document).then((blob) => {
      try {
        saveAs(blob, fileName);
      } catch (err) {
        Toast.open({
          message: `Something went wrong. Try again.`,
          position: 'is-top',
          type: 'is-danger',
          duration: 3500,
        });
      } finally {
        Toast.open({
          message: `Export complete.`,
          position: 'is-top',
          type: 'is-dark',
          duration: 3500,
        });
      }
    });
  }

  public async handleReportResponse(
    document: Document,
    analysisResponse: ProjectReportAnalysisResponseDto,
  ) {
    const {
      result,
      payload,
      analysisTitle,
      jbiLogo,
      projectTitle,
      projectSites,
      projectCriteria,
    } = analysisResponse;

    switch (payload.comparisonPeriods.length) {
      case 1:
        return this.generateSinglePeriodPdf(
          document,
          result,
          payload,
          analysisTitle,
          jbiLogo,
          projectTitle,
          projectSites,
          projectCriteria,
        );
      default:
        return this.generateMultiPeriodPdf(
          document,
          result,
          payload,
          analysisTitle,
          jbiLogo,
          projectTitle,
          projectSites,
          projectCriteria,
        );
    }
  }

  public async generateSinglePeriodPdf(
    document: Document,
    result: any,
    payload: any,
    analysisTitle: string,
    jbiLogo: string,
    projectTitle: string,
    projectSites: ProjectSite[],
    projectCriteria: ProjectCriteria[],
  ) {
    switch (projectSites.length) {
      case 1:
        const generateSingleSiteDocx = new GenerateSingleSiteDocx();
        return await generateSingleSiteDocx.create(
          document,
          result,
          payload,
          analysisTitle,
          jbiLogo,
          projectTitle,
          projectSites,
          projectCriteria,
        );
      default:
        const generateMultiSiteDocx = new GenerateMultiSiteDocx();
        return await generateMultiSiteDocx.create(
          document,
          result,
          payload,
          analysisTitle,
          jbiLogo,
          projectTitle,
          projectSites,
          projectCriteria,
        );
    }
  }

  public async generateMultiPeriodPdf(
    document: Document,
    result: any,
    payload: any,
    analysisTitle: string,
    jbiLogo: string,
    projectTitle: string,
    projectSites: ProjectSite[],
    projectCriteria: ProjectCriteria[],
  ) {
    switch (projectSites.length) {
      case 1:
        const generateSingleSiteComparisonDocx = new GenerateSingleSiteComparisonDocx();
        return await generateSingleSiteComparisonDocx.create(
          document,
          result,
          payload,
          analysisTitle,
          jbiLogo,
          projectTitle,
          projectSites,
          projectCriteria,
        );
      default:
        const generateMultiSiteComparisonDocx = new GenerateMultiSiteComparisonDocx();
        return await generateMultiSiteComparisonDocx.create(
          document,
          result,
          payload,
          analysisTitle,
          jbiLogo,
          projectTitle,
          projectSites,
          projectCriteria,
        );
    }
  }
}
