import { PacesParticipantRoles } from '@/jbi-shared/types/paces-project.types';
import {
  ProjectParticipantDTO,
  ProjectParticipantRole,
  ProjectParticipantSiteMap,
  UserRoleInAudit,
  UserRoleInProject,
} from '@/store/modules/projects/types/projects.types';

export const getParticipantByRole = (
  projectParticipants: ProjectParticipantDTO[],
  userEmail: string,
  participantRole: PacesParticipantRoles,
) => {
  return projectParticipants.find((participant: ProjectParticipantDTO) => {
    return (
      participant.userEmail === userEmail &&
      !participant.isDeleted &&
      participant.participantRoles.find(
        (role: ProjectParticipantRole) =>
          !role.isDeleted &&
          role.role === participantRole &&
          role.participantSites &&
          role.participantSites.some(
            (participantSite: ProjectParticipantSiteMap) =>
              !participantSite.isDeleted && participantSite.invite.acceptedAt,
          ),
      )
    );
  });
};

export const getProjectUrlByRole = (
  projectId: number,
  userRoleInProject: UserRoleInProject,
) => {
  switch (userRoleInProject) {
    case UserRoleInProject.isOwner:
      return '/projects/' + projectId + '#dashboard';
    case UserRoleInProject.isProjectCoOrdinator:
      return '/projects/' + projectId + '#dashboard';
    case UserRoleInProject.isDataCollector:
      return '/projects/' + projectId + '/homepage';
    default:
      return '/projects/me';
  }
};

export const getAuditUrlByRole = (
  auditId: number,
  userRoleInAudit: UserRoleInAudit,
): string => {
  if (
    userRoleInAudit === UserRoleInAudit.isOwner ||
    userRoleInAudit === UserRoleInAudit.isProjectCoOrdinator
  ) {
    return '/audits/' + auditId + '#dashboard';
  } else {
    return '/audits/' + auditId + '/homepage#dataCollectionSheet';
  }
};

export const getCurrentUser = (
  projectParticipants: ProjectParticipantDTO[],
  userEmail: string,
): ProjectParticipantDTO | undefined => {
  return projectParticipants.find(
    (participant) => participant.userEmail === userEmail,
  );
};
