import { OicPayload } from '@/store/modules/auth/types/auth.types';
import { axios } from './axios';
import {
  UserInvitationPayload,
  VerifyUserInvitePayload,
  GetUserPayload,
  SurveyAcknowledgement,
  SurveyDTO,
} from '@/store/modules/users/types/users.types';
import { AxiosResponse } from 'axios';

export const usersApi = {
  async inviteNewUser(payload: UserInvitationPayload) {
    return axios.post('/invitations', {
      ...payload,
    });
  },
  async getUsers(params: GetUserPayload) {
    return axios.get('/admin/users', { params });
  },
  async getUserInvitations(email: string) {
    return axios.get('/invitations', {
      params: { email },
    });
  },
  async removeUserInvitation(id: number) {
    return axios.delete(`/invitations/${id}`);
  },
  async resendUserInvitations(id: number) {
    return axios.post(`/invitations/${id}/resend`);
  },
  async searchUsersByText(text: string) {
    const size = 50;
    const source: any = text
      ? {
          size,
          query: {
            bool: {
              should: [
                {
                  multi_match: {
                    query: text,
                    fields: ['email', 'fullName', 'username'],
                    fuzziness: 3,
                    boost: 2,
                  },
                },
                {
                  query_string: {
                    fields: ['email', 'fullName', 'username'],
                    query: `*${text}*`,
                  },
                },
              ],
            },
          },
        }
      : {
          size,
          query: {
            match_all: {},
          },
        };
    return axios.get(`/user/_search`, {
      params: {
        source: JSON.stringify(source),
        source_content_type: 'application/json',
      },
    });
  },
  updateStatus(id: number) {
    return axios.patch(`admin/users/${id}/status`);
  },
  async getMyRoles() {
    return axios.get(`me/roles`);
  },
  async acceptUserInvite(token: any) {
    return axios.post(`invitations/accept`, {
      ...token,
    });
  },
  async verifyUserInvite(payload: VerifyUserInvitePayload) {
    return axios.post(`invitations/verify-token`, {
      ...payload,
    });
  },

  /** APIs for survey-data && survey-acknowledgement  */
  async getSurveyData(): Promise<AxiosResponse<SurveyDTO>> {
    return axios.get(`me/survey-data`);
  },

  async saveSurveyAcknowledgement(): Promise<
    AxiosResponse<SurveyAcknowledgement>
  > {
    return axios.post(`me/survey-acknowledgement`);
  },

  getUserOICPayload(): Promise<AxiosResponse<OicPayload>> {
    return axios.get('me/user-oic-payload');
  },
};
