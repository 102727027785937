import { Plugin } from 'vuex';
import { RootState } from '@/store/store';
import { get as _get, debounce as _debounce } from 'lodash';

export const errorLogger: Plugin<RootState> = store => {
  store.subscribe(mutation => {
    const { type } = mutation;
    if (type.endsWith('_ERROR')) {
      const error: Error = mutation.payload;
      // tslint:disable-next-line
      console.error({ type, error, mutation });
    }
  });
};
