





















































































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import {
  MultipleRecurrenceFrequencyOptions,
  RecurrenceSettingPayload,
  RecurrenceType,
  DataCollectionPeriodPayload,
} from '@/store/modules/projects/types/projects.types';
import { StringInputOption } from '@/jbi-shared/types/form.types';
import { cloneDeep as _cloneDeep, find as _find } from 'lodash';
import ProjectCreationMultipleDatePicker from './ProjectCreationMultipleDatepicker.vue';
import moment from 'moment';
import { getStartDateTime, getEndDateTime } from '../../../utils/date.util';
const dirtyMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

@Component({
  components: {
    ProjectCreationMultipleDatePicker,
  },
})
export default class NewAuditSettings extends Vue {
  @Prop()
  public recurrenceSetting!: RecurrenceSettingPayload;
  @Prop()
  public projectDetail!: any;
  @Prop()
  public latestDate!: any;
  public projectExpiryDatePicker: any = null;
  public auditSamplingTypesOptions = {
    targetSampleSize: 'targetSampleSize',
    adHoc: 'adHoc',
    consecutive: 'consecutive',
  };
  public dirtyAuditSamplingType = this.auditSamplingTypesOptions
    .targetSampleSize;
  public defaultEndDate: any = moment(this.latestDate).add(1, 'M');
  public dataCollectionPeriodDateTotal: DataCollectionPeriodPayload = {
    auditNumber: 1,
    auditName: 'Baseline',
    startDate: getStartDateTime(this.latestDate),
    endDate: getEndDateTime(this.defaultEndDate.subtract(1, 'd')),
  };
  public dirtyRecurrenceSetting: RecurrenceSettingPayload = {
    recurrenceType: 'oneOff',
    scheduledRecurrenceStartDate: getStartDateTime(this.latestDate),
    multipleRecurrenceType: 'Monthly',
    auditSamplingType: this.dirtyAuditSamplingType,
    interval: 1,
    auditNumber: 1,
    enableGrip: false,
    multipleDataCollectionPeriod: [
      {
        ...this.dataCollectionPeriodDateTotal,
      },
    ],
  };
  public datePickerKey: number = Math.floor(Math.random() * 999);
  public showTotalError: boolean = false;
  public showIntervalError: boolean = false;

  public mounted() {
    if (this.recurrenceSetting !== undefined) {
      this.dirtyRecurrenceSetting = _cloneDeep(this.recurrenceSetting);
      this.dirtyRecurrenceSetting.scheduledRecurrenceStartDate = getStartDateTime();
    }
  }

  get recurrenceOptions(): StringInputOption[] {
    return Object.entries(RecurrenceType).map(([key, value]) => {
      return {
        id: key,
        name: value,
      };
    });
  }

  get totalMultipleDataCollection() {
    return this.dirtyRecurrenceSetting.multipleDataCollectionPeriod;
  }

  get scheduledRecurrenceStartDate() {
    const recurrenceStartDate: any = getStartDateTime(
      this.dirtyRecurrenceSetting.scheduledRecurrenceStartDate,
    );

    return typeof recurrenceStartDate === 'string'
      ? getStartDateTime(recurrenceStartDate)
      : recurrenceStartDate;
  }
  set scheduledRecurrenceStartDate(newValue) {
    this.dirtyRecurrenceSetting.scheduledRecurrenceStartDate = getStartDateTime(
      newValue,
    );
    this.datePickerKey += 1;
    this.$emit('updateAuditSetting', this.dirtyRecurrenceSetting);
  }

  get recurrenceType() {
    return this.dirtyRecurrenceSetting.recurrenceType;
  }

  set recurrenceType(newValue) {
    this.dirtyRecurrenceSetting.recurrenceType = newValue;
    this.dirtyRecurrenceSetting.auditNumber = 1;
    this.dirtyRecurrenceSetting.interval = 1;
    this.dirtyRecurrenceSetting.multipleRecurrenceType = 'Monthly';
    if (newValue === 'multiple') {
      this.updateTotalDataCollectionPeriod();
    }
    this.$emit('updateAuditSetting', this.dirtyRecurrenceSetting);
  }

  public multiRecurrenceFrequencyValueFromKey(key: string) {
    const objectValue: any = _find(this.multipleRecurrenceFrequencyOptions, [
      'id',
      key,
    ]);
    return objectValue.name;
  }

  get multipleRecurrenceFrequencyOptions(): StringInputOption[] {
    return Object.entries(MultipleRecurrenceFrequencyOptions).map(
      ([key, value]) => {
        return {
          id: key,
          name: value,
        };
      },
    );
  }

  get multipleRecurrenceType() {
    return this.dirtyRecurrenceSetting.multipleRecurrenceType !== undefined &&
      this.dirtyRecurrenceSetting.multipleRecurrenceType
      ? this.dirtyRecurrenceSetting.multipleRecurrenceType
      : 'Monthly';
  }
  set multipleRecurrenceType(newValue) {
    this.dirtyRecurrenceSetting.multipleRecurrenceType = newValue;
    this.datePickerKey += 1;
    this.$emit('updateAuditSetting', this.dirtyRecurrenceSetting);
  }

  get interval(): number {
    return this.dirtyRecurrenceSetting.interval
      ? this.dirtyRecurrenceSetting.interval
      : 0;
  }

  set interval(newValue: number) {
    this.dirtyRecurrenceSetting.interval = newValue;
    this.showIntervalError = !newValue || newValue < 1 ? true : false;
    this.datePickerKey += 1;
    this.$emit('updateAuditSetting', this.dirtyRecurrenceSetting);
  }

  get auditNumber(): number {
    return this.dirtyRecurrenceSetting.auditNumber
      ? this.dirtyRecurrenceSetting.auditNumber
      : 0;
  }

  set auditNumber(newValue: number) {
    this.dirtyRecurrenceSetting.auditNumber = newValue;
    this.showTotalError = newValue === 0 ? true : false;
    this.datePickerKey += 1;
    this.$emit('updateAuditSetting', this.dirtyRecurrenceSetting);
  }

  public getDate(newValue: Date): string {
    return (
      newValue.getDate() +
      ' ' +
      dirtyMonths[newValue.getMonth()] +
      ' ' +
      newValue.getFullYear()
    );
  }

  public formatDateRange(date: Date): string {
    return this.getDate(date);
  }

  public updateDatePeriod(newValue: any) {
    this.dirtyRecurrenceSetting.multipleDataCollectionPeriod[
      newValue.auditNumber
    ] = _cloneDeep(newValue);
    this.$emit('updateAuditSetting', this.dirtyRecurrenceSetting);
  }

  public updateTotalDataCollectionPeriod() {
    if (this.recurrenceType !== 'multiple') {
      return;
    }
    if (this.auditNumber > 10) {
      this.auditNumber = 0;
    }
    const totalDataCollectionDatepicker: any = [];
    const endDate = moment(this.scheduledRecurrenceStartDate);
    for (let value = 0; value < this.auditNumber; value++) {
      const startDate =
        value > 0 ? endDate.add(1, 'd').toDate() : endDate.toDate();
      const newAuditName = value > 0 ? `Follow Up ${value}` : 'Baseline';
      switch (this.multipleRecurrenceType) {
        case Object.keys(MultipleRecurrenceFrequencyOptions)[0]:
          endDate.add(this.interval, 'd');
          break;
        case Object.keys(MultipleRecurrenceFrequencyOptions)[1]:
          endDate.add(this.interval, 'w');
          break;
        case Object.keys(MultipleRecurrenceFrequencyOptions)[2]:
          endDate.add(this.interval, 'M');
          break;
        default:
          endDate.add(this.interval, 'y');
          break;
      }
      const newEndDate = endDate.subtract(1, 'd').toDate();
      const dateRangePeriod: any = [startDate, newEndDate];
      totalDataCollectionDatepicker.push({
        startDate: getStartDateTime(dateRangePeriod[0]),
        endDate: getEndDateTime(dateRangePeriod[1]),
        auditNumber: value + 1,
        auditName: newAuditName,
      });
    }
    this.dirtyRecurrenceSetting.multipleDataCollectionPeriod = totalDataCollectionDatepicker;
    this.$emit('updateAuditSetting', this.dirtyRecurrenceSetting);
  }

  @Watch('dirtyAuditSamplingType')
  public watchDirtySamplingType(newValue: string) {
    this.dirtyRecurrenceSetting.auditSamplingType = newValue;
    this.$emit('updateAuditSetting', this.dirtyRecurrenceSetting);
  }
}
