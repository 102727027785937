



























































































import { Component, Vue, Watch } from 'vue-property-decorator';
import DefaultLayout from '../../layouts/DefaultLayout.vue';
import HeroBar from '@/components/base/HeroBar.vue';
import { Action, State } from 'vuex-class';
import {
  Site,
  GetSitesPayload,
  SiteInfo,
} from '@/store/modules/site/types/site.types';
import DeleteSiteModal from '@/views/Site/components/DeleteSiteModal.vue';
import InfoModal from '@/components/InfoModal.vue';
import SiteModal from '@/views/Site/components/SiteModal.vue';
import { ToastProgrammatic as Toast } from 'buefy';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { AxiosError } from 'axios';
import BasePagination from '@/components/base/BasePagination.vue';

@Component({
  components: {
    DefaultLayout,
    HeroBar,
    BasePagination,
  },
})
export default class SiteListPage extends Vue {
  @Action('site/getSites')
  public getAllSites!: (params: GetSitesPayload) => void;

  @State((state) => state.site.sites)
  public sites!: SiteInfo;

  @State((state) => state.site.apiState.editSite.success)
  public editSiteSuccess!: boolean;

  @State((state) => state.site.apiState.deleteSite.success)
  public deleteSiteSuccess!: boolean;

  @State((state) => state.site.apiState.addSite.success)
  public addSiteSuccess!: boolean;

  @State((state) => state.site.apiState.editSite.error)
  public editSiteError!: AxiosError;

  @State((state) => state.site.apiState.deleteSite.error)
  public deleteSiteError!: AxiosError;

  @State((state) => state.site.apiState.addSite.error)
  public addSiteError!: AxiosError;

  @State((state) => state.site.apiState.getSite.error)
  public getSiteError!: AxiosError;

  @Action('site/deleteSite')
  public deleteSite!: (siteId: number) => void;

  @Action('site/editSite')
  public editSite!: (updatedSite: Site) => void;

  @Action('site/addNewSite')
  public addNewSite!: (newSite: Site) => void;

  public searchSiteTerm: string = '';
  public totalCount: number = 0;
  public perPage = 50;
  public page = 1;
  public field = 'updated_at';
  public sort: 'ASC' | 'DESC' = 'DESC';
  public siteDetails: any = [];
  public noSiteItems: boolean = false;
  public deletedSiteName: string = '';

  get totalNumberOfPage(): number {
    return Math.ceil(this.totalCount / this.perPage);
  }

  get startItemIndex(): number {
    return this.page * this.perPage - this.perPage + 1;
  }

  get endItemIndex(): number {
    return Math.min(this.totalCount, this.page * this.perPage);
  }

  get isFirstPage(): boolean {
    return this.page === 1;
  }

  get isLastPage(): boolean {
    return this.page === this.totalNumberOfPage;
  }

  get getCurrentPage(): number {
    this.page = +this.$route.query.page || this.page;
    return this.page;
  }

  set getCurrentPage(newValue: number) {
    this.page = newValue;
    this.getAllSites({
      search: this.searchSiteTerm,
      limit: this.perPage,
      page: this.page,
      field: this.field,
      sort: this.sort,
    });
    this.$router
      .push({
        path: this.$route.path,
        query: {
          search: (this.$route.query.search as string) || this.searchSiteTerm,
          limit: (this.$route.query.limit as string) || this.perPage.toString(),
          page: this.page.toString(),
          field: (this.$route.query.field as string) || this.field,
          sort: (this.$route.query.sort as 'ASC' | 'DESC') || this.sort,
        },
      })
      .catch(() => {
        return;
      });
  }

  get getCurrentPerPage(): number {
    this.perPage = +this.$route.query.limit || this.perPage;
    return this.perPage;
  }

  set getCurrentPerPage(newValue: number) {
    this.perPage = newValue;
    this.getAllSites({
      search: this.searchSiteTerm,
      limit: this.perPage,
      page: this.page,
      field: this.field,
      sort: this.sort,
    });
    this.$router
      .push({
        path: this.$route.path,
        query: {
          search: (this.$route.query.search as string) || this.searchSiteTerm,
          limit: this.perPage.toString(),
          page: (this.$route.query.page as string) || this.page.toString(),
          field: (this.$route.query.field as string) || this.field,
          sort: (this.$route.query.sort as 'ASC' | 'DESC') || this.sort,
        },
      })
      .catch(() => {
        return;
      });
  }

  public submitSearchResult(newValue: string) {
    this.page = 1;
    this.getAllSites({
      search: this.searchSiteTerm,
      limit: this.perPage,
      page: this.page,
      field: this.field,
      sort: this.sort,
    });
    this.$router.push({
      path: this.$route.path,
      query: {
        search: this.searchSiteTerm,
        limit: (this.$route.query.limit as string) || this.perPage.toString(),
        page: (this.$route.query.page as string) || this.page.toString(),
        field: (this.$route.query.field as string) || this.field,
        sort: (this.$route.query.sort as 'ASC' | 'DESC') || this.sort,
      },
    });
  }

  public mounted() {
    this.searchSiteTerm = (this.$route.query.search as string) || '';
    this.$router
      .push({
        path: this.$route.path,
        query: {
          search: (this.$route.query.search as string) || this.searchSiteTerm,
          limit: (this.$route.query.limit as string) || this.perPage.toString(),
          page: (this.$route.query.page as string) || this.page.toString(),
          field: (this.$route.query.field as string) || this.field,
          sort: (this.$route.query.sort as 'ASC' | 'DESC') || this.sort,
        },
      })
      .catch(() => {
        return;
      });

    this.getAllSites({
      search: this.searchSiteTerm,
      limit: Number(this.$route.query.limit),
      page: Number(this.$route.query.page) || this.page,
      field: (this.$route.query.field as string) || this.field,
      sort: (this.$route.query.sort as 'ASC' | 'DESC') || this.sort,
    });
  }

  public openDeleteSiteModal(id: number, name: string) {
    this.$buefy.modal.open({
      parent: this,
      component: DeleteSiteModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle: 'Delete Site',
        id,
        name,
      },
      events: {
        deleteSelectedSite: async (siteId: number) => {
          if (this.sites && this.sites.items) {
            const selectedSite = this.sites.items.find(
              (value: any) => value.id === siteId,
            );
            if (selectedSite) {
              this.deletedSiteName = selectedSite.name;
            }
          }
          await this.deleteSite(siteId);
          await this.getAllSites({
            search: this.searchSiteTerm,
            limit: this.perPage,
            page: this.page,
            field: this.field,
            sort: this.sort,
          });
        },
      },
    });
  }

  public openEditSiteModal(siteDetail: Site, task: string) {
    this.$buefy.modal.open({
      parent: this,
      component: SiteModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        task,
        modalTitle: 'Edit Site',
        siteDetail,
      },
      events: {
        editSelectedSite: async (updatedSite: Site) => {
          await this.editSite(updatedSite);
          await this.getAllSites({
            search: this.searchSiteTerm,
            limit: this.perPage,
            page: this.page,
            field: this.field,
            sort: this.sort,
          });
        },
      },
    });
  }

  public openNewSiteModal(siteDetail: Site, task: string) {
    // @ts-ignore
    siteDetail = { name: '', address: '', description: '' };

    this.$buefy.modal.open({
      parent: this,
      component: SiteModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        task,
        modalTitle: 'Add Site',
        siteDetail,
      },
      events: {
        addNewSite: async (newSite: Site) => {
          await this.addNewSite(newSite);
          await this.getAllSites({
            search: this.searchSiteTerm,
            limit: this.perPage,
            page: this.page,
            field: this.field,
            sort: this.sort,
          });
        },
      },
    });
  }

  @Watch('editSiteSuccess')
  @isDifferent
  @isTruthy
  public onEditSiteSuccess() {
    Toast.open({
      message: `Site Successfully Edited.`,
      position: 'is-top',
      type: 'is-dark',
    });
    setTimeout(() => {
      // function
    }, 200);
  }

  @Watch('deleteSiteSuccess')
  @isDifferent
  @isTruthy
  public onDeleteSiteSuccess() {
    this.deletedSiteName = '';
    Toast.open({
      message: `Site Successfully Deleted.`,
      position: 'is-top',
      type: 'is-dark',
    });
    setTimeout(() => {
      // function
    }, 200);
  }

  @Watch('addSiteSuccess')
  @isDifferent
  @isTruthy
  public onAddSiteSuccess() {
    Toast.open({
      message: `Site Successfully Added.`,
      position: 'is-top',
      type: 'is-dark',
    });
    setTimeout(() => {
      // function
    }, 200);
  }

  @Watch('editSiteError')
  @isDifferent
  @isTruthy
  public onEditSiteError() {
    Toast.open({
      message: `${this.editSiteError}`,
      position: 'is-top',
      type: 'is-danger',
    });
    setTimeout(() => {
      // function
    }, 200);
  }

  @Watch('deleteSiteError')
  @isDifferent
  @isTruthy
  public onDeleteSiteError() {
    const modalContent = `'${this.deletedSiteName}' cannot be removed because it is currently being used in ongoing data collection period(s).`;
    const Modal = this.$buefy.modal.open({
      parent: this,
      component: InfoModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle: 'Failed To Delete Site',
        modalContent,
        confirmButtonText: 'OK',
      },
      events: {
        confirm: () => {
          Modal.close();
        },
        close: () => {
          Modal.close();
        },
      },
    });
    this.deletedSiteName = '';
  }

  @Watch('addSiteError')
  @isDifferent
  @isTruthy
  public onAddSiteError() {
    Toast.open({
      message: `${this.addSiteError}`,
      position: 'is-top',
      type: 'is-danger',
    });
    setTimeout(() => {
      // function
    }, 200);
  }

  @Watch('getSiteError')
  @isDifferent
  @isTruthy
  public onGetSiteError() {
    Toast.open({
      message: `${this.getSiteError}`,
      position: 'is-top',
      type: 'is-danger',
    });
    setTimeout(() => {
      // function
    }, 200);
  }

  @isTruthy
  @Watch('sites')
  public getSitesResult(newValue: any) {
    if (newValue.totalItems > 0) {
      this.siteDetails = newValue.items;
      this.totalCount = newValue.totalItems;
      this.noSiteItems = false;
    } else {
      this.siteDetails = {};
      this.noSiteItems = true;
    }
  }
}
