





















import { Vue, Prop } from 'vue-property-decorator';

export default class HeroBar extends Vue {
    @Prop({ type: Boolean, required: false, default: true })
    public hasRightVisible: boolean = true;
}
