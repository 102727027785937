






































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  CreateGripAnalysisEnablerPayload,
  CreateGripAnalysisStrategyPayload,
  CreateGripEnablerBase,
  GripAnalysisEnabler,
  GripItemType,
  GripEnablerDTO,
  GripEnablerStatus,
  GripItemReloadDTO,
  UpdateGripAnalysisStrategyPayload,
  UpdateGripEnablerBase,
} from '../../../store/types/grip.types';
import BaseLoading from '../../../components/base/BaseLoading.vue';
import EnablerModal from '../Enabler/EnablerModal.vue';
import DeleteModalBox from '../../../components/DeleteModalBox.vue';
import GripAnalysisStrategies from './GripAnalysisStrategies.vue';

@Component({
  components: {
    BaseLoading,
    EnablerModal,
    DeleteModalBox,
    GripAnalysisStrategies,
  },
})
export default class GripAnalysisEnablers extends Vue {
  @Prop() public projectId!: number;
  @Prop() public isDataCollector!: boolean;
  @Prop() public gripAnalysisId!: number;
  @Prop() public gripAnalysisBarrierId!: number;
  @Prop() public gripAnalysisEnablers!: GripAnalysisEnabler[];
  @Prop() public updatedItem!: GripItemReloadDTO;
  public isGripAnalysisEnablersLoading: boolean = false;
  public showEnablerDraggableBox: boolean = false;
  public itemList: GripAnalysisEnabler[] = [];
  public tempDirtyEnabler: CreateGripEnablerBase = {
    title: '',
    description: '',
    links: [],
    attachments: [],
    gripDomainCategoryId: null,
  };
  public modalKey: number = Math.floor(Math.random() * 999);
  public isModalActive: boolean = false;
  public isNew: boolean = false;
  public gripEnablerDetails: GripEnablerDTO | null = null;

  public getUpperCase(value: string): string {
    return value.toUpperCase();
  }

  public updated(): void {
    if (this.updatedItem.item === GripItemType.AnalysisEnabler) {
      const element = document.getElementById(
        this.updatedItem.item + '_' + this.updatedItem.id,
      );
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      } else if (this.updatedItem.prevItemId) {
        const prevElement = document.getElementById(
          this.updatedItem.prevItemId as string,
        );
        if (prevElement) {
          prevElement.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }

    if (this.showEnablerDraggableBox) {
      const element = document.getElementById('contentIdForAddEnablers');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  public mounted(): void {
    this.itemList = this.gripAnalysisEnablers
      ? this.gripAnalysisEnablers
          .filter((enabler) => enabler.status === GripEnablerStatus.ACTIVE)
          .sort((a, b) => a.id - b.id)
      : [];
  }

  public handleShowDraggableBox(): void {
    this.showEnablerDraggableBox = true;
  }

  public onDragOver(event: any): void {
    const dragSupported = event.dataTransfer.types.length;
    if (dragSupported) {
      event.dataTransfer.dropEffect = 'copy';
      event.preventDefault();
    }
  }

  public onDrop(event: any, id: string): void {
    event.preventDefault();
    const jsonData = event.dataTransfer.getData('application/json');
    const data = JSON.parse(jsonData);
    if (!data) {
      return;
    }

    const { itemType, ...enablerData } = data;
    if (id === 'enablerDropZone' && itemType === 'enabler') {
      const enablerPayload: CreateGripAnalysisEnablerPayload = {
        ...enablerData,
        gripAnalysisId: this.gripAnalysisId,
        gripAnalysisBarrierId: this.gripAnalysisBarrierId,
      };
      this.$emit('addGripAnalysisEnabler', { enablerPayload, isNew: false });
    }
  }

  public openEnablerModal(): void {
    this.isNew = true;
    this.isModalActive = true;
    this.modalKey += 1;
    this.gripEnablerDetails = null;
  }

  public handleEdit(enabler: GripAnalysisEnabler): void {
    const {
      gripAnalysisStrategies,
      status,
      createdDate,
      updatedDate,
      ...enablerDetails
    } = enabler;

    this.gripEnablerDetails = enablerDetails as GripEnablerDTO;
    this.isNew = false;
    this.isModalActive = true;
    this.modalKey += 1;
  }

  public closeEnablerModal(): void {
    this.isModalActive = false;
  }

  public handleEnablerCreate(gripEnabler: CreateGripEnablerBase): void {
    const enablerPayload: CreateGripAnalysisEnablerPayload = {
      ...gripEnabler,
      gripAnalysisId: this.gripAnalysisId,
      gripAnalysisBarrierId: this.gripAnalysisBarrierId,
    };
    this.$emit('addGripAnalysisEnabler', {
      enablerPayload,
      isNew: true,
    });
    this.closeEnablerModal();
    this.showEnablerDraggableBox = false;
  }

  public handleEnablerUpdate(gripEnabler: UpdateGripEnablerBase): void {
    this.$emit('updateGripAnalysisEnabler', {
      ...gripEnabler,
      gripAnalysisId: this.gripAnalysisId,
      gripAnalysisBarrierId: this.gripAnalysisBarrierId,
    });
    this.$emit('handleUpdatedItemReload', {
      item: GripItemType.AnalysisEnabler,
      id: gripEnabler.id,
      prevItemId: this.getPrevItemId(gripEnabler.id),
    });
    this.closeEnablerModal();
  }

  public handleDelete(gripAnalysisEnablerId: number): void {
    this.$buefy.modal.open({
      parent: this,
      component: DeleteModalBox,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle: 'Delete Enabler?',
        modalContent:
          'Are you sure you want to delete this enabler? This action cannot be undone.',
      },
      events: {
        close: () => {
          this.$emit('close');
        },
        confirm: () => {
          this.$emit('deleteGripAnalysisEnabler', gripAnalysisEnablerId);
          this.$emit('handleUpdatedItemReload', {
            item: GripItemType.AnalysisEnabler,
            id: gripAnalysisEnablerId,
            prevItemId: this.getPrevItemId(gripAnalysisEnablerId),
          });
        },
      },
    });
  }

  public getPrevItemId(gripEnablerId: number): string {
    const enablerIndex: number = this.itemList.findIndex(
      (item) => item.id === gripEnablerId,
    );

    return enablerIndex > 0
      ? GripItemType.AnalysisEnabler + '_' + this.itemList[enablerIndex - 1].id
      : GripItemType.AnalysisBarrier + '_' + this.gripAnalysisBarrierId;
  }

  public handleUpdatedItemReload(data: GripItemReloadDTO): void {
    this.$emit('handleUpdatedItemReload', data);
  }

  public handleAddGripAnalysisStrategy(strategyDetails: {
    strategyPayload: CreateGripAnalysisStrategyPayload;
    isNew: boolean;
  }): void {
    this.$emit('addGripAnalysisStrategy', strategyDetails);
  }

  public handleUpdateGripAnalysisStrategy(
    strategyPayload: UpdateGripAnalysisStrategyPayload,
  ): void {
    this.$emit('updateGripAnalysisStrategy', strategyPayload);
  }

  public handleDeleteGripAnalysisStrategy(
    gripAnalysisStrategyId: number,
  ): void {
    this.$emit('deleteGripAnalysisStrategy', gripAnalysisStrategyId);
  }
}
