




















































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import BaseDropDown from '@/components/base/BaseDropDown.vue';
import {
  Site,
  ParticipantSite,
  ParticipantFormData,
  ProjectParticipantDataTrfObj,
} from '@/store/modules/projects/types/projects.types';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { useUserEmail } from '@/utils/user.util';
import { cloneData } from '@/utils/general.util';
import { PacesParticipantRoles } from '@/jbi-shared/types/paces-project.types';

@Component({
  components: {
    BaseDropDown,
    ValidationProvider,
    ValidationObserver,
  },
})
export default class CreateParticipantForm extends Vue {
  @Prop({ default: [] })
  public sites!: Site[];

  @Prop()
  public participant!: ProjectParticipantDataTrfObj;

  @Prop()
  public isEdit!: boolean;

  @Prop()
  public participantsList!: ProjectParticipantDataTrfObj[]; // active participants only

  public form: ParticipantFormData = {
    userEmail: '',
    participantRole: null,
    participantSites: [],
    isDeleted: false,
  };
  public checkedAll: boolean = false;
  public isRoleProjectCoordinator: boolean = false;
  public selectedSiteNames: string = '';

  public isSameProjectOwnerEmail: boolean = false;
  public isSameParticipantEmail: boolean = false;
  public existingParticipant: ProjectParticipantDataTrfObj | undefined;
  public showErrorMessage: boolean = false;

  get projectId(): number {
    return +this.$route.params.projectId;
  }

  get isDraft(): boolean {
    return Boolean(this.projectId);
  }

  get roleOptions(): PacesParticipantRoles[] {
    return [
      PacesParticipantRoles.DataCollector,
      PacesParticipantRoles.ProjectCoordinator,
    ];
  }

  get siteOptions(): Site[] {
    if (Array.isArray(this.sites)) {
      return this.sites;
    }
    return [];
  }

  get selectedSiteContent(): string {
    if (this.checkedAll) {
      return 'All Sites';
    }

    if (this.form.participantSites.length === 0) {
      return 'Select Sites';
    }

    const selectedSites: Site[] = this.form.participantSites.filter(
      (participantSite: Site) => {
        return this.siteOptions.map((o) => o.id).includes(participantSite.id);
      },
    );

    this.selectedSiteNames = this.getSelectedSiteNames(selectedSites);
    return this.selectedSiteNames;
  }

  public getSelectedSiteNames(sites: Site[]): string {
    const selectedSitesNames: string = sites
      .map((item: Site) => item.name)
      .join(', ');
    return selectedSitesNames.length > 65
      ? selectedSitesNames.substring(0, 62) + '...'
      : selectedSitesNames;
  }

  public mounted() {
    if (this.participant) {
      const participant: ProjectParticipantDataTrfObj = cloneData(
        this.participant,
      );
      const {
        id,
        userEmail,
        participantRole,
        participantSites,
        participantRoleId,
      } = participant;

      if (id) {
        this.form.id = id;
      }

      if (participantRoleId) {
        this.form.participantRoleId = participantRoleId;
      }

      this.form.userEmail = userEmail;
      this.form.participantRole = participantRole;

      /**
       * form.participantSites must contain EXACT value from
       * siteOption in order for the checkbox to work
       */
      this.form.participantSites = this.transformSites(participantSites);
    } else {
      // this.form.participantSites = this.siteOptions;
    }
  }

  public transformSites(participantSites: ParticipantSite[]): Site[] {
    if (participantSites.length === 0 && this.isRoleProjectCoordinator) {
      return this.siteOptions;
    }

    // FIXME: deleted records should not be part of the response
    const activeSites: ParticipantSite[] = participantSites.filter(
      (participantSite) => {
        return participantSite.isDeleted === false;
      },
    );

    return this.siteOptions.filter((availableSite: Site) => {
      return activeSites.map(({ site }) => site.id).includes(availableSite.id);
    });
  }

  public handleCheckAll(value: boolean): void {
    this.checkedAll = value;
    this.form.participantSites = [];
    if (value) {
      this.form.participantSites = cloneData(this.siteOptions);
    }
  }

  public handleSubmit(): void {
    this.showErrorMessage = false;
    if (this.isSameProjectOwnerEmail || this.checkParticipantExists()) {
      this.showErrorMessage = true;
      return;
    }

    // only submit form when email is validated
    this.$emit('submit', this.form);
    this.$emit('close');
  }

  get userEmail(): string | undefined {
    return useUserEmail.call(this);
  }

  @Watch('form.participantSites', { deep: true })
  public onCheck(value: Site[]): void {
    this.checkedAll = false;
    if (
      value
        .map(({ id }) => id)
        .sort()
        .toString() ===
      this.siteOptions
        .map(({ id }) => id)
        .sort()
        .toString()
    ) {
      this.checkedAll = true;
    }
  }

  @Watch('form.userEmail')
  public validateDuplication(participantEmail: string): void {
    participantEmail = participantEmail.toLowerCase();

    if (this.isEdit) {
      this.existingParticipant = this.participantsList.find(
        (participant: ProjectParticipantDataTrfObj) =>
          participant.userEmail.toLowerCase() === participantEmail &&
          participant.participantRole !== this.form.participantRole,
      );
    } else {
      this.existingParticipant = this.participantsList.find(
        (participant: ProjectParticipantDataTrfObj) =>
          participant.userEmail.toLowerCase() ===
          participantEmail.toLowerCase(),
      );
    }

    switch (participantEmail) {
      case (this.userEmail || '').toLowerCase():
        this.isSameProjectOwnerEmail = true;
        break;
      case this.existingParticipant &&
        this.existingParticipant.userEmail.toLowerCase():
        this.isSameParticipantEmail = true;
        break;
      default:
        this.isSameParticipantEmail = false;
        this.isSameProjectOwnerEmail = false;
        this.showErrorMessage = false;
    }
  }

  private checkParticipantExists(): boolean {
    const existingParticipant: ProjectParticipantDataTrfObj | undefined = this
      .existingParticipant;

    // If the participant roles are the same then the participant exists
    let isSameRole: boolean = Boolean(
      this.participantsList.find(
        (participant: ProjectParticipantDataTrfObj) =>
          this.form.userEmail === participant.userEmail &&
          this.form.participantRole === participant.participantRole,
      ),
    );
    if (this.isEdit && existingParticipant) {
      // for edit it will be enough to match the existing participant role directly
      isSameRole =
        existingParticipant.participantRole === this.form.participantRole;
    }

    if (isSameRole && existingParticipant) {
      return true;
    }

    // The participant exists if at least one of the sites match with the existing participant
    let isSameSiteExists: boolean = false;
    if (existingParticipant) {
      existingParticipant.participantSites.some((existingParticipantSite) => {
        isSameSiteExists = Boolean(
          this.form.participantSites.find(
            (site: any) => site.id === existingParticipantSite.site.id,
          ),
        );
        return isSameSiteExists;
      });
    }
    return isSameSiteExists;
  }
}
