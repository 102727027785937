import {
  StaticApiState,
  GetSignedUrlForUploadResponsePayload,
} from './types/static-file.types';
import { defaultApiState } from '@/store/types/general.types';
import { AxiosError } from 'axios';

export const staticFileMutations = {
  /*
   ** getSignedUrlForUpload
   */
  GET_SIGNED_URL_FOR_UPLOAD_LOADING(state: StaticApiState) {
    state.apiState.getSignedUrlForUpload = {
      ...defaultApiState,
      loading: true,
    };
  },
  GET_SIGNED_URL_FOR_UPLOAD_SUCCESS(
    state: StaticApiState,
    payload: GetSignedUrlForUploadResponsePayload[],
  ) {
    state.apiState.getSignedUrlForUpload = {
      ...defaultApiState,
      success: true,
    };
    state.signedUrlData = payload;
  },
  GET_SIGNED_URL_FOR_UPLOAD_ERROR(state: StaticApiState, error: AxiosError) {
    state.apiState.getSignedUrlForUpload = {
      ...defaultApiState,
      error,
    };
  },
};
