































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import ProjectReportExportFilter from '@/views/Project/components/ProjectReportExportFilter.vue';
import ProjectReportIndividualAnalysis from '@/views/Project/components/ProjectReportIndividualAnalysis.vue';
import ProjectReportExportDropdown from '@/views/Project/components/ProjectReportExportDropdown.vue';
import ProjectAnalysisNavigationTabs from '@/views/Project/components/ProjectAnalysisNavigationTabs.vue';
import CompressedAnalysisNavigationTabs from '@/components/reports/CompressedAnalysisNavigationTabs.vue';
import Card from '@/components/Card.vue';
import { map as _map, cloneDeep as _cloneDeep } from 'lodash';
import { RootState } from '@/store/store';
import { projectsApi } from '@/api/projects.api';
import {
  ChartType,
  ProjectCriteria,
  ProjectSite,
  ProjectReportAnalysis,
  ProjectReportFiltersConfig,
  ProjectReportPeriod,
  ProjectDetailPayload,
  ProjectReportAnalysisResponseDto,
} from '@/store/modules/projects/types/projects.types';
import { AuditDetail } from '@/store/modules/audits/types/audits.types';
import { ProjectReportExportToPdf } from '@/components/reports/export/pdf/project/ProjectReportPdf';
import JbiLogo from '@/assets/images/jbi_navbar_img@2x.png';
import { ProjectReportExportToDocx } from '@/components/reports/export/docx/project/ProjectReportDocx';
import { ProjectReportExportToCsv } from '@/components/reports/export/csv/project/ProjectReportCsv';
import { ProjectReportExportToExcel } from '@/components/reports/export/excel/project/ProjectReportExcel';
import { reportExportType } from '@/store/types/general.types';
import { ApiState } from '../../../store/types/general.types';
import { handleErrorResponse } from '../../../utils/api-response-error-handler.util';

@Component({
  components: {
    ProjectReportExportFilter,
    ProjectReportIndividualAnalysis,
    ProjectReportExportDropdown,
    Card,
    ProjectAnalysisNavigationTabs,
    CompressedAnalysisNavigationTabs,
  },
})
export default class ProjectReportsTab extends Vue {
  @Prop({ default: true })
  public editable!: boolean;

  public overallExport: string = '';
  public totalAnalysis: ProjectReportAnalysis[] = [];
  public dirtyTotalAnalysis: ProjectReportAnalysis[] = [];
  public dirtyFiltersConfiguration: ProjectReportFiltersConfig = {
    filteredSites: [],
    filteredCriterias: [],
    selectedChartType: ChartType.verticalBarChart,
    checkIndividualSite: false,
    checkIndividualCriteria: false,
    selectedPeriod: {
      title: '',
      startedAt: '',
      endedAt: null,
      isAudit: false,
      auditIds: [],
    },
    checkComparison: false,
    comparisonPeriods: [],
  };
  public dirtyAnalysis: any = {
    title: null,
    filtersConfiguration: this.dirtyFiltersConfiguration,
    analysisId: null,
  };
  public onProjectSitesCriteriaAuditListTotalAnalysisSuccess: boolean = false;
  public showCompressedAnalysisHeader: boolean = false;
  public showIndividualAnalysis: boolean = false;
  public showNullContainer: boolean = false;
  public visibleAnalysisHeaderKey: number = Math.floor(Math.random() * 999);
  public compressedAnalysisHeaderKey: number = Math.floor(Math.random() * 999);
  public individualAnalysisKey: number = Math.floor(Math.random() * 999);
  public exportFilterKey: number = Math.floor(Math.random() * 999);
  public analysisTabContainerWidth: number = 1342;
  public selectedTabIndex: number = 0;
  public auditsConfigurationList: ProjectReportPeriod[] = [];
  public getTotalAnalysisSuccess: boolean = false;
  public analysisToExport: ProjectReportAnalysis[] = [];
  public isExportDisabled: boolean = false;

  @Action('projects/getUpdatedProjectReportJBICriteriaList')
  public getUpdatedProjectReportJBICriteriaList!: (projectId: number) => void;

  @Action('projects/getProjectSites')
  public getProjectSites!: (payload: number) => void;

  @State((state: RootState) => state.projects.projectSites)
  public projectSites!: ProjectSite[];

  @Action('projects/getTotalProjectReportAnalysis')
  public getTotalProjectReportAnalysis!: (projectId: number) => void;

  @State((state: RootState) => state.projects.totalProjectReportAnalysis)
  public totalProjectReportAnalysis!: any;

  @State(
    (state: RootState) => state.projects.apiState.getTotalProjectReportAnalysis,
  )
  public getTotalProjectReportAnalysisState!: ApiState;

  @Action('projects/getProjectReportsCriteria')
  public getProjectReportsCriteria!: (projectId: number) => void;

  @State((state: RootState) => state.projects.projectCriteria)
  public projectCriteria!: ProjectCriteria[];

  @Action('projects/getProjectAuditList')
  public getProjectAuditList!: (payload: number) => void;

  @State((state: RootState) => state.projects.projectAuditList)
  public projectAuditList!: AuditDetail[];

  @State((state: RootState) => state.projects.apiState.getProjectSites)
  public getProjectSitesSuccess!: any;

  @State((state: RootState) => state.projects.apiState.getProjectCriteria)
  public getProjectCriteriaSuccess!: any;

  @State((state: RootState) => state.projects.apiState.getProjectAuditList)
  public getProjectAuditListSuccess!: any;

  @State((state) => state.projects.projectDetail)
  public projectDetail!: ProjectDetailPayload;

  public mounted() {
    this.getProjectSites(this.getProjectId);
    this.getProjectReportsCriteria(this.getProjectId);
    this.getProjectAuditList(this.getProjectId);
    this.fetchTotalAnalysis(this.getProjectId);
    this.getUpdatedProjectReportJBICriteriaList(this.getProjectId);
  }

  public fetchTotalAnalysis(projectId: number) {
    this.getTotalProjectReportAnalysis(projectId);
  }

  public populateData() {
    if (this.totalAnalysis.length === 0) {
      const filterConfig: ProjectReportFiltersConfig = this
        .generateFilterConfig;
      this.dirtyTotalAnalysis.push({
        title: 'Analysis ' + (this.dirtyTotalAnalysis.length + 1),
        analysisId: Math.floor(Math.random() * 9000 + 1000),
        filtersConfiguration: filterConfig,
      });
      this.visibleAnalysisHeaderKey += 1;
      this.showIndividualAnalysis = true;
    } else {
      this.dirtyTotalAnalysis = _cloneDeep(this.totalAnalysis);
      this.visibleAnalysisHeaderKey += 1;
      const filterConfig: ProjectReportFiltersConfig = this
        .dirtyFiltersConfiguration;
      if (this.projectAuditList.length === 1) {
        const projectAudit: AuditDetail = this.projectAuditList[0];
        filterConfig.selectedPeriod = {
          title: projectAudit.name,
          startedAt: projectAudit.startDate,
          endedAt: projectAudit.endDate,
          isAudit: true,
          auditIds: [projectAudit.id],
        };
        this.auditsConfigurationList.push(filterConfig.selectedPeriod);
      } else {
        this.projectAuditList.forEach((projectAudit) => {
          if (projectAudit.startedAt) {
            filterConfig.selectedPeriod = {
              title: projectAudit.name,
              startedAt: projectAudit.startDate,
              endedAt: projectAudit.endDate,
              isAudit: true,
              auditIds: [projectAudit.id],
            };
            this.auditsConfigurationList.push(filterConfig.selectedPeriod);
            this.dirtyTotalAnalysis.forEach(
              (dirtyAnalysis, dirtyAnalysisIndex) => {
                const dirtyFilterConfig = dirtyAnalysis.filtersConfiguration;
                if (
                  dirtyFilterConfig.selectedPeriod.isAudit &&
                  dirtyFilterConfig.selectedPeriod.auditIds[0] ===
                    projectAudit.id
                ) {
                  this.dirtyTotalAnalysis[
                    dirtyAnalysisIndex
                  ].filtersConfiguration.selectedPeriod = {
                    ...dirtyFilterConfig.selectedPeriod,
                    title: projectAudit.name,
                    endedAt: projectAudit.endDate,
                  };
                }
                if (dirtyFilterConfig.checkComparison) {
                  dirtyFilterConfig.comparisonPeriods.forEach(
                    (comparisonPeriod, comparisonPeriodIndex) => {
                      if (
                        comparisonPeriod.auditIds.length === 1 &&
                        comparisonPeriod.auditIds[0] === projectAudit.id
                      ) {
                        this.dirtyTotalAnalysis[
                          dirtyAnalysisIndex
                        ].filtersConfiguration.comparisonPeriods[
                          comparisonPeriodIndex
                        ] = {
                          ...comparisonPeriod,
                          endedAt: projectAudit.endDate,
                        };
                      }
                    },
                  );
                }
                let filteredSites: number[] = _cloneDeep(
                  dirtyFilterConfig.filteredSites,
                ).filter((filteredSiteId: number) => {
                  const projectSite:
                    | ProjectSite
                    | undefined = this.projectSites.find(
                    (site: ProjectSite) => {
                      return site.id === filteredSiteId;
                    },
                  );
                  if (projectSite) {
                    return filteredSiteId;
                  }
                });
                filteredSites =
                  filteredSites.length > 0
                    ? filteredSites
                    : this.projectSites.map((site) => site.id);

                this.dirtyTotalAnalysis[
                  dirtyAnalysisIndex
                ].filtersConfiguration = {
                  ...dirtyFilterConfig,
                  filteredSites,
                  checkIndividualSite:
                    filteredSites.length > 1
                      ? dirtyFilterConfig.checkIndividualSite
                      : false,
                };
              },
            );
          }
        });
      }
      this.showIndividualAnalysis = true;
      if (this.dirtyTotalAnalysis.length > this.displayTab) {
        this.showCompressedAnalysisHeader = true;
        this.compressedAnalysisHeaderKey += 1;
      }

      this.analysisToExport = _cloneDeep(this.dirtyTotalAnalysis);
    }
  }

  get generateFilterConfig(): ProjectReportFiltersConfig {
    this.auditsConfigurationList = [];
    const filterConfig: ProjectReportFiltersConfig = this
      .dirtyFiltersConfiguration;
    if (this.projectAuditList.length === 1) {
      const projectAudit: AuditDetail = this.projectAuditList[0];
      filterConfig.selectedPeriod = {
        title: projectAudit.name,
        startedAt: projectAudit.startDate,
        endedAt: projectAudit.endDate,
        isAudit: true,
        auditIds: [projectAudit.id],
      };
      filterConfig.comparisonPeriods.push(filterConfig.selectedPeriod);
      this.auditsConfigurationList.push(filterConfig.selectedPeriod);
    } else {
      let countActiveAudits: number = 0;
      this.projectAuditList.forEach((projectAudit) => {
        if (projectAudit.startedAt) {
          countActiveAudits += 1;
          filterConfig.selectedPeriod = {
            title: projectAudit.name,
            startedAt: projectAudit.startDate,
            endedAt: projectAudit.endDate,
            isAudit: true,
            auditIds: [projectAudit.id],
          };
          filterConfig.comparisonPeriods.push(filterConfig.selectedPeriod);
          this.auditsConfigurationList.push(filterConfig.selectedPeriod);
        }
      });
      if (countActiveAudits > 1) {
        filterConfig.checkComparison = true;
      }
    }
    return filterConfig;
  }

  @Watch('onProjectSitesCriteriaAuditListTotalAnalysisSuccess')
  @isTruthy
  public watchAuditSitesCriteriaAuditListTotalAnalysisSuccess() {
    this.dirtyFiltersConfiguration.filteredSites = this.getAllSiteIds;
    this.dirtyFiltersConfiguration.filteredCriterias = this.getAllCriteriaIds;
  }

  @Watch('getProjectSitesSuccess')
  public watchGetProjectSitesSuccess() {
    this.projectSitesCriteriaAuditListTotalAnalysisSuccess();
  }

  @Watch('getProjectCriteriaSuccess')
  public watchGetProjectCriteriaSuccess() {
    this.projectSitesCriteriaAuditListTotalAnalysisSuccess();
  }

  @Watch('getProjectAuditListSuccess')
  public watchGetProjectAuditListSuccess() {
    this.projectSitesCriteriaAuditListTotalAnalysisSuccess();
  }

  @Watch('getTotalAnalysisSuccess')
  public watchGetTotalAnalysisSuccess() {
    this.projectSitesCriteriaAuditListTotalAnalysisSuccess();
  }

  public projectSitesCriteriaAuditListTotalAnalysisSuccess() {
    if (
      this.projectSites &&
      this.projectCriteria &&
      this.projectAuditList &&
      this.getTotalAnalysisSuccess
    ) {
      const startedAudits: AuditDetail[] = [];
      this.projectAuditList.forEach((projectAudit: AuditDetail) => {
        if (projectAudit.startedAt) {
          startedAudits.push(projectAudit);
        }
      });
      if (startedAudits.length !== 0) {
        this.showNullContainer = false;
        this.onProjectSitesCriteriaAuditListTotalAnalysisSuccess = true;
        this.populateData();
        this.$nextTick(() => {
          this.analysisTabContainerWidth = (this.$refs
            .container as Vue).$el.clientWidth;
        });
      } else {
        this.showNullContainer = true;
      }
    } else {
      this.onProjectSitesCriteriaAuditListTotalAnalysisSuccess = false;
    }
  }

  public handleAnalysisFilterUpdate(newValue: ProjectReportFiltersConfig) {
    this.dirtyTotalAnalysis[
      this.selectedTabIndex
    ].filtersConfiguration = _cloneDeep(newValue);
    const matchedIndex = this.analysisToExport.findIndex(
      (analysis: ProjectReportAnalysis) => {
        return (
          analysis.analysisId ===
          this.dirtyTotalAnalysis[this.selectedTabIndex].analysisId
        );
      },
    );
    if (matchedIndex !== -1) {
      this.analysisToExport[matchedIndex] = _cloneDeep(
        this.dirtyTotalAnalysis[this.selectedTabIndex],
      );
    }
  }

  public handleAnalysisIdUpdate(newValue: number) {
    this.dirtyTotalAnalysis[this.selectedTabIndex].analysisId = newValue;
    if (this.dirtyTotalAnalysis.length === 1) {
      this.analysisToExport = _cloneDeep(this.dirtyTotalAnalysis);
    } else {
      const matchedIndex = this.analysisToExport.findIndex(
        (analysis: ProjectReportAnalysis) => {
          return (
            analysis.title ===
            this.dirtyTotalAnalysis[this.selectedTabIndex].title
          );
        },
      );
      if (matchedIndex !== -1) {
        this.analysisToExport[matchedIndex] = _cloneDeep(
          this.dirtyTotalAnalysis[this.selectedTabIndex],
        );
      }
    }
    this.exportFilterKey += 1;
  }

  public handleSelectedAnalysis(analysisItemIndex: number) {
    this.selectedTabIndex = analysisItemIndex;
    this.visibleAnalysisHeaderKey += 1;
    this.individualAnalysisKey += 1;
  }

  public handleAnalysisTitleUpdate(newAnalysisTitle: string) {
    this.dirtyTotalAnalysis[this.selectedTabIndex].title = newAnalysisTitle;
    const matchedIndex = this.analysisToExport.findIndex(
      (analysis: ProjectReportAnalysis) => {
        return (
          analysis.analysisId ===
          this.dirtyTotalAnalysis[this.selectedTabIndex].analysisId
        );
      },
    );
    if (matchedIndex !== -1) {
      this.analysisToExport[matchedIndex] = _cloneDeep(
        this.dirtyTotalAnalysis[this.selectedTabIndex],
      );
    }
    this.visibleAnalysisHeaderKey += 1;
    this.individualAnalysisKey += 1;
  }

  public handleDeleteAnalysis() {
    this.dirtyTotalAnalysis.splice(this.selectedTabIndex, 1);
    this.analysisToExport = _cloneDeep(this.dirtyTotalAnalysis);
    this.visibleAnalysisHeaderKey += 1;
    this.showCompressedAnalysisHeader = false;
    this.individualAnalysisKey += 1;
    this.exportFilterKey += 1;
    this.selectedTabIndex = 0;
    if (this.dirtyTotalAnalysis.length === 0) {
      this.showIndividualAnalysis = false;
    }
    if (this.dirtyTotalAnalysis.length > this.displayTab) {
      this.showCompressedAnalysisHeader = true;
      this.compressedAnalysisHeaderKey += 1;
    }
  }

  public addNewAnalysis() {
    const filterConfig: ProjectReportFiltersConfig = this.generateFilterConfig;
    this.dirtyTotalAnalysis.unshift({
      title: 'Analysis ' + (this.dirtyTotalAnalysis.length + 1),
      analysisId: Math.floor(Math.random() * 9000 + 1000),
      filtersConfiguration: filterConfig,
    });
    this.analysisToExport = _cloneDeep(this.dirtyTotalAnalysis);
    this.exportFilterKey += 1;
    this.showIndividualAnalysis = true;
    this.visibleAnalysisHeaderKey += 1;
    this.individualAnalysisKey += 1;
    this.selectedTabIndex = 0;
    if (this.dirtyTotalAnalysis.length > this.displayTab) {
      this.showCompressedAnalysisHeader = true;
      this.compressedAnalysisHeaderKey += 1;
    }
  }

  public handleCompressedAnalysisClick(compressedAnalysisIndex: number) {
    const selectedCompressedAnalysis: ProjectReportAnalysis = this
      .dirtyTotalAnalysis[this.displayTab + compressedAnalysisIndex];
    this.dirtyTotalAnalysis.splice(
      this.displayTab + compressedAnalysisIndex,
      1,
    );
    this.dirtyTotalAnalysis.unshift(selectedCompressedAnalysis);
    this.visibleAnalysisHeaderKey += 1;
    this.compressedAnalysisHeaderKey += 1;
    this.individualAnalysisKey += 1;
    this.selectedTabIndex = 0;
  }

  public handleOverallExportAnalysisFilter(newValue: number[]) {
    if (newValue.length === 0) {
      this.isExportDisabled = true;
    } else {
      this.isExportDisabled = false;
      const dirtyAnalysisToExport: ProjectReportAnalysis[] = [];
      newValue.forEach((analysisId) => {
        const dirtyAnalysis:
          | ProjectReportAnalysis
          | undefined = this.dirtyTotalAnalysis.find(
          (analysis: ProjectReportAnalysis) => {
            return analysis.analysisId === analysisId;
          },
        );
        if (dirtyAnalysis) {
          dirtyAnalysisToExport.push(dirtyAnalysis);
        }
      });
      this.analysisToExport = _cloneDeep(dirtyAnalysisToExport);
    }
  }

  public async handleExport(exportType: string) {
    const reportAnalysisResponse: ProjectReportAnalysisResponseDto[] = [];
    for (const analysis of this.analysisToExport) {
      const payload: any = {
        ...analysis.filtersConfiguration,
        projectId: this.getProjectId,
        analysisId: analysis.analysisId,
        checkIndividualSite:
          analysis.filtersConfiguration.filteredSites.length > 1
            ? analysis.filtersConfiguration.checkIndividualSite
            : false,
        type: this.projectSites.length > 1 ? 'multiSite' : 'singleSite',
      };

      try {
        const response: any = await projectsApi.getProjectReportExport(
          payload.projectId,
          payload,
        );
        reportAnalysisResponse.push({
          result: response.data,
          payload,
          analysisTitle: analysis.title,
          jbiLogo: JbiLogo,
          projectTitle: this.projectDetail.title,
          projectSites: this.projectSites,
          projectCriteria: this.projectCriteria,
        });
      } catch (error) {
        handleErrorResponse(error, this);
        break;
      }
    }

    if (reportAnalysisResponse.length > 0) {
      switch (exportType) {
        case reportExportType[0]:
          this.handleExportToPdf(reportAnalysisResponse);
          break;
        case reportExportType[1]:
          this.handleExportToDocx(reportAnalysisResponse);
          break;
        case reportExportType[2]:
          this.handleExportToCsv(reportAnalysisResponse);
          break;
        case reportExportType[3]:
          this.handleExportToExcel(reportAnalysisResponse);
          break;
        default:
          break;
      }
    }
  }

  public handleExportToPdf(
    reportResponse: ProjectReportAnalysisResponseDto[],
  ): void {
    const projectReportExportToPdf = new ProjectReportExportToPdf();
    projectReportExportToPdf.create(reportResponse, this.projectDetail.title);
  }

  public handleExportToDocx(
    reportResponse: ProjectReportAnalysisResponseDto[],
  ): void {
    const projectReportExportToDocx = new ProjectReportExportToDocx();
    projectReportExportToDocx.create(reportResponse, this.projectDetail.title);
  }

  public handleExportToCsv(
    reportResponse: ProjectReportAnalysisResponseDto[],
  ): void {
    const projectReportExportToCsv = new ProjectReportExportToCsv();
    projectReportExportToCsv.create(reportResponse, this.projectDetail.title);
  }

  public handleExportToExcel(
    reportResponse: ProjectReportAnalysisResponseDto[],
  ): void {
    const projectReportExportToExcel = new ProjectReportExportToExcel();
    projectReportExportToExcel.create(reportResponse, this.projectDetail.title);
  }

  get getAnalysisDetail(): ProjectReportAnalysis {
    return this.dirtyTotalAnalysis[this.selectedTabIndex];
  }

  get getAllSiteIds(): number[] {
    const siteIdList: number[] = [];
    _map(this.projectSites, (projectSite: ProjectSite) => {
      siteIdList.push(projectSite.id);
    });
    return siteIdList;
  }

  get getAllCriteriaIds(): number[] {
    const criteriaIdList: number[] = [];
    _map(this.projectCriteria, (projectCriteria: ProjectCriteria) => {
      criteriaIdList.push(projectCriteria.id);
    });
    return criteriaIdList;
  }

  get getTotalAnalysis(): ProjectReportAnalysis[] {
    return this.dirtyTotalAnalysis;
  }

  get getVisibleAnalysis(): ProjectReportAnalysis[] {
    return this.dirtyTotalAnalysis.slice(0, this.displayTab);
  }

  get getCompressedAnalysis(): ProjectReportAnalysis[] {
    return this.dirtyTotalAnalysis.slice(this.displayTab);
  }

  get displayTab(): number {
    const tab = Math.floor((this.analysisTabContainerWidth - 220) / 150);
    if (tab === -1 || tab === 0) {
      return 1;
    }
    return tab;
  }

  get getProjectId(): number {
    return +this.$route.params.projectId;
  }

  get projectSiteList(): ProjectSite[] {
    return this.projectSites;
  }

  get projectCriteriaList(): ProjectCriteria[] {
    return this.projectCriteria;
  }

  get getAuditConfigurationList(): ProjectReportPeriod[] {
    return this.auditsConfigurationList;
  }

  @Watch('getTotalProjectReportAnalysisState.success')
  @isTruthy
  public watchGetTotalProjectReportAnalysisState(): void {
    this.totalAnalysis = JSON.parse(
      JSON.stringify(this.totalProjectReportAnalysis),
    );
    this.analysisToExport = JSON.parse(
      JSON.stringify(this.totalProjectReportAnalysis),
    );
    this.getTotalAnalysisSuccess = true;
  }
}
