










import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { CreateElement, VueConstructor } from 'vue';
import { get as _get } from 'lodash';
import { merge } from 'rxjs';
import { distinctUntilChanged, debounceTime, filter } from 'rxjs/operators';
import { pluckNewValue } from '@/jbi-shared/util/pluck.rx-operator';
import { PaginatedApiResponse } from '@/store/types/general.types';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { mixins } from 'vue-class-component';
import { PaginationMixin } from '@/components/base/pagination.mixin.ts';

@Component({
    subscriptions() {
        return {};
    },
})
export default class BasePaginatorHoc extends mixins(PaginationMixin) {
    @Prop({ type: Function, required: true })
    public component!: VueConstructor;
    @Prop() public items!: any[];
    @Prop() public totalCount!: number;
    @Prop() public filteredParams!: any;

    get totalNumberOfPage(): number {
        return Math.ceil(this.totalCount / this.perPage);
    }

    get startItemIndex(): number {
        return this.page * this.perPage - this.perPage + 1;
    }

    get endItemIndex(): number {
        return Math.min(this.totalCount, this.page * this.perPage);
    }

    get componentProps() {
        return {
            ...this.$props,
            ...this.$attrs,
            items: this.items,
            page: this.page,
            totalNumberOfPage: this.totalNumberOfPage,
            totalCount: this.totalCount,
            perPage: this.perPage,
            isFirstPage: this.page === 1,
            isLastPage: this.page === this.totalNumberOfPage,
            startItemIndex: this.startItemIndex,
            endItemIndex: this.endItemIndex,
        };
    }

    public goToPage(page: number) {
        if (page > 0 && page <= this.totalNumberOfPage) {
            this.page = page;
        }
    }

    public updatePerPage(perPage: number) {
        this.perPage = perPage;
    }

    public created() {
        merge(
            this.$watchAsObservable('page', { immediate: true }),
            this.$watchAsObservable('perPage', { immediate: true }),
        )
            .pipe(debounceTime(300))
            .subscribe(() => {
                this.$emit('paginate', {
                    perPage: this.perPage,
                    page: this.page,
                });
            });
    }

    @Watch('perPage')
    public onPerPageChanged() {
        this.page = 1;
    }
}
