

























import { Component, Vue, Prop, Watch, Model } from 'vue-property-decorator';
import { State, Getter, Action, Mutation, namespace } from 'vuex-class';

interface Options {
  label: string;
  value: string | number;
}

@Component({
  components: {},
})
export default class BaseDropDown extends Vue {
  @Prop({ default: [] })
  public options!: Options[];

  @Prop({ default: false })
  public multiple!: boolean;

  @Prop()
  public value!: string | string[];

  public renderLabel(values: Options | Options[]) {
    if (Array.isArray(values)) {
      return values.map(({ label }) => label).join(', ');
    }

    return values.label;
  }
}
