import { ProjectSite } from '@/store/modules/projects/types/projects.types';
import { cloneDeep as _cloneDeep, get as _get, map as _map } from 'lodash';
import { CRITERION_TYPE } from '@/store/types/criterions.types';
import dayjs from 'dayjs';

export class GenerateSingleSiteCsv {
  public dirtyMonths: string[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];

  public create(
    result: any,
    payload: any,
    analysisTitle: string,
    projectTitle: string,
    projectSites: ProjectSite[],
  ) {
    const exportDateTime = dayjs().format('D MMM YYYY, HH:mm A');
    const responseArray: any[] = [];
    responseArray.push({
      column1: 'Project Name',
      column2: projectTitle,
    });
    responseArray.push({
      column1: 'Analysis Name',
      column2: analysisTitle,
    });
    responseArray.push({
      column1: 'Site',
      column2: projectSites[0].site.name,
    });
    responseArray.push({
      column1: 'Data Collection Period',
      column2:
        payload.selectedPeriod.title +
        ': ' +
        this.formatDateRange(payload.selectedPeriod),
    });
    responseArray.push(
      ...this.generateCriteriaTables(result, projectSites, payload),
    );
    responseArray.push(...this.generateSingleRowSpace());
    responseArray.push({
      column1: 'Exported on ' + exportDateTime,
    });
    return responseArray;
  }

  public generateCriteriaTables(
    result: any,
    projectSites: ProjectSite[],
    payload: any,
  ): any {
    const criteriaStatistics: any[] = [];
    const { aggregateCriteriaConfiguration } = this.generateConfigurationData(
      result.statisticData.configurationData,
      result.statisticData.samplingConfiguration,
    );
    aggregateCriteriaConfiguration.forEach(
      (criteriaDetails, criteriaDetailsIndex) => {
        criteriaStatistics.push(...this.generateMultipleRowsSpace());
        criteriaStatistics.push({
          column1: criteriaDetailsIndex + 1 + '. ' + criteriaDetails.title,
        });
        criteriaStatistics.push({
          column1: 'Answer Choices',
          column2: projectSites[0].site.name,
        });
        let total: number = 0;
        let trueValue: number = 0;
        let naValue: number = 0;
        criteriaDetails.criteriaSamplingData.forEach(
          (optionsData: any, optionDataIndex: number) => {
            const option = Object.keys(optionsData)[0];
            switch (optionDataIndex) {
              case 0:
                trueValue = optionsData[option];
                break;
              case 1:
                break;
              default:
                naValue = optionsData[option];
            }
            total += optionsData[option];
            criteriaStatistics.push({
              column1: option,
              column2: optionsData[option],
            });
          },
        );
        let formattedTotal;
        if (total.toString().includes('-')) {
          formattedTotal = '-';
        } else {
          formattedTotal = total;
        }
        criteriaStatistics.push({
          column1: 'Total Data Collected',
          column2: formattedTotal,
        });
        if (criteriaDetails.criteriaType === CRITERION_TYPE.BOOLEAN) {
          const compliance = (trueValue / (total - naValue)) * 100;
          criteriaStatistics.push({
            column1: 'Compliance',
            column2: compliance
              ? Math.round(compliance * 100) / 100 + '%'
              : '0%',
          });
        }
        // sampling table
        criteriaStatistics.push(...this.generateSingleRowSpace());
        criteriaStatistics.push({
          column1: 'Sampling',
        });
        criteriaStatistics.push({
          column1:
            payload.selectedPeriod.title +
            '\n' +
            this.formatDateRange(payload.selectedPeriod),
        });
        if (!payload.selectedPeriod.isAudit) {
          criteriaStatistics.push({
            column1: 'Site',
            column2: 'Sampling',
          });
          criteriaStatistics.push({
            column1:
              criteriaDetails.siteSamplingConfiguration[0].auditSiteMap.site
                .name,
            column2: '-',
          });
        } else {
          switch (criteriaDetails.samplingConfiguration.auditSamplingType) {
            case 'adHoc':
              criteriaStatistics.push({
                column1: 'Site',
                column2: 'Sampling',
              });
              criteriaStatistics.push({
                column1:
                  criteriaDetails.siteSamplingConfiguration[0].auditSiteMap.site
                    .name,
                column2: criteriaDetails.criteriaSamplingDataConfiguration
                  .isSamplingEnabled
                  ? 'Enabled'
                  : 'Disabled',
              });
              break;
            case 'consecutive':
              if (
                criteriaDetails.samplingConfiguration.samplingMode ===
                'minAndMax'
              ) {
                criteriaStatistics.push({
                  column1: 'Site',
                  column2: 'Min.',
                  column3: 'Max.',
                });
                criteriaStatistics.push({
                  column1:
                    criteriaDetails.siteSamplingConfiguration[0].auditSiteMap
                      .site.name,
                  column2:
                    criteriaDetails.criteriaSamplingDataConfiguration
                      .minSampleSize,
                  column3:
                    criteriaDetails.criteriaSamplingDataConfiguration
                      .maxSampleSize,
                });
              } else {
                criteriaStatistics.push({
                  column1: 'Site',
                  column2: 'Target',
                });
                criteriaStatistics.push({
                  column1:
                    criteriaDetails.siteSamplingConfiguration[0].auditSiteMap
                      .site.name,
                  column2:
                    criteriaDetails.criteriaSamplingDataConfiguration
                      .sampleSize,
                });
              }
              break;
            default:
              criteriaStatistics.push({
                column1: 'Site',
                column2: 'Target',
              });
              criteriaStatistics.push({
                column1:
                  criteriaDetails.siteSamplingConfiguration[0].auditSiteMap.site
                    .name,
                column2:
                  criteriaDetails.criteriaSamplingDataConfiguration.sampleSize,
              });
          }
        }
      },
    );
    return criteriaStatistics;
  }

  public generateConfigurationData(
    configurationData: any[],
    samplingConfiguration: any,
  ) {
    let aggregateCriteriaConfiguration: any[];
    if (Array.isArray(configurationData) && configurationData.length > 0) {
      const aggregateConfig: any = configurationData.map((data: any) => {
        const criteriaOptionsDataDistribution =
          data.criteriaSamplingData.criteriaOptionsDataDistribution;
        const criteriaSamplingData = Object.keys(
          criteriaOptionsDataDistribution,
        ).map((key: string) => ({
          [key]: data.criteriaSamplingData.criteriaOptionsDataDistribution[key],
        }));
        return {
          title: _get(data, 'projectCriteria.title', ''),
          criteriaType: _get(data, 'projectCriteria.criteriaType', ''),
          sampleSize: Number(
            _get(data, 'criteriaSamplingDataConfigurations.sampleSize', 0),
          ),
          siteSamplingConfiguration: data.siteSamplingDataConfigurations,
          criteriaSamplingDataConfiguration:
            data.criteriaSamplingDataConfigurations,
          samplingConfiguration,
          criteriaSamplingData,
          isAuditCriteria: data.isAuditCriteria,
        };
      });
      aggregateCriteriaConfiguration = _cloneDeep(aggregateConfig);
    } else {
      aggregateCriteriaConfiguration = [];
    }
    return { aggregateCriteriaConfiguration };
  }

  public generateSingleRowSpace(): any {
    return [{}];
  }

  public generateMultipleRowsSpace(): any {
    return [{}, {}, {}];
  }

  public formatDateRange(dateObject: any): string {
    const startDate = new Date(dateObject.startedAt);
    let endDate = dateObject.endedAt;
    if (endDate === null) {
      endDate = dayjs().endOf('day').format();
    }
    return this.getDate(startDate) + ' - ' + this.getDate(new Date(endDate));
  }

  public getDate(newValue: Date): string {
    return (
      newValue.getDate() +
      ' ' +
      this.dirtyMonths[newValue.getMonth()] +
      ' ' +
      newValue.getFullYear()
    );
  }
}
