import _axios from 'axios';
import {
  nprogressRequestInterceptor,
  nprogressErrorInterceptor,
  nprogressResponseInterceptor,
} from '@/jbi-shared/axios-interceptors/axios-loading.interceptor';
import {
  axiosErrorInterceptor,
  axiosResponseInterceptor,
} from '@/jbi-shared/axios-interceptors/axios-response.interceptor';

export const axios = _axios;

// interceptor to display nprogress
axios.interceptors.request.use(
  nprogressRequestInterceptor,
  nprogressErrorInterceptor,
);
axios.interceptors.response.use(
  nprogressResponseInterceptor,
  nprogressErrorInterceptor,
);
axios.interceptors.response.use(
  axiosResponseInterceptor,
  axiosErrorInterceptor,
);
