





































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { cloneDeep as _cloneDeep } from 'lodash';
import { get as _get } from 'lodash';

@Component({
  components: {},
})
export default class ProjectOwnerRejectForm extends Vue {
  @Prop()
  public projectName!: string;
  @Prop()
  public notificationSenderName!: string;
  @Prop()
  public isReclaimProject!: boolean;
  public reason: string = '';

  public submitReason() {
    this.$emit('confirm', this.reason);
    this.$emit('close');
  }
}
