// TODO: rename all these variable to CplusSomething, because all these are purely for Cplus
export enum CasbinResource {
  Projects = 'projects',
}

export enum CasbinAction {
  GET = 'read',
  POST = 'write',
  PUT = 'update',
  DELETE = 'delete',
}

export type CasbinRoles = GeneralRoles | ProjectRoles;

export enum GeneralRoles {
  Admin = 'admin',
  User = 'user',
}

export enum ProjectRoles {
  ProjectOwner = 'project_owner_',
  ProjectCoordinator = 'project_coordinator_',
  ProjectDataCollector = 'data_collector',
}

export interface RoleGuardMetadata {
  role: CasbinRoles;
  param?: string;
}
