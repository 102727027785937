

























































import { Component, Vue, Prop } from 'vue-property-decorator';
import { ProjectCriteria } from '../../../store/modules/projects/types/projects.types';
import { GripAnalysis } from '../../../store/types/grip.types';
import ExportGripReportModalAnalysisList from './ExportGripReportModalAnalysisList.vue';

@Component({
  components: {
    ExportGripReportModalAnalysisList,
  },
})
export default class ViewGripAnalysisDetailsModal extends Vue {
  @Prop() public gripAnalysis!: GripAnalysis;

  get analysisCriteria(): ProjectCriteria[] {
    return this.gripAnalysis.gripAnalysisCriteriaMaps
      .filter((criteriaMap) => !criteriaMap.criteria.isDeleted)
      .map((criteriaMap) => criteriaMap.criteria);
  }
}
