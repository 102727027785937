














import { Component, Prop } from 'vue-property-decorator';
import BasePaginatorHoc from '@/components/base/BasePaginatorHoc.vue';
import { mixins } from 'vue-class-component';
import { PaginationMixin } from '@/components/base/pagination.mixin';
import ProjectList from '@/views/AdminProjectManagement/components/ProjectList.vue';
import {
  AdminProjectDTO,
  FilteredProjectPayload,
} from '@/store/modules/projects/types/projects.types';

@Component({
  components: { ProjectList, BasePaginatorHoc },
})
export default class PaginatedProjectList extends mixins(PaginationMixin) {
  @Prop() public items!: AdminProjectDTO[];
  @Prop() public totalCount!: number;
  @Prop() public filteredParams!: FilteredProjectPayload;

  get ProjectList() {
    return ProjectList;
  }
}
