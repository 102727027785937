import { defaultApiState } from '@/store/types/general.types';
import {
  NotificationState,
  UserNotificationPayload,
} from './types/notification.types';
import { AxiosError } from 'axios';
import { UpdateViewedSingleNotificationPayload } from '@/store/modules/notification/types/notification.types';

export const notificationMutations = {
  /**
   * get User Notification
   * @param state
   * @constructor
   */
  GET_USER_NOTIFICATION_LOADING(state: NotificationState) {
    state.apiState.getUserNotification = {
      ...defaultApiState,
      loading: true,
    };
    state.userNotification = undefined;
  },
  GET_USER_NOTIFICATION_SUCCESS(
    state: NotificationState,
    payload: UserNotificationPayload,
  ) {
    state.apiState.getUserNotification = {
      ...defaultApiState,
      success: true,
    };
    state.notificationDropdown = undefined;
    state.userNotification = payload;
  },
  GET_USER_NOTIFICATION_ERROR(state: NotificationState, error: AxiosError) {
    state.apiState.getUserNotification = {
      ...defaultApiState,
      error,
    };
  },
  /**
   * update User Viewed Notification
   * @param state
   * @constructor
   */
  UPDATE_VIEWED_NOTIFICATION_LOADING(state: NotificationState) {
    state.apiState.updateViewedNotification = {
      ...defaultApiState,
      loading: true,
    };
    state.userViewedNotifications = undefined;
  },
  UPDATE_VIEWED_NOTIFICATION_SUCCESS(state: NotificationState, payload: any) {
    state.apiState.updateViewedNotification = {
      ...defaultApiState,
      success: true,
    };
    state.userViewedNotifications = payload;
  },
  UPDATE_VIEWED_NOTIFICATION_ERROR(
    state: NotificationState,
    error: AxiosError,
  ) {
    state.apiState.updateViewedNotification = {
      ...defaultApiState,
      error,
    };
  },
  /**
   * get User Notification Dropdown
   * @param state
   * @constructor
   */
  GET_NOTIFICATION_DROPDOWN_LOADING(state: NotificationState) {
    state.apiState.getNotificationDropdown = {
      ...defaultApiState,
      loading: true,
    };
    state.notificationDropdown = undefined;
  },
  GET_NOTIFICATION_DROPDOWN_SUCCESS(
    state: NotificationState,
    payload: UserNotificationPayload,
  ) {
    state.apiState.getNotificationDropdown = {
      ...defaultApiState,
      success: true,
    };
    state.notificationDropdown = payload;
  },
  GET_NOTIFICATION_DROPDOWN_ERROR(state: NotificationState, error: AxiosError) {
    state.apiState.getNotificationDropdown = {
      ...defaultApiState,
      error,
    };
  },
  /**
   * update User Viewed Single Notification
   * @param state
   * @constructor
   */
  UPDATE_VIEWED_SINGLE_NOTIFICATION_LOADING(state: NotificationState) {
    state.apiState.updateViewedSingleNotification = {
      ...defaultApiState,
      loading: true,
    };
    state.userViewedSingleNotification = undefined;
  },
  UPDATE_VIEWED_SINGLE_NOTIFICATION_SUCCESS(
    state: NotificationState,
    payload: UpdateViewedSingleNotificationPayload,
  ) {
    state.apiState.updateViewedSingleNotification = {
      ...defaultApiState,
      success: true,
    };
    state.userViewedSingleNotification = payload;
  },
  UPDATE_VIEWED_SINGLE_NOTIFICATION_ERROR(
    state: NotificationState,
    error: AxiosError,
  ) {
    state.apiState.updateViewedSingleNotification = {
      ...defaultApiState,
      error,
    };
  },
};
