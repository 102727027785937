





























































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import DefaultLayout from '../../layouts/DefaultLayout.vue';
import HeroBar from '@/components/base/HeroBar.vue';
import { Action, State } from 'vuex-class';
import {
  ProjectDetailPayload,
  ProjectParticipantDTO,
  ProjectSettingResponsePayload,
  ProjectStatus,
  RecurrenceSettingPayload,
  UserEntity,
  UserRoleInProject,
} from '@/store/modules/projects/types/projects.types';
import dayjs from 'dayjs';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '@/utils/date.util';
import { last as _last, isEqual as _isEqual } from 'lodash';
import {
  isDifferentDeep,
  isTruthy,
} from '@/jbi-shared/util/watcher.vue-decorator';
import RecurrenceSettings from '@/views/Project/components/RecurrenceSettings.vue';
import AuditCard from '@/views/Project/components/AuditCard.vue';
import AuditList from '@/views/Project/components/AuditList.vue';
import ProjectDashboardTab from '@/views/Project/tabs/ProjectDashboardTab.vue';
import ProjectCriteriaTab from '@/views/Project/tabs/ProjectCriteriaTab.vue';
import ParticipantTab from '@/views/Project/tabs/ParticipantTab.vue';
import ProjectReportsTab from '@/views/Project/tabs/ProjectReportsTab.vue';
import ProjectEventLogs from '@/views/Project/components/ProjectEventLogs.vue';
import { useUserEmail } from '@/utils/user.util';
import ProjectSettingsTab from '@/views/Project/tabs/ProjectSettingsTab.vue';
import GripTab from '../Grip/GripTab.vue';

/**
 * Declaration of available tabs in the page
 */
const tabNames = [
  '#dashboard',
  '#criteria',
  '#participants',
  '#grip',
  '#report',
  '#settings',
  '#activityLog',
];

@Component({
  components: {
    ProjectEventLogs,
    AuditList,
    AuditCard,
    RecurrenceSettings,
    DefaultLayout,
    HeroBar,
    ParticipantTab,
    ProjectDashboardTab,
    ProjectCriteriaTab,
    ProjectReportsTab,
    ProjectSettingsTab,
    GripTab,
  },
})
export default class ProjectDetailPage extends Vue {
  public activeTab: number = 0;
  public activeSettingsTab: number = 0;
  public projectExpiry: any = null;
  public recurrenceSetting!: RecurrenceSettingPayload;
  public initialValue: any = {};
  public isSettingSubmitButtonDisabled: boolean = true;
  public isGeneralSettingSubmitButtonDisabled: boolean = true;
  public deletedCriteriaList: number[] = [];
  public projectOwner: UserEntity | null = null;
  public projectSettingInfo: any = {
    name: '',
    description: '',
    enableProjectGrip: true,
  };

  public isProjectArchived: boolean = false;
  public showDashboardTab: boolean = false;
  public showCriteriaTab: boolean = false;
  public showParticipantsTab: boolean = false;
  public showReportTab: boolean = false;
  public showGripTab: boolean = false;
  public showActivityLogTab: boolean = false;
  public showSettingTab: boolean = false;
  public showProjectDetail: boolean = false;
  public criteriaTabKey: number = Math.floor(Math.random() * 999);
  public dashboardTabKey: number = Math.floor(Math.random() * 999);
  public participantsTabKey: number = Math.floor(Math.random() * 999);
  public reportTabKey: number = Math.floor(Math.random() * 999);
  public gripTabKey: number = Math.floor(Math.random() * 999);
  public activityLogTabKey: number = Math.floor(Math.random() * 999);
  public settingTabKey: number = Math.floor(Math.random() * 999);
  public isLoading: boolean = true;

  @Action('projects/getProjectDetail')
  public getProjectDetail!: (projectId: number) => void;

  @State((state) => state.projects.projectDetail)
  public projectDetail!: ProjectDetailPayload;

  @State((state) => state.projects.apiState.getProjectDetail.success)
  public getProjectDetailApiStateSuccess!: boolean;

  @Action('projects/getUpdatedProjectReportJBICriteriaList')
  public getUpdatedProjectReportJBICriteriaList!: (projectId: number) => void;

  @Watch('getProjectDetailApiStateSuccess')
  @isTruthy
  public watchGetProjectDetailSuccess() {
    if (
      this.projectDetail.status === 'Deleted' ||
      !this.userEmail ||
      !this.isUserProjectOwnerOrCoordinator
    ) {
      this.$router.push({
        name: 'project-me',
      });
    }

    this.projectSettingInfo = {
      name: this.projectDetail.title,
      description: this.projectDetail.description,
      enableProjectGrip: this.projectDetail.enableProjectGrip,
    };
    this.transformSettings(this.projectDetail.settings);
    this.changeActiveTabBasedOnUrl();
    this.projectOwner = this.projectDetail.user;
    this.isLoading = false;
    this.isProjectArchived = Boolean(this.projectDetail.status === 'Archived');
  }

  public handleUpdatedProjectStatus(status: ProjectStatus) {
    this.isProjectArchived = Boolean(status === ProjectStatus.ARCHIVED);
  }

  public updateProjectSettingInfo(newValue: any): void {
    this.projectSettingInfo = {
      name: newValue.title,
      description: newValue.description,
      enableProjectGrip: newValue.enableProjectGrip,
    };
  }

  get userEmail(): string | undefined {
    return useUserEmail.call(this);
  }

  public tabChange() {
    this.isLoading = true;
    this.getProjectDetail(this.projectId);
    this.$router.replace({ hash: tabNames[this.activeTab] });
    if (tabNames[this.activeTab] === '#report') {
      this.getUpdatedProjectReportJBICriteriaList(this.projectId);
    }

    this.renderTabComponent(true);
  }

  public getTabIndex(value: string): number {
    return tabNames.findIndex((tab) => tab === value);
  }

  public renderTabComponent(updateKey: boolean = false) {
    switch (tabNames[this.activeTab]) {
      case '#dashboard':
        this.showDashboardTab = true;
        if (updateKey) {
          this.dashboardTabKey += 1;
        }
        break;
      case '#criteria':
        this.showCriteriaTab = true;
        if (updateKey) {
          this.criteriaTabKey += 1;
        }
        break;
      case '#participants':
        this.showParticipantsTab = true;
        if (updateKey) {
          this.participantsTabKey += 1;
        }
        break;
      case '#grip':
        this.showGripTab = true;
        if (updateKey) {
          this.gripTabKey += 1;
        }
        break;
      case '#report':
        this.showReportTab = true;
        if (updateKey) {
          this.reportTabKey += 1;
        }
        break;
      case '#settings':
        this.showSettingTab = true;
        if (updateKey) {
          this.settingTabKey += 1;
        }
        break;
      case '#activityLog':
        this.showActivityLogTab = true;
        if (updateKey) {
          this.activityLogTabKey += 1;
        }
        break;
      default:
        if (this.currentRouteHash !== tabNames[0]) {
          this.activeTab = 0;
          this.$router.replace({ hash: tabNames[this.activeTab] });
        }
    }
  }

  public transformSettings(settings: any[] = []) {
    if (settings.length === 0) {
      return;
    }
    const currentActiveSetting = settings[0];
    this.projectExpiry = new Date(currentActiveSetting.expiryDate);
    this.recurrenceSetting = {
      recurrenceType: currentActiveSetting.recurrenceType,
      scheduledRecurrenceStartDate:
        currentActiveSetting.scheduledRecurrenceStartDate,
      scheduledRecurrenceEndDate:
        currentActiveSetting.scheduledRecurrenceEndDate,
      multipleRecurrenceType: currentActiveSetting.multipleRecurrenceType,
      enableGrip: currentActiveSetting.enableGrip,
    };
    this.initialValue.currentActiveSetting = currentActiveSetting;
    this.initialValue.projectExpiry = this.projectExpiry;
    this.initialValue.recurrenceSetting = this.recurrenceSetting;
  }

  public mounted(): void {
    this.getProjectDetail(this.projectId);
    this.changeActiveTabBasedOnUrl();
    this.showProjectDetail = true;
  }

  public changeActiveTabBasedOnUrl() {
    let hashId: string = this.currentRouteHash;
    if (this.currentRouteHash.includes('settings')) {
      hashId = this.currentRouteHash.split('-')[0];
      const currentSettingTab: string = this.$route.hash.split('-')[1];
      const gripTabIndex = this.isProjectOwner ? 2 : 1;
      this.activeSettingsTab = currentSettingTab ? gripTabIndex : 0;
    }
    const tabIndex = Object.values(tabNames).findIndex(
      (value) => value === hashId,
    );

    this.activeTab = tabIndex !== -1 ? tabIndex : 0;
    this.renderTabComponent();
  }

  public formatDate(s: string) {
    return dayjs(s).format(DATE_FORMAT);
  }

  public formatDateTime(s: string) {
    return dayjs(s).format(DATE_TIME_FORMAT);
  }

  get currentRouteHash() {
    return this.$route.hash;
  }

  get projectId(): number {
    return +this.$route.params.projectId;
  }

  get projectStatus() {
    return this.projectDetail ? this.projectDetail.status : '';
  }

  get editable() {
    return !Boolean(this.projectStatus === 'Archived');
  }

  get message() {
    return 'Archived projects period cannot be edited';
  }

  get isProjectOwner(): boolean {
    if (this.projectDetail) {
      return this.projectDetail.user.oicPayload.email === this.userEmail
        ? true
        : false;
    }
    return false;
  }

  get isProjectCoordinator(): boolean {
    if (this.projectDetail && this.projectDetail.userRoleInProject) {
      const coordinator =
        this.projectDetail.userRoleInProject ===
        UserRoleInProject.isProjectCoOrdinator;

      return coordinator ? true : false;
    }
    return false;
  }

  get isUserProjectOwnerOrCoordinator(): boolean {
    if (this.userEmail && this.projectDetail) {
      return this.isProjectOwner || this.isProjectCoordinator;
    }
    return false;
  }

  public getCurrentProjectSetting(project: ProjectDetailPayload) {
    return _last(project.settings) as ProjectSettingResponsePayload;
  }

  public getCurrentProjectExpiryDate(project: ProjectDetailPayload) {
    const currentSetting: ProjectSettingResponsePayload = this.getCurrentProjectSetting(
      project,
    );
    return this.formatDate(currentSetting.expiryDate);
  }

  public convertDate(value: any) {
    const myDate = new Date(Date.parse(value));
    const realDate =
      myDate.getFullYear() +
      '-' +
      ('0' + (myDate.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + myDate.getDate()).slice(-2);
    return realDate;
  }

  public handleRecurrenceSettingUpdate(newValue: RecurrenceSettingPayload) {
    if (newValue.scheduledRecurrenceStartDate) {
      newValue.scheduledRecurrenceStartDate = this.convertDate(
        newValue.scheduledRecurrenceStartDate,
      );
    }
    if (newValue.scheduledRecurrenceEndDate) {
      newValue.scheduledRecurrenceEndDate = this.convertDate(
        newValue.scheduledRecurrenceEndDate,
      );
    }
    this.recurrenceSetting = newValue;
    if (!_isEqual(this.initialValue.recurrenceSetting, newValue)) {
      this.isSettingSubmitButtonDisabled = false;
    } else {
      this.isSettingSubmitButtonDisabled = true;
    }
  }

  @Watch('projectExpiry')
  @isTruthy
  @isDifferentDeep
  public watchProjectExpiry(newValue: any) {
    if (!_isEqual(this.initialValue.projectExpiry, newValue)) {
      this.isSettingSubmitButtonDisabled = false;
    }
  }

  public changeActiveTab(newValue: string) {
    this.getProjectDetail(this.projectId);
    this.$router.replace({ hash: newValue });
    const tabIndex: number = Object.values(tabNames).findIndex(
      (value) => value === this.currentRouteHash,
    );
    this.activeTab = tabIndex !== -1 ? tabIndex : 0;
    this.renderTabComponent(true);
  }
}
