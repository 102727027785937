







































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import BaseLoading from '../../../components/base/BaseLoading.vue';
import { Debounce } from '../../../jbi-shared/util/debounce.vue-decorator';
import { ApiState, Pagination } from '../../../store/types/general.types';
import {
  CreateGripStrategyBase,
  GetStrategiesPayload,
  GripStrategy,
} from '../../../store/types/grip.types';

@Component({
  components: { BaseLoading },
})
export default class ExistingGripStrategyList extends Vue {
  @Prop() public projectId!: number;
  public isLoading: boolean = true;
  public searchTitle: string = '';
  public perPage: number = 100;
  public page: number = 1;
  public strategyList: GripStrategy[] = [];
  public strategyInfo: string =
    'Determine and implement strategies for change.';

  @Action('projects/getGripStrategies')
  public getGripStrategies!: (payload: {
    projectId: number;
    params: GetStrategiesPayload;
  }) => void;

  @State((state) => state.projects.gripStrategies)
  public gripStrategies!: Pagination<GripStrategy>;

  @State((state) => state.projects.apiState.getGripStrategies)
  public getGripStrategiesApiState!: ApiState;

  public getUpperCase(value: string): string {
    return value.toUpperCase();
  }

  public createAnalysisStrategyItem(
    strategy: GripStrategy,
  ): CreateGripStrategyBase {
    return {
      title: strategy.title,
      description: strategy.description,
      resource: strategy.resource,
      method: strategy.method,
      links: strategy.links,
      attachments: strategy.attachments,
      gripDomainCategoryId: strategy.gripDomainCategory
        ? strategy.gripDomainCategory.id
        : null,
    };
  }

  public onDragStart(event: any, strategy: GripStrategy): void {
    const newItem = this.createAnalysisStrategyItem(strategy);
    const jsonData = JSON.stringify({ ...newItem, itemType: 'strategy' });
    event.dataTransfer.setData('application/json', jsonData);
  }

  public getStrategies(): void {
    this.isLoading = true;
    this.getGripStrategies({
      projectId: this.projectId,
      params: {
        title: this.searchTitle,
        limit: this.perPage,
        page: this.page,
      },
    });
  }

  public collapseSideNav(): void {
    this.$emit('collapseSideNav');
  }

  public mounted(): void {
    this.getStrategies();
  }

  @Watch('searchTitle')
  @Debounce(500)
  public onTitleSearch(): void {
    this.getStrategies();
  }

  @Watch('getGripStrategiesApiState.loading')
  public watchGetGripStrategiesLoading(): void {
    this.isLoading = true;
  }

  @Watch('getGripStrategiesApiState.success')
  public watchGetGripStrategiesSuccess(): void {
    this.isLoading = false;
    this.strategyList =
      this.gripStrategies && this.gripStrategies.items
        ? this.gripStrategies.items
        : [];
  }
}
