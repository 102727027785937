




















































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import DefaultLayout from '../../layouts/DefaultLayout.vue';
import HeroBar from '@/components/base/HeroBar.vue';
import { Action, State } from 'vuex-class';
import {
  ProjectListPagePayload,
  GetProjectListPayload,
} from '@/store/modules/projects/types/projects.types';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import BasePagination from '@/components/base/BasePagination.vue';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '@/utils/date.util';
import { capitalizeFirstLetter } from '@/jbi-shared/util/user.utils';
import { getProjectUrlByRole } from '@/utils/project.util';
@Component({
  components: {
    DefaultLayout,
    HeroBar,
    BasePagination,
  },
})
export default class ProjectActiveListPage extends Vue {
  @Prop() public participantProjectOnly!: boolean;
  @Prop() public userEmail!: string;
  @Prop() public isLoading!: boolean;
  public activeSearchQuery: string = '';
  public totalProjectActiveList: number = 0;
  public totalCount: number = 0;
  public perPage = 5;
  public page = 1;
  public activeProjectDetails: any = [];
  public isEmptyActive: boolean = false;
  public dirtyLoading: boolean = false;

  @Action('projects/getActiveProjects')
  public getActiveProjects!: (params: GetProjectListPayload) => void;

  @State((state) => state.projects.activeListProject)
  public activeListProject!: ProjectListPagePayload[];

  get totalNumberOfPage(): number {
    return Math.ceil(this.totalCount / this.perPage);
  }

  get startItemIndex(): number {
    return this.page * this.perPage - this.perPage + 1;
  }

  get endItemIndex(): number {
    return Math.min(this.totalCount, this.page * this.perPage);
  }

  get isFirstPage(): boolean {
    return this.page === 1;
  }

  get isLastPage(): boolean {
    return this.page === this.totalNumberOfPage;
  }

  get getCurrentPage(): number {
    this.page = +this.$route.query.page || this.page;
    return this.page;
  }

  set getCurrentPage(newValue: number) {
    this.page = newValue;
    this.$router
      .push({
        path: this.$route.path,
        query: {
          search:
            (this.$route.query.search as string) || this.activeSearchQuery,
          limit: (this.$route.query.limit as string) || this.perPage.toString(),
          page: this.page.toString(),
        },
      })
      .catch(() => {
        return;
      });
    this.getActiveProjects({
      search: this.activeSearchQuery,
      limit: this.perPage,
      page: this.page,
      participantProjectOnly: this.participantProjectOnly,
    });
  }

  get getCurrentPerPage(): number {
    this.perPage = +this.$route.query.limit || this.perPage;
    return this.perPage;
  }

  set getCurrentPerPage(newValue: number) {
    this.perPage = newValue;
    this.$router
      .push({
        path: this.$route.path,
        query: {
          search:
            (this.$route.query.search as string) || this.activeSearchQuery,
          limit: this.perPage.toString(),
          page: (this.$route.query.page as string) || this.page.toString(),
        },
      })
      .catch(() => {
        return;
      });
    this.getActiveProjects({
      search: this.activeSearchQuery,
      limit: this.perPage,
      page: this.page,
      participantProjectOnly: this.participantProjectOnly,
    });
  }

  public mounted() {
    this.dirtyLoading = this.isLoading;
    this.activeSearchQuery = (this.$route.query.search as string) || '';
    if (this.activeSearchQuery) {
      this.$router
        .push({
          path: this.$route.path,
          query: {
            tab: 'Active',
            search:
              (this.$route.query.search as string) || this.activeSearchQuery,
            limit:
              (this.$route.query.limit as string) || this.perPage.toString(),
            page: (this.$route.query.page as string) || this.page.toString(),
          },
        })
        .catch(() => {
          return;
        });
    }
    this.getActiveProjects({
      search: (this.$route.query.search as string) || this.activeSearchQuery,
      limit: Number(this.$route.query.limit) || this.perPage,
      page: Number(this.$route.query.page) || this.page,
      participantProjectOnly: this.participantProjectOnly,
    });
  }

  public formatDate(s: string) {
    return dayjs(s).format(DATE_FORMAT);
  }

  public getAdministratorName(item: ProjectListPagePayload) {
    const givenName = capitalizeFirstLetter(item.user.oicPayload.given_name);
    const familyName = capitalizeFirstLetter(item.user.oicPayload.family_name);
    item.administratorName = `${givenName} ${familyName} (${item.user.email})`;
    item.given_name = givenName;
    item.family_name = familyName;
    return item;
  }

  public getProjectUrl(item: any) {
    return getProjectUrlByRole(item.id, item.userRoleInProject);
  }

  @isTruthy
  @Watch('activeListProject')
  public getWatcherActiveListProject(newValue: any) {
    this.dirtyLoading = false;
    this.$emit('updateLoadingBool', this.dirtyLoading);
    this.activeProjectDetails = [];
    if (newValue.items.length > 0) {
      newValue.items.map((dirtyProjectItem: ProjectListPagePayload) => {
        const newProjectItem = this.getAdministratorName(dirtyProjectItem);
        this.activeProjectDetails.push(newProjectItem);
      });
      this.isEmptyActive = false;
    } else {
      this.isEmptyActive = true;
    }
    this.totalCount = newValue.totalItems;
  }

  @Watch('activeSearchQuery')
  public watchActiveSearchQuery(newValue: string) {
    this.page = 1;
    this.$router
      .push({
        path: this.$route.path,
        query: {
          tab: 'Active',
          search: newValue,
          limit: this.perPage.toString(),
          page: this.page.toString(),
        },
      })
      .catch(() => {
        return;
      });

    this.getActiveProjects({
      search: newValue,
      limit: this.perPage,
      page: this.page,
      participantProjectOnly: this.participantProjectOnly,
    });
  }

  @Watch('$route.query.tab')
  public routeParam() {
    if (this.$route.query.tab === 'Active') {
      this.$router
        .push({
          path: this.$route.path,
          query: {
            tab: 'Active',
            search: '',
            limit: this.perPage.toString(),
            page: this.page.toString(),
          },
        })
        .catch(() => {
          return;
        });
      const params = {
        search: this.activeSearchQuery,
        limit: this.perPage,
        page: this.page,
        participantProjectOnly: this.participantProjectOnly,
      };
      return this.getActiveProjects(params);
    }
  }
}
