import { NotificationState } from './types/notification.types';
import { defaultApiState } from '@/store/types/general.types';

export const notificationState: NotificationState = {
  apiState: {
    getUserNotification: defaultApiState,
    updateViewedNotification: defaultApiState,
    getNotificationDropdown: defaultApiState,
    updateViewedSingleNotification: defaultApiState,
  },
  userNotification: undefined,
  userViewedNotifications: undefined,
  notificationDropdown: undefined,
  userViewedSingleNotification: undefined,
};
