
















































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import DefaultLayout from '../../layouts/DefaultLayout.vue';
import HeroBar from '@/components/base/HeroBar.vue';
import { Action, State } from 'vuex-class';
import {
  CplusCriteria,
  CplusCriteriaResults,
  CreateProjectRequestPayload,
  CreateProjectResponsePayload,
  GetCplusCriteriaRequestPayload,
  RecurrenceSettingPayload,
  Site,
  ProjectDetailPayload,
  DataCollectionPeriodPayload,
  UpdateProjectCriteriasRequestPayload,
  ProjectSite,
  ProjectParticipantDataTrfObj,
  ParticipantSite,
  ProjectParticipantDTO,
  UpdateCriteriaDTO,
} from '@/store/modules/projects/types/projects.types';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import AuditCriteriaList from '@/views/Project/components/AuditCriteriaList.vue';
import ProjectSettings from '@/views/Project/components/ProjectSettings.vue';
import ProjectCreationExitModal from '@/views/Project/components/ProjectCreationExitModal.vue';
import { RootState } from '@/store/store';
import { ToastProgrammatic as Toast } from 'buefy';
import SiteSettings from '@/views/Project/components/SiteSettings.vue';
import ParticipantSettings from '@/views/Project/components/ParticipantSettings.vue';
import RecurrenceSettings from '@/views/Project/components/RecurrenceSettings.vue';
import InviteParticipants from './components/steps/InviteParticipants.vue';
import SelectSite from './components/steps/SelectSite.vue';
import SelectCriteria from './components/steps/SelectCriteria.vue';
import BaseDialog from '../../components/base/BaseDialog.vue';
import { Route } from 'vue-router';
import { AxiosError } from 'axios';
import { get as _get } from 'lodash';
import moment from 'moment';
import {
  JBICriteriaDTO,
  UpdatedJBICriteriaAcknowledgement,
} from '../../store/modules/projects/types/projects.types';
import { cloneData } from '@/utils/general.util';
import { orderByUtil } from '@/jbi-shared/util/sort.util';
import { SortOrder } from '@/jbi-shared/types/search.types';
import JBICriteriaSelection from '../Audit/components/JBICriteriaSelection.vue';

Component.registerHooks(['beforeRouteLeave']);

@Component({
  components: {
    RecurrenceSettings,
    ParticipantSettings,
    SiteSettings,
    DefaultLayout,
    HeroBar,
    AuditCriteriaList,
    ProjectSettings,
    JBICriteriaSelection,
    ProjectCreationExitModal,
    InviteParticipants,
    SelectSite,
    SelectCriteria,
  },
})
export default class ProjectCreatePage extends Vue {
  public projectTitle: string = '';
  public selectedCriteriaArray: CplusCriteria[] = [];
  public updatedJbiCriteriaList: JBICriteriaDTO[] = [];
  public showUpdatedJbiCriteriaNotification: boolean = false;
  public projectSites: Site[] = [];
  public selectedParticipantArray: ProjectParticipantDataTrfObj[] = [];
  public projectExpiry: any = null;
  public defaultEndDate: any = moment(new Date()).add(1, 'M');
  public dataCollectionPeriodDateTotal: DataCollectionPeriodPayload = {
    auditNumber: 1,
    auditName: 'Baseline',
    startDate: new Date(),
    endDate: this.defaultEndDate.subtract(1, 'd').toDate(),
  };
  public recurrenceSetting: RecurrenceSettingPayload = {
    recurrenceType: 'oneOff',
    scheduledRecurrenceStartDate: new Date(),
    auditSamplingType: 'targetSampleSize',
    enableGrip: true,
    multipleDataCollectionPeriod: [
      {
        ...this.dataCollectionPeriodDateTotal,
      },
    ],
  };
  public draftRecurrenceSetting: RecurrenceSettingPayload | null = null;
  public stepsIndex: number = 0;
  public perPage = 50;
  public page = 1;
  public isCreated = false;
  public showRecurrenceSetting: boolean = false;
  public deletedCriteriaList: number[] = [];
  public saveAsDraftLabel: string = '';
  public nextLabel: string = '';
  public isPageLoading: boolean = false;
  public projectLoadingDialog: any;

  @Action('projects/getDraftProjectDetail')
  public getDraftProjectDetail!: (draftProjectId: number) => void;

  @State((state) => state.projects.projectDetail)
  public projectDetail!: ProjectDetailPayload;

  @Action('projects/getUpdatedJBICriteriaWithAcknowledgement')
  public getUpdatedJBICriteriaWithAcknowledgement!: (projectId: number) => void;

  @State((state) => state.projects.updatedJBICriteriaListWithAcknowledgment)
  public updatedJBICriteriaListWithAcknowledgment!: UpdatedJBICriteriaAcknowledgement;

  @Action('projects/createProject')
  public createProject!: (payload: CreateProjectRequestPayload) => void;

  @Action('projects/createProjectById')
  public createProjectById!: (payload: CreateProjectRequestPayload) => void;

  @Action('projects/saveDraft')
  public saveDraft!: (payload: CreateProjectRequestPayload) => void;

  @Action('projects/updateDraft')
  public updateDraft!: (payload: CreateProjectRequestPayload) => void;

  @Action('projects/updateProjectCriterias')
  public updateProjectCriterias!: (
    payload: UpdateProjectCriteriasRequestPayload,
  ) => void;

  @State((state: RootState) => state.projects.createdProject)
  public createdProject!: CreateProjectResponsePayload;

  @State((state: RootState) => state.projects.apiState.createProject)
  public createProjectState!: boolean;

  @Watch('createProjectState.error')
  @isDifferent
  @isTruthy
  public onCreateProjectError(error: AxiosError) {
    this.isPageLoading = false;
    this.projectLoadingDialog.close();
    Toast.open({
      message: _get(error, 'response.data.message', 'Error'),
      position: 'is-top',
      type: 'is-dark',
    });
  }

  @Watch('createProjectState.success')
  @isDifferent
  @isTruthy
  public onCreateProjectSuccess() {
    // to bypass routeLeave
    this.isCreated = true;
    this.isPageLoading = false;
    this.projectLoadingDialog.close();
    const checkDraft = this.createdProject.project.status === 'Draft';
    if (checkDraft) {
      Toast.open({
        message: `Your project has been saved as a draft`,
        position: 'is-top',
        type: 'is-dark',
      });
      setTimeout(() => {
        this.$router.push({
          name: 'project-me',
        });
      }, 200);
    } else {
      const newProjectId = this.createdProject.project.id.toString();
      if (this.selectedParticipantArray.length > 0) {
        this.$buefy.modal.open({
          parent: this,
          component: BaseDialog,
          hasModalCard: true,
          trapFocus: true,
          props: {
            title: 'Project Successfully Created',
            content: `Invitation emails have been sent to all participants.`,
            confirmButton: false,
            cancelButtonText: 'OK',
          },
        });
      }
      setTimeout(() => {
        this.$router.push({
          name: 'project-details',
          params: {
            projectId: newProjectId,
          },
        });
      }, 200);
    }
  }

  @Watch('projectDetail')
  @isTruthy
  public watchProjectDetails(value: ProjectDetailPayload) {
    if (this.draftProjectId) {
      const {
        title,
        criterias,
        projectSites,
        participants,
        settings,
      }: ProjectDetailPayload = cloneData(this.projectDetail);

      this.projectTitle = title;
      this.transformCriterias(criterias);
      this.transformSites(projectSites);
      this.transformParticipants(participants);
      this.transformSettings(settings);
      this.stepsIndex = this.stepCalculator();
    }
  }

  public mounted(): void {
    this.showUpdatedJbiCriteriaNotification = false;
    if (this.draftProjectId) {
      this.getDraftProjectDetail(this.draftProjectId);
      this.getUpdatedJBICriteriaWithAcknowledgement(this.draftProjectId);
    } else {
      this.showRecurrenceSetting = true;
    }
  }

  public stepCalculator() {
    if (
      this.projectTitle === '' ||
      this.selectedCriteriaArray.length === 0 ||
      this.updatedJbiCriteriaList.length > 0
    ) {
      return 0;
    }
    if (this.projectSites.filter(Boolean).length === 0) {
      return 1;
    }
    if (this.selectedParticipantArray.length === 0) {
      return 2;
    }
    return 3;
  }

  public convertDate(value: any) {
    const myDate = new Date(Date.parse(value));
    const realDate =
      myDate.getFullYear() +
      '-' +
      ('0' + (myDate.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + myDate.getDate()).slice(-2);
    return realDate;
  }

  public handleRecurrenceSettingUpdate(newValue: RecurrenceSettingPayload) {
    if (newValue.scheduledRecurrenceStartDate) {
      newValue.scheduledRecurrenceStartDate =
        newValue.scheduledRecurrenceStartDate;
    }
    if (newValue.scheduledRecurrenceEndDate) {
      newValue.scheduledRecurrenceEndDate = newValue.scheduledRecurrenceEndDate;
    }
    this.recurrenceSetting = newValue;
  }

  public openJBICriteriaSelectionTemplate() {
    this.$buefy.modal.open({
      parent: this,
      component: JBICriteriaSelection,
      fullScreen: true,
      hasModalCard: false,
      trapFocus: true,
      animation: 'unset',
      props: {
        projectName: this.projectTitle,
        isCreateProject: true,
        selectedCriteriaArray: this.selectedCriteriaArray,
      },
      events: {
        updateSelectedCriteriaArray: (newValue: UpdateCriteriaDTO) => {
          this.handleSelectedCriteriaArray(newValue.emitCriteriaArray);
        },
      },
    });
  }

  public handleSelectedCriteriaArray(newValue: any) {
    const updatedValue: any[] = [];
    newValue.forEach((value: any) => {
      const dirtyValue = cloneData(value);
      updatedValue.push(dirtyValue);
    });
    this.selectedCriteriaArray = cloneData(updatedValue);
  }

  public isAuditIntervalInValid(): boolean {
    return (
      this.recurrenceSetting.interval !== undefined &&
      this.recurrenceSetting.recurrenceType === 'multiple' &&
      this.recurrenceSetting.interval < 1
    );
  }

  public isAuditNumberInValid(): boolean {
    return (
      this.recurrenceSetting.auditNumber !== undefined &&
      this.recurrenceSetting.recurrenceType === 'multiple' &&
      (this.recurrenceSetting.auditNumber < 1 ||
        this.recurrenceSetting.auditNumber > 10)
    );
  }

  public isRecurrenceSettingInValid() {
    return this.isAuditIntervalInValid() || this.isAuditNumberInValid();
  }

  public checkInvalidValueForProjectCreation(): boolean {
    return (
      this.projectTitle === '' ||
      this.selectedCriteriaArray.length === 0 ||
      this.projectSites.filter(Boolean).length === 0 ||
      this.isRecurrenceSettingInValid()
    );
  }

  public checkInvalidProjectSiteSelection(): boolean {
    if (
      this.stepsIndex === 1 &&
      this.projectSites.some((site) => typeof site !== 'object')
    ) {
      this.nextLabel = 'Site must be selected';
      return true;
    } else {
      this.nextLabel = '';
      return false;
    }
  }

  get validateForm() {
    return (
      this.projectTitle === '' ||
      (this.stepsIndex === 3 && this.checkInvalidValueForProjectCreation()) ||
      this.checkInvalidProjectSiteSelection()
    );
  }

  get validateSaveAsDraft() {
    let error = false;
    if (this.projectTitle === '') {
      this.saveAsDraftLabel = 'Project title is required';
      error = true;
    }
    if (
      this.stepsIndex === 3 &&
      this.recurrenceSetting.recurrenceType === 'multiple' &&
      this.isRecurrenceSettingInValid()
    ) {
      this.saveAsDraftLabel = 'Invalid data collection period';
      error = true;
    }

    return error;
  }

  public handleSubmit() {
    this.projectLoadingDialog = this.getProjectLoadingDialog();
    const postRequestPayload: CreateProjectRequestPayload = {
      projectTitle: this.projectTitle.trim(),
      auditCriterias: this.selectedCriteriaArray,
      // by default, selectedSite have prepand a empty string '' in a array
      sites: this.projectSites.filter(Boolean),
      participants: (this.selectedParticipantArray.map(
        (participant: ProjectParticipantDataTrfObj) => ({
          ...participant,
          participantRole: participant.participantRole,
          participantSites: participant.participantSites.map(
            (associatedSite: ParticipantSite) => {
              return associatedSite.site.id;
            },
          ),
        }),
      ) as unknown) as ProjectParticipantDataTrfObj[],
      recurrenceSetting: this.recurrenceSetting,
    };

    if (this.draftProjectId) {
      postRequestPayload.projectId = this.draftProjectId;
      this.createProjectById(postRequestPayload);
    } else {
      this.createProject(postRequestPayload);
    }
  }

  public getProjectLoadingDialog() {
    this.isPageLoading = true;
    return this.$buefy.modal.open({
      parent: this,
      component: BaseDialog,
      hasModalCard: true,
      trapFocus: true,
      props: {
        title: 'Project creation is in progress',
        content: 'Please wait for the project to be created.',
        confirmButton: false,
        cancelButton: false,
        showCloseButton: false,
      },
    });
  }

  public handleSaveDraft() {
    this.projectLoadingDialog = this.getProjectLoadingDialog();
    const postDraftRequestPayload: CreateProjectRequestPayload = {
      projectTitle: this.projectTitle.trim(),
      auditCriterias: this.selectedCriteriaArray,
      sites: this.projectSites.filter(Boolean),
      participants: (this.selectedParticipantArray.map(
        (participant: ProjectParticipantDataTrfObj) => ({
          ...participant,
          participantRole: participant.participantRole,
          participantSites: participant.participantSites.map(
            (associatedSite: ParticipantSite) => {
              return associatedSite.site.id;
            },
          ),
        }),
      ) as unknown) as ProjectParticipantDataTrfObj[],
      recurrenceSetting: this.recurrenceSetting,
    };
    if (this.draftProjectId) {
      this.updateDraft({
        ...postDraftRequestPayload,
        projectId: this.draftProjectId,
      });
    } else {
      this.saveDraft(postDraftRequestPayload);
    }
  }

  get draftProjectId(): number | null {
    return +this.$route.params.projectId;
  }

  public transformCriterias(criterias: any[]) {
    this.selectedCriteriaArray = [];
    criterias.forEach((criteria) => {
      const transformedCriteria: CplusCriteria = {
        id: criteria.id,
        title: criteria.title,
        type: criteria.criteriaType,
        criteriaOptions: JSON.parse(criteria.criteriaOptions).filter(
          (option: any) => {
            return option !== 'N/A';
          },
        ),
        booleanOptions: JSON.parse(criteria.criteriaOptions),
        range: JSON.parse(criteria.criteriaOptions),
        checkboxesOptions: JSON.parse(criteria.criteriaOptions),
        document: {
          id: criteria.documentId,
          title: criteria.documentName,
          description: criteria.description,
        },
        guide: criteria.guide,
        tags: criteria.tags,
        bestPracticeRecommendations: [],
        criteriaDetails: criteria,
        order: criteria.order,
        sourceLink: criteria.sourceLink,
        isChanged: criteria.isChanged,
      };

      // Adding updated JBI Criteria Details for Draft Project Criteria
      if (
        this.draftProjectId &&
        this.updatedJbiCriteriaList &&
        this.updatedJbiCriteriaList.length > 0
      ) {
        this.updatedJbiCriteriaList.forEach(
          (updatedJbiCriteria: JBICriteriaDTO) => {
            if (
              updatedJbiCriteria.connectPlusCriteriaId ===
              +criteria.connectPlusId
            ) {
              transformedCriteria.updatedJbiCriteria = updatedJbiCriteria;
            }
          },
        );
      }
      this.selectedCriteriaArray.push(transformedCriteria);
    });
  }

  public getTags(tagInfo: any) {
    const tags: string[] = [];
    Object.keys(tagInfo).forEach((key: string) => {
      const tagList = tagInfo[key];
      tagList.forEach((tag: string) => {
        tags.push(tag.charAt(0).toUpperCase() + tag.slice(1));
      });
    });
    return tags;
  }

  public transformSites(sites: ProjectSite[]) {
    this.projectSites = [];
    for (const projectSite of sites) {
      // skip deleted project sites
      if (projectSite.isDeleted) {
        continue;
      }

      const transformedSite: Site = {
        id: projectSite.site.id,
        name: projectSite.site.name,
        address: projectSite.site.address,
        description: projectSite.site.description,
        isIdentifierRequired: projectSite.isIdentifierRequired,
      };
      this.projectSites.push(transformedSite);
    }
    this.projectSites = this.projectSites.sort(
      orderByUtil(['id'], [SortOrder.ASC]),
    );
  }

  public transformParticipants(participants: ProjectParticipantDTO[]) {
    if (participants.length > 0) {
      participants.forEach((participant) => {
        participant.participantRoles.forEach((participantRole) => {
          const transformedParticipant: ProjectParticipantDataTrfObj = {
            ...participant,
            participantRole: participantRole.role,
            participantSites: participantRole.participantSites,
            participantRoleId: participantRole.id,
          };
          this.selectedParticipantArray.push(transformedParticipant);
        });
      });
    }
  }

  public transformSettings(settings: any) {
    const currentActiveSetting = settings[0];
    this.draftRecurrenceSetting = {
      recurrenceType: currentActiveSetting.recurrenceType,
      scheduledRecurrenceStartDate:
        currentActiveSetting.scheduledRecurrenceStartDate,
      scheduledRecurrenceEndDate:
        currentActiveSetting.scheduledRecurrenceEndDate,
      multipleRecurrenceType: currentActiveSetting.multipleRecurrenceType,
      auditSamplingType: 'targetSampleSize',
      enableGrip: true,
    };
    if (currentActiveSetting.interval !== undefined) {
      this.draftRecurrenceSetting.interval = currentActiveSetting.interval;
    }
    if (currentActiveSetting.auditSamplingType) {
      this.draftRecurrenceSetting.auditSamplingType =
        currentActiveSetting.auditSamplingType;
    }
    if (currentActiveSetting.auditNumber !== undefined) {
      this.draftRecurrenceSetting.auditNumber =
        currentActiveSetting.auditNumber;
    }
    if (currentActiveSetting.multipleAuditDates) {
      this.draftRecurrenceSetting.multipleDataCollectionPeriod =
        currentActiveSetting.multipleAuditDates;
    }
    this.recurrenceSetting = cloneData(this.draftRecurrenceSetting);
    this.showRecurrenceSetting = true;
  }

  public contentHasChanged() {
    if (
      this.projectTitle.length > 0 ||
      this.selectedCriteriaArray.length > 0 ||
      this.projectSites.filter(Boolean).length > 0 ||
      this.selectedParticipantArray.length > 0
    ) {
      return true;
    }
  }

  public handleDeletedCriteria(criteria: any) {
    if (criteria.criteriaDetails) {
      this.deletedCriteriaList.push(criteria.criteriaDetails.id);
      this.updatedJbiCriteriaList = this.updatedJBICriteriaForExistingCriteria.filter(
        (JBIcriteria: JBICriteriaDTO) =>
          JBIcriteria.connectPlusCriteriaId !== criteria.criteriaDetails.id,
      );
      this.showUpdatedJbiCriteriaNotification =
        this.updatedJbiCriteriaList.length > 0 ? true : false;
    }
  }

  public updateProjectCriteriaHandler() {
    if (this.draftProjectId && this.deletedCriteriaList.length > 0) {
      // Only update deleted criteria on draft project
      // Add/edit criteria on Save as Draft or on Create Project
      this.updateProjectCriterias({
        projectId: Number(this.draftProjectId),
        criterias: this.selectedCriteriaArray,
        deletedCriteriaIds: this.deletedCriteriaList,
      });
    }
  }

  get updatedJBICriteriaForExistingCriteria(): JBICriteriaDTO[] {
    return [
      ...this.updatedJBICriteriaListWithAcknowledgment.updatedJBICriteria,
      ...this.updatedJBICriteriaListWithAcknowledgment.deletedJBICriteria,
    ];
  }

  @Watch('updatedJBICriteriaListWithAcknowledgment')
  @isTruthy
  public watchUpdatedJbiCriteriaResponse(): void {
    if (this.draftProjectId && this.projectDetail) {
      this.updatedJbiCriteriaList = this.updatedJBICriteriaForExistingCriteria;
      this.transformCriterias(this.projectDetail.criterias);
      this.stepsIndex = this.stepCalculator();
      this.showUpdatedJbiCriteriaNotification =
        this.updatedJbiCriteriaList.length > 0 ? true : false;
    }
  }

  private confirmStay() {
    return window.confirm(
      'Do you really want to leave? you have unsaved changes!',
    );
  }

  private beforeRouteLeave(to: Route, from: any, next: (_?: boolean) => void) {
    // if new project has changed
    if (!this.draftProjectId && this.contentHasChanged()) {
      // skip route leave confirmation if project is saved or save as draft
      if (this.isCreated) {
        return next();
      }
      return next(this.confirmStay());
    }
    next();
  }

  private onBeforeUnload(e: any) {
    if (!this.draftProjectId && this.contentHasChanged()) {
      return '';
    }
  }

  private created() {
    window.onbeforeunload = this.onBeforeUnload;
  }

  private beforeDestroy() {
    window.onbeforeunload = null;
  }
}
