




























































import { Component, Prop, Watch } from 'vue-property-decorator';
import { ToastProgrammatic as Toast } from 'buefy';
import { Action, State } from 'vuex-class';
import {
  GripEnabler,
  GripEnablerDTO,
  UpdateGripEnablerBase,
  UpdateGripEnablerPayload,
} from '../../../store/types/grip.types';
import { ApiState } from '../../../store/types/general.types';
import EnablerModal from './EnablerModal.vue';
import BasePagination from '../../../components/base/BasePagination.vue';
import DeleteModalBox from '../../../components/DeleteModalBox.vue';
import { isTruthy } from '../../../jbi-shared/util/watcher.vue-decorator';
import { PaginationMixin } from '../../../components/base/pagination.mixin';
import { mixins } from 'vue-class-component';
import { getUpperCase } from '../../../utils/general.util';

@Component({
  components: { BasePagination, EnablerModal, DeleteModalBox },
})
export default class EnablerList extends mixins(PaginationMixin) {
  @Prop() public items!: GripEnabler[];
  @Prop(Number) public totalNumberOfPage!: number;
  @Prop(Number) public totalCount!: number;
  @Prop(Boolean) public isFirstPage!: boolean;
  @Prop(Boolean) public isLastPage!: boolean;
  @Prop(Number) public startItemIndex!: number;
  @Prop(Number) public endItemIndex!: number;
  public modalKey: number = Math.floor(Math.random() * 999);
  public isModalActive: boolean = false;
  public gripEnablerDetails: GripEnablerDTO | null = null;

  @Action('projects/updateGripEnabler')
  public updateGripEnabler!: (payload: UpdateGripEnablerPayload) => void;

  @State((state) => state.projects.apiState.updateGripEnabler)
  public updateGripEnablerApiState!: ApiState;

  @Action('projects/deleteGripEnabler')
  public deleteGripEnabler!: (payload: {
    projectId: number;
    enablerId: number;
  }) => void;

  @State((state) => state.projects.apiState.deleteGripEnabler)
  public deleteGripEnablerApiState!: ApiState;

  get projectId(): number {
    return +this.$route.params.projectId;
  }

  public getUpperCase(value: string) {
    return getUpperCase(value);
  }

  public closeEnablerModal(): void {
    this.isModalActive = false;
  }

  public handleEnablerUpdate(gripEnabler: UpdateGripEnablerBase): void {
    this.updateGripEnabler({ ...gripEnabler, projectId: this.projectId });
    this.closeEnablerModal();
  }

  public handleEdit(enabler: GripEnabler): void {
    const { project, status, createdDate, updatedDate, ...details } = enabler;

    this.gripEnablerDetails = details as GripEnablerDTO;
    this.isModalActive = true;
    this.modalKey += 1;
  }

  public handleDelete(enablerId: number) {
    this.$buefy.modal.open({
      parent: this,
      component: DeleteModalBox,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle: 'Delete Enabler?',
        modalContent:
          'Are you sure you want to delete this enabler? This action cannot be undone.',
      },
      events: {
        close: () => {
          this.$emit('close');
        },
        confirm: () => {
          this.deleteGripEnabler({ projectId: this.projectId, enablerId });
        },
      },
    });
  }

  @Watch('updateGripEnablerApiState.success')
  @isTruthy
  public onUpdateEnablerSuccess(): void {
    this.$emit('updateList');
    Toast.open({
      message: `Enabler updated`,
      position: 'is-top',
      type: 'is-dark',
      duration: 3500,
    });
  }

  @Watch('updateGripEnablerApiState.error')
  public onUpdateEnablerError(): void {
    this.$emit('close');
  }

  @Watch('deleteGripEnablerApiState.success')
  @isTruthy
  public onDeleteEnablerSuccess() {
    this.$emit('updateList');
    Toast.open({
      message: `Enabler deleted`,
      position: 'is-top',
      type: 'is-dark',
      duration: 3500,
    });
  }
}
