





































import { Component, Vue, Prop, Watch, Model } from 'vue-property-decorator';
import { State, Getter, Action, Mutation, namespace } from 'vuex-class';
import {
  DataCollectionCriteriaSamplingData,
  DataCollectionSummaryCriteria,
  DataCollectionSummaryRequestPayload,
  DataCollectionSummaryResponsePayload,
} from '../../../store/modules/audits/types/audits.types';
import DataCollectionTable from '../components/DataCollectionTable.vue';
import { get as _get } from 'lodash';

@Component({
  components: {
    DataCollectionTable,
  },
})
export default class DataCollectionList extends Vue {
  @Action('audits/getDataCollectionSummary')
  public getDataCollectionSummary!: (
    payload: DataCollectionSummaryRequestPayload,
  ) => void;

  @State((state) => state.audits.dataCollectionSummary)
  public dataCollectionSummary!: DataCollectionSummaryResponsePayload;

  public dataCollectionArray: any = [];
  public showExpandArrow: boolean = false;
  public showTableContent: boolean = false;

  get _get() {
    return _get;
  }

  get auditId() {
    return this.$route.params.auditId;
  }

  get samplingConfigurations() {
    return this.dataCollectionSummary
      ? this.dataCollectionSummary.samplingConfiguration
      : undefined;
  }

  public getDataCollection(): DataCollectionCriteriaSamplingData | [] {
    if (
      this.dataCollectionSummary &&
      this.dataCollectionSummary.configurationData &&
      Array.isArray(this.dataCollectionSummary.configurationData)
    ) {
      if (this.showExpandArrow) {
        this.showExpandArrow = false;
        this.dataCollectionArray = this.dataCollectionConfigurationData;
      } else {
        this.showExpandArrow = true;
        this.dataCollectionArray = this.dataCollectionConfigurationData.slice(
          0,
          5,
        );
      }
    }
    return [];
  }

  get dataCollectionConfigurationData(): DataCollectionCriteriaSamplingData[] {
    return this.dataCollectionSummary.configurationData
      .filter((dataCollection) => dataCollection.isActive)
      .map((data: DataCollectionSummaryCriteria) => {
        return {
          criteria: data.criteria.title,
          targetSize: data.criteriaSamplingDataConfigurations
            ? Number(data.criteriaSamplingDataConfigurations[0].sampleSize)
            : 0,
          minSampleSize: data.criteriaSamplingDataConfigurations
            ? Number(data.criteriaSamplingDataConfigurations[0].minSampleSize)
            : 0,
          maxSampleSize: data.criteriaSamplingDataConfigurations
            ? Number(data.criteriaSamplingDataConfigurations[0].maxSampleSize)
            : 0,
          isSamplingEnabled: data.criteriaSamplingDataConfigurations
            ? data.criteriaSamplingDataConfigurations[0].isSamplingEnabled
            : false,
          total: data.criteriaSamplingData.total
            ? data.criteriaSamplingData.total
            : 0,
        };
      });
  }

  public async mounted() {
    await this.getDataCollectionSummary({
      auditId: +this.auditId,
    });

    this.getDataCollection();
    this.showTableContent = true;
  }

  public handleNavigate() {
    this.$emit('changeActiveTab', '#dataCollection');
  }
}
