






import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import {Action, State} from 'vuex-class';
import {DocumentByPublication, GetDocumentByPublicationPayload} from '@/store/modules/projects/types/projects.types';
import {isTruthy} from '@/jbi-shared/util/watcher.vue-decorator';

@Component({
  components: {
    DefaultLayout,
  },
})
export default class ProjectCreatePage extends Vue {

    public loadingComponent: any = null;

    @Action('projects/getDocumentByPublication')
    public getDocumentByPublication!: (payload: GetDocumentByPublicationPayload) => void;

    @State((state) => state.projects.documentByPublication)
    public documentByPublication!: DocumentByPublication;

    get documentId(): number {
      return +this.$route.params.documentId;
    }
    get publicationId(): number {
      return +this.$route.params.publicationId;
    }
    public mounted() {
      this.loadingComponent = this.$buefy.loading.open({
        container: null,
      });

      this.getDocumentByPublication({
        documentId: this.documentId,
        publicationId: this.publicationId,
      });
    }

    @Watch('documentByPublication')
    @isTruthy
    public watchDocumentByPublication(newValue: any) {
      if (this.loadingComponent) {
        this.loadingComponent.close();
      }
    }
}
