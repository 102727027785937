var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:_vm.$style.container},[_c('table',{staticClass:"table is-bordered"},[_c('thead',[_c('tr',[_c('th',{attrs:{"rowspan":"2"}},[_vm._v("Answer Choices")]),_c('th',{class:_vm.$style.center,attrs:{"colspan":_vm.getNumberOfPeriods}},[_vm._v(" "+_vm._s(_vm.siteName)+" ")])]),(_vm.periodsInfo)?_c('tr',_vm._l((_vm.getPeriods),function(period,periodIndex){return _c('th',{key:periodIndex},[_vm._v(" "+_vm._s(period.title)),_c('br'),_c('br'),_vm._v(_vm._s(period.date)+" ")])}),0):_c('tr',_vm._l((_vm.getPeriods),function(period,periodIndex){return _c('th',{key:periodIndex},[_vm._v(" "+_vm._s(period.date)+" ")])}),0)]),_c('tbody',[_vm._l((_vm.bodyItems),function(item,index){return _c('tr',{key:("statistics" + index)},[_c('td',[_vm._v(_vm._s(item.optionKey))]),_vm._l((item.periodData),function(periodData,periodDataIndex){return _c('td',{key:periodDataIndex},[_vm._v(" "+_vm._s(periodData)+" ")])})],2)}),(_vm.bodyItems.length === 0)?_c('tr',[_c('td',{class:_vm.$style.noDataPlaceholder,attrs:{"colspan":"2"}},[_vm._v(" No data available ")])]):_vm._e()],2),_c('tfoot',[_c('tr',[_c('th',[_vm._v("Total Data Collected")]),_vm._l((_vm.getTotalData),function(totalData,totalDataIndex){return _c('th',{key:totalDataIndex},[_vm._v(" "+_vm._s(totalData)+" ")])})],2),(_vm.showComplianceRate)?_c('tr',[_c('th',[_vm._v("Compliance Rate")]),_vm._l((_vm.getCompliance),function(compliance,complianceIndex){return _c('th',{key:complianceIndex},[_vm._v(" "+_vm._s(("" + compliance))+" ")])})],2):_vm._e()])])]),(!_vm.auditReport)?_c('div',{class:_vm.$style.samplingContainer},[_vm._v(" Sampling "),_c('div',{class:_vm.$style.samplingTable},[_c('table',{staticClass:"table is-bordered"},[_c('thead',[_c('tr',_vm._l((_vm.getPeriods),function(period,periodIndex){return _c('th',{key:periodIndex,attrs:{"colspan":_vm.source[periodIndex].samplingConfiguration
                  .auditSamplingType === 'consecutive' &&
                _vm.source[periodIndex].samplingConfiguration.samplingMode ===
                  'minAndMax' &&
                _vm.periodsInfo[periodIndex].isAudit
                  ? 3
                  : 2}},[_vm._v(" "+_vm._s(period.title)),_c('br'),_c('br'),_vm._v(_vm._s(period.date)+" ")])}),0),_c('tr',_vm._l((_vm.getSamplingTitles),function(title,titleIndex){return _c('th',{key:titleIndex,class:[title !== 'Site' ? _vm.$style.columnAlignment : '']},[_vm._v(" "+_vm._s(title)+" ")])}),0)]),_c('tbody',_vm._l((_vm.getSamplingAggregate),function(rowData,rowDataIndex){return _c('tr',{key:rowDataIndex},_vm._l((rowData),function(samplingData,samplingDataIndex){return _c('td',{key:samplingDataIndex,class:[
                Number.isInteger(Number(samplingData)) ||
                Number.isInteger(Number(samplingData.replace('/', ''))) ||
                samplingData === 'Disabled' ||
                samplingData === 'Enabled' ||
                samplingData === '-'
                  ? _vm.$style.columnAlignment
                  : '' ]},[_vm._v(" "+_vm._s(samplingData)+" ")])}),0)}),0)])])]):_c('div',{class:_vm.$style.samplingContainer},[_vm._v(" Sampling "),_c('div',{class:_vm.$style.samplingTable},[_c('table',{staticClass:"table is-bordered"},[_c('thead',[_c('tr',_vm._l((_vm.getSamplingTitlesAudit),function(title,titleIndex){return _c('th',{key:titleIndex,class:[
                titleIndex !== 0
                  ? [_vm.$style.columnAlignment, _vm.$style.columnAlignmentAudit]
                  : '' ]},[_vm._v(" "+_vm._s(title)+" ")])}),0)]),_c('tbody',_vm._l((_vm.getSamplingAggregateAudit),function(rowData,rowDataIndex){return _c('tr',{key:rowDataIndex},[_c('td',[_vm._v(_vm._s(rowData.siteName))]),_vm._l((rowData.values),function(value,valueIndex){return _c('td',{key:valueIndex,class:_vm.$style.columnAlignment},[_vm._v(" "+_vm._s(value)+" ")])})],2)}),0)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }