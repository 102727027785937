































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  CreateGripAnalysisBarrierPayload,
  CreateGripAnalysisEnablerPayload,
  CreateGripAnalysisStrategyPayload,
  CreateGripBarrierBase,
  GripAnalysisBarrier,
  GripAnalysisDTO,
  GripItemType,
  GripBarrierDTO,
  GripBarrierStatus,
  GripItemReloadDTO,
  UpdateGripAnalysisEnablerPayload,
  UpdateGripAnalysisStrategyPayload,
  UpdateGripBarrierBase,
} from '../../../store/types/grip.types';
import BaseLoading from '../../../components/base/BaseLoading.vue';
import BarrierModal from '../Barrier/BarrierModal.vue';
import DeleteModalBox from '../../../components/DeleteModalBox.vue';
import GripAnalysisEnablers from './GripAnalysisEnablers.vue';

@Component({
  components: {
    BaseLoading,
    BarrierModal,
    DeleteModalBox,
    GripAnalysisEnablers,
  },
})
export default class GripAnalysisBarriers extends Vue {
  @Prop() public projectId!: number;
  @Prop() public isDataCollector!: boolean;
  @Prop() public isSideBarOpen!: boolean;
  @Prop() public gripAnalysisDetails!: GripAnalysisDTO;
  @Prop() public updatedItem!: GripItemReloadDTO;
  public isGripAnalysisBarriersLoading: boolean = false;
  public showBarrierDraggableBox: boolean = true;
  public itemList: GripAnalysisBarrier[] = [];
  public tempDirtyBarrier: CreateGripBarrierBase = {
    title: '',
    description: '',
    action: true,
    reason: null,
    links: [],
    attachments: [],
    gripDomainCategoryId: null,
  };
  public modalKey: number = Math.floor(Math.random() * 999);
  public isModalActive: boolean = false;
  public isNew: boolean = false;
  public gripBarrierDetails: GripBarrierDTO | null = null;

  public getUpperCase(value: string): string {
    return value.toUpperCase();
  }

  public updated(): void {
    if (this.updatedItem.item === GripItemType.AnalysisBarrier) {
      const element = document.getElementById(
        this.updatedItem.item + '_' + this.updatedItem.id,
      );
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
    if (this.showBarrierDraggableBox) {
      const element = document.getElementById('contentIdForAddBarriers');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  public mounted(): void {
    this.itemList =
      this.gripAnalysisDetails && this.gripAnalysisDetails.gripAnalysisBarriers
        ? this.gripAnalysisDetails.gripAnalysisBarriers
            .filter((barrier) => barrier.status === GripBarrierStatus.ACTIVE)
            .sort((a, b) => a.id - b.id)
        : [];

    this.showBarrierDraggableBox = this.itemList.length > 0 ? false : true;
  }

  public handleShowDraggableBox(): void {
    this.showBarrierDraggableBox = true;
  }

  public onDragOver(event: any): void {
    const dragSupported = event.dataTransfer.types.length;
    if (dragSupported) {
      event.dataTransfer.dropEffect = 'copy';
      event.preventDefault();
    }
  }

  public onDrop(event: any, id: string): void {
    event.preventDefault();
    const jsonData = event.dataTransfer.getData('application/json');
    const data = JSON.parse(jsonData);
    if (!data) {
      return;
    }

    const { itemType, ...barrierData } = data;
    if (id === 'barrierDropZone' && itemType === 'barrier') {
      const barrierPayload: CreateGripAnalysisBarrierPayload = {
        ...barrierData,
        gripAnalysisId: this.gripAnalysisDetails.id,
      };
      this.$emit('addGripAnalysisBarrier', { barrierPayload, isNew: false });
    }
  }

  public openBarrierModal(): void {
    this.isNew = true;
    this.isModalActive = true;
    this.modalKey += 1;
    this.gripBarrierDetails = null;
  }

  public handleEdit(barrier: GripAnalysisBarrier): void {
    const {
      gripAnalysisEnablers,
      status,
      createdDate,
      updatedDate,
      ...barrierDetails
    } = barrier;

    this.gripBarrierDetails = barrierDetails as GripBarrierDTO;
    this.isNew = false;
    this.isModalActive = true;
    this.modalKey += 1;
  }

  public closeBarrierModal(): void {
    this.isModalActive = false;
  }

  public handleBarrierCreate(gripBarrier: CreateGripBarrierBase): void {
    const barrierPayload: CreateGripAnalysisBarrierPayload = {
      ...gripBarrier,
      gripAnalysisId: this.gripAnalysisDetails.id,
    };
    this.$emit('addGripAnalysisBarrier', {
      barrierPayload,
      isNew: true,
    });
    this.closeBarrierModal();
    this.showBarrierDraggableBox = false;
  }

  public handleBarrierUpdate(gripBarrier: UpdateGripBarrierBase): void {
    this.$emit('updateGripAnalysisBarrier', {
      ...gripBarrier,
      gripAnalysisId: this.gripAnalysisDetails.id,
    });
    this.$emit('handleUpdatedItemReload', {
      item: GripItemType.AnalysisBarrier,
      id: gripBarrier.id,
      prevItemId: this.getPrevItemId(gripBarrier.id),
    });
    this.closeBarrierModal();
  }

  public handleDelete(gripAnalysisBarrierId: number): void {
    this.$buefy.modal.open({
      parent: this,
      component: DeleteModalBox,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle: 'Delete Barrier?',
        modalContent:
          'Are you sure you want to delete this barrier? This action cannot be undone.',
      },
      events: {
        close: () => {
          this.$emit('close');
        },
        confirm: () => {
          this.$emit('deleteGripAnalysisBarrier', gripAnalysisBarrierId);
          this.$emit('handleUpdatedItemReload', {
            item: GripItemType.AnalysisBarrier,
            id: gripAnalysisBarrierId,
            prevItemId: this.getPrevItemId(gripAnalysisBarrierId),
          });
        },
      },
    });
  }

  public getPrevItemId(gripBarrierId: number): string | null {
    const barrierIndex: number = this.itemList.findIndex(
      (item) => item.id === gripBarrierId,
    );

    return barrierIndex > 0
      ? GripItemType.AnalysisBarrier + '_' + this.itemList[barrierIndex - 1].id
      : null;
  }

  public handleUpdatedItemReload(data: GripItemReloadDTO): void {
    this.$emit('handleUpdatedItemReload', data);
  }

  public handleAddGripAnalysisEnabler(enablerDetails: {
    enablerPayload: CreateGripAnalysisEnablerPayload;
    isNew: boolean;
  }): void {
    this.$emit('addGripAnalysisEnabler', enablerDetails);
  }

  public handleUpdateGripAnalysisEnabler(
    enablerPayload: UpdateGripAnalysisEnablerPayload,
  ): void {
    this.$emit('updateGripAnalysisEnabler', enablerPayload);
  }

  public handleDeleteGripAnalysisEnabler(gripAnalysisEnablerId: number): void {
    this.$emit('deleteGripAnalysisEnabler', gripAnalysisEnablerId);
  }

  public handleAddGripAnalysisStrategy(strategyDetails: {
    strategyPayload: CreateGripAnalysisStrategyPayload;
    isNew: boolean;
  }): void {
    this.$emit('addGripAnalysisStrategy', strategyDetails);
  }

  public handleUpdateGripAnalysisStrategy(
    strategyPayload: UpdateGripAnalysisStrategyPayload,
  ): void {
    this.$emit('updateGripAnalysisStrategy', strategyPayload);
  }

  public handleDeleteGripAnalysisStrategy(
    gripAnalysisStrategyId: number,
  ): void {
    this.$emit('deleteGripAnalysisStrategy', gripAnalysisStrategyId);
  }
}
