




























































import { Component, Vue, Watch } from 'vue-property-decorator';
import DefaultLayout from '../../layouts/DefaultLayout.vue';
import HeroBar from '@/components/base/HeroBar.vue';
import { State } from 'vuex-class';
import { ProjectDetailPayload } from '@/store/modules/projects/types/projects.types';
import { cloneDeep as _cloneDeep, get as _get, last as _last } from 'lodash';
import BasePagination from '@/components/base/BasePagination.vue';
import { useUserEmail } from '@/utils/user.util';
import {
  SubscriptionInfo,
  SubscriptionStatus,
} from '@/store/modules/auth/types/auth.types';
import ProjectSubscription from '@/components/ProjectSubscription.vue';
import TableDropdown from '../AdminProjectManagement/components/TableDropdown.vue';
import ProjectActiveListPage from './ProjectActiveListPage.vue';
import ProjectCreatedListPage from './ProjectCreatedListPage.vue';
import ProjectDraftListPage from './ProjectDraftListPage.vue';
import ProjectArchiveListPage from './ProjectArchiveListPage.vue';

const tabs = [
  {
    tab: 'Active',
    search: '',
    limit: '50',
    page: '1',
  },
  {
    tab: 'Created',
    search: '',
    limit: '50',
    page: '1',
  },
  {
    tab: 'Draft',
    search: '',
    limit: '50',
    page: '1',
  },
  {
    tab: 'Archive',
    search: '',
    limit: '50',
    page: '1',
  },
];

@Component({
  components: {
    ProjectSubscription,
    DefaultLayout,
    HeroBar,
    BasePagination,
    TableDropdown,
    ProjectActiveListPage,
    ProjectCreatedListPage,
    ProjectDraftListPage,
    ProjectArchiveListPage,
  },
})
export default class ProjectListPage extends Vue {
  @State((state) => state.auth.subscriptionInfo)
  public subscriptionInfo!: SubscriptionInfo;

  public activeProjects: ProjectDetailPayload[] = [];
  public archiveProjects: ProjectDetailPayload[] = [];
  public draftProjects: ProjectDetailPayload[] = [];
  public filteredActiveProjects: ProjectDetailPayload[] = [];
  public filteredCreatedProjects: ProjectDetailPayload[] = [];
  public filteredArchiveProjects: ProjectDetailPayload[] = [];
  public filteredDraftProjects: ProjectDetailPayload[] = [];
  public createdByMeProjects: ProjectDetailPayload[] = [];
  public activeTab: number = 0;
  public activeSearchQuery: string = '';
  public archiveSearchQuery: string = '';
  public draftSearchQuery: string = '';
  public createdSearchQuery: string = '';
  public initialValue: any = {};
  public isEmptyActive: boolean = false;
  public isEmptyArchive: boolean = false;
  public isEmptyDraft: boolean = false;
  public isEmptyCreated: boolean = false;
  public page: number = 1;
  public perPage: number = 5;
  public totalCount: number = 0;
  public renderPaginationActive: boolean = true;
  public renderPaginationArchive: boolean = true;
  public renderPaginationDraft: boolean = true;
  public renderPaginationCreated: boolean = true;
  public administratorName: string = '';
  public isLoading: boolean = true;

  get havePacesSubscription(): boolean {
    return this.subscriptionInfo
      ? this.subscriptionInfo.subscriptionStatus === SubscriptionStatus.VALID
      : false;
  }

  public mounted() {
    this.isEmptyActive = true;
    if (this.$route.query.tab) {
      const index = tabs.findIndex((tab) => tab.tab === this.$route.query.tab);
      if (index >= 0) {
        this.activeTab = +index;
      }
    } else {
      this.$router.push({
        query: {
          ...tabs[0],
        },
      });
    }
    if (this.havePacesSubscription) {
      switch (this.activeTab) {
        case 0:
          this.isEmptyActive = true;
          this.isEmptyCreated = false;
          this.isEmptyDraft = false;
          this.isEmptyArchive = false;
          break;
        case 1:
          this.isEmptyCreated = true;
          this.isEmptyActive = false;
          this.isEmptyDraft = false;
          this.isEmptyArchive = false;
          break;
        case 2:
          this.isEmptyDraft = true;
          this.isEmptyCreated = false;
          this.isEmptyActive = false;
          this.isEmptyArchive = false;
          break;
        case 3:
          this.isEmptyArchive = true;
          this.isEmptyCreated = false;
          this.isEmptyActive = false;
          this.isEmptyDraft = false;
          break;
        default:
          break;
      }
    } else {
      switch (this.activeTab) {
        case 0:
          this.isEmptyActive = true;
          this.isEmptyCreated = false;
          this.isEmptyDraft = false;
          this.isEmptyArchive = false;
          break;
        case 1:
          this.isEmptyArchive = true;
          this.isEmptyCreated = false;
          this.isEmptyActive = false;
          this.isEmptyDraft = false;
          break;
        default:
          break;
      }
    }
  }

  @Watch('activeTab')
  public watchActiveTab(newValue: number) {
    if (this.$route.query.tab === tabs[newValue].tab) {
      return;
    }
    this.$router.replace({ query: undefined });
    this.$router.push({
      query: {
        ...(tabs as { [key: string]: any })[newValue],
      },
    });
    this.isLoading = true;
    if (this.havePacesSubscription) {
      switch (newValue) {
        case 0:
          this.isEmptyActive = true;
          this.isEmptyCreated = false;
          this.isEmptyDraft = false;
          this.isEmptyArchive = false;
          break;
        case 1:
          this.isEmptyCreated = true;
          this.isEmptyActive = false;
          this.isEmptyDraft = false;
          this.isEmptyArchive = false;
          break;
        case 2:
          this.isEmptyDraft = true;
          this.isEmptyCreated = false;
          this.isEmptyActive = false;
          this.isEmptyArchive = false;
          break;
        case 3:
          this.isEmptyArchive = true;
          this.isEmptyCreated = false;
          this.isEmptyActive = false;
          this.isEmptyDraft = false;
          break;
        case 3:
          if (this.filteredArchiveProjects.length > 0) {
            this.archiveProjects = this.filteredArchiveProjects.slice(
              0,
              newValue,
            );
          } else {
            this.archiveProjects = this.initialValue.archive.slice(0, newValue);
          }
          break;
        default:
          break;
      }
    } else {
      switch (newValue) {
        case 0:
          this.isEmptyActive = true;
          this.isEmptyCreated = false;
          this.isEmptyDraft = false;
          this.isEmptyArchive = false;
          break;
        case 1:
          this.isEmptyArchive = true;
          this.isEmptyCreated = false;
          this.isEmptyActive = false;
          this.isEmptyDraft = false;
          break;
        case 1:
          if (this.filteredArchiveProjects.length > 0) {
            this.archiveProjects = this.filteredArchiveProjects.slice(
              0,
              newValue,
            );
          } else {
            this.archiveProjects = this.initialValue.archive.slice(0, newValue);
          }
          break;
        default:
          break;
      }
    }
  }

  public handleUpdateLoadingBool(newValue: boolean) {
    this.isLoading = newValue;
  }

  get userEmail(): string | undefined {
    return useUserEmail.call(this);
  }
}
