














































































import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { RangeCriterionContent } from '@/store/types/criterions.types';
import {
  isTruthy,
  isDifferentDeep,
} from '@/jbi-shared/util/watcher.vue-decorator';

@Component({
  components: { ValidationProvider, ValidationObserver },
})
export default class RangeCriterionInput extends Vue {
  @Prop({
    default() {
      return [1, 10, 'N/A'];
    },
  })
  public range!: RangeCriterionContent['range'];

  public start = this.range[0];
  public end = this.range[1];
  public notApplicable = this.range[2];
  public minRangeError: boolean = false;
  public maxRangeError: boolean = false;

  @Watch('start')
  public onStartChanged() {
    this.minRangeError =
      this.start.toString() !== '' && Number(this.start) >= Number(this.end)
        ? true
        : false;
    if (this.maxRangeError) {
      this.maxRangeError = this.minRangeError;
    }
    this.$emit('update:range', [this.start, this.end, this.notApplicable]);
    this.$emit('disableSubmitButton', this.minRangeError);
  }

  @Watch('end')
  public onEndChanged() {
    this.maxRangeError =
      this.end.toString() !== '' && Number(this.end) <= Number(this.start)
        ? true
        : false;
    if (this.minRangeError) {
      this.minRangeError = this.maxRangeError;
    }
    this.$emit('update:range', [this.start, this.end, this.notApplicable]);
    this.$emit('disableSubmitButton', this.maxRangeError);
  }

  @isTruthy
  @Watch('range')
  public onRangeUpdated() {
    this.start = this.range[0];
    this.end = this.range[1];
  }
}
