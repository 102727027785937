






































import {Component, Vue, Prop} from 'vue-property-decorator';
import { cloneDeep as _cloneDeep } from 'lodash';
import {ChartType} from '@/store/modules/audits/types/audits.types';

@Component({ components : {} })

export default class ProjectChartTypeFilter extends Vue {
    @Prop({default: false})
    public isDisabled!: boolean;
    @Prop()
    public selectedChartType!: string;
    public dirtyChartType: string = '';

    public mounted() {
        this.dirtyChartType = this.selectedChartType;
    }

    public updateChartType(newValue: ChartType) {
        this.$emit('applyChartType', newValue);
    }

    get chartTypeList(): ChartType[] {
        return [ChartType.verticalBarChart, ChartType.horizontalBarChart];
    }
}
