import { isLink } from '@/jbi-shared/util/validate-email-domains.util';
import { LinkDTO } from '@/store/types/grip.types';

export const getUpperCase = (value: string) => {
  return value.toUpperCase();
};

export const getSourceLink = (barrierLinks: LinkDTO[]): string => {
  const pattern: RegExp = /^((http|https|ftp):\/\/)/;
  let sourceLink: string = '';
  if (barrierLinks.length !== 0) {
    barrierLinks.forEach((link: LinkDTO) => {
      if (link.url && !pattern.test(link.url)) {
        sourceLink = 'http://' + link.url;
      } else {
        sourceLink = link.url;
      }
    });
  }
  return sourceLink;
};

export const getWordCount = (value: string): number => {
  return value.split(' ').length;
};

export const validateLink = (link: LinkDTO): LinkDTO => {
  const isValid: boolean = isLink(link.url);
  link.isValid = isValid;
  link.errorMessage = !isValid ? 'Invalid link format' : '';

  return link;
};

// TODO: move this to jbi-shared
export const cloneData = (data: any) => {
  return JSON.parse(JSON.stringify(data));
};
