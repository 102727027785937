var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-field',{class:_vm.$style.modeSection},[_c('p',{staticClass:"control"},[_c('b-button',{class:[
          _vm.$style.button,
          _vm.dataCollectionType === 'aggregate' && _vm.$style.buttonActive ],on:{"click":function($event){return _vm.switchMode('aggregate')}}},[_c('img',{attrs:{"src":require(_vm.dataCollectionType === 'aggregate'
              ? '@/assets/icons/ic_aggregated_white.png'
              : '@/assets/icons/ic_aggregated_grey.png')}}),_vm._v(" Aggregate ")])],1),_c('p',{staticClass:"control"},[_c('b-button',{class:[
          _vm.$style.button,
          _vm.dataCollectionType === 'individual' && _vm.$style.buttonActive ],on:{"click":function($event){return _vm.switchMode('individual')}}},[_c('img',{attrs:{"src":require(_vm.dataCollectionType === 'individual'
              ? '@/assets/icons/ic_individual_white.png'
              : '@/assets/icons/ic_individual_grey.png')}}),_vm._v(" Individual ")])],1)]),(_vm.renderComponent)?_c('div',{class:_vm.$style.dataContainer},[(_vm.dataCollectionType === 'aggregate' && _vm.renderComponent)?[(_vm.defaultSiteId !== undefined)?_c('DataCollectionAggregate',{attrs:{"auditDetail":_vm.auditDetail,"singleAuditCriteriaId":undefined,"editDataCollectionRecord":null,"dataCollectionSummary":_vm.dirtyDataCollectionSummary,"defaultSiteId":_vm.defaultSiteId,"siteList":_vm.siteList,"auditCriteriaList":_vm.auditCriteriaList,"auditConfig":_vm.auditConfig},on:{"submitRecords":_vm.handleEnteredDataRecords,"updateSiteDropdownItem":_vm.handleSiteDropdownItem,"isMultipleSubmission":function (isSubmitAnotherRecord) { return (_vm.isMultipleSubmission = isSubmitAnotherRecord); }}}):_vm._e()]:_vm._e(),(_vm.dataCollectionType === 'individual' && _vm.renderComponent)?[(_vm.defaultSiteId !== undefined)?_c('DataCollectionIndividual',{attrs:{"auditDetail":_vm.auditDetail,"singleAuditCriteriaId":undefined,"editDataCollectionRecord":null,"dataCollectionSummary":_vm.dirtyDataCollectionSummary,"defaultSiteId":_vm.defaultSiteId,"siteList":_vm.siteList,"auditCriteriaList":_vm.auditCriteriaList,"auditConfig":_vm.auditConfig},on:{"submitRecords":_vm.handleEnteredDataRecords,"updateSiteDropdownItem":_vm.handleSiteDropdownItem,"isMultipleSubmission":function (isSubmitAnotherRecord) { return (_vm.isMultipleSubmission = isSubmitAnotherRecord); }}}):_vm._e()]:_vm._e()],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }