





































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import BaseModal from '@/jbi-shared/vue-components/BaseModal.vue';
import { cloneDeep as _cloneDeep } from 'lodash';
import {
  AuditSamplingSite,
  OneOffCalculateData,
} from '@/store/modules/audits/types/audits.types';

@Component({
  components: {
    BaseModal,
  },
})
export default class SamplingCalculator extends Vue {
  @Prop()
  public initialData!: any;
  @Prop()
  public siteArray!: AuditSamplingSite[];
  @Prop()
  public siteName!: string;
  @Prop()
  public criteriaTitle!: string;
  @Prop()
  public auditSiteId!: number;
  @Prop()
  public auditCriteriaId!: number;

  public modalTitle: string = 'Sample Size Calculator';
  public modalSize: string = 'large';
  public calculateData: OneOffCalculateData = {
    confidenceLevel: 95,
    population: 100000,
    margin: 5,
    groupOne: 50,
    recommended: 0,
    output: 0,
  };
  public isCriteriaBasedMultiSiteSampling = false;
  public isDisableInput: boolean = true;

  public mounted() {
    if (this.initialData) {
      this.calculateData = _cloneDeep(this.initialData);
    }

    if (this.siteArray) {
      this.isCriteriaBasedMultiSiteSampling = true;
      if (this.calculateData.output === 0) {
        this.calculateSampleSize();
      }
    } else {
      this.calculateSampleSize();
    }
  }

  public calculateSampleSize() {
    this.calculateData.output = this.sampleSize(
      Number(this.calculateData.margin),
      Number(this.calculateData.confidenceLevel),
      Number(this.calculateData.groupOne),
      Number(this.calculateData.population),
    );

    this.calculateData.recommended = this.calculateData.output;
    if (
      this.siteArray !== undefined &&
      this.calculateData.sites !== undefined
    ) {
      this.updateChildSamplingSize();
    }
  }

  public sampleSize(
    margin: number,
    confidence: number,
    response: number,
    population: number,
  ) {
    const pcn = this.probCriticalNormal(confidence / 100.0);
    const d1 = pcn * pcn * response * (100.0 - response);
    const d2 = (population - 1.0) * (margin * margin) + d1;
    if (d2 > 0.0) {
      return Math.ceil((population * d1) / d2);
    }
    return 0;
  }

  public probCriticalNormal(p: number) {
    // input p is confidence level convert it to
    // cumulative probability before computing critical

    let y: number;
    let pr: number;
    let real1: number;
    let real2: number;
    let hold: number;
    let i: number;
    const pn: number[] = [
      0, // ARRAY[1..5] OF REAL
      -0.322232431088,
      -1.0,
      -0.342242088547,
      -0.0204231210245,
      -0.453642210148e-4,
    ];

    const qn: number[] = [
      0, //  ARRAY[1..5] OF REAL
      0.099348462606,
      0.588581570495,
      0.531103462366,
      0.10353775285,
      0.38560700634e-2,
    ];

    pr = 0.5 - p / 2; // one side significance

    if (pr <= 1.0e-8) {
      hold = 6;
    } else {
      if (pr === 0.5) {
        hold = 0;
      } else {
        y = Math.sqrt(Math.log(1.0 / (pr * pr)));
        real1 = pn[5];
        real2 = qn[5];

        for (i = 4; i >= 1; i--) {
          real1 = real1 * y + pn[i];
          real2 = real2 * y + qn[i];
        }

        hold = y + real1 / real2;
      } // end of else pr = 0.5
    } // end of else Pr <= 1.0E-8

    return hold;
  }

  public handleCloseButton(closeModal: any) {
    closeModal();
  }

  public handleApplySampleSize(closeModal: any) {
    let emitData: any = this.calculateData;
    if (this.auditSiteId !== undefined) {
      emitData = {
        ...emitData,
        auditSiteId: this.auditSiteId,
      };
    }

    if (this.auditCriteriaId !== undefined) {
      emitData = {
        ...emitData,
        auditCriteriaId: this.auditCriteriaId,
      };
    }
    this.$emit('applySampleSize', emitData);
    closeModal();
  }

  public updateChildSamplingSize() {
    if (
      this.siteArray !== undefined &&
      this.calculateData.sites !== undefined
    ) {
      const parentVal = this.calculateData.output;
      const siteArrayLength = this.siteArray.length;
      const modulo = parentVal % siteArrayLength;
      const childVal = (parentVal - modulo) / siteArrayLength;
      this.calculateData.sites.forEach(
        (currentVal: AuditSamplingSite, siteIndex: number) => {
          currentVal.calculateData.output =
            siteIndex === 0 ? childVal + modulo : childVal;
        },
      );
    }
  }

  public updateOtherChildSamplingSize(siteIndex: number) {
    if (
      this.siteArray !== undefined &&
      this.calculateData.sites !== undefined
    ) {
      const currentElementVal = !this.calculateData.sites[siteIndex]
        .calculateData.output
        ? 0
        : Number(this.calculateData.sites[siteIndex].calculateData.output);
      const parentVal: number = !this.calculateData.output
        ? 0
        : Number(this.calculateData.output);
      if (parentVal < currentElementVal) {
        this.updateChildSamplingSize();
      } else {
        const tempParentVal = parentVal - currentElementVal;
        const siteArrayLength = Number(this.siteArray.length - 1);
        const modulo = tempParentVal % siteArrayLength;
        const childVal = (tempParentVal - modulo) / siteArrayLength;
        this.calculateData.sites.forEach(
          (currentVal: AuditSamplingSite, currentSiteIndex: number) => {
            if (currentSiteIndex !== siteIndex && siteIndex !== 0) {
              currentVal.calculateData.output =
                currentSiteIndex === 0 ? childVal + modulo : childVal;
            } else if (currentSiteIndex !== siteIndex) {
              currentVal.calculateData.output =
                currentSiteIndex === 1 ? childVal + modulo : childVal;
            }
          },
        );
      }
    }
  }

  @Watch('calculateData', { deep: true })
  public onCheckCalculateData(value: any) {
    if (value.sites) {
      this.isDisableInput = Number(value.output) !== 0 ? false : true;
    }
  }
}
