import { Store } from 'vuex';
import { authApi } from '@/api/auth.api';
import {
  ExchangeGrantForTokenRequestPayload,
  AuthState,
  ExchangeGrantForTokenResponsePayload,
  CheckSubscriptionStatusByEmailIdsPayload,
} from './types/auth.types';
import jsCookie from 'js-cookie';

export const authActions = {
  async exchangeGrantForToken(
    store: Store<AuthState>,
    payload: ExchangeGrantForTokenRequestPayload,
  ) {
    try {
      store.commit('EXCHANGE_GRANT_FOR_TOKEN_LOADING');
      const {
        data,
      }: {
        data: ExchangeGrantForTokenResponsePayload;
      } = await authApi.exchangeGrantForToken(payload);
      store.commit('EXCHANGE_GRANT_FOR_TOKEN_SUCCESS', data);
    } catch (error) {
      store.commit('EXCHANGE_GRANT_FOR_TOKEN_ERROR', error);
    }
  },
  async switchAccount(store: Store<AuthState>, value: boolean) {
    store.commit('SWITCH_ACCOUNT', value);
  },
  async signOut(store: Store<AuthState>) {
    try {
      store.commit('SIGN_OUT_LOADING');

      await authApi.signOut();

      const ifrm = document.createElement('iframe');
      ifrm.setAttribute('src', process.env.VUE_APP_JAAS_PUBLIC_URL + '/logout');
      ifrm.style.display = 'none';
      document.body.appendChild(ifrm);
      const topic = 'logout';
      ifrm.onload = () => {
        ifrm.contentWindow!.top.postMessage(topic, '*');
        ifrm.remove();
      };

      window.onmessage = async (event: any) => {
        if (event.data === topic) {
          /** Remove the survey cookie as the user is signout */
          jsCookie.remove('paces_survey');
          store.commit('SIGN_OUT_SUCCESS');
        }
      };
    } catch (error) {
      store.commit('SIGN_OUT_ERROR', error);
    }
  },
  async checkSessionHealth(store: Store<AuthState>) {
    try {
      store.commit('CHECK_SESSION_HEALTH_LOADING');

      await authApi.checkSessionHealth();

      store.commit('CHECK_SESSION_HEALTH_SUCCESS');
    } catch (error) {
      store.commit('CHECK_SESSION_HEALTH_ERROR', error);
    }
  },

  async checkSubscriptionHealth(store: Store<AuthState>) {
    try {
      store.commit('CHECK_SUBSCRIPTION_HEALTH_LOADING');

      const data = await authApi.checkSubscriptionHealth();

      store.commit('CHECK_SUBSCRIPTION_HEALTH_SUCCESS', data);
    } catch (error) {
      store.commit('CHECK_SUBSCRIPTION_HEALTH_ERROR', error);
    }
  },
  async getJaasAccountDetails(store: Store<AuthState>) {
    try {
      store.commit('GET_JAAS_ACCOUNT_DETAIL_LOADING');

      const {
        data,
      }: {
        data: ExchangeGrantForTokenResponsePayload;
      } = await authApi.getJaasAccountDetails();

      store.commit('GET_JAAS_ACCOUNT_DETAIL_SUCCESS', data);
    } catch (error) {
      store.commit('GET_JAAS_ACCOUNT_DETAIL_ERROR', error);
    }
  },
  async checkSubscriptionStatusByEmailIds(
    store: Store<AuthState>,
    payload: CheckSubscriptionStatusByEmailIdsPayload,
  ) {
    try {
      store.commit('CHECK_SUBSCRIPTION_STATUS_EMAIL_LOADING');

      const {
        data,
      }: {
        data: CheckSubscriptionStatusByEmailIdsPayload;
      } = await authApi.checkSubscriptionStatusByEmailIds(payload);

      store.commit('CHECK_SUBSCRIPTION_STATUS_EMAIL_SUCCESS', data);
    } catch (error) {
      store.commit('CHECK_SUBSCRIPTION_STATUS_EMAIL_ERROR', error);
    }
  },
};
