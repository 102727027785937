




















































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {
  AuditSamplingRequestPayload,
  AuditSamplingResponse,
  AuditSamplingType,
  AuditSite,
  ConsecutiveSamplingMode,
  SamplingMode,
} from '@/store/modules/audits/types/audits.types';
import TargetSampleSizeSingleSiteSampling from '@/views/Audit/components/TargetSampleSizeSingleSiteSampling.vue';
import TargetSampleSizeMultiSiteCriteriaSampling from '@/views/Audit/components/TargetSampleSizeMultiSiteCriteriaSampling.vue';
import ConsecutiveTargetSampleSingleSiteSampling from '@/views/Audit/components/ConsecutiveTargetSampleSingleSiteSampling.vue';
import ConsecutiveTargetSampleMultiSiteSampling from '@/views/Audit/components/ConsecutiveTargetSampleMultiSiteSampling.vue';
// tslint:disable-next-line:max-line-length
import ConsecutiveMinAndMaxSingleSiteSampling from '@/views/Audit/components/ConsecutiveMinAndMaxSingleSiteSampling.vue';
import ConsecutiveMinAndMaxMultiSiteSampling from '@/views/Audit/components/ConsecutiveMinAndMaxMultiSiteSampling.vue';
import AdHocSingleSiteSampling from '@/views/Audit/components/AdHocSingleSiteSampling.vue';
import AdHocMultiSiteSampling from '@/views/Audit/components/AdHocMultiSiteSampling.vue';
import {Action, State} from 'vuex-class';
import {isTruthy} from '@/jbi-shared/util/watcher.vue-decorator';
import ConfirmationDialogue from '@/components/ConfirmationDialogue.vue';

@Component({
    components: {
        ConfirmationDialogue,
        AdHocMultiSiteSampling,
        AdHocSingleSiteSampling,
        ConsecutiveMinAndMaxMultiSiteSampling,
        ConsecutiveMinAndMaxSingleSiteSampling,
        ConsecutiveTargetSampleMultiSiteSampling,
        ConsecutiveTargetSampleSingleSiteSampling,
        TargetSampleSizeMultiSiteCriteriaSampling,
        TargetSampleSizeSingleSiteSampling,
    },
})
export default class AuditSampling extends Vue {
    @Prop()
    public auditId !: number;
    @Prop()
    public auditDataCollectionDate!: string;
    @Prop()
    public auditCategory!: string;
    @Prop({ default: true })
    public editable!: boolean;

    public auditSamplingType: string = 'targetSampleSize';
    public auditSamplingMode: string = 'criteriaBased';
    public consecutiveSamplingMode: string = 'targetSample';
    public isFirstTimeMounted: boolean = true;
    public previousSamplingType: string = 'targetSampleSize';
    public validateDataEntry: boolean = false;

    @Action('audits/getAuditSites')
    public getAuditSites!: (payload: number) => void;

    @State(state => state.audits.auditSites)
    public auditSites!: AuditSite[];

    @Action('audits/getSamplingConfig')
    public getSamplingConfig!: (payload: AuditSamplingRequestPayload) => void;

    @State(state => state.audits.auditConfig)
    public auditConfig!: AuditSamplingResponse;

    @Watch('auditConfig')
    @isTruthy
    public watchAuditConfig(newValue: AuditSamplingResponse) {
        if (this.isFirstTimeMounted) {
            this.auditSamplingType = newValue.auditSamplingConfigs.auditSamplingType;
            this.previousSamplingType = this.auditSamplingType;
            if (this.auditSamplingType === 'targetSampleSize') {
                this.auditSamplingMode = newValue.auditSamplingConfigs.samplingMode;
            } else if (this.auditSamplingType === 'consecutive') {
                this.consecutiveSamplingMode = newValue.auditSamplingConfigs.samplingMode;
                this.validateDataEntry = newValue.auditSamplingConfigs.validateDataEntry;
            }
            this.isFirstTimeMounted = false;
        }
    }

    public mounted() {
        this.getSamplingConfig({
          auditId: this.auditId,
        });
        this.getAuditSites(this.auditId);
    }

    get samplingTypeOptions() {
        return AuditSamplingType;
    }

    get samplingModeOptions() {
        return SamplingMode;
    }

    get consecutiveSamplingModeOptions() {
        return ConsecutiveSamplingMode;
    }

    public handleSamplingTypeChange(newValue: string) {
        if (this.auditDataCollectionDate !== undefined && this.auditDataCollectionDate !== null) {
            let confirmationText = `Changing the sampling type will change
                the sampling information displayed in the data collection page.`;
            if (newValue === 'adHoc') {
            confirmationText = `Changing the sampling type to Ad-Hoc will cease data collection
                and delete collected data for criteria disabled for sampling.`;
            }
            this.$buefy.modal.open({
            parent: this,
            component: ConfirmationDialogue,
            hasModalCard: true,
            trapFocus: true,
            onCancel: () => {
                this.auditSamplingType = this.previousSamplingType;
            },
            props: {
                modalTitle: 'Change Sampling Type?',
                confirmationText,
                confirmationButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            },
            events: {
                confirm: () => {
                this.previousSamplingType = newValue;
                this.auditSamplingType = newValue;
                this.getSamplingConfig({
                    auditId: this.auditId,
                    samplingType: newValue,
                });
                },
                cancel: () => {
                    this.auditSamplingType = this.previousSamplingType;
                },
            },
            });
        } else {
            this.previousSamplingType = newValue;
            this.auditSamplingType = newValue;
            this.getSamplingConfig({
            auditId: this.auditId,
            samplingType: newValue,
            });
        }
    }

    public handleSamplingModeChange() {
      this.getSamplingConfig({
        auditId: this.auditId,
        samplingType: this.auditSamplingType,
        mode: this.consecutiveSamplingMode,
      });
    }

    get auditSiteType() {
      return this.auditSites && this.auditSites.length > 1
          ? 'multiSite'
          : 'singleSite';
    }
}
