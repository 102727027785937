





































































import { Component, Vue, Prop, Watch, Model } from 'vue-property-decorator';
import { State, Getter, Action, Mutation, namespace } from 'vuex-class';
import {
  DataCollectionSummaryRequestPayload,
  DataCollectionSummaryResponsePayload,
} from '../../../store/modules/audits/types/audits.types';
import { get as _get } from 'lodash';

@Component({
  components: {},
})
export default class DataCollectionTable extends Vue {
  @Prop()
  public data!: any;

  @Prop()
  public samplingConfigurations!: any;

  public showTableContent: boolean = false;

  public mounted() {
    this.showTableContent = true;
  }
}
