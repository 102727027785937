import { defaultApiState } from '@/store/types/general.types';
import { SiteState, Site, SiteInfo } from './types/site.types';
import { AxiosError } from 'axios';

export const siteMutations = {
  /*
   ** add site loading
   */
  ADD_SITE_LOADING(state: SiteState) {
    state.apiState.addSite = {
      ...defaultApiState,
      loading: true,
    };
  },

  /*
   ** add site success
   */
  ADD_SITE_SUCCESS(state: SiteState, payload: Site) {
    state.apiState.addSite = {
      ...defaultApiState,
      success: true,
    };
    if (state.sites && state.sites.items) {
      state.sites.items.push(payload);
    }
  },

  /*
   ** add site error
   */
  ADD_SITE_ERROR(state: SiteState, error: AxiosError) {
    state.apiState.addSite = {
      ...defaultApiState,
      error,
    };
  },

  /*
   ** delete site loading
   */
  DELETE_SITE_LOADING(state: SiteState) {
    state.apiState.deleteSite = {
      ...defaultApiState,
      loading: true,
    };
  },

  /*
   ** delete site success
   */
  DELETE_SITE_SUCCESS(state: SiteState, payload: number) {
    state.apiState.deleteSite = {
      ...defaultApiState,
      success: true,
    };
    if (state.sites && state.sites.items) {
      state.sites.items =
        state.sites.items &&
        state.sites.items.filter((siteElem: Site) => siteElem.id !== payload);
    }
  },

  /*
   ** delete site error
   */
  DELETE_SITE_ERROR(state: SiteState, error: AxiosError) {
    state.apiState.deleteSite = {
      ...defaultApiState,
      error,
    };
  },

  /*
   ** edit site loading
   */
  EDIT_SITE_LOADING(state: SiteState) {
    state.apiState.editSite = {
      ...defaultApiState,
      loading: true,
    };
  },

  /*
   ** edit site success
   */
  EDIT_SITE_SUCCESS(state: SiteState, payload: Site) {
    state.apiState.editSite = {
      ...defaultApiState,
      success: true,
    };
    if (state.sites && state.sites.items) {
      state.sites.items.map((siteElem) => {
        if (siteElem.id === payload.id) {
          siteElem.name = payload.name;
          siteElem.address = payload.address;
          siteElem.description = payload.description;
        }
      });
    }
  },

  /*
   ** edit site error
   */
  EDIT_SITE_ERROR(state: SiteState, error: AxiosError) {
    state.apiState.editSite = {
      ...defaultApiState,
      error,
    };
  },

  /*
   ** get site loading
   */
  GET_SITE_LOADING(state: SiteState) {
    state.apiState.getSite = {
      ...defaultApiState,
      loading: true,
    };
    state.sites = undefined;
  },

  /*
   ** get site success
   */
  GET_SITE_SUCCESS(state: SiteState, payload: SiteInfo) {
    state.apiState.getSite = {
      ...defaultApiState,
      success: true,
    };
    state.sites = payload;
  },

  /*
   ** get site success
   */
  GET_SITE_ERROR(state: SiteState, error: AxiosError) {
    state.apiState.getSite = {
      ...defaultApiState,
      error,
    };
  },
};
