















































import { Component, Vue, Prop, Watch, Model } from 'vue-property-decorator';
import { State, Getter, Action, Mutation, namespace } from 'vuex-class';

@Component({
  components: {},
})
export default class BaseDialog extends Vue {
  @Prop({ default: '' })
  public title!: string;

  @Prop({ default: '' })
  public content!: string;

  @Prop({ default: 'Remove' })
  public confirmButtonText!: string;

  @Prop({ default: true })
  public confirmButton!: boolean;

  @Prop({ default: 'Cancel' })
  public cancelButtonText!: string;

  @Prop({ default: true })
  public cancelButton!: boolean;

  @Prop({ default: true })
  public showCloseButton!: boolean;
}
