
































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import DefaultLayout from '../../layouts/DefaultLayout.vue';
import HeroBar from '../../components/base/HeroBar.vue';
import {
  AdministratorProjectListDTO,
  AdminProjectData,
  AdminProjectDTO,
  FilteredProjectPayload,
  GetProjectPayload,
  ProjectStatus,
} from '../../store/modules/projects/types/projects.types';
import { RootState } from '../../store/store';
import { isTruthy } from '../../jbi-shared/util/watcher.vue-decorator';
import ProjectFilter from '../../views/AdminProjectManagement/components/ProjectFilter.vue';
import PaginatedProjectList from '../../views/AdminProjectManagement/components/PaginatedProjectList.vue';
import { capitalizeFirstLetter } from '@/jbi-shared/util/user.utils';

@Component({
  components: {
    PaginatedProjectList,
    ProjectFilter,
    DefaultLayout,
    HeroBar,
  },
})
export default class AdminProjectManagement extends Vue {
  public page: number = 1;
  public perPage = 50;
  public filteredParams: FilteredProjectPayload = {};
  public projectDetailItems: AdminProjectDTO[] = [];
  public projectDetailTotalItems: number = 0;
  public showProjectLists: boolean = false;

  @Action('projects/getProjectListForAdmin')
  public getProjectListForAdmin!: (params: GetProjectPayload) => void;

  @State((state: RootState) => state.projects.administratorProjectList)
  public administratorProjectList!: AdministratorProjectListDTO[];

  public handleReset() {
    this.filteredParams = {
      projectName: '',
      projectStatus: '',
      administrator: '',
      administratorStatus: '',
      orderBy: {
        field: 'project',
        sort: 'DESC',
      },
    };
    this.$router
      .push({
        path: this.$route.path,
        query: {
          projectName: '',
          projectStatus: '',
          administrator: '',
          administratorStatus: '',
          orderByField: 'project',
          orderBySort: 'DESC',
          limit: this.perPage.toString(),
          page: this.page.toString(),
        },
      })
      .catch(() => {
        return;
      });
  }

  //   @Debounce(500)
  public handleFilter() {
    this.page = 1;
    this.handlePaginator();
  }

  public mounted() {
    this.filteredParams = {
      projectName: (this.$route.query.projectName as string) || '',
      projectStatus: (this.$route.query.projectStatus as string) || '',
      administrator: (this.$route.query.administrator as string) || '',
      administratorStatus:
        (this.$route.query.administratorStatus as string) || '',
      orderBy: {
        field: (this.$route.query.orderByField as string) || 'project',
        sort: (this.$route.query.orderBySort as string) || 'DESC',
      },
    };
    this.perPage = Number(this.$route.query.limit) || this.perPage;
    this.page = Number(this.$route.query.page) || this.page;
    this.showProjectLists = true;
  }

  public handleUpdateProject(value: boolean) {
    if (value) {
      this.handlePaginator();
    }
  }

  public handlePaginator() {
    let orderBy: any = {};
    let orderByField: string | undefined = '';
    let orderBySort: string | undefined = '';
    if (this.filteredParams.orderBy) {
      orderBy = {
        field: this.filteredParams.orderBy.field,
        sort: this.filteredParams.orderBy.sort,
      };
      orderByField = this.filteredParams.orderBy.field;
      orderBySort = this.filteredParams.orderBy.sort;
    }
    this.getProjectListForAdmin({
      projectName: this.filteredParams.projectName,
      projectStatus: this.filteredParams.projectStatus,
      administrator: this.filteredParams.administrator,
      administratorStatus: this.filteredParams.administratorStatus,
      orderBy,
      limit: this.perPage,
      page: this.page,
    });
    this.$router
      .push({
        path: this.$route.path,
        query: {
          projectName: this.filteredParams.projectName,
          projectStatus: this.filteredParams.projectStatus,
          administrator: this.filteredParams.administrator,
          administratorStatus: this.filteredParams.administratorStatus,
          orderByField,
          orderBySort,
          limit: this.perPage.toString(),
          page: this.page.toString(),
        },
      })
      .catch(() => {
        return;
      });
  }

  public getAdministratorName(item: AdminProjectData) {
    const givenName = capitalizeFirstLetter(item.firstName);
    const familyName = capitalizeFirstLetter(item.lastName);

    return {
      administratorName: `${givenName} ${familyName} (${item.email})`,
      given_name: givenName,
      family_name: familyName,
    };
  }

  @Watch('administratorProjectList')
  @isTruthy
  public async projectAdministratorDetailState(
    newValue: AdministratorProjectListDTO,
  ) {
    this.projectDetailItems = [];
    this.projectDetailTotalItems = newValue.totalItems;

    newValue.items.forEach((project: AdminProjectData) => {
      const administratorDetails = this.getAdministratorName(project);
      const disableChangeAdministration: boolean =
        +project.participant_count > 1 &&
        project.status === ProjectStatus.ACTIVE
          ? false
          : true;
      const disableProjectHistory: boolean =
        project.status === ProjectStatus.DRAFT ? true : false;

      this.projectDetailItems.push({
        ...project,
        ...administratorDetails,
        disableChangeAdministration,
        disableProjectHistory,
      });
    });
  }
}
