












import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import BasePaginatorHoc from '@/components/base/BasePaginatorHoc.vue';
import {FilteredUserPayload, Pagination, User} from '@/store/modules/users/types/users.types';
import { mixins } from 'vue-class-component';
import { PaginationMixin } from '@/components/base/pagination.mixin';
import UserList from '@/views/AdminUserManagement/components/UserList.vue';

@Component({
    components: {UserList, BasePaginatorHoc },
})
export default class PaginatedUserList extends mixins(PaginationMixin) {
    @Prop() public items!: User[];
    @Prop() public totalCount!: number;
    @Prop() public filteredParams!: FilteredUserPayload;

    get UserList() {
        return UserList;
    }
}
