export const DATE_TIME_FORMAT = `D MMM YYYY, hh:mm A`;
export const DATE_FORMAT = `D MMM YYYY`;
export const DATE_ALT_FORMAT = `d-MM-YYYY`;

export const getStartDateTime = (date?: any): Date => {
  date = date ? new Date(date) : new Date();
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

export const getEndDateTime = (date?: any): Date => {
  date = date ? new Date(date) : new Date();
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59);
  date.setMilliseconds(999);
  return date;
};
