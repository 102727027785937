



































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import DomainCategoryList from './DomainCategoryList.vue';
import { GripDomainCategory } from '../../../store/types/grip.types';
import DomainCategoryModal from './DomainCategoryModal.vue';
import { ApiState } from '../../../store/types/general.types';
import BaseLoading from '../../../components/base/BaseLoading.vue';

@Component({
  components: { BaseLoading, DomainCategoryList, DomainCategoryModal },
})
export default class DomainCategorySettings extends Vue {
  public isLoading: boolean = false;

  @Action('projects/getGripDomainCategories')
  public getGripDomainCategories!: (projectId: number) => void;

  @State((state) => state.projects.apiState.getGripDomainCategories)
  public getGripDomainCategoriesState!: ApiState;

  @State((state) => state.projects.gripDomainCategories)
  public gripDomainCategories!: GripDomainCategory[];

  get DomainCategoryList() {
    return DomainCategoryList;
  }

  get projectId(): number {
    return +this.$route.params.projectId;
  }

  get items(): GripDomainCategory[] {
    return this.gripDomainCategories ? this.gripDomainCategories : [];
  }

  public openDomainCategoryModal(): void {
    this.$buefy.modal.open({
      parent: this,
      props: {
        projectId: this.projectId,
        modalTitle: 'Add',
      },
      component: DomainCategoryModal,
      hasModalCard: true,
    });
  }

  public mounted(): void {
    this.getGripDomainCategories(this.projectId);
  }

  @Watch('getGripDomainCategoriesState')
  public watchGetGripDomainCategoryLoading(state: ApiState): void {
    if (state.loading) {
      this.isLoading = true;
    } else {
      this.isLoading = false;
    }
  }
}
