import {
  AuditReportAnalysisResponseDto,
  AuditSite,
} from '@/store/modules/audits/types/audits.types';
import { GenerateSingleSiteCsv } from '@/components/reports/export/csv/audit/SingleSiteCsv';
import { GenerateMultiSiteCsv } from '@/components/reports/export/csv/audit/MultiSiteCsv';
import { GenerateSingleSiteComparisonCsv } from '@/components/reports/export/csv/audit/SingleSiteComparisonCsv';
import { GenerateMultiSiteComparisonCsv } from '@/components/reports/export/csv/audit/MultiSiteComparisonCsv';
import dayjs from 'dayjs';
import XLSX from 'xlsx';
import JSZip from 'jszip';
import { ToastProgrammatic as Toast } from 'buefy';

export class AuditReportExportToCsv {
  public async create(
    reportAnalysisResponse: AuditReportAnalysisResponseDto[],
    auditTitle: string,
  ) {
    const zip = new JSZip();
    let exportedWorkbook;
    let exportedWorkSheet;
    const exportDateTime = dayjs().format('D MMM YYYY');
    const exportTitle = auditTitle + '_' + exportDateTime;
    const csvListArray: any[] = [];
    await Promise.all(
      reportAnalysisResponse.map(
        async (analysis: AuditReportAnalysisResponseDto, index: number) => {
          csvListArray[index] = await this.handleReportResponse(analysis);
        },
      ),
    );
    switch (reportAnalysisResponse.length) {
      case 1:
        exportedWorkbook = XLSX.utils.book_new();
        exportedWorkSheet = XLSX.utils.json_to_sheet(csvListArray[0], {
          skipHeader: true,
        });
        XLSX.utils.book_append_sheet(
          exportedWorkbook,
          exportedWorkSheet,
          reportAnalysisResponse[0].analysisTitle,
        );
        try {
          XLSX.writeFile(exportedWorkbook, exportTitle + '.csv');
        } catch (err) {
          Toast.open({
            message: `Something went wrong. Try again.`,
            position: 'is-top',
            type: 'is-danger',
            duration: 3500,
          });
        } finally {
          Toast.open({
            message: `Export complete.`,
            position: 'is-top',
            type: 'is-dark',
            duration: 3500,
          });
        }
        break;
      default:
        csvListArray.forEach((csvList, csvListIndex) => {
          exportedWorkbook = XLSX.utils.book_new();
          exportedWorkSheet = XLSX.utils.json_to_sheet(csvList, {
            skipHeader: true,
          });
          XLSX.utils.book_append_sheet(
            exportedWorkbook,
            exportedWorkSheet,
            reportAnalysisResponse[csvListIndex].analysisTitle,
          );
          const outputFile = XLSX.write(exportedWorkbook, {
            bookType: 'csv',
            type: 'binary',
          });
          zip.file(
            csvListIndex +
              1 +
              '-' +
              reportAnalysisResponse[csvListIndex].analysisTitle +
              '.csv',
            outputFile,
            {
              binary: true,
            },
          );
        });
        zip.generateAsync({ type: 'blob' }).then((content) => {
          try {
            saveAs(content, exportTitle + '.zip');
          } catch (err) {
            Toast.open({
              message: `Something went wrong. Try again.`,
              position: 'is-top',
              type: 'is-danger',
              duration: 3500,
            });
          } finally {
            Toast.open({
              message: `Export complete.`,
              position: 'is-top',
              type: 'is-dark',
              duration: 3500,
            });
          }
        });
    }
  }

  public async handleReportResponse(
    analysisResponse: AuditReportAnalysisResponseDto,
  ) {
    const {
      result,
      payload,
      analysisTitle,
      projectTitle,
      auditTitle,
      auditSites,
    } = analysisResponse;

    switch (payload.comparisonPeriods.length) {
      case 1:
        return await this.generateSinglePeriodCsv(
          result,
          payload,
          analysisTitle,
          projectTitle,
          auditTitle,
          auditSites,
        );
      default:
        return await this.generateMultiPeriodCsv(
          result,
          payload,
          analysisTitle,
          projectTitle,
          auditTitle,
          auditSites,
        );
    }
  }

  public async generateSinglePeriodCsv(
    result: any,
    payload: any,
    analysisTitle: string,
    projectTitle: string,
    auditTitle: string,
    auditSites: AuditSite[],
  ) {
    switch (auditSites.length) {
      case 1:
        const generateSingleSiteCsv = new GenerateSingleSiteCsv();
        return generateSingleSiteCsv.create(
          result,
          payload,
          analysisTitle,
          projectTitle,
          auditTitle,
          auditSites,
        );
      default:
        const generateMultiSiteCsv = new GenerateMultiSiteCsv();
        return generateMultiSiteCsv.create(
          result,
          payload,
          analysisTitle,
          projectTitle,
          auditTitle,
          auditSites,
        );
    }
  }

  public async generateMultiPeriodCsv(
    result: any,
    payload: any,
    analysisTitle: string,
    projectTitle: string,
    auditTitle: string,
    auditSites: AuditSite[],
  ) {
    switch (auditSites.length) {
      case 1:
        const generateSingleSiteComparisonCsv = new GenerateSingleSiteComparisonCsv();
        return generateSingleSiteComparisonCsv.create(
          result,
          payload,
          analysisTitle,
          projectTitle,
          auditTitle,
          auditSites,
        );
      default:
        const generateMultiSiteComparisonCsv = new GenerateMultiSiteComparisonCsv();
        return generateMultiSiteComparisonCsv.create(
          result,
          payload,
          analysisTitle,
          projectTitle,
          auditTitle,
          auditSites,
        );
    }
  }
}
