import { axios } from './axios';
import { Site, GetSitesPayload } from '@/store/modules/site/types/site.types';

export const siteApi = {
  async getSites(params: GetSitesPayload) {
    return await axios.get(`/site/all`, { params });
  },
  async addNewSite(newSite: Site) {
    return await axios.post(`/site/`, { ...newSite });
  },

  async deleteSite(id: number) {
    return await axios.delete(`/site/${id}`);
  },

  async editSite(updatedSite: Site) {
    return await axios.patch(`/site/edit/${updatedSite.id}`, {
      ...updatedSite,
    });
  },
};
