import {
  AuditSite,
  AuditCriteria,
  AuditReportAnalysisResponseDto,
} from '@/store/modules/audits/types/audits.types';
import { GenerateSingleSitePdf } from '@/components/reports/export/pdf/audit/SingleSitePdf';
import { GenerateMultiSitePdf } from '@/components/reports/export/pdf/audit/MultiSitePdf';
import { GenerateMultiSiteComparisonPdf } from '@/components/reports/export/pdf/audit/MultiSiteComparisonPdf';
import { GenerateSingleSiteComparisonPdf } from '@/components/reports/export/pdf/audit/SingleSiteComparisonPdf';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import dayjs from 'dayjs';
import { ToastProgrammatic as Toast } from 'buefy';

export class AuditReportExportToPdf {
  public async create(
    reportAnalysisResponse: AuditReportAnalysisResponseDto[],
    auditTitle: string,
  ) {
    const pdfListArray: any[] = [];
    (pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
    await Promise.all(
      reportAnalysisResponse.map(
        async (analysis: AuditReportAnalysisResponseDto, index: number) => {
          pdfListArray[index] = await this.handleReportResponse(analysis);
        },
      ),
    );
    if (reportAnalysisResponse.length === 1) {
      const fileName =
        reportAnalysisResponse[0].analysisTitle +
        '_' +
        dayjs().format('DDMMMYYYY') +
        '.pdf';
      try {
        pdfMake.createPdf(pdfListArray[0] as any).download(fileName);
      } catch (err) {
        Toast.open({
          message: `Something went wrong. Try again.`,
          position: 'is-top',
          type: 'is-danger',
          duration: 3500,
        });
      } finally {
        Toast.open({
          message: `Export complete.`,
          position: 'is-top',
          type: 'is-dark',
          duration: 3500,
        });
      }
    } else {
      const fileName = auditTitle + '_' + dayjs().format('DDMMMYYYY') + '.pdf';
      const resultArray = pdfListArray[0];
      if (resultArray.content === undefined) {
        resultArray.content = [];
      }
      pdfListArray.forEach((pdfData, index) => {
        if (index !== 0 && pdfData.content && pdfData.content.length > 0) {
          const pdfContentArray = pdfData.content;
          pdfContentArray[0].pageBreak = 'before';
          resultArray.content.push(pdfContentArray);
        }
      });
      try {
        pdfMake.createPdf(resultArray as any).download(fileName);
      } catch (err) {
        Toast.open({
          message: `Something went wrong. Try again.`,
          position: 'is-top',
          type: 'is-danger',
          duration: 3500,
        });
      } finally {
        Toast.open({
          message: `Export complete.`,
          position: 'is-top',
          type: 'is-dark',
          duration: 3500,
        });
      }
    }
  }

  public async handleReportResponse(
    analysisResponse: AuditReportAnalysisResponseDto,
  ) {
    const {
      result,
      payload,
      analysisTitle,
      jbiLogo,
      projectTitle,
      auditTitle,
      auditSites,
      auditCriteria,
    } = analysisResponse;

    switch (payload.comparisonPeriods.length) {
      case 1:
        return await this.generateSinglePeriodPdf(
          result,
          payload,
          analysisTitle,
          jbiLogo,
          projectTitle,
          auditTitle,
          auditSites,
          auditCriteria,
        );
      default:
        return await this.generateMultiPeriodPdf(
          result,
          payload,
          analysisTitle,
          jbiLogo,
          projectTitle,
          auditTitle,
          auditSites,
          auditCriteria,
        );
    }
  }

  public async generateSinglePeriodPdf(
    result: any,
    payload: any,
    analysisTitle: string,
    jbiLogo: string,
    projectTitle: string,
    auditTitle: string,
    auditSites: AuditSite[],
    auditCriteria: AuditCriteria[],
  ) {
    switch (auditSites.length) {
      case 1:
        const generateSingleSitePdf = new GenerateSingleSitePdf();
        return generateSingleSitePdf.create(
          result,
          payload,
          analysisTitle,
          jbiLogo,
          projectTitle,
          auditTitle,
          auditSites,
          auditCriteria,
        );
      default:
        const generateMultiSitePdf = new GenerateMultiSitePdf();
        return generateMultiSitePdf.create(
          result,
          payload,
          analysisTitle,
          jbiLogo,
          projectTitle,
          auditTitle,
          auditSites,
          auditCriteria,
        );
    }
  }

  public async generateMultiPeriodPdf(
    result: any,
    payload: any,
    analysisTitle: string,
    jbiLogo: string,
    projectTitle: string,
    auditTitle: string,
    auditSites: AuditSite[],
    auditCriteria: AuditCriteria[],
  ) {
    switch (auditSites.length) {
      case 1:
        const generateSingleSiteComparisonPdf = new GenerateSingleSiteComparisonPdf();
        return generateSingleSiteComparisonPdf.create(
          result,
          payload,
          analysisTitle,
          jbiLogo,
          projectTitle,
          auditTitle,
          auditSites,
          auditCriteria,
        );
      default:
        const generateMultiSiteComparisonPdf = new GenerateMultiSiteComparisonPdf();
        return generateMultiSiteComparisonPdf.create(
          result,
          payload,
          analysisTitle,
          jbiLogo,
          projectTitle,
          auditTitle,
          auditSites,
          auditCriteria,
        );
    }
  }
}
