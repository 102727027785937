

































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Site } from '@/store/modules/site/types/site.types';
import { Action } from 'vuex-class';
import { cloneDeep as _cloneDeep } from 'lodash';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class SiteModal extends Vue {
  @Prop()
  public modalTitle!: string;

  @Prop()
  public task!: string;

  @Prop()
  public siteDetail!: Site;

  public dirtySite: Partial<Site> = {
    id: 0,
    name: '',
    address: '',
    description: '',
    isIdentifierRequired: false,
  };

  public updateSite() {
    this.$emit('editSelectedSite', this.dirtySite);
    this.$emit('close');
  }

  public addSite() {
    this.$emit('addNewSite', this.dirtySite);
    this.$emit('close');
  }

  public closeModal() {
    this.$emit('close');
  }

  public mounted() {
    this.dirtySite = _cloneDeep(this.siteDetail);
  }
}
