







































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import BaseLoading from '../../../components/base/BaseLoading.vue';
import { Debounce } from '../../../jbi-shared/util/debounce.vue-decorator';
import { ApiState, Pagination } from '../../../store/types/general.types';
import {
  CreateGripBarrierBase,
  GetBarriersPayload,
  GripBarrier,
} from '../../../store/types/grip.types';

@Component({
  components: { BaseLoading },
})
export default class ExistingGripBarrierList extends Vue {
  @Prop() public projectId!: number;
  public isLoading: boolean = true;
  public searchTitle: string = '';
  public perPage: number = 100;
  public page: number = 1;
  public barrierList: GripBarrier[] = [];
  public barrierInfo: string =
    'Identify issues or barriers in achieving the best practice.';

  @Action('projects/getGripBarriers')
  public getGripBarriers!: (payload: {
    projectId: number;
    params: GetBarriersPayload;
  }) => void;

  @State((state) => state.projects.gripBarriers)
  public gripBarriers!: Pagination<GripBarrier>;

  @State((state) => state.projects.apiState.getGripBarriers)
  public getGripBarriersApiState!: ApiState;

  public getUpperCase(value: string): string {
    return value.toUpperCase();
  }

  public createAnalysisBarrierItem(
    barrier: GripBarrier,
  ): CreateGripBarrierBase {
    return {
      title: barrier.title,
      description: barrier.description,
      action: barrier.action,
      reason: barrier.reason,
      links: barrier.links,
      attachments: barrier.attachments,
      gripDomainCategoryId: barrier.gripDomainCategory
        ? barrier.gripDomainCategory.id
        : null,
    };
  }

  public onDragStart(event: any, barrier: GripBarrier): void {
    const newItem = this.createAnalysisBarrierItem(barrier);
    const jsonData = JSON.stringify({ ...newItem, itemType: 'barrier' });
    event.dataTransfer.setData('application/json', jsonData);
  }

  public getBarriers(): void {
    this.isLoading = true;
    this.getGripBarriers({
      projectId: this.projectId,
      params: {
        title: this.searchTitle,
        limit: this.perPage,
        page: this.page,
      },
    });
  }

  public collapseSideNav(): void {
    this.$emit('collapseSideNav');
  }

  public mounted(): void {
    this.getBarriers();
  }

  @Watch('searchTitle')
  @Debounce(500)
  public onTitleSearch(): void {
    this.getBarriers();
  }

  @Watch('getGripBarriersApiState.loading')
  public watchGetGripBarriersLoading(): void {
    this.isLoading = true;
  }

  @Watch('getGripBarriersApiState.success')
  public watchGetGripBarriersSuccess(): void {
    this.isLoading = false;
    this.barrierList =
      this.gripBarriers && this.gripBarriers.items
        ? this.gripBarriers.items
        : [];
  }
}
