import {
  ChartType,
  ProjectSite,
  ProjectCriteria,
  ReportSamplingDto,
} from '@/store/modules/projects/types/projects.types';
import { chartColorSet, labelSplitIndex } from '@/store/types/general.types';
import { cloneDeep as _cloneDeep, get as _get, map as _map } from 'lodash';
import { CRITERION_TYPE } from '@/store/types/criterions.types';
import Chart from 'chart.js';
import dayjs from 'dayjs';
import {
  Document,
  Footer,
  Header,
  HeadingLevel,
  IRunOptions,
  Media,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  TextRun,
  WidthType,
} from 'docx';
import { IOpAttributes } from 'quill-delta-to-html/dist/commonjs/OpAttributeSanitizer';
import {
  getAdHocSampling,
  getConsecutiveMinMaxSampling,
  getDefaultSampling,
} from '@/components/reports/utils/ReportSampling.util';
import { SamplingDataConfiguration } from '@/store/modules/audits/types/audits.types';

export class GenerateMultiSiteComparisonDocx {
  public docxFontSize: any = {
    default: 18,
    small: 14,
    big: 28,
    huge: 40,
  };
  public docxColor = {
    primary: '#1a538d',
    danger: 'red',
    warning: 'yellow',
  };
  public newLine: Paragraph = new Paragraph({
    text: '',
    heading: HeadingLevel.HEADING_1,
    thematicBreak: true,
  });

  public emptyLine: Paragraph = new Paragraph('');
  public dirtyMonths: string[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];

  public async create(
    document: Document,
    result: any,
    payload: any,
    analysisTitle: string,
    jbiLogo: string,
    projectTitle: string,
    projectSites: ProjectSite[],
    projectCriteria: ProjectCriteria[],
  ) {
    return {
      headers: {
        default: new Header({
          children: [await this.getHeader(document), this.emptyLine],
        }),
      },
      children: [
        ...this.getTitleSection(projectTitle),
        ...this.getSubTitleSection(analysisTitle),
        ...this.getSiteSection(projectSites, payload),
        ...this.getDataCollectionPeriodCriteriaSection(
          projectCriteria,
          payload,
        ),
        ...this.selectedPeriodSection(
          document,
          result,
          payload,
          jbiLogo,
          projectTitle,
          projectSites,
          projectCriteria,
          analysisTitle,
        ),
      ],
      footers: {
        default: new Footer({
          children: [await this.getFooter(), this.emptyLine],
        }),
      },
    };
  }

  public getTitleSection(projectTitle: string): Paragraph[] {
    return [
      new Paragraph({
        children: [
          this.createTextRun({
            text: projectTitle.replace(/\s+/g, ' ').toUpperCase(),
            bold: true,
            size: this.docxFontSize.huge,
            color: '#00205B',
          }),
        ],
      }),
      this.emptyLine,
    ];
  }

  public getSubTitleSection(auditTitle: string): Paragraph[] {
    return [
      new Paragraph({
        children: [
          this.createTextRun({
            text: auditTitle.replace(/\s+/g, ' '),
            bold: true,
            size: this.docxFontSize.big,
            color: 'black',
          }),
        ],
      }),
      this.emptyLine,
    ];
  }

  public getSiteSection(
    projectSites: ProjectSite[],
    payload: any,
  ): Paragraph[] {
    const siteTitleParagraph = new Paragraph({
      children: [
        this.createTextRun({
          text: 'Site(s)',
          bold: true,
          size: this.docxFontSize.default,
          color: '#1D70B8',
        }),
      ],
    });

    const siteListParagraph: Paragraph[] = [];
    const siteNames = this.getSiteList(projectSites, payload);
    siteNames.forEach((siteName) => {
      siteListParagraph.push(this.createBullet(siteName));
    });

    return [siteTitleParagraph, ...siteListParagraph, this.emptyLine];
  }

  public getSiteList(projectSites: ProjectSite[], payload: any): string[] {
    const siteNames: string[] = [];
    payload.filteredSites.forEach((auditSiteMapId: number) => {
      const auditSite: any = projectSites.find((projectSiteDetail) => {
        return projectSiteDetail.id === auditSiteMapId;
      });
      siteNames.push(auditSite.site.name);
    });
    return siteNames;
  }

  public getDataCollectionPeriodCriteriaSection(
    projectCriteria: ProjectCriteria[],
    payload: any,
  ) {
    const criteriaTitleParagraph = new Paragraph({
      children: [
        this.createTextRun({
          text: 'Data Collection Period Criteria',
          bold: true,
          size: this.docxFontSize.default,
          color: '#1D70B8',
        }),
      ],
    });

    const criteriaListParagraph: Paragraph[] = this.getSelectedCriteria(
      projectCriteria,
      payload,
    );

    return [criteriaTitleParagraph, ...criteriaListParagraph, this.emptyLine];
  }

  public getSelectedCriteria(
    projectCriteria: ProjectCriteria[],
    payload: any,
  ): Paragraph[] {
    const criteriaNames: Paragraph[] = [];
    payload.filteredCriterias.forEach((auditCriteriaId: number) => {
      const criteria: any = projectCriteria.find((projectCriteriaDetail) => {
        return projectCriteriaDetail.id === auditCriteriaId;
      });
      criteriaNames.push(this.createBullet(criteria.title));
    });
    return criteriaNames;
  }

  public selectedPeriodSection(
    doc: Document,
    result: any,
    payload: any,
    jbiLogo: string,
    projectTitle: string,
    projectSites: ProjectSite[],
    projectCriteria: ProjectCriteria[],
    analysisTitle: string,
  ): Paragraph[] {
    const paragraphList: any[] = [];
    if (payload.checkIndividualSite) {
      if (payload.checkIndividualCriteria) {
        const {
          allCharts,
          renderBooleanTables,
          renderBooleanSamplingTables,
          renderMixedTables,
          renderMixedSamplingTables,
        } = this.generateComparisonIndividualSiteIndividualCriteria(
          result,
          payload,
          projectSites,
          projectCriteria,
        );

        allCharts.forEach((individualChartStackData, individualChartIndex) => {
          const individualChart = individualChartStackData.stack;
          paragraphList.push(
            this.emptyLine,
            new Paragraph({
              children: [
                this.createTextRun({
                  text: individualChart[0].text,
                  bold: true,
                  size: this.docxFontSize.default,
                  color: individualChart[0].color,
                }),
              ],
            }),
            this.emptyLine,
          );
          const image = Media.addImage(
            doc,
            Uint8Array.from(atob(individualChart[1].image), (c) =>
              c.charCodeAt(0),
            ),
            600,
            individualChart[1].height,
          );
          paragraphList.push(new Paragraph(image), this.emptyLine);

          const booleanTableData = renderBooleanTables[individualChartIndex];
          booleanTableData.forEach((renderTable: any) => {
            const rows: TableRow[] = [];
            if (
              renderTable.stack[0] &&
              renderTable.stack[0].table &&
              renderTable.stack[0].table.body
            ) {
              renderTable.stack[0].table.body.forEach((rowData: any) => {
                const cells: TableCell[] = [];
                rowData.forEach((cellData: any, index: number) => {
                  let cellText: any;
                  if (
                    cellData.text !== undefined &&
                    Array.isArray(cellData.text)
                  ) {
                    cellText = cellData.text.join(': ');
                  } else {
                    cellText = this.getTableValueText(cellData);
                  }
                  cells.push(
                    new TableCell({
                      width: {
                        size: index === 0 ? 70 : 30,
                        type: WidthType.PERCENTAGE,
                      },
                      children: [
                        new Paragraph({
                          children: [
                            this.createTextRun({
                              text: cellText,
                              bold: !!cellData.bold,
                              size: this.docxFontSize.default,
                              color: cellData.color,
                            }),
                          ],
                        }),
                      ],
                    }),
                  );
                });
                rows.push(
                  new TableRow({
                    children: cells,
                  }),
                );
              });
            }
            paragraphList.push(
              new Table({
                rows,
              }),
              this.emptyLine,
            );
          });
          renderBooleanSamplingTables[individualChartIndex].forEach(
            (booleanSamplingTable: any, booleanSamplingTableIndex: number) => {
              const booleanSamplingRows: TableRow[] = [];
              const renderSamplingTableDetail =
                booleanSamplingTableIndex === 0
                  ? booleanSamplingTable.stack[1]
                  : booleanSamplingTable.stack[0];
              if (
                renderSamplingTableDetail &&
                renderSamplingTableDetail.table &&
                renderSamplingTableDetail.table.body
              ) {
                renderSamplingTableDetail.table.body.forEach((rowData: any) => {
                  const cells: TableCell[] = [];
                  rowData.forEach((cellData: any, index: number) => {
                    let cellText: any;
                    if (
                      cellData.text !== undefined &&
                      Array.isArray(cellData.text)
                    ) {
                      cellText = cellData.text.join(': ');
                    } else {
                      cellText = this.getTableValueText(cellData);
                    }
                    cells.push(
                      new TableCell({
                        width: {
                          size: index === 0 ? 70 : 30,
                          type: WidthType.PERCENTAGE,
                        },
                        children: [
                          new Paragraph({
                            children: [
                              this.createTextRun({
                                text: cellText,
                                bold: !!cellData.bold,
                                size: this.docxFontSize.default,
                                color: cellData.color,
                              }),
                            ],
                          }),
                        ],
                      }),
                    );
                  });
                  booleanSamplingRows.push(
                    new TableRow({
                      children: cells,
                    }),
                  );
                });
                paragraphList.push(
                  new Table({
                    rows: booleanSamplingRows,
                  }),
                  this.emptyLine,
                );
              }
            },
          );
          const renderBooleanSamplingTable =
            renderBooleanSamplingTables[individualChartIndex][0];
          paragraphList.push(
            new Paragraph({
              children: [
                this.createTextRun({
                  text: renderBooleanSamplingTable.stack[0].text,
                  bold: true,
                  size: this.docxFontSize.default,
                  color: renderBooleanSamplingTable.stack[0].color,
                }),
              ],
            }),
            this.emptyLine,
          );
        });
        renderMixedTables.forEach(
          (renderTableData: any, renderTableDataIndex: number) => {
            paragraphList.push(
              this.emptyLine,
              new Paragraph({
                children: [
                  this.createTextRun({
                    text: renderTableData[0].stack[0].text,
                    bold: true,
                    size: this.docxFontSize.default,
                    color: renderTableData[0].stack[0].color,
                  }),
                ],
              }),
              this.emptyLine,
            );
            renderTableData.forEach(
              (tableData: any, tableDataIndex: number) => {
                const rows: TableRow[] = [];
                let tableInfo = tableData.stack[1];
                if (tableDataIndex !== 0) {
                  tableInfo = tableData.stack[0];
                }
                tableInfo.table.body.forEach((rowData: any) => {
                  const cells: TableCell[] = [];
                  rowData.forEach((cellData: any, index: number) => {
                    cells.push(
                      new TableCell({
                        width: {
                          size: index === 0 ? 70 : 30,
                          type: WidthType.PERCENTAGE,
                        },
                        children: [
                          new Paragraph({
                            children: [
                              this.createTextRun({
                                text: this.getTableValueText(cellData),
                                bold: !!cellData.bold,
                                size: this.docxFontSize.default,
                                color: cellData.color,
                              }),
                            ],
                          }),
                        ],
                      }),
                    );
                  });
                  rows.push(
                    new TableRow({
                      children: cells,
                    }),
                  );
                });
                paragraphList.push(
                  new Table({
                    rows,
                  }),
                  this.emptyLine,
                );
              },
            );
            renderMixedSamplingTables[renderTableDataIndex].forEach(
              (mixedSamplingTable: any, mixedSamplingTableIndex: number) => {
                const mixedSamplingRows: TableRow[] = [];
                const renderMixedSamplingTableDetail =
                  mixedSamplingTableIndex === 0
                    ? mixedSamplingTable.stack[1]
                    : mixedSamplingTable.stack[0];
                if (
                  renderMixedSamplingTableDetail &&
                  renderMixedSamplingTableDetail.table &&
                  renderMixedSamplingTableDetail.table.body
                ) {
                  renderMixedSamplingTableDetail.table.body.forEach(
                    (rowData: any) => {
                      const cells: TableCell[] = [];
                      rowData.forEach((cellData: any, index: number) => {
                        cells.push(
                          new TableCell({
                            width: {
                              size: index === 0 ? 70 : 30,
                              type: WidthType.PERCENTAGE,
                            },
                            children: [
                              new Paragraph({
                                children: [
                                  this.createTextRun({
                                    text: this.getTableValueText(cellData),
                                    bold: !!cellData.bold,
                                    size: this.docxFontSize.default,
                                    color: cellData.color,
                                  }),
                                ],
                              }),
                            ],
                          }),
                        );
                      });
                      mixedSamplingRows.push(
                        new TableRow({
                          children: cells,
                        }),
                      );
                    },
                  );
                  paragraphList.push(
                    new Table({
                      rows: mixedSamplingRows,
                    }),
                    this.emptyLine,
                  );
                }
              },
            );
            const renderMixedSamplingTable =
              renderMixedSamplingTables[renderTableDataIndex];
            paragraphList.push(
              new Paragraph({
                children: [
                  this.createTextRun({
                    text: renderMixedSamplingTable[0].stack[0].text,
                    bold: true,
                    size: this.docxFontSize.default,
                    color: renderMixedSamplingTable[0].stack[0].color,
                  }),
                ],
              }),
              this.emptyLine,
            );
          },
        );
      } else {
        const {
          aggregateChart,
          renderTables,
          renderSamplingTables,
        } = this.generateComparisonIndividualSiteAggregateCriteria(
          result,
          payload,
          projectSites,
        );
        if (result.comparisonResult[0].chartData.length > 0) {
          aggregateChart[0].stack.forEach((stackObject: any) => {
            if (stackObject.image && stackObject.image !== '') {
              const image = Media.addImage(
                doc,
                Uint8Array.from(atob(stackObject.image), (c) =>
                  c.charCodeAt(0),
                ),
                600,
                aggregateChart[0].stack[0].height,
              );
              paragraphList.push(new Paragraph(image), this.emptyLine);
            }
          });
        }
        renderTables.forEach(
          (renderTableData: any, renderTableDataIndex: number) => {
            paragraphList.push(
              this.emptyLine,
              new Paragraph({
                children: [
                  this.createTextRun({
                    text: renderTableData[0].stack[0].text,
                    bold: true,
                    size: this.docxFontSize.default,
                    color: renderTableData[0].stack[0].color,
                  }),
                ],
              }),
              this.emptyLine,
            );
            renderTableData.forEach(
              (tableData: any, tableDataIndex: number) => {
                const rows: TableRow[] = [];
                let dirtyTable = tableData.stack[1];
                if (tableDataIndex !== 0) {
                  dirtyTable = tableData.stack[0];
                }
                dirtyTable.table.body.forEach((rowData: any) => {
                  const cells: TableCell[] = [];
                  rowData.forEach((cellData: any, index: number) => {
                    let cellText: any;
                    if (
                      cellData.text !== undefined &&
                      Array.isArray(cellData.text)
                    ) {
                      cellText = cellData.text.join(': ');
                    } else {
                      cellText = this.getTableValueText(cellData);
                    }
                    cells.push(
                      new TableCell({
                        width: {
                          size: index === 0 ? 70 : 30,
                          type: WidthType.PERCENTAGE,
                        },
                        children: [
                          new Paragraph({
                            children: [
                              this.createTextRun({
                                text: cellText,
                                bold: !!cellData.bold,
                                size: this.docxFontSize.default,
                                color: cellData.color,
                              }),
                            ],
                          }),
                        ],
                      }),
                    );
                  });
                  rows.push(
                    new TableRow({
                      children: cells,
                    }),
                  );
                });
                paragraphList.push(
                  new Table({
                    rows,
                  }),
                  this.emptyLine,
                );
              },
            );
            renderSamplingTables[renderTableDataIndex].forEach(
              (samplingTable: any, samplingTableIndex: number) => {
                const samplingRows: TableRow[] = [];
                const renderSamplingTableDetail =
                  samplingTableIndex === 0
                    ? samplingTable.stack[1]
                    : samplingTable.stack[0];
                if (
                  renderSamplingTableDetail &&
                  renderSamplingTableDetail.table &&
                  renderSamplingTableDetail.table.body
                ) {
                  renderSamplingTableDetail.table.body.forEach(
                    (rowData: any) => {
                      const cells: TableCell[] = [];
                      rowData.forEach((cellData: any, index: number) => {
                        let cellText: any;
                        if (
                          cellData.text !== undefined &&
                          Array.isArray(cellData.text)
                        ) {
                          cellText = cellData.text.join(': ');
                        } else {
                          cellText = this.getTableValueText(cellData);
                        }
                        cells.push(
                          new TableCell({
                            width: {
                              size: index === 0 ? 70 : 30,
                              type: WidthType.PERCENTAGE,
                            },
                            children: [
                              new Paragraph({
                                children: [
                                  this.createTextRun({
                                    text: cellText,
                                    bold: !!cellData.bold,
                                    size: this.docxFontSize.default,
                                    color: cellData.color,
                                  }),
                                ],
                              }),
                            ],
                          }),
                        );
                      });
                      samplingRows.push(
                        new TableRow({
                          children: cells,
                        }),
                      );
                    },
                  );
                  paragraphList.push(
                    new Table({
                      rows: samplingRows,
                    }),
                    this.emptyLine,
                  );
                }
              },
            );
            const renderSamplingTable =
              renderSamplingTables[renderTableDataIndex][0];
            paragraphList.push(
              new Paragraph({
                children: [
                  this.createTextRun({
                    text: renderSamplingTable.stack[0].text,
                    bold: true,
                    size: this.docxFontSize.default,
                    color: renderSamplingTable.stack[0].color,
                  }),
                ],
              }),
              this.emptyLine,
            );
          },
        );
      }
    } else {
      if (payload.checkIndividualCriteria) {
        const {
          allCharts,
          renderBooleanTables,
          renderBooleanSamplingTables,
          renderMixedTables,
          renderMixedSamplingTables,
        } = this.generateComparisonAggregateSiteIndividualCriteria(
          result,
          payload,
          projectSites,
          projectCriteria,
        );
        allCharts.forEach((individualChartStackData, individualChartIndex) => {
          const individualChart = individualChartStackData.stack;
          paragraphList.push(
            this.emptyLine,
            new Paragraph({
              children: [
                this.createTextRun({
                  text: individualChart[0].text,
                  bold: true,
                  size: this.docxFontSize.default,
                  color: individualChart[0].color,
                }),
              ],
            }),
            this.emptyLine,
          );
          const image = Media.addImage(
            doc,
            Uint8Array.from(atob(individualChart[1].image), (c) =>
              c.charCodeAt(0),
            ),
            600,
            individualChart[1].height,
          );
          paragraphList.push(new Paragraph(image), this.emptyLine);

          const booleanTableData = renderBooleanTables[individualChartIndex];
          booleanTableData.forEach((renderTable: any) => {
            const rows: TableRow[] = [];
            if (
              renderTable.stack[0] &&
              renderTable.stack[0].table &&
              renderTable.stack[0].table.body
            ) {
              renderTable.stack[0].table.body.forEach((rowData: any) => {
                const cells: TableCell[] = [];
                rowData.forEach((cellData: any, index: number) => {
                  let cellText: any;
                  if (
                    cellData.text !== undefined &&
                    Array.isArray(cellData.text)
                  ) {
                    cellText = cellData.text.join(': ');
                  } else {
                    cellText = this.getTableValueText(cellData);
                  }
                  cells.push(
                    new TableCell({
                      width: {
                        size: index === 0 ? 70 : 30,
                        type: WidthType.PERCENTAGE,
                      },
                      children: [
                        new Paragraph({
                          children: [
                            this.createTextRun({
                              text: cellText,
                              bold: !!cellData.bold,
                              size: this.docxFontSize.default,
                              color: cellData.color,
                            }),
                          ],
                        }),
                      ],
                    }),
                  );
                });
                rows.push(
                  new TableRow({
                    children: cells,
                  }),
                );
              });
            }
            paragraphList.push(
              new Table({
                rows,
              }),
              this.emptyLine,
            );
          });
          renderBooleanSamplingTables[individualChartIndex].forEach(
            (booleanSamplingTable: any, booleanSamplingTableIndex: number) => {
              const booleanSamplingRows: TableRow[] = [];
              const renderBooleanSamplingTableDetail =
                booleanSamplingTableIndex === 0
                  ? booleanSamplingTable.stack[1]
                  : booleanSamplingTable.stack[0];
              if (
                renderBooleanSamplingTableDetail &&
                renderBooleanSamplingTableDetail.table &&
                renderBooleanSamplingTableDetail.table.body
              ) {
                renderBooleanSamplingTableDetail.table.body.forEach(
                  (rowData: any) => {
                    const cells: TableCell[] = [];
                    rowData.forEach((cellData: any, index: number) => {
                      let cellText: any;
                      if (
                        cellData.text !== undefined &&
                        Array.isArray(cellData.text)
                      ) {
                        cellText = cellData.text.join(': ');
                      } else {
                        cellText = this.getTableValueText(cellData);
                      }
                      cells.push(
                        new TableCell({
                          width: {
                            size: index === 0 ? 70 : 30,
                            type: WidthType.PERCENTAGE,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                this.createTextRun({
                                  text: cellText,
                                  bold: !!cellData.bold,
                                  size: this.docxFontSize.default,
                                  color: cellData.color,
                                }),
                              ],
                            }),
                          ],
                        }),
                      );
                    });
                    booleanSamplingRows.push(
                      new TableRow({
                        children: cells,
                      }),
                    );
                  },
                );
                paragraphList.push(
                  new Table({
                    rows: booleanSamplingRows,
                  }),
                  this.emptyLine,
                );
              }
            },
          );
          const renderBooleanSamplingTable =
            renderBooleanSamplingTables[individualChartIndex][0];
          paragraphList.push(
            new Paragraph({
              children: [
                this.createTextRun({
                  text: renderBooleanSamplingTable.stack[0].text,
                  bold: true,
                  size: this.docxFontSize.default,
                  color: renderBooleanSamplingTable.stack[0].color,
                }),
              ],
            }),
            this.emptyLine,
          );
        });
        renderMixedTables.forEach(
          (renderTableData: any, renderTableDataIndex: number) => {
            const renderMixedTable = renderTableData;
            paragraphList.push(
              this.emptyLine,
              new Paragraph({
                children: [
                  this.createTextRun({
                    text: renderTableData[0].stack[0].text,
                    bold: true,
                    size: this.docxFontSize.default,
                    color: renderTableData[0].stack[0].color,
                  }),
                ],
              }),
              this.emptyLine,
            );
            renderTableData.forEach(
              (tableData: any, tableDataIndex: number) => {
                const rows: TableRow[] = [];
                let tableInfo = tableData.stack[1];
                if (tableDataIndex !== 0) {
                  tableInfo = tableData.stack[0];
                }
                tableInfo.table.body.forEach((rowData: any) => {
                  const cells: TableCell[] = [];
                  rowData.forEach((cellData: any, index: number) => {
                    cells.push(
                      new TableCell({
                        width: {
                          size: index === 0 ? 70 : 30,
                          type: WidthType.PERCENTAGE,
                        },
                        children: [
                          new Paragraph({
                            children: [
                              this.createTextRun({
                                text: this.getTableValueText(cellData),
                                bold: !!cellData.bold,
                                size: this.docxFontSize.default,
                                color: cellData.color,
                              }),
                            ],
                          }),
                        ],
                      }),
                    );
                  });
                  rows.push(
                    new TableRow({
                      children: cells,
                    }),
                  );
                });
                paragraphList.push(
                  new Table({
                    rows,
                  }),
                  this.emptyLine,
                );
              },
            );
            renderMixedSamplingTables[renderTableDataIndex].forEach(
              (mixedSamplingTable: any, mixedSamplingTableIndex: number) => {
                const mixedSamplingRows: TableRow[] = [];
                const renderMixedSamplingTableDetail =
                  mixedSamplingTableIndex === 0
                    ? mixedSamplingTable.stack[1]
                    : mixedSamplingTable.stack[0];
                if (
                  renderMixedSamplingTableDetail &&
                  renderMixedSamplingTableDetail.table &&
                  renderMixedSamplingTableDetail.table.body
                ) {
                  renderMixedSamplingTableDetail.table.body.forEach(
                    (rowData: any) => {
                      const cells: TableCell[] = [];
                      rowData.forEach((cellData: any, index: number) => {
                        cells.push(
                          new TableCell({
                            width: {
                              size: index === 0 ? 70 : 30,
                              type: WidthType.PERCENTAGE,
                            },
                            children: [
                              new Paragraph({
                                children: [
                                  this.createTextRun({
                                    text: this.getTableValueText(cellData),
                                    bold: !!cellData.bold,
                                    size: this.docxFontSize.default,
                                    color: cellData.color,
                                  }),
                                ],
                              }),
                            ],
                          }),
                        );
                      });
                      mixedSamplingRows.push(
                        new TableRow({
                          children: cells,
                        }),
                      );
                    },
                  );
                  paragraphList.push(
                    new Table({
                      rows: mixedSamplingRows,
                    }),
                    this.emptyLine,
                  );
                }
              },
            );
            const renderMixedSamplingTable =
              renderMixedSamplingTables[renderTableDataIndex];
            paragraphList.push(
              new Paragraph({
                children: [
                  this.createTextRun({
                    text: renderMixedSamplingTable[0].stack[0].text,
                    bold: true,
                    size: this.docxFontSize.default,
                    color: renderMixedSamplingTable[0].stack[0].color,
                  }),
                ],
              }),
              this.emptyLine,
            );
          },
        );
      } else {
        const {
          aggregateChart,
          renderTables,
          renderSamplingTables,
        } = this.generateComparisonAgregateSiteAggregateCriteria(
          result,
          payload,
          projectSites,
        );
        if (result.comparisonResult[0].chartData.length > 0) {
          aggregateChart[0].stack.forEach((stackObject: any) => {
            if (stackObject.image && stackObject.image !== '') {
              const image = Media.addImage(
                doc,
                Uint8Array.from(atob(stackObject.image), (c) =>
                  c.charCodeAt(0),
                ),
                600,
                aggregateChart[0].stack[0].height,
              );
              paragraphList.push(new Paragraph(image), this.emptyLine);
            }
          });
        }
        renderTables.forEach(
          (renderTableData: any, renderTableDataIndex: number) => {
            const renderTable = renderTableData[0];
            paragraphList.push(
              this.emptyLine,
              new Paragraph({
                children: [
                  this.createTextRun({
                    text: renderTable.stack[0].text,
                    bold: true,
                    size: this.docxFontSize.default,
                    color: renderTable.stack[0].color,
                  }),
                ],
              }),
              this.emptyLine,
            );
            renderTableData.forEach(
              (tableData: any, tableDataIndex: number) => {
                const rows: TableRow[] = [];
                let tableInfo = tableData.stack[1];
                if (tableDataIndex !== 0) {
                  tableInfo = tableData.stack[0];
                }
                tableInfo.table.body.forEach((rowData: any) => {
                  const cells: TableCell[] = [];
                  rowData.forEach((cellData: any, index: number) => {
                    let cellText: any;
                    if (
                      cellData.text !== undefined &&
                      Array.isArray(cellData.text)
                    ) {
                      cellText = cellData.text.join(': ');
                    } else {
                      cellText = this.getTableValueText(cellData);
                    }
                    cells.push(
                      new TableCell({
                        width: {
                          size: index === 0 ? 70 : 30,
                          type: WidthType.PERCENTAGE,
                        },
                        children: [
                          new Paragraph({
                            children: [
                              this.createTextRun({
                                text: cellText,
                                bold: !!cellData.bold,
                                size: this.docxFontSize.default,
                                color: cellData.color,
                              }),
                            ],
                          }),
                        ],
                      }),
                    );
                  });
                  rows.push(
                    new TableRow({
                      children: cells,
                    }),
                  );
                });
                paragraphList.push(
                  new Table({
                    rows,
                  }),
                  this.emptyLine,
                );
              },
            );
            renderSamplingTables[renderTableDataIndex].forEach(
              (samplingTable: any, samplingTableIndex: number) => {
                const samplingRows: TableRow[] = [];
                const renderSamplingTableDetail =
                  samplingTableIndex === 0
                    ? samplingTable.stack[1]
                    : samplingTable.stack[0];
                if (
                  renderSamplingTableDetail &&
                  renderSamplingTableDetail.table &&
                  renderSamplingTableDetail.table.body
                ) {
                  renderSamplingTableDetail.table.body.forEach(
                    (rowData: any) => {
                      const cells: TableCell[] = [];
                      rowData.forEach((cellData: any, index: number) => {
                        let cellText: any;
                        if (
                          cellData.text !== undefined &&
                          Array.isArray(cellData.text)
                        ) {
                          cellText = cellData.text.join(': ');
                        } else {
                          cellText = this.getTableValueText(cellData);
                        }
                        cells.push(
                          new TableCell({
                            width: {
                              size: index === 0 ? 70 : 30,
                              type: WidthType.PERCENTAGE,
                            },
                            children: [
                              new Paragraph({
                                children: [
                                  this.createTextRun({
                                    text: cellText,
                                    bold: !!cellData.bold,
                                    size: this.docxFontSize.default,
                                    color: cellData.color,
                                  }),
                                ],
                              }),
                            ],
                          }),
                        );
                      });
                      samplingRows.push(
                        new TableRow({
                          children: cells,
                        }),
                      );
                    },
                  );
                  paragraphList.push(
                    new Table({
                      rows: samplingRows,
                    }),
                    this.emptyLine,
                  );
                }
              },
            );
            const renderSamplingTable =
              renderSamplingTables[renderTableDataIndex][0];
            paragraphList.push(
              new Paragraph({
                children: [
                  this.createTextRun({
                    text: renderSamplingTable.stack[0].text,
                    bold: true,
                    size: this.docxFontSize.default,
                    color: renderSamplingTable.stack[0].color,
                  }),
                ],
              }),
              this.emptyLine,
            );
          },
        );
      }
    }

    return paragraphList;
  }

  public generateComparisonIndividualSiteIndividualCriteria(
    result: any,
    payload: any,
    projectSites: ProjectSite[],
    projectCriteria: ProjectCriteria[],
  ) {
    const allCharts: any[] = [];
    const renderBooleanTables: any[] = [];
    const renderBooleanSamplingTables: any[] = [];
    const renderMixedTables: any[] = [];
    const renderMixedSamplingTables: any[] = [];
    const dataSet: any[] = [];
    const dataSetOptions: any[] = [];
    const verticalBarChartDataSetOption: any = {
      animation: {
        duration: 0,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              max: 100,
              min: 0,
              fontSize: 20,
            },
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Compliance %',
              display: true,
              fontSize: 20,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Criteria',
              display: false,
            },
            ticks: {
              fontSize: 20,
              minRotation: 0,
              callback: (label: string, index: number) => {
                if (payload.checkIndividualCriteria) {
                  return this.transformLabel(label);
                } else {
                  if (payload.filteredCriterias.length > 4) {
                    return index + 1 + '.';
                  }
                  return this.transformLabel(label);
                }
              },
            },
          },
        ],
      },
      legend: {
        display: true,
        onHover: (e: any) => {
          e.target.style.cursor = 'pointer';
        },
        onLeave: (e: any) => {
          e.target.style.cursor = 'default';
        },
        labels: {
          boxWidth: 40,
          fontSize: 25,
        },
        align: 'start',
      },
      responsive: true,
      maintainAspectRatio: true,
      tooltips: {
        callbacks: {},
      },
    };
    const horizontalBarChartDataSetOption: any = {
      animation: {
        duration: 0,
      },
      scales: {
        xAxes: [
          {
            ticks: {
              beginAtZero: true,
              max: 100,
              min: 0,
              fontSize: 20,
            },
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Compliance %',
              display: true,
              fontSize: 20,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Criteria',
              display: false,
            },
            ticks: {
              fontSize: 20,
              minRotation: 0,
              callback: (label: string, index: number) => {
                if (payload.checkIndividualCriteria) {
                  return this.transformLabel(label);
                } else {
                  if (payload.filteredCriterias.length > 4) {
                    return index + 1 + '.';
                  }
                  return this.transformLabel(label);
                }
              },
            },
          },
        ],
      },
      legend: {
        display: true,
        onHover: (e: any) => {
          e.target.style.cursor = 'pointer';
        },
        onLeave: (e: any) => {
          e.target.style.cursor = 'default';
        },
        labels: {
          boxWidth: 40,
          fontSize: 25,
        },
        align: 'start',
      },
      responsive: true,
      maintainAspectRatio: true,
      tooltips: {
        callbacks: {},
      },
    };
    const chartData: any[][] = [];
    const statisticData: any[][] = [];
    const samplingConfigurationData: any[] = [];
    _map(result.comparisonResult, (comparisonResult: any) => {
      const dirtyChartData = comparisonResult.chartData;
      const dirtyStatisticData =
        comparisonResult.statisticData.configurationData;
      if (dirtyChartData.length > 0) {
        chartData.push(dirtyChartData);
      }
      statisticData.push(dirtyStatisticData);
      samplingConfigurationData.push(
        comparisonResult.statisticData.samplingConfiguration,
      );
    });
    const {
      comparisonAggregateCriteriaConfiguration,
      comparisonIndividualBooleanCriteriaConfiguration,
      comparisonIndividualMixedCriteriaConfiguration,
    } = this.generateComparisonConfigurationData(
      statisticData,
      payload,
      samplingConfigurationData,
    );
    const criteriaNames = [];
    const booleanFilteredCriteriaIds: number[] = this.getBooleanCriteriaIds(
      projectCriteria,
      payload,
    );
    _map(booleanFilteredCriteriaIds, (criteriaId: number) => {
      let criteriaName = '';
      _map(projectCriteria, (criteria) => {
        if (criteria.id === criteriaId) {
          criteriaName = criteria.title;
          criteriaNames.push(criteria.title);
        }
      });
      const data = {
        labels: [] as string[],
        datasets: [] as any[],
      };
      let dataOptions = {};
      let dataOptionsObject: any = {};
      Object.keys(chartData).forEach((key: string) => {
        const chartValues: number[] = [];
        const siteNames: string[] = [];
        const backgroundColors: string[] = [];
        const singleChartData = chartData[Number(key)];
        Object.keys(singleChartData).map((dataKey: string) => {
          if (
            singleChartData[Number(dataKey)].projectCriteriaId === criteriaId
          ) {
            _map(
              payload.filteredSites,
              (siteMapId: number, siteMapIndex: string) => {
                chartValues.push(
                  singleChartData[Number(dataKey)].data[Number(siteMapIndex)]
                    .compliance,
                );
              },
            );
            _map(singleChartData[Number(dataKey)].data, (eachRecord) => {
              siteNames.push(eachRecord.site.name);
              backgroundColors.push(chartColorSet[Number(key)]);
            });
          }
        });
        const dataSetObject = {
          label: this.formatDateRange(payload.comparisonPeriods[Number(key)]),
          backgroundColor: backgroundColors,
          hoverBackgroundColor: backgroundColors,
          pointBackgroundColor: 'white',
          borderWidth: 1,
          pointBorderColor: '#249EBF',
          data: chartValues,
          categoryPercentage: this.getIndividualCriteriaIndividualSiteChartCategoryPercentage(
            comparisonIndividualBooleanCriteriaConfiguration,
            payload,
          ),
        };
        data.datasets.push(dataSetObject);
        data.labels = _cloneDeep(siteNames);
      });
      if (payload.selectedChartType === ChartType.verticalBarChart) {
        dataOptionsObject = _cloneDeep(verticalBarChartDataSetOption);
      } else {
        dataOptionsObject = _cloneDeep(horizontalBarChartDataSetOption);
      }
      dataOptionsObject.tooltips.callbacks.title = () => criteriaName;
      dataOptionsObject.tooltips.callbacks.afterTitle = (item: any[]) =>
        item[0].label;
      dataOptions = _cloneDeep(dataOptionsObject);
      dataSet.push(data);
      dataSetOptions.push(dataOptions);
    });
    dataSet.forEach((individualSet, individualSetIndex) => {
      const renderChart = {
        width: 443,
        height: 250,
        image: '',
        alignment: 'center',
        margin: [0, 0, 0, 30],
        unbreakable: true,
      };
      const canvas = document.createElement('canvas');
      document.body.appendChild(canvas);
      if (payload.selectedChartType === ChartType.verticalBarChart) {
        const verticalChart = new Chart(
          canvas.getContext('2d') as CanvasRenderingContext2D,
          {
            type: 'bar',
            data: {
              labels: individualSet.labels,
              datasets: individualSet.datasets,
            },
            options: dataSetOptions[individualSetIndex],
            plugins: [
              {
                afterDatasetDraw(chart: any, args: any) {
                  args.meta.data.forEach((element: any) => {
                    if (element._model.base - element._model.y < 0) {
                      const borderWidth = 8;
                      const ctx = chart.ctx;
                      const vm = element._view;
                      const half = vm.width / 2;
                      const left = vm.x - half;
                      const right = vm.x + half;
                      const top = vm.y - 2;
                      const width = right - left;
                      const height =
                        chart.chartArea.bottom - top + borderWidth / 2 - 1;
                      ctx.beginPath();
                      ctx.lineWidth = borderWidth;
                      ctx.strokeStyle = '#000000';
                      ctx.setLineDash([3, 4]);
                      ctx.moveTo(left, top);
                      ctx.lineTo(left, top + height);
                      ctx.moveTo(left, top);
                      ctx.lineTo(left + width, top);
                      ctx.moveTo(left + width, top);
                      ctx.lineTo(left + width, top + height);
                      ctx.stroke();
                      ctx.save();
                    }
                  });
                },
              },
            ],
          },
        );
        const base64Image = verticalChart.toBase64Image();
        renderChart.image = base64Image.split(',')[1];
      } else {
        const horizontalChart = new Chart(
          canvas.getContext('2d') as CanvasRenderingContext2D,
          {
            type: 'horizontalBar',
            data: {
              labels: individualSet.labels,
              datasets: individualSet.datasets,
            },
            options: dataSetOptions[individualSetIndex],
            plugins: [
              {
                afterDatasetDraw(chart: any, args: any) {
                  args.meta.data.forEach((element: any) => {
                    if (element._model.base - element._model.x > 0) {
                      const borderWidth = 8;
                      const ctx = chart.ctx;
                      const vm = element._view;
                      const half = vm.height / 2;
                      const top = vm.y - half;
                      const bottom = vm.y + half;
                      const right = vm.x + 10;
                      const left = chart.chartArea.left;
                      ctx.beginPath();
                      ctx.lineWidth = borderWidth;
                      ctx.strokeStyle = '#000000';
                      ctx.setLineDash([4, 5]);
                      ctx.moveTo(left, top);
                      ctx.lineTo(right, top);
                      ctx.moveTo(right, top);
                      ctx.lineTo(right, bottom);
                      ctx.moveTo(right, bottom);
                      ctx.lineTo(left, bottom);
                      ctx.stroke();
                      ctx.save();
                    }
                  });
                },
              },
            ],
          },
        );
        const base64Image = horizontalChart.toBase64Image();
        renderChart.image = base64Image.split(',')[1];
      }
      const stack: any[] = [renderChart];
      const criteriaChart = {
        stack,
        unbreakable: true,
      };
      allCharts.push(criteriaChart);
      document.body.removeChild(canvas);
    });
    // generate tables
    const siteNameChunks = _cloneDeep(
      this.getSiteNames(projectSites, payload.filteredSites),
    );
    const periodChunks: any[] = [];
    const dirtyPeriodChunks = _cloneDeep(payload.comparisonPeriods);
    while (dirtyPeriodChunks.length) {
      periodChunks.push(dirtyPeriodChunks.splice(0, 2));
    }
    const samplingPeriodChunks: any[] = [];
    const dirtySamplingPeriodChunks = _cloneDeep(payload.comparisonPeriods);
    while (dirtySamplingPeriodChunks.length) {
      samplingPeriodChunks.push(dirtySamplingPeriodChunks.splice(0, 3));
    }
    comparisonIndividualBooleanCriteriaConfiguration.forEach(
      (booleanCriteriaDetails, booleanCriteriaDetailsIndex) => {
        const dirtyBooleanTables: any[] = [];
        const booleanCriteriaChunks: any[] = [];
        const dirtyBooleanCriteriaDetails = _cloneDeep(booleanCriteriaDetails);
        while (dirtyBooleanCriteriaDetails.length) {
          booleanCriteriaChunks.push(dirtyBooleanCriteriaDetails.splice(0, 2));
        }
        const title = {
          text:
            booleanCriteriaDetailsIndex +
            1 +
            '. ' +
            booleanCriteriaChunks[0][0].title,
          bold: true,
          color: '#444F51',
          fontSize: 11,
          margin: [0, 0, 0, 17],
        };
        siteNameChunks.forEach((projectSiteName, projectSiteNameIndex) => {
          booleanCriteriaChunks.forEach(
            (
              booleanCriteriaOptionDetails,
              booleanCriteriaOptionDetailsIndex,
            ) => {
              switch (booleanCriteriaOptionDetails.length) {
                case 1:
                  const bodyRowsSingle: any[][] = [];
                  const siteOptionDetailsSingle =
                    booleanCriteriaOptionDetails[0].criteriaSamplingData[
                      projectSiteNameIndex
                    ];
                  const optionKeysSingle: string[] = Object.keys(
                    siteOptionDetailsSingle.criteriaOptionsDataDistribution,
                  );
                  optionKeysSingle.forEach(
                    (optionKeySingle, optionKeySingleIndex) => {
                      const rowDataSingle: any[] = [];
                      rowDataSingle.push({
                        text: optionKeySingle,
                        color: '#444F51',
                        fontSize: 12,
                        border: [true, false, true, false],
                        margin:
                          optionKeySingleIndex === optionKeysSingle.length - 1
                            ? [8, 6, 0, 6]
                            : [8, 6, 0, 0],
                      });
                      rowDataSingle.push({
                        text: booleanCriteriaOptionDetails[0]
                          .criteriaSamplingDataConfiguration.length
                          ? siteOptionDetailsSingle
                              .criteriaOptionsDataDistribution[optionKeySingle]
                          : '-',
                        color: '#444F51',
                        fontSize: 12,
                        border: [true, false, true, false],
                        alignment: 'right',
                        margin:
                          optionKeySingleIndex === optionKeysSingle.length - 1
                            ? [0, 6, 8, 6]
                            : [0, 6, 8, 0],
                      });
                      bodyRowsSingle.push(rowDataSingle);
                    },
                  );
                  let singleTotal: number | string = 0;
                  let singleCompliance: any = 0;
                  bodyRowsSingle.forEach((optionValues) => {
                    if (optionValues[1].text === '-') {
                      singleTotal = '-';
                    } else {
                      singleTotal += optionValues[1].text;
                    }
                  });
                  bodyRowsSingle.push([
                    {
                      text: 'Total Data Collected',
                      color: '#444F51',
                      fontSize: 12,
                      margin: [8, 6, 0, 6],
                    },
                    {
                      text: singleTotal,
                      color: '#444F51',
                      fontSize: 12,
                      alignment: 'right',
                      margin: [0, 6, 8, 6],
                    },
                  ]);
                  if (bodyRowsSingle[0][1].text === 0) {
                    bodyRowsSingle.push([
                      {
                        text: 'Compliance',
                        color: '#444F51',
                        fontSize: 12,
                        margin: [8, 6, 0, 6],
                        bold: true,
                      },
                      {
                        text: singleCompliance + '%',
                        color: '#444F51',
                        fontSize: 12,
                        alignment: 'right',
                        bold: true,
                        margin: [0, 6, 8, 6],
                      },
                    ]);
                  } else {
                    if (bodyRowsSingle[0][1].text === '-') {
                      singleCompliance = '-';
                    } else {
                      singleCompliance =
                        (bodyRowsSingle[0][1].text /
                          (singleTotal - bodyRowsSingle[2][1].text)) *
                        100;
                    }
                    bodyRowsSingle.push([
                      {
                        text: 'Compliance',
                        color: '#444F51',
                        fontSize: 12,
                        margin: [8, 6, 0, 6],
                        bold: true,
                      },
                      {
                        text:
                          singleCompliance !== '-'
                            ? Math.round(singleCompliance * 100) / 100 + '%'
                            : singleCompliance,
                        color: '#444F51',
                        fontSize: 12,
                        alignment: 'right',
                        bold: true,
                        margin: [0, 6, 8, 6],
                      },
                    ]);
                  }
                  const singleTableData = {
                    table: {
                      unbreakable: true,
                      widths: ['*', 150],
                      body: [
                        [
                          {
                            text: 'Answer Choices',
                            color: '#9F9F9F',
                            fontSize: 11,
                            margin: [8, 10, 0, 10],
                            rowSpan: 2,
                          },
                          {
                            text: projectSiteName,
                            color: '#9F9F9F',
                            fontSize: 11,
                            alignment: 'right',
                            margin: [0, 10, 8, 10],
                          },
                        ],
                        [
                          {},
                          {
                            text: [
                              periodChunks[booleanCriteriaOptionDetailsIndex][0]
                                .title + '\n\n',
                              this.formatDateRange(
                                periodChunks[
                                  booleanCriteriaOptionDetailsIndex
                                ][0],
                              ),
                            ],
                            color: '#9F9F9F',
                            fontSize: 11,
                            alignment: 'right',
                            margin: [0, 10, 8, 10],
                          },
                        ],
                        ...bodyRowsSingle,
                      ],
                    },
                    layout: {
                      hLineWidth: () => {
                        return 0.5;
                      },
                      vLineWidth: () => {
                        return 0.5;
                      },
                      hLineColor: () => {
                        return '#DBDBDB';
                      },
                      vLineColor: () => {
                        return '#DBDBDB';
                      },
                    },
                  };
                  const singleStack: any[] = [singleTableData];
                  const criteriaSingleTable = {
                    stack: singleStack,
                    unbreakable: true,
                  };
                  dirtyBooleanTables.push(criteriaSingleTable);
                  break;
                default:
                  const bodyRows: any[] = [];
                  const valueRows: any[] = [];
                  const totalRow: number[] = [];
                  const complianceRow: any[] = [];
                  booleanCriteriaOptionDetails.forEach(
                    (
                      booleanCriteriaOptionDetail: any,
                      booleanCriteriaOptionDetailIndex: number,
                    ) => {
                      const siteOptionDetails =
                        booleanCriteriaOptionDetail.criteriaSamplingData[
                          projectSiteNameIndex
                        ];
                      const periodRow: any[] = [];
                      const optionKeys: string[] = Object.keys(
                        siteOptionDetails.criteriaOptionsDataDistribution,
                      );
                      // tslint:disable-next-line:max-line-length
                      const siteCriteriaDetails: SamplingDataConfiguration = booleanCriteriaOptionDetail.siteSamplingConfiguration.find(
                        (data: any) =>
                          data.auditSiteMap.site.name === projectSiteName,
                      );
                      optionKeys.forEach((optionKey) => {
                        const rowData: any = {
                          option: '',
                          value: null,
                        };
                        rowData.option = optionKey;
                        rowData.value =
                          siteOptionDetails.criteriaOptionsDataDistribution[
                            optionKey
                          ];
                        if (
                          siteCriteriaDetails === undefined ||
                          !siteCriteriaDetails.isSamplingEnabled
                        ) {
                          rowData.value = '-';
                        }
                        periodRow.push(rowData);
                      });
                      valueRows.push(periodRow);
                      let total: number | string = 0;
                      periodRow.forEach((optionValues) => {
                        if (
                          siteCriteriaDetails === undefined ||
                          !siteCriteriaDetails.isSamplingEnabled ||
                          optionValues.value === '-'
                        ) {
                          total = '-';
                        } else {
                          total += optionValues.value;
                        }
                      });
                      totalRow.push(total);
                      let compliance: any = 0;
                      if (
                        booleanCriteriaOptionDetail.criteriaType ===
                        CRITERION_TYPE.BOOLEAN
                      ) {
                        if (periodRow[0].value === 0) {
                          complianceRow.push(compliance);
                        } else {
                          if (
                            siteCriteriaDetails === undefined ||
                            !siteCriteriaDetails.isSamplingEnabled ||
                            periodRow[0].value === '-'
                          ) {
                            complianceRow.push('-');
                          } else {
                            compliance =
                              (periodRow[0].value /
                                (total - periodRow[2].value)) *
                              100;
                            complianceRow.push(
                              Math.round(compliance * 100) / 100,
                            );
                          }
                        }
                      }
                    },
                  );
                  const periodOne = valueRows[0];
                  const periodTwo = valueRows[1];
                  for (let step = 0; step < periodOne.length; step++) {
                    const optionColumnOne = {
                      text: periodOne[step].option,
                      color: '#444F51',
                      fontSize: 12,
                      border: [true, false, true, false],
                      margin:
                        step === periodOne.length - 1
                          ? [8, 6, 0, 6]
                          : [8, 6, 0, 0],
                    };
                    const optionColumnTwo = {
                      text: periodOne[step].value,
                      color: '#444F51',
                      fontSize: 12,
                      border: [true, false, true, false],
                      alignment: 'right',
                      margin:
                        step === periodOne.length - 1
                          ? [0, 6, 8, 6]
                          : [0, 6, 8, 0],
                    };
                    const optionColumnThree = {
                      text: periodTwo[step].value,
                      color: '#444F51',
                      fontSize: 12,
                      border: [true, false, true, false],
                      alignment: 'right',
                      margin:
                        step === periodOne.length - 1
                          ? [0, 6, 8, 6]
                          : [0, 6, 8, 0],
                    };
                    bodyRows.push([
                      optionColumnOne,
                      optionColumnTwo,
                      optionColumnThree,
                    ]);
                  }
                  const totalColumnOne = {
                    text: 'Total Data Collected',
                    color: '#444F51',
                    fontSize: 12,
                    margin: [8, 6, 0, 6],
                  };
                  const totalColumnTwo = {
                    text: totalRow[0],
                    color: '#444F51',
                    fontSize: 12,
                    alignment: 'right',
                    margin: [0, 6, 8, 6],
                  };
                  const totalColumnThree = {
                    text: totalRow[1],
                    color: '#444F51',
                    fontSize: 12,
                    alignment: 'right',
                    margin: [0, 6, 8, 6],
                  };
                  bodyRows.push([
                    totalColumnOne,
                    totalColumnTwo,
                    totalColumnThree,
                  ]);
                  const complianceColumnOne = {
                    text: 'Compliance',
                    color: '#444F51',
                    fontSize: 12,
                    margin: [8, 6, 0, 6],
                    bold: true,
                  };
                  const complianceColumnTwo = {
                    text:
                      complianceRow[0] !== '-'
                        ? complianceRow[0] + '%'
                        : complianceRow[0],
                    color: '#444F51',
                    fontSize: 12,
                    alignment: 'right',
                    bold: true,
                    margin: [0, 6, 8, 6],
                  };
                  const complianceColumnThree = {
                    text:
                      complianceRow[1] !== '-'
                        ? complianceRow[1] + '%'
                        : complianceRow[1],
                    color: '#444F51',
                    fontSize: 12,
                    alignment: 'right',
                    bold: true,
                    margin: [0, 6, 8, 6],
                  };
                  bodyRows.push([
                    complianceColumnOne,
                    complianceColumnTwo,
                    complianceColumnThree,
                  ]);
                  const tableData = {
                    table: {
                      unbreakable: true,
                      widths: ['*', 150, 150],
                      body: [
                        [
                          {
                            text: 'Answer Choices',
                            color: '#9F9F9F',
                            fontSize: 11,
                            margin: [8, 10, 0, 10],
                            rowSpan: 2,
                          },
                          {
                            text: projectSiteName,
                            color: '#9F9F9F',
                            fontSize: 11,
                            alignment: 'right',
                            margin: [0, 10, 8, 10],
                            colSpan: 2,
                          },
                          {},
                        ],
                        [
                          {},
                          {
                            text: [
                              periodChunks[booleanCriteriaOptionDetailsIndex][0]
                                .title + '\n\n',
                              this.formatDateRange(
                                periodChunks[
                                  booleanCriteriaOptionDetailsIndex
                                ][0],
                              ),
                            ],
                            color: '#9F9F9F',
                            fontSize: 11,
                            alignment: 'right',
                            margin: [0, 10, 8, 10],
                          },
                          {
                            text: [
                              periodChunks[booleanCriteriaOptionDetailsIndex][1]
                                .title + '\n\n',
                              this.formatDateRange(
                                periodChunks[
                                  booleanCriteriaOptionDetailsIndex
                                ][1],
                              ),
                            ],
                            color: '#9F9F9F',
                            fontSize: 11,
                            alignment: 'right',
                            margin: [0, 10, 8, 10],
                          },
                        ],
                        ...bodyRows,
                      ],
                    },
                    layout: {
                      hLineWidth: () => {
                        return 0.5;
                      },
                      vLineWidth: () => {
                        return 0.5;
                      },
                      hLineColor: () => {
                        return '#DBDBDB';
                      },
                      vLineColor: () => {
                        return '#DBDBDB';
                      },
                    },
                  };
                  const stack: any[] = [tableData];
                  const criteriaTable = {
                    stack,
                    unbreakable: true,
                  };
                  dirtyBooleanTables.push(criteriaTable);
                  break;
              }
            },
          );
        });
        const setMargin = {
          canvas: [],
          margin: [0, 0, 0, 13],
        };
        allCharts[booleanCriteriaDetailsIndex].stack.unshift(title);
        switch (dirtyBooleanTables.length) {
          case 1:
            dirtyBooleanTables[0].stack.push(setMargin);
            break;
          case 2:
            dirtyBooleanTables[0].stack.push(setMargin);
            break;
          default:
            dirtyBooleanTables.forEach(
              (dirtyBooleanTable, dirtyBooleanTableIndex) => {
                switch (dirtyBooleanTableIndex) {
                  case 0:
                    dirtyBooleanTable.stack.push(setMargin);
                    break;
                  case dirtyBooleanTables.length - 1:
                    dirtyBooleanTable.stack.push(setMargin);
                    break;
                  default:
                    dirtyBooleanTable.stack.push(setMargin);
                }
              },
            );
        }
        renderBooleanTables.push(dirtyBooleanTables);
      },
    );
    comparisonIndividualBooleanCriteriaConfiguration.forEach(
      (criteriaDetails, criteriaDetailsIndex) => {
        const criteriaChunks: any[] = [];
        const dirtyCriteriaDetails = _cloneDeep(criteriaDetails);
        while (dirtyCriteriaDetails.length) {
          criteriaChunks.push(dirtyCriteriaDetails.splice(0, 3));
        }
        const samplingTitle = {
          text: 'Sampling',
          bold: true,
          color: '#444F51',
          fontSize: 11,
          margin: [0, 13, 0, 17],
        };
        const dirtyBooleanSamplingTables = this.getSamplingTables(
          criteriaChunks,
          samplingPeriodChunks,
          projectSites,
          samplingTitle,
          payload.filteredSites,
        );
        renderBooleanSamplingTables.push(dirtyBooleanSamplingTables);
      },
    );
    if (comparisonIndividualMixedCriteriaConfiguration.length > 0) {
      comparisonIndividualMixedCriteriaConfiguration.forEach(
        (mixedCriteriaDetails, mixedCriteriaDetailsIndex) => {
          const dirtyMixedTables: any[] = [];
          const mixedCriteriaChunks: any[] = [];
          const dirtyMixedCriteriaDetails = _cloneDeep(mixedCriteriaDetails);
          while (dirtyMixedCriteriaDetails.length) {
            mixedCriteriaChunks.push(dirtyMixedCriteriaDetails.splice(0, 2));
          }
          const title = {
            text:
              comparisonIndividualBooleanCriteriaConfiguration.length +
              mixedCriteriaDetailsIndex +
              1 +
              '. ' +
              mixedCriteriaChunks[0][0].title,
            bold: true,
            color: '#444F51',
            fontSize: 11,
            margin: [0, 0, 0, 17],
          };
          mixedCriteriaChunks.forEach(
            (mixedCriteriaOptionDetails, mixedCriteriaOptionDetailsIndex) => {
              switch (mixedCriteriaOptionDetails.length) {
                case 1:
                  siteNameChunks.forEach(
                    (projectSiteName, projectSiteNameIndex) => {
                      const bodyRowsSingle: any[][] = [];
                      const siteOptionDetailsSingle =
                        mixedCriteriaOptionDetails[0].criteriaSamplingData[
                          projectSiteNameIndex
                        ];
                      const optionKeysSingle: string[] = Object.keys(
                        siteOptionDetailsSingle.criteriaOptionsDataDistribution,
                      );
                      optionKeysSingle.forEach(
                        (optionKeySingle, optionKeySingleIndex) => {
                          const rowDataSingle: any[] = [];
                          rowDataSingle.push({
                            text: optionKeySingle,
                            color: '#444F51',
                            fontSize: 12,
                            border: [true, false, true, false],
                            margin:
                              optionKeySingleIndex ===
                              optionKeysSingle.length - 1
                                ? [8, 6, 0, 6]
                                : [8, 6, 0, 0],
                          });
                          rowDataSingle.push({
                            text: mixedCriteriaOptionDetails[0]
                              .criteriaSamplingDataConfiguration
                              ? siteOptionDetailsSingle
                                  .criteriaOptionsDataDistribution[
                                  optionKeySingle
                                ]
                              : '-',
                            color: '#444F51',
                            fontSize: 12,
                            border: [true, false, true, false],
                            alignment: 'right',
                            margin:
                              optionKeySingleIndex ===
                              optionKeysSingle.length - 1
                                ? [0, 6, 8, 6]
                                : [0, 6, 8, 0],
                          });
                          bodyRowsSingle.push(rowDataSingle);
                        },
                      );
                      let singleTotal: number | string = 0;
                      bodyRowsSingle.forEach((optionValues) => {
                        if (optionValues[1].text === '-') {
                          singleTotal = '-';
                        } else {
                          singleTotal += optionValues[1].text;
                        }
                      });
                      bodyRowsSingle.push([
                        {
                          text: 'Total Data Collected',
                          color: '#444F51',
                          fontSize: 12,
                          margin: [8, 6, 0, 6],
                        },
                        {
                          text: singleTotal,
                          color: '#444F51',
                          fontSize: 12,
                          alignment: 'right',
                          margin: [0, 6, 8, 6],
                        },
                      ]);
                      const singleTableData = {
                        table: {
                          unbreakable: true,
                          widths: ['*', 150],
                          body: [
                            [
                              {
                                text: 'Answer Choices',
                                color: '#9F9F9F',
                                fontSize: 11,
                                margin: [8, 10, 0, 10],
                                rowSpan: 2,
                              },
                              {
                                text: projectSiteName,
                                color: '#9F9F9F',
                                fontSize: 11,
                                alignment: 'right',
                                margin: [0, 10, 8, 10],
                              },
                            ],
                            [
                              {},
                              {
                                text: [
                                  periodChunks[
                                    mixedCriteriaOptionDetailsIndex
                                  ][0].title + '\n\n',
                                  this.formatDateRange(
                                    periodChunks[
                                      mixedCriteriaOptionDetailsIndex
                                    ][0],
                                  ),
                                ],
                                color: '#9F9F9F',
                                fontSize: 11,
                                alignment: 'right',
                                margin: [0, 10, 8, 10],
                              },
                            ],
                            ...bodyRowsSingle,
                          ],
                        },
                        layout: {
                          hLineWidth: () => {
                            return 0.5;
                          },
                          vLineWidth: () => {
                            return 0.5;
                          },
                          hLineColor: () => {
                            return '#DBDBDB';
                          },
                          vLineColor: () => {
                            return '#DBDBDB';
                          },
                        },
                      };
                      const singleStack: any[] = [singleTableData];
                      const criteriaSingleTable = {
                        stack: singleStack,
                        unbreakable: true,
                      };
                      dirtyMixedTables.push(criteriaSingleTable);
                    },
                  );
                  break;
                default:
                  const optionsList: string[] = [];
                  const valueRows: any[] = [];
                  const totalRow: number[][] = [];
                  siteNameChunks.forEach(
                    (projectSiteName, projectSiteNameIndex) => {
                      const bodyRows: any[] = [];
                      mixedCriteriaOptionDetails.forEach(
                        (
                          mixedCriteriaOptionDetail: any,
                          mixedCriteriaOptionDetailIndex: number,
                        ) => {
                          const siteOptionDetails =
                            mixedCriteriaOptionDetail.criteriaSamplingData[
                              projectSiteNameIndex
                            ];
                          const criteriaOptions: string[] = Object.keys(
                            siteOptionDetails.criteriaOptionsDataDistribution,
                          );

                          criteriaOptions.forEach((option) => {
                            if (!optionsList.includes(option)) {
                              optionsList.push(option);
                            }
                          });
                          // tslint:disable-next-line:max-line-length
                          const siteCriteriaDetails: SamplingDataConfiguration = mixedCriteriaOptionDetail.siteSamplingConfiguration.find(
                            (data: any) =>
                              data.auditSiteMap.site.name === projectSiteName,
                          );

                          if (
                            mixedCriteriaOptionDetail
                              .criteriaSamplingDataConfiguration.length === 0 ||
                            siteCriteriaDetails === undefined ||
                            !siteCriteriaDetails.isSamplingEnabled
                          ) {
                            for (const [key] of Object.entries(
                              siteOptionDetails.criteriaOptionsDataDistribution,
                            )) {
                              siteOptionDetails.criteriaOptionsDataDistribution[
                                key
                              ] = '-';
                            }
                            siteOptionDetails.total = '-';
                          }
                          if (totalRow[mixedCriteriaOptionDetailIndex]) {
                            totalRow[mixedCriteriaOptionDetailIndex].push(
                              siteOptionDetails.total,
                            );
                          } else {
                            totalRow[mixedCriteriaOptionDetailIndex] = [
                              siteOptionDetails.total,
                            ];
                          }
                          if (valueRows[mixedCriteriaOptionDetailIndex]) {
                            valueRows[mixedCriteriaOptionDetailIndex] = {
                              ...siteOptionDetails.criteriaOptionsDataDistribution,
                            };
                          } else {
                            valueRows[mixedCriteriaOptionDetailIndex] =
                              siteOptionDetails.criteriaOptionsDataDistribution;
                          }
                        },
                      );
                      const periodOne = valueRows[0];
                      const periodTwo = valueRows[1];
                      optionsList.forEach((option, index) => {
                        const optionColumnOne = {
                          text: option,
                          color: '#444F51',
                          fontSize: 12,
                          border: [true, false, true, false],
                          margin:
                            index === optionsList.length - 1
                              ? [8, 6, 0, 6]
                              : [8, 6, 0, 0],
                        };
                        let periodOneValue: number | string;
                        if (periodOne && periodOne[option] !== undefined) {
                          periodOneValue = periodOne[option];
                        } else {
                          periodOneValue = option === 'N/A' ? 0 : '-';
                        }
                        const optionColumnTwo = {
                          text: periodOneValue,
                          color: '#444F51',
                          fontSize: 12,
                          border: [true, false, true, false],
                          alignment: 'right',
                          margin:
                            index === optionsList.length - 1
                              ? [0, 6, 8, 6]
                              : [0, 6, 8, 0],
                        };
                        let periodTwoValue: number | string;
                        if (periodTwo && periodTwo[option] !== undefined) {
                          periodTwoValue = periodTwo[option];
                        } else {
                          periodTwoValue = option === 'N/A' ? 0 : '-';
                        }
                        const optionColumnThree = {
                          text: periodTwoValue,
                          color: '#444F51',
                          fontSize: 12,
                          border: [true, false, true, false],
                          alignment: 'right',
                          margin:
                            index === optionsList.length - 1
                              ? [0, 6, 8, 6]
                              : [0, 6, 8, 0],
                        };
                        bodyRows.push([
                          optionColumnOne,
                          optionColumnTwo,
                          optionColumnThree,
                        ]);
                      });
                      const totalColumnOne = {
                        text: 'Total Data Collected',
                        color: '#444F51',
                        fontSize: 12,
                        margin: [8, 6, 0, 6],
                      };
                      const totalColumnTwo = {
                        text: totalRow[0][projectSiteNameIndex],
                        color: '#444F51',
                        fontSize: 12,
                        alignment: 'right',
                        margin: [0, 6, 8, 6],
                      };
                      const totalColumnThree = {
                        text: totalRow[1][projectSiteNameIndex],
                        color: '#444F51',
                        fontSize: 12,
                        alignment: 'right',
                        margin: [0, 6, 8, 6],
                      };
                      bodyRows.push([
                        totalColumnOne,
                        totalColumnTwo,
                        totalColumnThree,
                      ]);
                      const tableData = {
                        table: {
                          unbreakable: true,
                          widths: ['*', 150, 150],
                          body: [
                            [
                              {
                                text: 'Answer Choices',
                                color: '#9F9F9F',
                                fontSize: 11,
                                margin: [8, 10, 0, 10],
                                rowSpan: 2,
                              },
                              {
                                text: projectSiteName,
                                color: '#9F9F9F',
                                fontSize: 11,
                                alignment: 'right',
                                margin: [0, 10, 8, 10],
                                colSpan: 2,
                              },
                              {},
                            ],
                            [
                              {},
                              {
                                text: [
                                  periodChunks[
                                    mixedCriteriaOptionDetailsIndex
                                  ][0].title + '\n\n',
                                  this.formatDateRange(
                                    periodChunks[
                                      mixedCriteriaOptionDetailsIndex
                                    ][0],
                                  ),
                                ],
                                color: '#9F9F9F',
                                fontSize: 11,
                                alignment: 'right',
                                margin: [0, 10, 8, 10],
                              },
                              {
                                text: [
                                  periodChunks[
                                    mixedCriteriaOptionDetailsIndex
                                  ][1].title + '\n\n',
                                  this.formatDateRange(
                                    periodChunks[
                                      mixedCriteriaOptionDetailsIndex
                                    ][1],
                                  ),
                                ],
                                color: '#9F9F9F',
                                fontSize: 11,
                                alignment: 'right',
                                margin: [0, 10, 8, 10],
                              },
                            ],
                            ...bodyRows,
                          ],
                        },
                        layout: {
                          hLineWidth: () => {
                            return 0.5;
                          },
                          vLineWidth: () => {
                            return 0.5;
                          },
                          hLineColor: () => {
                            return '#DBDBDB';
                          },
                          vLineColor: () => {
                            return '#DBDBDB';
                          },
                        },
                      };
                      const stack: any[] = [tableData];
                      const criteriaTable = {
                        stack,
                        unbreakable: true,
                      };
                      dirtyMixedTables.push(criteriaTable);
                    },
                  );
                  break;
              }
            },
          );

          const setMargin = {
            canvas: [],
            margin: [0, 0, 0, 13],
          };
          switch (dirtyMixedTables.length) {
            case 1:
              dirtyMixedTables[0].stack.unshift(title);
              dirtyMixedTables[0].stack.push(setMargin);
              break;
            case 2:
              dirtyMixedTables[0].stack.unshift(title);
              dirtyMixedTables[0].stack.push(setMargin);
              break;
            default:
              dirtyMixedTables.forEach(
                (dirtyMixedTable, dirtyMixedTableIndex) => {
                  switch (dirtyMixedTableIndex) {
                    case 0:
                      dirtyMixedTable.stack.unshift(title);
                      dirtyMixedTable.stack.push(setMargin);
                      break;
                    case dirtyMixedTables.length - 1:
                      dirtyMixedTable.stack.push(setMargin);
                      break;
                    default:
                      dirtyMixedTable.stack.push(setMargin);
                  }
                },
              );
          }
          renderMixedTables.push(dirtyMixedTables);
        },
      );
      comparisonIndividualMixedCriteriaConfiguration.forEach(
        (criteriaDetails, criteriaDetailsIndex) => {
          const criteriaChunks: any[] = [];
          const dirtyCriteriaDetails = _cloneDeep(criteriaDetails);
          while (dirtyCriteriaDetails.length) {
            criteriaChunks.push(dirtyCriteriaDetails.splice(0, 3));
          }
          const samplingTitle = {
            text: 'Sampling',
            bold: true,
            color: '#444F51',
            fontSize: 11,
            margin: [0, 13, 0, 17],
          };
          const dirtyMixedSamplingTables = this.getSamplingTables(
            criteriaChunks,
            samplingPeriodChunks,
            projectSites,
            samplingTitle,
            payload.filteredSites,
          );
          renderMixedSamplingTables.push(dirtyMixedSamplingTables);
        },
      );
    }
    return {
      allCharts,
      renderBooleanTables,
      renderBooleanSamplingTables,
      renderMixedTables,
      renderMixedSamplingTables,
    };
  }

  public generateComparisonIndividualSiteAggregateCriteria(
    result: any,
    payload: any,
    projectSites: ProjectSite[],
  ) {
    const aggregateChart: any[] = [];
    const renderChart = {
      width: 443,
      height: 250,
      image: '',
      alignment: 'center',
      margin: [0, 0, 0, 30],
      unbreakable: true,
    };
    const renderTables: any[] = [];
    const renderSamplingTables: any[] = [];
    // generate chart
    const dataSet = [];
    const dataSetOptions = [];
    const verticalBarChartDataSetOption: any = {
      animation: {
        duration: 0,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              max: 100,
              min: 0,
              fontSize: 20,
            },
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Compliance %',
              display: true,
              fontSize: 20,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Criteria',
              display: false,
            },
            ticks: {
              fontSize: 20,
              minRotation: 0,
              callback: (label: string, index: number) => {
                if (payload.checkIndividualCriteria) {
                  return this.transformLabel(label);
                } else {
                  if (payload.filteredCriterias.length > 4) {
                    return index + 1 + '.';
                  }
                  return this.transformLabel(label);
                }
              },
            },
          },
        ],
      },
      legend: {
        display: true,
        onHover: (e: any) => {
          e.target.style.cursor = 'pointer';
        },
        onLeave: (e: any) => {
          e.target.style.cursor = 'default';
        },
        labels: {
          boxWidth: 40,
          fontSize: 25,
        },
        align: 'start',
      },
      responsive: true,
      maintainAspectRatio: true,
      tooltips: {
        callbacks: {},
      },
    };
    const horizontalBarChartDataSetOption: any = {
      animation: {
        duration: 0,
      },
      scales: {
        xAxes: [
          {
            ticks: {
              beginAtZero: true,
              max: 100,
              min: 0,
              fontSize: 20,
            },
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Compliance %',
              display: true,
              fontSize: 20,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Criteria',
              display: false,
            },
            ticks: {
              fontSize: 20,
              minRotation: 0,
              callback: (label: string, index: number) => {
                if (payload.checkIndividualCriteria) {
                  return this.transformLabel(label);
                } else {
                  if (payload.filteredCriterias.length > 4) {
                    return index + 1 + '.';
                  }
                  return this.transformLabel(label);
                }
              },
            },
          },
        ],
      },
      legend: {
        display: true,
        onHover: (e: any) => {
          e.target.style.cursor = 'pointer';
        },
        onLeave: (e: any) => {
          e.target.style.cursor = 'default';
        },
        labels: {
          boxWidth: 40,
          fontSize: 25,
        },
        align: 'start',
      },
      responsive: true,
      maintainAspectRatio: true,
      tooltips: {
        callbacks: {},
      },
    };
    const chartData: any[][] = [];
    const statisticData: any[][] = [];
    const samplingConfigurationData: any[] = [];
    _map(result.comparisonResult, (comparisonResult: any) => {
      const dirtyChartData = comparisonResult.chartData;
      const dirtyStatisticData =
        comparisonResult.statisticData.configurationData;
      if (dirtyChartData.length > 0) {
        chartData.push(dirtyChartData);
      }
      statisticData.push(dirtyStatisticData);
      samplingConfigurationData.push(
        comparisonResult.statisticData.samplingConfiguration,
      );
    });
    const {
      comparisonAggregateCriteriaConfiguration,
      comparisonIndividualBooleanCriteriaConfiguration,
      comparisonIndividualMixedCriteriaConfiguration,
    } = this.generateComparisonConfigurationData(
      statisticData,
      payload,
      samplingConfigurationData,
    );
    const data = {
      labels: [] as string[],
      datasets: [] as any[],
    };
    let dataOptions = {};
    _map(
      payload.filteredSites,
      (projectSiteMapId: number, projectSiteMapIdIndex: string) => {
        Object.keys(chartData).map((key: string) => {
          const singleChartData = chartData[Number(key)];
          const chartValues: number[] = this.getChartValues(
            singleChartData,
            projectSiteMapId,
          );
          const backgroundColors: string[] = this.getBackgroundColors(
            Number(key),
            Number(projectSiteMapIdIndex),
            payload.comparisonPeriods.length,
            payload,
          );
          const dataSetObject = {
            label: this.getCustomLabel(
              projectSiteMapId,
              Number(key),
              payload,
              projectSites,
            ),
            backgroundColor: backgroundColors,
            pointBackgroundColor: 'white',
            borderWidth: 1,
            pointBorderColor: '#249EBF',
            data: chartValues,
            categoryPercentage: this.getAggregateCriteriaAggregateSiteChartCategoryPercentage(
              comparisonIndividualBooleanCriteriaConfiguration,
              payload,
            ),
          };
          data.datasets.push(dataSetObject);
        });
      },
    );
    let dataOptionsObject;
    if (payload.selectedChartType === ChartType.verticalBarChart) {
      dataOptionsObject = _cloneDeep(verticalBarChartDataSetOption);
    } else {
      dataOptionsObject = _cloneDeep(horizontalBarChartDataSetOption);
    }
    data.labels = this.getCriteriaNames(chartData[0]);
    dataOptions = _cloneDeep(dataOptionsObject);
    dataSet.push(data);
    dataSetOptions.push(dataOptions);
    const canvas = document.createElement('canvas');
    document.body.appendChild(canvas);
    if (payload.selectedChartType === ChartType.verticalBarChart) {
      const verticalChart = new Chart(
        canvas.getContext('2d') as CanvasRenderingContext2D,
        {
          type: 'bar',
          data: {
            labels: dataSet[0].labels,
            datasets: dataSet[0].datasets,
          },
          options: dataSetOptions[0],
          plugins: [
            {
              afterDatasetDraw(chart: any, args: any) {
                args.meta.data.forEach((element: any) => {
                  if (element._model.base - element._model.y < 0) {
                    const borderWidth = 8;
                    const ctx = chart.ctx;
                    const vm = element._view;
                    const half = vm.width / 2;
                    const left = vm.x - half;
                    const right = vm.x + half;
                    const top = vm.y - 2;
                    const width = right - left;
                    const height =
                      chart.chartArea.bottom - top + borderWidth / 2 - 1;
                    ctx.beginPath();
                    ctx.lineWidth = borderWidth;
                    ctx.strokeStyle = '#000000';
                    ctx.setLineDash([3, 4]);
                    ctx.moveTo(left, top);
                    ctx.lineTo(left, top + height);
                    ctx.moveTo(left, top);
                    ctx.lineTo(left + width, top);
                    ctx.moveTo(left + width, top);
                    ctx.lineTo(left + width, top + height);
                    ctx.stroke();
                    ctx.save();
                  }
                });
              },
            },
          ],
        },
      );
      const base64Image = verticalChart.toBase64Image();
      renderChart.image = base64Image.split(',')[1];
    } else {
      const horizontalChart = new Chart(
        canvas.getContext('2d') as CanvasRenderingContext2D,
        {
          type: 'horizontalBar',
          data: {
            labels: dataSet[0].labels,
            datasets: dataSet[0].datasets,
          },
          options: dataSetOptions[0],
          plugins: [
            {
              afterDatasetDraw(chart: any, args: any) {
                args.meta.data.forEach((element: any) => {
                  if (element._model.base - element._model.x > 0) {
                    const borderWidth = 8;
                    const ctx = chart.ctx;
                    const vm = element._view;
                    const half = vm.height / 2;
                    const top = vm.y - half;
                    const bottom = vm.y + half;
                    const right = vm.x + 10;
                    const left = chart.chartArea.left;
                    ctx.beginPath();
                    ctx.lineWidth = borderWidth;
                    ctx.strokeStyle = '#000000';
                    ctx.setLineDash([4, 5]);
                    ctx.moveTo(left, top);
                    ctx.lineTo(right, top);
                    ctx.moveTo(right, top);
                    ctx.lineTo(right, bottom);
                    ctx.moveTo(right, bottom);
                    ctx.lineTo(left, bottom);
                    ctx.stroke();
                    ctx.save();
                  }
                });
              },
            },
          ],
        },
      );
      const base64Image = horizontalChart.toBase64Image();
      renderChart.image = base64Image.split(',')[1];
    }
    const criteriaNamesText: any[] = data.labels.map((label) => {
      return {
        text: label,
        color: '#444F51',
        margin: [10, 5, 0, 2],
      };
    });
    const names = {
      layout: 'noBorders',
      table: {
        widths: ['*'],
        body: [
          [
            {
              ol: criteriaNamesText,
              fontSize: 9,
              fillColor: '#EFF7FF',
              markerColor: '#444F51',
            },
          ],
        ],
      },
      margin: [10, 0, 0, 23],
    };
    let aggregateStack: any[];
    if (payload.filteredCriterias.length > 4) {
      renderChart.margin = [0, 0, 0, 15];
      aggregateStack = [renderChart, names];
    } else {
      aggregateStack = [renderChart];
    }
    const criteriaChart = {
      stack: aggregateStack,
      unbreakable: false,
    };
    aggregateChart.push(criteriaChart);
    document.body.removeChild(canvas);
    // generate tables
    const siteNameChunks = _cloneDeep(
      this.getSiteNames(projectSites, payload.filteredSites),
    );
    const periodChunks: any[] = [];
    const dirtyPeriodChunks = _cloneDeep(payload.comparisonPeriods);
    while (dirtyPeriodChunks.length) {
      periodChunks.push(dirtyPeriodChunks.splice(0, 2));
    }
    const samplingPeriodChunks: any[] = [];
    const dirtySamplingPeriodChunks = _cloneDeep(payload.comparisonPeriods);
    while (dirtySamplingPeriodChunks.length) {
      samplingPeriodChunks.push(dirtySamplingPeriodChunks.splice(0, 3));
    }
    comparisonAggregateCriteriaConfiguration.forEach(
      (criteriaDetails, criteriaDetailsIndex) => {
        const dirtyTables: any[] = [];
        const criteriaChunks: any[] = [];
        const dirtyCriteriaDetails = _cloneDeep(criteriaDetails);
        while (dirtyCriteriaDetails.length) {
          criteriaChunks.push(dirtyCriteriaDetails.splice(0, 2));
        }
        const title = {
          text: criteriaDetailsIndex + 1 + '. ' + criteriaChunks[0][0].title,
          bold: true,
          color: '#444F51',
          fontSize: 11,
          margin: [0, 0, 0, 17],
        };
        criteriaChunks.forEach(
          (criteriaOptionDetails, criteriaOptionDetailsIndex) => {
            switch (criteriaOptionDetails.length) {
              case 1:
                siteNameChunks.forEach(
                  (projectSiteName, projectSiteNameIndex) => {
                    const bodyRowsSingle: any[][] = [];
                    const siteOptionDetailsSingle =
                      criteriaOptionDetails[0].criteriaSamplingData[
                        projectSiteNameIndex
                      ];
                    const optionKeysSingle: string[] = Object.keys(
                      siteOptionDetailsSingle.criteriaOptionsDataDistribution,
                    );
                    optionKeysSingle.forEach(
                      (optionKeySingle, optionKeySingleIndex) => {
                        const rowDataSingle: any[] = [];
                        rowDataSingle.push({
                          text: optionKeySingle,
                          color: '#444F51',
                          fontSize: 12,
                          border: [true, false, true, false],
                          margin:
                            optionKeySingleIndex === optionKeysSingle.length - 1
                              ? [8, 6, 0, 6]
                              : [8, 6, 0, 0],
                        });
                        rowDataSingle.push({
                          text: criteriaOptionDetails[0]
                            .criteriaSamplingDataConfiguration.length
                            ? siteOptionDetailsSingle
                                .criteriaOptionsDataDistribution[
                                optionKeySingle
                              ]
                            : '-',
                          color: '#444F51',
                          fontSize: 12,
                          border: [true, false, true, false],
                          alignment: 'right',
                          margin:
                            optionKeySingleIndex === optionKeysSingle.length - 1
                              ? [0, 6, 8, 6]
                              : [0, 6, 8, 0],
                        });
                        bodyRowsSingle.push(rowDataSingle);
                      },
                    );
                    let singleTotal: number | string = 0;
                    let singleCompliance: any = 0;
                    bodyRowsSingle.forEach((optionValues) => {
                      if (optionValues[1].text === '-') {
                        singleTotal = '-';
                      } else {
                        singleTotal += optionValues[1].text;
                      }
                    });
                    bodyRowsSingle.push([
                      {
                        text: 'Total Data Collected',
                        color: '#444F51',
                        fontSize: 12,
                        margin: [8, 6, 0, 6],
                      },
                      {
                        text: singleTotal,
                        color: '#444F51',
                        fontSize: 12,
                        alignment: 'right',
                        margin: [0, 6, 8, 6],
                      },
                    ]);
                    if (
                      criteriaOptionDetails[0].criteriaType ===
                      CRITERION_TYPE.BOOLEAN
                    ) {
                      if (bodyRowsSingle[0][1].text === 0) {
                        bodyRowsSingle.push([
                          {
                            text: 'Compliance',
                            color: '#444F51',
                            fontSize: 12,
                            margin: [8, 6, 0, 6],
                            bold: true,
                          },
                          {
                            text: singleCompliance + '%',
                            color: '#444F51',
                            fontSize: 12,
                            alignment: 'right',
                            bold: true,
                            margin: [0, 6, 8, 6],
                          },
                        ]);
                      } else {
                        if (bodyRowsSingle[0][1].text === '-') {
                          singleCompliance = '-';
                        } else {
                          singleCompliance =
                            (bodyRowsSingle[0][1].text /
                              (singleTotal - bodyRowsSingle[2][1].text)) *
                            100;
                        }
                        bodyRowsSingle.push([
                          {
                            text: 'Compliance',
                            color: '#444F51',
                            fontSize: 12,
                            margin: [8, 6, 0, 6],
                            bold: true,
                          },
                          {
                            text:
                              singleCompliance !== '-'
                                ? Math.round(singleCompliance * 100) / 100 + '%'
                                : singleCompliance,
                            color: '#444F51',
                            fontSize: 12,
                            alignment: 'right',
                            bold: true,
                            margin: [0, 6, 8, 6],
                          },
                        ]);
                      }
                    }
                    const singleTableData = {
                      table: {
                        unbreakable: true,
                        widths: ['*', 150],
                        body: [
                          [
                            {
                              text: 'Answer Choices',
                              color: '#9F9F9F',
                              fontSize: 11,
                              margin: [8, 10, 0, 10],
                              rowSpan: 2,
                            },
                            {
                              text: projectSiteName,
                              color: '#9F9F9F',
                              fontSize: 11,
                              alignment: 'right',
                              margin: [0, 10, 8, 10],
                            },
                          ],
                          [
                            {},
                            {
                              text: [
                                periodChunks[criteriaOptionDetailsIndex][0]
                                  .title + '\n\n',
                                this.formatDateRange(
                                  periodChunks[criteriaOptionDetailsIndex][0],
                                ),
                              ],
                              color: '#9F9F9F',
                              fontSize: 11,
                              alignment: 'right',
                              margin: [0, 10, 8, 10],
                            },
                          ],
                          ...bodyRowsSingle,
                        ],
                      },
                      layout: {
                        hLineWidth: () => {
                          return 0.5;
                        },
                        vLineWidth: () => {
                          return 0.5;
                        },
                        hLineColor: () => {
                          return '#DBDBDB';
                        },
                        vLineColor: () => {
                          return '#DBDBDB';
                        },
                      },
                    };
                    const singleStack: any[] = [singleTableData];
                    const criteriaSingleTable = {
                      stack: singleStack,
                      unbreakable: true,
                    };
                    dirtyTables.push(criteriaSingleTable);
                  },
                );
                break;
              default:
                const complianceRow: any[] = [];
                const optionsList: string[] = [];
                const valueRows: any[] = [];
                const totalRow: number[][] = [];
                siteNameChunks.forEach(
                  (projectSiteName, projectSiteNameIndex) => {
                    const bodyRows: any[] = [];
                    const trueValueList: number[] = [];
                    const naValueList: number[] = [];
                    criteriaOptionDetails.forEach(
                      (
                        criteriaOptionDetail: any,
                        criteriaOptionDetailIndex: number,
                      ) => {
                        const siteOptionDetails =
                          criteriaOptionDetail.criteriaSamplingData[
                            projectSiteNameIndex
                          ];

                        const criteriaOptions = Object.keys(
                          siteOptionDetails.criteriaOptionsDataDistribution,
                        );
                        trueValueList.push(
                          siteOptionDetails.criteriaOptionsDataDistribution[
                            criteriaOptions[0]
                          ],
                        );
                        naValueList.push(
                          siteOptionDetails.criteriaOptionsDataDistribution[
                            criteriaOptions[2]
                          ],
                        );
                        criteriaOptions.forEach((option) => {
                          if (!optionsList.includes(option)) {
                            optionsList.push(option);
                          }
                        });
                        // tslint:disable-next-line:max-line-length
                        const siteCriteriaDetails: SamplingDataConfiguration = criteriaOptionDetail.siteSamplingConfiguration.find(
                          (samplingConfig: any) =>
                            samplingConfig.auditSiteMap.site.name ===
                            projectSiteName,
                        );

                        if (
                          criteriaOptionDetail.criteriaSamplingDataConfiguration
                            .length === 0 ||
                          siteCriteriaDetails === undefined ||
                          !siteCriteriaDetails.isSamplingEnabled
                        ) {
                          for (const [key] of Object.entries(
                            siteOptionDetails.criteriaOptionsDataDistribution,
                          )) {
                            siteOptionDetails.criteriaOptionsDataDistribution[
                              key
                            ] = '-';
                          }
                          siteOptionDetails.total = '-';
                        }

                        if (totalRow[criteriaOptionDetailIndex]) {
                          totalRow[criteriaOptionDetailIndex].push(
                            siteOptionDetails.total,
                          );
                        } else {
                          totalRow[criteriaOptionDetailIndex] = [
                            siteOptionDetails.total,
                          ];
                        }
                        if (valueRows[criteriaOptionDetailIndex]) {
                          valueRows[criteriaOptionDetailIndex] = {
                            ...siteOptionDetails.criteriaOptionsDataDistribution,
                          };
                        } else {
                          valueRows[criteriaOptionDetailIndex] =
                            siteOptionDetails.criteriaOptionsDataDistribution;
                        }
                        if (
                          criteriaOptionDetail.criteriaType ===
                          CRITERION_TYPE.BOOLEAN
                        ) {
                          let compliance: string | number = (
                            (trueValueList[criteriaOptionDetailIndex] /
                              (totalRow[criteriaOptionDetailIndex][
                                projectSiteNameIndex
                              ] -
                                naValueList[criteriaOptionDetailIndex])) *
                            100
                          ).toFixed(2);

                          if (compliance === 'NaN') {
                            compliance =
                              criteriaOptionDetail
                                .criteriaSamplingDataConfiguration.length &&
                              siteCriteriaDetails &&
                              siteCriteriaDetails.isSamplingEnabled
                                ? 0
                                : '-';
                          }

                          if (complianceRow[criteriaOptionDetailIndex]) {
                            complianceRow[criteriaOptionDetailIndex].push(
                              compliance ? compliance : 0,
                            );
                          } else {
                            complianceRow[criteriaOptionDetailIndex] = [
                              compliance ? compliance : 0,
                            ];
                          }
                        }
                      },
                    );
                    const periodOne = valueRows[0];
                    const periodTwo = valueRows[1];
                    optionsList.forEach((option, index) => {
                      const optionColumnOne = {
                        text: option,
                        color: '#444F51',
                        fontSize: 12,
                        border: [true, false, true, false],
                        margin:
                          index === optionsList.length - 1 - 1
                            ? [8, 6, 0, 6]
                            : [8, 6, 0, 0],
                      };
                      let periodOneValue: number | string;
                      if (periodOne && periodOne[option] !== undefined) {
                        periodOneValue = periodOne[option];
                      } else {
                        periodOneValue = option === 'N/A' ? 0 : '-';
                      }
                      const optionColumnTwo = {
                        text: periodOneValue,
                        color: '#444F51',
                        fontSize: 12,
                        border: [true, false, true, false],
                        alignment: 'right',
                        margin:
                          index === optionsList.length - 1 - 1
                            ? [0, 6, 8, 6]
                            : [0, 6, 8, 0],
                      };
                      let periodTwoValue: number | string;
                      if (periodTwo && periodTwo[option] !== undefined) {
                        periodTwoValue = periodTwo[option];
                      } else {
                        periodTwoValue = option === 'N/A' ? 0 : '-';
                      }
                      const optionColumnThree = {
                        text: periodTwoValue,
                        color: '#444F51',
                        fontSize: 12,
                        border: [true, false, true, false],
                        alignment: 'right',
                        margin:
                          index === optionsList.length - 1 - 1
                            ? [0, 6, 8, 6]
                            : [0, 6, 8, 0],
                      };
                      bodyRows.push([
                        optionColumnOne,
                        optionColumnTwo,
                        optionColumnThree,
                      ]);
                    });
                    const totalColumnOne = {
                      text: 'Total Data Collected',
                      color: '#444F51',
                      fontSize: 12,
                      margin: [8, 6, 0, 6],
                    };
                    const totalColumnTwo = {
                      text: totalRow[0][projectSiteNameIndex],
                      color: '#444F51',
                      fontSize: 12,
                      alignment: 'right',
                      margin: [0, 6, 8, 6],
                    };
                    const totalColumnThree = {
                      text: totalRow[1][projectSiteNameIndex],
                      color: '#444F51',
                      fontSize: 12,
                      alignment: 'right',
                      margin: [0, 6, 8, 6],
                    };
                    bodyRows.push([
                      totalColumnOne,
                      totalColumnTwo,
                      totalColumnThree,
                    ]);
                    if (
                      criteriaOptionDetails[0].criteriaType ===
                      CRITERION_TYPE.BOOLEAN
                    ) {
                      const complianceColumnOne = {
                        text: 'Compliance',
                        color: '#444F51',
                        fontSize: 12,
                        margin: [8, 6, 0, 6],
                        bold: true,
                      };
                      const complianceColumnTwo = {
                        text:
                          complianceRow[0][projectSiteNameIndex] !== '-'
                            ? complianceRow[0][projectSiteNameIndex] + '%'
                            : complianceRow[0][projectSiteNameIndex],
                        color: '#444F51',
                        fontSize: 12,
                        alignment: 'right',
                        bold: true,
                        margin: [0, 6, 8, 6],
                      };
                      const complianceColumnThree = {
                        text:
                          complianceRow[1][projectSiteNameIndex] !== '-'
                            ? complianceRow[1][projectSiteNameIndex] + '%'
                            : complianceRow[1][projectSiteNameIndex],
                        color: '#444F51',
                        fontSize: 12,
                        alignment: 'right',
                        bold: true,
                        margin: [0, 6, 8, 6],
                      };
                      bodyRows.push([
                        complianceColumnOne,
                        complianceColumnTwo,
                        complianceColumnThree,
                      ]);
                    }
                    const tableData = {
                      table: {
                        unbreakable: true,
                        widths: ['*', 150, 150],
                        body: [
                          [
                            {
                              text: 'Answer Choices',
                              color: '#9F9F9F',
                              fontSize: 11,
                              margin: [8, 10, 0, 10],
                              rowSpan: 2,
                            },
                            {
                              text: projectSiteName,
                              color: '#9F9F9F',
                              fontSize: 11,
                              alignment: 'right',
                              margin: [0, 10, 8, 10],
                              colSpan: 2,
                            },
                            {},
                          ],
                          [
                            {},
                            {
                              text: [
                                periodChunks[criteriaOptionDetailsIndex][0]
                                  .title + '\n\n',
                                this.formatDateRange(
                                  periodChunks[criteriaOptionDetailsIndex][0],
                                ),
                              ],
                              color: '#9F9F9F',
                              fontSize: 11,
                              alignment: 'right',
                              margin: [0, 10, 8, 10],
                            },
                            {
                              text: [
                                periodChunks[criteriaOptionDetailsIndex][1]
                                  .title + '\n\n',
                                this.formatDateRange(
                                  periodChunks[criteriaOptionDetailsIndex][1],
                                ),
                              ],
                              color: '#9F9F9F',
                              fontSize: 11,
                              alignment: 'right',
                              margin: [0, 10, 8, 10],
                            },
                          ],
                          ...bodyRows,
                        ],
                      },
                      layout: {
                        hLineWidth: () => {
                          return 0.5;
                        },
                        vLineWidth: () => {
                          return 0.5;
                        },
                        hLineColor: () => {
                          return '#DBDBDB';
                        },
                        vLineColor: () => {
                          return '#DBDBDB';
                        },
                      },
                    };
                    const stack: any[] = [tableData];
                    const criteriaTable = {
                      stack,
                      unbreakable: true,
                    };
                    dirtyTables.push(criteriaTable);
                  },
                );
                break;
            }
          },
        );

        const setMargin = {
          canvas: [],
          margin: [0, 0, 0, 13],
        };
        switch (dirtyTables.length) {
          case 1:
            dirtyTables[0].stack.unshift(title);
            dirtyTables[0].stack.push(setMargin);
            break;
          case 2:
            dirtyTables[0].stack.unshift(title);
            dirtyTables[0].stack.push(setMargin);
            break;
          default:
            dirtyTables.forEach((dirtyTable, dirtyTableIndex) => {
              switch (dirtyTableIndex) {
                case 0:
                  dirtyTable.stack.unshift(title);
                  dirtyTable.stack.push(setMargin);
                  break;
                case dirtyTables.length - 1:
                  dirtyTables[0].stack.push(setMargin);
                  break;
                default:
                  dirtyTable.stack.push(setMargin);
              }
            });
        }
        renderTables.push(dirtyTables);
      },
    );
    comparisonAggregateCriteriaConfiguration.forEach(
      (criteriaDetails, criteriaDetailsIndex) => {
        const criteriaChunks: any[] = [];
        const dirtyCriteriaDetails = _cloneDeep(criteriaDetails);
        while (dirtyCriteriaDetails.length) {
          criteriaChunks.push(dirtyCriteriaDetails.splice(0, 3));
        }
        const samplingTitle = {
          text: 'Sampling',
          bold: true,
          color: '#444F51',
          fontSize: 11,
          margin: [0, 13, 0, 17],
        };
        const dirtySamplingTables = this.getSamplingTables(
          criteriaChunks,
          samplingPeriodChunks,
          projectSites,
          samplingTitle,
          payload.filteredSites,
        );
        renderSamplingTables.push(dirtySamplingTables);
      },
    );
    return { aggregateChart, renderTables, renderSamplingTables };
  }

  public generateComparisonAggregateSiteIndividualCriteria(
    result: any,
    payload: any,
    projectSites: ProjectSite[],
    projectCriteria: ProjectCriteria[],
  ) {
    const allCharts: any[] = [];
    const renderBooleanTables: any[] = [];
    const renderBooleanSamplingTables: any[] = [];
    const renderMixedTables: any[] = [];
    const renderMixedSamplingTables: any[] = [];
    const dataSet: any[] = [];
    const dataSetOptions: any[] = [];
    const verticalBarChartDataSetOption: any = {
      animation: {
        duration: 0,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              max: 100,
              min: 0,
              fontSize: 20,
            },
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Compliance %',
              display: true,
              fontSize: 20,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Criteria',
              display: false,
            },
            ticks: {
              fontSize: 20,
              minRotation: 0,
              callback: (label: string, index: number) => {
                if (payload.checkIndividualCriteria) {
                  return this.transformLabel(label);
                } else {
                  if (payload.filteredCriterias.length > 4) {
                    return index + 1 + '.';
                  }
                  return this.transformLabel(label);
                }
              },
            },
          },
        ],
      },
      legend: {
        display: true,
        onHover: (e: any) => {
          e.target.style.cursor = 'pointer';
        },
        onLeave: (e: any) => {
          e.target.style.cursor = 'default';
        },
        labels: {
          boxWidth: 40,
          fontSize: 25,
        },
        align: 'start',
      },
      responsive: true,
      maintainAspectRatio: true,
      tooltips: {
        callbacks: {},
      },
    };
    const horizontalBarChartDataSetOption: any = {
      animation: {
        duration: 0,
      },
      scales: {
        xAxes: [
          {
            ticks: {
              beginAtZero: true,
              max: 100,
              min: 0,
              fontSize: 20,
            },
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Compliance %',
              display: true,
              fontSize: 20,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Criteria',
              display: false,
            },
            ticks: {
              fontSize: 20,
              minRotation: 0,
              callback: (label: string, index: number) => {
                if (payload.checkIndividualCriteria) {
                  return this.transformLabel(label);
                } else {
                  if (payload.filteredCriterias.length > 4) {
                    return index + 1 + '.';
                  }
                  return this.transformLabel(label);
                }
              },
            },
          },
        ],
      },
      legend: {
        display: true,
        onHover: (e: any) => {
          e.target.style.cursor = 'pointer';
        },
        onLeave: (e: any) => {
          e.target.style.cursor = 'default';
        },
        labels: {
          boxWidth: 40,
          fontSize: 25,
        },
        align: 'start',
      },
      responsive: true,
      maintainAspectRatio: true,
      tooltips: {
        callbacks: {},
      },
    };
    const chartData: any[][] = [];
    const statisticData: any[][] = [];
    const samplingConfigurationData: any[] = [];
    _map(result.comparisonResult, (comparisonResult: any) => {
      const dirtyChartData = comparisonResult.chartData;
      const dirtyStatisticData =
        comparisonResult.statisticData.configurationData;
      if (dirtyChartData.length > 0) {
        chartData.push(dirtyChartData);
      }
      statisticData.push(dirtyStatisticData);
      samplingConfigurationData.push(
        comparisonResult.statisticData.samplingConfiguration,
      );
    });
    const {
      comparisonAggregateCriteriaConfiguration,
      comparisonIndividualBooleanCriteriaConfiguration,
      comparisonIndividualMixedCriteriaConfiguration,
    } = this.generateComparisonConfigurationData(
      statisticData,
      payload,
      samplingConfigurationData,
    );
    let siteName: string;
    switch (payload.filteredSites.length) {
      case 1:
        siteName = chartData[0][0].data[0].site.name;
        break;
      case projectSites.length:
        siteName = 'All Sites';
        break;
      default:
        siteName = 'Selected sites (' + payload.filteredSites.length + ')';
    }
    const criteriaNames = [];
    const booleanFilteredCriteriaIds: number[] = this.getBooleanCriteriaIds(
      projectCriteria,
      payload,
    );
    _map(booleanFilteredCriteriaIds, (criteriaId: number) => {
      let criteriaName = '';
      _map(projectCriteria, (criteria) => {
        if (criteria.id === criteriaId) {
          criteriaName = criteria.title;
          criteriaNames.push(criteria.title);
        }
      });
      const data = {
        labels: [] as string[],
        datasets: [] as any[],
      };
      let dataOptions = {};
      let dataOptionsObject: any = {};
      Object.keys(chartData).forEach((key: string) => {
        const chartValues: number[] = [];
        const backgroundColors: string[] = [];
        const singleChartData = chartData[Number(key)];
        Object.keys(singleChartData).map((dataKey: string) => {
          if (
            singleChartData[Number(dataKey)].projectCriteriaId === criteriaId
          ) {
            chartValues.push(
              singleChartData[Number(dataKey)].data[0].compliance,
            );
          }
        });
        backgroundColors.push(chartColorSet[Number(key)]);
        const dataSetObject = {
          label: this.formatDateRange(payload.comparisonPeriods[Number(key)]),
          backgroundColor: backgroundColors,
          hoverBackgroundColor: backgroundColors,
          pointBackgroundColor: 'white',
          borderWidth: 1,
          pointBorderColor: '#249EBF',
          data: chartValues,
          categoryPercentage: this.getIndividualCriteriaAggregateSiteChartCategoryPercentage(
            payload,
          ),
        };
        data.datasets.push(dataSetObject);
      });
      data.labels = [siteName];
      if (payload.selectedChartType === ChartType.verticalBarChart) {
        dataOptionsObject = _cloneDeep(verticalBarChartDataSetOption);
      } else {
        dataOptionsObject = _cloneDeep(horizontalBarChartDataSetOption);
      }
      dataOptionsObject.tooltips.callbacks.title = () => criteriaName;
      dataOptionsObject.tooltips.callbacks.afterTitle = () => siteName;
      dataOptions = _cloneDeep(dataOptionsObject);
      dataSet.push(data);
      dataSetOptions.push(dataOptions);
    });
    dataSet.forEach((individualSet, individualSetIndex) => {
      const renderChart = {
        width: 443,
        height: 250,
        image: '',
        alignment: 'center',
        margin: [0, 0, 0, 30],
        unbreakable: true,
      };
      const canvas = document.createElement('canvas');
      document.body.appendChild(canvas);
      if (payload.selectedChartType === ChartType.verticalBarChart) {
        const verticalChart = new Chart(
          canvas.getContext('2d') as CanvasRenderingContext2D,
          {
            type: 'bar',
            data: {
              labels: individualSet.labels,
              datasets: individualSet.datasets,
            },
            options: dataSetOptions[individualSetIndex],
            plugins: [
              {
                afterDatasetDraw(chart: any, args: any) {
                  args.meta.data.forEach((element: any) => {
                    if (element._model.base - element._model.y < 0) {
                      const borderWidth = 8;
                      const ctx = chart.ctx;
                      const vm = element._view;
                      const half = vm.width / 2;
                      const left = vm.x - half;
                      const right = vm.x + half;
                      const top = vm.y - 2;
                      const width = right - left;
                      const height =
                        chart.chartArea.bottom - top + borderWidth / 2 - 1;
                      ctx.beginPath();
                      ctx.lineWidth = borderWidth;
                      ctx.strokeStyle = '#000000';
                      ctx.setLineDash([3, 4]);
                      ctx.moveTo(left, top);
                      ctx.lineTo(left, top + height);
                      ctx.moveTo(left, top);
                      ctx.lineTo(left + width, top);
                      ctx.moveTo(left + width, top);
                      ctx.lineTo(left + width, top + height);
                      ctx.stroke();
                      ctx.save();
                    }
                  });
                },
              },
            ],
          },
        );
        const base64Image = verticalChart.toBase64Image();
        renderChart.image = base64Image.split(',')[1];
      } else {
        const horizontalChart = new Chart(
          canvas.getContext('2d') as CanvasRenderingContext2D,
          {
            type: 'horizontalBar',
            data: {
              labels: individualSet.labels,
              datasets: individualSet.datasets,
            },
            options: dataSetOptions[individualSetIndex],
            plugins: [
              {
                afterDatasetDraw(chart: any, args: any) {
                  args.meta.data.forEach((element: any) => {
                    if (element._model.base - element._model.x > 0) {
                      const borderWidth = 8;
                      const ctx = chart.ctx;
                      const vm = element._view;
                      const half = vm.height / 2;
                      const top = vm.y - half;
                      const bottom = vm.y + half;
                      const right = vm.x + 10;
                      const left = chart.chartArea.left;
                      ctx.beginPath();
                      ctx.lineWidth = borderWidth;
                      ctx.strokeStyle = '#000000';
                      ctx.setLineDash([4, 5]);
                      ctx.moveTo(left, top);
                      ctx.lineTo(right, top);
                      ctx.moveTo(right, top);
                      ctx.lineTo(right, bottom);
                      ctx.moveTo(right, bottom);
                      ctx.lineTo(left, bottom);
                      ctx.stroke();
                      ctx.save();
                    }
                  });
                },
              },
            ],
          },
        );
        const base64Image = horizontalChart.toBase64Image();
        renderChart.image = base64Image.split(',')[1];
      }
      const stack: any[] = [renderChart];
      const criteriaChart = {
        stack,
        unbreakable: true,
      };
      allCharts.push(criteriaChart);
      document.body.removeChild(canvas);
    });
    // generate tables
    const periodChunks: any[] = [];
    const dirtyPeriodChunks = _cloneDeep(payload.comparisonPeriods);
    while (dirtyPeriodChunks.length) {
      periodChunks.push(dirtyPeriodChunks.splice(0, 2));
    }
    const samplingPeriodChunks: any[] = [];
    const dirtySamplingPeriodChunks = _cloneDeep(payload.comparisonPeriods);
    while (dirtySamplingPeriodChunks.length) {
      samplingPeriodChunks.push(dirtySamplingPeriodChunks.splice(0, 3));
    }
    comparisonIndividualBooleanCriteriaConfiguration.forEach(
      (booleanCriteriaDetails, booleanCriteriaDetailsIndex) => {
        const dirtyBooleanTables: any[] = [];
        const booleanCriteriaChunks: any[] = [];
        const dirtyBooleanCriteriaDetails = _cloneDeep(booleanCriteriaDetails);
        while (dirtyBooleanCriteriaDetails.length) {
          booleanCriteriaChunks.push(dirtyBooleanCriteriaDetails.splice(0, 2));
        }
        const title = {
          text:
            booleanCriteriaDetailsIndex +
            1 +
            '. ' +
            booleanCriteriaChunks[0][0].title,
          bold: true,
          color: '#444F51',
          fontSize: 11,
          margin: [0, 0, 0, 17],
        };
        booleanCriteriaChunks.forEach(
          (booleanCriteriaOptionDetails, booleanCriteriaOptionDetailsIndex) => {
            switch (booleanCriteriaOptionDetails.length) {
              case 1:
                const bodyRowsSinglePeriod: any[][] = [];
                booleanCriteriaOptionDetails[0].criteriaSamplingData.forEach(
                  (optionsData: any, optionDataIndex: number) => {
                    const rowDataSinglePeriod: any[] = [];
                    const optionSinglePeriod = Object.keys(optionsData)[0];
                    rowDataSinglePeriod.push({
                      text: optionSinglePeriod,
                      color: '#444F51',
                      fontSize: 12,
                      border: [true, false, true, false],
                      margin:
                        optionDataIndex ===
                        booleanCriteriaOptionDetails[0].criteriaSamplingData
                          .length -
                          1
                          ? [8, 6, 0, 6]
                          : [8, 6, 0, 0],
                    });
                    rowDataSinglePeriod.push({
                      text: booleanCriteriaOptionDetails[0]
                        .criteriaSamplingDataConfiguration.length
                        ? optionsData[optionSinglePeriod]
                        : '-',
                      color: '#444F51',
                      fontSize: 12,
                      border: [true, false, true, false],
                      alignment: 'right',
                      margin:
                        optionDataIndex ===
                        booleanCriteriaOptionDetails[0].criteriaSamplingData
                          .length -
                          1
                          ? [0, 6, 8, 6]
                          : [0, 6, 8, 0],
                    });
                    bodyRowsSinglePeriod.push(rowDataSinglePeriod);
                  },
                );
                let singlePeriodTotal = 0;
                let singlePeriodCompliance = 0;
                bodyRowsSinglePeriod.forEach((optionValues) => {
                  singlePeriodTotal += optionValues[1].text;
                });
                bodyRowsSinglePeriod.push([
                  {
                    text: 'Total Data Collected',
                    color: '#444F51',
                    fontSize: 12,
                    margin: [8, 6, 0, 6],
                  },
                  {
                    text: singlePeriodTotal,
                    color: '#444F51',
                    fontSize: 12,
                    alignment: 'right',
                    margin: [0, 6, 8, 6],
                  },
                ]);
                if (bodyRowsSinglePeriod[0][1].text === 0) {
                  bodyRowsSinglePeriod.push([
                    {
                      text: 'Compliance',
                      color: '#444F51',
                      fontSize: 12,
                      margin: [8, 6, 0, 6],
                      bold: true,
                    },
                    {
                      text: singlePeriodCompliance + '%',
                      color: '#444F51',
                      fontSize: 12,
                      alignment: 'right',
                      bold: true,
                      margin: [0, 6, 8, 6],
                    },
                  ]);
                } else {
                  singlePeriodCompliance =
                    (bodyRowsSinglePeriod[0][1].text /
                      (singlePeriodTotal - bodyRowsSinglePeriod[2][1].text)) *
                    100;
                  bodyRowsSinglePeriod.push([
                    {
                      text: 'Compliance',
                      color: '#444F51',
                      fontSize: 12,
                      margin: [8, 6, 0, 6],
                      bold: true,
                    },
                    {
                      text:
                        Math.round(singlePeriodCompliance * 100) / 100 + '%',
                      color: '#444F51',
                      fontSize: 12,
                      alignment: 'right',
                      bold: true,
                      margin: [0, 6, 8, 6],
                    },
                  ]);
                }
                const singlePeriodTableData = {
                  table: {
                    unbreakable: true,
                    widths: ['*', 150],
                    body: [
                      [
                        {
                          text: 'Answer Choices',
                          color: '#9F9F9F',
                          fontSize: 11,
                          margin: [8, 10, 0, 10],
                          rowSpan: 2,
                        },
                        {
                          text: siteName,
                          color: '#9F9F9F',
                          fontSize: 11,
                          alignment: 'right',
                          margin: [0, 10, 8, 10],
                        },
                      ],
                      [
                        {},
                        {
                          text: [
                            periodChunks[booleanCriteriaOptionDetailsIndex][0]
                              .title + '\n\n',
                            this.formatDateRange(
                              periodChunks[
                                booleanCriteriaOptionDetailsIndex
                              ][0],
                            ),
                          ],
                          color: '#9F9F9F',
                          fontSize: 11,
                          alignment: 'right',
                          margin: [0, 10, 8, 10],
                        },
                      ],
                      ...bodyRowsSinglePeriod,
                    ],
                  },
                  layout: {
                    hLineWidth: () => {
                      return 0.5;
                    },
                    vLineWidth: () => {
                      return 0.5;
                    },
                    hLineColor: () => {
                      return '#DBDBDB';
                    },
                    vLineColor: () => {
                      return '#DBDBDB';
                    },
                  },
                };
                const singlePeriodStack: any[] = [singlePeriodTableData];
                const criteriaSinglePeriodTable = {
                  stack: singlePeriodStack,
                  unbreakable: true,
                };
                dirtyBooleanTables.push(criteriaSinglePeriodTable);
                break;
              default:
                const bodyRows: any[] = [];
                const valueRows: any[] = [];
                const totalRow: number[] = [];
                const complianceRow: any[] = [];
                booleanCriteriaOptionDetails.forEach(
                  (detail: any, detailIndex: number) => {
                    const periodRow: any[] = [];
                    detail.criteriaSamplingData.forEach((optionsData: any) => {
                      const rowData: any = {
                        option: '',
                        value: null,
                      };
                      const option = Object.keys(optionsData)[0];
                      rowData.option = option;
                      rowData.value = optionsData[option];
                      if (
                        detail.criteriaSamplingDataConfiguration.length === 0
                      ) {
                        rowData.value = '-';
                      }
                      periodRow.push(rowData);
                    });
                    valueRows.push(periodRow);
                    let total: number | string = 0;
                    periodRow.forEach((optionValues) => {
                      if (
                        optionValues.value === '-' ||
                        detail.criteriaSamplingDataConfiguration.length === 0
                      ) {
                        total = '-';
                      } else {
                        total += optionValues.value;
                      }
                    });
                    totalRow.push(total);
                    let compliance = 0;
                    if (detail.criteriaType === CRITERION_TYPE.BOOLEAN) {
                      if (periodRow[0].value === 0) {
                        complianceRow.push(compliance);
                      } else {
                        if (
                          periodRow[0].value === '-' ||
                          detail.criteriaSamplingDataConfiguration.length === 0
                        ) {
                          complianceRow.push('-');
                        } else {
                          compliance =
                            (periodRow[0].value /
                              (total - periodRow[2].value)) *
                            100;
                          complianceRow.push(
                            Math.round(compliance * 100) / 100,
                          );
                        }
                      }
                    }
                  },
                );
                const periodOne = valueRows[0];
                const periodTwo = valueRows[1];
                for (let step = 0; step < periodOne.length; step++) {
                  const optionColumnOne = {
                    text: periodOne[step].option,
                    color: '#444F51',
                    fontSize: 12,
                    border: [true, false, true, false],
                    margin:
                      step === periodOne.length - 1
                        ? [8, 6, 0, 6]
                        : [8, 6, 0, 0],
                  };
                  const optionColumnTwo = {
                    text: periodOne[step].value,
                    color: '#444F51',
                    fontSize: 12,
                    border: [true, false, true, false],
                    alignment: 'right',
                    margin:
                      step === periodOne.length - 1
                        ? [0, 6, 8, 6]
                        : [0, 6, 8, 0],
                  };
                  const optionColumnThree = {
                    text: periodTwo[step].value,
                    color: '#444F51',
                    fontSize: 12,
                    border: [true, false, true, false],
                    alignment: 'right',
                    margin:
                      step === periodOne.length - 1
                        ? [0, 6, 8, 6]
                        : [0, 6, 8, 0],
                  };
                  bodyRows.push([
                    optionColumnOne,
                    optionColumnTwo,
                    optionColumnThree,
                  ]);
                }
                const totalColumnOne = {
                  text: 'Total Data Collected',
                  color: '#444F51',
                  fontSize: 12,
                  margin: [8, 6, 0, 6],
                };
                const totalColumnTwo = {
                  text: totalRow[0],
                  color: '#444F51',
                  fontSize: 12,
                  alignment: 'right',
                  margin: [0, 6, 8, 6],
                };
                const totalColumnThree = {
                  text: totalRow[1],
                  color: '#444F51',
                  fontSize: 12,
                  alignment: 'right',
                  margin: [0, 6, 8, 6],
                };
                bodyRows.push([
                  totalColumnOne,
                  totalColumnTwo,
                  totalColumnThree,
                ]);
                const complianceColumnOne = {
                  text: 'Compliance',
                  color: '#444F51',
                  fontSize: 12,
                  margin: [8, 6, 0, 6],
                  bold: true,
                };
                const complianceColumnTwo = {
                  text:
                    complianceRow[0] !== '-'
                      ? complianceRow[0] + '%'
                      : complianceRow[0],
                  color: '#444F51',
                  fontSize: 12,
                  alignment: 'right',
                  bold: true,
                  margin: [0, 6, 8, 6],
                };
                const complianceColumnThree = {
                  text:
                    complianceRow[1] !== '-'
                      ? complianceRow[1] + '%'
                      : complianceRow[1],
                  color: '#444F51',
                  fontSize: 12,
                  alignment: 'right',
                  bold: true,
                  margin: [0, 6, 8, 6],
                };
                bodyRows.push([
                  complianceColumnOne,
                  complianceColumnTwo,
                  complianceColumnThree,
                ]);
                const tableData = {
                  table: {
                    unbreakable: true,
                    widths: ['*', 150, 150],
                    body: [
                      [
                        {
                          text: 'Answer Choices',
                          color: '#9F9F9F',
                          fontSize: 11,
                          margin: [8, 10, 0, 10],
                          rowSpan: 2,
                        },
                        {
                          text: siteName,
                          color: '#9F9F9F',
                          fontSize: 11,
                          alignment: 'right',
                          margin: [0, 10, 8, 10],
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {},
                        {
                          text: [
                            periodChunks[booleanCriteriaOptionDetailsIndex][0]
                              .title + '\n\n',
                            this.formatDateRange(
                              periodChunks[
                                booleanCriteriaOptionDetailsIndex
                              ][0],
                            ),
                          ],
                          color: '#9F9F9F',
                          fontSize: 11,
                          alignment: 'right',
                          margin: [0, 10, 8, 10],
                        },
                        {
                          text: [
                            periodChunks[booleanCriteriaOptionDetailsIndex][1]
                              .title + '\n\n',
                            this.formatDateRange(
                              periodChunks[
                                booleanCriteriaOptionDetailsIndex
                              ][1],
                            ),
                          ],
                          color: '#9F9F9F',
                          fontSize: 11,
                          alignment: 'right',
                          margin: [0, 10, 8, 10],
                        },
                      ],
                      ...bodyRows,
                    ],
                  },
                  layout: {
                    hLineWidth: () => {
                      return 0.5;
                    },
                    vLineWidth: () => {
                      return 0.5;
                    },
                    hLineColor: () => {
                      return '#DBDBDB';
                    },
                    vLineColor: () => {
                      return '#DBDBDB';
                    },
                  },
                };
                const stack: any[] = [tableData];
                const criteriaTable = {
                  stack,
                  unbreakable: true,
                };
                dirtyBooleanTables.push(criteriaTable);
                break;
            }
          },
        );
        const setMargin = {
          canvas: [],
          margin: [0, 0, 0, 13],
        };
        allCharts[booleanCriteriaDetailsIndex].stack.unshift(title);
        switch (dirtyBooleanTables.length) {
          case 1:
            dirtyBooleanTables[0].stack.push(setMargin);
            break;
          case 2:
            dirtyBooleanTables[0].stack.push(setMargin);
            break;
          default:
            dirtyBooleanTables.forEach((dirtyBooleanTable, dirtyTableIndex) => {
              switch (dirtyTableIndex) {
                case 0:
                  dirtyBooleanTable.stack.push(setMargin);
                  break;
                case dirtyBooleanTables.length - 1:
                  dirtyBooleanTables[0].stack.push(setMargin);
                  break;
                default:
                  dirtyBooleanTable.stack.push(setMargin);
              }
            });
        }
        renderBooleanTables.push(dirtyBooleanTables);
      },
    );
    comparisonIndividualBooleanCriteriaConfiguration.forEach(
      (criteriaDetails, criteriaDetailsIndex) => {
        const criteriaChunks: any[] = [];
        const dirtyCriteriaDetails = _cloneDeep(criteriaDetails);
        while (dirtyCriteriaDetails.length) {
          criteriaChunks.push(dirtyCriteriaDetails.splice(0, 3));
        }
        const samplingTitle = {
          text: 'Sampling',
          bold: true,
          color: '#444F51',
          fontSize: 11,
          margin: [0, 13, 0, 17],
        };
        const dirtyBooleanSamplingTables = this.getSamplingTables(
          criteriaChunks,
          samplingPeriodChunks,
          projectSites,
          samplingTitle,
          payload.filteredSites,
        );
        renderBooleanSamplingTables.push(dirtyBooleanSamplingTables);
      },
    );
    if (comparisonIndividualMixedCriteriaConfiguration.length !== 0) {
      comparisonIndividualMixedCriteriaConfiguration.forEach(
        (mixedCriteriaDetails, mixedCriteriaDetailsIndex) => {
          const dirtyMixedTables: any[] = [];
          const mixedCriteriaChunks: any[] = [];
          const dirtyMixedCriteriaDetails = _cloneDeep(mixedCriteriaDetails);
          while (dirtyMixedCriteriaDetails.length) {
            mixedCriteriaChunks.push(dirtyMixedCriteriaDetails.splice(0, 2));
          }
          const title = {
            text:
              comparisonIndividualBooleanCriteriaConfiguration.length +
              mixedCriteriaDetailsIndex +
              1 +
              '. ' +
              mixedCriteriaChunks[0][0].title,
            bold: true,
            color: '#444F51',
            fontSize: 11,
            margin: [0, 0, 0, 17],
          };
          mixedCriteriaChunks.forEach(
            (mixedCriteriaOptionDetails, mixedCriteriaOptionDetailsIndex) => {
              switch (mixedCriteriaOptionDetails.length) {
                case 1:
                  const bodyRowsSinglePeriod: any[][] = [];
                  mixedCriteriaOptionDetails[0].criteriaSamplingData.forEach(
                    (optionsData: any, optionDataIndex: number) => {
                      const rowDataSinglePeriod: any[] = [];
                      const optionSinglePeriod = Object.keys(optionsData)[0];
                      rowDataSinglePeriod.push({
                        text: optionSinglePeriod,
                        color: '#444F51',
                        fontSize: 12,
                        border: [true, false, true, false],
                        margin:
                          optionDataIndex ===
                          mixedCriteriaOptionDetails[0].criteriaSamplingData
                            .length -
                            1
                            ? [8, 6, 0, 6]
                            : [8, 6, 0, 0],
                      });
                      rowDataSinglePeriod.push({
                        text: mixedCriteriaOptionDetails[0]
                          .criteriaSamplingDataConfiguration
                          ? optionsData[optionSinglePeriod]
                          : '-',
                        color: '#444F51',
                        fontSize: 12,
                        border: [true, false, true, false],
                        alignment: 'right',
                        margin:
                          optionDataIndex ===
                          mixedCriteriaOptionDetails[0].criteriaSamplingData
                            .length -
                            1
                            ? [0, 6, 8, 6]
                            : [0, 6, 8, 0],
                      });
                      bodyRowsSinglePeriod.push(rowDataSinglePeriod);
                    },
                  );
                  let singlePeriodTotal: number | string = 0;
                  bodyRowsSinglePeriod.forEach((optionValues) => {
                    if (optionValues[1].text === '-') {
                      singlePeriodTotal = '-';
                    } else {
                      singlePeriodTotal += optionValues[1].text;
                    }
                  });
                  bodyRowsSinglePeriod.push([
                    {
                      text: 'Total Data Collected',
                      color: '#444F51',
                      fontSize: 12,
                      margin: [8, 6, 0, 6],
                    },
                    {
                      text: singlePeriodTotal,
                      color: '#444F51',
                      fontSize: 12,
                      alignment: 'right',
                      margin: [0, 6, 8, 6],
                    },
                  ]);
                  const singlePeriodTableData = {
                    table: {
                      unbreakable: true,
                      widths: ['*', 150],
                      body: [
                        [
                          {
                            text: 'Answer Choices',
                            color: '#9F9F9F',
                            fontSize: 11,
                            margin: [8, 10, 0, 10],
                            rowSpan: 2,
                          },
                          {
                            text: siteName,
                            color: '#9F9F9F',
                            fontSize: 11,
                            alignment: 'right',
                            margin: [0, 10, 8, 10],
                          },
                        ],
                        [
                          {},
                          {
                            text: [
                              periodChunks[mixedCriteriaOptionDetailsIndex][0]
                                .title + '\n\n',
                              this.formatDateRange(
                                periodChunks[
                                  mixedCriteriaOptionDetailsIndex
                                ][0],
                              ),
                            ],
                            color: '#9F9F9F',
                            fontSize: 11,
                            alignment: 'right',
                            margin: [0, 10, 8, 10],
                          },
                        ],
                        ...bodyRowsSinglePeriod,
                      ],
                    },
                    layout: {
                      hLineWidth: () => {
                        return 0.5;
                      },
                      vLineWidth: () => {
                        return 0.5;
                      },
                      hLineColor: () => {
                        return '#DBDBDB';
                      },
                      vLineColor: () => {
                        return '#DBDBDB';
                      },
                    },
                  };
                  const singlePeriodStack: any[] = [singlePeriodTableData];
                  const criteriaSinglePeriodTable = {
                    stack: singlePeriodStack,
                    unbreakable: true,
                  };
                  dirtyMixedTables.push(criteriaSinglePeriodTable);
                  break;
                default:
                  const bodyRows: any[] = [];
                  const valueRows: any[] = [];
                  const totalRow: number[] = [];
                  const optionsList: string[] = [];
                  mixedCriteriaOptionDetails.forEach(
                    (detail: any, detailIndex: number) => {
                      let total: number | string = 0;
                      detail.criteriaSamplingData.forEach(
                        (optionsData: any) => {
                          const option = Object.keys(optionsData)[0];
                          if (!optionsList.includes(option)) {
                            optionsList.push(option);
                          }
                          total += optionsData[option];

                          if (
                            detail.criteriaSamplingDataConfiguration.length ===
                            0
                          ) {
                            optionsData[option] = '-';
                            total = '-';
                          }
                          if (valueRows[detailIndex]) {
                            valueRows[detailIndex] = {
                              ...valueRows[detailIndex],
                              ...optionsData,
                            };
                          } else {
                            valueRows[detailIndex] = optionsData;
                          }
                        },
                      );
                      totalRow.push(total);
                    },
                  );
                  const periodOne = valueRows[0];
                  const periodTwo = valueRows[1];
                  optionsList.forEach((option, index) => {
                    const optionColumnOne = {
                      text: option,
                      color: '#444F51',
                      fontSize: 12,
                      border: [true, false, true, false],
                      margin:
                        index === optionsList.length - 1
                          ? [8, 6, 0, 6]
                          : [8, 6, 0, 0],
                    };
                    let periodOneValue: number | string;
                    if (periodOne && periodOne[option] !== undefined) {
                      periodOneValue = periodOne[option];
                    } else {
                      periodOneValue = option === 'N/A' ? 0 : '-';
                    }
                    const optionColumnTwo = {
                      text: periodOneValue,
                      color: '#444F51',
                      fontSize: 12,
                      border: [true, false, true, false],
                      alignment: 'right',
                      margin:
                        index === optionsList.length - 1
                          ? [0, 6, 8, 6]
                          : [0, 6, 8, 0],
                    };
                    let periodTwoValue: number | string;
                    if (periodTwo && periodTwo[option] !== undefined) {
                      periodTwoValue = periodTwo[option];
                    } else {
                      periodTwoValue = option === 'N/A' ? 0 : '-';
                    }
                    const optionColumnThree = {
                      text: periodTwoValue,
                      color: '#444F51',
                      fontSize: 12,
                      border: [true, false, true, false],
                      alignment: 'right',
                      margin:
                        index === optionsList.length - 1
                          ? [0, 6, 8, 6]
                          : [0, 6, 8, 0],
                    };
                    bodyRows.push([
                      optionColumnOne,
                      optionColumnTwo,
                      optionColumnThree,
                    ]);
                  });
                  const totalColumnOne = {
                    text: 'Total Data Collected',
                    color: '#444F51',
                    fontSize: 12,
                    margin: [8, 6, 0, 6],
                  };
                  const totalColumnTwo = {
                    text: totalRow[0],
                    color: '#444F51',
                    fontSize: 12,
                    alignment: 'right',
                    margin: [0, 6, 8, 6],
                  };
                  const totalColumnThree = {
                    text: totalRow[1],
                    color: '#444F51',
                    fontSize: 12,
                    alignment: 'right',
                    margin: [0, 6, 8, 6],
                  };
                  bodyRows.push([
                    totalColumnOne,
                    totalColumnTwo,
                    totalColumnThree,
                  ]);
                  const tableData = {
                    table: {
                      unbreakable: true,
                      widths: ['*', 150, 150],
                      body: [
                        [
                          {
                            text: 'Answer Choices',
                            color: '#9F9F9F',
                            fontSize: 11,
                            margin: [8, 10, 0, 10],
                            rowSpan: 2,
                          },
                          {
                            text: siteName,
                            color: '#9F9F9F',
                            fontSize: 11,
                            alignment: 'right',
                            margin: [0, 10, 8, 10],
                            colSpan: 2,
                          },
                          {},
                        ],
                        [
                          {},
                          {
                            text: [
                              periodChunks[mixedCriteriaOptionDetailsIndex][0]
                                .title + '\n\n',
                              this.formatDateRange(
                                periodChunks[
                                  mixedCriteriaOptionDetailsIndex
                                ][0],
                              ),
                            ],
                            color: '#9F9F9F',
                            fontSize: 11,
                            alignment: 'right',
                            margin: [0, 10, 8, 10],
                          },
                          {
                            text: [
                              periodChunks[mixedCriteriaOptionDetailsIndex][1]
                                .title + '\n\n',
                              this.formatDateRange(
                                periodChunks[
                                  mixedCriteriaOptionDetailsIndex
                                ][1],
                              ),
                            ],
                            color: '#9F9F9F',
                            fontSize: 11,
                            alignment: 'right',
                            margin: [0, 10, 8, 10],
                          },
                        ],
                        ...bodyRows,
                      ],
                    },
                    layout: {
                      hLineWidth: () => {
                        return 0.5;
                      },
                      vLineWidth: () => {
                        return 0.5;
                      },
                      hLineColor: () => {
                        return '#DBDBDB';
                      },
                      vLineColor: () => {
                        return '#DBDBDB';
                      },
                    },
                  };
                  const stack: any[] = [tableData];
                  const criteriaTable = {
                    stack,
                    unbreakable: true,
                  };
                  dirtyMixedTables.push(criteriaTable);
                  break;
              }
            },
          );
          const setMargin = {
            canvas: [],
            margin: [0, 0, 0, 13],
          };
          switch (dirtyMixedTables.length) {
            case 1:
              dirtyMixedTables[0].stack.unshift(title);
              dirtyMixedTables[0].stack.push(setMargin);
              break;
            case 2:
              dirtyMixedTables[0].stack.unshift(title);
              dirtyMixedTables[0].stack.push(setMargin);
              break;
            default:
              dirtyMixedTables.forEach((dirtyMixedTable, dirtyTableIndex) => {
                switch (dirtyTableIndex) {
                  case 0:
                    dirtyMixedTable.stack.unshift(title);
                    dirtyMixedTable.stack.push(setMargin);
                    break;
                  case dirtyMixedTables.length - 1:
                    dirtyMixedTables[0].stack.push(setMargin);
                    break;
                  default:
                    dirtyMixedTable.stack.push(setMargin);
                }
              });
          }
          renderMixedTables.push(dirtyMixedTables);
        },
      );
      comparisonIndividualMixedCriteriaConfiguration.forEach(
        (criteriaDetails, criteriaDetailsIndex) => {
          const criteriaChunks: any[] = [];
          const dirtyCriteriaDetails = _cloneDeep(criteriaDetails);
          while (dirtyCriteriaDetails.length) {
            criteriaChunks.push(dirtyCriteriaDetails.splice(0, 3));
          }
          const samplingTitle = {
            text: 'Sampling',
            bold: true,
            color: '#444F51',
            fontSize: 11,
            margin: [0, 13, 0, 17],
          };
          const dirtyMixedSamplingTables = this.getSamplingTables(
            criteriaChunks,
            samplingPeriodChunks,
            projectSites,
            samplingTitle,
            payload.filteredSites,
          );
          renderMixedSamplingTables.push(dirtyMixedSamplingTables);
        },
      );
    }
    return {
      allCharts,
      renderBooleanTables,
      renderBooleanSamplingTables,
      renderMixedTables,
      renderMixedSamplingTables,
    };
  }

  public generateComparisonAgregateSiteAggregateCriteria(
    result: any,
    payload: any,
    projectSites: ProjectSite[],
  ) {
    const aggregateChart: any[] = [];
    const renderChart = {
      width: 443,
      height: 250,
      image: '',
      alignment: 'center',
      margin: [0, 0, 0, 30],
      unbreakable: true,
    };
    const renderTables: any[] = [];
    const renderSamplingTables: any[] = [];
    // generate chart
    const dataSet = [];
    const dataSetOptions = [];
    const verticalBarChartDataSetOption: any = {
      animation: {
        duration: 0,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              max: 100,
              min: 0,
              fontSize: 20,
            },
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Compliance %',
              display: true,
              fontSize: 20,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Criteria',
              display: false,
            },
            ticks: {
              fontSize: 20,
              minRotation: 0,
              callback: (label: string, index: number) => {
                if (payload.checkIndividualCriteria) {
                  return this.transformLabel(label);
                } else {
                  if (payload.filteredCriterias.length > 4) {
                    return index + 1 + '.';
                  }
                  return this.transformLabel(label);
                }
              },
            },
          },
        ],
      },
      legend: {
        display: true,
        onHover: (e: any) => {
          e.target.style.cursor = 'pointer';
        },
        onLeave: (e: any) => {
          e.target.style.cursor = 'default';
        },
        labels: {
          boxWidth: 40,
          fontSize: 25,
        },
        align: 'start',
      },
      responsive: true,
      maintainAspectRatio: true,
      tooltips: {
        callbacks: {},
      },
    };
    const horizontalBarChartDataSetOption: any = {
      animation: {
        duration: 0,
      },
      scales: {
        xAxes: [
          {
            ticks: {
              beginAtZero: true,
              max: 100,
              min: 0,
              fontSize: 20,
            },
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Compliance %',
              display: true,
              fontSize: 20,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Criteria',
              display: false,
            },
            ticks: {
              fontSize: 20,
              minRotation: 0,
              callback: (label: string, index: number) => {
                if (payload.checkIndividualCriteria) {
                  return this.transformLabel(label);
                } else {
                  if (payload.filteredCriterias.length > 4) {
                    return index + 1 + '.';
                  }
                  return this.transformLabel(label);
                }
              },
            },
          },
        ],
      },
      legend: {
        display: true,
        onHover: (e: any) => {
          e.target.style.cursor = 'pointer';
        },
        onLeave: (e: any) => {
          e.target.style.cursor = 'default';
        },
        labels: {
          boxWidth: 40,
          fontSize: 25,
        },
        align: 'start',
      },
      responsive: true,
      maintainAspectRatio: true,
      tooltips: {
        callbacks: {},
      },
    };
    const chartData: any[][] = [];
    const statisticData: any[][] = [];
    const samplingConfigurationData: any[] = [];
    _map(result.comparisonResult, (comparisonResult: any) => {
      const dirtyChartData = comparisonResult.chartData;
      const dirtyStatisticData =
        comparisonResult.statisticData.configurationData;
      if (dirtyChartData.length > 0) {
        chartData.push(dirtyChartData);
      }
      statisticData.push(dirtyStatisticData);
      samplingConfigurationData.push(
        comparisonResult.statisticData.samplingConfiguration,
      );
    });
    const {
      comparisonAggregateCriteriaConfiguration,
      comparisonIndividualBooleanCriteriaConfiguration,
      comparisonIndividualMixedCriteriaConfiguration,
    } = this.generateComparisonConfigurationData(
      statisticData,
      payload,
      samplingConfigurationData,
    );
    let siteName: string;
    switch (payload.filteredSites.length) {
      case 1:
        siteName = chartData[0][0].data[0].site.name;
        break;
      case projectSites.length:
        siteName = 'All Sites';
        break;
      default:
        siteName = 'Selected sites (' + payload.filteredSites.length + ')';
    }
    const data = {
      labels: [] as string[],
      datasets: [] as any[],
    };
    let dataOptions = {};
    const labels: string[] = [];
    let dataOptionsObject: any = {};
    Object.keys(chartData).map((key: string) => {
      const chartValues: number[] = [];
      const backgroundColors: string[] = [];
      const singleChartData = chartData[Number(key)];
      Object.keys(singleChartData).map((dataKey: string) => {
        if (Number(key) === 0) {
          labels.push(singleChartData[Number(dataKey)].criteria.title);
        }
        chartValues.push(singleChartData[Number(dataKey)].data[0].compliance);
        backgroundColors.push(chartColorSet[Number(key)]);
      });
      const dataSetObject = {
        label: this.formatDateRange(payload.comparisonPeriods[Number(key)]),
        backgroundColor: backgroundColors,
        hoverBackgroundColor: backgroundColors,
        pointBackgroundColor: 'white',
        borderWidth: 1,
        pointBorderColor: '#249EBF',
        data: chartValues,
        categoryPercentage: this.getAggregateCriteriaAggregateSiteChartCategoryPercentage(
          comparisonIndividualBooleanCriteriaConfiguration,
          payload,
        ),
      };
      data.datasets.push(dataSetObject);
    });
    if (payload.selectedChartType === ChartType.verticalBarChart) {
      dataOptionsObject = _cloneDeep(verticalBarChartDataSetOption);
    } else {
      dataOptionsObject = _cloneDeep(horizontalBarChartDataSetOption);
    }
    dataOptionsObject.tooltips.callbacks.afterTitle = () => siteName;
    data.labels = _cloneDeep(labels);
    dataOptions = _cloneDeep(dataOptionsObject);
    dataSet.push(data);
    dataSetOptions.push(dataOptions);
    const canvas = document.createElement('canvas');
    document.body.appendChild(canvas);
    if (payload.selectedChartType === ChartType.verticalBarChart) {
      const verticalChart = new Chart(
        canvas.getContext('2d') as CanvasRenderingContext2D,
        {
          type: 'bar',
          data: {
            labels: dataSet[0].labels,
            datasets: dataSet[0].datasets,
          },
          options: dataSetOptions[0],
          plugins: [
            {
              afterDatasetDraw(chart: any, args: any) {
                args.meta.data.forEach((element: any) => {
                  if (element._model.base - element._model.y < 0) {
                    const borderWidth = 8;
                    const ctx = chart.ctx;
                    const vm = element._view;
                    const half = vm.width / 2;
                    const left = vm.x - half;
                    const right = vm.x + half;
                    const top = vm.y - 2;
                    const width = right - left;
                    const height =
                      chart.chartArea.bottom - top + borderWidth / 2 - 1;
                    ctx.beginPath();
                    ctx.lineWidth = borderWidth;
                    ctx.strokeStyle = '#000000';
                    ctx.setLineDash([3, 4]);
                    ctx.moveTo(left, top);
                    ctx.lineTo(left, top + height);
                    ctx.moveTo(left, top);
                    ctx.lineTo(left + width, top);
                    ctx.moveTo(left + width, top);
                    ctx.lineTo(left + width, top + height);
                    ctx.stroke();
                    ctx.save();
                  }
                });
              },
            },
          ],
        },
      );
      const base64Image = verticalChart.toBase64Image();
      renderChart.image = base64Image.split(',')[1];
    } else {
      const horizontalChart = new Chart(
        canvas.getContext('2d') as CanvasRenderingContext2D,
        {
          type: 'horizontalBar',
          data: {
            labels: dataSet[0].labels,
            datasets: dataSet[0].datasets,
          },
          options: dataSetOptions[0],
          plugins: [
            {
              afterDatasetDraw(chart: any, args: any) {
                args.meta.data.forEach((element: any) => {
                  if (element._model.base - element._model.x > 0) {
                    const borderWidth = 8;
                    const ctx = chart.ctx;
                    const vm = element._view;
                    const half = vm.height / 2;
                    const top = vm.y - half;
                    const bottom = vm.y + half;
                    const right = vm.x + 10;
                    const left = chart.chartArea.left;
                    ctx.beginPath();
                    ctx.lineWidth = borderWidth;
                    ctx.strokeStyle = '#000000';
                    ctx.setLineDash([4, 5]);
                    ctx.moveTo(left, top);
                    ctx.lineTo(right, top);
                    ctx.moveTo(right, top);
                    ctx.lineTo(right, bottom);
                    ctx.moveTo(right, bottom);
                    ctx.lineTo(left, bottom);
                    ctx.stroke();
                    ctx.save();
                  }
                });
              },
            },
          ],
        },
      );
      const base64Image = horizontalChart.toBase64Image();
      renderChart.image = base64Image.split(',')[1];
    }
    const criteriaNamesText: any[] = labels.map((label) => {
      return {
        text: label,
        color: '#444F51',
        margin: [10, 5, 0, 2],
      };
    });
    const names = {
      layout: 'noBorders',
      table: {
        widths: ['*'],
        body: [
          [
            {
              ol: criteriaNamesText,
              fontSize: 9,
              fillColor: '#EFF7FF',
              markerColor: '#444F51',
            },
          ],
        ],
      },
      margin: [10, 0, 0, 23],
    };
    let aggregateStack: any[];
    if (payload.filteredCriterias.length > 4) {
      renderChart.margin = [0, 0, 0, 15];
      aggregateStack = [renderChart, names];
    } else {
      aggregateStack = [renderChart];
    }
    const criteriaChart = {
      stack: aggregateStack,
      unbreakable: false,
    };
    aggregateChart.push(criteriaChart);
    document.body.removeChild(canvas);
    // generate tables
    const periodChunks: any[] = [];
    const dirtyPeriodChunks = _cloneDeep(payload.comparisonPeriods);
    while (dirtyPeriodChunks.length) {
      periodChunks.push(dirtyPeriodChunks.splice(0, 2));
    }
    const samplingPeriodChunks: any[] = [];
    const dirtySamplingPeriodChunks = _cloneDeep(payload.comparisonPeriods);
    while (dirtySamplingPeriodChunks.length) {
      samplingPeriodChunks.push(dirtySamplingPeriodChunks.splice(0, 3));
    }
    comparisonAggregateCriteriaConfiguration.forEach(
      (criteriaDetails, criteriaDetailsIndex) => {
        const dirtyTables: any[] = [];
        const criteriaChunks: any[] = [];
        const dirtyCriteriaDetails = _cloneDeep(criteriaDetails);
        while (dirtyCriteriaDetails.length) {
          criteriaChunks.push(dirtyCriteriaDetails.splice(0, 2));
        }
        const title = {
          text: criteriaDetailsIndex + 1 + '. ' + criteriaChunks[0][0].title,
          bold: true,
          color: '#444F51',
          fontSize: 11,
          margin: [0, 0, 0, 17],
        };
        criteriaChunks.forEach(
          (criteriaOptionDetails, criteriaOptionDetailsIndex) => {
            switch (criteriaOptionDetails.length) {
              case 1:
                const bodyRowsSinglePeriod: any[][] = [];
                criteriaOptionDetails[0].criteriaSamplingData.forEach(
                  (optionsData: any, optionDataIndex: number) => {
                    const rowDataSinglePeriod: any[] = [];
                    const optionSinglePeriod = Object.keys(optionsData)[0];
                    rowDataSinglePeriod.push({
                      text: optionSinglePeriod,
                      color: '#444F51',
                      fontSize: 12,
                      border: [true, false, true, false],
                      margin:
                        optionDataIndex ===
                        criteriaOptionDetails[0].criteriaSamplingData.length - 1
                          ? [8, 6, 0, 6]
                          : [8, 6, 0, 0],
                    });
                    rowDataSinglePeriod.push({
                      text: criteriaOptionDetails[0]
                        .criteriaSamplingDataConfiguration.length
                        ? optionsData[optionSinglePeriod]
                        : '-',
                      color: '#444F51',
                      fontSize: 12,
                      border: [true, false, true, false],
                      alignment: 'right',
                      margin:
                        optionDataIndex ===
                        criteriaOptionDetails[0].criteriaSamplingData.length - 1
                          ? [0, 6, 8, 6]
                          : [0, 6, 8, 0],
                    });
                    bodyRowsSinglePeriod.push(rowDataSinglePeriod);
                  },
                );
                let singlePeriodTotal: number | string = 0;
                let singlePeriodCompliance: any = 0;
                bodyRowsSinglePeriod.forEach((optionValues) => {
                  if (optionValues[1].text === '-') {
                    singlePeriodTotal = '-';
                  } else {
                    singlePeriodTotal += optionValues[1].text;
                  }
                });
                bodyRowsSinglePeriod.push([
                  {
                    text: 'Total Data Collected',
                    color: '#444F51',
                    fontSize: 12,
                    margin: [8, 6, 0, 6],
                  },
                  {
                    text: singlePeriodTotal,
                    color: '#444F51',
                    fontSize: 12,
                    alignment: 'right',
                    margin: [0, 6, 8, 6],
                  },
                ]);
                if (
                  criteriaOptionDetails[0].criteriaType ===
                  CRITERION_TYPE.BOOLEAN
                ) {
                  if (bodyRowsSinglePeriod[0][1].text === 0) {
                    bodyRowsSinglePeriod.push([
                      {
                        text: 'Compliance',
                        color: '#444F51',
                        fontSize: 12,
                        margin: [8, 6, 0, 6],
                        bold: true,
                      },
                      {
                        text: singlePeriodCompliance + '%',
                        color: '#444F51',
                        fontSize: 12,
                        alignment: 'right',
                        bold: true,
                        margin: [0, 6, 8, 6],
                      },
                    ]);
                  } else {
                    if (bodyRowsSinglePeriod[0][1].text === '-') {
                      singlePeriodCompliance = '-';
                    } else {
                      singlePeriodCompliance =
                        (bodyRowsSinglePeriod[0][1].text /
                          (singlePeriodTotal -
                            bodyRowsSinglePeriod[2][1].text)) *
                        100;
                    }
                    bodyRowsSinglePeriod.push([
                      {
                        text: 'Compliance',
                        color: '#444F51',
                        fontSize: 12,
                        margin: [8, 6, 0, 6],
                        bold: true,
                      },
                      {
                        text:
                          singlePeriodCompliance !== '-'
                            ? Math.round(singlePeriodCompliance * 100) / 100 +
                              '%'
                            : singlePeriodCompliance,
                        color: '#444F51',
                        fontSize: 12,
                        alignment: 'right',
                        bold: true,
                        margin: [0, 6, 8, 6],
                      },
                    ]);
                  }
                }
                const singlePeriodTableData = {
                  table: {
                    unbreakable: true,
                    widths: ['*', 150],
                    body: [
                      [
                        {
                          text: 'Answer Choices',
                          color: '#9F9F9F',
                          fontSize: 11,
                          margin: [8, 10, 0, 10],
                          rowSpan: 2,
                        },
                        {
                          text: siteName,
                          color: '#9F9F9F',
                          fontSize: 11,
                          alignment: 'right',
                          margin: [0, 10, 8, 10],
                        },
                      ],
                      [
                        {},
                        {
                          text: [
                            periodChunks[criteriaOptionDetailsIndex][0].title +
                              '\n\n',
                            this.formatDateRange(
                              periodChunks[criteriaOptionDetailsIndex][0],
                            ),
                          ],
                          color: '#9F9F9F',
                          fontSize: 11,
                          alignment: 'right',
                          margin: [0, 10, 8, 10],
                        },
                      ],
                      ...bodyRowsSinglePeriod,
                    ],
                  },
                  layout: {
                    hLineWidth: () => {
                      return 0.5;
                    },
                    vLineWidth: () => {
                      return 0.5;
                    },
                    hLineColor: () => {
                      return '#DBDBDB';
                    },
                    vLineColor: () => {
                      return '#DBDBDB';
                    },
                  },
                };
                const singlePeriodStack: any[] = [singlePeriodTableData];
                const criteriaSinglePeriodTable = {
                  stack: singlePeriodStack,
                  unbreakable: true,
                };
                dirtyTables.push(criteriaSinglePeriodTable);
                break;
              default:
                const bodyRows: any[] = [];
                const valueRows: any[] = [];
                const totalRow: number[] = [];
                const complianceRow: any[] = [];
                const optionsList: string[] = [];
                criteriaOptionDetails.forEach(
                  (detail: any, detailIndex: number) => {
                    let total: number | string = 0;
                    let trueValue: number = 0;
                    let naValue: number = 0;
                    detail.criteriaSamplingData.forEach(
                      (optionsData: any, optionDataIndex: number) => {
                        const option = Object.keys(optionsData)[0];
                        if (!optionsList.includes(option)) {
                          optionsList.push(option);
                        }
                        switch (optionDataIndex) {
                          case 0:
                            trueValue = optionsData[option];
                            break;
                          case 1:
                            break;
                          default:
                            naValue = optionsData[option];
                        }
                        total += optionsData[option];
                        if (
                          detail.criteriaSamplingDataConfiguration.length ===
                            0 ||
                          total.toString().includes('-')
                        ) {
                          optionsData[option] = '-';
                          total = '-';
                        }
                        if (valueRows[detailIndex]) {
                          valueRows[detailIndex] = {
                            ...valueRows[detailIndex],
                            ...optionsData,
                          };
                        } else {
                          valueRows[detailIndex] = optionsData;
                        }
                      },
                    );
                    totalRow.push(total);
                    if (detail.criteriaType === CRITERION_TYPE.BOOLEAN) {
                      let compliance: number | string = (
                        (trueValue / (total - naValue)) *
                        100
                      ).toFixed(2);
                      if (
                        compliance === 'NaN' ||
                        total.toString().includes('-')
                      ) {
                        compliance =
                          detail.criteriaSamplingDataConfiguration.length &&
                          !total.toString().includes('-')
                            ? 0
                            : '-';
                      }
                      complianceRow.push(compliance ? compliance : 0);
                    }
                  },
                );
                const periodOne = valueRows[0];
                const periodTwo = valueRows[1];
                optionsList.forEach((option, index) => {
                  const optionColumnOne = {
                    text: option,
                    color: '#444F51',
                    fontSize: 12,
                    border: [true, false, true, false],
                    margin:
                      index === optionsList.length - 1
                        ? [8, 6, 0, 6]
                        : [8, 6, 0, 0],
                  };
                  let periodOneValue: number | string;
                  if (periodOne && periodOne[option] !== undefined) {
                    periodOneValue = periodOne[option];
                  } else {
                    periodOneValue = option === 'N/A' ? 0 : '-';
                  }
                  const optionColumnTwo = {
                    text: periodOneValue,
                    color: '#444F51',
                    fontSize: 12,
                    border: [true, false, true, false],
                    alignment: 'right',
                    margin:
                      index === optionsList.length - 1
                        ? [0, 6, 8, 6]
                        : [0, 6, 8, 0],
                  };
                  let periodTwoValue: number | string;
                  if (periodTwo && periodTwo[option] !== undefined) {
                    periodTwoValue = periodTwo[option];
                  } else {
                    periodTwoValue = option === 'N/A' ? 0 : '-';
                  }
                  const optionColumnThree = {
                    text: periodTwoValue,
                    color: '#444F51',
                    fontSize: 12,
                    border: [true, false, true, false],
                    alignment: 'right',
                    margin:
                      index === optionsList.length - 1
                        ? [0, 6, 8, 6]
                        : [0, 6, 8, 0],
                  };
                  bodyRows.push([
                    optionColumnOne,
                    optionColumnTwo,
                    optionColumnThree,
                  ]);
                });
                const totalColumnOne = {
                  text: 'Total Data Collected',
                  color: '#444F51',
                  fontSize: 12,
                  margin: [8, 6, 0, 6],
                };
                const totalColumnTwo = {
                  text: totalRow[0],
                  color: '#444F51',
                  fontSize: 12,
                  alignment: 'right',
                  margin: [0, 6, 8, 6],
                };
                const totalColumnThree = {
                  text: totalRow[1],
                  color: '#444F51',
                  fontSize: 12,
                  alignment: 'right',
                  margin: [0, 6, 8, 6],
                };
                bodyRows.push([
                  totalColumnOne,
                  totalColumnTwo,
                  totalColumnThree,
                ]);
                if (
                  criteriaOptionDetails[0].criteriaType ===
                  CRITERION_TYPE.BOOLEAN
                ) {
                  const complianceColumnOne = {
                    text: 'Compliance',
                    color: '#444F51',
                    fontSize: 12,
                    margin: [8, 6, 0, 6],
                    bold: true,
                  };
                  const complianceColumnTwo = {
                    text:
                      complianceRow[0] !== '-'
                        ? complianceRow[0] + '%'
                        : complianceRow[0],
                    color: '#444F51',
                    fontSize: 12,
                    alignment: 'right',
                    bold: true,
                    margin: [0, 6, 8, 6],
                  };
                  const complianceColumnThree = {
                    text:
                      complianceRow[1] !== '-'
                        ? complianceRow[1] + '%'
                        : complianceRow[1],
                    color: '#444F51',
                    fontSize: 12,
                    alignment: 'right',
                    bold: true,
                    margin: [0, 6, 8, 6],
                  };
                  bodyRows.push([
                    complianceColumnOne,
                    complianceColumnTwo,
                    complianceColumnThree,
                  ]);
                }
                const tableData = {
                  table: {
                    unbreakable: true,
                    widths: ['*', 150, 150],
                    body: [
                      [
                        {
                          text: 'Answer Choices',
                          color: '#9F9F9F',
                          fontSize: 11,
                          margin: [8, 10, 0, 10],
                          rowSpan: 2,
                        },
                        {
                          text: siteName,
                          color: '#9F9F9F',
                          fontSize: 11,
                          alignment: 'right',
                          margin: [0, 10, 8, 10],
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {},
                        {
                          text: [
                            periodChunks[criteriaOptionDetailsIndex][0].title +
                              '\n\n',
                            this.formatDateRange(
                              periodChunks[criteriaOptionDetailsIndex][0],
                            ),
                          ],
                          color: '#9F9F9F',
                          fontSize: 11,
                          alignment: 'right',
                          margin: [0, 10, 8, 10],
                        },
                        {
                          text: [
                            periodChunks[criteriaOptionDetailsIndex][1].title +
                              '\n\n',
                            this.formatDateRange(
                              periodChunks[criteriaOptionDetailsIndex][1],
                            ),
                          ],
                          color: '#9F9F9F',
                          fontSize: 11,
                          alignment: 'right',
                          margin: [0, 10, 8, 10],
                        },
                      ],
                      ...bodyRows,
                    ],
                  },
                  layout: {
                    hLineWidth: () => {
                      return 0.5;
                    },
                    vLineWidth: () => {
                      return 0.5;
                    },
                    hLineColor: () => {
                      return '#DBDBDB';
                    },
                    vLineColor: () => {
                      return '#DBDBDB';
                    },
                  },
                };
                const stack: any[] = [tableData];
                const criteriaTable = {
                  stack,
                  unbreakable: true,
                };
                dirtyTables.push(criteriaTable);
                break;
            }
          },
        );
        const setMargin = {
          canvas: [],
          margin: [0, 0, 0, 13],
        };
        switch (dirtyTables.length) {
          case 1:
            dirtyTables[0].stack.unshift(title);
            dirtyTables[0].stack.push(setMargin);
            break;
          case 2:
            dirtyTables[0].stack.unshift(title);
            dirtyTables[0].stack.push(setMargin);
            break;
          default:
            dirtyTables.forEach((dirtyTable, dirtyTableIndex) => {
              switch (dirtyTableIndex) {
                case 0:
                  dirtyTable.stack.unshift(title);
                  dirtyTable.stack.push(setMargin);
                  break;
                case dirtyTables.length - 1:
                  dirtyTables[0].stack.push(setMargin);
                  break;
                default:
                  dirtyTable.stack.push(setMargin);
              }
            });
        }
        renderTables.push(dirtyTables);
      },
    );

    comparisonAggregateCriteriaConfiguration.forEach(
      (criteriaDetails, criteriaDetailsIndex) => {
        const criteriaChunks: any[] = [];
        const dirtyCriteriaDetails = _cloneDeep(criteriaDetails);
        while (dirtyCriteriaDetails.length) {
          criteriaChunks.push(dirtyCriteriaDetails.splice(0, 3));
        }
        const samplingTitle = {
          text: 'Sampling',
          bold: true,
          color: '#444F51',
          fontSize: 11,
          margin: [0, 13, 0, 17],
        };
        const dirtySamplingTables = this.getSamplingTables(
          criteriaChunks,
          samplingPeriodChunks,
          projectSites,
          samplingTitle,
          payload.filteredSites,
        );
        renderSamplingTables.push(dirtySamplingTables);
      },
    );
    return { aggregateChart, renderTables, renderSamplingTables };
  }

  public getSelectedSiteDetails(
    projectSites: ProjectSite[],
    filteredSites: number[],
  ): ProjectSite[] {
    return projectSites.filter((projectSiteDetail) =>
      filteredSites.includes(projectSiteDetail.id),
    );
  }

  public getSamplingTables(
    criteriaChunks: any[],
    samplingPeriodChunks: any[],
    projectSites: ProjectSite[],
    samplingTitle: any,
    filteredSites: number[],
  ) {
    const dirtySamplingTables: any[] = [];
    criteriaChunks.forEach(
      (criteriaOptionDetails: any, criteriaOptionDetailsIndex: number) => {
        switch (criteriaOptionDetails.length) {
          case 1:
            const bodyRowsPrimary: any[] = [];
            let totalColumnPrimary: number = 0;
            const periodHeaderRowPrimary: any[] = [];
            const titleHeaderRowPrimary: any[] = [];
            const transformedSamplingDataPrimary: any[] = [];
            const samplingDataRowsPrimary: any[] = [];
            const dirtySamplingDataRowsPrimary: any[] = [];
            criteriaOptionDetails.forEach(
              (detail: any, detailIndex: number) => {
                let colSpanValue = 0;
                switch (detail.samplingConfiguration.auditSamplingType) {
                  case 'adHoc':
                    titleHeaderRowPrimary.push({
                      text: 'Site',
                      color: '#444F51',
                      fontSize: 9,
                      margin: [8, 6, 8, 6],
                    });
                    titleHeaderRowPrimary.push({
                      text: 'Sampling',
                      color: '#444F51',
                      fontSize: 9,
                      alignment: 'right',
                      margin: [8, 6, 8, 6],
                    });
                    totalColumnPrimary += 2;
                    colSpanValue = 2;
                    const dirtyAdHocSamplingRows: ReportSamplingDto[] = getAdHocSampling(
                      samplingPeriodChunks[criteriaOptionDetailsIndex][
                        detailIndex
                      ].isAudit,
                      detail.isAuditCriteria,
                      detail,
                      this.getSelectedSiteDetails(projectSites, filteredSites),
                    );
                    dirtySamplingDataRowsPrimary.push(
                      ...dirtyAdHocSamplingRows,
                    );
                    break;
                  case 'consecutive':
                    if (
                      detail.samplingConfiguration.samplingMode === 'minAndMax'
                    ) {
                      if (
                        samplingPeriodChunks[criteriaOptionDetailsIndex][
                          detailIndex
                        ].isAudit
                      ) {
                        titleHeaderRowPrimary.push({
                          text: 'Site',
                          color: '#444F51',
                          fontSize: 9,
                          margin: [8, 6, 8, 6],
                        });
                        titleHeaderRowPrimary.push({
                          text: 'Min.',
                          color: '#444F51',
                          fontSize: 9,
                          alignment: 'right',
                          margin: [8, 6, 8, 6],
                        });
                        titleHeaderRowPrimary.push({
                          text: 'Max.',
                          color: '#444F51',
                          fontSize: 9,
                          alignment: 'right',
                          margin: [8, 6, 8, 6],
                        });
                        totalColumnPrimary += 3;
                        colSpanValue = 3;
                      } else {
                        titleHeaderRowPrimary.push({
                          text: 'Site',
                          color: '#444F51',
                          fontSize: 9,
                          margin: [8, 6, 8, 6],
                        });
                        titleHeaderRowPrimary.push({
                          text: 'Sampling',
                          color: '#444F51',
                          fontSize: 9,
                          alignment: 'right',
                          margin: [8, 6, 8, 6],
                        });
                        totalColumnPrimary += 2;
                        colSpanValue = 2;
                      }
                      const dirtyConsecutiveMinMaxSamplingRows: ReportSamplingDto[] = getConsecutiveMinMaxSampling(
                        samplingPeriodChunks[criteriaOptionDetailsIndex][
                          detailIndex
                        ].isAudit,
                        detail.isAuditCriteria,
                        detail,
                        this.getSelectedSiteDetails(
                          projectSites,
                          filteredSites,
                        ),
                      );
                      dirtySamplingDataRowsPrimary.push(
                        ...dirtyConsecutiveMinMaxSamplingRows,
                      );
                    } else {
                      if (
                        samplingPeriodChunks[criteriaOptionDetailsIndex][
                          detailIndex
                        ].isAudit
                      ) {
                        titleHeaderRowPrimary.push({
                          text: 'Site',
                          color: '#444F51',
                          fontSize: 9,
                          margin: [8, 6, 8, 6],
                        });
                        titleHeaderRowPrimary.push({
                          text: 'Target',
                          color: '#444F51',
                          fontSize: 9,
                          alignment: 'right',
                          margin: [8, 6, 8, 6],
                        });
                      } else {
                        titleHeaderRowPrimary.push({
                          text: 'Site',
                          color: '#444F51',
                          fontSize: 9,
                          margin: [8, 6, 8, 6],
                        });
                        titleHeaderRowPrimary.push({
                          text: 'Sampling',
                          color: '#444F51',
                          fontSize: 9,
                          alignment: 'right',
                          margin: [8, 6, 8, 6],
                        });
                      }
                      totalColumnPrimary += 2;
                      colSpanValue = 2;
                      const dirtyDefaultConsecutiveSamplingRowsData: ReportSamplingDto[] = getDefaultSampling(
                        samplingPeriodChunks[criteriaOptionDetailsIndex][
                          detailIndex
                        ].isAudit,
                        detail.isAuditCriteria,
                        detail,
                        this.getSelectedSiteDetails(
                          projectSites,
                          filteredSites,
                        ),
                      );
                      dirtySamplingDataRowsPrimary.push(
                        ...dirtyDefaultConsecutiveSamplingRowsData,
                      );
                    }
                    break;
                  default:
                    if (
                      samplingPeriodChunks[criteriaOptionDetailsIndex][
                        detailIndex
                      ].isAudit
                    ) {
                      titleHeaderRowPrimary.push({
                        text: 'Site',
                        color: '#444F51',
                        fontSize: 9,
                        margin: [8, 6, 8, 6],
                      });
                      titleHeaderRowPrimary.push({
                        text: 'Target',
                        color: '#444F51',
                        fontSize: 9,
                        alignment: 'right',
                        margin: [8, 6, 8, 6],
                      });
                    } else {
                      titleHeaderRowPrimary.push({
                        text: 'Site',
                        color: '#444F51',
                        fontSize: 9,
                        margin: [8, 6, 8, 6],
                      });
                      titleHeaderRowPrimary.push({
                        text: 'Sampling',
                        color: '#444F51',
                        fontSize: 9,
                        alignment: 'right',
                        margin: [8, 6, 8, 6],
                      });
                    }
                    totalColumnPrimary += 2;
                    colSpanValue = 2;
                    const dirtyDefaultSamplingRowsData: ReportSamplingDto[] = getDefaultSampling(
                      samplingPeriodChunks[criteriaOptionDetailsIndex][
                        detailIndex
                      ].isAudit,
                      detail.isAuditCriteria,
                      detail,
                      this.getSelectedSiteDetails(projectSites, filteredSites),
                      detail.samplingConfiguration.samplingMode === 'siteBased',
                    );
                    dirtySamplingDataRowsPrimary.push(
                      ...dirtyDefaultSamplingRowsData,
                    );
                }
                const periodData = {
                  text: [
                    samplingPeriodChunks[criteriaOptionDetailsIndex][
                      detailIndex
                    ].title + '\n\n',
                    this.formatDateRange(
                      samplingPeriodChunks[criteriaOptionDetailsIndex][
                        detailIndex
                      ],
                    ),
                  ],
                  color: '#9F9F9F',
                  fontSize: 9,
                  alignment: 'left',
                  margin: [8, 10, 8, 10],
                  colSpan: colSpanValue,
                };
                if (colSpanValue === 2) {
                  periodHeaderRowPrimary.push(periodData);
                  periodHeaderRowPrimary.push({});
                } else {
                  periodHeaderRowPrimary.push(periodData);
                  periodHeaderRowPrimary.push({});
                  periodHeaderRowPrimary.push({});
                }
              },
            );
            const allSitesSamplingPrimary = dirtySamplingDataRowsPrimary.filter(
              (rowData: any) => rowData.siteName === 'All Sites',
            );
            const allSitesDataPrimary: any[] = [];
            allSitesSamplingPrimary.forEach((siteSampling) => {
              allSitesDataPrimary.push(siteSampling.siteName);
              siteSampling.values.forEach((value: any) => {
                allSitesDataPrimary.push(value);
              });
            });
            if (allSitesDataPrimary.length > 0) {
              samplingDataRowsPrimary.push(allSitesDataPrimary);
            }
            projectSites.forEach((siteData) => {
              const siteSampling = dirtySamplingDataRowsPrimary.filter(
                (rowData) => rowData.siteName === siteData.site.name,
              );
              const siteSamplingData: any[] = [];
              siteSampling.forEach((siteDataVar) => {
                siteSamplingData.push(siteDataVar.siteName);
                siteDataVar.values.forEach((value: any) => {
                  siteSamplingData.push(value);
                });
              });
              if (siteSamplingData.length > 0) {
                samplingDataRowsPrimary.push(siteSamplingData);
              }
            });
            samplingDataRowsPrimary.forEach((samplingDataRow) => {
              const transformedData: any[] = [];
              samplingDataRow.forEach((rowData: any) => {
                transformedData.push({
                  text: rowData,
                  color: '#444F51',
                  fontSize: 9,
                  alignment:
                    Number.isInteger(Number(rowData)) ||
                    Number.isInteger(Number(rowData.replace('/', ''))) ||
                    rowData === '-' ||
                    rowData === 'Disabled' ||
                    rowData === 'Enabled'
                      ? 'right'
                      : 'left',
                  margin: [8, 6, 8, 6],
                });
              });
              transformedSamplingDataPrimary.push(transformedData);
            });
            bodyRowsPrimary.push(periodHeaderRowPrimary);
            bodyRowsPrimary.push(titleHeaderRowPrimary);
            bodyRowsPrimary.push(...transformedSamplingDataPrimary);
            const samplingTableDataPrimary = {
              table: {
                unbreakable: true,
                widths: [...Array(totalColumnPrimary).keys()].map(
                  (temp) => '*',
                ),
                body: [...bodyRowsPrimary],
              },
              layout: {
                hLineWidth: () => {
                  return 0.5;
                },
                vLineWidth: () => {
                  return 0.5;
                },
                hLineColor: () => {
                  return '#DBDBDB';
                },
                vLineColor: () => {
                  return '#DBDBDB';
                },
              },
            };
            const stackPrimary: any[] = [samplingTableDataPrimary];
            const criteriaSamplingTablePrimary = {
              stack: stackPrimary,
              unbreakable: true,
            };
            dirtySamplingTables.push(criteriaSamplingTablePrimary);
            break;
          case 2:
            const bodyRowsSecondary: any[] = [];
            let totalColumnSecondary: number = 0;
            const periodHeaderRowSecondary: any[] = [];
            const titleHeaderRowSecondary: any[] = [];
            const transformedSamplingDataSecondary: any[] = [];
            const samplingDataRowsSecondary: any[] = [];
            const dirtySamplingDataRowsSecondary: any[] = [];
            criteriaOptionDetails.forEach(
              (detail: any, detailIndex: number) => {
                let colSpanValue = 0;
                switch (detail.samplingConfiguration.auditSamplingType) {
                  case 'adHoc':
                    titleHeaderRowSecondary.push({
                      text: 'Site',
                      color: '#444F51',
                      fontSize: 9,
                      margin: [8, 6, 8, 6],
                    });
                    titleHeaderRowSecondary.push({
                      text: 'Sampling',
                      color: '#444F51',
                      fontSize: 9,
                      alignment: 'right',
                      margin: [8, 6, 8, 6],
                    });
                    totalColumnSecondary += 2;
                    colSpanValue = 2;
                    const dirtyAdHocSamplingRows: ReportSamplingDto[] = getAdHocSampling(
                      samplingPeriodChunks[criteriaOptionDetailsIndex][
                        detailIndex
                      ].isAudit,
                      detail.isAuditCriteria,
                      detail,
                      this.getSelectedSiteDetails(projectSites, filteredSites),
                    );
                    dirtySamplingDataRowsSecondary.push(
                      ...dirtyAdHocSamplingRows,
                    );

                    break;
                  case 'consecutive':
                    if (
                      detail.samplingConfiguration.samplingMode === 'minAndMax'
                    ) {
                      if (
                        samplingPeriodChunks[criteriaOptionDetailsIndex][
                          detailIndex
                        ].isAudit
                      ) {
                        titleHeaderRowSecondary.push({
                          text: 'Site',
                          color: '#444F51',
                          fontSize: 9,
                          margin: [8, 6, 8, 6],
                        });
                        titleHeaderRowSecondary.push({
                          text: 'Min.',
                          color: '#444F51',
                          fontSize: 9,
                          alignment: 'right',
                          margin: [8, 6, 8, 6],
                        });
                        titleHeaderRowSecondary.push({
                          text: 'Max.',
                          color: '#444F51',
                          fontSize: 9,
                          alignment: 'right',
                          margin: [8, 6, 8, 6],
                        });
                        totalColumnSecondary += 3;
                        colSpanValue = 3;
                      } else {
                        titleHeaderRowSecondary.push({
                          text: 'Site',
                          color: '#444F51',
                          fontSize: 9,
                          margin: [8, 6, 8, 6],
                        });
                        titleHeaderRowSecondary.push({
                          text: 'Sampling',
                          color: '#444F51',
                          fontSize: 9,
                          alignment: 'right',
                          margin: [8, 6, 8, 6],
                        });
                        totalColumnSecondary += 2;
                        colSpanValue = 2;
                      }
                      const dirtyConsecutiveMinMaxSamplingRows: ReportSamplingDto[] = getConsecutiveMinMaxSampling(
                        samplingPeriodChunks[criteriaOptionDetailsIndex][
                          detailIndex
                        ].isAudit,
                        detail.isAuditCriteria,
                        detail,
                        this.getSelectedSiteDetails(
                          projectSites,
                          filteredSites,
                        ),
                      );
                      dirtySamplingDataRowsSecondary.push(
                        ...dirtyConsecutiveMinMaxSamplingRows,
                      );
                    } else {
                      if (
                        samplingPeriodChunks[criteriaOptionDetailsIndex][
                          detailIndex
                        ].isAudit
                      ) {
                        titleHeaderRowSecondary.push({
                          text: 'Site',
                          color: '#444F51',
                          fontSize: 9,
                          margin: [8, 6, 8, 6],
                        });
                        titleHeaderRowSecondary.push({
                          text: 'Target',
                          color: '#444F51',
                          fontSize: 9,
                          alignment: 'right',
                          margin: [8, 6, 8, 6],
                        });
                      } else {
                        titleHeaderRowSecondary.push({
                          text: 'Site',
                          color: '#444F51',
                          fontSize: 9,
                          margin: [8, 6, 8, 6],
                        });
                        titleHeaderRowSecondary.push({
                          text: 'Sampling',
                          color: '#444F51',
                          fontSize: 9,
                          alignment: 'right',
                          margin: [8, 6, 8, 6],
                        });
                      }
                      totalColumnSecondary += 2;
                      colSpanValue = 2;
                      const dirtyDefaultConsecutiveSamplingRowsData: ReportSamplingDto[] = getDefaultSampling(
                        samplingPeriodChunks[criteriaOptionDetailsIndex][
                          detailIndex
                        ].isAudit,
                        detail.isAuditCriteria,
                        detail,
                        this.getSelectedSiteDetails(
                          projectSites,
                          filteredSites,
                        ),
                      );
                      dirtySamplingDataRowsSecondary.push(
                        ...dirtyDefaultConsecutiveSamplingRowsData,
                      );
                    }
                    break;
                  default:
                    if (
                      samplingPeriodChunks[criteriaOptionDetailsIndex][
                        detailIndex
                      ].isAudit
                    ) {
                      titleHeaderRowSecondary.push({
                        text: 'Site',
                        color: '#444F51',
                        fontSize: 9,
                        margin: [8, 6, 8, 6],
                      });
                      titleHeaderRowSecondary.push({
                        text: 'Target',
                        color: '#444F51',
                        fontSize: 9,
                        alignment: 'right',
                        margin: [8, 6, 8, 6],
                      });
                    } else {
                      titleHeaderRowSecondary.push({
                        text: 'Site',
                        color: '#444F51',
                        fontSize: 9,
                        margin: [8, 6, 8, 6],
                      });
                      titleHeaderRowSecondary.push({
                        text: 'Sampling',
                        color: '#444F51',
                        fontSize: 9,
                        alignment: 'right',
                        margin: [8, 6, 8, 6],
                      });
                    }
                    totalColumnSecondary += 2;
                    colSpanValue = 2;
                    const dirtyDefaultSamplingRowsData: ReportSamplingDto[] = getDefaultSampling(
                      samplingPeriodChunks[criteriaOptionDetailsIndex][
                        detailIndex
                      ].isAudit,
                      detail.isAuditCriteria,
                      detail,
                      this.getSelectedSiteDetails(projectSites, filteredSites),
                      detail.samplingConfiguration.samplingMode === 'siteBased',
                    );
                    dirtySamplingDataRowsSecondary.push(
                      ...dirtyDefaultSamplingRowsData,
                    );
                }
                const periodData = {
                  text: [
                    samplingPeriodChunks[criteriaOptionDetailsIndex][
                      detailIndex
                    ].title + '\n\n',
                    this.formatDateRange(
                      samplingPeriodChunks[criteriaOptionDetailsIndex][
                        detailIndex
                      ],
                    ),
                  ],
                  color: '#9F9F9F',
                  fontSize: 9,
                  alignment: 'left',
                  margin: [8, 10, 8, 10],
                  colSpan: colSpanValue,
                };
                if (colSpanValue === 2) {
                  periodHeaderRowSecondary.push(periodData);
                  periodHeaderRowSecondary.push({});
                } else {
                  periodHeaderRowSecondary.push(periodData);
                  periodHeaderRowSecondary.push({});
                  periodHeaderRowSecondary.push({});
                }
              },
            );
            const allSitesSamplingSecondary = dirtySamplingDataRowsSecondary.filter(
              (rowData: any) => rowData.siteName === 'All Sites',
            );
            const allSitesDataSecondary: any[] = [];
            allSitesSamplingSecondary.forEach((siteSampling) => {
              allSitesDataSecondary.push(siteSampling.siteName);
              siteSampling.values.forEach((value: any) => {
                allSitesDataSecondary.push(value);
              });
            });
            if (allSitesDataSecondary.length > 0) {
              samplingDataRowsSecondary.push(allSitesDataSecondary);
            }
            projectSites.forEach((siteData) => {
              const siteSampling = dirtySamplingDataRowsSecondary.filter(
                (rowData) => rowData.siteName === siteData.site.name,
              );
              const siteSamplingData: any[] = [];
              siteSampling.forEach((siteDataVar) => {
                siteSamplingData.push(siteDataVar.siteName);
                siteDataVar.values.forEach((value: any) => {
                  siteSamplingData.push(value);
                });
              });
              if (siteSamplingData.length > 0) {
                samplingDataRowsSecondary.push(siteSamplingData);
              }
            });
            samplingDataRowsSecondary.forEach((samplingDataRow) => {
              const transformedData: any[] = [];
              samplingDataRow.forEach((rowData: any) => {
                transformedData.push({
                  text: rowData,
                  color: '#444F51',
                  fontSize: 9,
                  alignment:
                    Number.isInteger(Number(rowData)) ||
                    Number.isInteger(Number(rowData.replace('/', ''))) ||
                    rowData === '-' ||
                    rowData === 'Disabled' ||
                    rowData === 'Enabled'
                      ? 'right'
                      : 'left',
                  margin: [8, 6, 8, 6],
                });
              });
              transformedSamplingDataSecondary.push(transformedData);
            });
            bodyRowsSecondary.push(periodHeaderRowSecondary);
            bodyRowsSecondary.push(titleHeaderRowSecondary);
            bodyRowsSecondary.push(...transformedSamplingDataSecondary);
            const samplingTableDataSecondary = {
              table: {
                unbreakable: true,
                widths: [...Array(totalColumnSecondary).keys()].map(
                  (temp) => '*',
                ),
                body: [...bodyRowsSecondary],
              },
              layout: {
                hLineWidth: () => {
                  return 0.5;
                },
                vLineWidth: () => {
                  return 0.5;
                },
                hLineColor: () => {
                  return '#DBDBDB';
                },
                vLineColor: () => {
                  return '#DBDBDB';
                },
              },
            };
            const stackSecondary: any[] = [samplingTableDataSecondary];
            const criteriaSamplingTableSecondary = {
              stack: stackSecondary,
              unbreakable: true,
            };
            dirtySamplingTables.push(criteriaSamplingTableSecondary);
            break;
          default:
            const bodyRows: any[] = [];
            let totalColumn: number = 0;
            const periodHeaderRow: any[] = [];
            const titleHeaderRow: any[] = [];
            const transformedSamplingData: any[] = [];
            const samplingDataRows: any[] = [];
            const dirtySamplingDataRows: any[] = [];
            criteriaOptionDetails.forEach(
              (detail: any, detailIndex: number) => {
                let colSpanValue: number = 0;
                switch (detail.samplingConfiguration.auditSamplingType) {
                  case 'adHoc':
                    titleHeaderRow.push({
                      text: 'Site',
                      color: '#444F51',
                      fontSize: 9,
                      margin: [8, 6, 8, 6],
                    });
                    titleHeaderRow.push({
                      text: 'Sampling',
                      color: '#444F51',
                      fontSize: 9,
                      alignment: 'right',
                      margin: [8, 6, 8, 6],
                    });
                    totalColumn += 2;
                    colSpanValue = 2;
                    const dirtyAdHocSamplingRows: ReportSamplingDto[] = getAdHocSampling(
                      samplingPeriodChunks[criteriaOptionDetailsIndex][
                        detailIndex
                      ].isAudit,
                      detail.isAuditCriteria,
                      detail,
                      this.getSelectedSiteDetails(projectSites, filteredSites),
                    );
                    dirtySamplingDataRows.push(...dirtyAdHocSamplingRows);
                    break;
                  case 'consecutive':
                    if (
                      detail.samplingConfiguration.samplingMode === 'minAndMax'
                    ) {
                      if (
                        samplingPeriodChunks[criteriaOptionDetailsIndex][
                          detailIndex
                        ].isAudit
                      ) {
                        titleHeaderRow.push({
                          text: 'Site',
                          color: '#444F51',
                          fontSize: 9,
                          margin: [8, 6, 8, 6],
                        });
                        titleHeaderRow.push({
                          text: 'Min.',
                          color: '#444F51',
                          fontSize: 9,
                          alignment: 'right',
                          margin: [8, 6, 8, 6],
                        });
                        titleHeaderRow.push({
                          text: 'Max.',
                          color: '#444F51',
                          fontSize: 9,
                          alignment: 'right',
                          margin: [8, 6, 8, 6],
                        });
                        totalColumn += 3;
                        colSpanValue = 3;
                      } else {
                        titleHeaderRow.push({
                          text: 'Site',
                          color: '#444F51',
                          fontSize: 9,
                          margin: [8, 6, 8, 6],
                        });
                        titleHeaderRow.push({
                          text: 'Sampling',
                          color: '#444F51',
                          fontSize: 9,
                          alignment: 'right',
                          margin: [8, 6, 8, 6],
                        });
                        totalColumn += 2;
                        colSpanValue = 2;
                      }
                      const dirtyConsecutiveMinMaxSamplingRows: ReportSamplingDto[] = getConsecutiveMinMaxSampling(
                        samplingPeriodChunks[criteriaOptionDetailsIndex][
                          detailIndex
                        ].isAudit,
                        detail.isAuditCriteria,
                        detail,
                        this.getSelectedSiteDetails(
                          projectSites,
                          filteredSites,
                        ),
                      );
                      dirtySamplingDataRows.push(
                        ...dirtyConsecutiveMinMaxSamplingRows,
                      );
                    } else {
                      if (
                        samplingPeriodChunks[criteriaOptionDetailsIndex][
                          detailIndex
                        ].isAudit
                      ) {
                        titleHeaderRow.push({
                          text: 'Site',
                          color: '#444F51',
                          fontSize: 9,
                          margin: [8, 6, 8, 6],
                        });
                        titleHeaderRow.push({
                          text: 'Target',
                          color: '#444F51',
                          fontSize: 9,
                          alignment: 'right',
                          margin: [8, 6, 8, 6],
                        });
                      } else {
                        titleHeaderRow.push({
                          text: 'Site',
                          color: '#444F51',
                          fontSize: 9,
                          margin: [8, 6, 8, 6],
                        });
                        titleHeaderRow.push({
                          text: 'Sampling',
                          color: '#444F51',
                          fontSize: 9,
                          alignment: 'right',
                          margin: [8, 6, 8, 6],
                        });
                      }
                      totalColumn += 2;
                      colSpanValue = 2;
                      const dirtyDefaultConsecutiveSamplingRowsData: ReportSamplingDto[] = getDefaultSampling(
                        samplingPeriodChunks[criteriaOptionDetailsIndex][
                          detailIndex
                        ].isAudit,
                        detail.isAuditCriteria,
                        detail,
                        this.getSelectedSiteDetails(
                          projectSites,
                          filteredSites,
                        ),
                      );
                      dirtySamplingDataRows.push(
                        ...dirtyDefaultConsecutiveSamplingRowsData,
                      );
                    }
                    break;
                  default:
                    if (
                      samplingPeriodChunks[criteriaOptionDetailsIndex][
                        detailIndex
                      ].isAudit
                    ) {
                      titleHeaderRow.push({
                        text: 'Site',
                        color: '#444F51',
                        fontSize: 9,
                        margin: [8, 6, 8, 6],
                      });
                      titleHeaderRow.push({
                        text: 'Target',
                        color: '#444F51',
                        fontSize: 9,
                        alignment: 'right',
                        margin: [8, 6, 8, 6],
                      });
                    } else {
                      titleHeaderRow.push({
                        text: 'Site',
                        color: '#444F51',
                        fontSize: 9,
                        margin: [8, 6, 8, 6],
                      });
                      titleHeaderRow.push({
                        text: 'Sampling',
                        color: '#444F51',
                        fontSize: 9,
                        alignment: 'right',
                        margin: [8, 6, 8, 6],
                      });
                    }
                    totalColumn += 2;
                    colSpanValue = 2;
                    const dirtyDefaultSamplingRowsData: ReportSamplingDto[] = getDefaultSampling(
                      samplingPeriodChunks[criteriaOptionDetailsIndex][
                        detailIndex
                      ].isAudit,
                      detail.isAuditCriteria,
                      detail,
                      this.getSelectedSiteDetails(projectSites, filteredSites),
                      detail.samplingConfiguration.samplingMode === 'siteBased',
                    );
                    dirtySamplingDataRows.push(...dirtyDefaultSamplingRowsData);
                }
                const periodData = {
                  text: [
                    samplingPeriodChunks[criteriaOptionDetailsIndex][
                      detailIndex
                    ].title + '\n\n',
                    this.formatDateRange(
                      samplingPeriodChunks[criteriaOptionDetailsIndex][
                        detailIndex
                      ],
                    ),
                  ],
                  color: '#9F9F9F',
                  fontSize: 9,
                  alignment: 'left',
                  margin: [8, 10, 8, 10],
                  colSpan: colSpanValue,
                };
                if (colSpanValue === 2) {
                  periodHeaderRow.push(periodData);
                  periodHeaderRow.push({});
                } else {
                  periodHeaderRow.push(periodData);
                  periodHeaderRow.push({});
                  periodHeaderRow.push({});
                }
              },
            );
            const allSitesSampling = dirtySamplingDataRows.filter(
              (rowData: any) => rowData.siteName === 'All Sites',
            );
            const allSitesData: any[] = [];
            allSitesSampling.forEach((siteSampling) => {
              allSitesData.push(siteSampling.siteName);
              siteSampling.values.forEach((value: any) => {
                allSitesData.push(value);
              });
            });
            if (allSitesData.length > 0) {
              samplingDataRows.push(allSitesData);
            }
            projectSites.forEach((siteData) => {
              const siteSampling = dirtySamplingDataRows.filter(
                (rowData) => rowData.siteName === siteData.site.name,
              );
              const siteSamplingData: any[] = [];
              siteSampling.forEach((siteDataVar) => {
                siteSamplingData.push(siteDataVar.siteName);
                siteDataVar.values.forEach((value: any) => {
                  siteSamplingData.push(value);
                });
              });
              if (siteSamplingData.length > 0) {
                samplingDataRows.push(siteSamplingData);
              }
            });
            samplingDataRows.forEach((samplingDataRow) => {
              const transformedData: any[] = [];
              samplingDataRow.forEach((rowData: any) => {
                transformedData.push({
                  text: rowData,
                  color: '#444F51',
                  fontSize: 9,
                  alignment:
                    Number.isInteger(Number(rowData)) ||
                    Number.isInteger(Number(rowData.replace('/', ''))) ||
                    rowData === '-' ||
                    rowData === 'Disabled' ||
                    rowData === 'Enabled'
                      ? 'right'
                      : 'left',
                  margin: [8, 6, 8, 6],
                });
              });
              transformedSamplingData.push(transformedData);
            });
            bodyRows.push(periodHeaderRow);
            bodyRows.push(titleHeaderRow);
            bodyRows.push(...transformedSamplingData);
            const samplingTableData = {
              table: {
                unbreakable: true,
                widths: [...Array(totalColumn).keys()].map((temp) => '*'),
                body: [...bodyRows],
              },
              layout: {
                hLineWidth: () => {
                  return 0.5;
                },
                vLineWidth: () => {
                  return 0.5;
                },
                hLineColor: () => {
                  return '#DBDBDB';
                },
                vLineColor: () => {
                  return '#DBDBDB';
                },
              },
            };
            const stack: any[] = [samplingTableData];
            const criteriaSamplingTable = {
              stack,
              unbreakable: true,
            };
            dirtySamplingTables.push(criteriaSamplingTable);
        }
      },
    );
    const divider = {
      canvas: [
        {
          type: 'line',
          x1: 0,
          y1: 15,
          x2: 475,
          y2: 15,
          lineWidth: 0.5,
          lineColor: '#DBDBDB',
        },
      ],
      margin: [0, 0, 0, 13],
    };
    const setMargin = {
      canvas: [],
      margin: [0, 0, 0, 13],
    };
    switch (dirtySamplingTables.length) {
      case 1:
        dirtySamplingTables[0].stack.unshift(samplingTitle);
        dirtySamplingTables[0].stack.push(divider);
        break;
      case 2:
        dirtySamplingTables[0].stack.unshift(samplingTitle);
        dirtySamplingTables[0].stack.push(setMargin);
        dirtySamplingTables[1].stack.push(divider);
        break;
      default:
        dirtySamplingTables.forEach(
          (dirtySamplingTable, dirtySamplingTableIndex) => {
            switch (dirtySamplingTableIndex) {
              case 0:
                dirtySamplingTable.stack.unshift(samplingTitle);
                dirtySamplingTable.stack.push(setMargin);
                break;
              case dirtySamplingTables.length - 1:
                dirtySamplingTable.stack.push(divider);
                break;
              default:
                dirtySamplingTable.stack.push(setMargin);
            }
          },
        );
    }
    return dirtySamplingTables;
  }

  public getAggregateCriteriaAggregateSiteChartCategoryPercentage(
    comparisonIndividualBooleanCriteriaConfiguration: any[],
    payload: any,
  ): number {
    const noOfBooleanCriterias: number =
      comparisonIndividualBooleanCriteriaConfiguration.length;
    if (noOfBooleanCriterias === 1) {
      return this.getIndividualCriteriaAggregateSiteChartCategoryPercentage(
        payload,
      );
    }
    const noOfPeriods: number = payload.comparisonPeriods.length;
    return noOfBooleanCriterias * noOfPeriods <= 10 ? noOfPeriods * 0.2 : 0.8;
  }

  public getIndividualCriteriaAggregateSiteChartCategoryPercentage(
    payload: any,
  ): number {
    const noOfPeriods: number = payload.comparisonPeriods.length;
    return noOfPeriods <= 10 ? noOfPeriods * 0.1 : 0.8;
  }

  public getIndividualCriteriaIndividualSiteChartCategoryPercentage(
    comparisonIndividualBooleanCriteriaConfiguration: any[],
    payload: any,
  ): number {
    const noOfBooleanCriterias: number =
      comparisonIndividualBooleanCriteriaConfiguration.length;
    if (noOfBooleanCriterias === 1) {
      return this.getSingleCriteriaIndividualSiteChartCategoryPercentage(
        payload,
      );
    }
    const noOfPeriods: number = payload.comparisonPeriods.length;
    if (payload.selectedChartType === ChartType.verticalBarChart) {
      return noOfBooleanCriterias * noOfPeriods <= 10 ? noOfPeriods * 0.2 : 0.8;
    } else {
      return noOfBooleanCriterias * noOfPeriods <= 10 ? noOfPeriods * 0.3 : 0.8;
    }
  }

  public getSingleCriteriaIndividualSiteChartCategoryPercentage(
    payload: any,
  ): number {
    const noOfPeriods: number = payload.comparisonPeriods.length;
    const noOfSites: number = payload.filteredSites.length;
    return noOfSites * noOfPeriods <= 10 ? noOfPeriods * 0.2 : 0.8;
  }

  public generateComparisonConfigurationData(
    configurationData: any[],
    payload: any,
    samplingConfigurationData: any[],
  ) {
    let comparisonAggregateCriteriaConfiguration: any[];
    if (payload.checkIndividualSite) {
      if (
        Array.isArray(configurationData) &&
        configurationData.length === payload.comparisonPeriods.length
      ) {
        const aggregateConfiguration: any[] = [];
        _map(payload.filteredCriterias, (criteriaId) => {
          const aggregateCriteriaConfiguration: any[] = [];
          _map(configurationData, (configData, index) => {
            let dirtyAggregateConfiguration: any = {};
            configData.forEach((data: any) => {
              if (data.projectCriteria.id === criteriaId) {
                const criteriaSamplingData = data.criteriaSamplingData.filter(
                  (item: any) => {
                    const criteriaOptionsDataDistribution =
                      item.criteriaOptionsDataDistribution;
                    const dirtyCriteriaSamplingData = Object.keys(
                      criteriaOptionsDataDistribution,
                    ).map((key: string) => ({
                      [key]:
                        data.criteriaSamplingData[0]
                          .criteriaOptionsDataDistribution[key],
                    }));
                    return dirtyCriteriaSamplingData;
                  },
                );
                const dirtyConfig = {
                  title: _get(data, 'projectCriteria.title', ''),
                  criteriaType: _get(data, 'projectCriteria.criteriaType', ''),
                  sampleSize: Number(
                    _get(
                      data,
                      'criteriaSamplingDataConfigurations.sampleSize',
                      0,
                    ),
                  ),
                  siteSamplingConfiguration:
                    data.siteSamplingDataConfigurations,
                  criteriaSamplingDataConfiguration:
                    data.criteriaSamplingDataConfigurations,
                  samplingConfiguration: samplingConfigurationData[index],
                  criteriaSamplingData,
                  isAuditCriteria: _get(data, 'isAuditCriteria', false),
                };
                dirtyAggregateConfiguration = _cloneDeep(dirtyConfig);
              }
            });
            aggregateCriteriaConfiguration.push(dirtyAggregateConfiguration);
          });
          aggregateConfiguration.push(aggregateCriteriaConfiguration);
        });
        comparisonAggregateCriteriaConfiguration = _cloneDeep(
          aggregateConfiguration,
        );
      } else {
        comparisonAggregateCriteriaConfiguration = [];
      }
    } else {
      if (
        Array.isArray(configurationData) &&
        configurationData.length === payload.comparisonPeriods.length
      ) {
        const aggregateConfiguration: any[] = [];
        _map(payload.filteredCriterias, (criteriaId) => {
          const aggregateCriteriaConfiguration: any[] = [];
          _map(configurationData, (configData, index) => {
            let dirtyAggregateConfiguration: any = {};
            configData.forEach((data: any) => {
              if (data.projectCriteria.id === criteriaId) {
                const criteriaOptionsDataDistribution =
                  data.criteriaSamplingData.criteriaOptionsDataDistribution;
                const criteriaSamplingData = Object.keys(
                  criteriaOptionsDataDistribution,
                ).map((key: string) => ({
                  [key]:
                    data.criteriaSamplingData.criteriaOptionsDataDistribution[
                      key
                    ],
                }));
                const dirtyConfig = {
                  title: _get(data, 'projectCriteria.title', ''),
                  criteriaType: _get(data, 'projectCriteria.criteriaType', ''),
                  sampleSize: Number(
                    _get(
                      data,
                      'criteriaSamplingDataConfigurations.sampleSize',
                      0,
                    ),
                  ),
                  siteSamplingConfiguration:
                    data.siteSamplingDataConfigurations,
                  criteriaSamplingDataConfiguration:
                    data.criteriaSamplingDataConfigurations,
                  samplingConfiguration: samplingConfigurationData[index],
                  criteriaSamplingData,
                  isAuditCriteria: _get(data, 'isAuditCriteria', false),
                };
                dirtyAggregateConfiguration = _cloneDeep(dirtyConfig);
              }
            });
            aggregateCriteriaConfiguration.push(dirtyAggregateConfiguration);
          });
          aggregateConfiguration.push(aggregateCriteriaConfiguration);
        });
        comparisonAggregateCriteriaConfiguration = _cloneDeep(
          aggregateConfiguration,
        );
      } else {
        comparisonAggregateCriteriaConfiguration = [];
      }
    }
    const {
      comparisonIndividualBooleanCriteriaConfiguration,
      comparisonIndividualMixedCriteriaConfiguration,
    } = this.generateComparisonIndividualCriteriaSet(
      comparisonAggregateCriteriaConfiguration,
    );
    return {
      comparisonAggregateCriteriaConfiguration,
      comparisonIndividualBooleanCriteriaConfiguration,
      comparisonIndividualMixedCriteriaConfiguration,
    };
  }

  public generateComparisonIndividualCriteriaSet(
    comparisonAggregateCriteriaConfiguration: any[],
  ) {
    const comparisonIndividualBooleanCriteriaConfiguration = comparisonAggregateCriteriaConfiguration.filter(
      (item) => {
        return item[0].criteriaType === CRITERION_TYPE.BOOLEAN;
      },
    );
    const comparisonIndividualMixedCriteriaConfiguration = comparisonAggregateCriteriaConfiguration.filter(
      (item) => {
        return item[0].criteriaType !== CRITERION_TYPE.BOOLEAN;
      },
    );
    return {
      comparisonIndividualBooleanCriteriaConfiguration,
      comparisonIndividualMixedCriteriaConfiguration,
    };
  }

  public getBooleanCriteriaIds(
    projectCriteria: ProjectCriteria[],
    payload: any,
  ): number[] {
    const criteriaIds: number[] = [];
    const booleanCriteriaIds: number[] = [];
    _map(projectCriteria, (criteria: ProjectCriteria) => {
      if (criteria.criteriaType === CRITERION_TYPE.BOOLEAN) {
        booleanCriteriaIds.push(criteria.id);
      }
    });
    _map(booleanCriteriaIds, (eachId: number) => {
      if (payload.filteredCriterias.includes(eachId)) {
        criteriaIds.push(eachId);
      }
    });
    return criteriaIds;
  }

  public getChartValues(chartData: any[], projectSiteMapId: number): number[] {
    const chartValues: number[] = [];
    Object.keys(chartData).map((key: string) => {
      const dirtyChartData = chartData[Number(key)];
      _map(dirtyChartData.data, (dirtyData, dirtyDataIndex) => {
        if (dirtyData.projectSiteId === projectSiteMapId) {
          chartValues.push(dirtyData.compliance);
        }
      });
    });
    return chartValues;
  }

  public getBackgroundColors(
    periodIndex: number,
    projectSiteMapIdIndex: number,
    totalPeriods: number,
    payload: any,
  ): string[] {
    const backgroundColors: string[] = [];
    const dirtyColorSet = _cloneDeep(chartColorSet);
    const factor = projectSiteMapIdIndex * totalPeriods;
    if (factor !== 0) {
      dirtyColorSet.splice(0, factor);
    }
    _map(
      payload.filteredCriterias,
      (projectCriteriaMapId: number, projectCriteriaMapIndex: string) => {
        backgroundColors.push(dirtyColorSet[periodIndex]);
      },
    );
    return backgroundColors;
  }

  public getCustomLabel(
    projectSiteMapId: number,
    periodKey: number,
    payload: any,
    projectSites: ProjectSite[],
  ): string {
    const siteName = this.getSiteName(projectSiteMapId, projectSites);
    const formatPeriod = this.formatDateRange(
      payload.comparisonPeriods[periodKey],
    );
    return siteName + ' ( ' + formatPeriod + ' ) ';
  }

  public transformLabel(label: string): string | string[] {
    const words = label.split(' ');
    let endIndex: number = labelSplitIndex;
    let eachLine: string = '';
    const eachLabel: string[] = [];
    _map(words, (word: string, wordIndex: string) => {
      switch (Number(wordIndex)) {
        case 0:
          eachLine = word;
          break;
        case endIndex:
          eachLabel.push(eachLine);
          eachLine = word;
          endIndex += labelSplitIndex;
          break;
        case words.length - 1:
          eachLine += ' ' + word;
          eachLabel.push(eachLine);
          break;
        default:
          eachLine += ' ' + word;
      }
    });
    if (eachLabel.length >= 3) {
      eachLabel[2] = eachLabel[2] + '...';
      return eachLabel.slice(0, 3);
    }
    return label;
  }

  public getSiteNameByProjectSiteMapId(
    projectSiteMapId: number,
    projectSites: ProjectSite[],
  ): string {
    const siteName = projectSites.filter(
      (projectSite) => projectSite.id === projectSiteMapId,
    );
    return siteName[0].site.name;
  }

  public getCriteriaNames(chartData: any[]): string[] {
    const criteriaNames: string[] = [];
    if (chartData && chartData.length > 0) {
      Object.keys(chartData).map((key: string) => {
        criteriaNames.push(chartData[Number(key)].criteria.title);
      });
    }
    return criteriaNames;
  }

  public getSiteName(
    projectSiteMapId: number,
    projectSites: ProjectSite[],
  ): string {
    const projectSite = projectSites.filter(
      (site) => site.id === projectSiteMapId,
    );
    return projectSite[0].site.name;
  }

  public getSiteNames(
    projectSites: ProjectSite[],
    filteredSiteIds: number[],
  ): string[] {
    const siteNames: any[] = [];
    filteredSiteIds.forEach((projectSiteId) => {
      const projectSite = projectSites.find((site: ProjectSite) => {
        return site.id === projectSiteId;
      });
      if (projectSite) {
        siteNames.push(projectSite.site.name);
      }
    });
    return siteNames;
  }

  public formatDateRange(dateObject: any): string {
    const startDate = new Date(dateObject.startedAt);
    let endDate = dateObject.endedAt;
    if (endDate === null) {
      endDate = dayjs().endOf('day').format();
    }
    return this.getDate(startDate) + ' - ' + this.getDate(new Date(endDate));
  }

  public getDate(newValue: Date): string {
    return (
      newValue.getDate() +
      ' ' +
      this.dirtyMonths[newValue.getMonth()] +
      ' ' +
      newValue.getFullYear()
    );
  }

  public createTextRun(
    params: IRunOptions & {
      deltaAttributes?: IOpAttributes;
      type?: string;
    },
  ) {
    const extraParams = {};

    if (params && params.deltaAttributes) {
      const { deltaAttributes } = params;
      Object.assign(extraParams, { italics: Boolean(deltaAttributes.italic) });
      Object.assign(extraParams, { bold: Boolean(deltaAttributes.bold) });
      Object.assign(extraParams, {
        superScript: Boolean(deltaAttributes.script === 'super'),
      });
      Object.assign(extraParams, {
        subScript: Boolean(deltaAttributes.script === 'sub'),
      });

      if (deltaAttributes.underline) {
        Object.assign(extraParams, { underline: {} });
      } else if (deltaAttributes.size) {
        Object.assign(extraParams, {
          size:
            this.docxFontSize[deltaAttributes.size] ||
            this.docxFontSize.default,
        });
      }
    }

    return new TextRun({
      size: this.docxFontSize.default,
      ...params,
      ...extraParams,
      font: {
        name: 'Helvetica',
      },
    });
  }

  public createBullet(text: string): Paragraph {
    return new Paragraph({
      text,
      bullet: {
        level: 0,
      },
    });
  }

  public async getHeader(document: Document) {
    const blob: any = await fetch(
      `${process.env.VUE_APP_FRONTEND_URL}/assets/images/JBI_banner_report.png`,
    ).then((r) => r.blob());
    const image = Media.addImage(document, blob, 625, 48);
    return new Paragraph(image);
  }

  public getFooter() {
    const exportDateTime = dayjs().format('D MMM YYYY, HH:mm A');
    return new Paragraph(`Exported on ${exportDateTime}`);
  }

  public getTableValueText(cellData: any) {
    return Object.keys(cellData).length > 0
      ? cellData.text
        ? cellData.text.toString()
        : '0'
      : '';
  }
}
