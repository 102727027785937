import {
  DialogProgrammatic as Dialog,
  NotificationProgrammatic as Notification,
} from 'buefy';
import Vue from 'vue';

export const handleErrorResponse = (error: any, component: Vue): void => {
  const status: number = error.response.status;
  const isForbidden: boolean = status === 403;
  const isInternalError: boolean = !status || status >= 500;
  const isValidationError: boolean = status === 422;
  const isPageNotFound: boolean = status === 404;
  const serviceUnavailable: boolean = status === 503;
  if (isForbidden) {
    showErrorDialogAndRedirect(
      `You do not have access to this resource.
            Please contact the PACES system administrator to obtain access.`,
      component,
    );
  } else if (isValidationError) {
    showErrorNotification(
      `Your request cannot be processed due to validation errors.`,
    );
  } else if (isPageNotFound) {
    showErrorDialogAndRedirect(
      `The requested URL was not found on this server. Please contact the PACES system administrator`,
      component,
    );
  } else if (serviceUnavailable) {
    if (error && error.message) {
      showErrorDialogAndRedirect(error.response.data.message, component);
    } else {
      showErrorNotification(`Server Error. Please try again later.`);
    }
  } else if (isInternalError) {
    showErrorNotification(`Server Error. Please try again later.`);
  }
};

export const showErrorDialogAndRedirect = (
  alertMsg: string,
  component: Vue,
): void => {
  Dialog.alert({
    message: alertMsg,
    onConfirm: () => {
      component.$router.push('/');
    },
  });
};

export const showErrorNotification = (alertMsg: string): void => {
  Notification.open({
    type: 'is-danger',
    position: 'is-top',
    message: alertMsg,
    duration: 3000,
  });
};
