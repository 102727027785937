var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{class:['navbar', _vm.$style.navbar],attrs:{"role":"navigation","aria-label":"main navigation"}},[_c('div',{staticClass:"navbar-brand"},[_c('router-link',{staticClass:"navbar-item",attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("@/assets/images/jbi_navbar_img@2x.png"),"width":"68.57","height":"30"}}),_c('div',{class:_vm.$style.divider},[_c('img',{attrs:{"src":require("@/assets/images/logo_paces@2x.png"),"width":"128.26","height":"30"}})])]),_vm._m(0)],1),_c('div',{staticClass:"navbar-menu"},[(_vm.isLoggedIn)?_c('div',{staticClass:"navbar-start",class:_vm.$style.navbarItemContainer},[(_vm.showWithoutSubscriptionLink)?_c('router-link',{class:[
          'navbar-item',
          _vm.$style.navItem,
          _vm.isNavActive('dashboard') ? 'exact-active' : '' ],attrs:{"to":"/dashboard"}},[_vm._v(" DASHBOARD ")]):_vm._e(),_c('router-link',{class:[
          'navbar-item',
          _vm.$style.navItem,
          _vm.isNavActive('project') ? 'exact-active' : '' ],attrs:{"to":"/projects/me"}},[_vm._v(" PROJECTS ")]),(_vm.showWithoutSubscriptionLink)?_c('router-link',{class:[
          'navbar-item',
          _vm.$style.navItem,
          _vm.isNavActive('site') ? 'exact-active' : '' ],attrs:{"to":"/site"}},[_vm._v(" Sites ")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"navbar-end"},[_c('div',{class:['navbar-item', _vm.$style.navEndItem]},[(_vm.showSurveyButton)?_c('SurveyAcknowledgement',{attrs:{"showSurveyModal":_vm.showSurveyModal}}):_vm._e(),_c('div',{style:('width: 100px;display: flex;justify-content: flex-end;')},[_vm._t("main")],2)],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"navbar-burger",attrs:{"role":"button","aria-label":"menu","aria-expanded":"false"}},[_c('span',{attrs:{"aria-hidden":"true"}}),_vm._v(" "),_c('span',{attrs:{"aria-hidden":"true"}}),_c('span',{attrs:{"aria-hidden":"true"}})])}]

export { render, staticRenderFns }