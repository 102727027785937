





















































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { cloneDeep as _cloneDeep, map as _map } from 'lodash';
import { Action, State } from 'vuex-class';
import ProjectSiteFilter from '@/views/Project/components/ProjectSiteFilter.vue';
import ProjectCriteriaFilter from '@/views/Project/components/ProjectCriteriaFilter.vue';
import ProjectChartTypeFilter from '@/views/Project/components/ProjectChartTypeFilter.vue';
import ProjectAnalysisPeriodPicker from '@/views/Project/components/ProjectAnalysisPeriodPicker.vue';
import ProjectReportComparisonPeriodPicker from '@/views/Project/components/ProjectReportComparisonPeriodPicker.vue';
import {
  ChartType,
  ProjectCriteria,
  ProjectSite,
  ProjectReportFiltersConfig,
  ProjectReportPeriod,
} from '@/store/modules/projects/types/projects.types';
import dayjs from 'dayjs';

@Component({
  components: {
    ProjectSiteFilter,
    ProjectCriteriaFilter,
    ProjectChartTypeFilter,
    ProjectAnalysisPeriodPicker,
    ProjectReportComparisonPeriodPicker,
  },
})
export default class ProjectReportFilters extends Vue {
  @Prop()
  public projectSites!: ProjectSite[];
  @Prop()
  public projectCriteria!: ProjectCriteria[];
  @Prop()
  public analysisFilter!: ProjectReportFiltersConfig;
  @Prop()
  public reportLoading!: boolean;
  @Prop()
  public auditsConfigurationList!: ProjectReportPeriod[];

  public filteredSites: number[] = [];
  public filteredCriterias: number[] = [];
  public selectedChartType: string = ChartType.verticalBarChart;
  public selectedPeriod: ProjectReportPeriod = {
    title: '',
    startedAt: '',
    endedAt: null,
    isAudit: false,
    auditIds: [],
  };
  public checkboxIndividualSite: boolean = false;
  public checkboxIndividualCriteria: boolean = false;
  public showComparisonContainer: boolean = false;
  public comparisonPeriods: ProjectReportPeriod[] = [];
  public comparisonPeriodsKey: boolean = true;
  public populateDataSuccess: boolean = false;
  public projectAnalysisPeriodPickerKey: number = Math.floor(
    Math.random() * 999,
  );
  public projectAnalysisComparisonPeriodPickerKey: number = Math.floor(
    Math.random() * 999,
  );
  public customPeriod: ProjectReportPeriod = {
    title: 'Custom Period',
    startedAt: null,
    endedAt: null,
    isAudit: false,
    auditIds: [],
  };
  public showComparisonDropdown: boolean = false;

  public mounted() {
    this.populateData();
  }

  public populateData() {
    this.filteredSites = _cloneDeep(this.analysisFilter.filteredSites);
    this.filteredCriterias = _cloneDeep(this.analysisFilter.filteredCriterias);
    this.selectedChartType = this.analysisFilter.selectedChartType;
    this.checkboxIndividualSite = this.analysisFilter.checkIndividualSite;
    this.checkboxIndividualCriteria = this.analysisFilter.checkIndividualCriteria;
    this.selectedPeriod = _cloneDeep(this.analysisFilter.selectedPeriod);
    this.showComparisonContainer = this.analysisFilter.checkComparison;
    this.comparisonPeriods = _cloneDeep(this.analysisFilter.comparisonPeriods);
    this.handlePeriodList();
    this.populateDataSuccess = true;
  }

  public addNewPeriod() {
    this.showComparisonContainer = true;
    if (this.comparisonPeriods.length === 1) {
      this.comparisonPeriods = _cloneDeep([
        this.selectedPeriod,
        {
          ...this.customPeriod,
          auditIds: this.selectedPeriod.auditIds,
          startedAt: this.selectedPeriod.startedAt,
          endedAt: this.selectedPeriod.endedAt,
        },
      ]);
    } else {
      this.comparisonPeriods.push({
        ...this.customPeriod,
        auditIds: this.getProjectAuditIds,
        startedAt: this.auditsConfigurationList[0].startedAt,
        endedAt: this.auditsConfigurationList[0].endedAt,
      });
    }
    this.showAnalysisResult();
  }

  get getProjectAuditIds(): number[] {
    const auditIds: number[] = [];
    this.auditsConfigurationList.forEach((projectAuditConfig) => {
      auditIds.push(projectAuditConfig.auditIds[0]);
    });
    return auditIds;
  }

  get siteList(): any[] {
    const siteArray: any[] = [];
    _map(this.projectSites, (projectSite: ProjectSite) => {
      siteArray.push({
        siteId: projectSite.site.id,
        name: projectSite.site.name,
        projectSiteId: projectSite.id,
      });
    });
    return siteArray;
  }

  get criteriaList(): ProjectCriteria[] {
    return this.projectCriteria;
  }

  public applySiteFilter(newValue: number[]) {
    this.filteredSites = newValue;
    this.showAnalysisResult();
  }

  public applyCriteriaFilter(newValue: number[]) {
    this.filteredCriterias = newValue;
    this.showAnalysisResult();
  }

  public applyChartType(newValue: ChartType) {
    this.selectedChartType = newValue;
    this.showAnalysisResult();
  }

  public applyAnalysisPeriod(newValue: ProjectReportPeriod) {
    this.selectedPeriod = newValue;
    this.handlePeriodList();
    this.projectAnalysisPeriodPickerKey += 1;
    this.showAnalysisResult();
  }

  public applyAnalysisComparisonPeriod(newValue: {
    period: ProjectReportPeriod;
    periodIndex: number;
  }) {
    this.comparisonPeriods[newValue.periodIndex] = _cloneDeep(newValue.period);
    this.handlePeriodList();
    this.projectAnalysisComparisonPeriodPickerKey += 1;
    this.showAnalysisResult();
  }

  public showAnalysisResult() {
    const filters: ProjectReportFiltersConfig = {
      filteredSites: this.filteredSites,
      filteredCriterias: this.filteredCriterias,
      selectedChartType: this.selectedChartType,
      selectedPeriod: this.selectedPeriod,
      checkIndividualSite: this.checkboxIndividualSite,
      checkIndividualCriteria: this.checkboxIndividualCriteria,
      checkComparison: this.showComparisonContainer,
      comparisonPeriods: this.comparisonPeriods,
    };
    this.$emit('applyAnalysisFilters', filters);
  }

  public handleRemovePeriod(comparisonPeriodIndex: number) {
    this.comparisonPeriods.splice(comparisonPeriodIndex, 1);
    if (this.comparisonPeriods.length === 1) {
      this.selectedPeriod = _cloneDeep(this.comparisonPeriods[0]);
      this.showComparisonContainer = false;
    }
    this.handlePeriodList();
    this.showAnalysisResult();
  }

  public handlePeriodList() {
    if (this.showComparisonContainer) {
      const currentSelectedPeriods: ProjectReportPeriod[] = this.comparisonPeriods.filter(
        (period) => period.isAudit,
      );
      if (
        currentSelectedPeriods.length !== this.auditsConfigurationList.length
      ) {
        this.showComparisonDropdown = true;
      } else {
        this.showComparisonDropdown = false;
      }
      this.projectAnalysisComparisonPeriodPickerKey += 1;
    } else {
      if (
        this.selectedPeriod.isAudit &&
        this.auditsConfigurationList.length === 1
      ) {
        this.showComparisonDropdown = false;
      } else {
        this.showComparisonDropdown = true;
      }
      this.projectAnalysisPeriodPickerKey += 1;
    }
  }

  public addSelectedDataCollectionPeriod(newValue: ProjectReportPeriod) {
    if (!this.showComparisonContainer) {
      this.comparisonPeriods = [this.selectedPeriod, newValue];
      const currentSelectedAuditPeriods: ProjectReportPeriod[] = this.comparisonPeriods.filter(
        (period) => period.isAudit,
      );
      if (
        currentSelectedAuditPeriods.length -
          this.auditsConfigurationList.length ===
        0
      ) {
        this.showComparisonDropdown = false;
      }
      this.showComparisonContainer = true;
    } else {
      this.comparisonPeriods.push(newValue);
      const currentSelectedAuditPeriods: ProjectReportPeriod[] = this.comparisonPeriods.filter(
        (period) => period.isAudit,
      );
      if (
        currentSelectedAuditPeriods.length -
          this.auditsConfigurationList.length ===
        0
      ) {
        this.showComparisonDropdown = false;
      }
      this.projectAnalysisComparisonPeriodPickerKey += 1;
    }
    this.showAnalysisResult();
  }

  get getDataCollectionPeriodItems() {
    const dataCollectionPeriods: ProjectReportPeriod[] = [];
    if (this.showComparisonContainer) {
      const currentSelectedAuditPeriods: ProjectReportPeriod[] = this.comparisonPeriods.filter(
        (period) => period.isAudit,
      );
      const startDates: any[] = [];
      const endDates: any[] = [];
      const projectAuditIds: number[] = [];
      _map(this.auditsConfigurationList, (auditConfiguration) => {
        startDates.push(auditConfiguration.startedAt);
        endDates.push(auditConfiguration.endedAt);
        projectAuditIds.push(auditConfiguration.auditIds[0]);
        const matchedPeriod:
          | ProjectReportPeriod
          | undefined = currentSelectedAuditPeriods.find((auditPeriod: any) => {
          return auditPeriod.auditIds[0] === auditConfiguration.auditIds[0];
        });
        if (!matchedPeriod) {
          dataCollectionPeriods.push(auditConfiguration);
        }
      });
      const customStartDate: string = startDates.reduce(
        (firstElem, secondElem) =>
          firstElem < secondElem ? firstElem : secondElem,
      );
      const customEndDate: string = endDates.reduce((firstElem, secondElem) =>
        firstElem > secondElem ? firstElem : secondElem,
      );
      const customPeriod: ProjectReportPeriod = {
        title: 'Custom Period',
        endedAt: customEndDate,
        startedAt: customStartDate,
        auditIds: projectAuditIds,
        isAudit: false,
      };
      dataCollectionPeriods.push(customPeriod);
    } else {
      if (!this.selectedPeriod.isAudit) {
        const startDates: any[] = [];
        const endDates: any[] = [];
        const projectAuditIds: number[] = [];
        _map(this.auditsConfigurationList, (auditConfiguration) => {
          startDates.push(auditConfiguration.startedAt);
          endDates.push(auditConfiguration.endedAt);
          projectAuditIds.push(auditConfiguration.auditIds[0]);
          dataCollectionPeriods.push(auditConfiguration);
        });
        const customStartDate: string = startDates.reduce(
          (firstElem, secondElem) =>
            firstElem < secondElem ? firstElem : secondElem,
        );
        const customEndDate: string = endDates.reduce((firstElem, secondElem) =>
          firstElem > secondElem ? firstElem : secondElem,
        );
        const customPeriod: ProjectReportPeriod = {
          title: 'Custom Period',
          endedAt: customEndDate,
          startedAt: customStartDate,
          auditIds: projectAuditIds,
          isAudit: false,
        };
        dataCollectionPeriods.push(customPeriod);
      } else {
        if (this.auditsConfigurationList.length === 1) {
          const customPeriod: ProjectReportPeriod = {
            ...this.auditsConfigurationList[0],
            title: 'Custom Period',
            isAudit: false,
          };
          dataCollectionPeriods.push(customPeriod);
        } else {
          const startDates: any[] = [];
          const endDates: any[] = [];
          const projectAuditIds: number[] = [];
          _map(this.auditsConfigurationList, (auditConfiguration) => {
            startDates.push(auditConfiguration.startedAt);
            endDates.push(auditConfiguration.endedAt);
            projectAuditIds.push(auditConfiguration.auditIds[0]);
            if (
              !auditConfiguration.auditIds.includes(
                this.selectedPeriod.auditIds[0],
              )
            ) {
              dataCollectionPeriods.push(auditConfiguration);
            }
          });
          const customStartDate: string = startDates.reduce(
            (firstElem, secondElem) =>
              firstElem < secondElem ? firstElem : secondElem,
          );
          const customEndDate: string = endDates.reduce(
            (firstElem, secondElem) =>
              firstElem > secondElem ? firstElem : secondElem,
          );
          const customPeriod: ProjectReportPeriod = {
            title: 'Custom Period',
            endedAt: customEndDate,
            startedAt: customStartDate,
            auditIds: projectAuditIds,
            isAudit: false,
          };
          dataCollectionPeriods.push(customPeriod);
        }
      }
    }
    return dataCollectionPeriods;
  }

  get getSelectedPeriod(): ProjectReportPeriod {
    return this.selectedPeriod;
  }
}
