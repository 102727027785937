






















































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import BaseLoading from '../../components/base/BaseLoading.vue';
import { ApiState } from '../../store/types/general.types';
import { ToastProgrammatic as Toast } from 'buefy';
import {
  Grip,
  GripDTO,
  CreateGripPayload,
  UpdateGripPayload,
  UpdateGripAnalysisPayload,
  GripAnalysis,
  GripAnalysisCriteria,
  RenameGripAnalysisPayload,
} from '../../store/types/grip.types';
import GripAnalysisPane from './components/GripAnalysisPane.vue';
import GripAnalysisSideBar from './components/GripAnalysisSideBar.vue';
import GripTeamDetailCard from './components/GripTeamDetailCard.vue';
import {
  ProjectCriteria,
  ProjectDetailPayload,
} from '../../store/modules/projects/types/projects.types';
import ExportGripReportModal from './components/ExportGripReportModal.vue';
@Component({
  components: {
    GripTeamDetailCard,
    BaseLoading,
    GripAnalysisPane,
    GripAnalysisSideBar,
    ExportGripReportModal,
  },
})
export default class GripTab extends Vue {
  @Prop() public projectId!: number;
  @Prop() public projectDetail!: ProjectDetailPayload;
  @Prop() public isDataCollector!: boolean;

  public isSideBarOpen: boolean = true;
  public showNullContainer: boolean = true;
  public showGripDetailsContainer: boolean = false;
  public isGripDetails: boolean = true;
  public isGripAnalysis: boolean = false;
  public isGripAnalysisListLoading: boolean = false;
  public isGripAnalysisLoading: boolean = false;
  public isGripDetailStateLoading: boolean = false;
  public isGripAnalysisCriteriaUpdated: boolean = false;
  public selectedProjectCriteriaList: ProjectCriteria[] = [];
  public analysisCriteriaIds: number[] = [];
  public analysisPaneKey: number = Math.floor(Math.random() * 999);
  public analysisSideBarKey: number = Math.floor(Math.random() * 999);
  public selectedAnalysisTabIndex: number = 0;
  public activeSidebarTabIndex: number = 1;

  @State((state) => state.projects.projectCriteria)
  public projectCriteria!: ProjectCriteria[];

  @Action('projects/getGrip')
  public getGrip!: (projectId: number) => void;

  @State((state) => state.projects.gripDetails)
  public gripDetails!: Grip;

  @State((state) => state.projects.apiState.getGripDetails)
  public getGripDetailState!: ApiState;

  @Action('projects/createGrip')
  public createGrip!: (payload: CreateGripPayload) => void;

  @State((state) => state.projects.apiState.createGripDetails)
  public createGripDetailState!: ApiState;

  @State((state) => state.projects.createdGripDetails)
  public createGripDetails!: Grip;

  @Action('projects/updateGrip')
  public updateGrip!: (payload: UpdateGripPayload) => void;

  @State((state) => state.projects.updatedGripDetails)
  public updatedGripDetails!: Grip;

  @State((state) => state.projects.apiState.updateGripDetails)
  public updateGripDetailState!: ApiState;

  @Action('projects/getGripAnalysisList')
  public getGripAnalysisList!: (projectId: number) => void;

  @State((state) => state.projects.gripAnalysisList)
  public gripAnalysisList!: GripAnalysis[];

  @State((state) => state.projects.apiState.getGripAnalysisList)
  public getGripAnalysisListState!: ApiState;

  @Action('projects/getGripAnalysis')
  public getGripAnalysis!: (payload: {
    projectId: number;
    gripAnalysisId: number;
  }) => void;

  @State((state) => state.projects.gripAnalysis)
  public gripAnalysis!: GripAnalysis;

  @Action('projects/updateGripAnalysis')
  public updateGripAnalysis!: (payload: {
    projectId: number;
    payload: UpdateGripAnalysisPayload;
  }) => void;

  @State((state) => state.projects.apiState.updateGripAnalysis)
  public updateGripAnalysisState!: ApiState;

  @State((state) => state.projects.updatedGripAnalysis)
  public updatedGripAnalysis!: any;

  @Action('projects/renameGripAnalysis')
  public renameGripAnalysis!: (payload: {
    projectId: number;
    payload: RenameGripAnalysisPayload;
  }) => void;

  @State((state) => state.projects.apiState.renameGripAnalysis)
  public renameGripAnalysisState!: ApiState;

  get auditId(): number {
    return +this.$route.params.auditId;
  }

  get isExportDisabled(): boolean {
    return this.isGripDetails ? false : true;
  }

  public handleSideBarCollapse(isOpen: boolean): void {
    this.isSideBarOpen = isOpen;
  }

  public handleActiveSidebarTab(tabIndex: number): void {
    this.activeSidebarTabIndex = tabIndex;
  }

  public handleCreateGrip(gripDetails: GripDTO): void {
    this.createGrip({
      projectLead: gripDetails.projectLead,
      stakeholder: gripDetails.stakeholder,
      actionDate: gripDetails.actionDate,
      projectId: this.projectId,
    });
  }

  public handleUpdateGrip(gripDetails: UpdateGripPayload): void {
    this.updateGrip({
      id: gripDetails.id,
      projectLead: gripDetails.projectLead,
      stakeholder: gripDetails.stakeholder,
      actionDate: gripDetails.actionDate,
      projectId: this.projectId,
    });
  }

  public handleSelectedCriteria(data: {
    selectedCriteriaIds: number[];
    update: boolean;
  }): void {
    const selectedProjectCriteria: ProjectCriteria[] = this.projectCriteria
      ? this.projectCriteria.filter((criteria) =>
          data.selectedCriteriaIds.includes(criteria.id),
        )
      : [];

    const selectedCriteriaIds: number[] = selectedProjectCriteria.map(
      (criteria) => criteria.id,
    );
    if (data.update) {
      this.isGripAnalysisCriteriaUpdated = true;
      this.updateGripAnalysis({
        projectId: this.projectId,
        payload: {
          gripAnalysisId: this.gripAnalysis.id,
          criteriaIds: selectedCriteriaIds,
        },
      });
    }
  }

  public handleUpdatedGripAnalysisList(selectedAnalysisTab: number): void {
    this.selectedAnalysisTabIndex = selectedAnalysisTab;
    this.isGripAnalysisListLoading = true;
    this.getGripAnalysisList(this.projectId);
  }

  public handleGripAnalysisCriteria(
    gripAnalysisCriteriaMaps: GripAnalysisCriteria[],
    isGripAnalysisTabChange: boolean,
  ): void {
    if (this.isGripAnalysisCriteriaUpdated || isGripAnalysisTabChange) {
      this.isGripAnalysisCriteriaUpdated = false;
      this.analysisCriteriaIds = gripAnalysisCriteriaMaps.map(
        (gripAnalysisCriteria: GripAnalysisCriteria) =>
          gripAnalysisCriteria.criteria.id,
      );
      this.analysisSideBarKey += 1;
    }
  }

  public handleAddedToProjectGrip(item: string): void {
    const sideBarMenu: string[] = [
      'criteria',
      'barrier',
      'enabler',
      'strategy',
    ];
    const findIndex: number = sideBarMenu.findIndex((menu) => menu === item);
    if (this.activeSidebarTabIndex === findIndex + 1) {
      this.analysisSideBarKey += 1;
    }
  }

  public handleRenameGripAnalysis(
    renamePayload: RenameGripAnalysisPayload,
  ): void {
    this.renameGripAnalysis({
      projectId: this.projectId,
      payload: renamePayload,
    });
  }

  public handleNavigate(): void {
    this.$emit('changeActiveTab', '#settings');
  }

  public handleExport(): void {
    this.$buefy.modal.open({
      parent: this,
      component: ExportGripReportModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        projectId: this.projectId,
      },
      width: 1140,
      events: {
        close: () => {
          this.$emit('close');
        },
      },
    });
  }

  public handleAddGripTeamDetails(): void {
    this.showGripDetailsContainer = true;
  }

  public mounted(): void {
    this.showNullContainer =
      this.projectDetail && this.projectDetail.enableProjectGrip ? false : true;
    if (!this.showNullContainer) {
      this.isGripDetailStateLoading = true;
      this.getGrip(this.projectId);
      this.getGripAnalysisList(this.projectId);
    }
  }

  @Watch('getGripDetailState')
  public watchGetGripDetailState(state: ApiState): void {
    if (state.loading) {
      this.isGripDetailStateLoading = true;
    } else {
      this.isGripDetailStateLoading = false;
      if (state.success) {
        if (this.gripDetails && Object.keys(this.gripDetails).length > 0) {
          this.showGripDetailsContainer = true;
          this.isGripDetails = true;
        } else {
          this.showGripDetailsContainer = false;
          this.isGripDetails = false;
        }
      }
    }
  }

  @Watch('createGripDetailState')
  public watchCreateGripDetailState(state: ApiState): void {
    if (state.success) {
      this.getGrip(this.projectId);
      Toast.open({
        message: `GRiP team details added successfully.`,
        position: 'is-top',
        type: 'is-dark',
        duration: 3500,
      });
    } else if (state.error) {
      Toast.open({
        message: `Something went wrong. Try again.`,
        position: 'is-top',
        type: 'is-danger',
        duration: 3500,
      });
    }
  }

  @Watch('updateGripDetailState')
  public watchUpdateGripDetailState(state: ApiState): void {
    if (state.success) {
      this.getGrip(this.projectId);
      Toast.open({
        message: `Updated successfully.`,
        position: 'is-top',
        type: 'is-dark',
        duration: 3500,
      });
    } else if (state.error) {
      Toast.open({
        message: `Something went wrong. Try again.`,
        position: 'is-top',
        type: 'is-danger',
        duration: 3500,
      });
    }
  }

  @Watch('getGripAnalysisListState')
  public watchGetGripAnalysisListState(state: ApiState): void {
    if (state.loading) {
      this.isGripAnalysisListLoading = true;
    } else {
      this.isGripAnalysisListLoading = false;
      if (state.success) {
        this.isGripAnalysis = this.gripAnalysisList.length > 0;
      }
    }
  }

  @Watch('updateGripAnalysisState')
  public watchUpdateGripAnalysisState(state: ApiState): void {
    if (state.success) {
      Toast.open({
        message: `Analysis updated successfully.`,
        position: 'is-top',
        type: 'is-dark',
        duration: 3500,
      });
      this.getGripAnalysis({
        projectId: this.projectId,
        gripAnalysisId: this.gripAnalysis.id,
      });
    } else if (state.error) {
      Toast.open({
        message: `Something went wrong. Try again.`,
        position: 'is-top',
        type: 'is-danger',
        duration: 3500,
      });
    }
  }

  @Watch('renameGripAnalysisState')
  public watchRenameGripAnalysisState(state: ApiState): void {
    if (state.success) {
      Toast.open({
        message: `Analysis renamed successfully.`,
        position: 'is-top',
        type: 'is-dark',
        duration: 3500,
      });
      this.getGripAnalysisList(this.projectId);
    } else if (state.error) {
      Toast.open({
        message: `Something went wrong. Try again.`,
        position: 'is-top',
        type: 'is-danger',
        duration: 3500,
      });
    }
  }
}
