


























































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { AuditSite } from '../../store/modules/audits/types/audits.types';
import { ReportSamplingDto } from '../../store/modules/projects/types/projects.types';
import {
  getAdHocSampling,
  getConsecutiveMinMaxSampling,
  getDefaultSampling,
} from '../../components/reports/utils/ReportSampling.util';

const dirtyMonths: string[] = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

@Component({
  components: {},
})
export default class SingleSiteTable extends Vue {
  @Prop()
  public source!: Array<{ [key: string]: number }>;
  @Prop({ default: 0 })
  public sampleSize!: number;
  @Prop()
  public siteList!: AuditSite[];
  @Prop({ default: true })
  public showComplianceRate!: boolean;
  @Prop()
  public siteSamplingConfiguration!: any[];
  @Prop()
  public samplingConfiguration!: any;
  @Prop({ default: true })
  public isAuditCriteria!: boolean;
  @Prop()
  public criteriaSamplingDataConfiguration!: any;
  @Prop()
  public selectedPeriod!: any;
  @Prop({ default: false })
  public auditReport!: boolean;

  public siteName: string = '';

  public mounted() {
    this.siteName = this.siteList[0].site.name;
  }

  public formatDateRange(startedAt: string, endedAt: any): string {
    if (!endedAt) {
      endedAt = new Date();
    }
    return (
      this.getDate(new Date(startedAt)) +
      ' - ' +
      this.getDate(new Date(endedAt))
    );
  }

  public getDate(newValue: Date): string {
    return (
      newValue.getDate() +
      ' ' +
      dirtyMonths[newValue.getMonth()] +
      ' ' +
      newValue.getFullYear()
    );
  }

  get items(): Array<{ [key: string]: number }> {
    if (Array.isArray(this.source)) {
      return this.source;
    }
    return [];
  }

  get totalCollected(): string {
    let sum: number = 0;
    let sumNa: string = '';
    this.items.map((value) => {
      if (Object.values(value)[0].toString() === '-') {
        return (sumNa = '-');
      } else {
        return (sum += Number(Object.values(value)[0]));
      }
    });
    if (this.criteriaSamplingDataConfiguration.length === 0) {
      return (sumNa = '-');
    }
    if (sumNa === '-') {
      return sumNa;
    } else {
      return sum.toString();
    }
  }

  get totalNACollected(): number {
    let sum: number = 0;
    this.items.map((value, index) => {
      if (index === 2) {
        return (sum += Object.values(value)[0]);
      }
    });
    return sum;
  }

  get complianceRate(): string {
    const positive: any = this.items[0];
    const positiveKey: string = Object.keys(positive)[0];
    if (positive[positiveKey] === 0) {
      return this.criteriaSamplingDataConfiguration.length ? '0%' : '-';
    }
    const rate: number =
      (positive[positiveKey] /
        (Number(this.totalCollected) - this.totalNACollected)) *
        100 || 0;
    if (!rate) {
      return '-';
    }
    return `${rate.toFixed(2)}%`;
  }

  get getSamplingTitles() {
    const samplingTitles: string[] = [];
    samplingTitles.push('Site');
    switch (this.samplingConfiguration.auditSamplingType) {
      case 'adHoc':
        samplingTitles.push('Sampling');
        break;
      case 'consecutive':
        if (this.selectedPeriod.isAudit || this.auditReport) {
          if (this.samplingConfiguration.samplingMode === 'minAndMax') {
            samplingTitles.push('Min.');
            samplingTitles.push('Max.');
          } else {
            samplingTitles.push('Target');
          }
        } else {
          samplingTitles.push('Sampling');
        }
        break;
      default:
        if (this.selectedPeriod.isAudit || this.auditReport) {
          samplingTitles.push('Target');
        } else {
          samplingTitles.push('Sampling');
        }
    }
    return samplingTitles;
  }

  get getSamplingAggregate() {
    const samplingRows: any[] = [];
    switch (this.samplingConfiguration.auditSamplingType) {
      case 'adHoc':
        const dirtyAdHocSamplingRows: ReportSamplingDto[] = getAdHocSampling(
          this.selectedPeriod.isAudit || this.auditReport,
          this.isAuditCriteria,
          this,
          this.siteList,
        );
        samplingRows.push(...dirtyAdHocSamplingRows);
        break;
      case 'consecutive':
        if (this.samplingConfiguration.samplingMode === 'minAndMax') {
          const dirtyConsecutiveMinMaxSamplingRows: ReportSamplingDto[] = getConsecutiveMinMaxSampling(
            this.selectedPeriod.isAudit || this.auditReport,
            this.isAuditCriteria,
            this,
            this.siteList,
          );
          samplingRows.push(...dirtyConsecutiveMinMaxSamplingRows);
        } else {
          const dirtyDefaultConsecutiveSamplingRowsData: ReportSamplingDto[] = getDefaultSampling(
            this.selectedPeriod.isAudit || this.auditReport,
            this.isAuditCriteria,
            this,
            this.siteList,
          );
          samplingRows.push(...dirtyDefaultConsecutiveSamplingRowsData);
        }
        break;
      default:
        const dirtyDefaultSamplingRowsData: ReportSamplingDto[] = getDefaultSampling(
          this.selectedPeriod.isAudit || this.auditReport,
          this.isAuditCriteria,
          this,
          this.siteList,
          this.samplingConfiguration.samplingMode === 'siteBased',
        );
        samplingRows.push(...dirtyDefaultSamplingRowsData);
    }
    return samplingRows;
  }
}
