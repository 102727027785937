import { AuditSite } from '@/store/modules/audits/types/audits.types';
import { cloneDeep as _cloneDeep, get as _get, map as _map } from 'lodash';
import { CRITERION_TYPE } from '@/store/types/criterions.types';
import dayjs from 'dayjs';

export class GenerateMultiSiteComparisonCsv {
  public dirtyMonths: string[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];

  public create(
    result: any,
    payload: any,
    analysisTitle: string,
    projectTitle: string,
    auditTitle: string,
    auditSites: AuditSite[],
  ) {
    const exportDateTime = dayjs().format('D MMM YYYY, HH:mm A');
    const responseArray: any[] = [];
    responseArray.push({
      column1: 'Project Name',
      column2: projectTitle,
    });
    responseArray.push({
      column1: 'Data Collection Period Name',
      column2: auditTitle,
    });
    responseArray.push({
      column1: 'Analysis Name',
      column2: analysisTitle,
    });
    const selectedSites: string[] = [];
    payload.filteredSites.forEach((auditSiteMapId: number) => {
      const auditSiteMap = auditSites.find(
        (auditSite: AuditSite) => auditSite.id === auditSiteMapId,
      );
      if (auditSiteMap) {
        selectedSites.push(auditSiteMap.site.name);
      }
    });
    responseArray.push({
      column1: 'Site',
      column2: selectedSites.join('\n'),
    });
    responseArray.push({
      column1: 'Site Display Mode',
      column2: payload.checkIndividualSite ? 'Individual' : 'Aggregate',
    });
    const comparisonPeriods = this.getPeriods(payload.comparisonPeriods);
    responseArray.push({
      column1: 'Data Collection Periods',
      column2: this.transformPeriods(comparisonPeriods).join('\n'),
    });
    switch (payload.checkIndividualSite) {
      case true:
        responseArray.push(
          ...this.generateIndividualSiteCriteriaTables(
            result,
            payload,
            selectedSites,
            comparisonPeriods,
          ),
        );
        break;
      default:
        responseArray.push(
          ...this.generateAggregateSiteCriteriaTables(
            result,
            payload,
            comparisonPeriods,
            selectedSites,
            auditSites,
          ),
        );
    }
    responseArray.push(...this.generateSingleRowSpace());
    responseArray.push({
      column1: 'Exported on ' + exportDateTime,
    });
    return responseArray;
  }

  public generateIndividualSiteCriteriaTables(
    result: any,
    payload: any,
    siteNames: string[],
    comparisonPeriods: string[],
  ): any {
    const criteriaStatistics: any[] = [];
    const statisticData: any[][] = [];
    const samplingConfigurationData: any[] = [];
    _map(result.comparisonResult, (comparisonResult: any) => {
      const dirtyStatisticData =
        comparisonResult.statisticData.configurationData;
      statisticData.push(dirtyStatisticData);
      samplingConfigurationData.push(
        comparisonResult.statisticData.samplingConfiguration,
      );
    });
    const {
      comparisonAggregateCriteriaConfiguration,
    } = this.generateComparisonConfigurationData(
      statisticData,
      payload,
      samplingConfigurationData,
    );
    comparisonAggregateCriteriaConfiguration.forEach(
      (criteriaDetails, criteriaDetailsIndex) => {
        criteriaStatistics.push(...this.generateMultipleRowsSpace());
        criteriaStatistics.push({
          column1: criteriaDetailsIndex + 1 + '. ' + criteriaDetails[0].title,
        });
        const headerRow: any = {
          column1: 'Answer Choices',
        };
        const periodRow: any = {
          column1: '',
        };
        let columnCount = 2;
        siteNames.forEach((siteName: string, siteNameIndex: number) => {
          comparisonPeriods.forEach((period: string, periodIndex: number) => {
            periodRow['column' + columnCount] = period;
            switch (periodIndex) {
              case 0:
                headerRow['column' + columnCount] = siteName;
                break;
              default:
                headerRow['column' + columnCount] = '';
            }
            columnCount += 1;
          });
        });
        criteriaStatistics.push(headerRow);
        criteriaStatistics.push(periodRow);
        const optionsList: string[][][] = [];
        const totalList: number[][] = [];
        const complianceList: number[][] = [];
        const valuesList: number[][][] = [];
        criteriaDetails.forEach(
          (criteriaDetailPeriod: any, criteriaDetailPeriodIndex: number) => {
            const trueValueList: number[] = [];
            const naValueList: number[] = [];
            criteriaDetailPeriod.criteriaSamplingData.forEach(
              (siteData: any, siteDataIndex: number) => {
                const options = Object.keys(
                  siteData.criteriaOptionsDataDistribution,
                );
                trueValueList.push(
                  siteData.criteriaOptionsDataDistribution[options[0]],
                );
                naValueList.push(
                  siteData.criteriaOptionsDataDistribution[options[2]],
                );
                if (optionsList[criteriaDetailPeriodIndex]) {
                  optionsList[criteriaDetailPeriodIndex].push(options);
                } else {
                  optionsList[criteriaDetailPeriodIndex] = [options];
                }
                if (totalList[criteriaDetailPeriodIndex]) {
                  totalList[criteriaDetailPeriodIndex].push(siteData.total);
                } else {
                  totalList[criteriaDetailPeriodIndex] = [siteData.total];
                }
                const optionValues: number[] = [];
                options.forEach((option: string) => {
                  optionValues.push(
                    siteData.criteriaOptionsDataDistribution[option],
                  );
                });
                if (valuesList[criteriaDetailPeriodIndex]) {
                  valuesList[criteriaDetailPeriodIndex].push(optionValues);
                } else {
                  valuesList[criteriaDetailPeriodIndex] = [optionValues];
                }
              },
            );
            if (criteriaDetailPeriod.criteriaType === CRITERION_TYPE.BOOLEAN) {
              siteNames.forEach((siteName: string, siteNameIndex: number) => {
                const compliance =
                  (trueValueList[siteNameIndex] /
                    (totalList[criteriaDetailPeriodIndex][siteNameIndex] -
                      naValueList[siteNameIndex])) *
                  100;
                if (complianceList[criteriaDetailPeriodIndex]) {
                  complianceList[criteriaDetailPeriodIndex].push(
                    compliance ? compliance : 0,
                  );
                } else {
                  complianceList[criteriaDetailPeriodIndex] = [
                    compliance ? compliance : 0,
                  ];
                }
              });
            }
          },
        );
        optionsList[0][0].forEach(
          (optionName: string, optionNameIndex: number) => {
            const rowObject: any = {
              column1: optionName,
            };
            let valuesColumnCount = 2;
            siteNames.forEach((siteName: string, siteNameIndex: number) => {
              comparisonPeriods.forEach((period: any, periodIndex: number) => {
                rowObject['column' + valuesColumnCount] =
                  valuesList[periodIndex][siteNameIndex][optionNameIndex];
                valuesColumnCount += 1;
              });
            });
            criteriaStatistics.push(rowObject);
          },
        );
        const totalRow: any = {
          column1: 'Total Data Collected',
        };
        let totalColumnCount = 2;
        siteNames.forEach((siteName: string, siteNameIndex: number) => {
          comparisonPeriods.forEach((period: any, periodIndex: number) => {
            let formattedTotal;
            if (
              totalList[periodIndex][siteNameIndex].toString().includes('-')
            ) {
              formattedTotal = '-';
            } else {
              formattedTotal = totalList[periodIndex][siteNameIndex];
            }
            totalRow['column' + totalColumnCount] = formattedTotal;
            totalColumnCount += 1;
          });
        });
        criteriaStatistics.push(totalRow);
        if (criteriaDetails[0].criteriaType === CRITERION_TYPE.BOOLEAN) {
          const complianceRow: any = {
            column1: 'Compliance',
          };
          let complianceColumnCount = 2;
          siteNames.forEach((siteName: string, siteNameIndex: number) => {
            comparisonPeriods.forEach((period: any, periodIndex: number) => {
              complianceRow['column' + complianceColumnCount] =
                complianceList[periodIndex][siteNameIndex] + '%';
              complianceColumnCount += 1;
            });
          });
          criteriaStatistics.push(complianceRow);
        }
        // sampling table
        criteriaStatistics.push(...this.generateSingleRowSpace());
        criteriaStatistics.push({
          column1: 'Sampling',
        });
        const samplingTableData = this.getSamplingTable(criteriaDetails[0]);
        criteriaStatistics.push(...samplingTableData);
      },
    );
    return criteriaStatistics;
  }

  public generateAggregateSiteCriteriaTables(
    result: any,
    payload: any,
    comparisonPeriods: string[],
    siteNames: string[],
    auditSites: AuditSite[],
  ): any {
    const criteriaStatistics: any[] = [];
    const statisticData: any[][] = [];
    const samplingConfigurationData: any[] = [];
    _map(result.comparisonResult, (comparisonResult: any) => {
      const dirtyStatisticData =
        comparisonResult.statisticData.configurationData;
      statisticData.push(dirtyStatisticData);
      samplingConfigurationData.push(
        comparisonResult.statisticData.samplingConfiguration,
      );
    });
    const {
      comparisonAggregateCriteriaConfiguration,
    } = this.generateComparisonConfigurationData(
      statisticData,
      payload,
      samplingConfigurationData,
    );
    comparisonAggregateCriteriaConfiguration.forEach(
      (criteriaDetails, criteriaDetailsIndex) => {
        criteriaStatistics.push(...this.generateMultipleRowsSpace());
        criteriaStatistics.push({
          column1: criteriaDetailsIndex + 1 + '. ' + criteriaDetails[0].title,
        });
        let siteText: string;
        switch (siteNames.length) {
          case 1:
            siteText = siteNames[0];
            break;
          case auditSites.length:
            siteText = 'All Sites';
            break;
          default:
            siteText = 'Selected sites (' + siteNames.length + ')';
        }
        criteriaStatistics.push({
          column1: 'Answer Choices',
          column2: siteText,
        });
        const periodRow: any = {
          column1: '',
        };
        comparisonPeriods.forEach((period: string, periodIndex: number) => {
          periodRow['column' + (periodIndex + 2)] = period;
        });
        criteriaStatistics.push(periodRow);
        const optionsList: string[][] = [];
        const totalList: number[] = [];
        const complianceList: number[] = [];
        const valuesList: number[][] = [];
        const trueValueList: number[][] = [];
        const naValueList: number[][] = [];
        criteriaDetails.forEach(
          (criteriaDetailPeriod: any, criteriaDetailPeriodIndex: number) => {
            let total: number = 0;
            let trueValue: number = 0;
            let naValue: number = 0;
            criteriaDetailPeriod.criteriaSamplingData.forEach(
              (optionsData: any, optionDataIndex: number) => {
                const option = Object.keys(optionsData)[0];
                if (optionsList[criteriaDetailPeriodIndex]) {
                  optionsList[criteriaDetailPeriodIndex].push(option);
                } else {
                  optionsList[criteriaDetailPeriodIndex] = [option];
                }
                switch (optionDataIndex) {
                  case 0:
                    trueValue = optionsData[option];
                    break;
                  case 1:
                    break;
                  default:
                    naValue = optionsData[option];
                }
                total += optionsData[option];
                if (valuesList[criteriaDetailPeriodIndex]) {
                  valuesList[criteriaDetailPeriodIndex].push(
                    optionsData[option],
                  );
                } else {
                  valuesList[criteriaDetailPeriodIndex] = [optionsData[option]];
                }
              },
            );
            totalList.push(total);
            if (trueValueList[criteriaDetailPeriodIndex]) {
              trueValueList[criteriaDetailPeriodIndex].push(trueValue);
            } else {
              trueValueList[criteriaDetailPeriodIndex] = [trueValue];
            }
            if (naValueList[criteriaDetailPeriodIndex]) {
              naValueList[criteriaDetailPeriodIndex].push(naValue);
            } else {
              naValueList[criteriaDetailPeriodIndex] = [naValue];
            }
            if (criteriaDetailPeriod.criteriaType === CRITERION_TYPE.BOOLEAN) {
              const compliance = (trueValue / (total - naValue)) * 100;
              complianceList.push(compliance ? compliance : 0);
            }
          },
        );
        optionsList[0].forEach(
          (optionName: string, optionNameIndex: number) => {
            const rowObject: any = {
              column1: optionName,
            };
            comparisonPeriods.forEach((period: any, periodIndex: number) => {
              rowObject['column' + (periodIndex + 2)] =
                valuesList[periodIndex][optionNameIndex];
            });
            criteriaStatistics.push(rowObject);
          },
        );
        const totalRow: any = {
          column1: 'Total Data Collected',
        };
        totalList.forEach((total: number, totalIndex: number) => {
          let formattedTotal;
          if (total.toString().includes('-')) {
            formattedTotal = '-';
          } else {
            formattedTotal = total;
          }
          totalRow['column' + (totalIndex + 2)] = formattedTotal;
        });
        criteriaStatistics.push(totalRow);
        if (criteriaDetails[0].criteriaType === CRITERION_TYPE.BOOLEAN) {
          const complianceRow: any = {
            column1: 'Compliance',
          };
          complianceList.forEach(
            (compliance: number, complianceIndex: number) => {
              complianceRow['column' + (complianceIndex + 2)] =
                compliance + '%';
            },
          );
          criteriaStatistics.push(complianceRow);
        }
        // sampling table
        criteriaStatistics.push(...this.generateSingleRowSpace());
        criteriaStatistics.push({
          column1: 'Sampling',
        });
        const samplingTableData = this.getSamplingTable(criteriaDetails[0]);
        criteriaStatistics.push(...samplingTableData);
      },
    );
    return criteriaStatistics;
  }

  public getSamplingTable(criteriaDetails: any) {
    const samplingTableData: any[] = [];
    switch (criteriaDetails.samplingConfiguration.auditSamplingType) {
      case 'adHoc':
        samplingTableData.push({
          column1: 'Site',
          column2: 'Sampling',
        });
        const adHocRowData: any[] = [];
        adHocRowData.push({
          column1: 'All Sites',
          column2: criteriaDetails.criteriaSamplingDataConfiguration
            .isSamplingEnabled
            ? 'Enabled'
            : '-',
        });
        const adHocSitesData: boolean[] = [];
        criteriaDetails.siteSamplingConfiguration.forEach(
          (siteSampling: any) => {
            if (siteSampling.isSamplingEnabled) {
              adHocSitesData.push(true);
            } else {
              adHocSitesData.push(false);
            }
            adHocRowData.push({
              column1: siteSampling.auditSiteMap.site.name,
              column2: siteSampling.isSamplingEnabled ? 'Enabled' : 'Disabled',
            });
          },
        );
        const trueCount = adHocSitesData.filter((value) => value).length;
        adHocRowData[0] = {
          ...adHocRowData[0],
          column2: trueCount + ' out of ' + adHocSitesData.length,
        };
        samplingTableData.push(...adHocRowData);
        break;
      case 'consecutive':
        if (
          criteriaDetails.samplingConfiguration.samplingMode === 'minAndMax'
        ) {
          samplingTableData.push({
            column1: 'Site',
            column2: 'Min.',
            column3: 'Max.',
          });
          samplingTableData.push({
            column1: 'All Sites',
            column2:
              criteriaDetails.criteriaSamplingDataConfiguration.minSampleSize,
            column3:
              criteriaDetails.criteriaSamplingDataConfiguration.maxSampleSize,
          });
          criteriaDetails.siteSamplingConfiguration.forEach(
            (siteSampling: any) => {
              samplingTableData.push({
                column1: siteSampling.auditSiteMap.site.name,
                column2: siteSampling.minSampleSize,
                column3: siteSampling.maxSampleSize,
              });
            },
          );
        } else {
          samplingTableData.push({
            column1: 'Site',
            column2: 'Target',
          });
          samplingTableData.push({
            column1: 'All Sites',
            column2:
              criteriaDetails.criteriaSamplingDataConfiguration.sampleSize,
          });
          criteriaDetails.siteSamplingConfiguration.forEach(
            (siteSampling: any) => {
              samplingTableData.push({
                column1: siteSampling.auditSiteMap.site.name,
                column2: siteSampling.sampleSize,
              });
            },
          );
        }
        break;
      default:
        samplingTableData.push({
          column1: 'Site',
          column2: 'Target',
        });
        samplingTableData.push({
          column1: 'All Sites',
          column2: criteriaDetails.criteriaSamplingDataConfiguration.sampleSize,
        });
        criteriaDetails.siteSamplingConfiguration.forEach(
          (siteSampling: any) => {
            samplingTableData.push({
              column1: siteSampling.auditSiteMap.site.name,
              column2: siteSampling.sampleSize,
            });
          },
        );
    }
    return samplingTableData;
  }

  public generateComparisonConfigurationData(
    configurationData: any[],
    payload: any,
    samplingConfigurationData: any[],
  ) {
    let comparisonAggregateCriteriaConfiguration: any[];
    if (payload.checkIndividualSite) {
      if (
        Array.isArray(configurationData) &&
        configurationData.length === payload.comparisonPeriods.length
      ) {
        const aggregateConfiguration: any[] = [];
        _map(payload.filteredCriterias, (criteriaMapId) => {
          const aggregateCriteriaConfiguration: any[] = [];
          _map(configurationData, (configData, index) => {
            let dirtyAggregateConfiguration: any = {};
            configData.forEach((data: any) => {
              if (data.id === criteriaMapId) {
                const criteriaSamplingData = data.criteriaSamplingData.filter(
                  (item: any) => {
                    const criteriaOptionsDataDistribution =
                      item.criteriaOptionsDataDistribution;
                    const dirtyCriteriaSamplingData = Object.keys(
                      criteriaOptionsDataDistribution,
                    ).map((key: string) => ({
                      [key]:
                        data.criteriaSamplingData[0]
                          .criteriaOptionsDataDistribution[key],
                    }));
                    return dirtyCriteriaSamplingData;
                  },
                );
                const dirtyConfig = {
                  title: _get(data, 'criteria.title', ''),
                  criteriaType: _get(data, 'criteria.criteriaType', ''),
                  sampleSize: Number(
                    _get(
                      data,
                      'criteriaSamplingDataConfigurations.sampleSize',
                      0,
                    ),
                  ),
                  siteSamplingConfiguration:
                    data.siteSamplingDataConfigurations,
                  criteriaSamplingDataConfiguration:
                    data.criteriaSamplingDataConfigurations,
                  samplingConfiguration: samplingConfigurationData[index],
                  criteriaSamplingData,
                };
                dirtyAggregateConfiguration = _cloneDeep(dirtyConfig);
              }
            });
            aggregateCriteriaConfiguration.push(dirtyAggregateConfiguration);
          });
          aggregateConfiguration.push(aggregateCriteriaConfiguration);
        });
        comparisonAggregateCriteriaConfiguration = _cloneDeep(
          aggregateConfiguration,
        );
      } else {
        comparisonAggregateCriteriaConfiguration = [];
      }
    } else {
      if (
        Array.isArray(configurationData) &&
        configurationData.length === payload.comparisonPeriods.length
      ) {
        const aggregateConfiguration: any[] = [];
        _map(payload.filteredCriterias, (criteriaMapId) => {
          const aggregateCriteriaConfiguration: any[] = [];
          _map(configurationData, (configData, index) => {
            let dirtyAggregateConfiguration: any = {};
            configData.forEach((data: any) => {
              if (data.id === criteriaMapId) {
                const criteriaOptionsDataDistribution =
                  data.criteriaSamplingData.criteriaOptionsDataDistribution;
                const criteriaSamplingData = Object.keys(
                  criteriaOptionsDataDistribution,
                ).map((key: string) => ({
                  [key]:
                    data.criteriaSamplingData.criteriaOptionsDataDistribution[
                      key
                    ],
                }));
                const dirtyConfig = {
                  title: _get(data, 'criteria.title', ''),
                  criteriaType: _get(data, 'criteria.criteriaType', ''),
                  sampleSize: Number(
                    _get(
                      data,
                      'criteriaSamplingDataConfigurations.sampleSize',
                      0,
                    ),
                  ),
                  siteSamplingConfiguration:
                    data.siteSamplingDataConfigurations,
                  criteriaSamplingDataConfiguration:
                    data.criteriaSamplingDataConfigurations,
                  samplingConfiguration: samplingConfigurationData[index],
                  criteriaSamplingData,
                };
                dirtyAggregateConfiguration = _cloneDeep(dirtyConfig);
              }
            });
            aggregateCriteriaConfiguration.push(dirtyAggregateConfiguration);
          });
          aggregateConfiguration.push(aggregateCriteriaConfiguration);
        });
        comparisonAggregateCriteriaConfiguration = _cloneDeep(
          aggregateConfiguration,
        );
      } else {
        comparisonAggregateCriteriaConfiguration = [];
      }
    }
    return { comparisonAggregateCriteriaConfiguration };
  }

  public generateSingleRowSpace(): any {
    return [{}];
  }

  public generateMultipleRowsSpace(): any {
    return [{}, {}, {}];
  }

  public getPeriods(comparisonPeriods: Date[][]): string[] {
    const periods: string[] = [];
    comparisonPeriods.forEach((period: Date[], periodIndex: number) => {
      periods.push(this.formatDateRange(period));
    });
    return periods;
  }

  public transformPeriods(comparisonPeriods: string[]): string[] {
    const transformedPeriods: string[] = [];
    comparisonPeriods.forEach((period: string, periodIndex: number) => {
      transformedPeriods.push(periodIndex + 1 + '. ' + period);
    });
    return transformedPeriods;
  }

  public formatDateRange(date: Date[]): string {
    return (
      this.getDate(new Date(date[0])) + ' - ' + this.getDate(new Date(date[1]))
    );
  }

  public getDate(newValue: Date): string {
    return (
      newValue.getDate() +
      ' ' +
      this.dirtyMonths[newValue.getMonth()] +
      ' ' +
      newValue.getFullYear()
    );
  }
}
