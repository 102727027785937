import { Store, ActionContext } from 'vuex';
import { projectsApi } from '@/api/projects.api';
import {
  ProjectsState,
  GetCplusCriteriaRequestPayload,
  CplusCriteriaResults,
  CreateProjectRequestPayload,
  ProjectDetailPayload,
  Site,
  AcceptInvitationRequestPayload,
  AcceptInvitationResponsePayload,
  CriteriaPayload,
  CplusCriteria,
  UpdateProjectCriteriasRequestPayload,
  UpdateProjectSitesRequestPayload,
  UpdateProjectParticipantsRequestPayload,
  UpdateProjectSettingRequestPayload,
  UpdateProjectGeneralSettingRequestPayload,
  CreateProjectParticipantPayload,
  UpdateProjectParticipantPayload,
  DashboardChartDataRequestPayload,
  DashboardChartData,
  ProjectCriteria,
  ProjectSite,
  PaginatedProjectEventLogsPayload,
  PaginatedProjectEventLogsResponse,
  ProjectAuditDataCollectionRecordsPayload,
  GetDocumentByPublicationPayload,
  ChangeProjectOwnerPayload,
  ValidUserPayload,
  UpdateProjectOwnerRequestPayload,
  DocumentByPublication,
  GetExistingProjectOwnerRequestPayload,
  GetProjectPayload,
  GetProjectListPayload,
  AuditGripTypePayload,
  CreateAuditGripTypePayload,
  UpdateAuditGripTypePayload,
  DeleteAuditGripTypePayload,
  ProjectListPagePayload,
  CplusCriteriaItem,
  ProjectDataCollectionRecordExportPayload,
  ProjectGripBarrierPayload,
  ProjectGripEnablerPayload,
  ProjectGripStrategyPayload,
  ProjectGrip,
  UpdateProjectGrip,
  ProjectGripDetails,
  ProjectGripBarrier,
  ProjectGripEnabler,
  ProjectGripStrategy,
  UpdateJBICriteriaAcknowledgmentPayload,
  UpdateAnalysisTitlePayload,
  DeleteAnalysisPayload,
  ProjectReportAnalysisPayload,
  ProjectReportAnalysis,
  JBICriteriaDTO,
  UpdatedJBICriteriaAcknowledgement,
  UpdateJbiCriteriaRequestPayload,
  DeleteProjectParticipantPayload,
  AdministratorProjectListDTO,
  ProjectParticipantDTO,
  JBICriterion,
  JBICriterionTypeBoolean,
  JBICriterionTypeRange,
  JBICriterionTypeCheckboxes,
} from './types/projects.types';
import { CRITERION_TYPE } from '../../types/criterions.types';
import { AuditDetail } from '../audits/types/audits.types';
import { RootState } from '@/store/store';
import { AxiosResponse } from 'axios';
import {
  CreateGripAnalysisBarrierPayload,
  CreateGripAnalysisPayload,
  CreateGripBarrierPayload,
  CreateGripDomainCategoryPayload,
  CreateGripEnablerPayload,
  CreateGripPayload,
  CreateGripStrategyPayload,
  DeleteGripDomainCategoryPayload,
  GetBarriersPayload,
  GetEnablersPayload,
  GetStrategiesPayload,
  Grip,
  GripAnalysis,
  GripAnalysisBarrier,
  GripAnalysisEnabler,
  GripAnalysisParams,
  GripAnalysisStrategy,
  GripBarrier,
  GripDomainCategory,
  GripDomainCategoryDetails,
  GripEnabler,
  GripExportReportPayload,
  GripExportReportResponse,
  GripNotificationResponse,
  GripStrategy,
  RenameGripAnalysisPayload,
  UpdateGripAnalysisBarrierPayload,
  UpdateGripAnalysisPayload,
  UpdateGripBarrierPayload,
  UpdateGripDomainCategoryPayload,
  UpdateGripEnablerPayload,
  UpdateGripPayload,
  UpdateGripStrategyPayload,
} from '../../types/grip.types';
import { Pagination } from '@/store/types/general.types';

export const projectsActions = {
  async getCplusCriterias(
    store: Store<ProjectsState>,
    params: GetCplusCriteriaRequestPayload,
  ) {
    try {
      store.commit('GET_CPLUS_CRITERIAS_LOADING');

      const { data } = await projectsApi.getCplusCriterias(params);

      const result: CplusCriteriaResults = {
        total_count: data.total.value || 0,
        incomplete_results: false,
        items: data.hits.map((d: any) => d._source) || [],
      };

      result.items.forEach((criteriaItem: CplusCriteriaItem) => {
        criteriaItem.criteria.forEach((criteria: JBICriterion) => {
          const dirtyCriteriaType = criteria.type;
          switch (dirtyCriteriaType) {
            case CRITERION_TYPE.BOOLEAN:
              (criteria as JBICriterionTypeBoolean).booleanOptions.push('N/A');
              break;
            case CRITERION_TYPE.RANGE:
              (criteria as JBICriterionTypeRange).range.push('N/A');
              break;
            case CRITERION_TYPE.CHECKBOXES:
              (criteria as JBICriterionTypeCheckboxes).checkboxesOptions.push(
                'N/A',
              );
              break;
          }
        });
      });

      store.commit('GET_CPLUS_CRITERIAS_SUCCESS', result);
    } catch (error) {
      store.commit('GET_CPLUS_CRITERIAS_ERROR', error);
    }
  },
  async createProject(
    store: Store<ProjectsState>,
    payload: CreateProjectRequestPayload,
  ) {
    try {
      store.commit('CREATE_PROJECT_LOADING');

      const { data } = await projectsApi.createProject(payload);

      store.commit('CREATE_PROJECT_SUCCESS', data);
    } catch (error) {
      store.commit('CREATE_PROJECT_ERROR', error);
    }
  },
  async createProjectById(
    store: Store<ProjectsState>,
    payload: CreateProjectRequestPayload,
  ) {
    try {
      store.commit('CREATE_PROJECT_LOADING');

      const { data } = await projectsApi.createProjectById(payload);

      store.commit('CREATE_PROJECT_SUCCESS', data);
    } catch (error) {
      store.commit('CREATE_PROJECT_ERROR', error);
    }
  },
  async saveDraft(
    store: Store<ProjectsState>,
    payload: CreateProjectRequestPayload,
  ) {
    try {
      store.commit('CREATE_PROJECT_LOADING');

      const { data } = await projectsApi.saveDraft(payload);

      store.commit('CREATE_PROJECT_SUCCESS', data);
    } catch (error) {
      store.commit('CREATE_PROJECT_ERROR', error);
    }
  },
  async updateDraft(
    store: Store<ProjectsState>,
    payload: CreateProjectRequestPayload,
  ) {
    try {
      store.commit('CREATE_PROJECT_LOADING');

      const { data } = await projectsApi.updateDraft(payload);

      store.commit('CREATE_PROJECT_SUCCESS', data);
    } catch (error) {
      store.commit('CREATE_PROJECT_ERROR', error);
    }
  },
  // TODO: Remove me, not in use.
  async getMyProjects(
    store: Store<ProjectsState>,
    participantProjectOnly: boolean,
  ) {
    try {
      store.commit('GET_MY_PROJECTS_LOADING');

      const { data } = await projectsApi.getMyProjects(participantProjectOnly);

      const result: ProjectDetailPayload[] = data;
      store.commit('GET_MY_PROJECTS_SUCCESS', result);
    } catch (error) {
      store.commit('GET_MY_PROJECTS_ERROR', error);
    }
  },
  async getActiveProjects(
    store: Store<ProjectsState>,
    payload: GetProjectListPayload,
  ) {
    try {
      store.commit('GET_ACTIVE_PROJECTS_LOADING');

      const { data } = await projectsApi.getActiveProjects(payload);

      const result: ProjectListPagePayload[] = data;
      store.commit('GET_ACTIVE_PROJECTS_SUCCESS', result);
    } catch (error) {
      store.commit('GET_ACTIVE_PROJECTS_ERROR', error);
    }
  },
  async getCreatedProjects(
    store: Store<ProjectsState>,
    payload: GetProjectListPayload,
  ) {
    try {
      store.commit('GET_CREATED_PROJECTS_LOADING');

      const { data } = await projectsApi.getCreatedProjects(payload);

      const result: ProjectListPagePayload[] = data;
      store.commit('GET_CREATED_PROJECTS_SUCCESS', result);
    } catch (error) {
      store.commit('GET_CREATED_PROJECTS_ERROR', error);
    }
  },
  async getDraftProjects(
    store: Store<ProjectsState>,
    payload: GetProjectListPayload,
  ) {
    try {
      store.commit('GET_DRAFT_PROJECTS_LOADING');

      const { data } = await projectsApi.getDraftProjects(payload);

      const result: ProjectListPagePayload[] = data;
      store.commit('GET_DRAFT_PROJECTS_SUCCESS', result);
    } catch (error) {
      store.commit('GET_DRAFT_PROJECTS_ERROR', error);
    }
  },
  async getArchiveProjects(
    store: Store<ProjectsState>,
    payload: GetProjectListPayload,
  ) {
    try {
      store.commit('GET_ARCHIVE_PROJECTS_LOADING');

      const { data } = await projectsApi.getArchiveProjects(payload);

      const result: ProjectListPagePayload[] = data;
      store.commit('GET_ARCHIVE_PROJECTS_SUCCESS', result);
    } catch (error) {
      store.commit('GET_ARCHIVE_PROJECTS_ERROR', error);
    }
  },

  async getProjectDetail(store: Store<ProjectsState>, projectId: number) {
    try {
      store.commit('GET_PROJECT_LOADING');
      const {
        data,
      }: {
        data: ProjectDetailPayload;
      } = await projectsApi.getProjectDetail(projectId);
      store.commit('GET_PROJECT_SUCCESS', data);
    } catch (error) {
      store.commit('GET_PROJECT_ERROR', error);
    }
  },
  async getDraftProjectDetail(
    store: Store<ProjectsState>,
    draftProjectId: number,
  ) {
    try {
      store.commit('GET_PROJECT_LOADING');

      const {
        data,
      }: {
        data: ProjectDetailPayload;
      } = await projectsApi.getDraftProjectDetail(draftProjectId);
      store.commit('GET_PROJECT_SUCCESS', data);
    } catch (error) {
      store.commit('GET_PROJECT_ERROR', error);
    }
  },
  async getSites(store: Store<ProjectsState>) {
    try {
      store.commit('GET_SITES_LOADING');

      const { data } = await projectsApi.getSites();

      const result: Site[] = data;
      store.commit('GET_SITES_SUCCESS', result);
    } catch (error) {
      store.commit('GET_SITES_ERROR', error);
    }
  },
  async addNewSite(store: Store<ProjectsState>, newSite: Site) {
    try {
      const { data } = await projectsApi.addNewSite(newSite);
      const result: Site = data;
      store.commit('ADD_NEW_SITE', result);
    } catch (error) {
      // nothing
    }
  },
  async createSite(store: Store<ProjectsState>, newSite: Site) {
    try {
      store.commit('CREATE_SITE_LOADING');
      const { data } = await projectsApi.addNewSite(newSite);
      store.commit('CREATE_SITE_SUCCESS', data);
      return data;
    } catch (error) {
      store.commit('CREATE_SITE_ERROR', error);
    }
  },

  async archiveProjectById(store: Store<ProjectsState>, projectId: number) {
    try {
      store.commit('UPDATE_PROJECT_STATUS_LOADING');
      const { data } = await projectsApi.archiveProjectById(projectId);
      store.commit('UPDATE_PROJECT_STATUS_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_PROJECT_STATUS_ERROR', error);
    }
  },
  async deleteProjectById(store: Store<ProjectsState>, projectId: number) {
    try {
      store.commit('UPDATE_PROJECT_STATUS_LOADING');
      const { data } = await projectsApi.deleteProjectById(projectId);
      store.commit('UPDATE_PROJECT_STATUS_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_PROJECT_STATUS_ERROR', error);
    }
  },
  async publishProjectById(store: Store<ProjectsState>, projectId: number) {
    try {
      store.commit('UPDATE_PROJECT_STATUS_LOADING');
      const { data } = await projectsApi.publishProjectById(projectId);
      store.commit('UPDATE_PROJECT_STATUS_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_PROJECT_STATUS_ERROR', error);
    }
  },
  async acceptInvitation(
    store: ActionContext<ProjectsState, RootState>,
    payload: AcceptInvitationRequestPayload,
  ) {
    try {
      store.commit('ACCEPT_INVITATION_LOADING');

      const { data } = await projectsApi.acceptInvitation(payload);

      store.commit(
        'ACCEPT_INVITATION_SUCCESS',
        data as AcceptInvitationResponsePayload,
      );
    } catch (error) {
      store.commit('ACCEPT_INVITATION_ERROR', error);
    }
  },
  async updateCriteria(
    store: ActionContext<ProjectsState, RootState>,
    payload: CriteriaPayload,
  ) {
    try {
      store.commit('UPDATE_CRITEIA_LOADING');

      const { data } = await projectsApi.updateCriteria(payload);

      store.commit('UPDATE_CRITEIA_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_CRITEIA_ERROR', error);
    }
  },
  async updateProjectCriterias(
    store: Store<ProjectsState>,
    payload: UpdateProjectCriteriasRequestPayload,
  ) {
    try {
      store.commit('UPDATE_PROJECT_CRITEIAS_LOADING');

      const { data } = await projectsApi.updateProjectCriterias(
        payload.projectId,
        payload,
      );

      store.commit('UPDATE_PROJECT_CRITEIAS_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_PROJECT_CRITEIAS_ERROR', error);
    }
  },
  async updateProjectSites(
    store: Store<ProjectsState>,
    payload: UpdateProjectSitesRequestPayload,
  ) {
    try {
      store.commit('UPDATE_PROJECT_SITES_LOADING');

      const { data } = await projectsApi.updateProjectSites(
        payload.projectId,
        payload,
      );

      store.commit('UPDATE_PROJECT_SITES_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_PROJECT_SITES_ERROR', error);
    }
  },
  async updateProjectParticipants(
    store: Store<ProjectsState>,
    payload: UpdateProjectParticipantsRequestPayload,
  ) {
    try {
      store.commit('UPDATE_PROJECT_PARTICIPANTS_LOADING');

      const { data } = await projectsApi.updateProjectParticipants(
        payload.projectId,
        payload,
      );

      store.commit('UPDATE_PROJECT_PARTICIPANTS_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_PROJECT_PARTICIPANTS_ERROR', error);
    }
  },
  async updateProjectSetting(
    store: Store<ProjectsState>,
    payload: UpdateProjectSettingRequestPayload,
  ) {
    try {
      store.commit('UPDATE_PROJECT_SETTING_LOADING');

      const { data } = await projectsApi.updateProjectSetting(
        payload.projectId,
        payload,
      );

      store.commit('UPDATE_PROJECT_SETTING_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_PROJECT_SETTING_ERROR', error);
    }
  },
  async updateProjectGeneralSettings(
    store: Store<ProjectsState>,
    payload: UpdateProjectGeneralSettingRequestPayload,
  ) {
    try {
      store.commit('UPDATE_PROJECT_GENERAL_SETTING_LOADING');

      const { data } = await projectsApi.updateProjectGeneralSettings(
        payload.projectId,
        payload,
      );

      store.commit('UPDATE_PROJECT_GENERAL_SETTING_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_PROJECT_GENERAL_SETTING_ERROR', error);
    }
  },
  async addNewAudit(store: Store<ProjectsState>, payload: any) {
    try {
      store.commit('ADD_NEW_AUDIT_LOADING');

      const { data } = await projectsApi.addNewAudit(payload);

      store.commit('ADD_NEW_AUDIT_SUCCESS', data);
    } catch (error) {
      store.commit('ADD_NEW_AUDIT_ERROR', error);
    }
  },
  async getProjectParticipants(store: Store<ProjectsState>, projectId: number) {
    try {
      store.commit('GET_PROJECT_PARTICIPANT_LOADING');

      const { data } = await projectsApi.getParticipants(projectId);
      store.commit('GET_PROJECT_PARTICIPANT_SUCCESS', data);
    } catch (error) {
      store.commit('GET_PROJECT_PARTICIPANT_ERROR', error);
    }
  },
  async createProjectParticipant(
    store: Store<ProjectsState>,
    payload: CreateProjectParticipantPayload,
  ) {
    try {
      store.commit('CREATE_PROJECT_PARTICIPANT_LOADING');

      const { data } = await projectsApi.createParticipant(payload);

      store.commit('CREATE_PROJECT_PARTICIPANT_SUCCESS', data);
    } catch (error) {
      store.commit('CREATE_PROJECT_PARTICIPANT_ERROR', error);
    }
  },

  async updateProjectParticipant(
    store: Store<ProjectsState>,
    payload: UpdateProjectParticipantPayload,
  ) {
    try {
      store.commit('UPDATE_PROJECT_PARTICIPANT_LOADING');

      const { data } = await projectsApi.updateParticipant(payload);

      store.commit('UPDATE_PROJECT_PARTICIPANT_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_PROJECT_PARTICIPANT_ERROR', error);
    }
  },

  async deleteProjectParticipant(
    store: Store<ProjectsState>,
    payload: DeleteProjectParticipantPayload,
  ) {
    try {
      store.commit('DELETE_PROJECT_PARTICIPANT_LOADING');

      const { data } = await projectsApi.deleteParticipant(payload);

      store.commit('DELETE_PROJECT_PARTICIPANT_SUCCESS', data);
    } catch (error) {
      store.commit('DELETE_PROJECT_PARTICIPANT_ERROR', error);
    }
  },
  async getDashboardChartData(
    store: ActionContext<ProjectsState, RootState>,
    payload: DashboardChartDataRequestPayload,
  ) {
    try {
      store.commit('GET_DASHBOARD_CHART_DATA_LOADING');

      const {
        data,
      }: {
        data: DashboardChartData[];
      } = await projectsApi.getDashboardChartData(payload.projectId, payload);

      store.commit('GET_DASHBOARD_CHART_DATA_SUCCESS', data);
    } catch (error) {
      store.commit('GET_DASHBOARD_CHART_DATA_ERROR', error);
    }
  },

  async getMyProjectsSummary(store: Store<ProjectsState>) {
    try {
      store.commit('GET_MY_PROJECTS_SUMMARY_LOADING');

      const { data } = await projectsApi.getMyProjectsSummary();

      const result: ProjectDetailPayload[] = data;
      store.commit('GET_MY_PROJECTS_SUMMARY_SUCCESS', result);
    } catch (error) {
      store.commit('GET_MY_PROJECTS_SUMMARY_ERROR', error);
    }
  },

  async getDataCollectionActivityLogs(store: Store<ProjectsState>) {
    try {
      store.commit('GET_DATA_COLLECTION_ACTIVITY_LOGS_LOADING');
      const {
        data,
      }: {
        data: any;
      } = await projectsApi.getDataCollectionActivityLogs();

      store.commit('GET_DATA_COLLECTION_ACTIVITY_LOGS_SUCCESS', data);
    } catch (error) {
      store.commit('GET_DATA_COLLECTION_ACTIVITY_LOGS_ERROR', error);
    }
  },

  async getProjectCriteria(
    store: ActionContext<ProjectsState, RootState>,
    projectId: number,
  ) {
    try {
      store.commit('GET_PROJECT_CRITERIA_LOADING');

      const {
        data,
      }: {
        data: ProjectCriteria[];
      } = await projectsApi.getProjectCriteria(projectId);

      store.commit('GET_PROJECT_CRITERIA_SUCCESS', data);
    } catch (error) {
      store.commit('GET_PROJECT_CRITERIA_ERROR', error);
    }
  },

  async getFilteredProjectCriteria(
    store: ActionContext<ProjectsState, RootState>,
    payload: {
      projectId: number;
      params: { title: string };
    },
  ) {
    try {
      store.commit('GET_PROJECT_CRITERIA_LOADING');

      const {
        data,
      }: {
        data: ProjectCriteria[];
      } = await projectsApi.getProjectCriteria(
        payload.projectId,
        payload.params,
      );

      store.commit('GET_PROJECT_CRITERIA_SUCCESS', data);
    } catch (error) {
      store.commit('GET_PROJECT_CRITERIA_ERROR', error);
    }
  },

  async getProjectReportsCriteria(
    store: ActionContext<ProjectsState, RootState>,
    projectId: number,
  ) {
    try {
      store.commit('GET_PROJECT_CRITERIA_LOADING');

      const {
        data,
      }: {
        data: ProjectCriteria[];
      } = await projectsApi.getProjectReportsCriteria(projectId);

      store.commit('GET_PROJECT_CRITERIA_SUCCESS', data);
    } catch (error) {
      store.commit('GET_PROJECT_CRITERIA_ERROR', error);
    }
  },

  async getProjectSites(
    store: ActionContext<ProjectsState, RootState>,
    projectId: number,
  ) {
    try {
      store.commit('GET_PROJECT_SITES_LOADING');

      const {
        data,
      }: {
        data: ProjectSite[];
      } = await projectsApi.getProjectSites(projectId);

      store.commit('GET_PROJECT_SITES_SUCCESS', data);
    } catch (error) {
      store.commit('GET_PROJECT_SITES_ERROR', error);
    }
  },

  async getProjectAuditList(
    store: ActionContext<ProjectsState, RootState>,
    projectId: number,
  ) {
    try {
      store.commit('GET_PROJECT_AUDIT_LIST_LOADING');

      const {
        data,
      }: {
        data: AuditDetail[];
      } = await projectsApi.getProjectAuditList(projectId);

      store.commit('GET_PROJECT_AUDIT_LIST_SUCCESS', data);
    } catch (error) {
      store.commit('GET_PROJECT_AUDIT_LIST_ERROR', error);
    }
  },

  async getRecentEventLogs(store: Store<ProjectsState>) {
    try {
      store.commit('GET_RECENT_EVENT_LOGS_LOADING');
      const {
        data,
      }: {
        data: any;
      } = await projectsApi.getRecentEventLogs();

      store.commit('GET_RECENT_EVENT_LOGS_SUCCESS', data);
    } catch (error) {
      store.commit('GET_RECENT_EVENT_LOGS_ERROR', error);
    }
  },

  async getPaginatedEventLogs(
    store: Store<ProjectsState>,
    params: PaginatedProjectEventLogsPayload,
  ) {
    try {
      store.commit('GET_PAGINATED_EVENT_LOGS_LOADING');
      const {
        data,
      }: {
        data: PaginatedProjectEventLogsResponse;
      } = await projectsApi.getPaginatedEventLogs(params);

      store.commit('GET_PAGINATED_EVENT_LOGS_SUCCESS', data);
    } catch (error) {
      store.commit('GET_PAGINATED_EVENT_LOGS_ERROR', error);
    }
  },

  async getProjectAuditDataCollectionRecords(
    store: Store<ProjectsState>,
    payload: ProjectAuditDataCollectionRecordsPayload,
  ) {
    try {
      store.commit('GET_PROJECT_AUDIT_LOADING');
      const {
        data,
      }: {
        data: ProjectAuditDataCollectionRecordsPayload;
      } = await projectsApi.getProjectAuditDataCollectionRecords(
        payload.projectId,
        payload,
      );

      store.commit('GET_PROJECT_AUDIT_SUCCESS', data);
    } catch (error) {
      store.commit('GET_PROJECT_AUDIT_ERROR', error);
    }
  },

  async getDocumentByPublication(
    store: Store<ProjectsState>,
    payload: GetDocumentByPublicationPayload,
  ) {
    try {
      store.commit('GET_DOCUMENT_BY_PUBLICATION_LOADING');

      const {
        data,
      }: {
        data: DocumentByPublication;
      } = await projectsApi.getDocumentByPublication(payload);
      store.commit('GET_DOCUMENT_BY_PUBLICATION_SUCCESS', data);
    } catch (error) {
      store.commit('GET_DOCUMENT_BY_PUBLICATION_ERROR', error);
    }
  },

  // TODO: remove this, not in use
  async updateJBICriteria(
    store: Store<ProjectsState>,
    payload: UpdateJbiCriteriaRequestPayload,
  ) {
    try {
      store.commit('UPDATE_JBI_CRITERIA_LOADING');

      const {
        data,
      }: {
        data: any;
      } = await projectsApi.updateJBICriteria(payload);
      store.commit('UPDATE_JBI_CRITERIA_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_JBI_CRITERIA_ERROR', error);
    }
  },

  async updateProjectCriteriaGuide(store: Store<ProjectsState>, payload: any) {
    try {
      store.commit('GET_PROJECT_CRITERIA_LOADING');
      const {
        data,
      }: {
        data: ProjectCriteria[];
      } = await projectsApi.updateProjectCriteriaGuide(payload);
      store.commit('GET_PROJECT_CRITERIA_SUCCESS', data);
    } catch (error) {
      store.commit('GET_PROJECT_CRITERIA_ERROR', error);
    }
  },

  async getAuditGripType(store: Store<ProjectsState>, projectId: number) {
    try {
      store.commit('GET_AUDIT_GRIP_TYPE_LOADING');

      const {
        data,
      }: {
        data: AuditGripTypePayload[];
      } = await projectsApi.getAuditGripType(projectId);
      store.commit('GET_AUDIT_GRIP_TYPE_SUCCESS', data);
    } catch (error) {
      store.commit('GET_AUDIT_GRIP_TYPE_ERROR', error);
    }
  },

  async createAuditGripType(
    store: Store<ProjectsState>,
    payload: CreateAuditGripTypePayload,
  ) {
    try {
      store.commit('CREATE_AUDIT_GRIP_TYPE_LOADING');

      const { data } = await projectsApi.createAuditGripType(payload);

      store.commit('CREATE_AUDIT_GRIP_TYPE_SUCCESS', data);
    } catch (error) {
      store.commit('CREATE_AUDIT_GRIP_TYPE_ERROR', error);
    }
  },

  async updateAuditGripType(
    store: Store<ProjectsState>,
    payload: UpdateAuditGripTypePayload,
  ) {
    try {
      store.commit('UPDATE_AUDIT_GRIP_TYPE_LOADING');

      const { data } = await projectsApi.updateAuditGripType(payload);

      store.commit('UPDATE_AUDIT_GRIP_TYPE_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_AUDIT_GRIP_TYPE_ERROR', error);
    }
  },

  async getGripTypeDetail(
    store: Store<ProjectsState>,
    payload: {
      projectId: number;
      gripTypeId: number;
    },
  ) {
    try {
      store.commit('GET_GRIP_TYPE_DETAIL_LOADING');

      const {
        data,
      }: {
        data: AuditGripTypePayload[];
      } = await projectsApi.getGripTypeDetail(payload);
      store.commit('GET_GRIP_TYPE_DETAIL_SUCCESS', data);
    } catch (error) {
      store.commit('GET_GRIP_TYPE_DETAIL_ERROR', error);
    }
  },

  async deleteAuditGripType(
    store: Store<ProjectsState>,
    payload: DeleteAuditGripTypePayload,
  ) {
    try {
      store.commit('DELETE_AUDIT_GRIP_TYPE_LOADING');

      const { data } = await projectsApi.deleteAuditGripType(payload);

      store.commit('DELETE_AUDIT_GRIP_TYPE_SUCCESS', data);
    } catch (error) {
      store.commit('DELETE_AUDIT_GRIP_TYPE_ERROR', error);
    }
  },

  async getProjectDataCollectionRecordExport(
    store: Store<ProjectsState>,
    projectId: number,
  ) {
    try {
      store.commit('GET_PROJECT_DATA_COLLECTION_RECORD_EXPORT_LOADING');

      const {
        data,
      }: {
        data: ProjectDataCollectionRecordExportPayload;
      } = await projectsApi.getProjectDataCollectionRecordExport(projectId);
      store.commit('GET_PROJECT_DATA_COLLECTION_RECORD_EXPORT_SUCCESS', data);
    } catch (error) {
      store.commit('GET_PROJECT_DATA_COLLECTION_RECORD_EXPORT_ERROR', error);
    }
  },

  async getProjectReportExport(
    store: Store<ProjectsState>,
    projectId: number,
    payload: any,
  ): Promise<void> {
    try {
      store.commit('GET_PROJECT_REPORT_EXPORT_LOADING');

      const {
        data,
      }: {
        data: ProjectDataCollectionRecordExportPayload;
      } = await projectsApi.getProjectReportExport(projectId, payload);
      store.commit('GET_PROJECT_REPORT_EXPORT_SUCCESS', data);
    } catch (error) {
      store.commit('GET_PROJECT_REPORT_EXPORT_ERROR', error);
    }
  },

  async getProjectReportAnalysis(
    store: Store<ProjectsState>,
    payload: ProjectReportAnalysisPayload,
  ): Promise<void> {
    try {
      store.commit('GET_PROJECT_REPORT_ANALYSIS_LOADING');

      const {
        data,
      }: {
        data: any;
      } = await projectsApi.getProjectReportAnalysis(
        payload.projectId,
        payload,
      );
      store.commit('GET_PROJECT_REPORT_ANALYSIS_SUCCESS', data);
    } catch (error) {
      store.commit('GET_PROJECT_REPORT_ANALYSIS_ERROR', error);
    }
  },

  async getTotalProjectReportAnalysis(
    store: Store<ProjectsState>,
    projectId: number,
  ): Promise<void> {
    try {
      store.commit('GET_TOTAL_PROJECT_REPORT_ANALYSIS_LOADING');

      const {
        data,
      }: {
        data: ProjectReportAnalysis[];
      } = await projectsApi.getTotalProjectReportAnalysis(projectId);
      store.commit('GET_TOTAL_PROJECT_REPORT_ANALYSIS_SUCCESS', data);
    } catch (error) {
      store.commit('GET_TOTAL_PROJECT_REPORT_ANALYSIS_ERROR', error);
    }
  },

  async updateProjectReportAnalysisTitleById(
    store: Store<ProjectsState>,
    payload: UpdateAnalysisTitlePayload,
  ): Promise<void> {
    try {
      store.commit('UPDATE_PROJECT_REPORT_ANALYSIS_LOADING');

      const {
        data,
      }: {
        data: any;
      } = await projectsApi.updateProjectReportAnalysisTitleById(
        payload.projectId,
        payload,
      );
      store.commit('UPDATE_PROJECT_REPORT_ANALYSIS_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_PROJECT_REPORT_ANALYSIS_ERROR', error);
    }
  },

  async deleteProjectReportAnalysisById(
    store: Store<ProjectsState>,
    payload: DeleteAnalysisPayload,
  ): Promise<void> {
    try {
      store.commit('DELETE_PROJECT_REPORT_ANALYSIS_LOADING');

      const {
        data,
      }: {
        data: any;
      } = await projectsApi.deleteProjectReportAnalysisById(
        payload.projectId,
        payload,
      );
      store.commit('DELETE_PROJECT_REPORT_ANALYSIS_SUCCESS', data);
    } catch (error) {
      store.commit('DELETE_PROJECT_REPORT_ANALYSIS_ERROR', error);
    }
  },

  async getProjectGrip(
    store: ActionContext<ProjectsState, RootState>,
    projectId: number,
  ) {
    try {
      store.commit('GET_PROJECT_GRIP_LOADING');

      const {
        data,
      }: { data: ProjectGripDetails } = await projectsApi.getProjectGrip(
        projectId,
      );

      store.commit('GET_PROJECT_GRIP_SUCCESS', data);
    } catch (error) {
      store.commit('GET_PROJECT_GRIP_ERROR', error);
    }
  },

  async createProjectGrip(
    store: ActionContext<ProjectsState, RootState>,
    payload: ProjectGrip,
  ) {
    try {
      store.commit('CREATE_PROJECT_GRIP_LOADING');
      const { data } = await projectsApi.createProjectGrip(payload);
      store.commit('CREATE_PROJECT_GRIP_SUCCESS', data);
    } catch (error) {
      store.commit('CREATE_PROJECT_GRIP_ERROR', error);
    }
  },

  async updateProjectGrip(
    store: ActionContext<ProjectsState, RootState>,
    payload: UpdateProjectGrip,
  ) {
    try {
      store.commit('UPDATE_PROJECT_GRIP_LOADING');
      const { data } = await projectsApi.updateProjectGrip(payload);
      store.commit('UPDATE_PROJECT_GRIP_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_PROJECT_GRIP_ERROR', error);
    }
  },

  async getProjectGripBarriers(
    store: ActionContext<ProjectsState, RootState>,
    projectId: number,
  ) {
    try {
      store.commit('GET_PROJECT_GRIP_BARRIERS_LOADING');

      const {
        data,
      }: {
        data: ProjectGripBarrier[];
      } = await projectsApi.getProjectGripBarriers(projectId);

      store.commit('GET_PROJECT_GRIP_BARRIERS_SUCCESS', data);
    } catch (error) {
      store.commit('GET_PROJECT_GRIP_BARRIERS_ERROR', error);
    }
  },

  async createProjectGripBarrier(
    store: ActionContext<ProjectsState, RootState>,
    payload: ProjectGripBarrierPayload,
  ) {
    try {
      store.commit('CREATE_PROJECT_GRIP_BARRIER_LOADING');

      const {
        data,
      }: {
        data: any;
      } = await projectsApi.createProjectGripBarrier(payload);

      store.commit('CREATE_PROJECT_GRIP_BARRIER_SUCCESS', data);
    } catch (error) {
      store.commit('CREATE_PROJECT_GRIP_BARRIER_ERROR', error);
    }
  },

  async updateProjectGripBarrier(
    store: ActionContext<ProjectsState, RootState>,
    payload: ProjectGripBarrierPayload,
  ) {
    try {
      store.commit('UPDATE_PROJECT_GRIP_BARRIER_LOADING');
      const { data } = await projectsApi.updateProjectGripBarrier(
        payload.projectId,
        payload,
      );
      store.commit('UPDATE_PROJECT_GRIP_BARRIER_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_PROJECT_GRIP_BARRIER_ERROR', error);
    }
  },

  async deleteProjectGripBarrier(
    store: ActionContext<ProjectsState, RootState>,
    payload: {
      projectId: number;
      barrierId: number;
    },
  ) {
    try {
      store.commit('DELETE_PROJECT_GRIP_BARRIER_LOADING');

      const {
        data,
      }: {
        data: any;
      } = await projectsApi.deleteProjectGripBarrier(payload);

      store.commit('DELETE_PROJECT_GRIP_BARRIER_SUCCESS', data);
    } catch (error) {
      store.commit('DELETE_PROJECT_GRIP_BARRIER_ERROR', error);
    }
  },

  async getProjectGripEnablers(
    store: ActionContext<ProjectsState, RootState>,
    projectId: number,
  ) {
    try {
      store.commit('GET_PROJECT_GRIP_ENABLERS_LOADING');

      const {
        data,
      }: {
        data: ProjectGripEnabler[];
      } = await projectsApi.getProjectGripEnablers(projectId);

      store.commit('GET_PROJECT_GRIP_ENABLERS_SUCCESS', data);
    } catch (error) {
      store.commit('GET_PROJECT_GRIP_ENABLERS_ERROR', error);
    }
  },

  async createProjectGripEnabler(
    store: ActionContext<ProjectsState, RootState>,
    payload: ProjectGripEnablerPayload,
  ) {
    try {
      store.commit('CREATE_PROJECT_GRIP_ENABLER_LOADING');

      const {
        data,
      }: {
        data: any;
      } = await projectsApi.createProjectGripEnabler(payload);

      store.commit('CREATE_PROJECT_GRIP_ENABLER_SUCCESS', data);
    } catch (error) {
      store.commit('CREATE_PROJECT_GRIP_ENABLER_ERROR', error);
    }
  },

  async updateProjectGripEnabler(
    store: ActionContext<ProjectsState, RootState>,
    payload: ProjectGripEnablerPayload,
  ) {
    try {
      store.commit('UPDATE_PROJECT_GRIP_ENABLER_LOADING');
      const { data } = await projectsApi.updateProjectGripEnabler(
        payload.projectId,
        payload,
      );
      store.commit('UPDATE_PROJECT_GRIP_ENABLER_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_PROJECT_GRIP_ENABLER_ERROR', error);
    }
  },

  async deleteProjectGripEnabler(
    store: ActionContext<ProjectsState, RootState>,
    payload: { projectId: number; enablerId: number },
  ) {
    try {
      store.commit('DELETE_PROJECT_GRIP_ENABLER_LOADING');

      const {
        data,
      }: {
        data: any;
      } = await projectsApi.deleteProjectGripEnabler(payload);

      store.commit('DELETE_PROJECT_GRIP_ENABLER_SUCCESS', data);
    } catch (error) {
      store.commit('DELETE_PROJECT_GRIP_ENABLER_ERROR', error);
    }
  },

  async getProjectGripStrategies(
    store: ActionContext<ProjectsState, RootState>,
    projectId: number,
  ) {
    try {
      store.commit('GET_PROJECT_GRIP_STRATEGIES_LOADING');

      const {
        data,
      }: {
        data: ProjectGripStrategy[];
      } = await projectsApi.getProjectGripStrategies(projectId);

      store.commit('GET_PROJECT_GRIP_STRATEGIES_SUCCESS', data);
    } catch (error) {
      store.commit('GET_PROJECT_GRIP_STRATEGIES_ERROR', error);
    }
  },

  async createProjectGripStrategy(
    store: ActionContext<ProjectsState, RootState>,
    payload: ProjectGripStrategyPayload,
  ) {
    try {
      store.commit('CREATE_PROJECT_GRIP_STRATEGY_LOADING');

      const {
        data,
      }: {
        data: any;
      } = await projectsApi.createProjectGripStrategy(payload);

      store.commit('CREATE_PROJECT_GRIP_STRATEGY_SUCCESS', data);
    } catch (error) {
      store.commit('CREATE_PROJECT_GRIP_STRATEGY_ERROR', error);
    }
  },

  async updateProjectGripStrategy(
    store: ActionContext<ProjectsState, RootState>,
    payload: ProjectGripStrategyPayload,
  ) {
    try {
      store.commit('UPDATE_PROJECT_GRIP_STRATEGY_LOADING');
      const { data } = await projectsApi.updateProjectGripStrategy(
        payload.projectId,
        payload,
      );
      store.commit('UPDATE_PROJECT_GRIP_STRATEGY_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_PROJECT_GRIP_STRATEGY_ERROR', error);
    }
  },

  async deleteProjectGripStrategy(
    store: ActionContext<ProjectsState, RootState>,
    payload: { projectId: number; strategyId: number },
  ) {
    try {
      store.commit('DELETE_PROJECT_GRIP_STRATEGY_LOADING');

      const {
        data,
      }: {
        data: any;
      } = await projectsApi.deleteProjectGripStrategy(payload);

      store.commit('DELETE_PROJECT_GRIP_STRATEGY_SUCCESS', data);
    } catch (error) {
      store.commit('DELETE_PROJECT_GRIP_STRATEGY_ERROR', error);
    }
  },

  async updateProjectCriteriaById(
    store: Store<ProjectsState>,
    payload: { projectId: number; criteria: CplusCriteria },
  ) {
    try {
      store.commit('UPDATE_PROJECT_CRITERIAS_BY_ID_LOADING');

      const { data } = await projectsApi.updateProjectCriteriasById(
        payload.projectId,
        payload.criteria,
      );

      store.commit('UPDATE_PROJECT_CRITERIAS_BY_ID_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_PROJECT_CRITERIAS_BY_ID_ERROR', error);
    }
  },

  async getGripDomainCategories(
    store: Store<ProjectsState>,
    projectId: number,
  ): Promise<void> {
    try {
      store.commit('GET_GRIP_DOMAIN_CATEGORIES_LOADING');

      const { data } = await projectsApi.getGripDomainCategoriesByProjectId(
        projectId,
      );
      const result: GripDomainCategory[] = data;
      store.commit('GET_GRIP_DOMAIN_CATEGORIES_SUCCESS', result);
    } catch (error) {
      store.commit('GET_GRIP_DOMAIN_CATEGORIES_ERROR', error);
    }
  },

  async getGripDomainCategoryDetails(
    store: Store<ProjectsState>,
    payload: { projectId: number; gripDomainCategoryId: number },
  ): Promise<void> {
    try {
      store.commit('GET_GRIP_DOMAIN_CATEGORY_LOADING');

      const { data } = await projectsApi.getGripDomainCategoryDetails(
        payload.projectId,
        payload.gripDomainCategoryId,
      );
      const result: GripDomainCategoryDetails = data;
      store.commit('GET_GRIP_DOMAIN_CATEGORY_SUCCESS', result);
    } catch (error) {
      store.commit('GET_GRIP_DOMAIN_CATEGORY_ERROR', error);
    }
  },

  async verifyGripDomainCategory(
    store: Store<ProjectsState>,
    payload: CreateGripDomainCategoryPayload,
  ): Promise<void> {
    try {
      store.commit('VERIFY_GRIP_DOMAIN_CATEGORY_LOADING');

      const { data } = await projectsApi.verifyGripDomainCategory(payload);
      const result: boolean = data;
      store.commit('VERIFY_GRIP_DOMAIN_CATEGORY_SUCCESS', result);
    } catch (error) {
      store.commit('VERIFY_GRIP_DOMAIN_CATEGORY_ERROR', error);
    }
  },

  async createGripDomainCategory(
    store: Store<ProjectsState>,
    payload: CreateGripDomainCategoryPayload,
  ): Promise<void> {
    try {
      store.commit('CREATE_GRIP_DOMAIN_CATEGORY_LOADING');

      const { data } = await projectsApi.createGripDomainCategory(payload);
      const result: GripDomainCategory = data;
      store.commit('CREATE_GRIP_DOMAIN_CATEGORY_SUCCESS', result);
    } catch (error) {
      store.commit('CREATE_GRIP_DOMAIN_CATEGORY_ERROR', error);
    }
  },

  async updateGripDomainCategory(
    store: Store<ProjectsState>,
    payload: UpdateGripDomainCategoryPayload,
  ): Promise<void> {
    try {
      store.commit('UPDATE_GRIP_DOMAIN_CATEGORY_LOADING');

      const { data } = await projectsApi.updateGripDomainCategory(payload);
      const result: GripDomainCategory = data;
      store.commit('UPDATE_GRIP_DOMAIN_CATEGORY_SUCCESS', result);
    } catch (error) {
      store.commit('UPDATE_GRIP_DOMAIN_CATEGORY_ERROR', error);
    }
  },

  async deleteGripDomainCategory(
    store: Store<ProjectsState>,
    payload: DeleteGripDomainCategoryPayload,
  ): Promise<void> {
    try {
      store.commit('DELETE_GRIP_DOMAIN_CATEGORY_LOADING');

      const { data } = await projectsApi.deleteGripDomainCategory(payload);
      const result: void = data;
      store.commit('DELETE_GRIP_DOMAIN_CATEGORY_SUCCESS', result);
    } catch (error) {
      store.commit('DELETE_GRIP_DOMAIN_CATEGORY_ERROR', error);
    }
  },

  async getGripBarriers(
    store: Store<ProjectsState>,
    payload: { projectId: number; params: GetBarriersPayload },
  ): Promise<void> {
    try {
      store.commit('GET_GRIP_BARRIERS_LOADING');

      const { data } = await projectsApi.getGripBarriersByProjectId(payload);
      const result: Pagination<GripBarrier[]> = data;
      store.commit('GET_GRIP_BARRIERS_SUCCESS', result);
    } catch (error) {
      store.commit('GET_GRIP_BARRIERS_ERROR', error);
    }
  },

  async createGripBarrier(
    store: Store<ProjectsState>,
    payload: CreateGripBarrierPayload,
  ): Promise<void> {
    try {
      store.commit('CREATE_GRIP_BARRIER_LOADING');

      const { data } = await projectsApi.createGripBarrier(payload);
      const result: GripBarrier = data;
      store.commit('CREATE_GRIP_BARRIER_SUCCESS', result);
    } catch (error) {
      store.commit('CREATE_GRIP_BARRIER_ERROR', error);
    }
  },

  async updateGripBarrier(
    store: Store<ProjectsState>,
    payload: UpdateGripBarrierPayload,
  ): Promise<void> {
    try {
      store.commit('UPDATE_GRIP_BARRIER_LOADING');

      const { data } = await projectsApi.updateGripBarrierById(payload);
      const result: any = data;
      store.commit('UPDATE_GRIP_BARRIER_SUCCESS', result);
    } catch (error) {
      store.commit('UPDATE_GRIP_BARRIER_ERROR', error);
    }
  },

  async deleteGripBarrier(
    store: Store<ProjectsState>,
    payload: { projectId: number; barrierId: number },
  ): Promise<void> {
    try {
      store.commit('DELETE_GRIP_BARRIER_LOADING');

      const { data } = await projectsApi.deleteGripBarrierById(payload);
      const result: void = data;
      store.commit('DELETE_GRIP_BARRIER_SUCCESS', result);
    } catch (error) {
      store.commit('DELETE_GRIP_BARRIER_ERROR', error);
    }
  },

  async getGripEnablers(
    store: Store<ProjectsState>,
    payload: { projectId: number; params: GetEnablersPayload },
  ): Promise<void> {
    try {
      store.commit('GET_GRIP_ENABLERS_LOADING');

      const { data } = await projectsApi.getGripEnablersByProjectId(payload);
      const result: Pagination<GripEnabler[]> = data;
      store.commit('GET_GRIP_ENABLERS_SUCCESS', result);
    } catch (error) {
      store.commit('GET_GRIP_ENABLERS_ERROR', error);
    }
  },

  async createGripEnabler(
    store: Store<ProjectsState>,
    payload: CreateGripEnablerPayload,
  ): Promise<void> {
    try {
      store.commit('CREATE_GRIP_ENABLER_LOADING');

      const { data } = await projectsApi.createGripEnabler(payload);
      const result: GripEnabler = data;
      store.commit('CREATE_GRIP_ENABLER_SUCCESS', result);
    } catch (error) {
      store.commit('CREATE_GRIP_ENABLER_ERROR', error);
    }
  },

  async updateGripEnabler(
    store: Store<ProjectsState>,
    payload: UpdateGripEnablerPayload,
  ): Promise<void> {
    try {
      store.commit('UPDATE_GRIP_ENABLER_LOADING');

      const { data } = await projectsApi.updateGripEnablerById(payload);
      const result: any = data;
      store.commit('UPDATE_GRIP_ENABLER_SUCCESS', result);
    } catch (error) {
      store.commit('UPDATE_GRIP_ENABLER_ERROR', error);
    }
  },

  async deleteGripEnabler(
    store: Store<ProjectsState>,
    payload: { projectId: number; enablerId: number },
  ): Promise<void> {
    try {
      store.commit('DELETE_GRIP_ENABLER_LOADING');

      const { data } = await projectsApi.deleteGripEnablerById(payload);
      const result: void = data;
      store.commit('DELETE_GRIP_ENABLER_SUCCESS', result);
    } catch (error) {
      store.commit('DELETE_GRIP_ENABLER_ERROR', error);
    }
  },

  async getGripStrategies(
    store: Store<ProjectsState>,
    payload: { projectId: number; params: GetStrategiesPayload },
  ): Promise<void> {
    try {
      store.commit('GET_GRIP_STRATEGIES_LOADING');

      const { data } = await projectsApi.getGripStrategiesByProjectId(payload);
      const result: Pagination<GripStrategy[]> = data;
      store.commit('GET_GRIP_STRATEGIES_SUCCESS', result);
    } catch (error) {
      store.commit('GET_GRIP_STRATEGIES_ERROR', error);
    }
  },

  async createGripStrategy(
    store: Store<ProjectsState>,
    payload: CreateGripStrategyPayload,
  ): Promise<void> {
    try {
      store.commit('CREATE_GRIP_STRATEGY_LOADING');

      const { data } = await projectsApi.createGripStrategy(payload);
      const result: GripStrategy = data;
      store.commit('CREATE_GRIP_STRATEGY_SUCCESS', result);
    } catch (error) {
      store.commit('CREATE_GRIP_STRATEGY_ERROR', error);
    }
  },

  async updateGripStrategy(
    store: Store<ProjectsState>,
    payload: UpdateGripStrategyPayload,
  ): Promise<void> {
    try {
      store.commit('UPDATE_GRIP_STRATEGY_LOADING');

      const { data } = await projectsApi.updateGripStrategyById(payload);
      const result: any = data;
      store.commit('UPDATE_GRIP_STRATEGY_SUCCESS', result);
    } catch (error) {
      store.commit('UPDATE_GRIP_STRATEGY_ERROR', error);
    }
  },

  async deleteGripStrategy(
    store: Store<ProjectsState>,
    payload: { projectId: number; strategyId: number },
  ): Promise<void> {
    try {
      store.commit('DELETE_GRIP_STRATEGY_LOADING');

      const { data } = await projectsApi.deleteGripStrategyById(payload);
      const result: void = data;
      store.commit('DELETE_GRIP_STRATEGY_SUCCESS', result);
    } catch (error) {
      store.commit('DELETE_GRIP_STRATEGY_ERROR', error);
    }
  },

  async getGrip(
    store: ActionContext<ProjectsState, RootState>,
    projectId: number,
  ): Promise<void> {
    try {
      store.commit('GET_GRIP_DETAILS_LOADING');

      const {
        data,
      }: {
        data: Grip;
      } = await projectsApi.getGrip(projectId);

      store.commit('GET_GRIP_DETAILS_SUCCESS', data);
    } catch (error) {
      store.commit('GET_GRIP_DETAILS_ERROR', error);
    }
  },

  async createGrip(
    store: ActionContext<ProjectsState, RootState>,
    payload: CreateGripPayload,
  ): Promise<void> {
    try {
      store.commit('CREATE_GRIP_DETAILS_LOADING');

      const {
        data,
      }: {
        data: Grip;
      } = await projectsApi.createGrip(payload);

      store.commit('CREATE_GRIP_DETAILS_SUCCESS', data);
    } catch (error) {
      store.commit('CREATE_GRIP_DETAILS_ERROR', error);
    }
  },

  async updateGrip(
    store: ActionContext<ProjectsState, RootState>,
    payload: UpdateGripPayload,
  ): Promise<void> {
    try {
      store.commit('UPDATE_GRIP_DETAILS_LOADING');

      const {
        data,
      }: {
        data: Grip;
      } = await projectsApi.updateGrip(payload);

      store.commit('UPDATE_GRIP_DETAILS_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_GRIP_DETAILS_ERROR', error);
    }
  },

  async getGripAnalysisList(
    store: ActionContext<ProjectsState, RootState>,
    projectId: number,
  ): Promise<void> {
    try {
      store.commit('GET_GRIP_ANALYSIS_LIST_LOADING');

      const {
        data,
      }: {
        data: GripAnalysis[];
      } = await projectsApi.getGripAnalysisList(projectId);

      store.commit('GET_GRIP_ANALYSIS_LIST_SUCCESS', data);
    } catch (error) {
      store.commit('GET_GRIP_ANALYSIS_LIST_ERROR', error);
    }
  },

  async getFilteredGripAnalysisList(
    store: ActionContext<ProjectsState, RootState>,
    payload: { projectId: number; params: GripAnalysisParams },
  ): Promise<void> {
    try {
      store.commit('GET_FILTERED_GRIP_ANALYSIS_LIST_LOADING');

      const {
        data,
      }: {
        data: Pagination<GripAnalysis>;
      } = await projectsApi.getFilteredGripAnalysisList(payload);

      store.commit('GET_FILTERED_GRIP_ANALYSIS_LIST_SUCCESS', data);
    } catch (error) {
      store.commit('GET_FILTERED_GRIP_ANALYSIS_LIST_ERROR', error);
    }
  },

  async getGripAnalysis(
    store: ActionContext<ProjectsState, RootState>,
    payload: {
      projectId: number;
      gripAnalysisId: number;
    },
  ): Promise<void> {
    try {
      store.commit('GET_GRIP_ANALYSIS_LOADING');

      const {
        data,
      }: {
        data: GripAnalysis;
      } = await projectsApi.getGripAnalysis(payload);

      store.commit('GET_GRIP_ANALYSIS_SUCCESS', data);
    } catch (error) {
      store.commit('GET_GRIP_ANALYSIS_ERROR', error);
    }
  },

  async createGripAnalysis(
    store: ActionContext<ProjectsState, RootState>,
    payload: CreateGripAnalysisPayload,
  ): Promise<void> {
    try {
      store.commit('CREATE_GRIP_ANALYSIS_LOADING');
      const {
        data,
      }: {
        data: GripAnalysis;
      } = await projectsApi.createGripAnalysis(payload.projectId, payload);

      store.commit('CREATE_GRIP_ANALYSIS_SUCCESS', data);
    } catch (error) {
      store.commit('CREATE_GRIP_ANALYSIS_ERROR', error);
    }
  },

  async renameGripAnalysis(
    store: ActionContext<ProjectsState, RootState>,
    payload: { projectId: number; payload: RenameGripAnalysisPayload },
  ): Promise<void> {
    try {
      store.commit('RENAME_GRIP_ANALYSIS_LOADING');

      const {
        data,
      }: {
        data: GripAnalysis;
      } = await projectsApi.renameGripAnalysis(
        payload.projectId,
        payload.payload,
      );

      store.commit('RENAME_GRIP_ANALYSIS_SUCCESS', data);
    } catch (error) {
      store.commit('RENAME_GRIP_ANALYSIS_ERROR', error);
    }
  },

  async updateGripAnalysis(
    store: ActionContext<ProjectsState, RootState>,
    payload: { projectId: number; payload: UpdateGripAnalysisPayload },
  ): Promise<void> {
    try {
      store.commit('UPDATE_GRIP_ANALYSIS_LOADING');

      const {
        data,
      }: {
        data: GripAnalysis;
      } = await projectsApi.updateGripAnalysis(
        payload.projectId,
        payload.payload,
      );

      store.commit('UPDATE_GRIP_ANALYSIS_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_GRIP_ANALYSIS_ERROR', error);
    }
  },

  async deleteGripAnalysis(
    store: ActionContext<ProjectsState, RootState>,
    payload: { projectId: number; gripAnalysisId: number },
  ): Promise<void> {
    try {
      store.commit('DELETE_GRIP_ANALYSIS_LOADING');

      const {
        data,
      }: {
        data: void;
      } = await projectsApi.deleteGripAnalysis(
        payload.projectId,
        payload.gripAnalysisId,
      );

      store.commit('DELETE_GRIP_ANALYSIS_SUCCESS', data);
    } catch (error) {
      store.commit('DELETE_GRIP_ANALYSIS_ERROR', error);
    }
  },

  async createGripAnalysisBarrier(
    store: ActionContext<ProjectsState, RootState>,
    payload: {
      projectId: number;
      payload: CreateGripAnalysisBarrierPayload;
    },
  ): Promise<void> {
    try {
      store.commit('CREATE_GRIP_ANALYSIS_BARRIER_LOADING');
      const {
        data,
      }: {
        data: GripAnalysisBarrier;
      } = await projectsApi.createGripAnalysisBarrier(
        payload.projectId,
        payload.payload,
      );

      store.commit('CREATE_GRIP_ANALYSIS_BARRIER_SUCCESS', data);
    } catch (error) {
      store.commit('CREATE_GRIP_ANALYSIS_BARRIER_ERROR', error);
    }
  },

  async updateGripAnalysisBarrier(
    store: ActionContext<ProjectsState, RootState>,
    payload: {
      projectId: number;
      payload: UpdateGripAnalysisBarrierPayload;
    },
  ): Promise<void> {
    try {
      store.commit('UPDATE_GRIP_ANALYSIS_BARRIER_LOADING');

      const {
        data,
      }: {
        data: any;
      } = await projectsApi.updateGripAnalysisBarrier(
        payload.projectId,
        payload.payload,
      );

      store.commit('UPDATE_GRIP_ANALYSIS_BARRIER_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_GRIP_ANALYSIS_BARRIER_ERROR', error);
    }
  },

  async deleteGripAnalysisBarrier(
    store: ActionContext<ProjectsState, RootState>,
    payload: { projectId: number; gripAnalysisBarrierId: number },
  ): Promise<void> {
    try {
      store.commit('DELETE_GRIP_ANALYSIS_BARRIER_LOADING');

      const {
        data,
      }: {
        data: void;
      } = await projectsApi.deleteGripAnalysisBarrier(
        payload.projectId,
        payload.gripAnalysisBarrierId,
      );

      store.commit('DELETE_GRIP_ANALYSIS_BARRIER_SUCCESS', data);
    } catch (error) {
      store.commit('DELETE_GRIP_ANALYSIS_BARRIER_ERROR', error);
    }
  },

  async createGripAnalysisEnabler(
    store: ActionContext<ProjectsState, RootState>,
    payload: {
      projectId: number;
      payload: any;
    },
  ): Promise<void> {
    try {
      store.commit('CREATE_GRIP_ANALYSIS_ENABLER_LOADING');
      const {
        data,
      }: {
        data: GripAnalysisEnabler;
      } = await projectsApi.createGripAnalysisEnabler(
        payload.projectId,
        payload.payload,
      );

      store.commit('CREATE_GRIP_ANALYSIS_ENABLER_SUCCESS', data);
    } catch (error) {
      store.commit('CREATE_GRIP_ANALYSIS_ENABLER_ERROR', error);
    }
  },

  async updateGripAnalysisEnabler(
    store: ActionContext<ProjectsState, RootState>,
    payload: {
      projectId: number;
      payload: any;
    },
  ): Promise<void> {
    try {
      store.commit('UPDATE_GRIP_ANALYSIS_ENABLER_LOADING');

      const {
        data,
      }: {
        data: any;
      } = await projectsApi.updateGripAnalysisEnabler(
        payload.projectId,
        payload.payload,
      );

      store.commit('UPDATE_GRIP_ANALYSIS_ENABLER_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_GRIP_ANALYSIS_ENABLER_ERROR', error);
    }
  },

  async deleteGripAnalysisEnabler(
    store: ActionContext<ProjectsState, RootState>,
    payload: { projectId: number; gripAnalysisEnablerId: number },
  ): Promise<void> {
    try {
      store.commit('DELETE_GRIP_ANALYSIS_ENABLER_LOADING');

      const {
        data,
      }: {
        data: void;
      } = await projectsApi.deleteGripAnalysisEnabler(
        payload.projectId,
        payload.gripAnalysisEnablerId,
      );

      store.commit('DELETE_GRIP_ANALYSIS_ENABLER_SUCCESS', data);
    } catch (error) {
      store.commit('DELETE_GRIP_ANALYSIS_ENABLER_ERROR', error);
    }
  },

  async createGripAnalysisStrategy(
    store: ActionContext<ProjectsState, RootState>,
    payload: {
      projectId: number;
      payload: any;
    },
  ): Promise<void> {
    try {
      store.commit('CREATE_GRIP_ANALYSIS_STRATEGY_LOADING');
      const {
        data,
      }: {
        data: GripAnalysisStrategy;
      } = await projectsApi.createGripAnalysisStrategy(
        payload.projectId,
        payload.payload,
      );

      store.commit('CREATE_GRIP_ANALYSIS_STRATEGY_SUCCESS', data);
    } catch (error) {
      store.commit('CREATE_GRIP_ANALYSIS_STRATEGY_ERROR', error);
    }
  },

  async updateGripAnalysisStrategy(
    store: ActionContext<ProjectsState, RootState>,
    payload: {
      projectId: number;
      payload: any;
    },
  ): Promise<void> {
    try {
      store.commit('UPDATE_GRIP_ANALYSIS_STRATEGY_LOADING');

      const {
        data,
      }: {
        data: any;
      } = await projectsApi.updateGripAnalysisStrategy(
        payload.projectId,
        payload.payload,
      );

      store.commit('UPDATE_GRIP_ANALYSIS_STRATEGY_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_GRIP_ANALYSIS_STRATEGY_ERROR', error);
    }
  },

  async deleteGripAnalysisStrategy(
    store: ActionContext<ProjectsState, RootState>,
    payload: { projectId: number; gripAnalysisStrategyId: number },
  ): Promise<void> {
    try {
      store.commit('DELETE_GRIP_ANALYSIS_STRATEGY_LOADING');

      const {
        data,
      }: {
        data: void;
      } = await projectsApi.deleteGripAnalysisStrategy(
        payload.projectId,
        payload.gripAnalysisStrategyId,
      );

      store.commit('DELETE_GRIP_ANALYSIS_STRATEGY_SUCCESS', data);
    } catch (error) {
      store.commit('DELETE_GRIP_ANALYSIS_STRATEGY_ERROR', error);
    }
  },

  async getGripNotifications(
    store: ActionContext<ProjectsState, RootState>,
    projectId: number,
  ): Promise<void> {
    try {
      store.commit('GET_GRIP_NOTIFICATIONS_LOADING');

      const {
        data,
      }: {
        data: GripNotificationResponse[];
      } = await projectsApi.getGripNotifications(projectId);

      store.commit('GET_GRIP_NOTIFICATIONS_SUCCESS', data);
    } catch (error) {
      store.commit('GET_GRIP_NOTIFICATIONS_ERROR', error);
    }
  },

  async updateGripNotifications(
    store: ActionContext<ProjectsState, RootState>,
    payload: {
      projectId: number;
      payload: any;
    },
  ): Promise<void> {
    try {
      store.commit('UPDATE_GRIP_NOTIFICATIONS_LOADING');

      const {
        data,
      }: {
        data: any;
      } = await projectsApi.updateGripNotifications(
        payload.projectId,
        payload.payload,
      );

      store.commit('UPDATE_GRIP_NOTIFICATIONS_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_GRIP_NOTIFICATIONS_ERROR', error);
    }
  },

  async getGripExportReport(
    store: ActionContext<ProjectsState, RootState>,
    params: GripExportReportPayload,
  ): Promise<void> {
    try {
      store.commit('GET_GRIP_EXPORT_REPORT_LOADING');

      const {
        data,
      }: {
        data: GripExportReportResponse;
      } = await projectsApi.getGripExportReport(params);

      store.commit('GET_GRIP_EXPORT_REPORT_SUCCESS', data);
    } catch (error) {
      store.commit('GET_GRIP_EXPORT_REPORT_ERROR', error);
    }
  },

  async getUpdatedJBICriteriaWithAcknowledgement(
    store: Store<ProjectsState>,
    projectId: number,
  ): Promise<void> {
    try {
      store.commit('GET_UPDATED_JBI_CRITERIA_LIST_WITH_ACKNOWLEDGMENT_LOADING');

      const {
        data,
      }: {
        data: UpdatedJBICriteriaAcknowledgement;
      } = await projectsApi.getUpdatedJBICriteriaWithAcknowledgement(projectId);
      store.commit(
        'GET_UPDATED_JBI_CRITERIA_LIST_WITH_ACKNOWLEDGMENT_SUCCESS',
        data,
      );
    } catch (error) {
      store.commit(
        'GET_UPDATED_JBI_CRITERIA_LIST_WITH_ACKNOWLEDGMENT_ERROR',
        error,
      );
    }
  },

  async getUpdatedProjectReportJBICriteriaList(
    store: Store<ProjectsState>,
    projectId: number,
  ): Promise<void> {
    try {
      store.commit('GET_UPDATED_PROJECT_REPORT_JBI_CRITERIA_LIST_LOADING');

      const {
        data,
      }: {
        data: JBICriteriaDTO[];
      } = await projectsApi.getUpdatedProjectReportJBICriteriaList(projectId);

      store.commit(
        'GET_UPDATED_PROJECT_REPORT_JBI_CRITERIA_LIST_SUCCESS',
        data,
      );
    } catch (error) {
      store.commit('GET_UPDATED_PROJECT_REPORT_JBI_CRITERIA_LIST_ERROR', error);
    }
  },

  async updateJBICriteriaAcknowledgment(
    store: Store<ProjectsState>,
    payload: UpdateJBICriteriaAcknowledgmentPayload,
  ): Promise<void> {
    try {
      store.commit('UPDATE_PROJECT_CRITERIA_ACKNOWLEDGMENT_LOADING');

      const {
        data,
      }: {
        data: Promise<AxiosResponse<any>>;
      } = await projectsApi.updateJBICriteriaAcknowledgment(payload);
      store.commit('UPDATE_PROJECT_CRITERIA_ACKNOWLEDGMENT_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_PROJECT_CRITERIA_ACKNOWLEDGMENT_ERROR', error);
    }
  },

  async getProjectListForAdmin(
    store: Store<ProjectsState>,
    payload: GetProjectPayload,
  ) {
    try {
      store.commit('GET_PROJECT_LIST_FOR_ADMIN_LOADING');
      const {
        data,
      }: {
        data: AdministratorProjectListDTO;
      } = await projectsApi.getProjectListForAdmin(payload);
      store.commit('GET_PROJECT_LIST_FOR_ADMIN_SUCCESS', data);
    } catch (error) {
      store.commit('GET_PROJECT_LIST_FOR_ADMIN_ERROR', error);
    }
  },

  async getProjectParticipantsForAdmin(
    store: Store<ProjectsState>,
    projectId: number,
  ) {
    try {
      store.commit('GET_PROJECT_PARTICIPANTS_FOR_ADMIN_LOADING');
      const {
        data,
      }: {
        data: ProjectParticipantDTO[];
      } = await projectsApi.getProjectParticipantsForAdmin(projectId);
      store.commit('GET_PROJECT_PARTICIPANTS_FOR_ADMIN_SUCCESS', data);
    } catch (error) {
      store.commit('GET_PROJECT_PARTICIPANTS_FOR_ADMIN_ERROR', error);
    }
  },

  async getProjectSitesForAdmin(
    store: Store<ProjectsState>,
    projectId: number,
  ) {
    try {
      store.commit('GET_PROJECT_SITES_FOR_ADMIN_LOADING');
      const {
        data,
      }: {
        data: ProjectSite[];
      } = await projectsApi.getProjectSitesForAdmin(projectId);
      store.commit('GET_PROJECT_SITES_FOR_ADMIN_SUCCESS', data);
    } catch (error) {
      store.commit('GET_PROJECT_SITES_FOR_ADMIN_ERROR', error);
    }
  },

  async getExistingProjectOwnerRequest(
    store: Store<ProjectsState>,
    payload: GetExistingProjectOwnerRequestPayload,
  ) {
    try {
      store.commit('GET_EXISTING_PROJECT_OWNER_REQUEST_LOADING');

      const {
        data,
      }: {
        data: any;
      } = await projectsApi.getExistingProjectOwnerRequest(payload);
      store.commit('GET_EXISTING_PROJECT_OWNER_REQUEST_SUCCESS', data);
    } catch (error) {
      store.commit('GET_EXISTING_PROJECT_OWNER_REQUEST_ERROR', error);
    }
  },

  async changeProjectOwnerRequest(
    store: Store<ProjectsState>,
    payload: ChangeProjectOwnerPayload,
  ) {
    try {
      store.commit('CHANGE_PROJECT_OWNER_REQUEST_LOADING');
      const {
        data,
      }: {
        data: ValidUserPayload;
      } = await projectsApi.changeProjectOwnerRequest(
        payload.projectId,
        payload,
      );
      store.commit('CHANGE_PROJECT_OWNER_REQUEST_SUCCESS', data);
    } catch (error) {
      store.commit('CHANGE_PROJECT_OWNER_REQUEST_ERROR', error);
    }
  },

  async updateProjectOwnerRequest(
    store: Store<ProjectsState>,
    payload: UpdateProjectOwnerRequestPayload,
  ) {
    try {
      store.commit('UPDATE_PROJECT_OWNER_REQUEST_LOADING');

      const { data } = await projectsApi.updateProjectOwnerRequest(
        payload.projectId,
        payload,
      );

      store.commit('UPDATE_PROJECT_OWNER_REQUEST_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_PROJECT_OWNER_REQUEST_ERROR', error);
    }
  },

  async updateCancelProjectRequest(store: Store<ProjectsState>, payload: any) {
    try {
      store.commit('UPDATE_CANCEL_PROJECT_REQUEST_LOADING');
      const {
        data,
      }: {
        data: any;
      } = await projectsApi.updateCancelProjectRequest(payload);
      store.commit('UPDATE_CANCEL_PROJECT_REQUEST_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_CANCEL_PROJECT_REQUEST_ERROR', error);
    }
  },

  async getProjectOwnerHistory(store: Store<ProjectsState>, projectId: number) {
    try {
      store.commit('GET_PROJECT_OWNER_HISTORY_LOADING');
      const {
        data,
      }: {
        data: any;
      } = await projectsApi.getProjectOwnerHistory(projectId);
      store.commit('GET_PROJECT_OWNER_HISTORY_SUCCESS', data);
    } catch (error) {
      store.commit('GET_PROJECT_OWNER_HISTORY_ERROR', error);
    }
  },
};
