import {
  AuditReportAnalysisResponseDto,
  AuditSite,
} from '@/store/modules/audits/types/audits.types';
import { GenerateSingleSiteExcel } from '@/components/reports/export/excel/audit/SingleSiteExcel';
import { GenerateMultiSiteExcel } from '@/components/reports/export/excel/audit/MultiSiteExcel';
import { GenerateSingleSiteComparisonExcel } from '@/components/reports/export/excel/audit/SingleSiteComparisonExcel';
import { GenerateMultiSiteComparisonExcel } from '@/components/reports/export/excel/audit/MultiSiteComparisonExcel';
import dayjs from 'dayjs';
import { Workbook } from 'exceljs';
import { ToastProgrammatic as Toast } from 'buefy';
import { cloneDeep as _cloneDeep } from 'lodash';

export class AuditReportExportToExcel {
  public async create(
    reportAnalysisResponse: AuditReportAnalysisResponseDto[],
    auditTitle: string,
  ) {
    const exportDateTime = dayjs().format('D MMM YYYY');
    const exportTitle = auditTitle + '_' + exportDateTime;
    const csvListArray: any[] = [];
    await Promise.all(
      reportAnalysisResponse.map(
        async (analysis: AuditReportAnalysisResponseDto, index: number) => {
          csvListArray[index] = await this.handleReportResponse(analysis);
        },
      ),
    );
    const singleAnalysisWorkbook = new Workbook();
    reportAnalysisResponse.forEach(
      (analysis: AuditReportAnalysisResponseDto, analysisIndex: number) => {
        const singleAnalysisWorksheet = singleAnalysisWorkbook.addWorksheet(
          analysisIndex + 1 + '-' + analysis.analysisTitle,
        );
        csvListArray[analysisIndex].forEach(
          (listItem: any, listItemIndex: number) => {
            singleAnalysisWorksheet.addRow(listItem.data);
            this.applyStyles(singleAnalysisWorksheet, listItem.cellInfo);
          },
        );
        const totalColumns = singleAnalysisWorksheet.columnCount;
        for (let temp = 1; temp <= totalColumns; temp++) {
          switch (temp) {
            case 1:
              singleAnalysisWorksheet.getColumn(temp).width = 100;
              break;
            default:
              singleAnalysisWorksheet.getColumn(temp).width = 50;
              break;
          }
        }
      },
    );

    try {
      singleAnalysisWorkbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(blob, exportTitle + '.xlsx');
      });
    } catch (err) {
      Toast.open({
        message: `Something went wrong. Try again.`,
        position: 'is-top',
        type: 'is-danger',
        duration: 3500,
      });
    } finally {
      Toast.open({
        message: `Export complete.`,
        position: 'is-top',
        type: 'is-dark',
        duration: 3500,
      });
    }
  }

  public applyStyles(singleAnalysisWorksheet: any, cellInfo: any) {
    cellInfo.forEach((item: any, itemIndex: number) => {
      if (item.isEmpty) {
        singleAnalysisWorksheet.getCell(item.name).font = {
          size: 18,
        };
        return;
      }
      singleAnalysisWorksheet.getCell(item.name).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: item.bgColor },
      };
      singleAnalysisWorksheet.getCell(item.name).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      singleAnalysisWorksheet.getCell(item.name).alignment = {
        vertical: 'bottom',
        horizontal: item.alignment,
        wrapText: item.wrapText,
      };
      singleAnalysisWorksheet.getCell(item.name).font = {
        bold: item.bold,
        size: 18,
      };
      if (item.mergeInfo) {
        singleAnalysisWorksheet.mergeCells(item.mergeInfo);
      }
    });
  }

  public async handleReportResponse(
    analysisResponse: AuditReportAnalysisResponseDto,
  ) {
    const {
      result,
      payload,
      analysisTitle,
      projectTitle,
      auditTitle,
      auditSites,
    } = analysisResponse;

    switch (payload.comparisonPeriods.length) {
      case 1:
        return await this.generateSinglePeriodExcel(
          result,
          payload,
          analysisTitle,
          projectTitle,
          auditTitle,
          auditSites,
        );
      default:
        return await this.generateMultiPeriodExcel(
          result,
          payload,
          analysisTitle,
          projectTitle,
          auditTitle,
          auditSites,
        );
    }
  }

  public async generateSinglePeriodExcel(
    result: any,
    payload: any,
    analysisTitle: string,
    projectTitle: string,
    auditTitle: string,
    auditSites: AuditSite[],
  ) {
    switch (auditSites.length) {
      case 1:
        const generateSingleSiteExcel = new GenerateSingleSiteExcel();
        return generateSingleSiteExcel.create(
          result,
          payload,
          analysisTitle,
          projectTitle,
          auditTitle,
          auditSites,
        );
      default:
        const generateMultiSiteExcel = new GenerateMultiSiteExcel();
        return generateMultiSiteExcel.create(
          result,
          payload,
          analysisTitle,
          projectTitle,
          auditTitle,
          auditSites,
        );
    }
  }

  public async generateMultiPeriodExcel(
    result: any,
    payload: any,
    analysisTitle: string,
    projectTitle: string,
    auditTitle: string,
    auditSites: AuditSite[],
  ) {
    switch (auditSites.length) {
      case 1:
        const generateSingleSiteComparisonExcel = new GenerateSingleSiteComparisonExcel();
        return generateSingleSiteComparisonExcel.create(
          result,
          payload,
          analysisTitle,
          projectTitle,
          auditTitle,
          auditSites,
        );
      default:
        const generateMultiSiteComparisonExcel = new GenerateMultiSiteComparisonExcel();
        return generateMultiSiteComparisonExcel.create(
          result,
          payload,
          analysisTitle,
          projectTitle,
          auditTitle,
          auditSites,
        );
    }
  }
}
