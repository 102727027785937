// tslint:disable:max-classes-per-file

export enum CRITERION_TYPE {
  BOOLEAN = 'BOOLEAN',
  RANGE = 'RANGE',
  CHECKBOXES = 'CHECKBOXES',
  CHECKBOXES_SINGLE = 'CHECKBOXES_SINGLE',
  CONTINUOUS_DISCRETE = 'CONTINUOUS_DISCRETE',
}

export enum CRITERION_TYPE_DISPLAY_NAME {
  BOOLEAN = 'Boolean',
  RANGE = 'Range',
  CHECKBOXES = 'Multiple Checkboxes',
  CHECKBOXES_SINGLE = 'Single Checkbox',
  CONTINUOUS_DISCRETE = 'Continuous / Discrete',
}

class BaseCriterionContent {
  public type: CRITERION_TYPE = CRITERION_TYPE.BOOLEAN;
  public label: CRITERION_TYPE_DISPLAY_NAME =
    CRITERION_TYPE_DISPLAY_NAME.BOOLEAN;
  public title: string;
  public guide?: string;
  public sourceLink?: string;

  constructor({ title }: Partial<BaseCriterionContent>) {
    this.title = title || '';
  }
}

export class BooleanCriterionContent extends BaseCriterionContent {
  public type = CRITERION_TYPE.BOOLEAN;
  public label = CRITERION_TYPE_DISPLAY_NAME.BOOLEAN;
  public booleanOptions: [string, string, string] = ['Yes', 'No', 'N/A'];
  public guide: string = '';
  public sourceLink: string = '';

  constructor(params: Partial<BooleanCriterionContent>) {
    super(params);
    this.booleanOptions = params.booleanOptions || this.booleanOptions;
    this.sourceLink = params.sourceLink || this.sourceLink;
  }
}

export class RangeCriterionContent extends BaseCriterionContent {
  public type = CRITERION_TYPE.RANGE;
  public label = CRITERION_TYPE_DISPLAY_NAME.RANGE;
  public range: [number, number, string] = [1, 10, 'N/A'];
  public guide: string = '';
  public sourceLink: string = '';

  constructor(params: Partial<RangeCriterionContent>) {
    super(params);
    this.range = params.range || this.range;
    this.sourceLink = params.sourceLink || this.sourceLink;
  }
}

export class CheckboxesCriterionContent extends BaseCriterionContent {
  public type = CRITERION_TYPE.CHECKBOXES;
  public label = CRITERION_TYPE_DISPLAY_NAME.CHECKBOXES;
  public checkboxesOptions: string[] = ['N/A'];
  public guide: string = '';
  public sourceLink: string = '';

  constructor(params: Partial<CheckboxesCriterionContent>) {
    super(params);
    this.checkboxesOptions = params.checkboxesOptions || this.checkboxesOptions;
    this.sourceLink = params.sourceLink || this.sourceLink;
  }
}

export class CheckboxesSingleCriterionContent extends BaseCriterionContent {
  public type = CRITERION_TYPE.CHECKBOXES_SINGLE;
  public label = CRITERION_TYPE_DISPLAY_NAME.CHECKBOXES_SINGLE;
  public checkboxesOptions: string[] = ['N/A'];
  public guide: string = '';
  public sourceLink: string = '';

  constructor(params: Partial<CheckboxesCriterionContent>) {
    super(params);
    this.checkboxesOptions = params.checkboxesOptions || this.checkboxesOptions;
    this.sourceLink = params.sourceLink || this.sourceLink;
  }
}

export class ContinuousDiscreteCriterionContent extends BaseCriterionContent {
  public type = CRITERION_TYPE.CONTINUOUS_DISCRETE;
  public label = CRITERION_TYPE_DISPLAY_NAME.CONTINUOUS_DISCRETE;
  public config: number[] | null = null;
  public continuousDiscreteOptions: string[] = ['N/A'];
  public guide: string = '';
  public sourceLink: string = '';

  constructor(params: Partial<ContinuousDiscreteCriterionContent>) {
    super(params);
    this.config = params.config || this.config;
    this.continuousDiscreteOptions =
      params.continuousDiscreteOptions || this.continuousDiscreteOptions;
    this.sourceLink = params.sourceLink || this.sourceLink;
  }
}

export type CriterionContent =
  | BooleanCriterionContent
  | RangeCriterionContent
  | CheckboxesCriterionContent
  | CheckboxesSingleCriterionContent
  | ContinuousDiscreteCriterionContent;

export class Criterion {
  public id?: number;
  public tempId?: number | string;
  public content: CriterionContent;
  public subSections?: Array<{ id?: number; tempId?: number | string }> = [];

  constructor(params: Criterion) {
    this.id = params.id || this.id;
    this.tempId = params.tempId || this.tempId;
    this.content = params.content;
    this.subSections = params.subSections || this.subSections;
  }
}
