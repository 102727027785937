import Vue from 'vue';
import VueRouter from 'vue-router';
import HomePage from '@/views/Home/HomePage.vue';
import DashboardPage from '@/views/Dashboard/DashboardPage.vue';
import Oauth2Callback from '@/views/Oauth2Callback/Oauth2CallbackPage.vue';
import ProjectCreatePage from '@/views/Project/ProjectCreatePage.vue';
import ProjectListPage from '@/views/Project/ProjectListPage.vue';
import ProjectDetailPage from '@/views/Project/ProjectDetailPage.vue';
import ProjectDetailPageDataCollector from '@/views/Project/dataCollector/ProjectDetailPageDataCollector.vue';
import SiteListPage from '@/views/Site/SiteListPage.vue';
import AcceptInvitationToken from '@/views/AcceptInvitationToken/AcceptInvitationToken.vue';
import InviteAuditParticipant from '@/views/AcceptInvitationToken/InviteAuditParticipant.vue';
import InviteProjectParticipant from '@/views/AcceptInvitationToken/InviteProjectParticipant.vue';
import ProjectNewUserInvite from '@/views/AcceptInvitationToken/ProjectNewUserInvite.vue';
import AuditPage from '@/views/Audit/AuditPage.vue';
import AuditPageDataCollector from '@/views/Audit//dataCollector/AuditPageDataCollector.vue';
import AdminUserManagement from '@/views/AdminUserManagement/AdminUserManagement.vue';
import AdminProjectManagement from '@/views/AdminProjectManagement/AdminProjectManagement.vue';
import { GeneralRoles } from '@/jbi-shared/types/paces-casbin-permission.types';
import { requireAuth } from '@/router/router.guard';
import DataCollectionPage from '@/views/Audit/dataCollectionPage.vue';
import NoSubscription from '@/views/subscription/NoSubscription.vue';
import ExtendSubscription from '@/views/subscription/ExtendSubscription.vue';
import DocumentPreviewPage from '@/views/Project/components/DocumentPreviewPage.vue';
import { permissionGuard } from '@/router/permission.guard';
import ProjectOwnerRequestInvitation from '@/views/AcceptInvitationToken/ProjectOwnerRequestInvitation.vue';
import NotificationPage from '@/views/Notification/NotificationPage.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/oauth2/callback',
    name: 'oauth2callback',
    component: Oauth2Callback,
  },
  {
    path: '/',
    name: 'homepage',
    component: HomePage,
  },
  {
    path: '/no-subscription',
    name: 'noSubscription',
    component: NoSubscription,
  },
  {
    path: '/extend-subscription',
    name: 'extendSubscription',
    component: ExtendSubscription,
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardPage,
    beforeEnter: permissionGuard,
  },
  // TODO: Disable Notifications
  // {
  //   path: '/notification',
  //   name: 'notification',
  //   component: NotificationPage,
  //   beforeEnter: permissionGuard,
  // },
  {
    path: '/projects/create',
    name: 'project-create',
    component: ProjectCreatePage,
    beforeEnter: permissionGuard,
  },
  {
    path: '/projects/:projectId/draft',
    name: 'project-draft',
    component: ProjectCreatePage,
    beforeEnter: permissionGuard,
  },
  {
    path: '/documents/JBI-:typeShorthand-:documentId-:publicationId/',
    name: 'preview-publication',
    component: DocumentPreviewPage,
  },
  {
    path: '/projects/me',
    name: 'project-me',
    component: ProjectListPage,
  },
  {
    path: '/site',
    name: 'site',
    component: SiteListPage,
    beforeEnter: permissionGuard,
  },
  {
    path: '/projects/:projectId/homepage',
    name: 'project-details-data-collector',
    component: ProjectDetailPageDataCollector,
  },
  {
    path: '/projects/:projectId',
    name: 'project-details',
    component: ProjectDetailPage,
  },
  {
    path: '/audit-invitation',
    name: 'audit-invitation',
    component: InviteAuditParticipant,
  },
  {
    path: '/accept-project-invitation',
    name: 'accept-project-invitation',
    component: InviteProjectParticipant,
  },
  {
    path: '/accept-project-invitation/new-user-invite/:invitationToken',
    name: 'project-new-user-invite',
    component: ProjectNewUserInvite,
  },
  {
    path: '/audits/:auditId/homepage',
    name: 'audit-dashboard-data-collector',
    component: AuditPageDataCollector,
  },
  {
    path: '/audits/:auditId',
    name: 'audit',
    component: AuditPage,
  },
  {
    path: '/audits/:auditId/data-collections/:dataCollectionRecordId?',
    name: 'data-collections',
    component: DataCollectionPage,
  },
  // for admin only
  {
    path: '/admin/user-management',
    name: 'admin-user-management',
    component: AdminUserManagement,
    beforeEnter: requireAuth,
    meta: {
      roles: [GeneralRoles.Admin],
    },
  },
  {
    path: '/admin/project-management',
    name: 'admin-project-management',
    component: AdminProjectManagement,
    beforeEnter: requireAuth,
    meta: {
      roles: [GeneralRoles.Admin],
    },
  },
  {
    path: '/project-owner-request-invitation',
    name: 'project-owner-request-invitation',
    component: ProjectOwnerRequestInvitation,
  },
];

const router = new VueRouter({
  linkExactActiveClass: 'exact-active',
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
