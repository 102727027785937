































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import BaseModal from '@/jbi-shared/vue-components/BaseModal.vue';
import draggable from 'vuedraggable';
import { CplusCriteria } from '@/store/modules/projects/types/projects.types';
import { cloneDeep as _cloneDeep, orderBy as _orderBy } from 'lodash';
import { DialogProgrammatic as Dialog } from 'buefy';
import AuditCriteriaCreateModal from '@/views/Project/components/AuditCriteriaCreateModal.vue';
import CriteriaGuideModal from '@/components/CriteriaGuideModal.vue';
import {
  BooleanCriterionContent,
  CheckboxesCriterionContent,
  CheckboxesSingleCriterionContent,
  Criterion,
  CRITERION_TYPE,
  RangeCriterionContent,
  ContinuousDiscreteCriterionContent,
} from '@/store/types/criterions.types';
import {
  isDifferentDeep,
  isTruthy,
} from '@/jbi-shared/util/watcher.vue-decorator';
import JbiCriteriaUpdateModal from '../../../views/Project/components/JbiCriteriaUpdateModal.vue';
import { Action, State } from 'vuex-class';
import { ToastProgrammatic as Toast } from 'buefy';
import { ApiState } from '../../../store/types/general.types';

@Component({
  components: {
    BaseModal,
    draggable,
    AuditCriteriaCreateModal,
    CriteriaGuideModal,
  },
  data: () => {
    return {
      CRITERION_TYPE,
    };
  },
})
export default class AuditCriteriaList extends Vue {
  @Prop()
  public selectedCriteriaArray!: CplusCriteria[];
  @Prop({ default: null })
  public projectId!: number;
  @Prop({ default: null })
  public auditId!: number;
  @Prop({ default: false })
  public createProject!: boolean;
  @Prop({ default: false })
  public isProject!: boolean;
  @Prop({
    default() {
      return false;
    },
  })
  public projectEdit!: boolean;

  @Prop({ default: true })
  public editable!: boolean;

  public reOrderedCriteriaArray: CplusCriteria[] = [];
  public drag = false;
  public guideTooltipLabel = 'Add, edit, or delete a guide for this criteria';

  public dragOptions = {
    animation: 200,
    group: 'description',
    disabled: !this.editable,
    ghostClass: 'draggable__ghost',
    chosenClass: 'draggable__chosen',
    dragClass: 'draggable__drag',
    forceFallback: false, // it cause weird drag behavoir when set it to true
  };

  @Action('projects/getProjectCriteria')
  public getProjectCriteria!: (projectId: number) => void;

  @Action('audits/getAuditCriteria')
  public getAuditCriteria!: (auditId: number) => void;

  @Action('projects/updateProjectCriteriaById')
  public updateProjectCriteriaById!: (payload: {
    projectId: number;
    criteria: CplusCriteria;
  }) => void;

  @State((state) => state.projects.apiState.updateProjectCriteriaById)
  public updateProjectCriteriaByIdState!: ApiState;

  @Action('audits/updateAuditCriteriaById')
  public updateAuditCriteriaById!: (payload: {
    auditId: number;
    criteria: CplusCriteria;
  }) => void;

  @State((state) => state.audits.apiState.updateAuditCriteriaById)
  public updateAuditCriteriaByIdState!: ApiState;

  @Action('projects/getUpdatedJBICriteriaWithAcknowledgement')
  public getUpdatedJBICriteriaWithAcknowledgement!: (projectId: number) => void;

  get selectedCriteriaList() {
    return _orderBy(this.selectedCriteriaArray, ['order'], ['asc']);
  }

  public removeCriteria(index: number) {
    const message = `<h1 class="title">Are you sure, do you want to remove this Criterion?</h1>`;

    return Dialog.confirm({
      message,
      confirmText: 'Remove',
      cancelText: 'Cancel',
      onConfirm: () => {
        this.$emit(
          'updateDeletedCriteriaList',
          this.reOrderedCriteriaArray[index],
        );
        this.reOrderedCriteriaArray.splice(index, 1);
        this.$emit('update:selectedCriteriaArray', this.reOrderedCriteriaArray);
        this.$emit('updateCriteriaHandler');
      },
    });
  }

  public mounted() {
    this.reOrderedCriteriaArray = _cloneDeep(this.selectedCriteriaList);
  }

  public getRangeValue(type: string, criteria: any): string {
    const config =
      this.createProject && Object.keys(criteria).includes('config')
        ? criteria.config
        : criteria.criteriaDetails && criteria.criteriaDetails.config
        ? criteria.criteriaDetails.config
        : null;

    const configArray = typeof config === 'string' ? config.split(',') : config;
    if (type === 'min') {
      return configArray[0];
    } else {
      return configArray[1];
    }
  }

  public isContinuousDiscreteRangeEnabled(criteria: any): boolean {
    const config =
      this.createProject && Object.keys(criteria).includes('config')
        ? criteria.config
        : criteria.criteriaDetails && criteria.criteriaDetails.config
        ? criteria.criteriaDetails.config
        : null;

    return config ? true : false;
  }

  public openCriteriaAddModal() {
    this.$buefy.modal.open({
      parent: this,
      component: AuditCriteriaCreateModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle: 'Add New Criteria',
        reOrderedCriteriaArray: this.reOrderedCriteriaArray,
      },
      events: {
        updateReOrderedCriteriaArray: (newValue: any) => {
          this.reOrderedCriteriaArray = newValue;
          this.$emit(
            'update:selectedCriteriaArray',
            this.reOrderedCriteriaArray,
          );
        },
      },
    });
  }

  public openCriteriaGuideModal(
    criteria: CplusCriteria,
    criteriaIndex: number,
  ) {
    this.$buefy.modal.open({
      parent: this,
      component: CriteriaGuideModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        currentCriteria: criteria,
        criteriaIndex,
        projectId: this.projectId,
        auditId: this.auditId,
        createProject: this.createProject,
      },
      events: {
        updateCriteriaGuide: (newValue: any) => {
          this.reOrderedCriteriaArray[criteriaIndex] = newValue;
          this.$emit(
            'update:selectedCriteriaArray',
            this.reOrderedCriteriaArray,
          );
        },
      },
    });
  }

  public openCriteriaEditModal(criteria: CplusCriteria, criteriaIndex: number) {
    let criterion: Criterion;
    if (criteria.type === CRITERION_TYPE.BOOLEAN) {
      criterion = new Criterion({
        content: new BooleanCriterionContent({
          title: criteria.title,
          type: CRITERION_TYPE.BOOLEAN,
          booleanOptions: criteria.booleanOptions,
          sourceLink: criteria.sourceLink,
        }),
      });
    } else if (criteria.type === CRITERION_TYPE.RANGE) {
      criterion = new Criterion({
        content: new RangeCriterionContent({
          title: criteria.title,
          type: CRITERION_TYPE.RANGE,
          range: criteria.range,
          sourceLink:
            criteria.sourceLink !== undefined ? criteria.sourceLink : '',
        }),
      });
    } else if (criteria.type === CRITERION_TYPE.CHECKBOXES_SINGLE) {
      criterion = new Criterion({
        content: new CheckboxesSingleCriterionContent({
          title: criteria.title,
          type: CRITERION_TYPE.CHECKBOXES_SINGLE,
          checkboxesOptions: criteria.checkboxesOptions,
          sourceLink:
            criteria.sourceLink !== undefined ? criteria.sourceLink : '',
        }),
      });
    } else if (criteria.type === CRITERION_TYPE.CHECKBOXES) {
      criterion = new Criterion({
        content: new CheckboxesCriterionContent({
          title: criteria.title,
          type: CRITERION_TYPE.CHECKBOXES,
          checkboxesOptions: criteria.checkboxesOptions,
          sourceLink:
            criteria.sourceLink !== undefined ? criteria.sourceLink : '',
        }),
      });
    } else {
      const criteriaConfig = criteria.config
        ? criteria.config
        : criteria.criteriaDetails && criteria.criteriaDetails.config
        ? criteria.criteriaDetails.config
        : null;

      const config =
        typeof criteriaConfig === typeof 'string'
          ? criteriaConfig.split(', ')
          : criteriaConfig;
      criterion = new Criterion({
        content: new ContinuousDiscreteCriterionContent({
          title: criteria.title,
          type: CRITERION_TYPE.CONTINUOUS_DISCRETE,
          config,
          sourceLink:
            criteria.sourceLink !== undefined ? criteria.sourceLink : '',
        }),
      });
    }
    this.$buefy.modal.open({
      parent: this,
      component: AuditCriteriaCreateModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle: 'Edit Criteria',
        criterion,
        reOrderedCriteriaArray: this.reOrderedCriteriaArray,
        criteriaIndex,
        currentCriteria: criteria,
        projectEdit: this.projectEdit,
        createProject: this.createProject,
        isProject: this.isProject,
      },
      events: {
        updateReOrderedCriteriaArray: (newValue: any) => {
          this.reOrderedCriteriaArray = newValue;
          this.$emit(
            'update:selectedCriteriaArray',
            this.reOrderedCriteriaArray,
          );
          this.$emit('updateCriteriaHandler');
        },
        updateCriteria: (newValue: any) => {
          if (this.isProject) {
            this.updateProjectCriteriaById({
              projectId: this.projectId,
              criteria: newValue,
            });
          } else {
            this.updateAuditCriteriaById({
              auditId: this.auditId,
              criteria: newValue,
            });
          }
        },
      },
    });
  }

  public handleCriteriaReorder(event: any) {
    this.reOrderedCriteriaArray.forEach((criteria, index) => {
      criteria.order = index + 1;
    });
    this.$emit('update:selectedCriteriaArray', this.reOrderedCriteriaArray);
  }

  @Watch('selectedCriteriaArray')
  @isTruthy
  @isDifferentDeep
  public watchSelectedCriteriaArray() {
    this.reOrderedCriteriaArray = _cloneDeep(this.selectedCriteriaList);
  }

  public getTags(tagInfo: any) {
    if (Array.isArray(tagInfo)) {
      return tagInfo;
    } else {
      const tags: string[] = [];
      if (tagInfo) {
        Object.keys(tagInfo).forEach((key: string) => {
          const tag = tagInfo[key];
          tag.forEach((value: string) => {
            tags.push(value);
          });
        });
      }
      return tags;
    }
  }

  @Watch('updateProjectCriteriaByIdState.success')
  @isTruthy
  public watchUpdateProjectCriteriaByIdState() {
    this.getProjectCriteria(this.projectId);
    this.getUpdatedJBICriteriaWithAcknowledgement(this.projectId);
    Toast.open({
      message: `Criteria successfully updated.`,
      position: 'is-top',
      type: 'is-dark',
      duration: 3500,
    });
  }

  @Watch('updateAuditCriteriaByIdState.success')
  @isTruthy
  public watchUpdateAuditCriteriaByIdState(): void {
    this.getAuditCriteria(this.auditId);
    Toast.open({
      message: `Criteria successfully updated.`,
      position: 'is-top',
      type: 'is-dark',
      duration: 3500,
    });
  }

  public openJbiCriteriaUpdateModal(criteria: CplusCriteria): void {
    this.$buefy.modal.open({
      parent: this,
      component: JbiCriteriaUpdateModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        criteria,
      },
    });
  }
}
