var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(validator){return [_c('div',_vm._b({staticClass:"modal-card"},'div',_vm.dirtySite,false),[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v(" "+_vm._s(_vm.modalTitle)+" ")]),_c('button',{staticClass:"close-icon",attrs:{"type":"button"},on:{"click":_vm.closeModal}},[_c('i',{staticClass:"mdi mdi-close"})])]),_c('section',{staticClass:"modal-card-body"},[_c('ValidationProvider',{attrs:{"rules":"required|max:255","name":"Site Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:"label-style",attrs:{"label":"Site Name","message":errors[0],"type":errors.length ? 'is-danger' : undefined}},[_c('b-input',{attrs:{"placeholder":"Name of institution or place to run data collection period"},model:{value:(_vm.dirtySite.name),callback:function ($$v) {_vm.$set(_vm.dirtySite, "name", $$v)},expression:"dirtySite.name"}})],1)]}}],null,true)}),_c('b-field',{staticClass:"label-style",attrs:{"label":"Address"}},[_c('b-input',{attrs:{"placeholder":"Address of a medical institution"},model:{value:(_vm.dirtySite.address),callback:function ($$v) {_vm.$set(_vm.dirtySite, "address", $$v)},expression:"dirtySite.address"}})],1),_c('b-field',{staticClass:"no-bottom-margin label-style",attrs:{"label":"Description"}},[_c('b-input',{attrs:{"type":"textarea","placeholder":"Description of the site (example : opening hours, phone number)"},model:{value:(_vm.dirtySite.description),callback:function ($$v) {_vm.$set(_vm.dirtySite, "description", $$v)},expression:"dirtySite.description"}})],1),_c('hr'),_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-left"},[_c('div',{staticClass:"column padding-none"},[_c('p',{staticClass:"text-size text-color"},[_vm._v(" Sample Code "),_c('b-tooltip',{class:_vm.$style.tooltip,attrs:{"label":"Data collection sample code in individual mode.","position":"is-top","type":"is-dark"}},[_c('b-icon',{attrs:{"icon":"information-outline","size":"is-small","custom-size":"mdi-15px"}})],1)],1),_c('p',{class:_vm.$style.sampleText},[_vm._v(" This attribute setting is optional by default. ")])])]),_c('div',{staticClass:"level-right"},[_c('b-switch',{model:{value:(_vm.dirtySite.isIdentifierRequired),callback:function ($$v) {_vm.$set(_vm.dirtySite, "isIdentifierRequired", $$v)},expression:"dirtySite.isIdentifierRequired"}},[_vm._v("Required")])],1)])],1),_c('footer',{staticClass:"modal-card-foot"},[_c('button',{staticClass:"button is-primary is-rounded",on:{"click":function($event){_vm.task === 'Edit'
            ? validator.validate().then(function (validated) {
                if (validated === false) { return; }
                _vm.updateSite();
              })
            : validator.validate().then(function (validated) {
                if (validated === false) { return; }
                _vm.addSite();
              })}}},[_vm._v(" "+_vm._s(_vm.task === 'Edit' ? 'Save' : 'Add Site')+" ")])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }