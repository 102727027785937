

































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { cloneDeep as _cloneDeep } from 'lodash';
import { reportExportType } from '@/store/types/general.types';

@Component({ components: {} })
export default class ProjectExportExportButton extends Vue {
  @Prop({ default: false })
  public disabled!: boolean;
  @Prop({ default: false })
  public isProjectDashboard!: boolean;
  public dirtyExportType: string[] = [];

  public mounted() {
    this.dirtyExportType = _cloneDeep(reportExportType);
    /** project dashboard export should have 'CSV' & 'XLSX' formats only as option */
    if (this.isProjectDashboard) {
      this.dirtyExportType.splice(0, 2);
    } else {
      /** export report should have 'PDF' & 'DOCX' formats only as option */
      this.dirtyExportType.splice(2, 4);
    }
  }

  public handleExport(exportType: string) {
    this.$emit('handleExport', exportType);
  }
}
