


































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  DataCollectionSummaryRequestPayload,
  DataCollectionSummaryResponsePayload,
} from '@/store/modules/audits/types/audits.types';
import { Action, State } from 'vuex-class';
import { cloneDeep as _cloneDeep, orderBy as _orderBy } from 'lodash';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import CriteriaViewUpdateComponent from '../../Project/components/CriteriaViewUpdateComponent.vue';
@Component({
  components: {
    CriteriaViewUpdateComponent,
  },
})
export default class DataCollectionSummaryTab extends Vue {
  @Prop()
  public selectedSites!: number[];
  @Prop()
  public auditId!: number;
  @Prop({ default: true })
  public editable!: boolean;
  @Prop({ default: false })
  public isDataCollectionActive!: boolean;
  @Prop({ default: false })
  public disabledSubmitRecord!: boolean;

  public dirtyDataCollectionSummary: any = {};

  @Action('audits/getDataCollectionSummary')
  public getDataCollectionSummary!: (
    payload: DataCollectionSummaryRequestPayload,
  ) => void;

  @State((state) => state.audits.dataCollectionSummary)
  public dataCollectionSummary!: DataCollectionSummaryResponsePayload;

  public mounted() {
    this.getDataCollectionSummary({
      auditId: this.auditId,
    });
  }

  @Watch('dataCollectionSummary')
  public watchDataCollectionSummary() {
    this.dirtyDataCollectionSummary = _cloneDeep(this.dataCollectionSummary);
  }

  @Watch('selectedSites')
  @isTruthy
  public watchSelectedSites(newValue: number[]) {
    if (newValue.length > 0) {
      this.getDataCollectionSummary({
        auditId: this.auditId,
        siteIds: newValue.join(),
      });
    } else {
      this.dirtyDataCollectionSummary.configurationData.forEach(
        (summary: any) => {
          summary.criteriaSamplingData.total = 0;
          for (const key of Object.keys(
            summary.criteriaSamplingData.criteriaOptionsDataDistribution,
          )) {
            summary.criteriaSamplingData.criteriaOptionsDataDistribution[
              key
            ] = 0;
          }
        },
      );
    }
  }

  public handleCriteriaEnterData(auditCriteriaId: number) {
    this.$router.push({
      name: 'audit',
      params: {
        auditId: this.auditId.toString(),
      },
      hash: '#submitRecord',
    });
    this.$emit('showDataCollectionTemplateForSingleCriteria', auditCriteriaId);
  }

  get configurationData() {
    return _orderBy(
      this.dirtyDataCollectionSummary.configurationData,
      ['order'],
      ['asc'],
    );
  }

  get isAuditSamplingTypeAdHoc() {
    if (this.dirtyDataCollectionSummary) {
      return this.dirtyDataCollectionSummary.samplingConfiguration
        .auditSamplingType === 'adHoc'
        ? true
        : false;
    } else {
      return false;
    }
  }
}
