import { axios } from './axios';
import {
  AcceptInvitationRequestPayload,
  CreateProjectRequestPayload,
  CriteriaPayload,
  GetCplusCriteriaRequestPayload,
  UpdateProjectCriteriasRequestPayload,
  UpdateProjectGeneralSettingRequestPayload,
  UpdateProjectParticipantsRequestPayload,
  UpdateProjectSettingRequestPayload,
  UpdateProjectSitesRequestPayload,
  Site,
  CreateProjectParticipantPayload,
  UpdateProjectParticipantPayload,
  DashboardChartDataRequestPayload,
  PaginatedProjectEventLogsPayload,
  UpdateAnalysisTitlePayload,
  DeleteAnalysisPayload,
  ProjectAuditDataCollectionRecordsPayload,
  ChangeProjectOwnerPayload,
  UpdateProjectOwnerRequestPayload,
  GetDocumentByPublicationPayload,
  UpdateJbiCriteriaRequestPayload,
  GetProjectPayload,
  GetProjectListPayload,
  DeleteAuditGripTypePayload,
  CreateAuditGripTypePayload,
  UpdateAuditGripTypePayload,
  ProjectGripStrategyPayload,
  ProjectGripEnablerPayload,
  ProjectGripBarrierPayload,
  ProjectGrip,
  UpdateProjectGrip,
  ProjectGripDetails,
  ProjectGripBarrier,
  ProjectGripEnabler,
  ProjectGripStrategy,
  CplusCriteria,
  UpdateJBICriteriaAcknowledgmentPayload,
  JBICriteriaDTO,
  UpdatedJBICriteriaAcknowledgement,
  ProjectReportAnalysisPayload,
  GetExistingProjectOwnerRequestPayload,
  DeleteProjectParticipantPayload,
} from '@/store/modules/projects/types/projects.types';
import { AxiosPromise, AxiosResponse } from 'axios';
import {
  CreateGripAnalysisBarrierPayload,
  CreateGripAnalysisPayload,
  CreateGripBarrierPayload,
  CreateGripDomainCategoryPayload,
  CreateGripEnablerPayload,
  CreateGripPayload,
  CreateGripStrategyPayload,
  DeleteGripDomainCategoryPayload,
  GetBarriersPayload,
  GetEnablersPayload,
  GetStrategiesPayload,
  Grip,
  GripAnalysis,
  GripAnalysisBarrier,
  GripAnalysisEnabler,
  GripAnalysisParams,
  GripAnalysisStrategy,
  GripBarrier,
  GripDomainCategory,
  GripDomainCategoryDetails,
  GripEnabler,
  GripExportReportPayload,
  GripExportReportResponse,
  GripNotificationResponse,
  GripStrategy,
  RenameGripAnalysisPayload,
  UpdateGripAnalysisBarrierPayload,
  UpdateGripAnalysisPayload,
  UpdateGripBarrierPayload,
  UpdateGripDomainCategoryPayload,
  UpdateGripEnablerPayload,
  UpdateGripPayload,
  UpdateGripStrategyPayload,
} from '@/store/types/grip.types';
import { Pagination } from '@/store/types/general.types';

export const projectsApi = {
  async getCplusCriterias(params?: GetCplusCriteriaRequestPayload) {
    return axios.get(`/criteria/get-all-cplus-criterias`, { params });
  },

  async createProject(payload: CreateProjectRequestPayload) {
    return axios.post(
      `projects`,
      {
        ...payload,
      },
      { timeout: 120000 },
    );
  },

  async createProjectById(payload: CreateProjectRequestPayload) {
    return axios.post(
      `projects/create/${payload.projectId}`,
      {
        ...payload,
      },
      { timeout: 120000 },
    );
  },

  async saveDraft(payload: CreateProjectRequestPayload) {
    return axios.post(
      `projects/draft`,
      {
        ...payload,
      },
      { timeout: 120000 },
    );
  },

  async updateDraft(payload: CreateProjectRequestPayload) {
    return axios.put(
      `projects/draft/${payload.projectId}`,
      {
        ...payload,
      },
      { timeout: 120000 },
    );
  },

  // TODO: Remove me, not in use.
  async getMyProjects(participantProjectOnly: boolean) {
    return axios.get(
      `/projects/me?participantProjectOnly=${participantProjectOnly}`,
    );
  },

  async getActiveProjects(params: GetProjectListPayload) {
    return axios.get(`/projects/active-projects`, { params });
  },

  async getCreatedProjects(params: GetProjectListPayload) {
    return axios.get(`/projects/created-projects`, { params });
  },

  async getDraftProjects(params: GetProjectListPayload) {
    return axios.get(`/projects/draft-projects`, { params });
  },

  async getArchiveProjects(params: GetProjectListPayload) {
    return axios.get(`/projects/archive-projects`, { params });
  },

  async getProjectDetail(projectId: number) {
    return axios.get(`/projects/${projectId}`);
  },

  async getDraftProjectDetail(id: number) {
    return axios.get(`/projects/draft/${id}`);
  },

  async getUpdatedProjectSites(id: number) {
    return axios.get(`/projects/sites/${id}`);
  },

  async getSites() {
    return axios.get(`/site/all`);
  },

  async addNewSite(newSite: Site) {
    return axios.post(`/site/`, { ...newSite });
  },

  async acceptInvitation({ token }: AcceptInvitationRequestPayload) {
    return axios.put(`/projects/participants/accept`, {
      token,
    });
  },

  async updateCriteria(payload: CriteriaPayload) {
    return axios.put(`criteria/update`, {
      ...payload,
    });
  },

  async updateProjectCriterias(
    projectId: number,
    payload: UpdateProjectCriteriasRequestPayload,
  ) {
    return axios.put(`projects/${projectId}/criterias`, {
      ...payload,
    });
  },

  async updateProjectSites(
    projectId: number,
    payload: UpdateProjectSitesRequestPayload,
  ) {
    return axios.put(`projects/${projectId}/sites`, {
      ...payload,
    });
  },

  async updateProjectParticipants(
    projectId: number,
    payload: UpdateProjectParticipantsRequestPayload,
  ) {
    return axios.put(`projects/${projectId}/participants`, {
      ...payload,
    });
  },

  async updateProjectSetting(
    projectId: number,
    payload: UpdateProjectSettingRequestPayload,
  ) {
    return axios.put(`projects/${projectId}/settings`, {
      ...payload,
    });
  },

  async updateProjectGeneralSettings(
    projectId: number,
    payload: UpdateProjectGeneralSettingRequestPayload,
  ) {
    return axios.put(`projects/${projectId}`, {
      ...payload,
    });
  },

  async addNewAudit(payload: any) {
    return axios.post(
      `projects/${payload.projectId}/audit/`,
      {
        ...payload,
      },
      { timeout: 120000 },
    );
  },

  async archiveProjectById(projectId: number) {
    return axios.put(`projects/${projectId}/archive`, {});
  },

  async deleteProjectById(projectId: number) {
    return axios.delete(`projects/${projectId}`, {});
  },

  async publishProjectById(projectId: number) {
    return axios.put(`projects/${projectId}/publish`, {});
  },

  async getDashboardChartData(
    projectId: number,
    payload: DashboardChartDataRequestPayload,
  ) {
    return axios.get(`/projects/${projectId}/dashboard-chart-data`, {
      params: payload,
    });
  },

  async getMyProjectsSummary() {
    return axios.get(`/projects/me/summary`);
  },

  async getDataCollectionActivityLogs() {
    return axios.get(`/projects/me/data-collection-history`);
  },

  async getProjectCriteria(id: number, params?: { title: string }) {
    return axios.get(`/projects/${id}/criteria`, {
      params,
    });
  },

  async getProjectReportsCriteria(id: number) {
    return axios.get(`/projects/${id}/criteria-reports`);
  },

  async getProjectSites(id: number) {
    return axios.get(`/projects/${id}/sites`);
  },

  async getProjectParticipants(id: number) {
    return axios.get(`/projects/${id}/participants`);
  },

  async getProjectAuditList(id: number) {
    return axios.get(`/projects/${id}/auditList`);
  },

  async getProjectReportAnalysis(
    projectId: number,
    payload: ProjectReportAnalysisPayload,
  ): Promise<AxiosResponse<any>> {
    return axios.get(`/projects/${projectId}/project-report-analysis`, {
      params: payload,
    });
  },

  async getProjectReportExport(
    projectId: number,
    payload: any,
  ): Promise<AxiosResponse<any>> {
    return axios.get(`/projects/${projectId}/project-report-export`, {
      params: payload,
    });
  },

  async getTotalProjectReportAnalysis(
    projectId: number,
  ): Promise<AxiosResponse<any>> {
    return axios.get(`/projects/${projectId}/project-report-total-analysis`);
  },

  async updateProjectReportAnalysisTitleById(
    projectId: number,
    payload: UpdateAnalysisTitlePayload,
  ): Promise<AxiosResponse<any>> {
    return axios.put(`/projects/${projectId}/rename-report-analysis-title`, {
      ...payload,
    });
  },

  async deleteProjectReportAnalysisById(
    projectId: number,
    payload: DeleteAnalysisPayload,
  ): Promise<AxiosResponse<any>> {
    return axios.put(`/projects/${projectId}/delete-report-analysis`, {
      ...payload,
    });
  },

  async getParticipants(projectId: number) {
    return axios.get(`projects/${projectId}/participants`);
  },

  async createParticipant({
    projectId,
    ...body
  }: CreateProjectParticipantPayload) {
    return axios.post(`projects/${projectId}/participants`, {
      ...body,
    });
  },

  async deleteParticipant(payload: DeleteProjectParticipantPayload) {
    return axios.delete(`projects/${payload.projectId}/participant`, {
      data: {
        ...payload,
      },
    });
  },

  async updateParticipant(payload: UpdateProjectParticipantPayload) {
    return axios.put(
      `projects/${payload.projectId}/participants/${payload.id}`,
      {
        ...payload,
      },
    );
  },

  async getRecentEventLogs() {
    return axios.get(`/event-logs`);
  },

  async getPaginatedEventLogs(params: PaginatedProjectEventLogsPayload) {
    return axios.get('/event-logs/project-logs', { params });
  },

  async getProjectAuditDataCollectionRecords(
    projectId: number,
    payload: ProjectAuditDataCollectionRecordsPayload,
  ) {
    return axios.get(`/projects/${projectId}/project-audit-data-collection`, {
      params: payload,
    });
  },

  async getDocumentByPublication(payload: GetDocumentByPublicationPayload) {
    return axios.get(
      `/criteria/get-document-by-publication/${payload.documentId}/${payload.publicationId}`,
    );
  },

  // TODO: remove this, not in use
  async updateJBICriteria(payload: UpdateJbiCriteriaRequestPayload) {
    return axios.post(
      `projects/criteria/${payload.criteria.id}/update-jbi-criteria`,
      {
        ...payload,
      },
    );
  },

  async updateProjectCriteriaGuide(payload: any) {
    return axios.put(
      `/projects/${payload.projectId}/update-project-criteria-guide`,
      {
        ...payload,
      },
    );
  },

  async getAuditGripType(projectId: number) {
    return await axios.get(`/projects/${projectId}/getAuditGripType`);
  },

  async createAuditGripType(payload: CreateAuditGripTypePayload) {
    return axios.post(`/projects/${payload.projectId}/create-audit-grip-type`, {
      ...payload,
    });
  },

  async updateAuditGripType(payload: UpdateAuditGripTypePayload) {
    return axios.post(`/projects/${payload.projectId}/update-audit-grip-type`, {
      ...payload,
    });
  },

  async getGripTypeDetail(payload: { projectId: number; gripTypeId: number }) {
    return await axios.get(
      `/projects/${payload.projectId}/getGripTypeDetail/${payload.gripTypeId}`,
    );
  },

  async deleteAuditGripType(payload: DeleteAuditGripTypePayload) {
    return axios.put(
      `/projects/${payload.projectId}/delete-audit-grip-type/${payload.currentId}`,
      {
        ...payload,
      },
    );
  },

  async getProjectDataCollectionRecordExport(id: number) {
    const tz: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return axios.get(
      `/projects/${id}/getProjectDataCollectionRecordExport?tz=${tz}`,
    );
  },

  getProjectGrip(
    projectId: number,
  ): Promise<AxiosResponse<ProjectGripDetails>> {
    return axios.get(`/projects/${projectId}/old-grip`);
  },

  createProjectGrip(payload: ProjectGrip): Promise<AxiosResponse<ProjectGrip>> {
    return axios.post(`/projects/${payload.projectId}/old-grip`, {
      ...payload,
    });
  },

  updateProjectGrip(
    payload: UpdateProjectGrip,
  ): Promise<AxiosResponse<ProjectGrip>> {
    return axios.put(
      `/projects/${payload.projectGripDetail.projectId}/old-grip/${payload.projectGripId}`,
      {
        ...payload.projectGripDetail,
      },
    );
  },

  getProjectGripBarriers(
    projectId: number,
  ): Promise<AxiosResponse<ProjectGripBarrier[]>> {
    return axios.get(`/projects/${projectId}/project-level-grip/barriers`);
  },

  createProjectGripBarrier(
    payload: ProjectGripBarrierPayload,
  ): Promise<AxiosResponse<ProjectGripBarrier>> {
    return axios.post(
      `/projects/${payload.projectId}/project-level-grip/barrier`,
      {
        ...payload,
      },
    );
  },

  updateProjectGripBarrier(
    projectId: number,
    payload: ProjectGripBarrierPayload,
  ): Promise<AxiosResponse<ProjectGripBarrier>> {
    return axios.put(`/projects/${projectId}/project-level-grip/barrier`, {
      ...payload,
    });
  },

  deleteProjectGripBarrier(param: {
    projectId: number;
    barrierId: number;
  }): Promise<AxiosResponse<void>> {
    return axios.delete(
      `/projects/${param.projectId}/project-level-grip/barrier/${param.barrierId}`,
    );
  },

  getProjectGripEnablers(
    id: number,
  ): Promise<AxiosResponse<ProjectGripEnabler[]>> {
    return axios.get(`/projects/${id}/project-level-grip/enablers`);
  },

  createProjectGripEnabler(
    payload: ProjectGripEnablerPayload,
  ): Promise<AxiosResponse<ProjectGripEnabler>> {
    return axios.post(
      `/projects/${payload.projectId}/project-level-grip/enabler`,
      {
        ...payload,
      },
    );
  },

  updateProjectGripEnabler(
    projectId: number,
    payload: ProjectGripEnablerPayload,
  ): Promise<AxiosResponse<ProjectGripEnabler>> {
    return axios.put(`/projects/${projectId}/project-level-grip/enabler`, {
      ...payload,
    });
  },

  deleteProjectGripEnabler(param: {
    projectId: number;
    enablerId: number;
  }): Promise<AxiosResponse<void>> {
    return axios.delete(
      `/projects/${param.projectId}/project-level-grip/enabler/${param.enablerId}`,
    );
  },

  getProjectGripStrategies(
    id: number,
  ): Promise<AxiosResponse<ProjectGripStrategy[]>> {
    return axios.get(`/projects/${id}/project-level-grip/strategies`);
  },

  createProjectGripStrategy(
    payload: ProjectGripStrategyPayload,
  ): Promise<AxiosResponse<ProjectGripStrategy>> {
    return axios.post(
      `/projects/${payload.projectId}/project-level-grip/strategy`,
      {
        ...payload,
      },
    );
  },

  updateProjectGripStrategy(
    projectId: number,
    payload: ProjectGripStrategyPayload,
  ): Promise<AxiosResponse<ProjectGripStrategy>> {
    return axios.put(`/projects/${projectId}/project-level-grip/strategy`, {
      ...payload,
    });
  },

  deleteProjectGripStrategy(param: {
    projectId: number;
    strategyId: number;
  }): Promise<AxiosResponse<void>> {
    return axios.delete(
      `/projects/${param.projectId}/project-level-grip/strategy/${param.strategyId}`,
    );
  },

  async updateProjectCriteriasById(projectId: number, payload: CplusCriteria) {
    return axios.put(`projects/${projectId}/criteria/${payload.id}`, {
      ...payload,
    });
  },

  /** API's to manage Grip Domain Categories under Project Settings */
  getGripDomainCategoriesByProjectId(
    projectId: number,
  ): Promise<AxiosResponse<GripDomainCategory[]>> {
    return axios.get(`/projects/${projectId}/grip/domain-categories`);
  },

  getGripDomainCategoryDetails(
    projectId: number,
    gripDomainCategoryId: number,
  ): Promise<AxiosResponse<GripDomainCategoryDetails>> {
    return axios.get(
      `/projects/${projectId}/grip/domain-categories/${gripDomainCategoryId}`,
    );
  },

  verifyGripDomainCategory(
    payload: CreateGripDomainCategoryPayload,
  ): Promise<AxiosResponse<boolean>> {
    return axios.post(
      `/projects/${payload.projectId}/grip/domain-category/verify`,
      {
        ...payload,
      },
    );
  },

  createGripDomainCategory(
    payload: CreateGripDomainCategoryPayload,
  ): Promise<AxiosResponse<GripDomainCategory>> {
    return axios.post(`/projects/${payload.projectId}/grip/domain-category`, {
      ...payload,
    });
  },

  updateGripDomainCategory(
    payload: UpdateGripDomainCategoryPayload,
  ): Promise<AxiosResponse<GripDomainCategory>> {
    return axios.patch(
      `/projects/${payload.projectId}/grip/domain-category/${payload.id}`,
      {
        ...payload,
      },
    );
  },

  deleteGripDomainCategory(
    payload: DeleteGripDomainCategoryPayload,
  ): Promise<AxiosResponse<void>> {
    return axios.put(
      `/projects/${payload.projectId}/grip/domain-category/${payload.currentGripDomainCategoryId}/delete`,
      {
        ...payload,
      },
    );
  },

  /** API's to manage Grip Barriers under Project Settings */
  getGripBarriersByProjectId(payload: {
    projectId: number;
    params: GetBarriersPayload;
  }): Promise<AxiosResponse<Pagination<GripBarrier[]>>> {
    return axios.get(`/projects/${payload.projectId}/grip/barriers`, {
      params: payload.params,
    });
  },

  createGripBarrier(
    payload: CreateGripBarrierPayload,
  ): Promise<AxiosResponse<GripBarrier>> {
    return axios.post(`/projects/${payload.projectId}/grip/barrier`, {
      ...payload,
    });
  },

  updateGripBarrierById(
    payload: UpdateGripBarrierPayload,
  ): Promise<AxiosResponse<any>> {
    return axios.patch(
      `/projects/${payload.projectId}/grip/barrier/${payload.id}`,
      {
        ...payload,
      },
    );
  },

  deleteGripBarrierById(payload: {
    projectId: number;
    barrierId: number;
  }): Promise<AxiosResponse<void>> {
    return axios.delete(
      `/projects/${payload.projectId}/grip/barrier/${payload.barrierId}`,
    );
  },

  /** API's to manage Grip Enablers under Project Settings */
  getGripEnablersByProjectId(payload: {
    projectId: number;
    params: GetEnablersPayload;
  }): Promise<AxiosResponse<Pagination<GripEnabler[]>>> {
    return axios.get(`/projects/${payload.projectId}/grip/enablers`, {
      params: payload.params,
    });
  },

  createGripEnabler(
    payload: CreateGripEnablerPayload,
  ): Promise<AxiosResponse<GripEnabler>> {
    return axios.post(`/projects/${payload.projectId}/grip/enabler`, {
      ...payload,
    });
  },

  updateGripEnablerById(
    payload: UpdateGripEnablerPayload,
  ): Promise<AxiosResponse<any>> {
    return axios.patch(
      `/projects/${payload.projectId}/grip/enabler/${payload.id}`,
      {
        ...payload,
      },
    );
  },

  deleteGripEnablerById(payload: {
    projectId: number;
    enablerId: number;
  }): Promise<AxiosResponse<void>> {
    return axios.delete(
      `/projects/${payload.projectId}/grip/enabler/${payload.enablerId}`,
    );
  },

  /** API's to manage Grip Strategies under Project Settings */
  getGripStrategiesByProjectId(payload: {
    projectId: number;
    params: GetStrategiesPayload;
  }): Promise<AxiosResponse<Pagination<GripStrategy[]>>> {
    return axios.get(`/projects/${payload.projectId}/grip/strategies`, {
      params: payload.params,
    });
  },

  createGripStrategy(
    payload: CreateGripStrategyPayload,
  ): Promise<AxiosResponse<GripStrategy>> {
    return axios.post(`/projects/${payload.projectId}/grip/strategy`, {
      ...payload,
    });
  },

  updateGripStrategyById(
    payload: UpdateGripStrategyPayload,
  ): Promise<AxiosResponse<any>> {
    return axios.patch(
      `/projects/${payload.projectId}/grip/strategy/${payload.id}`,
      {
        ...payload,
      },
    );
  },

  deleteGripStrategyById(payload: {
    projectId: number;
    strategyId: number;
  }): Promise<AxiosResponse<void>> {
    return axios.delete(
      `/projects/${payload.projectId}/grip/strategy/${payload.strategyId}`,
    );
  },

  getGrip(projectId: number): AxiosPromise<Grip> {
    return axios.get(`/projects/${projectId}/grip`);
  },

  createGrip(payload: CreateGripPayload): AxiosPromise<Grip> {
    return axios.post(`/projects/${payload.projectId}/grip`, {
      ...payload,
    });
  },

  updateGrip(payload: UpdateGripPayload): AxiosPromise<Grip> {
    return axios.put(`/projects/${payload.projectId}/grip/${payload.id}`, {
      ...payload,
    });
  },

  getGripAnalysisList(projectId: number): AxiosPromise<GripAnalysis[]> {
    return axios.get(`/projects/${projectId}/grip/analysis`);
  },

  getFilteredGripAnalysisList(payload: {
    projectId: number;
    params?: GripAnalysisParams;
  }): AxiosPromise<Pagination<GripAnalysis>> {
    return axios.get(`/projects/${payload.projectId}/grip/analysis`, {
      params: payload.params,
    });
  },

  getGripAnalysis(payload: {
    projectId: number;
    gripAnalysisId: number;
  }): AxiosPromise<GripAnalysis> {
    return axios.get(
      `/projects/${payload.projectId}/grip/analysis/${payload.gripAnalysisId}`,
    );
  },

  createGripAnalysis(
    projectId: number,
    payload: CreateGripAnalysisPayload,
  ): AxiosPromise<GripAnalysis> {
    return axios.post(`/projects/${projectId}/grip/analysis`, {
      ...payload,
    });
  },

  renameGripAnalysis(
    projectId: number,
    payload: RenameGripAnalysisPayload,
  ): AxiosPromise<GripAnalysis> {
    return axios.patch(
      `/projects/${projectId}/grip/analysis/${payload.gripAnalysisId}/rename`,
      {
        ...payload,
      },
    );
  },

  updateGripAnalysis(
    projectId: number,
    payload: UpdateGripAnalysisPayload,
  ): AxiosPromise<GripAnalysis> {
    return axios.patch(
      `/projects/${projectId}/grip/analysis/${payload.gripAnalysisId}`,
      {
        ...payload,
      },
    );
  },

  deleteGripAnalysis(
    projectId: number,
    gripAnalysisId: number,
  ): AxiosPromise<void> {
    return axios.delete(
      `/projects/${projectId}/grip/analysis/${gripAnalysisId}`,
    );
  },

  createGripAnalysisBarrier(
    projectId: number,
    payload: CreateGripAnalysisBarrierPayload,
  ): AxiosPromise<GripAnalysisBarrier> {
    return axios.post(`/projects/${projectId}/grip/analysis-barrier`, {
      ...payload,
    });
  },

  updateGripAnalysisBarrier(
    projectId: number,
    payload: UpdateGripAnalysisBarrierPayload,
  ): AxiosPromise<any> {
    return axios.put(
      `/projects/${projectId}/grip/analysis-barrier/${payload.id}`,
      {
        ...payload,
      },
    );
  },

  deleteGripAnalysisBarrier(
    projectId: number,
    gripAnalysisBarrierId: number,
  ): AxiosPromise<void> {
    return axios.delete(
      `/projects/${projectId}/grip/analysis-barrier/${gripAnalysisBarrierId}`,
    );
  },

  createGripAnalysisEnabler(
    projectId: number,
    payload: any,
  ): AxiosPromise<GripAnalysisEnabler> {
    return axios.post(`/projects/${projectId}/grip/analysis-enabler`, {
      ...payload,
    });
  },

  updateGripAnalysisEnabler(
    projectId: number,
    payload: any,
  ): AxiosPromise<any> {
    return axios.put(
      `/projects/${projectId}/grip/analysis-enabler/${payload.id}`,
      {
        ...payload,
      },
    );
  },

  deleteGripAnalysisEnabler(
    projectId: number,
    gripAnalysisEnablerId: number,
  ): AxiosPromise<void> {
    return axios.delete(
      `/projects/${projectId}/grip/analysis-enabler/${gripAnalysisEnablerId}`,
    );
  },

  createGripAnalysisStrategy(
    projectId: number,
    payload: any,
  ): AxiosPromise<GripAnalysisStrategy> {
    return axios.post(`/projects/${projectId}/grip/analysis-strategy`, {
      ...payload,
    });
  },

  updateGripAnalysisStrategy(
    projectId: number,
    payload: any,
  ): AxiosPromise<any> {
    return axios.put(
      `/projects/${projectId}/grip/analysis-strategy/${payload.id}`,
      {
        ...payload,
      },
    );
  },

  deleteGripAnalysisStrategy(
    projectId: number,
    gripAnalysisStrategyId: number,
  ): AxiosPromise<void> {
    return axios.delete(
      `/projects/${projectId}/grip/analysis-strategy/${gripAnalysisStrategyId}`,
    );
  },

  getGripNotifications(
    projectId: number,
  ): AxiosPromise<GripNotificationResponse[]> {
    return axios.get(`/projects/${projectId}/grip-notifications`);
  },

  updateGripNotifications(projectId: number, payload: any): AxiosPromise<any> {
    return axios.put(`/projects/${projectId}/grip-notifications`, {
      ...payload,
    });
  },

  getGripExportReport(
    params: GripExportReportPayload,
  ): AxiosPromise<GripExportReportResponse> {
    return axios.get(`/projects/${params.projectId}/grip-export-report`, {
      params,
    });
  },

  getUpdatedJBICriteriaWithAcknowledgement(
    id: number,
  ): Promise<AxiosResponse<UpdatedJBICriteriaAcknowledgement>> {
    return axios.post(`/projects/${id}/updated-jbi-criteria-list`);
  },

  getUpdatedProjectReportJBICriteriaList(
    projectId: number,
  ): Promise<AxiosResponse<JBICriteriaDTO[]>> {
    return axios.get(
      `/projects/${projectId}/updated-project-report-jbi-criteria-list`,
    );
  },

  updateJBICriteriaAcknowledgment(
    payload: UpdateJBICriteriaAcknowledgmentPayload,
  ): Promise<AxiosResponse<any>> {
    return axios.put(
      `projects/${payload.projectId}/criteria/acknowledgement/`,
      {
        ...payload,
      },
    );
  },

  getProjectListForAdmin(params: GetProjectPayload) {
    return axios.get('/admin/projects', { params });
  },

  getProjectParticipantsForAdmin(projectId: number) {
    return axios.get(`/admin/projects/${projectId}/participants`);
  },

  getProjectSitesForAdmin(projectId: number) {
    return axios.get(`/admin/projects/${projectId}/sites`);
  },

  getExistingProjectOwnerRequest(
    params: GetExistingProjectOwnerRequestPayload,
  ) {
    return axios.get(
      `/admin/projects/${params.projectId}/checkExistingProjectOwnerRequest`,
      { params },
    );
  },

  changeProjectOwnerRequest(
    projectId: number,
    payload: ChangeProjectOwnerPayload,
  ) {
    return axios.post(`/admin/projects/${projectId}/change-project-owner`, {
      ...payload,
    });
  },

  updateProjectOwnerRequest(
    projectId: number,
    payload: UpdateProjectOwnerRequestPayload,
  ) {
    return axios.post(
      `/admin/projects/${projectId}/update-project-owner-request`,
      {
        ...payload,
      },
    );
  },

  updateCancelProjectRequest(payload: any) {
    return axios.post(
      `/admin/projects/${payload.projectId}/update-cancel-project-request`,
      {
        ...payload,
      },
    );
  },

  getProjectOwnerHistory(id: number) {
    return axios.get(`/admin/projects/${id}/getProjectOwnerHistoryByProjectId`);
  },
};
