import { Route, RawLocation } from 'vue-router';
import store, { RootState } from './../store/store';

export const requireAuth = (
    to: Route,
    from: Route,
    next: (to?: RawLocation | false | ((vm: any) => any) | void) => void,
) => {
    const roles = (store.state as RootState).users.myRoles || [];
    const passed = roles.some(
        r => Array.isArray(to.meta.roles) && to.meta.roles.includes(r),
    );
    if (passed) {
        return next();
    }

    return next('/');
};
