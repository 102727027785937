





























































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { State, Action } from 'vuex-class';
import {
  ChangeProjectOwnerDTO,
  DeleteProjectParticipantPayload,
  ProjectParticipantDTO,
  ProjectParticipantDataTrfObj,
  UserEntity,
} from '@/store/modules/projects/types/projects.types';
import ParticipantForm from '../components/ParticipantForm.vue';
import { RootState } from '@/store/store';
import { ToastProgrammatic as Toast } from 'buefy';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { ProjectSite } from '../../../store/modules/projects/types/projects.types';
import Card from '@/components/Card.vue';
import { useUserEmail } from '@/utils/user.util';
import DeleteModalBox from '../../../components/DeleteModalBox.vue';
import ProjectOwnerRequestForm from '../components/ProjectOwnerRequestForm.vue';
import { ApiState } from '../../../store/types/general.types';
import { OicPayload } from '@/jbi-shared/types/jaas-oauth2.types';
import { cloneData } from '@/utils/general.util';
import { getJBIUsername } from '@/jbi-shared/util/user.utils';
import { PacesParticipantRoles } from '@/jbi-shared/types/paces-project.types';

@Component({
  components: { Card },
})
export default class ParticipantTab extends Vue {
  @Prop({ default: true }) public editable!: boolean;
  @Prop() public projectOwner!: UserEntity;

  public showChangeProjectOwner: boolean = false;
  public disableChangeProjectOwner: boolean = false;

  @State((state) => state.projects.projectSites)
  public projectSites!: ProjectSite[];

  @State((state: RootState) => state.projects.participants)
  public participants!: ProjectParticipantDTO[];

  @State((state: RootState) => state.projects.apiState.deleteProjectParticipant)
  public deleteProjectParticipantState!: ApiState;

  @State((state: RootState) => state.projects.apiState.createProjectParticipant)
  public createProjectParticipantState!: ApiState;

  @State((state: RootState) => state.projects.apiState.updateProjectParticipant)
  public updateProjectParticipantState!: ApiState;

  @Action('projects/getProjectSites')
  public getProjectSites!: (projectId: number) => void;

  @Action('projects/getProjectParticipants')
  public getProjectParticipants!: (projectId: number) => void;

  @Action('projects/getProjectDetail')
  public getProjectDetail!: (payload: { projectId: number }) => void;

  @Action('projects/deleteProjectParticipant')
  public deleteProjectParticipant!: (
    payload: DeleteProjectParticipantPayload,
  ) => void;

  get projectId() {
    return +this.$route.params.projectId;
  }

  get participantList(): ProjectParticipantDTO[] {
    return this.participants ? cloneData(this.participants) : [];
  }

  get sites() {
    if (this.projectSites) {
      return this.projectSites.map((site) => {
        return {
          id: site.site.id,
          name: site.site.name,
          address: site.site.address,
          description: site.site.description,
        };
      });
    }

    return [];
  }

  public isAllowedToModified(
    participant: ProjectParticipantDataTrfObj,
  ): boolean {
    /** Only owner can edit or delete coordinators
     * if participant role is project coOrdinator,
     * then check if reqUser is project owner.
     *
     * else return true, as it's data collector role,
     * and it can be edit or delete by owners and coordinators.
     */

    const isAllowedToModifiedParticipant =
      participant.participantRole === PacesParticipantRoles.ProjectCoordinator
        ? this.projectOwner.email === this.userEmail
        : true;

    // If participant is NOT project owner or current user, allowed to edit or delete participant.
    return (
      this.editable &&
      isAllowedToModifiedParticipant &&
      this.projectOwner.email !== participant.userEmail &&
      this.userEmail !== participant.userEmail
    );
  }

  public mounted() {
    this.getProjectParticipants(this.projectId);
    this.getProjectSites(this.projectId);
  }

  get participantData(): ProjectParticipantDataTrfObj[] {
    const data: ProjectParticipantDataTrfObj[] = [];
    if (this.participantList.length > 0) {
      this.participantList.forEach((participant) => {
        participant.participantRoles.forEach((participantRole) => {
          /** return participants which doesn't have role as 'ProjectAdministrator' */
          if (
            participantRole.role !== PacesParticipantRoles.ProjectAdministrator
          ) {
            data.push({
              ...participant,
              participantRole: participantRole.role,
              participantSites: participantRole.participantSites,
              participantRoleId: participantRole.id,
            });
          }
        });
      });
    }
    this.disableChangeProjectOwner = !data.length || data.length === 1;
    return data;
  }

  get projectOwnerEmail() {
    if (this.projectOwner.email === this.userEmail) {
      this.showChangeProjectOwner = true;
      return 'You';
    } else {
      return getJBIUsername(
        this.projectOwner.email,
        this.projectOwner.oicPayload,
      );
    }
  }

  get userEmail(): string | undefined {
    return useUserEmail.call(this);
  }

  public getParticipantName(participant: ProjectParticipantDataTrfObj) {
    if (participant.user) {
      return getJBIUsername(participant.userEmail, participant.user.oicPayload);
    } else {
      return participant.userEmail;
    }
  }

  public handleEdit(participant: ProjectParticipantDataTrfObj) {
    this.$buefy.modal.open({
      parent: this,
      props: {
        isEdit: true,
        participant,
        sites: this.sites || [],
        projectOwnerEmail: this.projectOwner.email,
        participantsList: this.participantData,
        currentUserEmail: this.userEmail,
      },
      component: ParticipantForm,
      hasModalCard: true,
    });
  }

  public handleCreate() {
    const modal = this.$buefy.modal.open({
      parent: this,
      props: {
        isEdit: false,
        participant: null,
        sites: this.sites || [],
        projectOwnerEmail: this.projectOwner.email,
        participantsList: this.participantData,
        currentUserEmail: this.userEmail,
      },
      component: ParticipantForm,
      hasModalCard: true,
      events: {
        close: () => {
          modal.close();
        },
      },
    });
  }

  public handleDelete(participant: ProjectParticipantDataTrfObj) {
    this.$buefy.modal.open({
      parent: this,
      component: DeleteModalBox,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle: `Delete ${participant.userEmail}?`,
        modalContent: `${participant.userEmail} will no longer be able to access to this project and its data collection period.`,
      },
      events: {
        confirm: () => {
          // These are sites that needs to be deleted as a part of deleting the participant
          const siteIds: number[] = this.sites.map((site) => site.id);
          this.deleteProjectParticipant({
            projectId: this.projectId,
            participantRoleId: participant.participantRoleId,
            siteIds,
            participantId: participant.id,
          });
        },
      },
    });
  }

  public handleChangeProjectOwner() {
    this.$buefy.modal.open({
      parent: this,
      props: {
        projectId: this.projectId,
        projectOwnerEmail: this.projectOwner.email,
        projectOwnerName: getJBIUsername(
          this.projectOwner.email,
          this.projectOwner.oicPayload,
        ),
        isSystemAdmin: false,
      },
      events: {
        confirm: (newValue: ChangeProjectOwnerDTO) => {
          Toast.open({
            message: `Project administration request successfully sent to ${newValue.newRequestedProjectOwnerEmail}`,
            position: 'is-top',
            type: 'is-dark',
            duration: 3500,
          });
        },
      },
      component: ProjectOwnerRequestForm,
      hasModalCard: true,
    });
  }

  @Watch('deleteProjectParticipantState.success')
  @isTruthy
  public onDeleteSuccess() {
    this.getProjectParticipants(this.projectId);

    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `Participant removed`,
    });
  }

  @Watch('createProjectParticipantState.success')
  @isTruthy
  public onCreateSuccess(): void {
    this.getProjectParticipants(this.projectId);
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `Participant added successfully`,
    });
  }

  @Watch('updateProjectParticipantState.success')
  @isTruthy
  public updateSuccess(): void {
    this.getProjectParticipants(this.projectId);
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `Changes saved`,
    });
  }
}
