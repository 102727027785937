import {
  ChartType,
  AuditSite,
  AuditCriteria,
} from '@/store/modules/audits/types/audits.types';
import {
  primaryChartColor,
  labelSplitIndex,
} from '@/store/types/general.types';
import { cloneDeep as _cloneDeep, get as _get, map as _map } from 'lodash';
import { CRITERION_TYPE } from '@/store/types/criterions.types';
import Chart from 'chart.js';
import dayjs from 'dayjs';

export class GenerateSingleSitePdf {
  public dirtyMonths: string[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];

  public create(
    result: any,
    payload: any,
    analysisTitle: string,
    jbiLogo: string,
    projectTitle: string,
    auditTitle: string,
    auditSites: AuditSite[],
    auditCriteria: AuditCriteria[],
  ) {
    const exportDateTime = dayjs().format('D MMM YYYY, HH:mm A');
    let reportContent = {
      pageMargins: [75, 60, 75, 60],
      content: [],
      footer: (currentPage: number) => {
        return {
          columns: [
            {
              text: 'Exported on ' + exportDateTime,
              alignment: 'left',
              color: '#444F51',
              fontSize: 11,
              margin: [75, 40, 0, 0],
            },
            {
              text: currentPage.toString(),
              alignment: 'right',
              color: '#444F51',
              fontSize: 11,
              margin: [0, 40, 75, 0],
            },
          ],
        };
      },
      pageBreakBefore: (currentNode: any) => {
        return currentNode.pageNumbers.length > 1 && currentNode.unbreakable;
      },
    };
    reportContent = this.generateReportContent(
      reportContent,
      result,
      payload,
      jbiLogo,
      projectTitle,
      auditTitle,
      auditSites,
      auditCriteria,
      analysisTitle,
    );
    return reportContent;
  }

  public generateReportContent(
    reportContent: any,
    result: any,
    payload: any,
    jbiLogo: string,
    projectTitle: string,
    auditTitle: string,
    auditSites: AuditSite[],
    auditCriteria: AuditCriteria[],
    analysisTitle: string,
  ) {
    reportContent.content.push(this.setReportTitle(jbiLogo));
    reportContent.content.push(this.setProjectName(projectTitle));
    reportContent.content.push(this.setAuditName(auditTitle));
    reportContent.content.push(this.setSubTitle(analysisTitle));
    reportContent.content.push({
      text: 'Site(s)',
      bold: true,
      color: '#1D70B8',
      fontSize: 11,
    });
    reportContent.content.push(this.setSelectedSites(auditSites, payload));
    reportContent.content.push({
      text: 'Data Collection Period Criteria',
      bold: true,
      color: '#1D70B8',
      fontSize: 11,
    });
    reportContent.content.push(
      this.setSelectedCriteria(auditCriteria, payload),
    );
    reportContent.content.push({
      text: 'Data Collection Period',
      bold: true,
      color: '#1D70B8',
      fontSize: 11,
    });
    reportContent.content.push(this.setSelectedPeriod(payload));
    if (payload.checkIndividualCriteria) {
      const {
        allCharts,
        renderBooleanTables,
        renderBooleanSamplingTables,
        renderMixedTables,
        renderMixedSamplingTables,
      } = this.generateIndividualCriteria(result, payload, auditSites);
      allCharts.forEach((individualChart, individualChartIndex) => {
        reportContent.content.push(individualChart);
        reportContent.content.push(renderBooleanTables[individualChartIndex]);
        reportContent.content.push(
          renderBooleanSamplingTables[individualChartIndex],
        );
      });
      renderMixedTables.forEach((individualTable, individualTableIndex) => {
        reportContent.content.push(individualTable);
        reportContent.content.push(
          renderMixedSamplingTables[individualTableIndex],
        );
      });
    } else {
      const {
        aggregateChart,
        renderTables,
        renderSamplingTables,
      } = this.generateAggregateCriteria(result, payload, auditSites);
      reportContent.content.push(aggregateChart[0]);
      renderTables.forEach((individualTable, individualTableIndex) => {
        reportContent.content.push(individualTable);
        reportContent.content.push(renderSamplingTables[individualTableIndex]);
      });
    }
    return reportContent;
  }

  public setReportTitle(jbiLogo: string) {
    const reportTitle = {
      layout: 'noBorders',
      table: {
        widths: [96, '*'],
        body: [
          [
            {
              fit: [75, 26],
              image: jbiLogo,
              margin: [0, 7, 0, 5],
            },
            {
              text: [
                {
                  text: 'JBI PACES ',
                  bold: true,
                },
                'REPORT',
              ],
              margin: [0, 2, 0, 0],
              fillColor: '#062157',
              style: {
                color: 'white',
                fontSize: 30,
              },
            },
          ],
        ],
      },
    };
    return reportTitle;
  }

  public setProjectName(projectTitle: string) {
    return {
      text: projectTitle.replace(/\s+/g, ' ').toUpperCase(),
      fontSize: 30,
      color: '#00205B',
      bold: true,
      margin: [0, 30, 0, 0],
    };
  }

  public setAuditName(auditTitle: string) {
    return {
      text: auditTitle.replace(/\s+/g, ' '),
      fontSize: 11,
      color: '#444F51',
      bold: true,
      margin: [0, 0, 0, 5],
    };
  }

  public setSubTitle(subTitle: string) {
    return {
      text: subTitle.replace(/\s+/g, ' '),
      fontSize: 11,
      color: '#444F51',
      bold: true,
      margin: [0, 0, 0, 15],
    };
  }

  public setSelectedSites(auditSites: AuditSite[], payload: any) {
    const siteNames: string[] = [];
    payload.filteredSites.forEach((auditSiteMapId: number) => {
      const auditSite: any = auditSites.find((auditSiteDetail) => {
        return auditSiteDetail.id === auditSiteMapId;
      });
      siteNames.push(auditSite.site.name);
    });
    return {
      text: siteNames.join(', '),
      color: '#444F51',
      fontSize: 11,
      margin: [0, 0, 0, 12],
    };
  }

  public setSelectedCriteria(auditCriteria: AuditCriteria[], payload: any) {
    const criteriaNames: any[] = [];
    payload.filteredCriterias.forEach((auditCriteriaId: number) => {
      const criteria: any = auditCriteria.find((auditCriteriaDetail) => {
        return auditCriteriaDetail.id === auditCriteriaId;
      });
      criteriaNames.push({
        text: criteria.criteria.title,
        color: '#444F51',
        fontSize: 11,
        margin: [0, 0, 0, 3],
      });
    });
    return {
      ol: criteriaNames,
      markerColor: '#444F51',
      margin: [0, 0, 0, 12],
      fontSize: 11,
    };
  }

  public setSelectedPeriod(payload: any) {
    const selectedPeriodText: string = this.formatDateRange(
      payload.selectedPeriod,
    );
    return {
      text: selectedPeriodText,
      color: '#444F51',
      fontSize: 11,
      margin: [0, 0, 0, 30],
    };
  }

  public generateIndividualCriteria(
    result: any,
    payload: any,
    auditSites: AuditSite[],
  ) {
    const allCharts: any[] = [];
    const renderBooleanTables: any[] = [];
    const renderBooleanSamplingTables: any[] = [];
    const renderMixedTables: any[] = [];
    const renderMixedSamplingTables: any[] = [];
    // generate chart
    const dataSet: any[] = [];
    const dataSetOptions: any[] = [];
    const verticalBarChartDataSetOption: any = {
      animation: {
        duration: 0,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              max: 100,
              min: 0,
              fontSize: 20,
            },
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Compliance %',
              display: true,
              fontSize: 20,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Criteria',
              display: false,
            },
            ticks: {
              fontSize: 20,
              minRotation: 0,
              callback: (label: string, index: number) => {
                if (payload.checkIndividualCriteria) {
                  return this.transformLabel(label);
                } else {
                  if (payload.filteredCriterias.length > 4) {
                    return index + 1 + '.';
                  }
                  return this.transformLabel(label);
                }
              },
            },
          },
        ],
      },
      legend: {
        display: false,
      },
      responsive: true,
      maintainAspectRatio: true,
    };
    const horizontalBarChartDataSetOption: any = {
      animation: {
        duration: 0,
      },
      scales: {
        xAxes: [
          {
            ticks: {
              beginAtZero: true,
              max: 100,
              min: 0,
              fontSize: 20,
            },
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Compliance %',
              display: true,
              fontSize: 20,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Criteria',
              display: false,
            },
            ticks: {
              fontSize: 20,
              minRotation: 0,
              callback: (label: string, index: number) => {
                if (payload.checkIndividualCriteria) {
                  return this.transformLabel(label);
                } else {
                  if (payload.filteredCriterias.length > 4) {
                    return index + 1 + '.';
                  }
                  return this.transformLabel(label);
                }
              },
            },
          },
        ],
      },
      legend: {
        display: false,
      },
      responsive: true,
      maintainAspectRatio: true,
    };
    const {
      aggregateCriteriaConfiguration,
      individualBooleanCriteriaConfiguration,
      individualMixedCriteriaConfiguration,
    } = this.generateConfigurationData(
      result.statisticData.configurationData,
      result.statisticData.samplingConfiguration,
    );
    const siteName = auditSites[0].site.name;
    if (result.chartData.length > 0) {
      const chartData = result.chartData;
      Object.keys(chartData).forEach((key) => {
        const data = {
          labels: [] as string[],
          datasets: [] as any[],
        };
        let dataOptions = {};
        const dirtyChartData = chartData[Number(key)];
        const label = dirtyChartData.data[0].site.name;
        const chartValue = dirtyChartData.data[0].compliance;
        const dataSetObject = {
          label: dirtyChartData.criteria.title,
          backgroundColor: [primaryChartColor],
          hoverBackgroundColor: primaryChartColor,
          pointBackgroundColor: 'white',
          borderWidth: 1,
          pointBorderColor: '#249EBF',
          data: [chartValue],
          categoryPercentage: this.getIndividualCriteriaChartCategoryPercentage(
            payload,
          ),
        };
        if (payload.selectedChartType === ChartType.verticalBarChart) {
          dataOptions = _cloneDeep(verticalBarChartDataSetOption);
        } else {
          dataOptions = _cloneDeep(horizontalBarChartDataSetOption);
        }
        data.labels.push(label);
        data.datasets.push(dataSetObject);
        dataSet.push(data);
        dataSetOptions.push(dataOptions);
      });
      dataSet.forEach((individualSet, individualSetIndex) => {
        const renderChart = {
          width: 443,
          height: 250,
          image: '',
          alignment: 'center',
          margin: [0, 0, 0, 30],
          unbreakable: true,
        };
        const canvas = document.createElement('canvas');
        document.body.appendChild(canvas);
        if (payload.selectedChartType === ChartType.verticalBarChart) {
          const verticalChart = new Chart(
            canvas.getContext('2d') as CanvasRenderingContext2D,
            {
              type: 'bar',
              data: {
                labels: individualSet.labels,
                datasets: individualSet.datasets,
              },
              options: dataSetOptions[individualSetIndex],
              plugins: [
                {
                  afterDatasetDraw(chart: any, args: any) {
                    args.meta.data.forEach((element: any) => {
                      if (element._model.base - element._model.y < 0) {
                        const borderWidth = 8;
                        const ctx = chart.ctx;
                        const vm = element._view;
                        const half = vm.width / 2;
                        const left = vm.x - half;
                        const right = vm.x + half;
                        const top = vm.y - 2;
                        const width = right - left;
                        const height =
                          chart.chartArea.bottom - top + borderWidth / 2 - 1;
                        ctx.beginPath();
                        ctx.lineWidth = borderWidth;
                        ctx.strokeStyle = '#000000';
                        ctx.setLineDash([3, 4]);
                        ctx.moveTo(left, top);
                        ctx.lineTo(left, top + height);
                        ctx.moveTo(left, top);
                        ctx.lineTo(left + width, top);
                        ctx.moveTo(left + width, top);
                        ctx.lineTo(left + width, top + height);
                        ctx.stroke();
                        ctx.save();
                      }
                    });
                  },
                },
              ],
            },
          );
          renderChart.image = verticalChart.toBase64Image();
        } else {
          const horizontalChart = new Chart(
            canvas.getContext('2d') as CanvasRenderingContext2D,
            {
              type: 'horizontalBar',
              data: {
                labels: individualSet.labels,
                datasets: individualSet.datasets,
              },
              options: dataSetOptions[individualSetIndex],
              plugins: [
                {
                  afterDatasetDraw(chart: any, args: any) {
                    args.meta.data.forEach((element: any) => {
                      if (element._model.base - element._model.x > 0) {
                        const borderWidth = 8;
                        const ctx = chart.ctx;
                        const vm = element._view;
                        const half = vm.height / 2;
                        const top = vm.y - half;
                        const bottom = vm.y + half;
                        const right = vm.x + 10;
                        const left = chart.chartArea.left;
                        ctx.beginPath();
                        ctx.lineWidth = borderWidth;
                        ctx.strokeStyle = '#000000';
                        ctx.setLineDash([4, 5]);
                        ctx.moveTo(left, top);
                        ctx.lineTo(right, top);
                        ctx.moveTo(right, top);
                        ctx.lineTo(right, bottom);
                        ctx.moveTo(right, bottom);
                        ctx.lineTo(left, bottom);
                        ctx.stroke();
                        ctx.save();
                      }
                    });
                  },
                },
              ],
            },
          );
          renderChart.image = horizontalChart.toBase64Image();
        }
        const stack: any[] = [renderChart];
        const criteriaChart = {
          stack,
          unbreakable: true,
        };
        allCharts.push(criteriaChart);
        document.body.removeChild(canvas);
      });
    } else {
      allCharts.push({
        stack: [],
        unbreakable: true,
      });
    }
    if (individualBooleanCriteriaConfiguration.length !== 0) {
      // generate boolean criteria tables
      individualBooleanCriteriaConfiguration.forEach(
        (criteriaDetails, criteriaDetailsIndex: number) => {
          const title = {
            text: criteriaDetailsIndex + 1 + '. ' + criteriaDetails.title,
            bold: true,
            color: '#444F51',
            fontSize: 11,
            margin: [0, 0, 0, 17],
          };
          const bodyRows: any[][] = [];
          criteriaDetails.criteriaSamplingData.forEach(
            (optionsData: any, optionDataIndex: number) => {
              const rowData: any[] = [];
              const option = Object.keys(optionsData)[0];
              rowData.push({
                text: option,
                color: '#444F51',
                fontSize: 12,
                border: [true, false, true, false],
                margin:
                  optionDataIndex ===
                  criteriaDetails.criteriaSamplingData.length - 1
                    ? [8, 6, 0, 6]
                    : [8, 6, 0, 0],
              });
              rowData.push({
                text: optionsData[option],
                color: '#444F51',
                fontSize: 12,
                border: [true, false, true, false],
                alignment: 'right',
                margin:
                  optionDataIndex ===
                  criteriaDetails.criteriaSamplingData.length - 1
                    ? [0, 6, 8, 6]
                    : [0, 6, 8, 0],
              });
              bodyRows.push(rowData);
            },
          );
          let total: number | string = 0;
          let compliance: any = 0;
          bodyRows.forEach((optionValues) => {
            if (optionValues[1].text === '-') {
              total = '-';
            } else {
              total += optionValues[1].text;
            }
          });
          bodyRows.push([
            {
              text: 'Total Data Collected',
              color: '#444F51',
              fontSize: 12,
              margin: [8, 6, 0, 6],
            },
            {
              text: total,
              color: '#444F51',
              fontSize: 12,
              alignment: 'right',
              margin: [0, 6, 8, 6],
            },
          ]);
          if (bodyRows[0][1].text === 0) {
            bodyRows.push([
              {
                text: 'Compliance',
                color: '#444F51',
                fontSize: 12,
                margin: [8, 6, 0, 6],
                bold: true,
              },
              {
                text: compliance + '%',
                color: '#444F51',
                fontSize: 12,
                alignment: 'right',
                bold: true,
                margin: [0, 6, 8, 6],
              },
            ]);
          } else {
            if (bodyRows[0][1].text === '-') {
              compliance = '-';
            } else {
              compliance =
                (bodyRows[0][1].text / (total - bodyRows[2][1].text)) * 100;
            }
            bodyRows.push([
              {
                text: 'Compliance',
                color: '#444F51',
                fontSize: 12,
                margin: [8, 6, 0, 6],
                bold: true,
              },
              {
                text:
                  compliance !== '-'
                    ? Math.round(compliance * 100) / 100 + '%'
                    : compliance,
                color: '#444F51',
                fontSize: 12,
                alignment: 'right',
                bold: true,
                margin: [0, 6, 8, 6],
              },
            ]);
          }
          const tableData = {
            table: {
              unbreakable: true,
              widths: ['*', 100],
              body: [
                [
                  {
                    text: 'Answer Choices',
                    color: '#9F9F9F',
                    fontSize: 11,
                    margin: [8, 10, 0, 10],
                  },
                  {
                    text: siteName,
                    color: '#9F9F9F',
                    fontSize: 11,
                    alignment: 'right',
                    margin: [0, 10, 8, 10],
                  },
                ],
                ...bodyRows,
              ],
            },
            layout: {
              hLineWidth: () => {
                return 0.5;
              },
              vLineWidth: () => {
                return 0.5;
              },
              hLineColor: () => {
                return '#DBDBDB';
              },
              vLineColor: () => {
                return '#DBDBDB';
              },
            },
          };
          allCharts[criteriaDetailsIndex].stack.unshift(title);
          const stack: any[] = [tableData];
          const criteriaTable = {
            stack,
            unbreakable: true,
          };
          renderBooleanTables.push(criteriaTable);
        },
      );
      individualBooleanCriteriaConfiguration.forEach(
        (criteriaDetails, criteriaDetailsIndex) => {
          const samplingTitle = {
            text: 'Sampling',
            bold: true,
            color: '#444F51',
            fontSize: 11,
            margin: [0, 13, 0, 17],
          };
          const dirtySamplingTables: any[] = this.getSamplingTables(
            criteriaDetails,
            samplingTitle,
          );
          renderBooleanSamplingTables.push(dirtySamplingTables[0]);
        },
      );
    }
    // generate mixed criteria tables
    if (individualMixedCriteriaConfiguration.length !== 0) {
      individualMixedCriteriaConfiguration.forEach(
        (criteriaDetails, criteriaDetailsIndex: number) => {
          const renderTable: any[] = [];
          const title = {
            text:
              individualBooleanCriteriaConfiguration.length +
              criteriaDetailsIndex +
              1 +
              '. ' +
              criteriaDetails.title,
            bold: true,
            color: '#444F51',
            fontSize: 11,
            margin: [0, 0, 0, 17],
          };
          const bodyRows: any[][] = [];
          criteriaDetails.criteriaSamplingData.forEach(
            (optionsData: any, optionDataIndex: number) => {
              const rowData: any[] = [];
              const option = Object.keys(optionsData)[0];
              rowData.push({
                text: option,
                color: '#444F51',
                fontSize: 12,
                border: [true, false, true, false],
                margin:
                  optionDataIndex ===
                  criteriaDetails.criteriaSamplingData.length - 1
                    ? [8, 6, 0, 6]
                    : [8, 6, 0, 0],
              });
              rowData.push({
                text: optionsData[option],
                color: '#444F51',
                fontSize: 12,
                border: [true, false, true, false],
                alignment: 'right',
                margin:
                  optionDataIndex ===
                  criteriaDetails.criteriaSamplingData.length - 1
                    ? [0, 6, 8, 6]
                    : [0, 6, 8, 0],
              });
              bodyRows.push(rowData);
            },
          );
          let total: number | string = 0;
          bodyRows.forEach((optionValues) => {
            if (optionValues[1].text === '-') {
              total = '-';
            } else {
              total += optionValues[1].text;
            }
          });
          bodyRows.push([
            {
              text: 'Total Data Collected',
              color: '#444F51',
              fontSize: 12,
              margin: [8, 6, 0, 6],
            },
            {
              text: total,
              color: '#444F51',
              fontSize: 12,
              alignment: 'right',
              margin: [0, 6, 8, 6],
            },
          ]);
          const tableData = {
            table: {
              unbreakable: true,
              widths: ['*', 100],
              body: [
                [
                  {
                    text: 'Answer Choices',
                    color: '#9F9F9F',
                    fontSize: 11,
                    margin: [8, 10, 0, 10],
                  },
                  {
                    text: siteName,
                    color: '#9F9F9F',
                    fontSize: 11,
                    alignment: 'right',
                    margin: [0, 10, 8, 10],
                  },
                ],
                ...bodyRows,
              ],
            },
            layout: {
              hLineWidth: () => {
                return 0.5;
              },
              vLineWidth: () => {
                return 0.5;
              },
              hLineColor: () => {
                return '#DBDBDB';
              },
              vLineColor: () => {
                return '#DBDBDB';
              },
            },
          };
          const stack: any[] = [title, tableData];
          const criteriaTable = {
            stack,
            unbreakable: true,
          };
          renderMixedTables.push(criteriaTable);
        },
      );
      individualMixedCriteriaConfiguration.forEach(
        (criteriaDetails, criteriaDetailsIndex) => {
          const samplingTitle = {
            text: 'Sampling',
            bold: true,
            color: '#444F51',
            fontSize: 11,
            margin: [0, 13, 0, 17],
          };
          const dirtySamplingTables: any[] = this.getSamplingTables(
            criteriaDetails,
            samplingTitle,
          );
          renderMixedSamplingTables.push(dirtySamplingTables[0]);
        },
      );
    }
    return {
      allCharts,
      renderBooleanTables,
      renderBooleanSamplingTables,
      renderMixedTables,
      renderMixedSamplingTables,
    };
  }

  public generateAggregateCriteria(
    result: any,
    payload: any,
    auditSites: AuditSite[],
  ) {
    const aggregateChart: any[] = [];
    const renderChart = {
      width: 443,
      height: 250,
      image: '',
      alignment: 'center',
      margin: [0, 0, 0, 30],
      unbreakable: true,
    };
    const renderTables: any[] = [];
    const renderSamplingTables: any[] = [];
    // generate chart
    const dataSet = [];
    const dataSetOptions = [];
    const verticalBarChartDataSetOption: any = {
      animation: {
        duration: 0,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              max: 100,
              min: 0,
              fontSize: 20,
            },
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Compliance %',
              display: true,
              fontSize: 20,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Criteria',
              display: false,
            },
            ticks: {
              fontSize: 20,
              minRotation: 0,
              callback: (label: string, index: number) => {
                if (payload.checkIndividualCriteria) {
                  return this.transformLabel(label);
                } else {
                  if (payload.filteredCriterias.length > 4) {
                    return index + 1 + '.';
                  }
                  return this.transformLabel(label);
                }
              },
            },
          },
        ],
      },
      legend: {
        display: false,
      },
      responsive: true,
      maintainAspectRatio: true,
    };
    const horizontalBarChartDataSetOption: any = {
      animation: {
        duration: 0,
      },
      scales: {
        xAxes: [
          {
            ticks: {
              beginAtZero: true,
              max: 100,
              min: 0,
              fontSize: 20,
            },
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Compliance %',
              display: true,
              fontSize: 20,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Criteria',
              display: false,
            },
            ticks: {
              fontSize: 20,
              minRotation: 0,
              callback: (label: string, index: number) => {
                if (payload.checkIndividualCriteria) {
                  return this.transformLabel(label);
                } else {
                  if (payload.filteredCriterias.length > 4) {
                    return index + 1 + '.';
                  }
                  return this.transformLabel(label);
                }
              },
            },
          },
        ],
      },
      legend: {
        display: false,
      },
      responsive: true,
      maintainAspectRatio: true,
    };
    const {
      aggregateCriteriaConfiguration,
      individualBooleanCriteriaConfiguration,
      individualMixedCriteriaConfiguration,
    } = this.generateConfigurationData(
      result.statisticData.configurationData,
      result.statisticData.samplingConfiguration,
    );
    const siteName = auditSites[0].site.name;
    if (result.chartData.length > 0) {
      const chartData = result.chartData;
      const data = {
        labels: [] as string[],
        datasets: [] as any[],
      };
      let dataOptions = {};
      const labels: string[] = [];
      const chartValues: number[] = [];
      const backgroundColors: string[] = [];
      Object.keys(chartData).map((key: string) => {
        labels.push(chartData[Number(key)].criteria.title);
        chartValues.push(chartData[Number(key)].data[0].compliance);
        backgroundColors.push(primaryChartColor);
      });
      const dataSetObject = {
        label: siteName,
        backgroundColor: backgroundColors,
        hoverBackgroundColor: primaryChartColor,
        pointBackgroundColor: 'white',
        borderWidth: 1,
        pointBorderColor: '#249EBF',
        data: chartValues,
        categoryPercentage: this.getAggregateCriteriaChartCategoryPercentage(
          individualBooleanCriteriaConfiguration,
        ),
      };
      let dataOptionsObject;
      if (payload.selectedChartType === ChartType.verticalBarChart) {
        dataOptionsObject = _cloneDeep(verticalBarChartDataSetOption);
      } else {
        dataOptionsObject = _cloneDeep(horizontalBarChartDataSetOption);
      }
      data.labels = _cloneDeep(labels);
      data.datasets = [];
      data.datasets.push(dataSetObject);
      dataOptions = _cloneDeep(dataOptionsObject);
      dataSet.push(data);
      dataSetOptions.push(dataOptions);
      const canvas = document.createElement('canvas');
      document.body.appendChild(canvas);
      if (payload.selectedChartType === ChartType.verticalBarChart) {
        const verticalChart = new Chart(
          canvas.getContext('2d') as CanvasRenderingContext2D,
          {
            type: 'bar',
            data: {
              labels: dataSet[0].labels,
              datasets: dataSet[0].datasets,
            },
            options: dataSetOptions[0],
            plugins: [
              {
                afterDatasetDraw(chart: any, args: any) {
                  args.meta.data.forEach((element: any) => {
                    if (element._model.base - element._model.y < 0) {
                      const borderWidth = 8;
                      const ctx = chart.ctx;
                      const vm = element._view;
                      const half = vm.width / 2;
                      const left = vm.x - half;
                      const right = vm.x + half;
                      const top = vm.y - 2;
                      const width = right - left;
                      const height =
                        chart.chartArea.bottom - top + borderWidth / 2 - 1;
                      ctx.beginPath();
                      ctx.lineWidth = borderWidth;
                      ctx.strokeStyle = '#000000';
                      ctx.setLineDash([3, 4]);
                      ctx.moveTo(left, top);
                      ctx.lineTo(left, top + height);
                      ctx.moveTo(left, top);
                      ctx.lineTo(left + width, top);
                      ctx.moveTo(left + width, top);
                      ctx.lineTo(left + width, top + height);
                      ctx.stroke();
                      ctx.save();
                    }
                  });
                },
              },
            ],
          },
        );
        renderChart.image = verticalChart.toBase64Image();
      } else {
        const horizontalChart = new Chart(
          canvas.getContext('2d') as CanvasRenderingContext2D,
          {
            type: 'horizontalBar',
            data: {
              labels: dataSet[0].labels,
              datasets: dataSet[0].datasets,
            },
            options: dataSetOptions[0],
            plugins: [
              {
                afterDatasetDraw(chart: any, args: any) {
                  args.meta.data.forEach((element: any) => {
                    if (element._model.base - element._model.x > 0) {
                      const borderWidth = 8;
                      const ctx = chart.ctx;
                      const vm = element._view;
                      const half = vm.height / 2;
                      const top = vm.y - half;
                      const bottom = vm.y + half;
                      const right = vm.x + 10;
                      const left = chart.chartArea.left;
                      ctx.beginPath();
                      ctx.lineWidth = borderWidth;
                      ctx.strokeStyle = '#000000';
                      ctx.setLineDash([4, 5]);
                      ctx.moveTo(left, top);
                      ctx.lineTo(right, top);
                      ctx.moveTo(right, top);
                      ctx.lineTo(right, bottom);
                      ctx.moveTo(right, bottom);
                      ctx.lineTo(left, bottom);
                      ctx.stroke();
                      ctx.save();
                    }
                  });
                },
              },
            ],
          },
        );
        renderChart.image = horizontalChart.toBase64Image();
      }
      const criteriaNamesText: any[] = labels.map((label) => {
        return {
          text: label,
          color: '#444F51',
          margin: [10, 5, 0, 2],
        };
      });
      const names = {
        layout: 'noBorders',
        table: {
          widths: ['*'],
          body: [
            [
              {
                ol: criteriaNamesText,
                fontSize: 9,
                fillColor: '#EFF7FF',
                markerColor: '#444F51',
              },
            ],
          ],
        },
        margin: [10, 0, 0, 23],
      };
      let aggregateStack: any[];
      if (payload.filteredCriterias.length > 4) {
        renderChart.margin = [0, 0, 0, 15];
        aggregateStack = [renderChart, names];
      } else {
        aggregateStack = [renderChart];
      }
      const criteriaChart = {
        stack: aggregateStack,
        unbreakable: false,
      };
      aggregateChart.push(criteriaChart);
      document.body.removeChild(canvas);
    } else {
      aggregateChart.push({
        stack: [],
        unbreakable: false,
      });
    }
    // generate table
    aggregateCriteriaConfiguration.forEach(
      (criteriaDetails, criteriaDetailsIndex) => {
        const title = {
          text: criteriaDetailsIndex + 1 + '. ' + criteriaDetails.title,
          bold: true,
          color: '#444F51',
          fontSize: 11,
          margin: [0, 0, 0, 17],
        };
        const bodyRows: any[][] = [];
        criteriaDetails.criteriaSamplingData.forEach(
          (optionsData: any, optionDataIndex: number) => {
            const rowData: any[] = [];
            const option = Object.keys(optionsData)[0];
            rowData.push({
              text: option,
              color: '#444F51',
              fontSize: 12,
              border: [true, false, true, false],
              margin:
                optionDataIndex ===
                criteriaDetails.criteriaSamplingData.length - 1
                  ? [8, 6, 0, 6]
                  : [8, 6, 0, 0],
            });
            rowData.push({
              text: optionsData[option],
              color: '#444F51',
              fontSize: 12,
              border: [true, false, true, false],
              alignment: 'right',
              margin:
                optionDataIndex ===
                criteriaDetails.criteriaSamplingData.length - 1
                  ? [0, 6, 8, 6]
                  : [0, 6, 8, 0],
            });
            bodyRows.push(rowData);
          },
        );
        let total: number | string = 0;
        let compliance: any = 0;
        bodyRows.forEach((optionValues) => {
          if (optionValues[1].text === '-') {
            total = '-';
          } else {
            total += optionValues[1].text;
          }
        });
        bodyRows.push([
          {
            text: 'Total Data Collected',
            color: '#444F51',
            fontSize: 12,
            margin: [8, 6, 0, 6],
          },
          {
            text: total,
            color: '#444F51',
            fontSize: 12,
            alignment: 'right',
            margin: [0, 6, 8, 6],
          },
        ]);
        if (criteriaDetails.criteriaType === CRITERION_TYPE.BOOLEAN) {
          if (bodyRows[0][1].text === 0) {
            bodyRows.push([
              {
                text: 'Compliance',
                color: '#444F51',
                fontSize: 12,
                margin: [8, 6, 0, 6],
                bold: true,
              },
              {
                text: compliance + '%',
                color: '#444F51',
                fontSize: 12,
                alignment: 'right',
                bold: true,
                margin: [0, 6, 8, 6],
              },
            ]);
          } else {
            if (bodyRows[0][1].text === '-') {
              compliance = '-';
            } else {
              compliance =
                (bodyRows[0][1].text / (total - bodyRows[2][1].text)) * 100;
            }
            bodyRows.push([
              {
                text: 'Compliance',
                color: '#444F51',
                fontSize: 12,
                margin: [8, 6, 0, 6],
                bold: true,
              },
              {
                text:
                  compliance !== '-'
                    ? Math.round(compliance * 100) / 100 + '%'
                    : compliance,
                color: '#444F51',
                fontSize: 12,
                alignment: 'right',
                bold: true,
                margin: [0, 6, 8, 6],
              },
            ]);
          }
        }
        const tableData = {
          table: {
            unbreakable: true,
            widths: ['*', 100],
            body: [
              [
                {
                  text: 'Answer Choices',
                  color: '#9F9F9F',
                  fontSize: 11,
                  margin: [8, 10, 0, 10],
                },
                {
                  text: siteName,
                  color: '#9F9F9F',
                  fontSize: 11,
                  alignment: 'right',
                  margin: [0, 10, 8, 10],
                },
              ],
              ...bodyRows,
            ],
          },
          layout: {
            hLineWidth: () => {
              return 0.5;
            },
            vLineWidth: () => {
              return 0.5;
            },
            hLineColor: () => {
              return '#DBDBDB';
            },
            vLineColor: () => {
              return '#DBDBDB';
            },
          },
        };
        const stack: any[] = [title, tableData];
        const criteriaTable = {
          stack,
          unbreakable: true,
        };
        renderTables.push(criteriaTable);
      },
    );
    aggregateCriteriaConfiguration.forEach(
      (criteriaDetails, criteriaDetailsIndex) => {
        const samplingTitle = {
          text: 'Sampling',
          bold: true,
          color: '#444F51',
          fontSize: 11,
          margin: [0, 13, 0, 17],
        };
        const dirtySamplingTables: any[] = this.getSamplingTables(
          criteriaDetails,
          samplingTitle,
        );
        renderSamplingTables.push(dirtySamplingTables[0]);
      },
    );
    return { aggregateChart, renderTables, renderSamplingTables };
  }

  public getSamplingTables(criteriaDetails: any, samplingTitle: any) {
    const dirtySamplingTables: any[] = [];
    const bodyRows: any[][] = [];
    const titleHeaderRow: any[] = [];
    const transformedSamplingRows: any[] = [];
    const dirtySamplingRows: any[] = [];
    let totalColumn: number = 0;
    switch (criteriaDetails.samplingConfiguration.auditSamplingType) {
      case 'adHoc':
        titleHeaderRow.push({
          text: 'Site',
          color: '#444F51',
          fontSize: 11,
          margin: [8, 6, 8, 6],
          alignment: 'left',
        });
        titleHeaderRow.push({
          text: 'Sampling',
          color: '#444F51',
          fontSize: 11,
          margin: [8, 6, 8, 6],
          alignment: 'right',
        });
        totalColumn += 2;
        dirtySamplingRows.push({
          siteName:
            criteriaDetails.siteSamplingConfiguration[0].auditSiteMap.site.name,
          values: [
            criteriaDetails.criteriaSamplingDataConfiguration.isSamplingEnabled
              ? 'Enabled'
              : 'Disabled',
          ],
        });
        dirtySamplingRows.forEach((samplingData) => {
          const samplingRows: any[] = [];
          samplingRows.push({
            text: samplingData.siteName,
            color: '#444F51',
            fontSize: 12,
            alignment: 'left',
            margin: [8, 6, 8, 6],
          });
          samplingData.values.forEach((value: any) => {
            samplingRows.push({
              text: value,
              color: '#444F51',
              fontSize: 12,
              alignment: 'right',
              margin: [8, 6, 8, 6],
            });
          });
          transformedSamplingRows.push(samplingRows);
        });
        bodyRows.push(titleHeaderRow);
        bodyRows.push(...transformedSamplingRows);
        break;
      case 'consecutive':
        if (
          criteriaDetails.samplingConfiguration.samplingMode === 'minAndMax'
        ) {
          titleHeaderRow.push({
            text: 'Site',
            color: '#444F51',
            fontSize: 11,
            margin: [8, 6, 8, 6],
            alignment: 'left',
          });
          titleHeaderRow.push({
            text: 'Min.',
            color: '#444F51',
            fontSize: 11,
            margin: [8, 6, 8, 6],
            alignment: 'right',
          });
          titleHeaderRow.push({
            text: 'Max.',
            color: '#444F51',
            fontSize: 11,
            margin: [8, 6, 8, 6],
            alignment: 'right',
          });
          totalColumn += 3;
          dirtySamplingRows.push({
            siteName:
              criteriaDetails.siteSamplingConfiguration[0].auditSiteMap.site
                .name,
            values: [
              criteriaDetails.criteriaSamplingDataConfiguration[0]
                .minSampleSize,
              criteriaDetails.criteriaSamplingDataConfiguration[0]
                .maxSampleSize,
            ],
          });
          dirtySamplingRows.forEach((samplingData) => {
            const samplingRows: any[] = [];
            samplingRows.push({
              text: samplingData.siteName,
              color: '#444F51',
              fontSize: 12,
              alignment: 'left',
              margin: [8, 6, 8, 6],
            });
            samplingData.values.forEach((value: any) => {
              samplingRows.push({
                text: value,
                color: '#444F51',
                fontSize: 12,
                alignment: 'right',
                margin: [8, 6, 8, 6],
              });
            });
            transformedSamplingRows.push(samplingRows);
          });
          bodyRows.push(titleHeaderRow);
          bodyRows.push(...transformedSamplingRows);
        } else {
          titleHeaderRow.push({
            text: 'Site',
            color: '#444F51',
            fontSize: 11,
            margin: [8, 6, 8, 6],
            alignment: 'left',
          });
          titleHeaderRow.push({
            text: 'Target',
            color: '#444F51',
            fontSize: 11,
            margin: [8, 6, 8, 6],
            alignment: 'right',
          });
          totalColumn += 2;
          dirtySamplingRows.push({
            siteName:
              criteriaDetails.siteSamplingConfiguration[0].auditSiteMap.site
                .name,
            values: [
              criteriaDetails.criteriaSamplingDataConfiguration[0].sampleSize,
            ],
          });
          dirtySamplingRows.forEach((samplingData) => {
            const samplingRows: any[] = [];
            samplingRows.push({
              text: samplingData.siteName,
              color: '#444F51',
              fontSize: 12,
              alignment: 'left',
              margin: [8, 6, 8, 6],
            });
            samplingData.values.forEach((value: any) => {
              samplingRows.push({
                text: value,
                color: '#444F51',
                fontSize: 12,
                alignment: 'right',
                margin: [8, 6, 8, 6],
              });
            });
            transformedSamplingRows.push(samplingRows);
          });
          bodyRows.push(titleHeaderRow);
          bodyRows.push(...transformedSamplingRows);
        }
        break;
      default:
        titleHeaderRow.push({
          text: 'Site',
          color: '#444F51',
          fontSize: 11,
          margin: [8, 6, 8, 6],
          alignment: 'left',
        });
        titleHeaderRow.push({
          text: 'Target',
          color: '#444F51',
          fontSize: 11,
          margin: [8, 6, 8, 6],
          alignment: 'right',
        });
        totalColumn += 2;
        dirtySamplingRows.push({
          siteName:
            criteriaDetails.siteSamplingConfiguration[0].auditSiteMap.site.name,
          values: [
            criteriaDetails.criteriaSamplingDataConfiguration[0].sampleSize,
          ],
        });
        dirtySamplingRows.forEach((samplingData) => {
          const samplingRows: any[] = [];
          samplingRows.push({
            text: samplingData.siteName,
            color: '#444F51',
            fontSize: 12,
            alignment: 'left',
            margin: [8, 6, 8, 6],
          });
          samplingData.values.forEach((value: any) => {
            samplingRows.push({
              text: value,
              color: '#444F51',
              fontSize: 12,
              alignment: 'right',
              margin: [8, 6, 8, 6],
            });
          });
          transformedSamplingRows.push(samplingRows);
        });
        bodyRows.push(titleHeaderRow);
        bodyRows.push(...transformedSamplingRows);
    }
    const samplingTableData = {
      table: {
        unbreakable: true,
        widths: [...Array(totalColumn).keys()].map((temp) => '*'),
        body: [...bodyRows],
      },
      layout: {
        hLineWidth: () => {
          return 0.5;
        },
        vLineWidth: () => {
          return 0.5;
        },
        hLineColor: () => {
          return '#DBDBDB';
        },
        vLineColor: () => {
          return '#DBDBDB';
        },
      },
    };
    const stack: any[] = [samplingTableData];
    const criteriaSamplingTable = {
      stack,
      unbreakable: true,
    };
    dirtySamplingTables.push(criteriaSamplingTable);
    const divider = {
      canvas: [
        {
          type: 'line',
          x1: 0,
          y1: 15,
          x2: 445,
          y2: 15,
          lineWidth: 0.5,
          lineColor: '#DBDBDB',
        },
      ],
      margin: [0, 0, 0, 13],
    };
    dirtySamplingTables[0].stack.unshift(samplingTitle);
    dirtySamplingTables[0].stack.push(divider);
    return dirtySamplingTables;
  }

  public generateConfigurationData(
    configurationData: any[],
    samplingConfiguration: any,
  ) {
    let aggregateCriteriaConfiguration: any[];
    if (Array.isArray(configurationData) && configurationData.length > 0) {
      const aggregateConfig: any = configurationData.map((data: any) => {
        const criteriaOptionsDataDistribution =
          data.criteriaSamplingData.criteriaOptionsDataDistribution;
        const criteriaSamplingData = Object.keys(
          criteriaOptionsDataDistribution,
        ).map((key: string) => ({
          [key]: data.criteriaSamplingData.criteriaOptionsDataDistribution[key],
        }));
        return {
          title: _get(data, 'criteria.title', ''),
          criteriaType: _get(data, 'criteria.criteriaType', ''),
          sampleSize: Number(
            _get(data, 'criteriaSamplingDataConfigurations.sampleSize', 0),
          ),
          siteSamplingConfiguration: data.siteSamplingDataConfigurations,
          criteriaSamplingDataConfiguration:
            data.criteriaSamplingDataConfigurations,
          samplingConfiguration,
          criteriaSamplingData,
        };
      });
      aggregateCriteriaConfiguration = _cloneDeep(aggregateConfig);
    } else {
      aggregateCriteriaConfiguration = [];
    }
    const {
      individualBooleanCriteriaConfiguration,
      individualMixedCriteriaConfiguration,
    } = this.generateIndividualCriteriaSet(aggregateCriteriaConfiguration);
    return {
      aggregateCriteriaConfiguration,
      individualBooleanCriteriaConfiguration,
      individualMixedCriteriaConfiguration,
    };
  }

  public generateIndividualCriteriaSet(aggregateCriteriaConfiguration: any[]) {
    const individualBooleanCriteriaConfiguration = aggregateCriteriaConfiguration.filter(
      (item) => {
        return item.criteriaType === CRITERION_TYPE.BOOLEAN;
      },
    );
    const individualMixedCriteriaConfiguration = aggregateCriteriaConfiguration.filter(
      (item) => {
        return item.criteriaType !== CRITERION_TYPE.BOOLEAN;
      },
    );
    return {
      individualBooleanCriteriaConfiguration,
      individualMixedCriteriaConfiguration,
    };
  }

  public transformLabel(label: string): string | string[] {
    const words = label.split(' ');
    let endIndex: number = labelSplitIndex;
    let eachLine: string = '';
    const eachLabel: string[] = [];
    _map(words, (word: string, wordIndex: string) => {
      switch (Number(wordIndex)) {
        case 0:
          eachLine = word;
          break;
        case endIndex:
          eachLabel.push(eachLine);
          eachLine = word;
          endIndex += labelSplitIndex;
          break;
        case words.length - 1:
          eachLine += ' ' + word;
          eachLabel.push(eachLine);
          break;
        default:
          eachLine += ' ' + word;
      }
    });
    if (eachLabel.length >= 3) {
      eachLabel[2] = eachLabel[2] + '...';
      return eachLabel.slice(0, 3);
    }
    return label;
  }

  public getAggregateCriteriaChartCategoryPercentage(
    individualBooleanCriteriaConfiguration: any[],
  ): number {
    const noOfBooleanCriterias: number =
      individualBooleanCriteriaConfiguration.length;
    return noOfBooleanCriterias <= 10 ? noOfBooleanCriterias * 0.1 : 0.8;
  }

  public getIndividualCriteriaChartCategoryPercentage(payload: any): number {
    if (payload.selectedChartType === ChartType.verticalBarChart) {
      return 0.1;
    } else {
      return 0.2;
    }
  }

  public formatDateRange(date: Date[]): string {
    return (
      this.getDate(new Date(date[0])) + ' - ' + this.getDate(new Date(date[1]))
    );
  }

  public getDate(newValue: Date): string {
    return (
      newValue.getDate() +
      ' ' +
      this.dirtyMonths[newValue.getMonth()] +
      ' ' +
      newValue.getFullYear()
    );
  }
}
