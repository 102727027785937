







































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import BaseLoading from '../../../components/base/BaseLoading.vue';
import { Debounce } from '../../../jbi-shared/util/debounce.vue-decorator';
import { ApiState, Pagination } from '../../../store/types/general.types';
import {
  CreateGripEnablerBase,
  GetEnablersPayload,
  GripEnabler,
} from '../../../store/types/grip.types';

@Component({
  components: { BaseLoading },
})
export default class ExistingGripEnablerList extends Vue {
  @Prop() public projectId!: number;
  public isLoading: boolean = true;
  public searchTitle: string = '';
  public perPage: number = 100;
  public page: number = 1;
  public enablerList: GripEnabler[] = [];
  public enablerInfo: string =
    'Enablers to the utilisation of evidence in practice.';

  @Action('projects/getGripEnablers')
  public getGripEnablers!: (payload: {
    projectId: number;
    params: GetEnablersPayload;
  }) => void;

  @State((state) => state.projects.gripEnablers)
  public gripEnablers!: Pagination<GripEnabler>;

  @State((state) => state.projects.apiState.getGripEnablers)
  public getGripEnablersApiState!: ApiState;

  public getUpperCase(value: string): string {
    return value.toUpperCase();
  }

  public createAnalysisEnablerItem(
    enabler: GripEnabler,
  ): CreateGripEnablerBase {
    return {
      title: enabler.title,
      description: enabler.description,
      links: enabler.links,
      attachments: enabler.attachments,
      gripDomainCategoryId: enabler.gripDomainCategory
        ? enabler.gripDomainCategory.id
        : null,
    };
  }

  public onDragStart(event: any, enabler: GripEnabler): void {
    const newItem = this.createAnalysisEnablerItem(enabler);
    const jsonData = JSON.stringify({ ...newItem, itemType: 'enabler' });
    event.dataTransfer.setData('application/json', jsonData);
  }

  public getEnablers(): void {
    this.isLoading = true;
    this.getGripEnablers({
      projectId: this.projectId,
      params: {
        title: this.searchTitle,
        limit: this.perPage,
        page: this.page,
      },
    });
  }

  public collapseSideNav(): void {
    this.$emit('collapseSideNav');
  }

  public mounted(): void {
    this.getEnablers();
  }

  @Watch('searchTitle')
  @Debounce(500)
  public onTitleSearch(): void {
    this.getEnablers();
  }

  @Watch('getGripEnablersApiState.loading')
  public watchGetGripEnablersLoading(): void {
    this.isLoading = true;
  }

  @Watch('getGripEnablersApiState.success')
  public watchGetGripEnablersSuccess(): void {
    this.isLoading = false;
    this.enablerList =
      this.gripEnablers && this.gripEnablers.items
        ? this.gripEnablers.items
        : [];
  }
}
