










































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { State, Action } from 'vuex-class';
import {
  PaginatedProjectEventLogsPayload,
  PaginatedProjectEventLogsResponse,
} from '@/store/modules/projects/types/projects.types';
import BasePagination from '@/components/base/BasePagination.vue';
import dayjs from 'dayjs';
import {
  DATE_FORMAT,
  getEndDateTime,
  getStartDateTime,
} from '@/utils/date.util';
import { useUserEmail } from '@/utils/user.util';
import { ProjectDetailPayload } from '../../../store/modules/projects/types/projects.types';

const dirtyMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

@Component({
  components: {
    BasePagination,
  },
})
export default class ProjectEventLogs extends Vue {
  @Prop()
  public projectId!: number;

  @Prop()
  public projectDetail!: ProjectDetailPayload;

  @Action('projects/getPaginatedEventLogs')
  public getRecentEventLogs!: (
    params: PaginatedProjectEventLogsPayload,
  ) => void;

  @State((state) => state.projects.paginatedEventLogs)
  public recentEventLogs!: PaginatedProjectEventLogsResponse;
  public page: number = 1;
  public perPage: number = 50;
  public totalCount: number = 0;
  public todayDate: Date = new Date();
  public selectedPeriod: Date[] = [
    new Date(
      this.todayDate.getFullYear(),
      this.todayDate.getMonth(),
      this.todayDate.getDate() - 7,
    ),
    this.todayDate,
  ];

  public mounted() {
    this.fetchEventLogs();
  }

  public fetchEventLogs() {
    this.getRecentEventLogs({
      projectId: this.projectId,
      limit: this.getCurrentPerPage,
      page: this.getCurrentPage,
      fromDate: getStartDateTime(this.selectedPeriod[0]),
      toDate: getEndDateTime(this.selectedPeriod[1]),
    });
  }

  get totalNumberOfPage(): number {
    return Math.ceil(this.totalCount / this.perPage);
  }

  get startItemIndex(): number {
    return this.page * this.perPage - this.perPage + 1;
  }

  get endItemIndex(): number {
    return Math.min(this.totalCount, this.page * this.perPage);
  }

  get isFirstPage(): boolean {
    return this.page === 1;
  }

  get isLastPage(): boolean {
    return this.page === this.totalNumberOfPage || this.totalNumberOfPage === 0;
  }

  get getCurrentPage(): number {
    return this.page;
  }

  set getCurrentPage(newValue: number) {
    this.page = newValue;
    this.fetchEventLogs();
  }

  get getCurrentPerPage(): number {
    return this.perPage;
  }

  set getCurrentPerPage(newValue: number) {
    this.perPage = newValue;
    this.fetchEventLogs();
  }

  get histories() {
    if (this.recentEventLogs) {
      this.totalCount = this.recentEventLogs.itemCount;
      return this.recentEventLogs.items
        .filter((log: any) => {
          return log.eventPayload.originator;
        })
        .map((log: any) => {
          const today = dayjs().format(DATE_FORMAT);
          const yesterday = dayjs().add(-1, 'day').format(DATE_FORMAT);
          const dataUpdatedAt = dayjs(new Date(log.eventOccurredAt)).format(
            DATE_FORMAT,
          );

          let dateToDisplay;
          if (dataUpdatedAt === today) {
            dateToDisplay = 'Today';
          } else if (dataUpdatedAt === yesterday) {
            dateToDisplay = 'Yesterday';
          } else {
            dateToDisplay = dataUpdatedAt;
          }

          return {
            ...log,
            datetime: dateToDisplay,
          };
        });
    }
    return [];
  }

  public formatDateRange(date: Date[]) {
    return this.getDate(date[0]) + ' - ' + this.getDate(date[1]);
  }

  public getDate(newValue: Date) {
    return (
      newValue.getDate() +
      ' ' +
      dirtyMonths[newValue.getMonth()] +
      ' ' +
      newValue.getFullYear()
    );
  }

  public filterPeriod(newValue: Date[]) {
    this.selectedPeriod = newValue;
    this.fetchEventLogs();
  }

  public getUserName(userInfo: any) {
    const name = userInfo.name;
    const loggedInUserEmail = this.userEmail;
    if (loggedInUserEmail === userInfo.email) {
      return 'You';
    } else {
      return name;
    }
  }

  get userEmail(): string | undefined {
    return useUserEmail.call(this);
  }
}
