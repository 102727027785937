






























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { cloneDeep as _cloneDeep } from 'lodash';

@Component({ components: {} })
export default class DeleteSiteModal extends Vue {
  @Prop()
  public modalTitle!: string;

  @Prop()
  public id!: number;

  @Prop()
  public name!: string;
  public removeSite(id: number) {
    this.$emit('deleteSelectedSite', id);
    this.$emit('close');
  }
  public closeModal() {
    this.$emit('close');
  }
}
