




























import { Component, Vue, Prop } from 'vue-property-decorator';
import { GripAnalysis } from '../../../store/types/grip.types';

@Component({})
export default class GripAnalysisCompressedNavigationTabs extends Vue {
  @Prop() public compressedAnalysis!: GripAnalysis[];
  public dirtyCompressedAnalysis: GripAnalysis[] = [];

  public mounted(): void {
    this.dirtyCompressedAnalysis = JSON.parse(
      JSON.stringify(this.compressedAnalysis),
    );
  }

  public updateVisibleAnalysisHeader(compressedAnalysisIndex: number): void {
    this.$emit('updateVisibleAnalysisHeader', compressedAnalysisIndex);
  }

  public getUpperCase(value: string): string {
    return value.toUpperCase();
  }

  public getCompressedAnalysisTitle(compressedAnalysisTitle: string): string {
    if (compressedAnalysisTitle.length > 13) {
      return `${compressedAnalysisTitle.substring(0, 11)} ...`.toUpperCase();
    }
    return compressedAnalysisTitle.toUpperCase();
  }

  get getCompressedAnalysisDropdownText(): string {
    return `${this.dirtyCompressedAnalysis.length} MORE...`;
  }
}
