



















































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

@Component({ components: {
    },
})
export default class AuditMinAndMaxErrorModal extends Vue {
    @Prop()
    public modalTitle!: string;

    @Prop()
    public exceededMaxList!: any;

    public closeModal() {
        this.$emit('close');
    }

    public submitData() {
        this.$emit('emitResultData');
        this.$emit('close');
    }
}
