




























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class ConfirmationModal extends Vue {
  @Prop()
  public title!: string;
  @Prop()
  public content!: string;
  @Prop()
  public confirmButtonLabel!: string;
  @Prop()
  public cancelButtonLabel!: string;

  public confirm(): void {
    this.$emit('confirm');
    this.$emit('close');
  }
  public cancel(): void {
    this.$emit('close');
  }
}
