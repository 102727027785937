




























































import { Component, Prop, Watch } from 'vue-property-decorator';
import { ToastProgrammatic as Toast } from 'buefy';
import { Action, State } from 'vuex-class';
import {
  GripStrategy,
  GripStrategyDTO,
  UpdateGripStrategyBase,
  UpdateGripStrategyPayload,
} from '../../../store/types/grip.types';
import { ApiState } from '../../../store/types/general.types';
import StrategyModal from './StrategyModal.vue';
import BasePagination from '../../../components/base/BasePagination.vue';
import DeleteModalBox from '../../../components/DeleteModalBox.vue';
import { isTruthy } from '../../../jbi-shared/util/watcher.vue-decorator';
import { PaginationMixin } from '../../../components/base/pagination.mixin';
import { mixins } from 'vue-class-component';
import { getUpperCase } from '../../../utils/general.util';

@Component({
  components: { BasePagination, StrategyModal, DeleteModalBox },
})
export default class StrategyList extends mixins(PaginationMixin) {
  @Prop() public items!: GripStrategy[];
  @Prop(Number) public totalNumberOfPage!: number;
  @Prop(Number) public totalCount!: number;
  @Prop(Boolean) public isFirstPage!: boolean;
  @Prop(Boolean) public isLastPage!: boolean;
  @Prop(Number) public startItemIndex!: number;
  @Prop(Number) public endItemIndex!: number;
  public modalKey: number = Math.floor(Math.random() * 999);
  public isModalActive: boolean = false;
  public gripStrategyDetails: GripStrategyDTO | null = null;

  @Action('projects/updateGripStrategy')
  public updateGripStrategy!: (payload: UpdateGripStrategyPayload) => void;

  @State((state) => state.projects.apiState.updateGripStrategy)
  public updateGripStrategyApiState!: ApiState;

  @Action('projects/deleteGripStrategy')
  public deleteGripStrategy!: (payload: {
    projectId: number;
    strategyId: number;
  }) => void;

  @State((state) => state.projects.apiState.deleteGripStrategy)
  public deleteGripStrategyApiState!: ApiState;

  get projectId(): number {
    return +this.$route.params.projectId;
  }

  public getUpperCase(value: string) {
    return getUpperCase(value);
  }

  public closeStrategyModal(): void {
    this.isModalActive = false;
  }

  public handleStrategyUpdate(gripStrategy: UpdateGripStrategyBase): void {
    this.updateGripStrategy({
      ...gripStrategy,
      projectId: this.projectId,
    });
    this.closeStrategyModal();
  }

  public handleEdit(strategy: GripStrategy): void {
    const { project, status, createdDate, updatedDate, ...details } = strategy;

    this.gripStrategyDetails = details as GripStrategyDTO;
    this.isModalActive = true;
    this.modalKey += 1;
  }

  public handleDelete(strategyId: number) {
    this.$buefy.modal.open({
      parent: this,
      component: DeleteModalBox,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle: 'Delete Strategy?',
        modalContent:
          'Are you sure you want to delete this strategy? This action cannot be undone.',
      },
      events: {
        close: () => {
          this.$emit('close');
        },
        confirm: () => {
          this.deleteGripStrategy({ projectId: this.projectId, strategyId });
        },
      },
    });
  }

  @Watch('updateGripStrategyApiState.success')
  @isTruthy
  public onUpdateStrategySuccess(): void {
    this.$emit('updateList');
    Toast.open({
      message: `Strategy updated`,
      position: 'is-top',
      type: 'is-dark',
      duration: 3500,
    });
  }

  @Watch('updateGripStrategyApiState.error')
  public onUpdateStrategyError(): void {
    this.$emit('close');
  }

  @Watch('deleteGripStrategyApiState.success')
  @isTruthy
  public onDeleteStrategySuccess() {
    this.$emit('updateList');
    Toast.open({
      message: `Strategy deleted`,
      position: 'is-top',
      type: 'is-dark',
      duration: 3500,
    });
  }
}
