












































































































































    import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
    import BField from 'buefy/src/components/field/Field.vue';
    import BInput from 'buefy/src/components/input/Input.vue';
    import SiteAutoCompleteInput from '@/views/Project/components/SiteAutoCompleteInput.vue';
    import {
        RecurrenceType,
        Site,
        RecurrenceSettingPayload,
        MultipleRecurrenceFrequencyOptions,
    } from '@/store/modules/projects/types/projects.types';
    import {filter as _filter} from 'lodash';
    import ParticipantInputs from '@/views/Project/components/ParticipantInputs.vue';
    import {StringInputOption} from '@/jbi-shared/types/form.types';

    @Component({ components: {
        ParticipantSetting: ParticipantInputs,
        BInput,
        BField,
        SiteAutoCompleteInput,
    },
})
export default class ProjectSettings extends Vue {
    public projectExpiryDatePicker = null;
    @Prop()
    public sites!: Site[];
    public localSelectedSiteArray = [{
        name: '',
    }];
    public localParticipantList: any[] = [{
        email: '',
        role: '',
        sites: [],
    }];

    public dirtyRecurrenceSetting: RecurrenceSettingPayload = {
        recurrenceType: 'oneOff',
        enableGrip: false,
    };

    @Watch('localSelectedSiteArray')
    public watchSelectedSiteArray(newValue: Site[]) {
        this.$emit('update:selectedSiteArray', this.sanitizeSiteList(newValue));
    }

    public sanitizeSiteList(arr: Site[]) {
        return _filter(arr, (site) => {
            return site.name.trim() ? site.name : false;
        });
    }

    public addSiteCard() {
        this.localSelectedSiteArray.push({
            name: '',
        });
    }

    public addParticipantCard() {
        this.localParticipantList.push({
            email: '',
            role: '',
            sites: [],
        });
    }

    public handleInviteUserArray(newValue: any) {
        this.localParticipantList = this.sanitizeParticipantList(newValue);
        this.$emit('update:selectedParticipantArray', this.localParticipantList);
    }

    public sanitizeParticipantList(arr: any[]) {
        return _filter(arr, (participant) => {
            return participant.email.trim()
                && participant.role.trim()
                && participant.sites
                ? true
                : false;
        });
    }

    get recurrenceOptions(): StringInputOption[] {
        return Object.entries(RecurrenceType).map(([key, value]) => {
            return {
                id: key,
                name: value,
            };
        });
    }

    get recurrenceType() {
        return this.dirtyRecurrenceSetting.recurrenceType;
    }

    set recurrenceType(newValue) {
        this.dirtyRecurrenceSetting.recurrenceType = newValue;
        this.$emit('updateRecurenceSetting', this.dirtyRecurrenceSetting);
    }

    get scheduledRecurrenceStartDate() {
        return null;
    }
    set scheduledRecurrenceStartDate(newValue) {
        this.dirtyRecurrenceSetting.scheduledRecurrenceStartDate = newValue;
        this.$emit('updateRecurenceSetting', this.dirtyRecurrenceSetting);
    }

    get scheduledRecurrenceEndDate() {
        return null;
    }
    set scheduledRecurrenceEndDate(newValue) {
        this.dirtyRecurrenceSetting.scheduledRecurrenceEndDate = newValue;
        this.$emit('updateRecurenceSetting', this.dirtyRecurrenceSetting);
    }

    get multipleRecurrenceFrequencyOptions(): StringInputOption[] {
        return Object.entries(MultipleRecurrenceFrequencyOptions).map(([key, value]) => {
            return {
                id: key,
                name: value,
            };
        });
    }

    get multipleRecurrenceType() {
        return 'Daily';
    }
    set multipleRecurrenceType(newValue) {
        this.dirtyRecurrenceSetting.multipleRecurrenceType = newValue;
        this.$emit('updateRecurenceSetting', this.dirtyRecurrenceSetting);
    }

}
