














import { Vue, Component, Prop } from 'vue-property-decorator';
import RadialProgressBar from '@/jbi-shared/vue-components/RadialProgressBar.vue';

@Component({
  components: { RadialProgressBar },
})
export default class UploadLoading extends Vue {
  @Prop(Boolean) private isLoading!: boolean;
  @Prop(Boolean) private isSuccess!: boolean;

  get uploadStatus(): string | null {
    if (this.isLoading) {
      return 'Uploading...';
    } else if (this.isSuccess) {
      return 'Processing...';
    }
    return null;
  }
}
