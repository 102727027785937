





























































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { cloneDeep as _cloneDeep } from 'lodash';
export interface FilterList {
  id: number;
  name: string;
}

@Component({ components: {} })
export default class BaseFilterDropdown extends Vue {
  @Prop()
  public filterTitle!: string;
  @Prop()
  public fieldName!: string;
  @Prop()
  public filterList!: FilterList[];
  @Prop({ default: false })
  public isDisabled!: boolean;
  public selectedIds: number[] = [];
  public isAllOptionSelected: boolean = true;
  public allIds: number[] = [];

  public mounted() {
    this.populateData();
  }

  public populateData() {
    this.filterList.map((single: any) => {
      this.selectedIds.push(single.id);
    });
    this.allIds = _cloneDeep(this.selectedIds);
  }

  public updateSelection(label: boolean = false, id: number) {
    if (label) {
      const idIndex = this.selectedIds.indexOf(id);
      if (idIndex === -1) {
        this.selectedIds.push(id);
      } else {
        this.selectedIds.splice(idIndex, 1);
      }
    }
    this.isAllOptionSelected = this.allIds.length === this.selectedIds.length;
    this.emitFilter();
  }

  public updateAllSelection(label: boolean = false) {
    if (label) {
      this.isAllOptionSelected = !this.isAllOptionSelected;
    }
    if (this.isAllOptionSelected) {
      this.selectedIds = _cloneDeep(this.allIds);
    } else {
      this.selectedIds = [];
    }
    this.emitFilter();
  }

  public mapOrder(selectedIds: number[], mapIds: number[]) {
    selectedIds.sort((selectedData, selectedNextData) => {
      if (mapIds.indexOf(selectedData) > mapIds.indexOf(selectedNextData)) {
        return 1;
      } else {
        return -1;
      }
    });
    return selectedIds;
  }

  public emitFilter() {
    if (this.selectedIds.length === 0 || this.selectedIds.length === 1) {
      this.$emit('applyFilter', this.selectedIds);
    } else {
      const mapIds: number[] = this.filterList.map((single) => single.id);
      this.selectedIds = this.mapOrder(this.selectedIds, mapIds);
      this.$emit('applyFilter', this.selectedIds);
    }
  }
}
