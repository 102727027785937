















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import { Action, State } from 'vuex-class';
import HeroBar from '@/components/base/HeroBar.vue';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import {
  AuditDetail,
  AuditSamplingDataConfig,
  AuditSamplingResponse,
  AuditSamplingType,
  ConsecutiveSamplingMode,
  DataCollectionSummaryRequestPayload,
  SingleSitePayload,
  SingleSiteSamplingRequestPayload,
} from '@/store/modules/audits/types/audits.types';
import {
  map as _map,
  round as _round,
  cloneDeep as _cloneDeep,
  orderBy as _orderBy,
  findIndex as _findIndex,
} from 'lodash';
import { ToastProgrammatic as Toast } from 'buefy';
import SamplingCalculator from '@/views/Audit/components/SamplingCalculator.vue';
import ConsecutiveCalculator from '@/views/Audit/components/ConsecutiveCalculator.vue';
import ConfirmationDialogue from '@/components/ConfirmationDialogue.vue';

@Component({
  components: {
    ConsecutiveCalculator,
    SamplingCalculator,
    DefaultLayout,
    HeroBar,
    ConfirmationDialogue,
  },
})
export default class AdHocSingleSiteSampling extends Vue {
  @Prop()
  public auditId!: number;
  @Prop()
  public auditConfig!: AuditSamplingResponse;
  @Prop()
  public auditSamplingType!: AuditSamplingType;
  @Prop()
  public auditDataCollectionDate!: string;
  @Prop({ default: true })
  public editable!: boolean;
  public singleSitePayload: SingleSitePayload[] = [];

  @Action('audits/updateSingleSiteSampling')
  public updateSingleSiteSampling!: (
    payload: SingleSiteSamplingRequestPayload,
  ) => void;

  @State((state) => state.audits.updatedSingleSiteSampling)
  public updatedSingleSiteSampling!: any;

  @Watch('updatedSingleSiteSampling')
  @isTruthy
  public watchUpdatedSingleSiteSampling() {
    this.showNotificationAndRedirectToProject();
  }

  public mounted() {
    this.populateSingeSiteData();
  }

  public populateSingeSiteData() {
    _map(this.auditConfig.dataConfigs, (dataConfig: any) => {
      const currentCriteriaPayload: SingleSitePayload = {
        auditId: this.auditId,
        criteriaId: 0, // todo: change this logic later
        auditCriteriaId: 0,
        isSamplingEnabled: false,
      };
      _map(dataConfig, (singleConfig: AuditSamplingDataConfig) => {
        if (singleConfig.auditCriteriaMap.isActive) {
          currentCriteriaPayload.criteriaId =
            singleConfig.auditCriteriaMap.criteria.id;
          currentCriteriaPayload.auditId = this.auditId;
          currentCriteriaPayload.auditCriteriaId =
            singleConfig.auditCriteriaMap.id;
          currentCriteriaPayload.criteriaTitle =
            singleConfig.auditCriteriaMap.criteria.title;
          currentCriteriaPayload.order = singleConfig.auditCriteriaMap.order;
          if (singleConfig.auditSiteMap === null) {
            currentCriteriaPayload.isSamplingEnabled =
              singleConfig.isSamplingEnabled;
            currentCriteriaPayload.dataConfig = singleConfig;
          }
        }
      });
      this.singleSitePayload.push(currentCriteriaPayload);
    });
  }

  public showNotificationAndRedirectToProject() {
    Toast.open({
      message: `Changes saved`,
      position: 'is-top',
      type: 'is-dark',
      duration: 3500,
    });
  }

  public handleSingleSiteChanges() {
    this.updateSingleSiteSampling({
      auditId: this.auditId,
      singleSitePayload: this.singleSitePayload,
      auditSamplingType: this.auditSamplingType,
      samplingConfigId: this.auditConfig.auditSamplingConfigs.id,
    });
  }

  get auditCriteriaList() {
    return _orderBy(this.singleSitePayload, ['order'], ['asc']);
  }

  public samplingCheckboxChange(index: number) {
    if (
      !this.auditCriteriaList[index].isSamplingEnabled &&
      this.auditDataCollectionDate !== undefined &&
      this.auditDataCollectionDate !== null
    ) {
      this.$buefy.modal.open({
        parent: this,
        component: ConfirmationDialogue,
        hasModalCard: true,
        trapFocus: true,
        onCancel: () => {
          this.auditCriteriaList[index].isSamplingEnabled = true;
        },
        props: {
          modalTitle: 'Disable Sampling?',
          confirmationText: `Disable Sampling will cease data collection
                            and delete collected data for this data collection period criteria.`,
          confirmationButtonText: 'Disable',
          cancelButtonText: 'Cancel',
        },
        events: {
          confirm: () => {
            this.auditCriteriaList[index].isSamplingEnabled = false;
          },
          cancel: () => {
            this.auditCriteriaList[index].isSamplingEnabled = true;
          },
        },
      });
    }
  }
}
