
















































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  CplusCriteria,
  CplusCriteriaItem,
  CplusCriteriaResults,
  GetCplusCriteriaRequestPayload,
  GroupedCriterias,
  JBICriterion,
  JBICriterionTypeBoolean,
  JBICriterionTypeCheckboxes,
  JBICriterionTypeRange,
} from '@/store/modules/projects/types/projects.types';
// TODO: remove lodash, and use alternative methods.
import { chain as _chain, difference as _difference } from 'lodash';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import AuditCriteriaDetailModal from '@/views/Project/components/AuditCriteriaDetailModal.vue';
import AuditCriteriaCreateModal from '@/views/Project/components/AuditCriteriaCreateModal.vue';
import BasePagination from '@/components/base/BasePagination.vue';
import { Action, State } from 'vuex-class';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import { CRITERION_TYPE } from '@/jbi-shared/types/criterions.types';
import { Route } from 'vue-router';

@Component({
  components: {
    DefaultLayout,
    AuditCriteriaDetailModal,
    BasePagination,
  },
})
export default class JBICriteriaSelection extends Vue {
  public searchCriteriaTerm: string = '';
  @Prop() public projectName!: string;
  @Prop() public auditName!: string;
  @Prop({ default: false, type: Boolean }) public isProjectLevel!: boolean;
  @Prop({ default: false, type: Boolean }) public isCreateProject!: boolean;
  @Prop() public selectedCriteriaArray!: CplusCriteria[];

  public totalCount: number = 0;
  public perPage = 50;
  public page = 1;

  public currentCriteriaSelection: CplusCriteria[] = [];
  public currentCriteriaModel: string[] = [];
  public groupedCriteriaList: CplusCriteriaItem[] = [];
  public displayResultCountText: string = '';
  public currentDocumentModel: string[] = [];
  public currentGroupedCriteriaSelection: any = [];
  public groupedCriteriaCollapsed: number[] = [];
  public customCriteriaList: any[] = [];
  public lastOrder: number = 0;
  public deletedCriteriaIds: number[] = [];

  @Action('projects/getCplusCriterias')
  public getCplusCriterias!: (params: GetCplusCriteriaRequestPayload) => void;

  @State((state) => state.projects.cplusCriterias)
  public cplusCriterias!: CplusCriteriaResults;

  get pageTitle(): string {
    return this.isProjectLevel || this.isCreateProject
      ? this.projectName
        ? this.projectName
        : `Project Creation`
      : this.auditName;
  }

  get totalNumberOfPage(): number {
    return Math.ceil(this.totalCount / this.perPage);
  }

  get startItemIndex(): number {
    return this.page * this.perPage - this.perPage + 1;
  }

  get endItemIndex(): number {
    return Math.min(this.totalCount, this.page * this.perPage);
  }

  get isFirstPage(): boolean {
    return this.page === 1;
  }

  get isLastPage(): boolean {
    return this.page === this.totalNumberOfPage;
  }

  get getCurrentPage(): number {
    return this.page;
  }

  set getCurrentPage(newValue: number) {
    this.page = Number(newValue);
    this.getFilteredCplusCriterias();
  }

  get getCurrentPerPage(): number {
    return this.perPage;
  }

  set getCurrentPerPage(newValue: number) {
    this.perPage = Number(newValue);
    this.getFilteredCplusCriterias();
  }

  get totalSelectedCriteria() {
    let totalCriteria = 0;

    if (this.currentGroupedCriteriaSelection.length > 0) {
      totalCriteria = this.currentGroupedCriteriaSelection
        .map(({ criterias }: GroupedCriterias) => criterias.length)
        .reduce(
          (accumulator: number, currentValue: number) =>
            accumulator + currentValue,
        );
    }
    return this.customCriteriaList.length + totalCriteria;
  }

  public getFilteredCplusCriterias() {
    this.getCplusCriterias({
      search: this.searchCriteriaTerm,
      page: this.page,
      perPage: this.perPage,
    });
  }

  public getCriteriaOptions(criteria: JBICriterion): string[] {
    let options: string[] = [];
    switch (criteria.type) {
      case CRITERION_TYPE.BOOLEAN:
        options = (criteria as JBICriterionTypeBoolean).booleanOptions;
        break;
      case CRITERION_TYPE.RANGE:
        options = (criteria as JBICriterionTypeRange).range;
        break;
      case CRITERION_TYPE.CHECKBOXES:
        options = (criteria as JBICriterionTypeCheckboxes).checkboxesOptions;
        break;
      default:
        break;
    }

    return options;
  }

  public mounted(): void {
    this.populateData();
    this.getFilteredCplusCriterias();
  }

  public populateData(): void {
    this.selectedCriteriaArray.forEach((criteria) => {
      if (criteria.document && criteria.document.id !== '') {
        this.currentCriteriaSelection.push(criteria);
      } else {
        this.customCriteriaList.push(criteria);
      }

      this.lastOrder =
        criteria.order > this.lastOrder ? criteria.order : this.lastOrder;
    });
  }

  public changeSelectedCriteria(selectedCriteria: JBICriterion): void {
    const selectedCriteriaId: string = selectedCriteria.id;
    const selectedCriteriaDocumentId: string = selectedCriteria.document.id;

    const existingCriteriaInSelectedList = this.currentCriteriaSelection.find(
      (currentCriteria) => {
        const currentCriteriaId = currentCriteria.criteriaDetails
          ? currentCriteria.criteriaDetails.connectPlusId
          : currentCriteria.id;

        return (
          currentCriteriaId === selectedCriteria.id &&
          currentCriteria.document.id === selectedCriteria.document.id
        );
      },
    );

    /**
     * if criteria exist in selected list("currentCriteriaSelection"),
     * then it's been removed
     * else it's been added
     */
    if (existingCriteriaInSelectedList) {
      this.removeSelectedCriteria(existingCriteriaInSelectedList);
    } else {
      /** check if it's re-added criteria */
      const existingCriteria = this.selectedCriteriaArray.find(
        (existingSelectedCriteria) => {
          const existingSelectedCriteriaId = existingSelectedCriteria.criteriaDetails
            ? existingSelectedCriteria.criteriaDetails.connectPlusId
            : existingSelectedCriteria.id;

          const existingSelectedCriteriaDocumentId =
            existingSelectedCriteria.document.id;

          return (
            existingSelectedCriteriaId === selectedCriteriaId &&
            existingSelectedCriteriaDocumentId === selectedCriteriaDocumentId
          );
        },
      );

      if (existingCriteria) {
        this.currentCriteriaSelection.push(existingCriteria);
      } else {
        this.currentCriteriaSelection.push(selectedCriteria as any);
      }
    }

    this.updateDocumentModel(selectedCriteriaDocumentId);
    this.updateGroupedCriteriaSelection();
  }

  public handleRemoveSelectedCriteria(
    selectedCriteria: CplusCriteria,
    isCustomCriteria: boolean = false,
    criteriaIndex: number = 0,
  ) {
    if (isCustomCriteria) {
      if (selectedCriteria.id) {
        this.deletedCriteriaIds.push(Number(selectedCriteria.id));
      }
      this.customCriteriaList.splice(criteriaIndex, 1);
    } else {
      const selectedCriteriaId: string = selectedCriteria.criteriaDetails
        ? selectedCriteria.criteriaDetails.connectPlusId
        : selectedCriteria.id;

      const cPlusDocument = this.groupedCriteriaList.find(
        (criteriaDocument) =>
          criteriaDocument.document.id === selectedCriteria.document.id,
      );

      /** if CPlusDocument exists, remove from currentCriteriaModel */
      if (cPlusDocument) {
        const index = this.currentCriteriaModel.indexOf(selectedCriteriaId, 0);
        if (index > -1) {
          this.currentCriteriaModel.splice(index, 1);
        }
      }

      this.removeSelectedCriteria(selectedCriteria);
      this.updateDocumentModel(selectedCriteria.document.id);
      this.updateGroupedCriteriaSelection();
    }
  }

  public removeSelectedCriteria(selectedCriteria: any) {
    const selectedCriteriaId: string = selectedCriteria.criteriaDetails
      ? selectedCriteria.criteriaDetails.connectPlusId
      : selectedCriteria.id;

    /**
     * criteria has criteriaDetails and doesn't already added into deletedList,
     * then add into deletedCriteriaIds
     *
     * Note: criteria with criteriaDetails is already saved criteria,
     * so we need passed delete payload to backend api to marked it as deleted.
     */
    if (
      selectedCriteria.criteriaDetails &&
      !this.deletedCriteriaIds.includes(Number(selectedCriteria.id))
    ) {
      this.deletedCriteriaIds.push(Number(selectedCriteria.id));
    }

    /** Filter criteria - removed match criteria */
    this.currentCriteriaSelection = this.currentCriteriaSelection.filter(
      (currentCriteria) => {
        const currentCriteriaId = currentCriteria.criteriaDetails
          ? currentCriteria.criteriaDetails.connectPlusId
          : currentCriteria.id;

        /** match criteria based on criteriaId and documentID */
        const isCriteriaMatched: boolean =
          currentCriteriaId === selectedCriteriaId &&
          currentCriteria.document.id === selectedCriteria.document.id;

        /** return criteria, which doesn't match */
        if (!isCriteriaMatched) {
          return true;
        }
      },
    );
  }

  public updateCriteriaModel(): void {
    this.selectedCriteriaArray.forEach((criteria) => {
      const criteriaId: string = criteria.criteriaDetails
        ? criteria.criteriaDetails.connectPlusId
        : criteria.id;

      if (
        !this.currentCriteriaModel.includes(criteriaId) &&
        criteria.document &&
        criteria.document.id !== ''
      ) {
        const cPlusDocument = this.groupedCriteriaList.find(
          (criteriaDocument) =>
            criteriaDocument.document.id === criteria.document.id,
        );

        /** if CPlusDocument exists, add into currentCriteriaModel */
        if (
          cPlusDocument &&
          cPlusDocument.document.id === criteria.document.id
        ) {
          this.currentCriteriaModel.push(criteriaId);
        }

        this.updateDocumentModel(criteria.document.id);
        this.updateGroupedCriteriaSelection();
      }
    });
  }

  public updateCplusCriteriaDisplayResult() {
    /** Set document collapsed empty */
    this.groupedCriteriaCollapsed = [];
    this.groupedCriteriaList = this.cplusCriterias.items.map(
      (criteriaItem: CplusCriteriaItem) => {
        const criterias = criteriaItem.criteria.map(
          (criteria: JBICriterion) => {
            return {
              ...criteria,
              document: criteriaItem.document,
            };
          },
        );

        return {
          documentId: criteriaItem.document.id,
          document: criteriaItem.document,
          criteria: criterias,
        };
      },
    );

    this.updateCriteriaModel();

    this.groupedCriteriaList.forEach((criteriaDocument: CplusCriteriaItem) => {
      this.updateDocumentModel(criteriaDocument.documentId);
    });

    if (this.searchCriteriaTerm) {
      this.displayResultCountText =
        this.cplusCriterias.total_count +
        ` Results for '` +
        this.searchCriteriaTerm +
        `'`;
    } else {
      this.displayResultCountText = '';
    }

    // this.groupedCriteriaCollapsed = [];
  }

  public updateGroupedCriteriaSelection() {
    let count = 0;
    this.currentGroupedCriteriaSelection = _chain(this.currentCriteriaSelection)
      .groupBy('document.id')
      .map((value, key) => {
        value.map((o) => {
          count = count + 1;
          o.count = count;
          return o;
        });
        return { documentId: key, criterias: value };
      })
      .value();
  }

  public changeSelectedDocumentCriteria(
    updatedGroupedCriterias: CplusCriteriaItem,
  ) {
    if (
      this.currentDocumentModel.includes(updatedGroupedCriterias.document.id)
    ) {
      // Add all criteria by document
      updatedGroupedCriterias.criteria.forEach((criteria) => {
        if (!this.currentCriteriaModel.includes(criteria.id)) {
          this.currentCriteriaModel.push(criteria.id);
          this.changeSelectedCriteria(criteria);
        }
      });
    } else {
      // Remove all criteria by document
      updatedGroupedCriterias.criteria.forEach((criteria) => {
        const index = this.currentCriteriaModel.indexOf(criteria.id, 0);
        if (index > -1) {
          this.currentCriteriaModel.splice(index, 1);
        }
        this.changeSelectedCriteria(criteria);
      });
    }
  }

  public updateDocumentModel(documentId: string) {
    const cPlusDocument = this.groupedCriteriaList.find(
      (criteriaDocument) => criteriaDocument.document.id === documentId,
    );

    if (cPlusDocument) {
      const criteriaIdList: string[] = cPlusDocument.criteria.map(
        (criteria: JBICriterion) => criteria.id,
      );
      if (_difference(criteriaIdList, this.currentCriteriaModel).length === 0) {
        if (!this.currentDocumentModel.includes(documentId)) {
          this.currentDocumentModel.push(documentId);
        }
      } else {
        const index = this.currentDocumentModel.indexOf(documentId, 0);
        if (index > -1) {
          this.currentDocumentModel.splice(index, 1);
        }
      }
    }
  }

  public emitResult() {
    let emitCriteriaArray!: any[];
    if (this.selectedCriteriaArray.length === 0) {
      this.currentCriteriaSelection.forEach((criteria, index) => {
        criteria.order = this.lastOrder = criteria.count;
      });
      this.customCriteriaList.forEach((criteria, index) => {
        criteria.order = this.lastOrder + index + 1;
      });
      emitCriteriaArray = this.currentCriteriaSelection;
      emitCriteriaArray.push(...this.customCriteriaList);
    } else {
      emitCriteriaArray = this.currentCriteriaSelection;
      emitCriteriaArray.push(...this.customCriteriaList);

      emitCriteriaArray.forEach((criteriaParent) => {
        if (criteriaParent.order === undefined) {
          emitCriteriaArray.forEach((criteriaChild) => {
            if (criteriaChild.order !== undefined) {
              criteriaChild.order = criteriaChild.order + 1;
            }
          });
          criteriaParent.order = 1;
        }
      });
    }
    const nonUpdatedCriteria = this.compareSimilarElementsInObjects(
      this.selectedCriteriaArray,
      emitCriteriaArray,
    );
    if (nonUpdatedCriteria) {
      this.deletedCriteriaIds = [];
    }

    this.$emit('updateSelectedCriteriaArray', {
      emitCriteriaArray,
      deletedCriteriaIds: this.deletedCriteriaIds,
    });
    this.$emit('close');
  }

  public groupedCriteriaTriggerHandler(index: number) {
    const searchIndex = this.groupedCriteriaCollapsed.indexOf(index);
    if (searchIndex === -1) {
      this.groupedCriteriaCollapsed.push(index);
    } else {
      this.groupedCriteriaCollapsed.splice(searchIndex, 1);
    }
  }

  public openCriteriaAddModal() {
    this.$buefy.modal.open({
      parent: this,
      component: AuditCriteriaCreateModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle: 'Create Custom Criteria',
        reOrderedCriteriaArray: this.currentCriteriaSelection,
      },
      events: {
        newCriteria: (newCriteria: any) => {
          this.customCriteriaList.push({
            ...newCriteria,
            isChanged: false,
            isCustom: true,
          });
        },
      },
    });
  }

  public compareSimilarElementsInObjects(arr1: any[], arr2: any[]) {
    arr1.sort((a, b) => b.id - a.id);
    arr2.sort((a, b) => b.id - a.id);

    // If the elements are not similar in both objects return false and vise versa
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }
    return true;
  }

  public submitSearchResult(): void {
    this.page = 1;
    this.getFilteredCplusCriterias();
  }

  @Watch('searchCriteriaTerm')
  public onSearch(): void {
    if (this.searchCriteriaTerm.length === 0) {
      this.submitSearchResult();
    }
  }

  @isTruthy
  @Watch('cplusCriterias')
  public modifyCplusCriteriaResult(newValue: CplusCriteriaResults): void {
    this.totalCount = newValue.total_count;
    this.updateCplusCriteriaDisplayResult();
  }

  @Watch('$route', { immediate: true, deep: true })
  public onUrlChange(newValue: Route): void {
    if (
      newValue.name !== 'project-create' &&
      newValue.name !== 'project-draft' &&
      newValue.name !== 'project-details' &&
      newValue.name !== 'audit'
    ) {
      this.$emit('close');
    }
  }
}
