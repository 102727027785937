
























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({ components: {} })
export default class RenameAnalysisModal extends Vue {
  @Prop()
  public currentAnalysisTitle!: string;

  public newAnalysisTitle: string = '';

  public mounted(): void {
    this.newAnalysisTitle = this.currentAnalysisTitle;
  }

  public closeModal(): void {
    this.$emit('close');
  }
  public saveAnalysis(): void {
    this.$emit('updateAnalysisTitle', this.newAnalysisTitle);
    this.$emit('close');
  }
}
