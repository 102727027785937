

































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import AuditCriteriaList from '@/views/Project/components/AuditCriteriaList.vue';
import { Action, State } from 'vuex-class';
import {
  ProjectCriteria,
  CplusCriteria,
  UpdateProjectCriteriasRequestPayload,
  ProjectDetailPayload,
  ProjectParticipantDTO,
  UpdateCriteriaDTO,
} from '@/store/modules/projects/types/projects.types';
import {
  isDifferentDeep,
  isTruthy,
} from '@/jbi-shared/util/watcher.vue-decorator';
import { isEqual as _isEqual } from 'lodash';
import { ToastProgrammatic as Toast } from 'buefy';
import JBICriteriaAcknowledgmentModal from '../components/JBICriteriaAcknowledgmentModal.vue';
import {
  JBICriteriaDTO,
  UpdatedJBICriteriaAcknowledgement,
} from '../../../store/modules/projects/types/projects.types';
import { ApiState } from '../../../store/types/general.types';
import JBICriteriaSelection from '@/views/Audit/components/JBICriteriaSelection.vue';
import { useUserEmail } from '@/utils/user.util';
import { RootState } from '@/store/store';
import { getCurrentUser } from '@/utils/project.util';

@Component({
  components: {
    JBICriteriaSelection,
    AuditCriteriaList,
  },
})
export default class ProjectCriteriaTab extends Vue {
  @Prop({ default: true })
  public editable!: boolean;
  @Prop({ default: false })
  public isUserProjectOwnerOrCoordinator!: boolean;
  @Prop()
  public projectId!: number;
  @Prop()
  public projectDetail!: ProjectDetailPayload;
  public selectedCriteriaArray: CplusCriteria[] = [];
  public isAuditCriteriaSubmitButtonDisabled = true;
  public dirtyCriteriaArray: CplusCriteria[] = [];
  public deletedCriteriaList: number[] = [];
  public updatedJbiCriteriaList: JBICriteriaDTO[] = [];
  public isProjectCriteriaAcknowledged: boolean = true;

  @Action('projects/getProjectCriteria')
  public getProjectCriteria!: (projectId: number) => void;

  @State((state) => state.projects.projectCriteria)
  public projectCriteria!: ProjectCriteria[];

  @Action('projects/updateProjectCriterias')
  public updateProjectCriterias!: (
    payload: UpdateProjectCriteriasRequestPayload,
  ) => void;

  @State((state) => state.projects.updatedProjectCriterias)
  public updatedProjectCriterias!: any;

  @Action('projects/getUpdatedJBICriteriaWithAcknowledgement')
  public getUpdatedJBICriteriaWithAcknowledgement!: (projectId: number) => void;

  @State((state) => state.projects.updatedJBICriteriaListWithAcknowledgment)
  public updatedJBICriteriaListWithAcknowledgment!: UpdatedJBICriteriaAcknowledgement;

  @State(
    (state) =>
      state.projects.apiState.getUpdatedJBICriteriaListWithAcknowledgment,
  )
  public getUpdatedJBICriteriaListWithAcknowledgmentState!: ApiState;

  @Action('projects/getProjectParticipants')
  public getProjectParticipants!: (projectId: number) => void;

  @State((state: RootState) => state.projects.participants)
  public projectParticipants!: ProjectParticipantDTO[];

  public mounted() {
    this.getProjectCriteria(this.getProjectId);
    this.getUpdatedJBICriteriaWithAcknowledgement(this.getProjectId);
  }

  get userEmail(): string | undefined {
    return useUserEmail.call(this);
  }

  get currentUser(): ProjectParticipantDTO | undefined {
    if (this.projectParticipants && this.userEmail) {
      return getCurrentUser(this.projectParticipants, this.userEmail);
    }
    return undefined;
  }

  get getProjectId(): number {
    return this.projectId;
  }

  get selectedCriteriaList() {
    return this.selectedCriteriaArray;
  }

  set selectedCriteriaList(newValue: CplusCriteria[]) {
    this.selectedCriteriaArray = newValue;
  }

  get isJbiCriteriaUpdated(): boolean {
    return (
      this.updatedJBICriteriaListWithAcknowledgment &&
      (this.updatedJBICriteriaListWithAcknowledgment.newJBICriteria.length >
        0 ||
        this.updatedJBICriteriaListWithAcknowledgment.updatedJBICriteria
          .length > 0 ||
        this.updatedJBICriteriaListWithAcknowledgment.deletedJBICriteria
          .length > 0)
    );
  }

  get updatedJBICriteriaForExistingCriteria(): JBICriteriaDTO[] {
    return this.updatedJBICriteriaListWithAcknowledgment
      ? [
          ...this.updatedJBICriteriaListWithAcknowledgment.updatedJBICriteria,
          ...this.updatedJBICriteriaListWithAcknowledgment.deletedJBICriteria,
        ]
      : [];
  }

  public openJBICriteriaAcknowledgmentModal(): void {
    const {
      projectCriteriaAcknowledgement,
      ...updatedJBICriteriaResponse
    } = this.updatedJBICriteriaListWithAcknowledgment;

    this.$buefy.modal.open({
      parent: this,
      component: JBICriteriaAcknowledgmentModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        projectId: this.projectId,
        currentUser: this.currentUser,
        updatedJBICriteriaResponse,
        projectCriteriaAcknowledgement,
      },
    });
  }

  public transformCriterias(criterias: any[]): void {
    if (!criterias) {
      return;
    }
    this.dirtyCriteriaArray = [];
    criterias.forEach((projectCriteria) => {
      if (projectCriteria.isDeleted === true) {
        return;
      }
      const transformedCriteria: CplusCriteria = {
        id: projectCriteria.id,
        title: projectCriteria.title,
        type: projectCriteria.criteriaType,
        criteriaOptions: JSON.parse(projectCriteria.criteriaOptions).filter(
          (option: any) => {
            return option !== 'N/A';
          },
        ),
        booleanOptions: JSON.parse(projectCriteria.criteriaOptions),
        range: JSON.parse(projectCriteria.criteriaOptions),
        checkboxesOptions: JSON.parse(projectCriteria.criteriaOptions),
        document: {
          id: projectCriteria.documentId,
          title: projectCriteria.documentName,
          description: projectCriteria.description,
        },
        tags: this.getTags(projectCriteria.tags),
        bestPracticeRecommendations: [],
        criteriaDetails: projectCriteria,
        order: projectCriteria.order,
        guide: projectCriteria.guide,
        sourceLink: projectCriteria.sourceLink,
        isChanged: projectCriteria.isChanged,
        config: projectCriteria.config,
      };
      if (
        this.updatedJbiCriteriaList &&
        this.updatedJbiCriteriaList.length > 0
      ) {
        this.updatedJbiCriteriaList.forEach(
          (updatedJbiCriteria: JBICriteriaDTO) => {
            if (
              updatedJbiCriteria.connectPlusCriteriaId ===
              +projectCriteria.connectPlusId
            ) {
              transformedCriteria.updatedJbiCriteria = updatedJbiCriteria;
            }
          },
        );
      }
      this.dirtyCriteriaArray.push(transformedCriteria);
    });
    this.selectedCriteriaArray = this.dirtyCriteriaArray;
  }

  @Watch('projectCriteria')
  @isTruthy
  public watchProjectCriteria(): void {
    this.transformCriterias(this.projectCriteria);
  }

  @Watch('getUpdatedJBICriteriaListWithAcknowledgmentState')
  @isTruthy
  public watchUpdatedJbiCriteriaResponse(): void {
    this.updatedJbiCriteriaList = this.updatedJBICriteriaForExistingCriteria;
    this.transformCriterias(this.projectCriteria);

    if (
      this.updatedJBICriteriaListWithAcknowledgment &&
      this.updatedJBICriteriaListWithAcknowledgment
        .projectCriteriaAcknowledgement
    ) {
      // tslint:disable-next-line:max-line-length
      this.isProjectCriteriaAcknowledged = this.updatedJBICriteriaListWithAcknowledgment.projectCriteriaAcknowledgement.isAcknowledged;
    }
  }

  public getTags(tagsInfo: any) {
    const tags: string[] = [];
    if (tagsInfo === undefined) {
      return tags;
    }
    Object.keys(tagsInfo).forEach((key: string) => {
      const tag = tagsInfo[key];
      tag.forEach((tagVal: any) => {
        tags.push(tagVal.tag);
      });
    });
    return tags;
  }

  @Watch('selectedCriteriaArray')
  @isTruthy
  @isDifferentDeep
  public watchSelectedCriteriaArray(newValue: any) {
    this.isAuditCriteriaSubmitButtonDisabled = _isEqual(
      this.dirtyCriteriaArray,
      newValue,
    );
  }

  @Watch('updatedProjectCriterias')
  @isTruthy
  public watchUpdatedProjectCriterias() {
    this.getProjectCriteria(this.getProjectId);
    this.getUpdatedJBICriteriaWithAcknowledgement(this.getProjectId);
    Toast.open({
      message: `Criteria successfully updated.`,
      position: 'is-top',
      type: 'is-dark',
      duration: 3500,
    });
  }

  public openJBICriteriaSelectionTemplate() {
    this.$buefy.modal.open({
      parent: this,
      component: JBICriteriaSelection,
      fullScreen: true,
      hasModalCard: false,
      trapFocus: true,
      animation: 'unset',
      props: {
        projectName: this.getProjectTitle,
        isProjectLevel: true,
        selectedCriteriaArray: this.selectedCriteriaArray,
      },
      events: {
        updateSelectedCriteriaArray: (newValue: UpdateCriteriaDTO) => {
          this.selectedCriteriaArray = newValue.emitCriteriaArray;
          this.deletedCriteriaList = newValue.deletedCriteriaIds;
          this.updateProjectCriteriaHandler();
        },
      },
    });
  }

  get getProjectTitle(): string {
    return this.projectDetail.title;
  }

  public handleDeletedCriteria(criteria: any) {
    if (criteria.criteriaDetails) {
      this.deletedCriteriaList.push(criteria.criteriaDetails.id);
    }
  }

  public updateProjectCriteriaHandler() {
    this.updateProjectCriterias({
      projectId: this.getProjectId,
      criterias: this.selectedCriteriaArray,
      deletedCriteriaIds: this.deletedCriteriaList,
    });
  }
}
