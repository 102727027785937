




























































import { Component, Prop, Watch } from 'vue-property-decorator';
import { ToastProgrammatic as Toast } from 'buefy';
import { Action, State } from 'vuex-class';
import {
  GripBarrier,
  GripBarrierDTO,
  UpdateGripBarrierBase,
  UpdateGripBarrierPayload,
} from '../../../store/types/grip.types';
import { ApiState } from '../../../store/types/general.types';
import BarrierModal from './BarrierModal.vue';
import BasePagination from '../../../components/base/BasePagination.vue';
import DeleteModalBox from '../../../components/DeleteModalBox.vue';
import { isTruthy } from '../../../jbi-shared/util/watcher.vue-decorator';
import { PaginationMixin } from '../../../components/base/pagination.mixin';
import { mixins } from 'vue-class-component';
import { getUpperCase } from '../../../utils/general.util';

@Component({
  components: { BasePagination, BarrierModal, DeleteModalBox },
})
export default class BarrierList extends mixins(PaginationMixin) {
  @Prop() public items!: GripBarrier[];
  @Prop(Number) public totalNumberOfPage!: number;
  @Prop(Number) public totalCount!: number;
  @Prop(Boolean) public isFirstPage!: boolean;
  @Prop(Boolean) public isLastPage!: boolean;
  @Prop(Number) public startItemIndex!: number;
  @Prop(Number) public endItemIndex!: number;
  public modalKey: number = Math.floor(Math.random() * 999);
  public isModalActive: boolean = false;
  public gripBarrierDetails: GripBarrierDTO | null = null;

  @Action('projects/updateGripBarrier')
  public updateGripBarrier!: (payload: UpdateGripBarrierPayload) => void;

  @State((state) => state.projects.apiState.updateGripBarrier)
  public updateGripBarrierApiState!: ApiState;

  @Action('projects/deleteGripBarrier')
  public deleteGripBarrier!: (payload: {
    projectId: number;
    barrierId: number;
  }) => void;

  @State((state) => state.projects.apiState.deleteGripBarrier)
  public deleteGripBarrierApiState!: ApiState;

  get projectId(): number {
    return +this.$route.params.projectId;
  }

  public getUpperCase(value: string) {
    return getUpperCase(value);
  }

  public closeBarrierModal(): void {
    this.isModalActive = false;
  }

  public handleBarrierUpdate(gripBarrier: UpdateGripBarrierBase): void {
    this.updateGripBarrier({ ...gripBarrier, projectId: this.projectId });
    this.closeBarrierModal();
  }

  public handleEdit(barrier: GripBarrier): void {
    const { project, status, createdDate, updatedDate, ...details } = barrier;

    this.gripBarrierDetails = details as GripBarrierDTO;
    this.isModalActive = true;
    this.modalKey += 1;
  }

  public handleDelete(barrierId: number) {
    this.$buefy.modal.open({
      parent: this,
      component: DeleteModalBox,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle: 'Delete Barrier?',
        modalContent:
          'Are you sure you want to delete this barrier? This action cannot be undone.',
      },
      events: {
        close: () => {
          this.$emit('close');
        },
        confirm: () => {
          this.deleteGripBarrier({ projectId: this.projectId, barrierId });
        },
      },
    });
  }

  @Watch('updateGripBarrierApiState.success')
  @isTruthy
  public onUpdateBarrierSuccess(): void {
    this.$emit('updateList');
    Toast.open({
      message: `Barrier updated`,
      position: 'is-top',
      type: 'is-dark',
      duration: 3500,
    });
  }

  @Watch('updateGripBarrierApiState.error')
  public onUpdateBarrierError(): void {
    this.$emit('close');
  }

  @Watch('deleteGripBarrierApiState.success')
  @isTruthy
  public onDeleteBarrierSuccess() {
    this.$emit('updateList');
    Toast.open({
      message: `Barrier deleted`,
      position: 'is-top',
      type: 'is-dark',
      duration: 3500,
    });
  }
}
