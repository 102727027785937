



































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Action} from 'vuex-class';

@Component({ components: {}})

export default class DeleteSiteModal extends Vue {
    public exit() {
        this.$emit('exit');
        this.$emit('close');
    }
    public closeModal() {
        this.$emit('close');
    }
    public save() {
        this.$emit('save');
        this.$emit('close');
    }
}
