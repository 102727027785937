var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"block"},[_c('div',{class:['box is-paddingless', _vm.$style.userListContainer]},[_c('table',{staticClass:"table is-fullwidth"},[_c('thead',[_c('tr',[_c('th',{attrs:{"width":"35%"}},[_vm._v(" Project Name "),(
                _vm.filteredParams.orderBy.field === 'title' &&
                _vm.filteredParams.orderBy.sort === 'DESC'
              )?_c('b-icon',{class:_vm.$style.orderBy,attrs:{"icon":"arrow-up","size":"is-small"},nativeOn:{"click":function($event){return _vm.updateSort('title', 'ASC')}}}):_c('b-icon',{class:_vm.$style.orderBy,attrs:{"icon":"arrow-down","size":"is-small"},nativeOn:{"click":function($event){return _vm.updateSort('title', 'DESC')}}})],1),_c('th',{attrs:{"width":"15%"}},[_vm._v("Project Status")]),_c('th',{attrs:{"width":"30%"}},[_vm._v(" Administrator "),(
                _vm.filteredParams.orderBy.field === 'administrator' &&
                _vm.filteredParams.orderBy.sort === 'DESC'
              )?_c('b-icon',{class:_vm.$style.orderBy,attrs:{"icon":"arrow-up","size":"is-small"},nativeOn:{"click":function($event){return _vm.updateSort('administrator', 'ASC')}}}):_c('b-icon',{class:_vm.$style.orderBy,attrs:{"icon":"arrow-down","size":"is-small"},nativeOn:{"click":function($event){return _vm.updateSort('administrator', 'DESC')}}})],1),_c('th',{attrs:{"width":"15%"}},[_vm._v("Administrator Status")])])]),_c('tbody',_vm._l((_vm.items),function(item){return _c('tr',{key:item.id},[_c('td',{class:_vm.$style.projectTitleCell},[_vm._v(_vm._s(item.title || '-'))]),_c('td',[_vm._v(_vm._s(item.status || '-'))]),_c('td',{class:_vm.$style.projectAdminCell},[(item.administratorName.length > 50)?_c('div',[_c('b-tooltip',{attrs:{"label":item.administratorName,"position":"is-top","size":"is-small"}},[(item.given_name.length + item.family_name.length > 35)?_c('p',[_c('b',[_vm._v(_vm._s(_vm.truncateAdministratorName(item, true)))])]):_c('p',[_c('b',[_vm._v(" "+_vm._s(item.given_name)+" ")]),_c('b',[_vm._v(" "+_vm._s(item.family_name)+" ")]),_vm._v(" ("+_vm._s(_vm.truncateAdministratorName(item, false))+" ")])])],1):_c('div',[_c('p',{class:_vm.$style.projectAdminNameCell},[_vm._v(" "+_vm._s(item.given_name)+" "+_vm._s(item.family_name)+" ")]),_c('p',{class:_vm.$style.projectAdminEmailCell},[_vm._v("("+_vm._s(item.email)+")")])])]),_c('td',[_vm._v(_vm._s(_vm.getSubscriptionStatus(item) || '-'))]),_c('td',[[_c('TableDropdown',[_c('b-dropdown-item',{attrs:{"aria-role":"listitem","disabled":item.disableChangeAdministration,"title":item.disableChangeAdministration
                      ? _vm.unAvailableParticipantTooltip
                      : undefined},on:{"click":function($event){return _vm.handleChangeProjectAdministrator(item)}}},[_vm._v(" Change Project Administrator ")]),_c('b-dropdown-item',{attrs:{"aria-role":"listitem","disabled":item.disableProjectHistory},on:{"click":function($event){return _vm.handleProjectOwnerHistory(item)}}},[_vm._v(" View Project Ownership History ")])],1)]],2)])}),0),(_vm.items && _vm.items.length === 0)?_c('tfoot',[_vm._m(0)]):_vm._e()])]),(_vm.items && _vm.items.length > 0)?_c('BasePagination',{attrs:{"page":_vm.page,"perPage":_vm.perPage,"totalNumberOfPage":_vm.totalNumberOfPage,"totalCount":_vm.totalCount,"isFirstPage":_vm.isFirstPage,"isLastPage":_vm.isLastPage,"startItemIndex":_vm.startItemIndex,"endItemIndex":_vm.endItemIndex},on:{"update:page":function($event){_vm.page=$event},"update:perPage":function($event){_vm.perPage=$event},"update:per-page":function($event){_vm.perPage=$event}}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{staticClass:"is-paddingless",attrs:{"colspan":"100%"}},[_c('p',{staticClass:"p-2 has-text-centered"},[_vm._v("No Data Found")])])])}]

export { render, staticRenderFns }