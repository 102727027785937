import { AuditsState } from './types/audits.types';
import { defaultApiState } from '@/store/types/general.types';

export const auditsState: AuditsState = {
  apiState: {
    getAuditDetail: defaultApiState,
    updateSingleSiteSampling: defaultApiState,
    updateMultiSiteSampling: defaultApiState,
    getDataCollectionSummary: defaultApiState,
    postDataCollection: defaultApiState,
    getDataCollectionActivityLogs: defaultApiState,
    startAudit: defaultApiState,
    endAudit: defaultApiState,
    resumeAudit: defaultApiState,
    updateAuditCriterias: defaultApiState,
    updateAuditCriteriaById: defaultApiState,
    updateAuditSites: defaultApiState,
    deleteDataRecord: defaultApiState,
    updateAuditGeneralSettings: defaultApiState,
    updateAuditDataCollectionSettings: defaultApiState,
    getAuditDetailAndSettings: defaultApiState,
    getAuditCriteria: defaultApiState,
    getAuditSites: defaultApiState,
    getAuditConfig: defaultApiState,
    getDataCollectionRecord: defaultApiState,
    getDashboardChartData: defaultApiState,
    getParticipants: defaultApiState,
    deleteParticipant: defaultApiState,
    createParticipant: defaultApiState,
    updateParticipant: defaultApiState,
    resendInvitation: defaultApiState,
    getPaginatedEventLogs: defaultApiState,
    updateStatus: defaultApiState,
    updateAuditGrip: defaultApiState,
    createAuditGripBarrierModal: defaultApiState,
    updateAuditGripBarrierModal: defaultApiState,
    deletedGripBarrierRecord: defaultApiState,
    createAuditGripEnablerModal: defaultApiState,
    updateAuditGripEnablerModal: defaultApiState,
    deletedGripEnablerRecord: defaultApiState,
    createAuditGripStrategyModal: defaultApiState,
    updateAuditGripStrategyModal: defaultApiState,
    deletedGripStrategyRecord: defaultApiState,
    getAuditGrip: defaultApiState,
    getAuditGripBarrierModal: defaultApiState,
    getAuditGripEnablerModal: defaultApiState,
    getAuditGripStrategyModal: defaultApiState,
    getUpdatedAuditJBICriteriaList: defaultApiState,
    getAuditReportAnalysis: defaultApiState,
    getTotalAuditReportAnalysis: defaultApiState,
    updateAuditReportAnalysis: defaultApiState,
    deleteAuditReportAnalysis: defaultApiState,
  },
  participants: undefined,
  auditDetail: undefined,
  updatedSingleSiteSampling: undefined,
  updatedMultiSiteSampling: undefined,
  dataCollectionSummary: undefined,
  dataCollectionResponse: undefined,
  dataCollectionActivityLogs: undefined,
  updatedAuditCriterias: undefined,
  updatedAuditCriteriaById: undefined,
  updatedAuditSites: undefined,
  deletedDataRecord: undefined,
  updatedAuditGeneralSettings: undefined,
  updatedAuditDataCollectionSettings: undefined,
  auditDetailAndSettings: undefined,
  auditCriteria: undefined,
  auditSites: undefined,
  auditConfig: undefined,
  dataCollectionRecord: undefined,
  dashboardChartData: undefined,
  paginatedEventLogs: undefined,
  updatedStatus: undefined,
  updateAuditGrip: undefined,
  createAuditGripBarrier: undefined,
  updateAuditGripBarrier: undefined,
  deletedGripBarrierRecord: undefined,
  createAuditGripEnabler: undefined,
  updateAuditGripEnabler: undefined,
  deletedGripEnablerRecord: undefined,
  createAuditGripStrategy: undefined,
  updateAuditGripStrategy: undefined,
  deletedGripStrategyRecord: undefined,
  auditGrip: undefined,
  auditGripBarrier: undefined,
  auditGripEnabler: undefined,
  auditGripStrategy: undefined,
  dataCollectionPeriodTitle: undefined,
  updatedAuditJBICriteriaList: undefined,
  auditReportAnalysis: undefined,
  totalAuditReportAnalysis: undefined,
  updatedAuditReportAnalysis: undefined,
  deletedAuditReportAnalysis: undefined,
};
