import { projectsState } from './projects.state';
import { projectsActions } from './projects.actions';
import { projectsMutations } from './projects.mutations';
import { projectsGetters } from './projects.getters';
import { ModuleTree } from 'vuex';
import { RootState } from '@/store/store';

export default {
  namespaced: true,
  state: projectsState,
  getters: projectsGetters,
  actions: projectsActions,
  mutations: projectsMutations,
} as ModuleTree<RootState>;
