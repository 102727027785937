











































































































import { Site } from '@/store/modules/site/types/site.types';
import { RootState } from '@/store/store';
import { cloneDeep as _cloneDeep } from 'lodash';
import { Component, Vue, Prop, Watch, Model } from 'vue-property-decorator';
import { State, Getter, Action, Mutation, namespace } from 'vuex-class';
import {
  ParticipantSite,
  ProjectParticipantDataTrfObj,
} from '../../../../store/modules/projects/types/projects.types';
import CreateSiteForm from './CreateSiteForm.vue';
import BaseDialog from '@/components/base/BaseDialog.vue';

@Component({
  components: {},
})
export default class SelectSite extends Vue {
  @Action('projects/getSites')
  public getSites!: () => void;

  @State((state: RootState) => state.projects.sites)
  public sites!: Site[];

  @State((state: RootState) => state.projects.apiState.getProjectDetail.success)
  public onSuccess!: boolean;

  // to prevent participant selected site been remove
  @Prop()
  public participants!: ProjectParticipantDataTrfObj[];

  @Prop()
  public value!: Site[];

  public get selectedSites() {
    return this.value;
  }

  public set selectedSites(value) {
    this.$emit('input', value);
  }

  public get siteOptions() {
    const data = this.sites || [];
    return data.filter((site) => {
      return !this.selectedSites.find(({ id }) => id === site.id);
    });
  }

  public toggled(index: number, value: boolean) {
    const selectedSites = _cloneDeep(this.selectedSites);
    selectedSites[index] = {
      ...selectedSites[index],
      isIdentifierRequired: !value,
    };
    this.$emit('input', selectedSites);
  }

  public async mounted() {
    this.getSites();
  }

  public handleSiteChange(index: number, value: Site) {
    if (this.hasAssoicatedSite(index)) {
      return;
    }
    const selectedSites = _cloneDeep(this.selectedSites);
    selectedSites[index] = value;
    this.$emit('input', selectedSites);
  }

  public handleRemoveSite(index: number) {
    if (this.hasAssoicatedSite(index)) {
      return;
    }

    if (!Boolean(this.selectedSites[index].name)) {
      return this.selectedSites.splice(index, 1);
    }

    this.$buefy.modal.open({
      parent: this,
      component: BaseDialog,
      hasModalCard: true,
      trapFocus: true,
      props: {
        title: 'Remove Site',
        content: `You are trying to remove ${this.selectedSites[index].name}. Removing this site will also remove it from corresponding participants.`,
      },
      events: {
        confirm: () => {
          this.selectedSites.splice(index, 1);
        },
      },
    });
  }

  public handleAppendSite() {
    // @ts-ignore
    this.selectedSites.push('');
  }

  public handleCreate() {
    this.$buefy.modal.open({
      parent: this,
      props: {},
      events: {
        submit: (newSite: Site) => {
          // remove replace prepend empty string if create new site one a empty drop down list
          const length = this.selectedSites.length || 0;
          if (length > 0 && !this.selectedSites[length - 1]) {
            this.selectedSites.pop();
            this.selectedSites.push(newSite);
          }
        },
      },
      component: CreateSiteForm,
      hasModalCard: true,
    });
  }

  public hasAssoicatedSite(index: number): boolean {
    // prevent delete or change site that already assigned to participant
    const siteWithParticipant = this.participants.find((participant) => {
      return (
        !participant.isDeleted &&
        (participant.participantSites as ParticipantSite[]).find(
          (participantSite: ParticipantSite) =>
            participantSite.site.id === this.selectedSites[index].id,
        )
      );
    });
    if (siteWithParticipant) {
      this.$buefy.toast.open({
        type: 'is-danger',
        position: 'is-top',
        message: `Can't change or delete the site that already assigned to participant!`,
      });
    }
    return siteWithParticipant ? true : false;
  }

  @Watch('selectedSites', { immediate: true })
  public onSitesEmpty(value: Site[]): void {
    if (value.length === 0) {
      this.handleAppendSite();
    }
  }
}
