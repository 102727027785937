



















import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {Site} from '@/store/modules/projects/types/projects.types';
import {filter as _filter, cloneDeep as _cloneDeep} from 'lodash';
import ParticipantInputs from '@/views/Project/components/ParticipantInputs.vue';

@Component({ components: {
        ParticipantInputs,
    },
})
export default class ParticipantSettings extends Vue {
    @Prop()
    public selectedSiteArray !: Site[];

    @Prop()
    public selectedParticipantArray !: any[];

    public localParticipantList: any[] = [];
    public renderComponent: boolean = true;

    public mounted() {
        if (this.selectedParticipantArray !== undefined && this.selectedParticipantArray.length > 0) {
            this.localParticipantList = _cloneDeep(this.selectedParticipantArray);
        } else {
            this.addParticipantCard();
        }
    }

    public addParticipantCard() {
        this.localParticipantList.push({
            email: '',
            role: '',
            sites: [],
        });
    }

    public handleInviteUserArray(newValue: any) {
        this.localParticipantList = this.sanitizeParticipantList(newValue);
        this.$emit('update:selectedParticipantArray', this.localParticipantList);
    }

    public sanitizeParticipantList(arr: any[]) {
        return _filter(arr, (participant) => {
            return participant.email.trim()
            && participant.role.trim()
            && participant.sites
                ? true
                : false;
        });
    }

    public handleRemoveSelectedParticipant(index: number) {
        this.renderComponent = false;
        this.localParticipantList.splice(index, 1);
        this.$nextTick(() => {
            this.renderComponent = true;
        });
        this.$emit('update:selectedParticipantArray', this.localParticipantList);
    }
}
