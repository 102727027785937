

















































































































































import { Component, Vue, Prop, Watch, Model } from 'vue-property-decorator';
import { State, Getter, Action, Mutation } from 'vuex-class';
import {
  AuditSite,
  CriteriaSamplingData,
} from '../../store/modules/audits/types/audits.types';
import { cloneDeep as _cloneDeep, map as _map, get as _get } from 'lodash';
import {
  getAdHocSampling,
  getConsecutiveMinMaxSampling,
  getDefaultSampling,
} from '../../components/reports/utils/ReportSampling.util';

import { ReportSamplingDto } from '../../store/modules/projects/types/projects.types';

const dirtyMonths: string[] = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

@Component({
  components: {},
})
export default class MultiSiteTable extends Vue {
  @Prop()
  public source!: any[];
  @Prop()
  public individualSiteMode!: boolean;
  @Prop()
  public filteredSites!: number[];
  @Prop({ default: 0 })
  public sampleSize!: number;
  @Prop()
  public siteList!: AuditSite[];
  @Prop({ default: true })
  public showComplianceRate!: boolean;
  @Prop()
  public siteSamplingConfiguration!: any[];
  @Prop()
  public samplingConfiguration!: any;
  @Prop()
  public criteriaSamplingDataConfiguration!: any;
  @Prop()
  public selectedPeriod!: any;
  @Prop({ default: false })
  public auditReport!: boolean;
  @Prop({ default: true })
  public isAuditCriteria!: boolean;

  public siteName: string = 'All Sites';
  public showIndividualSites: boolean = false;

  public mounted() {
    if (this.individualSiteMode) {
      this.showIndividualSites = true;
    }
    switch (this.filteredSites.length) {
      case 1:
        this.siteName = this.getName;
        break;
      case this.siteList.length:
        this.siteName = 'All Sites';
        break;
      default:
        this.siteName = 'Selected sites (' + this.filteredSites.length + ')';
    }
  }

  public formatDateRange(startedAt: string, endedAt: any): string {
    if (!endedAt) {
      endedAt = new Date();
    }
    return (
      this.getDate(new Date(startedAt)) +
      ' - ' +
      this.getDate(new Date(endedAt))
    );
  }

  public getDate(newValue: Date): string {
    return (
      newValue.getDate() +
      ' ' +
      dirtyMonths[newValue.getMonth()] +
      ' ' +
      newValue.getFullYear()
    );
  }

  get items(): any[] {
    if (Array.isArray(this.source)) {
      return this.source;
    }
    return [];
  }

  get getKeys(): string[] {
    if (this.source[0].criteriaOptionsDataDistribution) {
      const keys: string[] = Object.keys(
        this.source[0].criteriaOptionsDataDistribution,
      );
      return keys;
    }
    return [];
  }

  public getData(keyValue: string): any[] {
    const data: any[] = [];
    this.source.forEach((value) => {
      if (value.criteriaOptionsDataDistribution) {
        const dataValue: string =
          this.criteriaSamplingDataConfiguration.length &&
          value.criteriaOptionsDataDistribution[keyValue] !== undefined
            ? value.criteriaOptionsDataDistribution[keyValue]
            : '-';
        data.push(dataValue);
      }
    });
    return data;
  }

  get getName(): string {
    const selectedSite: AuditSite[] = this.siteList.filter((site) => {
      return this.filteredSites.includes(site.id);
    });
    return selectedSite[0].site.name;
  }

  get getSiteName(): string[] {
    const siteName: string[] = [];
    _map(this.siteList, (site: AuditSite) => {
      if (this.filteredSites.includes(site.id)) {
        siteName.push(site.site.name);
      }
    });
    return siteName;
  }

  get getTotalData(): string[] {
    const total: string[] = [];
    this.source.forEach((value) => {
      const totalValue: string = this.siteSamplingConfiguration.length
        ? value.total
        : '-';
      total.push(totalValue);
    });
    return total;
  }

  public getSelectedSiteDetails(sites: AuditSite[]): AuditSite[] {
    return sites.filter((siteDetail) =>
      this.filteredSites.includes(siteDetail.id),
    );
  }

  get getSamplingAggregate() {
    const samplingRows: any[] = [];
    switch (this.samplingConfiguration.auditSamplingType) {
      case 'adHoc':
        const dirtyAdHocSamplingRows: ReportSamplingDto[] = getAdHocSampling(
          this.selectedPeriod.isAudit || this.auditReport,
          this.isAuditCriteria,
          this,
          this.getSelectedSiteDetails(this.siteList),
        );
        samplingRows.push(...dirtyAdHocSamplingRows);

        break;
      case 'consecutive':
        if (this.samplingConfiguration.samplingMode === 'minAndMax') {
          const dirtyConsecutiveMinMaxSamplingRows: ReportSamplingDto[] = getConsecutiveMinMaxSampling(
            this.selectedPeriod.isAudit || this.auditReport,
            this.isAuditCriteria,
            this,
            this.getSelectedSiteDetails(this.siteList),
          );
          samplingRows.push(...dirtyConsecutiveMinMaxSamplingRows);
        } else {
          const dirtyDefaultConsecutiveSamplingRowsData: ReportSamplingDto[] = getDefaultSampling(
            this.selectedPeriod.isAudit || this.auditReport,
            this.isAuditCriteria,
            this,
            this.getSelectedSiteDetails(this.siteList),
          );
          samplingRows.push(...dirtyDefaultConsecutiveSamplingRowsData);
        }
        break;
      default:
        const dirtyDefaultSamplingRowsData: ReportSamplingDto[] = getDefaultSampling(
          this.selectedPeriod.isAudit || this.auditReport,
          this.isAuditCriteria,
          this,
          this.getSelectedSiteDetails(this.siteList),
          this.samplingConfiguration.samplingMode === 'siteBased',
        );
        samplingRows.push(...dirtyDefaultSamplingRowsData);
    }
    return samplingRows;
  }

  get getCompliance(): string[] {
    const compliance: any[] = [];
    const keyNA = 'N/A';
    this.source.forEach((value) => {
      if (value.criteriaOptionsDataDistribution) {
        const keyYes = Object.keys(value.criteriaOptionsDataDistribution)[0];
        const yes = value.criteriaOptionsDataDistribution[keyYes];
        const na = value.criteriaOptionsDataDistribution[keyNA];
        const total = value.total;
        if (yes === 0) {
          const complianceValue: string = this.siteSamplingConfiguration.length
            ? '0%'
            : '-';
          compliance.push(complianceValue);
        } else {
          const eachCompliance: number = (yes / (total - na)) * 100;
          compliance.push(Math.round(eachCompliance * 100) / 100 + '%');
        }
      }
    });
    compliance.forEach((comp, compIndex) => {
      if (comp === 'NaN%') {
        compliance[compIndex] = '-';
      }
    });
    return compliance;
  }

  get totalCollected(): string {
    let sum: number = 0;
    let sumNa: string = '';
    this.items.map((value) => {
      if (Object.values(value)[0] === '-') {
        return (sumNa = '-');
      } else {
        return (sum += Number(Object.values(value)[0]));
      }
    });
    if (this.criteriaSamplingDataConfiguration.length === 0) {
      sumNa = '-';
    }
    if (sumNa === '-') {
      return sumNa;
    } else {
      return sum.toString();
    }
  }

  get totalNACollected(): number {
    let sum: number = 0;
    this.items.map((value, index) => {
      if (index === 2) {
        return (sum += Number(Object.values(value)[0]));
      }
    });
    return sum;
  }

  get complianceRate(): string {
    const positive: any = this.items[0];
    const positiveKey: string = Object.keys(positive)[0];
    if (positive[positiveKey] === 0) {
      return this.criteriaSamplingDataConfiguration.length ? '0%' : '-';
    }
    const rate: number =
      (positive[positiveKey] /
        (Number(this.totalCollected) - this.totalNACollected)) *
        100 || 0;
    if (!rate) {
      return '-';
    }
    return `${rate.toFixed(2)}%`;
  }

  get getSamplingTitles() {
    const samplingTitles: string[] = [];
    samplingTitles.push('Site');
    switch (this.samplingConfiguration.auditSamplingType) {
      case 'adHoc':
        samplingTitles.push('Sampling');
        break;
      case 'consecutive':
        if (this.selectedPeriod.isAudit || this.auditReport) {
          if (this.samplingConfiguration.samplingMode === 'minAndMax') {
            samplingTitles.push('Min.');
            samplingTitles.push('Max.');
          } else {
            samplingTitles.push('Target');
          }
        } else {
          samplingTitles.push('Sampling');
        }
        break;
      default:
        if (this.selectedPeriod.isAudit || this.auditReport) {
          samplingTitles.push('Target');
        } else {
          samplingTitles.push('Sampling');
        }
    }
    return samplingTitles;
  }
}
