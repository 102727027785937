


















































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { cloneDeep as _cloneDeep, get as _get, map as _map } from 'lodash';
import Card from '@/components/Card.vue';
import SingleSiteComparisonTable from '@/components/reports/SingleSiteComparisonTable.vue';
import VerticalBarChart from '@/components/reports/VerticalBarChart';
import HorizontalBarChart from '@/components/reports/HorizontalBarChart';
import {
  ChartRecords,
  ChartType,
  ProjectCriteria,
  ProjectSite,
  ProjectReportFiltersConfig,
  ProjectReportSinglePeriodResponse,
  ProjectReportMultiplePeriodResponse,
  ProjectReportPeriod,
} from '@/store/modules/projects/types/projects.types';
import { CRITERION_TYPE } from '@/store/types/criterions.types';
import {
  chartColorSet,
  primaryChartColor,
  labelSplitIndex,
} from '@/store/types/general.types';
import dayjs from 'dayjs';
import CriteriaViewUpdateComponent from './CriteriaViewUpdateComponent.vue';

const dirtyMonths: string[] = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

@Component({
  components: {
    Card,
    SingleSiteComparisonTable,
    VerticalBarChart,
    HorizontalBarChart,
    CriteriaViewUpdateComponent,
  },
})
export default class SingleSiteComparisonReport extends Vue {
  @Prop()
  public analysisFilter!: any;
  @Prop()
  public projectCriteriaList!: ProjectCriteria[];
  @Prop()
  public projectSitelist!: ProjectSite[];
  @Prop()
  public comparisonReportResponse!: ProjectReportMultiplePeriodResponse;

  public dataSet: any[] = [];
  public dataSetOptions: any[] = [];
  public loadingSet: boolean[] = [];
  public verticalBarChartDataSetOption: any = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            max: 100,
            min: 0,
          },
          gridLines: {
            display: true,
          },
          scaleLabel: {
            labelString: 'Compliance %',
            display: true,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: true,
          },
          scaleLabel: {
            labelString: 'Criteria',
            display: false,
          },
          ticks: {
            minRotation: 0,
            callback: (label: string, index: number) => {
              return this.generateLabel(label, index);
            },
          },
        },
      ],
    },
    legend: {
      display: true,
      onHover: (e: any) => {
        e.target.style.cursor = 'pointer';
      },
      onLeave: (e: any) => {
        e.target.style.cursor = 'default';
      },
      labels: {
        boxWidth: 15,
      },
      align: 'start',
    },
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: (tooltipItem: any, data: any) => {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';
          if (label) {
            label += ': ';
          }
          if (tooltipItem.yLabel < 0) {
            label += 'N/A';
          } else {
            label += Math.round(tooltipItem.yLabel * 100) / 100;
          }
          return label;
        },
      },
    },
  };
  public horizontalBarChartDataSetOption: any = {
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            max: 100,
            min: 0,
          },
          gridLines: {
            display: true,
          },
          scaleLabel: {
            labelString: 'Compliance %',
            display: true,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: true,
          },
          scaleLabel: {
            labelString: 'Criteria',
            display: false,
          },
          ticks: {
            minRotation: 0,
            callback: (label: string, index: number) => {
              return this.generateLabel(label, index);
            },
          },
        },
      ],
    },
    legend: {
      display: true,
      onHover: (e: any) => {
        e.target.style.cursor = 'pointer';
      },
      onLeave: (e: any) => {
        e.target.style.cursor = 'default';
      },
      labels: {
        boxWidth: 15,
      },
      align: 'start',
    },
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: (tooltipItem: any, data: any) => {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';
          if (label) {
            label += ': ';
          }
          if (tooltipItem.xLabel < 0) {
            label += 'N/A';
          } else {
            label += Math.round(tooltipItem.xLabel * 100) / 100;
          }
          return label;
        },
      },
    },
  };
  public comparisonIndividualBooleanCriteriaConfiguration: any[] = [];
  public comparisonIndividualMixedCriteriaConfiguration: any[] = [];
  public comparisonAggregateCriteriaConfiguration: any[] = [];
  public criteriaNames: string[] = [];
  public criteriaList: ProjectCriteria[] = [];
  public renderOverallStatisticTable: boolean = true;
  public periodsInfo: ProjectReportPeriod[] = [];

  public mounted() {
    this.populateData();
  }

  public populateData() {
    this.periodsInfo = _cloneDeep(this.analysisFilter.comparisonPeriods);
    const transformedComparisonPeriods: Date[][] = [];
    this.analysisFilter.comparisonPeriods.forEach((comparisonPeriod: any) => {
      const transformedSelectedPeriod: Date[] = [];
      transformedSelectedPeriod.push(new Date(comparisonPeriod.startedAt));
      if (comparisonPeriod.endedAt === null) {
        transformedSelectedPeriod.push(new Date(dayjs().endOf('day').format()));
      } else {
        transformedSelectedPeriod.push(new Date(comparisonPeriod.endedAt));
      }
      transformedComparisonPeriods.push(transformedSelectedPeriod);
    });
    this.analysisFilter.comparisonPeriods = _cloneDeep(
      transformedComparisonPeriods,
    );
    if (!this.comparisonReportResponse.success) {
      this.dataSet = [];
      this.dataSetOptions = [];
      this.loadingSet = [];
      this.comparisonIndividualBooleanCriteriaConfiguration = [];
      this.comparisonIndividualMixedCriteriaConfiguration = [];
    } else {
      this.dataSet = [];
      this.dataSetOptions = [];
      this.loadingSet = [];
      this.comparisonIndividualBooleanCriteriaConfiguration = [];
      this.comparisonIndividualMixedCriteriaConfiguration = [];
      const chartData: ChartRecords[][] = [];
      const statisticData: any[][] = [];
      const samplingConfigurationData: any[] = [];
      _map(
        this.comparisonReportResponse.comparisonResult,
        (comparisonResult: ProjectReportSinglePeriodResponse) => {
          const dirtyChartData = comparisonResult.chartData;
          const dirtyStatisticData =
            comparisonResult.statisticData.configurationData;
          if (dirtyChartData.length > 0) {
            chartData.push(dirtyChartData);
          }
          statisticData.push(dirtyStatisticData);
          samplingConfigurationData.push(
            comparisonResult.statisticData.samplingConfiguration,
          );
        },
      );
      this.generateComparisonConfigurationData(
        statisticData,
        samplingConfigurationData,
      );
      if (chartData.length > 0) {
        this.singleSiteComparisonRender(chartData);
      }
    }
  }

  public singleSiteComparisonRender(chartData: ChartRecords[][]) {
    if (this.analysisFilter.checkIndividualCriteria) {
      this.renderComparisonIndividualCriteria(chartData);
    } else {
      this.renderComparisonAggregateCriteria(chartData);
    }
  }

  public renderComparisonIndividualCriteria(chartData: ChartRecords[][]) {
    const siteName: string = chartData[0][0].data[0].site.name;
    this.criteriaNames = [];
    const booleanFilteredCriteriaIds: number[] = this.getBooleanCriteriaMapIds;
    _map(booleanFilteredCriteriaIds, (criteriaId: number) => {
      let criteriaName = '';
      _map(this.projectCriteriaList, (projectCriteria: ProjectCriteria) => {
        if (projectCriteria.id === criteriaId) {
          criteriaName = projectCriteria.title;
          this.criteriaNames.push(projectCriteria.title);
          this.criteriaList.push(projectCriteria);
        }
      });
      const data = {
        labels: [] as string[],
        datasets: [] as any[],
      };
      let dataOptions = {};
      let dataOptionsObject: any = {};
      Object.keys(chartData).forEach((key: string) => {
        const chartValues: number[] = [];
        const backgroundColors: string[] = [];
        const singleChartData = chartData[Number(key)];
        Object.keys(singleChartData).map((dataKey: string) => {
          if (
            singleChartData[Number(dataKey)].projectCriteriaId === criteriaId
          ) {
            chartValues.push(
              singleChartData[Number(dataKey)].data[0].compliance,
            );
          }
        });
        backgroundColors.push(chartColorSet[Number(key)]);
        const dataSetObject = {
          label: this.formatDateRange(
            this.analysisFilter.comparisonPeriods[Number(key)],
          ),
          backgroundColor: backgroundColors,
          hoverBackgroundColor: backgroundColors,
          pointBackgroundColor: 'white',
          borderWidth: 1,
          pointBorderColor: '#249EBF',
          data: chartValues,
          categoryPercentage: this.getIndividualCriteriaChartCategoryPercentage,
        };
        data.datasets.push(dataSetObject);
      });
      data.labels = [siteName];
      if (
        this.analysisFilter.selectedChartType === ChartType.verticalBarChart
      ) {
        dataOptionsObject = _cloneDeep(this.verticalBarChartDataSetOption);
      } else {
        dataOptionsObject = _cloneDeep(this.horizontalBarChartDataSetOption);
      }
      dataOptionsObject.tooltips.callbacks.title = () => criteriaName;
      dataOptionsObject.tooltips.callbacks.afterTitle = () => siteName;
      dataOptions = _cloneDeep(dataOptionsObject);
      this.dataSet.push(data);
      this.dataSetOptions.push(dataOptions);
      this.loadingSet.push(true);
    });
  }

  public renderComparisonAggregateCriteria(chartData: ChartRecords[][]) {
    const data = {
      labels: [] as string[],
      datasets: [] as any[],
    };
    let dataOptions = {};
    const labels: string[] = [];
    let dataOptionsObject: any = {};
    const siteName: string = chartData[0][0].data[0].site.name;
    Object.keys(chartData).map((key: string) => {
      const chartValues: number[] = [];
      const backgroundColors: string[] = [];
      const singleChartData = chartData[Number(key)];
      Object.keys(singleChartData).map((dataKey: string) => {
        if (Number(key) === 0) {
          labels.push(singleChartData[Number(dataKey)].criteria.title);
        }
        chartValues.push(singleChartData[Number(dataKey)].data[0].compliance);
        backgroundColors.push(chartColorSet[Number(key)]);
      });
      const dataSetObject = {
        label: this.formatDateRange(
          this.analysisFilter.comparisonPeriods[Number(key)],
        ),
        backgroundColor: backgroundColors,
        hoverBackgroundColor: backgroundColors,
        pointBackgroundColor: 'white',
        borderWidth: 1,
        pointBorderColor: '#249EBF',
        data: chartValues,
        categoryPercentage: this.getAggregateCriteriaChartCategoryPercentage,
      };
      data.datasets.push(dataSetObject);
    });
    if (this.analysisFilter.selectedChartType === ChartType.verticalBarChart) {
      dataOptionsObject = _cloneDeep(this.verticalBarChartDataSetOption);
      dataOptionsObject.tooltips.callbacks.afterTitle = () => siteName;
    } else {
      dataOptionsObject = _cloneDeep(this.horizontalBarChartDataSetOption);
      dataOptionsObject.tooltips.callbacks.afterTitle = () => siteName;
    }
    data.labels = _cloneDeep(labels);
    dataOptions = _cloneDeep(dataOptionsObject);
    this.dataSet.push(data);
    this.dataSetOptions.push(dataOptions);
    this.loadingSet.push(true);
  }

  public generateComparisonConfigurationData(
    configurationData: any[],
    samplingConfigurationData: any[],
  ) {
    if (
      Array.isArray(configurationData) &&
      configurationData.length === this.analysisFilter.comparisonPeriods.length
    ) {
      const aggregateConfiguration: any[] = [];
      _map(this.analysisFilter.filteredCriterias, (criteriaId, p) => {
        const aggregateCriteriaConfiguration: any[] = [];
        _map(configurationData, (configData, index) => {
          let dirtyAggregateConfiguration: any = {};
          configData.forEach((data: any) => {
            if (data.projectCriteria.id === criteriaId) {
              const criteriaOptionsDataDistribution =
                data.criteriaSamplingData.criteriaOptionsDataDistribution;
              const criteriaSamplingData = Object.keys(
                criteriaOptionsDataDistribution,
              ).map((key: string) => ({
                [key]:
                  data.criteriaSamplingData.criteriaOptionsDataDistribution[
                    key
                  ],
              }));
              const dirtyConfig = {
                id: data.projectCriteria.id,
                title: _get(data, 'projectCriteria.title', ''),
                criteriaType: _get(data, 'projectCriteria.criteriaType', ''),
                sampleSize: Number(
                  _get(
                    data,
                    'criteriaSamplingDataConfigurations.sampleSize',
                    0,
                  ),
                ),
                criteriaOptions: JSON.parse(
                  data.projectCriteria.criteriaOptions,
                ),
                siteSamplingConfiguration: data.siteSamplingDataConfigurations,
                criteriaSamplingDataConfiguration:
                  data.criteriaSamplingDataConfigurations,
                samplingConfiguration: samplingConfigurationData[index],
                criteriaSamplingData,
                isAuditCriteria: _get(data, 'isAuditCriteria', false),
              };
              dirtyAggregateConfiguration = _cloneDeep(dirtyConfig);
            }
          });
          aggregateCriteriaConfiguration.push(dirtyAggregateConfiguration);
        });
        aggregateConfiguration.push(aggregateCriteriaConfiguration);
      });
      this.comparisonAggregateCriteriaConfiguration = _cloneDeep(
        aggregateConfiguration,
      );
    } else {
      this.comparisonAggregateCriteriaConfiguration = [];
    }
    this.generateComparisonIndividualCriteriaSet(
      this.comparisonAggregateCriteriaConfiguration,
    );
  }

  public generateComparisonIndividualCriteriaSet(
    comparisonAggregateCriteriaConfiguration: any[],
  ) {
    this.comparisonIndividualBooleanCriteriaConfiguration = comparisonAggregateCriteriaConfiguration.filter(
      (item) => {
        return item[0].criteriaType === CRITERION_TYPE.BOOLEAN;
      },
    );
    this.comparisonIndividualMixedCriteriaConfiguration = comparisonAggregateCriteriaConfiguration.filter(
      (item) => {
        return item[0].criteriaType !== CRITERION_TYPE.BOOLEAN;
      },
    );
    if (
      this.comparisonIndividualBooleanCriteriaConfiguration.length ===
        this.comparisonAggregateCriteriaConfiguration.length &&
      this.analysisFilter.checkIndividualCriteria
    ) {
      this.renderOverallStatisticTable = false;
    }
  }

  public getDate(newValue: Date): string {
    return (
      newValue.getDate() +
      ' ' +
      dirtyMonths[newValue.getMonth()] +
      ' ' +
      newValue.getFullYear()
    );
  }

  public formatDateRange(date: Date[]): string {
    return this.getDate(date[0]) + ' - ' + this.getDate(date[1]);
  }

  public getMixedBooleanTitleIndex(index: number): number {
    return (
      this.comparisonIndividualBooleanCriteriaConfiguration.length + index + 1
    );
  }

  public generateLabel(label: string, index: number): string | string[] {
    if (this.analysisFilter.checkIndividualCriteria) {
      return this.transformLabel(label);
    } else {
      if (this.analysisFilter.filteredCriterias.length > 8) {
        return index + 1 + '.';
      }
      return this.transformLabel(label);
    }
  }

  public transformLabel(label: string): string | string[] {
    const words = label.split(' ');
    let endIndex: number = labelSplitIndex;
    let eachLine: string = '';
    const eachLabel: string[] = [];
    _map(words, (word: string, wordIndex: string) => {
      switch (Number(wordIndex)) {
        case 0:
          eachLine = word;
          break;
        case endIndex:
          eachLabel.push(eachLine);
          eachLine = word;
          endIndex += labelSplitIndex;
          break;
        case words.length - 1:
          eachLine += ' ' + word;
          eachLabel.push(eachLine);
          break;
        default:
          eachLine += ' ' + word;
      }
    });
    if (eachLabel.length >= 3) {
      eachLabel[2] = eachLabel[2] + '...';
      return eachLabel.slice(0, 3);
    }
    return label;
  }

  get getBooleanCriteriaMapIds(): number[] {
    const criteriaIds: number[] = [];
    const booleanCriteriaIds: number[] = [];
    _map(this.projectCriteriaList, (projectCriteria: ProjectCriteria) => {
      if (projectCriteria.criteriaType === CRITERION_TYPE.BOOLEAN) {
        booleanCriteriaIds.push(projectCriteria.id);
      }
    });
    _map(booleanCriteriaIds, (eachId: number) => {
      if (this.analysisFilter.filteredCriterias.includes(eachId)) {
        criteriaIds.push(eachId);
      }
    });
    return criteriaIds;
  }

  get getIndividualCriteriaChartCategoryPercentage(): number {
    const noOfPeriods: number = this.analysisFilter.comparisonPeriods.length;
    return noOfPeriods <= 10 ? noOfPeriods * 0.1 : 0.8;
  }

  get getAggregateCriteriaChartCategoryPercentage(): number {
    const noOfBooleanCriterias: number = this
      .comparisonIndividualBooleanCriteriaConfiguration.length;
    if (noOfBooleanCriterias === 1) {
      return this.getIndividualCriteriaChartCategoryPercentage;
    }
    const noOfPeriods: number = this.analysisFilter.comparisonPeriods.length;
    return noOfBooleanCriterias * noOfPeriods <= 10 ? noOfPeriods * 0.2 : 0.8;
  }

  get getSelectedCriteriaList(): ProjectCriteria[] {
    const selectedCriteriaList: any[] = [];
    this.analysisFilter.filteredCriterias.forEach(
      (projectCriteriaMapId: number) => {
        const projectCriteria = this.projectCriteriaList.find((criteria) => {
          return (
            criteria.id === projectCriteriaMapId &&
            criteria.criteriaType === CRITERION_TYPE.BOOLEAN
          );
        });
        if (projectCriteria) {
          selectedCriteriaList.push(projectCriteria);
        }
      },
    );
    return selectedCriteriaList;
  }
}
