import { axios } from './axios';
import {
  ExchangeGrantForTokenRequestPayload,
  CheckSubscriptionStatusByEmailIdsPayload,
} from '@/store/modules/auth/types/auth.types';

export const authApi = {
  async exchangeGrantForToken(payload: ExchangeGrantForTokenRequestPayload) {
    return axios.post('/oauth/token', {
      ...payload,
    });
  },
  async signOut() {
    return axios.delete('sessions');
  },
  async checkSessionHealth() {
    return axios.get('sessions/health');
  },
  async checkSubscriptionHealth() {
    return axios.get('subscription/health');
  },
  async getJaasAccountDetails() {
    return axios.get('jaas/account/details');
  },
  async checkSubscriptionStatusByEmailIds(
    payload: CheckSubscriptionStatusByEmailIdsPayload,
  ) {
    return axios.post('/subscription/emailIds', {
      ...payload,
    });
  },
  async updateSessionSocket(socketId: string) {
    axios.put('sessions/socket', { socketId });
  },
};
