
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { RootState } from '@/store/store';
import {
  ExchangeGrantForTokenRequestPayload,
  SubscriptionInfo,
  SubscriptionStatus,
} from '@/store/modules/auth/types/auth.types';
import { ApiState } from '@/store/types/general.types';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import moment, { Moment } from 'moment';

@Component({})
export default class Oauth2Callback extends Vue {
  @Action('auth/exchangeGrantForToken')
  public exchangeGrantForToken!: (
    payload: ExchangeGrantForTokenRequestPayload,
  ) => void;

  @State((state: RootState) => state.auth.apiState.exchangeGrantForToken)
  public exchangeGrantForTokenState!: ApiState;

  @State((state: RootState) => state.auth.subscriptionInfo)
  public subscriptionInfo!: SubscriptionInfo;

  public async mounted() {
    const { code } = this.$route.query as { code: string };

    this.exchangeGrantForToken({
      grant_type: 'authorization_code',
      code,
      client_id: process.env.VUE_APP_JAAS_OAUTH2_CLIENT_ID!,
      client_secret: process.env.VUE_APP_JAAS_OAUTH2_CLIENT_SECRET!,
      redirect_uri: process.env.VUE_APP_FRONTEND_URL + '/oauth2/callback',
    });
  }

  @Watch('exchangeGrantForTokenState.success')
  @isDifferent
  @isTruthy
  public onExchangeGrantForTokenStateSuccessChanged() {
    if (this.subscriptionInfo.subscriptionStatus === SubscriptionStatus.VALID) {
      this.$router.replace('/');
    } else if (this.subscriptionInfo.isParticipant) {
      this.$router.replace('/projects/me');
    } else if (
      this.subscriptionInfo.expireAt !== null &&
      moment(this.subscriptionInfo.expireAt).isBefore(new Date())
    ) {
      this.$router.replace('/extend-subscription');
    } else {
      this.$router.replace('/no-subscription');
    }
  }

  @Watch('exchangeGrantForTokenState.error')
  @isDifferent
  @isTruthy
  public onExchangeGrantForTokenStateErrorChanged() {
    alert('Permission Denied. Please Try Again.');
    this.$router.push('/');
  }

  public render() {
    return null;
  }
}
