import {
  AuditCriteria,
  AuditSite,
  ChartType,
} from '@/store/modules/audits/types/audits.types';
import {
  BorderStyle,
  Document,
  Footer,
  Header,
  HeadingLevel,
  IRunOptions,
  Media,
  Packer,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  TextRun,
  WidthType,
} from 'docx';
import dayjs from 'dayjs';
import { IOpAttributes } from 'quill-delta-to-html/dist/commonjs/OpAttributeSanitizer';
import {
  chartColorSet,
  labelSplitIndex,
  primaryChartColor,
} from '@/store/types/general.types';
import Chart from 'chart.js';
import { CRITERION_TYPE } from '@/store/types/criterions.types';
import { cloneDeep as _cloneDeep, get as _get, map as _map } from 'lodash';

export class GenerateMultiSiteDocx {
  public docxFontSize: any = {
    default: 18,
    small: 14,
    big: 28,
    huge: 40,
  };
  public docxColor = {
    primary: '#1a538d',
    danger: 'red',
    warning: 'yellow',
  };
  public newLine: Paragraph = new Paragraph({
    text: '',
    heading: HeadingLevel.HEADING_1,
    thematicBreak: true,
  });

  public emptyLine: Paragraph = new Paragraph('');

  public dirtyMonths: string[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];

  public async create(
    document: Document,
    result: any,
    payload: any,
    analysisTitle: string,
    jbiLogo: string,
    projectTitle: string,
    auditTitle: string,
    auditSites: AuditSite[],
    auditCriteria: AuditCriteria[],
  ) {
    return {
      headers: {
        default: new Header({
          children: [await this.getHeader(document), this.emptyLine],
        }),
      },
      children: [
        ...this.getTitleSection(projectTitle),
        ...this.getSubTitleSection(auditTitle),
        ...this.getSubTitleSection(analysisTitle),
        ...this.getSiteSection(auditSites, payload),
        ...this.getDataCollectionPeriodCriteriaSection(auditCriteria, payload),
        ...this.selectedPeriodSection(
          document,
          result,
          payload,
          analysisTitle,
          projectTitle,
          auditTitle,
          auditSites,
          auditCriteria,
        ),
      ],
      footers: {
        default: new Footer({
          children: [await this.getFooter(), this.emptyLine],
        }),
      },
    };
  }

  public getTitleSection(projectTitle: string): Paragraph[] {
    return [
      new Paragraph({
        children: [
          this.createTextRun({
            text: projectTitle.replace(/\s+/g, ' ').toUpperCase(),
            bold: true,
            size: this.docxFontSize.huge,
            color: '#00205B',
          }),
        ],
      }),
      this.emptyLine,
    ];
  }

  public getSubTitleSection(subTitle: string): Paragraph[] {
    return [
      new Paragraph({
        children: [
          this.createTextRun({
            text: subTitle.replace(/\s+/g, ' '),
            bold: true,
            size: this.docxFontSize.big,
            color: 'black',
          }),
        ],
      }),
      this.emptyLine,
    ];
  }

  public getSiteSection(auditSites: AuditSite[], payload: any): Paragraph[] {
    const siteTitleParagraph = new Paragraph({
      children: [
        this.createTextRun({
          text: 'Site(s)',
          bold: true,
          size: this.docxFontSize.default,
          color: '#1D70B8',
        }),
      ],
    });

    const siteListParagraph: Paragraph[] = [];
    const siteNames = this.getSiteList(auditSites, payload);
    siteNames.forEach((siteName) => {
      siteListParagraph.push(this.createBullet(siteName));
    });

    return [siteTitleParagraph, ...siteListParagraph, this.emptyLine];
  }

  public getSiteList(auditSites: AuditSite[], payload: any): string[] {
    const siteNames: string[] = [];
    payload.filteredSites.forEach((auditSiteMapId: number) => {
      const auditSite: any = auditSites.find((auditSiteDetail) => {
        return auditSiteDetail.id === auditSiteMapId;
      });
      siteNames.push(auditSite.site.name);
    });
    return siteNames;
  }

  public getDataCollectionPeriodCriteriaSection(
    auditCriteria: AuditCriteria[],
    payload: any,
  ) {
    const criteriaTitleParagraph = new Paragraph({
      children: [
        this.createTextRun({
          text: 'Data Collection Period Criteria',
          bold: true,
          size: this.docxFontSize.default,
          color: '#1D70B8',
        }),
      ],
    });

    const criteriaListParagraph: Paragraph[] = this.getSelectedCriteria(
      auditCriteria,
      payload,
    );

    return [criteriaTitleParagraph, ...criteriaListParagraph, this.emptyLine];
  }

  public getSelectedCriteria(
    auditCriteria: AuditCriteria[],
    payload: any,
  ): Paragraph[] {
    const criteriaNames: Paragraph[] = [];
    payload.filteredCriterias.forEach((auditCriteriaId: number) => {
      const criteria: any = auditCriteria.find((auditCriteriaDetail) => {
        return auditCriteriaDetail.id === auditCriteriaId;
      });
      criteriaNames.push(this.createBullet(criteria.criteria.title));
    });
    return criteriaNames;
  }

  public selectedPeriodSection(
    doc: Document,
    result: any,
    payload: any,
    analysisTitle: string,
    projectTitle: string,
    auditTitle: string,
    auditSites: AuditSite[],
    auditCriteria: AuditCriteria[],
  ): Paragraph[] {
    const paragraphList: any[] = [];
    const selectedPeriodText: string = this.formatDateRange(
      payload.selectedPeriod,
    );
    paragraphList.push(
      new Paragraph({
        children: [
          this.createTextRun({
            text: 'Data Collection Period',
            bold: true,
            size: this.docxFontSize.default,
            color: '#1D70B8',
          }),
        ],
      }),
      new Paragraph({
        children: [
          this.createTextRun({
            text: selectedPeriodText,
            size: this.docxFontSize.default,
            color: 'black',
          }),
        ],
      }),
      this.emptyLine,
    );

    if (payload.checkIndividualSite) {
      if (payload.checkIndividualCriteria) {
        const {
          allCharts,
          renderBooleanTables,
          renderBooleanSamplingTables,
          renderMixedTables,
          renderMixedSamplingTables,
        } = this.genderIndividualSiteIndividualCriteria(
          result,
          payload,
          auditSites,
        );
        allCharts.forEach((individualChartStackData, individualChartIndex) => {
          const individualChart = individualChartStackData.stack;
          paragraphList.push(
            this.emptyLine,
            new Paragraph({
              children: [
                this.createTextRun({
                  text: individualChart[0].text,
                  bold: true,
                  size: this.docxFontSize.default,
                  color: individualChart[0].color,
                }),
              ],
            }),
            this.emptyLine,
          );
          const image = Media.addImage(
            doc,
            Uint8Array.from(atob(individualChart[1].image), (c) =>
              c.charCodeAt(0),
            ),
            600,
            individualChart[1].height,
          );
          paragraphList.push(new Paragraph(image), this.emptyLine);

          const booleanTableData = renderBooleanTables[individualChartIndex];
          const renderBooleanSamplingTable =
            renderBooleanSamplingTables[individualChartIndex];
          booleanTableData.forEach((renderTable: any) => {
            const rows: TableRow[] = [];
            if (
              renderTable.stack[0] &&
              renderTable.stack[0].table &&
              renderTable.stack[0].table.body
            ) {
              renderTable.stack[0].table.body.forEach((rowData: any) => {
                const cells: TableCell[] = [];
                rowData.forEach((cellData: any, index: number) => {
                  cells.push(
                    new TableCell({
                      width: {
                        size: index === 0 ? 70 : 30,
                        type: WidthType.PERCENTAGE,
                      },
                      children: [
                        new Paragraph({
                          children: [
                            this.createTextRun({
                              text: this.getTableValueText(cellData),
                              bold: !!cellData.bold,
                              size: this.docxFontSize.default,
                              color: cellData.color,
                            }),
                          ],
                        }),
                      ],
                    }),
                  );
                });
                rows.push(
                  new TableRow({
                    children: cells,
                  }),
                );
              });
            }
            paragraphList.push(
              new Table({
                rows,
              }),
              this.emptyLine,
            );
          });
          const booleanSamplingrows: TableRow[] = [];
          if (
            renderBooleanSamplingTable.stack[1] &&
            renderBooleanSamplingTable.stack[1].table &&
            renderBooleanSamplingTable.stack[1].table.body
          ) {
            renderBooleanSamplingTable.stack[1].table.body.forEach(
              (rowData: any) => {
                const cells: TableCell[] = [];
                rowData.forEach((cellData: any, index: number) => {
                  cells.push(
                    new TableCell({
                      width: {
                        size: index === 0 ? 70 : 30,
                        type: WidthType.PERCENTAGE,
                      },
                      children: [
                        new Paragraph({
                          children: [
                            this.createTextRun({
                              text: this.getTableValueText(cellData),
                              bold: !!cellData.bold,
                              size: this.docxFontSize.default,
                              color: cellData.color,
                            }),
                          ],
                        }),
                      ],
                    }),
                  );
                });
                booleanSamplingrows.push(
                  new TableRow({
                    children: cells,
                  }),
                );
              },
            );
          }
          paragraphList.push(
            new Paragraph({
              children: [
                this.createTextRun({
                  text: renderBooleanSamplingTable.stack[0].text,
                  bold: true,
                  size: this.docxFontSize.default,
                  color: renderBooleanSamplingTable.stack[0].color,
                }),
              ],
            }),
            this.emptyLine,
          );
          paragraphList.push(
            new Table({
              rows: booleanSamplingrows,
            }),
            this.emptyLine,
          );
        });
        renderMixedTables.forEach(
          (renderTableData: any, renderTableDataIndex: number) => {
            const renderMixedSamplingTable =
              renderMixedSamplingTables[renderTableDataIndex];
            paragraphList.push(
              this.emptyLine,
              new Paragraph({
                children: [
                  this.createTextRun({
                    text: renderTableData[0].stack[0].text,
                    bold: true,
                    size: this.docxFontSize.default,
                    color: renderTableData[0].stack[0].color,
                  }),
                ],
              }),
              this.emptyLine,
            );
            renderTableData.forEach(
              (mixedTable: any, mixedTableIndex: number) => {
                const rows: TableRow[] = [];
                let tableInfo = mixedTable.stack[1];
                if (mixedTableIndex !== 0) {
                  tableInfo = mixedTable.stack[0];
                }
                tableInfo.table.body.forEach((rowData: any) => {
                  const cells: TableCell[] = [];
                  rowData.forEach((cellData: any, index: number) => {
                    cells.push(
                      new TableCell({
                        width: {
                          size: index === 0 ? 70 : 30,
                          type: WidthType.PERCENTAGE,
                        },
                        children: [
                          new Paragraph({
                            children: [
                              this.createTextRun({
                                text: this.getTableValueText(cellData),
                                bold: !!cellData.bold,
                                size: this.docxFontSize.default,
                                color: cellData.color,
                              }),
                            ],
                          }),
                        ],
                      }),
                    );
                  });
                  rows.push(
                    new TableRow({
                      children: cells,
                    }),
                  );
                });
                paragraphList.push(
                  new Table({
                    rows,
                  }),
                  this.emptyLine,
                );
              },
            );
            const mixedSamplingRows: TableRow[] = [];
            if (
              renderMixedSamplingTable.stack[1] &&
              renderMixedSamplingTable.stack[1].table &&
              renderMixedSamplingTable.stack[1].table.body
            ) {
              renderMixedSamplingTable.stack[1].table.body.forEach(
                (rowData: any) => {
                  const cells: TableCell[] = [];
                  rowData.forEach((cellData: any, index: number) => {
                    cells.push(
                      new TableCell({
                        width: {
                          size: index === 0 ? 70 : 30,
                          type: WidthType.PERCENTAGE,
                        },
                        children: [
                          new Paragraph({
                            children: [
                              this.createTextRun({
                                text: this.getTableValueText(cellData),
                                bold: !!cellData.bold,
                                size: this.docxFontSize.default,
                                color: cellData.color,
                              }),
                            ],
                          }),
                        ],
                      }),
                    );
                  });
                  mixedSamplingRows.push(
                    new TableRow({
                      children: cells,
                    }),
                  );
                },
              );
            }
            paragraphList.push(
              new Paragraph({
                children: [
                  this.createTextRun({
                    text: renderMixedSamplingTable.stack[0].text,
                    bold: true,
                    size: this.docxFontSize.default,
                    color: renderMixedSamplingTable.stack[0].color,
                  }),
                ],
              }),
              this.emptyLine,
            );
            paragraphList.push(
              new Table({
                rows: mixedSamplingRows,
              }),
              this.emptyLine,
            );
          },
        );
      } else {
        const {
          aggregateChart,
          renderTables,
          renderSamplingTables,
        } = this.generateIndividualSiteAggregateCriteria(
          result,
          payload,
          auditSites,
        );
        if (result.chartData.length > 0) {
          aggregateChart[0].stack.forEach((stackObject: any) => {
            if (stackObject.image && stackObject.image !== '') {
              const image = Media.addImage(
                doc,
                Uint8Array.from(atob(stackObject.image), (c) =>
                  c.charCodeAt(0),
                ),
                600,
                aggregateChart[0].stack[0].height,
              );
              paragraphList.push(new Paragraph(image), this.emptyLine);
            }
          });
        }
        renderTables.forEach(
          (renderTableData: any, renderTableDataIndex: number) => {
            const renderSamplingTable =
              renderSamplingTables[renderTableDataIndex];
            paragraphList.push(
              this.emptyLine,
              new Paragraph({
                children: [
                  this.createTextRun({
                    text: renderTableData[0].stack[0].text,
                    bold: true,
                    size: this.docxFontSize.default,
                    color: renderTableData[0].stack[0].color,
                  }),
                ],
              }),
              this.emptyLine,
            );
            renderTableData.forEach(
              (tableData: any, tableDataIndex: number) => {
                const rows: TableRow[] = [];
                let dirtyTable = tableData.stack[1];
                if (tableDataIndex !== 0) {
                  dirtyTable = tableData.stack[0];
                }
                dirtyTable.table.body.forEach((rowData: any) => {
                  const cells: TableCell[] = [];
                  rowData.forEach((cellData: any, index: number) => {
                    cells.push(
                      new TableCell({
                        width: {
                          size: index === 0 ? 70 : 30,
                          type: WidthType.PERCENTAGE,
                        },
                        children: [
                          new Paragraph({
                            children: [
                              this.createTextRun({
                                text: this.getTableValueText(cellData),
                                bold: !!cellData.bold,
                                size: this.docxFontSize.default,
                                color: cellData.color,
                              }),
                            ],
                          }),
                        ],
                      }),
                    );
                  });
                  rows.push(
                    new TableRow({
                      children: cells,
                    }),
                  );
                });
                paragraphList.push(
                  new Table({
                    rows,
                  }),
                  this.emptyLine,
                );
              },
            );
            const samplingrows: TableRow[] = [];
            if (
              renderSamplingTable.stack[1] &&
              renderSamplingTable.stack[1].table &&
              renderSamplingTable.stack[1].table.body
            ) {
              renderSamplingTable.stack[1].table.body.forEach(
                (rowData: any) => {
                  const cells: TableCell[] = [];
                  rowData.forEach((cellData: any, index: number) => {
                    cells.push(
                      new TableCell({
                        width: {
                          size: index === 0 ? 70 : 30,
                          type: WidthType.PERCENTAGE,
                        },
                        children: [
                          new Paragraph({
                            children: [
                              this.createTextRun({
                                text: this.getTableValueText(cellData),
                                bold: !!cellData.bold,
                                size: this.docxFontSize.default,
                                color: cellData.color,
                              }),
                            ],
                          }),
                        ],
                      }),
                    );
                  });
                  samplingrows.push(
                    new TableRow({
                      children: cells,
                    }),
                  );
                },
              );
            }
            paragraphList.push(
              new Paragraph({
                children: [
                  this.createTextRun({
                    text: renderSamplingTable.stack[0].text,
                    bold: true,
                    size: this.docxFontSize.default,
                    color: renderSamplingTable.stack[0].color,
                  }),
                ],
              }),
              this.emptyLine,
            );
            paragraphList.push(
              new Table({
                rows: samplingrows,
              }),
              this.emptyLine,
            );
          },
        );
      }
    } else {
      if (payload.checkIndividualCriteria) {
        const {
          allCharts,
          renderBooleanTables,
          renderBooleanSamplingTables,
          renderMixedTables,
          renderMixedSamplingTables,
        } = this.generateAggregateSiteIndividualCriteria(
          result,
          payload,
          auditSites,
        );
        allCharts.forEach((individualChartStackData, individualChartIndex) => {
          const individualChart = individualChartStackData.stack;
          paragraphList.push(
            this.emptyLine,
            new Paragraph({
              children: [
                this.createTextRun({
                  text: individualChart[0].text,
                  bold: true,
                  size: this.docxFontSize.default,
                  color: individualChart[0].color,
                }),
              ],
            }),
            this.emptyLine,
          );
          const image = Media.addImage(
            doc,
            Uint8Array.from(atob(individualChart[1].image), (c) =>
              c.charCodeAt(0),
            ),
            600,
            individualChart[1].height,
          );
          paragraphList.push(new Paragraph(image), this.emptyLine);

          const booleanTableData = renderBooleanTables[individualChartIndex];
          const renderBooleanSamplingTable =
            renderBooleanSamplingTables[individualChartIndex];
          booleanTableData.forEach((renderTable: any) => {
            const rows: TableRow[] = [];
            if (
              renderTable.stack[0] &&
              renderTable.stack[0].table &&
              renderTable.stack[0].table.body
            ) {
              renderTable.stack[0].table.body.forEach((rowData: any) => {
                const cells: TableCell[] = [];
                rowData.forEach((cellData: any, index: number) => {
                  cells.push(
                    new TableCell({
                      width: {
                        size: index === 0 ? 70 : 30,
                        type: WidthType.PERCENTAGE,
                      },
                      children: [
                        new Paragraph({
                          children: [
                            this.createTextRun({
                              text: this.getTableValueText(cellData),
                              bold: !!cellData.bold,
                              size: this.docxFontSize.default,
                              color: cellData.color,
                            }),
                          ],
                        }),
                      ],
                    }),
                  );
                });
                rows.push(
                  new TableRow({
                    children: cells,
                  }),
                );
              });
            }
            paragraphList.push(
              new Table({
                rows,
              }),
              this.emptyLine,
            );
          });
          const booleanSamplingrows: TableRow[] = [];
          if (
            renderBooleanSamplingTable.stack[1] &&
            renderBooleanSamplingTable.stack[1].table &&
            renderBooleanSamplingTable.stack[1].table.body
          ) {
            renderBooleanSamplingTable.stack[1].table.body.forEach(
              (rowData: any) => {
                const cells: TableCell[] = [];
                rowData.forEach((cellData: any, index: number) => {
                  cells.push(
                    new TableCell({
                      width: {
                        size: index === 0 ? 70 : 30,
                        type: WidthType.PERCENTAGE,
                      },
                      children: [
                        new Paragraph({
                          children: [
                            this.createTextRun({
                              text: this.getTableValueText(cellData),
                              bold: !!cellData.bold,
                              size: this.docxFontSize.default,
                              color: cellData.color,
                            }),
                          ],
                        }),
                      ],
                    }),
                  );
                });
                booleanSamplingrows.push(
                  new TableRow({
                    children: cells,
                  }),
                );
              },
            );
          }
          paragraphList.push(
            new Paragraph({
              children: [
                this.createTextRun({
                  text: renderBooleanSamplingTable.stack[0].text,
                  bold: true,
                  size: this.docxFontSize.default,
                  color: renderBooleanSamplingTable.stack[0].color,
                }),
              ],
            }),
            this.emptyLine,
          );
          paragraphList.push(
            new Table({
              rows: booleanSamplingrows,
            }),
            this.emptyLine,
          );
        });
        renderMixedTables.forEach(
          (renderTableData: any, renderTableDataIndex: number) => {
            const renderMixedTable = renderTableData;
            const renderMixedSamplingTable =
              renderMixedSamplingTables[renderTableDataIndex];
            paragraphList.push(
              this.emptyLine,
              new Paragraph({
                children: [
                  this.createTextRun({
                    text: renderMixedTable[0].stack[0].text,
                    bold: true,
                    size: this.docxFontSize.default,
                    color: renderMixedTable[0].stack[0].color,
                  }),
                ],
              }),
              this.emptyLine,
            );
            renderMixedTable.forEach((mixedTable: any) => {
              const rows: TableRow[] = [];
              if (
                mixedTable.stack[1] &&
                mixedTable.stack[1].table &&
                mixedTable.stack[1].table.body
              ) {
                mixedTable.stack[1].table.body.forEach((rowData: any) => {
                  const cells: TableCell[] = [];
                  rowData.forEach((cellData: any, index: number) => {
                    cells.push(
                      new TableCell({
                        width: {
                          size: index === 0 ? 70 : 30,
                          type: WidthType.PERCENTAGE,
                        },
                        children: [
                          new Paragraph({
                            children: [
                              this.createTextRun({
                                text: this.getTableValueText(cellData),
                                bold: !!cellData.bold,
                                size: this.docxFontSize.default,
                                color: cellData.color,
                              }),
                            ],
                          }),
                        ],
                      }),
                    );
                  });
                  rows.push(
                    new TableRow({
                      children: cells,
                    }),
                  );
                });
                paragraphList.push(
                  new Table({
                    rows,
                  }),
                  this.emptyLine,
                );
              }
            });
            const mixedSamplingRows: TableRow[] = [];
            if (
              renderMixedSamplingTable.stack[1] &&
              renderMixedSamplingTable.stack[1].table &&
              renderMixedSamplingTable.stack[1].table.body
            ) {
              renderMixedSamplingTable.stack[1].table.body.forEach(
                (rowData: any) => {
                  const cells: TableCell[] = [];
                  rowData.forEach((cellData: any, index: number) => {
                    cells.push(
                      new TableCell({
                        width: {
                          size: index === 0 ? 70 : 30,
                          type: WidthType.PERCENTAGE,
                        },
                        children: [
                          new Paragraph({
                            children: [
                              this.createTextRun({
                                text: this.getTableValueText(cellData),
                                bold: !!cellData.bold,
                                size: this.docxFontSize.default,
                                color: cellData.color,
                              }),
                            ],
                          }),
                        ],
                      }),
                    );
                  });
                  mixedSamplingRows.push(
                    new TableRow({
                      children: cells,
                    }),
                  );
                },
              );
            }
            paragraphList.push(
              new Paragraph({
                children: [
                  this.createTextRun({
                    text: renderMixedSamplingTable.stack[0].text,
                    bold: true,
                    size: this.docxFontSize.default,
                    color: renderMixedSamplingTable.stack[0].color,
                  }),
                ],
              }),
              this.emptyLine,
            );
            paragraphList.push(
              new Table({
                rows: mixedSamplingRows,
              }),
              this.emptyLine,
            );
          },
        );
      } else {
        const {
          aggregateChart,
          renderTables,
          renderSamplingTables,
        } = this.generateAggregateSiteAggregateCriteria(
          result,
          payload,
          auditSites,
        );
        if (result.chartData.length > 0) {
          aggregateChart[0].stack.forEach((stackObject: any) => {
            if (stackObject.image && stackObject.image !== '') {
              const image = Media.addImage(
                doc,
                Uint8Array.from(atob(stackObject.image), (c) =>
                  c.charCodeAt(0),
                ),
                600,
                aggregateChart[0].stack[0].height,
              );
              paragraphList.push(new Paragraph(image), this.emptyLine);
            }
          });
        }
        renderTables.forEach(
          (renderTable: any, renderTableDataIndex: number) => {
            const renderSamplingTable =
              renderSamplingTables[renderTableDataIndex];
            paragraphList.push(
              this.emptyLine,
              new Paragraph({
                children: [
                  this.createTextRun({
                    text: renderTable.stack[0].text,
                    bold: true,
                    size: this.docxFontSize.default,
                    color: renderTable.stack[0].color,
                  }),
                ],
              }),
              this.emptyLine,
            );
            const rows: TableRow[] = [];
            if (
              renderTable.stack[1] &&
              renderTable.stack[1].table &&
              renderTable.stack[1].table.body
            ) {
              renderTable.stack[1].table.body.forEach((rowData: any) => {
                const cells: TableCell[] = [];
                rowData.forEach((cellData: any, index: number) => {
                  cells.push(
                    new TableCell({
                      width: {
                        size: index === 0 ? 70 : 30,
                        type: WidthType.PERCENTAGE,
                      },
                      children: [
                        new Paragraph({
                          children: [
                            this.createTextRun({
                              text: this.getTableValueText(cellData),
                              bold: !!cellData.bold,
                              size: this.docxFontSize.default,
                              color: cellData.color,
                            }),
                          ],
                        }),
                      ],
                    }),
                  );
                });
                rows.push(
                  new TableRow({
                    children: cells,
                  }),
                );
              });
            }
            const samplingrows: TableRow[] = [];
            if (
              renderSamplingTable.stack[1] &&
              renderSamplingTable.stack[1].table &&
              renderSamplingTable.stack[1].table.body
            ) {
              renderSamplingTable.stack[1].table.body.forEach(
                (rowData: any) => {
                  const cells: TableCell[] = [];
                  rowData.forEach((cellData: any, index: number) => {
                    cells.push(
                      new TableCell({
                        width: {
                          size: index === 0 ? 70 : 30,
                          type: WidthType.PERCENTAGE,
                        },
                        children: [
                          new Paragraph({
                            children: [
                              this.createTextRun({
                                text: this.getTableValueText(cellData),
                                bold: !!cellData.bold,
                                size: this.docxFontSize.default,
                                color: cellData.color,
                              }),
                            ],
                          }),
                        ],
                      }),
                    );
                  });
                  samplingrows.push(
                    new TableRow({
                      children: cells,
                    }),
                  );
                },
              );
            }
            paragraphList.push(
              new Table({
                rows,
              }),
              this.emptyLine,
            );
            paragraphList.push(
              new Paragraph({
                children: [
                  this.createTextRun({
                    text: renderSamplingTable.stack[0].text,
                    bold: true,
                    size: this.docxFontSize.default,
                    color: renderSamplingTable.stack[0].color,
                  }),
                ],
              }),
              this.emptyLine,
            );
            paragraphList.push(
              new Table({
                rows: samplingrows,
              }),
              this.emptyLine,
            );
          },
        );
      }
    }

    return paragraphList;
  }

  public generateAggregateSiteAggregateCriteria(
    result: any,
    payload: any,
    auditSites: AuditSite[],
  ) {
    const aggregateChart: any[] = [];
    const renderChart = {
      width: 443,
      height: 250,
      image: '',
      alignment: 'center',
      margin: [0, 0, 0, 30],
      unbreakable: true,
    };
    const renderTables: any[] = [];
    const renderSamplingTables: any[] = [];
    // generate chart
    const dataSet = [];
    const dataSetOptions = [];
    const verticalBarChartDataSetOption: any = {
      animation: {
        duration: 0,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              max: 100,
              min: 0,
              fontSize: 20,
            },
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Compliance %',
              display: true,
              fontSize: 20,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Criteria',
              display: false,
            },
            ticks: {
              fontSize: 20,
              minRotation: 0,
              callback: (label: string, index: number) => {
                if (payload.checkIndividualCriteria) {
                  return this.transformLabel(label);
                } else {
                  if (payload.filteredCriterias.length > 4) {
                    return index + 1 + '.';
                  }
                  return this.transformLabel(label);
                }
              },
            },
          },
        ],
      },
      legend: {
        display: false,
        onHover: (e: any) => {
          e.target.style.cursor = 'pointer';
        },
        onLeave: (e: any) => {
          e.target.style.cursor = 'default';
        },
        labels: {
          boxWidth: 40,
          fontSize: 25,
        },
        align: 'start',
      },
      responsive: true,
      maintainAspectRatio: true,
    };
    const horizontalBarChartDataSetOption: any = {
      animation: {
        duration: 0,
      },
      scales: {
        xAxes: [
          {
            ticks: {
              beginAtZero: true,
              max: 100,
              min: 0,
              fontSize: 20,
            },
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Compliance %',
              display: true,
              fontSize: 20,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Criteria',
              display: false,
            },
            ticks: {
              fontSize: 20,
              minRotation: 0,
              callback: (label: string, index: number) => {
                if (payload.checkIndividualCriteria) {
                  return this.transformLabel(label);
                } else {
                  if (payload.filteredCriterias.length > 4) {
                    return index + 1 + '.';
                  }
                  return this.transformLabel(label);
                }
              },
            },
          },
        ],
      },
      legend: {
        display: false,
        onHover: (e: any) => {
          e.target.style.cursor = 'pointer';
        },
        onLeave: (e: any) => {
          e.target.style.cursor = 'default';
        },
        labels: {
          boxWidth: 40,
          fontSize: 25,
        },
        align: 'start',
      },
      responsive: true,
      maintainAspectRatio: true,
    };
    const {
      aggregateCriteriaConfiguration,
      individualBooleanCriteriaConfiguration,
      individualMixedCriteriaConfiguration,
    } = this.generateConfigurationData(
      result.statisticData.configurationData,
      payload,
      result.statisticData.samplingConfiguration,
    );
    const chartData = result.chartData;
    let siteName: string;
    switch (payload.filteredSites.length) {
      case 1:
        siteName = chartData[0].data[0].site.name;
        break;
      case auditSites.length:
        siteName = 'All Sites';
        break;
      default:
        siteName = 'Selected sites (' + payload.filteredSites.length + ')';
    }
    const data = {
      labels: [] as string[],
      datasets: [] as any[],
    };
    let dataOptions = {};
    const labels: string[] = [];
    const chartValues: number[] = [];
    const backgroundColors: string[] = [];
    Object.keys(chartData).map((key: string) => {
      labels.push(chartData[Number(key)].criteria.title);
      chartValues.push(chartData[Number(key)].data[0].compliance);
      backgroundColors.push(primaryChartColor);
    });
    const dataSetObject = {
      label: siteName,
      backgroundColor: backgroundColors,
      pointBackgroundColor: 'white',
      borderWidth: 1,
      pointBorderColor: '#249EBF',
      data: chartValues,
      categoryPercentage: this.getAggregateCriteriaChartCategoryPercentage(
        individualBooleanCriteriaConfiguration,
      ),
    };
    let dataOptionsObject;
    if (payload.selectedChartType === ChartType.verticalBarChart) {
      dataOptionsObject = _cloneDeep(verticalBarChartDataSetOption);
    } else {
      dataOptionsObject = _cloneDeep(horizontalBarChartDataSetOption);
    }
    data.labels = _cloneDeep(labels);
    data.datasets = [];
    data.datasets.push(dataSetObject);
    dataOptions = _cloneDeep(dataOptionsObject);
    dataSet.push(data);
    dataSetOptions.push(dataOptions);
    const canvas = document.createElement('canvas');
    document.body.appendChild(canvas);
    if (payload.selectedChartType === ChartType.verticalBarChart) {
      const verticalChart = new Chart(
        canvas.getContext('2d') as CanvasRenderingContext2D,
        {
          type: 'bar',
          data: {
            labels: dataSet[0].labels,
            datasets: dataSet[0].datasets,
          },
          options: dataSetOptions[0],
          plugins: [
            {
              afterDatasetDraw(chart: any, args: any) {
                args.meta.data.forEach((element: any) => {
                  if (element._model.base - element._model.y < 0) {
                    const borderWidth = 8;
                    const ctx = chart.ctx;
                    const vm = element._view;
                    const half = vm.width / 2;
                    const left = vm.x - half;
                    const right = vm.x + half;
                    const top = vm.y - 2;
                    const width = right - left;
                    const height =
                      chart.chartArea.bottom - top + borderWidth / 2 - 1;
                    ctx.beginPath();
                    ctx.lineWidth = borderWidth;
                    ctx.strokeStyle = '#000000';
                    ctx.setLineDash([3, 4]);
                    ctx.moveTo(left, top);
                    ctx.lineTo(left, top + height);
                    ctx.moveTo(left, top);
                    ctx.lineTo(left + width, top);
                    ctx.moveTo(left + width, top);
                    ctx.lineTo(left + width, top + height);
                    ctx.stroke();
                    ctx.save();
                  }
                });
              },
            },
          ],
        },
      );
      const base64Image = verticalChart.toBase64Image();
      renderChart.image = base64Image.split(',')[1];
    } else {
      const horizontalChart = new Chart(
        canvas.getContext('2d') as CanvasRenderingContext2D,
        {
          type: 'horizontalBar',
          data: {
            labels: dataSet[0].labels,
            datasets: dataSet[0].datasets,
          },
          options: dataSetOptions[0],
          plugins: [
            {
              afterDatasetDraw(chart: any, args: any) {
                args.meta.data.forEach((element: any) => {
                  if (element._model.base - element._model.x > 0) {
                    const borderWidth = 8;
                    const ctx = chart.ctx;
                    const vm = element._view;
                    const half = vm.height / 2;
                    const top = vm.y - half;
                    const bottom = vm.y + half;
                    const right = vm.x + 10;
                    const left = chart.chartArea.left;
                    ctx.beginPath();
                    ctx.lineWidth = borderWidth;
                    ctx.strokeStyle = '#000000';
                    ctx.setLineDash([4, 5]);
                    ctx.moveTo(left, top);
                    ctx.lineTo(right, top);
                    ctx.moveTo(right, top);
                    ctx.lineTo(right, bottom);
                    ctx.moveTo(right, bottom);
                    ctx.lineTo(left, bottom);
                    ctx.stroke();
                    ctx.save();
                  }
                });
              },
            },
          ],
        },
      );
      const base64Image = horizontalChart.toBase64Image();
      renderChart.image = base64Image.split(',')[1];
    }
    const criteriaNamesText: any[] = labels.map((label) => {
      return {
        text: label,
        color: '#444F51',
        margin: [10, 5, 0, 2],
      };
    });
    const names = {
      layout: 'noBorders',
      table: {
        widths: ['*'],
        body: [
          [
            {
              ol: criteriaNamesText,
              fontSize: 9,
              fillColor: '#EFF7FF',
              markerColor: '#444F51',
            },
          ],
        ],
      },
      margin: [10, 0, 0, 23],
    };
    let aggregateStack: any[];
    if (payload.filteredCriterias.length > 4) {
      renderChart.margin = [0, 0, 0, 15];
      aggregateStack = [renderChart, names];
    } else {
      aggregateStack = [renderChart];
    }
    const criteriaChart = {
      stack: aggregateStack,
      unbreakable: false,
    };
    aggregateChart.push(criteriaChart);
    document.body.removeChild(canvas);
    // generate table
    aggregateCriteriaConfiguration.forEach(
      (criteriaDetails: any, criteriaDetailsIndex: number) => {
        const title = {
          text: criteriaDetailsIndex + 1 + '. ' + criteriaDetails.title,
          bold: true,
          color: '#444F51',
          fontSize: 11,
          margin: [0, 0, 0, 17],
        };
        const bodyRows: any[][] = [];
        criteriaDetails.criteriaSamplingData.forEach(
          (optionsData: any, optionDataIndex: number) => {
            const rowData: any[] = [];
            const option = Object.keys(optionsData)[0];
            rowData.push({
              text: option,
              color: '#444F51',
              fontSize: 12,
              border: [true, false, true, false],
              margin:
                optionDataIndex ===
                criteriaDetails.criteriaSamplingData.length - 1
                  ? [8, 6, 0, 6]
                  : [8, 6, 0, 0],
            });
            rowData.push({
              text: optionsData[option],
              color: '#444F51',
              fontSize: 12,
              border: [true, false, true, false],
              alignment: 'right',
              margin:
                optionDataIndex ===
                criteriaDetails.criteriaSamplingData.length - 1
                  ? [0, 6, 8, 6]
                  : [0, 6, 8, 0],
            });
            bodyRows.push(rowData);
          },
        );
        let total: number | string = 0;
        let compliance: any = 0;
        bodyRows.forEach((optionValues) => {
          if (optionValues[1].text === '-') {
            total = '-';
          } else {
            total += optionValues[1].text;
          }
        });
        bodyRows.push([
          {
            text: 'Total Data Collected',
            color: '#444F51',
            fontSize: 12,
            margin: [8, 6, 0, 6],
          },
          {
            text: total,
            color: '#444F51',
            fontSize: 12,
            alignment: 'right',
            margin: [0, 6, 8, 6],
          },
        ]);
        if (criteriaDetails.criteriaType === CRITERION_TYPE.BOOLEAN) {
          if (bodyRows[0][1].text === 0) {
            bodyRows.push([
              {
                text: 'Compliance',
                color: '#444F51',
                fontSize: 12,
                margin: [8, 6, 0, 6],
                bold: true,
              },
              {
                text: compliance + '%',
                color: '#444F51',
                fontSize: 12,
                alignment: 'right',
                bold: true,
                margin: [0, 6, 8, 6],
              },
            ]);
          } else {
            if (bodyRows[0][1].text === '-') {
              compliance = '-';
            } else {
              compliance =
                (bodyRows[0][1].text / (total - bodyRows[2][1].text)) * 100;
            }
            bodyRows.push([
              {
                text: 'Compliance',
                color: '#444F51',
                fontSize: 12,
                margin: [8, 6, 0, 6],
                bold: true,
              },
              {
                text:
                  compliance !== '-'
                    ? Math.round(compliance * 100) / 100 + '%'
                    : compliance,
                color: '#444F51',
                fontSize: 12,
                alignment: 'right',
                bold: true,
                margin: [0, 6, 8, 6],
              },
            ]);
          }
        }
        const tableData = {
          table: {
            unbreakable: true,
            widths: ['*', 100],
            body: [
              [
                {
                  text: 'Answer Choices',
                  color: '#9F9F9F',
                  fontSize: 11,
                  margin: [8, 10, 0, 10],
                },
                {
                  text: siteName,
                  color: '#9F9F9F',
                  fontSize: 11,
                  alignment: 'right',
                  margin: [0, 10, 8, 10],
                },
              ],
              ...bodyRows,
            ],
          },
          layout: {
            hLineWidth: () => {
              return 0.5;
            },
            vLineWidth: () => {
              return 0.5;
            },
            hLineColor: () => {
              return '#DBDBDB';
            },
            vLineColor: () => {
              return '#DBDBDB';
            },
          },
        };
        const stack: any[] = [title, tableData];
        const criteriaTable = {
          stack,
          unbreakable: true,
        };
        renderTables.push(criteriaTable);
      },
    );
    aggregateCriteriaConfiguration.forEach(
      (criteriaDetails, criteriaDetailsIndex) => {
        const samplingTitle = {
          text: 'Sampling',
          bold: true,
          color: '#444F51',
          fontSize: 11,
          margin: [0, 13, 0, 17],
        };
        const dirtySamplingTables: any[] = this.getSamplingTables(
          criteriaDetails,
          samplingTitle,
        );
        renderSamplingTables.push(dirtySamplingTables[0]);
      },
    );
    return {
      aggregateChart,
      renderTables,
      renderSamplingTables,
    };
  }

  public getSamplingTables(criteriaDetails: any, samplingTitle: any) {
    const dirtySamplingTables: any[] = [];
    const bodyRows: any[][] = [];
    const titleHeaderRow: any[] = [];
    const transformedSamplingRows: any[] = [];
    const dirtySamplingRows: any[] = [];
    let totalColumn: number = 0;
    switch (criteriaDetails.samplingConfiguration.auditSamplingType) {
      case 'adHoc':
        titleHeaderRow.push({
          text: 'Site',
          color: '#444F51',
          fontSize: 11,
          margin: [8, 6, 8, 6],
          alignment: 'left',
        });
        titleHeaderRow.push({
          text: 'Sampling',
          color: '#444F51',
          fontSize: 11,
          margin: [8, 6, 8, 6],
          alignment: 'right',
        });
        totalColumn += 2;
        const adHocRowData: any[] = [];
        adHocRowData.push({
          siteName: 'All Sites',
          values: [
            criteriaDetails.criteriaSamplingDataConfiguration.isSamplingEnabled
              ? 'Enabled'
              : '-',
          ],
        });
        const adHocSitesData: boolean[] = [];
        criteriaDetails.siteSamplingConfiguration.forEach(
          (siteSampling: any) => {
            if (siteSampling.isSamplingEnabled) {
              adHocSitesData.push(true);
            } else {
              adHocSitesData.push(false);
            }
            adHocRowData.push({
              siteName: siteSampling.auditSiteMap.site.name,
              values: [siteSampling.isSamplingEnabled ? 'Enabled' : 'Disabled'],
            });
          },
        );
        const trueCount = adHocSitesData.filter((value) => value).length;
        adHocRowData[0] = {
          ...adHocRowData[0],
          values: [trueCount + '/' + adHocSitesData.length],
        };
        dirtySamplingRows.push(...adHocRowData);
        dirtySamplingRows.forEach((samplingData) => {
          const samplingRows: any[] = [];
          samplingRows.push({
            text: samplingData.siteName,
            color: '#444F51',
            fontSize: 12,
            alignment: 'left',
            margin: [8, 6, 8, 6],
          });
          samplingData.values.forEach((value: any) => {
            samplingRows.push({
              text: value,
              color: '#444F51',
              fontSize: 12,
              alignment: 'right',
              margin: [8, 6, 8, 6],
            });
          });
          transformedSamplingRows.push(samplingRows);
        });
        bodyRows.push(titleHeaderRow);
        bodyRows.push(...transformedSamplingRows);
        break;
      case 'consecutive':
        if (
          criteriaDetails.samplingConfiguration.samplingMode === 'minAndMax'
        ) {
          titleHeaderRow.push({
            text: 'Site',
            color: '#444F51',
            fontSize: 11,
            margin: [8, 6, 8, 6],
            alignment: 'left',
          });
          titleHeaderRow.push({
            text: 'Min.',
            color: '#444F51',
            fontSize: 11,
            margin: [8, 6, 8, 6],
            alignment: 'right',
          });
          titleHeaderRow.push({
            text: 'Max.',
            color: '#444F51',
            fontSize: 11,
            margin: [8, 6, 8, 6],
            alignment: 'right',
          });
          totalColumn += 3;
          dirtySamplingRows.push({
            siteName: 'All Sites',
            values: [
              criteriaDetails.criteriaSamplingDataConfiguration.minSampleSize,
              criteriaDetails.criteriaSamplingDataConfiguration.maxSampleSize,
            ],
          });
          criteriaDetails.siteSamplingConfiguration.forEach(
            (siteSampling: any) => {
              dirtySamplingRows.push({
                siteName: siteSampling.auditSiteMap.site.name,
                values: [
                  siteSampling.minSampleSize,
                  siteSampling.maxSampleSize,
                ],
              });
            },
          );
          dirtySamplingRows.forEach((samplingData) => {
            const samplingRows: any[] = [];
            samplingRows.push({
              text: samplingData.siteName,
              color: '#444F51',
              fontSize: 12,
              alignment: 'left',
              margin: [8, 6, 8, 6],
            });
            samplingData.values.forEach((value: any) => {
              samplingRows.push({
                text: value,
                color: '#444F51',
                fontSize: 12,
                alignment: 'right',
                margin: [8, 6, 8, 6],
              });
            });
            transformedSamplingRows.push(samplingRows);
          });
          bodyRows.push(titleHeaderRow);
          bodyRows.push(...transformedSamplingRows);
        } else {
          titleHeaderRow.push({
            text: 'Site',
            color: '#444F51',
            fontSize: 11,
            margin: [8, 6, 8, 6],
            alignment: 'left',
          });
          titleHeaderRow.push({
            text: 'Target',
            color: '#444F51',
            fontSize: 11,
            margin: [8, 6, 8, 6],
            alignment: 'right',
          });
          totalColumn += 2;
          dirtySamplingRows.push({
            siteName: 'All Sites',
            values: [
              criteriaDetails.criteriaSamplingDataConfiguration.sampleSize,
            ],
          });
          criteriaDetails.siteSamplingConfiguration.forEach(
            (siteSampling: any) => {
              dirtySamplingRows.push({
                siteName: siteSampling.auditSiteMap.site.name,
                values: [siteSampling.sampleSize],
              });
            },
          );
          dirtySamplingRows.forEach((samplingData) => {
            const samplingRows: any[] = [];
            samplingRows.push({
              text: samplingData.siteName,
              color: '#444F51',
              fontSize: 12,
              alignment: 'left',
              margin: [8, 6, 8, 6],
            });
            samplingData.values.forEach((value: any) => {
              samplingRows.push({
                text: value,
                color: '#444F51',
                fontSize: 12,
                alignment: 'right',
                margin: [8, 6, 8, 6],
              });
            });
            transformedSamplingRows.push(samplingRows);
          });
          bodyRows.push(titleHeaderRow);
          bodyRows.push(...transformedSamplingRows);
        }
        break;
      default:
        titleHeaderRow.push({
          text: 'Site',
          color: '#444F51',
          fontSize: 11,
          margin: [8, 6, 8, 6],
          alignment: 'left',
        });
        titleHeaderRow.push({
          text: 'Target',
          color: '#444F51',
          fontSize: 11,
          margin: [8, 6, 8, 6],
          alignment: 'right',
        });
        totalColumn += 2;
        dirtySamplingRows.push({
          siteName: 'All Sites',
          values: [
            criteriaDetails.criteriaSamplingDataConfiguration.sampleSize,
          ],
        });
        criteriaDetails.siteSamplingConfiguration.forEach(
          (siteSampling: any) => {
            dirtySamplingRows.push({
              siteName: siteSampling.auditSiteMap.site.name,
              values: [siteSampling.sampleSize],
            });
          },
        );
        dirtySamplingRows.forEach((samplingData) => {
          const samplingRows: any[] = [];
          samplingRows.push({
            text: samplingData.siteName,
            color: '#444F51',
            fontSize: 12,
            alignment: 'left',
            margin: [8, 6, 8, 6],
          });
          samplingData.values.forEach((value: any) => {
            samplingRows.push({
              text: value,
              color: '#444F51',
              fontSize: 12,
              alignment: 'right',
              margin: [8, 6, 8, 6],
            });
          });
          transformedSamplingRows.push(samplingRows);
        });
        bodyRows.push(titleHeaderRow);
        bodyRows.push(...transformedSamplingRows);
    }
    const samplingTableData = {
      table: {
        unbreakable: true,
        widths: [...Array(totalColumn).keys()].map((temp) => '*'),
        body: [...bodyRows],
      },
      layout: {
        hLineWidth: () => {
          return 0.5;
        },
        vLineWidth: () => {
          return 0.5;
        },
        hLineColor: () => {
          return '#DBDBDB';
        },
        vLineColor: () => {
          return '#DBDBDB';
        },
      },
    };
    const stack: any[] = [samplingTableData];
    const criteriaSamplingTable = {
      stack,
      unbreakable: true,
    };
    dirtySamplingTables.push(criteriaSamplingTable);
    const divider = {
      canvas: [
        {
          type: 'line',
          x1: 0,
          y1: 15,
          x2: 445,
          y2: 15,
          lineWidth: 0.5,
          lineColor: '#DBDBDB',
        },
      ],
      margin: [0, 0, 0, 13],
    };
    dirtySamplingTables[0].stack.unshift(samplingTitle);
    dirtySamplingTables[0].stack.push(divider);
    return dirtySamplingTables;
  }

  public transformLabel(label: string): string | string[] {
    const words = label.split(' ');
    let endIndex: number = labelSplitIndex;
    let eachLine: string = '';
    const eachLabel: string[] = [];
    _map(words, (word: string, wordIndex: string) => {
      switch (Number(wordIndex)) {
        case 0:
          eachLine = word;
          break;
        case endIndex:
          eachLabel.push(eachLine);
          eachLine = word;
          endIndex += labelSplitIndex;
          break;
        case words.length - 1:
          eachLine += ' ' + word;
          eachLabel.push(eachLine);
          break;
        default:
          eachLine += ' ' + word;
      }
    });
    if (eachLabel.length >= 3) {
      eachLabel[2] = eachLabel[2] + '...';
      return eachLabel.slice(0, 3);
    }
    return label;
  }

  public generateConfigurationData(
    configurationData: any[],
    payload: any,
    samplingConfiguration: any,
  ) {
    let aggregateCriteriaConfiguration: any[];
    if (payload.checkIndividualSite) {
      if (Array.isArray(configurationData) && configurationData.length > 0) {
        const aggregateConfig = configurationData.map((data: any) => {
          const criteriaSamplingData = data.criteriaSamplingData.filter(
            (item: any) => {
              const criteriaOptionsDataDistribution =
                item.criteriaOptionsDataDistribution;
              const dirtyCriteriaSamplingData = Object.keys(
                criteriaOptionsDataDistribution,
              ).map((key: string) => ({
                [key]:
                  data.criteriaSamplingData[0].criteriaOptionsDataDistribution[
                    key
                  ],
              }));
              return dirtyCriteriaSamplingData;
            },
          );
          return {
            title: _get(data, 'criteria.title', ''),
            criteriaType: _get(data, 'criteria.criteriaType', ''),
            sampleSize: Number(
              _get(data, 'criteriaSamplingDataConfigurations.sampleSize', 0),
            ),
            siteSamplingConfiguration: data.siteSamplingDataConfigurations,
            criteriaSamplingDataConfiguration:
              data.criteriaSamplingDataConfigurations,
            samplingConfiguration,
            criteriaSamplingData,
          };
        });
        aggregateCriteriaConfiguration = _cloneDeep(aggregateConfig);
      } else {
        aggregateCriteriaConfiguration = [];
      }
    } else {
      if (Array.isArray(configurationData) && configurationData.length > 0) {
        const aggregateConfig = configurationData.map((data: any) => {
          const criteriaOptionsDataDistribution =
            data.criteriaSamplingData.criteriaOptionsDataDistribution;
          const criteriaSamplingData = Object.keys(
            criteriaOptionsDataDistribution,
          ).map((key: string) => ({
            [key]:
              data.criteriaSamplingData.criteriaOptionsDataDistribution[key],
          }));
          return {
            title: _get(data, 'criteria.title', ''),
            criteriaType: _get(data, 'criteria.criteriaType', ''),
            sampleSize: Number(
              _get(data, 'criteriaSamplingDataConfigurations.sampleSize', 0),
            ),
            siteSamplingConfiguration: data.siteSamplingDataConfigurations,
            criteriaSamplingDataConfiguration:
              data.criteriaSamplingDataConfigurations,
            samplingConfiguration,
            criteriaSamplingData,
          };
        });
        aggregateCriteriaConfiguration = _cloneDeep(aggregateConfig);
      } else {
        aggregateCriteriaConfiguration = [];
      }
    }
    const {
      individualBooleanCriteriaConfiguration,
      individualMixedCriteriaConfiguration,
    } = this.generateIndividualCriteriaSet(aggregateCriteriaConfiguration);
    return {
      aggregateCriteriaConfiguration,
      individualBooleanCriteriaConfiguration,
      individualMixedCriteriaConfiguration,
    };
  }

  public getAggregateCriteriaChartCategoryPercentage(
    individualBooleanCriteriaConfiguration: any[],
  ): number {
    const noOfBooleanCriterias: number =
      individualBooleanCriteriaConfiguration.length;
    return noOfBooleanCriterias <= 10 ? noOfBooleanCriterias * 0.1 : 0.8;
  }

  public generateIndividualCriteriaSet(aggregateCriteriaConfiguration: any[]) {
    const individualBooleanCriteriaConfiguration = aggregateCriteriaConfiguration.filter(
      (item) => {
        return item.criteriaType === CRITERION_TYPE.BOOLEAN;
      },
    );
    const individualMixedCriteriaConfiguration = aggregateCriteriaConfiguration.filter(
      (item) => {
        return item.criteriaType !== CRITERION_TYPE.BOOLEAN;
      },
    );
    return {
      individualBooleanCriteriaConfiguration,
      individualMixedCriteriaConfiguration,
    };
  }

  public genderIndividualSiteIndividualCriteria(
    result: any,
    payload: any,
    auditSites: AuditSite[],
  ) {
    const allCharts: any[] = [];
    const renderBooleanTables: any[] = [];
    const renderBooleanSamplingTables: any[] = [];
    const renderMixedTables: any[] = [];
    const renderMixedSamplingTables: any[] = [];
    const dataSet: any[] = [];
    const dataSetOptions: any[] = [];
    const verticalBarChartDataSetOption: any = {
      animation: {
        duration: 0,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              max: 100,
              min: 0,
              fontSize: 20,
            },
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Compliance %',
              display: true,
              fontSize: 20,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Criteria',
              display: false,
            },
            ticks: {
              fontSize: 20,
              minRotation: 0,
              callback: (label: string, index: number) => {
                if (payload.checkIndividualCriteria) {
                  return this.transformLabel(label);
                } else {
                  if (payload.filteredCriterias.length > 4) {
                    return index + 1 + '.';
                  }
                  return this.transformLabel(label);
                }
              },
            },
          },
        ],
      },
      legend: {
        display: false,
        onHover: (e: any) => {
          e.target.style.cursor = 'pointer';
        },
        onLeave: (e: any) => {
          e.target.style.cursor = 'default';
        },
        labels: {
          boxWidth: 40,
          fontSize: 25,
        },
        align: 'start',
      },
      responsive: true,
      maintainAspectRatio: true,
    };
    const horizontalBarChartDataSetOption: any = {
      animation: {
        duration: 0,
      },
      scales: {
        xAxes: [
          {
            ticks: {
              beginAtZero: true,
              max: 100,
              min: 0,
              fontSize: 20,
            },
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Compliance %',
              display: true,
              fontSize: 20,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Criteria',
              display: false,
            },
            ticks: {
              fontSize: 20,
              minRotation: 0,
              callback: (label: string, index: number) => {
                if (payload.checkIndividualCriteria) {
                  return this.transformLabel(label);
                } else {
                  if (payload.filteredCriterias.length > 4) {
                    return index + 1 + '.';
                  }
                  return this.transformLabel(label);
                }
              },
            },
          },
        ],
      },
      legend: {
        display: false,
        onHover: (e: any) => {
          e.target.style.cursor = 'pointer';
        },
        onLeave: (e: any) => {
          e.target.style.cursor = 'default';
        },
        labels: {
          boxWidth: 40,
          fontSize: 25,
        },
        align: 'start',
      },
      responsive: true,
      maintainAspectRatio: true,
    };
    const {
      aggregateCriteriaConfiguration,
      individualBooleanCriteriaConfiguration,
      individualMixedCriteriaConfiguration,
    } = this.generateConfigurationData(
      result.statisticData.configurationData,
      payload,
      result.statisticData.samplingConfiguration,
    );
    const chartData = result.chartData;
    const backgroundColors: string[] = [];
    _map(payload.filteredSites, () => {
      backgroundColors.push(primaryChartColor);
    });
    Object.keys(chartData).forEach((key: string) => {
      const data = {
        labels: [] as string[],
        datasets: [] as any[],
      };
      let dataOptions = {};
      const dirtyChartData = chartData[Number(key)];
      const label: string[] = [];
      const chartValue: number[] = [];
      _map(dirtyChartData.data, (dirtyData) => {
        label.push(dirtyData.site.name);
        chartValue.push(dirtyData.compliance);
      });
      const dataSetObject = {
        label: dirtyChartData.criteria.title,
        backgroundColor: backgroundColors,
        pointBackgroundColor: 'white',
        borderWidth: 1,
        pointBorderColor: '#249EBF',
        data: chartValue,
        categoryPercentage: this.getIndividualCriteriaIndividualSiteChartCategoryPercentage(
          payload,
        ),
      };
      let dataOptionsObject;
      if (payload.selectedChartType === ChartType.verticalBarChart) {
        dataOptionsObject = _cloneDeep(verticalBarChartDataSetOption);
      } else {
        dataOptionsObject = _cloneDeep(horizontalBarChartDataSetOption);
      }
      data.labels = _cloneDeep(label);
      data.datasets.push(dataSetObject);
      dataOptions = _cloneDeep(dataOptionsObject);
      dataSet.push(data);
      dataSetOptions.push(dataOptions);
    });
    dataSet.forEach((individualSet, individualSetIndex) => {
      const renderChart = {
        width: 443,
        height: 250,
        image: '',
        alignment: 'center',
        margin: [0, 0, 0, 30],
        unbreakable: true,
      };
      const canvas = document.createElement('canvas');
      document.body.appendChild(canvas);
      if (payload.selectedChartType === ChartType.verticalBarChart) {
        const verticalChart = new Chart(
          canvas.getContext('2d') as CanvasRenderingContext2D,
          {
            type: 'bar',
            data: {
              labels: individualSet.labels,
              datasets: individualSet.datasets,
            },
            options: dataSetOptions[individualSetIndex],
            plugins: [
              {
                afterDatasetDraw(chart: any, args: any) {
                  args.meta.data.forEach((element: any) => {
                    if (element._model.base - element._model.y < 0) {
                      const borderWidth = 8;
                      const ctx = chart.ctx;
                      const vm = element._view;
                      const half = vm.width / 2;
                      const left = vm.x - half;
                      const right = vm.x + half;
                      const top = vm.y - 2;
                      const width = right - left;
                      const height =
                        chart.chartArea.bottom - top + borderWidth / 2 - 1;
                      ctx.beginPath();
                      ctx.lineWidth = borderWidth;
                      ctx.strokeStyle = '#000000';
                      ctx.setLineDash([3, 4]);
                      ctx.moveTo(left, top);
                      ctx.lineTo(left, top + height);
                      ctx.moveTo(left, top);
                      ctx.lineTo(left + width, top);
                      ctx.moveTo(left + width, top);
                      ctx.lineTo(left + width, top + height);
                      ctx.stroke();
                      ctx.save();
                    }
                  });
                },
              },
            ],
          },
        );
        const base64Image = verticalChart.toBase64Image();
        renderChart.image = base64Image.split(',')[1];
      } else {
        const horizontalChart = new Chart(
          canvas.getContext('2d') as CanvasRenderingContext2D,
          {
            type: 'horizontalBar',
            data: {
              labels: individualSet.labels,
              datasets: individualSet.datasets,
            },
            options: dataSetOptions[individualSetIndex],
            plugins: [
              {
                afterDatasetDraw(chart: any, args: any) {
                  args.meta.data.forEach((element: any) => {
                    if (element._model.base - element._model.x > 0) {
                      const borderWidth = 8;
                      const ctx = chart.ctx;
                      const vm = element._view;
                      const half = vm.height / 2;
                      const top = vm.y - half;
                      const bottom = vm.y + half;
                      const right = vm.x + 10;
                      const left = chart.chartArea.left;
                      ctx.beginPath();
                      ctx.lineWidth = borderWidth;
                      ctx.strokeStyle = '#000000';
                      ctx.setLineDash([4, 5]);
                      ctx.moveTo(left, top);
                      ctx.lineTo(right, top);
                      ctx.moveTo(right, top);
                      ctx.lineTo(right, bottom);
                      ctx.moveTo(right, bottom);
                      ctx.lineTo(left, bottom);
                      ctx.stroke();
                      ctx.save();
                    }
                  });
                },
              },
            ],
          },
        );
        const base64Image = horizontalChart.toBase64Image();
        renderChart.image = base64Image.split(',')[1];
      }
      const stack: any[] = [renderChart];
      const criteriaChart = {
        stack,
        unbreakable: true,
      };
      allCharts.push(criteriaChart);
      document.body.removeChild(canvas);
    });
    // generate tables
    const siteNameChunks: any[] = [];
    const dirtySiteNameChunks = _cloneDeep(
      this.getSiteNames(auditSites, payload.filteredSites),
    );
    while (dirtySiteNameChunks.length) {
      siteNameChunks.push(dirtySiteNameChunks.splice(0, 2));
    }
    individualBooleanCriteriaConfiguration.forEach(
      (booleanCriteriaDetails, booleanCriteriaDetailsIndex) => {
        const dirtyBooleanTables: any[] = [];
        const booleanCriteriaSitesChunks: any[] = [];
        const dirtyBooleanCriteriaSitesDetails = _cloneDeep(
          booleanCriteriaDetails.criteriaSamplingData,
        );
        while (dirtyBooleanCriteriaSitesDetails.length) {
          booleanCriteriaSitesChunks.push(
            dirtyBooleanCriteriaSitesDetails.splice(0, 2),
          );
        }
        const title = {
          text:
            booleanCriteriaDetailsIndex +
            1 +
            '. ' +
            booleanCriteriaDetails.title,
          bold: true,
          color: '#444F51',
          fontSize: 11,
          margin: [0, 0, 0, 17],
        };
        booleanCriteriaSitesChunks.forEach(
          (
            booleanCriteriaSiteOptionDetails,
            booleanCriteriaSiteOptionDetailsIndex,
          ) => {
            switch (booleanCriteriaSiteOptionDetails.length) {
              case 1:
                const bodyRowsSingle: any[][] = [];
                const optionKeysSingle: string[] = Object.keys(
                  booleanCriteriaSiteOptionDetails[0]
                    .criteriaOptionsDataDistribution,
                );
                optionKeysSingle.forEach(
                  (optionKeySingle, optionKeySingleIndex) => {
                    const key = optionKeySingle;
                    const rowDataSingle: any[] = [];
                    rowDataSingle.push({
                      text: optionKeySingle,
                      color: '#444F51',
                      fontSize: 12,
                      border: [true, false, true, false],
                      margin:
                        optionKeySingleIndex === optionKeysSingle.length - 1
                          ? [8, 6, 0, 6]
                          : [8, 6, 0, 0],
                    });
                    rowDataSingle.push({
                      text:
                        booleanCriteriaSiteOptionDetails[0]
                          .criteriaOptionsDataDistribution[key],
                      color: '#444F51',
                      fontSize: 12,
                      border: [true, false, true, false],
                      alignment: 'right',
                      margin:
                        optionKeySingleIndex === optionKeysSingle.length - 1
                          ? [0, 6, 8, 6]
                          : [0, 6, 8, 0],
                    });
                    bodyRowsSingle.push(rowDataSingle);
                  },
                );
                let singleTotal: number | string = 0;
                let singleCompliance: any = 0;
                bodyRowsSingle.forEach((optionValues) => {
                  if (optionValues[1].text === '-') {
                    singleTotal = '-';
                  } else {
                    singleTotal += optionValues[1].text;
                  }
                });
                bodyRowsSingle.push([
                  {
                    text: 'Total Data Collected',
                    color: '#444F51',
                    fontSize: 12,
                    margin: [8, 6, 0, 6],
                  },
                  {
                    text: singleTotal,
                    color: '#444F51',
                    fontSize: 12,
                    alignment: 'right',
                    margin: [0, 6, 8, 6],
                  },
                ]);
                if (bodyRowsSingle[0][1].text === 0) {
                  bodyRowsSingle.push([
                    {
                      text: 'Compliance',
                      color: '#444F51',
                      fontSize: 12,
                      margin: [8, 6, 0, 6],
                      bold: true,
                    },
                    {
                      text: singleCompliance + '%',
                      color: '#444F51',
                      fontSize: 12,
                      alignment: 'right',
                      bold: true,
                      margin: [0, 6, 8, 6],
                    },
                  ]);
                } else {
                  if (bodyRowsSingle[0][1].text === '-') {
                    singleCompliance = '-';
                  } else {
                    singleCompliance =
                      (bodyRowsSingle[0][1].text /
                        (singleTotal - bodyRowsSingle[2][1].text)) *
                      100;
                  }
                  bodyRowsSingle.push([
                    {
                      text: 'Compliance',
                      color: '#444F51',
                      fontSize: 12,
                      margin: [8, 6, 0, 6],
                      bold: true,
                    },
                    {
                      text:
                        singleCompliance !== '-'
                          ? Math.round(singleCompliance * 100) / 100 + '%'
                          : singleCompliance,
                      color: '#444F51',
                      fontSize: 12,
                      alignment: 'right',
                      bold: true,
                      margin: [0, 6, 8, 6],
                    },
                  ]);
                }
                const singleTableData = {
                  table: {
                    unbreakable: true,
                    widths: ['*', 150],
                    body: [
                      [
                        {
                          text: 'Answer Choices',
                          color: '#9F9F9F',
                          fontSize: 11,
                          margin: [8, 10, 0, 10],
                        },
                        {
                          text:
                            siteNameChunks[
                              booleanCriteriaSiteOptionDetailsIndex
                            ][0],
                          color: '#9F9F9F',
                          fontSize: 11,
                          alignment: 'right',
                          margin: [0, 10, 8, 10],
                        },
                      ],
                      ...bodyRowsSingle,
                    ],
                  },
                  layout: {
                    hLineWidth: () => {
                      return 0.5;
                    },
                    vLineWidth: () => {
                      return 0.5;
                    },
                    hLineColor: () => {
                      return '#DBDBDB';
                    },
                    vLineColor: () => {
                      return '#DBDBDB';
                    },
                  },
                };
                const singleStack: any[] = [singleTableData];
                const criteriaSingleTable = {
                  stack: singleStack,
                  unbreakable: true,
                };
                dirtyBooleanTables.push(criteriaSingleTable);
                break;
              default:
                const bodyRows: any[] = [];
                const valueRows: any[] = [];
                const totalRow: number[] = [];
                const complianceRow: any[] = [];
                booleanCriteriaSiteOptionDetails.forEach(
                  (siteOptionDetails: any) => {
                    const periodRow: any[] = [];
                    const optionKeys: string[] = Object.keys(
                      siteOptionDetails.criteriaOptionsDataDistribution,
                    );
                    optionKeys.forEach((optionKey) => {
                      const rowData: any = {
                        option: '',
                        value: null,
                      };
                      rowData.option = optionKey;
                      rowData.value =
                        siteOptionDetails.criteriaOptionsDataDistribution[
                          optionKey
                        ];
                      periodRow.push(rowData);
                    });
                    valueRows.push(periodRow);
                    let total: number | string = 0;
                    periodRow.forEach((optionValues) => {
                      if (optionValues.value === '-') {
                        total = '-';
                      } else {
                        total += optionValues.value;
                      }
                    });
                    totalRow.push(total);
                    let compliance = 0;
                    if (
                      booleanCriteriaDetails.criteriaType ===
                      CRITERION_TYPE.BOOLEAN
                    ) {
                      if (periodRow[0].value === 0) {
                        complianceRow.push(compliance);
                      } else {
                        if (periodRow[0].value === '-') {
                          complianceRow.push('-');
                        } else {
                          compliance =
                            (periodRow[0].value /
                              (total - periodRow[2].value)) *
                            100;
                          complianceRow.push(
                            Math.round(compliance * 100) / 100,
                          );
                        }
                      }
                    }
                  },
                );
                const periodOne = valueRows[0];
                const periodTwo = valueRows[1];
                for (let step = 0; step < periodOne.length; step++) {
                  const optionColumnOne = {
                    text: periodOne[step].option,
                    color: '#444F51',
                    fontSize: 12,
                    border: [true, false, true, false],
                    margin:
                      step === periodOne.length - 1
                        ? [8, 6, 0, 6]
                        : [8, 6, 0, 0],
                  };
                  const optionColumnTwo = {
                    text: periodOne[step].value,
                    color: '#444F51',
                    fontSize: 12,
                    border: [true, false, true, false],
                    alignment: 'right',
                    margin:
                      step === periodOne.length - 1
                        ? [0, 6, 8, 6]
                        : [0, 6, 8, 0],
                  };
                  const optionColumnThree = {
                    text: periodTwo[step].value,
                    color: '#444F51',
                    fontSize: 12,
                    border: [true, false, true, false],
                    alignment: 'right',
                    margin:
                      step === periodOne.length - 1
                        ? [0, 6, 8, 6]
                        : [0, 6, 8, 0],
                  };
                  bodyRows.push([
                    optionColumnOne,
                    optionColumnTwo,
                    optionColumnThree,
                  ]);
                }
                const totalColumnOne = {
                  text: 'Total Data Collected',
                  color: '#444F51',
                  fontSize: 12,
                  margin: [8, 6, 0, 6],
                };
                const totalColumnTwo = {
                  text: totalRow[0],
                  color: '#444F51',
                  fontSize: 12,
                  alignment: 'right',
                  margin: [0, 6, 8, 6],
                };
                const totalColumnThree = {
                  text: totalRow[1],
                  color: '#444F51',
                  fontSize: 12,
                  alignment: 'right',
                  margin: [0, 6, 8, 6],
                };
                bodyRows.push([
                  totalColumnOne,
                  totalColumnTwo,
                  totalColumnThree,
                ]);
                const complianceColumnOne = {
                  text: 'Compliance',
                  color: '#444F51',
                  fontSize: 12,
                  margin: [8, 6, 0, 6],
                  bold: true,
                };
                const complianceColumnTwo = {
                  text:
                    complianceRow[0] !== '-'
                      ? complianceRow[0] + '%'
                      : complianceRow[0],
                  color: '#444F51',
                  fontSize: 12,
                  alignment: 'right',
                  bold: true,
                  margin: [0, 6, 8, 6],
                };
                const complianceColumnThree = {
                  text:
                    complianceRow[1] !== '-'
                      ? complianceRow[1] + '%'
                      : complianceRow[1],
                  color: '#444F51',
                  fontSize: 12,
                  alignment: 'right',
                  bold: true,
                  margin: [0, 6, 8, 6],
                };
                bodyRows.push([
                  complianceColumnOne,
                  complianceColumnTwo,
                  complianceColumnThree,
                ]);
                const tableData = {
                  table: {
                    unbreakable: true,
                    widths: ['*', 150, 150],
                    body: [
                      [
                        {
                          text: 'Answer Choices',
                          color: '#9F9F9F',
                          fontSize: 11,
                          margin: [8, 10, 0, 10],
                        },
                        {
                          text:
                            siteNameChunks[
                              booleanCriteriaSiteOptionDetailsIndex
                            ][0],
                          color: '#9F9F9F',
                          fontSize: 11,
                          alignment: 'right',
                          margin: [0, 10, 8, 10],
                        },
                        {
                          text:
                            siteNameChunks[
                              booleanCriteriaSiteOptionDetailsIndex
                            ][1],
                          color: '#9F9F9F',
                          fontSize: 11,
                          alignment: 'right',
                          margin: [0, 10, 8, 10],
                        },
                      ],
                      ...bodyRows,
                    ],
                  },
                  layout: {
                    hLineWidth: () => {
                      return 0.5;
                    },
                    vLineWidth: () => {
                      return 0.5;
                    },
                    hLineColor: () => {
                      return '#DBDBDB';
                    },
                    vLineColor: () => {
                      return '#DBDBDB';
                    },
                  },
                };
                const stack: any[] = [tableData];
                const criteriaTable = {
                  stack,
                  unbreakable: true,
                };
                dirtyBooleanTables.push(criteriaTable);
                break;
            }
          },
        );
        const setMargin = {
          canvas: [],
          margin: [0, 0, 0, 13],
        };
        allCharts[booleanCriteriaDetailsIndex].stack.unshift(title);
        switch (dirtyBooleanTables.length) {
          case 1:
            dirtyBooleanTables[0].stack.push(setMargin);
            break;
          case 2:
            dirtyBooleanTables[0].stack.push(setMargin);
            break;
          default:
            dirtyBooleanTables.forEach(
              (dirtyBooleanTable, dirtyBooleanTableIndex) => {
                switch (dirtyBooleanTableIndex) {
                  case 0:
                    dirtyBooleanTable.stack.push(setMargin);
                    break;
                  case dirtyBooleanTables.length - 1:
                    dirtyBooleanTable.stack.push(setMargin);
                    break;
                  default:
                    dirtyBooleanTable.stack.push(setMargin);
                }
              },
            );
        }
        renderBooleanTables.push(dirtyBooleanTables);
      },
    );
    individualBooleanCriteriaConfiguration.forEach(
      (criteriaDetails, criteriaDetailsIndex) => {
        const samplingTitle = {
          text: 'Sampling',
          bold: true,
          color: '#444F51',
          fontSize: 11,
          margin: [0, 13, 0, 17],
        };
        const dirtySamplingTables: any[] = this.getSamplingTables(
          criteriaDetails,
          samplingTitle,
        );
        renderBooleanSamplingTables.push(dirtySamplingTables[0]);
      },
    );
    if (individualMixedCriteriaConfiguration.length > 0) {
      individualMixedCriteriaConfiguration.forEach(
        (mixedCriteriaDetails, mixedCriteriaDetailsIndex) => {
          const dirtyMixedTables: any[] = [];
          const mixedCriteriaSitesChunks: any[] = [];
          const dirtyMixedCriteriaSitesDetails = _cloneDeep(
            mixedCriteriaDetails.criteriaSamplingData,
          );
          while (dirtyMixedCriteriaSitesDetails.length) {
            mixedCriteriaSitesChunks.push(
              dirtyMixedCriteriaSitesDetails.splice(0, 2),
            );
          }
          const title = {
            text:
              individualBooleanCriteriaConfiguration.length +
              mixedCriteriaDetailsIndex +
              1 +
              '. ' +
              mixedCriteriaDetails.title,
            bold: true,
            color: '#444F51',
            fontSize: 11,
            margin: [0, 0, 0, 17],
          };
          mixedCriteriaSitesChunks.forEach(
            (
              mixedCriteriaSiteOptionDetails,
              mixedCriteriaSiteOptionDetailsIndex,
            ) => {
              switch (mixedCriteriaSiteOptionDetails.length) {
                case 1:
                  const bodyRowsSingle: any[][] = [];
                  const optionKeysSingle: string[] = Object.keys(
                    mixedCriteriaSiteOptionDetails[0]
                      .criteriaOptionsDataDistribution,
                  );
                  optionKeysSingle.forEach(
                    (optionKeySingle, optionKeySingleIndex) => {
                      const key = optionKeySingle;
                      const rowDataSingle: any[] = [];
                      rowDataSingle.push({
                        text: optionKeySingle,
                        color: '#444F51',
                        fontSize: 12,
                        border: [true, false, true, false],
                        margin:
                          optionKeySingleIndex === optionKeysSingle.length - 1
                            ? [8, 6, 0, 6]
                            : [8, 6, 0, 0],
                      });
                      rowDataSingle.push({
                        text:
                          mixedCriteriaSiteOptionDetails[0]
                            .criteriaOptionsDataDistribution[key],
                        color: '#444F51',
                        fontSize: 12,
                        border: [true, false, true, false],
                        alignment: 'right',
                        margin:
                          optionKeySingleIndex === optionKeysSingle.length - 1
                            ? [0, 6, 8, 6]
                            : [0, 6, 8, 0],
                      });
                      bodyRowsSingle.push(rowDataSingle);
                    },
                  );
                  let singleTotal: number | string = 0;
                  bodyRowsSingle.forEach((optionValues) => {
                    if (optionValues[1].text === '-') {
                      singleTotal = '-';
                    } else {
                      singleTotal += optionValues[1].text;
                    }
                  });
                  bodyRowsSingle.push([
                    {
                      text: 'Total Data Collected',
                      color: '#444F51',
                      fontSize: 12,
                      margin: [8, 6, 0, 6],
                    },
                    {
                      text: singleTotal,
                      color: '#444F51',
                      fontSize: 12,
                      alignment: 'right',
                      margin: [0, 6, 8, 6],
                    },
                  ]);
                  const singleTableData = {
                    table: {
                      unbreakable: true,
                      widths: ['*', 150],
                      body: [
                        [
                          {
                            text: 'Answer Choices',
                            color: '#9F9F9F',
                            fontSize: 11,
                            margin: [8, 10, 0, 10],
                          },
                          {
                            text:
                              siteNameChunks[
                                mixedCriteriaSiteOptionDetailsIndex
                              ][0],
                            color: '#9F9F9F',
                            fontSize: 11,
                            alignment: 'right',
                            margin: [0, 10, 8, 10],
                          },
                        ],
                        ...bodyRowsSingle,
                      ],
                    },
                    layout: {
                      hLineWidth: () => {
                        return 0.5;
                      },
                      vLineWidth: () => {
                        return 0.5;
                      },
                      hLineColor: () => {
                        return '#DBDBDB';
                      },
                      vLineColor: () => {
                        return '#DBDBDB';
                      },
                    },
                  };
                  const singleStack: any[] = [singleTableData];
                  const criteriaSingleTable = {
                    stack: singleStack,
                    unbreakable: true,
                  };
                  dirtyMixedTables.push(criteriaSingleTable);
                  break;
                default:
                  const bodyRows: any[] = [];
                  const valueRows: any[] = [];
                  const totalRow: number[] = [];
                  mixedCriteriaSiteOptionDetails.forEach(
                    (siteOptionDetails: any) => {
                      const periodRow: any[] = [];
                      const optionKeys: string[] = Object.keys(
                        siteOptionDetails.criteriaOptionsDataDistribution,
                      );
                      optionKeys.forEach((optionKey) => {
                        const rowData: any = {
                          option: '',
                          value: null,
                        };
                        rowData.option = optionKey;
                        rowData.value =
                          siteOptionDetails.criteriaOptionsDataDistribution[
                            optionKey
                          ];
                        periodRow.push(rowData);
                      });
                      valueRows.push(periodRow);
                      let total: number | string = 0;
                      periodRow.forEach((optionValues) => {
                        if (optionValues.value === '-') {
                          total = '-';
                        } else {
                          total += optionValues.value;
                        }
                      });
                      totalRow.push(total);
                    },
                  );
                  const periodOne = valueRows[0];
                  const periodTwo = valueRows[1];
                  for (let step = 0; step < periodOne.length; step++) {
                    const optionColumnOne = {
                      text: periodOne[step].option,
                      color: '#444F51',
                      fontSize: 12,
                      border: [true, false, true, false],
                      margin:
                        step === periodOne.length - 1
                          ? [8, 6, 0, 6]
                          : [8, 6, 0, 0],
                    };
                    const optionColumnTwo = {
                      text: periodOne[step].value,
                      color: '#444F51',
                      fontSize: 12,
                      border: [true, false, true, false],
                      alignment: 'right',
                      margin:
                        step === periodOne.length - 1
                          ? [0, 6, 8, 6]
                          : [0, 6, 8, 0],
                    };
                    const optionColumnThree = {
                      text: periodTwo[step].value,
                      color: '#444F51',
                      fontSize: 12,
                      border: [true, false, true, false],
                      alignment: 'right',
                      margin:
                        step === periodOne.length - 1
                          ? [0, 6, 8, 6]
                          : [0, 6, 8, 0],
                    };
                    bodyRows.push([
                      optionColumnOne,
                      optionColumnTwo,
                      optionColumnThree,
                    ]);
                  }
                  const totalColumnOne = {
                    text: 'Total Data Collected',
                    color: '#444F51',
                    fontSize: 12,
                    margin: [8, 6, 0, 6],
                  };
                  const totalColumnTwo = {
                    text: totalRow[0],
                    color: '#444F51',
                    fontSize: 12,
                    alignment: 'right',
                    margin: [0, 6, 8, 6],
                  };
                  const totalColumnThree = {
                    text: totalRow[1],
                    color: '#444F51',
                    fontSize: 12,
                    alignment: 'right',
                    margin: [0, 6, 8, 6],
                  };
                  bodyRows.push([
                    totalColumnOne,
                    totalColumnTwo,
                    totalColumnThree,
                  ]);
                  const tableData = {
                    table: {
                      unbreakable: true,
                      widths: ['*', 150, 150],
                      body: [
                        [
                          {
                            text: 'Answer Choices',
                            color: '#9F9F9F',
                            fontSize: 11,
                            margin: [8, 10, 0, 10],
                          },
                          {
                            text:
                              siteNameChunks[
                                mixedCriteriaSiteOptionDetailsIndex
                              ][0],
                            color: '#9F9F9F',
                            fontSize: 11,
                            alignment: 'right',
                            margin: [0, 10, 8, 10],
                          },
                          {
                            text:
                              siteNameChunks[
                                mixedCriteriaSiteOptionDetailsIndex
                              ][1],
                            color: '#9F9F9F',
                            fontSize: 11,
                            alignment: 'right',
                            margin: [0, 10, 8, 10],
                          },
                        ],
                        ...bodyRows,
                      ],
                    },
                    layout: {
                      hLineWidth: () => {
                        return 0.5;
                      },
                      vLineWidth: () => {
                        return 0.5;
                      },
                      hLineColor: () => {
                        return '#DBDBDB';
                      },
                      vLineColor: () => {
                        return '#DBDBDB';
                      },
                    },
                  };
                  const stack: any[] = [tableData];
                  const criteriaTable = {
                    stack,
                    unbreakable: true,
                  };
                  dirtyMixedTables.push(criteriaTable);
                  break;
              }
            },
          );
          const setMargin = {
            canvas: [],
            margin: [0, 0, 0, 13],
          };
          switch (dirtyMixedTables.length) {
            case 1:
              dirtyMixedTables[0].stack.unshift(title);
              dirtyMixedTables[0].stack.push(setMargin);
              break;
            case 2:
              dirtyMixedTables[0].stack.unshift(title);
              dirtyMixedTables[0].stack.push(setMargin);
              break;
            default:
              dirtyMixedTables.forEach(
                (dirtyMixedTable, dirtyMixedTableIndex) => {
                  switch (dirtyMixedTableIndex) {
                    case 0:
                      dirtyMixedTables[0].stack.unshift(title);
                      dirtyMixedTable.stack.push(setMargin);
                      break;
                    case dirtyMixedTables.length - 1:
                      dirtyMixedTable.stack.push(setMargin);
                      break;
                    default:
                      dirtyMixedTable.stack.push(setMargin);
                  }
                },
              );
          }
          renderMixedTables.push(dirtyMixedTables);
        },
      );
      individualMixedCriteriaConfiguration.forEach(
        (criteriaDetails, criteriaDetailsIndex) => {
          const samplingTitle = {
            text: 'Sampling',
            bold: true,
            color: '#444F51',
            fontSize: 11,
            margin: [0, 13, 0, 17],
          };
          const dirtySamplingTables: any[] = this.getSamplingTables(
            criteriaDetails,
            samplingTitle,
          );
          renderMixedSamplingTables.push(dirtySamplingTables[0]);
        },
      );
    }
    return {
      allCharts,
      renderBooleanTables,
      renderBooleanSamplingTables,
      renderMixedTables,
      renderMixedSamplingTables,
    };
  }

  public getIndividualCriteriaIndividualSiteChartCategoryPercentage(
    payload: any,
  ): number {
    const noOfSites: number = payload.filteredSites.length;
    if (payload.selectedChartType === ChartType.verticalBarChart) {
      return noOfSites <= 10 ? noOfSites * 0.1 : 0.8;
    } else {
      return noOfSites <= 10 ? noOfSites * 0.2 : 0.8;
    }
  }

  public getSiteNames(
    auditSites: AuditSite[],
    filteredSiteIds: number[],
  ): string[] {
    const siteNames: any[] = [];
    filteredSiteIds.forEach((auditSiteId) => {
      const auditSite = auditSites.find((site: AuditSite) => {
        return site.id === auditSiteId;
      });
      if (auditSite) {
        siteNames.push(auditSite.site.name);
      }
    });
    return siteNames;
  }

  public generateAggregateSiteIndividualCriteria(
    result: any,
    payload: any,
    auditSites: AuditSite[],
  ) {
    const allCharts: any[] = [];
    const renderBooleanTables: any[] = [];
    const renderBooleanSamplingTables: any[] = [];
    const renderMixedTables: any[] = [];
    const renderMixedSamplingTables: any[] = [];
    const dataSet: any[] = [];
    const dataSetOptions: any[] = [];
    const verticalBarChartDataSetOption: any = {
      animation: {
        duration: 0,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              max: 100,
              min: 0,
              fontSize: 20,
            },
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Compliance %',
              display: true,
              fontSize: 20,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Criteria',
              display: false,
            },
            ticks: {
              fontSize: 20,
              minRotation: 0,
              callback: (label: string, index: number) => {
                if (payload.checkIndividualCriteria) {
                  return this.transformLabel(label);
                } else {
                  if (payload.filteredCriterias.length > 4) {
                    return index + 1 + '.';
                  }
                  return this.transformLabel(label);
                }
              },
            },
          },
        ],
      },
      legend: {
        display: false,
        onHover: (e: any) => {
          e.target.style.cursor = 'pointer';
        },
        onLeave: (e: any) => {
          e.target.style.cursor = 'default';
        },
        labels: {
          boxWidth: 40,
          fontSize: 25,
        },
        align: 'start',
      },
      responsive: true,
      maintainAspectRatio: true,
    };
    const horizontalBarChartDataSetOption: any = {
      animation: {
        duration: 0,
      },
      scales: {
        xAxes: [
          {
            ticks: {
              beginAtZero: true,
              max: 100,
              min: 0,
              fontSize: 20,
            },
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Compliance %',
              display: true,
              fontSize: 20,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Criteria',
              display: false,
            },
            ticks: {
              fontSize: 20,
              minRotation: 0,
              callback: (label: string, index: number) => {
                if (payload.checkIndividualCriteria) {
                  return this.transformLabel(label);
                } else {
                  if (payload.filteredCriterias.length > 4) {
                    return index + 1 + '.';
                  }
                  return this.transformLabel(label);
                }
              },
            },
          },
        ],
      },
      legend: {
        display: false,
        onHover: (e: any) => {
          e.target.style.cursor = 'pointer';
        },
        onLeave: (e: any) => {
          e.target.style.cursor = 'default';
        },
        labels: {
          boxWidth: 40,
          fontSize: 25,
        },
        align: 'start',
      },
      responsive: true,
      maintainAspectRatio: true,
    };
    const {
      aggregateCriteriaConfiguration,
      individualBooleanCriteriaConfiguration,
      individualMixedCriteriaConfiguration,
    } = this.generateConfigurationData(
      result.statisticData.configurationData,
      payload,
      result.statisticData.samplingConfiguration,
    );
    const chartData = result.chartData;
    let siteName: string;
    Object.keys(chartData).forEach((key: string) => {
      const data = {
        labels: [] as string[],
        datasets: [] as any[],
      };
      let dataOptions = {};
      const dirtyChartData = chartData[Number(key)];
      let label: string;
      switch (payload.filteredSites.length) {
        case 1:
          label = dirtyChartData.data[0].site.name;
          break;
        case auditSites.length:
          label = 'All Sites';
          break;
        default:
          label = 'Selected sites (' + payload.filteredSites.length + ')';
      }
      siteName = label;
      const chartValue: number = dirtyChartData.data[0].compliance;
      const dataSetObject = {
        label: dirtyChartData.criteria.title,
        backgroundColor: [primaryChartColor],
        pointBackgroundColor: 'white',
        borderWidth: 1,
        pointBorderColor: '#249EBF',
        data: [chartValue],
        categoryPercentage: this.getIndividualCriteriaAggregateSiteChartCategoryPercentage(
          payload,
        ),
      };
      let dataOptionsObject;
      if (payload.selectedChartType === ChartType.verticalBarChart) {
        dataOptionsObject = _cloneDeep(verticalBarChartDataSetOption);
      } else {
        dataOptionsObject = _cloneDeep(horizontalBarChartDataSetOption);
      }
      data.labels.push(label);
      data.datasets.push(dataSetObject);
      dataOptions = _cloneDeep(dataOptionsObject);
      dataSet.push(data);
      dataSetOptions.push(dataOptions);
    });
    dataSet.forEach((individualSet, individualSetIndex) => {
      const renderChart = {
        width: 443,
        height: 250,
        image: '',
        alignment: 'center',
        margin: [0, 0, 0, 30],
        unbreakable: true,
      };
      const canvas = document.createElement('canvas');
      document.body.appendChild(canvas);
      if (payload.selectedChartType === ChartType.verticalBarChart) {
        const verticalChart = new Chart(
          canvas.getContext('2d') as CanvasRenderingContext2D,
          {
            type: 'bar',
            data: {
              labels: individualSet.labels,
              datasets: individualSet.datasets,
            },
            options: dataSetOptions[individualSetIndex],
            plugins: [
              {
                afterDatasetDraw(chart: any, args: any) {
                  args.meta.data.forEach((element: any) => {
                    if (element._model.base - element._model.y < 0) {
                      const borderWidth = 8;
                      const ctx = chart.ctx;
                      const vm = element._view;
                      const half = vm.width / 2;
                      const left = vm.x - half;
                      const right = vm.x + half;
                      const top = vm.y - 2;
                      const width = right - left;
                      const height =
                        chart.chartArea.bottom - top + borderWidth / 2 - 1;
                      ctx.beginPath();
                      ctx.lineWidth = borderWidth;
                      ctx.strokeStyle = '#000000';
                      ctx.setLineDash([3, 4]);
                      ctx.moveTo(left, top);
                      ctx.lineTo(left, top + height);
                      ctx.moveTo(left, top);
                      ctx.lineTo(left + width, top);
                      ctx.moveTo(left + width, top);
                      ctx.lineTo(left + width, top + height);
                      ctx.stroke();
                      ctx.save();
                    }
                  });
                },
              },
            ],
          },
        );
        const base64Image = verticalChart.toBase64Image();
        renderChart.image = base64Image.split(',')[1];
      } else {
        const horizontalChart = new Chart(
          canvas.getContext('2d') as CanvasRenderingContext2D,
          {
            type: 'horizontalBar',
            data: {
              labels: individualSet.labels,
              datasets: individualSet.datasets,
            },
            options: dataSetOptions[individualSetIndex],
            plugins: [
              {
                afterDatasetDraw(chart: any, args: any) {
                  args.meta.data.forEach((element: any) => {
                    if (element._model.base - element._model.x > 0) {
                      const borderWidth = 8;
                      const ctx = chart.ctx;
                      const vm = element._view;
                      const half = vm.height / 2;
                      const top = vm.y - half;
                      const bottom = vm.y + half;
                      const right = vm.x + 10;
                      const left = chart.chartArea.left;
                      ctx.beginPath();
                      ctx.lineWidth = borderWidth;
                      ctx.strokeStyle = '#000000';
                      ctx.setLineDash([4, 5]);
                      ctx.moveTo(left, top);
                      ctx.lineTo(right, top);
                      ctx.moveTo(right, top);
                      ctx.lineTo(right, bottom);
                      ctx.moveTo(right, bottom);
                      ctx.lineTo(left, bottom);
                      ctx.stroke();
                      ctx.save();
                    }
                  });
                },
              },
            ],
          },
        );
        const base64Image = horizontalChart.toBase64Image();
        renderChart.image = base64Image.split(',')[1];
      }
      const stack: any[] = [renderChart];
      const criteriaChart = {
        stack,
        unbreakable: true,
      };
      allCharts.push(criteriaChart);
      document.body.removeChild(canvas);
    });
    if (individualBooleanCriteriaConfiguration.length !== 0) {
      // generate boolean criteria tables
      individualBooleanCriteriaConfiguration.forEach(
        (criteriaDetails: any, criteriaDetailsIndex: number) => {
          const renderTable: any[] = [];
          const title = {
            text: criteriaDetailsIndex + 1 + '. ' + criteriaDetails.title,
            bold: true,
            color: '#444F51',
            fontSize: 11,
            margin: [0, 0, 0, 17],
          };
          const bodyRows: any[][] = [];
          criteriaDetails.criteriaSamplingData.forEach(
            (optionsData: any, optionDataIndex: number) => {
              const rowData: any[] = [];
              const option = Object.keys(optionsData)[0];
              rowData.push({
                text: option,
                color: '#444F51',
                fontSize: 12,
                border: [true, false, true, false],
                margin:
                  optionDataIndex ===
                  criteriaDetails.criteriaSamplingData.length - 1
                    ? [8, 6, 0, 6]
                    : [8, 6, 0, 0],
              });
              rowData.push({
                text: optionsData[option],
                color: '#444F51',
                fontSize: 12,
                border: [true, false, true, false],
                alignment: 'right',
                margin:
                  optionDataIndex ===
                  criteriaDetails.criteriaSamplingData.length - 1
                    ? [0, 6, 8, 6]
                    : [0, 6, 8, 0],
              });
              bodyRows.push(rowData);
            },
          );
          let total: number | string = 0;
          let compliance: any = 0;
          bodyRows.forEach((optionValues) => {
            if (optionValues[1].text === '-') {
              total = '-';
            } else {
              total += optionValues[1].text;
            }
          });
          bodyRows.push([
            {
              text: 'Total Data Collected',
              color: '#444F51',
              fontSize: 12,
              margin: [8, 6, 0, 6],
            },
            {
              text: total,
              color: '#444F51',
              fontSize: 12,
              alignment: 'right',
              margin: [0, 6, 8, 6],
            },
          ]);
          if (bodyRows[0][1].text === 0) {
            bodyRows.push([
              {
                text: 'Compliance',
                color: '#444F51',
                fontSize: 12,
                margin: [8, 6, 0, 6],
                bold: true,
              },
              {
                text: compliance + '%',
                color: '#444F51',
                fontSize: 12,
                alignment: 'right',
                bold: true,
                margin: [0, 6, 8, 6],
              },
            ]);
          } else {
            if (bodyRows[0][1].text === '-') {
              compliance = '-';
            } else {
              compliance =
                (bodyRows[0][1].text / (total - bodyRows[2][1].text)) * 100;
            }
            bodyRows.push([
              {
                text: 'Compliance',
                color: '#444F51',
                fontSize: 12,
                margin: [8, 6, 0, 6],
                bold: true,
              },
              {
                text:
                  compliance !== '-'
                    ? Math.round(compliance * 100) / 100 + '%'
                    : compliance,
                color: '#444F51',
                fontSize: 12,
                alignment: 'right',
                bold: true,
                margin: [0, 6, 8, 6],
              },
            ]);
          }
          const tableData = {
            table: {
              unbreakable: true,
              widths: ['*', 100],
              body: [
                [
                  {
                    text: 'Answer Choices',
                    color: '#9F9F9F',
                    fontSize: 11,
                    margin: [8, 10, 0, 10],
                  },
                  {
                    text: siteName,
                    color: '#9F9F9F',
                    fontSize: 11,
                    alignment: 'right',
                    margin: [0, 10, 8, 10],
                  },
                ],
                ...bodyRows,
              ],
            },
            layout: {
              hLineWidth: () => {
                return 0.5;
              },
              vLineWidth: () => {
                return 0.5;
              },
              hLineColor: () => {
                return '#DBDBDB';
              },
              vLineColor: () => {
                return '#DBDBDB';
              },
            },
          };
          allCharts[criteriaDetailsIndex].stack.unshift(title);
          const stack: any[] = [tableData];
          const criteriaTable = {
            stack,
            unbreakable: true,
          };
          renderTable.push(criteriaTable);
          renderBooleanTables.push(renderTable);
        },
      );
      individualBooleanCriteriaConfiguration.forEach(
        (criteriaDetails, criteriaDetailsIndex) => {
          const samplingTitle = {
            text: 'Sampling',
            bold: true,
            color: '#444F51',
            fontSize: 11,
            margin: [0, 13, 0, 17],
          };
          const dirtySamplingTables: any[] = this.getSamplingTables(
            criteriaDetails,
            samplingTitle,
          );
          renderBooleanSamplingTables.push(dirtySamplingTables[0]);
        },
      );
    }
    // generate mixed criteria tables
    if (individualMixedCriteriaConfiguration.length !== 0) {
      individualMixedCriteriaConfiguration.forEach(
        (criteriaDetails: any, criteriaDetailsIndex: number) => {
          const renderTable: any[] = [];
          const title = {
            text:
              individualBooleanCriteriaConfiguration.length +
              criteriaDetailsIndex +
              1 +
              '. ' +
              criteriaDetails.title,
            bold: true,
            color: '#444F51',
            fontSize: 11,
            margin: [0, 0, 0, 17],
          };
          const bodyRows: any[][] = [];
          criteriaDetails.criteriaSamplingData.forEach(
            (optionsData: any, optionDataIndex: number) => {
              const rowData: any[] = [];
              const option = Object.keys(optionsData)[0];
              rowData.push({
                text: option,
                color: '#444F51',
                fontSize: 12,
                border: [true, false, true, false],
                margin:
                  optionDataIndex ===
                  criteriaDetails.criteriaSamplingData.length - 1
                    ? [8, 6, 0, 6]
                    : [8, 6, 0, 0],
              });
              rowData.push({
                text: optionsData[option],
                color: '#444F51',
                fontSize: 12,
                border: [true, false, true, false],
                alignment: 'right',
                margin:
                  optionDataIndex ===
                  criteriaDetails.criteriaSamplingData.length - 1
                    ? [0, 6, 8, 6]
                    : [0, 6, 8, 0],
              });
              bodyRows.push(rowData);
            },
          );
          let total: number | string = 0;
          bodyRows.forEach((optionValues) => {
            if (optionValues[1].text === '-') {
              total = '-';
            } else {
              total += optionValues[1].text;
            }
          });
          bodyRows.push([
            {
              text: 'Total Data Collected',
              color: '#444F51',
              fontSize: 12,
              margin: [8, 6, 0, 6],
            },
            {
              text: total,
              color: '#444F51',
              fontSize: 12,
              alignment: 'right',
              margin: [0, 6, 8, 6],
            },
          ]);
          const tableData = {
            table: {
              unbreakable: true,
              widths: ['*', 100],
              body: [
                [
                  {
                    text: 'Answer Choices',
                    color: '#9F9F9F',
                    fontSize: 11,
                    margin: [8, 10, 0, 10],
                  },
                  {
                    text: siteName,
                    color: '#9F9F9F',
                    fontSize: 11,
                    alignment: 'right',
                    margin: [0, 10, 8, 10],
                  },
                ],
                ...bodyRows,
              ],
            },
            layout: {
              hLineWidth: () => {
                return 0.5;
              },
              vLineWidth: () => {
                return 0.5;
              },
              hLineColor: () => {
                return '#DBDBDB';
              },
              vLineColor: () => {
                return '#DBDBDB';
              },
            },
          };
          const stack: any[] = [title, tableData];
          const criteriaTable = {
            stack,
            unbreakable: true,
          };
          renderTable.push(criteriaTable);
          renderMixedTables.push(renderTable);
        },
      );
      individualMixedCriteriaConfiguration.forEach(
        (criteriaDetails, criteriaDetailsIndex) => {
          const samplingTitle = {
            text: 'Sampling',
            bold: true,
            color: '#444F51',
            fontSize: 11,
            margin: [0, 13, 0, 17],
          };
          const dirtySamplingTables: any[] = this.getSamplingTables(
            criteriaDetails,
            samplingTitle,
          );
          renderMixedSamplingTables.push(dirtySamplingTables[0]);
        },
      );
    }
    return {
      allCharts,
      renderBooleanTables,
      renderBooleanSamplingTables,
      renderMixedTables,
      renderMixedSamplingTables,
    };
  }

  public getIndividualCriteriaAggregateSiteChartCategoryPercentage(
    payload: any,
  ): number {
    if (payload.selectedChartType === ChartType.verticalBarChart) {
      return 0.1;
    } else {
      return 0.2;
    }
  }

  public generateIndividualSiteAggregateCriteria(
    result: any,
    payload: any,
    auditSites: AuditSite[],
  ) {
    const aggregateChart: any[] = [];
    const renderChart = {
      width: 443,
      height: 250,
      image: '',
      alignment: 'center',
      margin: [0, 0, 0, 30],
      unbreakable: true,
    };
    const renderTables: any[] = [];
    const renderSamplingTables: any[] = [];
    // generate chart
    const dataSet = [];
    const dataSetOptions = [];
    const verticalBarChartDataSetOption: any = {
      animation: {
        duration: 0,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              max: 100,
              min: 0,
              fontSize: 20,
            },
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Compliance %',
              display: true,
              fontSize: 20,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Criteria',
              display: false,
            },
            ticks: {
              fontSize: 20,
              minRotation: 0,
              callback: (label: string, index: number) => {
                if (payload.checkIndividualCriteria) {
                  return this.transformLabel(label);
                } else {
                  if (payload.filteredCriterias.length > 4) {
                    return index + 1 + '.';
                  }
                  return this.transformLabel(label);
                }
              },
            },
          },
        ],
      },
      legend: {
        display: false,
        onHover: (e: any) => {
          e.target.style.cursor = 'pointer';
        },
        onLeave: (e: any) => {
          e.target.style.cursor = 'default';
        },
        labels: {
          boxWidth: 40,
          fontSize: 25,
        },
        align: 'start',
      },
      responsive: true,
      maintainAspectRatio: true,
    };
    const horizontalBarChartDataSetOption: any = {
      animation: {
        duration: 0,
      },
      scales: {
        xAxes: [
          {
            ticks: {
              beginAtZero: true,
              max: 100,
              min: 0,
              fontSize: 20,
            },
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Compliance %',
              display: true,
              fontSize: 20,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: true,
              color: '#000000',
            },
            scaleLabel: {
              labelString: 'Criteria',
              display: false,
            },
            ticks: {
              fontSize: 20,
              minRotation: 0,
              callback: (label: string, index: number) => {
                if (payload.checkIndividualCriteria) {
                  return this.transformLabel(label);
                } else {
                  if (payload.filteredCriterias.length > 4) {
                    return index + 1 + '.';
                  }
                  return this.transformLabel(label);
                }
              },
            },
          },
        ],
      },
      legend: {
        display: false,
        onHover: (e: any) => {
          e.target.style.cursor = 'pointer';
        },
        onLeave: (e: any) => {
          e.target.style.cursor = 'default';
        },
        labels: {
          boxWidth: 40,
          fontSize: 25,
        },
        align: 'start',
      },
      responsive: true,
      maintainAspectRatio: true,
    };
    const {
      aggregateCriteriaConfiguration,
      individualBooleanCriteriaConfiguration,
      individualMixedCriteriaConfiguration,
    } = this.generateConfigurationData(
      result.statisticData.configurationData,
      payload,
      result.statisticData.samplingConfiguration,
    );
    const chartData = result.chartData;
    const data = {
      labels: [] as string[],
      datasets: [] as any[],
    };
    let dataOptions = {};
    _map(
      payload.filteredSites,
      (auditSiteMapId: number, auditSiteMapIdIndex: string) => {
        const backgroundColors: string[] = this.getIndividualSiteColors(
          Number(auditSiteMapIdIndex),
          payload,
        );
        const chartValues: number[] = this.getChartValues(
          chartData,
          auditSiteMapId,
        );
        const dataSetObject = {
          label: this.getSiteNameByProjectSiteMapId(auditSiteMapId, auditSites),
          backgroundColor: backgroundColors,
          pointBackgroundColor: 'white',
          borderWidth: 1,
          pointBorderColor: '#249EBF',
          data: chartValues,
          categoryPercentage: this.getAggregateCriteriaChartCategoryPercentage(
            individualBooleanCriteriaConfiguration,
          ),
        };
        data.datasets.push(dataSetObject);
      },
    );
    let dataOptionsObject;
    if (payload.selectedChartType === ChartType.verticalBarChart) {
      dataOptionsObject = _cloneDeep(verticalBarChartDataSetOption);
    } else {
      dataOptionsObject = _cloneDeep(horizontalBarChartDataSetOption);
    }
    dataOptionsObject.legend.display = true;
    data.labels = this.getCriteriaNames(chartData);
    dataOptions = _cloneDeep(dataOptionsObject);
    dataSet.push(data);
    dataSetOptions.push(dataOptions);
    const canvas = document.createElement('canvas');
    document.body.appendChild(canvas);
    if (payload.selectedChartType === ChartType.verticalBarChart) {
      const verticalChart = new Chart(
        canvas.getContext('2d') as CanvasRenderingContext2D,
        {
          type: 'bar',
          data: {
            labels: dataSet[0].labels,
            datasets: dataSet[0].datasets,
          },
          options: dataSetOptions[0],
          plugins: [
            {
              afterDatasetDraw(chart: any, args: any) {
                args.meta.data.forEach((element: any) => {
                  if (element._model.base - element._model.y < 0) {
                    const borderWidth = 8;
                    const ctx = chart.ctx;
                    const vm = element._view;
                    const half = vm.width / 2;
                    const left = vm.x - half;
                    const right = vm.x + half;
                    const top = vm.y - 2;
                    const width = right - left;
                    const height =
                      chart.chartArea.bottom - top + borderWidth / 2 - 1;
                    ctx.beginPath();
                    ctx.lineWidth = borderWidth;
                    ctx.strokeStyle = '#000000';
                    ctx.setLineDash([3, 4]);
                    ctx.moveTo(left, top);
                    ctx.lineTo(left, top + height);
                    ctx.moveTo(left, top);
                    ctx.lineTo(left + width, top);
                    ctx.moveTo(left + width, top);
                    ctx.lineTo(left + width, top + height);
                    ctx.stroke();
                    ctx.save();
                  }
                });
              },
            },
          ],
        },
      );
      const base64Image = verticalChart.toBase64Image();
      renderChart.image = base64Image.split(',')[1];
    } else {
      const horizontalChart = new Chart(
        canvas.getContext('2d') as CanvasRenderingContext2D,
        {
          type: 'horizontalBar',
          data: {
            labels: dataSet[0].labels,
            datasets: dataSet[0].datasets,
          },
          options: dataSetOptions[0],
          plugins: [
            {
              afterDatasetDraw(chart: any, args: any) {
                args.meta.data.forEach((element: any) => {
                  if (element._model.base - element._model.x > 0) {
                    const borderWidth = 8;
                    const ctx = chart.ctx;
                    const vm = element._view;
                    const half = vm.height / 2;
                    const top = vm.y - half;
                    const bottom = vm.y + half;
                    const right = vm.x + 10;
                    const left = chart.chartArea.left;
                    ctx.beginPath();
                    ctx.lineWidth = borderWidth;
                    ctx.strokeStyle = '#000000';
                    ctx.setLineDash([4, 5]);
                    ctx.moveTo(left, top);
                    ctx.lineTo(right, top);
                    ctx.moveTo(right, top);
                    ctx.lineTo(right, bottom);
                    ctx.moveTo(right, bottom);
                    ctx.lineTo(left, bottom);
                    ctx.stroke();
                    ctx.save();
                  }
                });
              },
            },
          ],
        },
      );
      const base64Image = horizontalChart.toBase64Image();
      renderChart.image = base64Image.split(',')[1];
    }
    const criteriaNamesText: any[] = data.labels.map((label) => {
      return {
        text: label,
        color: '#444F51',
        margin: [10, 5, 0, 2],
      };
    });
    const names = {
      layout: 'noBorders',
      table: {
        widths: ['*'],
        body: [
          [
            {
              ol: criteriaNamesText,
              fontSize: 9,
              fillColor: '#EFF7FF',
              markerColor: '#444F51',
            },
          ],
        ],
      },
      margin: [10, 0, 0, 23],
    };
    let aggregateStack: any[];
    if (payload.filteredCriterias.length > 4) {
      renderChart.margin = [0, 0, 0, 15];
      aggregateStack = [renderChart, names];
    } else {
      aggregateStack = [renderChart];
    }
    const criteriaChart = {
      stack: aggregateStack,
      unbreakable: false,
    };
    aggregateChart.push(criteriaChart);
    document.body.removeChild(canvas);
    // generate tables
    const siteNameChunks: any[] = [];
    const dirtySiteNameChunks = _cloneDeep(
      this.getSiteNames(auditSites, payload.filteredSites),
    );
    while (dirtySiteNameChunks.length) {
      siteNameChunks.push(dirtySiteNameChunks.splice(0, 2));
    }
    aggregateCriteriaConfiguration.forEach(
      (criteriaDetails, criteriaDetailsIndex) => {
        const dirtyTables: any[] = [];
        const criteriaSitesChunks: any[] = [];
        const dirtyCriteriaSitesDetails = _cloneDeep(
          criteriaDetails.criteriaSamplingData,
        );
        while (dirtyCriteriaSitesDetails.length) {
          criteriaSitesChunks.push(dirtyCriteriaSitesDetails.splice(0, 2));
        }
        const title = {
          text: criteriaDetailsIndex + 1 + '. ' + criteriaDetails.title,
          bold: true,
          color: '#444F51',
          fontSize: 11,
          margin: [0, 0, 0, 17],
        };
        criteriaSitesChunks.forEach(
          (criteriaSiteOptionDetails, criteriaSiteOptionDetailsIndex) => {
            switch (criteriaSiteOptionDetails.length) {
              case 1:
                const bodyRowsSingle: any[][] = [];
                const optionKeysSingle: string[] = Object.keys(
                  criteriaSiteOptionDetails[0].criteriaOptionsDataDistribution,
                );
                optionKeysSingle.forEach(
                  (optionKeySingle, optionKeySingleIndex) => {
                    const rowDataSingle: any[] = [];
                    rowDataSingle.push({
                      text: optionKeySingle,
                      color: '#444F51',
                      fontSize: 12,
                      border: [true, false, true, false],
                      margin:
                        optionKeySingleIndex === optionKeysSingle.length - 1
                          ? [8, 6, 0, 6]
                          : [8, 6, 0, 0],
                    });
                    rowDataSingle.push({
                      text:
                        criteriaSiteOptionDetails[0]
                          .criteriaOptionsDataDistribution[optionKeySingle],
                      color: '#444F51',
                      fontSize: 12,
                      border: [true, false, true, false],
                      alignment: 'right',
                      margin:
                        optionKeySingleIndex === optionKeysSingle.length - 1
                          ? [0, 6, 8, 6]
                          : [0, 6, 8, 0],
                    });
                    bodyRowsSingle.push(rowDataSingle);
                  },
                );
                let singleTotal: number | string = 0;
                let singleCompliance: any = 0;
                bodyRowsSingle.forEach((optionValues) => {
                  if (optionValues[1].text === '-') {
                    singleTotal = '-';
                  } else {
                    singleTotal += optionValues[1].text;
                  }
                });
                bodyRowsSingle.push([
                  {
                    text: 'Total Data Collected',
                    color: '#444F51',
                    fontSize: 12,
                    margin: [8, 6, 0, 6],
                  },
                  {
                    text: singleTotal,
                    color: '#444F51',
                    fontSize: 12,
                    alignment: 'right',
                    margin: [0, 6, 8, 6],
                  },
                ]);
                if (criteriaDetails.criteriaType === CRITERION_TYPE.BOOLEAN) {
                  if (bodyRowsSingle[0][1].text === 0) {
                    bodyRowsSingle.push([
                      {
                        text: 'Compliance',
                        color: '#444F51',
                        fontSize: 12,
                        margin: [8, 6, 0, 6],
                        bold: true,
                      },
                      {
                        text: singleCompliance + '%',
                        color: '#444F51',
                        fontSize: 12,
                        alignment: 'right',
                        bold: true,
                        margin: [0, 6, 8, 6],
                      },
                    ]);
                  } else {
                    if (bodyRowsSingle[0][1].text === '-') {
                      singleCompliance = '-';
                    } else {
                      singleCompliance =
                        (bodyRowsSingle[0][1].text /
                          (singleTotal - bodyRowsSingle[2][1].text)) *
                        100;
                    }
                    bodyRowsSingle.push([
                      {
                        text: 'Compliance',
                        color: '#444F51',
                        fontSize: 12,
                        margin: [8, 6, 0, 6],
                        bold: true,
                      },
                      {
                        text:
                          singleCompliance !== '-'
                            ? Math.round(singleCompliance * 100) / 100 + '%'
                            : singleCompliance,
                        color: '#444F51',
                        fontSize: 12,
                        alignment: 'right',
                        bold: true,
                        margin: [0, 6, 8, 6],
                      },
                    ]);
                  }
                }
                const singleTableData = {
                  table: {
                    unbreakable: true,
                    widths: ['*', 150],
                    body: [
                      [
                        {
                          text: 'Answer Choices',
                          color: '#9F9F9F',
                          fontSize: 11,
                          margin: [8, 10, 0, 10],
                        },
                        {
                          text:
                            siteNameChunks[criteriaSiteOptionDetailsIndex][0],
                          color: '#9F9F9F',
                          fontSize: 11,
                          alignment: 'right',
                          margin: [0, 10, 8, 10],
                        },
                      ],
                      ...bodyRowsSingle,
                    ],
                  },
                  layout: {
                    hLineWidth: () => {
                      return 0.5;
                    },
                    vLineWidth: () => {
                      return 0.5;
                    },
                    hLineColor: () => {
                      return '#DBDBDB';
                    },
                    vLineColor: () => {
                      return '#DBDBDB';
                    },
                  },
                };
                const singleStack: any[] = [singleTableData];
                const criteriaSingleTable = {
                  stack: singleStack,
                  unbreakable: true,
                };
                dirtyTables.push(criteriaSingleTable);
                break;
              default:
                const bodyRows: any[] = [];
                const valueRows: any[] = [];
                const totalRow: number[] = [];
                const complianceRow: any[] = [];
                criteriaSiteOptionDetails.forEach((siteOptionDetails: any) => {
                  const periodRow: any[] = [];
                  const optionKeys: string[] = Object.keys(
                    siteOptionDetails.criteriaOptionsDataDistribution,
                  );
                  optionKeys.forEach((optionKey) => {
                    const rowData: any = {
                      option: '',
                      value: null,
                    };
                    rowData.option = optionKey;
                    rowData.value =
                      siteOptionDetails.criteriaOptionsDataDistribution[
                        optionKey
                      ];
                    periodRow.push(rowData);
                  });
                  valueRows.push(periodRow);
                  let total: number | string = 0;
                  periodRow.forEach((optionValues) => {
                    if (optionValues.value === '-') {
                      total = '-';
                    } else {
                      total += optionValues.value;
                    }
                  });
                  totalRow.push(total);
                  let compliance = 0;
                  if (criteriaDetails.criteriaType === CRITERION_TYPE.BOOLEAN) {
                    if (periodRow[0].value === 0) {
                      complianceRow.push(compliance);
                    } else {
                      if (periodRow[0].value === '-') {
                        complianceRow.push('-');
                      } else {
                        compliance =
                          (periodRow[0].value / (total - periodRow[2].value)) *
                          100;
                        complianceRow.push(Math.round(compliance * 100) / 100);
                      }
                    }
                  }
                });
                const periodOne = valueRows[0];
                const periodTwo = valueRows[1];
                for (let step = 0; step < periodOne.length; step++) {
                  const optionColumnOne = {
                    text: periodOne[step].option,
                    color: '#444F51',
                    fontSize: 12,
                    border: [true, false, true, false],
                    margin:
                      step === periodOne.length - 1
                        ? [8, 6, 0, 6]
                        : [8, 6, 0, 0],
                  };
                  const optionColumnTwo = {
                    text: periodOne[step].value,
                    color: '#444F51',
                    fontSize: 12,
                    border: [true, false, true, false],
                    alignment: 'right',
                    margin:
                      step === periodOne.length - 1
                        ? [0, 6, 8, 6]
                        : [0, 6, 8, 0],
                  };
                  const optionColumnThree = {
                    text: periodTwo[step].value,
                    color: '#444F51',
                    fontSize: 12,
                    border: [true, false, true, false],
                    alignment: 'right',
                    margin:
                      step === periodOne.length - 1
                        ? [0, 6, 8, 6]
                        : [0, 6, 8, 0],
                  };
                  bodyRows.push([
                    optionColumnOne,
                    optionColumnTwo,
                    optionColumnThree,
                  ]);
                }
                const totalColumnOne = {
                  text: 'Total Data Collected',
                  color: '#444F51',
                  fontSize: 12,
                  margin: [8, 6, 0, 6],
                };
                const totalColumnTwo = {
                  text: totalRow[0],
                  color: '#444F51',
                  fontSize: 12,
                  alignment: 'right',
                  margin: [0, 6, 8, 6],
                };
                const totalColumnThree = {
                  text: totalRow[1],
                  color: '#444F51',
                  fontSize: 12,
                  alignment: 'right',
                  margin: [0, 6, 8, 6],
                };
                bodyRows.push([
                  totalColumnOne,
                  totalColumnTwo,
                  totalColumnThree,
                ]);
                if (criteriaDetails.criteriaType === CRITERION_TYPE.BOOLEAN) {
                  const complianceColumnOne = {
                    text: 'Compliance',
                    color: '#444F51',
                    fontSize: 12,
                    margin: [8, 6, 0, 6],
                    bold: true,
                  };
                  const complianceColumnTwo = {
                    text:
                      complianceRow[0] !== '-'
                        ? complianceRow[0] + '%'
                        : complianceRow[0],
                    color: '#444F51',
                    fontSize: 12,
                    alignment: 'right',
                    bold: true,
                    margin: [0, 6, 8, 6],
                  };
                  const complianceColumnThree = {
                    text:
                      complianceRow[1] !== '-'
                        ? complianceRow[1] + '%'
                        : complianceRow[1],
                    color: '#444F51',
                    fontSize: 12,
                    alignment: 'right',
                    bold: true,
                    margin: [0, 6, 8, 6],
                  };
                  bodyRows.push([
                    complianceColumnOne,
                    complianceColumnTwo,
                    complianceColumnThree,
                  ]);
                }
                const tableData = {
                  table: {
                    unbreakable: true,
                    widths: ['*', 150, 150],
                    body: [
                      [
                        {
                          text: 'Answer Choices',
                          color: '#9F9F9F',
                          fontSize: 11,
                          margin: [8, 10, 0, 10],
                        },
                        {
                          text:
                            siteNameChunks[criteriaSiteOptionDetailsIndex][0],
                          color: '#9F9F9F',
                          fontSize: 11,
                          alignment: 'right',
                          margin: [0, 10, 8, 10],
                        },
                        {
                          text:
                            siteNameChunks[criteriaSiteOptionDetailsIndex][1],
                          color: '#9F9F9F',
                          fontSize: 11,
                          alignment: 'right',
                          margin: [0, 10, 8, 10],
                        },
                      ],
                      ...bodyRows,
                    ],
                  },
                  layout: {
                    hLineWidth: () => {
                      return 0.5;
                    },
                    vLineWidth: () => {
                      return 0.5;
                    },
                    hLineColor: () => {
                      return '#DBDBDB';
                    },
                    vLineColor: () => {
                      return '#DBDBDB';
                    },
                  },
                };
                const stack: any[] = [tableData];
                const criteriaTable = {
                  stack,
                  unbreakable: true,
                };
                dirtyTables.push(criteriaTable);
                break;
            }
          },
        );
        const setMargin = {
          canvas: [],
          margin: [0, 0, 0, 13],
        };
        switch (dirtyTables.length) {
          case 1:
            dirtyTables[0].stack.unshift(title);
            dirtyTables[0].stack.push(setMargin);
            break;
          case 2:
            dirtyTables[0].stack.unshift(title);
            dirtyTables[0].stack.push(setMargin);
            break;
          default:
            dirtyTables.forEach((dirtyTable, dirtyTableIndex) => {
              switch (dirtyTableIndex) {
                case 0:
                  dirtyTable.stack.unshift(title);
                  dirtyTable.stack.push(setMargin);
                  break;
                case dirtyTables.length - 1:
                  dirtyTable.stack.push(setMargin);
                  break;
                default:
                  dirtyTable.stack.push(setMargin);
              }
            });
        }
        renderTables.push(dirtyTables);
      },
    );
    aggregateCriteriaConfiguration.forEach(
      (criteriaDetails, criteriaDetailsIndex) => {
        const samplingTitle = {
          text: 'Sampling',
          bold: true,
          color: '#444F51',
          fontSize: 11,
          margin: [0, 13, 0, 17],
        };
        const dirtySamplingTables: any[] = this.getSamplingTables(
          criteriaDetails,
          samplingTitle,
        );
        renderSamplingTables.push(dirtySamplingTables[0]);
      },
    );
    return {
      aggregateChart,
      renderTables,
      renderSamplingTables,
    };
  }

  public getIndividualSiteColors(index: number, payload: any): string[] {
    const backgroundColors: string[] = [];
    _map(
      payload.filteredCriterias,
      (auditCriteriaMapId: number, auditCriteriaMapIndex: string) => {
        backgroundColors.push(chartColorSet[index]);
      },
    );
    return backgroundColors;
  }

  public getChartValues(chartData: any[], auditSiteMapId: number): number[] {
    const chartValues: number[] = [];
    Object.keys(chartData).map((key: string) => {
      const dirtyChartData = chartData[Number(key)];
      _map(dirtyChartData.data, (dirtyData) => {
        if (dirtyData.auditSiteId === auditSiteMapId) {
          chartValues.push(dirtyData.compliance);
        }
      });
    });
    return chartValues;
  }

  public getSiteNameByProjectSiteMapId(
    auditSiteMapId: number,
    auditSites: AuditSite[],
  ): string {
    const siteName = auditSites.filter(
      (auditSite) => auditSite.id === auditSiteMapId,
    );
    return siteName[0].site.name;
  }

  public getCriteriaNames(chartData: any[]): string[] {
    const criteriaNames: string[] = [];
    Object.keys(chartData).map((key: string) => {
      criteriaNames.push(chartData[Number(key)].criteria.title);
    });
    return criteriaNames;
  }

  public createTextRun(
    params: IRunOptions & {
      deltaAttributes?: IOpAttributes;
      type?: string;
    },
  ) {
    const extraParams = {};

    if (params && params.deltaAttributes) {
      const { deltaAttributes } = params;
      Object.assign(extraParams, { italics: Boolean(deltaAttributes.italic) });
      Object.assign(extraParams, { bold: Boolean(deltaAttributes.bold) });
      Object.assign(extraParams, {
        superScript: Boolean(deltaAttributes.script === 'super'),
      });
      Object.assign(extraParams, {
        subScript: Boolean(deltaAttributes.script === 'sub'),
      });

      if (deltaAttributes.underline) {
        Object.assign(extraParams, { underline: {} });
      } else if (deltaAttributes.size) {
        Object.assign(extraParams, {
          size:
            this.docxFontSize[deltaAttributes.size] ||
            this.docxFontSize.default,
        });
      }
    }

    return new TextRun({
      size: this.docxFontSize.default,
      ...params,
      ...extraParams,
      font: {
        name: 'Helvetica',
      },
    });
  }

  public createBullet(text: string): Paragraph {
    return new Paragraph({
      text,
      bullet: {
        level: 0,
      },
    });
  }

  public formatDateRange(date: Date[]): string {
    return (
      this.getDate(new Date(date[0])) + ' - ' + this.getDate(new Date(date[1]))
    );
  }

  public getDate(newValue: Date): string {
    return (
      newValue.getDate() +
      ' ' +
      this.dirtyMonths[newValue.getMonth()] +
      ' ' +
      newValue.getFullYear()
    );
  }

  public async getHeader(document: Document) {
    const blob: any = await fetch(
      `${process.env.VUE_APP_FRONTEND_URL}/assets/images/JBI_banner_report.png`,
    ).then((r) => r.blob());
    const image = Media.addImage(document, blob, 625, 48);
    return new Paragraph(image);
  }

  public getFooter() {
    const exportDateTime = dayjs().format('D MMM YYYY, HH:mm A');
    return new Paragraph(`Exported on ${exportDateTime}`);
  }

  public getTableValueText(cellData: any) {
    return Object.keys(cellData).length > 0
      ? cellData.text
        ? cellData.text.toString()
        : '0'
      : '';
  }
}
