var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.projectCriteriaEditContainer},[(
      _vm.isUserProjectOwnerOrCoordinator &&
      !_vm.isProjectCriteriaAcknowledged &&
      _vm.isJbiCriteriaUpdated
    )?_c('p',{staticClass:"criteria-tab-text text-size"},[_vm._v(" Your JBI criteria have been updated, please view and acknowledge the change(s) "),_c('a',{class:_vm.$style.clickBtn,on:{"click":_vm.openJBICriteriaAcknowledgmentModal}},[_vm._v(" here")]),_vm._v(". ")]):_vm._e(),_c('p',{class:[
      !_vm.isProjectCriteriaAcknowledged && _vm.isJbiCriteriaUpdated
        ? _vm.$style.criteriaInfo
        : 'criteria-tab-text',
      "text-size" ]},[_vm._v(" Setup Criteria for project-wide here. You may also customise criteria for each data collection period. Any changes here will be applied to the new data collection period. ")]),(_vm.selectedCriteriaList.length > 0)?_c('AuditCriteriaList',{ref:"auditCriteria",attrs:{"selectedCriteriaArray":_vm.selectedCriteriaList,"projectEdit":true,"projectId":_vm.projectId,"editable":_vm.editable,"isProject":true},on:{"update:selectedCriteriaArray":function($event){_vm.selectedCriteriaList=$event},"update:selected-criteria-array":function($event){_vm.selectedCriteriaList=$event},"updateDeletedCriteriaList":_vm.handleDeletedCriteria,"updateCriteriaHandler":_vm.updateProjectCriteriaHandler}}):_vm._e(),(_vm.editable)?[_c('div',{staticClass:"columns",class:_vm.$style.addItem},[_c('div',{staticClass:"column"},[_c('a',{staticClass:"add-participant-text",on:{"click":_vm.openJBICriteriaSelectionTemplate}},[_vm._v(" + Add Criteria ")])])]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-two-thirds vertical-center text-color"}),_c('div',{staticClass:"column is-one-third save-btn-container"},[_c('b-button',{class:_vm.$style.saveChangesBtn,attrs:{"disabled":_vm.isAuditCriteriaSubmitButtonDisabled,"type":"is-primary"},on:{"click":_vm.updateProjectCriteriaHandler}},[_vm._v("Save ")])],1)])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }