







import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/base/Header.vue';
import { axios } from '@/api/axios.ts';
import { AxiosError, AxiosResponse } from 'axios';
import { get as _get } from 'lodash';
import { useAction, useAuthState } from '@/utils/store.util';
import { ToastProgrammatic as Toast } from 'buefy';
import { redirectToJaasLogin } from '../../utils/login.util';
import BaseDialog from '../../components/base/BaseDialog.vue';

@Component({
  components: {
    Header,
  },
})
export default class InviteAuditParticipant extends Vue {
  public isLoading = false;

  get isLoggedIn(): boolean {
    return useAuthState.call(this).isLoggedIn;
  }

  get switchAccount() {
    return useAction.call(this, 'auth/switchAccount');
  }

  get signOut() {
    return useAction.call(this, 'auth/signOut');
  }

  private acceptInvitation() {
    axios
      .get(`/audit-participant-invitations/accept`, {
        params: {
          email: this.$route.query.email.toString(),
          token: this.$route.query.token.toString(),
        },
      })
      .then((response: AxiosResponse) => {
        Toast.open({
          queue: true,
          type: 'is-dark',
          position: 'is-top',
          message:
            response.data && response.data.message
              ? response.data.message
              : `Invitation Accepted Successfully.`,
        });
        this.$router.replace('/');
      })
      .catch((error: AxiosError) => {
        this.isLoading = false;

        if (error.response!.status === 409) {
          this.$buefy.modal.open({
            parent: this,
            component: BaseDialog,
            hasModalCard: true,
            trapFocus: true,
            props: {
              title: 'Invitation link is not valid',
              content:
                'Your invitation link is not valid for the MyJBI account you are signed in to. Please sign in with the email address the invitation was sent to instead.',
              confirmButtonText: 'Logout',
            },
            events: {
              confirm: async () => {
                await this.switchAccount(true);
                await this.signOut();
              },
              cancel: () => {
                this.$router.replace('/');
              },
            },
          });
        } else {
          Toast.open({
            queue: true,
            type: 'is-danger',
            position: 'is-top',
            message: `${_get(error.response, 'data.message')}`,
          });
          this.$router.replace('/');
        }
      });
  }

  private verifyUser() {
    return axios
      .get('/audit-participant-invitations/verify', {
        params: {
          email: this.$route.query.email.toString(),
          token: this.$route.query.token.toString(),
        },
      })
      .then((resposne: AxiosResponse) => {
        redirectToJaasLogin({
          destination: this.$route.fullPath,
          loginUrl: this.$route.query['login-url'].toString(),
        });
      })
      .catch((error: AxiosError) => {
        Toast.open({
          queue: true,
          type: 'is-danger',
          position: 'is-top',
          message: `${_get(error.response, 'data.message')}`,
          duration: 5000,
        });
        this.$router.replace('/');
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  private async mounted() {
    this.isLoading = true;

    // TODO: findout why persist state wont work without setTimeout
    setTimeout(async () => {
      if (this.isLoggedIn) {
        await this.acceptInvitation();
      } else {
        await this.verifyUser();
      }
    }, 3000);
  }
}
