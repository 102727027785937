





































































import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import { cloneDeep as _cloneDeep } from 'lodash';
import {ProjectCriteria} from '@/store/modules/projects/types/projects.types';

@Component({ components : {} })

export default class ProjectCriteriaFilter extends Vue {
    @Prop()
    public projectCriteria!: ProjectCriteria[];
    @Prop()
    public filteredCriterias!: number[];
    @Prop({default: false})
    public isDisabled!: boolean;
    public selectedCriteria: number[] = [];
    public isAllCriteriaSelected: boolean = true;
    public allCriteriaIds: number[] = [];

    public mounted() {
        if (this.filteredCriterias.length !== 0) {
            this.populateFilteredData();
        } else {
            this.populateData();
        }
    }

    public populateData() {
        this.projectCriteria.map((projectCriteria: any) => {
            this.selectedCriteria.push(projectCriteria.id);
        });
        this.allCriteriaIds = _cloneDeep(this.selectedCriteria);
    }

    public populateFilteredData() {
        this.projectCriteria.map((projectCriteria: any) => {
            this.allCriteriaIds.push(projectCriteria.id);
        });
        this.selectedCriteria = _cloneDeep(this.filteredCriterias);
        if (this.allCriteriaIds.length !== this.selectedCriteria.length) {
            this.isAllCriteriaSelected = false;
        }
    }

    public updateSelection(label: boolean = false, id: number) {
        if (label) {
            const criteriaIndex = this.selectedCriteria.indexOf(id);
            if (criteriaIndex === -1) {
                this.selectedCriteria.push(id);
            } else {
                this.selectedCriteria.splice(criteriaIndex, 1);
            }
        }
        this.isAllCriteriaSelected = this.allCriteriaIds.length === this.selectedCriteria.length;
        this.emitCriteriaFilter();
    }

    public updateAllSelection(label: boolean = false) {
        if (label) {
            this.isAllCriteriaSelected = !this.isAllCriteriaSelected;
        }
        if (this.isAllCriteriaSelected) {
            this.selectedCriteria = _cloneDeep(this.allCriteriaIds);
        } else {
            this.selectedCriteria = [];
        }
        this.emitCriteriaFilter();
    }

    public mapOrder(selectedCriteria: number[], projectCriteriaMapIds: number[]) {
        selectedCriteria.sort((selectedSite, selectedSiteNext) => {
            if (projectCriteriaMapIds.indexOf(selectedSite) > projectCriteriaMapIds.indexOf(selectedSiteNext)) {
                return 1;
            } else {
                return -1;
            }
        });
        return selectedCriteria;
    }

    public emitCriteriaFilter() {
        if (!(this.selectedCriteria.length === 0 || this.selectedCriteria.length === 1)) {
            const projectCriteriaMapIds: number[] = this.projectCriteria.map((projectCriteria) => projectCriteria.id);
            this.selectedCriteria = this.mapOrder(this.selectedCriteria, projectCriteriaMapIds);
        }
        this.$emit('applyCriteriaFilter', this.selectedCriteria);
    }
}
