import { RevisionPublicationStatus } from './document-status.types';

export enum PacesParticipantRoles {
  DataCollector = 'Data Collector',
  ProjectCoordinator = 'Project Coordinator',
  ProjectAdministrator = 'Project Administrator',
}

/**
 * @deprecated
 * use DocumentCriteriaResponse instead
 */
export interface UpdateCriterionRequestPayload {
  id: number;
  connectPlusId: number;
  documentId: number;
  publicationId: number;
  projectId: number;
}

/**
 * @deprecated
 * use DocumentCriteriaResponse instead
 */
export interface UpdateCriterionResponsePayload {
  id: number;
  connectPlusId: number;
  documentId: number;
  publicationId: number;
  projectId: number;
  isDeleted: boolean;
  updatedCriterion: {
    id: number;
    content: CriteriaContent;
    createdAt: string;
    revisionId: number;
    criterionSubSectionId: number;
  };
  latestPublication: {
    revisionId: number;
    projectDocumentId: number;
    versionId: number;
    publicationId: number;
    uniquePublicationId: string;
  };
}

export interface DocumentCriteriaPayload {
  documentIds: number[];
}

export const DocumentCriteriaStatusNotFound = 'NotFound';

export type DocumentCriteriaStatus =
  | RevisionPublicationStatus
  | typeof DocumentCriteriaStatusNotFound;

export interface DocumentCriteriaResponse {
  documentId: number;
  status: DocumentCriteriaStatus;
  version?: number;
  criteria: {
    connectPlusCriteriaId: number;
    content: CriteriaContent;
  }[];
}

export interface CriteriaContentBase {
  type: string;
  title: string;
}

export type CriteriaContent =
  | CriteriaTypeBoolean
  | CriteriaTypeRange
  | CriteriaTypeCheckboxs;

export interface CriteriaTypeBoolean extends CriteriaContentBase {
  booleanOptions: string[];
}

export interface CriteriaTypeRange extends CriteriaContentBase {
  range: number[];
}

export interface CriteriaTypeCheckboxs extends CriteriaContentBase {
  checkboxesOptions: string[];
}
