






























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { cloneDeep as _cloneDeep } from 'lodash';

@Component({ components: {} })
export default class DeleteModalBox extends Vue {
  @Prop()
  public modalTitle!: string;
  @Prop()
  public modalContent!: string;

  @Prop()
  public id!: number;

  @Prop()
  public name!: string;
  public confirmDelete() {
    this.$emit('confirm');
    this.$emit('close');
  }
  public closeModal() {
    this.$emit('close');
  }
}
