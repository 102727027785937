
















































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { State, Action } from 'vuex-class';
import Card from '@/components/Card.vue';
import AvatarCard from '@/components/AvatarCard.vue';
import { RootState } from '@/store/store';
import {
  ProjectAuditDataCollectionRecordsPayload,
  ProjectParticipantDTO,
} from '@/store/modules/projects/types/projects.types';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import moment from 'moment';
import { getJBIUsername } from '@/jbi-shared/util/user.utils';

@Component({
  components: {
    Card,
    AvatarCard,
  },
})
export default class ParticipantList extends Vue {
  @Prop()
  public title!: string;

  @Prop({ default: 0 })
  public maxItemDisplay!: number;

  @Prop()
  public participants!: ProjectParticipantDTO[];

  @Prop()
  public auditList!: any;

  @Prop()
  public projectId!: number;

  public viewAll = false;
  public auditIds: number[] = [];
  public dataCollectionRecords: any = [];

  @Action('projects/getProjectAuditDataCollectionRecords')
  public getProjectAuditDataCollectionRecords!: (
    payload: ProjectAuditDataCollectionRecordsPayload,
  ) => void;

  @State((state: RootState) => state.projects.projectAuditDataCollectionRecords)
  public projectAuditDataCollectionRecords!: ProjectAuditDataCollectionRecordsPayload;

  @Watch('projectAuditDataCollectionRecords')
  @isTruthy
  public watchProjectAuditDataCollectionRecords(newValue: any) {
    newValue.hits.map((data: any) => {
      data._source.dataCollectionRecords.map((value: any) => {
        this.dataCollectionRecords.push({
          email: data._source.user.email,
          total: value.total,
        });
      });
    });
  }

  public totalDataCollection(value: any, email: any) {
    let totalValue = 0;
    value.map((data: any) => {
      if (data.email === email) {
        totalValue += Number(data.total);
      }
    });
    return totalValue;
  }

  public getLastSubmission(activityRecords: any, email: string) {
    const userActivityRecords = activityRecords.hits.filter(
      (activityRecord: any) => {
        return activityRecord._source.user.email === email;
      },
    );
    switch (userActivityRecords.length) {
      case 0:
        return ['-', ''];
      case 1:
        return [
          this.getFormattedTimeGap(userActivityRecords[0]._source.updatedAt),
          userActivityRecords[0]._source.audit.name,
        ];
      default:
        const lastSubmission = userActivityRecords.reduce(
          (firstRecord: any, secondRecord: any) => {
            return moment(secondRecord._source.updatedAt) >
              moment(firstRecord._source.updatedAt)
              ? secondRecord
              : firstRecord;
          },
        );
        return [
          this.getFormattedTimeGap(lastSubmission._source.updatedAt),
          lastSubmission._source.audit.name,
        ];
    }
  }

  public getFormattedTimeGap(lastSubmissionDate: string) {
    const timeGap = moment(lastSubmissionDate).fromNow();
    if (timeGap === 'a day ago') {
      return 'Yesterday';
    } else if (timeGap.includes('days')) {
      return moment(lastSubmissionDate).format('DD MMM YYYY');
    } else {
      return timeGap.charAt(0).toUpperCase() + timeGap.slice(1);
    }
  }

  get items() {
    const participantData: Array<{
      userEmail: string;
      participantName: string;
    }> = [];
    this.participants.map((participant: ProjectParticipantDTO) => {
      if (!participant.isDeleted) {
        const participantName: string = participant.user
          ? getJBIUsername(participant.userEmail, participant.user.oicPayload)
          : participant.userEmail;

        participantData.push({
          userEmail: participant.userEmail,
          participantName,
        });
      }
    });
    if (this.viewAll) {
      return participantData;
    }
    return participantData.length > 0
      ? participantData.slice(0, this.maxItemDisplay)
      : participantData;
  }

  public handleViewAll() {
    this.$emit('changeActiveTab', '#participants');
  }

  public async mounted() {
    this.auditList.map((data: any) => {
      this.auditIds.push(data.id);
    });
    const projectAuditId = [];
    projectAuditId.push({
      projectId: this.projectId,
      auditIds: this.auditIds,
    });
    if (this.auditIds.length > 0) {
      await this.getProjectAuditDataCollectionRecords(projectAuditId[0]);
    }
  }
}
