var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dirtyLogs)?_c('div',{class:_vm.$style.container},_vm._l((_vm.dirtyLogs),function(activityItem,activityItemIndex){return _c('div',{key:activityItemIndex,staticClass:"data-collection-activitylogs-row-container",class:_vm.$style.item},[_c('b-collapse',{staticClass:"panel",attrs:{"aria-id":"contentIdForA11y2","animation":"slide","open":_vm.isRecordOpen},scopedSlots:_vm._u([{key:"trigger",fn:function(props){return _c('div',{staticClass:"panel-heading data-collection-activitylog-panel-heading",attrs:{"role":"button","aria-controls":"contentIdForA11y2"}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-1 time-period"},[_vm._v(" "+_vm._s(_vm.getDateTime(activityItem.recordedStartDateTime))+" ")]),_c('div',{staticClass:"column title-content"},_vm._l((_vm.getTitle(activityItem)),function(word,wordIndex){return _c('span',{key:wordIndex},[(wordIndex === 0)?_c('span',{staticClass:"bold"},[_vm._v(_vm._s(word))]):_c('span',[_vm._v(" "+_vm._s(word))])])}),0),_c('div',{staticClass:"columns column is-2"},[_c('div',{staticClass:"column edit-btn"},[_c('router-link',{class:_vm.$style.editBtn,attrs:{"to":'/audits/' +
                  _vm.auditDetail.id +
                  '/data-collections/' +
                  activityItem.id}},[_c('b-icon',{attrs:{"icon":"pencil","size":"is-small"}})],1)],1),_c('div',{staticClass:"column delete-btn"},[_c('a',{class:_vm.$style.deletebtn,on:{"click":function($event){return _vm.deleteDataCollectionRecord(activityItem)}}},[_c('b-icon',{attrs:{"icon":"delete","size":"is-small"}})],1)]),_c('div',{class:[
                !_vm.isDataCollectionActive ? _vm.$style.centerAccordian : null,
                'column',
                'accordion-btn' ]},[_c('a',{class:_vm.$style.accordionbtn},[_c('b-icon',{attrs:{"icon":props.open ? 'menu-up' : 'menu-down'}})],1)])])])])}}],null,true),model:{value:(_vm.isRecordOpen),callback:function ($$v) {_vm.isRecordOpen=$$v},expression:"isRecordOpen"}},[_c('div',{staticClass:"panel-block",class:_vm.$style.panelBlockContainer},[_c('div',{staticClass:"data-recorded-row"},[_vm._v(" "+_vm._s(_vm.getDataRecordedRowinfo(activityItem))+" ")]),(
            activityItem.dataCollectionType === 'individual' &&
            activityItem.aid
          )?_c('div',{staticClass:"data-recorded-aid"},[_vm._v(" Sample Code : "+_vm._s(activityItem.aid)+" ")]):_vm._e(),(
            activityItem.comments !== '' && activityItem.comments !== null
          )?_c('div',[_c('div',{staticClass:"data-comment-row"},[_vm._v("Overall Comment")]),_c('div',{staticClass:"data-comment-data-row"},[_vm._v(_vm._s(activityItem.comments))])]):_vm._e(),_vm._l((activityItem.dataCollectionRecords),function(dataCollectionRecords,index){return _c('div',{key:index,staticClass:"data-per-criteria-row",class:index === 0 ? _vm.$style.applyPaddingTopFirstChildCriteriaRow : null},[_c('div',{staticClass:"columns data-info-row"},[_c('div',{staticClass:"column index-column"},[_vm._v(_vm._s(index + 1)+".")]),_c('CriteriaViewUpdateComponent',{attrs:{"criteria":dataCollectionRecords.auditCriteria.criteria}})],1),_c('div',{staticClass:"columns data-table-row"},[_c('div',{staticClass:"column empty-column-data-table"}),_c('div',{staticClass:"column table-column"},[_c('div',{staticClass:"row-table-heading"},[_c('div',{staticClass:"col-answer-choices"},[_vm._v("Answer Choices")]),_c('div',{staticClass:"col-responses"},[_vm._v("Count")])]),_c('div',{class:[
                  _vm.getOptionValues(
                    dataCollectionRecords,
                    activityItem.dataCollectionType
                  ).length > 8
                    ? _vm.$style.scrollableColumn
                    : _vm.$style.nonScrollableColumn ]},_vm._l((_vm.getOptionValues(
                    dataCollectionRecords,
                    activityItem.dataCollectionType
                  )),function(optionValue,index){return _c('div',{key:index,class:activityItem.dataCollectionType !== 'individual' ||
                    dataCollectionRecords.auditCriteria.criteria
                      .criteriaType !== 'BOOLEAN'
                      ? 'row-table-options'
                      : 'row-table-options-unique'},[_c('div',{staticClass:"option-element"},[_vm._v(" "+_vm._s(optionValue.option)+" ")]),_c('div',{staticClass:"option-element-count"},[_vm._v(" "+_vm._s((optionValue.value !== 0 ? '+' : '') + (optionValue.value ? optionValue.value : 0))+" ")])])}),0),(
                  activityItem.dataCollectionType !== 'individual' ||
                  dataCollectionRecords.auditCriteria.criteria
                    .criteriaType !== 'BOOLEAN'
                )?_c('div',{staticClass:"row-table-total"},[_c('div',{staticClass:"col-total-collected"},[_vm._v("Total Collected")]),_c('div',{staticClass:"col-total-collected-count"},[_vm._v(" "+_vm._s((_vm.getInteger(dataCollectionRecords.total) !== 0 ? '+' : '') + _vm.getInteger(dataCollectionRecords.total))+" ")])]):_vm._e(),(
                  dataCollectionRecords.comments !== '' &&
                  dataCollectionRecords.comments !== null
                )?_c('div',[_c('div',{staticClass:"criteria-comment-row",class:activityItem.dataCollectionType !== 'individual' ||
                    dataCollectionRecords.auditCriteria.criteria
                      .criteriaType !== 'BOOLEAN'
                      ? null
                      : _vm.$style.removeCommentBoxMargin},[_vm._v(" Comment ")]),_c('div',{staticClass:"criteria-comment-data-row"},[_vm._v(" "+_vm._s(dataCollectionRecords.comments)+" ")])]):_vm._e()])])])})],2)]),(activityItemIndex + 1 !== _vm.dirtyLogs.length)?_c('hr',{staticClass:"divider"}):_vm._e()],1)}),0):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }