























































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '@/utils/date.util';

@Component({ components: {} })
export default class AuditListDataCollector extends Vue {
  @Prop()
  public activeAuditList!: any[];
  @Prop()
  public archivedAuditList!: any[];

  public showArchivedList: boolean = false;
  public dateToday: Date = new Date();

  public formatDate(s: string) {
    return dayjs(s).format(DATE_FORMAT);
  }

  public getDurationText(startDate: string, endDate: string) {
    if (startDate === null && endDate === null) {
      return 'N/A';
    } else {
      if (endDate !== null) {
        return `${dayjs(startDate).format(DATE_TIME_FORMAT)} - ${dayjs(
          endDate,
        ).format(DATE_TIME_FORMAT)}`;
      } else {
        return `from ${dayjs(startDate).format(DATE_TIME_FORMAT)}`;
      }
    }
  }

  public showArchivedAudits() {
    this.showArchivedList = true;
  }

  public hideArchivedAudits() {
    this.showArchivedList = false;
  }
}
