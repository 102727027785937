
























import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import {cloneDeep as _cloneDeep} from 'lodash';
import {ProjectReportAnalysis} from '@/store/modules/projects/types/projects.types';

@Component({})
export default class ProjectAnalysisNavigationTabs extends Vue {
    @Prop()
    public visibleAnalysis!: ProjectReportAnalysis[];
    @Prop()
    public selectedTabIndex!: number;

    public dirtyVisibleAnalysis: ProjectReportAnalysis[] = [];
    public dirtySelectedTabIndex: number = 0;

    public mounted() {
        this.dirtyVisibleAnalysis = _cloneDeep(this.visibleAnalysis);
        this.dirtySelectedTabIndex = this.selectedTabIndex;
    }

    public selectAnalysis(analysisItemIndex: number) {
        this.$emit('selectAnalysis', analysisItemIndex);
    }

    public getAnalysisTitle(analysisTitle: string): string {
        if (analysisTitle.length > 14) {
            return `${analysisTitle.substring(0, 12)} ...`.toUpperCase();
        }
        return analysisTitle.toUpperCase();
    }
}
