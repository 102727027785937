var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-tabs',{staticClass:"grip-analysis-sidebar-tab",attrs:{"size":"is-small","vertical":"","animated":false,"type":"is-boxed"},on:{"input":_vm.tabChange},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('b-tab-item',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"tab-header",on:{"click":function($event){return _vm.openSideNav('library')}}},[_c('img',{attrs:{"width":"24px","src":require(_vm.activeTab === _vm.tabList.indexOf('library')
                ? '/src/assets/icons/ic_edit_library_blue.png'
                : '/src/assets/icons/ic_edit_library_white.png')}}),_c('p',[_vm._v("Edit Library")])])]},proxy:true}])}),_c('b-tab-item',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"tab-header",on:{"click":function($event){return _vm.openSideNav('criteria')}}},[_c('img',{attrs:{"width":"24px","src":require(_vm.activeTab === _vm.tabList.indexOf('criteria')
                ? '/src/assets/icons/ic_criteria_blue.png'
                : '/src/assets/icons/ic_criteria_white.png')}}),_c('p',[_vm._v("Criteria")])])]},proxy:true}])},[_c('ExistingCriteriaList',{key:_vm.criteriaTabKey,class:_vm.isSideBarCollapsed ? _vm.$style.openContainer : _vm.$style.closeContainer,attrs:{"projectId":_vm.projectId,"analysisCriteriaIds":_vm.analysisCriteriaIds,"isGripAnalysis":_vm.isGripAnalysis},on:{"updateSelectedCriteria":_vm.handleSelectedCriteria,"collapseSideNav":function($event){return _vm.closeSideNav()}}})],1),_c('b-tab-item',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"tab-header",on:{"click":function($event){return _vm.openSideNav('barrier')}}},[_c('img',{attrs:{"width":"24px","src":require(_vm.activeTab === _vm.tabList.indexOf('barrier')
                ? '/src/assets/icons/ic_barrier_blue.png'
                : '/src/assets/icons/ic_barrier_white.png')}}),_c('p',[_vm._v("Barrier")])])]},proxy:true}])},[_c('ExistingGripBarrierList',{key:_vm.barrierTabKey,class:_vm.isSideBarCollapsed ? _vm.$style.openContainer : _vm.$style.closeContainer,attrs:{"projectId":_vm.projectId},on:{"collapseSideNav":function($event){return _vm.closeSideNav()}}})],1),_c('b-tab-item',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"tab-header",on:{"click":function($event){return _vm.openSideNav('enabler')}}},[_c('img',{attrs:{"width":"24px","src":require(_vm.activeTab === _vm.tabList.indexOf('enabler')
                ? '/src/assets/icons/ic_enabler_blue.png'
                : '/src/assets/icons/ic_enabler_white.png')}}),_c('p',[_vm._v("Enabler")])])]},proxy:true}])},[_c('ExistingGripEnablerList',{key:_vm.enablerTabKey,class:_vm.isSideBarCollapsed ? _vm.$style.openContainer : _vm.$style.closeContainer,attrs:{"projectId":_vm.projectId},on:{"collapseSideNav":function($event){return _vm.closeSideNav()}}})],1),_c('b-tab-item',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"tab-header",on:{"click":function($event){return _vm.openSideNav('strategy')}}},[_c('img',{attrs:{"width":"24px","src":require(_vm.activeTab === _vm.tabList.indexOf('strategy')
                ? '/src/assets/icons/ic_strategy_blue.png'
                : '/src/assets/icons/ic_strategy_white.png')}}),_c('p',[_vm._v("Strategy")])])]},proxy:true}])},[_c('ExistingGripStrategyList',{key:_vm.strategyTabKey,class:_vm.isSideBarCollapsed ? _vm.$style.openContainer : _vm.$style.closeContainer,attrs:{"projectId":_vm.projectId},on:{"collapseSideNav":function($event){return _vm.closeSideNav()}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }