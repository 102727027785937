
























import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { cloneDeep as _cloneDeep } from 'lodash';
import { State } from 'vuex-class';
import { AuditDetail } from '@/store/modules/audits/types/audits.types';
import dayjs from 'dayjs';
import { ProjectReportPeriod } from '@/store/modules/projects/types/projects.types';
import { getStartDateTime, getEndDateTime } from '../../../utils/date.util';
const dirtyMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

@Component({ components: {} })
export default class ProjectCreationMultipleDatePicker extends Vue {
  @Prop()
  public datepickerItem!: any;
  @Prop()
  public periodIndex!: number;
  @Prop()
  public periodName!: string;
  @Prop()
  public isDraftProject!: boolean;

  public dirtyDatepickerItem: any = null;
  public dirtyPeriodRange: Date[] = [];
  public showEditLabelInput: boolean = false;
  public newLabel: string = '';
  public emptyLabelError: boolean = false;

  public mounted() {
    this.dirtyDatepickerItem = _cloneDeep(this.datepickerItem);
    const startDate: Date = this.isDraftProject
      ? getStartDateTime(this.dirtyDatepickerItem.startDate)
      : this.dirtyDatepickerItem.startDate;
    const endDate: Date = this.isDraftProject
      ? getEndDateTime(this.dirtyDatepickerItem.endDate)
      : this.dirtyDatepickerItem.endDate;
    this.dirtyPeriodRange.push(startDate);
    this.dirtyPeriodRange.push(endDate);
  }

  public getDate(newValue: Date): string {
    return (
      newValue.getDate() +
      ' ' +
      dirtyMonths[newValue.getMonth()] +
      ' ' +
      newValue.getFullYear()
    );
  }

  public formatDataCollectionDateRange(date: Date[]): string {
    return this.getDate(date[0]) + ' - ' + this.getDate(date[1]);
  }

  public updateDatePeriod(newValue: Date[]) {
    const dataCollectionPeriodDate = {
      auditName: this.dirtyDatepickerItem.auditName,
      auditNumber: this.periodIndex,
      startDate: getStartDateTime(newValue[0]),
      endDate: getEndDateTime(newValue[1]),
    };
    this.$emit('updateDatePicker', dataCollectionPeriodDate);
  }
}
