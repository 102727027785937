








































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import AuditCriteriaList from '@/views/Project/components/AuditCriteriaList.vue';
import { Action, State } from 'vuex-class';
import {
  AuditDetail,
  AuditCriteria,
  UpdateAuditCriteriasRequestPayload,
} from '@/store/modules/audits/types/audits.types';
import {
  CplusCriteria,
  UpdateCriteriaDTO,
} from '@/store/modules/projects/types/projects.types';
import {
  isDifferentDeep,
  isTruthy,
} from '@/jbi-shared/util/watcher.vue-decorator';
import { isEqual as _isEqual } from 'lodash';
import { ToastProgrammatic as Toast } from 'buefy';
import JBICriteriaSelection from '@/views/Audit/components/JBICriteriaSelection.vue';
import { JBICriteriaDTO } from '../../../store/modules/projects/types/projects.types';

@Component({
  components: {
    JBICriteriaSelection,
    AuditCriteriaList,
  },
})
export default class AuditCriteriaTab extends Vue {
  @Prop({ default: true })
  public editable!: boolean;
  @Prop()
  public auditId!: number;
  @Prop()
  public auditDetail!: AuditDetail;
  public selectedCriteriaArray: CplusCriteria[] = [];
  public isAuditCriteriaSubmitButtonDisabled = true;
  public dirtyCriteriaArray: CplusCriteria[] = [];
  public deletedCriteriaList: number[] = [];

  @Action('audits/getAuditCriteria')
  public getAuditCriteria!: (auditId: number) => void;

  @State((state) => state.audits.auditCriteria)
  public auditCriteria!: AuditCriteria[];

  @State((state) => state.audits.apiState.getAuditCriteria.success)
  public getAuditCriteriaApiStateSuccess!: boolean;

  @Action('audits/updateAuditCriterias')
  public updateAuditCriterias!: (
    payload: UpdateAuditCriteriasRequestPayload,
  ) => void;

  @State((state) => state.audits.updatedAuditCriterias)
  public updatedAuditCriterias!: any;

  @State((state) => state.audits.updatedAuditJBICriteriaList)
  public updatedAuditJBICriteriaList!: JBICriteriaDTO[];

  @State(
    (state) => state.audits.apiState.getUpdatedAuditJBICriteriaList.success,
  )
  public getUpdatedAuditJBICriteriaListSuccess!: boolean;

  public mounted() {
    this.getAuditCriteria(this.auditId);
  }

  get selectedCriteriaList() {
    return this.selectedCriteriaArray;
  }

  set selectedCriteriaList(newValue: CplusCriteria[]) {
    this.selectedCriteriaArray = newValue;
  }

  @Watch('getAuditCriteriaApiStateSuccess')
  @isTruthy
  public watchAuditCriteriaSuccess(): void {
    this.transformCriterias(this.auditCriteria);
  }

  @Watch('getUpdatedAuditJBICriteriaListSuccess')
  @isTruthy
  public watchUpdatedAuditJBICriteriaListSuccess(): void {
    if (this.getAuditCriteriaApiStateSuccess) {
      this.transformCriterias(this.auditCriteria);
    }
  }

  public transformCriterias(criterias: any[]) {
    this.dirtyCriteriaArray = [];
    criterias.forEach((auditCriteria) => {
      if (
        auditCriteria.isDeleted === true ||
        auditCriteria.isActive === false
      ) {
        return;
      }
      const criteria = auditCriteria.criteria;
      const transformedCriteria: CplusCriteria = {
        id: criteria.id,
        title: criteria.title,
        type: criteria.criteriaType,
        criteriaOptions: JSON.parse(criteria.criteriaOptions).filter(
          (option: any) => {
            return option !== 'N/A';
          },
        ),
        booleanOptions: JSON.parse(criteria.criteriaOptions),
        range: JSON.parse(criteria.criteriaOptions),
        checkboxesOptions: JSON.parse(criteria.criteriaOptions),
        continuousDiscreteOptions: JSON.parse(criteria.criteriaOptions),
        document: {
          id: criteria.documentId,
          title: criteria.documentName,
          description: criteria.description,
        },
        guide: criteria.guide,
        tags: this.getTags(criteria.criteriaTags),
        bestPracticeRecommendations: [],
        criteriaDetails: criteria,
        order: auditCriteria.order,
        sourceLink: criteria.sourceLink,
        isChanged: criteria.isChanged,
        config: criteria.config,
      };
      if (
        this.updatedAuditJBICriteriaList &&
        this.updatedAuditJBICriteriaList.length > 0
      ) {
        this.updatedAuditJBICriteriaList.forEach(
          (updatedJbiCriteria: JBICriteriaDTO) => {
            if (
              updatedJbiCriteria.connectPlusCriteriaId ===
              +criteria.connectPlusId
            ) {
              transformedCriteria.updatedJbiCriteria = updatedJbiCriteria;
            }
          },
        );
      }
      this.dirtyCriteriaArray.push(transformedCriteria);
    });
    this.selectedCriteriaArray = this.dirtyCriteriaArray;
  }

  public getTags(tagsInfo: any) {
    const tagsList: string[] = [];
    if (tagsInfo) {
      tagsInfo.forEach((tags: any) => {
        tagsList.push(tags.tag);
      });
    }
    return tagsList;
  }

  @Watch('selectedCriteriaArray')
  @isTruthy
  @isDifferentDeep
  public watchSelectedCriteriaArray(newValue: any) {
    this.isAuditCriteriaSubmitButtonDisabled = _isEqual(
      this.dirtyCriteriaArray,
      newValue,
    );
  }

  @Watch('updatedAuditCriterias')
  @isTruthy
  public watchUpdatedAuditCriterias() {
    this.getAuditCriteria(this.auditId);
    Toast.open({
      message: `Criteria successfully updated.`,
      position: 'is-top',
      type: 'is-dark',
      duration: 3500,
    });
  }

  public openJBICriteriaSelectionTemplate() {
    this.$buefy.modal.open({
      parent: this,
      component: JBICriteriaSelection,
      fullScreen: true,
      hasModalCard: false,
      trapFocus: true,
      animation: 'unset',
      props: {
        auditName: this.auditDetail.name,
        selectedCriteriaArray: this.selectedCriteriaArray,
      },
      events: {
        updateSelectedCriteriaArray: (newValue: UpdateCriteriaDTO) => {
          this.selectedCriteriaArray = newValue.emitCriteriaArray;
          this.deletedCriteriaList = newValue.deletedCriteriaIds;
          this.updateAuditCriteriaHandler();
        },
      },
    });
  }

  public handleDeletedCriteria(criteria: any) {
    if (criteria.criteriaDetails) {
      this.deletedCriteriaList.push(criteria.criteriaDetails.id);
    }
  }

  public updateAuditCriteriaHandler() {
    this.updateAuditCriterias({
      auditId: this.auditId,
      criterias: this.selectedCriteriaArray,
      deletedCriteriaIds: this.deletedCriteriaList,
    });
  }
}
