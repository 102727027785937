




















































import { Component, Vue, Prop, Watch, Model } from 'vue-property-decorator';
import { State, Getter, Action, Mutation, namespace } from 'vuex-class';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '@/utils/date.util';
import {
  PaginatedAuditEventLogsPayload,
  PaginatedAuditEventLogsResponse,
} from '@/store/modules/audits/types/audits.types';
import { useUserEmail } from '@/utils/user.util';

@Component({
  components: {},
})
export default class AuditActivityLogList extends Vue {
  @Prop()
  public auditId!: number;

  @Action('audits/getPaginatedEventLogs')
  public getRecentEventLogs!: (params: PaginatedAuditEventLogsPayload) => void;

  @State((state) => state.audits.paginatedEventLogs)
  public recentEventLogs!: PaginatedAuditEventLogsResponse;

  public mounted() {
    this.getRecentEventLogs({
      auditId: this.auditId,
      limit: 10,
      page: 1,
    });
  }

  get histories() {
    if (this.recentEventLogs) {
      return this.recentEventLogs.items.map((log) => {
        const today = dayjs().format(DATE_FORMAT);
        const yesterday = dayjs().add(-1, 'day').format(DATE_FORMAT);
        const dataUpdatedAt = dayjs(new Date(log.eventOccurredAt)).format(
          DATE_FORMAT,
        );

        let dateToDisplay;
        if (dataUpdatedAt === today) {
          dateToDisplay = 'Today';
        } else if (dataUpdatedAt === yesterday) {
          dateToDisplay = 'Yesterday';
        } else {
          dateToDisplay = dataUpdatedAt;
        }

        return {
          ...log,
          // @ts-ignore
          datetime: dateToDisplay,
        };
      });
    }
    return [];
  }

  public handleNavigate() {
    this.$emit('changeActiveTab', '#activityLog');
  }

  public getUserName(userInfo: any) {
    const name = userInfo.name;
    const loggedInUserEmail = this.userEmail;
    if (loggedInUserEmail === userInfo.email) {
      return 'You';
    } else {
      return name;
    }
  }

  get userEmail(): string | undefined {
    return useUserEmail.call(this);
  }
}
