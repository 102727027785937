import { Store, ActionContext } from 'vuex';
import { auditsApi } from '@/api/audits.api';
import { RootState } from '@/store/store';
import { get as _get } from 'lodash';
import {
  AuditDetail,
  AuditsState,
  DataCollectionSubmitRequestPayload,
  DataCollectionSummaryRequestPayload,
  MultiSiteSamplingRequestPayload,
  SingleSiteSamplingRequestPayload,
  UpdateAuditCriteriasRequestPayload,
  UpdateAuditGeneralSettingRequestPayload,
  UpdateAuditDataCollectionSettingRequestPayload,
  DataCollectionActivityLogRequestPayload,
  AuditCriteria,
  AuditSite,
  DataCollectionSummaryResponsePayload,
  AuditSamplingResponse,
  AuditSamplingRequestPayload,
  DataCollectionRecord,
  DashboardChartDataRequestPayload,
  DashboardChartData,
  CreateAuditParticipantPayload,
  UpdateAuditParticipantPayload,
  PaginatedAuditEventLogsPayload,
  PaginatedAuditEventLogsResponse,
  UpdateAuditStatusPayload,
  UpdateAuditGripBarrierModalPayload,
  UpdateAuditGripEnablerModalPayload,
  UpdateAuditGripStrategyModalPayload,
  AuditGripBarrierModalPayload,
  AuditGripEnablerModalPayload,
  AuditGripStrategyModalPayload,
  ResumeEndDatePayload,
  AuditReportAnalysisPayload,
  UpdateAnalysisTitlePayload,
  DeleteAnalysisPayload,
  AuditReportAnalysis,
  UpdateAuditGripPayload,
  AuditGrip,
  DeleteAuditParticipantPayload,
} from '@/store/modules/audits/types/audits.types';
import {
  CplusCriteria,
  JBICriteriaDTO,
} from '../projects/types/projects.types';
export const auditsActions = {
  async getAuditDetail(
    store: ActionContext<AuditsState, RootState>,
    auditId: number,
  ) {
    try {
      store.commit('GET_AUDIT_LOADING');

      const {
        data,
      }: {
        data: AuditDetail;
      } = await auditsApi.getAuditDetail(auditId);

      store.commit('GET_AUDIT_SUCCESS', data);
    } catch (error) {
      store.commit('GET_AUDIT_ERROR', error);
    }
  },

  async updateSingleSiteSampling(
    store: ActionContext<AuditsState, RootState>,
    payload: SingleSiteSamplingRequestPayload,
  ) {
    try {
      store.commit('UPDATE_SINGLE_SITE_SAMPLING_LOADING');

      const {
        data,
      }: {
        data: AuditDetail;
      } = await auditsApi.updateSingleSiteSampling(payload.auditId, payload);

      store.commit('UPDATE_SINGLE_SITE_SAMPLING_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_SINGLE_SITE_SAMPLING_ERROR', error);
    }
  },

  async updateMultiSiteSampling(
    store: ActionContext<AuditsState, RootState>,
    payload: MultiSiteSamplingRequestPayload,
  ) {
    try {
      store.commit('UPDATE_MULTI_SITE_SAMPLING_LOADING');

      const {
        data,
      }: {
        data: AuditDetail;
      } = await auditsApi.updateMultiSiteSampling(payload.auditId, payload);

      store.commit('UPDATE_MULTI_SITE_SAMPLING_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_MULTI_SITE_SAMPLING_ERROR', error);
    }
  },

  async getDataCollectionSummary(
    store: ActionContext<AuditsState, RootState>,
    payload: DataCollectionSummaryRequestPayload,
  ) {
    try {
      store.commit('GET_DATA_COLLECTION_SUMMARY_LOADING');
      const {
        data,
      }: {
        data: DataCollectionSummaryResponsePayload;
      } = await auditsApi.getDataCollectionSummary(payload.auditId, payload);

      store.commit('GET_DATA_COLLECTION_SUMMARY_SUCCESS', data);
    } catch (error) {
      store.commit('GET_DATA_COLLECTION_SUMMARY_ERROR', error);
    }
  },

  async postDataCollection(
    store: ActionContext<AuditsState, RootState>,
    payload: DataCollectionSubmitRequestPayload,
  ) {
    try {
      store.commit('POST_DATA_COLLECTION_LOADING');

      const {
        data,
      }: {
        data: any;
      } = await auditsApi.postDataCollection(payload);

      store.commit('POST_DATA_COLLECTION_SUCCESS', data);
    } catch (error) {
      store.commit('POST_DATA_COLLECTION_ERROR', error);
    }
  },

  async getDataCollectionActivityLogs(
    store: ActionContext<AuditsState, RootState>,
    payload: DataCollectionActivityLogRequestPayload,
  ) {
    try {
      store.commit('GET_DATA_COLLECTION_ACTIVITY_LOGS_LOADING');

      const {
        data,
      }: {
        data: any;
      } = await auditsApi.getDataCollectionActivityLogs(
        payload.auditId,
        payload,
      );

      store.commit('GET_DATA_COLLECTION_ACTIVITY_LOGS_SUCCESS', data);
    } catch (error) {
      store.commit('GET_DATA_COLLECTION_ACTIVITY_LOGS_ERROR', error);
    }
  },

  async getDataCollectionUserActivityLogs(
    store: ActionContext<AuditsState, RootState>,
    payload: DataCollectionActivityLogRequestPayload,
  ) {
    try {
      store.commit('GET_DATA_COLLECTION_ACTIVITY_LOGS_LOADING');

      const {
        data,
      }: {
        data: any;
      } = await auditsApi.getDataCollectionUserActivityLogs(
        payload.auditId,
        payload,
      );

      store.commit('GET_DATA_COLLECTION_ACTIVITY_LOGS_SUCCESS', data);
    } catch (error) {
      store.commit('GET_DATA_COLLECTION_ACTIVITY_LOGS_ERROR', error);
    }
  },

  async startAudit(
    store: ActionContext<AuditsState, RootState>,
    auditId: number,
  ) {
    try {
      store.commit('START_AUDIT_LOADING');

      const {
        data,
      }: {
        data: any;
      } = await auditsApi.startAudit(auditId);

      store.commit('START_AUDIT_SUCCESS', data);
    } catch (error) {
      store.commit('START_AUDIT_ERROR', error);
    }
  },

  async endAudit(
    store: ActionContext<AuditsState, RootState>,
    auditId: number,
  ) {
    try {
      store.commit('END_AUDIT_LOADING');

      const {
        data,
      }: {
        data: any;
      } = await auditsApi.endAudit(auditId);

      store.commit('END_AUDIT_SUCCESS', data);
    } catch (error) {
      store.commit('END_AUDIT_ERROR', error);
    }
  },

  async resumeAudit(
    store: ActionContext<AuditsState, RootState>,
    payload: ResumeEndDatePayload,
  ) {
    try {
      store.commit('RESUME_AUDIT_LOADING');

      const {
        data,
      }: {
        data: any;
      } = await auditsApi.resumeAudit(payload.auditId, payload);

      store.commit('RESUME_AUDIT_SUCCESS', data);
    } catch (error) {
      store.commit('RESUME_AUDIT_ERROR', error);
    }
  },

  async updateAuditCriterias(
    store: ActionContext<AuditsState, RootState>,
    payload: UpdateAuditCriteriasRequestPayload,
  ) {
    try {
      store.commit('UPDATE_AUDIT_CRITEIAS_LOADING');

      const { data } = await auditsApi.updateAuditCriterias(
        payload.auditId,
        payload,
      );

      store.commit('UPDATE_AUDIT_CRITEIAS_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_AUDIT_CRITEIAS_ERROR', error);
    }
  },

  async updateAuditCriteriaById(
    store: ActionContext<AuditsState, RootState>,
    payload: { auditId: number; criteria: CplusCriteria },
  ) {
    try {
      store.commit('UPDATE_AUDIT_CRITEIA_BY_ID_LOADING');

      const { data } = await auditsApi.updateAuditCriteriaById(
        payload.auditId,
        payload.criteria,
      );

      store.commit('UPDATE_AUDIT_CRITEIA_BY_ID_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_AUDIT_CRITEIA_BY_ID_ERROR', error);
    }
  },

  async getDataCollectionRecord(
    store: ActionContext<AuditsState, RootState>,
    payload: { auditId: number; dataCollectionRecordId: number },
  ): Promise<void> {
    try {
      store.commit('GET_DATA_COLLECTION_RECORD_LOADING');

      const {
        data,
      }: {
        data: DataCollectionRecord;
      } = await auditsApi.getDataCollectionRecord(payload);

      store.commit('GET_DATA_COLLECTION_RECORD_SUCCESS', data);
    } catch (error) {
      store.commit('GET_DATA_COLLECTION_RECORD_ERROR', error);
    }
  },

  async deleteDataCollectionRecord(
    store: ActionContext<AuditsState, RootState>,
    payload: { auditId: number; dataCollectionRecordId: number },
  ) {
    try {
      store.commit('DELETE_DATA_COLLECTION_RECORD_LOADING');

      const {
        data,
      }: {
        data: any;
      } = await auditsApi.deleteDataCollectionRecord(payload);

      store.commit('DELETE_DATA_COLLECTION_RECORD_SUCCESS', data);
    } catch (error) {
      store.commit('DELETE_DATA_COLLECTION_RECORD_ERROR', error);
    }
  },

  async updateAuditGeneralSettings(
    store: ActionContext<AuditsState, RootState>,
    payload: UpdateAuditGeneralSettingRequestPayload,
  ) {
    try {
      store.commit('UPDATE_AUDIT_GENERAL_SETTING_LOADING');
      const { data } = await auditsApi.updateAuditGeneralSettings(
        payload.auditId,
        payload,
      );

      store.commit('UPDATE_AUDIT_GENERAL_SETTING_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_AUDIT_GENERAL_SETTING_ERROR', error);
    }
  },

  async updateAuditDataCollectionSettings(
    store: ActionContext<AuditsState, RootState>,
    payload: UpdateAuditDataCollectionSettingRequestPayload,
  ) {
    try {
      store.commit('UPDATE_AUDIT_DATA_COLLECTION_SETTING_LOADING');
      const { data } = await auditsApi.updateAuditDataCollectionSettings(
        payload.auditId,
        payload,
      );
      store.commit('UPDATE_AUDIT_DATA_COLLECTION_SETTING_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_AUDIT_DATA_COLLECTION_SETTING_ERROR', error);
    }
  },

  async getAuditDetailAndSettings(
    store: ActionContext<AuditsState, RootState>,
    auditId: number,
  ) {
    try {
      store.commit('GET_AUDIT_DETAIL_AND_SETTINGS_LOADING');

      const { data } = await auditsApi.getAuditDetailAndSettings(auditId);

      store.commit('GET_AUDIT_DETAIL_AND_SETTINGS_SUCCESS', data);
    } catch (error) {
      store.commit('GET_AUDIT_DETAIL_AND_SETTINGS_ERROR', error);
    }
  },

  async getAuditCriteria(
    store: ActionContext<AuditsState, RootState>,
    auditId: number,
  ) {
    try {
      store.commit('GET_AUDIT_CRITERIA_LOADING');

      const {
        data,
      }: {
        data: AuditCriteria[];
      } = await auditsApi.getAuditCriteria(auditId);

      store.commit('GET_AUDIT_CRITERIA_SUCCESS', data);
    } catch (error) {
      store.commit('GET_AUDIT_CRITERIA_ERROR', error);
    }
  },

  async getAuditSites(
    store: ActionContext<AuditsState, RootState>,
    auditId: number,
  ) {
    try {
      store.commit('GET_AUDIT_SITES_LOADING');

      const {
        data,
      }: {
        data: AuditSite[];
      } = await auditsApi.getAuditSites(auditId);

      store.commit('GET_AUDIT_SITES_SUCCESS', data);
    } catch (error) {
      store.commit('GET_AUDIT_SITES_ERROR', error);
    }
  },

  async getAuditSitesForDataCollection(
    store: ActionContext<AuditsState, RootState>,
    auditId: number,
  ) {
    try {
      store.commit('GET_AUDIT_SITES_LOADING');

      const {
        data,
      }: {
        data: AuditSite[];
      } = await auditsApi.getAuditSitesForDataCollection(auditId);

      store.commit('GET_AUDIT_SITES_SUCCESS', data);
    } catch (error) {
      store.commit('GET_AUDIT_SITES_ERROR', error);
    }
  },

  async getSamplingConfig(
    store: ActionContext<AuditsState, RootState>,
    payload: AuditSamplingRequestPayload,
  ) {
    try {
      store.commit('GET_SAMPLING_CONFIG_LOADING');

      const {
        data,
      }: {
        data: AuditSamplingResponse;
      } = await auditsApi.getSamplingConfig(payload.auditId, payload);

      store.commit('GET_SAMPLING_CONFIG_SUCCESS', data);
    } catch (error) {
      store.commit('GET_SAMPLING_CONFIG_ERROR', error);
    }
  },

  async getDashboardChartData(
    store: ActionContext<AuditsState, RootState>,
    payload: DashboardChartDataRequestPayload,
  ) {
    try {
      store.commit('GET_DASHBOARD_CHART_DATA_LOADING');

      const {
        data,
      }: {
        data: DashboardChartData[];
      } = await auditsApi.getDashboardChartData(payload.auditId, payload);

      store.commit('GET_DASHBOARD_CHART_DATA_SUCCESS', data);
    } catch (error) {
      store.commit('GET_DASHBOARD_CHART_DATA_ERROR', error);
    }
  },
  async getParticipants(
    store: ActionContext<AuditsState, RootState>,
    auditId: number,
  ) {
    try {
      store.commit('GET_PARTICIPANTS_LOADING');

      const { data } = await auditsApi.getParticipants(auditId);

      store.commit('GET_PARTICIPANTS_SUCCESS', data);
    } catch (error) {
      store.commit('GET_PARTICIPANTS_ERROR', error);
    }
  },
  async createParticipant(
    store: ActionContext<AuditsState, RootState>,
    payload: CreateAuditParticipantPayload,
  ) {
    try {
      store.commit('CREATE_PARTICIPANT_LOADING');
      await auditsApi.createParticipant(payload);
      store.commit('CREATE_PARTICIPANT_SUCCESS');
    } catch (error) {
      store.commit('CREATE_PARTICIPANT_ERROR', error);
    }
  },
  async updateParticipant(
    store: ActionContext<AuditsState, RootState>,
    payload: UpdateAuditParticipantPayload,
  ) {
    try {
      store.commit('UPDATE_PARTICIPANT_LOADING');
      await auditsApi.updateParticipant(payload);
      store.commit('UPDATE_PARTICIPANT_SUCCESS');
    } catch (error) {
      store.commit('UPDATE_PARTICIPANT_ERROR', error);
    }
  },
  async deleteParticipant(
    store: ActionContext<AuditsState, RootState>,
    payload: DeleteAuditParticipantPayload,
  ) {
    try {
      store.commit('DELETE_PARTICIPANT_LOADING');
      await auditsApi.deleteParticipant(payload);
      store.commit('DELETE_PARTICIPANT_SUCCESS');
    } catch (error) {
      store.commit('DELETE_PARTICIPANT_ERROR', error);
    }
  },
  async resendInvitation(
    store: ActionContext<AuditsState, RootState>,
    payload: {
      auditId: number;
      invitationId: number;
      auditParticipantId: number;
    },
  ) {
    try {
      store.commit('RESEND_INVITATION_LOADING');
      await auditsApi.resendInvitation(payload);
      store.commit('RESEND_INVITATION_SUCCESS');
    } catch (error) {
      store.commit('RESEND_INVITATION_ERROR', error);
    }
  },

  async getPaginatedEventLogs(
    store: Store<AuditsState>,
    params: PaginatedAuditEventLogsPayload,
  ) {
    try {
      store.commit('GET_PAGINATED_EVENT_LOGS_LOADING');
      const {
        data,
      }: {
        data: PaginatedAuditEventLogsResponse;
      } = await auditsApi.getPaginatedEventLogs(params);

      store.commit('GET_PAGINATED_EVENT_LOGS_SUCCESS', data);
    } catch (error) {
      store.commit('GET_PAGINATED_EVENT_LOGS_ERROR', error);
    }
  },

  async updateStatus(
    store: Store<AuditsState>,
    params: UpdateAuditStatusPayload,
  ) {
    try {
      store.commit('UPDATE_STATUS_LOADING');
      const {
        data,
      }: {
        data: any;
      } = await auditsApi.updateStatus(params.auditId, params);

      store.commit('UPDATE_STATUS_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_STATUS_ERROR', error);
    }
  },

  async updateAuditGripByAuditId(
    store: ActionContext<AuditsState, RootState>,
    payload: UpdateAuditGripPayload,
  ) {
    try {
      store.commit('UPDATE_AUDIT_GRIP_LOADING');
      const { data } = await auditsApi.updateAuditGripByAuditId(
        payload.auditId,
        payload,
      );
      store.commit('UPDATE_AUDIT_GRIP_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_AUDIT_GRIP_ERROR', error);
    }
  },

  async getAuditGripByAuditId(
    store: ActionContext<AuditsState, RootState>,
    auditId: number,
  ) {
    try {
      store.commit('GET_AUDIT_GRIP_LOADING');

      const {
        data,
      }: {
        data: AuditGrip;
      } = await auditsApi.getAuditGripByAuditId(auditId);

      store.commit('GET_AUDIT_GRIP_SUCCESS', data);
    } catch (error) {
      store.commit('GET_AUDIT_GRIP_ERROR', error);
    }
  },

  async createAuditGripBarrierModal(
    store: ActionContext<AuditsState, RootState>,
    payload: AuditGripBarrierModalPayload,
  ) {
    try {
      store.commit('POST_AUDIT_GRIP_BARRIER_MODAL_LOADING');

      const {
        data,
      }: {
        data: any;
      } = await auditsApi.createAuditGripBarrierModal(payload);

      store.commit('POST_AUDIT_GRIP_BARRIER_MODAL_SUCCESS', data);
    } catch (error) {
      store.commit('POST_AUDIT_GRIP_BARRIER_MODAL_ERROR', error);
    }
  },

  async updateAuditGripBarrierModal(
    store: ActionContext<AuditsState, RootState>,
    payload: UpdateAuditGripBarrierModalPayload,
  ) {
    try {
      store.commit('UPDATE_AUDIT_GRIP_BARRIER_MODAL_LOADING');
      const { data } = await auditsApi.updateAuditGripBarrierModal(
        payload.auditId,
        payload,
      );
      store.commit('UPDATE_AUDIT_GRIP_BARRIER_MODAL_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_AUDIT_GRIP_BARRIER_MODAL_ERROR', error);
    }
  },

  async getAuditGripBarrierModal(
    store: ActionContext<AuditsState, RootState>,
    auditId: number,
  ) {
    try {
      store.commit('GET_AUDIT_GRIP_BARRIER_MODAL_LOADING');

      const {
        data,
      }: {
        data: UpdateAuditGripBarrierModalPayload[];
      } = await auditsApi.getAuditGripBarrierModal(auditId);

      store.commit('GET_AUDIT_GRIP_BARRIER_MODAL_SUCCESS', data);
    } catch (error) {
      store.commit('GET_AUDIT_GRIP_BARRIER_MODAL_ERROR', error);
    }
  },

  async deleteGripBarrierRecord(
    store: ActionContext<AuditsState, RootState>,
    payload: {
      auditId: number;
      gripBarrierId: number;
    },
  ) {
    try {
      store.commit('DELETE_GRIP_BARRIER_RECORD_LOADING');

      const {
        data,
      }: {
        data: any;
      } = await auditsApi.deleteGripBarrierRecord(payload);

      store.commit('DELETE_GRIP_BARRIER_RECORD_SUCCESS', data);
    } catch (error) {
      store.commit('DELETE_GRIP_BARRIER_RECORD_ERROR', error);
    }
  },

  async createAuditGripEnablerModal(
    store: ActionContext<AuditsState, RootState>,
    payload: AuditGripEnablerModalPayload,
  ) {
    try {
      store.commit('POST_AUDIT_GRIP_ENABLER_MODAL_LOADING');

      const {
        data,
      }: {
        data: any;
      } = await auditsApi.createAuditGripEnablerModal(payload);

      store.commit('POST_AUDIT_GRIP_ENABLER_MODAL_SUCCESS', data);
    } catch (error) {
      store.commit('POST_AUDIT_GRIP_ENABLER_MODAL_ERROR', error);
    }
  },

  async updateAuditGripEnablerModal(
    store: ActionContext<AuditsState, RootState>,
    payload: UpdateAuditGripEnablerModalPayload,
  ) {
    try {
      store.commit('UPDATE_AUDIT_GRIP_ENABLER_MODAL_LOADING');
      const { data } = await auditsApi.updateAuditGripEnablerModal(
        payload.auditId,
        payload,
      );
      store.commit('UPDATE_AUDIT_GRIP_ENABLER_MODAL_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_AUDIT_GRIP_ENABLER_MODAL_ERROR', error);
    }
  },

  async getAuditGripEnablerModal(
    store: ActionContext<AuditsState, RootState>,
    auditId: number,
  ) {
    try {
      store.commit('GET_AUDIT_GRIP_ENABLER_MODAL_LOADING');

      const {
        data,
      }: {
        data: UpdateAuditGripEnablerModalPayload;
      } = await auditsApi.getAuditGripEnablerModal(auditId);

      store.commit('GET_AUDIT_GRIP_ENABLER_MODAL_SUCCESS', data);
    } catch (error) {
      store.commit('GET_AUDIT_GRIP_MODAL_ERROR', error);
    }
  },

  async deleteGripEnablerRecord(
    store: ActionContext<AuditsState, RootState>,
    payload: {
      auditId: number;
      gripEnablerId: number;
    },
  ) {
    try {
      store.commit('DELETE_GRIP_ENABLER_RECORD_LOADING');

      const {
        data,
      }: {
        data: any;
      } = await auditsApi.deleteGripEnablerRecord(payload);

      store.commit('DELETE_GRIP_ENABLER_RECORD_SUCCESS', data);
    } catch (error) {
      store.commit('DELETE_GRIP_ENABLER_RECORD_ERROR', error);
    }
  },

  async createAuditGripStrategyModal(
    store: ActionContext<AuditsState, RootState>,
    payload: AuditGripStrategyModalPayload,
  ) {
    try {
      store.commit('POST_AUDIT_GRIP_STRATEGY_MODAL_LOADING');

      const {
        data,
      }: {
        data: any;
      } = await auditsApi.createAuditGripStrategyModal(payload);

      store.commit('POST_AUDIT_GRIP_STRATEGY_MODAL_SUCCESS', data);
    } catch (error) {
      store.commit('POST_AUDIT_GRIP_STRATEGY_MODAL_ERROR', error);
    }
  },

  async updateAuditGripStrategyModal(
    store: ActionContext<AuditsState, RootState>,
    payload: UpdateAuditGripStrategyModalPayload,
  ) {
    try {
      store.commit('UPDATE_AUDIT_GRIP_STRATEGY_MODAL_LOADING');
      const { data } = await auditsApi.updateAuditGripStrategyModal(
        payload.auditId,
        payload,
      );
      store.commit('UPDATE_AUDIT_GRIP_STRATEGY_MODAL_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_AUDIT_GRIP_STRATEGY_MODAL_ERROR', error);
    }
  },

  async getAuditGripStrategyModal(
    store: ActionContext<AuditsState, RootState>,
    auditId: number,
  ) {
    try {
      store.commit('GET_AUDIT_GRIP_STRATEGY_MODAL_LOADING');

      const {
        data,
      }: {
        data: UpdateAuditGripStrategyModalPayload;
      } = await auditsApi.getAuditGripStrategyModal(auditId);

      store.commit('GET_AUDIT_GRIP_STRATEGY_MODAL_SUCCESS', data);
    } catch (error) {
      store.commit('GET_AUDIT_GRIP_STRATEGY_MODAL_ERROR', error);
    }
  },

  async deleteGripStrategyRecord(
    store: ActionContext<AuditsState, RootState>,
    payload: {
      auditId: number;
      gripStrategyId: number;
    },
  ) {
    try {
      store.commit('DELETE_GRIP_STRATEGY_RECORD_LOADING');

      const {
        data,
      }: {
        data: any;
      } = await auditsApi.deleteGripStrategyRecord(payload);

      store.commit('DELETE_GRIP_STRATEGY_RECORD_SUCCESS', data);
    } catch (error) {
      store.commit('DELETE_GRIP_STRATEGY_RECORD_ERROR', error);
    }
  },

  async updateAuditCriteriaGuide(store: Store<AuditsState>, payload: any) {
    try {
      store.commit('GET_AUDIT_CRITERIA_LOADING');
      const {
        data,
      }: {
        data: AuditCriteria[];
      } = await auditsApi.updateAuditCriteriaGuide(payload);
      store.commit('GET_AUDIT_CRITERIA_SUCCESS', data);
    } catch (error) {
      store.commit('GET_AUDIT_CRITERIA_ERROR', error);
    }
  },

  async getAuditReportAnalysis(
    store: Store<AuditsState>,
    payload: AuditReportAnalysisPayload,
  ): Promise<void> {
    try {
      store.commit('GET_AUDIT_REPORT_ANALYSIS_LOADING');

      const {
        data,
      }: {
        data: any;
      } = await auditsApi.getAuditReportAnalysis(payload.auditId, payload);
      store.commit('GET_AUDIT_REPORT_ANALYSIS_SUCCESS', data);
    } catch (error) {
      store.commit('GET_AUDIT_REPORT_ANALYSIS_ERROR', error);
    }
  },

  async getTotalAuditReportAnalysis(
    store: Store<AuditsState>,
    payload: { projectId: number; auditId: number },
  ): Promise<void> {
    try {
      store.commit('GET_TOTAL_AUDIT_REPORT_ANALYSIS_LOADING');

      const {
        data,
      }: {
        data: AuditReportAnalysis[];
      } = await auditsApi.getTotalAuditReportAnalysis(
        payload.auditId,
        payload.projectId,
      );
      store.commit('GET_TOTAL_AUDIT_REPORT_ANALYSIS_SUCCESS', data);
    } catch (error) {
      store.commit('GET_TOTAL_AUDIT_REPORT_ANALYSIS_ERROR', error);
    }
  },

  async updateAuditReportAnalysisTitleById(
    store: Store<AuditsState>,
    payload: UpdateAnalysisTitlePayload,
  ): Promise<void> {
    try {
      store.commit('UPDATE_AUDIT_REPORT_ANALYSIS_LOADING');

      const {
        data,
      }: {
        data: any;
      } = await auditsApi.updateAuditReportAnalysisTitleById(payload);
      store.commit('UPDATE_AUDIT_REPORT_ANALYSIS_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_AUDIT_REPORT_ANALYSIS_ERROR', error);
    }
  },

  async deleteAuditReportAnalysisById(
    store: Store<AuditsState>,
    payload: DeleteAnalysisPayload,
  ): Promise<void> {
    try {
      store.commit('DELETE_AUDIT_REPORT_ANALYSIS_LOADING');

      const {
        data,
      }: {
        data: any;
      } = await auditsApi.deleteAuditReportAnalysisById(payload);
      store.commit('DELETE_AUDIT_REPORT_ANALYSIS_SUCCESS', data);
    } catch (error) {
      store.commit('DELETE_AUDIT_REPORT_ANALYSIS_ERROR', error);
    }
  },

  async getUpdatedAuditJBICriteriaList(
    store: Store<AuditsState>,
    auditId: number,
  ): Promise<void> {
    try {
      store.commit('GET_UPDATED_AUDIT_JBI_CRITERIA_LIST_LOADING');

      const {
        data,
      }: {
        data: JBICriteriaDTO[];
      } = await auditsApi.getUpdatedAuditJBICriteriaList(auditId);

      store.commit('GET_UPDATED_AUDIT_JBI_CRITERIA_LIST_SUCCESS', data);
    } catch (error) {
      store.commit('GET_UPDATED_AUDIT_JBI_CRITERIA_LIST_ERROR', error);
    }
  },
};
