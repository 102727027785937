





















































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import { Action, State } from 'vuex-class';
import HeroBar from '@/components/base/HeroBar.vue';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import {
  AuditSamplingDataConfig,
  AuditSamplingResponse,
  AuditSamplingType,
  ConsecutiveSamplingMode,
  SingleSitePayload,
  SingleSiteSamplingRequestPayload,
} from '@/store/modules/audits/types/audits.types';
import {
  map as _map,
  round as _round,
  cloneDeep as _cloneDeep,
  orderBy as _orderBy,
  findIndex as _findIndex,
} from 'lodash';
import { ToastProgrammatic as Toast } from 'buefy';
import SamplingCalculator from '@/views/Audit/components/SamplingCalculator.vue';
import ConsecutiveCalculator from '@/views/Audit/components/ConsecutiveCalculator.vue';

@Component({
  components: {
    ConsecutiveCalculator,
    SamplingCalculator,
    DefaultLayout,
    HeroBar,
  },
})
export default class ConsecutiveMinAndMaxSingleSiteSampling extends Vue {
  @Prop()
  public auditId!: number;
  @Prop()
  public auditConfig!: AuditSamplingResponse;
  @Prop()
  public auditSamplingType!: AuditSamplingType;
  @Prop()
  public consecutiveSamplingMode!: ConsecutiveSamplingMode;
  @Prop()
  public validateCurrentDataEntryValue!: boolean;
  @Prop({ default: true })
  public editable!: boolean;
  public singleSitePayload: SingleSitePayload[] = [];
  public validateDataEntry: boolean = false;

  @Action('audits/updateSingleSiteSampling')
  public updateSingleSiteSampling!: (
    payload: SingleSiteSamplingRequestPayload,
  ) => void;

  @State((state) => state.audits.updatedSingleSiteSampling)
  public updatedSingleSiteSampling!: any;

  @Watch('updatedSingleSiteSampling')
  @isTruthy
  public watchUpdatedSingleSiteSampling() {
    this.showNotificationAndRedirectToProject();
  }

  public mounted() {
    this.validateDataEntry = this.validateCurrentDataEntryValue;
    this.populateSingeSiteData();
  }

  public populateSingeSiteData() {
    _map(this.auditConfig.dataConfigs, (dataConfig: any) => {
      const currentCriteriaPayload: SingleSitePayload = {
        auditId: this.auditId,
        criteriaId: 0, // todo: change this logic later
        auditCriteriaId: 0,
        minSampleSize: null,
        maxSampleSize: null,
      };
      _map(dataConfig, (singleConfig: AuditSamplingDataConfig) => {
        if (singleConfig.auditCriteriaMap.isActive) {
          currentCriteriaPayload.criteriaId =
            singleConfig.auditCriteriaMap.criteria.id;
          currentCriteriaPayload.auditId = this.auditId;
          currentCriteriaPayload.auditCriteriaId =
            singleConfig.auditCriteriaMap.id;
          currentCriteriaPayload.criteriaTitle =
            singleConfig.auditCriteriaMap.criteria.title;
          currentCriteriaPayload.order = singleConfig.auditCriteriaMap.order;
          if (singleConfig.auditSiteMap === null) {
            currentCriteriaPayload.minSampleSize =
              singleConfig.minSampleSize !== null
                ? _round(singleConfig.minSampleSize)
                : null;
            currentCriteriaPayload.maxSampleSize =
              singleConfig.maxSampleSize !== null
                ? _round(singleConfig.maxSampleSize)
                : null;
            currentCriteriaPayload.dataConfig = singleConfig;
          }
        }
      });
      this.singleSitePayload.push(currentCriteriaPayload);
    });
  }

  public showNotificationAndRedirectToProject() {
    Toast.open({
      message: `Changes saved`,
      position: 'is-top',
      type: 'is-dark',
      duration: 3500,
    });
  }

  public handleSingleSiteChanges() {
    this.updateSingleSiteSampling({
      auditId: this.auditId,
      singleSitePayload: this.singleSitePayload,
      auditSamplingType: this.auditSamplingType,
      auditSamplingMode: this.consecutiveSamplingMode,
      validateDataEntry: this.validateDataEntry,
      samplingConfigId: this.auditConfig.auditSamplingConfigs.id,
    });
  }

  public validateRange(index: number, type: string, min: number, max: number) {
    if (type === 'min' && Number(max) > 0 && Number(max) < Number(min)) {
      this.auditCriteriaList[index].minSampleSize = null;
      Toast.open({
        message: `Min value cannot be greater than max value`,
        position: 'is-top',
        type: 'is-danger',
        duration: 3000,
      });
    } else if (Number(max) > 0 && Number(max) < Number(min)) {
      this.auditCriteriaList[index].maxSampleSize = null;
      Toast.open({
        message: `Min value cannot be greater than max value`,
        position: 'is-top',
        type: 'is-danger',
        duration: 3000,
      });
    }
  }

  get auditCriteriaList() {
    return _orderBy(this.singleSitePayload, ['order'], ['asc']);
  }
}
