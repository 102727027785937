





















import { Component, Vue, Watch } from 'vue-property-decorator';
import { useAuthState } from '@/utils/store.util';
import DefaultLayout from '../../layouts/DefaultLayout.vue';
import HeroBar from '@/components/base/HeroBar.vue';
import DashboardProjectList from '@/views/Dashboard/components/DashboardProjectList.vue';
import DashboardDataHistory from '@/views/Dashboard/components/DashboardDataHistory.vue';
import DashboardRecentEventLogs from '@/views/Dashboard/components/DashboardRecentEventLogs.vue';

@Component({
  components: {
    DashboardRecentEventLogs,
    DashboardDataHistory,
    DashboardProjectList,
    DefaultLayout,
    HeroBar,
  },
})
export default class DashboardPage extends Vue {
  get isLoggedIn(): boolean {
    return useAuthState.call(this).isLoggedIn;
  }
}
