





















































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import {
  AuditCriteria,
  AuditDetail,
  AuditSamplingRequestPayload,
  AuditSamplingResponse,
  AuditSite,
  AuditSiteListDto,
  DataCollectionRecord,
  DataCollectionSubmitRequestPayload,
} from '@/store/modules/audits/types/audits.types';
import { Action, State } from 'vuex-class';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { ToastProgrammatic as Toast } from 'buefy';
import DataCollectionAggregate from '@/views/Audit/components/DataCollectionAggregate.vue';
import DataCollectionIndividual from '@/views/Audit/components/DataCollectionIndividual.vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import { useUserEmail } from '@/utils/user.util';
import { SortOrder } from '@/store/types/general.types';
import { UserRoleInAudit } from '@/store/modules/projects/types/projects.types';
import { orderByUtil } from '@/jbi-shared/util/sort.util';

const dirtyMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

@Component({
  components: {
    DefaultLayout,
    DataCollectionAggregate,
    DataCollectionIndividual,
  },
})
export default class DataCollectionPage extends Vue {
  public singleAuditCriteriaId!: number;
  public editDataCollectionRecord!: any;

  public dataCollectionType: string = 'aggregate';
  public newEntryTitle: boolean = true;
  public renderComponent: boolean = true;
  public breadcrumbTitle: string = 'Submit New Record';
  public defaultSiteId: number | null = null;
  public isNextEnabled: boolean = false;
  public isPreviousEnabled: boolean = false;
  public auditDetail: AuditDetail | null = null;
  public currentComponentKey: number = Math.floor(Math.random() * 999);
  public isDataCollector: boolean = false;

  @Action('audits/getAuditDetail')
  public getAuditDetail!: (auditId: number) => void;

  @State((state) => state.audits.auditDetail)
  public stateAuditDetail!: AuditDetail;

  @Action('audits/getAuditCriteria')
  public getAuditCriteria!: (auditId: number) => void;

  @State((state) => state.audits.auditCriteria)
  public auditCriteria!: AuditCriteria[];

  @Action('audits/getAuditSitesForDataCollection')
  public getAuditSitesForDataCollection!: (auditId: number) => void;

  @State((state) => state.audits.auditSites)
  public auditSites!: AuditSite[];

  @Action('audits/postDataCollection')
  public fetchDataCollection!: (
    payload: DataCollectionSubmitRequestPayload,
  ) => void;

  @State((state) => state.audits.dataCollectionResponse)
  public dataCollectionResponse!: any;

  @Action('audits/getSamplingConfig')
  public getSamplingConfig!: (payload: AuditSamplingRequestPayload) => void;

  @State((state) => state.audits.auditConfig)
  public auditConfig!: AuditSamplingResponse;

  @Action('audits/getDataCollectionRecord')
  public getDataCollectionRecord!: (payload: {
    auditId: number;
    dataCollectionRecordId: number;
  }) => void;

  @State((state) => state.audits.dataCollectionRecord)
  public dataCollectionRecord!: DataCollectionRecord;

  @Watch('auditSites')
  @isTruthy
  public watchAuditSites(newValue: AuditSite[]) {
    this.defaultSiteId = newValue[0].id;
  }

  @Watch('dataCollectionRecord')
  @isTruthy
  public watchDataCollectionRecord(newValue: DataCollectionRecord) {
    this.newEntryTitle = false;
    this.dataCollectionType = newValue.dataCollectionType;
    this.breadcrumbTitle = 'Edit Record';
    this.editDataCollectionRecord = newValue;
    this.isPreviousEnabled = newValue.previousRecord !== undefined;
    this.isNextEnabled = newValue.nextRecord !== undefined;
  }

  @Watch('stateAuditDetail')
  @isTruthy
  public watchStateAuditDetail(auditDetails: AuditDetail) {
    this.auditDetail = auditDetails;
  }

  @Watch('dataCollectionResponse')
  @isTruthy
  public watchDataCollectionResponse(newValue: any) {
    Toast.open({
      message: `Changes saved`,
      position: 'is-top',
      type: 'is-dark',
      duration: 3500,
    });
    const auditId = this.auditId.toString();
    if (this.isDataCollector) {
      this.$router.push({
        path: `/audits/${auditId}/homepage#dataHistory`,
      });
    } else {
      this.$router.push({
        name: 'audit',
        params: {
          auditId,
        },
        hash: '#dataHistory',
      });
    }
  }

  public mounted() {
    this.fetchData();
  }

  public getFormattedTitle(title: string) {
    if (title.length > 45) {
      return title.substring(0, 45) + '...';
    } else {
      return title;
    }
  }

  public fetchData() {
    if (!this.auditDetail) {
      this.getAuditDetail(this.auditId);
    }
    this.getDataCollectionRecord({
      auditId: this.auditId,
      dataCollectionRecordId: this.dataCollectionRecordId,
    });
    this.getAuditCriteria(this.auditId);
    this.getAuditSitesForDataCollection(this.auditId);
    this.getSamplingConfig({
      auditId: this.auditId,
    });
  }

  public handleEnteredDataRecords(
    newValue: DataCollectionSubmitRequestPayload,
  ) {
    if (newValue.aid === '') {
      newValue.aid = null;
    }
    this.fetchDataCollection(newValue);
  }

  public switchMode(newValue: string) {
    this.renderComponent = false;
    this.dataCollectionType = newValue;
    this.$nextTick(() => {
      this.renderComponent = true;
    });
  }

  get siteList(): AuditSiteListDto[] {
    if (!this.auditSites || !this.auditSites.length) {
      return [];
    }

    return this.auditSites.map((participantSite) => {
      return {
        siteId: participantSite.site.id,
        name: participantSite.site.name,
        auditSiteId: participantSite.id,
        id: participantSite.id,
      };
    });
  }

  get auditCriteriaList(): AuditCriteria[] {
    // returning only criteria which were submitted
    const auditCriteria: AuditCriteria[] = [];
    this.auditCriteria.forEach((audit: AuditCriteria) => {
      this.editDataCollectionRecord.dataCollectionRecords.forEach(
        (editedRecord: any) => {
          if (editedRecord.auditCriteria.id === audit.id) {
            auditCriteria.push(audit);
          }
        },
      );
    });

    return auditCriteria.sort(orderByUtil(['order'], [SortOrder.ASC]));
  }

  public handleSiteDropdownItem(newValue: number) {
    this.defaultSiteId = newValue;
  }

  get auditId(): number {
    return +this.$route.params.auditId;
  }

  get dataCollectionRecordId(): number {
    return +this.$route.params.dataCollectionRecordId;
  }

  public redirectToPrevious() {
    if (this.dataCollectionRecord.previousRecord) {
      this.$router.replace({
        params: {
          dataCollectionRecordId: this.dataCollectionRecord.previousRecord.id.toString(),
        },
      });
      this.fetchData();
    }
  }

  public redirectToNext() {
    if (this.dataCollectionRecord.nextRecord) {
      this.$router.replace({
        params: {
          dataCollectionRecordId: this.dataCollectionRecord.nextRecord.id.toString(),
        },
      });
      this.fetchData();
    }
  }

  public getProjectPath(projectId: number) {
    let projectPath = '/projects/' + projectId;
    if (
      this.auditDetail &&
      this.auditDetail.userRoleInAudit === UserRoleInAudit.isDataCollector
    ) {
      projectPath = '/projects/' + projectId + '/homepage';
      this.isDataCollector = true;
    }
    return projectPath;
  }

  public getAuditPath(auditId: number) {
    let auditPath = '/audits/' + auditId;
    if (
      this.auditDetail &&
      this.auditDetail.userRoleInAudit === UserRoleInAudit.isDataCollector
    ) {
      auditPath += '/homepage';
      this.isDataCollector = true;
    }
    return auditPath;
  }

  get userEmail(): string | undefined {
    return useUserEmail.call(this);
  }
}
