















































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import BaseLoading from '../../../components/base/BaseLoading.vue';
import BasePaginatorHoc from '../../../components/base/BasePaginatorHoc.vue';
import { Debounce } from '../../../jbi-shared/util/debounce.vue-decorator';
import { isDifferentDeep } from '../../../jbi-shared/util/watcher.vue-decorator';
import {
  ProjectCriteria,
  ProjectDetailPayload,
} from '../../../store/modules/projects/types/projects.types';
import {
  ApiState,
  Pagination,
  PaginatorDto,
  SortOrder,
  TableSortDto,
} from '../../../store/types/general.types';
import {
  GripAnalysis,
  GripAnalysisCriteria,
  GripAnalysisParams,
  GripExportReportPayload,
  GripExportReportResponse,
} from '../../../store/types/grip.types';
import ExportGripReportModalAnalysisList from './ExportGripReportModalAnalysisList.vue';
import { generateGripReportPdf } from '../../../components/grip/export/pdf/GripReportPdf';
import { generateGripReportDocx } from '../../../components/grip/export/docx/GripReportDocx';

@Component({
  components: {
    BaseLoading,
    BasePaginatorHoc,
    ExportGripReportModalAnalysisList,
  },
})
export default class ExportGripReportModal extends Vue {
  @Prop(Number) public projectId!: number;
  public isLoading: boolean = true;
  public isDropdownActive: boolean = false;
  public totalCount: number = 0;
  public perPage = 50;
  public page = 1;
  public sortColumn: string = 'title';
  public sortOrder: SortOrder = SortOrder.ASC;
  public searchAnalysisTitle = '';
  public filteredParams: GripAnalysisParams = {
    isExportGrip: true,
    title: this.searchAnalysisTitle,
    limit: this.perPage,
    page: this.page,
    sort: this.sortOrder,
    sortColumn: this.sortColumn,
  };
  public isAllGripAnalysisSelected: boolean = false;
  public selectedGripAnalysisIds: number[] = [];
  public isDomainCategoryRequired: boolean = true;
  public exportOptions: string[] = ['PDF', 'DOCX'];
  public exportType: string = '';
  public isViewSummary: boolean = false;

  @Action('projects/getFilteredGripAnalysisList')
  public getFilteredGripAnalysisList!: (payload: {
    projectId: number;
    params: GripAnalysisParams;
  }) => void;

  @State((state) => state.projects.filteredGripAnalysisList)
  public filteredGripAnalysisList!: Pagination<GripAnalysis>;

  @State((state) => state.projects.apiState.getFilteredGripAnalysisList)
  public getFilteredGripAnalysisListState!: ApiState;

  @Action('projects/getGripExportReport')
  public getGripExportReport!: (payload: GripExportReportPayload) => void;

  @State((state) => state.projects.gripExportReport)
  public gripExportReport!: GripExportReportResponse;

  @State((state) => state.projects.apiState.getGripExportReport)
  public getGripExportReportState!: ApiState;

  @State((state) => state.projects.projectDetail)
  public projectDetail!: ProjectDetailPayload;

  get ExportGripReportModalAnalysisList() {
    return ExportGripReportModalAnalysisList;
  }

  get totalNumberOfPage(): number {
    return Math.ceil(this.totalCount / this.perPage);
  }

  get startItemIndex(): number {
    return this.page * this.perPage - this.perPage + 1;
  }

  get endItemIndex(): number {
    return Math.min(this.totalCount, this.page * this.perPage);
  }

  get isFirstPage(): boolean {
    return this.page === 1;
  }

  get isLastPage(): boolean {
    return this.page === this.totalNumberOfPage;
  }

  get gripAnalysisList(): GripAnalysis[] {
    return this.filteredGripAnalysisList
      ? this.filteredGripAnalysisList.items
      : [];
  }

  get isButtonDisabled(): boolean {
    return !this.exportType.length ? true : false;
  }

  public handleDropdown(): void {
    this.isDropdownActive = !this.isDropdownActive;
  }

  public updateSelectedAnalysis(
    analysisIds: number[],
    isAllSelected: boolean,
  ): void {
    this.selectedGripAnalysisIds = analysisIds;
    this.isAllGripAnalysisSelected = isAllSelected;
  }

  public gripAnalysisCriteria(
    gripAnalysisCriteriaMaps: GripAnalysisCriteria[],
  ): ProjectCriteria[] {
    return gripAnalysisCriteriaMaps.map((criteria) => criteria.criteria);
  }

  public handlePaginator({ perPage, page }: PaginatorDto): void {
    this.page = page;
    this.perPage = perPage;
    this.filteredParams = {
      title: this.searchAnalysisTitle,
      limit: this.perPage,
      page: this.page,
      sort: this.sortOrder,
      sortColumn: this.sortColumn,
      isExportGrip: true,
    };
  }

  public handleSort(data: TableSortDto): void {
    this.sortOrder = data.sortOrder;
    this.sortColumn = data.sortColumn;
    this.filteredParams = {
      title: this.searchAnalysisTitle,
      limit: this.perPage,
      page: this.page,
      sort: this.sortOrder,
      sortColumn: this.sortColumn,
      isExportGrip: true,
    };
  }

  /** Get Filtered Grip Analysis List through API */
  public getFilteredGripAnalysis(): void {
    this.getFilteredGripAnalysisList({
      projectId: this.projectId,
      params: this.filteredParams,
    });
  }

  /** Get Grip Report Data through API */
  public getGripReportData(): void {
    this.getGripExportReport({
      projectId: this.projectId,
      isAllGripAnalysisSelected:
        this.selectedGripAnalysisIds.length > 0
          ? this.isAllGripAnalysisSelected
          : false,
      isDomainCategoryRequired: this.isDomainCategoryRequired,
      gripAnalysisIds: this.selectedGripAnalysisIds,
    });
  }

  public handleViewSummary(): void {
    /** on view summary default export type would be PDF */
    this.exportType = 'PDF';
    this.isViewSummary = true;
    this.getGripReportData();
  }

  public handleExport(): void {
    this.getGripReportData();
  }

  public handleExportToPdf(): void {
    generateGripReportPdf(
      this.isViewSummary,
      this.gripExportReport,
      this.isDomainCategoryRequired,
      this.projectDetail.title,
    );
  }

  public handleExportToDocx(): void {
    generateGripReportDocx(
      this.gripExportReport,
      this.isDomainCategoryRequired,
      this.projectDetail.title,
    );
  }

  public mounted(): void {
    this.getFilteredGripAnalysis();
  }

  @Watch('filteredParams', { deep: true })
  @isDifferentDeep
  public watchFilteredParams(): void {
    this.getFilteredGripAnalysis();
  }

  @Watch('searchAnalysisTitle')
  @Debounce(800)
  public onTitleSearch(): void {
    this.getFilteredGripAnalysis();
  }

  @Watch('getFilteredGripAnalysisListState')
  public watchGetFilteredGripAnalysisListState(state: ApiState): void {
    if (state.loading) {
      this.isLoading = true;
    } else {
      this.isLoading = false;
      if (state.success) {
        this.totalCount = this.filteredGripAnalysisList.totalItems;
        this.selectedGripAnalysisIds = this.isAllGripAnalysisSelected
          ? this.filteredGripAnalysisList.items.map((item) => item.id)
          : this.selectedGripAnalysisIds;
      }
    }
  }

  @Watch('getGripExportReportState')
  public watchGetGripExportReportState(state: ApiState): void {
    if (state.loading) {
      this.isLoading = true;
    } else if (state.success) {
      switch (this.exportType) {
        case 'PDF':
          this.handleExportToPdf();
          break;
        case 'DOCX':
          this.handleExportToDocx();
          break;
        default:
          break;
      }
      if (!this.isViewSummary) {
        this.$emit('close');
      }
      this.isLoading = false;
      this.isViewSummary = false;
    } else {
      this.$emit('close');
      this.isLoading = false;
      this.isViewSummary = false;
    }
  }
}
