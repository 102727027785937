



















































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  CplusCriteria,
  JBICriteriaStatus,
} from '../../../store/modules/projects/types/projects.types';
import { CRITERION_TYPE } from '../../../store/types/criterions.types';

@Component({})
export default class JbiCriteriaUpdateModal extends Vue {
  @Prop()
  public criteria!: CplusCriteria;

  public criteriaDetails!: CplusCriteria;

  get criteriaType(): typeof CRITERION_TYPE {
    return CRITERION_TYPE;
  }

  get jbiCriteriaStatus(): typeof JBICriteriaStatus {
    return JBICriteriaStatus;
  }

  public closeModal(): void {
    this.$emit('close');
  }
}
