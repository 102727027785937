










































import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import { cloneDeep as _cloneDeep } from 'lodash';
import {State} from 'vuex-class';
import {AuditDetail} from '@/store/modules/audits/types/audits.types';
import dayjs from 'dayjs';
const dirtyMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

@Component({ components : {} })

export default class AnalysisPeriodPicker extends Vue {
    @Prop()
    public selectedPeriod!: Date[];
    @Prop({default: false})
    public isDisabled!: boolean;

    @State(state => state.audits.auditDetail)
    public auditDetail!: AuditDetail;

    public dirtySelectedPeriod: Date[] = [];
    public startDate: Date = new Date();
    public endDate: Date = this.setTimeEnd(new Date());
    public minDate: Date = new Date();
    public auditDataCollectionStartDate: Date = new Date();

    public mounted() {
        this.auditDataCollectionStartDate = new Date(this.auditDetail.startedAt);
        const transformedSelectedPeriod: Date[] = [];
        this.selectedPeriod.forEach((period) => {
            transformedSelectedPeriod.push(new Date(period));
        });
        this.dirtySelectedPeriod = _cloneDeep(transformedSelectedPeriod);
    }

    public updatePeriod(newValue: Date[]) {
        this.$emit('applyAnalysisPeriod', [
            new Date(dayjs(newValue[0]).startOf('day').format()),
            new Date(dayjs(newValue[1]).endOf('day').format()),
        ]);
    }

    @Watch('auditDetail')
    public watchAuditDetail(newValue: AuditDetail) {
        this.auditDataCollectionStartDate = new Date(this.auditDetail.startedAt);
    }

    public getDate(newValue: Date) {
        return newValue.getDate() + ' ' + dirtyMonths[newValue.getMonth()] + ' ' + newValue.getFullYear();
    }

    public formatDateRange(date: Date[]) {
        return this.getDate(date[0]) + ' - ' + this.getDate(date[1]);
    }

    public selectWholeAuditPeriod() {
        this.startDate = this.getAuditDataCollectionStartDate;
        this.dirtySelectedPeriod = [this.startDate, this.endDate];
        this.updatePeriod(this.dirtySelectedPeriod);
    }

    public selectPastWeek() {
        this.startDate = new Date(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate() - 7);
        this.dirtySelectedPeriod = [this.startDate, this.endDate];
        this.updatePeriod(this.dirtySelectedPeriod);
    }

    public selectPastMonth() {
        this.startDate = new Date(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate() - 30);
        this.dirtySelectedPeriod = [this.startDate, this.endDate];
        this.updatePeriod(this.dirtySelectedPeriod);
    }

    public setTimeEnd(date: Date): Date {
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
        return date;
    }

    public setTimeStart(date: Date): Date {
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        return date;
    }

    get getAuditDataCollectionStartDate(): Date {
        return this.setTimeStart(new Date(this.auditDataCollectionStartDate));
    }
}
