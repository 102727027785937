















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { State, Action } from 'vuex-class';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import VerticalBarChart from '@/components/reports/VerticalBarChart';
import BaseFilterDropdown from '@/components/base/BaseFilterDropdown.vue';
import {
  DashboardChartData,
  DashboardChartDataRequestPayload,
} from '@/store/modules/projects/types/projects.types';
import { RootState } from '@/store/store';
const dirtyMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

@Component({
  components: {
    BaseFilterDropdown,
    VerticalBarChart,
  },
})
export default class ProjectDashboardChart extends Vue {
  @Prop()
  public auditList!: any[];
  @Action('projects/getDashboardChartData')
  public getDashboardChartData!: (
    payload: DashboardChartDataRequestPayload,
  ) => void;

  @State((state: RootState) => state.projects.dashboardChartData)
  public dashboardChartData!: DashboardChartData[];

  public today = new Date();
  public maxDate = new Date();
  public selectedAuditIds: number[] = this.auditList.map((audit) => audit.id);
  public todayDate: Date = new Date();
  public selectedPeriod: Date[] = [
    new Date(
      this.todayDate.getFullYear(),
      this.todayDate.getMonth(),
      this.todayDate.getDate() - 7,
    ),
    this.todayDate,
  ];
  public barChartKey: number = Math.floor(Math.random() * 999);

  public chartData = {
    labels: [],
    datasets: [
      {
        label: 'Total Data Collected',
        data: [],
        backgroundColor: Array(0).fill('#1D445E'),
        barThickness: 60,
      },
    ],
  };

  get projectId(): number {
    return +this.$route.params.projectId;
  }

  public options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
          gridLines: {
            display: true,
          },
          scaleLabel: {
            labelString: 'New Responses',
            display: true,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          scaleLabel: {
            labelString: 'Date',
            display: true,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  public mounted() {
    this.filterChartData();
  }

  public filterPeriod(newValue: Date[]) {
    this.selectedPeriod = newValue;
    this.filterChartData();
  }

  public filterChartData() {
    const payload: DashboardChartDataRequestPayload = {
      projectId: this.projectId,
      fromDate: this.setTimeStart(this.selectedPeriod[0]),
      toDate: this.setTimeEnd(this.selectedPeriod[1]),
      auditIds: this.selectedAuditIds,
    };

    this.getDashboardChartData(payload);
  }

  public setTimeEnd(date: Date) {
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    return date;
  }

  public setTimeStart(date: Date) {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    return date;
  }

  public formatDateRange(date: Date[]) {
    return this.getDate(date[0]) + ' - ' + this.getDate(date[1]);
  }

  public getDate(newValue: Date) {
    return (
      newValue.getDate() +
      ' ' +
      dirtyMonths[newValue.getMonth()] +
      ' ' +
      newValue.getFullYear()
    );
  }

  public accumulateNewResponseByDate(
    data: DashboardChartData[],
  ): DashboardChartData[] {
    const accumulatedData: DashboardChartData[] = [];
    data.reduce((prev, curr) => {
      const pureDate: string = new Date(curr.createdDate).toLocaleDateString();
      if (!prev[pureDate]) {
        prev[pureDate] = { pureDate, sum: 0, createdDate: curr.createdDate };
        accumulatedData.push(prev[pureDate]);
      }
      prev[pureDate].sum += +curr.sum;
      return prev;
    }, {} as any);
    return accumulatedData;
  }

  @Watch('dashboardChartData')
  @isTruthy
  public watchDashboardChartData(newValue: DashboardChartData[]) {
    const lables: any = [];
    const data: any = [];

    this.accumulateNewResponseByDate(newValue).forEach(
      (chartData: DashboardChartData) => {
        lables.push(this.getDate(new Date(chartData.createdDate)));
        data.push(chartData.sum);
      },
    );
    this.chartData = {
      labels: lables,
      datasets: [
        {
          label: 'Total Data Collected',
          data,
          backgroundColor: Array(data.length).fill('#1D445E'),
          barThickness: 60,
        },
      ],
    };
    this.barChartKey += 1;
  }

  public applyAuditFilter(newValue: number[]) {
    this.selectedAuditIds = newValue;
    this.filterChartData();
  }
}
