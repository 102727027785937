
























































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { cloneDeep as _cloneDeep, map as _map, get as _get } from 'lodash';
import { Action, State } from 'vuex-class';
import ExportDropdown from '@/views/Audit/components/ExportDropdown.vue';
import AuditReportFilters from '@/views/Audit/components/AuditReportFilters.vue';
import { auditsApi } from '@/api/audits.api';
import SingleSiteReport from '@/views/Audit/components/SingleSiteReport.vue';
import SingleSiteComparisonReport from '@/views/Audit/components/SingleSiteComparisonReport.vue';
import MultiSiteReport from '@/views/Audit/components/MultiSiteReport.vue';
import MultiSiteComparisonReport from '@/views/Audit/components/MultiSiteComparisonReport.vue';
import Card from '@/components/Card.vue';
import RenameAnalysisModal from '@/components/reports/RenameAnalysisModal.vue';
import DeleteAnalysisModal from '@/components/reports/DeleteAnalysisModal.vue';
import {
  AuditSite,
  AuditCriteria,
  ChartType,
  AuditReportFiltersConfig,
  AuditReportAnalysisPayload,
  AuditReportSinglePeriodResponse,
  AuditReportMultiplePeriodResponse,
  AuditReportAnalysis,
  UpdateAnalysisTitlePayload,
  DeleteAnalysisPayload,
} from '@/store/modules/audits/types/audits.types';
import { RootState } from '../../../store/store';
import { ApiState } from '../../../store/types/general.types';
import { isTruthy } from '../../../jbi-shared/util/watcher.vue-decorator';

@Component({
  components: {
    Card,
    ExportDropdown,
    AuditReportFilters,
    SingleSiteReport,
    MultiSiteReport,
    SingleSiteComparisonReport,
    MultiSiteComparisonReport,
    RenameAnalysisModal,
    DeleteAnalysisModal,
  },
})
export default class IndividualAnalysis extends Vue {
  @Prop()
  public auditId!: number;
  @Prop()
  public projectId!: number;
  @Prop()
  public auditSites!: AuditSite[];
  @Prop()
  public auditCriteria!: AuditCriteria[];
  @Prop()
  public analysisDetail!: AuditReportAnalysis;

  public endDate: Date = this.setTimeEnd(new Date());
  public startDate: Date = new Date(
    this.endDate.getFullYear(),
    this.endDate.getMonth(),
    this.endDate.getDate() - 7,
  );
  public isAnalysisOpen: boolean = true;
  public analysisFilter: AuditReportFiltersConfig = {
    filteredSites: [],
    filteredCriterias: [],
    selectedChartType: '',
    checkIndividualSite: false,
    checkIndividualCriteria: false,
    selectedPeriod: [],
    checkComparison: false,
    comparisonPeriods: [],
  };
  public reportResponse: AuditReportSinglePeriodResponse = {
    chartData: [],
    statisticData: {
      configurationData: null,
      samplingConfiguration: null,
    },
    success: false,
    analysisId: 0,
  };
  public comparisonReportResponse: AuditReportMultiplePeriodResponse = {
    comparisonResult: [],
    success: false,
    analysisId: 0,
  };
  public showSingleSiteReport: boolean = false;
  public showMultiSiteReport: boolean = false;
  public showSingleSiteComparisonReport: boolean = false;
  public showMultiSiteComparisonReport: boolean = false;
  public isLoading: boolean = true;
  public validateSiteCriteriaFilter: boolean = false;
  public showAuditReportFilters: boolean = false;
  public dirtyAnalysisDetails: any = {};

  @Action('audits/getAuditReportAnalysis')
  public getAuditReportAnalysis!: (payload: AuditReportAnalysisPayload) => void;

  @State((state: RootState) => state.audits.auditReportAnalysis)
  public auditReportAnalysis!: any;

  @State((state: RootState) => state.audits.apiState.getAuditReportAnalysis)
  public getAuditReportAnalysisStatus!: ApiState;

  @Action('audits/updateAuditReportAnalysisTitleById')
  public updateAuditReportAnalysisTitleById!: (
    payload: UpdateAnalysisTitlePayload,
  ) => void;

  @State((state: RootState) => state.audits.updatedAuditReportAnalysis)
  public updatedAuditReportAnalysis!: any;

  @State((state: RootState) => state.audits.apiState.updateAuditReportAnalysis)
  public updateAuditReportAnalysisState!: ApiState;

  @Action('audits/deleteAuditReportAnalysisById')
  public deleteAuditReportAnalysisById!: (
    payload: DeleteAnalysisPayload,
  ) => void;

  @State((state: RootState) => state.audits.apiState.deleteAuditReportAnalysis)
  public deleteAuditReportAnalysisState!: ApiState;

  public mounted() {
    this.dirtyAnalysisDetails = _cloneDeep(this.analysisDetail);
    this.analysisFilter = _cloneDeep(
      this.dirtyAnalysisDetails.filtersConfiguration,
    );
    this.showAuditReportFilters = true;
    const payload: AuditReportAnalysisPayload = {
      ...this.analysisFilter,
      auditId: this.getAuditId,
      projectId: this.getProjectId,
      analysisId: this.getAnalysisId,
      analysisTitle: this.getAnalysisTitle,
      type: this.auditSitelist.length > 1 ? 'multiSite' : 'singleSite',
    };
    if (this.$route.hash === '#report') {
      this.getAnalysisData(payload);
    }
  }

  public applyAnalysisFilters(newValue: AuditReportFiltersConfig) {
    this.analysisFilter = _cloneDeep(newValue);
    this.$emit('updateAnalysisFilter', this.analysisFilter);
    if (
      this.analysisFilter.filteredSites.length === 0 ||
      this.analysisFilter.filteredCriterias.length === 0
    ) {
      this.showSingleSiteReport = false;
      this.showSingleSiteComparisonReport = false;
      this.showMultiSiteReport = false;
      this.showMultiSiteComparisonReport = false;
      this.validateSiteCriteriaFilter = true;
    } else {
      const payload: AuditReportAnalysisPayload = {
        ...this.analysisFilter,
        auditId: this.getAuditId,
        projectId: this.getProjectId,
        analysisId: this.getAnalysisId,
        analysisTitle: this.getAnalysisTitle,
        type: this.auditSitelist.length > 1 ? 'multiSite' : 'singleSite',
      };
      if (this.auditSitelist.length === 1) {
        this.showSingleSiteReport = false;
        this.showSingleSiteComparisonReport = false;
      } else {
        this.showMultiSiteReport = false;
        this.showMultiSiteComparisonReport = false;
      }
      this.isLoading = true;
      this.validateSiteCriteriaFilter = false;
      this.getAnalysisData(payload);
    }
  }

  public setTimeEnd(date: Date): Date {
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    return date;
  }

  public getAnalysisData(payload: AuditReportAnalysisPayload) {
    this.getAuditReportAnalysis(payload);
  }

  @Watch('getAuditReportAnalysisStatus.success')
  @isTruthy
  public watchGetAuditReportAnalysisStatus() {
    if (this.analysisFilter.checkComparison) {
      this.comparisonReportResponse = JSON.parse(
        JSON.stringify(this.auditReportAnalysis),
      );
    } else {
      this.reportResponse = JSON.parse(
        JSON.stringify(this.auditReportAnalysis),
      );
    }
    if (
      this.dirtyAnalysisDetails.analysisId !==
      JSON.parse(JSON.stringify(this.auditReportAnalysis)).analysisId
    ) {
      this.dirtyAnalysisDetails.analysisId = JSON.parse(
        JSON.stringify(this.auditReportAnalysis),
      ).analysisId;
      this.$emit('updateAnalysisId', this.dirtyAnalysisDetails.analysisId);
    }
  }

  @Watch('updateAuditReportAnalysisState.success')
  @isTruthy
  public watchUpdateAuditReportAnalysisState() {
    this.$emit('updateAnalysisTitle', this.updatedAuditReportAnalysis.title);
  }

  @Watch('deleteAuditReportAnalysisState.success')
  @isTruthy
  public watchDeleteAuditReportAnalysisState() {
    this.$emit('deleteAnalysis');
  }

  @Watch('reportResponse')
  public watchResponse(newValue: AuditReportSinglePeriodResponse) {
    if (this.auditSitelist.length === 1) {
      if (newValue.success) {
        this.showSingleSiteReport = true;
        this.isLoading = false;
      }
    } else {
      if (newValue.success) {
        this.showMultiSiteReport = true;
        this.isLoading = false;
      }
    }
  }

  @Watch('comparisonReportResponse')
  public watchComparisonResponse(newValue: AuditReportMultiplePeriodResponse) {
    if (this.auditSitelist.length === 1) {
      if (newValue.success) {
        this.showSingleSiteComparisonReport = true;
        this.isLoading = false;
      }
    } else {
      if (newValue.success) {
        this.showMultiSiteComparisonReport = true;
        this.isLoading = false;
      }
    }
  }

  public renameAnalysis() {
    this.$buefy.modal.open({
      parent: this,
      component: RenameAnalysisModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        currentAnalysisTitle: this.dirtyAnalysisDetails.title,
      },
      events: {
        updateAnalysisTitle: (newAnalysisTitle: string) => {
          this.updateAnalysisTitleById(newAnalysisTitle);
        },
      },
    });
  }

  public updateAnalysisTitleById(newAnalysisTitle: string) {
    const payload: UpdateAnalysisTitlePayload = {
      auditId: this.auditId,
      analysisId: this.dirtyAnalysisDetails.analysisId,
      analysisTitle: newAnalysisTitle,
    };
    this.updateAuditReportAnalysisTitleById(payload);
  }

  public deleteAnalysis() {
    this.$buefy.modal.open({
      parent: this,
      component: DeleteAnalysisModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        analysisTitle: this.dirtyAnalysisDetails.title,
      },
      events: {
        deleteAnalysis: () => {
          this.deleteAnalysisById();
        },
      },
    });
  }

  public deleteAnalysisById() {
    const payload: DeleteAnalysisPayload = {
      auditId: this.auditId,
      analysisId: this.dirtyAnalysisDetails.analysisId,
    };
    this.deleteAuditReportAnalysisById(payload);
  }

  get getAllSiteIds(): number[] {
    const siteIdList: number[] = [];
    _map(this.auditSites, (auditSite: AuditSite) => {
      siteIdList.push(auditSite.id);
    });
    return siteIdList;
  }

  get getAllCriteriaIds(): number[] {
    const criteriaIdList: number[] = [];
    _map(this.auditCriteria, (auditCriteria: AuditCriteria) => {
      criteriaIdList.push(auditCriteria.id);
    });
    return criteriaIdList;
  }

  get getAnalysisId(): number {
    return this.dirtyAnalysisDetails.analysisId;
  }

  get getAnalysisTitle(): string {
    return this.dirtyAnalysisDetails.title;
  }

  get getAuditId(): number {
    return this.auditId;
  }

  get getProjectId(): number {
    return this.projectId;
  }

  get auditSitelist(): AuditSite[] {
    return this.auditSites;
  }

  get auditCriteriaList(): AuditCriteria[] {
    return this.auditCriteria;
  }
}
