
































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {Action} from 'vuex-class';
import { cloneDeep as _cloneDeep } from 'lodash';

@Component({ components: {}})

export default class DeleteRecordModal extends Vue {
    public removeRecord() {
        this.$emit('deleteRecord');
        this.$emit('close');
    }
    public closeModal() {
        this.$emit('close');
    }
}
