import Vue from 'vue';
import Component from 'vue-class-component';
import { useAction, useApiState, useAuthState } from './store.util';
import { Watch } from 'vue-property-decorator';
import {
  isDifferent,
  isTruthy,
} from '../jbi-shared/util/watcher.vue-decorator';
import { AxiosError } from 'axios';
import { get as _get, includes as _includes } from 'lodash';
import { DialogProgrammatic as Dialog } from 'buefy';
import { Throttle } from '@/jbi-shared/util/throttle.vue-decorator';
import {
  AuthInfo,
  OicPayload,
  SubscriptionInfo,
  SubscriptionStatus,
} from '@/store/modules/auth/types/auth.types';
import moment from 'moment';
import { JAAS_LOGIN_URL } from '@/utils/login.util';
import { State } from 'vuex-class';
import { RootState } from '@/store/store';
import { NotificationProgrammatic as Notification } from 'buefy';
// import handleNotificationWS  from './notification.util';

@Component({})
export class SessionHandlerMixin extends Vue {
  public showingSessionDialog = false;
  public showEmailUpdatedNotification = false;

  get isSwitchAccount() {
    return useAuthState.call(this).isSwitchAccount;
  }

  get signOutSuccess() {
    return useApiState.call(this, 'auth/signOut')!.success;
  }

  get getMyRoles() {
    return useAction.call(this, 'users/getMyRoles');
  }

  get getSurveyData() {
    return useAction.call(this, 'users/getSurveyData');
  }

  get checkSessionHealth() {
    return useAction.call(this, 'auth/checkSessionHealth');
  }

  get checkSubscriptionHealth() {
    return useAction.call(this, 'auth/checkSubscriptionHealth');
  }

  get authInfo() {
    return useAuthState.call(this).authInfo;
  }

  get subscriptionInfo() {
    return useAuthState.call(this).subscriptionInfo;
  }

  get getUserOicPayload() {
    return useAction.call(this, 'users/getUserOICPayload');
  }

  @State((state: RootState) => state.users.oicPayload)
  public oicPayload!: OicPayload;

  get shouldCheckSession() {
    const excludedPath = [
      'homepage',
      'oauth2callback',
      'project-new-user-invite',
      'audit-invitation',
      'accept-project-invitation',
      'noSubscription',
      'extendSubscription',
      'project-owner-request-invitation',
    ];
    return !_includes(excludedPath, this.$route.name);
  }

  @Watch('signOutSuccess')
  @isDifferent
  @isTruthy
  public async watchSignoutSuccess(value: boolean) {
    if (!this.isSwitchAccount) {
      return this.$router.push('/');
    } else {
      return this.$router.go(0);
    }
  }

  @Watch('subscriptionInfo', { immediate: true })
  @isDifferent
  @isTruthy
  public onSubscriptionChange(newValue: SubscriptionInfo) {
    if (
      newValue.subscriptionStatus !== SubscriptionStatus.VALID &&
      newValue.isParticipant === false
    ) {
      if (
        newValue.expireAt !== null &&
        moment(newValue.expireAt).isBefore(new Date())
      ) {
        this.$router.replace('/extend-subscription');
      } else {
        this.$router.replace('/no-subscription');
      }
    }
  }

  @Watch('$route', { immediate: true })
  @isDifferent
  @isTruthy
  public onRouteChange() {
    if (this.shouldCheckSession) {
      this.checkSessionHealth();
      this.checkSubscriptionHealth();
    }
    if (this.$route.name === 'noSubscription') {
      this.checkSubscriptionHealth();
    }
  }

  /**
   * This watcher will listen only on the parent route changes
   */
  @Watch('$route.name', { immediate: true })
  @isDifferent
  @isTruthy
  public onParentRouteChange() {
    if (this.shouldCheckSession) {
      this.getUserOicPayload();
    }
  }

  public created() {
    this.setupApiResponseHandler();
  }

  public setupApiResponseHandler() {
    const store = this.$store;
    store.subscribe((mutation) => {
      const { type } = mutation;
      if (type.endsWith('_ERROR')) {
        const error: AxiosError = mutation.payload;
        const status = _get(error, 'response.status') as number;
        const isUnauthorized = status === 401;
        const hasNoSession =
          status === 400 && type.includes('CHECK_SESSION_HEALTH_ERROR');

        if (hasNoSession) {
          // this.loginAlertAndRedirect(
          //   'You are not logged in. You cannot view this URL as a guest.',
          // );
          window.location.href = JAAS_LOGIN_URL;
        } else if (isUnauthorized) {
          // this.loginAlertAndRedirect(
          //   'Your session has expired. Please login again.',
          // );
          window.location.href = JAAS_LOGIN_URL;
        }
      }
    });
  }

  public loginAlertCb() {
    this.showingSessionDialog = false;
    this.$router.push('/');
  }

  public loginAlertAndRedirect(alertMsg: string) {
    if (this.showingSessionDialog) {
      return;
    }
    this.showingSessionDialog = true;
    Dialog.alert({
      message: alertMsg,
      onConfirm: this.loginAlertCb,
      onCancel: this.loginAlertCb,
    });
  }

  @Watch('isLoggedIn', { immediate: true })
  @Watch('authInfo', { immediate: true })
  @isTruthy
  @Throttle(100)
  public onLoggedIn() {
    this.getSurveyData();
    return this.getMyRoles();
  }

  @Watch('oicPayload', { immediate: true })
  public checkIfEmailIsSynced(oicPayload: OicPayload) {
    const authInfo: AuthInfo | null = this.authInfo;

    if (
      !this.showEmailUpdatedNotification &&
      authInfo &&
      authInfo.oicPayload &&
      authInfo.oicPayload.email &&
      oicPayload &&
      authInfo.oicPayload.email !== oicPayload.email
    ) {
      this.showEmailUpdatedNotification = true;
      Notification.open({
        type: 'is-warning',
        position: 'is-top',
        message: `Email has been updated, kindly logout and login again to view the update.`,
        indefinite: true,
      }).$on('close', () => {
        this.showEmailUpdatedNotification = false;
      });
    }
  }
}
