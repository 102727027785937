



























import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { GripDomainCategory } from '../../../store/types/grip.types';
import DeleteDomainCategoryForm from './DeleteDomainCategoryForm.vue';
import DomainCategoryModal from './DomainCategoryModal.vue';

@Component({
  components: { DomainCategoryModal, DeleteDomainCategoryForm },
})
export default class DomainCategoryList extends Vue {
  @Prop() public gripDomainCategories!: GripDomainCategory[];

  @Action('projects/getGripDomainCategories')
  public getGripDomainCategories!: (projectId: number) => void;

  get projectId(): number {
    return +this.$route.params.projectId;
  }

  public handleEdit(gripDomainCategory: GripDomainCategory): void {
    this.$buefy.modal.open({
      parent: this,
      props: {
        projectId: this.projectId,
        gripDomainCategoryDetails: gripDomainCategory,
        modalTitle: 'Edit',
      },
      component: DomainCategoryModal,
      hasModalCard: true,
    });
  }

  public handleDelete(gripDomainCategoryId: number): void {
    this.$buefy.modal.open({
      parent: this,
      props: {
        projectId: this.projectId,
        gripDomainCategoryId,
        gripDomainCategories: this.gripDomainCategories,
      },
      events: {
        close: () => {
          this.$emit('close');
        },
      },
      component: DeleteDomainCategoryForm,
      hasModalCard: true,
    });
  }
}
