



























































































































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { get as _get, cloneDeep as _cloneDeep } from 'lodash';
import { State, Action } from 'vuex-class';
import { RootState } from '../../../store/store';
import {
  isDifferent,
  isTruthy,
  isDifferentDeep,
} from '../../../jbi-shared/util/watcher.vue-decorator';
import { ToastProgrammatic as Toast } from 'buefy';
import { useUserEmail } from '../../../utils/user.util';
import { DATE_FORMAT } from '../../../utils/date.util';
import dayjs from 'dayjs';
import { UpdateProjectOwnerRequestPayload } from '../../../store/modules/projects/types/projects.types';
import {
  UpdateViewedSingleNotificationPayload,
  UpdateNewNotificationPayload,
} from '../../../store/modules/notification/types/notification.types';
import ProjectOwnerRejectForm from './ProjectOwnerRejectForm.vue';

@Component({
  components: {
    ProjectOwnerRejectForm,
  },
})
export default class ProjectOwnerRequestNotification extends Vue {
  @Prop() public notificationInfo!: any;
  @Prop() public notificationIds!: number[];

  public isProjectOwner: boolean = false;
  public projectUserEmail: any = [];
  public projectTitle: string = '';
  public requestStatus: boolean = false;
  public isReclaimProject: boolean = false;

  @Action('projects/updateProjectOwnerRequest')
  public updateProjectOwnerRequest!: (
    payload: UpdateProjectOwnerRequestPayload,
  ) => void;

  @State((state: RootState) => state.projects.projectOwnerRequestPayload)
  public userNotificationAction!: any;

  @Action('notifications/updateViewedNotification')
  public updateViewedNotification!: (
    payload: UpdateNewNotificationPayload,
  ) => void;

  @Action('notifications/updateViewedSingleNotification')
  public updateViewedSingleNotification!: (
    payload: UpdateViewedSingleNotificationPayload,
  ) => void;

  @State((state: RootState) => state.notifications.userViewedNotifications)
  public userViewedNotifications!: any;

  @State((state: RootState) => state.notifications.userViewedSingleNotification)
  public userViewedSingleNotification!: any;

  public notificationDetail: any = [];

  public mounted() {
    this.notificationDetail = _cloneDeep(this.notificationInfo);
  }

  get userEmail(): string | undefined {
    return useUserEmail.call(this);
  }

  get nonViewedNotification() {
    const notificationDetail = this.notificationDetail.filter(
      (data: any) => !data.isViewed,
    );
    if (Array.isArray(notificationDetail)) {
      return notificationDetail.slice(0, 3).map((item) => {
        if (!item.isViewed) {
          const today = dayjs().format(DATE_FORMAT);
          const yesterday = dayjs().add(-1, 'day').format(DATE_FORMAT);
          const dataUpdatedAt = dayjs(new Date(item.createdAt)).format(
            DATE_FORMAT,
          );

          let dateToDisplay;
          if (dataUpdatedAt === today) {
            dateToDisplay = 'Today';
          } else if (dataUpdatedAt === yesterday) {
            dateToDisplay = 'Yesterday';
          } else {
            dateToDisplay = dataUpdatedAt;
          }

          this.isProjectOwner =
            this.userEmail === item.projectOwnerEmail ? true : false;
          return {
            ...item,
            isProjectOwner: this.isProjectOwner,
            datetime: dateToDisplay,
          };
        }
      });
    }
    return [];
  }

  public handleNotificationRespond(
    info: any,
    isRequestAccepted: boolean,
    isReclaimProject: boolean,
  ) {
    if (!isRequestAccepted) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          notificationSenderName: info.notificationSenderName,
          projectName: info.projectName,
          isReclaimProject,
        },
        events: {
          confirm: async (newValue: any) => {
            this.updateNotificationRespond(
              info,
              false,
              newValue,
              isReclaimProject,
            );
          },
        },
        component: ProjectOwnerRejectForm,
        hasModalCard: true,
      });
    } else {
      this.updateNotificationRespond(info, true, null, isReclaimProject);
    }
  }

  public updateNotificationRespond(
    info: any,
    notificationStatus: boolean,
    notificationReason: string | null,
    isReclaimProject: boolean,
  ) {
    this.projectTitle = info.projectName;
    this.requestStatus = notificationStatus;
    this.isReclaimProject = isReclaimProject;
    const userRespondInfo = {
      token: info.token,
      email: info.email,
      originatorProjectEmail: info.originatorProjectEmail,
      projectId: info.projectId,
      isRequestAccepted: notificationStatus,
      reason: notificationReason,
      isReclaimProject,
    };
    this.updateProjectOwnerRequest(userRespondInfo);
  }

  @Watch('userNotificationAction')
  @isTruthy
  public watchUserNotificationAction(newValue: any) {
    let toastMessage = '';
    if (this.$route.path !== '/notification') {
      if (this.requestStatus) {
        if (this.isReclaimProject) {
          toastMessage = `You have accepted the project administration change request`;
        } else {
          toastMessage = `You are now the project administrator in ‘${this.projectTitle}'.`;
        }
      } else {
        toastMessage = 'Project administration request successfully declined.';
      }
      Toast.open({
        queue: true,
        type: 'is-dark',
        position: 'is-top',
        message: toastMessage,
      });
    }
    this.$emit('updateNotificationBell');
  }

  public handleNavigateNotifications() {
    this.updateViewedNotification({
      notificationIds: this.notificationIds,
    });
  }

  public handleNavigateSingleNotification(info: any) {
    this.updateViewedSingleNotification({
      notificationId: info.id,
    });
  }

  public getParticipantRole(item: any) {
    if (item === 'None') {
      return 'you will no longer be a participant in this project.';
    }
    return `your new role in this project will be ${item}.`;
  }

  public navigateToNotifications() {
    this.$router.replace('/notification');
  }

  @Watch('userViewedNotifications')
  @isTruthy
  public watchUserViewedNotification(newValue: any) {
    if (this.$route.path !== '/notification') {
      this.$router.replace('/notification');
    }
  }

  @Watch('userViewedSingleNotification')
  @isTruthy
  public watchUserViewedSingleNotification(newValue: any) {
    if (this.$route.path !== '/notification') {
      this.$router.replace(`/projects/${newValue.project.id}#dashboard`);
    }
  }
}
