


















































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import {
  AuditDetail,
  AuditCriteria,
  AuditSite,
  AuditSamplingRequestPayload,
  AuditSamplingResponse,
  DataCollectionSubmitRequestPayload,
  DataCollectionSummaryRequestPayload,
  DataCollectionSummaryResponsePayload,
  AuditSiteListDto,
} from '@/store/modules/audits/types/audits.types';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { ToastProgrammatic as Toast } from 'buefy';
import DataCollectionAggregate from '@/views/Audit/components/DataCollectionAggregate.vue';
import DataCollectionIndividual from '@/views/Audit/components/DataCollectionIndividual.vue';
import { cloneData } from '@/utils/general.util';

@Component({
  components: {
    DataCollectionAggregate,
    DataCollectionIndividual,
  },
})
export default class DataCollectorSheet extends Vue {
  @Prop()
  public auditDetail!: AuditDetail;
  @Prop()
  public auditId!: number;

  public dataCollectionType: string = 'aggregate';
  public dirtyDataCollectionSummary: any = {};
  public renderComponent: boolean = false;
  public defaultSiteId: number | null = null;
  public isMultipleSubmission: boolean = false;

  @Action('audits/getDataCollectionSummary')
  public getDataCollectionSummary!: (
    payload: DataCollectionSummaryRequestPayload,
  ) => void;

  @State((state) => state.audits.dataCollectionSummary)
  public dataCollectionSummary!: DataCollectionSummaryResponsePayload;

  @Action('audits/getAuditCriteria')
  public getAuditCriteria!: (auditId: number) => void;

  @State((state) => state.audits.auditCriteria)
  public auditCriteria!: AuditCriteria[];

  @Action('audits/getAuditSitesForDataCollection')
  public getAuditSitesForDataCollection!: (auditId: number) => void;

  @State((state) => state.audits.auditSites)
  public auditSites!: AuditSite[];

  @Action('audits/getSamplingConfig')
  public getSamplingConfig!: (payload: AuditSamplingRequestPayload) => void;

  @State((state) => state.audits.auditConfig)
  public auditConfig!: AuditSamplingResponse;

  @Action('audits/postDataCollection')
  public fetchDataCollection!: (
    payload: DataCollectionSubmitRequestPayload,
  ) => void;

  @State((state) => state.audits.dataCollectionResponse)
  public dataCollectionResponse!: any;

  @Watch('auditSites')
  @isTruthy
  public watchAuditSites(newValue: AuditSite[]) {
    this.defaultSiteId = newValue[0].id;
  }

  @Watch('auditConfig')
  @isTruthy
  public watchAuditConfig(newValue: AuditSamplingResponse) {
    if (this.dataCollectionSummary) {
      this.renderComponent = true;
    }
  }

  @Watch('dataCollectionSummary')
  @isTruthy
  public watchDataCollectionSummary(
    newValue: DataCollectionSummaryResponsePayload,
  ) {
    this.dirtyDataCollectionSummary = cloneData(newValue);
    if (this.auditConfig) {
      this.renderComponent = true;
    }
  }

  @Watch('dataCollectionResponse')
  @isTruthy
  public watchDataCollectionResponse(newValue: any) {
    Toast.open({
      message: `Data Successfully Submitted.`,
      position: 'is-top',
      type: 'is-dark',
      duration: 3500,
    });
    if (!this.isMultipleSubmission) {
      this.$emit('refreshDataCollectionSheet');
      this.$router.go(-1);
    }
  }

  public mounted() {
    this.getAuditCriteria(this.auditId);
    this.getAuditSitesForDataCollection(this.auditId);
    this.getDataCollectionSummary({
      auditId: this.auditId,
    });
    this.getSamplingConfig({
      auditId: this.auditId,
    });
  }

  public handleSiteDropdownItem(newValue: number) {
    this.defaultSiteId = newValue;
  }

  public handleEnteredDataRecords(
    newValue: DataCollectionSubmitRequestPayload,
  ) {
    if (newValue.aid === '') {
      newValue.aid = null;
    }
    this.fetchDataCollection(newValue);
  }

  public switchMode(newValue: string) {
    this.renderComponent = false;
    this.dataCollectionType = newValue;
    this.$nextTick(() => {
      this.renderComponent = true;
    });
  }

  public compareSites(participantSites: any[]) {
    return (current: any) => {
      return (
        participantSites.filter((participantSite: any) => {
          return participantSite.site.id === current.site.id;
        }).length === 0
      );
    };
  }

  get siteList(): AuditSiteListDto[] {
    if (!this.auditSites || !this.auditSites.length) {
      return [];
    }

    return this.auditSites.map((participantSite) => {
      return {
        siteId: participantSite.site.id,
        name: participantSite.site.name,
        auditSiteId: participantSite.id,
        id: participantSite.id,
      };
    });
  }

  get auditCriteriaList(): AuditCriteria[] {
    return this.auditCriteria ? this.auditCriteria : [];
  }
}
