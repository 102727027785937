var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:_vm.$style.infoInputContainer},[(_vm.resultData.auditSiteId)?_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Site"}},[_c('b-dropdown',{staticClass:"audit-criterion-dropdown-container",attrs:{"aria-role":"list"},on:{"change":_vm.editRecordSubmitBtnStatus},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var active = ref.active;
return _c('button',{staticClass:"button audit-criterion-type-dropdown"},[_c('span',[_vm._v(_vm._s(_vm.getSiteName(_vm.resultData.auditSiteId)))]),_c('b-icon',{attrs:{"icon":active ? 'menu-up' : 'menu-down'}})],1)}}],null,false,1709046052),model:{value:(_vm.resultData.auditSiteId),callback:function ($$v) {_vm.$set(_vm.resultData, "auditSiteId", $$v)},expression:"resultData.auditSiteId"}},_vm._l((_vm.siteList),function(site){return _c('b-dropdown-item',{key:site.siteId,staticClass:"audit-criterion-type-dropdown-item",attrs:{"aria-role":"listitem","value":site.auditSiteId}},[_vm._v(" "+_vm._s(site.name)+" ")])}),1)],1)],1),_c('div',{staticClass:"column"})]):_vm._e(),(_vm.dirtyDateTimeOption !== 'None')?_c('div',{staticClass:"columns",class:_vm.$style.datesInputContainer},[_c('div',{staticClass:"columns column"},[_c('div',{staticClass:"column",class:[
            _vm.dirtyDateTimeOption === 'Single Date' ||
            _vm.dirtyDateTimeOption === 'Date Range'
              ? 'is-12'
              : 'is-9 datepicker-container' ]},[_c('b-field',{class:_vm.$style.bField,attrs:{"label":_vm.dirtyDateTimeOption === 'Single Date' ||
              _vm.dirtyDateTimeOption === 'Single Date with Time'
                ? 'Record Date'
                : 'Record Start Date'}},[_c('b-datepicker',{staticClass:"has-icon-right input-grey datepicker-input",attrs:{"min-date":_vm.minDate,"max-date":_vm.maxDate,"placeholder":"Click to select...","icon":"calendar-today","icon-pack":"mdi","date-formatter":_vm.formatDate},on:{"input":_vm.editRecordSubmitBtnStatus},model:{value:(_vm.dirtyRecordedStartDate),callback:function ($$v) {_vm.dirtyRecordedStartDate=$$v},expression:"dirtyRecordedStartDate"}})],1),(_vm.invalidStartDate)?_c('p',{class:_vm.$style.validationErrorMessage},[_vm._v(" "+_vm._s(_vm.dirtyDateTimeOption === 'Date Range with Time' ? 'Start Date and Time must be earlier than End Date and Time' : 'Start Date must be earlier than End Date')+" ")]):_vm._e()],1),(
            _vm.dirtyDateTimeOption === 'Single Date with Time' ||
            _vm.dirtyDateTimeOption === 'Date Range with Time'
          )?_c('div',{staticClass:"column is-3 timepicker-input"},[_c('b-field',[_c('b-timepicker',{staticClass:"timepicker-container",attrs:{"min-time":_vm.minDateTime,"max-time":_vm.maxDateTime,"icon":"clock-outline","editable":""},on:{"input":_vm.editRecordSubmitBtnStatus},model:{value:(_vm.dirtyRecordedStartTime),callback:function ($$v) {_vm.dirtyRecordedStartTime=$$v},expression:"dirtyRecordedStartTime"}})],1)],1):_vm._e()]),_c('div',{staticClass:"columns column"},[(
            _vm.dirtyDateTimeOption === 'Date Range' ||
            _vm.dirtyDateTimeOption === 'Date Range with Time'
          )?_c('div',{staticClass:"column",class:[
            _vm.dirtyDateTimeOption === 'Date Range'
              ? 'is-12'
              : 'is-9 datepicker-container' ]},[_c('b-field',{class:_vm.$style.bField,attrs:{"label":"Record End Date"}},[_c('b-datepicker',{staticClass:"has-icon-right input-grey datepicker-input",attrs:{"min-date":_vm.minDate,"max-date":_vm.maxDate,"placeholder":"Click to select...","icon":"calendar-today","icon-pack":"mdi","date-formatter":_vm.formatDate},model:{value:(_vm.dirtyRecordedEndDate),callback:function ($$v) {_vm.dirtyRecordedEndDate=$$v},expression:"dirtyRecordedEndDate"}})],1),(_vm.invalidEndDate)?_c('p',{class:_vm.$style.validationErrorMessage},[_vm._v(" "+_vm._s(_vm.dirtyDateTimeOption === 'Date Range with Time' ? 'End Date and Time must be later than Start Date and Time' : 'End Date must be later than Start Date')+" ")]):_vm._e()],1):_vm._e(),(_vm.dirtyDateTimeOption === 'Date Range with Time')?_c('div',{staticClass:"column is-3 timepicker-input"},[_c('b-field',[_c('b-timepicker',{staticClass:"timepicker-container",attrs:{"icon":"clock-outline","editable":""},model:{value:(_vm.dirtyRecordedEndTime),callback:function ($$v) {_vm.dirtyRecordedEndTime=$$v},expression:"dirtyRecordedEndTime"}})],1)],1):_vm._e()])]):_vm._e()]),_c('hr',{class:_vm.$style.divider}),(_vm.dataCollectionSummary && !_vm.isCriteriaDisabled)?_c('div',{key:_vm.formKey,staticClass:"dataEntryContainerAggregate"},[_vm._m(0),_vm._l((_vm.criteriaDataGetter),function(criteriaOption,index){return _c('div',{key:index,staticClass:"row-content"},[(
          criteriaOption.criteria.criteriaType ===
          _vm.CRITERION_TYPE.CONTINUOUS_DISCRETE
        )?_c('DataCollectionContinuousDiscreteCriteria',{attrs:{"index":index,"mode":"aggregate","criteriaOption":criteriaOption,"totalToDateMessage":_vm.totalToDateMessage,"resultData":_vm.resultData,"editDataCollectionRecord":_vm.editDataCollectionRecord,"criteriaSamplingData":_vm.getCriteriaSamplingData(criteriaOption.criteria.id)},on:{"update:resultData":function($event){_vm.resultData=$event},"update:result-data":function($event){_vm.resultData=$event},"updateSubmitButton":_vm.handleContinuousDiscreteCriteriaSubmitButton}}):(
          criteriaOption.criteria.criteriaType === _vm.CRITERION_TYPE.RANGE &&
          _vm.dataCollectionSummary
        )?_c('DataCollectionRangeCriteria',{key:_vm.rangeCriteriaKey,attrs:{"index":index,"criteriaOption":criteriaOption,"totalToDateMessage":_vm.totalToDateMessage,"resultData":_vm.resultData,"editDataCollectionRecord":_vm.editDataCollectionRecord,"criteriaSamplingData":_vm.getCriteriaSamplingData(criteriaOption.criteria.id)},on:{"update:resultData":function($event){_vm.resultData=$event},"update:result-data":function($event){_vm.resultData=$event},"updateSubmitButton":_vm.handleRangeCriteriaSubmitButton}}):_c('div',[_c('div',{staticClass:"columns row-table-headings"},[_c('div',{staticClass:"column column-no"},[_vm._v(_vm._s(index + 1)+".")]),_c('div',{staticClass:"column column-criteria-title"},[_c('CriteriaViewUpdateComponent',{class:_vm.$style.criteriaTitleContainer,attrs:{"criteria":criteriaOption.criteria}}),(criteriaOption.criteria.guide !== '')?_c('b-collapse',{attrs:{"open":false,"aria-id":"contentIdForA11y1"},scopedSlots:_vm._u([{key:"trigger",fn:function(props){return [_c('div',{staticClass:"guide-btn-container"},[_c('b-button',{staticClass:"guide-trigger-btn",attrs:{"label":!props.open ? 'Show Guide' : 'Hide Guide',"aria-controls":"contentIdForA11y1"}}),_c('b-icon',{attrs:{"icon":!props.open ? 'chevron-down' : 'chevron-up',"size":"is-medium"}})],1)]}}],null,true)},[_c('div',{class:_vm.$style.guideSection},[_c('div',{staticClass:"content"},[_vm._v(" "+_vm._s(criteriaOption.criteria.guide)+" ")])])]):_vm._e()],1)]),_c('div',{staticClass:"columns row-table-content"},[_c('div',{staticClass:"column column-empty"}),_c('div',{staticClass:"column column-table-data"},[_c('div',{staticClass:"columns row-data-heading"},[_c('div',{staticClass:"column-choices"},[_vm._v("Answer Choices")]),_c('div',{class:[
                  'column-total',
                  _vm.totalToDateMessage ? 'padding-right25' : '' ]},[_c('span',[_vm._v("Total To-Date")]),(_vm.totalToDateMessage)?_c('span',[_c('b-tooltip',{class:_vm.$style.infoTooltip,attrs:{"label":"Includes the value currently being edited","position":"is-bottom"}},[_c('b-icon',{attrs:{"icon":"information-outline","size":"is-small","custom-size":"mdi-15px","custom-class":_vm.$style.infoIcon}})],1)],1):_vm._e()]),_c('div',{staticClass:"column-count"},[_vm._v("Count")])]),_vm._l((criteriaOption.optionValues),function(option,optionIndex){return _c('div',{key:optionIndex,staticClass:"columns row-data-content"},[_c('div',{staticClass:"column-choices",class:[
                  optionIndex + 1 === criteriaOption.optionValues.length
                    ? _vm.$style.applyBorderColumnChoices
                    : null ]},[_vm._v(" "+_vm._s(option.option)+" ")]),_c('div',{staticClass:"column-total",class:[
                  optionIndex + 1 === criteriaOption.optionValues.length
                    ? _vm.$style.applyBorderColumnTotal
                    : null,
                  optionIndex === 0
                    ? _vm.$style.applyPaddingTopFirstChildTotal
                    : null ]},[_vm._v(" "+_vm._s(option.totalToDate)+" ")]),_c('div',{staticClass:"column-count",class:[
                  optionIndex + 1 === criteriaOption.optionValues.length
                    ? _vm.$style.applyBorderColumnCount
                    : null,
                  optionIndex === 0
                    ? _vm.$style.applyPaddingTopFirstChildCount
                    : null ]},[_c('b-field',[_c('b-input',{class:_vm.$style.numberInput,attrs:{"type":"number","onkeydown":"javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))","oninput":"javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);","maxlength":"7","min":"0"},on:{"input":function($event){return _vm.handleInputChange(criteriaOption, index)}},model:{value:(
                      _vm.resultData.criteriaData[index].optionValues[optionIndex]
                        .value
                    ),callback:function ($$v) {_vm.$set(_vm.resultData.criteriaData[index].optionValues[optionIndex]
                        , "value", $$v)},expression:"\n                      resultData.criteriaData[index].optionValues[optionIndex]\n                        .value\n                    "}})],1)],1)])})],2)]),(
            !criteriaOption.isCommentSectionExpanded &&
            _vm.resultData.criteriaData.length > 1
          )?_c('div',{staticClass:"columns row-table-comment"},[_c('div',{staticClass:"column column-empty-comment"}),_c('div',{staticClass:"column column-data-comment",on:{"click":function($event){return _vm.showCommentBox(criteriaOption)}}},[_vm._v(" + Add Comment ")])]):_vm._e(),(
            criteriaOption.isCommentSectionExpanded ||
            _vm.resultData.criteriaData.length === 1
          )?_c('div',{staticClass:"columns row-table-comment"},[_c('div',{staticClass:"column column-empty-comment"}),_c('div',{staticClass:"column column-data-comment-box"},[_c('b-field',{attrs:{"label":"Comment"}},[_c('b-input',{attrs:{"type":"textarea"},model:{value:(criteriaOption.comments),callback:function ($$v) {_vm.$set(criteriaOption, "comments", $$v)},expression:"criteriaOption.comments"}})],1)],1)]):_vm._e()]),(index + 1 !== _vm.criteriaDataGetter.length)?_c('hr',{staticClass:"divider"}):_vm._e()],1)})],2):_vm._e(),(_vm.resultData.criteriaData.length > 1 && _vm.dataCollectionSummary)?_c('div',[_c('hr',{class:_vm.$style.containerDivider}),_c('div',{staticClass:"columns",class:_vm.$style.containerComment},[_c('div',{staticClass:"column",class:_vm.$style.columnEmptyComment}),_c('div',{staticClass:"column",class:_vm.$style.columnContainerComment},[_c('b-field',{attrs:{"label":"Overall Comment"}},[_c('b-input',{attrs:{"type":"textarea"},model:{value:(_vm.resultData.comments),callback:function ($$v) {_vm.$set(_vm.resultData, "comments", $$v)},expression:"resultData.comments"}})],1)],1)])]):_vm._e(),(_vm.isCriteriaDisabled)?_c('div',{class:_vm.$style.contentSection},[_c('div',{class:_vm.$style.noDataState},[_vm._v("No criteria found")])]):_vm._e(),(_vm.dataCollectionSummary)?_c('div',{class:_vm.$style.actionButtonWrapper},[(!_vm.isEdit)?_c('button',{staticClass:"button",class:_vm.$style.backToOverviewButton,on:{"click":function($event){return _vm.navigateToOverview()}}},[_c('span',[_vm._v(_vm._s('Back to ' + (!_vm.isDataCollector ? 'Overview' : 'Project Details')))])]):_vm._e(),_c('button',{staticClass:"button is-large is-rounded is-primary",attrs:{"disabled":_vm.submitDisabled || _vm.isCriteriaDisabled},on:{"click":function($event){_vm.isEdit ? _vm.handleEnteredDataRecords() : _vm.handleMultipleSubmission()}}},[_vm._v(" "+_vm._s(_vm.editDataCollectionRecord ? 'Save' : 'Submit')+" ")])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns row-headers"},[_c('div',{staticClass:"column column-index"},[_vm._v("No.")]),_c('div',{staticClass:"column column-heading"},[_vm._v("Data Collection Period Criteria")])])}]

export { render, staticRenderFns }