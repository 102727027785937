




















































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import { Action, State } from 'vuex-class';
import HeroBar from '@/components/base/HeroBar.vue';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import {
  AuditSamplingType,
  AuditSamplingSite,
  ConsecutiveSamplingMode,
  DataCollectionSummaryRequestPayload,
  MultiSitePayload,
  MultiSiteSamplingRequestPayload,
  AuditSite,
  AuditSamplingResponse,
  AuditSamplingDataConfig,
  RangeCriteriaTypeSamplingValidation,
  RangeSamplingValidation,
} from '@/store/modules/audits/types/audits.types';
import {
  map as _map,
  round as _round,
  cloneDeep as _cloneDeep,
  orderBy as _orderBy,
  findIndex as _findIndex,
  find as _find,
} from 'lodash';
import { ToastProgrammatic as Toast } from 'buefy';
import SamplingCalculator from '@/views/Audit/components/SamplingCalculator.vue';

@Component({
  components: {
    SamplingCalculator,
    DefaultLayout,
    HeroBar,
  },
})
export default class ConsecutiveMinAndMaxMultiSiteSampling extends Vue {
  @Prop()
  public auditId!: number;
  @Prop()
  public auditSiteList!: AuditSite[];
  @Prop()
  public auditConfig!: AuditSamplingResponse;
  @Prop()
  public auditSamplingType!: AuditSamplingType;
  @Prop()
  public consecutiveSamplingMode!: ConsecutiveSamplingMode;
  @Prop()
  public validateCurrentDataEntryValue!: boolean;
  @Prop({ default: true })
  public editable!: boolean;
  public auditStartDate: any = null;
  public multiSitePayload: MultiSitePayload[] = [];
  public siteArray: AuditSamplingSite[] = [];
  public renderComponent: boolean = true;
  public validateDataEntry: boolean = false;
  public isButtonDisabled: boolean = false;
  public isButtonClick: boolean = false;
  public autoDistribute: boolean = true;
  public invalidSamplingSites: RangeCriteriaTypeSamplingValidation[] = [];
  public tooltipMinErrorMessage: string = '';
  public tooltipMaxErrorMessage: string = '';

  @Action('audits/updateMultiSiteSampling')
  public updateMultiSiteSampling!: (
    payload: MultiSiteSamplingRequestPayload,
  ) => void;

  @State((state) => state.audits.updatedMultiSiteSampling)
  public updatedMultiSiteSampling!: any;

  @Watch('updatedMultiSiteSampling')
  @isTruthy
  public watchUpdatedMultiSiteSampling() {
    this.showNotificationAndRedirectToProject();
  }

  public mounted() {
    this.validateDataEntry = this.validateCurrentDataEntryValue;
    this.populateMultiSiteData();
  }

  public populateMultiSiteData() {
    this.auditSiteList.forEach((auditSite: AuditSite) => {
      const siteObject: AuditSamplingSite = {
        siteId: auditSite.site.id,
        name: auditSite.site.name,
        minSampleSize: null,
        maxSampleSize: null,
        auditSiteId: auditSite.id,
      };
      this.siteArray.push(siteObject);
    });

    this.auditConfig.dataConfigs.forEach(
      (dataConfig: AuditSamplingDataConfig) => {
        const currentCriteriaPayload: MultiSitePayload = {
          auditId: this.auditId,
          criteriaId: 0, // todo: change this logic later
          auditCriteriaId: 0,
          sites: [],
          minSampleSize: null,
          maxSampleSize: null,
        };
        Object.values(dataConfig).forEach(
          (singleConfig: AuditSamplingDataConfig) => {
            if (singleConfig.auditCriteriaMap.isActive) {
              currentCriteriaPayload.criteriaId =
                singleConfig.auditCriteriaMap.criteria.id;
              currentCriteriaPayload.auditId = this.auditId;
              currentCriteriaPayload.auditCriteriaId =
                singleConfig.auditCriteriaMap.id;
              currentCriteriaPayload.criteriaTitle =
                singleConfig.auditCriteriaMap.criteria.title;
              currentCriteriaPayload.order =
                singleConfig.auditCriteriaMap.order;
              if (singleConfig.auditSiteMap === null) {
                currentCriteriaPayload.minSampleSize =
                  singleConfig.minSampleSize !== null
                    ? _round(singleConfig.minSampleSize)
                    : null;
                currentCriteriaPayload.maxSampleSize =
                  singleConfig.maxSampleSize !== null
                    ? _round(singleConfig.maxSampleSize)
                    : null;
                currentCriteriaPayload.dataConfig = singleConfig;
              } else {
                const auditSamplingConfig = _find(
                  this.siteArray,
                  (value: AuditSamplingSite) => {
                    return value.auditSiteId === singleConfig.auditSiteMap.id;
                  },
                );
                if (auditSamplingConfig) {
                  currentCriteriaPayload.sites.push({
                    ...auditSamplingConfig,
                    minSampleSize:
                      singleConfig.minSampleSize !== null
                        ? _round(singleConfig.minSampleSize)
                        : null,
                    maxSampleSize:
                      singleConfig.maxSampleSize !== null
                        ? _round(singleConfig.maxSampleSize)
                        : null,
                    dataConfig: singleConfig,
                  });
                }
              }
            }
          },
        );
        currentCriteriaPayload.sites = _orderBy(
          currentCriteriaPayload.sites,
          ['name'],
          ['asc'],
        );
        this.multiSitePayload.push(currentCriteriaPayload);
      },
    );
    this.multiSitePayload = this.multiSitePayload.map(
      (criteria: MultiSitePayload) => {
        const sitesIdsToCheckAgainst: number[] = this.siteArray.map(
          (site: AuditSamplingSite) => site.siteId,
        );
        return {
          ...criteria,
          sites: criteria.sites.sort(
            (optionA: AuditSamplingSite, optionB: AuditSamplingSite) => {
              if (
                sitesIdsToCheckAgainst.indexOf(optionA.siteId) >
                sitesIdsToCheckAgainst.indexOf(optionB.siteId)
              ) {
                return 1;
              } else {
                return -1;
              }
            },
          ),
        };
      },
    );
    this.validateSamplingData();
  }

  public showNotificationAndRedirectToProject() {
    Toast.open({
      message: `Changes saved`,
      position: 'is-top',
      type: 'is-dark',
      duration: 3500,
    });
  }

  public validateRangeAndUpdateChildSamplingSize(
    index: number,
    type: string,
    min: number,
    max: number,
  ) {
    let message: string = '';
    if (Number(max) > 0 && Number(max) < Number(min)) {
      if (type === 'min') {
        message = 'Min value cannot be greater than max value';
        this.auditCriteriaList[index].minSampleSize = null;
      } else {
        message = 'Max value cannot be less than min value';
        this.auditCriteriaList[index].maxSampleSize = null;
      }
      Toast.open({
        message,
        position: 'is-top',
        type: 'is-danger',
      });
    } else {
      this.updateChildSamplingSize(index, type);
    }
  }

  public handleMultiSiteChanges() {
    this.isButtonClick = true;
    this.validateSamplingData();
    if (!this.isButtonDisabled) {
      this.updateMultiSiteSampling({
        auditId: this.auditId,
        multiSitePayload: this.multiSitePayload,
        auditSamplingType: this.auditSamplingType,
        auditSamplingMode: this.consecutiveSamplingMode,
        validateDataEntry: this.validateDataEntry,
        samplingConfigId: this.auditConfig.auditSamplingConfigs.id,
      });
    }
  }

  public isParentSamplingValid(criteriaId: number, type: string): boolean {
    const criteriaDetails: any = this.invalidSamplingSites.find(
      (criteria) => criteria.criteriaId === criteriaId,
    );
    if (type === 'min') {
      return criteriaDetails && criteriaDetails.hasInvalidMin ? false : true;
    } else {
      return criteriaDetails && criteriaDetails.hasInvalidMax ? false : true;
    }
  }

  public isSiteSamplingValid(criteriaId: number, siteId: number): boolean {
    const criteriaDetails: any = this.invalidSamplingSites.find(
      (criteria) => criteria.criteriaId === criteriaId,
    );

    if (criteriaDetails) {
      const siteDetails: any = criteriaDetails.sites.find(
        (site: any) => site.siteId === siteId,
      );
      return siteDetails ? !siteDetails.hasInvalidRange : true;
    } else {
      return true;
    }
  }

  public validateRangeOnChange() {
    if (this.isButtonClick) {
      this.isButtonDisabled = false;
      this.tooltipMinErrorMessage = '';
      this.tooltipMaxErrorMessage = '';
      this.validateSamplingData();
    }
  }

  public validateSamplingData() {
    this.invalidSamplingSites = this.multiSitePayload.map((criteria) => {
      let totalMin: number = 0;
      let totalMax: number = 0;
      let invlidCriteriaMinValue = false;
      let invlidCriteriaMaxValue = false;
      let siteValueErrors = '';
      const sites: RangeSamplingValidation[] = criteria.sites.map((site) => {
        let inValidSiteRange = false;
        if (
          site.minSampleSize &&
          site.maxSampleSize &&
          Number(site.minSampleSize) > Number(site.maxSampleSize)
        ) {
          inValidSiteRange = true;
          this.isButtonDisabled = true;
          siteValueErrors = 'Min value cannot be greater than max value.';
        }
        return {
          siteId: site.siteId,
          hasInvalidRange: inValidSiteRange,
          minSampleSize: site.minSampleSize,
          maxSampleSize: site.maxSampleSize,
        };
      });
      if (siteValueErrors) {
        this.tooltipMinErrorMessage = siteValueErrors;
      }

      criteria.sites.forEach((site) => {
        totalMin += Number(site.minSampleSize);
        totalMax += Number(site.maxSampleSize);
      });
      if (
        totalMin &&
        criteria.minSampleSize &&
        totalMin < Number(criteria.minSampleSize)
      ) {
        invlidCriteriaMinValue = true;
        this.isButtonDisabled = true;
        this.tooltipMinErrorMessage = siteValueErrors
          ? `The total min value should be greater than equal to the sum of sites min value and ` +
            siteValueErrors
          : `The total min value should be greater than equal to the sum of sites min value.`;
      }
      if (
        totalMax &&
        criteria.maxSampleSize &&
        totalMax > Number(criteria.maxSampleSize)
      ) {
        invlidCriteriaMaxValue = true;
        this.isButtonDisabled = true;
        this.tooltipMaxErrorMessage = `The total max value should be less than equal to the sum of sites max value.`;
      }

      return {
        criteriaId: criteria.criteriaId,
        autoDistributeMinSampling: Number(criteria.minSampleSize)
          ? false
          : true,
        autoDistributeMaxSampling: Number(criteria.maxSampleSize)
          ? false
          : true,
        disableMinSiteInput: Number(criteria.minSampleSize) ? false : true,
        disableMaxSiteInput: Number(criteria.maxSampleSize) ? false : true,
        hasInvalidMin: invlidCriteriaMinValue,
        hasInvalidMax: invlidCriteriaMaxValue,
        minSampleSize: criteria.minSampleSize,
        maxSampleSize: criteria.maxSampleSize,
        sites,
      };
    });
  }

  public isSiteInputDisable(type: string, criteriaId: number) {
    const criteriaDetails: any = this.invalidSamplingSites.find(
      (criteria) => criteria.criteriaId === criteriaId,
    );

    return criteriaDetails
      ? type === 'min'
        ? criteriaDetails.disableMinSiteInput
        : criteriaDetails.disableMaxSiteInput
      : false;
  }

  public isAutoDistributeEnable(type: string, criteriaId: number) {
    const criteriaDetails: any = this.invalidSamplingSites.find(
      (criteria) => criteria.criteriaId === criteriaId,
    );

    return criteriaDetails
      ? type === 'min'
        ? criteriaDetails.autoDistributeMinSampling
        : criteriaDetails.autoDistributeMaxSampling
      : false;
  }

  public disableSiteInput(type: string, criteriaIndex: number) {
    this.invalidSamplingSites = this.invalidSamplingSites.map((criteria) => {
      if (
        criteria.criteriaId === this.auditCriteriaList[criteriaIndex].criteriaId
      ) {
        if (type === 'min') {
          criteria.disableMinSiteInput = false;
          criteria.autoDistributeMinSampling = false;
        } else {
          criteria.disableMaxSiteInput = false;
          criteria.autoDistributeMaxSampling = false;
        }
      }
      return criteria;
    });
  }

  public updateChildSamplingSize(index: number, type: string) {
    const min = Number(this.auditCriteriaList[index].minSampleSize);
    const max = Number(this.auditCriteriaList[index].maxSampleSize);
    const siteArrayLength = this.siteArray.length;
    if (type === 'min') {
      if (
        this.isAutoDistributeEnable(
          'min',
          this.auditCriteriaList[index].criteriaId,
        )
      ) {
        let modulo = min % siteArrayLength;
        const childVal = (min - modulo) / siteArrayLength;
        this.auditCriteriaList[index].sites.forEach(
          (currentVal: AuditSamplingSite, siteIndex: number) => {
            const tempModulo = modulo > 0 ? 1 : 0;
            this.auditCriteriaList[index].sites[siteIndex].minSampleSize =
              childVal + tempModulo;
            modulo -= 1;
          },
        );

        this.disableSiteInput('min', index);
      } else {
        this.validateRangeOnChange();
      }
    } else {
      if (
        this.isAutoDistributeEnable(
          'max',
          this.auditCriteriaList[index].criteriaId,
        )
      ) {
        let modulo = max % siteArrayLength;
        const childVal = (max - modulo) / siteArrayLength;
        this.auditCriteriaList[index].sites.forEach(
          (currentVal: AuditSamplingSite, siteIndex: number) => {
            const tempModulo = modulo > 0 ? 1 : 0;
            this.auditCriteriaList[index].sites[siteIndex].maxSampleSize =
              childVal + tempModulo;
            modulo -= 1;
          },
        );
        this.disableSiteInput('max', index);
      } else {
        this.validateRangeOnChange();
      }
    }
  }

  get auditCriteriaList() {
    return _orderBy(this.multiSitePayload, ['order'], ['asc']);
  }
}
