

































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import BaseModal from '@/jbi-shared/vue-components/BaseModal.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
  CRITERION_TYPE_DISPLAY_NAME,
  CRITERION_TYPE,
  Criterion,
  BooleanCriterionContent,
  RangeCriterionContent,
  CheckboxesCriterionContent,
  CheckboxesSingleCriterionContent,
  ContinuousDiscreteCriterionContent,
} from '@/store/types/criterions.types';
import { StringInputOption } from '@/jbi-shared/types/form.types';
import {
  cloneDeep as _cloneDeep,
  isEqual as _isEqual,
  uniq as _uniq,
  merge as _merge,
} from 'lodash';
import { DialogProgrammatic as Dialog } from 'buefy';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import CheckboxesCriterionInput from '@/views/Project/components/CheckboxesCriterionInput.vue';
import BooleanCriterionInput from '@/views/Project/components/BooleanCriterionInput.vue';
import RangeCriterionInput from '@/views/Project/components/RangeCriterionInput.vue';
import ContinuousDiscreteCriterionInput from '@/views/Project/components/ContinuousDiscreteCriterionInput.vue';
import { CplusCriteria } from '@/store/modules/projects/types/projects.types';
import ConfirmationDialogue from '../../../components/ConfirmationDialogue.vue';

@Component({
  components: {
    BaseModal,
    CheckboxesCriterionInput,
    BooleanCriterionInput,
    RangeCriterionInput,
    ValidationProvider,
    ValidationObserver,
    ContinuousDiscreteCriterionInput,
  },
})
export default class AuditCriteriaCreateModal extends Vue {
  @Prop()
  public modalTitle!: string;

  @Prop({
    default() {
      return new Criterion({
        content: new BooleanCriterionContent({
          title: '',
          type: CRITERION_TYPE.BOOLEAN,
          sourceLink: '',
        }),
      });
    },
  })
  public criterion!: Criterion;

  @Prop()
  public reOrderedCriteriaArray!: any;

  @Prop()
  public criteriaIndex!: number;

  @Prop({
    default() {
      return false;
    },
  })
  public isSavedCriteria!: boolean;

  @Prop()
  public currentCriteria!: CplusCriteria;

  @Prop({ default: true })
  public projectEdit!: boolean;

  @Prop()
  public createProject!: boolean;

  public dirtyCriterion = _cloneDeep(this.criterion);
  public dirtyCriterions!: any;

  public hasRangeError: boolean = false;
  public hasBooleanError: boolean = false;
  public isBtnDisabled: boolean = true;

  get CRITERION_TYPE() {
    return CRITERION_TYPE;
  }

  get options(): StringInputOption[] {
    return Object.entries(CRITERION_TYPE_DISPLAY_NAME).map(([key, value]) => {
      return {
        id: key,
        name: value,
      };
    });
  }

  public reset() {
    this.dirtyCriterion = _cloneDeep(this.criterion);
  }

  public async addCriterion() {
    if (!(await this.validatePreSave())) {
      return false;
    }
    const dirtyCriterion = this.sanitizeCriterion(this.dirtyCriterion);
    if (dirtyCriterion.content instanceof CheckboxesCriterionContent) {
      const [first, ...rest] = dirtyCriterion.content.checkboxesOptions;
      dirtyCriterion.content.checkboxesOptions = [...rest, first];
    }
    this.dirtyCriterions = [
      ...this.dirtyCriterions,
      {
        id: '',
        ...dirtyCriterion.content,
      },
    ];
    this.$emit('updateReOrderedCriteriaArray', this.dirtyCriterions);
    this.$emit('newCriteria', {
      id: '',
      ...dirtyCriterion.content,
    });
    this.$emit('close');
  }

  public async editCriterion() {
    if (!(await this.validatePreSave())) {
      return false;
    }

    if (this.projectEdit) {
      this.updateCriterion();
    } else {
      this.$buefy.modal.open({
        parent: this,
        component: ConfirmationDialogue,
        hasModalCard: true,
        trapFocus: true,
        props: {
          modalTitle: 'Are you sure you want to customise this criteria?',
          confirmationText:
            'Customising this criteria will archive the previous version and disable any further data collection against it. You cannot undo this action.',
        },
        events: {
          confirm: () => {
            this.updateCriterion();
          },
        },
      });
    }
  }

  public updateCriterion() {
    const dirtyCriterion = this.sanitizeCriterion(this.dirtyCriterion);
    const updatedDirtyCriterion: CplusCriteria = this.updateDirtyCplusCriteria(
      this.dirtyCriterions[this.criteriaIndex],
      dirtyCriterion.content,
    );
    if (this.createProject) {
      this.dirtyCriterions[this.criteriaIndex] = { ...updatedDirtyCriterion };
      this.dirtyCriterions[this.criteriaIndex].isChanged = true;
      this.$emit('updateReOrderedCriteriaArray', this.dirtyCriterions);
    } else {
      this.dirtyCriterions[this.criteriaIndex] = { ...updatedDirtyCriterion };
      this.dirtyCriterions[this.criteriaIndex].isChanged = true;
      this.$emit('updateCriteria', this.dirtyCriterions[this.criteriaIndex]);
    }
    this.$emit('close');
  }

  public async validatePreSave() {
    // check duplicated checkboxed options
    if (
      this.dirtyCriterion.content instanceof CheckboxesCriterionContent ||
      this.dirtyCriterion.content instanceof CheckboxesSingleCriterionContent
    ) {
      const { checkboxesOptions } = this.dirtyCriterion.content;
      const sanitized = this.sanitizeCriterionCheckboxesOptions(
        checkboxesOptions,
      );
      if (!_isEqual(checkboxesOptions, sanitized)) {
        const confirm = () =>
          new Promise((resolve) => {
            return Dialog.confirm({
              message: `Empty values and duplicated values will be removed.`,
              confirmText: 'Confirm',
              cancelText: 'Cancel',
              onConfirm() {
                resolve(true);
              },
              onCancel() {
                resolve(false);
              },
            });
          });
        if (!(await confirm())) {
          return false;
        }
      }
    }
    if (this.dirtyCriterion.content instanceof RangeCriterionContent) {
      if (
        Number(this.dirtyCriterion.content.range[1]) <=
        Number(this.dirtyCriterion.content.range[0])
      ) {
        return false;
      }
    }
    return true;
  }

  public sanitizeCriterion(criterion: Criterion): Criterion {
    criterion = _cloneDeep(criterion);
    if (criterion.content instanceof RangeCriterionContent) {
      criterion.content.range = [
        Number(criterion.content.range[0]),
        Number(criterion.content.range[1]),
        String(criterion.content.range[2]),
      ];
    } else if (
      criterion.content instanceof CheckboxesCriterionContent ||
      criterion.content instanceof CheckboxesSingleCriterionContent
    ) {
      criterion.content.checkboxesOptions = this.sanitizeCriterionCheckboxesOptions(
        criterion.content.checkboxesOptions,
      );
    }
    return criterion;
  }

  public sanitizeCriterionCheckboxesOptions(arr: string[]) {
    arr = arr.map((s) => String(s).trim()).filter(Boolean);
    arr = _uniq(arr);
    return arr;
  }

  public updateDirtyCplusCriteria(
    dirtyCriteria: CplusCriteria,
    updatedCriteria: any,
  ): CplusCriteria {
    // Iterate through the object keys of the existing object
    Object.keys(dirtyCriteria).forEach((item: keyof CplusCriteria) => {
      // Find the matched key value from the updatedCriteria
      const matchedObjectKeys = Object.keys(updatedCriteria).find(
        (data: any) => data === item,
      );

      if (matchedObjectKeys) {
        // Update key value from existing object with the updatedCriteria value
        dirtyCriteria[item] = updatedCriteria[item];
      }
      if (
        this.dirtyCriterion.content.type === CRITERION_TYPE.CONTINUOUS_DISCRETE
      ) {
        dirtyCriteria.config = updatedCriteria.config;
        dirtyCriteria.continuousDiscreteOptions =
          updatedCriteria.continuousDiscreteOptions;
      }
    });
    return dirtyCriteria;
  }

  public disableRangeSubmit(value: boolean) {
    return (this.hasRangeError = value);
  }

  public disableBooleanSubmit(value: boolean) {
    return (this.hasBooleanError = value);
  }

  @isDifferent
  @Watch('dirtyCriterion.content.type')
  public onTypeChange(type: CRITERION_TYPE) {
    switch (type) {
      case CRITERION_TYPE.BOOLEAN:
        this.dirtyCriterion.content = new BooleanCriterionContent(
          this.dirtyCriterion.content,
        );
        if (!this.isNew) {
          this.dirtyCriterions[this.criteriaIndex].booleanOptions = [];
        }
        break;
      case CRITERION_TYPE.RANGE:
        this.dirtyCriterion.content = new RangeCriterionContent({
          ...this.dirtyCriterion.content,
        });
        if (!this.isNew) {
          this.dirtyCriterions[this.criteriaIndex].range = [];
        }
        break;
      case CRITERION_TYPE.CHECKBOXES:
        this.dirtyCriterion.content = new CheckboxesCriterionContent({
          ...this.dirtyCriterion.content,
        });
        if (!this.isNew) {
          this.dirtyCriterions[this.criteriaIndex].checkboxesOptions = [];
        }
        break;
      case CRITERION_TYPE.CHECKBOXES_SINGLE:
        this.dirtyCriterion.content = new CheckboxesSingleCriterionContent({
          ...this.dirtyCriterion.content,
        });
        if (!this.isNew) {
          this.dirtyCriterions[this.criteriaIndex].checkboxesOptions = [];
        }
        break;
      case CRITERION_TYPE.CONTINUOUS_DISCRETE:
        this.dirtyCriterion.content = new ContinuousDiscreteCriterionContent({
          ...this.dirtyCriterion.content,
        });
        break;
    }
  }

  @Watch('dirtyCriterion', { deep: true })
  public isCriteriaEdited(): void {
    this.isBtnDisabled =
      JSON.stringify(this.dirtyCriterion) !== JSON.stringify(this.criterion)
        ? false
        : true;
  }

  get isNew() {
    return this.criteriaIndex !== undefined ? false : true;
  }

  public mounted() {
    this.dirtyCriterions = _cloneDeep(this.reOrderedCriteriaArray);
  }

  public closeModal() {
    this.$emit('close');
  }

  public getFormattedText(value: string) {
    const lowerCaseValue = value.toLowerCase();
    return lowerCaseValue.charAt(0).toUpperCase() + lowerCaseValue.slice(1);
  }

  get showSourceLinkInput(): boolean {
    return !(
      this.currentCriteria &&
      this.currentCriteria.document &&
      this.currentCriteria.document.id !== ''
    );
  }

  get sourceLink() {
    const pattern = /^((http|https|ftp):\/\/)/;
    if (
      this.dirtyCriterion.content.sourceLink &&
      !pattern.test(this.dirtyCriterion.content.sourceLink)
    ) {
      return 'http://' + this.dirtyCriterion.content.sourceLink;
    } else {
      return this.dirtyCriterion.content.sourceLink;
    }
  }
}
