import { axios } from './axios';
import {
  GetNotificationsPayload,
  UpdateViewedSingleNotificationPayload,
  UpdateNewNotificationPayload,
} from '@/store/modules/notification/types/notification.types';

export const notificationApi = {
  async getUserNotification(payload: GetNotificationsPayload) {
    return axios.get(`/notification`, {
      params: payload,
    });
  },
  async updateViewedNotification(payload: UpdateNewNotificationPayload) {
    return axios.post(`/notification/updateViewedNotification`, {
      ...payload,
    });
  },
  async getNotificationDropdown() {
    return axios.get(`/notification/getNotificationDropdown`);
  },
  async updateViewedSingleNotification(
    payload: UpdateViewedSingleNotificationPayload,
  ) {
    return axios.post(`/notification/updateViewedSingleNotification`, {
      ...payload,
    });
  },
};
