



























































































































import {
  Component,
  Vue,
  Prop,
  Watch,
  Model,
  Emit,
} from 'vue-property-decorator';
import { State, Getter, Action, Mutation, namespace } from 'vuex-class';
import { AxiosError } from 'axios';
import BasePagination from '@/components/base/BasePagination.vue';
import BaseModal from '@/jbi-shared/vue-components/BaseModal.vue';
import { RootState } from '@/store/store';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import {
  User,
  Invitation,
  FilteredUserPayload,
} from '@/store/modules/users/types/users.types';
import { mixins } from 'vue-class-component';
import { PaginationMixin } from '@/components/base/pagination.mixin';
import {
  ToastProgrammatic as Toast,
  DialogProgrammatic as Dialog,
} from 'buefy';
import { ApiState } from '@/store/types/general.types';
import { get as _get } from 'lodash';
import BaseDialog from '../../../components/base/BaseDialog.vue';
import dayjs from 'dayjs';

@Component({
  components: {
    BaseModal,
    BasePagination,
  },
})
export default class UserList extends mixins(PaginationMixin) {
  @Prop() public items!: User[];
  @Prop(Number) public currentPage!: number;
  @Prop(Number) public totalNumberOfPage!: number;
  @Prop(Number) public totalCount!: number;
  @Prop(Boolean) public isFirstPage!: boolean;
  @Prop(Boolean) public isLastPage!: boolean;
  @Prop(Number) public startItemIndex!: number;
  @Prop(Number) public endItemIndex!: number;
  @Prop() public filteredParams!: FilteredUserPayload;

  @Action('users/updateStatus')
  public updateStatus!: (userId: number) => void;

  public toggleStatus({ username, userId, email, status }: User) {
    const isInactive = status === 'Inactive';
    const titleStatus = isInactive
      ? `Activate ${username}?`
      : `Deactivate ${username}?`;
    const msg = isInactive
      ? `Are you sure you want to activate user <b>${username}</b>?`
      : `Are you sure you want to deactivate user <b>${username}</b>?`;

    this.$buefy.modal.open({
      parent: this,
      component: BaseDialog,
      hasModalCard: true,
      trapFocus: true,
      props: {
        title: titleStatus,
        content: msg,
        confirmButtonText: 'Cancel',
        cancelButtonText: isInactive ? 'Activate' : 'Deactivate',
      },
      events: {
        cancel: () => {
          this.updateStatus(userId);
        },
      },
    });
  }

  public updateSort(field: string, sort: string) {
    if (this.filteredParams.orderBy) {
      this.filteredParams.orderBy.field = field;
      this.filteredParams.orderBy.sort = sort;
    }
  }

  public getSubscriptionStatus(data: any) {
    let subscriptionStatus: string = '';
    const remainingDays = dayjs(data.expire_at).diff(new Date(), 'day');
    if (remainingDays <= 7) {
      subscriptionStatus =
        remainingDays <= 0 ? `Expired` : `Expiring (${remainingDays} days)`;
    } else {
      subscriptionStatus = 'Active';
    }
    return subscriptionStatus;
  }
}
