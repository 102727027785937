










































import { Component, Vue, Prop, Watch, Model } from 'vue-property-decorator';
import { State, Getter, Action, Mutation, namespace } from 'vuex-class';

@Component({
  components: {},
})
export default class DataTable extends Vue {
  @Prop({ default: [] })
  public data!: any[];

  @Prop({ default: false })
  public hoverable!: boolean;

  @Prop({ default: '' })
  public noDataText!: string;

  @Prop({ default: false })
  public center!: boolean;

  @Prop({ default: false })
  public shadowless!: boolean;

  @Prop({ default: false })
  public borderless!: boolean;

  @Prop({ default: false })
  public radiusless!: boolean;
}
