
























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import OverallExportFilter from '@/views/Audit/components/OverallExportFilter.vue';
import IndividualAnalysis from '@/views/Audit/components/IndividualAnalysis.vue';
import AnalysisNavigationTabs from '@/views/Audit/components/AnalysisNavigationTabs.vue';
import CompressedAnalysisNavigationTabs from '@/components/reports/CompressedAnalysisNavigationTabs.vue';
import ExportDropdown from '@/views/Audit/components/ExportDropdown.vue';
import Card from '@/components/Card.vue';
import { cloneDeep as _cloneDeep } from 'lodash';
import {
  AuditDetail,
  AuditSite,
  AuditCriteria,
  AuditReportFiltersConfig,
  ChartType,
  AuditReportAnalysis,
} from '@/store/modules/audits/types/audits.types';
import { map as _map } from 'lodash';
import { RootState } from '@/store/store';
import { AuditReportExportToPdf } from '@/components/reports/export/pdf/audit/AuditReportPdf';
import JbiLogo from '@/assets/images/jbi_navbar_img@2x.png';
import { AuditReportDocx } from '@/components/reports/export/docx/audit/AuditReportDocx';
import { AuditReportExportToCsv } from '@/components/reports/export/csv/audit/AuditReportCsv';
import { AuditReportExportToExcel } from '@/components/reports/export/excel/audit/AuditReportExcel';
import { reportExportType } from '@/store/types/general.types';
import { auditsApi } from '@/api/audits.api';
import { ApiState } from '../../../store/types/general.types';
import { AuditReportAnalysisResponseDto } from '../../../store/modules/audits/types/audits.types';
import { handleErrorResponse } from '../../../utils/api-response-error-handler.util';

@Component({
  components: {
    OverallExportFilter,
    IndividualAnalysis,
    ExportDropdown,
    Card,
    AnalysisNavigationTabs,
    CompressedAnalysisNavigationTabs,
  },
})
export default class ReportsTab extends Vue {
  @Prop()
  public auditDetail!: AuditDetail;
  public overallExport: string = '';
  public endDate: Date = this.setTimeEnd(new Date());
  public startDate: Date = new Date(
    this.endDate.getFullYear(),
    this.endDate.getMonth(),
    this.endDate.getDate() - 7,
  );
  public totalAnalysis: AuditReportAnalysis[] = [];
  public dirtyTotalAnalysis: AuditReportAnalysis[] = [];
  public dirtyFiltersConfiguration: AuditReportFiltersConfig = {
    filteredSites: [],
    filteredCriterias: [],
    selectedChartType: ChartType.verticalBarChart,
    checkIndividualSite: false,
    checkIndividualCriteria: false,
    selectedPeriod: [this.startDate, this.endDate],
    checkComparison: false,
    comparisonPeriods: [[this.startDate, this.endDate]],
  };
  public dirtyAnalysis: any = {
    title: null,
    filtersConfiguration: this.dirtyFiltersConfiguration,
    analysisId: null,
  };
  public onAuditSitesCriteriaTotalAnalysisSuccess: boolean = false;
  public showCompressedAnalysisHeader: boolean = false;
  public showIndividualAnalysis: boolean = false;
  public visibleAnalysisHeaderKey: number = Math.floor(Math.random() * 999);
  public compressedAnalysisHeaderKey: number = Math.floor(Math.random() * 999);
  public individualAnalysisKey: number = Math.floor(Math.random() * 999);
  public exportFilterKey: number = Math.floor(Math.random() * 999);
  public analysisTabContainerWidth: number = 1342;
  public selectedTabIndex: number = 0;
  public getTotalAnalysisSuccess: boolean = false;
  public analysisToExport: AuditReportAnalysis[] = [];
  public isExportDisabled: boolean = false;

  @Action('audits/getAuditSites')
  public getAuditSites!: (payload: number) => void;

  @State((state: RootState) => state.audits.auditSites)
  public auditSites!: AuditSite[];

  @Action('audits/getAuditCriteria')
  public getAuditCriteria!: (auditId: number) => void;

  @State((state: RootState) => state.audits.auditCriteria)
  public auditCriteria!: AuditCriteria[];

  @State((state: RootState) => state.audits.apiState.getAuditSites)
  public getAuditSitesSuccess!: any;

  @State((state: RootState) => state.audits.apiState.getAuditCriteria)
  public getAuditCriteriaSuccess!: any;

  @Action('audits/getTotalAuditReportAnalysis')
  public getTotalAuditReportAnalysis!: (payload: {
    projectId: number;
    auditId: number;
  }) => void;

  @State((state: RootState) => state.audits.totalAuditReportAnalysis)
  public totalAuditReportAnalysis!: any;

  @State(
    (state: RootState) => state.audits.apiState.getTotalAuditReportAnalysis,
  )
  public getTotalAuditReportAnalysisStatus!: ApiState;

  public mounted() {
    this.getAuditSites(this.getAuditId);
    this.getAuditCriteria(this.getAuditId);
    this.fetchTotalAnalysis(this.getAuditId, this.getProjectId);
  }

  public fetchTotalAnalysis(auditId: number, projectId: number) {
    this.getTotalAuditReportAnalysis({ auditId, projectId });
  }

  @Watch('getTotalAuditReportAnalysisStatus.success')
  @isTruthy
  public watchGetTotalAuditReportAnalysisStatus() {
    this.totalAnalysis = JSON.parse(
      JSON.stringify(this.totalAuditReportAnalysis),
    );
    this.analysisToExport = JSON.parse(
      JSON.stringify(this.totalAuditReportAnalysis),
    );
    this.getTotalAnalysisSuccess = true;
    this.populateData();
  }

  @Watch('onAuditSitesCriteriaTotalAnalysisSuccess')
  @isTruthy
  public watchAuditSitesCriteriaTotalAnalysisSuccess() {
    this.dirtyFiltersConfiguration.filteredSites = this.getAllSiteIds;
    this.dirtyFiltersConfiguration.filteredCriterias = this.getAllCriteriaIds;
  }

  public populateData() {
    if (this.totalAnalysis.length === 0) {
      this.dirtyTotalAnalysis.push({
        ...this.dirtyAnalysis,
        title: 'Analysis ' + (this.dirtyTotalAnalysis.length + 1),
        analysisId: Math.floor(Math.random() * 9000 + 1000),
      });
      this.visibleAnalysisHeaderKey += 1;
      this.showIndividualAnalysis = true;
    } else {
      this.dirtyTotalAnalysis = _cloneDeep(this.totalAnalysis);
      this.visibleAnalysisHeaderKey += 1;
      this.showIndividualAnalysis = true;
      if (this.dirtyTotalAnalysis.length > this.displayTab) {
        this.showCompressedAnalysisHeader = true;
        this.compressedAnalysisHeaderKey += 1;
      }
    }
  }

  public addNewAnalysis() {
    this.dirtyTotalAnalysis.unshift({
      ...this.dirtyAnalysis,
      title: 'Analysis ' + (this.dirtyTotalAnalysis.length + 1),
      analysisId: Math.floor(Math.random() * 9000 + 1000),
    });
    this.analysisToExport = _cloneDeep(this.dirtyTotalAnalysis);
    this.exportFilterKey += 1;
    this.showIndividualAnalysis = true;
    this.visibleAnalysisHeaderKey += 1;
    this.individualAnalysisKey += 1;
    this.selectedTabIndex = 0;
    if (this.dirtyTotalAnalysis.length > this.displayTab) {
      this.showCompressedAnalysisHeader = true;
      this.compressedAnalysisHeaderKey += 1;
    }
  }

  public handleOverallExportAnalysisFilter(newValue: number[]) {
    if (newValue.length === 0) {
      this.isExportDisabled = true;
    } else {
      this.isExportDisabled = false;
      const dirtyAnalysisToExport: AuditReportAnalysis[] = [];
      newValue.forEach((analysisId) => {
        const dirtyAnalysis:
          | AuditReportAnalysis
          | undefined = this.dirtyTotalAnalysis.find(
          (analysis: AuditReportAnalysis) => {
            return analysis.analysisId === analysisId;
          },
        );
        if (dirtyAnalysis) {
          dirtyAnalysisToExport.push(dirtyAnalysis);
        }
      });
      this.analysisToExport = _cloneDeep(dirtyAnalysisToExport);
    }
  }

  public async handleExport(exportType: string) {
    const reportAnalysisResponse: AuditReportAnalysisResponseDto[] = [];
    for (const analysis of this.analysisToExport) {
      const payload: any = {
        ...analysis.filtersConfiguration,
        projectId: this.getProjectId,
        auditId: this.getAuditId,
        analysisId: analysis.analysisId,
        type: this.auditSitelist.length > 1 ? 'multiSite' : 'singleSite',
      };

      try {
        const response: any = await auditsApi.getAuditReportExport(
          payload.auditId,
          payload,
        );
        reportAnalysisResponse.push({
          result: response.data,
          payload,
          analysisTitle: analysis.title,
          jbiLogo: JbiLogo,
          projectTitle: this.auditDetail.project.title,
          auditTitle: this.auditDetail.name,
          auditSites: this.auditSitelist,
          auditCriteria: this.auditCriteriaList,
        });
      } catch (error) {
        handleErrorResponse(error, this);
        break;
      }
    }
    if (reportAnalysisResponse.length > 0) {
      switch (exportType) {
        case reportExportType[0]:
          this.handleExportToPdf(reportAnalysisResponse);
          break;
        case reportExportType[1]:
          this.handleExportToDocx(reportAnalysisResponse);
          break;
        case reportExportType[2]:
          this.handleExportToCsv(reportAnalysisResponse);
          break;
        case reportExportType[3]:
          this.handleExportToExcel(reportAnalysisResponse);
          break;
        default:
          break;
      }
    }
  }

  public handleExportToPdf(
    reportResponse: AuditReportAnalysisResponseDto[],
  ): void {
    const auditReportExportToPdf = new AuditReportExportToPdf();
    auditReportExportToPdf.create(reportResponse, this.auditDetail.name);
  }

  public handleExportToDocx(
    reportResponse: AuditReportAnalysisResponseDto[],
  ): void {
    const auditReportExportToDocx = new AuditReportDocx();
    auditReportExportToDocx.create(reportResponse, this.auditDetail.name);
  }

  public handleExportToCsv(
    reportResponse: AuditReportAnalysisResponseDto[],
  ): void {
    const auditReportExportToCsv = new AuditReportExportToCsv();
    auditReportExportToCsv.create(reportResponse, this.auditDetail.name);
  }

  public handleExportToExcel(
    reportResponse: AuditReportAnalysisResponseDto[],
  ): void {
    const auditReportExportToExcel = new AuditReportExportToExcel();
    auditReportExportToExcel.create(reportResponse, this.auditDetail.name);
  }

  @Watch('getAuditSitesSuccess')
  public watchGetAuditSitesSuccess() {
    this.auditSitesCriteriaTotalAnalysisSuccess();
  }

  @Watch('getTotalAnalysisSuccess')
  public watchGetTotalAnalysisSuccess() {
    this.auditSitesCriteriaTotalAnalysisSuccess();
  }

  @Watch('getAuditCriteriaSuccess')
  public watchGetAuditCriteriaSuccess() {
    this.auditSitesCriteriaTotalAnalysisSuccess();
  }

  public auditSitesCriteriaTotalAnalysisSuccess() {
    if (this.auditSites && this.auditCriteria && this.getTotalAnalysisSuccess) {
      this.onAuditSitesCriteriaTotalAnalysisSuccess = true;
      this.$nextTick(() => {
        this.analysisTabContainerWidth = (this.$refs
          .container as Vue).$el.clientWidth;
      });
    } else {
      this.onAuditSitesCriteriaTotalAnalysisSuccess = false;
    }
  }

  public setTimeEnd(date: Date): Date {
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    return date;
  }

  public handleSelectedAnalysis(analysisItemIndex: number) {
    this.selectedTabIndex = analysisItemIndex;
    this.visibleAnalysisHeaderKey += 1;
    this.individualAnalysisKey += 1;
  }

  public handleCompressedAnalysisClick(compressedAnalysisIndex: number) {
    const selectedCompressedAnalysis: AuditReportAnalysis = this
      .dirtyTotalAnalysis[this.displayTab + compressedAnalysisIndex];
    this.dirtyTotalAnalysis.splice(
      this.displayTab + compressedAnalysisIndex,
      1,
    );
    this.dirtyTotalAnalysis.unshift(selectedCompressedAnalysis);
    this.visibleAnalysisHeaderKey += 1;
    this.compressedAnalysisHeaderKey += 1;
    this.individualAnalysisKey += 1;
    this.selectedTabIndex = 0;
  }

  public handleAnalysisFilterUpdate(newValue: AuditReportFiltersConfig) {
    this.dirtyTotalAnalysis[
      this.selectedTabIndex
    ].filtersConfiguration = _cloneDeep(newValue);
    const matchedIndex = this.analysisToExport.findIndex(
      (analysis: AuditReportAnalysis) => {
        return (
          analysis.analysisId ===
          this.dirtyTotalAnalysis[this.selectedTabIndex].analysisId
        );
      },
    );
    if (matchedIndex !== -1) {
      this.analysisToExport[matchedIndex] = _cloneDeep(
        this.dirtyTotalAnalysis[this.selectedTabIndex],
      );
    }
  }

  public handleAnalysisIdUpdate(newValue: number) {
    this.dirtyTotalAnalysis[this.selectedTabIndex].analysisId = newValue;
    if (this.dirtyTotalAnalysis.length === 1) {
      this.analysisToExport = _cloneDeep(this.dirtyTotalAnalysis);
    } else {
      const matchedIndex = this.analysisToExport.findIndex(
        (analysis: AuditReportAnalysis) => {
          return (
            analysis.title ===
            this.dirtyTotalAnalysis[this.selectedTabIndex].title
          );
        },
      );
      if (matchedIndex !== -1) {
        this.analysisToExport[matchedIndex] = _cloneDeep(
          this.dirtyTotalAnalysis[this.selectedTabIndex],
        );
      }
    }
    this.exportFilterKey += 1;
  }

  public handleAnalysisTitleUpdate(newAnalysisTitle: string) {
    this.dirtyTotalAnalysis[this.selectedTabIndex].title = newAnalysisTitle;
    const matchedIndex = this.analysisToExport.findIndex(
      (analysis: AuditReportAnalysis) => {
        return (
          analysis.analysisId ===
          this.dirtyTotalAnalysis[this.selectedTabIndex].analysisId
        );
      },
    );
    if (matchedIndex !== -1) {
      this.analysisToExport[matchedIndex] = _cloneDeep(
        this.dirtyTotalAnalysis[this.selectedTabIndex],
      );
    }
    this.visibleAnalysisHeaderKey += 1;
    this.individualAnalysisKey += 1;
  }

  public handleDeleteAnalysis() {
    this.dirtyTotalAnalysis.splice(this.selectedTabIndex, 1);
    this.analysisToExport = _cloneDeep(this.dirtyTotalAnalysis);
    this.visibleAnalysisHeaderKey += 1;
    this.showCompressedAnalysisHeader = false;
    this.individualAnalysisKey += 1;
    this.exportFilterKey += 1;
    this.selectedTabIndex = 0;
    if (this.dirtyTotalAnalysis.length === 0) {
      this.showIndividualAnalysis = false;
    }
    if (this.dirtyTotalAnalysis.length > this.displayTab) {
      this.showCompressedAnalysisHeader = true;
      this.compressedAnalysisHeaderKey += 1;
    }
  }

  get getAllSiteIds(): number[] {
    const siteIdList: number[] = [];
    _map(this.auditSites, (auditSite: AuditSite) => {
      siteIdList.push(auditSite.id);
    });
    return siteIdList;
  }

  get getAllCriteriaIds(): number[] {
    const criteriaIdList: number[] = [];
    _map(this.auditCriteria, (auditCriteria: AuditCriteria) => {
      criteriaIdList.push(auditCriteria.id);
    });
    return criteriaIdList;
  }

  get getAnalysisDetail(): AuditReportAnalysis {
    return this.dirtyTotalAnalysis[this.selectedTabIndex];
  }

  get getVisibleAnalysis(): AuditReportAnalysis[] {
    return this.dirtyTotalAnalysis.slice(0, this.displayTab);
  }

  get getCompressedAnalysis(): AuditReportAnalysis[] {
    return this.dirtyTotalAnalysis.slice(this.displayTab);
  }

  get displayTab(): number {
    const tab = Math.floor((this.analysisTabContainerWidth - 220) / 150);
    if (tab === -1 || tab === 0) {
      return 1;
    }
    return tab;
  }

  get getTotalAnalysis(): AuditReportAnalysis[] {
    return this.dirtyTotalAnalysis;
  }

  get getAuditId(): number {
    return +this.$route.params.auditId;
  }

  get getProjectId(): number {
    return this.auditDetail.project.id;
  }

  get auditSitelist(): AuditSite[] {
    return this.auditSites;
  }

  get auditCriteriaList(): AuditCriteria[] {
    return this.auditCriteria;
  }
}
