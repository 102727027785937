





































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import { Action, State } from 'vuex-class';
import HeroBar from '@/components/base/HeroBar.vue';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import {
  AuditDetail,
  AuditSamplingType,
  AuditSamplingSite,
  ConsecutiveSamplingMode,
  DataCollectionSummaryRequestPayload,
  MultiSitePayload,
  MultiSiteSamplingRequestPayload,
  AuditSite,
  AuditSamplingResponse,
  AuditSamplingDataConfig,
} from '@/store/modules/audits/types/audits.types';
import {
  map as _map,
  round as _round,
  cloneDeep as _cloneDeep,
  orderBy as _orderBy,
  findIndex as _findIndex,
  find as _find,
} from 'lodash';
import { ToastProgrammatic as Toast } from 'buefy';
import SamplingCalculator from '@/views/Audit/components/SamplingCalculator.vue';
import ConsecutiveCalculator from '@/views/Audit/components/ConsecutiveCalculator.vue';
import ConfirmationDialogue from '@/components/ConfirmationDialogue.vue';

@Component({
  components: {
    ConsecutiveCalculator,
    SamplingCalculator,
    DefaultLayout,
    HeroBar,
  },
})
export default class AdHocMultiSiteSampling extends Vue {
  @Prop()
  public auditId!: number;
  @Prop()
  public auditSiteList!: AuditSite[];
  @Prop()
  public auditConfig!: AuditSamplingResponse;
  @Prop()
  public auditSamplingType!: AuditSamplingType;
  @Prop()
  public auditDataCollectionDate!: string;
  @Prop({ default: true })
  public editable!: boolean;
  public multiSitePayload: MultiSitePayload[] = [];
  public siteArray: AuditSamplingSite[] = [];
  public renderComponent: boolean = true;
  public criteriaSiteIndex: any = [];

  @Action('audits/updateMultiSiteSampling')
  public updateMultiSiteSampling!: (
    payload: MultiSiteSamplingRequestPayload,
  ) => void;

  @State((state) => state.audits.updatedMultiSiteSampling)
  public updatedMultiSiteSampling!: any;

  @Watch('updatedMultiSiteSampling')
  @isTruthy
  public watchUpdatedMultiSiteSampling() {
    this.showNotificationAndRedirectToProject();
  }

  public mounted() {
    this.populateMultiSiteData();
  }

  public populateMultiSiteData() {
    this.auditSiteList.map((auditSite: AuditSite) => {
      const siteObject: AuditSamplingSite = {
        siteId: auditSite.site.id,
        name: auditSite.site.name,
        isSamplingEnabled: false,
        auditSiteId: auditSite.id,
      };
      this.siteArray.push(siteObject);
    });

    this.auditConfig.dataConfigs.forEach(
      (dataConfig: AuditSamplingDataConfig) => {
        const currentCriteriaPayload: MultiSitePayload = {
          auditId: this.auditId,
          criteriaId: 0, // todo: change this logic later
          auditCriteriaId: 0,
          sites: [],
          sampleSize: 0,
        };
        Object.values(dataConfig).forEach(
          (singleConfig: AuditSamplingDataConfig) => {
            if (singleConfig.auditCriteriaMap.isActive) {
              currentCriteriaPayload.criteriaId =
                singleConfig.auditCriteriaMap.criteria.id;
              currentCriteriaPayload.auditId = this.auditId;
              currentCriteriaPayload.auditCriteriaId =
                singleConfig.auditCriteriaMap.id;
              currentCriteriaPayload.criteriaTitle =
                singleConfig.auditCriteriaMap.criteria.title;
              currentCriteriaPayload.order =
                singleConfig.auditCriteriaMap.order;
              if (singleConfig.auditSiteMap === null) {
                currentCriteriaPayload.isSamplingEnabled =
                  singleConfig.isSamplingEnabled;
                currentCriteriaPayload.dataConfig = singleConfig;
              } else {
                const auditSamplingConfig = this.siteArray.find(
                  (value: AuditSamplingSite) => {
                    return value.auditSiteId === singleConfig.auditSiteMap.id;
                  },
                );
                if (auditSamplingConfig) {
                  currentCriteriaPayload.sites.push({
                    ...auditSamplingConfig,
                    isSamplingEnabled: singleConfig.isSamplingEnabled,
                    dataConfig: singleConfig,
                  });
                  currentCriteriaPayload.sites.sort((itemA, itemB) => {
                    return itemA.siteId - itemB.siteId;
                  });
                  const filteredCriteria: any = [];
                  currentCriteriaPayload.sites.map((data) =>
                    filteredCriteria.filter(
                      (item: any) => item.siteId === data.siteId,
                    ).length > 0
                      ? null
                      : filteredCriteria.push(data),
                  );
                  currentCriteriaPayload.sites = filteredCriteria;
                }
              }
            }
          },
        );
        this.multiSitePayload.push(currentCriteriaPayload);
      },
    );
    this.multiSitePayload = this.multiSitePayload.map(
      (criteria: MultiSitePayload) => {
        const sitesIdsToCheckAgainst: number[] = this.siteArray.map(
          (site: AuditSamplingSite) => site.siteId,
        );
        return {
          ...criteria,
          sites: criteria.sites.sort(
            (optionA: AuditSamplingSite, optionB: AuditSamplingSite) => {
              if (
                sitesIdsToCheckAgainst.indexOf(optionA.siteId) >
                sitesIdsToCheckAgainst.indexOf(optionB.siteId)
              ) {
                return 1;
              } else {
                return -1;
              }
            },
          ),
        };
      },
    );
  }

  public showNotificationAndRedirectToProject() {
    Toast.open({
      message: `Changes saved`,
      position: 'is-top',
      type: 'is-dark',
      duration: 3500,
    });
  }

  public handleMultiSiteChanges() {
    this.updateMultiSiteSampling({
      auditId: this.auditId,
      multiSitePayload: this.multiSitePayload,
      auditSamplingType: this.auditSamplingType,
      samplingConfigId: this.auditConfig.auditSamplingConfigs.id,
    });
  }

  public updateChildSampling(index: number) {
    if (this.auditCriteriaList[index].isSamplingEnabled) {
      this.auditCriteriaList[index].sites.forEach((siteData) => {
        siteData.isSamplingEnabled = true;
      });
    } else {
      if (
        this.auditDataCollectionDate !== undefined &&
        this.auditDataCollectionDate !== null
      ) {
        this.$buefy.modal.open({
          parent: this,
          component: ConfirmationDialogue,
          hasModalCard: true,
          trapFocus: true,
          onCancel: () => {
            this.auditCriteriaList[index].isSamplingEnabled = true;
            this.auditCriteriaList[index].sites.forEach((siteData) => {
              siteData.isSamplingEnabled = true;
            });
          },
          props: {
            modalTitle: 'Disable Sampling?',
            confirmationText: `Disable Sampling will cease data collection
                            and delete collected data for this data collection period criteria.`,
            confirmationButtonText: 'Disable',
            cancelButtonText: 'Cancel',
          },
          events: {
            confirm: () => {
              this.auditCriteriaList[index].isSamplingEnabled = false;
              this.auditCriteriaList[index].sites.forEach((siteData) => {
                siteData.isSamplingEnabled = false;
              });
            },
            cancel: () => {
              this.auditCriteriaList[index].isSamplingEnabled = true;
              this.auditCriteriaList[index].sites.forEach((siteData) => {
                siteData.isSamplingEnabled = true;
              });
            },
          },
        });
      } else {
        this.auditCriteriaList[index].sites.forEach((siteData) => {
          siteData.isSamplingEnabled = false;
        });
      }
    }
  }

  public updateParentSampling(index: number, siteIndex: number) {
    let parentVal: boolean = true;
    if (
      this.auditDataCollectionDate !== undefined &&
      this.auditDataCollectionDate !== null &&
      !this.auditCriteriaList[index].sites[siteIndex].isSamplingEnabled
    ) {
      this.$buefy.modal.open({
        parent: this,
        component: ConfirmationDialogue,
        hasModalCard: true,
        trapFocus: true,
        onCancel: () => {
          this.auditCriteriaList[index].sites[
            siteIndex
          ].isSamplingEnabled = true;
          this.auditCriteriaList[index].sites.forEach((siteData) => {
            if (siteData.isSamplingEnabled === false) {
              parentVal = false;
            }
          });

          this.auditCriteriaList[index].isSamplingEnabled = parentVal;
        },
        props: {
          modalTitle: 'Disable Sampling?',
          confirmationText: `Disable Sampling will cease data collection
                            and delete collected data for this data collection period criteria.`,
          confirmationButtonText: 'Disable',
          cancelButtonText: 'Cancel',
        },
        events: {
          confirm: () => {
            this.auditCriteriaList[index].sites[
              siteIndex
            ].isSamplingEnabled = false;
            this.auditCriteriaList[index].sites.forEach((siteData) => {
              if (siteData.isSamplingEnabled === false) {
                parentVal = false;
              }
            });

            this.auditCriteriaList[index].isSamplingEnabled = parentVal;
          },
          cancel: () => {
            this.auditCriteriaList[index].sites[
              siteIndex
            ].isSamplingEnabled = true;
            this.auditCriteriaList[index].sites.forEach((siteData) => {
              if (siteData.isSamplingEnabled === false) {
                parentVal = false;
              }
            });

            this.auditCriteriaList[index].isSamplingEnabled = parentVal;
          },
        },
      });
    } else {
      this.auditCriteriaList[index].sites.forEach((siteData) => {
        if (siteData.isSamplingEnabled === false) {
          parentVal = false;
        }
      });

      this.auditCriteriaList[index].isSamplingEnabled = parentVal;
    }
  }

  get auditCriteriaList() {
    return _orderBy(this.multiSitePayload, ['order'], ['asc']);
  }
}
