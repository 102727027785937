var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoading && _vm.showAuditPage)?_c('DefaultLayout',[[_c('AuditHeader',{attrs:{"auditDetail":_vm.auditDetail,"auditSettingInfo":_vm.auditSettingInfo,"dataCollectorRole":true}}),_c('br'),(_vm.isDataCollectionArchived)?_c('article',{staticClass:"tile notification bg-primary-light p1"},[_c('small',[_vm._v(_vm._s(_vm.message))])]):_vm._e(),_c('b-tabs',{class:_vm.$style.tabLabel,attrs:{"animated":false,"destroy-on-hide":true},on:{"change":_vm.tabChange},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('b-tab-item',{class:_vm.$style.tabItem,attrs:{"label":"DATA COLLECTION SHEET"}},[(
            _vm.showDataCollectionSheet &&
            _vm.isDataCollectionActive &&
            !_vm.isDataCollectionArchived &&
            _vm.isDisplayDataCollection
          )?_c('DataCollectionSheet',{key:_vm.dataCollectionSheetTabKey,attrs:{"auditDetail":_vm.auditDetail,"auditId":_vm.auditId},on:{"refreshDataCollectionSheet":_vm.refreshDataCollectionSheet}}):_vm._e(),(!_vm.isDataCollectionActive || _vm.isDataCollectionArchived)?_c('Card',{class:_vm.$style.dataCollectionInactive},[_c('div',[_c('b-icon',{attrs:{"icon":"alert-circle-outline","size":"is-medium"}})],1),_c('span',{class:_vm.$style.headerText},[_vm._v("Record Submissions Disabled")]),_c('span',{class:_vm.$style.substituteText},[_vm._v("Records cannot be submitted because the project administrator has stopped data collection.")])]):(
            _vm.auditDetail.endDate && _vm.dateToday > new Date(_vm.auditDetail.endDate)
          )?_c('Card',{class:_vm.$style.dataCollectionInactive},[_c('div',[_c('b-icon',{attrs:{"icon":"alert-circle-outline","size":"is-medium"}})],1),_c('span',{class:_vm.$style.headerText},[_vm._v("Record Submissions Disabled")]),_c('span',{class:_vm.$style.substituteText},[_vm._v("Records cannot be submitted for former data collection period.")])]):_vm._e()],1),_c('b-tab-item',{class:_vm.$style.tabItem,attrs:{"label":"DATA HISTORY"}},[_c('DataHistory',{attrs:{"auditDetail":_vm.auditDetail,"auditId":_vm.auditId}})],1),(_vm.checkGripStatus)?_c('b-tab-item',{class:_vm.$style.tabItem,attrs:{"label":"GRIP"}},[_c('GripTab',{class:_vm.$style.gripTab,attrs:{"isDataCollector":true,"projectId":_vm.auditDetail.project.id,"projectDetail":_vm.auditDetail.project}})],1):_vm._e()],1)]],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }