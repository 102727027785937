
























import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import {cloneDeep as _cloneDeep} from 'lodash';
import {AuditReportAnalysis} from '@/store/modules/audits/types/audits.types';

@Component({})
export default class AnalysisNavigationTabs extends Vue {
    @Prop()
    public visibleAnalysis!: AuditReportAnalysis[];
    @Prop()
    public selectedTabIndex!: number;

    public dirtyVisibleAnalysis: AuditReportAnalysis[] = [];
    public dirtySelectedTabIndex: number = 0;

    public mounted() {
        this.dirtyVisibleAnalysis = _cloneDeep(this.visibleAnalysis);
        this.dirtySelectedTabIndex = this.selectedTabIndex;
    }

    public selectAnalysis(analysisItemIndex: number) {
        this.$emit('selectAnalysis', analysisItemIndex);
    }

    public getAnalysisTitle(analysisTitle: string): string {
        if (analysisTitle.length > 14) {
            return `${analysisTitle.substring(0, 12)} ...`.toUpperCase();
        }
        return analysisTitle.toUpperCase();
    }
}
