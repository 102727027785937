






























































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { cloneDeep, cloneDeep as _cloneDeep } from 'lodash';
import Slider from '@vueform/slider/dist/slider.vue2.js';
import { isDifferent } from '@/jbi-shared/util/watcher.vue-decorator';
import {
  DataCollectionCriteriaDataPayload,
  RangeOptionValueType,
  RangeErrorValidation,
} from '@/store/modules/audits/types/audits.types';

@Component({
  components: {
    Slider,
  },
})
export default class DataCollectionContinuousDiscreteCriteria extends Vue {
  @Prop()
  public index!: number;

  @Prop()
  public criteriaOption!: any;

  @Prop()
  public resultData!: any;

  @Prop()
  public totalToDateMessage!: boolean;

  @Prop()
  public editDataCollectionRecord!: any;

  @Prop()
  public criteriaSamplingData!: any;

  @Prop()
  public mode!: string;

  @Prop()
  public tryToSubmitKey!: number;

  public optionInput: string | number = '';
  public optionTotalToDate: number = 0;
  public optionCount: number = 0;
  public options: any[] = [];
  public isRange: boolean = false;
  public optionInputIndividual: string | number = '';
  public optionCountIndividual: number = 1;
  public setForNaOptionIndividualMode: boolean = false;
  public sliderValue: string | number = '';
  public tempSliderValue: number | string = '';
  public showRangeErrorMessage: boolean = false;
  public answerValueDetail: any[] = [];
  public existingOptions: any[] = [];
  public showValueErrorMessage: boolean = false;
  public countValue: number = 0;
  public showCountErrorMessage: boolean = false;
  public hasSelectedNA: boolean = false;
  public validateValueError: RangeErrorValidation[] = [];
  public removedAnswerValueDetail: RangeOptionValueType[] = [];
  public showOptionCountError: boolean = false;
  public invalidFloatErrorMessage: string[] = [];
  public invalidOptionInputErrorMessage: string = '';
  public invalidSliderErrorMessage: string = '';
  public invalidIndividualInputErrorMessage: string = '';

  public parseFloat(value: any): number {
    return parseFloat(value.toString());
  }

  public mounted() {
    this.isRange = this.criteriaOption.criteria.config ? true : false;
    this.answerValueDetail = this.criteriaOption.optionValues;
    this.options = this.criteriaOption.optionValues;
    this.existingOptions = [...this.criteriaOption.optionValues];

    const indexOfExistingNA = this.answerValueDetail
      .map((optionValue) => optionValue.option)
      .indexOf('N/A');

    const naOptionValue: RangeOptionValueType | undefined = {
      option: 'N/A',
      totalToDate:
        indexOfExistingNA >= 0
          ? this.answerValueDetail[indexOfExistingNA].totalToDate
          : 0,
      value:
        indexOfExistingNA >= 0
          ? this.answerValueDetail[indexOfExistingNA].value
          : 0,
    };
    if (indexOfExistingNA >= 0) {
      this.answerValueDetail.splice(indexOfExistingNA, 1);
    }
    this.answerValueDetail.push(naOptionValue as RangeOptionValueType); // to ensure NA option is always the last item
    this.answerValueDetail.forEach((data: RangeOptionValueType) => {
      this.validateValueError.push({
        option: data.option,
        hasError: false,
      });
    });
    this.options = this.answerValueDetail;

    if (this.editDataCollectionRecord) {
      if (this.mode === 'aggregate') {
        this.sliderValue = this.minValue;
      } else {
        if (this.isRange) {
          const editedRangeValue = this.answerValueDetail.find(
            (data: RangeOptionValueType) => Number(data.value) === 1,
          );
          if (editedRangeValue && editedRangeValue.option === 'N/A') {
            this.hasSelectedNA = true;
            this.sliderValue = this.minValue;
          } else {
            this.sliderValue =
              editedRangeValue && editedRangeValue.option
                ? editedRangeValue.option
                : '';
          }
        } else {
          const editedOptionValue = this.options.find(
            (data: RangeOptionValueType) => Number(data.value) === 1,
          );
          if (editedOptionValue && editedOptionValue.option === 'N/A') {
            this.optionInputIndividual = 'N/A';
            this.setForNaOptionIndividualMode = true;
          } else {
            this.optionInputIndividual =
              editedOptionValue && editedOptionValue.option
                ? editedOptionValue.option
                : '';
          }
        }
      }
    } else {
      if (this.mode === 'aggregate') {
        this.sliderValue = this.minValue;
      }
    }
  }

  public handleRemoveOption(item: any, optionsDataIndex: number) {
    this.removedAnswerValueDetail.push(this.options[optionsDataIndex]);
    this.options.splice(optionsDataIndex, 1);
    this.invalidFloatErrorMessage.splice(optionsDataIndex, 1);

    this.validateValueError.find((element: RangeErrorValidation) => {
      if (element.option === item.option) {
        element.hasError = false;
      }
    });

    this.resultData.criteriaData.forEach(
      (data: DataCollectionCriteriaDataPayload) => {
        if (data.criteria.id === this.criteriaOption.criteria.id) {
          data.optionValues = this.options;
        }
      },
    );
  }

  public handleAddCountValue() {
    if (this.optionInput === '') {
      return;
    }
    const existingInputIndex: number = this.getExistingInputIndex();
    if (existingInputIndex !== -1) {
      this.options[existingInputIndex].value =
        Number(this.options[existingInputIndex].value) +
        Number(this.optionCount);
      this.resetInputParams();
      return;
    }
    this.options.unshift({
      option: this.parseFloat(this.optionInput).toString(),
      totalToDate: this.totalToDateValue,
      value: this.optionCount,
    });
    this.resetInputParams();
  }

  public getExistingInputIndex() {
    return this.options.findIndex((option: any) => {
      return (
        this.parseFloat(option.option) === this.parseFloat(this.optionInput)
      );
    });
  }

  public resetInputParams() {
    this.optionInput = '';
    this.optionCount = 0;
  }

  public getOptionsTotal() {
    let count: number = 0;
    this.options.forEach((option: any) => {
      count = count + Number(option.value);
    });
    return count;
  }

  @Watch('options', { deep: true })
  public watchOptions(newValue: any) {
    this.resultData.criteriaData.forEach((criteriaData: any) => {
      if (criteriaData.criteria.id === this.criteriaOption.criteria.id) {
        criteriaData.optionValues = cloneDeep(this.options);
        criteriaData.optionTotal = this.getOptionsTotal();
      }
    });
  }

  @Watch('setForNaOptionIndividualMode')
  public watchSetForNaOptionIndividualMode(newValue: boolean) {
    if (newValue) {
      this.optionInputIndividual = 'N/A';
      this.setForNaOptionIndividualMode = true;
    } else {
      this.optionInputIndividual = '';
      this.setForNaOptionIndividualMode = false;
    }
    this.handleIndividualInputOption();
  }

  public handleIndividualInputOption() {
    if (this.optionInputIndividual === 'N/A') {
      this.optionInputIndividual = this.optionInputIndividual;
      this.invalidIndividualInputErrorMessage = '';
      this.$emit('updateSubmitButton', {
        index: this.index,
        isError: false,
      });
    } else {
      this.optionInputIndividual = !isNaN(
        this.parseFloat(this.optionInputIndividual),
      )
        ? this.parseFloat(this.optionInputIndividual).toString()
        : this.optionInputIndividual;

      this.invalidIndividualInputErrorMessage = this.optionInputIndividual
        ? !isNaN(this.parseFloat(this.optionInputIndividual))
          ? ''
          : 'Invalid float value'
        : 'Value must not be empty';

      this.$emit('updateSubmitButton', {
        index: this.index,
        isError: isNaN(this.parseFloat(this.optionInputIndividual)),
      });
    }

    if (this.optionInputIndividual === '') {
      this.resultData.criteriaData.forEach((criteriaData: any) => {
        if (criteriaData.criteria.id === this.criteriaOption.criteria.id) {
          criteriaData.optionValues = this.options;
        }
      });
      return;
    }

    const existingOption = this.existingOptions.find((options: any) => {
      return options.option === this.optionInputIndividual;
    });

    if (this.options[this.options.length - 1].option !== 'N/A') {
      this.options.pop();
    }

    this.options.map((item: any) => {
      if (this.optionInputIndividual === item.option) {
        item.value = 1;
      } else {
        item.value = 0;
      }
    });
    if (!existingOption) {
      this.options.push({
        option: this.optionInputIndividual,
        totalToDate: 0,
        value: 1,
      });
    }

    this.resultData.criteriaData.forEach((element: any) => {
      if (element.criteria.id === this.criteriaOption.criteria.id) {
        element.optionValues = this.options;
      }
    });
  }

  get minValue(): number {
    if (this.criteriaOption.criteria.config) {
      return Number(this.criteriaOption.criteria.config.split(',')[0].trim());
    }
    return 1;
  }

  get maxValue(): number {
    if (this.criteriaOption.criteria.config) {
      return Number(this.criteriaOption.criteria.config.split(',')[1].trim());
    }
    return 10;
  }

  public formatSliderTooltip(value: number) {
    return value.toFixed(2);
  }

  get totalToDateRangeValue(): number {
    if (this.sliderValue === '' || !this.parseFloat(this.sliderValue)) {
      this.sliderValue = this.sliderValue;
    } else {
      this.sliderValue = this.parseFloat(this.sliderValue).toString();
    }

    if (this.editDataCollectionRecord && this.criteriaSamplingData) {
      const existingTotalToDateRangeValue = this.existingOptions.find(
        (data: RangeOptionValueType) => {
          if (this.hasSelectedNA) {
            return data.option === 'N/A';
          } else {
            return data.option === this.sliderValue;
          }
        },
      );
      return existingTotalToDateRangeValue
        ? existingTotalToDateRangeValue.totalToDate
        : 0;
    } else {
      const existingOptionValue = this.existingOptions.find(
        (data: RangeOptionValueType) => {
          if (this.hasSelectedNA) {
            return data.option === 'N/A';
          } else {
            return data.option === this.sliderValue;
          }
        },
      );
      const existingTotalToDateRangeValue = this.removedAnswerValueDetail.find(
        (data: RangeOptionValueType) => {
          if (this.hasSelectedNA) {
            return data.option === 'N/A';
          } else {
            return data.option === this.sliderValue;
          }
        },
      );

      return existingOptionValue
        ? existingOptionValue.totalToDate
        : existingTotalToDateRangeValue
        ? existingTotalToDateRangeValue.totalToDate
        : 0;
    }
  }

  get totalToDateValue(): number {
    const optionToCheck =
      this.mode === 'aggregate'
        ? this.parseFloat(this.optionInput)
        : this.parseFloat(this.optionInputIndividual);
    const existingOptionValue = this.existingOptions.find(
      (data: RangeOptionValueType) => {
        if (this.setForNaOptionIndividualMode) {
          return data.option === 'N/A';
        } else {
          return this.parseFloat(data.option) === optionToCheck;
        }
      },
    );
    let existingTotalToDateValue = this.removedAnswerValueDetail.find(
      (data: RangeOptionValueType) => {
        if (this.setForNaOptionIndividualMode) {
          return data.option === 'N/A';
        } else {
          return this.parseFloat(data.option) === optionToCheck;
        }
      },
    );
    if (this.criteriaSamplingData && !existingTotalToDateValue) {
      existingTotalToDateValue = this.criteriaSamplingData.find(
        (data: RangeOptionValueType) => {
          if (this.setForNaOptionIndividualMode) {
            return data.option === 'N/A';
          } else {
            return this.parseFloat(data.option) === optionToCheck;
          }
        },
      );
    }

    return existingOptionValue
      ? existingOptionValue.totalToDate
      : existingTotalToDateValue
      ? existingTotalToDateValue.totalToDate
      : 0;
  }

  public handleAddCountValueRange(value: number) {
    let optionIndex = 0;
    const existingOptionValue = this.criteriaOption.optionValues.find(
      (data: any, index: number) => {
        if (data.option === this.parseFloat(this.sliderValue).toString()) {
          optionIndex = index;
          return data;
        }
      },
    );

    if (existingOptionValue) {
      this.answerValueDetail[optionIndex].value =
        Number(existingOptionValue.value) + Number(value);
    } else {
      this.answerValueDetail.unshift({
        option: this.parseFloat(this.sliderValue).toString(),
        totalToDate: this.totalToDateRangeValue,
        value: Number(value),
      });
    }

    this.countValue = 0;

    this.resultData.criteriaData.forEach((data: any) => {
      if (data.criteria.id === this.criteriaOption.criteria.id) {
        data.optionValues = this.answerValueDetail;
      }
    });
  }

  public validateOptionsDataInputError(index: number): string | null {
    if (
      this.options[index].option === '' ||
      this.options[index].option === '-' ||
      (this.invalidFloatErrorMessage[index] &&
        this.invalidFloatErrorMessage[index].length)
    ) {
      return this.invalidFloatErrorMessage[index] &&
        this.invalidFloatErrorMessage[index].length
        ? this.invalidFloatErrorMessage[index]
        : 'Value must not be empty';
    } else {
      return null;
    }
  }

  public validateOptionsDataInput(index: number) {
    if (this.options[index].option) {
      const optionDataInput: number = this.parseFloat(
        this.options[index].option,
      );

      this.options[index].option = !isNaN(optionDataInput)
        ? optionDataInput
        : '';

      this.invalidFloatErrorMessage[index] = !isNaN(optionDataInput)
        ? ''
        : 'Invalid float value';

      const existingOptionInputIndex: number = this.options.find(
        (option: any, existingIndex: number) => {
          return (
            this.parseFloat(option.option) === optionDataInput &&
            existingIndex !== index
          );
        },
      );
      if (existingOptionInputIndex) {
        this.invalidFloatErrorMessage[index] = 'This Value already exists';
      }

      let existingTotalToDateValue: any = this.removedAnswerValueDetail.find(
        (data: RangeOptionValueType) => {
          if (this.parseFloat(data.option) === optionDataInput) {
            return data;
          }
        },
      );
      if (this.criteriaSamplingData && !existingTotalToDateValue) {
        existingTotalToDateValue = this.criteriaSamplingData.find(
          (data: RangeOptionValueType) => {
            if (this.parseFloat(data.option) === optionDataInput) {
              return data;
            }
          },
        );
      }

      this.options[index].totalToDate = existingTotalToDateValue
        ? existingTotalToDateValue.totalToDate
        : 0;

      this.$emit('updateSubmitButton', {
        index: this.index,
        isError: this.invalidFloatErrorMessage[index].length ? true : false,
      });
    } else {
      this.options[index].totalToDate = 0;
      this.invalidFloatErrorMessage[index] = '';
      this.$emit('updateSubmitButton', {
        index: this.index,
        isError: true,
      });
    }
  }

  public validateOptionsDataValue(index: number) {
    if (!this.options[index].value) {
      this.$emit('updateSubmitButton', {
        index: this.index,
        isError: true,
      });
    } else {
      this.$emit('updateSubmitButton', {
        index: this.index,
        isError: false,
      });
    }
  }

  public validateInput(optionInput: string) {
    this.invalidOptionInputErrorMessage =
      optionInput && isNaN(this.parseFloat(optionInput))
        ? 'Invalid float value'
        : '';

    this.showOptionCountError = isNaN(this.parseFloat(optionInput));
  }

  public updateRangeValue(data: RangeOptionValueType) {
    this.showValueErrorMessage = data.value ? false : true;

    this.validateRangeValue(data.option);

    if (!this.showValueErrorMessage) {
      this.answerValueDetail.find((item: RangeOptionValueType) => {
        if (item.option === data.option) {
          return (item.value = data.value);
        }
      });
      this.resultData.criteriaData.forEach(
        (element: DataCollectionCriteriaDataPayload) => {
          if (element.criteria.id === this.criteriaOption.criteria.id) {
            element.optionValues = this.answerValueDetail;
          }
        },
      );
    }

    this.$emit('updateSubmitButton', {
      index: this.index,
      isError: this.showValueErrorMessage,
    });
  }

  public validateRangeValue(option: number | string) {
    const hasValueValidateError = this.validateValueError.find(
      (element: RangeErrorValidation) => element.option === option,
    );

    if (hasValueValidateError) {
      this.validateValueError.find((element: RangeErrorValidation) => {
        if (element.option === hasValueValidateError.option) {
          element.hasError = this.showValueErrorMessage;
        }
      });
    } else {
      this.validateValueError.push({
        option,
        hasError: this.showValueErrorMessage,
      });
    }

    this.$emit('updateSubmitButton', {
      index: this.index,
      isError: this.showValueErrorMessage,
    });
  }

  public handleRemoveRangeValue(
    item: RangeOptionValueType,
    rangeIndex: number,
  ) {
    this.removedAnswerValueDetail.push(this.answerValueDetail[rangeIndex]);
    this.answerValueDetail.splice(rangeIndex, 1);

    this.validateValueError.find((element: RangeErrorValidation) => {
      if (element.option === item.option) {
        element.hasError = false;
      }
    });

    this.resultData.criteriaData.forEach(
      (data: DataCollectionCriteriaDataPayload) => {
        if (data.criteria.id === this.criteriaOption.criteria.id) {
          data.optionValues = this.answerValueDetail;
        }
      },
    );
  }

  get disableTooltipSlider(): boolean {
    return this.hasSelectedNA ? false : true;
  }

  public showCommentBox(rowData: DataCollectionCriteriaDataPayload) {
    rowData.isCommentSectionExpanded = !rowData.isCommentSectionExpanded;
  }

  public handleMarkedNAValue() {
    this.hasSelectedNA = false;
    this.sliderValue = this.tempSliderValue
      ? this.tempSliderValue
      : this.minValue;
    if (
      this.answerValueDetail[this.answerValueDetail.length - 1].option !== 'N/A'
    ) {
      this.answerValueDetail.pop();
    }
    this.answerValueDetail.map((item: any) => {
      if (item.option === this.minValue.toString()) {
        item.value = 1;
      } else {
        item.value = 0;
      }
    });
    this.resultData.criteriaData.forEach((element: any) => {
      if (element.criteria.id === this.criteriaOption.criteria.id) {
        element.optionValues = this.answerValueDetail;
      }
    });
  }

  public handleUnmarkedNAValue() {
    this.hasSelectedNA = true;
    this.tempSliderValue = this.sliderValue;
    this.sliderValue = this.minValue;
    this.showRangeErrorMessage = false;
    this.invalidSliderErrorMessage = '';
    this.$emit('updateSubmitButton', {
      index: this.index,
      isError: false,
    });

    if (
      this.answerValueDetail[this.answerValueDetail.length - 1].option !== 'N/A'
    ) {
      this.answerValueDetail.pop();
    }
    this.answerValueDetail.map((item: any) => {
      if (item.option === 'N/A') {
        item.value = 1;
      } else {
        item.value = 0;
      }
    });
    this.resultData.criteriaData.forEach((element: any) => {
      if (element.criteria.id === this.criteriaOption.criteria.id) {
        element.optionValues = this.answerValueDetail;
      }
    });
  }

  @Watch('sliderValue', { deep: true })
  @isDifferent
  public watchSliderValue(newValue: any) {
    newValue = newValue ? this.parseFloat(newValue).toString() : newValue;
    if (this.mode === 'aggregate') {
      this.showRangeErrorMessage =
        newValue && isNaN(newValue)
          ? true
          : newValue < this.minValue || newValue > this.maxValue
          ? true
          : false;

      this.invalidSliderErrorMessage =
        newValue && isNaN(newValue) ? 'Invalid float value' : '';

      this.$emit('updateSubmitButton', {
        index: this.index,
        isError: this.showRangeErrorMessage,
      });
    } else {
      if (this.hasSelectedNA && Number(newValue) === this.minValue) {
        return;
      }

      this.hasSelectedNA = false;

      this.showRangeErrorMessage =
        newValue && isNaN(newValue)
          ? true
          : newValue < this.minValue || newValue > this.maxValue
          ? true
          : false;

      this.invalidSliderErrorMessage =
        newValue && isNaN(newValue) ? 'Invalid float value' : '';
      this.$emit('updateSubmitButton', {
        index: this.index,
        isError: this.showRangeErrorMessage,
      });

      if (!this.showValueErrorMessage) {
        const existingRangeValue = this.existingOptions.find(
          (data: any) => data.option === newValue.toString(),
        );
        if (
          this.answerValueDetail[this.answerValueDetail.length - 1].option !==
          'N/A'
        ) {
          this.answerValueDetail.pop();
        }
        this.answerValueDetail.map((item: any) => {
          if (newValue === item.option) {
            item.value = 1;
          } else {
            item.value = 0;
          }
        });
        if (!existingRangeValue) {
          this.answerValueDetail.push({
            option: newValue,
            totalToDate: 0,
            value: 1,
          });
        }

        this.resultData.criteriaData.forEach((element: any) => {
          if (element.criteria.id === this.criteriaOption.criteria.id) {
            element.optionValues = this.answerValueDetail;
          }
        });
      }
    }
  }

  @Watch('countValue')
  public watchCountValue(newValue: number) {
    this.showCountErrorMessage = newValue || newValue === 0 ? false : true;
    if (!this.showRangeErrorMessage) {
      this.$emit('updateSubmitButton', {
        index: this.index,
        isError: this.showCountErrorMessage,
      });
    }
  }

  @Watch('tryToSubmitKey')
  public watchSubmitAction() {
    if (
      (this.isRange && this.sliderValue === '') ||
      (!this.isRange &&
        this.optionInputIndividual === '' &&
        !this.setForNaOptionIndividualMode)
    ) {
      this.showRangeErrorMessage = true;
      if (!this.isRange) {
        this.invalidIndividualInputErrorMessage = 'Value must not be empty';
      }
      this.$emit('updateSubmitButton', {
        index: this.index,
        isError: this.showRangeErrorMessage,
      });
    }
  }
}
