import { SiteState } from './types/site.types';
import {defaultApiState} from '@/store/types/general.types';

export const siteState: SiteState = {
  apiState: {
    editSite: defaultApiState,
    deleteSite: defaultApiState,
    addSite: defaultApiState,
    getSite: defaultApiState,
  },
  sites: undefined,
};
