

























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { monthsList } from '../../../store/types/general.types';
import {
  Grip,
  GripDTO,
  UpdateGripPayload,
} from '../../../store/types/grip.types';

@Component({
  components: {},
})
export default class GripTeamDetailCard extends Vue {
  @Prop() public gripDetails!: Grip;
  @Prop() public projectId!: number;
  @Prop() public isDataCollector!: boolean;

  public errorMessage: {
    projectLead: string | null;
  } = { projectLead: null };

  public dirtyGripDetail: GripDTO = {
    projectLead: '',
    stakeholder: '',
    actionDate: new Date(),
  };

  get disableSubmitButton(): boolean {
    return !this.dirtyGripDetail.projectLead.trim() ? true : false;
  }

  public formatDateRange(date: Date): string {
    return (
      date.getDate() +
      ' ' +
      monthsList[date.getMonth()] +
      ' ' +
      date.getFullYear()
    );
  }

  public validateData(): void {
    this.errorMessage = {
      projectLead: !this.dirtyGripDetail.projectLead.trim()
        ? 'This Field is Required'
        : null,
    };
  }

  public handleSave(): void {
    if (this.gripDetails && Object.keys(this.gripDetails).length > 0) {
      const updateGrip: UpdateGripPayload = {
        id: this.gripDetails.id,
        projectId: this.gripDetails.projectId,
        ...this.dirtyGripDetail,
      };
      this.$emit('updateGrip', updateGrip);
    } else {
      this.$emit('createGrip', this.dirtyGripDetail);
    }
  }
  public mounted(): void {
    if (this.gripDetails && Object.keys(this.gripDetails).length > 0) {
      this.dirtyGripDetail.projectLead = this.gripDetails.projectLead;
      this.dirtyGripDetail.stakeholder = this.gripDetails.stakeholder;
      this.dirtyGripDetail.actionDate = new Date(this.gripDetails.actionDate);
    }
  }
}
