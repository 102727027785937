import { render, staticRenderFns } from "./DataCollectionRangeCriteria.vue?vue&type=template&id=5bc6598a&"
import script from "./DataCollectionRangeCriteria.vue?vue&type=script&lang=ts&"
export * from "./DataCollectionRangeCriteria.vue?vue&type=script&lang=ts&"
import style0 from "@vueform/slider/themes/default.css?vue&type=style&index=0&lang=css&"
import style1 from "./DataCollectionRangeCriteria.vue?vue&type=style&index=1&lang=scss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports