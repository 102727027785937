



























































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import BaseModal from '@/jbi-shared/vue-components/BaseModal.vue';
import { CplusCriteria } from '@/store/modules/projects/types/projects.types';

@Component({
  components: {
    BaseModal,
  },
})
export default class AuditCriteriaDetailModal extends Vue {
  public modalTitle: string = 'Data Collection Period Criteria Details';
  @Prop() public criteria!: CplusCriteria;
}
