




























import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { State, Action } from 'vuex-class';
import { cloneDeep as _cloneDeep } from 'lodash';
import {
  isDifferent,
  isTruthy,
  isDifferentDeep,
} from '../../../jbi-shared/util/watcher.vue-decorator';
import { RootState } from '../../../store/store';
import { get as _get } from 'lodash';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '../../../utils/date.util';
import BaseDropDown from '../../../components/base/BaseDropDown.vue';

@Component({
  components: {
    BaseDropDown,
  },
})
export default class ProjectOwnerHistory extends Vue {
  @Prop()
  public projectOwnerHistoryLog!: any;

  public originalProjectOwner: string = '';
  public dateEventOccured: Date = new Date();

  public getEventOccuredAt(info: any) {
    return dayjs(new Date(info.eventOccuredAt)).format(DATE_FORMAT);
  }
}
