































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import {
  DataCollectionSubmitRequestPayload,
  DataCollectionSummaryCriteria,
  RangeOptionValueType,
  DataCollectionCriteriaDataPayload,
} from '@/store/modules/audits/types/audits.types';
import Slider from '@vueform/slider/dist/slider.vue2.js';
import { isDifferent } from '@/jbi-shared/util/watcher.vue-decorator';
import CriteriaViewUpdateComponent from '../../Project/components/CriteriaViewUpdateComponent.vue';

@Component({
  components: {
    Slider,
    CriteriaViewUpdateComponent,
  },
})
export default class DataCollectionRangeCriteriaIndividual extends Vue {
  @Prop()
  public index!: number;

  @Prop()
  public criteriaOption!: DataCollectionCriteriaDataPayload;

  @Prop()
  public totalToDateMessage!: boolean;

  @Prop()
  public resultData!: DataCollectionSubmitRequestPayload;

  @Prop()
  public editDataCollectionRecord!: any;

  @Prop()
  public criteriaSamplingData!: any;

  @Prop()
  public tryToSubmitKey!: number;

  public sliderValue: number | string = '';
  public tempSliderValue: number | string = '';
  public showRangeErrorMessage: boolean = false;
  public showCountErrorMessage: boolean = false;
  public showValueErrorMessage: boolean = false;
  public hasSelectedNA: boolean = false;
  public isMarkedAsNA: boolean = false;
  public answerValueDetail: RangeOptionValueType[] = [];

  public mounted() {
    this.answerValueDetail = this.criteriaOption.optionValues;
    if (this.editDataCollectionRecord) {
      const editedRangeValue = this.answerValueDetail.find(
        (data: RangeOptionValueType) => Number(data.value) === 1,
      );

      if (editedRangeValue) {
        if (editedRangeValue.option === 'N/A') {
          this.hasSelectedNA = true;
          this.sliderValue = this.minValue;
        } else {
          this.sliderValue = editedRangeValue.option;
        }
      }
    }
    this.$emit('valuePopulated');
  }

  get disableTooltipSlider(): boolean {
    return this.hasSelectedNA ? false : true;
  }

  get totalToDateValue(): number {
    let totalToDate = 0;
    if (this.editDataCollectionRecord && this.criteriaSamplingData) {
      const existingTotalToDateValue = this.criteriaSamplingData.find(
        (data: RangeOptionValueType) => {
          if (this.hasSelectedNA) {
            return data.option === 'N/A';
          } else {
            return data.option === this.sliderValue.toString();
          }
        },
      );

      totalToDate = existingTotalToDateValue
        ? existingTotalToDateValue.totalToDate
        : totalToDate;
    } else {
      const existingOptionValue = this.criteriaOption.optionValues.find(
        (data: RangeOptionValueType) => {
          if (this.hasSelectedNA) {
            return data.option === 'N/A';
          } else {
            return data.option === this.sliderValue.toString();
          }
        },
      );

      totalToDate = existingOptionValue
        ? existingOptionValue.totalToDate
        : totalToDate;
    }

    return totalToDate;
  }

  get minValue(): number {
    return parseInt(
      JSON.parse(this.criteriaOption.criteria.criteriaOptions)[0],
      10,
    );
  }

  get maxValue(): number {
    return parseInt(
      JSON.parse(this.criteriaOption.criteria.criteriaOptions)[1],
      10,
    );
  }

  public showCommentBox(rowData: DataCollectionCriteriaDataPayload) {
    rowData.isCommentSectionExpanded = !rowData.isCommentSectionExpanded;
  }

  public handleUnmarkedNAValue() {
    this.hasSelectedNA = true;
    this.tempSliderValue = this.sliderValue;
    this.sliderValue = this.minValue;
    this.showRangeErrorMessage = false;

    const lastAnswerIndex = this.answerValueDetail[
      this.answerValueDetail.length - 1
    ];
    if (lastAnswerIndex.option !== 'N/A' && lastAnswerIndex.totalToDate === 0) {
      this.answerValueDetail.pop();
    }
    this.answerValueDetail.map((item: RangeOptionValueType) => {
      if (item.option === 'N/A') {
        item.value = 1;
      } else {
        item.value = 0;
      }
    });
    this.resultData.criteriaData.forEach(
      (element: DataCollectionCriteriaDataPayload) => {
        if (element.criteria.id === this.criteriaOption.criteria.id) {
          element.optionValues = this.answerValueDetail;
        }
      },
    );
    this.$emit('updateSubmitButton', { index: this.index, isError: false });
  }

  public handleMarkedNAValue() {
    this.hasSelectedNA = false;
    this.sliderValue = this.tempSliderValue
      ? this.tempSliderValue
      : this.minValue;
    const lastAnswerIndex = this.answerValueDetail[
      this.answerValueDetail.length - 1
    ];
    if (lastAnswerIndex.option !== 'N/A' && lastAnswerIndex.totalToDate === 0) {
      this.answerValueDetail.pop();
    }
    this.answerValueDetail.map((item: RangeOptionValueType) => {
      if (Number(item.option) === this.minValue) {
        item.value = 1;
      } else {
        item.value = 0;
      }
    });
    this.resultData.criteriaData.forEach(
      (element: DataCollectionCriteriaDataPayload) => {
        if (element.criteria.id === this.criteriaOption.criteria.id) {
          element.optionValues = this.answerValueDetail;
        }
      },
    );
  }

  @Watch('sliderValue', { deep: true })
  @isDifferent
  public watchSliderValue(newValue: number | string) {
    if (this.hasSelectedNA && newValue === this.minValue) {
      return;
    }

    this.sliderValue = newValue;
    this.hasSelectedNA = false;

    this.showRangeErrorMessage =
      newValue < this.minValue ||
      newValue > this.maxValue ||
      this.sliderValue === ''
        ? true
        : false;

    this.$emit('updateSubmitButton', {
      index: this.index,
      isError: this.showRangeErrorMessage,
    });

    if (!this.showValueErrorMessage) {
      const existingRangeValue = this.answerValueDetail.find(
        (data: RangeOptionValueType) =>
          Number(data.option) === Number(newValue),
      );
      const lastAnswerIndex = this.answerValueDetail[
        this.answerValueDetail.length - 1
      ];
      if (
        lastAnswerIndex.option !== 'N/A' &&
        lastAnswerIndex.totalToDate === 0
      ) {
        this.answerValueDetail.pop();
      }
      this.answerValueDetail.map((item: RangeOptionValueType) => {
        if (Number(newValue) === Number(item.option)) {
          item.value = 1;
        } else {
          item.value = 0;
        }
      });
      if (!existingRangeValue) {
        this.answerValueDetail.push({
          option: newValue,
          totalToDate: 0,
          value: 1,
        });
      }

      this.resultData.criteriaData.forEach(
        (element: DataCollectionCriteriaDataPayload) => {
          if (element.criteria.id === this.criteriaOption.criteria.id) {
            element.optionValues = this.answerValueDetail;
          }
        },
      );
    }
  }

  @Watch('tryToSubmitKey')
  public watchSubmitAction(): void {
    if (this.sliderValue === '') {
      this.showRangeErrorMessage = true;
      this.$emit('updateSubmitButton', {
        index: this.index,
        isError: this.showRangeErrorMessage,
      });
    }
  }
}
