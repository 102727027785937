import Vue from 'vue';
import Component from 'vue-class-component';
import { AxiosError } from 'axios';
import { get as _get } from 'lodash';
import { NotificationProgrammatic as Notification } from 'buefy';
import { Subject } from 'rxjs';
import { MutationPayload } from 'vuex';
import { DialogProgrammatic as Dialog } from 'buefy';

@Component({})
/**
 * Class ApiResponseErrorHandlerMixin
 */
export class ApiResponseErrorHandlerMixin extends Vue {
  /**
   * closeNotification: function
   * close notification flag
   */
  // tslint:disable-next-line
  public closeNotification: Function | null = null;
  /**
   * showingErrorDialog: boolean
   * showing Error Dialog flag
   */
  public showingErrorDialog: boolean = false;

  /**
   * created
   * Main function for error handling
   */
  public created() {
    const store = this.$store;
    const mutation$ = new Subject<MutationPayload>();
    store.subscribe((mutation) => {
      const { type } = mutation;
      if (type.endsWith('_ERROR')) {
        const error: AxiosError = mutation.payload;
        const status = _get(error, 'response.status') as number;
        const isForbidden = status === 403;
        const isInternalError = !status || status >= 500;
        const isValidationError = status === 422;
        const isPageNotFound = status === 404;
        const serviceUnavailable = status === 503;
        if (isForbidden) {
          this.showErrorDialogAndRedirect(
            `You do not have access to this resource.
            Please contact the PACES system administrator to obtain access.`,
          );
        } else if (isValidationError) {
          this.showErrorNotification(
            `Your request cannot be processed due to validation errors.`,
          );
        } else if (isPageNotFound) {
          this.showErrorDialogAndRedirect(
            `The requested URL was not found on this server. Please contact the PACES system administrator`,
          );
        } else if (serviceUnavailable) {
          if (
            mutation.payload.response &&
            mutation.payload.response.data &&
            mutation.payload.response.data.message
          ) {
            this.showErrorDialogAndRedirect(
              mutation.payload.response.data.message,
            );
          } else {
            this.showErrorNotification(`Server Error. Please try again later.`);
          }
        } else if (isInternalError) {
          this.showErrorNotification(`Server Error. Please try again later.`);
        }
      }
    });
  }

  public handleErrorResponse(error: any): void {
    const status: number = error.response.status;
    const isForbidden: boolean = status === 403;
    const isInternalError: boolean = !status || status >= 500;
    const isValidationError: boolean = status === 422;
    const isPageNotFound: boolean = status === 404;
    const serviceUnavailable: boolean = status === 503;
    if (isForbidden) {
      this.showErrorDialogAndRedirect(
        `You do not have access to this resource.
            Please contact the PACES system administrator to obtain access.`,
      );
    } else if (isValidationError) {
      this.showErrorNotification(
        `Your request cannot be processed due to validation errors.`,
      );
    } else if (isPageNotFound) {
      this.showErrorDialogAndRedirect(
        `The requested URL was not found on this server. Please contact the PACES system administrator`,
      );
    } else if (serviceUnavailable) {
      if (error && error.message) {
        this.showErrorDialogAndRedirect(error.response.data.message);
      } else {
        this.showErrorNotification(`Server Error. Please try again later.`);
      }
    } else if (isInternalError) {
      this.showErrorNotification(`Server Error. Please try again later.`);
    }
  }

  /**
   * errorDialogDisplayCb
   * Error dialog display call back
   */
  public errorDialogDisplayCb() {
    this.showingErrorDialog = false;
    this.$router.push('/');
  }

  /**
   * showErrorDialogAndRedirect
   * Show error dialog and redirect to dashboard
   * @param alertMsg
   */
  public showErrorDialogAndRedirect(alertMsg: string) {
    if (this.showingErrorDialog) {
      return;
    }
    this.showingErrorDialog = true;
    Dialog.alert({
      message: alertMsg,
      onConfirm: this.errorDialogDisplayCb,
      onCancel: this.errorDialogDisplayCb,
    });
  }

  /**
   * showErrorNotification
   * Show error notification on top
   * @param alertMsg
   */
  public showErrorNotification(alertMsg: string) {
    const { close: closeNotification } = Notification.open({
      type: 'is-danger',
      position: 'is-top',
      message: alertMsg,
      indefinite: true,
    });
    this.closeNotification = closeNotification;

    setTimeout(() => {
      if (this.closeNotification) {
        this.closeNotification();
      }
    }, 3000);
  }
}
