import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import auth from './modules/auth/auth.module';
import { AuthState } from './modules/auth/types/auth.types';
import { errorLogger } from './plugins/error-logging.plugin';
import projects from './modules/projects/projects.module';
import { ProjectsState } from '@/store/modules/projects/types/projects.types';
import { AuditsState } from '@/store/modules/audits/types/audits.types';
import audits from './modules/audits/audits.module';
import site from './modules/site/site.module';
import { SiteState } from './modules/site/types/site.types';
import { UsersState } from '@/store/modules/users/types/users.types';
import users from './modules/users/users.module';
import staticFile from './modules/static-file/static-file.module';
import { NotificationState } from '@/store/modules/notification/types/notification.types';
import notifications from './modules/notification/notification.module';

Vue.use(Vuex);

export interface RootState {
  auth: AuthState;
  projects: ProjectsState;
  audits: AuditsState;
  site: SiteState;
  users: UsersState;
  notifications: NotificationState;
}

const store = new Vuex.Store<RootState>({
  strict: process.env.NODE_ENV !== 'prod',
  modules: {
    auth,
    projects,
    audits,
    site,
    users,
    staticFile,
    notifications,
  },
  mutations: {},
  actions: {},
  plugins: [
    errorLogger,
    createPersistedState({
      paths: [
        'auth.isLoggedIn',
        'auth.authInfo',
        'auth.jaasAccountDetail',
        'auth.roles',
        'users.myRoles',
        'auth.subscriptionInfo',
      ],
      key: 'paces-vuex',
    }),
  ],
});
export default store;
