

























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ProjectCriteria } from '../../../store/modules/projects/types/projects.types';

@Component({
  components: {},
})
export default class GripAnalysisCriteria extends Vue {
  @Prop() public selectedCriteria!: ProjectCriteria[];
  public isOpen: boolean = false;

  public handledOpen(): void {
    this.isOpen = !this.isOpen;
  }
}
