import Vue from 'vue';
import Component from 'vue-class-component';

@Component({})
export class PaginationMixin extends Vue {
    get perPage(): number {
        return +this.$attrs.perPage;
    }
    set perPage(v: number) {
        this.$emit('update:perPage', v);
    }
    get page(): number {
        return +this.$attrs.page;
    }
    set page(v: number) {
        this.$emit('update:page', v);
    }
}
