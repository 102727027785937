


































































































































































































































import { Component, Vue, Prop, Watch, Model } from 'vue-property-decorator';
import { State, Getter, Action, Mutation } from 'vuex-class';
import {
  AuditSite,
  SamplingDataConfiguration,
} from '../../store/modules/audits/types/audits.types';
import { cloneDeep as _cloneDeep, map as _map, get as _get } from 'lodash';
import {
  getAdHocSampling,
  getConsecutiveMinMaxSampling,
  getDefaultSampling,
} from '../../components/reports/utils/ReportSampling.util';
import { ReportSamplingDto } from '../../store/modules/projects/types/projects.types';

const dirtyMonths: string[] = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

@Component({
  components: {},
})
export default class MultiSiteComparisonTable extends Vue {
  @Prop()
  public source!: any[];
  @Prop()
  public individualSiteMode!: boolean;
  @Prop()
  public comparisonPeriods!: any[];
  @Prop()
  public filteredSites!: number[];
  @Prop()
  public siteList!: AuditSite[];
  @Prop({ default: true })
  public showComplianceRate!: boolean;
  @Prop({ default: null })
  public periodsInfo!: any[];
  @Prop({ default: false })
  public auditReport!: boolean;

  public siteName: string = 'All Sites';
  public showIndividualSites: boolean = false;
  public dirtyComparisonPeriods: any[] = [];

  public mounted() {
    if (this.individualSiteMode) {
      this.showIndividualSites = true;
    }
    switch (this.filteredSites.length) {
      case 1:
        this.siteName = this.getName;
        break;
      case this.siteList.length:
        this.siteName = 'All Sites';
        break;
      default:
        this.siteName = 'Selected sites (' + this.filteredSites.length + ')';
    }
    this.dirtyComparisonPeriods = _cloneDeep(this.comparisonPeriods);
  }

  public getDate(newValue: Date): string {
    return (
      newValue.getDate() +
      ' ' +
      dirtyMonths[newValue.getMonth()] +
      ' ' +
      newValue.getFullYear()
    );
  }

  public formatDateRange(date: Date[]): string {
    return this.getDate(date[0]) + ' - ' + this.getDate(date[1]);
  }

  public getDataIndividualSite(keyValue: string): any[] {
    const data: any[] = [];
    this.getProjectSiteDetails.forEach(
      (projectSiteInfo: AuditSite, index: number) => {
        this.source.forEach((value) => {
          const dirtyData = value.criteriaSamplingData[index];
          let dataValue: string = '-';
          const matchedProjectSiteSampling: SamplingDataConfiguration = value.siteSamplingConfiguration.find(
            (item: SamplingDataConfiguration) =>
              item.auditSiteMap.site.id === projectSiteInfo.site.id,
          );
          /**
           * Reassign data record value in missing criteria and particular sites with '-'
           */
          if (value.criteriaSamplingDataConfiguration.length) {
            dataValue =
              matchedProjectSiteSampling &&
              matchedProjectSiteSampling.isSamplingEnabled &&
              dirtyData.criteriaOptionsDataDistribution[keyValue] !== undefined
                ? dirtyData.criteriaOptionsDataDistribution[keyValue]
                : '-';
          }
          data.push(dataValue);
        });
      },
    );
    return data;
  }

  get bodyItems(): any[] {
    const rowItems: any[] = [];
    const criteriaOptionKeys: string[] = this.getKeys;
    criteriaOptionKeys.forEach((optionKey: string) => {
      const val: number[] = [];
      const rowData: any = {
        optionKey: '' as string,
        periodData: [] as number[],
      };
      rowData.optionKey = optionKey;
      this.source.forEach((data) => {
        const dataOptionKeys: string[] = data.criteriaSamplingData.map(
          (entry: any) => Object.keys(entry)[0],
        );
        data.criteriaSamplingData.forEach((entry: any, index: any) => {
          if (
            Object.keys(entry)[0] === optionKey &&
            data.criteriaSamplingDataConfiguration.length
          ) {
            rowData.periodData.push(entry[optionKey]);
          } else {
            if (
              data.criteriaSamplingDataConfiguration.length &&
              index + 1 === data.criteriaSamplingData.length &&
              !dataOptionKeys.includes(optionKey)
            ) {
              rowData.periodData.push('-');
            }
          }
        });
        if (data.criteriaSamplingDataConfiguration.length === 0) {
          rowData.periodData.push('-');
        }
      });
      rowItems.push(rowData);
    });
    return rowItems;
  }

  public getSelectedSiteDetails(sites: AuditSite[]): AuditSite[] {
    return sites.filter((siteDetail) =>
      this.filteredSites.includes(siteDetail.id),
    );
  }

  get getSamplingAggregate() {
    const samplingRows: any[][] = [];
    const dirtySamplingRows: any[] = [];
    this.source.forEach((sourceItem, sourceItemIndex) => {
      switch (sourceItem.samplingConfiguration.auditSamplingType) {
        case 'adHoc':
          const dirtyAdHocSamplingRows: ReportSamplingDto[] = getAdHocSampling(
            this.periodsInfo[sourceItemIndex].isAudit,
            sourceItem.isAuditCriteria,
            sourceItem,
            this.getSelectedSiteDetails(this.siteList),
          );
          dirtySamplingRows.push(...dirtyAdHocSamplingRows);
          break;
        case 'consecutive':
          if (sourceItem.samplingConfiguration.samplingMode === 'minAndMax') {
            const dirtyConsecutiveMinMaxSamplingRows: ReportSamplingDto[] = getConsecutiveMinMaxSampling(
              this.periodsInfo[sourceItemIndex].isAudit,
              sourceItem.isAuditCriteria,
              sourceItem,
              this.getSelectedSiteDetails(this.siteList),
            );
            dirtySamplingRows.push(...dirtyConsecutiveMinMaxSamplingRows);
          } else {
            const dirtyDefaultConsecutiveSamplingRowsData: ReportSamplingDto[] = getDefaultSampling(
              this.periodsInfo[sourceItemIndex].isAudit,
              sourceItem.isAuditCriteria,
              sourceItem,
              this.getSelectedSiteDetails(this.siteList),
            );
            dirtySamplingRows.push(...dirtyDefaultConsecutiveSamplingRowsData);
          }
          break;
        default:
          const dirtyDefaultSamplingRowsData: ReportSamplingDto[] = getDefaultSampling(
            this.periodsInfo[sourceItemIndex].isAudit,
            sourceItem.isAuditCriteria,
            sourceItem,
            this.getSelectedSiteDetails(this.siteList),
            sourceItem.samplingConfiguration.samplingMode === 'siteBased',
          );
          dirtySamplingRows.push(...dirtyDefaultSamplingRowsData);
      }
    });
    const allSitesSampling = dirtySamplingRows.filter(
      (rowData: any) => rowData.siteName === 'All Sites',
    );
    const allSitesData: any[] = [];
    allSitesSampling.forEach((siteSampling) => {
      allSitesData.push(siteSampling.siteName);
      siteSampling.values.forEach((value: any) => {
        allSitesData.push(value);
      });
    });
    samplingRows.push(allSitesData);
    this.siteList.forEach((siteData) => {
      const siteSampling = dirtySamplingRows.filter(
        (rowData) => rowData.siteName === siteData.site.name,
      );
      const siteSamplingData: any[] = [];
      siteSampling.forEach((siteDataVar) => {
        siteSamplingData.push(siteDataVar.siteName);
        siteDataVar.values.forEach((value: any) => {
          siteSamplingData.push(value);
        });
      });
      samplingRows.push(siteSamplingData);
    });
    return samplingRows;
  }

  get getTotalData(): string[] {
    const totalData: string[] = [];
    this.source.forEach((data) => {
      let sum: number = 0;
      let sumNa: string = '';
      data.criteriaSamplingData.map((value: any) => {
        if (Object.values(value)[0] === '-') {
          return (sumNa = '-');
        }
        return (sum += Number(Object.values(value)[0]));
      });
      if (data.criteriaSamplingDataConfiguration.length === 0) {
        sumNa = '-';
      }
      if (sumNa === '-') {
        totalData.push(sumNa);
      } else {
        totalData.push(sum.toString());
      }
    });
    return totalData;
  }

  get getCompliance(): number[] {
    const allCompliance: any[] = [];
    const criteriaOptionKeys: string[] = this.getKeys;
    this.source.forEach((data) => {
      let totalSum: number = 0;
      let totalNA: number = 0;
      let totalYes: number = 0;
      data.criteriaSamplingData.map((value: any, valueIndex: number) => {
        totalSum += Number(Object.values(value)[0]);
        if (valueIndex === 2) {
          totalNA += Number(Object.values(value)[0]);
        } else if (valueIndex === 0) {
          totalYes += Number(Object.values(value)[0]);
        }
      });
      if (totalYes === 0) {
        const allComplianceValue: string = data
          .criteriaSamplingDataConfiguration.length
          ? '0%'
          : '-';
        allCompliance.push(allComplianceValue);
      } else {
        const rate: number = (totalYes / (totalSum - totalNA)) * 100;
        if (!rate) {
          allCompliance.push('-');
        } else {
          allCompliance.push(Number(rate.toFixed(2)) + '%');
        }
      }
    });
    return allCompliance;
  }

  get getKeys(): string[] {
    const keys: string[] = [];
    if (!this.auditReport && this.source) {
      this.source.forEach((sourceRecord: any) => {
        if (sourceRecord.criteriaSamplingData) {
          sourceRecord.criteriaSamplingData.forEach((optionSet: any) => {
            if (!keys.includes(Object.keys(optionSet)[0])) {
              keys.push(Object.keys(optionSet)[0]);
            }
          });
        }
      });
      return keys;
    } else if (this.source[0].criteriaSamplingData) {
      _map(this.source[0].criteriaSamplingData, (optionSet) => {
        keys.push(Object.keys(optionSet)[0]);
      });
      return keys;
    } else {
      return keys;
    }
  }

  get getName(): string {
    const selectedSite: AuditSite[] = this.siteList.filter(
      (site: AuditSite) => {
        return this.filteredSites.includes(site.id);
      },
    );
    return selectedSite[0].site.name;
  }

  get getSiteName(): string[] {
    const siteName: string[] = [];
    _map(this.siteList, (site: AuditSite) => {
      if (this.filteredSites.includes(site.id)) {
        siteName.push(site.site.name);
      }
    });
    return siteName;
  }

  get getNumberOfPeriods(): number {
    return this.dirtyComparisonPeriods.length;
  }

  get getPeriods(): string[] {
    const periods: any[] = [];
    _map(this.dirtyComparisonPeriods, (period: Date[], periodIndex: number) => {
      if (this.periodsInfo) {
        periods.push({
          title: this.periodsInfo[periodIndex].title,
          date: this.formatDateRange(period),
        });
      } else {
        periods.push({ date: this.formatDateRange(period) });
      }
    });
    return periods;
  }

  get getKeysIndividualSite(): string[] {
    const keys: string[] = [];
    if (!this.auditReport && this.source) {
      this.source.forEach((sourceRecord: any) => {
        if (sourceRecord.criteriaSamplingData) {
          sourceRecord.criteriaSamplingData.forEach((optionSet: any) => {
            Object.keys(optionSet.criteriaOptionsDataDistribution).forEach(
              (option) => {
                if (!keys.includes(option)) {
                  keys.push(option);
                }
              },
            );
          });
        }
      });
      return keys;
    } else if (
      this.source[0].criteriaSamplingData[0].criteriaOptionsDataDistribution
    ) {
      return Object.keys(
        this.source[0].criteriaSamplingData[0].criteriaOptionsDataDistribution,
      );
    } else {
      return keys;
    }
  }

  get getPeriodsIndividualSite(): string[] {
    const periods: any[] = [];
    _map(this.dirtyComparisonPeriods, (period: Date[], periodIndex: number) => {
      if (this.periodsInfo) {
        periods.push({
          title: this.periodsInfo[periodIndex].title,
          date: this.formatDateRange(period),
        });
      } else {
        periods.push({ date: this.formatDateRange(period) });
      }
    });
    const periodRow: any[] = [];
    _map(this.filteredSites, () => {
      _map(periods, (period: any) => {
        periodRow.push(period);
      });
    });
    return periodRow;
  }

  get getProjectSiteDetails(): AuditSite[] {
    return this.siteList.filter((data) => this.filteredSites.includes(data.id));
  }

  get getTotalDataIndividualSite(): string[] {
    const total: string[] = [];
    this.getProjectSiteDetails.forEach(
      (projectSiteInfo: AuditSite, index: number) => {
        this.source.forEach((value) => {
          const dirtyValue = value.criteriaSamplingData[index];
          const matchedProjectSiteSampling: SamplingDataConfiguration = value.siteSamplingConfiguration.find(
            (item: SamplingDataConfiguration) =>
              item.auditSiteMap.site.id === projectSiteInfo.site.id,
          );
          /**
           * Reassign data record value in missing criteria and particular sites with '-'
           */
          if (value.criteriaSamplingDataConfiguration.length) {
            const totalValue =
              matchedProjectSiteSampling &&
              matchedProjectSiteSampling.isSamplingEnabled
                ? dirtyValue.total
                : '-';
            total.push(totalValue);
          } else {
            total.push('-');
          }
        });
      },
    );
    return total;
  }

  get getComplianceIndividualSite(): string[] {
    const compliance: string[] = [];
    const keyNA = 'N/A';
    this.getProjectSiteDetails.forEach(
      (projectSiteInfo: AuditSite, index: number) => {
        this.source.forEach((value) => {
          const dirtyData: any = value.criteriaSamplingData[index];
          const keyYes = Object.keys(
            dirtyData.criteriaOptionsDataDistribution,
          )[0];
          const yes: number = dirtyData.criteriaOptionsDataDistribution[keyYes];
          const na: number = dirtyData.criteriaOptionsDataDistribution[keyNA];
          const total: number = dirtyData.total;
          const matchedProjectSiteSampling: SamplingDataConfiguration = value.siteSamplingConfiguration.find(
            (item: SamplingDataConfiguration) =>
              item.auditSiteMap.site.id === projectSiteInfo.site.id,
          );
          if (yes === 0) {
            /**
             * Reassign data record value in missing criteria and particular sites with '-'
             */
            const complianceValue: string =
              matchedProjectSiteSampling &&
              matchedProjectSiteSampling.isSamplingEnabled &&
              value.criteriaSamplingDataConfiguration.length
                ? '0%'
                : '-';
            compliance.push(complianceValue);
          } else {
            const eachCompliance: number = (yes / (total - na)) * 100;
            if (!eachCompliance) {
              compliance.push('-');
            } else {
              compliance.push(Math.round(eachCompliance * 100) / 100 + '%');
            }
          }
        });
      },
    );
    return compliance;
  }

  get sourceItems(): any[] {
    if (Array.isArray(this.source)) {
      return this.source;
    }
    return [];
  }

  get getSamplingTitles() {
    const samplingTitles: string[] = [];
    this.source.forEach((sourceItem, sourceItemIndex) => {
      samplingTitles.push('Site');
      switch (sourceItem.samplingConfiguration.auditSamplingType) {
        case 'adHoc':
          samplingTitles.push('Sampling');
          break;
        case 'consecutive':
          if (this.periodsInfo[sourceItemIndex].isAudit) {
            if (sourceItem.samplingConfiguration.samplingMode === 'minAndMax') {
              samplingTitles.push('Min.');
              samplingTitles.push('Max.');
            } else {
              samplingTitles.push('Target');
            }
          } else {
            samplingTitles.push('Sampling');
          }
          break;
        default:
          if (this.periodsInfo[sourceItemIndex].isAudit) {
            samplingTitles.push('Target');
          } else {
            samplingTitles.push('Sampling');
          }
      }
    });
    return samplingTitles;
  }

  get getSamplingTitlesAudit() {
    const samplingTitles: string[] = [];
    samplingTitles.push('Site');
    switch (this.source[0].samplingConfiguration.auditSamplingType) {
      case 'adHoc':
        samplingTitles.push('Sampling');
        break;
      case 'consecutive':
        if (this.source[0].samplingConfiguration.samplingMode === 'minAndMax') {
          samplingTitles.push('Min.');
          samplingTitles.push('Max.');
        } else {
          samplingTitles.push('Target');
        }
        break;
      default:
        samplingTitles.push('Target');
    }
    return samplingTitles;
  }

  get getSamplingAggregateAudit() {
    const samplingRows: any[] = [];
    switch (this.source[0].samplingConfiguration.auditSamplingType) {
      case 'adHoc':
        const adHocRowData: any[] = [];
        adHocRowData.push({
          siteName: 'All Sites',
          values: [
            this.source[0].criteriaSamplingDataConfiguration.isSamplingEnabled
              ? 'Enabled'
              : '-',
          ],
        });
        const adHocSitesData: boolean[] = [];
        this.source[0].siteSamplingConfiguration.forEach(
          (siteSampling: any) => {
            if (siteSampling.isSamplingEnabled) {
              adHocSitesData.push(true);
            } else {
              adHocSitesData.push(false);
            }
            adHocRowData.push({
              siteName: siteSampling.auditSiteMap.site.name,
              values: [siteSampling.isSamplingEnabled ? 'Enabled' : 'Disabled'],
            });
          },
        );
        const trueCount = adHocSitesData.filter((value) => value).length;
        adHocRowData[0] = {
          ...adHocRowData[0],
          values: [trueCount + '/' + adHocSitesData.length],
        };
        samplingRows.push(...adHocRowData);
        break;
      case 'consecutive':
        if (this.source[0].samplingConfiguration.samplingMode === 'minAndMax') {
          samplingRows.push({
            siteName: 'All Sites',
            values: [
              this.source[0].criteriaSamplingDataConfiguration.minSampleSize,
              this.source[0].criteriaSamplingDataConfiguration.maxSampleSize,
            ],
          });
          this.source[0].siteSamplingConfiguration.forEach(
            (siteSampling: any) => {
              samplingRows.push({
                siteName: siteSampling.auditSiteMap.site.name,
                values: [
                  siteSampling.minSampleSize,
                  siteSampling.maxSampleSize,
                ],
              });
            },
          );
        } else {
          samplingRows.push({
            siteName: 'All Sites',
            values: [
              this.source[0].criteriaSamplingDataConfiguration.sampleSize,
            ],
          });
          this.source[0].siteSamplingConfiguration.forEach(
            (siteSampling: any) => {
              samplingRows.push({
                siteName: siteSampling.auditSiteMap.site.name,
                values: [siteSampling.sampleSize],
              });
            },
          );
        }
        break;
      default:
        samplingRows.push({
          siteName: 'All Sites',
          values: [this.source[0].criteriaSamplingDataConfiguration.sampleSize],
        });
        this.source[0].siteSamplingConfiguration.forEach(
          (siteSampling: any) => {
            samplingRows.push({
              siteName: siteSampling.auditSiteMap.site.name,
              values: [siteSampling.sampleSize],
            });
          },
        );
    }
    return samplingRows;
  }
}
