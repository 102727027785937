var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card",class:[
    _vm.$style.criteriaAuditCard,
    _vm.auditDetails.isFutureAudit && _vm.isAuditUpNext
      ? _vm.$style.isActiveBackground
      : '',
    _vm.auditDetails.isFutureAudit && !_vm.isAuditUpNext
      ? _vm.$style.isFadedBackground
      : '' ]},[_c('div',{staticClass:"card-content",class:_vm.$style.criteriaAuditCardTop},[_c('div',{staticClass:"title padding-top padding-bottom"},[_c('a',{class:_vm.$style.auditTitle,attrs:{"href":"/audits/" + _vm.auditId + "/#dashboard"}},[_vm._v(_vm._s(_vm.auditDetails.name))])]),(_vm.auditDetails.startDate)?_c('div',[_c('label',{staticClass:"label"},[_vm._v(_vm._s(_vm.dataCollectionStatus === 'notStarted' ? 'Starts' : 'Started')+" "+_vm._s(_vm.auditDetails.startDate ? _vm.formatDate(_vm.auditDetails.startDate) : 'N/A'))])]):_vm._e(),(_vm.auditDetails.endDate)?_c('div',[_c('label',{staticClass:"label"},[_vm._v(_vm._s(_vm.dataCollectionStatus === 'completed' ? 'Ended' : 'Ends')+" "+_vm._s(_vm.formatDate(_vm.auditDetails.endDate)))])]):_vm._e()]),(_vm.auditDetails.isFutureAudit && _vm.isAuditUpNext)?_c('div',{staticClass:"card-content",class:_vm.dataCollectionStatus === 'ongoing'
        ? _vm.$style.onGoingAuditCardBottom
        : _vm.$style.criteriaAuditCardBottom},[_c('hr',{class:_vm.$style.hr}),(_vm.dataCollectionStatus === 'notStarted')?_c('b-button',{class:_vm.$style.configureBtn,attrs:{"tag":"router-link","to":"/audits/" + _vm.auditId,"expanded":""}},[_vm._v("Configure")]):_vm._e(),(_vm.dataCollectionStatus === 'completed')?_c('span',{class:_vm.$style.dataCollectionText},[_vm._v("Data Collection Period Completed")]):_vm._e(),(_vm.dataCollectionStatus === 'ongoing')?_c('div',[_c('p',{class:_vm.$style.percentageTitle},[_vm._v("Data Collected")]),_c('p',[_vm._v("0 %")]),_c('progress',{staticClass:"progress is-small is-primary",attrs:{"max":"100"},domProps:{"value":0}},[_vm._v(" 0 ")])]):_vm._e()],1):(!_vm.auditDetails.isFutureAudit)?_c('div',{staticClass:"card-content",class:_vm.dataCollectionStatus === 'ongoing'
        ? _vm.$style.onGoingAuditCardBottom
        : _vm.$style.criteriaAuditCardBottom},[_c('hr',{class:_vm.$style.hr}),(_vm.dataCollectionStatus === 'completed')?_c('span',{class:_vm.$style.dataCollectionText},[_vm._v("Data Collection Period Completed")]):_vm._e(),(_vm.dataCollectionStatus === 'ongoing')?_c('div',[_c('p',{class:_vm.$style.percentageTitle},[_vm._v("Data Collected")]),_c('p',[_vm._v("0 %")]),_c('progress',{staticClass:"progress is-small is-primary",attrs:{"max":"100"},domProps:{"value":0}},[_vm._v(" 0 ")])]):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }