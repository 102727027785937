





















































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import SiteAutoCompleteInput from '@/views/Project/components/SiteAutoCompleteInput.vue';
import {
  ProjectDetailPayload,
  Site,
} from '@/store/modules/projects/types/projects.types';
import {
  ProjectSite,
  UpdateProjectSitesRequestPayload,
} from '../../../store/modules/projects/types/projects.types';
import { Action, State } from 'vuex-class';
import { ApiState } from '../../../store/types/general.types';
import { isTruthy } from '../../../jbi-shared/util/watcher.vue-decorator';
import { ToastProgrammatic as Toast } from 'buefy';
import { RootState } from '@/store/store';

@Component({
  components: {
    SiteAutoCompleteInput,
  },
})
export default class SiteSettings extends Vue {
  @Prop({ default: true })
  public editable!: boolean;
  @Prop()
  public projectDetail!: ProjectDetailPayload;

  @Action('projects/getSites')
  public getSites!: () => void;

  @State((state: RootState) => state.projects.sites)
  public sites!: Site[];

  @Action('projects/getProjectSites')
  public getProjectSites!: (projectId: number) => void;

  @State((state: RootState) => state.projects.apiState.getProjectSites)
  public getProjectSitesState!: ApiState;

  @State((state: RootState) => state.projects.projectSites)
  public projectSites!: ProjectSite[];

  @Action('projects/updateProjectSites')
  public updateProjectSites!: (
    params: UpdateProjectSitesRequestPayload,
  ) => void;

  @State((state) => state.projects.apiState.updateProjectSites)
  public getUpdateProjectSitesState!: ApiState;

  @State((state) => state.projects.updatedProjectSites)
  public updatedProjectSites!: any[];

  public localSelectedSiteArray: Site[] = [];
  public renderComponent: boolean = true;
  public isSiteSubmitButtonDisabled: boolean = true;

  public mounted(): void {
    this.getSites();
    this.getProjectSites(this.projectDetail.id);
  }

  public handleSelectedSiteArray(newValue: Site[]): void {
    this.renderComponent = false;
    this.localSelectedSiteArray = newValue;
    this.$nextTick(() => {
      this.renderComponent = true;
    });
  }

  public handleRemoveSelectedSite(index: number): void {
    this.renderComponent = false;
    this.localSelectedSiteArray.splice(index, 1);
    this.$nextTick(() => {
      this.renderComponent = true;
    });
    this.localSelectedSiteArray = this.sanitizeSiteList(
      this.localSelectedSiteArray,
    );
  }

  public sanitizeSiteList(arr: Site[]): Site[] {
    return arr.filter((site) => {
      return site.name.trim() ? site.name : false;
    });
  }

  public addSiteCard(): void {
    this.localSelectedSiteArray.push({
      id: 0,
      name: '',
      address: '',
      description: '',
      isIdentifierRequired: false,
    } as Site);
  }

  public transformSites(sites: ProjectSite[]): void {
    this.localSelectedSiteArray = [];
    sites.forEach((site: ProjectSite) => {
      const transformedSite: Site = {
        id: site.site.id,
        name: site.site.name,
        address: site.site.address,
        description: site.site.description,
        isIdentifierRequired: site.isIdentifierRequired,
      };
      this.localSelectedSiteArray.push(transformedSite);
    });
    this.localSelectedSiteArray = this.localSelectedSiteArray.sort(
      (site1, site2) => site1.id - site2.id,
    );
  }

  public updateSitesHandler(): void {
    this.updateProjectSites({
      projectId: this.projectDetail.id,
      sites: this.localSelectedSiteArray,
    });
  }

  @Watch('projectSites')
  @isTruthy
  public watchProjectSites(): void {
    if (this.projectSites !== undefined && this.projectSites.length > 0) {
      this.transformSites(this.projectSites || []);
    } else {
      this.addSiteCard();
    }
  }

  @Watch('updatedProjectSites')
  @isTruthy
  public updateSavedSites(): void {
    this.isSiteSubmitButtonDisabled = true;
    Toast.open({
      message: `Changes saved`,
      position: 'is-top',
      type: 'is-dark',
    });
  }

  @Watch('localSelectedSiteArray', { deep: true })
  public onChangeLocalSelectedSiteArray(): void {
    this.isSiteSubmitButtonDisabled =
      JSON.parse(JSON.stringify(this.localSelectedSiteArray)) ===
      JSON.parse(JSON.stringify(this.projectSites));
  }
}
