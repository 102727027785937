





























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '@/utils/date.util';
import moment from 'moment';

@Component({ components: {} })
export default class AuditCard extends Vue {
  @Prop()
  public auditDetails: any;

  @Prop()
  public upNextAudit: any;

  public formatDate(s: string) {
    return dayjs(s).format(DATE_FORMAT);
  }

  get auditId() {
    return this.auditDetails.id;
  }

  get isAuditUpNext() {
    return this.upNextAudit && this.upNextAudit.id === this.auditId
      ? true
      : false;
  }

  public redirectToConfigureAudit() {
    this.$router.push({
      name: 'audit-sampling',
      params: {
        auditId: this.auditId.toString(),
      },
    });
  }

  get dataCollectionStatus() {
    if (moment(new Date()).isAfter(this.auditDetails.endDate)) {
      return 'completed';
    } else if (
      this.auditDetails.startedAt ||
      moment(new Date()).isBetween(
        this.auditDetails.startDate,
        this.auditDetails.endDate,
      )
    ) {
      return 'ongoing';
    } else {
      return 'notStarted';
    }
  }
}
