

















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ParticipantDTOForInviteList } from '../../../store/modules/projects/types/projects.types';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '../../../utils/date.util';
import {
  AuditParticipantDTO,
  AuditParticipantSiteMap,
} from '@/store/modules/audits/types/audits.types';

@Component({
  components: {},
})
export default class AuditInvitationList extends Vue {
  @Prop() public auditParticipants!: AuditParticipantDTO[];

  get participantData() {
    if (this.auditParticipants) {
      const data: ParticipantDTOForInviteList[] = [];
      this.auditParticipants.forEach((participant) => {
        participant.auditParticipantRoles.forEach((participantRole) => {
          if (!participant.isDeleted && !participantRole.isDeleted) {
            const siteInviteIds: number[] = [];
            participantRole.auditParticipantSites.forEach((siteMap) => {
              if (
                !siteMap.isDeleted &&
                !siteInviteIds.includes(siteMap.invite.id)
              ) {
                siteInviteIds.push(siteMap.invite.id);
              }
            });

            siteInviteIds.forEach((inviteId) => {
              const participantSites: AuditParticipantSiteMap[] = participantRole.auditParticipantSites.filter(
                (siteMap) =>
                  !siteMap.isDeleted && siteMap.invite.id === inviteId,
              );

              const participantDataWithInvite: ParticipantDTOForInviteList = {
                userEmail: participant.userEmail,
                participantRole: participantRole.role,
                participantSites: participantSites.map(
                  (siteMap) => siteMap.site.name,
                ),
                invite: participantSites[0].invite,
                isDeleted: participant.isDeleted,
              };
              data.push(participantDataWithInvite);
            });
          }
        });
      });

      return data.slice(0, 5);
    }
    return [];
  }

  get dayjs() {
    return dayjs;
  }

  public invitationDate(item: ParticipantDTOForInviteList) {
    return item.invite
      ? dayjs(item.invite.updatedAt || item.invite.createdAt).format(
          DATE_FORMAT,
        ) || null
      : null;
  }

  public invitationStatus(item: ParticipantDTOForInviteList) {
    const invitation = item.invite || '';

    if (invitation.acceptedAt) {
      return 'Accepted';
    }

    if (new Date(invitation.expiredAt) <= new Date()) {
      return 'Expired';
    } else {
      return 'Pending';
    }
  }

  public handleNavigate() {
    this.$emit('changeActiveTab', '#participants');
  }
}
