import { Store, ActionContext } from 'vuex';
import { notificationApi } from '@/api/notification.api';
import {
  NotificationState,
  UserNotificationPayload,
  GetNotificationsPayload,
  UpdateNewNotificationPayload,
} from './types/notification.types';
import { CRITERION_TYPE } from '../../types/criterions.types';
import { AuditDetail } from '../audits/types/audits.types';
import { RootState } from '@/store/store';
import { get as _get, map as _map } from 'lodash';
import { UpdateViewedSingleNotificationPayload } from '@/store/modules/notification/types/notification.types';

export const notificationActions = {
  async getUserNotification(
    store: Store<NotificationState>,
    payload: GetNotificationsPayload,
  ) {
    try {
      store.commit('GET_USER_NOTIFICATION_LOADING');
      const {
        data,
      }: {
        data: UserNotificationPayload;
      } = await notificationApi.getUserNotification(payload);
      store.commit('GET_USER_NOTIFICATION_SUCCESS', data);
    } catch (error) {
      store.commit('GET_USER_NOTIFICATION_ERROR', error);
    }
  },
  async updateViewedNotification(
    store: Store<NotificationState>,
    payload: UpdateNewNotificationPayload,
  ) {
    try {
      store.commit('UPDATE_VIEWED_NOTIFICATION_LOADING');
      const {
        data,
      }: {
        data: any;
      } = await notificationApi.updateViewedNotification(payload);
      store.commit('UPDATE_VIEWED_NOTIFICATION_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_VIEWED_NOTIFICATION_ERROR', error);
    }
  },
  async getNotificationDropdown(
    store: Store<NotificationState>,
    payload: GetNotificationsPayload,
  ) {
    try {
      store.commit('GET_NOTIFICATION_DROPDOWN_LOADING');
      const {
        data,
      }: {
        data: UserNotificationPayload;
      } = await notificationApi.getNotificationDropdown();
      store.commit('GET_NOTIFICATION_DROPDOWN_SUCCESS', data);
    } catch (error) {
      store.commit('GET_NOTIFICATION_DROPDOWN_ERROR', error);
    }
  },
  async updateViewedSingleNotification(
    store: Store<NotificationState>,
    payload: UpdateViewedSingleNotificationPayload,
  ) {
    try {
      store.commit('UPDATE_VIEWED_SINGLE_NOTIFICATION_LOADING');
      const {
        data,
      }: {
        data: any;
      } = await notificationApi.updateViewedSingleNotification(payload);
      store.commit('UPDATE_VIEWED_SINGLE_NOTIFICATION_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_VIEWED_SINGLE_NOTIFICATION_ERROR', error);
    }
  },
};
