














































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { uniq, cloneDeep as _cloneDeep } from 'lodash';

@Component({})
export default class BasePagination extends Vue {
  @Prop(Number) public page!: number;
  @Prop(Number) public totalNumberOfPage!: number;
  @Prop(Number) public totalCount!: number;
  @Prop(Number) public perPage!: number;
  @Prop(Boolean) public isFirstPage!: boolean;
  @Prop(Boolean) public isLastPage!: boolean;
  @Prop(Number) public startItemIndex!: number;
  @Prop(Number) public endItemIndex!: number;

  public insertedPageNumber: number = _cloneDeep(this.page);
}
