import dayjs from 'dayjs';
import XLSX from 'xlsx';
import JSZip from 'jszip';
import { ToastProgrammatic as Toast } from 'buefy';
import { Workbook } from 'exceljs';
import {
  ProjectDataCollectionRecordExportPayload,
  ProjectExportDataCollectionType,
} from '@/store/modules/projects/types/projects.types';

export class ProjectExportDataExcel {
  public async create(
    projectTitle: string,
    projectDataCollectionRecords: ProjectDataCollectionRecordExportPayload,
  ): Promise<void> {
    const zip = new JSZip();
    const exportDateTime = dayjs().format('D MMM YYYY');
    const truncatedProjectTitle =
      projectTitle.length > 10 ? projectTitle.slice(0, 10) : projectTitle;
    const exportTitle = truncatedProjectTitle + '_' + exportDateTime;

    // constrctedData is an array of with dynamic key attribute which stores information of
    // header, subheader and data record in the exported file
    const constructedDataCollectionRecords: any[] = [];

    const {
      aggregate,
      individual,
      combined,
    } = await this.getProjectDataCollectionRecord(projectDataCollectionRecords);

    constructedDataCollectionRecords.push(aggregate, individual, combined);

    const singleAnalysisWorkbook = new Workbook();
    constructedDataCollectionRecords.forEach((data: any, index: number) => {
      let exportDataCollectionMode: string;
      switch (index) {
        case 0:
          exportDataCollectionMode = ProjectExportDataCollectionType.aggregate;
          break;
        case 1:
          exportDataCollectionMode = ProjectExportDataCollectionType.individual;
          break;
        default:
          exportDataCollectionMode = ProjectExportDataCollectionType.combined;
      }
      const singleAnalysisWorksheet = singleAnalysisWorkbook.addWorksheet(
        exportDataCollectionMode,
      );
      data.forEach((item: any) => {
        singleAnalysisWorksheet.addRow(item);
      });
    });
    try {
      singleAnalysisWorkbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(blob, exportTitle + '.xlsx');
      });
    } catch (err) {
      Toast.open({
        message: `Something went wrong. Try again.`,
        position: 'is-top',
        type: 'is-danger',
        duration: 3500,
      });
    } finally {
      Toast.open({
        message: `Export complete.`,
        position: 'is-top',
        type: 'is-dark',
        duration: 3500,
      });
    }
  }

  public async getProjectDataCollectionRecord(
    projectDataCollectionRecords: ProjectDataCollectionRecordExportPayload,
  ) {
    const projectExportDataAggregate: any[] = this.modifiedProjectDataCollectionRecord(
      Object.values(projectDataCollectionRecords.aggregate.projectHeaderExport),
      Object.values(
        projectDataCollectionRecords.aggregate.projectSubHeaderExport,
      ),
      projectDataCollectionRecords.aggregate
        .projectDataCollectionRecordAggregate,
    );

    const projectExportDataIndividual: any[] = this.modifiedProjectDataCollectionRecord(
      Object.values(
        projectDataCollectionRecords.individual.projectHeaderExportIndividual,
      ),
      Object.values(
        projectDataCollectionRecords.individual
          .projectSubHeaderExportIndividual,
      ),
      projectDataCollectionRecords.individual
        .projectDataCollectionRecordIndividual,
    );

    const projectExportDataCombined: any[] = this.modifiedProjectDataCollectionRecord(
      Object.values(projectDataCollectionRecords.combined.projectHeaderExport),
      Object.values(
        projectDataCollectionRecords.combined.projectSubHeaderExport,
      ),
      projectDataCollectionRecords.combined.projectDataCollectionRecordInfo,
    );

    return {
      aggregate: projectExportDataAggregate,
      individual: projectExportDataIndividual,
      combined: projectExportDataCombined,
    };
  }

  public modifiedProjectDataCollectionRecord(
    dataCollectionRecordHeader: any,
    dataCollectionRecordSubHeader: any,
    dataCollectionRecordInfo: any,
  ) {
    const projectExportDataCollection: any[] = [];
    projectExportDataCollection.push(
      dataCollectionRecordHeader,
      dataCollectionRecordSubHeader,
    );

    dataCollectionRecordInfo.forEach((data: any) => {
      projectExportDataCollection.push(Object.values(data));
    });

    return projectExportDataCollection;
  }
}
