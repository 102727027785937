























































import { Component, Vue, Prop, Watch, Model } from 'vue-property-decorator';
import { State, Getter, Action, Mutation, namespace } from 'vuex-class';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { Site } from '@/store/modules/site/types/site.types';
import { RootState } from '@/store/store';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { ToastProgrammatic as Toast } from 'buefy';

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class CreateSiteForm extends Vue {
  public name = '';
  public address = '';
  public description = '';

  @Action('projects/getSites')
  public getSites!: () => void;

  @Action('projects/createSite')
  public createSite!: (newSite: Partial<Site>) => void;

  public async handleSubmit() {
    // @ts-ignore
    const isValid = await this.$refs.observer.validate();
    if (!isValid) {
      return;
    }

    const result = await this.createSite({
      name: this.name,
      address: this.address,
      description: this.description,
    });
    await this.getSites();

    this.$emit('submit', result);
    this.$emit('close');
    // TODO: this should track instead of toast
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `New Site Create Successfully`,
    });
  }
}
