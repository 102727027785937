





























































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { cloneDeep as _cloneDeep, map as _map } from 'lodash';
import { Action, State } from 'vuex-class';
import SiteFilter from '@/views/Audit/components/SiteFilter.vue';
import CriteriaFilter from '@/views/Audit/components/CriteriaFilter.vue';
import ChartTypeFilter from '@/views/Audit/components/ChartTypeFilter.vue';
import AnalysisPeriodPicker from '@/views/Audit/components/AnalysisPeriodPicker.vue';
import AnalysisComparisonPeriodPicker from '@/views/Audit/components/AnalysisComparisonPeriodPicker.vue';
import {
  AuditSite,
  AuditCriteria,
  ChartType,
  AuditReportFiltersConfig,
} from '@/store/modules/audits/types/audits.types';

@Component({
  components: {
    SiteFilter,
    CriteriaFilter,
    ChartTypeFilter,
    AnalysisPeriodPicker,
    AnalysisComparisonPeriodPicker,
  },
})
export default class AuditReportFilters extends Vue {
  @Prop()
  public auditSites!: AuditSite[];
  @Prop()
  public auditCriteria!: AuditCriteria[];
  @Prop()
  public analysisFilter!: AuditReportFiltersConfig;
  @Prop()
  public reportLoading!: boolean;

  public filteredSites: number[] = [];
  public filteredCriterias: number[] = [];
  public selectedChartType: string = ChartType.verticalBarChart;
  public todayDate: Date = this.setTimeEnd(new Date());
  public basePeriod: Date[] = [
    new Date(
      this.todayDate.getFullYear(),
      this.todayDate.getMonth(),
      this.todayDate.getDate() - 7,
    ),
    this.todayDate,
  ];
  public selectedPeriod: Date[] = [];
  public checkboxIndividualSite: boolean = false;
  public checkboxIndividualCriteria: boolean = false;
  public showComparisonContainer: boolean = false;
  public showSinglePeriodContainer: boolean = true;
  public comparisonPeriods: Date[][] = [];
  public comparisonPeriodsKey: boolean = true;
  public populateDataSuccess: boolean = false;

  public mounted() {
    this.populateData();
  }

  public populateData() {
    this.filteredSites = _cloneDeep(this.analysisFilter.filteredSites);
    this.filteredCriterias = _cloneDeep(this.analysisFilter.filteredCriterias);
    this.selectedChartType = this.analysisFilter.selectedChartType;
    this.checkboxIndividualSite = this.analysisFilter.checkIndividualSite;
    this.checkboxIndividualCriteria = this.analysisFilter.checkIndividualCriteria;
    this.selectedPeriod = _cloneDeep(this.analysisFilter.selectedPeriod);
    this.showComparisonContainer = this.analysisFilter.checkComparison;
    this.comparisonPeriods = _cloneDeep(this.analysisFilter.comparisonPeriods);
    if (this.showComparisonContainer) {
      this.showSinglePeriodContainer = false;
    }
    this.populateDataSuccess = true;
  }

  get siteList(): any[] {
    const siteArray: any[] = [];
    _map(this.auditSites, (auditSite) => {
      siteArray.push({
        siteId: auditSite.site.id,
        name: auditSite.site.name,
        auditSiteId: auditSite.id,
      });
    });
    return siteArray;
  }

  get criteriaList(): AuditCriteria[] {
    return this.auditCriteria;
  }

  public applySiteFilter(newValue: number[]) {
    this.filteredSites = newValue;
    this.showAnalysisResult();
  }

  public applyCriteriaFilter(newValue: number[]) {
    this.filteredCriterias = newValue;
    this.showAnalysisResult();
  }

  public applyChartType(newValue: ChartType) {
    this.selectedChartType = newValue;
    this.showAnalysisResult();
  }

  public applyAnalysisPeriod(newValue: Date[]) {
    this.selectedPeriod = newValue;
    this.showAnalysisResult();
  }

  public addNewPeriod() {
    this.showComparisonContainer = true;
    this.showSinglePeriodContainer = false;
    if (this.comparisonPeriods.length === 1) {
      this.comparisonPeriods = _cloneDeep([
        this.selectedPeriod,
        this.basePeriod,
      ]);
    } else {
      this.comparisonPeriods.push(this.basePeriod);
    }
    this.showAnalysisResult();
  }

  public handleRemovePeriod(comparisonPeriodIndex: number) {
    this.comparisonPeriods.splice(comparisonPeriodIndex, 1);
    if (this.comparisonPeriods.length === 1) {
      this.selectedPeriod = _cloneDeep(this.comparisonPeriods[0]);
      this.showComparisonContainer = false;
      this.showSinglePeriodContainer = true;
    }
    this.showAnalysisResult();
  }

  public applyAnalysisComparisonPeriod(newValue: {
    period: Date[];
    periodIndex: number;
  }) {
    this.comparisonPeriods[newValue.periodIndex] = _cloneDeep(newValue.period);
    this.showAnalysisResult();
  }

  public showAnalysisResult() {
    const filters: AuditReportFiltersConfig = {
      filteredSites: this.filteredSites,
      filteredCriterias: this.filteredCriterias,
      selectedChartType: this.selectedChartType,
      selectedPeriod: this.selectedPeriod,
      checkIndividualSite: this.checkboxIndividualSite,
      checkIndividualCriteria: this.checkboxIndividualCriteria,
      checkComparison: this.showComparisonContainer,
      comparisonPeriods: this.comparisonPeriods,
    };
    this.$emit('applyAnalysisFilters', filters);
  }

  public setTimeEnd(date: Date): Date {
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    return date;
  }

  public setTimeStart(date: Date): Date {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    return date;
  }

  get getSelectedPeriod(): Date[] {
    return this.selectedPeriod;
  }
}
