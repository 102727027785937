





















































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { State, Action } from 'vuex-class';
import { ToastProgrammatic as Toast } from 'buefy';
import {
  DeleteGripDomainCategoryPayload,
  GripDomainCategory,
  GripDomainCategoryDetails,
} from '../../../store/types/grip.types';
import { ApiState } from '../../../store/types/general.types';
import { isTruthy } from '../../../jbi-shared/util/watcher.vue-decorator';
import BaseLoading from '../../../components/base/BaseLoading.vue';

@Component({
  components: { BaseLoading },
})
export default class DeleteDomainCategoryForm extends Vue {
  public name = '';
  public errorMessage: boolean = false;
  public selectedGripDomainCategory: string = 'None';
  public gripDomainCategoryOptions: string[] = [];
  public isCurrentGripDomainCategoryAssigned: boolean = false;
  public isLoading: boolean = true;

  @Prop() public projectId!: number;
  @Prop() public gripDomainCategoryId!: number;
  @Prop() public gripDomainCategories!: GripDomainCategory[];

  @Action('projects/getGripDomainCategories')
  public getGripDomainCategories!: (projectId: number) => void;

  @Action('projects/getGripDomainCategoryDetails')
  public getGripDomainCategoryDetails!: (payload: {
    projectId: number;
    gripDomainCategoryId: number;
  }) => void;

  @State((state) => state.projects.apiState.getGripDomainCategory)
  public getGripDomainCategoryApiState!: ApiState;

  @State((state) => state.projects.gripDomainCategory)
  public gripDomainCategoryDetails!: GripDomainCategoryDetails;

  @Action('projects/deleteGripDomainCategory')
  public deleteGripDomainCategory!: (
    payload: DeleteGripDomainCategoryPayload,
  ) => void;

  @State((state) => state.projects.apiState.deleteGripDomainCategory)
  public deleteGripDomainCategoryApiState!: ApiState;

  public mounted(): void {
    this.getGripDomainCategoryDetails({
      projectId: this.projectId,
      gripDomainCategoryId: this.gripDomainCategoryId,
    });
  }

  public handleSubmit(): void {
    const selectedGripDomainCategory:
      | GripDomainCategory
      | undefined = this.gripDomainCategories.find(
      (data: GripDomainCategory) =>
        data.name === this.selectedGripDomainCategory,
    );
    this.deleteGripDomainCategory({
      projectId: this.projectId,
      currentGripDomainCategoryId: this.gripDomainCategoryDetails.id,
      newGripDomainCategoryId:
        selectedGripDomainCategory && selectedGripDomainCategory.id
          ? selectedGripDomainCategory.id
          : null,
      isCurrentGripDomainCategoryAssigned: this
        .isCurrentGripDomainCategoryAssigned,
    });
    this.$emit('close');
  }

  public optionSelect(selectedOption: string): void {
    this.selectedGripDomainCategory = selectedOption;
    this.gripDomainCategoryOptions = this.gripDomainCategories
      .filter(
        (data: GripDomainCategory) =>
          data.name !== this.selectedGripDomainCategory,
      )
      .map((data: GripDomainCategory) => data.name);

    if (this.selectedGripDomainCategory !== 'None') {
      this.gripDomainCategoryOptions.push('None');
    }
  }

  /**
   * Get info of GripDomainCategory being assinged to
   * Barriers/Enablers/Startegies and
   * Analysis Barriers/Enablers/Startegies
   */
  public getGripDomainCategoryAssingedInfo(): string {
    let message: string = '';
    if (!this.gripDomainCategoryDetails) {
      return message;
    }
    const gripBarrier: number = this.gripDomainCategoryDetails.gripBarrierCount;
    const gripEnabler: number = this.gripDomainCategoryDetails.gripEnablerCount;
    const gripStrategy: number = this.gripDomainCategoryDetails
      .gripStrategyCount;
    const gripAnalysisBarrier: number = this.gripDomainCategoryDetails
      .gripAnalysisBarrierCount;
    const gripAnalysisEnabler: number = this.gripDomainCategoryDetails
      .gripAnalysisEnablerCount;
    const gripAnalysisStrategy: number = this.gripDomainCategoryDetails
      .gripAnalysisStrategyCount;

    const dataArray: string[] = [];
    if (gripBarrier > 0) {
      dataArray.push(`${gripBarrier} barrier(s)`);
    }
    if (gripEnabler > 0) {
      dataArray.push(`${gripEnabler} enabler(s)`);
    }
    if (gripStrategy > 0) {
      dataArray.push(`${gripStrategy} strategy(ies)`);
    }
    if (gripAnalysisBarrier > 0) {
      dataArray.push(`${gripAnalysisBarrier} analysis barrier(s)`);
    }
    if (gripAnalysisEnabler > 0) {
      dataArray.push(`${gripAnalysisEnabler} analysis enabler(s)`);
    }
    if (gripAnalysisStrategy > 0) {
      dataArray.push(`${gripAnalysisStrategy} analysis strategy(ies)`);
    }
    if (dataArray.length > 0) {
      message += `${this.gripDomainCategoryDetails.name} is currently being used in `;
      dataArray.forEach((data, index) => {
        if (index === dataArray.length - 1) {
          message += `${data}.`;
        } else if (index === dataArray.length - 2) {
          message += `${data} and `;
        } else {
          message += `${data}, `;
        }
      });
    }
    return message;
  }

  @Watch('getGripDomainCategoryApiState.success')
  @isTruthy
  public watchGripDomainCategoryApiState(): void {
    this.isLoading = false;
    const barrier: number = this.gripDomainCategoryDetails.gripBarrierCount;
    const enabler: number = this.gripDomainCategoryDetails.gripEnablerCount;
    const strategy: number = this.gripDomainCategoryDetails.gripStrategyCount;
    const analysisBarrier: number = this.gripDomainCategoryDetails
      .gripAnalysisBarrierCount;
    const analysisEnabler: number = this.gripDomainCategoryDetails
      .gripAnalysisEnablerCount;
    const analysisStrategy: number = this.gripDomainCategoryDetails
      .gripAnalysisStrategyCount;

    if (
      barrier === 0 &&
      enabler === 0 &&
      strategy === 0 &&
      analysisBarrier === 0 &&
      analysisEnabler === 0 &&
      analysisStrategy === 0
    ) {
      this.isCurrentGripDomainCategoryAssigned = false;
    } else {
      this.isCurrentGripDomainCategoryAssigned = true;
    }
    this.gripDomainCategoryOptions = this.gripDomainCategories
      .filter(
        (gripDomainCategory: GripDomainCategory) =>
          gripDomainCategory.name !== this.gripDomainCategoryDetails.name,
      )
      .map((gripDomainCategory: GripDomainCategory) => gripDomainCategory.name);
  }

  @Watch('deleteGripDomainCategoryApiState.success')
  @isTruthy
  public onDeleteGripDomainCategorySuccess(): void {
    this.getGripDomainCategories(this.projectId);
    this.$emit('close');
    Toast.open({
      message: `successfully deleted`,
      position: 'is-top',
      type: 'is-dark',
      duration: 3500,
    });
  }
}
