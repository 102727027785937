


























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { AuditDetail } from '@/store/modules/audits/types/audits.types';
import dayjs from 'dayjs';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '../../../utils/date.util';

@Component({})
export default class AuditHeader extends Vue {
  @Prop()
  public auditDetail!: AuditDetail;
  @Prop({ default: false })
  public dataCollectorRole!: boolean;
  @Prop()
  public auditSettingInfo!: string;

  public getFormattedTitle(title: string) {
    if (title.length > 45) {
      return title.substring(0, 45) + '...';
    } else {
      return title;
    }
  }

  get auditPageSubHeading() {
    if (this.auditDetail.auditCategory === 'Cycle') {
      return (
        this.auditDetail.auditCategory +
        ', Block ' +
        this.auditDetail.auditCycleNo
      );
    } else {
      return this.auditDetail.auditCategory;
    }
  }

  get getProjectUrl() {
    if (this.auditDetail) {
      let projectUrl =
        '/projects/' + this.auditDetail.project.id + '#dashboard';
      if (this.dataCollectorRole) {
        projectUrl = '/projects/' + this.auditDetail.project.id + '/homepage';
      }
      return projectUrl;
    }
    return '';
  }

  public formatDate(s: string) {
    return dayjs(s).format(DATE_TIME_FORMAT);
  }
}
