import { siteState } from './site.state';
import { siteActions } from './site.actions';
import { siteMutations } from './site.mutations';
import { siteGetters } from './site.getters';
import { ModuleTree } from 'vuex';
import { RootState } from '@/store/store';

export default {
  namespaced: true,
  state: siteState,
  getters: siteGetters,
  actions: siteActions,
  mutations: siteMutations,
} as ModuleTree<RootState>;
