


























import Header from '../components/base/Header.vue';
import Footer from '../components/base/Footer.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: { Header, Footer },
})
export default class DefaultLayout extends Vue {
  public redirectToSupport() {
    window.open(
      `${Vue.prototype.JBI_GLOBAL_URL}/servicedesk/customer/portal/3`,
      '_blank',
    );
  }
}
