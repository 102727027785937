




















































import { Component, Vue, Prop } from 'vue-property-decorator';
import { State, Action } from 'vuex-class';
import {
  PaginatedProjectEventLogsPayload,
  PaginatedProjectEventLogsResponse,
} from '@/store/modules/projects/types/projects.types';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '@/utils/date.util';
import { useUserEmail } from '@/utils/user.util';

@Component({
  components: {},
})
export default class ActivityLogList extends Vue {
  @Prop()
  public projectId!: number;

  @Action('projects/getPaginatedEventLogs')
  public getRecentEventLogs!: (
    params: PaginatedProjectEventLogsPayload,
  ) => void;

  @State((state) => state.projects.paginatedEventLogs)
  public recentEventLogs!: PaginatedProjectEventLogsResponse;

  public mounted() {
    this.getRecentEventLogs({
      projectId: this.projectId,
      limit: 10,
      page: 1,
    });
  }

  get histories() {
    if (this.recentEventLogs) {
      return this.recentEventLogs.items
        .filter((log: any) => {
          return log.eventPayload.originator;
        })
        .map((log: any) => {
          const today = dayjs().format(DATE_FORMAT);
          const yesterday = dayjs().add(-1, 'day').format(DATE_FORMAT);
          const dataUpdatedAt = dayjs(new Date(log.eventOccurredAt)).format(
            DATE_FORMAT,
          );

          let dateToDisplay;
          if (dataUpdatedAt === today) {
            dateToDisplay = 'Today';
          } else if (dataUpdatedAt === yesterday) {
            dateToDisplay = 'Yesterday';
          } else {
            dateToDisplay = dataUpdatedAt;
          }

          return {
            ...log,
            // @ts-ignore
            datetime: dateToDisplay,
          };
        });
    }
    return [];
  }

  public getUserName(userInfo: any) {
    const name = userInfo.name;
    const loggedInUserEmail = this.userEmail;
    if (loggedInUserEmail === userInfo.email) {
      return 'You';
    } else {
      return name;
    }
  }

  public handleNavigate() {
    this.$emit('changeActiveTab', '#activityLog');
  }

  get userEmail(): string | undefined {
    return useUserEmail.call(this);
  }
}
